package com.speechify.client.internal.util.extensions.collections

/**
 * Adds [elementToConcatAfterLast] to the end if [this] is not empty.
 */
internal fun <T> Array<T>.concatItemToNonEmpty(
    elementToConcatAfterLast: (last: T) -> T,
): Sequence<T> =
    this.asSequence().concatItemToNonEmpty(elementToConcatAfterLast)

/**
 * Adds [elementToConcatAfterLast] to the end if [this] is not empty.
 */
internal fun <T> Sequence<T>.concatItemToNonEmpty(
    elementToConcatAfterLast: (last: T) -> T,
): Sequence<T> =
    concatToNonEmpty { last -> sequenceOf(elementToConcatAfterLast(last)) }

/**
 * Adds [elementsToConcatAfterLast] to the end if [this] is not empty.
 */
internal fun <T> Sequence<T>.concatToNonEmpty(
    elementsToConcatAfterLast: (last: T) -> Sequence<T>,
): Sequence<T> = sequence {
    val iterator = this@concatToNonEmpty.iterator()
    if (!iterator.hasNext()) {
        return@sequence
    }

    var lastElement = iterator.next()
    yield(lastElement)

    for (element in iterator) {
        yield(element)
        lastElement = element
    }

    yieldAll(elementsToConcatAfterLast(lastElement).asIterable())
}
