package com.speechify.client.api.adapters.mediaplayer

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
abstract class LocalMediaPlayer {
    abstract fun play()

    abstract fun seek(timeInMilliseconds: Int)

    abstract fun pause()

    protected abstract fun getCurrentTimeInMilliseconds(callback: (Int) -> Unit)

    internal suspend fun getCurrentTimeInMilliseconds(): Int =
        suspendCoroutine { continuation ->
            getCurrentTimeInMilliseconds(continuation::resume)
        }

    abstract fun updateOptions(options: LocalMediaPlayerOptions)

    abstract fun setSpeed(speed: Float)

    abstract fun setVolume(volume: Float)

    abstract fun getOptions(callback: (LocalMediaPlayerOptions) -> Unit)

    protected abstract fun setEventListener(callback: Callback<LocalMediaPlayerEvent>)

    internal fun getEventsColdFlow(): Flow<Result<LocalMediaPlayerEvent>> =
        callbackFlowNeverThrowingToProducer(
            /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
             *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
             *  until no more items coming is ensured)
             */
            shouldLogErrorIfCancellationPreventedDelivery = false,
            sourceAreaId = "LocalMediaPlayer.getFlow",
            bufferCapacity = Channel.UNLIMITED,
        ) {
            setEventListener(callback = {
                this.send(it)
            },)
            awaitClose()
        }

    abstract fun destroy()
}
