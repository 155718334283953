package com.speechify.client.helpers.content.standard.book.heuristics.v2.models

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.helpers.content.standard.book.heuristics.v2.getText

internal class ParsingContext(
    input: List<BookPageTextContentItem>,
    private val logger: Logger? = null,
) {

    val platform: RawContentType = identifyRawContentType(input)

    private fun identifyRawContentType(input: List<BookPageTextContentItem>): RawContentType {
        return when (isRawContentLines(input)) {
            true -> RawContentType.LINES
            false -> RawContentType.WORDS
        }.also(::logSetRawContentType)
    }

    private fun isRawContentLines(raw: List<BookPageTextContentItem>): Boolean {
        val isRawContentLines = raw.any { it.getText().contains(" ") }
        return isRawContentLines
    }

    private fun logSetRawContentType(type: RawContentType) {
        logger?.log("Raw content type is: $type")
    }
}

internal enum class RawContentType {
    LINES,
    WORDS,
}
