package com.speechify.client.bundlers.reading

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.ensureListenableMimeType
import com.speechify.client.api.util.fromCoWithTelemetryLoggingErrors
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentBundler
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload
import com.speechify.client.bundlers.content.SpeechifyContentBundler
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import com.speechify.client.bundlers.reading.classic.ClassicReadingBundle
import com.speechify.client.bundlers.reading.epub.EpubReadingBundle
import com.speechify.client.helpers.features.SavedListeningProgress
import com.speechify.client.helpers.features.getSavedListeningProgress
import com.speechify.client.internal.time.DateTime
import kotlin.js.JsExport

/**
 * A bundler for 'universal sources of content', i.e. things like URLs, Files, [SpeechifyURI]s which are universal for
 * different bundle types, and where the bundle type will be discovered as appropriate (e.g. [BookReadingBundle] will
 * be created if the underlying content is of supported type).
 */
@JsExport
class UniversalSourcesReadingBundler internal constructor(
    override val listeningBundler: ListeningBundler,
    private val contentBundler: ContentBundler,
    override val speechifyClient: SpeechifyClient,
    override val speechifyContentBundler: SpeechifyContentBundler,
    private val eventsTrackerAdapter: EventsTrackerAdapter,
) : ReadingBundler() {
    /**
     * Creates a [ReadingBundle] for the specified Speechify Resource.
     * If you have access to a [LibraryItem.Content] use [createBundleForLibraryItem] instead.
     */
    fun createBundleForResource(
        uri: SpeechifyURI,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        scope,
        telemetryEventName = "${UniversalSourcesReadingBundler::class.simpleName}.createBundleForResource",
    ) { telemetryEventBuilder ->
        telemetryEventBuilder.addProperty("type", uri.type.name)
        telemetryEventBuilder.addProperty("id", uri.id)

        val (contentBundle, savedListeningProgress) = when (uri.type) {
            SpeechifyEntityType.LIBRARY_ITEM, SpeechifyEntityType.SCANNED_BOOK -> {
                val libraryItem = telemetryEventBuilder.addMeasurement("loadLibraryItem") {
                    speechifyClient.libraryService.delegate
                        .getItemFromFirestoreOrLocalFromUri(uri) as? LibraryItem.ListenableContent
                        ?: return@fromCoWithTelemetryLoggingErrors Result.Failure(
                            SDKError.OtherMessage("Folders not yet supported for bundling"),
                        )
                }
                val contentBundle =
                    speechifyContentBundler.coCreateBundleForLibraryItem(libraryItem, bundleMetadata)
                        .orReturn { return@fromCoWithTelemetryLoggingErrors it }
                val firebaseListeningProgress = libraryItem.getSavedListeningProgress()
                val localListeningProgress = speechifyClient.importService.getLocalListeningProgress(uri)
                val localListeningProgressLastUpdatedTime = localListeningProgress?.lastUpdatedTime ?: 0
                if (localListeningProgress != null && localListeningProgress.listeningProgress != null &&
                    localListeningProgressLastUpdatedTime >
                    (firebaseListeningProgress?.lastUpdateTime?.asSeconds() ?: 0)
                ) {
                    val lastListenedASDateTime = DateTime.fromSeconds(localListeningProgress.lastUpdatedTime.toDouble())
                    contentBundle to
                        SavedListeningProgress.CursorProgress(
                            localListeningProgress.listeningProgress.cursor,
                            lastListenedASDateTime,
                        )
                } else {
                    contentBundle to firebaseListeningProgress
                }
            }

            SpeechifyEntityType.AUDIOBOOK_CHAPTER -> {
                val contentBundle = speechifyContentBundler.coCreateBundleForResource(uri, bundleMetadata)
                    .orReturn { return@fromCoWithTelemetryLoggingErrors it }

                val savedListeningProgress = speechifyClient
                    .audiobookLibraryService
                    .coGetAudiobookChapter(uri.id)
                    .toNullable()
                    ?.listeningProgress
                    ?.let { SavedListeningProgress.CursorProgress(it.cursor, DateTime.fromIsoString(it.timestamp)) }

                contentBundle to savedListeningProgress
            }

            SpeechifyEntityType.FOLDER ->
                throw IllegalArgumentException("Cannot bundle folders")
        }

        val startingCursor = savedListeningProgress?.toCursor(contentBundle)

        val dependencies = createDependencies(
            contentBundle = contentBundle,
            startingCursor = startingCursor ?: contentBundle.standardView.start,
            bundleMetadata = bundleMetadata,
            eventsTrackerAdapter = eventsTrackerAdapter,
        ).orReturn { return@fromCoWithTelemetryLoggingErrors it }

        return@fromCoWithTelemetryLoggingErrors createBundleFromDependencies(dependencies, bundleMetadata)
            .successfully()
    }

    /**
     * Creates a [ReadingBundle] for the specified [LibraryItem.ListenableContent].
     * This is more efficient than [createBundleForResource] because it doesn't need to fetch the [LibraryItem.ListenableContent]
     * from Firestore first instead using the data you already have available.
     */
    fun createBundleForLibraryItem(
        libraryItem: LibraryItem.ListenableContent,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        scope,
        telemetryEventName = "${UniversalSourcesReadingBundler::class.simpleName}.createBundleForLibraryItem",
    ) { telemetryEventBuilder ->
        telemetryEventBuilder.addUUID()
        createBundleForLibraryItem(libraryItem, telemetryEventBuilder, bundleMetadata)
    }

    private suspend fun createBundleForLibraryItem(
        libraryItem: LibraryItem.ListenableContent,
        telemetryEventBuilder: TelemetryEventBuilder?,
        bundleMetadata: BundleMetadata? = null,
    ): Result<ReadingBundle> {
        telemetryEventBuilder?.addProperty("type", libraryItem.uri.type.name)
        telemetryEventBuilder?.addProperty("id", libraryItem.uri.id)

        val contentBundle = speechifyContentBundler.coCreateBundleForLibraryItem(libraryItem, bundleMetadata)
            .orReturn { return it }

        val startingCursor = run {
            val savedListeningProgress: SavedListeningProgress? = when (libraryItem.uri.type) {
                SpeechifyEntityType.LIBRARY_ITEM, SpeechifyEntityType.SCANNED_BOOK -> {
                    val localListeningProgress = speechifyClient.importService.getLocalListeningProgress(
                        libraryItem.uri,
                    )
                    val localListeningProgressLastUpdatedTime = localListeningProgress?.lastUpdatedTime ?: 0
                    val firebaseListeningProgress = libraryItem.getSavedListeningProgress()
                    if (localListeningProgress != null && localListeningProgress.listeningProgress != null &&
                        localListeningProgressLastUpdatedTime >
                        (firebaseListeningProgress?.lastUpdateTime?.asSeconds() ?: 0)
                    ) {
                        val lastListenedASDateTime = DateTime.fromSeconds(
                            localListeningProgress.lastUpdatedTime.toDouble(),
                        )
                        SavedListeningProgress.CursorProgress(
                            localListeningProgress.listeningProgress.cursor,
                            lastListenedASDateTime,
                        )
                    } else {
                        firebaseListeningProgress
                    }
                }

                SpeechifyEntityType.AUDIOBOOK_CHAPTER -> {
                    speechifyClient
                        .audiobookLibraryService
                        .coGetAudiobookChapter(libraryItem.uri.id)
                        .toNullable()
                        ?.listeningProgress
                        ?.let { SavedListeningProgress.CursorProgress(it.cursor, DateTime.fromIsoString(it.timestamp)) }
                }

                SpeechifyEntityType.FOLDER ->
                    throw IllegalArgumentException("Cannot bundle folders")
            }

            return@run savedListeningProgress?.toCursor(contentBundle)
        }

        val dependencies = createDependencies(
            contentBundle = contentBundle,
            startingCursor = startingCursor ?: contentBundle.standardView.start,
            bundleMetadata = bundleMetadata,
            eventsTrackerAdapter = eventsTrackerAdapter,
        ).orReturn { return it }

        return createBundleFromDependencies(dependencies, bundleMetadata)
            .successfully()
    }

    /**
     * Creates a [ReadingBundle] for the binary content specified in [content].
     */
    fun createBundleForBinaryContent(
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        content: BinaryContentReadableRandomly,
        /**
         * See [MimeType] for how to create one.
         */
        mimeType: MimeType,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        scope,
        telemetryEventName = "${UniversalSourcesReadingBundler::class.simpleName}.createBundleForBinaryContent",
    ) { telemetryEvent ->
        telemetryEvent.addUUID()
        telemetryEvent.addProperty("contentType", mimeType.fullString)
        return@fromCoWithTelemetryLoggingErrors createReadingBundleForContentBundle(
            contentBundle = contentBundler.coCreateBundleForUnimportedBinaryContent(
                payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                    content = content,
                    mimeType = mimeType.ensureListenableMimeType(contentTypeForFallback = null),
                    sourceUrl = null,
                )
                    .orReturn { return@fromCoWithTelemetryLoggingErrors it },
                deviceLocalContent = null,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            )
                .orReturn { return@fromCoWithTelemetryLoggingErrors it },
            telemetryEventBuilder = telemetryEvent,
            bundleMetadata = bundleMetadata,
        )
    }

    /**
     * Creates a [ReadingBundle] for the specified URL.
     * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForURL(
        url: String,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        scope,
        telemetryEventName = "${UniversalSourcesReadingBundler::class.simpleName}.createBundleForURL",
    ) { telemetryEventBuilder ->
        telemetryEventBuilder.addUUID()
        telemetryEventBuilder.addProperty("url", url)

        return@fromCoWithTelemetryLoggingErrors createReadingBundleForContentBundle(
            contentBundle = contentBundler.coCreateBundleForURL(
                url = url,
                deviceLocalContent = null,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ).orReturn { return@fromCoWithTelemetryLoggingErrors it },
            telemetryEventBuilder = telemetryEventBuilder,
            bundleMetadata = bundleMetadata,
        )
    }

    private suspend fun createReadingBundleForContentBundle(
        contentBundle: ContentBundle,
        telemetryEventBuilder: TelemetryEventBuilder,
        bundleMetadata: BundleMetadata,
    ): Result<ReadingBundle> {
        return createBundleFromDependencies(
            dependencies = createDependencies(
                contentBundle = contentBundle,
                startingCursor = contentBundle.standardView.start,
                bundleMetadata = bundleMetadata,
                eventsTrackerAdapter = eventsTrackerAdapter,
            )
                .orReturn { return it },
            bundleMetadata = bundleMetadata,
        )
            .successfully()
    }

    private suspend fun createBundleFromDependencies(
        dependencies: ReadingBundle.Dependencies,
        bundleMetadata: BundleMetadata?,
    ): ReadingBundle =
        if (dependencies.playbackControls.listeningBundle.contentBundle is ContentBundle.EpubBundleV3) {
            EpubReadingBundle(dependencies, currentTelemetryEvent(), bundleMetadata)
        } else if (dependencies.playbackControls.listeningBundle.contentBundle is ContentBundle.BookBundle) {
            BookReadingBundle(dependencies, currentTelemetryEvent(), bundleMetadata)
        } else {
            ClassicReadingBundle(dependencies, currentTelemetryEvent(), bundleMetadata)
        }

    /**
     * Retrieve the saved listening progress for a [LibraryItem.ListenableContent].
     * If the item is uploaded to the user's library (a [LibraryItem.Content]) - we get the listening progress from the
     * item itself, since we want the latest data that may have been updated by a different listening session on a different
     * device
     * If the item is a [LibraryItem.DeviceLocalContent] - we get the listening progress from the local database
     */
    private suspend fun LibraryItem.ListenableContent.getSavedListeningProgress(): SavedListeningProgress? {
        val currentUser = speechifyClient.adaptersProvider.firebaseService.auth.getCurrentUserOrNullResult().orThrow()
        return when (this) {
            is LibraryItem.Content -> {
                // If opening a shared item and the current user is not the owner,
                // return null to start from the beginning.
                if (this.isShared && currentUser?.uid != this.ownerId) {
                    null
                } else {
                    getSavedListeningProgress(this)
                }
            }
            is LibraryItem.DeviceLocalContent -> {
                val lastListenedAt = if (this.lastListenedAt != null) {
                    DateTime.fromIsoString(this.lastListenedAt)
                } else {
                    DateTime.EPOCH
                }
                speechifyClient.importService.getLocalListeningProgress(this.uri)
                    ?.listeningProgress
                    ?.let { SavedListeningProgress.CursorProgress(it.cursor, lastListenedAt) }
            }
            else -> null
        }
    }
}
