package com.speechify.client.internal.util.coroutines

import com.speechify.client.internal.util.extensions.coroutines.launchWithoutFailingOnError
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

internal enum class ChildCoroutineErrorBehavior {
    /** Same behavior, as [CoroutineScope.launch] */
    PROPAGATE_TO_PARENT,

    /** Same behavior as [CoroutineScope.launchWithoutFailingOnError]. */
    DONT_PROPAGATE_TO_PARENT,
}

internal fun CoroutineScope.launchWithOnErrorBehavior(
    onError: ChildCoroutineErrorBehavior,
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
) =
    when (onError) {
        ChildCoroutineErrorBehavior.PROPAGATE_TO_PARENT -> this.launch(context, start, block)
        ChildCoroutineErrorBehavior.DONT_PROPAGATE_TO_PARENT -> this.launchWithoutFailingOnError(context, start, block)
    }
