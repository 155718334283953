package com.speechify.client.internal.services

import com.speechify.client.api.adapters.http.HttpRequestBodyData
import com.speechify.client.api.telemetry.TelemetrySessionAndEventsPayload
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.services.auth.AuthService
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

internal class DiagnosticsService(
    private val httpClient: HttpClient,
    private val authService: AuthService,
    private val baseUrl: String,
) {
    suspend fun reportDiagnostics(
        telemetrySessionAndEventsPayload: TelemetrySessionAndEventsPayload,
    ): Result<Unit> {
        val body = Json.encodeToString(telemetrySessionAndEventsPayload).encodeToByteArray()

        val firebaseToken = authService
            .getCurrentUserIdentityToken()
            .orReturn { return it }
        val url = "$baseUrl/logs"

        return httpClient.post(url) {
            this.httpRequestBodyData = HttpRequestBodyData.BodyBytes(body)
            this.header(
                "Authorization",
                "Bearer ${firebaseToken.token}",
            )
            this.header("Content-Type", "application/json")
        }.then {
            if (it.ok) {
                Unit.successfully()
            } else {
                Result.Failure(
                    SDKError.HttpError(
                        status = it.status,
                        message = "Error sending diagnostics",
                        response = it,
                    ),
                )
            }
        }
    }
}
