package com.speechify.client.reader.core

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.view.speech.SpeechView
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class HoveredSentenceHelper internal constructor(
    scope: CoroutineScope,
    private val speechView: SpeechView,
) : Helper<HoveredSentenceState>(scope) {

    override val stateFlow = MutableStateFlow(HoveredSentenceState.empty)
    override val initialState = stateFlow.value

    init {
        commands
            .onEachInstance<HoveredSentenceHelperCommand.HoverSentence> {
                val cursor = it.aroundLocation.cursor
                val speech = speechView.getSpeechContainingCursor(cursor).orThrow()
                val sentenceContainingCursor = speech.sentences.find { it.text.containsCursor(cursor) }
                sentenceContainingCursor?.run {
                    stateFlow.update {
                        it.copy(hoveredSentence = this.text)
                    }
                }
            }.onEachInstance<HoveredSentenceHelperCommand.ClearHoveredSentence> {
                stateFlow.update {
                    it.cleared()
                }
            }.launchInHelper()
    }
}

internal sealed class HoveredSentenceHelperCommand {
    data class HoverSentence(val aroundLocation: SerialLocation) : HoveredSentenceHelperCommand()
    object ClearHoveredSentence : HoveredSentenceHelperCommand()
}

@JsExport
data class HoveredSentenceState(
    val hoveredSentence: Content?,
) {

    internal fun cleared() = empty

    internal companion object {
        val empty = HoveredSentenceState(
            hoveredSentence = null,
        )
    }
}
