package com.speechify.client.api.services.scannedbook.models

import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.createTopLevelCoroutineScope
import kotlin.js.JsExport

/**
 * Converts [LazyOCRFiles] to [ScannedBook].
 */
@JsExport
class LocalScannedBookLazy(
    private val lazyOCRFiles: LazyOCRFiles,
    private val ocrAdapter: OCRAdapter,
) : WithScope(createTopLevelCoroutineScope()), ScannedBook {

    override val numberOfPages: Int
        get() = lazyOCRFiles.numberOfFiles

    override fun getPage(pageIndex: Int, callback: Callback<ScannedBookPage>) = callback.fromCo(scope) {
        LocalPageLazy(
            pageIndex,
            lazyOCRFiles.getOcrableImageCached(pageIndex),
            lazyOCRFiles,
            ocrAdapter.ocrMaxConcurrencyGuard,
        ).successfully()
    }
}
