package com.speechify.client.api.content

import kotlin.js.JsExport

@JsExport
enum class ContentRelativeOrder {
    BEFORE,
    EQUAL,
    AFTER,
}
