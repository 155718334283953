package com.speechify.client.reader.classic

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.coGetCursorFromProgress
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardElement
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksAroundCursor
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.reader.core.CoreCommand
import com.speechify.client.reader.core.FocusCommand
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.HoveredSentenceState
import com.speechify.client.reader.core.PlaybackState
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ReadingLocationState
import com.speechify.client.reader.core.ResolvedNavigationIntent
import com.speechify.client.reader.core.RobustLocation
import com.speechify.client.reader.core.SearchState
import com.speechify.client.reader.core.SelectionState
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.core.SerialSpan
import com.speechify.client.reader.core.UserHighlightsList
import com.speechify.client.reader.core.createReaderFeaturesFlow
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.onEach
import kotlin.js.JsExport

@JsExport
class ClassicViewHelper internal constructor(
    scope: CoroutineScope,
    internal val standardView: StandardView,
    internal val contentIndex: ContentIndex,
    private val playbackStateFlow: Flow<PlaybackState>,
    private val selectionStateFlow: Flow<SelectionState>,
    private val highlightsInView: Flow<UserHighlightsList>,
    private val navigationIntentsFlow: Flow<ResolvedNavigationIntent>,
    private val hoveredSentenceStateFlow: Flow<HoveredSentenceState>,
    private val readingLocationStateFlow: Flow<ReadingLocationState>,
    private val searchStateFlow: Flow<SearchState>,
) : Helper<ClassicView>(scope) {

    private fun stateFrom(blocks: StandardBlocks): ClassicBlocksState {
        return ClassicBlocksState(
            blocks = blocks.blocks.flatMap { it.toClassicBlocks() }.toTypedArray(),
            start = SerialLocation(blocks.start),
            end = SerialLocation(blocks.end),
            hasMoreBefore = true,
            hasMoreAfter = true,
            isInitialLoadComplete = true,
        )
    }

    private fun ClassicBlocksState.prepend(other: StandardBlocks): ClassicBlocksState {
        return this.copy(
            blocks = other.blocks.filter {
                it.end.isBeforeOrAt(this.start.cursor)
            }.flatMap { it.toClassicBlocks() }.toTypedArray() + this.blocks,
            start = SerialLocation(other.start),
            end = if (end.cursor is ContentElementBoundary &&
                end.cursor.element.path.isEmpty() &&
                end.cursor.boundary == ContentBoundary.START
            ) {
                SerialLocation(other.end)
            } else {
                end
            },
            hasMoreBefore = hasMoreBefore && other.start.isBefore(this.start.cursor),
            isInitialLoadComplete = true,
        )
    }

    private fun ClassicBlocksState.append(other: StandardBlocks): ClassicBlocksState {
        return this.copy(
            blocks = this.blocks + other.blocks.filter {
                it.end.isAfter(this.end.cursor)
            }.flatMap { it.toClassicBlocks() }.toTypedArray(),
            start = if (start.cursor is ContentElementBoundary && start.cursor.element.path.isEmpty()) {
                SerialLocation(other.start)
            } else {
                start
            },
            end = SerialLocation(other.end),
            hasMoreAfter = hasMoreAfter && other.end.isAfter(this.end.cursor),
            isInitialLoadComplete = true,
        )
    }

    private fun StandardBlock.toClassicBlocks(): List<ClassicBlock> = when (this) {
        is StandardBlock.Footer -> listOf(
            ClassicBlock.Paragraph(
                scope = scope,
                readerFeatures = readerFeatures,
                builder = _elements.joinToTextBlock(),
            ),
        )

        is StandardBlock.Footnote -> listOf(
            ClassicBlock.Paragraph(
                scope = scope,
                readerFeatures = readerFeatures,
                builder = _elements.joinToTextBlock(),
            ),
        )

        is StandardBlock.Header -> listOf(
            ClassicBlock.Paragraph(
                scope = scope,
                readerFeatures = readerFeatures,
                builder = _elements.joinToTextBlock(),
            ),
        )

        is StandardBlock.Paragraph -> ClassicBlockBuilder.splitIntoClassicBlocks(
            _elements,
            scope = scope,
            readerFeatures = readerFeatures,
            headingLevel = null,
        )

        // NOTE: completely ignores any nested Heading elements, relies entirely on StandardBlock.Heading
        is StandardBlock.Heading -> ClassicBlockBuilder.splitIntoClassicBlocks(
            _elements,
            scope,
            readerFeatures,
            headingLevel = this.level,
        )

        is StandardBlock.List -> {
            val bulletListItems = items.filterNot { it._elements.isEmpty() }.map {
                ClassicBlockBuilder.List.ListItem(it._elements)
            }
            val bulletListStyle = when {
                this.isNumbered -> StandardElement.List.ListStyle.DECIMAL
                else -> StandardElement.List.ListStyle.DISC
            }
            listOf(
                ClassicBlock.List(
                    builder = ClassicBlockBuilder.List(
                        bulletListItems,
                        bulletListStyle,
                        ClassicBlockBuilder.Text(
                            elements = listOf(StandardElement.Text(text = this.text)),
                        ),
                    ),
                    style = when {
                        this.isNumbered -> ClassicBlock.List.Style.Number
                        else -> ClassicBlock.List.Style.Bullet
                    },
                    scope = scope,
                    readerFeatures = readerFeatures,
                ),
            )
        }

        is StandardBlock.Caption -> listOf(
            ClassicBlock.Paragraph(
                scope = scope,
                readerFeatures = readerFeatures,
                builder = _elements.joinToTextBlock(),
            ),
        )
    }

    private fun ResolvedNavigationIntent.toCoarseClassicNavigationIntent(
        currentBlockState: ClassicBlocksState,
        currentReadingLocationState: ReadingLocationState,
    ):
        CoarseClassicNavigationIntent? {
        val location = location.hack
        /*
            Map the navigation intent only if the current location is within a valid range.
            This prevents handling intents for "invalid" blocks, which can occur when the
            `location` is already up-to-date but `blocksInView` is not.
        */
        val start = currentBlockState.start.cursor
        val end = currentBlockState.end.cursor

        val isLocationBefore = location.cursor.isBefore(start)
        val isLocationAfterOrAt = location.cursor.isAfterOrAt(end)
        val isInInitialState =
            end is ContentElementBoundary && end.element.path.isEmpty() && end.boundary == ContentBoundary.START

        val containsLocation = !isLocationBefore && !isLocationAfterOrAt && !isInInitialState

        if (!containsLocation) return null

        val blocksInView = currentBlockState.blocks
        val block = blocksInView.find { it.isLocationBeforeOrAtEnd(location) } ?: return null

        val currentReadingLocation = currentReadingLocationState.location
        val targetBlockPosition = when {
            currentReadingLocation == null -> null
            block.isLocationAfter(currentReadingLocation) ->
                CoarseClassicNavigationIntent.TargetBlockPosition.BeforeReadingLocation

            block.isLocationBefore(currentReadingLocation) ->
                CoarseClassicNavigationIntent.TargetBlockPosition.AfterReadingLocation

            else -> null
        }

        return CoarseClassicNavigationIntent(
            dispatch = dispatch,
            resolvedIntent = this,
            blockKey = block.key,
            targetBlockPosition = targetBlockPosition,
        )
    }

    private val currentBlocksInView = MutableStateFlow(
        ClassicBlocksState(
            blocks = emptyArray(),
            start = SerialLocation(standardView.start),
            end = SerialLocation(standardView.start),
            hasMoreBefore = true,
            hasMoreAfter = true,
            isInitialLoadComplete = false,
        ),
    )

    private val navigationHelper = ClassicNavigationHelper(scope, navigationIntentsFlow)

    private val readerFeatures =
        createReaderFeaturesFlow(
            playbackStateFlow,
            selectionStateFlow,
            highlightsInView,
            navigationHelper.fineIntentsFlow,
            hoveredSentenceStateFlow,
            searchStateFlow,
        ).stateInHelper(
            initialValue = ReaderFeatures.Empty,
        )

    private val isLoadingMoreAfterFlow = MutableStateFlow(false)
    private val isLoadingMoreBeforeFlow = MutableStateFlow(false)
    override val stateFlow: StateFlow<ClassicView> =
        combine(
            currentBlocksInView,
            isLoadingMoreBeforeFlow,
            isLoadingMoreAfterFlow,
            navigationHelper.coarseIntentsFlow,
            readingLocationStateFlow,
        ) { blocksState, isLoadingMoreBefore, isLoadingMoreAfter, coarseNavigationIntent, readingLocationState ->
            ClassicView(
                blocksInView = blocksState.blocks,
                navigationIntent = coarseNavigationIntent?.toCoarseClassicNavigationIntent(
                    currentBlockState = blocksState,
                    currentReadingLocationState = readingLocationState,
                ),
                hasMoreBefore = blocksState.hasMoreBefore,
                hasMoreAfter = blocksState.hasMoreAfter,
                isLoadingMoreBefore = isLoadingMoreBefore,
                isLoadingMoreAfter = isLoadingMoreAfter,
            )
        }.stateInHelper(
            initialValue = ClassicView(
                blocksInView = emptyArray(),
                navigationIntent = null,
                hasMoreBefore = true,
                hasMoreAfter = true,
                isLoadingMoreBefore = false,
                isLoadingMoreAfter = false,
            ),
        )

    override val initialState = stateFlow.value

    init {
        commands
            .onEachInstance<ClassicReaderViewCommand.LoadMoreAfter> {
                val current = stateFlow.value
                if (current.isLoadingMoreAfter || !current.hasMoreAfter) return@onEachInstance
                isLoadingMoreAfterFlow.value = true
                val currentBlocks = currentBlocksInView.value
                val allBlocks = getBlocksAfter(currentBlocks)
                currentBlocksInView.value = allBlocks

                dispatch(
                    FocusCommand.SetFocus(
                        start = allBlocks.start,
                        end = allBlocks.end,
                    ),
                )
                isLoadingMoreAfterFlow.value = false
            }
            .onEachInstance<ClassicReaderViewCommand.LoadMoreBefore> {
                val current = stateFlow.value
                if (current.isLoadingMoreBefore || !current.hasMoreBefore) return@onEachInstance
                isLoadingMoreBeforeFlow.value = true
                val currentBlocks = currentBlocksInView.value
                val allBlocks = getBlocksBefore(currentBlocks)
                currentBlocksInView.value = allBlocks

                dispatch(
                    FocusCommand.SetFocus(
                        start = allBlocks.start,
                        end = allBlocks.end,
                    ),
                )
                isLoadingMoreBeforeFlow.value = false
            }
            .onEachInstance<ClassicReaderViewCommand.SkipToLocation> {
                // NOTE: this is probably safe from design standpoint, since StandardView must be able to resolve
                // robust cursors anyway
                val state = currentBlocksInView.value
                val start = state.start.cursor
                val end = state.end.cursor

                val isInInitialState =
                    end is ContentElementBoundary && end.element.path.isEmpty() && end.boundary == ContentBoundary.START
                val isLocationBefore = it.location.hack.cursor.isBefore(start)
                val isLocationAfterOrAt = it.location.hack.cursor.isAfterOrAt(end)

                if (!isLocationBefore && !isLocationAfterOrAt && !isInInitialState) {
                    return@onEachInstance
                }

                val blocks = getBlocksAroundLocation(location = it.location.hack)
                currentBlocksInView.value = blocks

                dispatch(
                    FocusCommand.SetFocus(
                        start = blocks.start,
                        end = blocks.end,
                    ),
                )
            }
            .onEachInstance<ClassicReaderViewCommand.SkipToProgressPercent> {
                val cursor = contentIndex.coGetCursorFromProgress(it.progressPercent).orThrow()
                val blocks = getBlocksAroundLocation(
                    location = SerialLocation(cursor = cursor),
                )

                currentBlocksInView.value = blocks

                dispatch(
                    FocusCommand.SetFocus(
                        start = blocks.start,
                        end = blocks.end,
                    ),
                )
            }
            .onEachInstance<CoreCommand.ReloadContent> {
                val blocks = getBlocksAroundLocation(it.location.hack)
                currentBlocksInView.value = blocks

                dispatch(
                    FocusCommand.SetFocus(
                        start = blocks.start,
                        end = blocks.end,
                    ),
                )
            }
            .launchInHelper()

        navigationIntentsFlow.onEach {
            dispatch(ClassicReaderViewCommand.SkipToLocation(it.location))
        }.launchInHelper()

        launchInHelper { populateInitialBlocks() }
    }

    fun loadMoreAfter() {
        dispatch(ClassicReaderViewCommand.LoadMoreAfter)
    }

    fun loadMoreBefore() {
        dispatch(ClassicReaderViewCommand.LoadMoreBefore)
    }

    fun skipToProgressPercent(progressPercent: Double) {
        dispatch(ClassicReaderViewCommand.SkipToProgressPercent(progressPercent))
    }

    private suspend fun populateInitialBlocks() {
        val initialBlocks = getBlocksAroundLocation(location = currentBlocksInView.value.start)
        currentBlocksInView.value = initialBlocks

        dispatch(
            FocusCommand.SetFocus(
                start = initialBlocks.start,
                end = initialBlocks.end,
            ),
        )
    }

    private suspend fun getBlocksAroundLocation(location: SerialLocation): ClassicBlocksState {
        var result = stateFrom(standardView.coGetBlocksAroundCursor(location.cursor).orThrow())

        if (result.hasMoreAfter) {
            result = getBlocksAfter(result)
        }

        if (result.hasMoreBefore) {
            result = getBlocksBefore(result)
        }

        return result
    }

    private suspend fun getBlocksBefore(
        currentState: ClassicBlocksState,
    ): ClassicBlocksState {
        var result = currentState

        while (result.hasMoreBefore) {
            val newBlocks = standardView.coGetBlocksAroundCursor(result.start.cursor).orThrow()
            val updatedState = result.prepend(other = newBlocks)

            result = updatedState

            if (updatedState.blocks.size > currentState.blocks.size) {
                return updatedState
            }
        }

        return result
    }

    private suspend fun getBlocksAfter(
        currentState: ClassicBlocksState,
    ): ClassicBlocksState {
        var result = currentState

        while (result.hasMoreAfter) {
            val newBlocks = standardView.coGetBlocksAroundCursor(result.end.cursor).orThrow()
            val updatedState = result.append(other = newBlocks)

            result = updatedState

            if (updatedState.blocks.size > currentState.blocks.size) {
                return updatedState
            }
        }

        return result
    }
}

internal sealed class ClassicReaderViewCommand {
    object LoadMoreAfter : ClassicReaderViewCommand()
    object LoadMoreBefore : ClassicReaderViewCommand()
    data class SkipToProgressPercent(val progressPercent: Double) : ClassicReaderViewCommand()
    data class SkipToLocation(val location: RobustLocation) : ClassicReaderViewCommand()
    data class ReloadContent(val location: RobustLocation) : ClassicReaderViewCommand()
}

private data class ClassicBlocksState internal constructor(
    val blocks: Array<ClassicBlock>,
    val start: SerialLocation,
    val end: SerialLocation,
    val hasMoreBefore: Boolean,
    val hasMoreAfter: Boolean,
    val isInitialLoadComplete: Boolean,
) {
    internal val span: SerialSpan get() = SerialSpan(start, end)

    override fun hashCode(): Int {
        var result = blocks.contentHashCode()
        result = 31 * result + start.hashCode()
        result = 31 * result + end.hashCode()
        result = 31 * result + hasMoreBefore.hashCode()
        result = 31 * result + hasMoreAfter.hashCode()
        return result
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ClassicBlocksState

        if (!blocks.contentEquals(other.blocks)) return false
        if (start != other.start) return false
        if (end != other.end) return false
        if (hasMoreBefore != other.hasMoreBefore) return false
        if (hasMoreAfter != other.hasMoreAfter) return false
        if (isInitialLoadComplete != other.isInitialLoadComplete) return false

        return true
    }
}
