package com.speechify.client.internal.services.subscription.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseValidateReceiptResult(
    /** Will be true if the receipt is for a valid subscription (not expired, not refunded), false otherwise (invalid receipt, expired, refunded). */
    val isValid: Boolean,
    /** Will be true if the subscription already exists in our system, but is assigned to a different user id than the caller. */
    val needsTransfer: Boolean,
    /** Only set if the receipt is for a subscription, date when the subscription expires. */
    val expiresAt: DateTime? = null,
    /** Only set if the receipt is for a subscription, status of the subscription. */
    val status: FirebaseSubscriptionStatus? = null,
    /**
     * Only set if the receipt is for a subscription, indicates if the user is still eligible for a trial period,
     * true when the next subscription most likely will have a trial, false otherwise.
     */
    val isEligibleForTrialPeriod: Boolean? = null,
)
