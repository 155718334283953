package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformOneClickRenewalStatus(
    val isAvailable: Boolean,
    val price: Float? = null,
    val currency: String? = null,
    val productId: String? = null,
    val source: String? = null,
    val periodUnit: String? = null,
    val periodCount: Int? = null,
    val subscriptionId: String? = null,
    val originalPrice: Float? = null,
    val willChargeUser: Boolean? = null,
)
