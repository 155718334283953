package com.speechify.client.api

import com.speechify.client.api.adapters.AdapterFactory
import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.db.AbstractSqlDriverFactory
import com.speechify.client.api.adapters.firebase.FirebaseAuthService
import com.speechify.client.api.adapters.firebase.FirebaseService
import com.speechify.client.api.adapters.http.HttpClientAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisVoiceBySpecProvider
import com.speechify.client.api.adapters.localsynthesis.toLocalSpeechSynthesisVoiceBySpecProvider
import com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider
import com.speechify.client.api.adapters.traced
import com.speechify.client.api.audio.VoiceSpecAvailabilityProvider
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.account.AccountSettingsService
import com.speechify.client.api.services.adoption.EcosystemAdoptionDelegate
import com.speechify.client.api.services.adoption.EcosystemAdoptionService
import com.speechify.client.api.services.audio.AudioServer
import com.speechify.client.api.services.audio.AudioServerConfiguration
import com.speechify.client.api.services.audio.AuthorizationTokenProviderV2
import com.speechify.client.api.services.audio.LegacyAudioService
import com.speechify.client.api.services.audiobook.AudiobookLibraryService
import com.speechify.client.api.services.ebook.EbookServiceImpl
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.LibraryService
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.api.services.logging.LoggingService
import com.speechify.client.api.services.personalvoice.PersonalVoiceService
import com.speechify.client.api.services.scannedbook.ScannedBookService
import com.speechify.client.api.services.subscription.SubscriptionService
import com.speechify.client.api.telemetry.SpeechifySDKTelemetry
import com.speechify.client.api.telemetry.TelemetryClientDependencies
import com.speechify.client.api.util.DeviceResourceManager
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorageImpl
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.HttpClientWithMiddleware
import com.speechify.client.internal.services.DiagnosticsService
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.adoption.EcosystemAdoptionDataFetcher
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.auth.ReportUserChangesToPaymentServerService
import com.speechify.client.internal.services.book.PlatformBookPageContentStatsService
import com.speechify.client.internal.services.book.PlatformMLParsedBookPageService
import com.speechify.client.internal.services.db.DbService
import com.speechify.client.internal.services.editing.BookEditingService
import com.speechify.client.internal.services.epub.PlatformEpubChapterContentStatsService
import com.speechify.client.internal.services.highlight.UserHighlightsService
import com.speechify.client.internal.services.importing.PlatformImportService
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.services.library.PlatformShareService
import com.speechify.client.internal.services.ml.MLPageParsingWithRemoteOCRService
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.subscription.PlatformFetcher
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataFetcher
import com.speechify.client.internal.services.userDocumentSettings.UserProfileService
import com.speechify.client.internal.util.IdGenerator
import kotlinx.coroutines.CoroutineScope
import kotlin.js.JsExport

@JsExport
class SpeechifyClientFactory(
    private val clientConfig: ClientConfig,
    adapterFactory: AdapterFactory,
) {
    init {
        Log.ensureQueuelessReportersReady()
        Log.isSendingInfoDiagnosticLogsToTelemetryEnabled = clientConfig.options.isSendingInfoDiagnosticLogsToTelemetry
    }

    private val scope: CoroutineScope = createTopLevelCoroutineScope(
        shouldFailIfAnyChildFails = false,
    )

    // Note: this initialization must be **after** the init block as it depends on the state of Log.isDebugLoggingEnabled
    private val clientInstance = AdaptersProvider(
        adapterFactory.traced(),
        clientConfig,
        scope = scope,
    ).let { adaptersProvider ->
        val dbService = DbService(adaptersProvider)

        val subscriptionsFirebaseDataFetcher = SubscriptionsFirebaseDataFetcher(
            adaptersProvider.firebaseService.firestore,
            adaptersProvider.firebaseService.fieldValueFactory,
            shouldUpdateFirestoreWhenSubtractingHdWords = !clientConfig.options.useAudioServerV2,
        )
        val firebaseFunctionsService = FirebaseFunctionsServiceImpl(
            adaptersProvider.httpClient,
            adaptersProvider.firebaseService.auth,
            clientConfig.platformFirebaseFunctionsUrl,
        )

        SpeechifySDKTelemetry.setClientDependencies(
            TelemetryClientDependencies(
                clientConfig,
                adaptersProvider.firebaseService.auth,
                firebaseFunctionsService,
                DiagnosticsService(
                    httpClient = adaptersProvider.httpClient,
                    authService = adaptersProvider.firebaseService.auth,
                    baseUrl = clientConfig.diagnosticsServiceUrl,
                ),
            ),
        )

        val platformShareService = PlatformShareService(
            adaptersProvider.httpClient,
            adaptersProvider.firebaseService.firestore,
            clientConfig,
        )

        val ebookService = EbookServiceImpl(
            authService = adaptersProvider.firebaseService.auth,
            httpClient = adaptersProvider.httpClient,
            baseUrl = clientConfig.ebookBackendUrl,
            encryptionAdapter = adaptersProvider.encryptionAdapter,
            blobStorageAdapter = adaptersProvider.blobStorage,
        )

        val offlineAvailabilityManager = OfflineAvailabilityManager(
            clientConfig,
            adaptersProvider.firebaseService.storage,
            adaptersProvider.firebaseService.firestore,
            dbService,
            ebookService,
        )

        val libraryFirebaseDataFetcher = LibraryFirebaseDataFetcher(
            adaptersProvider.firebaseService.firestore,
            adaptersProvider.firebaseService.timestampFactory,
            adaptersProvider.firebaseService.storage,
            platformShareService,
            clientConfig,
            offlineAvailabilityManager,
        )

        val platformScannedBookService = PlatformScannedBookService(
            adaptersProvider.firebaseService.auth,
            clientConfig,
            adaptersProvider.firebaseService.storage,
            adaptersProvider.firebaseService.firestore,
            adaptersProvider.firebaseService.timestampFactory,
            firebaseFunctionsService,
            IdGenerator,
            libraryFirebaseDataFetcher,
        )

        val mlPageParsingWithRemoteOCRService = MLPageParsingWithRemoteOCRService(
            httpClient = adaptersProvider.httpClient,
            appVersion = clientConfig.appVersion,
            appEnvironment = clientConfig.appEnvironment,
            authService = adaptersProvider.firebaseService.auth,
            platformFirebaseFunctionsUrl = clientConfig.platformFirebaseFunctionsUrl,
        )

        val platformMLParsedBookPageService = PlatformMLParsedBookPageService(
            authService = adaptersProvider.firebaseService.auth,
            firebaseFirestoreService = adaptersProvider.firebaseService.firestore,
            idGenerator = IdGenerator,
            libraryFirebaseDataFetcher = libraryFirebaseDataFetcher,
            firebaseTimestampAdapter = adaptersProvider.firebaseService.timestampFactory,
        )

        val importService = ImportService(
            PlatformImportService(
                authService = adaptersProvider.firebaseService.auth,
                clientConfig = clientConfig,
                firebaseStorage = adaptersProvider.firebaseService.storage,
                firebaseFunctionsService = firebaseFunctionsService,
                firebaseFirestoreService = adaptersProvider.firebaseService.firestore,
                firebaseTimestampAdapter = adaptersProvider.firebaseService.timestampFactory,
                platformShareService = platformShareService,
                idGenerator = IdGenerator,
                libraryFirebaseDataFetcher = libraryFirebaseDataFetcher,
                platformScannedBookService = platformScannedBookService,
                imageConverter = adaptersProvider.imageConverter,
                xmlParser = adaptersProvider.xmlParser,
                htmlParser = adaptersProvider.htmlParser,
                archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
            ),
            httpClient = adaptersProvider.httpClient,
            pdfAdapterFactory = adaptersProvider.pdfAdapterFactory,
            dbService = dbService,
            blobStorageAdapter = adaptersProvider.blobStorage,
            ocrAdapter = adaptersProvider.ocrAdapter,
            authService = adaptersProvider.firebaseService.auth,
            clientConfig = clientConfig,
            eventsTrackerAdapter = adaptersProvider.eventsTrackerAdapter,
        )

        val libraryService = LibraryService(
            adaptersProvider.firebaseService.auth,
            libraryFirebaseDataFetcher,
            firebaseFunctionsService,
            adaptersProvider.firebaseService.timestampFactory,
            importService,
            useLiveQueryViewV2 = clientConfig.options.useLiveQueryViewV2,
            isSpeechifyEbookVisibleInLibrary = clientConfig.options.isSpeechifyEbookVisibleInLibrary,
        )

        val platformFetcher = PlatformFetcher(
            adaptersProvider.httpClient,
            clientConfig.platformPaymentServiceUrl,
            clientConfig.platformCatalogServiceUrl,
            adaptersProvider.firebaseService.auth,
        )

        val reportUserChangesToPaymentServerService =
            ReportUserChangesToPaymentServerService(
                adaptersProvider.firebaseService.auth,
                platformFetcher,
                adaptersProvider.localKeyValueStorage,
            )

        val personalVoiceService = PersonalVoiceService(
            adaptersProvider.firebaseService.auth,
            adaptersProvider.httpClient,
            clientConfig.platformVoicesServiceUrl,
        )

        val audioService = when (val customAudioServerConfig = clientConfig.options.audioServerConfiguration) {
            null -> if (clientConfig.options.useAudioServerV2) {
                AudioServer(
                    httpClient = adaptersProvider.httpClient,
                    clientConfig = clientConfig,
                    configuration = AudioServerConfiguration(
                        authorizationTokenProvider = AuthorizationTokenProviderV2(
                            authService = adaptersProvider.firebaseService.auth,
                        ),
                        serviceVersionUrlPrefix = "v2",
                    ),
                )
            } else {
                LegacyAudioService(adaptersProvider.httpClient, clientConfig)
            }

            else -> AudioServer(
                httpClient = adaptersProvider.httpClient,
                clientConfig = clientConfig,
                configuration = customAudioServerConfig,
            )
        }

        val ecosystemAdoptionDataFetcher = EcosystemAdoptionDataFetcher(adaptersProvider.firebaseService.firestore)
        val ecosystemAdoptionDelegate = EcosystemAdoptionDelegate(
            adaptersProvider.firebaseService.auth,
            clientConfig,
            ecosystemAdoptionDataFetcher,
        )

        val platformBookPageContentStatsService = PlatformBookPageContentStatsService(
            firebaseFirestoreService = adaptersProvider.firebaseService.firestore,
            libraryFirebaseDataFetcher = libraryFirebaseDataFetcher,
        )

        val platformEpubChapterContentStatsService = PlatformEpubChapterContentStatsService(
            firebaseFirestoreService = adaptersProvider.firebaseService.firestore,
            libraryFirebaseDataFetcher = libraryFirebaseDataFetcher,
        )

        val userProfileService = UserProfileService(
            httpClient = adaptersProvider.httpClient,
            authService = adaptersProvider.firebaseService.auth,
            serviceUrl = clientConfig.userProfileServiceUrl,
            appEnvironment = clientConfig.appEnvironment,
        )

        SpeechifyClient(
            clientConfig,
            adaptersProvider,
            AudiobookLibraryService(
                authService = adaptersProvider.firebaseService.auth,
                firebaseFunctionsService = firebaseFunctionsService,
                firestoreService = adaptersProvider.firebaseService.firestore,
                firebaseTimestampAdapter = adaptersProvider.firebaseService.timestampFactory,
                blobStorageAdapter = adaptersProvider.blobStorage,
                httpClient = adaptersProvider.httpClient,
            ),
            SubscriptionService(
                clientConfig,
                adaptersProvider.firebaseService.auth,
                subscriptionsFirebaseDataFetcher,
                platformFetcher,
                adaptersProvider.localKeyValueStorage,
            ),
            importService,
            libraryService,
            offlineAvailabilityManager,
            ScannedBookService(platformScannedBookService),
            personalVoiceService,
            AccountSettingsService(
                adaptersProvider.firebaseService.auth,
                adaptersProvider.firebaseService.firestore,
                platformFetcher,
            ),
            LoggingService(),
            audioService,
            adaptersProvider.firebaseService.storage,
            reportUserChangesToPaymentServerService,
            firebaseFunctionsService,
            EcosystemAdoptionService(ecosystemAdoptionDelegate),
            BookEditingService(adaptersProvider.firebaseService.firestore),
            UserHighlightsService(
                firestoreService = adaptersProvider.firebaseService.firestore,
                firebaseFunctionsService = firebaseFunctionsService,
            ),
            platformScannedBookService,
            dbService,
            platformMLParsedBookPageService,
            deviceResourceManager = DeviceResourceManager(),
            ebookService = ebookService,
            mlPageParsingWithRemoteOCRService = mlPageParsingWithRemoteOCRService,
            platformBookPageContentStatsService = platformBookPageContentStatsService,
            epubChapterContentStatsService = platformEpubChapterContentStatsService,
            userProfileService = userProfileService,
        ).also { client ->
            offlineAvailabilityManager.lateInject(
                speechifyClient = client,
                libraryServiceDelegate = libraryService.delegate,
            )
            importService.lateInject(
                contentBundler = client.createBundlerFactory(
                    clientConfig.options.defaultBundlerFactoryConfig,
                ).getContentBundler(),
                libraryServiceDelegate = libraryService.delegate,
            )
        }
    }

    fun getClient(): SpeechifyClient = clientInstance
}

/**
 * Wraps [AdapterFactory] for use in SDK, so that:
 *  - only wrapped instances are used **everywhere**
 *  - the same wrapped instance is used **everywhere**
 *  - wrapping happens only once
 */
@JsExport /* Exporting only to preserve the historic behavior of `SpeechifyClient.adapterFactory` being public.
  it is possible that it's not actually needed.
 */
class AdaptersProvider internal constructor(
    adapterFactory: AdapterFactory,
    clientConfig: ClientConfig,
    scope: CoroutineScope,
) {

    val blobStorage by lazy {
        adapterFactory.getBlobStorage()
    }

    val localKeyValueStorage by lazy {
        adapterFactory.getLocalKeyValueStorage()
    }

    internal val sqlDriverFactory: AbstractSqlDriverFactory? by lazy {
        adapterFactory.getSqlDriverFactory()
    }

    private val httpClientAdapter: HttpClientAdapter = adapterFactory.getHttpClient()

    @Deprecated(
        /** SDK could expose the [HttpClient], but it shouldn't do it if not needed, to allow SDK to improve without impedance from the API surface */
        "SDK consumers should not use the raw `HttpClientAdapter`. If you need the HttpClient from SDK, please" +
            " contact the SDK team.",
    )
    fun getHttpClient(): HttpClientAdapter = httpClientAdapter

    private val browserIdentityUserAgentProvider = adapterFactory.getBrowserIdentityUserAgentProvider()

    internal val httpClient = HttpClient(
        HttpClientWithMiddleware(httpClientAdapter),
        browserIdentityUserAgentProvider,
        clientConfig,
    )

    internal val firebaseService: FirebaseServicesProvider = FirebaseServicesProvider(
        blobStorage,
        httpClient,
        adapterFactory.getFirebaseService(),
    )

    val localSpeechSynthesis = adapterFactory.getLocalSpeechSynthesis()
    internal val localSpeechSynthesisVoiceBySpecProvider: LocalSpeechSynthesisVoiceBySpecProvider =
        localSpeechSynthesis.toLocalSpeechSynthesisVoiceBySpecProvider()

    internal val voiceSpecAvailabilityProvider = VoiceSpecAvailabilityProvider(
        localSpeechSynthesisVoiceBySpecProvider = localSpeechSynthesisVoiceBySpecProvider,
    )

    val localMediaPlayer = adapterFactory.getLocalMediaPlayer()

    val pdfAdapterFactory = adapterFactory.getPDFAdapterFactory()

    val ocrAdapter = adapterFactory.getOcrAdapter()

    val htmlParser = adapterFactory.getHTMLParser()

    val imageConverter = adapterFactory.getImageConverter()

    val archiveFilesAdapter = adapterFactory.getArchiveFilesAdapter()

    val xmlParser = adapterFactory.getXMLParser()

    val encryptionAdapter = adapterFactory.getEncryptionAdapter()

    val eventsTrackerAdapter = adapterFactory.getEventsTrackerAdapter()

    val webViewAdapter = adapterFactory.getWebViewAdapter()

    internal val offlineModeStatusFlowProvider: OfflineModeStatusProvider.FlowProvider =
        adapterFactory.getOfflineModeStatusProvider()
            .toFlowProviderIn(
                scope = scope,
            )

    class FirebaseServicesProvider internal constructor(
        blobStorageAdapter: BlobStorageAdapter,
        httpClient: HttpClient,
        @Suppress("MemberVisibilityCanBePrivate")
        val firebaseService: FirebaseService,
    ) {
        @Deprecated(
            "SDK consumers should not use the raw `FirebaseAuthService`. If you need it from SDK, please" +
                " contact the SDK team.",
        )
        val authAdapter: FirebaseAuthService = firebaseService.getAuth()

        internal val auth: AuthService = AuthService(
            @Suppress("DEPRECATION")
            authAdapter,
        )

        val firestore = firebaseService.getFirestore()

        internal val storage: ReadWriteThroughCachedFirebaseStorage =
            ReadWriteThroughCachedFirebaseStorageImpl(
                firebaseService.getStorage(),
                blobStorageAdapter,
                httpClient,
            )

        val timestampFactory = firebaseService.getTimestampFactory()

        val fieldValueFactory = firebaseService.getFieldValueFactory()
    }
}
