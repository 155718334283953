package com.speechify.client.helpers.audio.controller.root

import com.speechify.client.api.audio.AudioControllerEvent
import com.speechify.client.api.audio.AudioControllerOptions
import com.speechify.client.api.audio.PlayerOptions
import com.speechify.client.api.audio.Utterance
import com.speechify.client.api.audio.UtteranceFlowProvider
import com.speechify.client.api.audio.UtteranceFlowProviderFromSpeechFlow
import com.speechify.client.api.audio.Voice
import com.speechify.client.api.audio.VoiceFactoryFromSpec
import com.speechify.client.api.audio.VoiceOfPreferenceProvider
import com.speechify.client.api.audio.VoiceOfPreferenceProviderWithCache
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.audio.VoicesOfPreferenceStateProvider
import com.speechify.client.api.audio.toUtteranceFlowProviderWithPredefinedStartingPoint
import com.speechify.client.api.audio.toVoiceOfPreferenceProviderWithCacheForCurrentVoice
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentStartAndEndCursors
import com.speechify.client.api.content.view.speech.SpeechFlow
import com.speechify.client.api.content.view.speech.SpeechFlowProvider
import com.speechify.client.api.content.view.speech.SpeechSentence
import com.speechify.client.api.content.view.speech.getSentenceAndWordLocationInContentAtCursorOrFirstAfter
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.useWithLateDestructibles
import com.speechify.client.helpers.audio.controller.AudioControllerCommand
import com.speechify.client.helpers.audio.controller.root.BundlingToPlaybackLatencyTelemetryTracker.Companion.currentBundlingToPlaybackLatencyTelemetryTracker
import com.speechify.client.helpers.content.standard.ContentMutationsInfo
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.actor.Actor
import com.speechify.client.internal.newSingleThreadContextDispatcher
import com.speechify.client.internal.sync.AtomicRef
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.time.Duration
import com.speechify.client.internal.util.collections.flows.onEachAfterDownstream
import com.speechify.client.internal.util.extensions.collections.firstNotNull
import com.speechify.client.internal.util.extensions.collections.flows.BufferObserver
import com.speechify.client.internal.util.extensions.collections.flows.firstOrNullRetainingFlowWhilePreventingTwoConsumptionsIn
import com.speechify.client.internal.util.extensions.collections.flows.swap
import com.speechify.client.internal.util.extensions.coroutines.coroutineScope.launchAndCancelChildrenAtEnd
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorJob
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorScope
import com.speechify.client.internal.util.intentSyntax.ifInstanceOf
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.async
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.channels.ReceiveChannel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlinx.coroutines.plus
import kotlinx.coroutines.withContext

/**
 * The root actor is responsible for high level control and delegation of audio tasks, these are:
 *
 * - Managing the lifecycle of the [PlayerActor] (start it at initialization time)
 * - Changing the [Voice] (kill and start the player actor with a different [Voice])
 * - Seeking (kill and start the [PlayerActor] in a different place)
 * - Forwarding playback commands to the [PlayerActor] (these are listed under [AudioControllerCommand])
 */
// TODO: Remove lazy initialization and lateinit properties in a refactoring effort: CXP-4926
internal class RootActor private constructor(
    private val speechFlowProvider: SpeechFlowProvider,
    /**
     * Note: This is parametrized to support custom TTS sources, but in the normal scenario is backed by
     * [UtteranceFlowProviderFromSpeechFlow].
     * TODO: Unify all [UtteranceFlowProvider]s into [UtteranceFlowProviderFromSpeechFlow] and remove this parameter
     *  by making [com.speechify.client.api.content.view.speech.SpeechFlow] more expressive, and able to convey what is
     *  necessary by those providers (e.g. different voices for different parts, including prerecorded audio, etc.).
     *  This will make [speechFlowProvider] the main source of content.
     *  #TODOUnifyUtteranceFlowProviders
     */
    private val utteranceFlowProvider: UtteranceFlowProvider,
    /**
     * The cursors delimiting the whole content traversed by the [UtteranceFlowProvider].
     * TODO - consider not requiring them, and just use the value of [com.speechify.client.api.content.ContentElementReference.forRoot].
     *  #ConsiderNotRequiringStartAndEndCursorsOfWhole
     */
    private val startAndEndCursorsOfWhole: ContentStartAndEndCursors,
    private val initialOptions: AudioControllerOptions,
    private val voicesOfPreferenceStateProviderForDefaultVoices: VoicesOfPreferenceStateProvider,
    voiceFactoryFromSpec: VoiceFactoryFromSpec,
    scope: CoroutineScope,
    bufferAndReplaySize: Int,
    private val bufferObserver: BufferObserver<Utterance>,
) : Actor<AudioControllerCommand, AudioControllerEvent>(
    coroutineScope = scope,
    bufferAndReplaySize = bufferAndReplaySize,
) {
    /**
     * Need a separate `SupervisorJob`, because RootActor will create children and neither the RootActor's Job, nor the
     * parent `coroutineScope.coroutineContext[Job]` (if any) should be failed when they fail. RootActor
     * must be able to facilitate retries on failures, and it does so by simply allowing to spawn new `PlayerActor`s,
     * and should keep any other jobs going (e.g. its own command-handling loop).
     */
    private lateinit var supervisorJobForPlayerActors: CompletableJob

    private var bundlingToPlaybackLatencyTelemetryTracker: BundlingToPlaybackLatencyTelemetryTracker? = null

    companion object {
        // Will be removed. Only used for RootActorSetVoiceTests investigation
        val DEBUG_PREFIX_ROOT_ACTOR_TEST = "[ROOT_ACTOR_SERVICE_TEST]"
        internal fun createStarted(
            speechFlowProvider: SpeechFlowProvider,
            /**
             * Note: This is parametrized to support custom TTS sources, but in the normal scenario is backed by
             * [UtteranceFlowProviderFromSpeechFlow].
             * TODO: Unify all [UtteranceFlowProvider]s into [UtteranceFlowProviderFromSpeechFlow] and remove this parameter
             *  by making [com.speechify.client.api.content.view.speech.SpeechFlow] more expressive, and able to convey what is
             *  necessary by those providers (e.g. different voices for different parts, including prerecorded audio, etc.).
             *  This will make [speechFlowProvider] the main source of content.
             *  #TODOUnifyUtteranceFlowProviders
             */
            utteranceFlowProvider: UtteranceFlowProvider,
            /**
             * The cursors delimiting the whole content traversed by the [UtteranceFlowProvider].
             * TODO - consider not requiring them, and just use the value of [com.speechify.client.api.content.ContentElementReference.forRoot].
             *  #ConsiderNotRequiringStartAndEndCursorsOfWhole
             */
            startAndEndCursorsOfWhole: ContentStartAndEndCursors,
            initialOptions: AudioControllerOptions,
            coroutineScope: CoroutineScope,
            bufferAndReplaySize: Int,
            voicesOfPreferenceStateProvider: VoicesOfPreferenceStateProvider,
            voiceFactoryFromSpec: VoiceFactoryFromSpec,
            bufferObserver: BufferObserver<Utterance>,
        ) = RootActor(
            speechFlowProvider = speechFlowProvider,
            utteranceFlowProvider = utteranceFlowProvider,
            startAndEndCursorsOfWhole = startAndEndCursorsOfWhole,
            initialOptions = initialOptions,
            scope = coroutineScope,
            bufferAndReplaySize = bufferAndReplaySize,
            voicesOfPreferenceStateProviderForDefaultVoices = voicesOfPreferenceStateProvider,
            voiceFactoryFromSpec = voiceFactoryFromSpec,
            bufferObserver = bufferObserver,
        ).apply {
            start()
        }
    }

    init {
        scope.launch {
            // This is created in the context of the RootActor for two reasons:
            // - This is the first place where we have access to the original bundling Telemetry event from the
            //   coroutineContext.
            // - The player actor is recreated on occasion and by creating the tracker here we ensure that it is
            //   only created and sent once.
            bundlingToPlaybackLatencyTelemetryTracker = currentBundlingToPlaybackLatencyTelemetryTracker()

            coroutineScope {
                ifInstanceOf<ContentMutationsInfo.Mutable>(
                    value = speechFlowProvider.contentMutationsInfo,
                ) {
                    launch {
                        it.mutationsFlow
                            .collect {
                                send(AudioControllerCommand.ReloadContent)
                            }
                    }
                }

                launch {
                    // The underlying content transform options can notify us if the content has changed in place and we need to reload.
                    // One example would be the user toggling skipping of headers/footers on or off, or the speech transform
                    // being changed. In both cases we need to refetch the sentences and re-synthesize them if needed.
                    initialOptions.contentTransformOptions.contentTransformOptionsChanged
                        // Drop the initial value, because those settings are the already applied ones.
                        .drop(1)
                        .collect {
                            send(AudioControllerCommand.ReloadContent)
                        }
                }
            }
        }
    }

    private lateinit var childActors: ChildActors
    internal val isPlaying
        get() = this::childActors.isInitialized && childActors.isPlaying

    private val voiceOfPreferenceProviderMutableState:
        MutableStateFlow<VoiceOfPreferenceProviderWithDisposableResources> =
            MutableStateFlow(
                value = VoiceOfPreferenceProviderFromDefaultVoices(),
            )

    /**
     * The voice to prefer for this document. If `null`, the preferences from [com.speechify.client.bundlers.listening.VoicePreferences]
     * will be used.
     */
    internal val voiceOfPreferenceOverrideMutable: MutableStateFlow<VoiceSpecOfAvailableVoice?> =
        MutableStateFlow(
            value = null,
        )

    private val voiceOfPreferenceProvider: VoiceOfPreferenceProviderWithCache =
        object : VoiceOfPreferenceProvider {
            override suspend fun getPreferredVoice(): VoiceSpecOfAvailableVoice =
                voiceOfPreferenceProviderMutableState.value.getPreferredVoice()
        }
            .toVoiceOfPreferenceProviderWithCacheForCurrentVoice(
                voiceFactoryFromSpec = voiceFactoryFromSpec,
            )

    private val currentCursor: MutableStateFlow<ContentCursor> = MutableStateFlow(
        value = initialOptions.startingCursor ?: startAndEndCursorsOfWhole.start,
    )

    // ------ Child Actors -------

    private inner class ChildActors(
        /**
         * The actor will be started immediately and the Job will be kept for ensuring that errors are caught and enable
         * a retry.
         */
        unstartedPlayerActor: PlayerActor,
        val events: MutableSharedFlow<AudioControllerEvent>,
    ) {
        var playerActor: PlayerActor = unstartedPlayerActor
            private set

        private var playerActorJob: Job = unstartedPlayerActor
            .startAndHandleFailure(
                events = events,
                voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                startingCursor = initialOptions.startingCursor
                    ?: startAndEndCursorsOfWhole.start,
                shouldEmitStartSentenceAndWordLocation = true,
            )

        val isPlaying
            get() = playerActorJob.isActive &&
                !playerActor.isPaused()

        fun cancelExistingPlayerJob() {
            playerActorJob.cancel()
        }

        fun prepareJob(startingCursor: ContentCursor) {
            playerActorJob = playerActor
                .startAndHandleFailure(
                    events = events,
                    voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                    startingCursor = startingCursor,
                    shouldEmitStartSentenceAndWordLocation = true,
                )
        }

        fun isPlayerJobActive() = playerActorJob.isActive

        suspend fun changePlayerActor(
            events: MutableSharedFlow<AudioControllerEvent>,
            voiceOfPreferenceProvider: VoiceOfPreferenceProviderWithCache,
            cursorOrNullIfNoChange: ContentCursor? = null,
            bufferOnInit: Boolean,
            startPlaying: Boolean? = null,
            shouldEmitStartSentenceAndWordLocation: Boolean,
        ) {
            val start = DateTime.now().asMillisecondsLong()
            Log.d(
                { "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [changePlayerActor] changing voice started at $start" },
                sourceAreaId = "RootActor.changePlayerActor",
            )
            /**
             * Checking `isCompleted`, prevents playing on a seek after the playing has reached the end.
             */
            val wasStopped = playerActorJob.isCompleted

            val wasPaused = playerActor.isPaused()
            val prevOptions = playerActor.getOptions()
            Log.d(
                {
                    "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [changePlayerActor] reached canceling in " +
                        "${DateTime.now().asMillisecondsLong() - start}ms"
                },
                sourceAreaId = "RootActor.changePlayerActor",
            )
            playerActorJob.cancelAndJoin()

            val startingCursor = cursorOrNullIfNoChange ?: currentCursor.value
            Log.d(
                {
                    "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [changePlayerActor] reached creation of a new player actor in " +
                        "${DateTime.now().asMillisecondsLong() - start}ms"
                },
                sourceAreaId = "RootActor.changePlayerActor",
            )
            playerActor = PlayerActor(
                initialOptions = prevOptions,
                shouldStartPlayingImmediately = startPlaying ?: (!wasPaused && !wasStopped),
                shouldAlwaysLoadFirstUtteranceImmediately = bufferOnInit,
            ).apply {
                playerActorJob = startAndHandleFailure(
                    events = events,
                    voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                    startingCursor = startingCursor,
                    shouldEmitStartSentenceAndWordLocation = shouldEmitStartSentenceAndWordLocation,
                )
            }
            Log.d(
                {
                    "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [changePlayerActor] finished creation of a new player " +
                        "actor in ${DateTime.now().asMillisecondsLong() - start}ms"
                },
                sourceAreaId = "RootActor.changePlayerActor",
            )
        }

        suspend fun stop() {
            playerActorJob.cancelAndJoin()
        }

        fun PlayerActor.startAndHandleFailure(
            events: MutableSharedFlow<AudioControllerEvent>,
            voiceOfPreferenceProvider: VoiceOfPreferenceProviderWithCache,
            startingCursor: ContentCursor,
            shouldEmitStartSentenceAndWordLocation: Boolean,
        ): Job =
            (scope + supervisorJobForPlayerActors).launchAndCancelChildrenAtEnd(
                logWarningIfChildrenRunning = true,
            ) {
                val start = DateTime.now().asMillisecondsLong()
                Log.d(
                    { "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [startAndHandleFailure] started at $start" },
                    sourceAreaId = "RootActor.PlayerActor.startAndHandleFailure",
                )
                useWithLateDestructibles { destructibles ->
                    val speechFlow: SpeechFlow
                    val fullFirstSentenceOrNull: SpeechSentence?

                    if (shouldEmitStartSentenceAndWordLocation) {
                        Log.d(
                            {
                                "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [startAndHandleFailure] reached " +
                                    "speechFlowWithFirstElementOrNullIfNoContent in " +
                                    "${DateTime.now().asMillisecondsLong() - start}ms"
                            },
                            sourceAreaId = "RootActor.PlayerActor.startAndHandleFailure",
                        )
                        val speechFlowWithFirstElementOrNullIfNoContent =
                            speechFlowProvider.getFullSentencesFlowFromSentenceContaining(startingCursor)
                                .firstOrNullRetainingFlowWhilePreventingTwoConsumptionsIn(
                                    scope = this,
                                )
                        fullFirstSentenceOrNull = speechFlowWithFirstElementOrNullIfNoContent?.firstElement
                        speechFlow = speechFlowWithFirstElementOrNullIfNoContent?.flow
                            /**
                             * Re-represent no-content as an empty flow, so that any 'on complete' logic is still executed,
                             * whether here or in [PlayerActor.getUnstartedPlayingFlow].
                             */
                            ?: flowOf()

                        if (speechFlowWithFirstElementOrNullIfNoContent != null) {
                            /**
                             * Sometimes the speech flow is not pulled at all, depending on the [utteranceFlowProvider] implementation,
                             * so we add it to [destructibles] mutable list to destroy it when reaches the end (see #SpeechFlowNotAlwaysPulled).
                             * (No need to do this in `finally` because `speechFlowWithFirstElementOrNullIfNoContent` is already
                             * connected to the parent scope, so it will be cancelled when the parent is cancelled.)
                             */
                            destructibles.addDestructible(speechFlowWithFirstElementOrNullIfNoContent)
                        }

                        Log.d(
                            {
                                "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [startAndHandleFailure] reached calculation of " +
                                    " startingSentenceAndWordLocation in " +
                                    "${DateTime.now().asMillisecondsLong() - start}ms"
                            },
                            sourceAreaId = "RootActor.startAndHandleFailure",
                        )
                        val startingSentenceAndWordLocation = fullFirstSentenceOrNull
                            ?.let { firstSentence ->
                                listOf(firstSentence)
                                    .getSentenceAndWordLocationInContentAtCursorOrFirstAfter(
                                        cursor = startingCursor,
                                        logNotFoundSourceAreaId = "RootActor.PlayerActor.startAndHandleFailure",
                                    )
                            }

                        events.emit(
                            if (isPaused()) {
                                AudioControllerEvent.Paused(
                                    cursor = startingCursor,
                                    sentenceAndWordLocation = startingSentenceAndWordLocation,
                                )
                            } else {
                                AudioControllerEvent.Playing(
                                    cursor = startingCursor,
                                    voice = null,
                                    sentenceAndWordLocation = startingSentenceAndWordLocation,
                                )
                            },
                        )
                    } else {
                        /*
                         * We were told not to emit a cursor location (e.g. to not pull on the content), but we should
                         * at least emit an event reflecting the playing state. This is especially needed while we
                         * don't have addressed #TODOErrorToHealPlayerDeferringStartPlayerActorToPlayPress.
                         */
                        events.emit(
                            if (isPaused()) {
                                AudioControllerEvent.Paused(
                                    cursor = startingCursor,
                                    sentenceAndWordLocation = null,
                                )
                            } else {
                                AudioControllerEvent.Playing(
                                    cursor = startingCursor,
                                    voice = null,
                                    sentenceAndWordLocation = null,
                                )
                            },
                        )
                        fullFirstSentenceOrNull = null
                        speechFlow = speechFlowProvider.getFullSentencesFlowFromSentenceContaining(startingCursor)
                    }

                    currentCursor.value = startingCursor // cursor value should be updated even if audio is not playing

                    Log.d(
                        {
                            "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [startAndHandleFailure] reached retrieval of" +
                                "unstarted playing flow in $start"
                        },
                        sourceAreaId = "RootActor.PlayerActor.startAndHandleFailure",
                    )
                    getUnstartedPlayingFlow(
                        utteranceFlowProvider = utteranceFlowProvider
                            .toUtteranceFlowProviderWithPredefinedStartingPoint(
                                speechFlow = speechFlow,
                                startingCursor = startingCursor,
                                voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                                preSpeechTransform = initialOptions.contentTransformOptions.preSpeechTransformationsFlow
                                    .value,
                                contentSequenceCharacteristics = speechFlowProvider,
                                utteranceBufferSizeOption = initialOptions.utteranceBufferSizeOption,
                                bufferObserver = bufferObserver,
                            ),
                        fullFirstSentenceOrNull = fullFirstSentenceOrNull,
                    )
                        .onEach {
                            if (it is AudioControllerEvent.HasCursor) {
                                Log.d(
                                    {
                                        "RootActor.PlayerActor.startAndHandleFailure: Will be updating cursor " +
                                            "to ${it.cursor}"
                                    },
                                    sourceAreaId = "RootActor.PlayerActor.startAndHandleFailure",
                                )
                                currentCursor.value = it.cursor
                            }
                        }.collect {
                            events.emit(it)
                        }

                    // `collect` returned, which means that the end was reached successfully. We can inform about it:
                    events.emit(
                        AudioControllerEvent.Playing(
                            cursor = startAndEndCursorsOfWhole.end,
                            voice = null,
                            sentenceAndWordLocation =
                            /* `null` will keep the last word, as per #KeepingOldLocationOnPlayWithNull
                           (here is the case of #EndOfPlayWordLocationIsNull) */
                            null,
                        ),
                    )
                    /*  We are required to inform the consumers about it with an `Ended` event. */
                    events.emit(AudioControllerEvent.Ended)
                }
            }.apply {
                /* #InfinitePlayLoopPrevention - send the `AudioControllerEvent.Errored`/`AudioControllerEvent.Ended`
                   events only *after* the job completed, so that any reaction to the event like retries or seeking make
                   the `RootActor` already see the job as completed (in `playerActorJob.isCompleted`). So, e.g. when any
                   `seek` is done immediately,  it can be done with ensuring that this does not result in starting the
                   process again.
                 */
                invokeOnCompletion { e ->
                    e ?: return@invokeOnCompletion
                    if (e is CancellationException) {
                        /* Cancellations mean a graceful disposal of the PlayerActor, so we don't want to raise any
                         problem, or even raise `Destroyed`, because most of the time these happen when continuing
                         to play but user did something that required a new `PlayerActor` (e.g. seek or change of
                         voice)
                         */
                        return@invokeOnCompletion
                    }

                    scope /* NOTE - here we're not using the `supervisorJobForPlayerActors` because
                        handling the end or error is the `RootActor`s responsibility, and if this fails, the
                        `RootActor` should also fail.
                    */
                        .launch {
                            /* Entering here means it is a real error. */
                            events.emit(AudioControllerEvent.Errored(SDKError.OtherException(e)))
                            events.emit(AudioControllerEvent.Destroyed(SDKError.OtherException(e)))
                            /* Need to prepare the state, so that a retry is allowed. */

                            /* TODO - consider just emitting to the UI events that will make the 'Play'
                                button clickable again (e.g. `Errored` and `Paused`, or just change the
                                behavior on `Errored`) which should allow us to only perform the
                                instantiation of a new actor only in `play()`. This will allow the
                                #InfinitePlayLoopPrevention to simply result from the design - we
                                would just never start a new player on an error (it's pretty bad design
                                to do so much on an error). #TODOErrorToHealPlayerDeferringStartPlayerActorToPlayPress */
                            changePlayerActor(
                                events,
                                voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                                /*
                                  #InfinitePlayLoopPrevention - setting the below to `false` is
                                  critical for preventing endless loop on synthesis error, as it
                                  prevents making any new synthesis at the start.
                                */
                                bufferOnInit = false,
                                startPlaying = false,
                                /**
                                 #InfinitePlayLoopPrevention - setting the below to `false` is
                                 critical for preventing endless loop error from content retrieval, as it
                                 prevents pulling the content at the start.
                                 */
                                shouldEmitStartSentenceAndWordLocation = false,
                            )
                        }
                }
            }
    }

    private fun createStartedChildActors(events: MutableSharedFlow<AudioControllerEvent>): ChildActors {
        return ChildActors(
            unstartedPlayerActor = PlayerActor(
                initialOptions = initialOptions.toPlayerOptions(),
                shouldStartPlayingImmediately = false,
                shouldAlwaysLoadFirstUtteranceImmediately = initialOptions.bufferOnInit,
            ),
            events = events,
        )
    }

    // ------- Root Actor Loop --------

    override suspend fun CoroutineScope.loop(
        commands: ReceiveChannel<AudioControllerCommand>,
        events: MutableSharedFlow<AudioControllerEvent>,
    ) {
        supervisorJobForPlayerActors =
            scope.coroutineContext.job.createChildSupervisorJob() /* Need to use a 'supervisor job' (see KDoc
             of createChildSupervisorJob) for the player actors, so that this actor doesn't fail, when a player actor
             fails (this actor has the capability to recover from this, by just starting a new child again).
             Note that it's purposeful to create it as a child of the actor loop's job, and not that of `coroutineScope`
             as this would make it its sibling (and it would not get cancelled or failed automatically when the loop
             fails).
             */

        this@RootActor.childActors = createStartedChildActors(events)
        try {
            val singleThreadDispatcher = newSingleThreadContextDispatcher("AudioControllerRootActor")
            for (command in commands) {
                // Make sure that anything touching the state happens on our own thread.
                withContext(
                    singleThreadDispatcher,
                ) {
                    handleCommand(command, events)
                }
            }
        } finally {
            childActors.stop()
        }
        supervisorJobForPlayerActors.complete() /* Else nothing would complete it. */
    }

    private var hasReceivedCommandImplyingUserInteraction = false

    private suspend fun handleCommand(
        command: AudioControllerCommand,
        events: MutableSharedFlow<AudioControllerEvent>,
    ) {
        Log.d(
            { "RootActor: Received COMMAND: $command" },
            sourceAreaId = "RootActor.handleCommand",
        )

        /*
         * We want to be able to make tradeoffs based on the level of user intent to engage with this listening
         * experience, so for now we infer intent from the incoming commands. Sometimes client integrations will call
         * these commands to proactively setup listening experiences, or they will be fired as side-effects of
         * components responsible for handling content that changes - in these cases we have found it useful to
         * explicitly model the intent from the origination point where it is ambiguous and explicitly show the
         * inference logic here below.
         */
        hasReceivedCommandImplyingUserInteraction = hasReceivedCommandImplyingUserInteraction || when (command) {
            is AudioControllerCommand.ReloadContent -> false
            is AudioControllerCommand.Seek -> command.impliesUserInteraction
            AudioControllerCommand.Pause -> true
            is AudioControllerCommand.Play -> true
            AudioControllerCommand.Resume -> true
            is AudioControllerCommand.SetSpeed -> true
            is AudioControllerCommand.SetVoice -> true
        }

        when (command) {
            is AudioControllerCommand.Play -> seek(
                childActors,
                events,
                command.cursor,
                bufferOnInit = true,
                startPlaying = true,
            )

            is AudioControllerCommand.Pause -> {
                childActors.playerActor.pause()
                if (childActors.playerActor.isBuffering) {
                    events.emit(AudioControllerEvent.Paused(currentCursor.value, null))
                    childActors.cancelExistingPlayerJob()
                }
            }
            AudioControllerCommand.Resume -> {
                if (!childActors.isPlayerJobActive()) {
                    childActors.prepareJob(currentCursor.value)
                }
                childActors.playerActor.resume()
            }
            is AudioControllerCommand.Seek -> seek(
                childActors,
                events,
                command.cursor,
                bufferOnInit = initialOptions.bufferOnInit || hasReceivedCommandImplyingUserInteraction,
            )

            is AudioControllerCommand.SetSpeed -> {
                childActors.playerActor.setSpeed(command.speedInWordsPerMinute)
                events.emit(AudioControllerEvent.ChangedSpeed(command.speedInWordsPerMinute))
            }

            is AudioControllerCommand.SetVoice -> {
                val start = DateTime.now().asMillisecondsLong()
                Log.d(
                    { "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] set voice started at $start" },
                    sourceAreaId = "RootActor.handleCommand",
                )
                val newVoice = command.voice
                Log.d(
                    {
                        "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] reached swapping voice in " +
                            "${DateTime.now().asMillisecondsLong() - start}ms"
                    },
                    sourceAreaId = "RootActor.handleCommand",
                )
                val oldVoice = voiceOfPreferenceOverrideMutable.swap(newVoice)
                Log.d(
                    {
                        "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] finished swapping voice in " +
                            "${DateTime.now().asMillisecondsLong() - start}ms"
                    },
                    sourceAreaId = "RootActor.handleCommand",
                )
                if (newVoice == null) {
                    /* Setting a `null` means we're back to user's `DefaultVoices` preferences, so need to ensure
                     * the current provider takes from these.
                     */
                    val oldProvider = voiceOfPreferenceProviderMutableState.swap(
                        VoiceOfPreferenceProviderFromDefaultVoices(),
                    )
                    oldProvider.destroy()
                }
                if (oldVoice == null && newVoice != null) {
                    /* If `null` was here previously, it means we were using user's `DefaultVoices` preferences,
                     * And now we want to be back to the `voiceOfPreferenceOverride`.
                     */
                    val oldProvider = voiceOfPreferenceProviderMutableState.swap(
                        VoiceOfPreferenceProviderFromOverride(),
                    )
                    /* Because we are told to override user's `DefaultVoices` preferences, we need to also destroy the
                     * old provider, to prevent `DefaultVoices` preferences from changing the voice.
                     */
                    oldProvider.destroy()
                }

                Log.d(
                    {
                        "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] reached applyVoiceChange in " +
                            "${DateTime.now().asMillisecondsLong() - start}ms"
                    },
                    sourceAreaId = "RootActor.handleCommand",
                )
                applyVoiceChange()
                Log.d(
                    {
                        "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] done applyVoiceChange in " +
                            "${DateTime.now().asMillisecondsLong() - start}ms"
                    },
                    sourceAreaId = "RootActor.handleCommand",
                )
                events.emit(
                    AudioControllerEvent.ChangedVoice(
                        oldVoice = oldVoice,
                        voice = newVoice,
                    ),
                )
                Log.d(
                    {
                        "$DEBUG_PREFIX_ROOT_ACTOR_TEST: [handleCommand] finished set voice in " +
                            "${DateTime.now().asMillisecondsLong() - start}ms"
                    },
                    sourceAreaId = "RootActor.handleCommand",
                )
            }

            is AudioControllerCommand.ReloadContent -> {
                // We recreate the player actor, this will refetch the content, applying any
                // content changes like changed content filters, or sentence transforms.
                childActors.changePlayerActor(
                    events,
                    voiceOfPreferenceProvider = voiceOfPreferenceProvider,
                    bufferOnInit = isPlaying,
                    shouldEmitStartSentenceAndWordLocation = true,
                )
            }
        }
    }

    private suspend fun seek(
        childActors: ChildActors,
        events: MutableSharedFlow<AudioControllerEvent>,
        cursor: ContentCursor,
        bufferOnInit: Boolean,
        startPlaying: Boolean? = null,
    ) {
        events.emit(AudioControllerEvent.Seeking(cursor))
        childActors.changePlayerActor(
            events,
            voiceOfPreferenceProvider = voiceOfPreferenceProvider,
            cursorOrNullIfNoChange = cursor,
            bufferOnInit = bufferOnInit,
            startPlaying = startPlaying,
            shouldEmitStartSentenceAndWordLocation = true,
        )
    }

    /**
     * TODO - refactor [com.speechify.client.api.audio.AudioController] to have its own flow of events, and not use the
     *  actor's events, and remove this function, or maybe even refactor `AudioController` to be one with the RootActor
     */
    suspend fun emitErrorEvent(event: AudioControllerEvent.Errored) =
        eventsSink.emit(event)

    private interface VoiceOfPreferenceProviderWithDisposableResources : VoiceOfPreferenceProvider, Destructible

    private suspend fun applyVoiceChange() {
        childActors.changePlayerActor(
            events = eventsSink,
            bufferOnInit = true,
            /* the user has already started listening/interacting with
                                the audio controller, so let's expend some work of Audio Synthesis to make his start of play
                                quicker */
            shouldEmitStartSentenceAndWordLocation = true,
            voiceOfPreferenceProvider = voiceOfPreferenceProvider,
        )
    }

    inner class VoiceOfPreferenceProviderFromDefaultVoices :
        WithScope(
            /* Use a `SupervisorScope`, so that a crash here doesn't prevent `RootActor` to recover by changing voice. */
            scope = scope.createChildSupervisorScope(),
        ),
        VoiceOfPreferenceProviderWithDisposableResources {

        private val preferencesFlowDeferred: Deferred<StateFlow<VoiceSpecOfAvailableVoice>> = scope.async(
            /**
             * `LAZY` so that we don't even ask for preference when the content dictates the voice.
             */
            start = CoroutineStart.LAZY,
        ) {
            val lastVoice: AtomicRef<VoiceSpecOfAvailableVoice?> = AtomicRef(null)

            return@async voicesOfPreferenceStateProviderForDefaultVoices
                .getPreferredVoiceStateIn(
                    /** Must use [scope] and not `this@async`, or else the `async` won't return. */
                    scope = scope,
                )
                /** Need to build-in the [applyVoiceChange] into the flow, so that even when [getPreferredVoice]
                 * is not called (it's only called when restarting the [PlayerActor], we still react to the preference
                 * changes and change the current playing voice).
                 */
                .onEachAfterDownstream { currentPreferredVoice ->
                    val oldPreferredVoice = lastVoice.swap(currentPreferredVoice)
                    if (oldPreferredVoice != null &&
                        oldPreferredVoice.idQualified != currentPreferredVoice.idQualified
                    ) {
                        applyVoiceChange()
                    }
                }
                /* Convert back to state flow */
                .stateIn(
                    /** Must use [scope] and not `this@async`, or else the `async` won't return. */
                    scope = scope,
                )
        }

        override suspend fun getPreferredVoice(): VoiceSpecOfAvailableVoice =
            preferencesFlowDeferred.await().value
    }

    inner class VoiceOfPreferenceProviderFromOverride : VoiceOfPreferenceProviderWithDisposableResources {
        override suspend fun getPreferredVoice(): VoiceSpecOfAvailableVoice =
            voiceOfPreferenceOverrideMutable
                .firstNotNull()

        override fun destroy() {
            /* Do nothing - this provider is stateless */
        }
    }

    override suspend fun stop(waitTimeForSelfFinish: Duration) {
        childActors.playerActor.stop()
        super.stop(waitTimeForSelfFinish)
    }
}

// TODO(mendess) this probably needs a lot of changing
internal fun Int.toSpeed(): Float = this.toFloat() / 220f

private fun AudioControllerOptions.toPlayerOptions() = PlayerOptions(speedInWordsPerMinute.toSpeed(), 100f)
