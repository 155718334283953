package com.speechify.client.internal.actor

import com.speechify.client.internal.time.Duration
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.withTimeoutOrNull

internal fun <Commands, Events> makeChannels(
    bufferAndReplaySize: Int,
): Pair<Channel<Commands>, MutableSharedFlow<Events?>> {
    val commands = Channel<Commands>(
        capacity = bufferAndReplaySize,
    )
    val events = MutableSharedFlow<Events?>(
        replay = bufferAndReplaySize,
    )
    return Pair(commands, events)
}

/**
 * Tries to join on a job, if after the [timeout] the job has not finished, cancel the job
 * forcefully.
 *
 * @return whether the job joined successfully
 */
internal suspend fun Job.timeoutJoinOrCancel(
    timeout: Duration,
    createException: (() -> CancellationException)? = null,
): Boolean {
    val joined = null != withTimeoutOrNull(timeout.inWholeMilliseconds) { join() }
    if (!joined) {
        cancel(createException?.invoke())
        join()
    }
    return joined
}
