// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

/** It starts with windows smaller than [maxSize] so, as opposed to [windowed], this one does not force to collect
 *  minimum `size` values.
 *
 * Apart for the behavior in the beginning, it's equivalent to [windowed] with `step=0` and `partialWindows=false` (
 * note that `partialWindows` in [windowed] pertains only to the behavior at the end)
 *
 * @see allSublistsFromSingleFirstItemToAllItems for a version that grows indefinitely.
 */
internal fun <T> Flow<T>.windowedStartingWithSmaller(maxSize: Int): Flow<List<T>> = flow {
    val deque = ArrayDeque<T>(maxSize)
    collect {
        if (deque.size == maxSize) {
            deque.removeFirst()
        }
        deque.addLast(it)
        emit(deque)
    }
}
