package com.speechify.client.api.services.library

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.DocumentChangeType
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.HasSnapshotRef
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.FilterAndSortOptions
import com.speechify.client.api.services.library.models.FilterType
import com.speechify.client.api.services.library.models.FolderChangeSet
import com.speechify.client.api.services.library.models.FolderQuery
import com.speechify.client.api.services.library.models.FolderReference
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.SearchRequest
import com.speechify.client.api.services.library.models.SearchRequestInternal
import com.speechify.client.api.services.library.models.SearchResult
import com.speechify.client.api.services.library.models.SearchResultInternal
import com.speechify.client.api.services.library.models.SortBy
import com.speechify.client.api.services.library.models.SortOrder
import com.speechify.client.api.services.library.models.UpdateLibraryItemParams
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.FirestorePaginator
import com.speechify.client.api.util.FirestoreRecordUpdatesFlowFactory
import com.speechify.client.api.util.ILiveQueryView
import com.speechify.client.api.util.ItemChange
import com.speechify.client.api.util.LiveQueryView
import com.speechify.client.api.util.LiveQueryViewV2
import com.speechify.client.api.util.PredicateAsync
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.and
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toException
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer
import com.speechify.client.internal.util.retry.retryWithExponentialBackoff
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.flattenMerge
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

internal class LibraryServiceDelegate internal constructor(
    private val authService: AuthService,
    private val libraryFirestoreService: LibraryFirebaseDataFetcher,
    private val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
    private val importService: ImportService,
    private val useLiveQueryViewV2: Boolean,
    private val isSpeechifyEbookVisibleInLibrary: Boolean,
) {

    private val maxItemsPerPage = 50

    private val optimisticItemChangesThatOriginatedLocally = MutableSharedFlow<ItemChange<LibraryItem>>()

    internal suspend fun getLegacyPageURLs(itemId: String): Result<List<String>> {
        return libraryFirestoreService.getLegacyPageURLs(itemId)
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    internal suspend fun getItems(
        parentFolderId: FolderQuery,
        options: FilterAndSortOptions,
        count: Int = maxItemsPerPage,
    ): Result<ILiveQueryView<LibraryItem>> {
        val user = authService.getCurrentUser().orReturn { return it }

        val userId = user.uid

        // Some filters cannot be applied at the query level, so we need to filter the results after we get them from
        // firestore.
        val filter = options.type
        val outputItemFilter: PredicateAsync<LibraryItem>? = if (filter is FilterType.ApplyLocallyFilter) {
            and(filter::shouldInclude, this::shouldShowContent)
        } else {
            this::shouldShowContent
        }

        // For local content we can only support certain filters. So if we encounter ones that are not supported we
        // simply don't include the local content.
        val supportedFiltersForLocalContent = listOf(FilterType.ANY, FilterType.AVAILABLE_OFFLINE, FilterType.RECORDS)
        val supportedSortByForLocalContent = listOf(SortBy.ALPHABETICAL, SortBy.DATE_ADDED)
        val (importingItems, localComparator) = if (supportedFiltersForLocalContent.contains(options.type) &&
            supportedSortByForLocalContent.contains(options.sortBy)
        ) {
            importService.observeListenableImports(userId, parentFolderId)
                .map { l ->
                    l.map { LibraryItem.DeviceLocalContent(it) }
                } to
                Comparator<LibraryItem> { a, b ->
                    val sortResult = when (options.sortBy) {
                        // SortBy.ALPHABETICAL comparison should be case-insensitive for title
                        SortBy.ALPHABETICAL -> a.title.lowercase().compareTo(b.title.lowercase())
                        SortBy.DATE_ADDED -> a.createdAt.compareTo(b.createdAt)
                        SortBy.DATE_ARCHIVED ->
                            throw IllegalStateException("Sorting local items by archived date is not supported.")

                        SortBy.RECENTLY_LISTENED ->
                            throw IllegalStateException("Sorting local items by recently listened is not supported.")
                    }

                    if (options.sortOrder == SortOrder.DESC) sortResult * -1 else sortResult
                }
        } else {
            null to null
        }

        val libraryQuery = libraryFirestoreService.getItemsQuery(
            parentFolderId,
            options,
            userId,
            count,
            null,
        )

        val sortByComparator = when (options.sortBy) {
            SortBy.ALPHABETICAL -> compareBy<LibraryItem> { it.title.lowercase() }
            SortBy.RECENTLY_LISTENED -> compareBy<LibraryItem> { it.lastListenedAt }.thenBy { it.createdAt }
            SortBy.DATE_ADDED -> compareBy { it.createdAt }
            SortBy.DATE_ARCHIVED -> compareBy { it.removedAt }
        }.let {
            when (options.sortOrder) {
                SortOrder.ASC -> it
                SortOrder.DESC -> it.reversed()
            }
        }

        val timestampComparator: Comparator<LibraryItem> = compareBy { it.updatedAt }

        val lazyLoadedResult: ILiveQueryView<LibraryItem> = when (useLiveQueryViewV2) {
            true -> {
                val firestorePaginator = FirestorePaginator(
                    query = libraryQuery,
                    itemsPerPage = count,
                )
                val itemChangesThatOriginatedRemotely = FirestoreRecordUpdatesFlowFactory(
                    libraryQuery.observeImpl,
                    libraryQuery.queryDto(),
                ).create().map { itemChanges ->
                    itemChanges.mapNotNull {
                        val item =
                            it.doc.toLibraryItemOrNullWithLoggingError(DataSource.DEFAULT) ?: return@mapNotNull null
                        when (it.type) {
                            DocumentChangeType.Added -> ItemChange.Added(item)
                            DocumentChangeType.Removed -> ItemChange.Removed(item)
                            DocumentChangeType.Modified -> ItemChange.Modified(item)
                        }
                    }
                }

                // Merge of itemChanges originated remotely and locally.
                val mergedItemChanges = flowOf(
                    itemChangesThatOriginatedRemotely,
                    optimisticItemChangesThatOriginatedLocally.map { listOf(it) },
                ).flattenMerge()

                LiveQueryViewV2.empty(
                    getNextPageOfRemoteItems = { lastItem: HasSnapshotRef? ->
                        firestorePaginator.getNextPage(lastItem).mapNotNull {
                            it.toLibraryItemOrNullWithLoggingError(DataSource.DEFAULT)
                        }
                    },
                    localItems = importingItems ?: emptyFlow(),
                    itemChanges = mergedItemChanges,
                    outputItemFilter = outputItemFilter,
                    sortByComparator = sortByComparator,
                    timestampComparator = timestampComparator,
                )
            }

            false -> {
                LiveQueryView.empty(
                    itemsPerPage = count,
                    query = libraryFirestoreService.getItemsQuery(
                        parentFolderId,
                        options,
                        userId,
                        count,
                        null,
                    ),
                    transformer = libraryFirestoreService::toLibraryItem,
                    outputItemFilter = outputItemFilter,
                    localItemsFlow = importingItems,
                    localComparator = localComparator,
                )
            }
        }

        return suspendCoroutine { cont ->
            lazyLoadedResult.loadMoreItems {
                cont.resume(it.map { lazyLoadedResult })
            }
        }
    }
    private suspend fun shouldShowContent(item: LibraryItem): Boolean {
        val isEbook = item is LibraryItem.ListenableContent &&
            item.contentType?.equals(ContentType.SPEECHIFY_BOOK) == true

        if (isEbook) return isSpeechifyEbookVisibleInLibrary

        return true
    }

    internal suspend fun getItemWithSourceURL(sourceURL: String): Result<LibraryItem.Content?> {
        val user = authService.getCurrentUser().orReturn { return it }

        val userId = user.uid

        val itemWithSourceURL: Result<LibraryItem.Content?> = suspendCoroutine { cont ->
            libraryFirestoreService.getContentItemBySourceURL(sourceURL, userId, cont::resume)
        }

        return when (itemWithSourceURL) {
            is Result.Success -> {
                if (itemWithSourceURL.value == null) {
                    null.successfully()
                } else {
                    itemWithSourceURL
                }
            }

            is Result.Failure -> itemWithSourceURL
        }
    }

    internal suspend fun getTopLevelArchivedItems(): Result<ILiveQueryView<LibraryItem>> {
        return getItems(
            FolderQuery.All,
            FilterAndSortOptions(
                FilterType.TOP_LEVEL_ARCHIVED_ITEMS,
                SortBy.DATE_ARCHIVED,
                SortOrder.DESC,
            ),
        )
    }

    internal suspend fun shareFile(fileItemId: String): Result<String> {
        val file = getItemFromFirestore(fileItemId).orReturn { return it }
        return when (file) {
            is LibraryItem.Content -> {
                libraryFirestoreService.shareFileItem(file)
            }

            is LibraryItem.Folder -> {
                Result.Failure(SDKError.OtherException(Exception("Folder share is not supported")))
            }

            is LibraryItem.DeviceLocalContent ->
                Result.Failure(SDKError.OtherException(Exception("Sharing device local content is not supported")))
            // TODO: Perhaps with Kotlin updates this branch becomes unneeded.
            is LibraryItem.ListenableContent ->
                Result.Failure(SDKError.OtherException(Exception("Sharing non imported content is not supported")))
        }
    }

    internal suspend fun stopSharingFile(fileItemId: String): Result<Unit> {
        return libraryFirestoreService.stopSharingFileItem(fileItemId)
    }

    internal suspend fun getItemFromFirestore(
        itemId: SpeechifyContentId,
        dataSource: DataSource = DataSource.DEFAULT,
    ): Result<LibraryItem> {
        return libraryFirestoreService.getLibraryItem(itemId, dataSource)
    }

    /**
     * Resolves both pending imports as well as items only existing in firestore.
     */
    internal suspend fun getItemFromFirestoreOrLocalFromUri(
        uri: SpeechifyURI,
        dataSource: DataSource = DataSource.DEFAULT,
    ): LibraryItem {
        return importService.getPendingImportFromUri(uri)?.let { LibraryItem.DeviceLocalContent(it) }
            ?: libraryFirestoreService.getLibraryItem(uri.id, dataSource).orThrow()
    }

    /**
     * Resolves both pending imports as well as items only existing in firestore.
     */
    internal suspend fun getItemFromFirestoreOrLocalFromId(
        id: SpeechifyContentId,
        dataSource: DataSource = DataSource.DEFAULT,
    ): LibraryItem {
        return importService.getPendingImportFromId(id)?.let { LibraryItem.DeviceLocalContent(it) }
            ?: libraryFirestoreService.getLibraryItem(id, dataSource).orThrow()
    }

    internal suspend fun getItemForSpeechifyBookFromFirestore(
        speechifyBookUri: String,
    ): LibraryItem.Content? {
        val user = authService.getCurrentUser().orThrow()
        val userId = user.uid

        return libraryFirestoreService.getSpeechifyBookItem(speechifyBookUri, userId).orThrow()
    }

    internal suspend fun archiveItem(itemId: String): Result<Unit> {
        val item = getItemFromFirestoreOrLocalFromId(itemId)
        // Optimistic archive of a library item. This will remove the item from the LiveQueryViewV2 layer only.
        optimisticItemChangesThatOriginatedLocally.emit(ItemChange.Removed(item))

        return firebaseFunctionsService.archiveLibraryItem(
            LibraryFirebaseTransformer.buildLibraryManagementFunctionPayload(
                itemId,
            ),
        ).ifSuccessful {
            if (item is LibraryItem.DeviceLocalContent) {
                // For local content we want to clear any local data as well.
                importService.stopImportingAndRemoveFromLibrary(item.underlyingItemRequiringImport)
                optimisticItemChangesThatOriginatedLocally.emit(ItemChange.Removed(item))
            }
        }
    }

    internal suspend fun restoreItem(itemId: String): Result<Unit> {
        return firebaseFunctionsService.restoreLibraryItem(
            LibraryFirebaseTransformer.buildLibraryManagementFunctionPayload(
                itemId,
            ),
        )
    }

    internal suspend fun deleteItem(itemId: String): Result<Unit> {
        val item = getItemFromFirestoreOrLocalFromId(itemId)

        // Optimistic deletion of a library item. This will remove the item from the LiveQueryViewV2 layer only.
        optimisticItemChangesThatOriginatedLocally.emit(ItemChange.Removed(item))

        // We always remove the resource from Firestore as well to clean up any half imported items.
        return retryWithExponentialBackoff(blockName = "firebaseFunctionsService.removeLibraryItem") {
            firebaseFunctionsService.removeLibraryItem(
                LibraryFirebaseTransformer.buildLibraryManagementFunctionPayload(
                    itemId,
                ),
            )
        }.ifSuccessful {
            if (item is LibraryItem.DeviceLocalContent) {
                // For local content we want to clear any local data as well.
                importService.stopImportingAndRemoveFromLibrary(item.underlyingItemRequiringImport)
                optimisticItemChangesThatOriginatedLocally.emit(ItemChange.Removed(item))
            }
        }
    }

    internal suspend fun restoreAllArchivedItems(): Result<Unit> {
        return firebaseFunctionsService.restoreAllArchivedItems()
    }

    internal suspend fun deleteAllArchivedItems(): Result<Unit> {
        return firebaseFunctionsService.removeAllArchivedItems()
    }

    internal suspend fun addDefaultLibraryItems(): Result<Unit> {
        return firebaseFunctionsService.addDefaultLibraryItems()
    }

    internal suspend fun moveItem(itemId: String, folderItemId: FolderReference): Result<Unit> {
        return libraryFirestoreService.updateItemFolder(itemId, folderItemId)
    }

    internal suspend fun updateItem(itemId: String, patch: UpdateLibraryItemParams): Result<Unit> {
        val item = getItemFromFirestoreOrLocalFromId(itemId)
        if (item is LibraryItem.DeviceLocalContent) {
            importService.updatePendingImport(item.underlyingItemRequiringImport, patch).successfully()
        }
        if (item is LibraryItem.ListenableContent &&
            item.contentType == ContentType.SPEECHIFY_BOOK &&
            (patch.title != null || patch.coverImageUrl != null)
        ) {
            throw IllegalArgumentException("Item with type ${ContentType.SPEECHIFY_BOOK} can't be updated")
        }
        return libraryFirestoreService.updateItemDataFromParams(itemId, patch)
    }

    internal suspend fun transferLibrary(
        fromUserFirebaseIdentityToken: String,
        toUserFirebaseIdentityToken: String,
    ): Result<Unit> {
        return firebaseFunctionsService.transferLibrary(
            LibraryFirebaseTransformer.buildTransferLibraryFunctionPayload(
                fromUserFirebaseIdentityToken,
                toUserFirebaseIdentityToken,
            ),
        )
    }

    internal suspend fun libraryItemSearch(
        searchRequest: SearchRequest,
    ): Result<SearchResult> {
        val searchHelper: suspend (SearchRequestInternal) -> SearchResultInternal =
            { internalRequest: SearchRequestInternal ->
                val searchPayload = LibraryFirebaseTransformer.buildLibrarySearchPayload(internalRequest)
                val platformSearchResult = firebaseFunctionsService.libraryItemSearch(searchPayload).orThrow()
                SearchResultInternal(
                    hasMore = platformSearchResult.hasMore,
                    page = platformSearchResult.page,
                    items = libraryFirestoreService.toLibraryItems(platformSearchResult).toTypedArray(),
                )
            }
        val searchRequestInternal = SearchRequestInternal(searchRequest)
        val searchResultInternal = searchHelper(searchRequestInternal)
        return SearchResult(
            hasMore = searchResultInternal.hasMore,
            items = searchResultInternal.items,
            searchRequest = searchRequestInternal,
            searchHelperFn = searchHelper,
        ).successfully()
    }

    // Destructors corresponding to our archive listeners
    private val archiveChangeListenerDestructors =
        mutableMapOf<Callback<FolderChangeSet>, Destructor>()

    internal suspend fun addArchiveChangeListener(callback: Callback<FolderChangeSet>) {
        val user = when (val userResult = authService.getCurrentUser()) {
            is Result.Success -> userResult.value
            is Result.Failure -> {
                callback(userResult)
                return
            }
        }

        val archiveObserverDestructor =
            libraryFirestoreService.observeTopLevelArchivedItems(user.uid, callback)

        archiveChangeListenerDestructors[callback] = archiveObserverDestructor
    }

    internal fun removeArchiveChangeListener(callback: Callback<FolderChangeSet>) {
        val destructor = archiveChangeListenerDestructors[callback]
        if (destructor != null) {
            destructor()
            archiveChangeListenerDestructors.remove(callback)
        }
    }

    internal fun removeAllArchiveChangeListeners() {
        archiveChangeListenerDestructors.forEach {
            it.value()
        }
        archiveChangeListenerDestructors.clear()
    }

    internal suspend fun createFolder(
        folderTitle: String,
        parentFolderId: FolderReference,
    ): Result<FolderReference> {
        val user = authService.getCurrentUser().orReturn { return it }
        return libraryFirestoreService.createFolder(folderTitle, user.uid, parentFolderId)
    }

    internal suspend fun updateLibraryItemIfIsOwner(
        ownerId: String,
        itemId: String,
        patch: UpdateLibraryItemParams,
    ): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }
        // Ignore updating document if the current user is not the owner.
        if (ownerId != user.uid) return Unit.successfully()
        return updateItem(itemId, patch)
    }

    internal suspend fun getCount(filterType: FilterType): Result<Long> {
        val user = authService.getCurrentUser().orReturn { return it }
        return libraryFirestoreService.getCount(user.uid, filterType)
    }

    internal suspend fun observeLibraryItem(itemId: String): Flow<Result<LibraryItem>> {
        val user = authService.getCurrentUser().orReturn { return flowOf(it) }

        return importService.observePendingLocalItem(itemId, user.uid).map {
            it?.let { LibraryItem.DeviceLocalContent(it) }
        }.combine(libraryFirestoreService.observeLibraryItem(itemId)) { deviceLocalItems, firestoreItemFlow ->
            deviceLocalItems?.successfully() ?: firestoreItemFlow
        }
    }

    private suspend fun FirebaseFirestoreDocumentSnapshot.Exists.toLibraryItemOrNullWithLoggingError(
        dataSource: DataSource,
    ) = when (val itemResult = libraryFirestoreService.toLibraryItem(snapshot = this, dataSource = dataSource)) {
        is Result.Failure -> {
            Log.w(
                DiagnosticEvent(
                    error = ErrorInfoForDiagnostics(itemResult.error.toException()),
                    sourceAreaId = "LibraryFirestoreService.toLibraryItem",
                ),
            )
            null
        }

        is Result.Success -> itemResult.value
    }
}

internal suspend fun LibraryServiceDelegate.getContentLibraryItemFromFirestore(
    id: SpeechifyContentId,
    dataSource: DataSource = DataSource.DEFAULT,
): LibraryItem.Content {
    return when (val item = getItemFromFirestore(id, dataSource).orThrow()) {
        is LibraryItem.Content -> item
        is LibraryItem.Folder ->
            throw IllegalArgumentException("Content item expected, but folder found")

        is LibraryItem.DeviceLocalContent ->
            throw IllegalArgumentException("Content item expected, but DeviceLocalContent found")
        // TODO: Perhaps with Kotlin updates this branch becomes unneeded.
        is LibraryItem.ListenableContent ->
            throw IllegalArgumentException("Content item expected, but ListenableContent found")
    }
}
