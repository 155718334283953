package com.speechify.client.api.util.collections.flows

import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.internal.getGlobalScopeWithContext
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.mapNotNull
import kotlin.js.JsExport

/**
 * Encapsulates a never ending stream of data.
 * This is the boundary equivalent of a non ending Flow of non null values.
 */
@JsExport
class NeverEndingCallbackStateFlowOfNonNulls<T : Any> internal constructor(
    /**
     * You can pass in a nullable StateFlow here in case there is no initial value.
     */
    private val flow: StateFlow<T?>,
) {

    /**
     * The last value of this flow, or null if none was emitted yet.
     */
    val lastValue: T?
        get() = flow.value

    /**
     * The [collectOne] callback will be called with each value that is provided by the underlying data source.
     * This will keep being called with values forever.
     *
     * Receiving of data can be stopped by using the returned [Destructible].
     */
    fun subscribe(
        collectOne: CallbackNoError<T>,
    ): Destructible =

        collectOne.multiShotFromFlowIn(
            flow.mapNotNull { it },
            getGlobalScopeWithContext(),
        )
}

/**
 * Turns a [StateFlow] into [NeverEndingCallbackStateFlowOfNonNulls].
 */
internal fun <T : Any> StateFlow<T?>.toNeverEndingCallbackStateFlowOfNonNulls():
    NeverEndingCallbackStateFlowOfNonNulls<T> =
    NeverEndingCallbackStateFlowOfNonNulls(this)
