package com.speechify.client.internal.services.importing.models

import kotlinx.serialization.Serializable

@Serializable
internal data class CopyLibraryItemV1Payload(
    val currentRecordUid: String,
)

@Serializable
internal data class CopyLibraryItemV2Payload(
    val currentRecordUid: String,
    val newRecordUid: String,
)
