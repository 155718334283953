package com.speechify.client.api.audio

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.util.SDKError

sealed class PlayerEvent private constructor(val type: String) {

    /**
     * Groups the events that surface during the play (useful for extension points that work only with these)
     */
    sealed class InPlayEvent(type: String) : PlayerEvent(type)

    interface HasCursor {
        val cursor: ContentCursor
    }

    data class Started(override val cursor: ContentCursor) : InPlayEvent("started"), HasCursor

    data class Paused(override val cursor: ContentCursor) : InPlayEvent("paused"), HasCursor

    object Ended : PlayerEvent("ended")

    data class Progressed(override val cursor: ContentCursor) : InPlayEvent("progressed"), HasCursor

    object Destroyed : InPlayEvent("destroyed")

    /**
     * The player encountered an error and will not reach [Ended].
     */
    data class Error(val error: SDKError) : PlayerEvent("error")

    override fun toString(): String = "${this::class.simpleName ?: this::class}(type=$type)"
}
