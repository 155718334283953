package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow

/**
 * A bit like `flowOf()`, but this one never emits any item, useful for cases where there's some logic that
 * needs to be executed when the flow is collected, when that logic should never run for this specific use case.
 */
internal fun <T> sharedFlowNeverReceivingAnyItem(): SharedFlow<T> =
    MutableSharedFlow(
        replay = 0,
    )
