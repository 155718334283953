package com.speechify.client.helpers.content.standard.html.contentExtractionRules

import com.speechify.client.helpers.content.standard.html.ElementMatcher
import com.speechify.client.helpers.content.standard.html.plus

/**
 * Rules for extracting user-relevant content from HTML.
 */
internal class RelevantContentExtractionRules(
    val ignored: ElementMatcher.CompositeAnyOf? = null,
    val inline: ElementMatcher.CompositeAnyOf? = null,
) {
    /**
     * Combine with another set of rules.
     */
    operator fun plus(another: RelevantContentExtractionRules?): RelevantContentExtractionRules {
        return if (another == null) {
            this
        } else {
            RelevantContentExtractionRules(
                ignored + another.ignored,
                inline + another.inline,
            )
        }
    }
}
