package com.speechify.client.api

import kotlin.js.JsExport

/**
 * The types of Entities in the Speechify system
 */
@JsExport
enum class SpeechifyEntityType {
    AUDIOBOOK_CHAPTER,
    LIBRARY_ITEM,
    SCANNED_BOOK,
    FOLDER,
}
