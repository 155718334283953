package com.speechify.client.bundlers.content

import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.Searcher
import com.speechify.client.api.content.StandardViewWithIndex
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.epub.Epub
import com.speechify.client.api.content.epub.EpubV2
import com.speechify.client.api.content.startofmaincontent.StartOfMainContent
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.epub.EpubView
import com.speechify.client.api.content.view.epub.EpubViewAsWebPage
import com.speechify.client.api.content.view.epub.EpubViewV3
import com.speechify.client.api.content.view.speech.SpeechView
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.txt.PlainTextView
import com.speechify.client.api.content.view.web.WebPageView
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.services.audiobook.AudiobookChapter
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.reading.ContentTitleExtractor
import com.speechify.client.bundlers.reading.MutableObservableContentTitle
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.bundlers.reading.importing.TitleExtractingContentImporter
import com.speechify.client.helpers.content.index.ApproximateBookIndexV1
import com.speechify.client.helpers.content.index.ApproximateBookIndexV2
import com.speechify.client.helpers.content.index.EagerStandardIndex
import com.speechify.client.helpers.content.speech.StandardSpeechView
import com.speechify.client.helpers.content.standard.book.BookStandardView
import com.speechify.client.internal.services.book.PlatformBookPageContentStatsService
import com.speechify.client.internal.services.editing.BookEditingService
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

/**
 * A simple container of abstractions that provide views into the structure and information within a piece of content.
 */
@JsExport
sealed class ContentBundle(
    realContentImporter: ContentImporter,
    existingMutableObservableContentTitle: MutableObservableContentTitle? = null,
) : Destructible, ContentModelsImportDependencies {

    /**
     * The ContentBundlerOptions with which this bundle was created.
     */
    internal abstract val contentBundlerOptions: ContentBundlerOptions

    internal abstract val tableOfContentsFlow: StateFlow<TableOfContents?>

    internal abstract val startOfMainContentFlow: StateFlow<StartOfMainContent>

    // must use lazy here because these values depend on the `this` reference during the constructor
    // of this non-final class, and it's never safe to pass a `this` reference to another function during
    // the constructor

    private val _titleExtractor = lazy {
        ContentTitleExtractor(this)
    }

    private val _importer = lazy {
        TitleExtractingContentImporter.of(realContentImporter, _titleExtractor.value)
    }

    @Deprecated("Use `title` instead", ReplaceWith("title"))
    val titleExtractor get() = _titleExtractor.value

    /**
     * See [MutableObservableContentTitle] for semantics of this member.
     */
    val title: MutableObservableContentTitle = existingMutableObservableContentTitle ?: _titleExtractor.value.observable

    val importer: ContentImporter get() = _importer.value

    internal val coImporter: ContentImporter get() = _importer.value

    /**
     * A [StandardView] of the content from which the bundle was derived
     */
    abstract override val standardView: StandardView

    /**
     * A [SpeechView] of the content from which the bundle was derived
     */
    abstract val speechView: SpeechView

    /**
     * An index of the content from which the bundle was derived
     */
    abstract override val contentIndex: ContentIndex

    /**
     * A [Searcher] that scans this bundle for occurrences of a query.
     * `null` if searching is not supported for this content bundle.
     */
    internal abstract val searcher: Searcher?

    override fun destroy() {
        _titleExtractor.value.destroy()
    }

    /**
     * A bundle derived from Book-like content
     */
    class BookBundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        val bookView: BookView,
        internal val bookEditingService: BookEditingService,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?>,
        override val startOfMainContentFlow: StateFlow<StartOfMainContent>,
        override val searcher: Searcher?,
        contentImporter: ContentImporter,
        existingMutableObservableContentTitle: MutableObservableContentTitle? = null,
        internal val bookPageOCRRequirementFlow: MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>>,
        internal val platformBookPageContentStatsService: PlatformBookPageContentStatsService,
    ) : ContentBundle(contentImporter, existingMutableObservableContentTitle) {

        private var hasMovedDependencies = false
        internal fun createFromEditorByMovingDependencies(
            editor: BookEditor,
            contentBundlerOptions: ContentBundlerOptions,
            useApproximateBookIndexV2: Boolean,
        ): BookBundle {
            // Creates new bundle.
            val newBookView = EditingBookView(editor.currentState, editor.originalBookView)
            val newStandardView = BookStandardView(newBookView)
            val newBundle = BookBundle(
                editor.bookBundle.contentBundlerOptions,
                newBookView,
                bookEditingService,
                newStandardView,
                StandardSpeechView(newStandardView, contentBundlerOptions),
                contentIndex = when (useApproximateBookIndexV2) {
                    true -> ApproximateBookIndexV2(bookView, platformBookPageContentStatsService, importer)
                    false -> ApproximateBookIndexV1(bookView)
                },
                tableOfContentsFlow = editor.bookBundle.tableOfContentsFlow,
                startOfMainContentFlow = editor.bookBundle.startOfMainContentFlow,
                searcher = null,
                editor.bookBundle.coImporter,
                existingMutableObservableContentTitle = editor.bookBundle.title,
                bookPageOCRRequirementFlow = editor.bookBundle.bookPageOCRRequirementFlow,
                platformBookPageContentStatsService = platformBookPageContentStatsService,
            )

            // Destroying things that are not needed by the new bundle.
            super.destroy()
            speechView.destroy()

            // Marking this bundle so destroy() no longer destroys things.
            hasMovedDependencies = true
            return newBundle
        }

        fun createEditor(callback: Callback<BookEditor>) = callback.fromCoWithErrorLogging(
            sourceAreaId = "BookBundle.createEditor",
        ) {
            createEditor().successfully()
        }

        internal suspend fun createEditor(): BookEditor {
            return when (bookView) {
                is EditingBookView -> bookEditingService.createBookEditingInstance(this, bookView.bookEdits)
                else -> bookEditingService.createBookEditingInstance(this)
            }
        }

        override fun destroy() {
            if (hasMovedDependencies) return
            super.destroy()
            speechView.destroy()
            bookView.destroy()
            contentIndex.destroy()
            standardView.destroy()
        }
    }

    /**
     * A bundle derived from Audiobook Chapter
     */
    class AudioBookChapterBundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?> = MutableStateFlow(null),
        override val startOfMainContentFlow: StateFlow<StartOfMainContent> = MutableStateFlow(
            StartOfMainContent.NotAvailable,
        ),
        override val searcher: Searcher?,
        val audioBookChapter: AudiobookChapter,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {

        override fun destroy() {
            speechView.destroy()
            standardView.destroy()
            contentIndex.destroy()
        }
    }

    /**
     * A bundle derived from WebPage-like content
     */
    class WebPageBundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        internal val webPageView: WebPageView,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?> = MutableStateFlow(null),
        override val startOfMainContentFlow: StateFlow<StartOfMainContent> = MutableStateFlow(
            StartOfMainContent.NotAvailable,
        ),
        override val searcher: Searcher?,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
            // TODO(anson): add WebPageView.destroy()
        }
    }

    class EpubBundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        internal val epubViewAsWebPage: EpubViewAsWebPage,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?>,
        override val startOfMainContentFlow: StateFlow<StartOfMainContent>,
        override val searcher: Searcher?,
        internal val epub: Epub,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
            // TODO(anson): add WebPageView.destroy()
        }
    }

    class EpubV2Bundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        internal val epubView: EpubView,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?>,
        override val startOfMainContentFlow: StateFlow<StartOfMainContent>,
        override val searcher: Searcher?,
        internal val epubV2: EpubV2,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
        }
    }

    class EpubBundleV3 internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        internal val epubView: EpubViewV3,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?>,
        override val startOfMainContentFlow: StateFlow<StartOfMainContent>,
        override val searcher: Searcher?,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
        }
    }

    class PlainTextBundle internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        internal val plaintextView: PlainTextView,
        override val standardView: StandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?> = MutableStateFlow(null),
        override val startOfMainContentFlow: StateFlow<StartOfMainContent> = MutableStateFlow(
            StartOfMainContent.NotAvailable,
        ),
        override val searcher: Searcher?,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
        }
    }

    /**
     * A bundle derived from content that was already standard-formatted
     */
    class StandardBundle<out TStandardView : StandardView> internal constructor(
        override val contentBundlerOptions: ContentBundlerOptions,
        override val standardView: TStandardView,
        override val speechView: SpeechView,
        override val contentIndex: ContentIndex,
        override val tableOfContentsFlow: StateFlow<TableOfContents?> = MutableStateFlow(null),
        override val startOfMainContentFlow: StateFlow<StartOfMainContent> = MutableStateFlow(
            StartOfMainContent.NotAvailable,
        ),
        override val searcher: Searcher?,
        contentImporter: ContentImporter,
    ) : ContentBundle(contentImporter) {
        internal constructor(
            standardView: TStandardView,
            contentImporter: ContentImporter,
            contentBundlerOptions: ContentBundlerOptions,
        ) : this(
            contentBundlerOptions = contentBundlerOptions,
            standardView = standardView,
            speechView = StandardSpeechView(standardView, contentBundlerOptions),
            contentIndex = EagerStandardIndex(standardView),
            searcher = null,
            contentImporter = contentImporter,
        )

        companion object {
            internal fun <TStandardView : StandardViewWithIndex> createFromStandardViewWithIndex(
                standardViewWithIndex: TStandardView,
                contentImporter: ContentImporter,
                contentBundlerOptions: ContentBundlerOptions,
            ): StandardBundle<TStandardView> =
                StandardBundle(
                    contentBundlerOptions = contentBundlerOptions,
                    standardView = standardViewWithIndex,
                    speechView = StandardSpeechView(standardViewWithIndex, contentBundlerOptions),
                    contentIndex = standardViewWithIndex,
                    searcher = null,
                    contentImporter = contentImporter,
                )
        }

        override fun destroy() {
            super.destroy()
            speechView.destroy()
            contentIndex.destroy()
            standardView.destroy()
        }

        internal fun withContentIndex(
            block: (ContentIndex) -> ContentIndex,
        ): StandardBundle<TStandardView> =
            ContentBundle.StandardBundle(
                contentBundlerOptions = this.contentBundlerOptions,
                standardView = this.standardView,
                speechView = this.speechView,
                contentIndex = this.contentIndex.run(block),
                searcher = this.searcher,
                contentImporter = this.coImporter,
            )
    }
}

/**
 * Groups the APIs to the public content models.
 */
@JsExport
interface ContentModels {
    val standardView: StandardView
    val contentIndex: ContentIndex
}

/**
 * Groups the APIs to the content models that are used internally by import.
 */
internal interface ContentModelsImportDependencies :
/** Just [ContentModels], because that's what import currently only uses. */
        ContentModels

internal fun ContentBundle.withContentIndex(block: (ContentIndex) -> ContentIndex): ContentBundle {
    return when (this) {
        is ContentBundle.BookBundle -> ContentBundle.BookBundle(
            contentBundlerOptions = this.contentBundlerOptions,
            bookView = this.bookView,
            bookEditingService = this.bookEditingService,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            tableOfContentsFlow = this.tableOfContentsFlow,
            startOfMainContentFlow = this.startOfMainContentFlow,
            searcher = this.searcher,
            contentImporter = this.coImporter,
            existingMutableObservableContentTitle = this.title,
            bookPageOCRRequirementFlow = this.bookPageOCRRequirementFlow,
            platformBookPageContentStatsService = this.platformBookPageContentStatsService,
        )

        is ContentBundle.PlainTextBundle -> ContentBundle.PlainTextBundle(
            contentBundlerOptions = this.contentBundlerOptions,
            plaintextView = this.plaintextView,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            searcher = this.searcher,
            contentImporter = this.coImporter,
        )

        is ContentBundle.StandardBundle<*> -> this.withContentIndex(block)
        is ContentBundle.WebPageBundle -> ContentBundle.WebPageBundle(
            contentBundlerOptions = this.contentBundlerOptions,
            webPageView = this.webPageView,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            searcher = this.searcher,
            contentImporter = this.coImporter,
        )

        is ContentBundle.AudioBookChapterBundle -> ContentBundle.AudioBookChapterBundle(
            contentBundlerOptions = this.contentBundlerOptions,
            audioBookChapter = this.audioBookChapter,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            searcher = this.searcher,
            contentImporter = this.coImporter,
        )

        is ContentBundle.EpubBundle -> ContentBundle.EpubBundle(
            contentBundlerOptions = this.contentBundlerOptions,
            epubViewAsWebPage = this.epubViewAsWebPage,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            tableOfContentsFlow = this.tableOfContentsFlow,
            startOfMainContentFlow = this.startOfMainContentFlow,
            searcher = this.searcher,
            contentImporter = this.coImporter,
            epub = this.epub,
        )

        is ContentBundle.EpubV2Bundle -> ContentBundle.EpubV2Bundle(
            contentBundlerOptions = this.contentBundlerOptions,
            epubView = this.epubView,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            tableOfContentsFlow = this.tableOfContentsFlow,
            startOfMainContentFlow = this.startOfMainContentFlow,
            searcher = this.searcher,
            contentImporter = this.coImporter,
            epubV2 = this.epubV2,
        )

        is ContentBundle.EpubBundleV3 -> ContentBundle.EpubBundleV3(
            contentBundlerOptions = this.contentBundlerOptions,
            epubView = this.epubView,
            standardView = this.standardView,
            speechView = this.speechView,
            contentIndex = this.contentIndex.run(block),
            tableOfContentsFlow = this.tableOfContentsFlow,
            startOfMainContentFlow = this.startOfMainContentFlow,
            searcher = this.searcher,
            contentImporter = this.coImporter,
        )
    }
}
