package com.speechify.client.internal.util

/**
 * Represents the most primitive result of a 'try', which does not convey any explanation about reason for lack
 * of success (it's either not needed to report the details, or they were already reported to developers).
 * It's equivalent to a "Maybe" monad in functional programming, AKA "Option".
 *
 * NOTE: Use [kotlin.Result] for unhandled errors that have yet to be reported to developers with information allowing
 * to find where they occurred, i.e. stacktrace, etc.
 *
 * This one is especially useful for trying to get [Value]s where it may not exist, and a successful value can be null.
 */
internal sealed class TryGetResult<out Value> {
    class Success<Value>(val value: Value) : TryGetResult<Value>()
    object Unsuccessful : TryGetResult<Nothing>()
}

/**
 * Shorthand for non-nullable [Value].
 */
internal fun <Value> TryGetResult<Value>.getValueOrNull(): Value? = when (this) {
    is TryGetResult.Success -> value
    is TryGetResult.Unsuccessful -> null
}
