package com.speechify.client.api.audio

import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerAdapter
import com.speechify.client.api.adapters.mediaplayer.coCreateMediaPlayer
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.speech.Speech
import com.speechify.client.api.content.view.speech.slice
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.fromCoNoError
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.extensions.collections.flows.emitEnsuringReceivedOrBuffered

internal data class MediaUtterance(
    internal val mediaUrl: String,
    internal val speechMarks: SpeechMarks,
    override val speech: Speech,
    override val text: ContentText,
    override val synthesisLocation: SynthesisLocation,
    private val mediaPlayerFactory: LocalMediaPlayerAdapter,
    override val voiceMetadata: VoiceMetadata,
) : Utterance() {

    override fun slice(start: ContentCursor, end: ContentCursor): Utterance {
        val startIndex = text.getFirstIndexOfCursor(start)
        val endIndexExclusive = text.getLastIndexOfCursor(end) + 1
        return MediaUtterance(
            mediaUrl = mediaUrl,
            speechMarks = speechMarks.slice(
                startIndex = startIndex,
                endIndex = endIndexExclusive,
            ),
            speech = speech.slice(
                start = start,
                end = end,
            ),
            text = this.text.slice(
                startIndex = startIndex,
                endIndex = endIndexExclusive,
            ),
            synthesisLocation = synthesisLocation,
            mediaPlayerFactory = mediaPlayerFactory,
            voiceMetadata = voiceMetadata,
        )
    }

    override fun getPlayer(initialOptions: PlayerOptions, callback: Callback<Player>) = callback.fromCo {
        if (speechMarks.let { it.startTimeInMilliseconds == it.endTimeInMilliseconds }) {
            /** Returning a special 'empty player', if we end up in a position where the position has no audio left
             * because some [com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayer] implementations
             * throw an error if we try to play from the end, or even very close to it (for example the browser players
             * does this, as reported [here](https://linear.app/speechify-inc/issue/PLT-2916/[bug-report]-webapp-pdf-stops-playing-the-content-and-we-see-the-error)). */
            return@fromCo object : Player() {
                override val utterance: Utterance
                    get() = this@MediaUtterance

                override suspend fun getCurrentCursor(): ContentCursor =
                    utterance.speech.start

                override fun play() {
                    eventsSink.emitEnsuringReceivedOrBuffered(
                        PlayerEvent.Started(
                            utterance.speech.start,
                        ),
                    )
                    eventsSink.emitEnsuringReceivedOrBuffered(
                        PlayerEvent.Ended,
                    )
                }

                override fun stop() {
                }

                override fun seek(to: ContentCursor) {
                }

                private var options: PlayerOptions = initialOptions

                override fun updateOptions(newOptions: PlayerOptions) {
                    options = newOptions
                }

                override fun setSpeed(speed: Float) {
                    options = options.copy(speed = speed)
                }

                override fun setVolume(volume: Float) {
                    options = options.copy(volume = volume)
                }

                override fun getOptions(callback: (PlayerOptions) -> Unit) = callback.fromCoNoError {
                    options
                }

                override fun isPlaying(): Boolean =
                    false

                override fun destroy() {
                    eventsSink.emitEnsuringReceivedOrBuffered(PlayerEvent.Destroyed)
                }
            }
                .successfully()
        }

        return@fromCo MediaPlayer(
            mediaPlayer = mediaPlayerFactory.coCreateMediaPlayer(
                mediaUrl,
                initialOptions.toLocalPlayerOptions(),
            ).orReturn { return@fromCo it },
            utterance = this@MediaUtterance,
        )
            .successfully()
    }
}
