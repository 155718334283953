package com.speechify.client.internal.services.subscription

import com.speechify.client.api.adapters.http.HttpResponse
import com.speechify.client.api.adapters.http.HttpStatus
import com.speechify.client.api.adapters.http.consumeBodyAsByteArray
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.account.models.BillingData
import com.speechify.client.api.services.subscription.models.RenewalFrequency
import com.speechify.client.api.services.subscription.models.SubscriptionPricingResult
import com.speechify.client.api.services.subscription.transformers.toPlatformRenewalFrequency
import com.speechify.client.api.util.CreateSubscriptionErrorCode
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.asUnitResult
import com.speechify.client.internal.http.parse
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.subscription.models.FirebaseValidateReceiptResult
import com.speechify.client.internal.services.subscription.models.OneClickRenewalBody
import com.speechify.client.internal.services.subscription.models.PlatformCancelSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformCoupon
import com.speechify.client.internal.services.subscription.models.PlatformCreateAppleSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformCreateAppleSubscriptionErrorResponse
import com.speechify.client.internal.services.subscription.models.PlatformCreatePayPalSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformCreatePlayStoreSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformCreateStripeSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformCreateSubscriptionErrorResponse
import com.speechify.client.internal.services.subscription.models.PlatformCreateSubscriptionErrorResponseDetails
import com.speechify.client.internal.services.subscription.models.PlatformGetPortalUrlBody
import com.speechify.client.internal.services.subscription.models.PlatformGetPortalUrlResponse
import com.speechify.client.internal.services.subscription.models.PlatformGetRewardBalanceResponse
import com.speechify.client.internal.services.subscription.models.PlatformOneClickRenewalStatus
import com.speechify.client.internal.services.subscription.models.PlatformPreparePayPalSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformRestoreAppleSubscriptionBody
import com.speechify.client.internal.services.subscription.models.PlatformRestoreAppleSubscriptionResponse
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionAndEntitlementResponse
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionCreationResponse
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionPrepareResponse
import com.speechify.client.internal.services.subscription.models.PlatformUpdateBillingDataErrorResponse
import com.speechify.client.internal.services.subscription.models.PlatformValidateAppleReceiptBody
import com.speechify.client.internal.services.subscription.models.PlatformValidateCardCountryBody
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json

const val COUPONS = "coupons"
const val PORTAL = "portal"
const val SUBSCRIBE_WITH_STRIPE = "subscriptions"
const val VALIDATE_CARD_COUNTRY = "subscriptions/stripe/validate-card-country"
const val SUBSCRIBE_WITH_PAYPAL = "subscriptions/paypal"
const val PREPARE_WITH_PAYPAL = "subscriptions/paypal/prepareSubscription"
const val SUBSCRIBE_WITH_APPLE = "subscriptions/appstore/create"
const val RESTORE_FROM_APPLE = "subscriptions/appstore/restore"
const val VALIDATE_APPSTORE_RECEIPT = "subscriptions/appstore/validateReceipt"
const val SUBSCRIBE_WITH_PLAYSTORE = "subscriptions/playstore"
const val CANCEL_SUBSCRIPTION = "subscriptions/cancelSubscription"
const val ONE_CLICK_RENEWAL = "subscriptions/one-click-renew"
const val GET_ALL_SUBSCRIPTIONS_AND_ENTITLEMENTS = "subscriptions/getAllSubscriptionsAndEntitlements"
const val ONE_CLICK_RENEWAL_STATUS = "subscriptions/one-click-renew/status"
const val GET_REWARDS_BALANCE = "referrals/reward-balance"
const val EXTEND_TRIAL = "subscriptions/extend-trial"
const val SKIP_TRIAL = "subscriptions/skip-trial"
const val UPDATE_USER = "users/update"
const val UPDATE_BILLING_DATA = "users/update-billing-data"

// The currently supported API version
const val PLATFORM_API_VERSION = '1'

internal class PlatformFetcher(
    private val client: HttpClient,
    private val paymentServerBaseUrl: String,
    private val catalogServiceBaseUrl: String,
    private val authService: AuthService,
) {

    internal suspend fun getOneClickRenewalStatus(body: OneClickRenewalBody): Result<PlatformOneClickRenewalStatus> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }
        return client
            .get("$paymentServerBaseUrl/$ONE_CLICK_RENEWAL_STATUS") {
                header("Authorization", firebaseToken.token)
                if (body.stripePromotionCode != null) {
                    parameter("stripePromotionCode", body.stripePromotionCode)
                }
                if (body.stripePriceId != null) {
                    parameter("stripePriceId", body.stripePriceId)
                }
            }.parse()
    }

    internal suspend fun performOneClickRenew(body: OneClickRenewalBody): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }
        return client
            .post("$paymentServerBaseUrl/$ONE_CLICK_RENEWAL") {
                header("Authorization", firebaseToken.token)
                bodyJson(body)
            }.asUnitResult()
    }

    internal suspend fun checkCoupon(code: String): Result<PlatformCoupon> {
        return client
            .get("$paymentServerBaseUrl/$COUPONS") { parameter("code", code) }
            .parse()
    }

    internal suspend fun getPortalUrl(
        userId: String,
        extensionId: String,
    ): Result<PlatformGetPortalUrlResponse> {
        return client
            .post("$paymentServerBaseUrl/$PORTAL") { bodyJson(PlatformGetPortalUrlBody(userId, extensionId)) }
            .parse()
    }

    internal suspend fun preparePayPalSubscription(
        planId: String,
    ): Result<PlatformSubscriptionPrepareResponse> {
        val requestBody = PlatformPreparePayPalSubscriptionBody(planId)
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$PREPARE_WITH_PAYPAL") {
                header("Authorization", firebaseToken.token)
                bodyJson(requestBody)
            }
            .parse()
    }

    internal suspend fun createStripeSubscription(
        userId: String,
        email: String?,
        paymentMethodId: String,
        renewalFrequency: RenewalFrequency,
        coupon: String?,
        hasTrial: Boolean?,
        trialLength: Int? = 3,
        subscriptionCurrency: String? = null,
        planId: String? = null,
        confirmPaymentOnClient: Boolean? = false,
    ): Result<PlatformSubscriptionCreationResponse> {
        // TODO(roman): Result<Unit> is more applicable here PLT-899
        val requestBody = PlatformCreateStripeSubscriptionBody(
            userId,
            paymentMethodId,
            toPlatformRenewalFrequency(renewalFrequency),
            email,
            hasTrial,
            coupon,
            trialLength ?: 3,
            subscriptionCurrency,
            planId,
            confirmPaymentOnClient,
        )
        val result = client
            .post("$paymentServerBaseUrl/$SUBSCRIBE_WITH_STRIPE") { bodyJson(requestBody) }

        val response = result.orReturn { return it }

        if (response.ok) {
            return response.parse()
        }

        return handleStripeCreationError(
            response,
            bodyAsByteArray = response.consumeBodyAsByteArray(
                sourceAreaIdForInefficienciesWarnings = "PlatformFetcher.createStripeSubscription",
            )
                .orReturn { return it },
        )
    }

    internal suspend fun validateCardCountry(
        paymentMethodId: String,
        renewalFrequency: RenewalFrequency,
        subscriptionCurrency: String,
    ): Result<Unit> {
        val requestBody = PlatformValidateCardCountryBody(
            paymentMethodId,
            toPlatformRenewalFrequency(renewalFrequency),
            subscriptionCurrency,
        )
        val result = client
            .post("$paymentServerBaseUrl/$VALIDATE_CARD_COUNTRY") { bodyJson(requestBody) }

        val response = result.orReturn { return it }

        if (response.ok) {
            return response.parse()
        }

        return handleStripeCreationError(
            response,
            bodyAsByteArray = response.consumeBodyAsByteArray(
                sourceAreaIdForInefficienciesWarnings = "PlatformFetcher.validateCardCountry",
            )
                .orReturn { return it },
        )
    }

    internal suspend fun createPayPalSubscription(
        userId: String,
        subscriptionId: String,
        renewalFrequency: RenewalFrequency,
        email: String?,
        hasTrial: Boolean?,
    ): Result<PlatformSubscriptionCreationResponse> {
        // TODO(roman): Result<Unit> is more applicable here PLT-899
        val requestBody = PlatformCreatePayPalSubscriptionBody(
            userId,
            subscriptionId,
            toPlatformRenewalFrequency(renewalFrequency),
            email,
            hasTrial,
        )
        return client
            .post("$paymentServerBaseUrl/$SUBSCRIBE_WITH_PAYPAL") { bodyJson(requestBody) }
            .parse()
    }

    internal suspend fun createPlayStoreSubscription(
        userId: String,
        planId: String,
        purchaseToken: String,
    ): Result<PlatformSubscriptionCreationResponse> {
        val requestBody = PlatformCreatePlayStoreSubscriptionBody(
            userId,
            planId,
            purchaseToken,
        )
        return client
            .post("$paymentServerBaseUrl/$SUBSCRIBE_WITH_PLAYSTORE") { bodyJson(requestBody) }
            .parse()
    }

    internal suspend fun createAppleSubscription(
        receipt: String,
    ): Result<PlatformSubscriptionCreationResponse> {
        val requestBody = PlatformCreateAppleSubscriptionBody(receipt)

        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        val result = client
            .post("$paymentServerBaseUrl/$SUBSCRIBE_WITH_APPLE") {
                header("Authorization", firebaseToken.token)
                bodyJson(requestBody)
            }

        val response = result.orReturn { return it }

        if (!response.ok && response.status != HttpStatus.NOT_MODIFIED.status) {
            return Result.Failure(
                handleAppStoreError(
                    response = response,
                    bodyAsByteArray = response.consumeBodyAsByteArray(
                        sourceAreaIdForInefficienciesWarnings = "PlatformFetcher.createAppleSubscription",
                    )
                        .orReturn { return it },
                ),
            )
        }

        return result.parse()
    }

    internal suspend fun restoreAppleSubscription(
        receipt: String,
        allowTransferringSubscription: Boolean,
    ): Result<Unit> {
        val requestBody = PlatformRestoreAppleSubscriptionBody(
            receipt,
            allowTransferringSubscription,
        )

        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        val result = client
            .post("$paymentServerBaseUrl/$RESTORE_FROM_APPLE") {
                header("Authorization", firebaseToken.token)
                bodyJson(requestBody)
            }

        val response = result.orReturn { return it }

        if (!response.ok) {
            return if (response.status == HttpStatus.NOT_MODIFIED.status) {
                Result.Success(Unit)
            } else {
                Result.Failure(
                    handleAppStoreError(
                        response,
                        bodyAsByteArray = response.consumeBodyAsByteArray(
                            sourceAreaIdForInefficienciesWarnings = "PlatformFetcher.restoreAppleSubscription",
                        )
                            .orReturn { return it },
                    ),
                )
            }
        }

        val parsedResponse = response.parse<PlatformRestoreAppleSubscriptionResponse>().orReturn { return it }

        return if (parsedResponse.isValid) {
            Unit.successfully()
        } else {
            Result.Failure(
                SDKError.CreateSubscriptionError(
                    parsedResponse.message,
                    CreateSubscriptionErrorCode.INVALID_SUBSCRIPTION,
                    parsedResponse.subscriptionId,
                ),
            )
        }
    }

    internal suspend fun validateAppStoreReceipt(receipt: String): Result<FirebaseValidateReceiptResult> {
        val requestBody = PlatformValidateAppleReceiptBody(receipt)

        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$VALIDATE_APPSTORE_RECEIPT") {
                header("Authorization", firebaseToken.token)
                bodyJson(requestBody)
            }.parse()
    }

    internal suspend fun getSubscriptionPricing(
        renewalFrequency: RenewalFrequency,
        currency: String?,
    ): Result<SubscriptionPricingResult> {
        val period = when (renewalFrequency) {
            RenewalFrequency.YEARLY -> "annual"
            RenewalFrequency.MONTHLY -> "monthly"
            RenewalFrequency.QUARTERLY -> "quarterly"
            RenewalFrequency.WEEKLY -> "weekly"
        }
        return client.get("$catalogServiceBaseUrl/subscriptions/pricing") {
            parameter("period", period)
            if (currency != null) {
                parameter("currency", currency)
            }
        }.parse()
    }

    internal suspend fun extendTrial(): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$EXTEND_TRIAL") {
                header("Authorization", firebaseToken.token)
            }.parse()
    }

    internal suspend fun skipTrial(): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$SKIP_TRIAL") {
                header("Authorization", firebaseToken.token)
            }.parse()
    }

    internal suspend fun getRewardBalance(): Result<PlatformGetRewardBalanceResponse> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }
        return client
            .get("$paymentServerBaseUrl/$GET_REWARDS_BALANCE") {
                header("Authorization", firebaseToken.token)
            }.parse()
    }

    internal suspend fun updateUser(): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }
        return client
            .post("$paymentServerBaseUrl/$UPDATE_USER") {
                header("Authorization", firebaseToken.token)
            }.asUnitResult()
    }

    private fun handleAppStoreError(
        response: HttpResponse,
        bodyAsByteArray: ByteArray?,
    ): SDKError {
        // If we reach this point the server returned a 4XX status, and we need to parse it.
        try {
            val parsed = Json {
                ignoreUnknownKeys = true
            }.decodeFromString<PlatformCreateAppleSubscriptionErrorResponse>(bodyAsByteArray!!.decodeToString())
            return SDKError.CreateSubscriptionError(
                parsed.message,
                CreateSubscriptionErrorCode.enumValue(parsed.detail.code),
                parsed.detail.otherEmail,
            )
        } catch (e: SerializationException) {
            return SDKError.Serialization(
                e,
                bodyAsByteArray ?: "",
                PlatformCreateAppleSubscriptionErrorResponse::class,
            )
        }
    }

    private fun <T> handleStripeCreationError(
        response: HttpResponse,
        bodyAsByteArray: ByteArray?,
    ): Result<T> {
        // If we reach this point the server returned a 4XX status, and we need to parse it.
        try {
            val parsed = Json {
                ignoreUnknownKeys = true
            }.decodeFromString<PlatformCreateSubscriptionErrorResponse>(bodyAsByteArray!!.decodeToString())

            val detail = parsed.detail
                ?: return Result.Failure(SDKError.HttpError(response.status, bodyAsByteArray.decodeToString()))

            if (detail.code == "CURRENCY_MISMATCH") {
                val isoCurrency = detail.currencyISO ?: return buildMissingFieldFailure("currencyISO")
                val priceCents = detail.priceCents ?: return buildMissingFieldFailure("priceCents")

                return Result.Failure(
                    SDKError.WrongCurrencyError(
                        isoCurrency,
                        priceCents,
                    ),
                )
            }

            return Result.Failure(SDKError.HttpError(response.status, bodyAsByteArray.decodeToString()))
        } catch (e: SerializationException) {
            return Result.Failure(
                SDKError.Serialization(
                    e,
                    bodyAsByteArray ?: "",
                    PlatformCreateSubscriptionErrorResponseDetails::class,
                ),
            )
        }
    }

    internal suspend fun cancelSubscription(subscriptionId: String? = null): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$CANCEL_SUBSCRIPTION") {
                header("Authorization", firebaseToken.token)
                if (subscriptionId != null) {
                    bodyJson(PlatformCancelSubscriptionBody(subscriptionId))
                }
            }.asUnitResult()
    }

    internal suspend fun updateBillingData(billingData: BillingData): Result<Unit> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .post("$paymentServerBaseUrl/$UPDATE_BILLING_DATA") {
                header("Authorization", firebaseToken.token)
                bodyJson(billingData)
            }.asUnitResult().mapFailure { originalError ->
                if (originalError is SDKError.HttpError && originalError.response != null) {
                    val error = originalError.response.parse<PlatformUpdateBillingDataErrorResponse>().orReturn {
                        Log.e(
                            error = it.error,
                            message = "Couldn't parse update billing error",
                            sourceAreaId = "PlatformFetcher.updateBillingData",
                        )
                        return@mapFailure originalError
                    }
                    SDKError.InvalidBillingDataError(
                        error.detail?.code
                            ?: error.detail?.error
                            ?: "Unknown Error",
                        originalError,
                    )
                } else {
                    originalError
                }
            }
    }

    internal suspend fun getAllSubscriptionsAndEntitlements():
        Result<PlatformSubscriptionAndEntitlementResponse?> {
        val firebaseToken = this.authService.getCurrentUserIdentityToken().orReturn { return it }

        return client
            .get("$paymentServerBaseUrl/$GET_ALL_SUBSCRIPTIONS_AND_ENTITLEMENTS") {
                header("Authorization", firebaseToken.token)
                header("x-api-version", "$PLATFORM_API_VERSION")
            }.parse<PlatformSubscriptionAndEntitlementResponse>()
    }
}
