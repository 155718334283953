package com.speechify.client.api.content.startofmaincontent

import com.speechify.client.api.services.library.models.LibraryStartOfMainContent

internal sealed class RawStartOfMainContent {
    internal data class Epub(
        val filename: String,
        val fragmentId: String?,
    ) : RawStartOfMainContent()
}

internal fun LibraryStartOfMainContent.toRawStartOfMainContent(): RawStartOfMainContent = when (this) {
    is LibraryStartOfMainContent.Epub -> RawStartOfMainContent.Epub(
        filename = filename,
        fragmentId = nodeId,
    )
}
