package com.speechify.client.api.services.audio

import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

/**
 * The max wait time for a speech synthesis request to complete.
 *
 * NOTE: Setting this to too high will impair the user experience of 'switching to local voice when no internet'.
 */
internal val SpeechSynthesisRequestTimeout: Duration = 5.seconds
