package com.speechify.client.api.adapters.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.Transacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlPreparedStatement
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.sqldelight.Database
import org.khronos.webgl.Int8Array
import org.khronos.webgl.Uint8Array
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * This class is used to create a SqlDriver for NodeJS.
 * The implementation is designed to work with the sqlite3 module.
 */
@JsExport
abstract class SqlDriverFactoryForNodeJsBase : AbstractSqlDriverFactory() {

    override suspend fun createSqlDriver(localKeyValueStorageAdapter: LocalKeyValueStorageAdapter): SqlDriver {
        return SqlDriverForNodeJS(this).also {
            Database.Schema.create(it).await()
        }
    }

    abstract fun closeDb()

    abstract fun exec(sql: String, params: Array<Any?>, callback: Callback<Array<Array<dynamic>>>)

    abstract fun beginTransaction()

    abstract fun endTransaction()

    abstract fun rollbackTransaction()
}

class SqlDriverForNodeJS(private val adapter: SqlDriverFactoryForNodeJsBase) : SqlDriver {
    private var transaction: NodeJsTransaction? = null
    private val listeners = mutableMapOf<String, MutableSet<Query.Listener>>()

    override fun newTransaction(): QueryResult<Transacter.Transaction> = QueryResult.AsyncValue {
        val enclosing = transaction
        val transaction = NodeJsTransaction(enclosing)
        this.transaction = transaction
        if (enclosing == null) {
            adapter.beginTransaction()
        }

        return@AsyncValue transaction
    }

    override fun currentTransaction(): Transacter.Transaction? = transaction

    override fun execute(
        identifier: Int?,
        sql: String,
        parameters: Int,
        binders: (SqlPreparedStatement.() -> Unit)?,
    ): QueryResult<Long> {
        val bound = NodeJsSqlPreparedStatement()
        binders?.invoke(bound)

        return QueryResult.AsyncValue {
            val response = coExec(sql, bound.parameters)
            return@AsyncValue when {
                response.isEmpty() -> 0L
                else -> response[0][0].unsafeCast<Double>().toLong()
            }
        }
    }

    override fun <R> executeQuery(
        identifier: Int?,
        sql: String,
        mapper: (SqlCursor) -> QueryResult<R>,
        parameters: Int,
        binders: (SqlPreparedStatement.() -> Unit)?,
    ): QueryResult<R> {
        val bound = NodeJsSqlPreparedStatement()
        binders?.invoke(bound)
        return QueryResult.AsyncValue {
            val response = coExec(sql, bound.parameters)
            return@AsyncValue mapper(NodeJsSqlCursor(response[0])).await()
        }
    }

    private suspend fun coExec(
        sql: String,
        params: Array<Any?>,
    ): Array<Array<dynamic>> =
        suspendCoroutine { cont ->
            adapter.exec(sql, params, cont::resume)
        }
            .orThrow()

    override fun addListener(vararg queryKeys: String, listener: Query.Listener) {
        queryKeys.forEach {
            listeners.getOrPut(it) { mutableSetOf() }.add(listener)
        }
    }

    override fun removeListener(vararg queryKeys: String, listener: Query.Listener) {
        queryKeys.forEach {
            listeners[it]?.remove(listener)
        }
    }

    override fun notifyListeners(vararg queryKeys: String) {
        queryKeys.flatMap { listeners[it].orEmpty() }
            .distinct()
            .forEach(Query.Listener::queryResultsChanged)
    }

    override fun close() {
        adapter.closeDb()
    }

    inner class NodeJsTransaction(override val enclosingTransaction: NodeJsTransaction?) : Transacter.Transaction() {
        override fun endTransaction(successful: Boolean): QueryResult<Unit> = QueryResult.AsyncValue {
            if (enclosingTransaction == null) {
                if (successful) {
                    adapter.endTransaction()
                } else {
                    adapter.rollbackTransaction()
                }
            }
            transaction = enclosingTransaction
        }
    }
}

private class NodeJsSqlPreparedStatement : SqlPreparedStatement {

    private val _parameters = mutableListOf<Any?>()
    val parameters
        get() = _parameters.toTypedArray()

    override fun bindBytes(index: Int, bytes: ByteArray?) {
        _parameters.add(bytes)
    }

    override fun bindLong(index: Int, long: Long?) {
        // We convert Long to Double because Kotlin's Double is mapped to JS number
        // whereas Kotlin's Long is implemented as a JS object
        _parameters.add(long?.toDouble())
    }

    override fun bindDouble(index: Int, double: Double?) {
        _parameters.add(double)
    }

    override fun bindString(index: Int, string: String?) {
        _parameters.add(string)
    }

    override fun bindBoolean(index: Int, boolean: Boolean?) {
        _parameters.add(boolean)
    }
}

private class NodeJsSqlCursor(private val values: Array<Array<dynamic>>) : SqlCursor {
    private var currentRow = -1

    override fun next(): QueryResult.Value<Boolean> = QueryResult.Value(++currentRow < values.size)

    override fun getString(index: Int): String? = values[currentRow][index].unsafeCast<String?>()

    override fun getLong(index: Int): Long? = (values[currentRow][index] as? Double)?.toLong()

    override fun getBytes(index: Int): ByteArray? =
        (values[currentRow][index] as? Uint8Array)?.let { Int8Array(it.buffer).unsafeCast<ByteArray>() }

    override fun getDouble(index: Int): Double? = values[currentRow][index].unsafeCast<Double?>()

    override fun getBoolean(index: Int): Boolean? = values[currentRow][index].unsafeCast<Boolean?>()
}
