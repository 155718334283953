package com.speechify.client.api.adapters.json

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.toMap
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlin.js.JsExport
import kotlin.reflect.KClass

@JsExport
interface JsonElementProvider {
    /**
     * This method is for internal use only, and should not be called.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    fun toJsonElement(): JsonElement
}

@JsExport
/**
 * This class serves as a cross-platform representation of parsed JSON content. This is used to allow clients
 * provide us data without going through a lossy data -> JSON string -> data conversion. Instead, the data is turned
 * directly into JSON primitives, for example by recursively iterating through the data and mapping it to the
 * appropriate JSON primitive.
 * The alternative to passing data to SDK is to use the [BoundaryRecord]. This allows you to use platform specific
 * data types, and tries to handle this mapping for you. In general [BoundaryRecord] is the preferred way to pass data
 * and to use this class only to implement [BoundaryRecord.mapUnsupportedValue].
 */
sealed class SpeechifyJsonElement : JsonElementProvider {

    data class SpeechifyJsonPrimitive internal constructor(
        val value: String,
        internal val type: KClass<*>,
    ) : SpeechifyJsonElement() {
        companion object {
            fun fromString(value: String): SpeechifyJsonPrimitive {
                return SpeechifyJsonPrimitive(value, value::class)
            }

            fun fromBoolean(value: Boolean): SpeechifyJsonPrimitive {
                return SpeechifyJsonPrimitive(value.toString(), value::class)
            }

            fun fromInt(value: Int): SpeechifyJsonPrimitive {
                return SpeechifyJsonPrimitive(value.toString(), value::class)
            }

            fun fromDouble(value: Double): SpeechifyJsonPrimitive {
                return SpeechifyJsonPrimitive(value.toString(), value::class)
            }
        }
    }

    data class SpeechifyJsonArray(val items: Array<SpeechifyJsonElement>) : SpeechifyJsonElement() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as SpeechifyJsonArray

            if (!items.contentEquals(other.items)) return false

            return true
        }

        override fun hashCode(): Int {
            return items.contentHashCode()
        }
    }

    data class SpeechifyJsonObject constructor(val items: BoundaryMap<SpeechifyJsonElement>) : SpeechifyJsonElement()

    @Suppress("NON_EXPORTABLE_TYPE")
    data class SpeechifyFirebaseTimestamp(val seconds: Long, val nanoseconds: Int) : SpeechifyJsonElement()

    object SpeechifyJsonNull : SpeechifyJsonElement()

    /**
     * This method is for internal use only, and should not be called.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override fun toJsonElement(): JsonElement {
        return when (this) {
            is SpeechifyJsonPrimitive -> when (this.type) {
                String::class -> JsonPrimitive(this.value)
                Boolean::class -> JsonPrimitive(this.value.toBoolean())
                Double::class -> JsonPrimitive(this.value.toDouble())
                Int::class -> JsonPrimitive(this.value.toInt())
                else -> throw IllegalArgumentException("Unsupported type: ${this.type}")
            }
            is SpeechifyJsonArray -> JsonArray(this.items.map { it.toJsonElement() })
            is SpeechifyJsonObject -> JsonObject(this.items.toMap().mapValues { it.value.toJsonElement() })
            is SpeechifyFirebaseTimestamp -> JsonObject(
                mapOf(
                    "seconds" to JsonPrimitive(this.seconds),
                    "nanoseconds" to JsonPrimitive(this.nanoseconds),
                ),
            )
            SpeechifyJsonNull -> JsonNull
        }
    }
}
