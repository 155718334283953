package com.speechify.client.api.util.collections.flows

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.internal.util.collections.flows.flowFromCallbackProducer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.shareIn
import kotlin.js.JsExport

/**
 * Represents a possibly-changing, asynchronously-produced, value coming from SDK consumers, and readable by SDK.
 * Similar to [StateFlowFromCallback], but without the requirement to return the initial value immediately. First
 * value can be asynchronous like the subsequent ones.
 * This can be used for example to ask the user for the initial value only when needed.
 *
 * In Kotlin's idioms, this is closest to a [kotlinx.coroutines.flow.SharedFlow], because there the initial value is
 * also not required to be returned immediately (in contrast to [kotlinx.coroutines.flow.StateFlow]). But this type is
 * more "a [kotlinx.coroutines.flow.Flow] that is the building-block for constructing a [kotlinx.coroutines.flow.SharedFlow]",
 * which should be done using the provided [stateIn], with the desired replay size and [SharingStarted] strategy decided
 * by SDK developers, while taking into account contract for the specific use (e.g. how multiple calls to [getValueAndSubscribeAndGetCancel]
 * behave - did the contract with implementation specify any implicit replay behavior, e.g. the first item returns the
 * previous call's last returned value, or does it treat each call as a new establishment of value, e.g. asking the user
 * again).
 */
@JsExport
abstract class SharedFlowFromCallback<T> {

    @JsExport.Ignore
    internal fun shareIn(
        replay: Int = 0,
        started: SharingStarted,
        /**
         * The [DiagnosticEvent.sourceAreaId] to use for logging, to be able to identify the source of the error.
         */
        sourceAreaId: String,
        diagnosticProperties: Map<String, Any>? = null,
        scope: CoroutineScope,
    ) =
        flowFromCallbackProducer(
            callbackBasedProducer = this@SharedFlowFromCallback::getValueAndSubscribeAndGetCancel,
            shouldLogErrorIfCancellationPreventedDelivery = true,
            sourceAreaId = sourceAreaId,
            diagnosticProperties =
            mapOf(
                "SharedFlowFromCallback.actualType" to (this::class.simpleName ?: this.toString()),
            ) +
                diagnosticProperties.orEmpty(),
        )
            .shareIn(
                scope = scope,
                started = started,
                replay = replay,
            )

    /**
     * This function should notify the [receiveItem] of the current connectivity status.
     * NOTE: It is required that the [receiveItem] calls are never made in parallel (this is the only thread-safe way to
     * be able to ensure that a later call does not override an earlier one).
     *
     * @return a function that, when called, cancels the subscription - it should make the component stop calling
     * [receiveItem] and lose the reference to that function (to avoid memory leaks).
     */

    protected abstract fun getValueAndSubscribeAndGetCancel(
        /**
         * For the implementor: This function should initiate any uninitiated processes that will lead to determining
         * the first value, call [receiveItem] when it becomes available, and then every time the value changes (if the
         * value never changes, call it only once, and return a no-op function).
         */
        receiveItem: CallbackNoError<T>,
    ): Destructor
}

/**
 * An Implementation of [SharedFlowFromCallback] that always returns the same value.
 */
@JsExport
class SharedFlowFromCallbackWithStaticValue<T>(
    private val staticValue: T,
) : SharedFlowFromCallback<T>() {
    override fun getValueAndSubscribeAndGetCancel(
        receiveItem: CallbackNoError<T>,
    ): Destructor {
        receiveItem(staticValue)
        return {}
    }
}
