package com.speechify.client.helpers.content.standard.html

import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.util.extensions.collections.filterNotNullSecond
import kotlin.js.JsExport

/**
 * Carries additional information about the HTML content being loaded that can enhance user experience.
 */
@JsExport
class HtmlContentLoadOptions(
    /**
     * The URL from which the HTML was loaded. This is used to:
     * - allow the user to open the original URL in the browser
     * - enhance speaking of just the relevant content `null` if the URL is unknown
     */
    val sourceUrl: String?,

    /**
     * `true` when the HTML constitutes the entire document (with `<body>`, etc.). `false` when it's a fragment of it,
     * `null` if it's unknown.
     *
     * See also [com.speechify.client.internal.services.importing.models.RecordProperties.htmlIsEntireDocument]
     */
    val isEntireDocument: Boolean?,

    /**
     * `true` when the HTML was extracted from after executing JavaScript
     * (e.g. from a browser window or Android Preview), `null` if it's unknown.
     *
     * See [com.speechify.client.internal.services.importing.models.RecordProperties.htmlIsPostJavaScriptExecution]
     */
    val isPostJavaScriptExecution: Boolean?,

    /**
     * `true` when the HTML already had the content extracted by Speechify (AKA was 'parsed'), `null` if it's unknown.
     *
     * See also [com.speechify.client.internal.services.importing.models.RecordProperties.htmlIsPostContentExtraction]
     */
    val isPostContentExtraction: Boolean?,
)

internal fun HtmlContentLoadOptions.asRecordProperties(): Sequence<Pair<String, Any>> = sequenceOf(
    RecordProperties.htmlIsEntireDocument.key
        .toPairWithVal(isEntireDocument),
    RecordProperties.htmlIsPostJavaScriptExecution.key
        .toPairWithVal(isPostJavaScriptExecution),
    RecordProperties.htmlIsPostContentExtraction.key
        .toPairWithVal(isPostContentExtraction),
)
    .filterNotNullSecond()
