package com.speechify.client.api.services.subscription

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.adapters.keyvalue.coGetSingle
import com.speechify.client.api.adapters.keyvalue.coPutSingle
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.subscription.models.BillingDashboardOptions
import com.speechify.client.api.services.subscription.models.Coupon
import com.speechify.client.api.services.subscription.models.Entitlements
import com.speechify.client.api.services.subscription.models.OneClickRenewal
import com.speechify.client.api.services.subscription.models.OneClickRenewalStatus
import com.speechify.client.api.services.subscription.models.RenewalFrequency
import com.speechify.client.api.services.subscription.models.RewardBalance
import com.speechify.client.api.services.subscription.models.StripeSubscriptionPaymentIntent
import com.speechify.client.api.services.subscription.models.Subscription
import com.speechify.client.api.services.subscription.models.SubscriptionAndEntitlements
import com.speechify.client.api.services.subscription.models.SubscriptionCreationResponse
import com.speechify.client.api.services.subscription.models.SubscriptionPlan
import com.speechify.client.api.services.subscription.models.SubscriptionPreparation
import com.speechify.client.api.services.subscription.models.SubscriptionPricingResult
import com.speechify.client.api.services.subscription.models.SubscriptionPurchase
import com.speechify.client.api.services.subscription.models.SubscriptionRestore
import com.speechify.client.api.services.subscription.models.SubscriptionSource
import com.speechify.client.api.services.subscription.models.SubscriptionStatus
import com.speechify.client.api.services.subscription.models.SubscriptionValidation
import com.speechify.client.api.services.subscription.models.ValidateReceiptResult
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.Service
import com.speechify.client.api.util.asThrowingFlow
import com.speechify.client.api.util.isCausedByConnectionError
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.success
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toNullSuccessIfResourceNotFound
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.subscription.PlatformCouponTransformer
import com.speechify.client.internal.services.subscription.PlatformDataTransformer
import com.speechify.client.internal.services.subscription.PlatformFetcher
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataFetcher
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataTransformer
import com.speechify.client.internal.services.subscription.models.FirebaseSubscriptionStatus
import com.speechify.client.internal.services.subscription.models.FirebaseUserAudiobookCredits
import com.speechify.client.internal.services.subscription.models.FirebaseUserHdWords
import com.speechify.client.internal.services.subscription.models.FirebaseUserRewards
import com.speechify.client.internal.services.subscription.models.OneClickRenewalBody
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.merge
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

internal class SubscriptionServiceDelegate internal constructor(
    private val clientConfig: ClientConfig,
    private val authService: AuthService,
    private val subscriptionsFirebaseDataFetcher: SubscriptionsFirebaseDataFetcher,
    private val platformFetcher: PlatformFetcher,
    private val localKeyValueStorageAdapter: LocalKeyValueStorageAdapter,
) : Service {

    /**
     * Note: querying this will return the cached value if the actual does not arrive first.
     */
    private val entitlementsCachedCrossUserListeners = CrossUserChangeMutablePropertyListeners(
        currentUserIdStateFlow = authService.currentUserIdOrNullFlow,
        getPropertyValuesFlowForUserId = entitlementsProducer(
            subscriptionsFirebaseDataFetcher,
            this,
            localKeyValueStorageAdapter,

        ) { userDataForEntitlements ->
            val userRewards = userDataForEntitlements.userRewards.orDefaultWith {
                Log.e(
                    error = it,
                    message = "Failed to get user rewards.",
                    sourceAreaId = "SubscriptionServiceDelegate.entitlementsProducer",
                )
                null
            }
                ?: FirebaseUserRewards(0)

            val fallbackEntitlements = Entitlements.NONE.copy(
                hdWordsLeft = userRewards.premiumWords,
            ).successfully()

            val subscriptionAndEntitlements = userDataForEntitlements.userDataForSubscription?.orDefaultWith {
                null
            } ?: return@entitlementsProducer fallbackEntitlements

            val audiobookCredits = userDataForEntitlements.audiobookCredits
                .toNullSuccessIfResourceNotFound()
                .orDefaultWith {
                    Log.e(
                        error = it,
                        message = "Failed to get user audio book credits in entitlements listener.",
                        sourceAreaId = "SubscriptionServiceDelegate.entitlementsProducer",
                    )
                    null
                }

            val userHdWords = userDataForEntitlements.userHdWords
                .let {
                    // If a user has no subscriptions not having userHdWords is not an error.
                    if (subscriptionAndEntitlements.subscriptions.isEmpty()) {
                        it.toNullSuccessIfResourceNotFound()
                    } else {
                        it
                    }
                }
                .orDefaultWith {
                    Log.e(
                        error = it,
                        message = "Failed to get user HD words in entitlements listener.",
                        sourceAreaId = "SubscriptionServiceDelegate.entitlementsCachedCrossUserListeners",
                    )
                    null
                } ?: FirebaseUserHdWords(0, null, 0)

            val entitlements = subscriptionAndEntitlements.entitlements
            return@entitlementsProducer entitlements.copy(
                hdWordsLeft = userHdWords.hdWordsLeft + userRewards.premiumWords,
                audiobookCreditsLeft = audiobookCredits?.creditsLeft ?: 0,
                lastAudiobookCreditsGrantDate = audiobookCredits?.lastGrantedAt?.toIsoString(),
            ).successfully()
        },
    )

    /**
     * Note: querying this will return the cached value if the actual does not arrive first.
     */
    private val subscriptionsCachedCrossUserListeners = CrossUserChangeMutablePropertyListeners(
        currentUserIdStateFlow = authService.currentUserIdOrNullFlow,
        getPropertyValuesFlowForUserId = subscriptionProducer(
            subscriptionsFirebaseDataFetcher,
            this,
            localKeyValueStorageAdapter,
        ) { subscriptionAndEntitlements ->
            val subscriptionsAndEntitlements = subscriptionAndEntitlements?.orDefaultWith {
                null
            } ?: return@subscriptionProducer arrayOf<Subscription>().successfully()

            return@subscriptionProducer subscriptionsAndEntitlements.subscriptions.successfully()
        },
    )

    /**
     * Returns the primary subscription for the current user.
     * A primary subscription is the first on the list from the [getAllSubscriptions] return.
     */
    internal suspend fun getPrimarySubscription(): Result<Subscription?> {
        // Check if user is authenticated
        authService.getCurrentUser().orReturn { return it }

        // Get all the subscriptions and return the latest
        val subscriptionsAndEntitlements = this.getAllSubscriptions()
        if (subscriptionsAndEntitlements == null || subscriptionsAndEntitlements.subscriptions.isEmpty()) {
            return Result.Success(null)
        }

        val subscription = subscriptionsAndEntitlements.subscriptions.firstOrNull()
        return Result.Success(subscription)
    }

    /**
     * Returns all subscriptions and entitlements for the current user.
     * Subscription are unique by the products they are associated with.
     * For instance, a user might have a subscription for VoiceOver and one for TTS.
     */
    internal suspend fun getAllSubscriptions(): SubscriptionAndEntitlements? {
        val userId = authService.getCurrentUser().orThrow().uid
        val result = platformFetcher.getAllSubscriptionsAndEntitlements()
            .orDefaultWith {
                // In case we fail with a connection error, we can try to fetch the subscription from the cache.
                if (it.isCausedByConnectionError()) {
                    return localKeyValueStorageAdapter.getCachedSubscriptionResponse(
                        userId,
                    )
                } else {
                    null
                }
            }
        if (result != null) {
            return PlatformDataTransformer.parseSubscriptionAndEntitlementsResponse(result).also {
                localKeyValueStorageAdapter.cacheSubscriptionResponse(it, userId)
            }
        }

        return null
    }

    internal suspend fun getEntitlementsFromBackend(): Result<Entitlements> =
        getEntitlementsFromBackend(userId = authService.getCurrentUser().orReturn { return it }.uid)

    private suspend fun getEntitlementsFromBackend(userId: UserId): Result<Entitlements> {
        val userRewards = subscriptionsFirebaseDataFetcher.getUserRewards(userId)
            .orDefaultWith { failure ->
                Log.e(error = failure, sourceAreaId = "SubscriptionServiceDelegate.getEntitlementsFromBackend")
                null
            } ?: FirebaseUserRewards(0)

        val subscriptionsAndEntitlements = this.getAllSubscriptions()
            ?: return Entitlements.NONE.copy(
                hdWordsLeft = userRewards.premiumWords,
            ).successfully()

        val userAudiobookCredits = subscriptionsFirebaseDataFetcher.getUserAudiobookCredits(userId)
            .orDefaultWith { e ->
                Log.d(
                    DiagnosticEvent(
                        message = "Failed to get userAudiobookCredits in getEntitlements()",
                        error = ErrorInfoForDiagnostics(e.toNativeError()),
                        sourceAreaId = "SubscriptionServiceDelegate.getEntitlementsFromBackend",
                    ),
                )
                null
            }
            ?: FirebaseUserAudiobookCredits(0, null, null)

        val userHdWords = subscriptionsFirebaseDataFetcher.getUserHdWords(userId)
            .let {
                // If a user has no subscriptions not having userHdWords is not an error.
                if (subscriptionsAndEntitlements.subscriptions.isEmpty()) {
                    it.toNullSuccessIfResourceNotFound()
                } else {
                    it
                }
            }
            .orDefaultWith {
                Log.e(
                    error = it,
                    message = "Failed to get user HD words in entitlements listener.",
                    sourceAreaId = "SubscriptionServiceDelegate.getEntitlementsFromBackend",
                )
                null
            } ?: FirebaseUserHdWords(0, null, 0)

        val entitlements = subscriptionsAndEntitlements.entitlements
        return entitlements.copy(
            hdWordsLeft = userHdWords.hdWordsLeft + userRewards.premiumWords,
            audiobookCreditsLeft = userAudiobookCredits.creditsLeft,
            lastAudiobookCreditsGrantDate = userAudiobookCredits.lastGrantedAt?.toIsoString(),
        ).successfully()
    }

    internal suspend fun prepareSubscription(preparation: SubscriptionPreparation): Result<String> {
        val preparationResult = when (preparation) {
            is SubscriptionPreparation.Paypal -> {
                platformFetcher.preparePayPalSubscription(preparation.planId)
            }
        }

        return preparationResult.map { it.subscriptionId }
    }

    internal suspend fun createSubscription(purchase: SubscriptionPurchase): Result<SubscriptionCreationResponse> {
        val user = authService.getCurrentUser().orReturn { return it }
        val userId = user.uid
        val userEmail = user.email

        val createSubscriptionResult = when (purchase) {
            is SubscriptionPurchase.Stripe -> {
                platformFetcher.createStripeSubscription(
                    userId,
                    userEmail,
                    purchase.paymentMethodId,
                    purchase.renewalFrequency,
                    purchase.couponCode,
                    purchase.hasTrial,
                    purchase.trialLength,
                    purchase.subscriptionCurrency,
                    purchase.planId,
                    purchase.confirmPaymentOnClient,
                )
            }

            is SubscriptionPurchase.Paypal -> {
                platformFetcher.createPayPalSubscription(
                    userId,
                    purchase.subscriptionId,
                    purchase.renewalFrequency,
                    userEmail,
                    purchase.hasTrial,
                )
            }

            is SubscriptionPurchase.Apple -> {
                platformFetcher.createAppleSubscription(
                    purchase.receiptInfo,
                )
            }

            is SubscriptionPurchase.PlayStore -> {
                platformFetcher.createPlayStoreSubscription(
                    userId,
                    purchase.productId,
                    purchase.purchaseToken,
                )
            }
        }

        return when (purchase) {
            is SubscriptionPurchase.Stripe -> {
                createSubscriptionResult.map { itResponse ->
                    SubscriptionCreationResponse.Stripe(
                        itResponse.message,
                        itResponse.err,
                        itResponse.paymentIntent?.let {
                            StripeSubscriptionPaymentIntent(it.clientSecret)
                        },
                    )
                }
            }

            else -> {
                createSubscriptionResult.map {
                    SubscriptionCreationResponse.Generic(
                        it.message,
                        it.err,
                    )
                }
            }
        }
    }

    internal suspend fun restoreSubscription(restore: SubscriptionRestore): Result<Unit> {
        val restoreSubscriptionResult = when (restore) {
            is SubscriptionRestore.Apple -> {
                platformFetcher.restoreAppleSubscription(
                    restore.receiptInfo,
                    restore.shouldTransferSubscription,
                )
            }
        }
        return when (restoreSubscriptionResult) {
            is Result.Success -> success()
            is Result.Failure -> restoreSubscriptionResult
        }
    }

    internal suspend fun getBillingDashboardUrl(
        billingDashboardOptions: BillingDashboardOptions,
    ): Result<String> {
        val user = authService.getCurrentUser().orReturn { return it }

        when (val existingSubscription = this.getPrimarySubscription()) {
            is Result.Failure -> return existingSubscription
            is Result.Success -> {
                val subscription = existingSubscription.value
                    ?: return Result.Failure(
                        SDKError.OtherMessage("User does not have an active subscription."),
                    )
                when (subscription.plan.source) {
                    SubscriptionSource.STRIPE -> {
                        return platformFetcher.getPortalUrl(
                            user.uid,
                            billingDashboardOptions.extensionId,
                        ).then { it.url.successfully() }
                    }

                    SubscriptionSource.PAYPAL -> {
                        return "https://www.paypal.com/myaccount/autopay".successfully()
                    }

                    SubscriptionSource.APPLE -> {
                        return "https://apps.apple.com/account/billing".successfully()
                    }

                    SubscriptionSource.PLAY_STORE -> {
                        return "https://play.google.com/store/account/subscriptions".successfully()
                    }

                    SubscriptionSource.TEAMS -> {
                        return Result.Failure(
                            SDKError.OtherMessage(
                                "Teams subscriptions don't have a billing dashboard URL",
                            ),
                        )
                    }

                    else -> return Result.Failure(
                        SDKError.OtherMessage(
                            "could not get source for subscription: $subscription",
                        ),
                    )
                }
            }
        }
    }

    internal suspend fun validateCoupon(couponCode: String): Result<Coupon> {
        return platformFetcher.checkCoupon(couponCode)
            .map { PlatformCouponTransformer.toCoupon(it) }
            .then {
                it?.successfully() ?: Result.Failure(SDKError.OtherMessage("invalid coupon: $it"))
            }
    }

    /**
     * Note that this will return the cached value if the actual does not arrive first.
     */
    val subscriptionsCachingFlow: Flow<Array<Subscription>?> get() =
        subscriptionsCachingFlowOfResults
            .asThrowingFlow()

    /**
     * Note that this will return the cached value if the actual does not arrive first.
     */
    val entitlementsCachingFlow: Flow<Entitlements?> get() =
        entitlementsCachingFlowOfResults
            .asThrowingFlow()

    /**
     * A version of [subscriptionsCachingFlow] where [Result]s are retained.
     */
    val subscriptionsCachingFlowOfResults: Flow<Result<Array<Subscription>?>> get() =
        subscriptionsCachedCrossUserListeners.resultsFlow

    /**
     * A version of [entitlementsCachingFlow] where [Result]s are retained.
     */
    val entitlementsCachingFlowOfResults: Flow<Result<Entitlements?>> get() =
        entitlementsCachedCrossUserListeners.resultsFlow

    suspend fun logHDWordsListened(numberOfWords: Int): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }

        return subscriptionsFirebaseDataFetcher.subtractHDWords(user.uid, numberOfWords)
    }

    override fun destroy() {
        subscriptionsCachedCrossUserListeners.destroy()
        entitlementsCachedCrossUserListeners.destroy()
    }

    suspend fun cancelSubscription(subscriptionId: String? = null): Result<Unit> {
        return platformFetcher.cancelSubscription(subscriptionId)
    }

    internal suspend fun validateSubscription(validation: SubscriptionValidation): Result<ValidateReceiptResult> {
        return when (validation) {
            is SubscriptionValidation.Apple -> {
                platformFetcher.validateAppStoreReceipt(validation.receiptInfo).map {
                    val subscriptionStatus = if (!it.isValid) {
                        SubscriptionStatus.EXPIRED
                    } else {
                        when (it.status) {
                            FirebaseSubscriptionStatus.TRIAL, FirebaseSubscriptionStatus.PAYING ->
                                SubscriptionStatus.ACTIVE

                            FirebaseSubscriptionStatus.CANCELLED_PAYING, FirebaseSubscriptionStatus.CANCELLED_TRIAL ->
                                SubscriptionStatus.CANCELED

                            null ->
                                null
                        }
                    }

                    ValidateReceiptResult(
                        it.isValid,
                        it.needsTransfer,
                        it.expiresAt?.toIsoString(),
                        subscriptionStatus,
                        it.isEligibleForTrialPeriod,
                    )
                }
            }
        }
    }

    internal suspend fun getSubscriptionPlan(subscriptionPlanId: String): Result<SubscriptionPlan> {
        return subscriptionsFirebaseDataFetcher.getSubscriptionPlan(subscriptionPlanId)
            .then { SubscriptionsFirebaseDataTransformer.planToSubscriptionPlan(it) }
    }

    internal suspend fun getOneClickRenewalStatus(oneClickRenewal: OneClickRenewal): Result<OneClickRenewalStatus> {
        val body = when (oneClickRenewal) {
            is OneClickRenewal.Stripe -> {
                OneClickRenewalBody(
                    stripePromotionCode = oneClickRenewal.promotionCode,
                    stripePriceId = oneClickRenewal.priceId,
                )
            }
        }
        return platformFetcher.getOneClickRenewalStatus(body).then {
            PlatformDataTransformer.statusToOneClickRenewalStatus(it)
        }
    }

    internal suspend fun performOneClickRenew(oneClickRenewal: OneClickRenewal): Result<Unit> {
        val body = when (oneClickRenewal) {
            is OneClickRenewal.Stripe -> {
                OneClickRenewalBody(
                    stripePromotionCode = oneClickRenewal.promotionCode,
                    stripePriceId = oneClickRenewal.priceId,
                )
            }
        }
        return platformFetcher.performOneClickRenew(body)
    }

    internal suspend fun getSubscriptionPricing(
        renewalFrequency: RenewalFrequency,
        currency: String?,
    ): Result<SubscriptionPricingResult> {
        return platformFetcher.getSubscriptionPricing(renewalFrequency, currency)
    }

    internal suspend fun getRewardBalance(): Result<RewardBalance> {
        return platformFetcher.getRewardBalance().map {
            RewardBalance(
                it.usdCents,
                it.claimedRewards,
                it.usersReferred,
                it.totalAudiobookCredits,
            )
        }
    }

    internal suspend fun extendTrial(): Result<Unit> {
        return platformFetcher.extendTrial()
    }

    internal suspend fun skipTrial(): Result<Unit> {
        return platformFetcher.skipTrial()
    }

    internal suspend fun validateCardCountry(purchase: SubscriptionPurchase): Result<Unit> {
        val validationResult = when (purchase) {
            is SubscriptionPurchase.Stripe -> {
                // Payment Server always accepts purchases with no explicit currency requested.
                if (purchase.subscriptionCurrency == null) {
                    return success()
                }

                platformFetcher.validateCardCountry(
                    purchase.paymentMethodId,
                    purchase.renewalFrequency,
                    purchase.subscriptionCurrency,
                )
            }

            else -> return Result.Failure(
                SDKError.OtherMessage(
                    "could not validate card country for: $purchase",
                ),
            )
        }

        return when (validationResult) {
            is Result.Success -> success()
            is Result.Failure -> validationResult
        }
    }

    companion object {
        const val ALL_SUBSCRIPTIONS_KEY = "ALL_SUBSCRIPTIONS_KEY"
    }
}

// internal for testing only
internal fun subscriptionProducer(
    subscriptionsFirebaseDataFetcher: SubscriptionsFirebaseDataFetcher,
    subscriptionServiceDelegate: SubscriptionServiceDelegate,
    keyValueStore: LocalKeyValueStorageAdapter,
    valueRetriever: suspend (subscription: Result<SubscriptionAndEntitlements>?) -> Result<Array<Subscription>>,
): (userId: UserId) -> Flow<Result<Array<Subscription>>> {
    return { userId ->
        userDataForSubscriptionFlow(
            userId,
            subscriptionsFirebaseDataFetcher,
            subscriptionServiceDelegate,
            keyValueStore,
        )
            .map {
                valueRetriever(it)
            }
    }
}

private fun userDataForSubscriptionFlow(
    userId: UserId,
    subscriptionsFirebaseDataFetcher: SubscriptionsFirebaseDataFetcher,
    subscriptionServiceDelegate: SubscriptionServiceDelegate,
    keyValueStore: LocalKeyValueStorageAdapter,
): Flow<Result<SubscriptionAndEntitlements>?> {
    val cachedValueFlow = flow {
        val cachedValue = try {
            keyValueStore.getCachedSubscriptionResponse(userId) ?: return@flow
        } catch (e: CancellationException) {
            /**
             * Rethrow [CancellationException]s, not to log them, as they simply mean that the user no longer needs the information
             * from this flow.
             */
            throw e
        } catch (e: Throwable) {
            // In case of error don't fail the flow, just emit nothing.
            Log.e(
                message = "Failed to load cached subscription state in userDataForSubscriptionFlow",
                exception = e,
                sourceAreaId = "SubscriptionServiceDelegate.userDataForSubscriptionFlow",
            )
            return@flow
        }
        emit(
            cachedValue
                .successfully(),
        )
    }

    return merge(
        cachedValueFlow,
        (subscriptionsFirebaseDataFetcher.observeSubscriptionsInfo(userId)).mapNotNull { _ ->
            try {
                val allSubscriptions =
                    subscriptionServiceDelegate.getAllSubscriptions() ?: return@mapNotNull cachedValueFlow.firstOrNull()

                // Update cache
                keyValueStore.cacheSubscriptionResponse(allSubscriptions, userId)

                allSubscriptions.successfully()
            } catch (e: CancellationException) {
                /**
                 * Rethrow [CancellationException]s, not to log them, as they simply mean that the user no longer needs the information
                 * from this flow.
                 */
                throw e
            } catch (error: Throwable) {
                Log.e(
                    message = error.message ?: "Unknown Error in userDataForSubscriptionFlow",
                    sourceAreaId = "SubscriptionServiceDelegate.userDataForSubscriptionFlow",
                )
                return@mapNotNull cachedValueFlow.firstOrNull()
            }
        },
    )
}

// internal for testing only
internal fun entitlementsProducer(
    subscriptionsFirebaseDataFetcher: SubscriptionsFirebaseDataFetcher,
    subscriptionServiceDelegate: SubscriptionServiceDelegate,
    keyValueStore: LocalKeyValueStorageAdapter,
    valueRetriever: suspend (
        userDataForEntitlements: UserDataForEntitlements,
    )
    -> Result<Entitlements>,
): (
    userId: UserId,
)
-> Flow<Result<Entitlements>> {
    return { userId ->
        val userSubscriptionDataFlow = userDataForSubscriptionFlow(
            userId,
            subscriptionsFirebaseDataFetcher,
            subscriptionServiceDelegate,
            keyValueStore,
        )
        val userHdWordsFlow = subscriptionsFirebaseDataFetcher.observeUserHdWords(userId)
        val userRewardsFlow = subscriptionsFirebaseDataFetcher.observeUserRewards(userId)
        val userAudiobookCreditsFlow = subscriptionsFirebaseDataFetcher.observeUserAudioBookCredits(userId)

        combine(
            userSubscriptionDataFlow,
            userHdWordsFlow,
            userRewardsFlow,
            userAudiobookCreditsFlow,
        ) { subscriptionData, hdWordsResult, rewardsResult, audiobookCreditsResult ->
            UserDataForEntitlements(
                subscriptionData,
                hdWordsResult,
                rewardsResult.toNullSuccessIfResourceNotFound(),
                audiobookCreditsResult,
            )
        }.map {
            valueRetriever(it)
        }
    }
}

internal data class UserDataForEntitlements(
    val userDataForSubscription: Result<SubscriptionAndEntitlements>?,
    val userHdWords: Result<FirebaseUserHdWords>,
    val userRewards: Result<FirebaseUserRewards?>,
    val audiobookCredits: Result<FirebaseUserAudiobookCredits?>,
)

private fun getSubscriptionResponseCacheKey(userId: UserId) =
    "${SubscriptionServiceDelegate.ALL_SUBSCRIPTIONS_KEY}-$userId"

private suspend fun LocalKeyValueStorageAdapter.getCachedSubscriptionResponse(
    userId: UserId,
): SubscriptionAndEntitlements? =
    this.coGetSingle(
        key = getSubscriptionResponseCacheKey(userId),
    )
        ?.decodeToString()
        ?.let { Json.decodeFromString(it) }

private suspend fun LocalKeyValueStorageAdapter.cacheSubscriptionResponse(
    subscriptionsAndEntitlements: SubscriptionAndEntitlements,
    userId: UserId,
) {
    val cacheKey = getSubscriptionResponseCacheKey(userId)
    this.coPutSingle(
        cacheKey,
        Json.encodeToString(subscriptionsAndEntitlements).encodeToByteArray(),
    ).orThrow()
}
