package com.speechify.client.api.services.scannedbook.models

import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * Paginated content where each page has a background image and fixed-positioned text content
 */
@JsExport
interface ScannedBook : Destructible {
    /**
     * @return the number of pages in this book
     */
    val numberOfPages: Int

    /**
     * Asynchronously fetch a representation for the specified page (the first page has index zero)
     */
    fun getPage(pageIndex: Int, callback: Callback<ScannedBookPage>)
}

internal suspend fun ScannedBook.coGetPage(pageIndex: Int) =
    suspendCoroutine { cont -> getPage(pageIndex, cont::resume) }

/**
 * A single page of a Scanned Book
 */
@JsExport
interface ScannedBookPage : Destructible {

    /**
     * @return the dimensions of the background image. Necessary for positioning the text correctly in a view.
     */
    fun getViewport(): Viewport

    /**
     * Asynchronously provide the fixed-positioned text content on the page
     */
    fun getContent(callback: Callback<Array<OCRTextContent>>)

    /**
     * The image of the scanned page
     */
    fun getImage(
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )
}

internal suspend fun ScannedBookPage.coGetContent() =
    suspendCoroutine { cont -> getContent(cont::resume) }

internal suspend fun ScannedBookPage.coGetImage() =
    suspendCoroutine { cont -> getImage(cont::resume) }
