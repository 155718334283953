package com.speechify.client.api.util.boundary

import kotlin.js.JsExport

/**
 * `BoundaryMap` was created to overcome a `map` not being correctly compiled to the equivalent type in the
 * product app
 */
@JsExport
abstract class AbstractBoundaryMap<V> : BoundaryMap<V>
