package com.speechify.client.internal.services.subscription.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseSubscriptionDiscount(
    /** Type of coupon, percentage or amount */
    val type: String,

    /** Value of the discount (amount should be aligned with payment currency) */
    val value: Double,

    /** human-readable code from stripe or other system */
    val name: String,

    /** The discount duration: Forever, Once, Repeating **/
    var duration: String,

    /** The starting timestamp of the coupon **/
    var startTime: DateTime,

    /** The expiry timestamp of the coupon **/
    var expiryTime: DateTime,
)
