package com.speechify.client.internal.util.intentSyntax

/**
 * Shortcut that gives a smart-cast to non-null `it` for expressions on changeable symbols (variables/properties).
 * Unlike `when (val x = ...) is TheType` this one saves you from repeating the type name, when all you need is to do
 * something when it's not null.
 */
internal inline fun <reified Val> ifNotNull(value: Val?, expressionOnNonNull: (Val) -> Unit) {
    when (value) {
        is Val -> expressionOnNonNull(value)
    }
}
