package com.speechify.client.api.editing

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.TransientContentTextPosition
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.containsEndExclusive
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.editing.unEdited
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.standard.coGetBlocksBetweenCursors
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.helpers.content.standard.book.BookStandardView
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.services.editing.BookEditingService
import com.speechify.client.internal.toDestructor
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.updateAndGet
import kotlinx.coroutines.launch
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.serialization.Serializable
import kotlin.coroutines.resume
import kotlin.js.JsExport

@JsExport
@Serializable
data class BookEdits internal constructor(val pages: Array<Page>) {
    companion object {
        internal fun defaultWith(numberOfPages: Int): BookEdits {
            return BookEdits(Array(numberOfPages) { Page() })
        }
    }

    /**
     * Tracks whether a page is hidden and which regions of it should be presented.
     */
    @Serializable
    data class Page(
        /**
         * The regions of interest the user wants to listen to (empty means that the entire area should be read).
         *
         * NOTE: The units of the regions are the same as in dimensions of the contents provided by
         * the adapter (e.g. in [com.speechify.client.api.adapters.ocr.OCRTextContent.boundingBox] for scanned content, or
         * [com.speechify.client.api.adapters.pdf.PDFPageTextContentItem.box] for PDFs).
         *
         * Use [regionsNormalized] to get the regions in units relative to the page dimensions.
         */
        val regions: Array<BoundingBox> = emptyArray(),
        /**
         * Whether the page has been hidden
         */
        val hidden: Boolean = false,

        /**
         * When this is set this content will be used for the page instead of the original OCR / PDF content.
         */
        val replacementPageContent: PageContent? = null,
    ) {
        override fun toString() = "Page(regions=${regions.contentToString()}, hidden=$hidden)"
    }

    override fun toString() = "BookEdits(pages=${pages.contentToString()})"
}

internal fun BookEdits.Page.regionsNormalized(viewPort: Viewport) = regions
    .map { it.normalize(viewPort) }
    .toTypedArray()

private fun BookEdits.updatePage(index: Int, block: BookEdits.Page.() -> BookEdits.Page): BookEdits {
    return copy(
        pages = this.pages.copyOf().apply {
            this[index] = this[index].run(block)
        },
    )
}

@JsExport
class BookEditor internal constructor(
    internal val bookBundle: ContentBundle.BookBundle,
    private val bookEditingService: BookEditingService,
    initialState: BookEdits,
) : WithScope() {
    /**
     * The original, unedited, [BookView]. Use this to implement an UI where you can show the user all the pages
     * and their states.
     */
    val originalBookView = bookBundle.bookView.unEdited()

    private var _editedBookView: Lazy<BookView> = lazy { EditingBookView(currentState, bookBundle.bookView.unEdited()) }

    /**
     * The [BookView] with all current edits applied to it. Use this is you need to show the user the current
     * edits, for example by only highlighting text in selected regions.
     */
    val editedBookView: BookView
        get() = _editedBookView.value

    private val _state = MutableStateFlow(initialState)

    init {
        scope.launch {
            _state.collect { currentState ->
                // Reset the edit book view since it is now stale.
                _editedBookView = lazy { EditingBookView(currentState, originalBookView) }
            }
        }
    }

    /**
     * The current editing state. Will always return the current state.
     *
     * **WARNING:** mutating this value without calling [BookEditor] methods is undefined behavior.
     */
    val currentState: BookEdits get() = _state.value

    /**
     * Listen to state change events. This will be fired every time you call one of the editing functions.
     *
     * This is just a convenience function to help implement the UI and not at all necessary for use of this class.
     *
     * It's perfectly fine to use the following pattern instead:
     *
     * ```
     * updateUI(editor.movePage(1, 2))
     * ```
     */
    fun addStateChangedListener(callback: (BookEdits) -> Unit): Destructor {
        val flow = _state.asStateFlow()

        return launchTask { flow.collect(callback) }.toDestructor()
    }

    /**
     * Hides a page from the book
     *
     * Hiding an already hidden page is a no-op
     */
    fun hidePage(index: Int): BookEdits {
        Log.d("hiding $index", sourceAreaId = "BookEditor.hidePage")
        return _state.updateAndGet {
            it.updatePage(index) { copy(hidden = true) }
        }
    }

    /**
     * Shows a page from the book
     *
     * Showing a page that already is visible is a no-op
     */
    fun showPage(index: Int): BookEdits {
        Log.d("showing $index", sourceAreaId = "BookEditor.showPage")
        return _state.updateAndGet {
            it.updatePage(index) { copy(hidden = false) }
        }
    }

    /**
     * Set the regions of interest in the page, overwriting the current regions of that page if any
     *
     * This needs to be done before any replacement content is set using [setTextContent]. Calling this after
     * replacement content has been set will throw an exception. Any regions set here will be reflected when calling
     * [getEditableTextContent].
     *
     * NOTE: The units of the regions should be the same as in dimensions of the contents provided by
     * the adapter (e.g. in [com.speechify.client.api.adapters.ocr.OCRTextContent.boundingBox] for scanned content, or
     * [com.speechify.client.api.adapters.pdf.PDFPageTextContentItem.box] for PDFs)
     */
    fun setRegionsOfInterest(index: Int, regions: Array<BoundingBox>): BookEdits {
        Log.d(
            "setting regions of interest $index : ${regions.contentToString()}",
            sourceAreaId = "BookEditor.setRegionsOfInterest",
        )
        return _state.updateAndGet {
            if (it.pages[index].replacementPageContent != null) {
                throw UnsupportedOperationException(
                    "Cannot set regions of interest on a page that has replacement text",
                )
            }
            it.updatePage(index) { copy(regions = regions) }
        }
    }

    /**
     * Removes the regions of interest in a page, effectively restoring its original state
     */
    fun resetRegionsOfInterest(index: Int): BookEdits {
        Log.d(
            "resetting regions of interest $index",
            sourceAreaId = "BookEditor.resetRegionsOfInterest",
        )
        return _state.updateAndGet {
            it.updatePage(index) { copy(regions = emptyArray()) }
        }
    }

    /**
     * This gets an editable representation of the text content for the given page.
     * If the content was already edited, the edited version will be returned.
     * You can use [resetTextContent] to reset the content to the original version, after which calling this
     * will once again return the original content.
     */
    fun getEditableTextContent(pageIndex: Int, callback: Callback<PageContent>) = callback.fromCo {
        val currentEdit = _state.value.pages[pageIndex].replacementPageContent
        if (currentEdit != null) {
            return@fromCo currentEdit.successfully()
        }
        val page = originalBookView.coGetPages(arrayOf(pageIndex)).orReturn { return@fromCo it }[0]

        // We apply the latest changes before fetching the blocks so any regions of interest are applied correctly.
        val standardBookView = BookStandardView(EditingBookView(_state.value, bookBundle.bookView.unEdited()))
        val blocks = standardBookView.coGetBlocksBetweenCursors(page.start, page.end).orReturn { return@fromCo it }
        blocks.toPageContent(pageIndex).successfully()
    }

    /**
     * This allows you to find which [PageContentPart] to edit for a given [ContentCursor].
     * It returns the page and part index for the cursor which you can then look up in the [PageContent] returned
     * by [getEditableTextContent].
     */
    fun findPageContentPositionFromCursor(
        cursor: ContentCursor,
        callback: Callback<PageContentPosition>,
    ) = callback.fromCo {
        val actualCursor = when (cursor) {
            is TransientContentTextPosition -> cursor.originalContentTextPosition
            else -> cursor
        }

        val pageIndex = editedBookView.getPageIndex(actualCursor)

        val page = editedBookView.coGetPages(arrayOf(pageIndex)).orReturn { return@fromCo it }[0]
        val standardBookView = BookStandardView(editedBookView)
        val blocks = standardBookView.coGetBlocksBetweenCursors(page.start, page.end).orReturn { return@fromCo it }

        if (actualCursor is ContentElementBoundary) {
            return@fromCo when (actualCursor.boundary) {
                ContentBoundary.END -> PageContentPosition(0, blocks.blocks.size - 1).successfully()
                ContentBoundary.START -> PageContentPosition(pageIndex, 0).successfully()
            }
        }

        val replacementPageContent = currentState.pages[pageIndex].replacementPageContent
        if (replacementPageContent == null) {
            // If the page isn't edited the cursors we get also will be from unedited content so we use the original
            // blocks to find the position.
            val index = blocks.blocks.indexOfFirst { it.contains(actualCursor) }
            return@fromCo PageContentPosition(pageIndex, index).successfully()
        } else {
            // On an edited page we use the replacement content to find the position.
            val contentWithCursors = replacementPageContent.getPartsWithCursors(page.start.getParentElement())
            val index =
                contentWithCursors.indexOfFirst {
                    it.containsEndExclusive(actualCursor)
                }
            if (index == -1) {
                throw IllegalArgumentException("Cursor $actualCursor was not found in replacement content.")
            }
            return@fromCo PageContentPosition(pageIndex, index).successfully()
        }
    }

    /**
     * This sets the new content for the given page index, completely replacing the original content.
     */
    fun setTextContent(pageIndex: Int, pageContent: PageContent): BookEdits {
        return _state.updateAndGet {
            it.updatePage(pageIndex) { copy(replacementPageContent = pageContent) }
        }
    }

    /**
     * Calling this will remove any text edits made to the given page and restore the original content.
     */
    fun resetTextContent(pageIndex: Int): BookEdits {
        return _state.updateAndGet {
            it.updatePage(pageIndex) { copy(replacementPageContent = null) }
        }
    }

    /**
     * Saves the edits to the content into the user-library (in case of already imported documents) or submits them to
     * be saved when the user decides to import the content.
     *
     * The [callback] passed will be called once the save has been completed or abandoned, which can be used
     * to communicate success or failure to the user, but NOTE: use [com.speechify.client.api.util.boundary.deferred.CancellationUtils.isCancellationResult] to
     * check if abandonment of the save was expected by the user (e.g. they made other edits, or decided not to import
     * at all), in which case user shouldn't be notified, and no errors logged.
     *
     * DANGER: Calling this method multiple times is only safe when no import is in progress.
     * If an import is in progress, calling this method more than once will cause a race-condition
     * and **may cause loss of data** (multiple database writes racing over network). Preventing this is the
     * responsibility of the caller, and can be done by awaiting the result of this function (e.g. UI can block
     * saving more edits when it is not resolved).
     */
    fun save(callback: Callback<SpeechifyURI>) {
        // copy the values so that this functions always stores the current snapshot and the caller is free to further
        // mutate the instance
        val editingState = _state.value

        callback.fromCo {
            return@fromCo bookBundle.coImporter.setEditsSaveAction { uri ->
                bookEditingService.setBookEdits(uri, editingState, bookBundle.coImporter.state)
            }.await()
                .successfully()
        }
    }
}

internal suspend fun BookEditor.coFindTextContentFromCursor(cursor: ContentCursor) = suspendCancellableCoroutine {
    findPageContentPositionFromCursor(cursor, it::resume)
}
