package com.speechify.client.api.content

import com.speechify.client.api.content.view.speech.SpeechView
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toResultFailure
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.helpers.features.getProgressFractionValidationExceptionOrNull
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import com.speechify.client.internal.util.collections.flows.externalStateChangesFlow
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * Provides information about content that requires understanding of the *entire* content.
 */
@JsExport
interface ContentIndex : Destructible {
    /* TODO - introduce something like BoundaryFlow? Or make the StandardViewWithIndex an abstract class, with its
         members hidden #TODOStandardViewWithIndexAsAbstractClass */
    val contentAmountStateFlow: ExternalStateChangesFlow

    /**
     * Maps a fractional progress to a cursor
     *
     * @param progress a fraction of range [0, 1] with 0 representing the beginning and 1 the end
     */
    @JsName("getCursorFromProgress")
    fun getCursorFromProgress(progress: ProgressFraction, callback: Callback<ContentCursor>)

    /**
     * Maps a cursor to a fractional progress
     *
     * @return a fraction of range [0, 1] with 0 representing the beginning and 1 the end
     */
    @JsName("getProgressFromCursor")
    fun getProgressFromCursor(cursor: ContentCursor, callback: Callback<ProgressFraction>)

    // (it's fuzzy so we can approximate w/ partial content)
    @JsName("getStats")
    fun getStats(callback: Callback<ContentStats>)

    /**
     * This is for SDK internal use only.
     * [getStats] may return the non-settled interim result while a better one is estimating. Use this method to obtain the
     * statistics that includes all the pending calculations (e.g. for the stats to reported on the imported library item)
     */
    @JsExport.Ignore
    suspend fun getStatsIncludingPending(): ContentStats?
}

/**
 * A Standard view that also provides its own index.
 */
@JsExport
interface StandardViewWithIndex : StandardView, ContentIndex

abstract class StaticContentIndexBase : ContentIndex {
    override val contentAmountStateFlow: ExternalStateChangesFlow = externalStateChangesFlow()

    override suspend fun getStatsIncludingPending(): ContentStats =
        coGetStats().orThrow()
}

abstract class SpeechViewWithStaticContentIndexBase : SpeechView(), ContentIndex {
    override val contentAmountStateFlow: ExternalStateChangesFlow = externalStateChangesFlow()

    override suspend fun getStatsIncludingPending(): ContentStats =
        coGetStats().orThrow()
}

internal suspend fun ContentIndex.coGetCursorFromProgress(progress: ProgressFraction): Result<ContentCursor> =
    suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
        onCancellationLeavingJobRunning = {
            // TODO #TODOImplementCancellations
        },
    ) { /* Cancellable, despite lack of cancellation API, so that this suspension doesn't
     block cancellations of this coroutine - this is safe, because the code is a value retrieval without any side
     effects.
    */
        getCursorFromProgress(progress, it::resume)
    }

internal suspend fun ContentIndex.coGetProgressFromCursor(cursor: ContentCursor): Result<ProgressFraction> {
    val progressFraction: ProgressFraction =
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
            onCancellationLeavingJobRunning = {
                // TODO #TODOImplementCancellations
            },
        ) { /* Cancellable, despite lack of cancellation API, so that this suspension doesn't
     block cancellations of this coroutine - this is safe, because the code is a value retrieval without any side
     effects.
    */
            getProgressFromCursor(cursor, it::resume)
        }
            .orReturn { return it }

    ifNotNull(
        getProgressFractionValidationExceptionOrNull(
            progressFraction = progressFraction,
            areaId = "ContentIndex.getProgressFromCursor",
        ),
    ) {
        return@coGetProgressFromCursor it.toResultFailure()
    }

    return progressFraction.successfully()
}

internal suspend fun ContentIndex.coGetStats(): Result<ContentStats> =
    suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
        onCancellationLeavingJobRunning = {
            // TODO #TODOImplementCancellations
        },
    ) { /* Cancellable, despite lack of cancellation API, so that this suspension doesn't
     block cancellations of this coroutine - this is safe, because the code is a value retrieval without any side
     effects.
    */
        getStats(it::resume)
    }

/**
 * Returns a new [ContentIndex] overriding the receiver's [ContentIndex.getStats] to always return the given [stats].
 * Useful in cases where we've precomputed the stats and cached them somewhere
 */
internal fun ContentIndex.withStaticContentStats(stats: ContentStats): ContentIndex {
    return object : ContentIndex by this {
        override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
            return@fromCo stats.successfully()
        }
    }
}

@JsExport
data class ContentStats(
    /**
     * see [com.speechify.client.internal.services.importing.models.RecordProperties.wordCount]
     */
    val estimatedWordCount: EstimatedCount,
    /**
     * When not specified at construction, it defaults to a heuristic based solely on `estimatedWordCount`.
     * see [com.speechify.client.internal.services.importing.models.RecordProperties.charCount]
     */
    val estimatedCharCount: EstimatedCount = EstimatedCount(
        count = (estimatedWordCount.count * AVERAGE_CHAR_PER_WORD).toInt(),
        confidence = estimatedWordCount.confidence,
    ),
) {
    companion object {
        /**
         * 4.7 is average word length in English language src (https://www.wyliecomm.com/2021/11/whats-the-best-length-of-a-word-online/#:~:text=The%20average%20word%20in%20the%20English%20language%20is%204.7%20characters)
         */
        private const val AVERAGE_CHAR_PER_WORD = 4.7
    }
}

@JsExport
data class EstimatedCount(
    val count: Int,

    /**
     * The likelihood that [count] is correct, expressed within range [0, 1] with 0 representing absolutely
     * no confidence and 1 representing certainty.
     */
    val confidence: Double,
)
