package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Result.Failure
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult
import com.speechify.client.internal.util.collections.flows.FlowThatFinishesOnlyThroughCollectionCancel
import com.speechify.client.internal.util.collections.flows.flowFromCallbackProducer
import kotlin.js.JsExport

@JsExport
interface FirebaseAuthService {
    /**
     * Should return the currently signed-in user from Firebase Auth or `null` if there is no currently
     * signed-in user.
     * <https://firebase.google.com/docs/reference/kotlin/com/google/firebase/auth/FirebaseAuth#getcurrentuser>
     */
    fun getCurrentUser(callback: Callback<FirebaseAuthUser?>)

    /**
     * Should return the id token for currently signed-in user from Firebase Auth or `null` if there is no currently
     * signed-in user.
     * <https://firebase.google.com/docs/reference/kotlin/com/google/firebase/auth/FirebaseAuth#getcurrentuser>
     */
    fun getCurrentUserIdentityToken(callback: Callback<FirebaseAuthToken?>)

    /**
     * Should call the callback on every Firebase Auth user state change.
     * <https://firebase.google.com/docs/reference/kotlin/com/google/firebase/auth/FirebaseAuth#addauthstatelistener>
     *
     * NOTE: It has been observed that onboarding an anonymous user to an authenticated one may not trigger the
     * FirebaseAuth observer, so you may need to trigger it additionally (for how this was done see [here](https://linear.app/speechify-inc/issue/PLT-2490/onboarding-a-user-does-not-emit-any-event-to) or [here](https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1678205563768989?thread_ts=1678087884.552029&cid=C02LEG7AEGM)).
     *
     * @param callback should be called with the currently signed-in user from Firebase Auth or `null` if there is no
     * currently signed-in user.
     * Should be called with a [Failure] if an error happened.
     *
     * @return[Unit]
     */
    fun observeCurrentUser(callback: Callback<FirebaseAuthUser?>): Destructor
}

internal fun FirebaseAuthService.observeCurrentUserAsFlow():
    FlowThatFinishesOnlyThroughCollectionCancel<Result<FirebaseAuthUser?>> =
    flowFromCallbackProducer(
        callbackBasedProducer = this::observeCurrentUser,
        /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
         *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
         *  until no more items coming is ensured)
         */
        shouldLogErrorIfCancellationPreventedDelivery = false,
        sourceAreaId = "FirebaseAuthService.observeCurrentUserAsFlow",
    )

internal suspend fun FirebaseAuthService.coGetCurrentUser(): Result<FirebaseAuthUser?> =
    suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
        onCancellationLeavingJobRunning = {
            /*
              Detaching is our only option while there is no cancellable API, but detaching is still desired, because
              it doesn't make sense to keep the coroutine from finishing - this is a read-only, side-effect-free
              operation (except the possible cache-warmup, which our detach is still desirably allowing to complete).
              #DetachingFromSideEffectFreeOperationIsDesirable
            */
        },
    ) { cont ->
        getCurrentUser(cont::resume)
    }

internal suspend fun FirebaseAuthService.coGetCurrentUserIdentityToken(): Result<FirebaseAuthToken?> =
    suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
        onCancellationLeavingJobRunning = {
            /*
              Detaching is our only option while there is no cancellable API, but detaching is still desired as per
              #DetachingFromSideEffectFreeOperationIsDesirable
            */
        },
    ) { cont ->
        getCurrentUserIdentityToken(cont::resume)
    }

internal fun FirebaseAuthService.traced(): FirebaseAuthService =
    if (Log.isDebugLoggingEnabled) FirebaseAuthServiceTraced(this) else this

internal class FirebaseAuthServiceTraced(private val firebaseAuthService: FirebaseAuthService) : FirebaseAuthService {
    override fun getCurrentUser(callback: Callback<FirebaseAuthUser?>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseAuthService.getCurrentUser()",
            sourceAreaId = "FirebaseAuthServiceTraced.getCurrentUser",
        )
        firebaseAuthService.getCurrentUser(taggedCallback)
    }

    override fun getCurrentUserIdentityToken(callback: Callback<FirebaseAuthToken?>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseAuthService.getCurrentUserIdentityToken()",
            sourceAreaId = "FirebaseAuthServiceTraced.getCurrentUserIdentityToken",
        )
        firebaseAuthService.getCurrentUserIdentityToken(taggedCallback)
    }

    override fun observeCurrentUser(callback: Callback<FirebaseAuthUser?>): Destructor {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseAuthService.observeCurrentUser()",
            sourceAreaId = "FirebaseAuthServiceTraced.observeCurrentUser",
        )
        return firebaseAuthService.observeCurrentUser(taggedCallback)
    }
}
