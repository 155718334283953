package com.speechify.client.internal.sync

internal actual class AtomicLong actual constructor(private var value: Long) {
    actual fun get(): Long = value

    actual fun set(newInt: Long) {
        value = newInt
    }

    actual fun getAndAdd(delta: Long): Long {
        val valueBeforeAdd = value
        value += delta
        return valueBeforeAdd
    }

    actual fun addAndGet(delta: Long): Long = getAndAdd(delta) + delta

    actual fun getAndIncrement(): Long = value++

    actual fun getAndDecrement(): Long = value--
}
