package com.speechify.client.internal.services.importing.models

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.services.editing.getEditsPath
import com.speechify.client.internal.util.collections.KeyWithValueType
import com.speechify.client.internal.util.www.UrlString

/**
 * Insert a key value pair in a boundary map, maintaining the type safety of the insertion.
 *
 * ## Example
 * ```
 * payload[RecordProperties.owner] = "ownerId" // can't pass a type that isn't string here
 * ```
 */
internal operator fun <Value> BoundaryMap<in Value>.set(property: RecordProperty<Value>, value: Value) {
    this[property.key.keyId] = value
}

internal interface RecordProperty<T> {
    val key: RecordProperties.RecordPropertyKey<T>
}

internal object RecordProperties {
    class RecordPropertyKey<Value>(keyId: String) : KeyWithValueType<Value>(keyId)

    /**
     * If imported from URL: The original URL. If imported from local file: the equivalent of [sourceStoredURL] (done
     * historically and still done for backwards compatibility (see #SettingSourceStoredURL_to_sourceURL). There were
     * efforts to remove this quirk - see [1](https://www.notion.so/fresh-hoodie-9f1/Discussion-sourceUrl-de3c21310c5d4797a4edcd3ad83f942f#d6381545d7e746779ef3c4a6206ecb86)
     * [2](https://linear.app/speechify-inc/issue/PLT-1040/library-sdk-support-for-original-url-use-cases-formerly-sourceurl))
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object sourceURL : RecordProperty<String?> {
        const val keyId = "sourceURL"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * Points at a Speechify-local stored instance of the resource
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object sourceStoredURL : RecordProperty<String?> {
        const val keyId = "sourceStoredURL"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * Property is only applicable to `recordType=WEB`
     * `true` when the HTML constitutes the entire document. `false` when it's a fragment of it.
     * If the property is not there, then the information is unknown (can be either).
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object htmlIsEntireDocument : RecordProperty<Boolean?> {
        const val keyId = "htmlIsEntireDocument"
        override val key = RecordPropertyKey<Boolean?>(keyId)
    }

    /**
     * Property is only applicable to `recordType=WEB`
     * `true` when the HTML was extracted from after executing JavaScript
     * (e.g. from a browser window or Android Preview)
     * If the property is not there, then the information is unknown (can be either).
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object htmlIsPostJavaScriptExecution : RecordProperty<Boolean?> {
        const val keyId = "htmlIsJavaScriptProcessed"
        override val key = RecordPropertyKey<Boolean?>(keyId)
    }

    /**
     * Property is only applicable to `recordType=WEB`
     * `true` when the HTML already had the content extracted by Speechify (AKA was 'parsed')
     * If the property is not there, then the information is unknown (can be either).
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object htmlIsPostContentExtraction : RecordProperty<Boolean?> {
        const val keyId = "htmlIsPostContentExtraction"
        override val key = RecordPropertyKey<Boolean?>(keyId)
    }

    @Suppress("ClassName")
    object owner : RecordProperty<String> {
        const val keyId = "owner"
        override val key = RecordPropertyKey<String>(keyId)
    }

    @Suppress("ClassName")
    object coverImagePath : RecordProperty<UrlString?> {
        const val keyId = "coverImagePath"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    @Suppress("ClassName")
    object analyticsProperties : RecordProperty<BoundaryMap<String>> {
        const val keyId = "analyticsProperties"
        override val key = RecordPropertyKey<BoundaryMap<String>>(keyId)
    }

    /**
     * Key of the fields that pertain to scanned books and are stored in library items
     */
    @Suppress("ClassName")
    object scannedBookFields : RecordProperty<BoundaryMap<Any?>> {
        const val keyId = "scannedBookFields"
        override val key = RecordPropertyKey<BoundaryMap<Any?>>(keyId)
    }

    /**
     * One of the [scannedBookFields], this one stores an array that stores in which order the pages are to be read.
     */
    @Suppress("ClassName")
    object pageOrdering : RecordProperty<Array<BoundaryMap<String>>> {
        const val keyId = "pageOrdering"
        override val key = RecordPropertyKey<Array<BoundaryMap<String>>>(keyId)
    }

    /**
     * The id of the page at this index in the [pageOrdering].
     */
    @Suppress("ClassName")
    object pageId : RecordProperty<String> {
        const val keyId = "pageId"
        override val key = RecordPropertyKey<String>(keyId)
    }

    /**
     * Indicates if the given library item was edited.
     * The contents of the edits are stored in the collection indicated by the [getEditsPath] function.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object hasPageEdits : RecordProperty<Boolean> {
        const val keyId = "hasPageEdits"
        override val key = RecordPropertyKey<Boolean>(keyId)
    }

    /**
     * The last legacy page that was edited in this library item.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object lastUpdatedPageId : RecordProperty<String?> {
        const val keyId = "lastUpdatedPageId"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * Key of the fields that pertain to ml parsed books and are stored in library items
     */
    @Suppress("ClassName")
    object mlParsedBookFields : RecordProperty<BoundaryMap<Any?>> {
        const val keyId = "mlParsedBookFields"
        override val key = RecordPropertyKey<BoundaryMap<Any?>>(keyId)
    }

    /**
     * The sdk version that created this library item.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object createdBySDKVersion : RecordProperty<String?> {
        const val keyId = "createdBySDKVersion"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * The app version that created this library item.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object createdByAppVersion : RecordProperty<String?> {
        const val keyId = "createdByAppVersion"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * The excerpt of the library item.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object excerpt : RecordProperty<String?> {
        const val keyId = "excerpt"
        override val key = RecordPropertyKey<String?>(keyId)
    }

    /**
     * The word count of the library item.
     * Used [here](https://github.com/SpeechifyInc/platform/blob/071b4ca1f8168b81d8b219064d10da0645b930b5/services/firebase-cloud-functions/functions/src/utils/addListeningFieldsToLibraryItem/index.ts#L138) to calculate the `wordsLeft`.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object wordCount : RecordProperty<Int?> {
        const val keyId = "wordCount"
        override val key = RecordPropertyKey<Int?>(keyId)
    }

    /**
     * The char count of the library item.
     * Used [here](https://github.com/SpeechifyInc/platform/blob/b2a7b9839acada8c9437128e6e8ae9ede714586d/services/firebase-cloud-functions/functions/src/utils/addListeningFieldsToLibraryItem/index.ts#L203) to calculate the `progressFraction`.
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object charCount : RecordProperty<Int?> {
        const val keyId = "charCount"
        override val key = RecordPropertyKey<Int?>(keyId)
    }

    /**
     * Field indicating that the item has been removed.
     * Notion artifact on how the "v2" came to be: https://www.notion.so/fresh-hoodie-9f1/File-archival-deletion-restoration-implementation-8b41c758849e4a57a619ce05416220e5#a90d623824554eb99037496a6052cb3e
     * Used [here](https://github.com/SpeechifyInc/platform/blob/85aa2d046d9299caa78443c55003105b106645f9/services/firebase-cloud-functions/functions/src/firestore/library.ts#L190-L190)
     */
    @Suppress("ClassName") // No CamelCasing - we want to match the property name, despite grouping into object
    object isRemovedV2 : RecordProperty<Boolean> {
        const val keyId = "isRemovedV2"
        override val key = RecordPropertyKey<Boolean>(keyId)
    }
}
