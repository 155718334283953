package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.hasEnrichment
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.TextSourceType
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.verticalDistanceOrOverlapTo
import com.speechify.client.api.util.images.verticalDistanceTo
import com.speechify.client.helpers.content.standard.book.SuperSubScriptDetection.isSubscript
import com.speechify.client.helpers.content.standard.book.SuperSubScriptDetection.isSuperScript
import com.speechify.client.internal.util.eqWithTolerance
import com.speechify.client.internal.util.isGreaterThanOrEqualToSumOf
import com.speechify.client.internal.util.scaleToCeiling
import kotlin.math.abs
import kotlin.math.max

/**
 * A single line in a book. This is composed of [BookPageTextContentItem]s whose [BoundingBox]s suggest they all belong
 * to the same visual line
 */
internal data class Line(var chunks: MutableList<BookPageTextContentItem>, var normalizedBox: BoundingBox) {

    /**
     * The full bounding box of the line, including any super/subscripts.
     * [normalizedBox] is the core bounding box of the line, without any super/subscripts.
     */
    val fullBox: BoundingBox
        get() = chunks.fold(normalizedBox) { acc, chunk -> acc.union(chunk.normalizedBox) }

    fun getType(stats: LineStats): Type {
        if (chunks.first().text.hasEnrichment(TextEnrichment.DropCap)) {
            return Type.Paragraph
        }
        return if (normalizedBox.height > stats.usualLineHeight * 1.5) Type.Heading else Type.Paragraph
    }

    enum class Type {
        Heading,
        Paragraph,
    }

    companion object {
        private fun BookPageTextContentItem.withEnrichedRange(
            textEnrichment: TextEnrichment,
        ) = copy(
            text = text.run {
                withMetadata(metadata.copy(textEnrichments = metadata.textEnrichments + textEnrichment))
            },
        )

        private fun Line.addChunk(chunk: BookPageTextContentItem) {
            normalizedBox = if (chunk.text.hasEnrichment(TextEnrichment.Subscript) ||
                chunk.text.hasEnrichment(TextEnrichment.Superscript)
            ) {
                // We don't want to modify the boxes height or top value for super sub scripts,
                // so the detection will work for other items on the same line.
                BoundingBox.fromDimensionsAndCoordinates(
                    chunk.normalizedBox.width,
                    normalizedBox.height,
                    chunk.normalizedBox.left,
                    normalizedBox.top,
                )
                    .union(normalizedBox)
            } else {
                chunk.normalizedBox.union(normalizedBox)
            }

            chunks.add(chunk)
        }

        fun groupsFrom(content: List<BookPageTextContentItem>): MutableList<Line> {
            val lines = mutableListOf<Line>()
            for (chunk in content) {
                val current = lines.lastOrNull()

                when {
                    current == null -> lines.add(
                        Line(chunks = mutableListOf(chunk), normalizedBox = chunk.normalizedBox),
                    )

                    // This check must happen first since otherwise it will be detected as a subscript.
                    isDropCap(current, chunk) -> {
                        // We can't have the drop cap in the same chunk as the regular line since that will mess with
                        // the highlighting due to the wildly different font sizes. So we split the chunk into two.
                        // Mark the chunk as a drop cap so we have an easier time merging later.
                        val dropCappedChunk = current.chunks.removeAt(0).withEnrichedRange(TextEnrichment.DropCap)
                        current.addChunk(dropCappedChunk)
                        // And start a new line.
                        lines.add(Line(chunks = mutableListOf(chunk), normalizedBox = chunk.normalizedBox))
                    }

                    isSubscript(current.normalizedBox, chunk) ->
                        current.addChunk(chunk.withEnrichedRange(TextEnrichment.Subscript))

                    isSuperScript(current.normalizedBox, chunk) ->
                        current.addChunk(chunk.withEnrichedRange(TextEnrichment.Superscript))

                    isListicle(current, chunk) ->
                        current.addChunk(chunk)

                    // this check must happen after the subscript check since sub/super scripts look line breaks
                    // to this function
                    isLineBreak(current, chunk) ->
                        lines.add(Line(chunks = mutableListOf(chunk), normalizedBox = chunk.normalizedBox))

                    else -> current.addChunk(chunk)
                }
            }
            return lines
        }

        private fun isLineBreak(currentLine: Line, newChunk: BookPageTextContentItem): Boolean {
            fun BookPageTextContentItem.isWhitespaceOrLowChars(): Boolean {
                return this.text.text.all { c -> c.isWhitespace() || c in charArrayOf('.', ',', '_') }
            }

            fun areVerticallyAligned(previous: BoundingBox, next: BoundingBox): Boolean {
                val middleOfPrev = (previous.bottom + previous.top) / 2
                val middleOfNext = (next.bottom + previous.top) / 2
                // We can vary our strictness depending on what kind of accuracy we can expect from the data.
                val tolerance = when (newChunk.textSourceType) {
                    TextSourceType.DIGITAL_TEXT -> 0.001
                    TextSourceType.IMAGE -> 0.01
                }
                return middleOfPrev.eqWithTolerance(middleOfNext, tolerance = tolerance)
            }

            fun areInHorizontalOrder(line: Line, item: BookPageTextContentItem): Boolean {
                return when (currentLine.textDirection) {
                    // Default to LTR when line direction unknown to minimize regression risk
                    null, TextDirection.LeftToRight -> line.normalizedBox.centerX < item.normalizedBox.centerX
                    TextDirection.RightToLeft -> line.normalizedBox.centerX > item.normalizedBox.centerX
                }
            }

            return !areVerticallyAligned(
                currentLine.normalizedBox,
                if (newChunk.isWhitespaceOrLowChars()) {
                    newChunk.normalizedBox.copy(height = currentLine.normalizedBox.height)
                } else {
                    newChunk.normalizedBox
                },
            ) || !areInHorizontalOrder(currentLine, newChunk)
        }

        private fun isDropCap(currentLine: Line, newChunk: BookPageTextContentItem): Boolean {
            if (!newChunk.textSourceType.supportsTextEnrichmentDetection) {
                return false
            }

            // Only if the line contains exactly one uppercase character can it be a drop cap.
            if (currentLine.chunks.size != 1 ||
                currentLine.chunks.first().text.text.length != 1 ||
                !currentLine.chunks.first().text.text.first().isUpperCase()
            ) {
                return false
            }
            val heightRatio = currentLine.normalizedBox.height / newChunk.normalizedBox.height

            // Letter covers at least 2 or more lines.
            return heightRatio >= 2.84
        }

        private fun isListicle(currentLine: Line, newChunk: BookPageTextContentItem): Boolean {
            if (!newChunk.textSourceType.supportsTextEnrichmentDetection) {
                return false
            }

            if (currentLine.chunks.size == 1 && currentLine.chunks.first().text.text.isListSymbol() &&
                currentLine.normalizedBox verticalDistanceTo newChunk.normalizedBox <= 0
            ) {
                return true
            }

            return false
        }

        private fun String.isListSymbol(): Boolean {
            return this.length == 1 && this.first() == '●'
        }
    }
}

/**
 * A sequence of visually close lines, indicating that they are somehow related to each other.
 *
 * Each of these groups will then be qualified into one of the [StandardBlock]
 */
internal data class LineGroup(val lines: MutableList<Line>, val type: Line.Type) {
    val normalizedBox by lazy {
        lines.asSequence().map { it.normalizedBox }.reduce(BoundingBox::union)
    }

    infix fun verticalDistanceTo(that: LineGroup) = this.normalizedBox verticalDistanceTo that.normalizedBox

    companion object {
        fun groupsFrom(lines: MutableList<Line>, stats: LineStats): MutableList<LineGroup> {
            val groups = mutableListOf<LineGroup>()
            var group: LineGroup? = null
            var currentGroupStats: LineStats? = null
            var previousParagraphDistance = 0.0
            for (line in lines) {
                if (group != null &&
                    currentGroupStats != null &&
                    group.type == line.getType(stats) &&
                    !isStandardBlockBreak(group.lines.last(), line, stats, currentGroupStats, previousParagraphDistance)
                ) {
                    group.lines.add(line)
                } else {
                    if (group != null) {
                        groups.add(group)
                        previousParagraphDistance =
                            group.lines.last().fullBox verticalDistanceOrOverlapTo line.fullBox
                    }
                    group = LineGroup(mutableListOf(line), type = line.getType(stats))
                }
                currentGroupStats = LineStats.of(group.lines)
            }
            if (group != null) groups.add(group)
            return groups
        }

        private fun isStandardBlockBreak(
            lastLineInGroup: Line,
            lineToBeAdded: Line,
            globalStats: LineStats,
            groupStats: LineStats,
            previousParagraphDistance: Double,
        ): Boolean {
            val distance = lastLineInGroup.fullBox verticalDistanceOrOverlapTo lineToBeAdded.fullBox

            // If the last line is the drop cap the following line always should be grouped in the same standard block.
            if (lastLineInGroup.chunks.size == 1 &&
                lastLineInGroup.chunks.first().text.hasEnrichment(TextEnrichment.DropCap)
            ) {
                return false
            }

            // Lines are clearly spaced far apart.
            // Paragraph to Paragraph distance is usually 1.5x of the line height. We're using 1.4 to be safe
            if (distance >= globalStats.usualLineHeight.scaleToCeiling(1.45)) {
                return true
            }

            // We want to treat a large offset at end also as an end of previous paragraph.
            // To illustrate:
            // L1:       xxxxxxxxxxxxxxxxxxxxxxxxx
            // L2:       xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            // Conditions:
            // - To make sure it's actually the end of the paragraph we consider only the lines which are vertically significantly apart
            // - The end of L2 should be greater than then end of L1.
            //   # Sometimes L1 might end a bit early to accommodate large word starting at L2. We're setting the tolerance at 5% of the total width of L1

            val widthToleranceForLineEnd = lastLineInGroup.fullBox.width.scaleToCeiling(0.05)
            val isPreviousLineEndedBeforeLineToBeAddedEnd = when (lineToBeAdded.textDirection) {
                null, TextDirection.LeftToRight -> {
                    lineToBeAdded.fullBox.right.isGreaterThanOrEqualToSumOf(
                        lastLineInGroup.fullBox.right,
                        widthToleranceForLineEnd,
                    )
                }

                TextDirection.RightToLeft -> {
                    lineToBeAdded.fullBox.left.isGreaterThanOrEqualToSumOf(
                        lastLineInGroup.fullBox.left,
                        widthToleranceForLineEnd,
                    )
                }
            }

            if (distance >= globalStats.usualLineHeight && isPreviousLineEndedBeforeLineToBeAddedEnd) {
                return true
            }

            val heightDifference = abs(lastLineInGroup.normalizedBox.height - lineToBeAdded.normalizedBox.height)
            // Upper case letter's height are generally 20-30% larger than lowercase letters
            // `heightDifferenceThreshold` is set to tolerate up to 30% of the line height
            val heightDifferenceThreshold = lineToBeAdded.normalizedBox.height * 0.3
            val isDifferentFontSize = heightDifference > 0.02 || heightDifference > heightDifferenceThreshold

            // Font size changes should always result in a new paragraph.
            if (isDifferentFontSize && distance >= globalStats.usualLineHeight) {
                return true
            }

            // The line to be added is a bullet point
            if (lineToBeAdded.chunks.first().text.text.isNotEmpty() &&
                lineToBeAdded.chunks.first().text.text.first().isBulletPoint()
            ) {
                return true
            }

            // We want to treat a large offset at start also as a new paragraph.
            // This can be the case for centered titles, or if a new paragraph is started with a tab.
            // To illustrate:
            // L1:                  xxxx
            // L2:       xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            // or
            // L1:       xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            // L2:            xxxxxxxxxxxxxxxxxxxxxxxxxxxx
            // We take maximum height of the two lines. This serves as a proxy for font size.
            // x
            // And calculate as a fraction how many characters the inset is big
            // xxxxxxxxxxx (11)
            // is.
            // If the inset is large enough we treat it as a new paragraph.
            // In the context of larger work on improvements this will be even more accurate
            // once we split the page into large constructs (columns, margins, headers, footers) as we can fine tune
            // this logic more.
            val fontSizeApproximation = max(lastLineInGroup.normalizedBox.height, lineToBeAdded.normalizedBox.height)

            val charactersOffset = when (lastLineInGroup.textDirection) {
                // Default to LTR when last line direction unknown to minimize regression risk
                null, TextDirection.LeftToRight ->
                    (lastLineInGroup.normalizedBox.left - lineToBeAdded.normalizedBox.left)
                TextDirection.RightToLeft ->
                    -1 * (lastLineInGroup.normalizedBox.right - lineToBeAdded.normalizedBox.right)
            } / fontSizeApproximation

            // For example if we have a listing with the content perfectly aligned:
            // 1. aaaa
            //    aaaa
            // 2. aaaa
            //    aaaa
            // This makes sure that the second line will not be treated as an inset paragraph.
            val isAlignedWithSecondChunk = when (lastLineInGroup.textDirection) {
                // Default to LTR when last line direction unknown to minimize regression risk
                null, TextDirection.LeftToRight ->
                    lastLineInGroup.chunks
                        .getOrNull(1)?.normalizedBox?.left?.eqWithTolerance(
                            lineToBeAdded.normalizedBox.left,
                            tolerance = 0.001,
                        )
                TextDirection.RightToLeft ->
                    lastLineInGroup.chunks
                        .getOrNull(1)?.normalizedBox?.right?.eqWithTolerance(
                            lineToBeAdded.normalizedBox.right,
                            tolerance = 0.001,
                        )
            } ?: false

            val isOffsetOnXAxisToPreviousLine = (
                // This captures case one where a centered header is above a paragraph.
                charactersOffset >= 6 ||
                    // This captures case two where the next line is indented to indicate a new paragraph starting.
                    charactersOffset <= -1.1
                )

            // Offset always indicated a new paragraph.
            if (isOffsetOnXAxisToPreviousLine && !isAlignedWithSecondChunk) {
                return true
            }

            // We choose a very tiny fraction here, something that visually would definitely look like it belongs.
            val fractionLineHeight = groupStats.usualLineHeight * 0.02
            // This check ensures that in otherwise perfectly laid out PDFs (usualLineDistance is 0)
            // items that are just barely off still are considered to be part of the same line group.
            val isSpacedFurtherThanLineHeightFraction = distance > fractionLineHeight

            // Lines are clearly spaced close together.
            if (distance <= globalStats.usualLineDistance.scaleToCeiling(1.5) ||
                !isSpacedFurtherThanLineHeightFraction
            ) {
                return false
            }

            // if the previous line ended with a hyphen and the distance is small, we can assume that it's part of
            // the same paragraph.
            if (distance < lineToBeAdded.fullBox.height &&
                lastLineInGroup.chunks.last().text.text.trimEnd().last().isLineBreakHyphen()
            ) {
                return false
            }

            // If we already have specific data for this paragraph rely on that.
            if (groupStats.numberOfLines > 1) {
                if (lastLineInGroup.chunks.last().text.text.last().isLineBreakHyphen()) {
                    // If the previous line terminated in a hyphen, we must make sure the distance is considerable
                    return distance > groupStats.usualLineHeight
                }
                return distance > groupStats.usualLineDistance.scaleToCeiling(1.5)
            } else if (distance < lineToBeAdded.fullBox.height / 4 &&
                distance.isAlmostEqual(previousParagraphDistance)
            ) {
                // If the distance a lot smaller than a line heigh and almsot equal to the previous line hegith,
                // we can assume that it's part of the same paragraph.
                return false
            } else if (distance < previousParagraphDistance) {
                // We can look back to the spacing to the previous line to see if the new gap is much shorter.
                // Essentially this logic detects situations like this:
                // L: Content
                // L:
                // L:
                // L:
                // L:             previousParagraphDistance = 4
                // L: Content   \
                // L:           | distance = 1
                // L: (Content) | - Will be grouped into one paragraph since they are closer than previous gap.
                // L:           |
                // L: Content   /
                // L:
                // L:
                // L: Content | - Will be new paragraph since the usualLineDistance will be 1 and distance here is 2.

                // This ratio represents the fraction the distance is compared to the previous paragraph distance.
                // 1 - They are the same size.
                // 0.5 - Distance from current line to previous is half, of previous to their previous.
                val ratio = distance / previousParagraphDistance
                return ratio >= 0.25
            }

            // If we find no reason for the lines to belong together we treat it as a paragraph.
            return true

            /*
            // very useful for debugging, so I'm keeping it commented here
              .also {
              println(
                  """
                  this: ${this.chunks.map { it.text.text }.joinToString(" ")}
                  that: ${block.chunks.map { it.text.text }.joinToString(" ")}
                  distance: $distance
                  heightDif: $heightDifference
                  stats: ${stats.usualLineDistance}
                  stats.5: ${stats.usualLineDistance * 1.5}
                  => $it
                  """.trimIndent(),
              )
              }
             */
        }
    }
}

fun Double.isAlmostEqual(b: Double, tolerance: Double = 1e-11): Boolean {
    return kotlin.math.abs(this - b) <= tolerance
}
