package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.StandardViewBase
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import kotlin.js.JsExport

/**
 * A [StandardView] wrapping a fixed set of [StandardBlock]s.
 *
 * See [Builder] for means of construction.
 */
@JsExport
class StaticStandardView private constructor(val blocks: MutableList<StandardBlock>) :
    StandardViewBase(),
    ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent {
    override val start: ContentCursor get() = blocks.first().start
    override val end: ContentCursor get() = blocks.last().end

    override suspend fun getBlocksAroundCursor(cursor: ContentCursor): Result<StandardBlocks> =
        StandardBlocks(blocks.toTypedArray(), start, end).successfully()

    override suspend fun getBlocksBetweenCursors(start: ContentCursor, end: ContentCursor): Result<StandardBlocks> {
        val between = blocks.filter { !it.end.isBefore(start) && !it.start.isAfter(end) }

        return StandardBlocks(
            between.toTypedArray(),
            between.firstOrNull()?.start ?: start,
            between.lastOrNull()?.end ?: end,
        ).successfully()
    }

    override fun destroy() {
        blocks.clear()
    }

    companion object {

        /**
         * Constructs a [StaticStandardView] from the [blocks] provided.
         *
         * [blocks] must be non-empty.
         */
        internal fun fromBlocks(blocks: MutableList<StandardBlock>): StaticStandardView {
            check(blocks.isNotEmpty()) {
                "StaticStandardView cannot be constructed from an empty list of blocks."
            }
            return StaticStandardView(blocks)
        }
    }

    /**
     * A utility class that lets you build a [StandardView] from a fixed sequence of text content that you can already map to the [StandardBlock] data model.
     *
     * This class handles the conversion of text to [ContentText] for you!
     */
    class Builder : StandardViewProducerBase<Builder>() {
        /**
         * Constructs a [StandardView] with the blocks accumulated by the builder
         *
         * Returns `null` if no content was added to the builder.
         */
        fun build(): StaticStandardView? {
            return if (blocks.isEmpty()) null else fromBlocks(blocks)
        }

        override fun appendBlocks(newBlocks: Iterable<StandardBlock>) {
            blocks += newBlocks
        }

        private val blocks = mutableListOf<StandardBlock>()
    }
}
