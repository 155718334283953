package com.speechify.client.api.util

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.launchAsync
import com.speechify.client.internal.sync.BlockingWrappingMutex
import kotlinx.coroutines.awaitAll
import kotlin.js.JsExport

@JsExport
class DeviceResourceManager {
    /**
     * Evicts non-essential in-memory cached objects. For use in high memory pressure situations - can lead to
     * user experience degradation, so it's important to make the tradeoff between user experience under memory
     * pressure vs user experience with cache cleared.
     */
    fun evictNonEssentialInMemoryCachedObjects(
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) = callback.fromCo {
        InMemoryCacheManager.destroyAll().successfully()
    }
}

/**
 * An "in-memory cache manager" used for managing in-memory objects by registering destructors for manual destruction
 */
internal object InMemoryCacheManager {
    // internal for testing
    internal val destructors = BlockingWrappingMutex.of(mutableSetOf<AsyncDestructor>())

    /**
     * Registers a destructor with the manager. Returns a destructor for the caller to use to manage the
     * object lifecycle by themselves. Do note that it's important to invoke the returned destructor at the end of
     * the object lifecycle, to make sure that the object is released for garbage collection.
     */
    internal fun register(destructor: AsyncDestructor): AsyncDestructor {
        destructors.locked {
            it.add(destructor)
        }
        return {
            destructors.locked {
                it.remove(destructor)
            }
            destructor()
        }
    }

    /**
     * Invokes all destructors under management.
     */
    internal suspend fun destroyAll() {
        val destructorsToInvoke = destructors.locked {
            val copy = it.toList()
            copy
        }
        val deferred = destructorsToInvoke.map { destructor ->
            launchAsync {
                try {
                    destructor()
                } catch (e: Exception) {
                    Log.e(
                        DiagnosticEvent(
                            message = "Destructor invocation failed",
                            sourceAreaId = "InMemoryCacheManager",
                            error = ErrorInfoForDiagnostics(nativeError = e),
                        ),
                    )
                }
            }
        }
        deferred.awaitAll()
    }
}

/**
 * Returns a lambda that ensures the given [block] is executed at most once. To be used when registering a destructor
 * that isn't idempotent, for example.
 */
internal fun calledAtMostOnce(block: suspend () -> Unit): suspend () -> Unit {
    var called = false
    return {
        if (!called) {
            try {
                block()
            } finally {
                called = true
            }
        }
    }
}
