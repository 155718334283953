package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.helpers.content.standard.ContentProvider
import kotlinx.coroutines.flow.Flow

abstract class SpeechFlowProvider : ContentProvider {
    /**
     * If [startingPoint] is `null`, the flow will start from the beginning of the content.
     *
     * The resulting flow always attempts to start at the beginning of a sentence, so if the [startingPoint] points in the
     * middle of a sentence, the flow will start **not** from that point, but from the beginning of the sentence containing
     * that middle (if [startingPoint] points at the beginning of a sentence then the flow will not add any sentences but
     * start from [startingPoint]).
     */
    internal abstract fun getFullSentencesFlowFromSentenceContaining(
        startingPoint: ContentCursor?,
    ): SpeechFlow
}

internal typealias SpeechFlow = Flow<SpeechSentence>
