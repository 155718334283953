package com.speechify.client.reader.classic

import kotlin.js.JsExport

@JsExport
data class ClassicView(
    val blocksInView: Array<ClassicBlock>,
    val navigationIntent: CoarseClassicNavigationIntent?,
    val hasMoreBefore: Boolean,
    val hasMoreAfter: Boolean,
    val isLoadingMoreBefore: Boolean,
    val isLoadingMoreAfter: Boolean,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ClassicView

        if (!blocksInView.contentEquals(other.blocksInView)) return false
        if (navigationIntent != other.navigationIntent) return false
        if (hasMoreBefore != other.hasMoreBefore) return false
        if (hasMoreAfter != other.hasMoreAfter) return false
        if (isLoadingMoreBefore != other.isLoadingMoreBefore) return false
        if (isLoadingMoreAfter != other.isLoadingMoreAfter) return false

        return true
    }

    override fun hashCode(): Int {
        var result = blocksInView.contentHashCode()
        result = 31 * result + (navigationIntent?.hashCode() ?: 0)
        result = 31 * result + hasMoreBefore.hashCode()
        result = 31 * result + hasMoreAfter.hashCode()
        result = 31 * result + isLoadingMoreBefore.hashCode()
        result = 31 * result + isLoadingMoreAfter.hashCode()
        return result
    }
}
