package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class OneClickRenewal {

    data class Stripe(
        /** An optional promotion code to apply to the renewal. */
        val promotionCode: String?,

        /** An optional Stripe price id to use for the renewal. */
        val priceId: String?,
    ) : OneClickRenewal()
}
