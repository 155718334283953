package com.speechify.client.bundlers.reading.embedded

import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.listening.ListeningBundle
import com.speechify.client.bundlers.reading.BasicReadingBundleWithContent
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.helpers.ui.controls.PlaybackControls
import kotlin.js.JsExport

/**
 * Everything you need to deliver an embedded reading experience.
 */
@JsExport
class EmbeddedReadingBundle(
    playbackControls: PlaybackControls,
) : EmbeddedReadingBundleBase<ContentBundle>(
    playbackControls = playbackControls,
) {
    override val contentBundle: ContentBundle get() = listeningBundle.contentBundle
}

@JsExport
abstract class EmbeddedReadingBundleBase<out TContentBundle : ContentBundle>(
    override val playbackControls: PlaybackControls,
) : BasicReadingBundleWithContent<TContentBundle>() {

    val listeningBundle: ListeningBundle get() = playbackControls.listeningBundle

    val contentImporter: ContentImporter get() = listeningBundle.contentBundle.importer

    /**
     * Destroy all resources held by this bundle
     */
    fun destroy() {
        playbackControls.destroy()
        listeningBundle.destroyPlaybackLeaveContent()
        listeningBundle.contentBundle.destroy()
    }
}
