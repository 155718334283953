package com.speechify.client.api.content.view.book

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.internal.WithScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * A way to access the all the content necessary to read and listen to Book-like content
 */
@JsExport
abstract class BookView : WithScope(), Content, Destructible {

    internal abstract val parsedPageContentFlow: MutableSharedFlow<Pair<BookPageIndex, ParsedPageContent>>
    internal abstract val mlParsingModeFlow: StateFlow<MLParsingMode>

    internal abstract val bookEditsFlow: MutableSharedFlow<BookEdits>

    internal abstract val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy>

    internal abstract fun initializeBookViewFlows()

    internal abstract fun search(
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        searchOptions: BookSearchOptions,
        callback: Callback<Array<BookSearchResult>>,
    )

    /**
     * The metadata describing the general attributes of the Book
     */
    @JsName("getMetadata")
    abstract fun getMetadata(): BookMetadata

    /**
     * Fetch the data for the specified pages
     */
    @JsName("getPages")
    abstract fun getPages(pageIndexes: Array<Int>, callback: Callback<Array<BookPage>>)

    /* note(peterz):Throws annotation is here because of a crash on iOS. Make sure to remove this if we see no
       error logs around this area in Crashlytics or Amplitude in a few months. (Feb 15, 2024)
    */
    @Throws(Throwable::class)
    abstract fun getPageIndex(cursor: ContentCursor): Int

    /**
     * This function tries to retrieve a usable cursor based on a saved remotely one [originalCursor], which may
     * undergo changes when content sorting is enabled.
     */
    internal abstract suspend fun translateToUsableCursor(originalCursor: ContentCursor): ContentCursor?

    internal abstract suspend fun getTableOfContents(): TableOfContents?

    internal suspend fun notifyTextContentRetrieved(pageIndex: Int, parsedPageContent: ParsedPageContent) {
        parsedPageContentFlow.emit(pageIndex to parsedPageContent)
    }
}

/**
 * Resolve each cursor to the index of the page that contains it
 *
 * The result will contain the same number of elements as the [cursors] (error will be thrown
 * if any cursor did not correspond to a page).
 */
internal fun BookView.getPageIndexes(cursors: Array<ContentCursor>): Array<Int> =
    cursors.map {
        getPageIndex(it)
    }
        .toTypedArray()

internal suspend fun BookView.coGetPages(pageIndexes: Array<Int>): Result<Array<BookPage>> =
    suspendCancellableCoroutine { getPages(pageIndexes, it::resume) }

internal suspend fun BookView.coSearch(
    text: String,
    startPageIndex: BookPageIndex,
    endPageIndex: BookPageIndex,
    searchOptions: BookSearchOptions,
): Result<Array<BookSearchResult>> =
    suspendCoroutine { search(text, startPageIndex, endPageIndex, searchOptions, it::resume) }
