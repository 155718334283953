package com.speechify.client.internal.sync

/**
 * This list will drop items that are added when the list is already full.
 * Use [AtomicCircularFixedList] if you want new items to push out older items.
 */
internal class AtomicDroppingFixedList<T> (
    /**
     * NOTE: `0` is permitted, which means the collection throws-away any [add]s, but this can be dynamically raised
     * with [setMaxSizePreservingExcess] - see there for details.
     */
    maxSize: Int,
) {
    /**
     * Returns the current maximum size of the list.
     *
     * NOTE: `0` is permitted which means the collection throws-away any [add]s.
     */
    val maxSize: Int
        get() = maxSizeMutable.value

    private val maxSizeMutable = AtomicInt(maxSize)

    /**
     * NOTE: If the new [maxSizeMutable] is smaller than the current size of the list, the list will **not** be truncated to
     * the new size immediately.
     * This is fine for many uses, especially for independent concurrent consumption, because if there was excess,
     * then whether the consumer ends up consuming the excess or not would be a race condition anyway.
     *
     * NOTE: `0` is permitted, which will mean the collection throws-away any [add]s, but this can be dynamically raised
     * with another call to [setMaxSizePreservingExcess].
     */
    fun setMaxSizePreservingExcess(newMaxSize: Int) {
        this.maxSizeMutable.value = newMaxSize
    }

    private val blockingMutex = BlockingMutex()
    private val _list = mutableListOf<T>()
    val size: Int
        get() = blockingMutex.withLock { _list.size }

    fun clear() = blockingMutex.withLock { _list.clear() }

    /**
     * adds an item to the list, but, when the current size of the array is equal to [maxSizeMutable]
     * it will not add the requested item. Keeping the existing items in the list.
     */
    fun add(element: T): Unit = blockingMutex.withLock {
        val maxSize = maxSizeMutable.value
        if (_list.size >= maxSize) {
            return@withLock
        }

        _list.add(element)
    }

    /**
     * adds all items to the list. If the list is full the given items will be added at the beginning dropping items from the end.
     */
    fun prependAll(elements: List<T>): Unit = blockingMutex.withLock {
        elements.reversed().forEach {
            if (_list.size >= maxSize) {
                _list.removeAt(_list.lastIndex)
            }

            _list.add(0, it)
        }
    }

    operator fun get(index: Int) = blockingMutex.withLock {
        _list[index]
    }

    fun find(predicate: (T) -> Boolean): T? = blockingMutex.withLock {
        _list.find(predicate)
    }

    /**
     * Performs a shallow copy of the list and clear as an atomic operation and returns the copied list the caller
     */
    fun takeAll(): MutableList<T> = blockingMutex.withLock {
        val copiedList = _list.toMutableList()
        _list.clear()
        copiedList
    }
}
