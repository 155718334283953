package com.speechify.client.reader.core

import com.speechify.client.api.util.Destructible
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.reader.core.utils.SelectionUtils
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.cancel
import kotlin.js.JsExport

@JsExport
/**
 * EXPERIMENTAL API
 *
 * The [Reader] is our top-level API for building reading experience UIs, exposing observable state and imperative
 * command APIs for ALL the data elements and interactions in the experience.
 *
 * This component is intended to COMPLETELY REPLACE all access to the bundles, views, and related components that
 * consumers previously had to wire up themselves to build these experiences.
 *
 * We provide instances designed specifically for each kind of reading experience at Speechify -
 * see [com.speechify.client.reader.classic.ClassicReader] for "Classic Mode" and
 * [com.speechify.client.reader.fixedlayoutbook.FixedLayoutBookReader] for "Original Mode".
 *
 * All functionality of a [Reader] is exposed via [Helper]s.
 */
abstract class Reader internal constructor(internal val scope: CoroutineScope) : Destructible {
    abstract val titleHelper: TitleHelper
    abstract val importHelper: ImportHelper
    abstract val playbackHelper: PlaybackHelper
    abstract val scrubberHelper: ScrubberHelper
    abstract val userHighlightsHelper: UserHighlightsHelper
    abstract val selectionHelper: SelectionHelper
    abstract val autoscrollHelper: AutoscrollHelper
    abstract val hoveredSentenceHelper: HoveredSentenceHelper
    abstract val analyticsHelper: AnalyticsHelper
    abstract val listenedWordsCountHelper: ListenedWordsCountHelper
    abstract val premiumStateHelper: PremiumStateHelper
    abstract val skipSettingsHelper: SkipSettingsHelper
    abstract val tableOfContentsHelper: TableOfContentsHelper
    abstract val paywallHelper: PaywallHelper
    abstract val contextualActionHelper: ContextualActionHelper
    abstract val selectionUtils: SelectionUtils
    abstract val searchHelper: SearchHelper

    internal abstract val navigationHelper: NavigationHelper
    internal abstract val focusHelper: FocusHelper
    internal abstract val bundle: ReadingBundle

    override fun destroy() {
        scope.cancel()
    }
}
