package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseSubscriptionPlanEntitlements(
    val wordsLimit: Int?,
    val audiobookCredits: Int? = 0,
    val avatarsSeconds: Int? = 0,
    val dubbingSeconds: Int? = 0,
    val voiceoverSeconds: Int? = 0,
    val ttsExportSeconds: Int? = 0,
) {
    companion object {
        val EMPTY =
            FirebaseSubscriptionPlanEntitlements(
                wordsLimit = 0,
                audiobookCredits = 0,
                avatarsSeconds = 0,
                dubbingSeconds = 0,
                voiceoverSeconds = 0,
                ttsExportSeconds = 0,
            )
    }
}

@Serializable
internal data class FirebaseSubscriptionPlan(
    val trial: Boolean?,
    val name: String? = null,
    val period: String,
    val price: Double,
    val renewPeriod: Int,
    val renewPeriodUnit: String,
    val source: String? = null,
    val trialPeriod: Int? = null,
    val conversionId: String? = null,
    val discounts: Array<String>? = null,
    val priceLocale: String? = null,
    /** voiceOver subscription */
    val voiceOver: Boolean? = false,
    /** List of product types that are included in this plan */
    var productTypes: Array<String>? = arrayOf(),
    /** Plan entitlements such as wordsLimits */
    var entitlements: FirebaseSubscriptionPlanEntitlements = FirebaseSubscriptionPlanEntitlements.EMPTY,
)
