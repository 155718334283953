package com.speechify.client.test

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import kotlin.js.JsExport

/**
 * Test suit to validate a functionality with a list of tests.
 *
 * Child of this class must provide the list of test cases to run via [tests] method.
 */
@JsExport
abstract class BreakingChangeTestSuit {
    private var passedTest = 0
    private var totalTest = 0
    private val testResults = mutableListOf<TestResult>()

    /**
     * @return list of test cases to run
     */
    internal abstract fun tests(): List<suspend () -> TestResult>

    fun run(callback: Callback<ValidationResult>) {
        reset()
        callback.fromCo {
            tests().forEach { test ->
                totalTest++
                val result = test.invoke()
                if (result is TestResult.Success) {
                    passedTest++
                }
                testResults.add(
                    result,
                )
            }
            return@fromCo Result.Success(
                ValidationResult(
                    isValid = totalTest == passedTest,
                    validationsPassed = passedTest,
                    totalValidations = totalTest,
                    testResults = testResults.toTypedArray(),
                ),
            )
        }
    }

    private fun reset() {
        passedTest = 0
        totalTest = 0
        testResults.clear()
    }
}

/**
 * Result of the search validation
 * @property isValid Whether all validations passed
 * @property validationsPassed Number of validations that passed
 * @property totalValidations Total number of validations run
 * @property testResults List of results from test cases ran
 */
@JsExport
data class ValidationResult(
    val isValid: Boolean,
    val validationsPassed: Int,
    val totalValidations: Int,
    val testResults: Array<TestResult>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ValidationResult

        if (isValid != other.isValid) return false
        if (validationsPassed != other.validationsPassed) return false
        if (totalValidations != other.totalValidations) return false
        if (!testResults.contentEquals(other.testResults)) return false

        return true
    }

    override fun hashCode(): Int {
        var result = isValid.hashCode()
        result = 31 * result + validationsPassed
        result = 31 * result + totalValidations
        result = 31 * result + testResults.contentHashCode()
        return result
    }
}

@JsExport
sealed class TestResult {
    data class Success(val description: String) : TestResult()
    data class Failure(val description: String, val reason: String) : TestResult()
}
