package com.speechify.client.internal.services.book

import com.speechify.client.api.SpeechifyVersions
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.ml.models.BoxCoordinates
import com.speechify.client.internal.services.ml.models.LabeledPageTextGroup
import com.speechify.client.internal.services.ml.models.LabeledPageTextItem
import com.speechify.client.internal.services.scannedbook.orderedPageIdsToBoundaryMap
import com.speechify.client.internal.services.scannedbook.toFirestorePayload
import com.speechify.client.internal.util.boundary.toBoundaryMap

internal fun buildMlParsedBookPageFirestorePayload(
    owner: String,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    firestoreLabeledPageTextGroups: Array<LabeledPageTextGroup>,
    viewport: Viewport,
    generatedBySoftwareVersion: String,
) =
    /** see [com.speechify.client.internal.services.book.FirestoreMLParsedPageModel] */
    mapOf(
        RecordProperties.owner.key.toPairWithVal(owner),
        "createdAt" to firebaseTimestampAdapter.now(),
        "labeledPageTextGroups" to firestoreLabeledPageTextGroups.map {
            it.toFirestorePayload().toBoundaryMap()
        }.toTypedArray(),
        "viewport" to viewport.toFirestorePayload().toBoundaryMap(),
        /**
         *  According to the thread here[https://speechifyworkspace.slack.com/archives/C05QD4CA5J5/p1701366928332159],
         *  The current ml-endpoint does not support the detection of `DropCap`, `SuperScript` and `Subscript` thus the
         *  [com.speechify.client.api.content.view.book.LabeledBookPageTextContentItem.type] will be empty.
         *  For now, we use heuristics to detect those. Consequently, we added the `createdBySDKVersion` and
         *  `generatedBySoftwareVersion` to the MlParsedBookPageFirestorePayload for future tracking.
         *  E.g: once the new ml-processing that supports labels detection is ready, we can for example re-run the
         *  parsing for those pages.
         */
        "generatedBySoftwareVersion" to generatedBySoftwareVersion,
        "createdBySDKVersion" to SpeechifyVersions.SDK_VERSION,
    )

private fun LabeledPageTextGroup.toFirestorePayload() = mapOf(
    "labeledPageTextItems" to this.labeledPageTextItems.map {
        it.toFirestorePayload().toBoundaryMap()
    }.toTypedArray(),
    "type" to this.type.serializedValue,
)

private fun LabeledPageTextItem.toFirestorePayload() = mapOf(
    "type" to this.type.serializedValue,
    "text" to this.text,
    "box" to this.box.toFirestorePayload().toBoundaryMap(),
)

private fun BoxCoordinates.toFirestorePayload() =
    mapOf(
        "left" to this.left,
        "right" to this.right,
        "top" to this.top,
        "bottom" to this.bottom,
    )

internal fun mlParsedBookFields(
    orderedPageIds: Sequence<String>,
) = mapOf(
    RecordProperties.mlParsedBookFields.key.toPairWithVal(
        mapOf(
            RecordProperties.pageOrdering.key.toPairWithVal(orderedPageIdsToBoundaryMap(orderedPageIds)),
        ).toBoundaryMap(),
    ),
)
