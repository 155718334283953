package com.speechify.client.api.content

import com.speechify.client.api.content.ocr.ExperimentalStrategyImplementation
import com.speechify.client.api.content.ocr.LegacyStrategyImplementation
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.book.BookPageTextContentItem

internal fun shouldRunOcrFallback(
    ocrFallbackStrategy: OcrFallbackStrategy,
    textContent: List<BookPageTextContentItem>,
): Boolean =
    when (ocrFallbackStrategy) {
        is OcrFallbackStrategy.ForceEnable -> true
        is OcrFallbackStrategy.ForceDisable -> false
        is OcrFallbackStrategy.ConservativeLegacyStrategy ->
            LegacyStrategyImplementation.shouldApplyOcrFallback(textContent)
        is OcrFallbackStrategy.ExperimentalStrategy ->
            ExperimentalStrategyImplementation.shouldApplyOcrFallback(textContent)
    }
