package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformSubscriptionPaymentIntent(
    @SerialName("client_secret")
    val clientSecret: String? = null,
)

@Serializable
internal data class PlatformSubscriptionCreationResponse(
    val message: String? = null,
    val err: String? = null,
    val paymentIntent: PlatformSubscriptionPaymentIntent? = null,
)

@Serializable
internal data class PlatformCreateSubscriptionErrorResponse(
    val message: String? = null,
    val detail: PlatformCreateSubscriptionErrorResponseDetails? = null,
)

@Serializable
internal data class PlatformCreateSubscriptionErrorResponseDetails(
    val code: String,
    val currencyISO: String? = null,
    val priceCents: Int? = null,
)
