package com.speechify.client.bundlers.listening

import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.services.library.offline.VoiceAudioDownloadInfo
import com.speechify.client.api.util.collections.flows.NeverEndingCallbackStateFlowOfNonNulls
import com.speechify.client.api.util.collections.flows.toNeverEndingCallbackStateFlowOfNonNulls
import com.speechify.client.bundlers.content.ContentBundle
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

/**
 * A simple container containing everything you need to start listening to a piece of content
 */
@JsExport
class ListeningBundle internal constructor(
    /**
     * The configuration with which this bundle was bundled.
     */
    // TODO(anson): remove the need for this by adding sync getter for speed to AudioController
    internal val config: ListeningBundlerConfig,

    /**
     * The [audioController] enables you to control playback of the content
     */
    val audioController: AudioController,

    /**
     * The [contentBundle] enables you to display a reading experience that is synchronized with the [audioController]
     */
    val contentBundle: ContentBundle,

    /**
     * Those of the voices specified in [ListeningBundlerConfig.allVoices] that are available on the device.
     */
    @Suppress(
        /* Compiler glitch? The member is private */
        "NON_EXPORTABLE_TYPE",
    )
    private val voicesAvailableFromConfig: List<VoiceSpecOfAvailableVoice>,

    /**
     * Lists the voice audio downloads for the document (possibly only for part of the document), performed earlier
     * via [com.speechify.client.api.services.library.offline.OfflineAvailabilityManager.makeLibraryItemAvailableOffline]).
     */
    val voicesAudioDownloads: Array<VoiceAudioDownloadInfo>,

    // TODO_NICETOHAVE: Maybe we should also report where the gap is reported, it could allow product teams to do cool
    // things like warn the user that their voice is gonna run out in X minutes.
    hasDetectedGapsInDownloadedAudioThisListeningSessionFlow: StateFlow<Boolean>,
) {
    /**
     * Those of the voices specified in [ListeningBundlerConfig.allVoices] that are available on the device, and you can
     * use them to control playback, combined with the voices available in [voicesAudioDownloads].
     *
     * Populating this member using [ListeningBundlerConfig.allVoices] is optional, will especially be the case for
     * SDK consumers who maintain their own list of [VoiceSpecOfAvailableVoice], in which case the collection will be
     * empty, unless any voices have audio downloaded.
     */
    val voices: Array<DocumentVoiceInfo> get() =
        (
            voicesAudioDownloads.map {
                DocumentVoiceInfo(
                    voice = it.lastDownloadOptions.voice,
                    audioDownload = it,
                )
            } +
                /* NOTE: this being after `voicesAudioDownloads` is important, so that `distinctBy`
                 * prefers the voice with the download status, if one exists (it takes the first item found).
                 */
                voicesAvailableFromConfig
                    .map { voiceSpec ->
                        DocumentVoiceInfo(
                            voice = voiceSpec,
                            audioDownload = null,
                        )
                    }
            )
            .distinctBy { it.voice.idQualified }
            .toTypedArray()

    /**
     * See [com.speechify.client.api.services.library.models.ContentItemAudioDownloadsInfo.hasGaps].
     */
    val hasDetectedGapsInDownloadedAudioThisListeningSession: NeverEndingCallbackStateFlowOfNonNulls<Boolean> =
        hasDetectedGapsInDownloadedAudioThisListeningSessionFlow
            .toNeverEndingCallbackStateFlowOfNonNulls()

    /**
     * Destroy the resources held by this bundle that are responsible for playback.
     * NOTE: Does not destroy the [contentBundle], since it is used by other processes, e.g. for import.
     */
    fun destroyPlaybackLeaveContent() {
        audioController.destroy()
    }
}

/**
 * Carries document-specific information about a [VoiceSpecOfAvailableVoice] instance.
 */
@JsExport
class DocumentVoiceInfo(
    val voice: VoiceSpecOfAvailableVoice,
    /**
     * Non-null if the voice is available offline. For more details check the [VoiceAudioDownloadInfo].
     */
    val audioDownload: VoiceAudioDownloadInfo?,
)
