package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetImage
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.book.getPageIndexes
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.util.extensions.collections.flows.debounceInstances
import com.speechify.client.internal.util.extensions.collections.flows.flatMapLatestForEachInstance
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.reader.core.BookEditorHelper
import com.speechify.client.reader.core.CoreCommand
import com.speechify.client.reader.core.FocusCommand
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.HoveredSentenceState
import com.speechify.client.reader.core.PlaybackState
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ResolvedNavigationIntent
import com.speechify.client.reader.core.RobustLocation
import com.speechify.client.reader.core.SearchState
import com.speechify.client.reader.core.SelectionState
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.core.UserHighlightsList
import com.speechify.client.reader.core.createReaderFeaturesFlow
import com.speechify.client.reader.fixedlayoutbook.overlay.FixedLayoutOverlayStrategy
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class FixedLayoutBookViewHelper internal constructor(
    scope: CoroutineScope,
    private val bookView: BookView,
    private val playbackStateFlow: Flow<PlaybackState>,
    private val selectionStateFlow: Flow<SelectionState>,
    private val highlightsInView: Flow<UserHighlightsList>,
    private val navigationIntentsFlow: Flow<ResolvedNavigationIntent>,
    private val hoveredSentenceStateFlow: Flow<HoveredSentenceState>,
    private val searchStateFlow: Flow<SearchState>,
    private val bookEditorHelper: BookEditorHelper,
    private val overlayStrategy: FixedLayoutOverlayStrategy,
    initialConfig: FixedLayoutReaderConfig,
    initialLocation: CompletableDeferred<RobustLocation>,
) : Helper<FixedLayoutBookView>(scope) {
    private val navigationHelper = FixedLayoutBookNavigationHelper(scope, navigationIntentsFlow)
    private val pagesInFocusFlow: MutableStateFlow<Map<Int, FixedLayoutPageHelper>> = MutableStateFlow(emptyMap())
    private val zoomLevelFlow = MutableStateFlow(initialConfig.initialZoomLevel)

    private val readerFeatures = createReaderFeaturesFlow(
        playbackStateFlow,
        selectionStateFlow,
        highlightsInView,
        navigationHelper.fineIntentsFlow,
        hoveredSentenceStateFlow,
        searchStateFlow,
    ).stateInHelper(
        initialValue = ReaderFeatures.Empty,
    )

    private val originalBookView = when (bookView) {
        is EditingBookView -> bookView.originalBookView
        else -> bookView
    }

    init {
        launchInHelper {
            val location = initialLocation.await()
            val (initialPageIndex) = originalBookView.getPageIndexes(arrayOf(location.hack.cursor))
            val (startIndex, endIndexExclusive) = calculateFocusIndices(
                pageIndex = initialPageIndex,
                totalPages = originalBookView.getMetadata().numberOfPages,
                extraPagesInEachDirection = EXTRA_PAGES_IN_EACH_DIRECTION,
            )

            updatePagesInFocus(startIndex = startIndex, endIndexExclusive = endIndexExclusive)

            // We intentionally wait for the initial location before subscribing to commands.
            // This deferred initialization pattern allows clients to set up the UI scaffolding
            // while the location loads, rather than blocking the entire `ListeningExperience` construction.
            // It ensures commands are only processed after proper initialization.

            commands
                .onEachInstance<FixedLayoutBookReaderViewCommand.SetZoom> { command ->
                    zoomLevelFlow.value = command.zoomLevel
                    pagesInFocusFlow.value.values.forEach {
                        it.setZoomLevel(command.zoomLevel)
                    }
                }
                .onEachInstance<CoreCommand.ReloadContent> {
                    // clear pages in focus because we are willing to reload the content.
                    pagesInFocusFlow.value.values.forEach {
                        it.destroy()
                    }
                    pagesInFocusFlow.value = emptyMap()
                    val (pageIndex) = originalBookView.getPageIndexes(arrayOf(it.location.hack.cursor))
                    setFocusAroundPage(pageIndex, extraPagesInEachDirection = EXTRA_PAGES_IN_EACH_DIRECTION)
                }
                // used here flatMapLatest variant to make sure any previous operations is automatically cancel when receiving
                // a new command.
                // Important Note: Any new command added after flatMapLatestForEachInstance will not be triggered.
                // Therefore, ensure flatMapLatestForEachInstance is the last command in the sequence.
                .debounceInstances<FixedLayoutBookReaderViewCommand.SetPagesInFocus>(periodMillis = 100)
                .flatMapLatestForEachInstance<FixedLayoutBookReaderViewCommand.SetPagesInFocus> { command ->
                    updatePagesInFocus(startIndex = command.startIndex, endIndexExclusive = command.endIndexExclusive)
                }
                .collect()
        }
    }

    fun setZoomLevel(zoomLevel: Double) {
        dispatch(FixedLayoutBookReaderViewCommand.SetZoom(zoomLevel.coerceAtLeast(0.1)))
    }

    fun setPagesInFocus(startIndex: Int, endIndexExclusive: Int) {
        dispatch(FixedLayoutBookReaderViewCommand.SetPagesInFocus(startIndex, endIndexExclusive))
    }

    internal sealed class FixedLayoutBookReaderViewCommand {
        data class SetPagesInFocus(val startIndex: Int, val endIndexExclusive: Int) :
            FixedLayoutBookReaderViewCommand()

        data class SetZoom(val zoomLevel: Double) : FixedLayoutBookReaderViewCommand()
    }

    private fun calculateFocusIndices(
        pageIndex: Int,
        extraPagesInEachDirection: Int,
        totalPages: Int,
    ): Pair<Int, Int> {
        val startIndex = (pageIndex - extraPagesInEachDirection).coerceAtLeast(0)
        val endIndexExclusive = (pageIndex + extraPagesInEachDirection).coerceAtMost(totalPages)
        return startIndex to endIndexExclusive
    }

    private fun setFocusAroundPage(pageIndex: Int, extraPagesInEachDirection: Int) {
        val totalPages = originalBookView.getMetadata().numberOfPages
        val (startIndex, endIndexExclusive) = calculateFocusIndices(pageIndex, extraPagesInEachDirection, totalPages)
        dispatch(FixedLayoutBookReaderViewCommand.SetPagesInFocus(startIndex, endIndexExclusive))
    }

    private suspend fun updatePagesInFocus(startIndex: Int, endIndexExclusive: Int) {
        val pagesInFocus = pagesInFocusFlow.value
        val focusRange = (startIndex until endIndexExclusive)
        val fixedLayoutPageHelpers = focusRange.associate { pageIndex ->
            if (pageIndex !in pagesInFocus) {
                val (isHidden, actualPageIndex) = when (bookView) {
                    is EditingBookView -> {
                        // when page is hidden -> return pageIndex - if not calculate the actualPageIndex.
                        val actualIndex = when (bookView.bookEdits.pages[pageIndex].hidden) {
                            true -> pageIndex
                            false -> pageIndex - bookView.bookEdits.pages.take(
                                pageIndex,
                            ).count { it.hidden }
                        }

                        bookView.bookEdits.pages[pageIndex].hidden to actualIndex
                    }

                    else -> false to pageIndex
                }
                // TODO: To fix this EditingBookView that can not provide any hidden pages
                //  when all clients migrate to the new reader APIs.
                val adequateBookView = when (isHidden) {
                    true -> originalBookView
                    false -> bookView
                }
                val (page) = adequateBookView.coGetPages(arrayOf(actualPageIndex)).orThrow()
                val imageScale = zoomLevelFlow.value
                val image = page.coGetImage(BookPageRequestOptions(imageScale)).orThrow()
                val pageNoOverlays = FixedLayoutPageNoFeatures(
                    page = page,
                    zoomLevel = imageScale,
                    image = image,
                    regionsOfInterest = page.regionsOfInterest
                        .map { FixedLayoutPageRegion.fromNormalizedBoundingBox(it) }
                        .toTypedArray(),
                )
                pageIndex to FixedLayoutPageHelper(
                    scope = scope,
                    pageNoFeatures = pageNoOverlays,
                    // If the page is hidden, there's no need to calculate reader features.
                    readerFeatures = if (isHidden) emptyFlow() else readerFeatures,
                    pageIndex = pageIndex,
                    overlayStrategy = overlayStrategy,
                )
            } else {
                pageIndex to pagesInFocus[pageIndex]!!
            }
        }

        val pagesLeavingFocus = pagesInFocus.filter {
            it.value.pageIndex !in focusRange
        }

        pagesInFocusFlow.update { existingPages ->
            (existingPages - pagesLeavingFocus.keys + fixedLayoutPageHelpers)
                .toList()
                .sortedBy { it.first }
                .toMap()
        }

        // destroy the pages once they leave focus to free up resources
        pagesLeavingFocus.forEach { it.value.destroy() }

        // TODO: clean up cursor/location wrapping
        val start = pagesInFocusFlow.value.entries.first().value.pageNoFeatures.start
        val end = pagesInFocusFlow.value.entries.last().value.pageNoFeatures.end

        // TODO: consider having this react to focus?
        dispatch(
            FocusCommand.SetFocus(
                start = SerialLocation(start),
                end = SerialLocation(end),
            ),
        )
    }

    private fun ResolvedNavigationIntent.toCoarseFixedLayoutNavigationIntent(): CoarseFixedLayoutBookNavigationIntent {
        val cursor = this.location.hack.cursor
        val pageIndex = originalBookView.getPageIndex(cursor)
        return CoarseFixedLayoutBookNavigationIntent(
            dispatch = dispatch,
            resolvedIntent = this,
            pageIndex = pageIndex,
        )
    }

    override val stateFlow: StateFlow<FixedLayoutBookView> =
        combine(
            pagesInFocusFlow,
            zoomLevelFlow,
            navigationHelper.coarseIntentsFlow,
        ) { pagesInFocus, zoomLevel, coarseIntent ->
            val numberOfPages = originalBookView.getMetadata().numberOfPages
            val pageHelpers = (0 until numberOfPages).map { pageIndex ->
                val pageInFocus = pagesInFocus[pageIndex]
                when {
                    pageInFocus != null -> {
                        val isHidden = when (bookView) {
                            is EditingBookView -> bookView.bookEdits.pages[pageIndex].hidden
                            else -> false
                        }
                        when (isHidden) {
                            true -> FixedLayoutPageView.Hidden(
                                bookEditorHelper,
                                pageIndex,
                                pageInFocus,
                            )

                            false -> FixedLayoutPageView.InFocus(pageInFocus.pageIndex, pageInFocus)
                        }
                    }

                    else -> FixedLayoutPageView.NotInFocus((pageIndex))
                }
            }
            FixedLayoutBookView(
                zoomLevel = zoomLevel,
                navigationIntent = coarseIntent?.toCoarseFixedLayoutNavigationIntent(),
                pages = pageHelpers.toTypedArray(),
            )
        }.stateInHelper(
            initialValue = FixedLayoutBookView(
                zoomLevel = 1.0,
                navigationIntent = null,
                pages = (0 until originalBookView.getMetadata().numberOfPages)
                    .map { FixedLayoutPageView.NotInFocus(it) }
                    .toTypedArray(),
            ),
        )

    override val initialState = stateFlow.value

    private companion object {
        const val EXTRA_PAGES_IN_EACH_DIRECTION = 3
    }
}
