package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.internal.util.isBetween

internal object SuperSubScriptDetection {

    private const val VISIBLE_THRESHOLD_FRACTION = 0.03

    internal fun isSuperScript(previous: BoundingBox, next: BookPageTextContentItem): Boolean {
        /*
                      +----+
                      |    |
          +------+ <- | -- | -- previous.top
          |      |    +----+ <- next.bottom
          |      |
          |      |
          +------+ <----------- previous.bottom
         */
        return next.textSourceType.supportsTextEnrichmentDetection &&
            next.normalizedBox.bottom.isBetween(previous.top, previous.bottom, tolerance = 0.0001) &&
            (
                goesAboveBaseline(previous, next.normalizedBox) ||
                    hasVisibleHeightDifference(previous, next.normalizedBox)
                ) &&
            isAfterOnLine(previous, next.normalizedBox) &&
            hasVisibleTopOverlap(previous, next.normalizedBox) &&
            next.normalizedBox.centerY < previous.centerY
    }

    internal fun isSubscript(previous: BoundingBox, next: BookPageTextContentItem): Boolean {
        /*
          +------+ <----------- previous.top
          |      |
          |      |
          |      |    +----+ <- next.top
          +------+ <- | -- | -- previous.bottom
                      |    |
                      +----+
         */
        return next.textSourceType.supportsTextEnrichmentDetection &&
            next.normalizedBox.top.isBetween(previous.top, previous.bottom, tolerance = 0.0001) &&
            (
                goesBelowBaseline(previous, next.normalizedBox) ||
                    hasVisibleHeightDifference(previous, next.normalizedBox)
                ) &&
            isAfterOnLine(previous, next.normalizedBox) &&
            hasVisibleBottomOverlap(previous, next.normalizedBox) &&
            next.normalizedBox.centerY > previous.centerY
    }

    private fun hasVisibleHeightDifference(previous: BoundingBox, next: BoundingBox): Boolean {
        val heightFraction = previous.height * VISIBLE_THRESHOLD_FRACTION
        val heightDifference = previous.height - next.height
        return heightDifference > heightFraction
    }

    private fun goesAboveBaseline(previous: BoundingBox, next: BoundingBox): Boolean {
        val heightFraction = previous.height * VISIBLE_THRESHOLD_FRACTION
        val bottomDifference = previous.bottom - next.bottom
        return bottomDifference > heightFraction
    }

    private fun goesBelowBaseline(previous: BoundingBox, next: BoundingBox): Boolean {
        val heightFraction = previous.height * VISIBLE_THRESHOLD_FRACTION
        val bottomDifference = next.bottom - previous.bottom
        return bottomDifference > heightFraction
    }

    private fun hasVisibleBottomOverlap(previous: BoundingBox, next: BoundingBox): Boolean {
        val heightFraction = previous.height * VISIBLE_THRESHOLD_FRACTION
        val bottomOverlap = previous.bottom - next.top

        return bottomOverlap > heightFraction
    }

    private fun hasVisibleTopOverlap(previous: BoundingBox, next: BoundingBox): Boolean {
        val heightFraction = previous.height * VISIBLE_THRESHOLD_FRACTION
        val topOverlap = next.bottom - previous.top

        return topOverlap > heightFraction
    }

    private fun isAfterOnLine(previous: BoundingBox, next: BoundingBox): Boolean {
        return next.left > previous.centerX
    }
}
