package com.speechify.client.api.content.epub

import kotlin.js.JsExport

/**
 * Defines the ePub parsing strategy.
 *
 * - **V1:** Parses the entire ePub content as a single HTML file.
 * - **V2:** Parses content chapter by chapter, offering better performance than V1.
 * - **V3:** Parses content while preserving its original format for the listening screen,
 *   used in the new EpubReader 2.0.
 */
@JsExport
enum class EpubVersion {
    V1,
    V2,
    V3,
}
