package com.speechify.client.internal.util.extensions.coroutines.job

import kotlinx.coroutines.Job

/**
 * NOTE: Does not include [this] job itself - use [thisAndAllDescendantJobs] for that.
 */
internal fun Job.allDescendantJobs(): Sequence<Job> =
    children.flatMap { it.thisAndAllDescendantJobs() }

internal fun Job.thisAndAllDescendantJobs(): Sequence<Job> = sequence {
    yield(this@thisAndAllDescendantJobs)
    for (child in children) {
        yieldAll(child.thisAndAllDescendantJobs())
    }
}
