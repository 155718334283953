package com.speechify.client.api.content

import com.speechify.client.internal.services.library.CursorSurgeon
import com.speechify.client.internal.services.library.DestructuredCursor
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * A pointer to a "place" within a body of content
 */
@JsExport
@kotlinx.serialization.Serializable(with = ContentCursorSerializer::class)
sealed class ContentCursor {
    /**
     * Get a reference to the structural element that contains this "place" within the body of content
     */
    abstract fun getParentElement(): ContentElementReference

    @JsName("compareToCursor")
    abstract fun compareTo(cursor: ContentCursor): ContentRelativeOrder

    @JsName("compareToContentTextPosition")
    abstract fun compareTo(position: ContentTextPosition): ContentRelativeOrder

    @JsName("compareToContentElementBoundary")
    abstract fun compareTo(elementBoundary: ContentElementBoundary): ContentRelativeOrder

    abstract fun getClosestPositionBetween(
        start: ContentTextPosition,
        end: ContentTextPosition,
    ): ContentTextPosition

    fun isBefore(cursor: ContentCursor): Boolean {
        return this.compareTo(cursor) === ContentRelativeOrder.BEFORE
    }

    fun isAfter(cursor: ContentCursor): Boolean {
        return this.compareTo(cursor) === ContentRelativeOrder.AFTER
    }

    fun isEqual(cursor: ContentCursor): Boolean {
        return this.compareTo(cursor) === ContentRelativeOrder.EQUAL
    }

    @JsName("IsBeforeOrAt")
    fun isBeforeOrAt(other: ContentCursor) = !this.isAfter(other)

    @JsName("IsAfterOrAt")
    fun isAfterOrAt(other: ContentCursor) = !this.isBefore(other)
}

object ContentCursorComparator : Comparator<ContentCursor> {
    override fun compare(a: ContentCursor, b: ContentCursor): Int =
        if (a.isBefore(b)) {
            -1
        } else if (a.isEqual(b)) {
            0
        } else {
            1
        }
}

/**
 * Returns `true` if the intervals overlap, even if they only share endpoints. Returns `false` otherwise.
 */
internal fun hasNontrivialIntersection(
    thisStart: ContentCursor,
    thisEnd: ContentCursor,
    otherStart: ContentCursor,
    otherEnd: ContentCursor,
): Boolean = thisStart.isBeforeOrAt(otherEnd) && thisEnd.isAfterOrAt(otherStart)

/**
 * Returns `true` if the specified [cursor] is within the range of [thisStart]
 * and [thisEnd] (inclusive). Otherwise, returns `false`.
 */
internal fun containsCursor(
    thisStart: ContentCursor,
    thisEnd: ContentCursor,
    cursor: ContentCursor,
): Boolean = cursor.isAfterOrAt(thisStart) && cursor.isBeforeOrAt(thisEnd)

/**
 * Returns `true` if the specified [cursor] is within the range of [thisStart]
 * and [thisEnd] (exclusive). Otherwise, returns `false`.
 */
internal fun containsCursorEndExclusive(
    thisStart: ContentCursor,
    thisEnd: ContentCursor,
    cursor: ContentCursor,
): Boolean = !cursor.isBefore(thisStart) && cursor.isBefore(thisEnd)

/**
 * Equivalent of [kotlin.ranges.coerceAtLeast] for cursors.
 */
internal fun ContentCursor.coerceAtLeast(minimum: ContentCursor): ContentCursor =
    this.nullIf { isBefore(minimum) } ?: minimum

/**
 * Equivalent of [kotlin.ranges.coerceAtMost] for cursors.
 */
internal fun ContentCursor.coerceAtMost(maximum: ContentCursor): ContentCursor =
    this.nullIf { isAfter(maximum) } ?: maximum

/**
 * Checks if [this] is the same as the leading edge of [elementReference], so
 *  For example:
 *  If [elementReference] is: `[4, 2]`, then it will return:
 *
 *  `true` when [this] is:
 *    `[4, 2] Start`
 *    `[4, 2] CharIndex=0`
 *    `[4, 2, 0] Start`
 *    `[4, 2, 0] CharIndex=0`
 *    `[4, 2, 0, 0] Start`
 *    `[4, 2, 0, 0] CharIndex=0`
 *   etc.
 *
 *  `false` when [this] is:
 *    `[4, 2] End`
 *    `[4, 2] CharIndex=4`
 *    `[4, 2, 5] Start`
 *    `[4, 2, 5] CharIndex=0`
 *    `[4, 2, 0, 5] Start`
 *    `[4, 2, 0, 5] CharIndex=0`
 *   etc.
 */
internal fun ContentCursor.isBeginningOfElementOrEquivalent(elementReference: ContentElementReference): Boolean {
    val parent = this.getParentElement()
    return if (this.isEqual(parent.getPosition(0)) || this.isEqual(parent.start)) {
        parent.path.dropLastWhile { it == 0 }.toTypedArray().contentEquals(elementReference.path.toTypedArray())
    } else {
        false
    }
}

internal object ContentCursorSerializer : KSerializer<ContentCursor> {
    override val descriptor: SerialDescriptor
        get() = SerialDescriptor("ContentCursor", DestructuredCursor.serializer().descriptor)

    override fun deserialize(decoder: Decoder): ContentCursor {
        val cursor = DestructuredCursor.serializer().deserialize(decoder)
        return CursorSurgeon.assemble(cursor)
    }

    override fun serialize(encoder: Encoder, value: ContentCursor) {
        val destructuredCursor = CursorSurgeon.destructure(value)
        DestructuredCursor.serializer().serialize(encoder, destructuredCursor)
    }
}
