package com.speechify.client.api.content.view.epub

import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.epub.EpubStartOfMainContent
import com.speechify.client.api.content.epub.EpubV2
import com.speechify.client.api.content.startofmaincontent.RawStartOfMainContent

internal class EpubViewV2(
    private val epubV2: EpubV2,
    private val htmlParser: HTMLParser,
) : EpubView {
    override val start = ContentElementReference.forRoot().start
    override val end = ContentElementReference.forRoot().end

    override val initialNavPointsToTocEntries by lazy {
        epubV2.navigation.toTocEntries()
    }

    override fun getMetadata() = EpubMetadata(
        numberOfChapters = epubV2.rawChapters.size,
        initialNavPointsToTocEntries = initialNavPointsToTocEntries,
    )

    override fun getChapterIndex(cursor: ContentCursor): Int {
        return when (cursor) {
            is ContentTextPosition -> cursor.element.path[0]
            is ContentElementBoundary -> cursor.element.path.firstOrNull() ?: when (cursor.boundary) {
                is ContentBoundary.START -> 0
                is ContentBoundary.END -> epubV2.rawChapters.size - 1
            }
        }
    }

    override suspend fun getChapters(indexes: List<Int>): List<EpubChapter> {
        return indexes.map { chapterIndex ->
            EpubChapterV2(
                epubV2 = epubV2,
                htmlParser = htmlParser,
                index = chapterIndex,
            )
        }
    }

    override suspend fun getMainContentStartCursor(
        remoteStartMainOfContent: RawStartOfMainContent.Epub?,
    ): ContentCursor? {
        return resolveMainContentStartCursor(
            remoteStartMainOfContent = remoteStartMainOfContent,
            resolveRemoteStartMainOfContent = epubV2::resolve,
            localParsedStartOfMainContent = epubV2.startOfMainContent,
        )
    }

    override suspend fun getChaptersEstimatedContentRatios(): Map<Int, Double> {
        return epubV2.rawChapters.mapValues { it.value.estimatedContentRatio }
    }

    override fun destroy() = Unit // NO-OP since ePubV2 destroys the archiveView in an early stage.
}

private fun EpubV2.resolve(
    staticStartOfMainContent: RawStartOfMainContent.Epub,
) = rawChapters.entries.firstOrNull { rawChapter ->
    rawChapter.value.filename == staticStartOfMainContent.filename.substringAfterLast("/")
}?.let {
    EpubStartOfMainContent(
        chapterIndex = it.key,
        fragment = staticStartOfMainContent.fragmentId,
    )
}
