package com.speechify.client.internal.memory

internal actual class WeakMap<K : Any, V> actual constructor() {

    private val map = js.collections.WeakMap<K, V>()

    actual fun get(key: K): V? {
        return map[key]
    }

    actual fun put(key: K, value: V) {
        map[key] = value
    }

    actual fun delete(key: K) {
        map.delete(key)
    }
}
