package com.speechify.client.reader.core

import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class ImportHelper internal constructor(
    scope: CoroutineScope,
    importerState: Flow<ContentImporterState?>,
) : Helper<ImportState>(scope) {
    override val stateFlow: StateFlow<ImportState> = importerState
        .map { ImportState(contentImporterState = it) }
        .stateInHelper(initialValue = ImportState(contentImporterState = null))
    override val initialState = stateFlow.value

    // NOTE: possible that this logic should be pushed further down into controller
    fun startImport(options: ImportOptions) {
        when (stateFlow.value.contentImporterState) {
            is ContentImporterState.Starting, is ContentImporterState.NotImported, null -> dispatch(
                StartImport(options),
            )
            else -> {}
        }
    }

    internal companion object {
        internal fun create(scope: ReaderScope, readingBundle: ReadingBundle): ImportHelper {
            return ImportHelper(scope, readingBundle.contentImporter.stateFlow).also {
                it.commands
                    .onEachInstance<StartImport> {
                        // TODO: how to propagate error state?
                        readingBundle.contentImporter.startImport(it.options)
                    }
                    .launchIn(scope)
            }
        }
    }
}

@JsExport
data class ImportState(val contentImporterState: ContentImporterState?)
internal data class StartImport(val options: ImportOptions)
