package com.speechify.client.internal.services.subscription.models

import com.speechify.client.api.services.subscription.models.SubscriptionDiscount
import com.speechify.client.api.services.subscription.models.SubscriptionDiscountDuration
import com.speechify.client.api.services.subscription.models.SubscriptionDiscountType
import com.speechify.client.api.services.subscription.models.SubscriptionSource
import com.speechify.client.api.services.subscription.models.SubscriptionStatus
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.time.ISO8601DateString
import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformSubscriptionPlanResponse(
    val source: String,
    val name: String,
    val price: Double,
    val renewalFrequency: String,
    val conversionId: String? = null,
    val hasTrial: Boolean,
    val initialTrialDurationDays: Int? = null,
    val discountIds: Array<String>? = arrayOf(),
    val productTypes: Array<String>? = arrayOf(),

    /** Plan entitlements */
    val wordsLimit: Int? = 0,
    val audiobookCredits: Int? = 0,
    val avatarsSeconds: Int? = 0,
    val dubbingSeconds: Int? = 0,
    val voiceoverSeconds: Int? = 0,
    val ttsExportSeconds: Int? = 0,
)

@Serializable
internal data class PlatformSubscriptionResponse(
    val id: String,
    val isOnTrial: Boolean,
    val status: String,
    val renewalStatus: String,
    val renewsAt: ISO8601DateString,
    val subscribedAt: ISO8601DateString,
    val lastPaymentAt: ISO8601DateString? = null,
    val plan: PlatformSubscriptionPlanResponse,
    val localCurrency: String? = null,

    /** All discounts applied to the Subscription **/
    val discounts: Array<PlatformSubscriptionDiscountsResponse>? = null,
)

@Serializable
internal data class PlatformSubscriptionDiscountsResponse(
    /** Type of coupon, percentage or amount */
    val type: String,

    /** Value of the discount (amount should be aligned with payment currency) */
    val value: Double,

    /** human-readable code from stripe or other system */
    val name: String,

    /** The discount duration: Forever, Once, Repeating **/
    var duration: String,

    /** The starting timestamp of the coupon **/
    var startTime: DateTime,

    /** The expiry timestamp of the coupon **/
    var expiryTime: DateTime,
)

@Serializable
internal data class PlatformEntitlementsResponse(
    val isPremium: Boolean,
    val hdWordsLeft: Int,
    val nextHDWordsGrant: Int,
    val nextHDWordsGrantDate: String? = null,
    val lastHDWordsGrantDate: String? = null,
    val audiobookCreditsLeft: Int,
    val lastAudiobookCreditsGrantDate: String? = null,
)

@Serializable
internal data class PlatformSubscriptionAndEntitlementResponse(
    val entitlements: PlatformEntitlementsResponse,
    val subscriptions: Array<PlatformSubscriptionResponse>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PlatformSubscriptionAndEntitlementResponse

        if (!other.subscriptions.contentEquals(subscriptions)) {
            return false
        } else if (other.entitlements != entitlements) return false

        return true
    }

    override fun hashCode(): Int {
        var result = entitlements.hashCode()
        result = 31 * result + subscriptions.hashCode()
        return result
    }
}

internal fun PlatformSubscriptionPlanResponse.toSubscriptionSource(): SubscriptionSource {
    return when (this.source) {
        "apple" -> SubscriptionSource.APPLE
        "play_store" -> SubscriptionSource.PLAY_STORE
        "stripe" -> SubscriptionSource.STRIPE
        "paypal" -> SubscriptionSource.PAYPAL
        "teams" -> SubscriptionSource.TEAMS
        else -> SubscriptionSource.OTHER
    }
}

internal fun PlatformSubscriptionResponse.toSubscriptionStatus(): SubscriptionStatus {
    return when (this.status) {
        "canceled" -> SubscriptionStatus.CANCELED
        "expired" -> SubscriptionStatus.EXPIRED
        else -> SubscriptionStatus.ACTIVE
    }
}

internal fun PlatformSubscriptionResponse.toSubscriptionDiscounts(): Array<SubscriptionDiscount> {
    return this.discounts?.map {
        val type = when (it.type) {
            "percentage" -> SubscriptionDiscountType.Percentage
            else -> SubscriptionDiscountType.Amount
        }

        val duration = when (it.duration) {
            "forever" -> SubscriptionDiscountDuration.Forever
            "repeating" -> SubscriptionDiscountDuration.Repeating
            else -> SubscriptionDiscountDuration.Once
        }

        SubscriptionDiscount(
            type = type,
            value = it.value,
            name = it.name,
            duration = duration,
            startDate = it.startTime.toIsoString(),
            expiryDate = it.expiryTime.toIsoString(),
        )
    }.orEmpty().toTypedArray()
}
