package com.speechify.client.helpers.ui.controls

import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.audio.AudioControllerEvent
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.SentenceAndWordLocation
import com.speechify.client.api.content.coGetCursorFromProgress
import com.speechify.client.api.content.coGetProgressFromCursor
import com.speechify.client.api.content.coGetStats
import com.speechify.client.api.content.view.speech.CursorQuery
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.success
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.listening.ListeningBundle
import com.speechify.client.helpers.audio.controller.AudioControllerCommand
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.launchAsync
import com.speechify.client.internal.sync.SingleJobMutexByCancelling
import com.speechify.client.internal.time.Seconds
import com.speechify.client.internal.time.nowInMillisecondsFromEpoch
import com.speechify.client.internal.util.intentSyntax.ValueUpdateChoice
import com.speechify.client.internal.util.intentSyntax.asValueUpdateChoiceWithNullAsKeep
import com.speechify.client.internal.util.roundUpToInt
import com.speechify.client.internal.util.runCatchingSilencingErrorsLoggingThem
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlin.js.JsExport
import kotlin.math.roundToInt

@JsExport
sealed class PlayPauseButton {
    object ShowBuffering : PlayPauseButton()
    object ShowPlay : PlayPauseButton()
    object ShowPause : PlayPauseButton()
    object ShowRestart : PlayPauseButton()
}

@JsExport
sealed class PlaybackTime() {
    class NotReady : PlaybackTime()

    data class Ready(val totalTimeSeconds: Seconds, val currentTimeSeconds: Seconds) :
        PlaybackTime()
}

/**
 * The action performed by the [PlaybackControls.pressPlayPause] and [PlaybackControls.pressPlayPauseWithSetup] methods
 */
@JsExport
enum class PlayPausePerformedAction {
    /** Nothing was done */
    Ignored,

    /** Playback was paused */
    Paused,

    /** Playback was (re)started */
    Played,
}

@JsExport
class PlaybackControls private constructor(
    internal val listeningBundle: ListeningBundle,
    initialState: State,
) : WithScope() {
    private val _stateFlow: MutableStateFlow<State> = MutableStateFlow(
        value = initialState,
    )

    internal val audioController: AudioController get() = listeningBundle.audioController
    private val standardView: StandardView get() = listeningBundle.contentBundle.standardView
    private val contentIndex: ContentIndex get() = listeningBundle.contentBundle.contentIndex

    internal val scopeForChildren: CoroutineScope
        get() = this.scope

    private var lastPlayPauseActionTimestamp = 0L

    companion object {
        internal suspend fun ListeningBundle.createPlaybackControls(
            startingCursor: ContentCursor,
        ): Result<PlaybackControls> {
            // FIXME(anson): please add sync getter for speed so we can just pull from listeningBundle.audioController

            val progressFraction =
                contentBundle.contentIndex.coGetProgressFromCursor(startingCursor).orReturn { return it }
            val playbackControls = PlaybackControls(
                listeningBundle = this,
                initialState = State(
                    latestPlaybackProgressFraction = progressFraction,
                    uiPlaybackProgressFraction = progressFraction,
                    latestPlaybackCursor = startingCursor,
                    sentenceAndWordLocation = null,
                    wordsPerMinute = config.defaultSpeedWPM,
                    voiceOfPreferenceOverride = audioController.voiceOfPreferenceOverride,
                ),
            )

            return playbackControls.successfully()
        }
    }

    data class State internal constructor(
        val playPauseButton: PlayPauseButton = PlayPauseButton.ShowBuffering,
        val latestPlaybackProgressFraction: ProgressFraction = 0.0,
        val uiPlaybackProgressFraction: ProgressFraction = 0.0,
        val playbackTime: PlaybackTime = PlaybackTime.NotReady(),
        val latestPlaybackCursor: ContentCursor,
        val sentenceAndWordLocation: SentenceAndWordLocation?,
        val wordsPerMinute: Int,
        val voiceOfCurrentUtterance: VoiceSpecOfAvailableVoice? = null,
        /**
         * This is the voice that will be used when content doesn't dictate the voice (e.g. dubbed or pre-recorded
         * content will dictate the voice) `null` if the preferred voice is not yet established.
         */
        val voiceOfPreferenceOverride: VoiceSpecOfAvailableVoice? = null,
        internal val furthestBufferProgressFractionUnsafe: ProgressFraction = 0.0,
    ) {
        val furthestBufferProgressFraction: ProgressFraction
            get() = furthestBufferProgressFractionUnsafe.coerceAtLeast(latestPlaybackProgressFraction)
    }

    /**
     * A flow exposing the state transitions (usable by android as an alternative to [addListener])
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    val stateFlow = _stateFlow.asStateFlow()

    /**
     * The current state
     */
    val state: State get() = stateFlow.value

    private val endScrubbingJob = SingleJobMutexByCancelling()
    private val isPlayingStateWhenScrubbing = MutableStateFlow(false)

    /**
     * The scrubber instance of these [PlaybackControls]. Use it to implement your scrubber experience
     */
    val scrubber: Scrubber = Scrubber(
        onStartScrubbing = {
            // The previous scrubbing job has not finished yet. To ensure proper continuation, we will return the
            // previous state when a new scrubbing starts before the previous one has finished.
            val isPlaying = when (endScrubbingJob.isCurrentJobRunning) {
                true -> isPlayingStateWhenScrubbing.value
                false -> audioController.isPlaying
            }
            if (isPlaying) {
                audioController.pause()
            }
            _stateFlow.update {
                it.copy(
                    playPauseButton = PlayPauseButton.ShowBuffering,
                )
            }
            isPlaying
        },
        handleProgressUpdate = { progressFraction ->
            _stateFlow.update {
                it.copy(
                    uiPlaybackProgressFraction = progressFraction,
                    sentenceAndWordLocation = null,
                )
            }
        },
        onEndScrubbing = { progressFraction, wasPlaying ->
            // Because calculating the cursor takes time, especially for big files, we update in two steps.
            // First the progress fraction so that UI is properly updated instantly, then the cursor for playback.
            // Both are needed because the UI progress fraction does not get updated with `handleProgressUpdate` when
            // clicking directly on a position. `handleProgressUpdate` is only called when the scrubber is dragged.
            _stateFlow.update {
                it.copy(
                    uiPlaybackProgressFraction = progressFraction,
                )
            }

            // Updating the cursor takes some time when we have big files. If another scrub takes place in this time
            // window, it may cause inconsistencies or won't actually happen. To counteract this, we only update the
            // cursor in a separate job that we cancel if another one comes along
            isPlayingStateWhenScrubbing.emit(wasPlaying)
            endScrubbingJob.cancelCurrentJob()
            endScrubbingJob.replaceWithNewJobNoWaitForCancelIn(scope) {
                val cursor = contentIndex.coGetCursorFromProgress(progressFraction).orThrow()
                _stateFlow.update {
                    it.copy(
                        latestPlaybackProgressFraction = progressFraction,
                        latestPlaybackCursor = cursor,
                    )
                }
                if (wasPlaying) {
                    audioController.playFromCursor(cursor)
                } else {
                    audioController.seekToCursor(cursor)
                }
            }
        },
        scope = scope,
    )

    private suspend fun cursorUpdate(
        cursor: ContentCursor,
        button: PlayPauseButton,
        sentenceAndWordLocation: ValueUpdateChoice<SentenceAndWordLocation?>,
        currentUtteranceVoice: ValueUpdateChoice<VoiceSpecOfAvailableVoice>,
    ): Result<Unit> {
        val progressFraction = contentIndex.coGetProgressFromCursor(cursor)
            .orReturn { return it }
        _stateFlow.update {
            val playbackTime =
                when (it.playbackTime) {
                    is PlaybackTime.NotReady -> it.playbackTime
                    is PlaybackTime.Ready ->
                        it.playbackTime.copy(
                            currentTimeSeconds = (it.playbackTime.totalTimeSeconds.times(progressFraction)).toInt(),
                        )
                }
            it.copy(
                playPauseButton = button,
                latestPlaybackProgressFraction = progressFraction,
                uiPlaybackProgressFraction = progressFraction,
                latestPlaybackCursor = cursor,
                sentenceAndWordLocation = when (sentenceAndWordLocation) {
                    is ValueUpdateChoice.Set -> sentenceAndWordLocation.value
                    is ValueUpdateChoice.Keep -> it.sentenceAndWordLocation
                },
                playbackTime = playbackTime,
                voiceOfCurrentUtterance = when (currentUtteranceVoice) {
                    is ValueUpdateChoice.Set -> currentUtteranceVoice.value
                    is ValueUpdateChoice.Keep -> it.voiceOfCurrentUtterance
                },
            )
        }
        return success()
    }

    private suspend fun contentSizeUpdate(): Result<Unit> {
        return _stateFlow.update { oldState ->
            val progressFraction = contentIndex.coGetProgressFromCursor(oldState.latestPlaybackCursor)
                .orReturn { return it }

            val totalTimeSeconds = calculateTotalTimeSeconds(
                wordCount = contentIndex
                    .coGetStats()
                    .orReturn { return it }
                    .estimatedWordCount.count,
                wordsPerMinute = oldState.wordsPerMinute,
            )

            oldState.copy(
                latestPlaybackProgressFraction = progressFraction,
                uiPlaybackProgressFraction = progressFraction,
                playbackTime = PlaybackTime.Ready(
                    totalTimeSeconds = totalTimeSeconds,
                    currentTimeSeconds = (totalTimeSeconds * progressFraction).toInt(),
                ),
            )
        }.successfully()
    }

    init {
        launchAsync {
            val contentIndexStats = currentTelemetryEvent().addMeasurement("getContentIndexStats") {
                listeningBundle.contentBundle.contentIndex.coGetStats()
            }

            val totalTimeSeconds = calculateTotalTimeSeconds(
                wordCount = contentIndexStats
                    .orReturn { return@launchAsync it }
                    .estimatedWordCount.count,
                state.wordsPerMinute,
            )

            _stateFlow.update {
                it.copy(
                    playbackTime = PlaybackTime.Ready(
                        totalTimeSeconds,
                        (totalTimeSeconds.toDouble() * it.latestPlaybackProgressFraction).toInt(),
                    ),
                )
            }
        }

        scope.launch(context = CoroutineName("${PlaybackControls::class.simpleName}.contentAmountStateChangeHandler")) {
            this@PlaybackControls.contentIndex.contentAmountStateFlow
                .collect {
                    /**
                     * Use `runCatching*`, because so we don't want to render [PlaybackControls]
                     * dead - chances are that future events will fix it.
                     */
                    runCatchingSilencingErrorsLoggingThem(
                        sourceAreaId = "${PlaybackControls::class.simpleName}.contentAmountStateChangeHandler",
                        shouldIgnoreCancellationExceptions =
                        true,
                    ) {
                        contentSizeUpdate()
                            .orThrow()
                    }
                }
        }

        scope.launch(
            context =
            CoroutineName("${PlaybackControls::class.simpleName}.furthestBufferItemChangedHandler"),
        ) {
            this@PlaybackControls.audioController.bufferEvents.furthestBufferItemFlow.collect {
                /**
                 * Use `runCatchingSilencingErrorsLoggingThem`, because updating buffer progress is not critical for the playback
                 * controls, so we shouldn't fail it.
                 */
                runCatchingSilencingErrorsLoggingThem(
                    sourceAreaId = "${PlaybackControls::class.simpleName}.furthestBufferItemChangedHandler",
                    shouldIgnoreCancellationExceptions =
                    /** `true` to ignore cancellations. If the whole playback is disposed, we don't care for updating
                     * the UI anymore.
                     */
                    true,
                ) {
                    _stateFlow.update { oldState ->
                        oldState.copy(
                            furthestBufferProgressFractionUnsafe = contentIndex.coGetProgressFromCursor(it.speech.end)
                                .orThrow(),
                        )
                    }
                }
            }
        }

        this.audioController.coAddEventListener { event ->
            when (event) {
                is AudioControllerEvent.ChangedSpeed -> {
                    val stats = contentIndex.coGetStats().orReturn { return@coAddEventListener }
                    val totalTimeSeconds = calculateTotalTimeSeconds(
                        stats.estimatedWordCount.count,
                        event.speedInWordsPerMinute,
                    )
                    _stateFlow.update {
                        it.copy(
                            wordsPerMinute = event.speedInWordsPerMinute,
                            playbackTime = PlaybackTime.Ready(
                                totalTimeSeconds = totalTimeSeconds,
                                currentTimeSeconds =
                                (it.latestPlaybackProgressFraction * totalTimeSeconds).roundToInt(),
                            ),
                        )
                    }
                }

                is AudioControllerEvent.ChangedVoice -> _stateFlow.update {
                    it.copy(voiceOfPreferenceOverride = event.voice)
                }

                is AudioControllerEvent.Buffering -> _stateFlow.update {
                    it.copy(playPauseButton = PlayPauseButton.ShowBuffering)
                }

                is AudioControllerEvent.Paused ->
                    cursorUpdate(
                        cursor = event.cursor,
                        button = PlayPauseButton.ShowPlay.toBufferingIfScrubbing(),
                        sentenceAndWordLocation = event.sentenceAndWordLocation
                            /* It's desired to keep the speech location, because when pausing the user is still in the same place */
                            .asValueUpdateChoiceWithNullAsKeep(),
                        currentUtteranceVoice = ValueUpdateChoice.Keep,
                    ).orReturn { return@coAddEventListener }

                is AudioControllerEvent.Destroyed,
                -> if (event.error != null) {
                    /* For now nothing, but there could be some state introduced that would allow SDK consumers to
                       display a graceful message to the user whose playing request has just been discarded, rather than
                       pretend like everything is OK.
                    */
                }

                is AudioControllerEvent.Ended,
                -> _stateFlow.update {
                    val playbackTime = when (it.playbackTime) {
                        is PlaybackTime.NotReady -> it.playbackTime
                        is PlaybackTime.Ready -> PlaybackTime.Ready(
                            it.playbackTime.totalTimeSeconds,
                            it.playbackTime.totalTimeSeconds,
                        )
                    }
                    it.copy(
                        playPauseButton = PlayPauseButton.ShowRestart,
                        latestPlaybackProgressFraction = 1.0,
                        uiPlaybackProgressFraction = 1.0,
                        latestPlaybackCursor = standardView.end,
                        playbackTime = playbackTime,
                    )
                }

                is AudioControllerEvent.Playing ->
                    cursorUpdate(
                        cursor = event.cursor,
                        button = PlayPauseButton.ShowPause.toBufferingIfScrubbing(),
                        sentenceAndWordLocation = event.sentenceAndWordLocation
                            /* #KeepingOldLocationOnPlayWithNull
                               If, for whatever reason, there is no specific location of the speech (it shouldn't
                               really happen but there are some cases like #EndOfPlayWordLocationIsNull and
                               #UtterancePlaybackReportingLocationNotWithinUtterance), then keeping the previous one
                               is hopefully the closest to the real location and is still useful for the user.
                             */
                            .asValueUpdateChoiceWithNullAsKeep(),
                        currentUtteranceVoice = event.voice.asValueUpdateChoiceWithNullAsKeep(),
                    ).orReturn { return@coAddEventListener }

                is AudioControllerEvent.Errored -> return@coAddEventListener
                is AudioControllerEvent.Seeking -> {
                    cursorUpdate(
                        cursor = event.cursor,
                        button = _stateFlow.value.playPauseButton,
                        sentenceAndWordLocation = null.asValueUpdateChoiceWithNullAsKeep(),
                        currentUtteranceVoice = ValueUpdateChoice.Keep,
                    ).orReturn { return@coAddEventListener }
                }
            }
        }
    }

    /**
     * Jump to the next sentence
     */
    fun skipForwards() {
        audioController.seek(CursorQuery.fromCursor(state.latestPlaybackCursor).scanForwardToSentenceStart())
    }

    /**
     * Jump to the previous sentence
     */
    fun skipBackwards() {
        // TODO smart skip backwards PLT-1704
        this.audioController.seek(CursorQuery.fromCursor(state.latestPlaybackCursor).scanBackwardToSentenceStart(1))
    }

    /**
     * Runs the logic needed to implement a play pause button
     *
     * @return what was done
     * @see PlayPausePerformedAction
     */
    fun pressPlayPause(): PlayPausePerformedAction {
        return pressPlayPauseWithSetup { }
    }

    /**
     * Runs the logic needed to implement a play pause button
     *
     * @param beforePlay callback that runs before starting playback, use this to set up any extra state that is needed
     * before playing audio
     *
     * @return what was done
     * @see PlayPausePerformedAction
     */
    fun pressPlayPauseWithSetup(beforePlay: () -> Unit): PlayPausePerformedAction {
        fun throttlePlayPauseActionWithDefaultValue(
            intervalInMilliseconds: Long,
            defaultValue: PlayPausePerformedAction,
            action: () -> PlayPausePerformedAction,
        ): PlayPausePerformedAction {
            val currentTime = nowInMillisecondsFromEpoch()
            // Check if the interval has passed since the last action
            return if (currentTime - lastPlayPauseActionTimestamp >= intervalInMilliseconds) {
                action().also {
                    lastPlayPauseActionTimestamp = currentTime
                }
            } else {
                defaultValue
            }
        }
        return throttlePlayPauseActionWithDefaultValue(150, PlayPausePerformedAction.Ignored) {
            when (state.playPauseButton) {
                PlayPauseButton.ShowBuffering,
                PlayPauseButton.ShowPause,
                -> {
                    this.audioController.pause()
                    PlayPausePerformedAction.Paused
                }

                PlayPauseButton.ShowRestart -> {
                    beforePlay()
                    restart()
                    PlayPausePerformedAction.Played
                }

                PlayPauseButton.ShowPlay -> {
                    beforePlay()
                    if (state.latestPlaybackProgressFraction >= 1.0) {
                        this.audioController.playFromCursor(
                            standardView.start,
                        )
                    } else {
                        this.audioController.resume()
                    }
                    PlayPausePerformedAction.Played
                }
            }
        }
    }

    fun restart() {
        this.audioController.playFromCursor(
            standardView.start,
        )
    }

    /**
     * Hard pause the player, independent of state
     */
    fun pause() {
        this.audioController.pause()
    }

    /**
     * Change the current speed
     */
    fun setSpeed(wordsPerMinute: Int) {
        this.audioController.setSpeed(wordsPerMinute)
    }

    val voiceOfPreferenceOverride: VoiceSpecOfAvailableVoice? get() = state.voiceOfPreferenceOverride

    /**
     * Change the current voice
     */
    fun setVoice(
        availableVoice: VoiceSpecOfAvailableVoice,
    ) =
        this.audioController.setVoice(
            availableVoice = availableVoice,
        )

    /**
     * Add a listener to state events
     */
    fun addListener(callback: CallbackNoError<State>): Destructor =
        callback
            .multiShotFromFlowIn(
                flow = stateFlow,
                scope = scope,
            )::destroy

    /**
     * Allows to act on changes of [PlaybackControls.state]'s [PlaybackControls.State.voiceOfCurrentUtterance],
     * without being notified of changes to other parts of the state.
     */
    fun subscribeToVoiceOfCurrentUtterance(callback: CallbackNoError<VoiceSpecOfAvailableVoice?>): Destructor =
        callback
            .multiShotFromFlowIn(
                flow = stateFlow
                    .map {
                        it.voiceOfCurrentUtterance
                    }
                    .distinctUntilChanged(),
                scope = scope,
            )::destroy

    /**
     * Allows to act on changes of [PlaybackControls.state]'s [PlaybackControls.State.voiceOfPreferenceOverride],
     * without being notified of changes to other parts of the state.
     */
    fun subscribeToVoiceOfPreferenceOverride(callback: CallbackNoError<VoiceSpecOfAvailableVoice?>): Destructor =
        callback
            .multiShotFromFlowIn(
                flow = stateFlow
                    .map {
                        it.voiceOfPreferenceOverride
                    }
                    .distinctUntilChanged(),
                scope = scope,
            )::destroy

    private fun PlayPauseButton.toBufferingIfScrubbing(): PlayPauseButton =
        if (scrubber.isScrubbing) PlayPauseButton.ShowBuffering else this

    internal suspend fun reloadContent() = audioController.actor.send(AudioControllerCommand.ReloadContent)
}

internal fun calculateTotalTimeSeconds(wordCount: Int, wordsPerMinute: Int): Int =
    (60.0 * (wordCount.toDouble() / wordsPerMinute.toDouble())).roundUpToInt()
