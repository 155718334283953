package com.speechify.client.internal.services.adoption.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseUserUsageDataEntry(
    /**
     * The last version of the platform type the user have used.
     */
    @SerialName("last_version")
    val lastVersion: String,

    /**
     * The last visit timestamp in seconds since 1970
     */
    @SerialName("last_visit")
    val lastVisitDateTime: DateTime,
)
