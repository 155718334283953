package com.speechify.client.internal.services.library

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.firebase.CollectionReference
import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.SnapshotRef
import com.speechify.client.api.adapters.firebase.coGetCollection
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.FilterType
import com.speechify.client.api.services.library.models.FilterType.LISTENING_IN_PROGRESS_AND_NOT_STARTED
import com.speechify.client.api.services.library.models.FolderReference
import com.speechify.client.api.services.library.models.ItemStatus
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.LibraryStartOfMainContent
import com.speechify.client.api.services.library.models.ListenProgressStatus
import com.speechify.client.api.services.library.models.SearchRequestInternal
import com.speechify.client.api.services.library.models.SearchableFilterType
import com.speechify.client.api.services.library.models.UpdateLibraryItemParams
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.library.SearchFilter.Eq
import com.speechify.client.internal.services.library.SearchFilter.IsFalse
import com.speechify.client.internal.services.library.SearchFilter.IsTrue
import com.speechify.client.internal.services.library.SearchFilter.OneOf
import com.speechify.client.internal.services.library.models.FirebaseLibraryItem
import com.speechify.client.internal.services.library.models.FirebaseListeningProgress
import com.speechify.client.internal.services.library.models.FirebasePageItem
import com.speechify.client.internal.services.library.models.LibrarySearchPayload
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import com.speechify.client.internal.util.extensions.intentSyntax.isNotNullAnd
import com.speechify.client.internal.util.extensions.intentSyntax.nullIfNotNullAnd
import kotlinx.coroutines.flow.MutableStateFlow

internal fun getLegacyPagesCollectionRef(itemId: SpeechifyContentId): CollectionReference = "items/$itemId/pages"

internal fun getLocationsCollectionRef(itemId: SpeechifyContentId): CollectionReference = "items/$itemId/locations"

internal object LibraryFirebaseTransformer {
    suspend fun toLibraryItem(
        firebaseFirestoreService: FirebaseFirestoreService,
        firebaseLibraryItemId: String,
        firebaseLibraryItem: FirebaseLibraryItem,
        snapshotRef: SnapshotRef,
        offlineAvailabilityManager: OfflineAvailabilityManager,
        dataSource: DataSource = DataSource.DEFAULT,
    ): LibraryItem {
        val coverImagePath = firebaseLibraryItem.coverImagePath

        if (firebaseLibraryItem.type == "folder") {
            return LibraryItem.Folder(
                childrenCount = 0,
                ownerId = firebaseLibraryItem.owner,
                id = firebaseLibraryItemId,
                // Fine to assert non-null here because this field in [FirebaseLibraryItem] has a non-null default value
                createdAt = firebaseLibraryItem.createdAt!!.toIsoString(),
                coverImageUrl = coverImagePath,
                title = firebaseLibraryItem.title,
                updatedAt = firebaseLibraryItem.updatedAt.toIsoString(),
                snapshotRef = snapshotRef,
                analyticsProperties =
                firebaseLibraryItem.analyticsProperties?.let(SdkBoundaryMap.Companion::fromMap)
                    ?: SdkBoundaryMap.empty(),
                parentFolderId = firebaseLibraryItem.parentFolderId ?: FolderReference.Root.asRaw(),
                lastListenedAt = firebaseLibraryItem.lastListenedAt?.toIsoString(),
                removedAt = firebaseLibraryItem.removedAt?.toIsoString(),
                isArchived = firebaseLibraryItem.isArchived || firebaseLibraryItem.isArchivedV2,
            )
        }

        val status =
            inferStatusForRecord(
                firebaseFirestoreService,
                libraryItemFirestoreId = firebaseLibraryItemId,
                libraryItemFirestoreData = firebaseLibraryItem,
                dataSource = dataSource,
            )

        val contentType = ContentType.fromItemRecordType(firebaseLibraryItem.recordType)

        val uri =
            SpeechifyURI.fromExistingId(
                when (contentType) {
                    ContentType.PDF -> SpeechifyEntityType.LIBRARY_ITEM
                    ContentType.HTML -> SpeechifyEntityType.LIBRARY_ITEM
                    ContentType.DOCX -> SpeechifyEntityType.LIBRARY_ITEM
                    ContentType.TXT -> SpeechifyEntityType.LIBRARY_ITEM
                    /**
                     * This is to not break legacy documents by looking at the existence of the `scannedBookFields` field.
                     * If it exists and is not empty, then it's __likely__ a legacy doc.
                     * In this case we use the fallback of treating at as a normal item with concatenated HTML
                     * when we detect that it's of "legacy" scanned book type.
                     * For context: https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1694012167648679
                     */
                    ContentType.SCAN -> {
                        if (firebaseLibraryItem.scannedBookFields == null ||
                            firebaseLibraryItem.scannedBookFields.pageOrdering.isEmpty()
                        ) {
                            SpeechifyEntityType.LIBRARY_ITEM
                        } else {
                            SpeechifyEntityType.SCANNED_BOOK
                        }
                    }

                    ContentType.EPUB -> SpeechifyEntityType.LIBRARY_ITEM
                    ContentType.SPEECHIFY_BOOK -> SpeechifyEntityType.LIBRARY_ITEM
                },
                firebaseLibraryItemId,
            )
        return LibraryItem.Content(
            updatedAt = firebaseLibraryItem.updatedAt.toIsoString(),
            status = status,
            isShared = firebaseLibraryItem.isShared,
            sourceUrl = firebaseLibraryItem.sourceUrl,
            sourceStoredUrl = firebaseLibraryItem.sourceStoredUrl,
            title = firebaseLibraryItem.title,
            excerpt = firebaseLibraryItem.excerpt,
            totalWords = firebaseLibraryItem.wordCount,
            wordsLeft = firebaseLibraryItem.wordsLeft,
            listenProgressStatus =
            when (firebaseLibraryItem.progressStatus) {
                "in-progress" -> ListenProgressStatus.IN_PROGRESS
                "not-started" -> ListenProgressStatus.NOT_STARTED
                "done" -> ListenProgressStatus.DONE
                else -> null
            },
            listenProgressPercent =
            if (firebaseLibraryItem.listeningProgress == null) {
                if (firebaseLibraryItem.progressFraction.isNotNullAnd { this > 1.0 }) {
                    1.0
                } else {
                    firebaseLibraryItem.progressFraction
                        .nullIfNotNullAnd {
                            this < 0 || isNaN() // NaN have been observed in data - must have been a bug.
                        }
                }
            } else {
                if (firebaseLibraryItem.listeningProgress.fraction.isNotNullAnd { this > 1.0 }) {
                    1.0
                } else {
                    firebaseLibraryItem.listeningProgress.fraction
                        .nullIfNotNullAnd {
                            this < 0 || isNaN() // NaN have been observed in data - must have been a bug.
                        }
                }
            },
            uri = uri,
            contentType = contentType,
            ownerId = firebaseLibraryItem.owner,
            id = firebaseLibraryItemId,
            // Fine to assert non-null here because this field in [FirebaseLibraryItem] has a non-null default value
            createdAt = firebaseLibraryItem.createdAt!!.toIsoString(),
            coverImageUrl = coverImagePath,
            snapshotRef = snapshotRef,
            lastListenedAt = firebaseLibraryItem.lastListenedAt?.toIsoString(),
            listeningProgress = firebaseLibraryItem.listeningProgress?.toSyncedListeningProgress(),
            analyticsProperties =
            firebaseLibraryItem.analyticsProperties?.let(SdkBoundaryMap.Companion::fromMap)
                ?: SdkBoundaryMap.empty(),
            offlineAvailabilityStatusFlowProvider = {
                offlineAvailabilityManager.getOfflineAvailabilityStatusFlow(uri)
            },
            // We default to true, since for legacy data we can't know if edits exist and true is the safe assumption.
            hasPageEditsFlow = MutableStateFlow(firebaseLibraryItem.hasPageEdits),
            offlineAvailabilityManager = offlineAvailabilityManager,
            parentFolderId = firebaseLibraryItem.parentFolderId ?: FolderReference.Root.asRaw(),
            removedAt = firebaseLibraryItem.removedAt?.toIsoString(),
            speechifyBookProductUri = firebaseLibraryItem.speechifyBookProductUri,
            isArchived = firebaseLibraryItem.isArchived || firebaseLibraryItem.isArchivedV2,
            startOfMainContent = firebaseLibraryItem.startOfMainContent?.let { startOfMainContent ->
                when (contentType) {
                    ContentType.PDF, ContentType.HTML, ContentType.DOCX, ContentType.TXT,
                    ContentType.SCAN, ContentType.EPUB,
                    -> null
                    ContentType.SPEECHIFY_BOOK -> startOfMainContent["filename"]?.let { filename ->
                        LibraryStartOfMainContent.Epub(
                            filename = filename,
                            nodeId = startOfMainContent["nodeId"],
                        )
                    }
                }
            },
            contentAccess = firebaseLibraryItem.contentAccess,
        )
    }

    private suspend fun inferStatusForRecord(
        firebaseFirestoreService: FirebaseFirestoreService,
        libraryItemFirestoreId: String,
        libraryItemFirestoreData: FirebaseLibraryItem,
        dataSource: DataSource,
    ): ItemStatus {
        // We can't return directly from the `when` as that leads to issues in the
        // JS version of this.
        val status =
            when (libraryItemFirestoreData.status?.lowercase()) {
                "done", "success" -> ItemStatus.DONE
                "error" -> ItemStatus.FAILED
                "processing" -> ItemStatus.PROCESSING
                "initializing" -> ItemStatus.PROCESSING
                // We only want to run our heuristics for empty status, since we want to make sure that
                // all known values for status, are handled explicitly.
                "", null -> null
                else -> {
                    Log.d(
                        DiagnosticEvent(
                            message = "Unhandled status of library item. Received wrong status.",
                            properties = mapOf(
                                "itemId" to libraryItemFirestoreId,
                                "status" to libraryItemFirestoreData.status,
                            ),
                            sourceAreaId = "LibraryFirebaseTransformer.inferStatusForRecord",
                        ),
                    )
                    ItemStatus.FAILED
                }
            }

        if (status != null) {
            return status
        }

        // This item has no status set, we can check the "pages" sub collection to see if this item
        // was successfully processed at some point.
        val pagesResult =
            firebaseFirestoreService
                .coGetCollection(getLegacyPagesCollectionRef(libraryItemFirestoreId), dataSource = dataSource)

        val pages =
            when (pagesResult) {
                is Result.Success ->
                    pagesResult.value

                is Result.Failure ->
                    return ItemStatus.FAILED
            }.mapNotNull { page ->
                when (page) {
                    is FirebaseFirestoreDocumentSnapshot.Exists ->
                        page.value<FirebasePageItem>().toNullable()

                    else ->
                        null
                }
            }

        if (pages.isEmpty()) {
            // This record has no valid pages, mark it as failed.
            return ItemStatus.FAILED
        }

        if (pages[0].storagePath != null) {
            // This record has a valid page, mark it as done.
            return ItemStatus.DONE
        }

        Log.d(
            DiagnosticEvent(
                message = "could not determine status of library item",
                properties = mapOf("itemId" to libraryItemFirestoreId),
                sourceAreaId = "LibraryFirebaseTransformer.inferStatusForRecord",
            ),
        )
        return ItemStatus.FAILED
    }

    fun updateLibraryItemParamsToFirestoreSavePayload(
        updateLibraryItemParams: UpdateLibraryItemParams,
        firebaseTimestampAdapter: FirebaseTimestampAdapter,
    ): BoundaryMap<Any?> {
        val payloadMap = mutableMapOf("updatedAt" to firebaseTimestampAdapter.now())
        with(updateLibraryItemParams) {
            if (title != null) {
                payloadMap["title"] = title
            }
            if (coverImageUrl != null) {
                payloadMap["coverImagePath"] = coverImageUrl
            }
            if (listeningProgress != null) {
                payloadMap["listeningProgress"] =
                    FirebaseListeningProgress
                        .fromListeningProgress(listeningProgress)
                        .toBoundaryMap(firebaseTimestampAdapter)
            }
            return SdkBoundaryMap.fromMap(payloadMap)
        }
    }

    fun updateItemFolderToFirestoreSavePayload(
        folderId: String?,
        firebaseTimestampAdapter: FirebaseTimestampAdapter,
    ): BoundaryMap<Any?> {
        return SdkBoundaryMap.of(
            "updatedAt" to firebaseTimestampAdapter.now(),
            "parentFolderId" to folderId,
        )
    }

    fun shareItemToFirestoreSavePayload(sharedFlag: Boolean): BoundaryMap<Any?> {
        return SdkBoundaryMap.of(
            "isShared" to sharedFlag,
        )
    }

    fun createFolderToFirestoreSavePayload(
        ownerId: String,
        parentFolderId: FolderReference,
        title: String,
        firebaseTimestampAdapter: FirebaseTimestampAdapter,
    ): BoundaryMap<Any?> {
        val now = firebaseTimestampAdapter.now()
        return SdkBoundaryMap.of(
            "admins" to null,
            "coverImagePath" to null,
            "childrenCount" to 0,
            "createdAt" to now,
            "updatedAt" to now,
            "guests" to null,
            "isArchived" to false,
            "isArchivedV2" to false,
            "isRemoved" to false,
            RecordProperties.isRemovedV2.key.toPairWithVal(false),
            "owner" to ownerId,
            "parentFolderId" to parentFolderId.id,
            "removedAt" to null,
            "title" to title,
            "type" to "folder",
            "users" to null,
            "lastListenedAt" to null,
        )
    }

    fun buildShareLibraryItemFunctionPayload(itemId: String): Map<String, String> {
        return mapOf(
            "currentRecordUid" to itemId,
        )
    }

    fun buildLibraryManagementFunctionPayload(rootItemId: String): Map<String, String> {
        return mapOf("rootItemId" to rootItemId)
    }

    fun buildTransferLibraryFunctionPayload(
        fromUserIdToken: String,
        toUserIdToken: String,
    ): Map<String, String> {
        return mapOf("fromUserIdToken" to fromUserIdToken, "toUserIdToken" to toUserIdToken)
    }

    fun buildLibrarySearchPayload(searchRequestInternal: SearchRequestInternal): LibrarySearchPayload {
        return LibrarySearchPayload(
            title = searchRequestInternal.searchRequest.queryString,
            page = searchRequestInternal.page,
            pageSize = searchRequestInternal.searchRequest.pageSize,
            filters =
            searchRequestInternal.searchRequest.filters.mapNotNull {
                it.toElasticsearchFilters().map { filter -> filter.toJson() }
            }.flatten(),
        )
    }
}

internal fun SearchableFilterType.toElasticsearchFilters(): List<SearchFilter> {
    val nonArchivedOrRemovedItemsFilter = listOf(
        IsFalse("isArchived"),
        IsFalse("isArchivedV2"),
        IsFalse("isRemoved"),
        IsFalse("isRemovedV2"),
    )
    return when (this) {
        is FilterType.ANY -> nonArchivedOrRemovedItemsFilter
        is FilterType.FOLDERS -> nonArchivedOrRemovedItemsFilter + listOf(Eq("type", "folder"))
        is FilterType.TOP_LEVEL_ARCHIVED_ITEMS -> listOf(IsTrue("isTopLevelArchivedItem"))
        is FilterType.LISTENING_NOT_STARTED ->
            nonArchivedOrRemovedItemsFilter +
                listOf(Eq("progressStatus", "not-started"))
        is FilterType.LISTENING_IN_PROGRESS ->
            nonArchivedOrRemovedItemsFilter +
                listOf(Eq("progressStatus", "in-progress"))
        is FilterType.LISTENING_FINISHED ->
            nonArchivedOrRemovedItemsFilter +
                listOf(Eq("progressStatus", "done"))
        is LISTENING_IN_PROGRESS_AND_NOT_STARTED ->
            nonArchivedOrRemovedItemsFilter +
                listOf(OneOf("progressStatus", listOf("in-progress", "not-started")))
        is FilterType.RECORDS -> buildList {
            addAll(nonArchivedOrRemovedItemsFilter)
            add(Eq("type", "record"))
            if (recordTypes.isNotEmpty()) {
                add(OneOf("recordType", recordTypes.map { it.name }))
            }
        }
        else -> throw IllegalArgumentException("Unsupported SearchableFilter type: ${this::class}")
    }
}
