package com.speechify.client.api.content

import kotlin.js.JsExport

@JsExport
class SentenceAndWordLocation(
    val sentence: ContentText,
    /**
     * `null` means that the word was not found when attempted to resolve the index
     * This should not really happen but, as a fault tolerance, it's better to at least point at the sentence than
     * at nothing.
     * TODO - try to redesign so that this is non-nullable, i.e. word is always provided by the playback, e.g. always
     *  start with the first word and emit word changes, rather than index/cursor changes, thanks to which we always
     *  provide the word to SDK consumers.
     */
    val word: ContentText?,
)

/**
 * Compares the cursors and the [ContentText.text]s.
 * See [SentenceAndWordLocation.isEquivalentByStartAndEndCursors] for a quicker version that does not compare the text.
 */
internal fun SentenceAndWordLocation?.isEquivalent(
    other: SentenceAndWordLocation?,
): Boolean =
    this?.sentence.isEquivalent(other?.sentence) &&
        this?.word.isEquivalent(other?.word)

/**
 * A quicker version of [isEquivalent] that does not compare the text, for use where the text doesn't change.
 */
internal fun SentenceAndWordLocation?.isEquivalentByStartAndEndCursors(
    other: SentenceAndWordLocation?,
): Boolean =
    this?.sentence.isEquivalentByStartAndEndCursors(other?.sentence) &&
        this?.word.isEquivalentByStartAndEndCursors(other?.word)
