package com.speechify.client.internal.util.www

private class ByteBuffer(
    private var buf: ByteArray = byteArrayOf(),
    private var len: Int = 0,
) {
    fun push(b: Byte) {
        if (len == buf.size) {
            buf = buf.copyOf((buf.size + 1) * 2)
        }
        buf[len++] = b
    }

    fun intoString() = buf.decodeToString(0, len, throwOnInvalidSequence = false)
}

inline val Int.b get() = this.toByte()

internal fun String.percentDecode(): String {
    val buf = ByteBuffer()
    val bytes = this.encodeToByteArray()
    var i = 0
    while (i < bytes.size) {
        val b = bytes[i++]
        if (b == 37.b) { // '%'
            val byte = afterPercent(bytes, i)
            if (byte == null) {
                buf.push(b)
            } else {
                buf.push(byte)
                i += 2
            }
        } else {
            buf.push(b)
        }
    }
    return buf.intoString()
}

private fun Byte.toDigit(): Byte? = when (val b = this) {
    // 0 - 9
    in (48.b..57.b) -> b - 48.b
    // a - f
    in (97.b..102.b) -> b - 97.b + 10
    // A - F
    in (65.b..70.b) -> b - 65.b + 10
    else -> null
}?.toByte()

private fun afterPercent(bytes: ByteArray, i: Int): Byte? {
    val first = bytes[i].toDigit() ?: return null
    val second = bytes[i + 1].toDigit() ?: return null
    return (first * 0x10.b + second).toByte()
}
