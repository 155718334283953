package com.speechify.client.api.adapters.db

import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import kotlin.js.JsExport

/**
 * Platform specific implementation of [SqlDriver] factory which creates a [SqlDriver] for use by the SDK.
 * The [createSqlDriver] function is also responsible for creating the DB schema, this is because on some platforms
 * schema creation is part of the actual driver implementation. For platforms where this is a seperate step it should
 * happen in the [createSqlDriver] function.
 */
@JsExport
abstract class AbstractSqlDriverFactory {

    /**
     * Creates a new [SqlDriver] for use by the SDK and initializes the DB schema.
     * SDK will only call this function once and will use the same [SqlDriver] instance for the lifetime of the SDK.
     */
    internal abstract suspend fun createSqlDriver(localKeyValueStorageAdapter: LocalKeyValueStorageAdapter): SqlDriver
}

/**
 * This var ensures the `!!` below fails-fast to the developer if they for some reason change to a class without a name
 */
internal val AbstractSqlDriverFactoryClassName = AbstractSqlDriverFactory::class.simpleName!!
