package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
enum class SubscriptionDiscountDuration(val status: String) {
    Forever("forever"),
    Once("once"),
    Repeating("repeating"),
}
