package com.speechify.client.api.adapters.db

import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully

/**
 * A base for [AbstractSqlDriverFactory] implementations without built-in migrations (so, belonging to [SqlDriverFactoryForMigrationlessDriverDependenciesBase])
 * that are an in-memory-only implementation.
 * (In-memory database always starts from empty, so the database version should also not be persistent, i.e. be in-memory)
 */
@JsExport
abstract class SqlDriverFactoryUsingWebWorkerForInMemoryOnlyDependenciesBase :
    SqlDriverFactoryUsingWebWorkerDependenciesBase() {
    private var lastMigrationVersion: Int? = null

    override fun getLastMigrationVersionStorage(
        localKeyValueStorageAdapter: LocalKeyValueStorageAdapter,
    ): SqlLastMigrationVersionStorage =
        @JsExport.Ignore
        object : SqlLastMigrationVersionStorage() {
            override fun getLastMigrationVersion(callback: Callback<Int?>) = callback.fromCo {
                lastMigrationVersion
                    .successfully()
            }

            override fun setLastMigrationVersion(
                newValue: Int,
                callback: Callback<Unit>,
            ) = callback.fromCo {
                lastMigrationVersion = newValue
                Unit.successfully()
            }
        }

    override fun ensureDbDoesNotExist(
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    ) = callback.fromCo {
        Unit.successfully()
    }
}

@JsExport
class SqlDriverFactoryUsingWebWorkerForInMemoryOnly(
    dependencies: SqlDriverFactoryUsingWebWorkerForInMemoryOnlyDependenciesBase,
) : SqlDriverFactoryUsingWebWorker(
    dependencies = dependencies,
)
