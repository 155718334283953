package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
enum class SubscriptionSource(val source: String) {
    STRIPE("stripe"),
    APPLE("apple"),
    PAYPAL("paypal"),
    PLAY_STORE("play_store"),
    TEAMS("teams"),
    OTHER("other"),
}
