package com.speechify.client.api.telemetry

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.flattenSdkFailureToNative
import com.speechify.client.internal.sync.WrappingMutex
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.collections.flows.flowFromAsyncSeed
import com.speechify.client.internal.util.diagnostics.enriching.errorEnrichingByPropertyAdd
import com.speechify.client.internal.util.extensions.collections.flows.collectWrapper
import com.speechify.client.internal.util.extensions.throwable.addValueToPropertyList
import com.speechify.client.internal.util.extensions.throwable.getOrSetErrorUUID
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withContext
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract
import kotlin.coroutines.AbstractCoroutineContextElement
import kotlin.coroutines.CoroutineContext

/**
 * Calls [block] while reporting telemetry.
 *
 * Reports a [com.speechify.client.api.telemetry.TelemetryEvent] with properties describing the execution time of the
 * block and any other properties accumulated during execution of [block] via the builder passed to it.
 *
 * This is also the one common building block for other convenience functions for the telemetry, written as `inline`,
 * so it works with `suspend` and non-`suspend` functions alike.
 *
 * NOTE: when wrapping an entire function, make it a `=` style function, and put the call in the same line as the `=`
 * like in `) = withTelemetry {`, so that indentation in the function is single-level, just like for a plain one (Kotlin
 * formatter treats it like a plain block decorator, similar to `by lazy`).
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <reified T> withTelemetry(
    telemetryEventName: String,
    // Don't remove "crossinline" - Removing it will make it possible for callers to use an unqualified return, which
    // will prevent telemetry from being reported.
    crossinline block: suspend (TelemetryEventBuilder) -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return withTelemetryViaRemap(
        telemetryEvent = TelemetryEventBuilder(
            telemetryEventName,
        ),
        entryPoint = "withTelemetry",
        block = block,
        /* Detect `Result.Failure`s automatically, so that when changing between Result-based and idiomatic, developers
           don't have to use a different function for telemetry.
         */
        reMapResult = if (T::class !== Result::class) {
            { result -> result }
        } else {
            { result ->
                @Suppress("UNCHECKED_CAST")
                (result as kotlin.Result<Result<*>>).flattenSdkFailureToNative() as kotlin.Result<T>
            }
        },
    )
}

/**
 * NOTE: If there is a need to put the telemetry event builder in the context, use [withTelemetryInContext] instead
 * or prepare the context yourself using [contextWithTelemetryEventBuilderNode], because this function doesn't do that,
 * and there will be no telemetry event builder in the context if the context hasn't been prepared
 * (this is especially in order to be usable as flow wrappers, where using [withContext] violates the [Flow invariant](https://kotlinlang.org/docs/flow.html#a-common-pitfall-when-using-withcontext)
 * #WithTelemetryDoesntAddEventToContext
 */
@OptIn(ExperimentalContracts::class)
private suspend inline fun <reified T> withTelemetry(
    telemetryEvent: TelemetryEventBuilder,
    // Don't remove "crossinline" - Removing it will make it possible for callers to use an unqualified return, which
    // will prevent telemetry from being reported.
    crossinline block: suspend (TelemetryEventBuilder) -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return withTelemetryViaRemap(
        telemetryEvent = telemetryEvent,
        entryPoint = "withTelemetry",
        block = block,
        /* Detect `Result.Failure`s automatically, so that when changing between Result-based and idiomatic, developers
           don't have to use a different function for telemetry.
         */
        reMapResult = if (T::class !== Result::class) {
            { result -> result }
        } else {
            { result ->
                @Suppress("UNCHECKED_CAST")
                (result as kotlin.Result<Result<*>>).flattenSdkFailureToNative() as kotlin.Result<T>
            }
        },
    )
}

/**
 * A version of [withTelemetry] that also ensures a non-null [TelemetryEventBuilder] is available in
 * [currentTelemetryEvent].
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <reified T> withTelemetryInContext(
    telemetryEventName: String,
    // Don't remove "crossinline" - Removing it will make it possible for callers to use an unqualified return, which
    // will prevent telemetry from being reported.
    crossinline block: suspend (TelemetryEventBuilder) -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return withContext(contextWithTelemetryEventBuilderNode()) {
        withTelemetry(
            telemetryEventName = telemetryEventName,
        ) {
            block(it)
        }
    }
}

/**
 * Extracted implementation of [withTelemetry] to allow [reMapResult] to differ depending on result type.
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <T> withTelemetryViaRemap(
    telemetryEvent: TelemetryEventBuilder,
    entryPoint: String,
    // Don't remove "crossinline" - Removing it will make it possible for callers to use an unqualified return, which
    // will prevent telemetry from being reported.
    crossinline block: suspend (telemetryEventBuilder: TelemetryEventBuilder) -> T,
    /**
     * If some results of the calls should be considered unsuccessful, use this to remap to [kotlin.Result.failure].
     */
    reMapResult: (result: kotlin.Result<T>) -> kotlin.Result<T>,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    val telemetryEventBuilder = telemetryEvent.addStartTime()
    withTelemetryInContextWithoutMeasuringWithNonLocalReturns(telemetryEventBuilder) {
        val result = runCatching {
            // We want to copy all telemetry properties to the error.
            errorEnrichingByPropertyAdd(
                getCustomProperties = {
                    telemetryEventBuilder.getProperties().map {
                        it.key to it.value
                    }
                },
            ) {
                block(telemetryEventBuilder)
            }
        }

        if (!SpeechifySDKTelemetry.enabled) {
            return result.getOrThrow()
        }

        run {
            try {
                val resultRemappedForTelemetry = reMapResult(result)
                val exception = resultRemappedForTelemetry.exceptionOrNull()
                if (exception is CancellationException) {
                    return@run // Don't report telemetry for cancelled calls.
                }

                if (exception != null) {
                    // We link the error to this event so we can look it up later.
                    val errorUuid = exception.getOrSetErrorUUID()
                    telemetryEventBuilder.addProperty(ErrorUuidTelemetryProp.toPairWithVal(errorUuid))

                    // In case an error bubbles through multiple telemetry blocks this will allow us to see which ones.
                    exception.addValueToPropertyList("passed_telemetry_blocks", telemetryEvent.telemetryEventName)
                }

                SpeechifySDKTelemetry.report(
                    telemetryEventBuilder.addResult(
                        resultRemappedForTelemetry,
                    ).build(),
                )
            } catch (ex: Exception) {
                // If we get cancelled while reporting don't spam the logs.
                if (ex is CancellationException) {
                    throw ex
                } else {
                    Log.e(
                        error = SDKError.OtherException(ex),
                        message = entryPoint,
                        sourceAreaId = "WithTelemetry",
                    )
                }
            }
        }
        return result.getOrThrow()
    }
}

/**
 * Only ensures that a non-null [TelemetryEventBuilder] is available in [currentTelemetryEvent], so that properties
 * can be added to it, but doesn't actually measure or report the [block].
 */
@OptIn(ExperimentalContracts::class)
private suspend inline fun <T> withTelemetryInContextWithoutReporting(
    telemetryEvent: TelemetryEventBuilder,
    /* Don't remove "crossinline" - Removing it will make it possible for callers to use an unqualified return, which
     * will prevent telemetry from being reported. */
    crossinline block: suspend (telemetryEventBuilder: TelemetryEventBuilder) -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    addTelemetryEventBuilderNodeToContext(telemetryEvent)

    return withContext(
        contextWithTelemetryEventBuilderNode(),
    ) {
        withTelemetryInContextWithoutMeasuringWithNonLocalReturns(telemetryEvent) { telemetryEventBuilder ->
            block(telemetryEventBuilder)
        }
    }
}

/**
 * For internal use only - NOTE: this doesn't yet initialize the context with [contextWithTelemetryEventBuilderNode]
 * - it must be used in an already prepared context.
 * A version of [withTelemetryInContextWithoutReporting] that allows non-local returns (without `crossinline`) - for internal use only.
 */
@OptIn(ExperimentalContracts::class)
private suspend inline fun <T> withTelemetryInContextWithoutMeasuringWithNonLocalReturns(
    telemetryEvent: TelemetryEventBuilder,
    block: (telemetryEventBuilder: TelemetryEventBuilder) -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    addTelemetryEventBuilderNodeToContext(telemetryEvent)

    return try {
        block(telemetryEvent)
    } finally {
        removeTelemetryEventBuilderNodeFromContext(telemetryEvent)
    }
}

/**
 * An extension-version of [withTelemetryOfFlowCollectingAllItems] to be used across collection of all the items
 * of the flow specified in [this].
 */
internal fun <T> Flow<T>.withTelemetryOfCollectingAllItems(
    telemetryEventName: String,
    properties: Iterable<Pair<String, Any>> = emptyList(),
): Flow<T> =
    withTelemetryOfFlowCollectingAllItems(
        telemetryEventName = telemetryEventName,
        properties = properties,
        getFlowWithTelemetry = { this },
    )

/**
 * Adds telemetry around when the [getFlowWithTelemetry] finishes consuming (so, not reporting when consuming each individual item,
 * finished, but rather when all consumption has finished).
 * Same semantics as [withTelemetryInContext], apply, so e.g.
 * - cancellations are not reported.
 * - the telemetry event is available in [currentTelemetryEvent].
 */
internal fun <T> withTelemetryOfFlowCollectingAllItems(
    telemetryEventName: String,
    properties: Iterable<Pair<String, Any>> = emptyList(),
    getFlowWithTelemetry: (telemetry: TelemetryEventBuilder) -> Flow<T>,
): Flow<T> =
    flowFromAsyncSeed(
        getSeed = {
            TelemetryEventBuilder(
                telemetryEventName,
            )
                .also {
                    it.addProperties(
                        *properties.toList().toTypedArray(),
                    )
                }
        },
        getFlow = { telemetry ->
            withTelemetryInContextWithoutReporting(
                telemetryEvent = telemetry,
            ) {
                getFlowWithTelemetry(telemetry)
                    .collectWrapper(
                        /**
                         * We must prepare context for [withTelemetry], because it doesn't add it, as per #WithTelemetryDoesntAddEventToContext
                         */
                        contextForWrapperAndUpstreamFlow = contextWithTelemetryEventBuilderNode(),
                    ) { collect ->
                        /**
                         * Note that, thanks to using [withTelemetry], this will also have a standard behavior for an
                         * error, in that the telemetry event will even be reported with a failure result.
                         */
                        /**
                         * Also as per #WithTelemetryDoesntAddEventToContext, we cannot use [withTelemetryInContext],
                         * as this would break [Flow invariant](https://kotlinlang.org/docs/flow.html#a-common-pitfall-when-using-withcontext),
                         * so must use [withTelemetry] with the `contextForWrapperAndUpstreamFlow` above.
                         */
                        withTelemetry(
                            telemetryEvent = telemetry,
                        ) {
                            collect()
                        }
                    }
            }
        },
    )

/**
 * An overload of [withTelemetryOfFlowCollectingAllItems] that doesn't take a lambda for [flow], reducing indentation.
 */
internal fun <T> withTelemetryOfFlowCollectingAllItems(
    telemetryEventName: String,
    properties: Iterable<Pair<String, Any>> = emptyList(),
    flow: Flow<T>,
) =
    withTelemetryOfFlowCollectingAllItems(
        telemetryEventName = telemetryEventName,
        properties = properties,
        getFlowWithTelemetry = { flow },
    )

/**
 * Builds a flow with telemetry around when the flow finishes consuming (so, not reporting when consuming each
 * individual item, finished, but rather when all consumption has finished).
 * Same semantics as [withTelemetry], apply, so e.g. cancellations are not reported.
 */
internal fun <T> flowWithTelemetryOfCollectingAllItems(
    telemetryEventName: String,
    properties: Iterable<Pair<String, Any>> = emptyList(),
    block: suspend FlowCollector<T>.(TelemetryEventBuilder) -> Unit,
): Flow<T> =
    withTelemetryOfFlowCollectingAllItems(
        telemetryEventName = telemetryEventName,
        properties = properties,
        getFlowWithTelemetry = { telemetry ->
            flow {
                block(this, telemetry)
            }
        },
    )

/**
 * Runs the given block and adds the timing information to the given [TelemetryEventBuilder].
 * If the block fails the timing is still recorded.
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <T> TelemetryEventBuilder?.addMeasurement(
    measurementName: String,
    block: () -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    val startTime = DateTime.now()
    try {
        return block()
    } finally {
        this?.addProperty(
            "measurement_$measurementName",
            DateTime.now().asMillisecondsLong() - startTime.asMillisecondsLong(),
        )
    }
}

private class TelemetryEventBuilderNode :
    AbstractCoroutineContextElement(Key) {

    val telemetryEventBuilderStack: WrappingMutex<MutableList<TelemetryEventBuilder>> =
        WrappingMutex.of(mutableListOf())

    companion object Key : CoroutineContext.Key<TelemetryEventBuilderNode>
}

/**
 * Call this at the highest level of the call stack with [withContext] to setup the coroutine for easy passing of the
 * telemetry event builder.
 */
internal suspend fun contextWithTelemetryEventBuilderNode(): CoroutineContext =
    (currentTelemetryEventBuilderNode() ?: TelemetryEventBuilderNode())

private suspend fun addTelemetryEventBuilderNodeToContext(telemetryEventBuilder: TelemetryEventBuilder) =
    currentTelemetryEventBuilderNode()
        ?.telemetryEventBuilderStack
        ?.locked { it.add(telemetryEventBuilder) }

private suspend fun removeTelemetryEventBuilderNodeFromContext(telemetryEventBuilder: TelemetryEventBuilder) =
    currentTelemetryEventBuilderNode()
        ?.telemetryEventBuilderStack
        ?.locked { it.remove(telemetryEventBuilder) }

/**
 * If available gives you the current [TelemetryEventBuilder] for the current coroutine context.
 * In case multiple [withTelemetry] calls are nested, this will write to the latest one.
 */
internal suspend fun currentTelemetryEvent(): TelemetryEventBuilder? =
    currentTelemetryEventBuilderNode()?.telemetryEventBuilderStack?.locked {
        it.lastOrNull()
    }

private suspend fun currentTelemetryEventBuilderNode(): TelemetryEventBuilderNode? =
    currentCoroutineContext()[TelemetryEventBuilderNode]
