package com.speechify.client.api.content.epub

import com.speechify.client.api.adapters.archiveFiles.ArchiveFilesAdapter
import com.speechify.client.api.adapters.archiveFiles.ZipArchiveView
import com.speechify.client.api.adapters.archiveFiles.ZipFileEntry
import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.adapters.xml.XMLDOMElement
import com.speechify.client.api.adapters.xml.XMLDOMNode
import com.speechify.client.api.adapters.xml.XMLDOMTextNode
import com.speechify.client.api.adapters.xml.XMLParser
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.FileFromString
import com.speechify.client.api.util.io.InMemoryByteArrayBinaryContent
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.services.file.models.InMemoryFile

internal val ignorableChapterFilenames = listOf(
    "toc",
    "contents",
    "content",
    "cover",
    "SS_US_adult_signup_front",
)

internal data class Epub(
    val title: String?,
    val htmlContent: HTMLDOMElement,
    val coverImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?,
    val navigation: EpubNavigation?,
)

internal data class EpubV2(
    val title: String?,
    val rawChapters: Map<Int, EpubRawChapter>,
    val coverImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?,
    val navigation: EpubNavigation?,
    val guide: List<EpubGuideReference>,
) {
    val startOfMainContent by lazy {
        val href =
            navigation?.landmarks?.firstOrNull { it.type.lowercase() == "bodymatter" }?.href
                ?: guide.firstOrNull { it.type.lowercase() == "text" }?.href

        val specifiedByAuthor = href?.let {
            // Remove path and fragment to get the filename
            val filename = href.substringAfterLast("/").substringBeforeLast("#")
            val entry = rawChapters.entries.first { rawChapter -> rawChapter.value.filename == filename }
            EpubStartOfMainContent(
                chapterIndex = entry.key,
                fragment = if (it.contains("#")) it.substringAfter("#") else null,
            )
        }

        val inferred = rawChapters
            .entries
            .sortedBy { it.key }
            .firstOrNull { !it.value.isProbablyFrontOrBackMatterThatWeShouldIgnore }
            ?.let {
                EpubStartOfMainContent(
                    chapterIndex = it.key,
                    fragment = null,
                )
            }

        if (specifiedByAuthor != null && inferred != null && inferred.chapterIndex > specifiedByAuthor.chapterIndex) {
            inferred
        } else {
            specifiedByAuthor ?: inferred
        }
    }
}

internal data class EpubLandmark(
    val type: String,
    val title: String?,
    val href: String,
)

internal data class EpubRawChapter(
    val filename: String,
    val file: InMemoryFile,
    val estimatedContentRatio: Double,
)

internal data class EpubSpineItem(
    val idref: String,
)

internal data class EpubManifestItem(
    val id: String,
    val href: String,
    val properties: String?,
)

internal data class EpubGuideReference(
    val type: String,
    val title: String?,
    val href: String,
)

internal data class EpubOpf(
    val opfFilePath: String,
    val title: String?,
    val coverImageHref: String?,
    val spine: List<EpubSpineItem>,
    val manifest: List<EpubManifestItem>,
    val guide: List<EpubGuideReference>,
)

internal data class EpubStartOfMainContent(
    val chapterIndex: Int,
    val fragment: String?,
)

internal data class EpubNavigation(
    val tocNavPoints: List<EpubNavPoint>,
    val landmarks: List<EpubLandmark>,
) {
    val flattenedTocNavPoints by lazy {
        tocNavPoints.flattenNavPoints()
    }
}

internal data class EpubNavPoint(
    internal val href: String,
    val chapterIndex: Int,
    val label: String,
    val children: List<EpubNavPoint> = emptyList(),
) {
    val id by lazy {
        // If the href contains a fragment identifier (after a # symbol), extract it and use it to match
        // the navigation points with their corresponding nodes in the upper layers.
        if (href.contains("#")) {
            href.substringAfter("#")
        } else {
            // If there’s no fragment identifier, the href points to the beginning of the document.
            // Since the parsing process concatenates all files into one, we generate a custom id
            // to correctly identify the document's starting point for matching navigation points
            // with their corresponding nodes in the upper layers.
            Constants.chapterBoundaryId(chapterIndex)
        }
    }

    val fragment by lazy {
        if (href.contains("#")) href.substringAfter("#") else null
    }
}

internal data class FlattenedEpubNavPoint(
    val level: Int,
    val navPoint: EpubNavPoint,
)

/**
 * This class acts like an "EPUB factory" of sorts - it takes the raw binary content of an EPUB, parses it, and assembles
 * the componenents into an [Epub] that we can use for consumption inside the SDK.
 */
class EpubParser internal constructor(
    private val archiveFilesAdapter: ArchiveFilesAdapter,
    private val xmlParser: XMLParser,
    private val htmlParser: HTMLParser,
) {

    /**
     * Turns an EPUB's file binary content into an [Epub] object containing the HTML content stitched together
     * with correct chapter ordering, the Title if available, and the Cover Image if available
     */
    internal suspend fun parseEpub(bytes: BinaryContentReadableRandomly): Epub {
        val unzippedEntries = archiveFilesAdapter.coCreateViewOfZip(bytes)
        return parseEpub(unzippedEntries)
    }

    internal suspend fun parseEpubV2(bytes: BinaryContentReadableRandomly): EpubV2 {
        val unzippedEntries = archiveFilesAdapter.coCreateViewOfZip(bytes)
        return parseEpubV2(unzippedEntries)
    }

    internal suspend fun parseEpubFromByteArray(binaryContent: InMemoryByteArrayBinaryContent): Epub {
        val unzippedEntries = archiveFilesAdapter.coCreateViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(
            binaryContent.binaryContent.coGetAllBytes().orThrow(),
        )
        return parseEpub(unzippedEntries)
    }

    internal suspend fun parseEpubV2FromByteArray(binaryContent: InMemoryByteArrayBinaryContent) =
        parseEpubV2(
            archiveFilesAdapter.coCreateViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(
                binaryContent.binaryContent.coGetAllBytes().orThrow(),
            ),
        )

    private suspend fun parseEpub(unzippedEntries: ZipArchiveView): Epub {
        val epubOpfFile = findAndParseOpfFile(unzippedEntries)
        val coverImage = getCoverImage(epubOpfFile, unzippedEntries)
        val correctlyOrderedAndConcatenatedHtml =
            getCorrectlyOrderedAndConcatenatedHtml(epubOpfFile, unzippedEntries.entries.toList())
        val navigation = parseNavOrNcxFile(epubOpf = epubOpfFile, unzippedEntries = unzippedEntries)

        unzippedEntries.coDestroy()

        return Epub(
            htmlContent = correctlyOrderedAndConcatenatedHtml,
            title = epubOpfFile.title,
            coverImage = coverImage,
            navigation = navigation,
        )
    }

    private suspend fun parseEpubV2(unzippedEntries: ZipArchiveView): EpubV2 {
        val epubOpfFile = findAndParseOpfFile(unzippedEntries)
        val coverImage = getCoverImage(epubOpfFile, unzippedEntries)
        val rawChapters = getRawChapters(epubOpf = epubOpfFile, unzippedEntries = unzippedEntries.entries.toList())
        val navigation = parseNavOrNcxFile(epubOpf = epubOpfFile, unzippedEntries = unzippedEntries)

        unzippedEntries.coDestroy()
        return EpubV2(
            title = epubOpfFile.title,
            rawChapters = rawChapters,
            coverImage = coverImage,
            navigation = navigation,
            guide = epubOpfFile.guide,
        )
    }

    internal suspend fun findAndParseOpfFile(unzippedEntries: ZipArchiveView): EpubOpf {
        val opfContent = unzippedEntries.entries.first { it.path.endsWith(".opf") }
        val opfPath = opfContent.path

        val opfFile = FileFromString(
            "text/xml",
            opfContent.coCreateBinaryContentReadableRandomly().coGetAllBytes().orThrow().decodeToString(),
        )
        val opfElements = coGetAllElements(opfFile)

        // The "ITEMREF" tag defines a spine reference to a manifest item, which represents a reading order.
        // EPUB 3.0 reference: http://www.idpf.org/epub/30/spec/epub30-publications.html#sec-itemref-elem
        // EPUB 2.0 reference: http://idpf.org/epub/20/spec/OPF_2.0.1_draft.htm#Section2.4.1
        val spine = opfElements.filter { it.tagName.lowercase() == "itemref" }
        val spineItems = spine.flatMap {
            it.attributes.filter { (first, _) -> first == "idref" }.map { (_, idref) -> EpubSpineItem(idref) }
        }

        // The "DC:TITLE" tag in EPUB specifies the title of the publication.
        // EPUB 3.0 reference: http://www.idpf.org/epub/30/spec/epub30-publications.html#sec-title-elem
        // EPUB 2.0 reference: : http://idpf.org/epub/20/spec/OPF_2.0.1_draft.htm#Section2.2.1
        val title =
            opfElements.firstOrNull { it.tagName.lowercase() == "dc:title" }
                ?.children?.filterIsInstance<XMLDOMTextNode>()
                ?.firstOrNull()?.text

        // The "ITEM" tag defines a manifest item, a rendition resource.
        // EPUB 3.0 reference: http://www.idpf.org/epub/30/spec/epub30-publications.html#sec-item-elem
        // EPUB 2.0 reference: http://idpf.org/epub/20/spec/OPF_2.0.1_draft.htm#Section2.4

        val manifest = opfElements.filter { it.tagName.lowercase() == "item" }

        val epubManifestItems = manifest.mapNotNull { element ->
            val id = element.attributes.find { it.first == "id" }?.second
            val href = element.attributes.find { it.first == "href" }?.second
            val properties = element.attributes.find { it.first == "properties" }?.second
            when {
                id != null && href != null -> EpubManifestItem(id, href = href, properties = properties)
                else -> null
            }
        }

        // The "META" tag provides metadata information about the EPUB publication.
        // EPUB 3.0 reference: http://www.idpf.org/epub/30/spec/epub30-publications.html#sec-meta-elem
        // EPUB 2.0 reference: http://idpf.org/epub/20/spec/OPF_2.0.1_draft.htm#Section2.2
        val meta =
            opfElements.firstOrNull {
                it.tagName.lowercase() == "meta" && it.attributes
                    .any { pair -> pair.second == "cover" }
            }
        val imgAttribute = meta?.attributes?.firstOrNull { it.first == "content" }?.second
        val imgLocation =
            manifest.firstOrNull { it.attributes.any { it.first == "id" && it.second == imgAttribute } }
                ?.attributes?.firstOrNull { it.first == "href" }?.second

        // Parse guide references
        val guideElement = opfElements.find { it.tagName.lowercase() == "guide" }
        val guideReferences = guideElement?.children
            ?.filterIsInstance<XMLDOMElement>()
            ?.filter { it.tagName.lowercase() == "reference" }
            ?.mapNotNull { element ->
                val type = element.attributes.find { it.first == "type" }?.second ?: return@mapNotNull null
                val href = element.attributes.find { it.first == "href" }?.second ?: return@mapNotNull null
                val title = element.attributes.find { it.first == "title" }?.second

                EpubGuideReference(type, title, href)
            } ?: emptyList()

        return EpubOpf(
            opfFilePath = opfPath,
            title = title,
            coverImageHref = imgLocation,
            spine = spineItems,
            manifest = epubManifestItems,
            guide = guideReferences,
        )
    }

    private suspend fun parseNavOrNcxFile(
        epubOpf: EpubOpf,
        unzippedEntries: ZipArchiveView,
    ) = parseNavFile(epubOpf, unzippedEntries) ?: parseNcxFile(epubOpf, unzippedEntries)

    private suspend fun parseNavFile(epubOpf: EpubOpf, unzippedEntries: ZipArchiveView): EpubNavigation? {
        // Find the nav file in the manifest
        val navItem = epubOpf.manifest.find { item ->
            item.properties?.split(" ")?.contains("nav") == true
        } ?: return null

        // Extract and decode the nav file content
        val navBasePath = navItem.href.substringBeforeLast(delimiter = '/', missingDelimiterValue = String())
        val navContent = unzippedEntries.entries.find { it.path.endsWith(navItem.href) }
            ?.coCreateBinaryContentReadableRandomly()?.coGetAllBytes()?.orThrow()?.decodeToString()
            ?: return null

        // Parse the nav file content as XML
        val navDom = xmlParser.coParseAsDOM(FileFromString("application/xhtml+xml", navContent))

        // Find both TOC and landmarks nav elements
        val tocNavElement = findNavElementByType(navDom, "toc")
        val landmarksNavElement = findNavElementByType(navDom, "landmarks")

        // Create a map of href to spine index for easy lookup
        val hrefToIndexMap = epubOpf.spine.mapIndexed { index, spineItem ->
            epubOpf.manifest.find { it.id == spineItem.idref }?.href to index
        }.toMap()

        // Parse TOC nav points
        val tocNavPoints = tocNavElement?.let {
            parseNavPoints(
                navElement = it,
                hrefToIndexMap = hrefToIndexMap,
                navBasePath = navBasePath,
            )
        } ?: emptyList()

        // Parse landmarks
        val landmarks = landmarksNavElement?.let {
            parseLandmarks(it, navBasePath)
        } ?: emptyList()

        return if (tocNavPoints.isEmpty()) {
            null
        } else {
            EpubNavigation(
                tocNavPoints = tocNavPoints,
                landmarks = landmarks,
            )
        }
    }

    private suspend fun parseNcxFile(epubOpf: EpubOpf, unzippedEntries: ZipArchiveView): EpubNavigation? {
        // Find the NCX file in the manifest
        val ncxItem = epubOpf.manifest.find { it.id == "ncx" || it.href.endsWith(".ncx") } ?: return null

        // Extract and decode the NCX file content
        val ncxContent = unzippedEntries.entries.find { it.path.endsWith(ncxItem.href) }
            ?.coCreateBinaryContentReadableRandomly()?.coGetAllBytes()?.orThrow()?.decodeToString()
            ?: return null

        // Parse the NCX file content as XML
        val ncxDom = xmlParser.coParseAsDOM(FileFromString("application/x-dtbncx+xml", ncxContent))

        // Find the navMap element
        val navMapElement = findNavMapElement(ncxDom) ?: return null

        // Create a map of href to spine index for easy lookup
        val hrefToIndexMap = epubOpf.spine.mapIndexed { index, spineItem ->
            epubOpf.manifest.find { it.id == spineItem.idref }?.href to index
        }.toMap()

        // Parse the NCX navigation points
        return EpubNavigation(
            tocNavPoints = parseNcxNavPoints(
                element = navMapElement,
                hrefToIndexMap = hrefToIndexMap,
            ),
            landmarks = emptyList(),
        )
    }

    /**
     * Organizes and concatenates HTML content based on the order defined in the EPUB's spine (from the OPF file).
     * The spine provides a logical reading order, ensuring that chapters and sections are presented
     * in the sequence intended by the EPUB's creator.
     */
    private suspend fun getCorrectlyOrderedAndConcatenatedHtml(
        epubOpf: EpubOpf,
        // instead of filtering for content files by a heuristic that looks at extensions (which can come in many
        // formats including .html, .xhtml, .xml, etc. and is error-prone) we can actually just pass in the entire
        // content paths of the archive, and then we can filter and sort based on the manifest, so it doesn't matter
        // what the extension of the content files are, we just match them against the manifest.
        unzippedEntries: List<ZipFileEntry>,
    ): HTMLDOMElement {
        // Sort the content files based on the ordering in the spine
        // We also exclude all content that is not in the spine
        val manifestMap = epubOpf.manifest.associateBy { it.id }
        val hrefs: List<String> = epubOpf.spine.mapNotNull {
            manifestMap[it.idref]?.href?.substringAfterLast("/")
        }
        val zipEntryMap = unzippedEntries.associateBy { it.path.substringAfterLast("/") }
        val sortedFiles = hrefs.mapNotNull { zipEntryMap[it]?.coCreateBinaryContentReadableRandomly() }
        val htmlTexts = sortedFiles.map { it.coGetAllBytes().orThrow().decodeToString() }
        val concatenated = concatHtml(htmlTexts)
        return htmlParser.coParseAsDOM(FileFromString("text/html", concatenated)).orThrow()
    }

    private suspend fun getRawChapters(
        epubOpf: EpubOpf,
        unzippedEntries: List<ZipFileEntry>,
    ): Map<Int, EpubRawChapter> {
        // Get manifest items by ID for spine lookup
        val manifestMap = epubOpf.manifest.associateBy { it.id }

        // Map zip entries by filename for easy lookup
        val zipEntryMap = unzippedEntries.associateBy { it.path.substringAfterLast("/") }

        // First, collect all the files and their sizes
        val chaptersWithSizes = epubOpf.spine.mapIndexedNotNull { index, spineItem ->
            val manifestItem = manifestMap[spineItem.idref] ?: return@mapIndexedNotNull null
            val filename = manifestItem.href.substringAfterLast("/")
            val zipEntry = zipEntryMap[filename] ?: return@mapIndexedNotNull null

            val extension = getFileExtension(filename).lowercase()
            val binary = zipEntry.coCreateBinaryContentReadableRandomly()
            val bytes = binary.coGetAllBytes().orThrow()

            val inMemoryFile = InMemoryFile(
                mimeType = when (extension) {
                    "html", "htm" -> "text/html"
                    "xhtml", "xml" -> "application/xhtml+xml"
                    else -> null
                }?.let { MimeType(typeSubtype = it) },
                bytes = bytes,
            )

            index to (Triple(inMemoryFile, bytes.size, filename))
        }

        // Calculate total size of all chapters
        val totalSize = chaptersWithSizes.sumOf { it.second.second.toDouble() }

        // Create final map with relative sizes
        return chaptersWithSizes.associate { (index, triple) ->
            val (file, size, filename) = triple
            val estimatedContentRatio = (size.toDouble() / totalSize * 100)

            index to EpubRawChapter(
                filename = filename,
                file = file,
                estimatedContentRatio = estimatedContentRatio,
            )
        }
    }

    private suspend fun getCoverImage(
        epubOpf: EpubOpf,
        unzippedContent: ZipArchiveView,
    ): BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>? {
        return when (val imgLocation = epubOpf.coverImageHref) {
            null -> null
            else -> {
                val extension = getFileExtension(imgLocation)
                val mimeTypeMap = mapOf(
                    "jpg" to "image/jpeg",
                    "jpeg" to "image/jpeg",
                    "png" to "image/png",
                )

                mimeTypeMap[extension]?.let { mimeType ->
                    // Try precise path resolution first
                    val opfDirectory = epubOpf.opfFilePath.substringBeforeLast(
                        delimiter = "/",
                        missingDelimiterValue = String(),
                    )
                    val absoluteImagePath = when {
                        imgLocation.startsWith(prefix = "/") -> imgLocation.removePrefix(prefix = "/")
                        opfDirectory.isEmpty() -> imgLocation
                        else -> "$opfDirectory/$imgLocation"
                    }
                    val normalizedPath = normalizePathComponents(absoluteImagePath)

                    val precisePathEntry = unzippedContent.entries.firstOrNull {
                        it.path == normalizedPath
                    }

                    // Fallback to "original" filename-only matching logic
                    val coverEntry = precisePathEntry ?: unzippedContent.entries.firstOrNull {
                        it.path.substringAfterLast("/") == imgLocation.substringAfterLast("/")
                    }

                    coverEntry?.coCreateBinaryContentReadableRandomly()?.withMimeType(
                        MimeType(mimeType),
                    )
                }
            }
        }
    }

    private suspend fun coGetAllElements(file: File): List<XMLDOMElement> {
        val result = mutableListOf<XMLDOMElement>()
        fun extractDOMElements(node: XMLDOMNode) {
            if (node is XMLDOMElement) {
                result.add(node)
                node.children.forEach { child -> extractDOMElements(child) }
            }
        }

        val root = xmlParser.coParseAsDOM(file)
        extractDOMElements(root)
        return result
    }

    /**
     * Concatenates a list of HTML content strings into a single HTML content string.
     *
     * The function specifically focuses on extracting content from within the <body> tags of each HTML and concatenating
     * them into one unified <body> within a new <html> tag. If a particular HTML does not have clear <body> tags, the
     * entire content of that HTML is used as is.
     *
     * This approach is predicated on the general standardization and well-formed nature of HTML content in EPUBs. EPUB
     * specifications mandate that the contained HTML/XHTML files be valid and adhere to specific standards. This
     * means we often deal with clean, well-formed HTML that allows for reliable regex-based operations.
     *
     */
    private fun concatHtml(htmls: List<String>) = htmls.mapIndexed { index, html ->
        val content = html.let {
            val start = Regex("<body.*?>").find(it)
            val end = Regex("</body>").find(it)
            when {
                start == null || end == null -> it
                else -> it.substring(start.range.last + 1, end.range.first)
            }
        }
        // Each file's content is embedded within a div, allowing us to explicitly
        // identify the starting node of the file.
        "<div id=\"${Constants.chapterBoundaryId(index)}\">$content</div>"
    }.joinToString("\n").let { "<html><body>$it</body></html>" }

    private fun getFileExtension(path: String): String {
        val lastIndexOfDot = path.lastIndexOf('.')
        val lastIndexOfSeparator = path.lastIndexOfAny(charArrayOf('/', '\\'))

        return if (lastIndexOfDot > lastIndexOfSeparator) {
            path.substring(lastIndexOfDot + 1)
        } else {
            ""
        }
    }

    private fun normalizePathComponents(path: String): String {
        val components = path.split("/")
        val normalized = mutableListOf<String>()

        for (component in components) {
            when (component) {
                "", "." -> continue // Skip empty and current directory components
                ".." -> if (normalized.isNotEmpty()) normalized.removeLast() // Go up one directory
                else -> normalized.add(component)
            }
        }

        return normalized.joinToString("/")
    }
}

private object Constants {
    fun chapterBoundaryId(fileIndex: Int) = "speechify-epub-chapter-boundary-$fileIndex"
}

internal typealias HTMLDOMElement = com.speechify.client.api.adapters.html.DOMElement

internal fun List<EpubNavPoint>.flattenNavPoints(): List<FlattenedEpubNavPoint> {
    fun flatten(navPoint: EpubNavPoint, level: Int): List<FlattenedEpubNavPoint> {
        return buildList {
            // Add the parent nav point with its level
            add(FlattenedEpubNavPoint(navPoint = navPoint.copy(children = emptyList()), level = level))
            // Then recursively add all children with incremented level
            addAll(navPoint.children.flatMap { child -> flatten(child, level + 1) })
        }
    }

    return flatMap { navPoint -> flatten(navPoint, 1) }
}

private val EpubRawChapter.isProbablyFrontOrBackMatterThatWeShouldIgnore: Boolean get() {
    val filename = filename.substringBefore(".").lowercase()
    return ignorableChapterFilenames.any {
        val pattern = it.lowercase()
        filename.endsWith(pattern) || filename.startsWith(pattern)
    }
}

// Recursively parse nav points from the nav element
internal fun parseNavPoints(
    navElement: XMLDOMElement,
    hrefToIndexMap: Map<String?, Int>,
    navBasePath: String,
) = navElement
    .children
    .filterIsInstance<XMLDOMElement>()
    .filter { it.tagName.lowercase() == "ol" }
    .flatMap { parseNavListItems(it, hrefToIndexMap, navBasePath) }

// Parse list items in the navigation
private fun parseNavListItems(
    olElement: XMLDOMElement,
    hrefToIndexMap: Map<String?, Int>,
    navBasePath: String,
) = olElement
    .children
    .filterIsInstance<XMLDOMElement>()
    .filter { it.tagName.lowercase() == "li" }
    .mapNotNull { parseNavPoint(it, hrefToIndexMap, navBasePath) }

// Parse a single navigation point
private fun parseNavPoint(
    liElement: XMLDOMElement,
    hrefToIndexMap: Map<String?, Int>,
    navBasePath: String,
): EpubNavPoint? {
    // Find the anchor element
    val anchor = liElement.children
        .filterIsInstance<XMLDOMElement>()
        .find { it.tagName.lowercase() == "a" } ?: return null

    // Extract the label text
    val label = anchor.children
        .filterIsInstance<XMLDOMTextNode>()
        .firstOrNull()?.text ?: return null

    // Get the href attribute
    val href = anchor.attributes
        .find { it.first == "href" }?.second ?: return null

    // Extract the document href and find its index in the spine
    val documentHref = href.substringBefore('#')
    val resolvedHref = when {
        href.startsWith("/") -> documentHref
        navBasePath.isEmpty() -> documentHref
        else -> "$navBasePath/$documentHref"
    }

    val documentIndex = hrefToIndexMap[resolvedHref] ?: return null

    // Parse child navigation points recursively
    val subList = liElement.children
        .filterIsInstance<XMLDOMElement>()
        .find { it.tagName.lowercase() == "ol" }

    val children = subList?.let { parseNavListItems(it, hrefToIndexMap, navBasePath) } ?: emptyList()

    return EpubNavPoint(href, documentIndex, label, children)
}

// Parse NCX navigation points recursively
internal fun parseNcxNavPoints(element: XMLDOMElement, hrefToIndexMap: Map<String?, Int>) = element.children
    .filterIsInstance<XMLDOMElement>()
    .filter { it.tagName.lowercase() == "navpoint" }
    .mapNotNull { parseSingleNcxNavPoint(it, hrefToIndexMap) }

// Parse a single NCX navigation point
private fun parseSingleNcxNavPoint(navPoint: XMLDOMElement, hrefToIndexMap: Map<String?, Int>): EpubNavPoint? {
    // Extract the label text
    val label = navPoint.children
        .filterIsInstance<XMLDOMElement>()
        .find { it.tagName.lowercase() == "navlabel" }
        ?.children?.filterIsInstance<XMLDOMElement>()
        ?.find { it.tagName.lowercase() == "text" }
        ?.children?.filterIsInstance<XMLDOMTextNode>()
        ?.firstOrNull()?.text
        ?: return null

    // Extract the content href
    val href = navPoint.children
        .filterIsInstance<XMLDOMElement>()
        .find { it.tagName.lowercase() == "content" }
        ?.attributes?.find { it.first == "src" }?.second
        ?: return null

    // Find the document index in the spine
    val documentHref = href.substringBefore('#')
    val documentIndex = hrefToIndexMap[documentHref] ?: return null

    // Parse child navigation points recursively
    val children = parseDescendantNavPoints(navPoint, hrefToIndexMap)

    return EpubNavPoint(href, documentIndex, label, children)
}

// Parse descendant NCX navigation points recursively
private fun parseDescendantNavPoints(
    element: XMLDOMElement,
    hrefToIndexMap: Map<String?, Int>,
): List<EpubNavPoint> {
    return element.children
        .filterIsInstance<XMLDOMElement>()
        .flatMap { childElement ->
            if (childElement.tagName.lowercase() == "navpoint") {
                listOfNotNull(parseSingleNcxNavPoint(childElement, hrefToIndexMap))
            } else {
                parseDescendantNavPoints(childElement, hrefToIndexMap)
            }
        }
}

// Find the navMap element in the NCX DOM
internal fun findNavMapElement(dom: XMLDOMNode): XMLDOMElement? {
    return when (dom) {
        is XMLDOMElement -> {
            when {
                dom.tagName.lowercase() == "navmap" -> dom
                else -> dom.children.firstNotNullOfOrNull { findNavMapElement(it) }
            }
        }
        else -> null
    }
}

// Helper function to find nav elements by type
internal fun findNavElementByType(dom: XMLDOMNode, type: String): XMLDOMElement? {
    return when (dom) {
        is XMLDOMElement -> {
            when {
                dom.tagName.lowercase() == "nav" &&
                    dom.attributes.any { it.first == "epub:type" && it.second == type } -> dom
                else -> dom.children.firstNotNullOfOrNull { findNavElementByType(it, type) }
            }
        }
        else -> null
    }
}

// Parse landmarks from nav element
internal fun parseLandmarks(landmarksNav: XMLDOMElement, navBasePath: String): List<EpubLandmark> {
    return landmarksNav.children
        .filterIsInstance<XMLDOMElement>()
        .filter { it.tagName.lowercase() == "ol" }
        .flatMap { ol ->
            ol.children
                .filterIsInstance<XMLDOMElement>()
                .filter { it.tagName.lowercase() == "li" }
                .mapNotNull { li -> parseSingleLandmark(li, navBasePath) }
        }
}

// Parse a single landmark entry
private fun parseSingleLandmark(li: XMLDOMElement, navBasePath: String): EpubLandmark? {
    val anchor = li.children
        .filterIsInstance<XMLDOMElement>()
        .find { it.tagName.lowercase() == "a" } ?: return null

    val type = anchor.attributes.find { it.first == "epub:type" }?.second ?: return null
    val href = anchor.attributes.find { it.first == "href" }?.second ?: return null
    val title = anchor.children
        .filterIsInstance<XMLDOMTextNode>()
        .firstOrNull()?.text

    // Resolve the href relative to nav base path
    val resolvedHref = when {
        href.startsWith("/") -> href.removePrefix("/")
        navBasePath.isEmpty() -> href
        else -> "$navBasePath/$href"
    }

    return EpubLandmark(
        type = type,
        title = title,
        href = resolvedHref,
    )
}
