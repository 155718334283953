package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class SubscriptionValidation {
    data class Apple(
        /** Base64 encoded app store receipt. */
        val receiptInfo: String,
    ) : SubscriptionValidation()
}
