package com.speechify.client.api.content.ocr

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed class OcrFallbackStrategy {
    @Serializable
    // Retaining 'Never' as the serialized name to ensure backward compatibility with local database records.
    @SerialName("Never")
    object ForceDisable : OcrFallbackStrategy()

    @Serializable
    @SerialName("ConservativeLegacyStrategy")
    object ConservativeLegacyStrategy : OcrFallbackStrategy()

    @Serializable
    @SerialName("ExperimentalStrategy")
    object ExperimentalStrategy : OcrFallbackStrategy()

    @Serializable
    @SerialName("ForceEnable")
    object ForceEnable : OcrFallbackStrategy()
}
