package com.speechify.client.api.content.view.book

import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithErrorLoggingGetJob
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.helpers.content.standard.book.BookStandardView
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.toDestructor
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * The data required to read and listen to a page in a Book
 */
@JsExport
abstract class BookPage internal constructor() : WithScope(), Content, Destructible {
    abstract val pageIndex: Int
    abstract override val start: ContentCursor
    abstract override val end: ContentCursor

    @JsName("getMetadata")
    abstract fun getMetadata(): BookPageMetadata

    abstract fun getImage(
        options: BookPageRequestOptions,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    /**
     * Gets the content as the SDK will be reading it. Use this if you need access to the content
     * in your application.
     */
    @JsName("getTextContent")
    fun getTextContent(callback: Callback<Array<BookPageTextContentItem>>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(sourceAreaId = "BookPage.getTextContent") {
            getStableParsedPageContent().map {
                it.toRawOrderedTextItems()
            }
        }.toDestructor()

    /**
     * Get the text in the given bounding boxes,
     * @return [TextInBoundingBoxResult]
     * where [TextInBoundingBoxResult.text] combined text in all the input bounding boxes, if not supported should be empty
     * [TextInBoundingBoxResult.characterBoxes] character bounding boxes for each character in [TextInBoundingBoxResult.text],if not supported
     * should be empty or null
     */
    internal abstract suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult

    /**
     * Provides the content of the page, using algorithms that may be expensive, but the result is as good as we can
     * provide, and the [ContentText] it contains is safe for use.
     */
    internal abstract suspend fun getStableParsedPageContent(): Result<ParsedPageContent>

    /**
     * Provides the same textual content as the result of [getStableParsedPageContent], with an ordering that
     * may-or-may-not be of worse quality (but is unlikely to be better), and may-or-may-not be computed significantly
     * more efficiently under the hood (but will definitely not be less efficient).
     *
     * Use this for computing rough statistics about the text content and their positions on the page.
     *
     * DO NOT use this to derive [ContentText] and [ContentCursors] for use elsewhere in the system. The tradeoff of the
     * possibly-more-efficient implementation of this method is that it cannot guarantee that cursors derived from the
     * [ContentText] it contains are valid when used elsewhere in the system.
     */
    internal abstract suspend fun getUnstableTextContentApproximatelyOrdered():
        Result<List<UnstableBookPageTextContentItem>>

    /**
     * This function needs to be implemented by [BookPage]s that want to ensure that the standard blocks that will
     * be read / displayed to the user match exactly to some underlying data structure. If this isn't implemented
     * the representation depends on the [BookPageTextContentItem] returned in [getTextContent] and might change over
     * time as the parsing logic in [BookStandardView] changes.
     *
     * Furthermore, without this function there is no way to guarantee a certain [StandardBlocks] representation for
     * a page since in some cases the bounds of the [BookPageTextContentItem]s vary from device to device.
     *
     * Essentially this offers a way for [BookPage]s that already know how to represent the content in a standard way
     * to short circuit our layout parsing heuristics and just provide the content for classic mode / listening
     * directly. The content provided in [getTextContent] will still be used for highlighting in original mode.
     *
     * One specific example of this is the [EditingBookView], the internal EditingBookPage allows users to directly
     * provide a [StandardBlocks] representation of the page, without the [getStandardBlockRepresentation] functionality
     * it would be impossible to guarantee that what the user gets in reading mode would be exactly what they
     * setup in the editor.
     *
     * Note to implementor:
     * The text returned here should match the text returned by [getStableParsedPageContent], especially in terms of
     * the ranges of the text slices.
     *
     * @return The [StandardBlocks] representation of the content on this page, or null if this page should have its
     * blocks determined based on the text returned by [getTextContent].
     */
    internal abstract suspend fun getStandardBlockRepresentation(): StandardBlocks?
}

/**
 *
 * See [com.speechify.client.api.content.view.book.BookView.translateToUsableCursor]. This function tries to
 * retrieve a usable cursor based on a saved remotely one [originalCursor], which may undergo changes when
 * content sorting is enabled.
 * In case of failure, it tries to fall back to the cursor positioned at the start of the slice
 * (textElementContentSlice) containing the [originalCursor]. If null, it tries to fall back again
 * to the cursor positioned at the start of the current page.
 */
suspend fun BookPage.translateToUsableCursor(originalCursor: ContentCursor): ContentCursor? {
    return when (originalCursor) {
        is ContentElementBoundary -> originalCursor
        is ContentTextPosition -> {
            val rawTextContent = getStableParsedPageContent().orReturn { return null }.toRawOrderedTextItems()
            val textElementContentSlice = rawTextContent.map { it.text }.find { it.containsCursor(originalCursor) }
            val foundCursorOrNull = textElementContentSlice?.rootElement?.getPosition(originalCursor.characterIndex)
            return if (foundCursorOrNull != null) {
                foundCursorOrNull
            } else if (textElementContentSlice?.start != null) {
                Log.d(
                    DiagnosticEvent(
                        message =
                        "BookPage translateToUsableCursor: Unable to find a usable cursor from" +
                            " the given one. Falling back to the cursor positioned at the beginning of the" +
                            " `textElementContentSlice` that contains the `originalCursor`.",
                        sourceAreaId = "BookPage.translateToUsableCursor",
                    ),
                )
                // Falls back to the start of the `textElementContentSlice` that contains the `originalCursor`.
                textElementContentSlice.start
            } else if (rawTextContent.firstOrNull()?.text?.start != null) {
                Log.d(
                    DiagnosticEvent(
                        message =
                        "BookPage translateToUsableCursor: Unable to fall back to the" +
                            " cursor positioned at the beginning of the `textElementContentSlice` that contains" +
                            " the `originalCursor`. Falling back once again to the cursor positioned at the" +
                            " beginning of the current page.",
                        sourceAreaId = "BookPage.translateToUsableCursor",
                    ),
                )
                // Falls back to the beginning of the current page.
                rawTextContent.firstOrNull()?.text?.start
            } else {
                null
            }
        }
    }
}

internal suspend fun BookPage.coGetImage(
    options: BookPageRequestOptions,
): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> =
    suspendCancellableCoroutine { getImage(options, it::resume) }

internal suspend fun BookPage.coGetTextContent():
    Result<Array<BookPageTextContentItem>> = suspendCoroutine { getTextContent(it::resume) }
