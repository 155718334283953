package com.speechify.client.api.services.audiobook

import com.speechify.client.internal.services.library.models.FirebaseListeningProgress
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal enum class FirestoreAudiobookItemType {
    @SerialName("folder")
    BOOK,

    @SerialName("record")
    CHAPTER,
}

@Serializable
internal enum class FirestoreAudiobookChapterType {
    @SerialName("audiobook")
    AUDIO_ONLY,

    @SerialName("aligned")
    ALIGNED,

    @SerialName("ebook")
    EBOOK,
}

@Serializable
internal data class FirestoreAudiobookItem(
    val type: FirestoreAudiobookItemType,

    val listeningProgress: FirebaseListeningProgress? = null,

    @SerialName("ref")
    val productReference: String?,
    val chapterType: FirestoreAudiobookChapterType,
    val coverImagePath: String? = null,
    val parentFolderId: String? = null,

    val author: String? = null,
    val title: String,

    @SerialName("chapterNumber")
    val chapterIndex: Int? = null,

    val audioUrl: String? = null,
    val alignmentUri: String? = null,
    val contentUri: String? = null,

    @SerialName("duration")
    val durationSeconds: Int? = null,
    val wordCount: Int? = null,
)
