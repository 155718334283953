package com.speechify.client.api.services.adoption

import com.speechify.client.api.services.adoption.models.UserUsageData
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Service
import com.speechify.client.api.util.fromCoWithErrorLogging
import kotlin.js.JsExport

@JsExport
class EcosystemAdoptionService internal constructor(
    private val ecosystemAdoptionDelegate: EcosystemAdoptionDelegate,
) : Service {
    fun getUserUsageData(callback: Callback<UserUsageData>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "EcosystemAdoptionService.getUserUsageData",
    ) {
        coGetUserUsageData()
    }

    internal suspend fun coGetUserUsageData(): Result<UserUsageData> {
        return ecosystemAdoptionDelegate.getUserUsageData()
    }

    override fun destroy() {
        ecosystemAdoptionDelegate.destroy()
    }
}
