package com.speechify.client.reader.fixedlayoutbook

import kotlin.js.JsExport

/**
 * Configuration class for customizing the zoom behavior of a fixed layout reader.
 *
 * @constructor Creates an instance of [FixedLayoutReaderConfig] with initial configuration values.
 *
 * @param initialZoomLevel Initial zoom level for the reader, represented as a [Double].
 * A value of 1.0 represents 100% zoom (original size), 2.0 represents 200% zoom, etc.
 */
@JsExport
data class FixedLayoutReaderConfig(
    val initialZoomLevel: Double,
)
