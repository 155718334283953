package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.horizontalDistanceTo
import com.speechify.client.api.util.images.verticalDistanceTo
import com.speechify.client.helpers.content.standard.book.heuristics.v2.isNeighborIndex
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentBlock
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentLine
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.LinePosition
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage
import com.speechify.client.helpers.content.standard.book.isAlmostEqual

/**
 * Defines a search region around a line for finding adjacent lines.
 * The region is determined as 30% of the symbol height in all directions (up, down, left, right).
 */
private const val SEARCH_REGION_HEIGHT_RATIO = 0.3

/**
 * Defines a larger search region (50% of the symbol height) for lines that have neighbors index
 * values.
 */
private const val SEARCH_REGION_HEIGHT_RATIO_FOR_NEIGHBORS = 0.5

/**
 * Tolerance for determining if two lines have the same font size (height).
 * A difference smaller than 0.003 is considered negligible.
 */
private const val FONT_SIZE_TOLERANCE = 0.003

internal class LinesToBlocks(private val logger: Logger?) :
    ParsingPipelineStage<List<ContentLine>, List<ContentBlock>> {
    private val builtBlocks: MutableList<ContentBlock> = mutableListOf()

    override fun process(input: List<ContentLine>): List<ContentBlock> {
        listOf(LinePosition.HORIZONTAL, LinePosition.VERTICAL).forEach { position ->
            val lines = input.filter { it.position == position }
            groupLinesIntoBlocks(lines, position)
        }

        logBuiltBlocks()
        return builtBlocks
    }

    private fun groupLinesIntoBlocks(
        lines: List<ContentLine>,
        position: LinePosition,
    ) {
        val visited = mutableSetOf<Int>()
        val indexedLines = lines.mapIndexed { index, line -> index to line }

        for ((index, line) in indexedLines) {
            if (index !in visited) {
                val newBlock = mutableListOf<ContentLine>()
                deepBlockSearch(newBlock, index to line, indexedLines, visited, position)
                builtBlocks.add(ContentBlock(newBlock, position))
            }
        }
    }

    private fun deepBlockSearch(
        newBlock: MutableList<ContentLine>,
        current: Pair<Int, ContentLine>,
        linesWithIndex: List<Pair<Int, ContentLine>>,
        visited: MutableSet<Int>,
        position: LinePosition,
    ) {
        val (currentIndex, currentLine) = current
        if (!visited.add(currentIndex)) return
        newBlock.add(currentLine)

        val neighbors = current.findNeighbors(linesWithIndex, position).filter { (index, _) -> index !in visited }
        neighbors.forEach { deepBlockSearch(newBlock, it, linesWithIndex, visited, position) }
    }

    private fun Pair<Int, ContentLine>.findNeighbors(
        lines: List<Pair<Int, ContentLine>>,
        position: LinePosition,
    ): List<Pair<Int, ContentLine>> {
        val (thisIndex, thisLine) = this
        val thisHeight = thisLine.box.getSymbolHeight(position)

        val baseEps = thisHeight * SEARCH_REGION_HEIGHT_RATIO
        val neighborEps = thisHeight * SEARCH_REGION_HEIGHT_RATIO_FOR_NEIGHBORS

        return lines.filter { (thatIndex, thatLine) ->
            if (thisIndex == thatIndex) return@filter false
            if (!thisHeight.isSameFontSize(thatLine.box.getSymbolHeight(position))) return@filter false

            val distanceThreshold = if (thisIndex.isNeighborIndex(thatIndex)) neighborEps else baseEps
            val horizontalDistance = thisLine.box.horizontalDistanceTo(thatLine.box)
            val verticalDistance = thisLine.box.verticalDistanceTo(thatLine.box)
            val isCloseEnough = horizontalDistance < distanceThreshold && verticalDistance < distanceThreshold
            isCloseEnough
        }
    }

    private fun BoundingBox.getSymbolHeight(position: LinePosition): Double = when (position) {
        LinePosition.VERTICAL -> this.width
        LinePosition.HORIZONTAL -> this.height
    }

    private fun Double.isSameFontSize(other: Double): Boolean =
        this.isAlmostEqual(other, FONT_SIZE_TOLERANCE)

    private fun logBuiltBlocks() {
        logger?.log("Built blocks:")
        builtBlocks.forEach { logger?.log(it.plainText) }
    }
}
