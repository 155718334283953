package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.`internal`.services.db.DbBoolean
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.audio.caching.CachedSynthesisResponse
import com.speechify.client.api.audio.caching.VoiceIdForDb
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.services.library.offline.AudioDownloadOptions
import kotlin.Any
import kotlin.ByteArray
import kotlin.Double
import kotlin.Long
import kotlin.String

public class VoiceCacheQueries(
  driver: SqlDriver,
  private val synthesisResultAdapter: SynthesisResult.Adapter,
  private val sentenceIndexAdapter: SentenceIndex.Adapter,
  private val downloadedAudioForItemAdapter: DownloadedAudioForItem.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun getSynthesisResultId(synthesisResultUUID: String): Query<Long> =
      GetSynthesisResultIdQuery(synthesisResultUUID) { cursor ->
    cursor.getLong(0)!!
  }

  public fun findUtterancesWithSentence(
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
    sentenceText: String,
  ): Query<Long> = FindUtterancesWithSentenceQuery(documentUri, voiceId, sentenceText) { cursor ->
    cursor.getLong(0)!!
  }

  public fun <T : Any> getSynthesisResult(synthesisResultId: Long, mapper: (synthesisResultId: Long,
      synthesisMetadata: CachedSynthesisResponse) -> T): Query<T> =
      GetSynthesisResultQuery(synthesisResultId) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      synthesisResultAdapter.synthesisMetadataAdapter.decode(cursor.getString(1)!!)
    )
  }

  public fun getSynthesisResult(synthesisResultId: Long): Query<GetSynthesisResult> =
      getSynthesisResult(synthesisResultId) { synthesisResultId_, synthesisMetadata ->
    GetSynthesisResult(
      synthesisResultId_,
      synthesisMetadata
    )
  }

  public fun getAudioData(synthesisResultId: Long): Query<ByteArray> =
      GetAudioDataQuery(synthesisResultId) { cursor ->
    cursor.getBytes(0)!!
  }

  public fun <T : Any> getDownloadedAudioForItems(mapper: (
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
    downloadOptions: AudioDownloadOptions,
    sdkVersionAtCreation: String,
    hasGapsInAudio: DbBoolean,
    downloadProgress: Double,
    startProgressFraction: Double,
    startCursor: ContentCursor,
    endProgressFraction: Double,
    endCursor: ContentCursor,
  ) -> T): Query<T> = Query(-1_488_966_862, arrayOf("downloadedAudioForItem"), driver,
      "voiceCache.sq", "getDownloadedAudioForItems", """
  |SELECT *
  |  FROM downloadedAudioForItem
  """.trimMargin()) { cursor ->
    mapper(
      downloadedAudioForItemAdapter.documentUriAdapter.decode(cursor.getString(0)!!),
      downloadedAudioForItemAdapter.voiceIdAdapter.decode(cursor.getString(1)!!),
      downloadedAudioForItemAdapter.downloadOptionsAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      downloadedAudioForItemAdapter.hasGapsInAudioAdapter.decode(cursor.getLong(4)!!),
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      downloadedAudioForItemAdapter.startCursorAdapter.decode(cursor.getString(7)!!),
      cursor.getDouble(8)!!,
      downloadedAudioForItemAdapter.endCursorAdapter.decode(cursor.getString(9)!!)
    )
  }

  public fun getDownloadedAudioForItems(): Query<DownloadedAudioForItem> =
      getDownloadedAudioForItems { documentUri, voiceId, downloadOptions, sdkVersionAtCreation,
      hasGapsInAudio, downloadProgress, startProgressFraction, startCursor, endProgressFraction,
      endCursor ->
    DownloadedAudioForItem(
      documentUri,
      voiceId,
      downloadOptions,
      sdkVersionAtCreation,
      hasGapsInAudio,
      downloadProgress,
      startProgressFraction,
      startCursor,
      endProgressFraction,
      endCursor
    )
  }

  public fun <T : Any> getDownloadedAudioForItem(documentUri: SpeechifyURI, mapper: (
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
    downloadOptions: AudioDownloadOptions,
    sdkVersionAtCreation: String,
    hasGapsInAudio: DbBoolean,
    downloadProgress: Double,
    startProgressFraction: Double,
    startCursor: ContentCursor,
    endProgressFraction: Double,
    endCursor: ContentCursor,
  ) -> T): Query<T> = GetDownloadedAudioForItemQuery(documentUri) { cursor ->
    mapper(
      downloadedAudioForItemAdapter.documentUriAdapter.decode(cursor.getString(0)!!),
      downloadedAudioForItemAdapter.voiceIdAdapter.decode(cursor.getString(1)!!),
      downloadedAudioForItemAdapter.downloadOptionsAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      downloadedAudioForItemAdapter.hasGapsInAudioAdapter.decode(cursor.getLong(4)!!),
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      downloadedAudioForItemAdapter.startCursorAdapter.decode(cursor.getString(7)!!),
      cursor.getDouble(8)!!,
      downloadedAudioForItemAdapter.endCursorAdapter.decode(cursor.getString(9)!!)
    )
  }

  public fun getDownloadedAudioForItem(documentUri: SpeechifyURI): Query<DownloadedAudioForItem> =
      getDownloadedAudioForItem(documentUri) { documentUri_, voiceId, downloadOptions,
      sdkVersionAtCreation, hasGapsInAudio, downloadProgress, startProgressFraction, startCursor,
      endProgressFraction, endCursor ->
    DownloadedAudioForItem(
      documentUri_,
      voiceId,
      downloadOptions,
      sdkVersionAtCreation,
      hasGapsInAudio,
      downloadProgress,
      startProgressFraction,
      startCursor,
      endProgressFraction,
      endCursor
    )
  }

  public suspend fun insertSynthesisResult(
    synthesisResultUUID: String,
    voiceId: VoiceIdForDb,
    audioData: ByteArray,
    synthesisMetadata: CachedSynthesisResponse,
  ) {
    driver.execute(1_351_457_462, """
        |INSERT INTO synthesisResult (
        |  synthesisResultUUID,
        |  voiceId,
        |  audioData,
        |  synthesisMetadata
        |) VALUES (
        |  ?,
        |  ?,
        |  ?,
        |  ?
        |)
        """.trimMargin(), 4) {
          bindString(0, synthesisResultUUID)
          bindString(1, synthesisResultAdapter.voiceIdAdapter.encode(voiceId))
          bindBytes(2, audioData)
          bindString(3, synthesisResultAdapter.synthesisMetadataAdapter.encode(synthesisMetadata))
        }.await()
    notifyQueries(1_351_457_462) { emit ->
      emit("synthesisResult")
    }
  }

  public suspend fun insertSentenceIndex(
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
    sentenceText: String,
    synthesisResultId: Long,
    utteranceSentencesIndexOfThisSentence: Long,
    utteranceSentencesTotalCount: Long,
  ) {
    driver.execute(-267_575_468, """
        |INSERT OR REPLACE INTO sentenceIndex (
        |  documentUri,
        |  voiceId,
        |  sentenceText,
        |  synthesisResultId,
        |  utteranceSentencesIndexOfThisSentence,
        |  utteranceSentencesTotalCount
        |) VALUES (
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?
        |)
        """.trimMargin(), 6) {
          bindString(0, sentenceIndexAdapter.documentUriAdapter.encode(documentUri))
          bindString(1, sentenceIndexAdapter.voiceIdAdapter.encode(voiceId))
          bindString(2, sentenceText)
          bindLong(3, synthesisResultId)
          bindLong(4, utteranceSentencesIndexOfThisSentence)
          bindLong(5, utteranceSentencesTotalCount)
        }.await()
    notifyQueries(-267_575_468) { emit ->
      emit("sentenceIndex")
    }
  }

  public suspend fun insertDownloadedAudioForItem(
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
    downloadOptions: AudioDownloadOptions,
    sdkVersionAtCreation: String,
    downloadProgress: Double,
    startProgressFraction: Double,
    startCursor: ContentCursor,
    endProgressFraction: Double,
    endCursor: ContentCursor,
    hasGapsInAudio: DbBoolean,
  ) {
    driver.execute(29_406_728, """
        |INSERT OR REPLACE
        |  INTO downloadedAudioForItem (
        |    documentUri,
        |    voiceId,
        |    downloadOptions,
        |    sdkVersionAtCreation,
        |    downloadProgress,
        |    startProgressFraction,
        |    startCursor,
        |    endProgressFraction,
        |    endCursor,
        |    hasGapsInAudio
        |  ) VALUES (
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?,
        |  ?
        |)
        """.trimMargin(), 10) {
          bindString(0, downloadedAudioForItemAdapter.documentUriAdapter.encode(documentUri))
          bindString(1, downloadedAudioForItemAdapter.voiceIdAdapter.encode(voiceId))
          bindString(2,
              downloadedAudioForItemAdapter.downloadOptionsAdapter.encode(downloadOptions))
          bindString(3, sdkVersionAtCreation)
          bindDouble(4, downloadProgress)
          bindDouble(5, startProgressFraction)
          bindString(6, downloadedAudioForItemAdapter.startCursorAdapter.encode(startCursor))
          bindDouble(7, endProgressFraction)
          bindString(8, downloadedAudioForItemAdapter.endCursorAdapter.encode(endCursor))
          bindLong(9, downloadedAudioForItemAdapter.hasGapsInAudioAdapter.encode(hasGapsInAudio))
        }.await()
    notifyQueries(29_406_728) { emit ->
      emit("downloadedAudioForItem")
    }
  }

  public suspend fun updateDownloadedAudioForItemDownloadProgress(
    downloadProgress: Double,
    endProgressFraction: Double,
    endCursor: ContentCursor,
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
  ) {
    driver.execute(1_784_363_789, """
        |UPDATE downloadedAudioForItem
        |  SET
        |    downloadProgress = ?,
        |    endProgressFraction = ?,
        |    endCursor = ?
        |  WHERE documentUri = ? AND voiceId = ?
        """.trimMargin(), 5) {
          bindDouble(0, downloadProgress)
          bindDouble(1, endProgressFraction)
          bindString(2, downloadedAudioForItemAdapter.endCursorAdapter.encode(endCursor))
          bindString(3, downloadedAudioForItemAdapter.documentUriAdapter.encode(documentUri))
          bindString(4, downloadedAudioForItemAdapter.voiceIdAdapter.encode(voiceId))
        }.await()
    notifyQueries(1_784_363_789) { emit ->
      emit("downloadedAudioForItem")
    }
  }

  public suspend fun removeSynthesisResultsForDocumentVoice(documentUri: SpeechifyURI,
      voiceId: VoiceIdForDb) {
    driver.execute(1_516_288_758, """
        |DELETE FROM synthesisResult
        |  WHERE synthesisResultId IN (
        |    SELECT synthesisResultId
        |      FROM sentenceIndex
        |      WHERE
        |        documentUri = ?
        |        AND voiceId = ?
        |  )
        """.trimMargin(), 2) {
          bindString(0, sentenceIndexAdapter.documentUriAdapter.encode(documentUri))
          bindString(1, sentenceIndexAdapter.voiceIdAdapter.encode(voiceId))
        }.await()
    notifyQueries(1_516_288_758) { emit ->
      emit("synthesisResult")
    }
  }

  public suspend fun removeSentenceIndexForDocumentVoice(documentUri: SpeechifyURI,
      voiceId: VoiceIdForDb) {
    driver.execute(636_438_551, """
        |DELETE FROM sentenceIndex
        |  WHERE
        |    documentUri = ?
        |    AND voiceId = ?
        """.trimMargin(), 2) {
          bindString(0, sentenceIndexAdapter.documentUriAdapter.encode(documentUri))
          bindString(1, sentenceIndexAdapter.voiceIdAdapter.encode(voiceId))
        }.await()
    notifyQueries(636_438_551) { emit ->
      emit("sentenceIndex")
    }
  }

  public suspend fun removeDownloadedAudioForDocumentVoice(documentUri: SpeechifyURI,
      voiceId: VoiceIdForDb) {
    driver.execute(-1_398_951_337, """
        |DELETE FROM downloadedAudioForItem
        |  WHERE
        |    documentUri = ?
        |    AND voiceId = ?
        """.trimMargin(), 2) {
          bindString(0, downloadedAudioForItemAdapter.documentUriAdapter.encode(documentUri))
          bindString(1, downloadedAudioForItemAdapter.voiceIdAdapter.encode(voiceId))
        }.await()
    notifyQueries(-1_398_951_337) { emit ->
      emit("downloadedAudioForItem")
    }
  }

  public suspend fun setVoiceHasGapsInAudio(
    hasGapsInAudio: DbBoolean,
    documentUri: SpeechifyURI,
    voiceId: VoiceIdForDb,
  ) {
    driver.execute(1_929_611_148,
        """UPDATE downloadedAudioForItem SET hasGapsInAudio = ? WHERE documentUri = ? AND voiceId = ?""",
        3) {
          bindLong(0, downloadedAudioForItemAdapter.hasGapsInAudioAdapter.encode(hasGapsInAudio))
          bindString(1, downloadedAudioForItemAdapter.documentUriAdapter.encode(documentUri))
          bindString(2, downloadedAudioForItemAdapter.voiceIdAdapter.encode(voiceId))
        }.await()
    notifyQueries(1_929_611_148) { emit ->
      emit("downloadedAudioForItem")
    }
  }

  /**
   * * Reclaims space - [_"rebuilds the database file, repacking it into a minimal amount of disk
   * space"_](https://www.sqlite.org/lang_vacuum.html)
   */
  public suspend fun reclaimSpace() {
    driver.execute(-1_171_283_713, """VACUUM""", 0).await()
  }

  private inner class GetSynthesisResultIdQuery<out T : Any>(
    public val synthesisResultUUID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("synthesisResult", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("synthesisResult", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_466_240_888, """
    |SELECT synthesisResultId
    |  FROM synthesisResult
    |  WHERE synthesisResultUUID = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, synthesisResultUUID)
    }

    override fun toString(): String = "voiceCache.sq:getSynthesisResultId"
  }

  private inner class FindUtterancesWithSentenceQuery<out T : Any>(
    public val documentUri: SpeechifyURI,
    public val voiceId: VoiceIdForDb,
    public val sentenceText: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("sentenceIndex", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("sentenceIndex", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_742_818_938, """
    |SELECT synthesisResultId
    |  FROM sentenceIndex
    |  WHERE documentUri = ? AND voiceId = ? AND sentenceText = ?
    """.trimMargin(), mapper, 3) {
      bindString(0, sentenceIndexAdapter.documentUriAdapter.encode(documentUri))
      bindString(1, sentenceIndexAdapter.voiceIdAdapter.encode(voiceId))
      bindString(2, sentenceText)
    }

    override fun toString(): String = "voiceCache.sq:findUtterancesWithSentence"
  }

  private inner class GetSynthesisResultQuery<out T : Any>(
    public val synthesisResultId: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("synthesisResult", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("synthesisResult", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-2_005_175_939, """
    |SELECT synthesisResultId, synthesisMetadata
    |  FROM synthesisResult
    |  WHERE synthesisResultId = ?
    """.trimMargin(), mapper, 1) {
      bindLong(0, synthesisResultId)
    }

    override fun toString(): String = "voiceCache.sq:getSynthesisResult"
  }

  private inner class GetAudioDataQuery<out T : Any>(
    public val synthesisResultId: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("synthesisResult", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("synthesisResult", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-961_876_404, """
    |SELECT audioData
    |  FROM synthesisResult
    |  WHERE synthesisResultId = ?
    """.trimMargin(), mapper, 1) {
      bindLong(0, synthesisResultId)
    }

    override fun toString(): String = "voiceCache.sq:getAudioData"
  }

  private inner class GetDownloadedAudioForItemQuery<out T : Any>(
    public val documentUri: SpeechifyURI,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("downloadedAudioForItem", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("downloadedAudioForItem", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_891_631_457,
        """SELECT * FROM downloadedAudioForItem WHERE documentUri = ?""", mapper, 1) {
      bindString(0, downloadedAudioForItemAdapter.documentUriAdapter.encode(documentUri))
    }

    override fun toString(): String = "voiceCache.sq:getDownloadedAudioForItem"
  }
}
