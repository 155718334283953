package com.speechify.client.api.util.io

import com.speechify.client.api.util.MimeType
import kotlin.js.JsExport

/**
 * A markup interface grouping all types that carry some binary content that is ordered (so, every bit/byte has an index
 * even if there is no known length) and the type offers a memory-efficient way to read the content using just 'chunks'
 * of the whole, so without having to load the whole into a single [ByteArray] in memory (e.g. sequentially, or by
 * index-ranges, AKA randomly).
 */
@JsExport
sealed interface BinaryContentReadableInChunks

/**
 * A markup interface grouping all subtypes of [BinaryContentReadableInChunks] backed by some performant native object.
 */
@JsExport
sealed interface BinaryContentReadableInChunksWithNativeAPI : BinaryContentReadableInChunks

internal fun <BC : BinaryContentReadableInChunksWithNativeAPI>
BC.withMimeType(
    mimeType: MimeType?,
): BinaryContentWithMimeTypeFromNativeReadableInChunks<BC> =
    object : BinaryContentWithMimeTypeFromNativeReadableInChunks<BC> {
        override val mimeType: MimeType? = mimeType
        override val binaryContent: BC = this@withMimeType
    }
