package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Point
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.HighlightsHelperCommand
import com.speechify.client.reader.core.SelectionHandle
import kotlin.js.JsExport

@JsExport
data class FixedLayoutPage internal constructor(
    private val page: FixedLayoutPageNoFeatures,
    val features: FixedLayoutPageFeatures,
) {
    val viewport: Viewport = page.viewport
    val zoomLevel: Double = page.zoomLevel

    @Suppress("NON_EXPORTABLE_TYPE")
    val image: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly> = page.image

    val regionsOfInterests: Array<FixedLayoutPageRegion> = page.regionsOfInterest
}

/**
 * Defines how the associated page should appear in [FixedLayoutBookReader] mode.
 *
 * To receive updates on how the page should appear,
 * [subscribe to the FixedLayoutPageHelper][FixedLayoutPageHelper.addStateChangeListener] linked to it.
 */
@JsExport
data class FixedLayoutPageFeatures internal constructor(
    /**
     * The regions on the associated page that contain the word currently being spoken.
     * `null` or an empty array if no word on the associated page is being spoken.
     */
    val wordHighlight: Array<FixedLayoutPageRegion>?,

    /**
     * The regions on the associated page that contain the sentence currently being spoken.
     * `null` or an empty array if no sentence on the associated page is being spoken.
     */
    val sentenceHighlight: Array<FixedLayoutPageRegion>?,

    /**
     * The currently active user selection on the associated page.
     * `null` if no region on the associated page is selected.
     */
    val selection: FixedLayoutSelectionRegion?,

    /**
     * The highlights added by the user on the associated page.
     * Empty if no region on the associated page is highlighted.
     */
    val userHighlights: Array<FixedLayoutUserHighlight>,

    /**
     * The intent to center a specific [region][FineFixedLayoutBookNavigationIntent.region] on the associated page
     * on the user's display.
     * `null` if no centering action is requested for the associated page.
     */
    val navigationIntent: FineFixedLayoutBookNavigationIntent?,

    /**
     * The regions on the associated page that contain the sentence currently hovered over.
     * `null` or an empty array if no sentence on the associated page is hovered over.
     */
    val hoveredSentence: Array<FixedLayoutPageRegion>?,
) {

    internal companion object {
        fun empty(): FixedLayoutPageFeatures {
            return FixedLayoutPageFeatures(
                wordHighlight = null,
                sentenceHighlight = null,
                selection = null,
                userHighlights = emptyArray(),
                navigationIntent = null,
                hoveredSentence = null,
            )
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as FixedLayoutPageFeatures

        if (wordHighlight != null) {
            if (other.wordHighlight == null) return false
            if (!wordHighlight.contentEquals(other.wordHighlight)) return false
        } else if (other.wordHighlight != null) return false
        if (sentenceHighlight != null) {
            if (other.sentenceHighlight == null) return false
            if (!sentenceHighlight.contentEquals(other.sentenceHighlight)) return false
        } else if (other.sentenceHighlight != null) return false
        if (selection != other.selection) return false
        if (!userHighlights.contentEquals(other.userHighlights)) return false
        if (navigationIntent != other.navigationIntent) return false
        if (hoveredSentence != null) {
            if (other.hoveredSentence == null) return false
            if (!hoveredSentence.contentEquals(other.hoveredSentence)) return false
        } else if (other.hoveredSentence != null) return false

        return true
    }

    override fun hashCode(): Int {
        var result = wordHighlight?.contentHashCode() ?: 0
        result = 31 * result + (sentenceHighlight?.contentHashCode() ?: 0)
        result = 31 * result + (selection?.hashCode() ?: 0)
        result = 31 * result + userHighlights.contentHashCode()
        result = 31 * result + (navigationIntent?.hashCode() ?: 0)
        result = 31 * result + (hoveredSentence?.contentHashCode() ?: 0)
        return result
    }
}

internal class FixedLayoutPageContent(
    val text: String,
    val normalizedBoundingBox: BoundingBox,
    val fontFamily: String?,
)

@JsExport
class FixedLayoutPagePoint internal constructor(
    val normalizedLeft: Double,
    val normalizedTop: Double,
)

@JsExport
data class FixedLayoutSelectionRegion internal constructor(
    val pageRegions: Array<FixedLayoutPageRegion>?,
    val startHandle: SelectionHandle?,
    val endHandle: SelectionHandle?,
) {

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as FixedLayoutSelectionRegion

        if (pageRegions != null) {
            if (other.pageRegions == null) return false
            if (!pageRegions.contentEquals(other.pageRegions)) return false
        } else if (other.pageRegions != null) return false
        if (startHandle != other.startHandle) return false
        if (endHandle != other.endHandle) return false

        return true
    }

    override fun hashCode(): Int {
        var result = pageRegions?.contentHashCode() ?: 0
        result = 31 * result + (startHandle?.hashCode() ?: 0)
        result = 31 * result + (endHandle?.hashCode() ?: 0)
        return result
    }
}

@JsExport
class FixedLayoutPageRegion internal constructor(
    val estimatedNormalizedBoundingBox: BoundingBox,
) {
    /** Returns the normalized [top][BoundingBox.top] position of the center of this page region. */
    val normalizedTopOfCenter: Double
        get() = estimatedNormalizedBoundingBox.centerY

    internal companion object {
        internal fun fromPageTextContent(
            content: FixedLayoutPageContent,
            startIndex: Int,
            endIndexExclusive: Int,
            startPoint: Point? = null,
            endPoint: Point? = null,
        ): FixedLayoutPageRegion {
            val boxWidth = content.normalizedBoundingBox.width
            val startOffset = (startIndex / content.text.length.toDouble()) * boxWidth
            val endOffset = (endIndexExclusive / content.text.length.toDouble()) * boxWidth

            val start = startPoint?.x ?: (content.normalizedBoundingBox.left + startOffset)
            val width = endPoint?.let {
                Point(start, it.y).distanceTo(it)
            } ?: startPoint?.let {
                startPoint.distanceTo(Point(content.normalizedBoundingBox.left + endOffset, it.y))
            } ?: (endOffset - startOffset)
            val estimatedNormalizedBoundingBox =
                BoundingBox.fromDimensionsAndCoordinates(
                    top = content.normalizedBoundingBox.top,
                    left = start,
                    width = width,
                    height = content.normalizedBoundingBox.height,
                )
            return FixedLayoutPageRegion(estimatedNormalizedBoundingBox)
        }

        internal fun fromNormalizedBoundingBox(
            normalizedBoundingBox: BoundingBox,
        ): FixedLayoutPageRegion {
            return FixedLayoutPageRegion(estimatedNormalizedBoundingBox = normalizedBoundingBox)
        }
    }
}

@JsExport
class FixedLayoutUserHighlight internal constructor(
    internal val dispatch: CommandDispatch,
    internal val userHighlight: UserHighlight,
    val regions: Array<FixedLayoutPageRegion>,
) {
    val key get() = userHighlight.id
    val highlight: UserHighlight get() = userHighlight

    fun delete() {
        dispatch(HighlightsHelperCommand.DeleteHighlight(userHighlight.id))
    }

    fun updateColor(colorToken: UserHighlight.Style.ColorToken) {
        dispatch(
            HighlightsHelperCommand.UpdateColor(
                userHighlight.id,
                colorToken,
            ),
        )
    }

    fun updateNote(note: String?) {
        dispatch(
            HighlightsHelperCommand.UpdateNote(
                userHighlight.id,
                note = note,
            ),
        )
    }
}
