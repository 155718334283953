package com.speechify.client.helpers.ui.overlays

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ObjectRef
import kotlin.js.JsExport

/**
 * Provides the relationships between any instance of [ContentText] and the objects it originated from.
 *
 * Note: Callers will specify the type parameter based on their own knowledge of the type of content they are dealing with. This isn't type-safe, but it helps us avoid threading this type parameter through every content API in the codebase.
 */
@JsExport
class OriginalContentOverlayProvider<ContentRef : Any?> : ContentOverlayProvider<ContentRef> {

    /**
     * Returns "where" this [text] came from in terms of index ranges related to the foreign objects specified when the [text] was constructed (usually in a Builder).
     */
    override fun getOverlayRanges(text: ContentText): Array<ContentOverlayRange<ContentRef>> {
        return text.slices.map {
            @Suppress("UNCHECKED_CAST")
            ContentOverlayRange(
                it.rootElement.ref as ObjectRef<ContentRef>,
                it.range.first,
                it.range.second,
            )
        }.toTypedArray()
    }
}
