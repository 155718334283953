package com.speechify.client.api.adapters.xml

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.coGetAllAsString
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import org.w3c.dom.Element
import org.w3c.dom.ItemArrayLike
import org.w3c.dom.Text
import org.w3c.dom.parsing.DOMParser

/**
 * [XMLParser] implementation using [org.w3c.dom.parsing.DOMParser] available in the browsers.
 * This is a copy-paste of [com.speechify.client.api.adapters.html.HTMLParser] — though with a differentiation that this is
 * being used to parse XML files and not HTML files. Currently this is only being used to parse the XML tree in an EPUB's
 * content file, to grab the content structure of the EPUB for correct ordering.
 * This currently serves the functionality well, hence the straight up copy-paste.
 * In the future should we require a more robust XML parser, changes can be made here.
 *
 * Important Note: The adapter may reject valid XML based on the MIME type alone. To prevent this,
 * we mask the MIME type to ensure proper processing of valid XML input files.
 * For example, an .ncx file in EPUB 2 has the MIME type 'application/x-dtbncx+xml' but is still a valid XML file.
 * As Mention above the contract of this parser is designed to parse xml based on file content.
 */
@JsExport
class W3CXMLParser : XMLParser() {

    private fun isSupportedMimeType(inputMimeType: String): Boolean {
        /**
         * According to the (MDN documentation)[https://developer.mozilla.org/en-US/docs/Web/API/DOMParser/parseFromString#mimetype],
         * DOMParser only supports the types bellow.
         */
        return setOf(
            "text/html",
            "text/xml",
            "application/xml",
            "application/xhtml+xml",
            "image/svg+xml",
        ).contains(inputMimeType)
    }

    override fun parseAsDOM(
        file: File,
        callback: Callback<XMLDOMElement>,
    ) =
        callback.fromCo { coParseAsDOM(file).successfully() }

    override suspend fun coParseAsDOM(file: File): XMLDOMElementFromW3CElement {
        /**
         * To prevent rejecting valid XML based on MIME type alone,
         * we mask the MIME type for proper processing by falling back to default xml mime type: "application/xml"
         *  For example, an .ncx file in EPUB 2 (application/x-dtbncx+xml) is valid XML.
         */
        val supportedMimeTypeOrFallbackToDefault =
            if (isSupportedMimeType(file.mimeType.typeSubtype)) {
                file.mimeType.typeSubtype
            } else {
                // falls back to xml mimetype here because this xml parser.
                "application/xml"
            }
        return XMLDOMElementFromW3CElement(
            w3cElement = DOMParser().parseFromString(
                str = file.coGetAllAsString().orThrow(),
                type = supportedMimeTypeOrFallbackToDefault,
            ).documentElement!!,
        )
    }
}

internal class XMLDOMElementFromW3CElement(private val w3cElement: Element) : XMLDOMElement() {

    override val tagName: String
        get() = w3cElement.tagName

    override val attributes: Array<BoundaryPair<String, String>>
        get() =
            w3cElement.attributes.iterate().filterNotNull().map {
                BoundaryPair(it.name, it.value)
            }.toTypedArray()

    override val children: Array<XMLDOMNode>
        get() =
            sequence {
                for (child in w3cElement.childNodes.iterate().filterNotNull()) {
                    when (child) {
                        is Element -> yield(XMLDOMElementFromW3CElement(child))
                        is Text -> {
                            val text = child.textContent
                            if (text != null) {
                                yield(XMLDOMTextNodeFromW3CTextNode(text, child))
                            }
                        }
                    }
                }
            }.toList().toTypedArray()
}

private class XMLDOMTextNodeFromW3CTextNode(
    // Requiring the non-null text here, because [org.w3c.dom.Node] itself has it nullable. We want to simplify this API and skip such weird text nodes (likely they don't even happen).
    override val text: String,
    private val node: Text,
) : XMLDOMTextNode()

private fun <Item> ItemArrayLike<Item>.iterate(): Iterable<Item?> =
    (0 until this.length).map { index ->
        this.item(index)
    }
