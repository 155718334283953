package com.speechify.client.api.services.library.models

import kotlin.js.JsExport

@JsExport
data class FolderChangeSet(
    val added: Array<LibraryItem>,
    val removed: Array<LibraryItem>,
    val modified: Array<LibraryItem>,
)
