package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformCreateAppleSubscriptionErrorResponse(
    val message: String,
    val detail: PlatformCreateAppleSubscriptionErrorResponseDetails,
)

@Serializable
internal data class PlatformCreateAppleSubscriptionErrorResponseDetails(
    val code: String,
    val subscriptionId: String,
    val otherEmail: String? = null,
)
