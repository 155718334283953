package com.speechify.client.internal.util.io.arrayBuffer.extensions

import com.speechify.client.internal.util.collections.arrays.toByteArray
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array

internal fun ArrayBuffer.toByteArray(): ByteArray =
    toInt8Array().toByteArray()

internal fun ArrayBuffer.toInt8Array(): Int8Array =
    Int8Array(this)
