package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.emitAll
import kotlinx.coroutines.flow.flow

/**
 * A version of [flow] that saves a level of indentation when the caller is a non-`suspend` function, but there
 * is asynchronous information that is needed for the flow (AKA the 'seed').
 */
internal inline fun <T, Seed> flowFromAsyncSeed(
    /**
     * Specifies the 'seed', i.e. the information that is needed for the flow.
     * The expression can be suspending, and will be evaluated in the coroutine of the caller of [Flow.collect].
     */
    crossinline getSeed: suspend () -> Seed,
    /**
     * Produces the flow using the seed that was obtained from [getSeed].
     */
    crossinline getFlow: suspend (seed: Seed) -> Flow<T>,
) = flow {
    emitAll(
        flow = getFlow(
            /* seed =*/ getSeed(),
        ),
    )
}

/**
 * A version of [flowFromAsyncSeed] where the seed is not immediately needed for the flow, but e.g. at some advanced
 * items or conditionally (or both), but it should be only evaluated once (be it for correctness or just optimization).
 */
internal inline fun <T, Seed> flowFromAsyncSeedDeferred(
    crossinline getSeed: suspend () -> Seed,
    crossinline getFlow: suspend (seedDeferred: Deferred<Seed>) -> Flow<T>,
) = flow {
    coroutineScope {
        val seedDeferred = async(
            start = kotlinx.coroutines.CoroutineStart.LAZY,
        ) {
            getSeed()
        }
        try {
            getFlow(
                /* seedDeferred =*/ seedDeferred,
            )
                .collect {
                    emit(it)
                }
        } catch (e: Throwable) {
            if (!seedDeferred.isCancelled) {
                seedDeferred.cancel(
                    cause = if (e is CancellationException) {
                        e
                    } else {
                        CancellationException(
                            message = "Flow production or collection threw an exception.",
                            cause = e,
                        )
                    },
                )
            }
            throw e
        }
        seedDeferred.cancel()
    }
}

/**
 * A version of [flowFromAsyncSeed] where there's no need for an async seed, i.e. the async code readily returns a flow.
 */
internal inline fun <T> flowFromAsyncGetFlow(
    crossinline getFlow: suspend () -> Flow<T>,
) = flow {
    emitAll(
        flow = getFlow(),
    )
}
