package com.speechify.client.bundlers.content

import com.speechify.client.api.AdaptersProvider
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.html.DOMElement
import com.speechify.client.api.adapters.html.serializeHtmlToFile
import com.speechify.client.api.adapters.pdf.coGetPDFDocumentAdapter
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.StandardViewWithIndex
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.epub.Epub
import com.speechify.client.api.content.epub.EpubParser
import com.speechify.client.api.content.epub.EpubV2
import com.speechify.client.api.content.epub.EpubVersion
import com.speechify.client.api.content.epubV3.EpubParserV3
import com.speechify.client.api.content.epubV3.EpubV3
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.startofmaincontent.RawStartOfMainContent
import com.speechify.client.api.content.startofmaincontent.StartOfMainContent
import com.speechify.client.api.content.startofmaincontent.toRawStartOfMainContent
import com.speechify.client.api.content.txt.FilePlainTextView
import com.speechify.client.api.content.txt.InMemoryPlainTextView
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.epub.EpubViewV1
import com.speechify.client.api.content.view.epub.EpubViewV2
import com.speechify.client.api.content.view.epub.EpubViewV3
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.toHtmlFragment
import com.speechify.client.api.content.view.txt.PlainTextView
import com.speechify.client.api.content.view.web.DOMWebPageView
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.scannedbook.models.FirestoreScannedBook
import com.speechify.client.api.services.scannedbook.models.LazyOCRFiles
import com.speechify.client.api.services.scannedbook.models.LazyOCRFilesFromList
import com.speechify.client.api.services.scannedbook.models.LocalScannedBook
import com.speechify.client.api.services.scannedbook.models.LocalScannedBookLazy
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.services.scannedbook.models.ScannedBook
import com.speechify.client.api.telemetry.LibraryItemContentTypeTelemetryProp
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.ensureListenableMimeType
import com.speechify.client.api.util.fromBlock
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.HtmlFileFromString
import com.speechify.client.api.util.io.coGetSizeInBytes
import com.speechify.client.api.util.io.toFailureIfNoContentType
import com.speechify.client.api.util.io.toNotNullableContentOrNull
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.BookViewFactory
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.addBundleMetadataProperties
import com.speechify.client.bundlers.reading.importing.AllImportableContentImporterFactory
import com.speechify.client.bundlers.reading.importing.BinaryContentImporterFactory
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.bundlers.reading.importing.createForHtmlContent
import com.speechify.client.helpers.content.index.ApproximateBookIndexV1
import com.speechify.client.helpers.content.index.ApproximateBookIndexV2
import com.speechify.client.helpers.content.index.ApproximateEpubV2Index
import com.speechify.client.helpers.content.index.EagerStandardIndex
import com.speechify.client.helpers.content.index.PlainTextContentIndex
import com.speechify.client.helpers.content.index.SpeechifyBookIndex
import com.speechify.client.helpers.content.speech.StandardSpeechView
import com.speechify.client.helpers.content.standard.PlainTextStandardView
import com.speechify.client.helpers.content.standard.StaticStandardView
import com.speechify.client.helpers.content.standard.book.BookStandardView
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV1
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.content.standard.html.WebPageStandardView
import com.speechify.client.internal.services.book.PlatformBookPageContentStatsService
import com.speechify.client.internal.services.editing.BookEditingService
import com.speechify.client.internal.services.epub.EpubChapterContentStatsService
import com.speechify.client.internal.services.file.models.InMemoryFile
import com.speechify.client.internal.services.importing.ImportableContentPayload
import com.speechify.client.internal.services.userDocumentSettings.BookBundleConfigService
import com.speechify.client.internal.util.coroutines.coroutineScopeWithResultDeferredAvailableInBlock
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import com.speechify.client.internal.util.www.UrlString
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlin.js.JsExport

internal typealias BookPageIndex = Int
internal typealias IsBookPageContentEmpty = Boolean

/**
 * A way to get a [ContentBundle] for any piece of content out there, optimized for instant listening.
 */
@JsExport
open class ContentBundler internal constructor(
    private val adaptersProvider: AdaptersProvider,
    internal val contentBundlerConfig: ContentBundlerConfig,
    private val bookViewFactory: BookViewFactory,
    private val bookEditingService: BookEditingService,
    internal val contentImporterFactory: AllImportableContentImporterFactory,
    private val platformBookPageContentStatsService: PlatformBookPageContentStatsService,
    private val epubChapterContentStatsService: EpubChapterContentStatsService,
    private val useApproximateBookIndexV2: Boolean,
    private val epubVersion: EpubVersion,
    private val epubSpeechifyBookVersion: EpubVersion,
    private val bookBundleConfigService: BookBundleConfigService,
) {
    /**
     * Create a [ContentBundle] for the given [content].
     */
    fun createBundleForBinaryContent(
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        content: BinaryContentReadableRandomly,
        /**
         * See [MimeType] for how to create one.
         */
        mimeType: MimeType,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ContentBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ContentBundler.createBundleForBinaryContent",
    ) {
        coCreateBundleForUnimportedBinaryContent(
            payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                content = content,
                mimeType = mimeType.ensureListenableMimeType(contentTypeForFallback = null),
                sourceUrl = null,
            ).orReturn { return@fromCoWithErrorLogging it },
            null,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
    }

    /**
     * Create a [ContentBundle] for this URL.
     */
    fun createBundleForURL(
        url: UrlString,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ContentBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ContentBundler.createBundleForURL",
    ) {
        coCreateBundleForURL(
            url = url,
            deviceLocalContent = null,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
    }

    internal suspend fun coCreateBundleForURL(
        url: UrlString,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundle> {
        val content = currentTelemetryEvent().addMeasurement("downloadFile") {
            adaptersProvider.httpClient.getBinaryContentReadableRandomly(
                url = url,
            )
                /* TODO investigate if `toFailureIfNoContentType` is still needed.
                 *  It is there just to keep historical behavior.
                 */
                .toFailureIfNoContentType(url)
                .orReturn { return it }
                .let {
                    it.toNotNullableContentOrNull()
                        ?: return@coCreateBundleForURL Result.Failure(
                            SDKError.OtherException(
                                Error("No body for {url}")
                                    .apply { addCustomProperty("url", url) },
                            ),
                        )
                }
        }
        return coCreateBundleForUnimportedBinaryContent(
            payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                content = content.binaryContent,
                mimeType = content.mimeType.ensureListenableMimeType(contentTypeForFallback = null),
                sourceUrl = url,
            ).orReturn { return it },
            deviceLocalContent = deviceLocalContent,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
    }

    @Suppress(
        /** Kotlin code analysis glitch? The [BinaryContentImporterFactory] is a function's inner class, so not
         exported, but it complains that it's not-exportable */
        "NON_EXPORTABLE_TYPE",
    )
    internal suspend fun <ContentBundleType : ContentBundle>
    coCreateBundleForImportedBinaryContent(
        uri: SpeechifyURI,
        libraryItem: LibraryItem.Content,
        payload: ListenableBinaryContentPayload<*, *, ContentBundleType>,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundle> =
        coCreateBundleForBinaryContent(
            payload = payload,
            deviceLocalContent = null,
            importerFactory = object : BinaryContentImporterFactory {
                override fun createContentImporter(
                    payload: ImportableContentPayload.ImportableContentPayloadOfSingleBlob,
                    deviceLocalContent: LibraryItem.DeviceLocalContent?,
                    customProperties: Sequence<Pair<String, Any>>,
                    importStartChoice: ImportStartChoice,
                    bundleMetadata: BundleMetadata?,
                ): ContentImporter =
                    contentImporterFactory.createForAlreadyImportedResource(uri, libraryItem)
            },
            importStartChoice = ImportStartChoice.DoNotStart,
            bundleMetadata = bundleMetadata,
        )

    internal suspend fun <ContentBundleType : ContentBundle>
    coCreateBundleForUnimportedBinaryContent(
        payload: ListenableBinaryContentPayload<*, *, ContentBundleType>,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundleType> =
        coCreateBundleForBinaryContent(
            payload = payload,
            deviceLocalContent = deviceLocalContent,
            importerFactory = contentImporterFactory,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )

    internal suspend fun <ContentBundleType : ContentBundle>
    coCreateBundleForBinaryContent(
        payload: ListenableBinaryContentPayload<*, *, ContentBundleType>,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importerFactory: BinaryContentImporterFactory,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundleType> {
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        currentTelemetryEvent()?.addProperty("contentType", payload.contentWithMimeType.mimeType?.fullString)
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(
                    payload.libraryItemContentType,
                ),
        )
        when (val fileSize = payload.contentWithMimeType.binaryContent.coGetSizeInBytes()) {
            is Result.Failure -> Log.e(
                failure = fileSize,
                message = "Failed to get file size",
                sourceAreaId = "ContentBundler.coCreateBundleForBinaryContent",
            )

            is Result.Success -> currentTelemetryEvent()?.addProperty("contentBytesCount", fileSize.value)
        }

        return when (payload) {
            is ListenableBinaryContentPayload.Pdf -> {
                coCreateBundleForPdf(
                    content = payload,
                    deviceLocalContent = deviceLocalContent,
                    importerFactory = importerFactory,
                    importStartChoice = importStartChoice,
                    bundleMetadata = bundleMetadata,
                )
                    .let {
                        @Suppress(
                            /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                            "UNCHECKED_CAST",
                            "USELESS_CAST",
                        )
                        it.successfully() as Result<ContentBundle.BookBundle>
                            as Result<ContentBundleType>
                    }
            }

            is ListenableBinaryContentPayload.Html -> {
                createBundleForHtmlPayload(
                    payload = payload,
                    options = HtmlContentLoadOptions(
                        sourceUrl = payload.sourceUrl,
                        isEntireDocument = null,
                        isPostJavaScriptExecution = null,
                        isPostContentExtraction = null,
                    ),
                    deviceLocalContent = deviceLocalContent,
                    importerFactory = importerFactory,
                    importStartChoice = importStartChoice,
                    bundleMetadata = bundleMetadata,
                )
                    .let {
                        @Suppress(
                            /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                            "UNCHECKED_CAST",
                            "USELESS_CAST",
                        )
                        it.successfully() as Result<ContentBundle.WebPageBundle>
                            as Result<ContentBundleType>
                    }
            }

            is ListenableBinaryContentPayload.PlainTextOrMarkdown -> {
                coroutineScopeWithResultDeferredAvailableInBlock { contentBundleDeferred ->
                    createBundleForPlainTextView(
                        view = FilePlainTextView(
                            payload
                                .contentWithMimeType,
                        ),
                        contentImporter = importerFactory.createContentImporter(
                            payload = ImportableContentPayload.PlainTextOrMarkdown(
                                listenableBinaryContentPayload = payload,
                                parsedContentsForImport = ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                    .ParsedContentsForImportOfSingleBlob(
                                        contentBundle = contentBundleDeferred,
                                    ),
                            ),
                            deviceLocalContent = deviceLocalContent,
                            importStartChoice = importStartChoice,
                            bundleMetadata = bundleMetadata,
                        ),
                        bundleMetadata = bundleMetadata,
                    )
                }
                    .let {
                        @Suppress(
                            /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                            "UNCHECKED_CAST",
                            "USELESS_CAST",
                        )
                        it.successfully() as Result<ContentBundle.PlainTextBundle>
                            as Result<ContentBundleType>
                    }
            }

            is ListenableBinaryContentPayload.Epub -> {
                when (epubVersion) {
                    EpubVersion.V2 -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForEpubV2File(
                                epubV2 = EpubParser(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                    htmlParser = adaptersProvider.htmlParser,
                                ).parseEpubV2(payload.contentWithMimeType.binaryContent),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.Epub(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubV2Bundle>
                                as Result<ContentBundleType>
                        }
                    }

                    EpubVersion.V3 -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForEpubV3File(
                                epubV3 = EpubParserV3(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                ).parseEpubV3(payload.contentWithMimeType.binaryContent),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.Epub(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubBundle>
                                as Result<ContentBundleType>
                        }
                    }
                    // V1
                    else -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForEpubFile(
                                epub = EpubParser(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                    htmlParser = adaptersProvider.htmlParser,
                                )
                                    .parseEpub(payload.contentWithMimeType.binaryContent),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.Epub(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubBundle>
                                as Result<ContentBundleType>
                        }
                    }
                }
            }

            is ListenableBinaryContentPayload.SpeechifyBook -> {
                when (epubSpeechifyBookVersion) {
                    EpubVersion.V3 -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForSpeechifyBook(
                                epubV3 = EpubParserV3(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                ).parseEpubV3FromByteArray(payload.contentWithMimeType),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.SpeechifyBook(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubV2Bundle>
                                as Result<ContentBundleType>
                        }
                    }

                    EpubVersion.V2 -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForSpeechifyBook(
                                epubV2 = EpubParser(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                    htmlParser = adaptersProvider.htmlParser,
                                ).parseEpubV2FromByteArray(payload.contentWithMimeType),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.SpeechifyBook(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubV2Bundle>
                                as Result<ContentBundleType>
                        }
                    }

                    else -> {
                        coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
                            coCreateBundleForEpubFile(
                                epub = EpubParser(
                                    archiveFilesAdapter = adaptersProvider.archiveFilesAdapter,
                                    xmlParser = adaptersProvider.xmlParser,
                                    htmlParser = adaptersProvider.htmlParser,
                                )
                                    .parseEpubFromByteArray(payload.contentWithMimeType),
                                importer = importerFactory.createContentImporter(
                                    payload = ImportableContentPayload.SpeechifyBook(
                                        listenableBinaryContentPayload = payload,
                                        parsedContentsForImport =
                                        ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                                            .ParsedContentsForImportOfSingleBlob(
                                                contentBundle = contentBundle,
                                            ),
                                    ),
                                    deviceLocalContent = deviceLocalContent,
                                    importStartChoice = importStartChoice,
                                    bundleMetadata = bundleMetadata,
                                ),
                                bundleMetadata = bundleMetadata,
                            )
                        }.let {
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <ContentBundleType>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            it.successfully() as Result<ContentBundle.EpubBundle>
                                as Result<ContentBundleType>
                        }
                    }
                }
            }
        }
    }

    private suspend fun coCreateBundleForSpeechifyBook(
        epubV3: EpubV3,
        importer: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.EpubBundleV3 {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.SPEECHIFY_BOOK),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        return createBundleForEpubV3(
            epubV3,
            importer,
            withContentIndex = { SpeechifyBookIndex(standardView = it) },
        )
    }

    private suspend fun coCreateBundleForEpubV3File(
        epubV3: EpubV3,
        importer: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.EpubBundleV3 {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.EPUB),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        return createBundleForEpubV3(
            epubV3,
            importer,
            withContentIndex = {
                ApproximateEpubV2Index(
                    standardView = it,
                    epubChapterContentStatsService = epubChapterContentStatsService,
                    postImportActionsManager = importer,
                )
            },
        )
    }

    private fun createBundleForEpubV3(
        epubV3: EpubV3,
        importer: ContentImporter,
        withContentIndex: (EpubStandardViewV2) -> ContentIndex,
    ): ContentBundle.EpubBundleV3 {
        val epubView = EpubViewV3(
            htmlParser = adaptersProvider.htmlParser,
            epubV3 = epubV3,
            webViewAdapter = adaptersProvider.webViewAdapter,
        )

        val imported = importer.state as? ContentImporterState.ImportedToLibrary
        val startOfMainContent = imported?.libraryItem?.startOfMainContent?.toRawStartOfMainContent()

        val standardView = EpubStandardViewV2(
            view = epubView,
            staticStartOfMainContent = startOfMainContent as? RawStartOfMainContent.Epub,
            // For EpubReader 2.0, we enforce the use of `RichBlocksFromWebPage` since it's rolled out 100% for clients.
            // Maintaining both `RichBlocksFromWebPage` and `NormalParsing` is challenging because one cleans up
            // whitespace before constructing cursors, while the other does it afterward.
            shouldUseRichBlocksParsing = true,
        )
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)
        return ContentBundle.EpubBundleV3(
            contentBundlerOptions = contentBundlerConfig.options,
            epubView = epubView,
            standardView = standardView,
            speechView = speechView,
            contentIndex = withContentIndex(standardView),
            contentImporter = importer,
            tableOfContentsFlow = standardView.tableOfContentsFlow,
            startOfMainContentFlow = standardView.startOfMainContentFlow,
            searcher = null,
        )
    }

    private suspend fun coCreateBundleForPdf(
        content: ListenableBinaryContentPayload.Pdf,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importerFactory: BinaryContentImporterFactory,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.BookBundle =
        /** Workaround for circular reference of [ContentBundle] requiring [ContentImporter] #CircularReferenceInContentBundleAndContentImporter
         * Creating a [ContentBundle.BookBundle], but it needs an importer, which in turns needs the objects created
         * in the also putting it into a [Deferred] that can be used by the importer
         *  TODO To solve #CircularReferenceInContentBundleAndContentImporter, likely [ContentBundle] needs to be freed
         *   from the need to have [ContentImporter]. This should make sense because [ContentBundle] is the data that
         *   the [ContentImporter] needs, not the other way around. If there are any changes to the [ContentBundle] that
         *   the [ContentImporter] needs to know about, they should be communicated out from the [ContentBundle] not
         *   directly to the [ContentImporter], but to a different component that may merely link them to the importer.
         */
        coroutineScopeWithResultDeferredAvailableInBlock { contentBundleDeferred ->
            val pdfDocumentDeferred = async {
                currentTelemetryEvent().addMeasurement("createPdfDocumentAdapter") {
                    adaptersProvider
                        .pdfAdapterFactory.coGetPDFDocumentAdapter(
                            content.contentWithMimeType.binaryContent,
                            content.password,
                        )
                        .orThrow()
                }
            }

            val importer: ContentImporter = importerFactory.createContentImporter(
                payload = ImportableContentPayload.Pdf(
                    listenableBinaryContentPayload = content,
                    parsedContentsForImport = ImportableContentPayload.Pdf.ParsedContentsForImportOfPdf(
                        pdfDocument = pdfDocumentDeferred.await(),
                        contentBundle = contentBundleDeferred,
                    ),
                ),
                deviceLocalContent = deviceLocalContent,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            )

            // Assigning a value of 10 to the replay ensures that OCR-ed pages are emitted for new
            // collectors/subscribers during the initialization of the ReadingBundle at the ApproximateBookIndex layer.
            val bookPageOCRRequirementFlow = MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>>(replay = 10)
            val pdfDocument = pdfDocumentDeferred.await()
            val ocrFallbackStrategyFlow = getOcrFallbackStrategyFlow(
                uri = (importer.state as? ContentImporterState.Imported)?.uri,
            )
            val bookView = currentTelemetryEvent().addMeasurement("createBookView") {
                checkDocumentPagesNumberNotEmpty(pdfDocument.getMetadata().numberOfPages)
                importer.wrapWithEditingBookViewIfAlreadyImported(
                    bookView = bookViewFactory.createFromPdf(
                        source = pdfDocument,
                        postImportActionsManager = importer,
                        bookPageOCRRequirementFlow = bookPageOCRRequirementFlow,
                        ocrFallbackStrategyFlow = ocrFallbackStrategyFlow,
                    ),
                )
            }
            return@coroutineScopeWithResultDeferredAvailableInBlock createBundleForBookView(
                bookView = bookView,
                importer = importer,
                bookPageOCRRequirementFlow = bookPageOCRRequirementFlow,
            )
        }

    /**
     * Create a [ContentBundle] for this [StandardView]. Intended for clients who construct [StaticStandardView] directly.
     */
    fun createBundleForStandardView(
        standardView: StandardView,
        sourceUrl: String?,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ContentBundle.StandardBundle<StandardView>>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ContentBundler.createBundleForStandardView",
    ) {
        coCreateBundleForStandardView(
            standardView = standardView,
            sourceUrl = sourceUrl,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
            .successfully()
    }

    internal suspend fun <TStandardView : StandardView> coCreateBundleForStandardView(
        standardView: TStandardView,
        sourceUrl: String? = null,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.StandardBundle<TStandardView> = coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata)
        ContentBundle.StandardBundle(
            standardView = standardView,
            contentImporter = contentImporterFactory.createForHtmlContent(
                payload = ImportableContentPayload.Html(
                    listenableBinaryContentPayload = ListenableBinaryContentPayload.Html(
                        contentWithMimeType = HtmlFileFromString(
                            standardView.toHtmlFragment().orThrow(),
                        ),
                        sourceUrl = sourceUrl,
                    ),
                    parsedContentsForImport = ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                        .ParsedContentsForImportOfSingleBlob(
                            contentBundle = contentBundle,
                        ),
                ),
                deviceLocalContent = null,
                options = HtmlContentLoadOptions(
                    sourceUrl = sourceUrl,
                    isEntireDocument = null,
                    isPostContentExtraction = true,
                    isPostJavaScriptExecution = null,
                ),
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
            contentBundlerOptions = contentBundlerConfig.options,
        )
    }

    internal fun <TStandardViewWithIndex : StandardViewWithIndex> coCreateBundleForStandardViewWithIndex(
        standardViewWithIndex: TStandardViewWithIndex,
    ): ContentBundle.StandardBundle<TStandardViewWithIndex> =
        ContentBundle.StandardBundle.createFromStandardViewWithIndex(
            standardViewWithIndex = standardViewWithIndex,
            contentImporter = object : ContentImporter() {
                /*
                 TODO. when importing dynamic content is required (it wasn't for the initial release of improved Google
                   Docs), then we'd need to create an importer that isn't eager (maybe use it only in the
                    #TODOSpecializedCreateBundle's created method)
                 */
                override val stateFlow: StateFlow<ContentImporterState>
                    get() = MutableStateFlow(
                        value = ContentImporterState.NotImported(
                            libraryItem = null,
                        ),
                    )
                        .asStateFlow()

                override suspend fun startImport(options: ImportOptions): Result<SpeechifyURI> {
                    TODO("Implement StartImport")
                    /* ... or see if its calling can be removed in your use case */
                }

                override fun setEditsSaveAction(saveEditsAction: suspend (uri: SpeechifyURI) -> Unit):
                    Deferred<SpeechifyURI> {
                    TODO("Implement setEditsSaveAction")
                    /* ... or see if its calling can be removed in your use case */
                }

                override fun queueTaskAfterImport(task: suspend (uri: SpeechifyURI) -> Unit) {
                    TODO("Implement queueTaskAfterImport")
                    /* ... or see if its calling can be removed in your use case */
                }
            },
            contentBundlerOptions = contentBundlerConfig.options,
        )

    /**
     * Create a the [ContentBundle] for this [ScannedBook]
     */
    fun createBundleForOcrFiles(
        ocrFiles: Array<OCRFile>,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ContentBundle.BookBundle>,
    ) {
        callback.fromCoWithErrorLogging(
            sourceAreaId = "ContentBundler.createBundleForOcrFiles",
        ) {
            createBundleForScannedBook(
                ocrFiles = ocrFiles,
                deviceLocalContent = null,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            )
                .successfully()
        }
    }

    internal suspend fun createBundleForOcrFilesLazily(
        lazyOcrFiles: LazyOCRFiles,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.BookBundle = coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
        createBundleForScannedBook(
            scannedBook = LocalScannedBookLazy(
                lazyOCRFiles = lazyOcrFiles,
                ocrAdapter = adaptersProvider.ocrAdapter,
            ),
            importer = contentImporterFactory.createContentImporter(
                payload = ImportableContentPayload.OCR(
                    ocrFiles = lazyOcrFiles,
                    parsedContentsForImport = ImportableContentPayload.OCR.ParsedContentsForImportOfOcr(
                        contentBundle = contentBundle,
                    ),
                ),
                deviceLocalContent = deviceLocalContent,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
        )
    }

    internal suspend fun createBundleForScannedBookAlreadyImported(
        scannedBook: FirestoreScannedBook,
        libraryItem: LibraryItem.Content,
    ): ContentBundle.BookBundle =
        createBundleForScannedBook(
            scannedBook = scannedBook,
            importer = contentImporterFactory.createForAlreadyImportedResource(
                uri = scannedBook.uri,
                libraryItem = libraryItem,
            ),
        )

    internal suspend fun createBundleForScannedBook(
        ocrFiles: Array<OCRFile>,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.BookBundle = coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
        createBundleForScannedBook(
            scannedBook = LocalScannedBook(ocrFiles),
            importer = contentImporterFactory.createContentImporter(
                payload = ImportableContentPayload.OCR(
                    ocrFiles = LazyOCRFilesFromList(ocrFiles.toList()),
                    parsedContentsForImport = ImportableContentPayload.OCR.ParsedContentsForImportOfOcr(
                        contentBundle = contentBundle,
                    ),
                ),
                deviceLocalContent = deviceLocalContent,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
        )
    }

    /* #InternalForTests */
    internal suspend fun createBundleForBookView(
        bookView: BookView,
        importer: ContentImporter,
        bookPageOCRRequirementFlow: MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>> =
            MutableSharedFlow(),
    ): ContentBundle.BookBundle {
        val standardView = BookStandardView(bookView)
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)
        val contentIndex = when (useApproximateBookIndexV2) {
            true -> ApproximateBookIndexV2(bookView, platformBookPageContentStatsService, importer)
            false -> ApproximateBookIndexV1(bookView)
        }
        return ContentBundle.BookBundle(
            contentBundlerOptions = contentBundlerConfig.options,
            bookView = bookView,
            bookEditingService = bookEditingService,
            standardView = standardView,
            speechView = speechView,
            contentIndex = contentIndex,
            contentImporter = importer,
            existingMutableObservableContentTitle = null,
            bookPageOCRRequirementFlow = bookPageOCRRequirementFlow,
            platformBookPageContentStatsService = platformBookPageContentStatsService,
            tableOfContentsFlow = MutableStateFlow(bookView.getTableOfContents()),
            startOfMainContentFlow = MutableStateFlow(StartOfMainContent.NotAvailable),
            searcher = null,
        )
    }

    private suspend fun createBundleForScannedBook(
        scannedBook: ScannedBook,
        importer: ContentImporter,
    ): ContentBundle.BookBundle {
        checkDocumentPagesNumberNotEmpty(scannedBook.numberOfPages)
        val telemetryEvent = currentTelemetryEvent()

        telemetryEvent?.addProperty(
            LibraryItemContentTypeTelemetryProp.toPairWithVal(ContentType.SCAN),
        )

        val bookView = currentTelemetryEvent().addMeasurement("createBookView") {
            importer.wrapWithEditingBookViewIfAlreadyImported(
                bookViewFactory.createFromScannedBook(scannedBook, importer),
            )
        }

        return createBundleForBookView(
            bookView = bookView,
            importer,
        )
    }

    internal suspend fun createBundleForHtmlPayload(
        payload: ListenableBinaryContentPayload.Html,
        options: HtmlContentLoadOptions,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importerFactory: BinaryContentImporterFactory = contentImporterFactory,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.WebPageBundle = coroutineScopeWithResultDeferredAvailableInBlock { contentBundleDeferred ->
        createBundleForHtmlElementAndImportablePayload(
            element = currentTelemetryEvent().addMeasurement("parseHtmlAsDom") {
                adaptersProvider.htmlParser.coParseAsDOM(
                    file = payload.contentWithMimeType,
                )
                    .orThrow()
            },
            options = options,
            importablePayload = ImportableContentPayload.Html(
                listenableBinaryContentPayload = payload,
                parsedContentsForImport = ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                    .ParsedContentsForImportOfSingleBlob(
                        contentBundle = contentBundleDeferred,
                    ),
            ),
            deviceLocalContent = deviceLocalContent,
            importerFactory = importerFactory,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
    }

    private suspend fun coCreateBundleForEpubFile(
        epub: Epub,
        importer: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.EpubBundle {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.EPUB),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        return createBundleForEpub(
            epub,
            importer,
        )
    }

    private suspend fun coCreateBundleForSpeechifyBook(
        epubV2: EpubV2,
        importer: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.EpubV2Bundle {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.SPEECHIFY_BOOK),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        return createBundleForEpubV2(
            epubV2,
            importer,
            withContentIndex = { SpeechifyBookIndex(standardView = it) },
        )
    }

    private suspend fun coCreateBundleForEpubV2File(
        epubV2: EpubV2,
        importer: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.EpubV2Bundle {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.EPUB),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        return createBundleForEpubV2(
            epubV2,
            importer,
            withContentIndex = {
                val isEpubVerySmall = epubV2.rawChapters.values.sumOf {
                    it.file.bytes.size
                } < VERY_SMALL_EPUB_CHAPTER_SIZE_SUM_THRESHOLD
                if (isEpubVerySmall) {
                    EagerStandardIndex(standardView = it)
                } else {
                    ApproximateEpubV2Index(
                        standardView = it,
                        epubChapterContentStatsService = epubChapterContentStatsService,
                        postImportActionsManager = importer,
                    )
                }
            },
        )
    }

    internal suspend fun createBundleForHtmlElementAndImportablePayload(
        element: DOMElement,
        options: HtmlContentLoadOptions,
        importablePayload: ImportableContentPayload.Html,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        importerFactory: BinaryContentImporterFactory = contentImporterFactory,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.WebPageBundle {
        val currentTelemetryEvent = currentTelemetryEvent()
        currentTelemetryEvent?.addProperty(
            LibraryItemContentTypeTelemetryProp
                .toPairWithVal(ContentType.HTML),
        )
        currentTelemetryEvent?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        if (options.isEntireDocument == false) {
            TODO(
                "Loading HTML with `isEntireDocument==false` (parts of document) is not supported yet." +
                    " Contact SDK team",
            )
            /* Throwing because there are no usages for now, while on first usage of this, need
               to propagate this property to [WebPageStandardView] (not just here, but also when loading from database)
               and ensure there are no 'whitelist' style rules looking for paragraphs - see https://github.com/SpeechifyInc/multiplatform-sdk/pull/662#discussion_r957141590 */
        }
        if (options.isPostContentExtraction == true) {
            TODO(
                "Loading HTML with `isPostContentExtraction==true` (AKA parsed HTML) is not supported yet." +
                    " Contact SDK team",
            )
            /* Throwing because there are no usages for now, while on first usage of this, need to propagate this
              property to [WebPageStandardView]  (not just here, but also when loading from database) and ensure there
              are no further extractions performed */
        }

        val webPageView = DOMWebPageView(element, options.sourceUrl)
        val standardView = WebPageStandardView(
            webPageView,
            contentBundlerConfig.options.shouldUseRichBlocksParsingForHtmlContentFlow.value,
        )
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)
        val contentIndex = EagerStandardIndex(standardView)
        return ContentBundle.WebPageBundle(
            contentBundlerOptions = contentBundlerConfig.options,
            webPageView = webPageView,
            standardView = standardView,
            speechView = speechView,
            contentIndex = contentIndex,
            searcher = null,
            contentImporter = importerFactory.createForHtmlContent(
                payload = importablePayload,
                deviceLocalContent = deviceLocalContent,
                options = options,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
        )
    }

    private fun createBundleForEpub(
        epub: Epub,
        importer: ContentImporter,
    ): ContentBundle.EpubBundle {
        val epubView = EpubViewV1(epub = epub)
        val tableOfContents = epubView.getWebPage().tableOfContents
        val standardView = EpubStandardViewV1(
            epubViewAsWebPage = epubView,
            shouldUseRichBlocksParsing =
            contentBundlerConfig.options.shouldUseRichBlocksParsingForEpubContentFlow.value,
        )
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)
        val contentIndex = EagerStandardIndex(standardView)
        return ContentBundle.EpubBundle(
            contentBundlerOptions = contentBundlerConfig.options,
            epubViewAsWebPage = epubView,
            standardView = standardView,
            speechView = speechView,
            contentIndex = contentIndex,
            contentImporter = importer,
            tableOfContentsFlow = MutableStateFlow(tableOfContents),
            startOfMainContentFlow = MutableStateFlow(StartOfMainContent.NotAvailable),
            searcher = null,
            epub = epub,
        )
    }

    private fun createBundleForEpubV2(
        epubV2: EpubV2,
        importer: ContentImporter,
        withContentIndex: (EpubStandardViewV2) -> ContentIndex,
    ): ContentBundle.EpubV2Bundle {
        val epubViewV2 = EpubViewV2(
            epubV2 = epubV2,
            htmlParser = adaptersProvider.htmlParser,
        )

        val imported = importer.state as? ContentImporterState.ImportedToLibrary
        val startOfMainContent = imported?.libraryItem?.startOfMainContent?.toRawStartOfMainContent()

        val standardView = EpubStandardViewV2(
            view = epubViewV2,
            staticStartOfMainContent = startOfMainContent as? RawStartOfMainContent.Epub,
            shouldUseRichBlocksParsing =
            contentBundlerConfig.options.shouldUseRichBlocksParsingForEpubContentFlow.value,
        )
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)

        return ContentBundle.EpubV2Bundle(
            contentBundlerOptions = contentBundlerConfig.options,
            epubView = epubViewV2,
            standardView = standardView,
            speechView = speechView,
            contentIndex = withContentIndex(standardView),
            contentImporter = importer,
            tableOfContentsFlow = standardView.tableOfContentsFlow,
            startOfMainContentFlow = standardView.startOfMainContentFlow,
            searcher = null,
            epubV2 = epubV2,
        )
    }

    fun createBundleForPlainText(
        content: String,
        sourceUrl: String?,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata? = null,
        callback: Callback<ContentBundle.PlainTextBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ContentBundler.createBundleForPlainText",
    ) {
        createBundleForPlainText(
            content = content,
            sourceUrl = sourceUrl,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
            .successfully()
    }

    fun createForEditedBook(edits: BookEditor, callback: Callback<ContentBundle.BookBundle>) = callback.fromBlock {
        createForEditedBook(edits)
            .successfully()
    }

    internal fun createForEditedBook(edits: BookEditor): ContentBundle.BookBundle {
        return edits.bookBundle.createFromEditorByMovingDependencies(
            editor = edits,
            contentBundlerOptions = contentBundlerConfig.options,
            useApproximateBookIndexV2 = useApproximateBookIndexV2,
        )
    }

    internal suspend fun createBundleForPlainText(
        content: String,
        sourceUrl: String?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.PlainTextBundle = coroutineScopeWithResultDeferredAvailableInBlock { contentBundle ->
        createBundleForPlainTextView(
            InMemoryPlainTextView(content),
            contentImporter = contentImporterFactory.createContentImporter(
                payload = ImportableContentPayload.PlainTextOrMarkdown(
                    listenableBinaryContentPayload = ListenableBinaryContentPayload.PlainTextOrMarkdown(
                        contentWithMimeType = InMemoryFile(
                            mimeType = MimeType(
                                typeSubtype = "text/plain",
                            ),
                            bytes = content.encodeToByteArray(),
                        ),
                        sourceUrl = sourceUrl,
                    ),
                    parsedContentsForImport = ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                        .ParsedContentsForImportOfSingleBlob(
                            contentBundle = contentBundle,
                        ),
                ),
                deviceLocalContent = null,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
            bundleMetadata = bundleMetadata,
        )
    }

    private suspend fun createBundleForPlainTextView(
        view: PlainTextView,
        contentImporter: ContentImporter,
        bundleMetadata: BundleMetadata?,
    ): ContentBundle.PlainTextBundle {
        currentTelemetryEvent()?.addProperty(
            LibraryItemContentTypeTelemetryProp.toPairWithVal(ContentType.TXT),
        )
        currentTelemetryEvent()?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)
        val standardView = PlainTextStandardView(view)
        val speechView = StandardSpeechView(standardView, contentBundlerConfig.options)
        val contentIndex = PlainTextContentIndex(view)
        return ContentBundle.PlainTextBundle(
            contentBundlerOptions = contentBundlerConfig.options,
            plaintextView = view,
            standardView = standardView,
            speechView = speechView,
            contentIndex = contentIndex,
            searcher = standardView,
            contentImporter = contentImporter,
        )
    }

    private suspend fun ContentImporter.wrapWithEditingBookViewIfAlreadyImported(
        bookView: BookView,
    ): BookView =
        when (val state = this.state) {
            is ContentImporterState.ImportedToLibrary -> {
                val shouldFetchEdits = state.libraryItem.hasPageEditsFlow.value
                val edits = currentTelemetryEvent().addMeasurement("fetchEdits") {
                    if (shouldFetchEdits) {
                        bookEditingService.loadBookEditsPayload(state.uri).orThrow()
                    } else {
                        BookEdits.defaultWith(bookView.getMetadata().numberOfPages)
                    }
                }
                EditingBookView(edits, bookView)
            }

            else -> EditingBookView(BookEdits.defaultWith(bookView.getMetadata().numberOfPages), bookView)
        }

    private suspend fun getOcrFallbackStrategyFlow(uri: SpeechifyURI?): StateFlow<OcrFallbackStrategy> {
        return when (uri) {
            null -> bookBundleConfigService.getOcrFallbackStrategy()

            else -> bookBundleConfigService.getOcrFallbackStrategy(libraryItemId = uri.id)
        }
    }

    private fun checkDocumentPagesNumberNotEmpty(pagesNumber: Int) {
        /*
         * TODO: make the code able to import and open 0-page documents, so that the App is able to import any valid
         *  file, and is also more resilient to temporary situations where 0-pages is reported, e.g. a bug or a use edit.
         */
        if (pagesNumber == 0) {
            throw Exception(
                "ContentBundler checkDocumentPagesNumberNotEmpty:" +
                    " 0 page documents are currently not supported.",
            )
        }
    }

    private companion object {
        // The sum of the chapter sizes in bytes under which an epub is considered very small
        const val VERY_SMALL_EPUB_CHAPTER_SIZE_SUM_THRESHOLD = (0.1 * 1024 * 1024).toInt() // 0.1 MB in bytes
    }
}

internal suspend fun ContentBundler.createBundleForHtmlFromSdkElement(
    htmlElement: DOMElement,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
    bundleMetadata: BundleMetadata?,
): ContentBundle.WebPageBundle =
    createBundleForParsedHtml(
        htmlSerialized = htmlElement.serializeHtmlToFile(),
        htmlElement = htmlElement,
        options = options,
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
    )

internal suspend fun ContentBundler.createBundleForParsedHtml(
    htmlSerialized: File,
    htmlElement: DOMElement,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice,
    bundleMetadata: BundleMetadata?,
): ContentBundle.WebPageBundle =
    coroutineScopeWithResultDeferredAvailableInBlock { contentBundleDeferred ->
        this@createBundleForParsedHtml.createBundleForHtmlElementAndImportablePayload(
            element = htmlElement,
            options = options,
            importablePayload = ImportableContentPayload.Html(
                listenableBinaryContentPayload = ListenableBinaryContentPayload.Html(
                    contentWithMimeType = htmlSerialized,
                    sourceUrl = options.sourceUrl,
                ),
                parsedContentsForImport = ImportableContentPayload.ImportableContentPayloadOfSingleBlob
                    .ParsedContentsForImportOfSingleBlob(
                        contentBundle = contentBundleDeferred,
                    ),
            ),
            deviceLocalContent = null,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        )
    }
