package com.speechify.client.helpers.content.standard.epub

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentElementReferenceUtils
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.epub.EpubNavPoint
import com.speechify.client.api.content.startofmaincontent.RawStartOfMainContent
import com.speechify.client.api.content.startofmaincontent.StartOfMainContent
import com.speechify.client.api.content.view.epub.EpubChapterContent
import com.speechify.client.api.content.view.epub.EpubView
import com.speechify.client.api.content.view.epub.OrderedTocEntry
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.web.WebPage
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.InMemoryCacheManager
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent
import com.speechify.client.helpers.content.standard.html.chunkIfItsSingleParagraphWithLargeTextContent
import com.speechify.client.helpers.content.standard.html.getBlocksFromWebPage
import com.speechify.client.helpers.content.standard.html.getRichBlocksFromWebPage
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.AtomicCircularFixedList
import com.speechify.client.internal.util.collections.maps.BlockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.asMutableMapWithBasics
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch

internal class EpubStandardViewV2(
    internal val view: EpubView,
    private val staticStartOfMainContent: RawStartOfMainContent.Epub?,
    private val shouldUseRichBlocksParsing: Boolean = false,
) : WithScope(), StandardView, ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent {
    override val start = ContentElementReference.forRoot().start
    override val end = ContentElementReference.forRoot().end

    private val metadata by lazy { view.getMetadata() }

    val parsedChapterContentFlow = MutableSharedFlow<Pair<Int, StandardBlocks>>()
    val tableOfContentsFlow = MutableStateFlow(
        metadata.initialNavPointsToTocEntries.mapKeys { it.key.toNavPointKey() }.toTableOfContents(),
    )

    val startOfMainContentFlow = MutableStateFlow<StartOfMainContent>(
        StartOfMainContent.NotReady,
    )

    private val parsedChapterIndexes = BlockingThreadsafeMap<Int, Unit>()
    private val fetchedTocChapters = BlockingThreadsafeMap<Int, Unit>()
    private val navPointsToTocEntries = BlockingThreadsafeMap(
        backingMap = metadata
            .initialNavPointsToTocEntries
            .mapKeys { it.key.toNavPointKey() }
            .toMutableMap()
            .asMutableMapWithBasics(),
    )

    // Caching is implemented here to optimize performance, as mapping chapters to blocks can be resource-intensive.
    // To conserve memory, we avoid storing the string representation of files in other layers,
    // retaining only the final parsed result in this cache.

    private val blocksCache = ChapterBlocksCache(
        maxCacheSize = CHAPTER_CACHE_SIZE,
        parseBlocksFromChapter = { chapterIndex ->
            val chapterContent = view.getChapters(indexes = listOf(chapterIndex)).single().getContent()
            val chapterAsWebpage = chapterContent.toWebPage()
            val blocks = if (shouldUseRichBlocksParsing) {
                getRichBlocksFromWebPage(chapterAsWebpage)
            } else {
                getBlocksFromWebPage(chapterAsWebpage)
            }.flatMap {
                it.chunkIfItsSingleParagraphWithLargeTextContent(TEXT_LENGTH_LIMIT_IN_SINGLE_PARAGRAPH)
            }.toList()

            val contentElementReference = ContentElementReferenceUtils.fromPath(path = listOf(chapterIndex))

            val resultBlocks = StandardBlocks(
                blocks = blocks.toList().toTypedArray(),
                start = blocks.firstOrNull()?.start ?: contentElementReference.start,
                end = blocks.lastOrNull()?.end ?: contentElementReference.end,
            )

            if (parsedChapterIndexes[chapterIndex] == null) {
                parsedChapterIndexes.put(chapterIndex, Unit)
                updateTocEntriesWithParsedChapter(chapterContent = chapterContent)
                parsedChapterContentFlow.emit(chapterIndex to resultBlocks)
            }

            resultBlocks
        },
    )

    /**
     * Important note: We need always to have all variables instantiated before calling them in the init block,
     * Otherwise the app crash on Android. See similar issue [https://linear.app/speechify-inc/issue/CXP-4648/fix-attempt-to-invoke-interface-method-javalangobject-hrecollecthrf]
     */
    init {
        loadStartOfMainContent()
    }

    override fun getBlocksAroundCursor(
        cursor: ContentCursor,
        callback: Callback<StandardBlocks>,
    ) = callback.fromCo {
        coGetBlocksAroundCursor(cursor = cursor).successfully()
    }

    override fun getBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
        callback: Callback<StandardBlocks>,
    ) = callback.fromCo {
        coGetBlocksBetweenCursors(start = start, end = end).successfully()
    }

    override fun destroy() {
        super.destroy()
        view.destroy()
        blocksCache.destroy()
        navPointsToTocEntries.clear()
        parsedChapterIndexes.clear()
        fetchedTocChapters.clear()
    }

    suspend fun getBlocksForChapter(chapterIndex: Int) = blocksCache.getBlocksForChapter(chapterIndex = chapterIndex)

    suspend fun getContentCursorForNavPoint(epubNavPoint: EpubNavPoint): ContentCursor? {
        // Case 1: No fragment - create new chapter reference
        if (epubNavPoint.fragment == null) {
            val cursor = ContentElementReference.fromPath(listOf(epubNavPoint.chapterIndex)).start
            return cursor
        }

        // Case 2: Try to get the existing if there's one
        navPointsToTocEntries[epubNavPoint.toNavPointKey()]?.entry?.start?.resolvedCursorOrNull?.let { cursor ->
            return cursor
        }

        // Case 3: Load blocks and try again
        blocksCache.getBlocksForChapter(chapterIndex = epubNavPoint.chapterIndex)
        val cursor = navPointsToTocEntries[epubNavPoint.toNavPointKey()]?.entry?.start?.resolvedCursorOrNull
        return cursor
    }

    private suspend fun coGetBlocksAroundCursor(cursor: ContentCursor) = chunkBlocksAroundCursor(
        cursor = cursor,
        standardBlocksOfChapters = getStandardBlocksOfChapters(start = cursor, end = cursor),
    )

    private suspend fun coGetBlocksBetweenCursors(start: ContentCursor, end: ContentCursor) =
        filterBlocksBetweenCursors(
            start = start,
            end = end,
            standardBlocksOfChapters = getStandardBlocksOfChapters(start = start, end = end),
        )

    private suspend fun getStandardBlocksOfChapters(
        start: ContentCursor,
        end: ContentCursor,
    ): StandardBlocksOfChapters {
        val startIndex = view.getChapterIndex(cursor = start)
        val endIndex = view.getChapterIndex(cursor = end)

        val blocks = (startIndex..endIndex)
            .map { chapterIndex ->
                scope.async {
                    blocksCache
                        .getBlocksForChapter(chapterIndex = chapterIndex)
                        .also { preloadTableOfContents(chapterIndex = chapterIndex) }
                }
            }
            .awaitAll()

        if (blocks.isEmpty()) {
            throw IllegalStateException(
                "EpubV2StandardView.getChapterBlocksBetweenCursors got no results for non-empty input",
            )
        }

        val resultStartChapterIndex = (startIndex - 1).coerceAtLeast(minimumValue = 0)
        val resultStart = ContentElementReferenceUtils.fromPath(path = listOf(resultStartChapterIndex)).let {
            if (startIndex == 0) it.start else it.end
        }

        val resultEndPageIndex = (endIndex + 1).coerceAtMost(maximumValue = metadata.numberOfChapters - 1)
        val resultEnd = ContentElementReferenceUtils.fromPath(path = listOf(resultEndPageIndex)).let {
            if (endIndex >= resultEndPageIndex) view.end else it.start
        }

        return StandardBlocksOfChapters(
            startChapterIndex = startIndex,
            endChapterIndex = endIndex,
            standardBlocks = StandardBlocks(
                blocks = blocks.flatMap { it.blocks.toList() }.toTypedArray(),
                start = resultStart,
                end = resultEnd,
            ),
        )
    }

    private fun chunkBlocksAroundCursor(
        cursor: ContentCursor,
        standardBlocksOfChapters: StandardBlocksOfChapters,
    ): StandardBlocks {
        val allBlocks = standardBlocksOfChapters.standardBlocks.blocks

        if (allBlocks.size <= CHUNK_SIZE * 4) {
            return standardBlocksOfChapters.standardBlocks
        }

        if (cursor is ContentElementBoundary && cursor.element.path.isEmpty()) {
            when (cursor.boundary) {
                is ContentBoundary.START -> {
                    // Get the next element in the list
                    val end = allBlocks.getOrNull(CHUNK_SIZE)?.start
                    StandardBlocks(
                        blocks = allBlocks.take(CHUNK_SIZE).toTypedArray(),
                        start = standardBlocksOfChapters.standardBlocks.start,
                        // Get the next in allBlocks if exists, otherwise we're at the end of the blocks
                        end = end ?: standardBlocksOfChapters.standardBlocks.end,
                    )
                }

                is ContentBoundary.END -> {
                    // Get the previous element in the list
                    val start = allBlocks.reversed().getOrNull(CHUNK_SIZE)?.end
                    StandardBlocks(
                        blocks = allBlocks.takeLast(CHUNK_SIZE).toTypedArray(),
                        // Get the previous in allBlocks if exists, otherwise we're at the start of the blocks
                        start = start ?: standardBlocksOfChapters.standardBlocks.end,
                        end = standardBlocksOfChapters.standardBlocks.end,
                    )
                }
            }
        }

        val containingBlockIndex = allBlocks.indexOfFirstOrNull {
            // This block search follows the existing logic from `StandardBlockChunking`,
            // which is designed for cases where the cursor's path is clearly defined.
            // The block is identified if the cursor lies within the block's start and end boundaries.
            it.start.isBeforeOrAt(cursor) && it.end.isAfterOrAt(cursor)
        } ?: allBlocks.indexOfFirstOrNull {
            // In some cases, such as with a Table of Contents (ToC) entry, the cursor provides a less precise
            // reference, pointing to a broader section of the chapter rather than a specific heading or paragraph
            // (e.g., the start of the file). In these instances, we need to locate the first block in the
            // tree that aligns with the cursor's position.
            //
            // cursor.path = [0, 1, 2]
            // block.start.path = [0, 1, 2, 5, 43]
            it.start.isAfterOrAt(cursor)
        } ?: return standardBlocksOfChapters.standardBlocks

        // Return window of blocks around cursor
        val startIndex = maxOf(0, containingBlockIndex - CHUNK_SIZE)
        val start = allBlocks.getOrNull(startIndex - 1)?.end

        val endIndex = minOf(allBlocks.size, containingBlockIndex + CHUNK_SIZE)
        val end = allBlocks.getOrNull(endIndex)?.start

        val chunkedBlocks = allBlocks.toList().subList(startIndex, endIndex)

        return StandardBlocks(
            blocks = chunkedBlocks.toTypedArray(),
            start = start ?: standardBlocksOfChapters.standardBlocks.start,
            end = end ?: standardBlocksOfChapters.standardBlocks.end,
        )
    }

    private fun filterBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
        standardBlocksOfChapters: StandardBlocksOfChapters,
    ): StandardBlocks {
        val allBlocks = standardBlocksOfChapters.standardBlocks.blocks

        val filteredBlocks = allBlocks
            .filter { !it.end.isBefore(start) && !it.start.isAfter(end) }
            .toTypedArray()

        val firstFilteredIndex = allBlocks.indexOfFirstOrNull { it == filteredBlocks.firstOrNull() }
        val lastFilteredIndex = allBlocks.indexOfLastOrNull { it == filteredBlocks.lastOrNull() }

        if (firstFilteredIndex == null || lastFilteredIndex == null) {
            return standardBlocksOfChapters.standardBlocks
        }

        val resultStart = allBlocks.getOrNull(firstFilteredIndex - 1)?.end
        val resultEnd = allBlocks.getOrNull(lastFilteredIndex + 1)?.start

        return StandardBlocks(
            blocks = filteredBlocks,
            start = resultStart ?: standardBlocksOfChapters.standardBlocks.start,
            end = resultEnd ?: standardBlocksOfChapters.standardBlocks.end,
        )
    }

    private fun findNearestTocEntryChapterIndex(chapterIndex: Int) = metadata
        .initialNavPointsToTocEntries
        .values
        .map { it.chapterIndex }
        .distinct()
        .filter { it <= chapterIndex }
        .maxOrNull()

    private fun preloadTableOfContents(chapterIndex: Int) {
        val nearestTocEntryChapterIndex = findNearestTocEntryChapterIndex(chapterIndex)
        if (nearestTocEntryChapterIndex != null && fetchedTocChapters[nearestTocEntryChapterIndex] == null) {
            fetchedTocChapters.put(nearestTocEntryChapterIndex, Unit)
            scope.launch {
                getBlocksForChapter(chapterIndex = nearestTocEntryChapterIndex)
            }
        }
    }

    private fun updateTocEntriesWithParsedChapter(chapterContent: EpubChapterContent) {
        navPointsToTocEntries.putAll(chapterContent.navPointsToTocEntries.mapKeys { it.key.toNavPointKey() })
        tableOfContentsFlow.value = navPointsToTocEntries.toTableOfContents()
    }

    private fun loadStartOfMainContent() {
        scope.launch {
            val startCursor = view.getMainContentStartCursor(
                remoteStartMainOfContent = staticStartOfMainContent,
            )
            startOfMainContentFlow.value = if (startCursor != null) {
                StartOfMainContent.Ready(cursor = startCursor)
            } else {
                StartOfMainContent.NotAvailable
            }
        }
    }

    private companion object {
        const val CHAPTER_CACHE_SIZE = 5
        const val TEXT_LENGTH_LIMIT_IN_SINGLE_PARAGRAPH = 3000
        const val CHUNK_SIZE = 25
    }
}

private class ChapterBlocksCache(
    maxCacheSize: Int,
    private val parseBlocksFromChapter: suspend (Int) -> StandardBlocks,
) : Destructible {
    private val cache = AtomicCircularFixedList<CacheEntry>(maxSize = maxCacheSize)

    private val cacheDtor = InMemoryCacheManager.register(
        destructor = { cache.clear() },
    )

    suspend fun getBlocksForChapter(chapterIndex: Int): StandardBlocks {
        // Try to find in cache first
        cache.find { it.chapterIndex == chapterIndex }?.let { return it.blocks }

        // Not found in cache, need to parse
        val blocks = parseBlocksFromChapter(chapterIndex)
        cache.add(CacheEntry(chapterIndex, blocks))
        return blocks
    }

    override fun destroy() {
        launchTask {
            cacheDtor()
        }
    }

    private data class CacheEntry(
        val chapterIndex: Int,
        val blocks: StandardBlocks,
    )
}

private fun EpubChapterContent.toWebPage() = WebPage(
    root = root,
    sourceUrl = null,
)

internal val TableOfContents.Entry.Start.resolvedCursorOrNull
    get() = when (this) {
        is TableOfContents.Entry.Start.Resolved -> cursor
        is TableOfContents.Entry.Start.Unresolved.EpubChapter -> null
    }

private fun BlockingThreadsafeMap<NavPointKey, OrderedTocEntry>.toTableOfContents() =
    TableOfContents(entries = entries.toMap().toTocEntries())

internal fun Map<NavPointKey, OrderedTocEntry>.toTableOfContents() = TableOfContents(
    entries = this.toTocEntries(),
)

internal data class NavPointKey(
    val chapterIndex: Int,
    val fragment: String?,
)

internal fun EpubNavPoint.toNavPointKey() = NavPointKey(
    chapterIndex = chapterIndex,
    fragment = fragment,
)

private fun Map<NavPointKey, OrderedTocEntry>.toTocEntries() = entries.sortedWith(
    compareBy<Map.Entry<NavPointKey, OrderedTocEntry>> { it.value.chapterIndex }
        .thenBy { it.value.rank },
)
    .map { it.value.entry }

private data class StandardBlocksOfChapters(
    val startChapterIndex: Int,
    val endChapterIndex: Int,
    val standardBlocks: StandardBlocks,
)
