package com.speechify.client.internal.util.retry
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.coroutines.delay

/**
 * Tries to execute a suspending block of code with a specified number of attempts and a configurable
 * exponential backoff.
 *
 * Note: Some tests for this function are ignored due to their flaky nature.
 * For more details, see [RetryWithExponentialBackoffTests].
 */
suspend fun <T>
retryWithExponentialBackoff(
    maxAttempts: Int = 3,
    initialDelayMillis: Long = 1000,
    maxDelayMillis: Long = 10000,
    factor: Double = 2.0,
    blockName: String? = null,
    block: suspend () -> Result<T>,
): Result<T> {
    var currentDelay = initialDelayMillis
    repeat(maxAttempts - 1) { attempt ->
        when (val result = block()) {
            is Result.Success -> return result
            is Result.Failure -> {
                Log.e(
                    diagnosticEvent = DiagnosticEvent(
                        properties = SdkBoundaryMap.fromMap(
                            mapOf("retryAttempt" to attempt, "blockName" to (blockName ?: "lambda")),
                        ),
                        error = ErrorInfoForDiagnostics(result.errorNative),
                        sourceAreaId = "retryWithExponentialBackoff",
                    ),
                )
                delay(currentDelay)
                currentDelay = (currentDelay * factor).toLong().coerceAtMost(maxDelayMillis)
            }
        }
    }
    // by placing the last attempt outside the loop we avoid unnecessary delays that would occur
    // if the last attempt were part of the loop
    return block()
}
