package com.speechify.client.reader.core

import com.benasher44.uuid.uuid4
import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentCursorComparator
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.slice
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksBetweenCursors
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.services.library.models.UserHighlight.Style
import com.speechify.client.api.services.library.models.UserHighlight.Style.ColorToken
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.helpers.content.standard.book.BookStandardView
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2
import com.speechify.client.internal.services.highlight.UserHighlightsService
import com.speechify.client.internal.util.extensions.collections.flows.onEachPairInstance
import com.speechify.client.reader.core.utils.processHighlights
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

/**
 * This helper is the public interface to managing the "User Highlights" for a document. These are sections of content
 * that the user has specified and captured, with optional style and note information.
 *
 * The state of this helper is a optimistically updated view of all highlights for this document,
 * in the order of their appearance.
 */
@JsExport
class UserHighlightsHelper internal constructor(
    scope: CoroutineScope,
    contentImporterFlow: Flow<ContentImporterState?>,
    userHighlightsService: UserHighlightsService,
    selection: Flow<SelectionState>,
    private val standardView: StandardView,
    editBookFlow: Flow<EditBookState>,
) : Helper<UserHighlightsList>(scope) {

    private val optimisticUpdates = MutableStateFlow<Map<String, OptimisticUpdate>>(emptyMap())

    @OptIn(ExperimentalCoroutinesApi::class)
    override val stateFlow: StateFlow<UserHighlightsList> = contentImporterFlow
        // Only process when content is imported and available in library
        .filterIsInstance<ContentImporterState.ImportedToLibrary>()
        .flatMapLatest { state ->
            // Combine three flows:
            // 1. Edit state for page visibility
            // 2. Stable highlights from the service
            // 3. Optimistic updates for immediate feedback
            combine(
                editBookFlow,
                userHighlightsService.observeHighlights(state.uri.id),
                optimisticUpdates,
            ) { editState, stableHighlights, updates ->
                val (processedHighlights, updatesToRemove, pendingUpdateIds) =
                    processHighlights(stableHighlights, updates)

                // If we found updates that are now in stable state:
                // 1. Clean them up from optimistic updates
                // 2. Return null to skip this emission (prevents double updates)
                if (updatesToRemove.isNotEmpty()) {
                    optimisticUpdates.value = updates.filterValues { it !in updatesToRemove }
                    null
                } else {
                    Triple(
                        editState,
                        processedHighlights,
                        pendingUpdateIds,
                    )
                }
            }.filterNotNull()
                .map { (editState, processedHighlights, pendingUpdateIds) ->
                    UserHighlightsList(
                        Array(processedHighlights.size) { index ->
                            createHighlightItem(processedHighlights[index], editState, pendingUpdateIds)
                        },
                    )
                }
        }.stateInHelper(initialValue = UserHighlightsList.empty())

    override val initialState = stateFlow.value

    private val selectionState = selection.stateInHelper(initialValue = SelectionState.empty())

    init {
        contentImporterFlow
            .filterNotNull()
            .filterIsInstance<ContentImporterState.ImportedToLibrary>()
            .combine(commands) { importState, command ->
                importState.uri.id to command
            }
            .onEachPairInstance<SpeechifyContentId, HighlightsHelperCommand.UpdateColor> { itemId, command ->
                // launching in separate coroutine to unblock the subsequent commands because of any
                // network delay in processing the request
                launchInHelper {
                    val highlightToUpdate =
                        state.items.firstOrNull { it.highlight.id == command.highlightId }?.highlight
                    if (highlightToUpdate == null) {
                        Log.e("No highlight found for given id", sourceAreaId = "UserHighlightsHelper.UpdateColor")
                        return@launchInHelper
                    }
                    val updatedHighlight = highlightToUpdate.copy(
                        style = Style(command.colorToken),
                    )
                    optimisticUpdates.update { current ->
                        current + (updatedHighlight.id to OptimisticUpdate.Update(updatedHighlight))
                    }

                    userHighlightsService.updateHighlight(itemId, updatedHighlight).onFailure {
                        optimisticUpdates.update { current ->
                            current.filterValues { update ->
                                !(
                                    update is OptimisticUpdate.Update &&
                                        update.highlight === updatedHighlight
                                    )
                            }
                        }
                    }
                }
            }
            .onEachPairInstance<SpeechifyContentId, HighlightsHelperCommand.UpdateNote> { itemId, command ->
                launchInHelper {
                    val highlightToUpdate =
                        state.items.firstOrNull { it.highlight.id == command.highlightId }?.highlight
                    if (highlightToUpdate == null) {
                        Log.e("No highlight found for given id", sourceAreaId = "UserHighlightsHelper.UpdateNote")
                        return@launchInHelper
                    }
                    val updatedHighlight = highlightToUpdate.copy(note = command.note)
                    optimisticUpdates.update { current ->
                        current + (updatedHighlight.id to OptimisticUpdate.Update(updatedHighlight))
                    }
                    userHighlightsService.updateHighlight(itemId, updatedHighlight).onFailure {
                        optimisticUpdates.update { current ->
                            current.filterValues { update ->
                                !(
                                    update is OptimisticUpdate.Update &&
                                        update.highlight === updatedHighlight
                                    )
                            }
                        }
                    }
                }
            }
            .onEachPairInstance<SpeechifyContentId, HighlightsHelperCommand.DeleteHighlight> { itemId, command ->
                launchInHelper {
                    val highlightToDelete =
                        state.items.firstOrNull { it.highlight.id == command.highlightId }?.highlight
                    if (highlightToDelete == null) {
                        Log.e("No highlight found for given id", sourceAreaId = "UserHighlightsHelper.DeleteHighlight")
                        return@launchInHelper
                    }
                    optimisticUpdates.update { current ->
                        current + (highlightToDelete.id to OptimisticUpdate.Delete(highlightToDelete))
                    }

                    userHighlightsService.deleteHighlight(itemId, highlightToDelete).onFailure {
                        optimisticUpdates.update { current ->
                            current.filterValues { update ->
                                !(
                                    update is OptimisticUpdate.Delete &&
                                        update.highlight === highlightToDelete
                                    )
                            }
                        }
                    }
                }
            }
            .onEachPairInstance<SpeechifyContentId, HighlightsHelperCommand.CreateHighlightFromSelection>
            { itemId, command ->
                launchInHelper {
                    val currentSelection = selectionState.value.selection ?: return@launchInHelper
                    val style = Style(command.colorToken)
                    val highlight = makeHighlightWithSelection(currentSelection, style, command.note)
                    optimisticUpdates.update { current ->
                        current + (highlight.id to OptimisticUpdate.Create(highlight))
                    }
                    userHighlightsService.createHighlight(itemId, highlight).onFailure {
                        optimisticUpdates.update { current ->
                            current.filterValues { update ->
                                !(
                                    update is OptimisticUpdate.Create &&
                                        update.highlight === highlight
                                    )
                            }
                        }
                    }
                }
            }
            .onEachPairInstance<SpeechifyContentId, HighlightsHelperCommand.MergeHighlightsFromSelection>
            { itemId, command ->
                launchInHelper {
                    val currentSelection = selectionState.value.selection ?: return@launchInHelper
                    val mergedHighlight = getMergedHighlightWithSelection(
                        currentSelection,
                        command.highlightsToMerge.toList(),
                        Style(command.colorToken),
                        command.note,
                    )

                    optimisticUpdates.update { current ->
                        val newMap = current.toMutableMap()
                        newMap[mergedHighlight.id] = OptimisticUpdate.Merge(
                            mergedHighlight,
                            command.highlightsToMerge.toList(),
                        )
                        command.highlightsToMerge.forEach {
                            newMap.remove(it.id)
                        }
                        newMap
                    }

                    userHighlightsService.mergeHighlights(
                        itemId,
                        mergedHighlight,
                        command.highlightsToMerge.toList(),
                    ).onFailure {
                        optimisticUpdates.update { current ->
                            current.filterValues { update ->
                                !(
                                    update is OptimisticUpdate.Merge &&
                                        update.highlight === mergedHighlight
                                    )
                            }
                        }
                    }
                }
            }
            .launchInHelper()
    }

    private fun createHighlightItem(
        highlight: UserHighlight,
        editState: EditBookState,
        pendingUpdateIds: Set<String>,
    ): UserHighlightsList.Item = UserHighlightsList.Item(
        dispatch = dispatch,
        highlight = highlight.copy(
            isPageHidden = when (editState) {
                is EditBookState.Ready ->
                    highlight.pageIndex?.let { pageIndex ->
                        editState.pagesToEdit.getOrNull(pageIndex)?.isHidden ?: false
                    } ?: false

                else -> false
            },
        ),
        syncStatus = if (highlight.id in pendingUpdateIds) {
            UserHighlightsList.Item.SyncStatus.IN_QUEUE
        } else {
            UserHighlightsList.Item.SyncStatus.SYNCED
        },
    )

    /**
     * find the big enough bounding box that covers the current selection, and overlapping highlights. And create a new
     * [UserHighlight] to return
     *
     */
    private suspend fun getMergedHighlightWithSelection(
        currentSelection: Selection,
        highlightsToMerge: List<UserHighlight>,
        style: Style,
        note: String?,
    ): UserHighlight {
        val startCursor =
            (highlightsToMerge.map { it.robustStart.hack.cursor } + currentSelection.start).sortedWith { item1, item2 ->
                ContentCursorComparator.compare(
                    item1,
                    item2,
                )
            }.first()

        val endCursor =
            (highlightsToMerge.map { it.robustEnd.hack.cursor } + currentSelection.end).sortedWith { item1, item2 ->
                ContentCursorComparator.compare(
                    item1,
                    item2,
                )
            }.last()

        return UserHighlight(
            id = uuid4().toString(),
            text = getContentBetween(startCursor, endCursor),
            robustStart = RobustLocation.fromHack(SerialLocation(startCursor)),
            robustEnd = RobustLocation.fromHack(SerialLocation(endCursor)),
            style = style,
            note = note,
            pageIndex = pageIndex(startCursor),
        )
    }

    private suspend fun makeHighlightWithSelection(
        currentSelection: Selection,
        style: Style,
        note: String?,
    ): UserHighlight {
        return UserHighlight(
            id = uuid4().toString(),
            text = getContentBetween(currentSelection.start, currentSelection.end),
            robustStart = RobustLocation.fromHack(currentSelection.startLocation),
            robustEnd = RobustLocation.fromHack(currentSelection.endLocation),
            style = style,
            note = note,
            pageIndex = pageIndex(currentSelection.start),
        )
    }

    private suspend fun getContentBetween(start: ContentCursor, end: ContentCursor): String {
        val blocks = standardView.coGetBlocksBetweenCursors(start, end).orReturn { return "" }
        val textList = blocks.blocks
            .filter {
                it.hasNontrivialIntersectionWith(start, end)
            }
            .map { it.text.slice(start, end) }.toList()

        if (textList.isEmpty()) {
            return ""
        }
        return ContentTextUtils.join(
            blocks.blocks
                .filter {
                    it.hasNontrivialIntersectionWith(start, end)
                }
                .map { it.text.slice(start, end) }.toList(),
            "\n",
        ).text
    }

    /**
     * Check if the [StandardView] has the page index,
     * and if yes return the index otherwise returns null
     */
    private fun pageIndex(contentCursor: ContentCursor): Int? {
        return when (standardView) {
            is BookStandardView -> {
                when (standardView.view) {
                    is EditingBookView -> {
                        standardView.view.originalBookView.getPageIndex(contentCursor)
                    }

                    else -> standardView.view.getPageIndex(contentCursor)
                }
            }

            is EpubStandardViewV2 -> standardView.view.getChapterIndex(contentCursor)
            else -> null
        }
    }

    fun createHighlightFromCurrentSelection(colorToken: ColorToken, note: String?) {
        dispatch(
            HighlightsHelperCommand.CreateHighlightFromSelection(
                colorToken = colorToken,
                note,
            ),
        )
    }

    fun mergeHighlightsFromCurrentSelection(
        colorToken: ColorToken,
        note: String?,
        highlightsToMerge: Array<UserHighlight>,
    ) {
        dispatch(
            HighlightsHelperCommand.MergeHighlightsFromSelection(
                colorToken = colorToken,
                note,
                highlightsToMerge,
            ),
        )
    }
}

internal sealed class OptimisticUpdate {
    abstract val highlight: UserHighlight

    data class Create(
        override val highlight: UserHighlight,
    ) : OptimisticUpdate()

    data class Update(
        override val highlight: UserHighlight,
    ) : OptimisticUpdate()

    data class Delete(
        override val highlight: UserHighlight,
    ) : OptimisticUpdate()

    data class Merge(
        override val highlight: UserHighlight,
        val originalHighlights: List<UserHighlight>,
    ) : OptimisticUpdate()
}

@JsExport
data class UserHighlightsList(
    val items: Array<Item>,
) {

    class Item internal constructor(
        internal val dispatch: CommandDispatch,
        val highlight: UserHighlight,
        val syncStatus: SyncStatus,
    ) {
        fun delete() {
            dispatch(HighlightsHelperCommand.DeleteHighlight(highlight.id))
        }

        fun updateColor(colorToken: ColorToken) {
            dispatch(
                HighlightsHelperCommand.UpdateColor(
                    highlight.id,
                    colorToken = colorToken,
                ),
            )
        }

        fun updateNote(note: String?) {
            dispatch(
                HighlightsHelperCommand.UpdateNote(
                    highlight.id,
                    note = note,
                ),
            )
        }

        fun goTo() {
            dispatch(
                NavigationCommand.NavigateTo(
                    intent = NavigationIntent.GoToUserHighlight(highlight),
                ),
            )
        }

        enum class SyncStatus {
            IN_QUEUE, // Optimistic update pending
            SYNCED, // Confirmed with server
        }
    }

    companion object {
        fun empty(): UserHighlightsList {
            return UserHighlightsList(items = emptyArray())
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as UserHighlightsList

        return items.contentEquals(other.items)
    }

    override fun hashCode(): Int {
        return items.contentHashCode()
    }
}

internal sealed class HighlightsHelperCommand {
    data class CreateHighlightFromSelection(
        val colorToken: ColorToken,
        val note: String?,
    ) : HighlightsHelperCommand()

    data class MergeHighlightsFromSelection(
        val colorToken: ColorToken,
        val note: String?,
        val highlightsToMerge: Array<UserHighlight>,
    ) : HighlightsHelperCommand() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as MergeHighlightsFromSelection

            if (colorToken != other.colorToken) return false
            if (note != other.note) return false
            if (!highlightsToMerge.contentEquals(other.highlightsToMerge)) return false

            return true
        }

        override fun hashCode(): Int {
            var result = colorToken.hashCode()
            result = 31 * result + (note?.hashCode() ?: 0)
            result = 31 * result + highlightsToMerge.contentHashCode()
            return result
        }
    }

    data class UpdateColor(
        val highlightId: String,
        val colorToken: ColorToken,
    ) : HighlightsHelperCommand()

    data class UpdateNote(
        val highlightId: String,
        val note: String?,
    ) : HighlightsHelperCommand()

    data class DeleteHighlight(val highlightId: String) : HighlightsHelperCommand()
}
