package com.speechify.client.reader.core.utils

import com.speechify.client.api.content.ContentCursorComparator
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.reader.core.OptimisticUpdate

/** Process highlights:
 * - Identify updates that need to be removed (already in stable)
 * - Track pending update IDs for sync status
 * - process the stable highlights with optimistic updates
 */
internal fun processHighlights(
    stableHighlights: List<UserHighlight>,
    updates: Map<String, OptimisticUpdate>,
): Triple<List<UserHighlight>, Set<OptimisticUpdate>, Set<String>> {
    val updatesToRemove = updates.values.filter { update ->
        findUpdatesToRemove(stableHighlights, update)
    }.toSet()

    // Get remaining valid optimistic updates
    val remainingUpdates = updates.values.filterNot { it in updatesToRemove }

    // Build set of all IDs with pending updates
    val pendingUpdateIds = getPendingUpdateIds(remainingUpdates)

    // merge and sort valid highlights
    val processedHighlights = mergeValidOptimisticAndStableHighlights(
        remainingUpdates,
        filterPendingHighlightsForMerging(stableHighlights, remainingUpdates),
        pendingUpdateIds,
    )

    return Triple(processedHighlights, updatesToRemove, pendingUpdateIds)
}

/**
 * checks if any highlight in stable data is
 * present in pending merge request. if yes we should filter those out, so
 * final list does have only the pending merge highlight and not other merging highlights
 */
private fun filterPendingHighlightsForMerging(
    processedHighlights: List<UserHighlight>,
    updates: List<OptimisticUpdate>,
) = processedHighlights.filter { highlight ->
    updates.none { update ->
        update is OptimisticUpdate.Merge &&
            highlight.id in update.originalHighlights.map { it.id }
    }
}

/**
 *  Find updates that are now reflected in stable state
 *  and should be removed from optimistic updates
 */
private fun findUpdatesToRemove(
    stableHighlights: List<UserHighlight>,
    update: OptimisticUpdate,
): Boolean = when (update) {
    is OptimisticUpdate.Create ->
        stableHighlights.any { it.id == update.highlight.id }

    is OptimisticUpdate.Update ->
        stableHighlights.any { stable ->
            stable.id == update.highlight.id &&
                stable.style == update.highlight.style &&
                stable.note == update.highlight.note
        }

    is OptimisticUpdate.Delete ->
        stableHighlights.none { it.id == update.highlight.id }

    is OptimisticUpdate.Merge -> {
        val originalIdsRemoved = update.originalHighlights.all { original ->
            stableHighlights.none { it.id == original.id }
        }
        val mergedHighlightExists = stableHighlights.any { it.id == update.highlight.id }
        originalIdsRemoved && mergedHighlightExists
    }
}

/**
 * @return set of pending highlight ids, for which request is in progress
 */
private fun getPendingUpdateIds(
    updates: List<OptimisticUpdate>,
): Set<String> = buildSet {
    updates.forEach { update ->
        when (update) {
            is OptimisticUpdate.Merge -> {
                add(update.highlight.id)
                update.originalHighlights.forEach { add(it.id) }
            }

            else -> {
                add(update.highlight.id)
            }
        }
    }
}

private fun mergeValidOptimisticAndStableHighlights(
    remainingUpdates: Collection<OptimisticUpdate>,
    stableHighlights: List<UserHighlight>,
    pendingUpdateIds: Set<String>,
): List<UserHighlight> = buildList {
    // First add highlights from remaining updates
    remainingUpdates.forEach { update ->
        when (update) {
            is OptimisticUpdate.Create,
            is OptimisticUpdate.Update,
            is OptimisticUpdate.Merge,
            -> add(update.highlight)

            is OptimisticUpdate.Delete -> { /* Skip deleted highlights */
            }
        }
    }

    // Then add unmodified stable highlights
    stableHighlights.forEach { stable ->
        if (stable.id !in pendingUpdateIds) {
            add(stable)
        }
    }
}.sortedWith { a, b ->
    ContentCursorComparator.compare(
        a.robustStart.hack.cursor,
        b.robustStart.hack.cursor,
    )
}
