package com.speechify.client.internal.services.importing.models

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.adapters.ocr.OCRableImage
import com.speechify.client.api.adapters.ocr.OcrSource
import com.speechify.client.api.adapters.ocr.toOCRableImageWithCompletingTextResultJob
import com.speechify.client.api.adapters.ocr.toOcrableImage
import com.speechify.client.api.services.scannedbook.models.LazyOCRFiles
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fallbackNullValueToFailure
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.db.DbOcrFile
import com.speechify.client.internal.services.db.DbScannedPageFile
import com.speechify.client.internal.services.db.toDbOcrFileWithNoOcrResult

internal class LazyOCRFilesFromDbOcrFile(
    private val initialScannedPages: List<DbScannedPageFile>,
    private val blobStorageAdapter: BlobStorageAdapter,
    private val ocrAdapter: OCRAdapter,
    /**
     * This function should return the [DbOcrFile] for the given index.
     * If the import is finished and there are no item requiring import, this function should return null.
     */
    private val obtainScannedPageForImportingItem: suspend (index: Int) -> DbOcrFile?,
) : LazyOCRFiles() {

    override val lastIndex: Int = initialScannedPages.size - 1

    override fun getOcrableImage(index: Int, callback: Callback<OCRableImage>) = callback.fromCo {
        val dbOcrFile = getDbOcrFileForIndex(index)
        if (dbOcrFile.ocrResult != null) {
            dbOcrFile.ocrResult.toOcrableImage().successfully()
        } else {
            val file = getFile(index)
            val ocrResult = ocrAdapter.runOCR(file, OcrSource.ScannedBookPage).fallbackNullValueToFailure {
                SDKError.OtherMessage("Didn't get OCR result for file ${dbOcrFile.localStorageKey.originalKey}")
            }.orThrow()
            ocrResult.toOCRableImageWithCompletingTextResultJob(ocrAdapter.ocrMaxConcurrencyGuard).successfully()
        }
    }

    override fun getFile(
        index: Int,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) = callback.fromCo {
        getFile(index).successfully()
    }

    private suspend fun getFile(index: Int):
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly> {
        val dbOcrFile = getDbOcrFileForIndex(index)
        return blobStorageAdapter.coGetBlob(dbOcrFile.localStorageKey)
            .fallbackNullValueToFailure {
                SDKError.ResourceNotFound(dbOcrFile.localStorageKey.originalKey, "Couldn't load file from blob storage")
            }.orThrow()
    }

    private suspend fun getDbOcrFileForIndex(index: Int): DbOcrFile =
        /**
         * Fall back to initial scanned pages if there are no items requiring import.
         * This could happen if the import is finished and as part of cleanup, we remove the pending import items from the DB.
         * But the reading bundle is still being used by consumers.
         *
         * TODO: Consider triggering the cleanup of the imported pending items from the DB before triggering the import on the next session.
         *  This will ensure that the reading bundle is not broken during the ongoing session.
         */
        obtainScannedPageForImportingItem(index)
            /**
             * Fall back to DbOcrFile with no OCR result ocrResult is not found in the DB.
             */
            ?: initialScannedPages[index].toDbOcrFileWithNoOcrResult()
}
