package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.sync.AtomicBool
import kotlinx.coroutines.Deferred

/**
 * A map that is only filled while getting values from it.
 * A good foundation for a very simple map-based cache.
 *
 * NOTE: All implementations must be thread-safe!
 */
internal interface MapFilledWhileAsyncGetting<K, V> :
    MutableMapInsertionOrFailureAsync<K, V> {
    suspend fun getOrPut(
        key: K,
        /**
         * The function is guaranteed not to execute if there is already a value for the key.
         */
        defaultValue: suspend () -> V,
    ): V

    override suspend fun add(
        key: K,
        value: suspend () -> V,
    ): V {
        val wasDefaultValueEvaluated = AtomicBool(false)
        val valueInMap = getOrPut(
            key = key,
            defaultValue = {
                wasDefaultValueEvaluated.set(true)
                value()
            },
        )

        if (wasDefaultValueEvaluated.get().not()) {
            throw IllegalStateException("Key '$key' already has a value")
        }

        return valueInMap
    }
}

/**
 * Groups methods useful for implementing a thread-safe cache whose values are not obtained 'while getting', like
 * [MapFilledWhileAsyncGetting], but by insertion.
 * See also [ThreadSafeMutableMapSyncOperations].
 */
internal interface ThreadSafeMutableMapAsyncOperations<K, V> :
    MapRetrievalIncludingPending<K, V>,
    MutableMapInsertionOrFailureAsync<K, V>,
    MutableMapRemovalIncludingPending<K, V>

internal interface MapFilledWhileAsyncGettingWithRetrieval<K, V> :
    MapFilledWhileAsyncGetting<K, V>,
    MapRetrieval<K, V>

internal interface MapRetrievalIncludingPending<K, V> {
    fun getIncludingPending(key: K): Deferred<V>?
}

internal interface MutableMapRemovalIncludingPending<K, V> {
    /**
     * Async version of [MutableMapRemoval.remove].
     * Returns a [Deferred] just like [MapRetrievalIncludingPending.getIncludingPending].
     */
    fun removeIncludingPending(key: K): Deferred<V>?
}

/**
 * Asynchronous version of [MutableMapInsertionOrFailure].
 */
internal interface MutableMapInsertionOrFailureAsync<K, V> {
    /**
     * Asynchronous version of [MutableMapInsertionOrFailure.add].
     * Throws an error if the key already exists.
     * This is especially helpful for code that uses the map for its concurrency-control/thread-safety, e.g. when it's
     * required to never lose a value.
     * Equivalent of .NET's [Dictionary.Add](https://learn.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.add?view=net-7.0#exceptions)
     * (seem it [doesn't exist in Kotlin](https://kotlinlang.org/docs/map-operations.html))
     */
    suspend fun add(
        key: K,
        /**
         * The function is guaranteed not to execute if there is already a value for the key.
         */
        value: suspend () -> V,
    ): V
}

internal interface MapFilledWhileAsyncGettingWithRetrievalIncludingPending<K, V> :
    MapFilledWhileAsyncGetting<K, V>,
    MapRetrievalIncludingPending<K, V>

/**
 * A map that is only filled while getting values from it.
 * A good foundation for a very simple map-based cache.
 *
 * NOTE: All implementations must be thread-safe!
 *
 * An async-version of [MapFilledWhileGettingWithMulti].
 */
internal interface MapFilledWhileAsyncGettingWithMulti<K, V> :
    MapFilledWhileAsyncGetting<K, V> {
    suspend fun getOrPutMulti(
        keys: List<K>,
        produceMissingValues: suspend (keysOfMissingEntries: List<K>) -> List<V>,
    ): List<V>

    override suspend fun getOrPut(key: K, defaultValue: suspend () -> V): V =
        getOrPutMulti(listOf(key)) { listOf(defaultValue()) }.single()
}

/**
 * A good foundation for a simple map-based cache that also needs clearing functionality.
 *
 * NOTE: All implementations must be thread-safe!
 */
internal interface MapFilledWhileAsyncGettingWithMultiAndClear<K, V> :
    MapFilledWhileAsyncGettingWithMulti<K, V> {
    /**
     * Returns the last state of the map before clearing.
     */
    fun clear(): List<Pair<K, Deferred<V>>>
}

/**
 * A simple device to disable cache where it is implemented as [MapFilledWhileAsyncGettingWithClear] - just make
 * the cache-usage code dependent on the interface implemented hereby, and use an instance of this class instead of the
 * cache.
 */
internal class NeverCachingDummyMapWithClear<K, V> : MapFilledWhileAsyncGettingWithMultiAndClear<K, V> {
    override suspend fun getOrPutMulti(
        keys: List<K>,
        produceMissingValues: suspend (keysOfMissingEntries: List<K>) -> List<V>,
    ): List<V> =
        produceMissingValues(keys)

    override fun clear(): List<Pair<K, Deferred<V>>> =
        emptyList()
}
