package com.speechify.client.api.services.library.models

import kotlin.js.JsExport
import kotlin.js.JsName

private const val LIBRARY_ROOT_FOLDER_ID = "LIBRARY_ROOT_FOLDER_ID"

@JsExport
object FolderReferenceFactory {
    @JsName("fromId")
    fun fromId(id: String) = FolderReference.fromId(id)
}

@Suppress("DataClassPrivateConstructor")
@JsExport
@kotlinx.serialization.Serializable
data class FolderReference private constructor(internal val id: String?) {
    companion object {
        internal val Root = FolderReference(null)

        fun fromId(id: String) = when (id) {
            // A possible bug in web caused some mvoed items to have an empty parent folder id
            "" -> FolderReference(null)
            LIBRARY_ROOT_FOLDER_ID -> FolderReference(null)
            else -> FolderReference(id)
        }
    }

    fun asRaw(): String = when (id) {
        null -> LIBRARY_ROOT_FOLDER_ID
        else -> id
    }
}

@JsExport
object FolderQueryOnlyFactory {
    @JsName("fromId")
    fun fromId(id: String) = FolderQuery.fromId(id)

    @JsName("fromReference")
    fun fromReference(ref: FolderReference) = FolderQuery.fromReference(ref)
}

/**
 * A way to reference what folders to access in a give query
 */
@JsExport
sealed class FolderQuery {
    /**
     * All folders
     */
    object All : FolderQuery()

    /**
     * A single folder
     */
    @Suppress("DataClassPrivateConstructor")
    data class Only internal constructor(internal val ref: FolderReference) : FolderQuery()

    companion object {
        internal val Root = Only(FolderReference.Root)

        fun fromId(id: String) = Only(FolderReference.fromId(id))
        fun fromReference(ref: FolderReference) = Only(ref)
    }

    override fun toString() = this::class.simpleName ?: this::class.toString()
}
