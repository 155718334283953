@file:Suppress("MemberVisibilityCanBePrivate")

package com.speechify.client.api.services.library

import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.models.FilterAndSortOptions
import com.speechify.client.api.services.library.models.FilterType
import com.speechify.client.api.services.library.models.FolderChangeSet
import com.speechify.client.api.services.library.models.FolderQuery
import com.speechify.client.api.services.library.models.FolderReference
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.SearchRequest
import com.speechify.client.api.services.library.models.SearchResult
import com.speechify.client.api.services.library.models.UpdateLibraryItemParams
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.ILiveQueryView
import com.speechify.client.api.util.LiveQueryViewV2
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Result.Success
import com.speechify.client.api.util.fromCoWithErrorLoggingGetJob
import com.speechify.client.api.util.multiShotFromSuspendFlowIn
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.getGlobalScopeWithContext
import com.speechify.client.internal.runTask
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.toDestructor
import kotlin.js.JsExport

/**
 * The library service exposes an api that allows the manipulation of the user's library.
 *
 * A user's library follows a tree structure, starting at the root folder
 * ([LibraryService.getRootFolder]).
 *
 * It also allows creating new folders, moving items between folders, archiving items and deleting
 * items. But to add new items, see the [ImportService].
 */
@JsExport
class LibraryService internal constructor(
    firebaseAuthService: AuthService,
    private val libraryFirestoreService: LibraryFirebaseDataFetcher,
    firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    importService: ImportService,
    useLiveQueryViewV2: Boolean,
    isSpeechifyEbookVisibleInLibrary: Boolean,
) {
    internal val delegate =
        LibraryServiceDelegate(
            firebaseAuthService,
            libraryFirestoreService,
            firebaseFunctionsService,
            firebaseTimestampAdapter,
            importService,
            useLiveQueryViewV2,
            isSpeechifyEbookVisibleInLibrary,
        )

    /**
     * Gets the root folder, to be used as a parameter of other methods that require a folder
     */
    fun getRootFolder(callback: Callback<FolderReference>) = callback(
        FolderReference.Root.successfully(),
    )

    /**
     * Paginates the children of a directory, pagination is hardcoded to 100 items a page
     *
     * @see LiveQueryViewV2
     *
     * @param folder The folder to get items from, see [getRootFolder] for how to get the root
     * @param options General filtering and sorting options for the pages
     * @param callback Callback that provides access to the pages
     */
    fun getChildrenItems(
        folder: FolderReference,
        options: FilterAndSortOptions,
        callback: Callback<ILiveQueryView<LibraryItem>>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getChildrenItems",
    ) {
        delegate.getItems(
            FolderQuery.fromReference(folder),
            options,
        )
    }.toDestructor()

    /**
     * Gets the top X items of a user's library, based on some criteria
     *
     * @param options General filtering and sorting options for the items
     * @param count How many items to get
     * @param callback Callback that provides access to the top items
     */
    fun getTopItems(
        options: FilterAndSortOptions,
        count: Int,
        callback: Callback<ILiveQueryView<LibraryItem>>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getTopItems",
    ) {
        delegate.getItems(FolderQuery.All, options, count)
    }.toDestructor()

    /**
     * Paginates the user's archived items, pagination is hardcoded to 100 items per page
     *
     * @see LiveQueryViewV2
     */
    fun getTopLevelArchivedItems(
        callback: Callback<ILiveQueryView<LibraryItem>>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getTopLevelArchivedItems",
    ) {
        delegate.getTopLevelArchivedItems()
    }.toDestructor()

    /**
     * Create a shareable link to a library item
     *
     * @param fileItemId The item's id
     * @param callback Callback which will provide the shared link if successful otherwise `SDKError`
     */
    fun shareFile(fileItemId: String, callback: Callback<String>): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.shareFile",
    ) {
        delegate.shareFile(fileItemId)
    }.toDestructor()

    /**
     * Revokes the shareable link to a library item
     *
     * @param fileItemId The item's id
     * @param callback Callback to handle whether the operation failed
     */
    fun stopSharingFile(fileItemId: String, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.stopSharingFile",
        ) {
            delegate.stopSharingFile(fileItemId)
        }.toDestructor()

    /**
     * Get a Library Item by its ID
     *
     * @param itemId The item's ID
     * @param callback to handle the result
     */
    fun getItem(itemId: String, callback: Callback<LibraryItem>): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getItem",
    ) {
        delegate.getItemFromFirestoreOrLocalFromId(itemId).successfully()
    }.toDestructor()

    fun getItemForSpeechifyBook(
        speechifyBookUri: String,
        callback: Callback<LibraryItem.Content?>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getItemForSpeechifyBook",
    ) {
        delegate.getItemForSpeechifyBookFromFirestore(speechifyBookUri).successfully()
    }.toDestructor()

    /**
     * Observe a Library Item changes by its ID.
     */
    fun addItemChangeListener(itemId: String, callback: Callback<LibraryItem>): Destructor =
        callback.multiShotFromSuspendFlowIn(
            flowProvider = { delegate.observeLibraryItem(itemId) },
            scope = getGlobalScopeWithContext(),
        )::destroy

    /**
     * Get the URLs of the files containing the text extracted from the item to support legacy listening experiences.
     * URLs will be sorted in order of the `index` property assigned to the page.
     *
     * @param itemId The item's ID
     * @param callback to handle the result
     */
    internal fun getLegacyPageURLs(
        itemId: String,
        callback: Callback<List<String>>,
    ): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.getLegacyPageURLs",
        ) {
            delegate.getLegacyPageURLs(itemId)
        }.toDestructor()

    /**
     * Archive a library item
     *
     * @param itemId The item's id
     * @param callback Callback to handle whether the operation failed
     */
    fun archiveItem(itemId: String, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.archiveItem",
        ) {
            delegate.archiveItem(itemId)
        }.toDestructor()

    /**
     * Restore a deleted library item
     *
     * @param itemId The item's id
     * @param callback Callback to handle whether the operation failed
     */
    fun restoreItem(itemId: String, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.restoreItem",
        ) {
            delegate.restoreItem(itemId)
        }.toDestructor()

    /**
     * Delete a library item
     *
     * @param itemId The item's id
     * @param callback Callback to handle whether the operation failed
     */
    fun deleteItem(itemId: String, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.deleteItem",
        ) {
            delegate.deleteItem(itemId)
        }.toDestructor()

    /**
     * Restores all archived items in user's library
     *
     * @param callback Callback to handle whether the operation failed
     */
    fun restoreAllArchivedItems(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.restoreAllArchivedItems",
        ) {
            delegate.restoreAllArchivedItems()
        }.toDestructor()

    /**
     * Delete all archived items in user's library
     *
     * @param callback Callback to handle whether the operation failed
     */
    fun deleteAllArchivedItems(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.deleteAllArchivedItems",
        ) {
            delegate.deleteAllArchivedItems()
        }.toDestructor()

    /**
     * This will add the default items to the current users' library.
     * It is safe to call this multiple times, and the items will only be added once.
     */
    fun addDefaultLibraryItems(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "LibraryService.addDefaultLibraryItems",
        ) {
            delegate.addDefaultLibraryItems()
        }.toDestructor()

    /**
     * Move an item to a different folder in user's library
     *
     * @param itemId The item's id
     * @param folder The folder to move the item to
     * @param callback Callback to handle whether the operation failed
     */
    fun moveItem(
        itemId: String,
        folder: FolderReference,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.moveItem",
    ) {
        delegate.moveItem(
            itemId,
            folder,
        )
    }.toDestructor()

    /**
     * Update a library item's metadata
     *
     * @param itemId The item's id
     * @param patch The parameters to update in the item
     * @param callback Callback to handle whether the operation failed
     */
    fun updateItem(
        itemId: String,
        patch: UpdateLibraryItemParams,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.updateItem",
    ) {
        updateItem(itemId, patch)
    }
        .toDestructor()

    internal suspend fun updateItem(
        itemId: String,
        patch: UpdateLibraryItemParams,
    ): Result<Unit> =
        delegate.updateItem(itemId, patch)

    /**
     * Get the item in the user's Library that was imported from a specific source url
     *
     * Note: if more than one item was imported from this same URL, the return value may be any of those items.
     *
     * @param sourceURL The sourceUrl to search for
     * @param callback Callback that receives the item. [Success]{null} means no item was found
     */
    fun getItemWithSourceURL(
        sourceURL: String,
        callback: Callback<LibraryItem.Content?>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getItemWithSourceURL",
    ) {
        delegate.getItemWithSourceURL(sourceURL)
    }.toDestructor()

    /**
     * Transfer a library from one user to another
     *
     * @param fromUserFirebaseIdentityToken The identity token of the user to transfer the items from
     * @param toUserFirebaseIdentityToken The identity token of the user to transfer the items to
     * @param callback Callback to handler whether the operation failed
     */
    fun transferLibrary(
        fromUserFirebaseIdentityToken: String,
        toUserFirebaseIdentityToken: String,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.transferLibrary",
    ) {
        delegate.transferLibrary(
            fromUserFirebaseIdentityToken,
            toUserFirebaseIdentityToken,
        )
    }.toDestructor()

    /**
     * Search for items matching a title query
     *
     * @param searchRequest [SearchRequest] - search query, and the associated parameters
     * @param callback Callback that takes the results of the search
     */
    fun search(
        searchRequest: SearchRequest,
        callback: Callback<SearchResult>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.search",
    ) {
        delegate.libraryItemSearch(searchRequest)
    }.toDestructor()

    /**
     * Search for archived items
     *
     * @param searchRequest [SearchRequest] search query, and the associated parameters
     * @param callback Callback that takes the results of the search
     */
    @Deprecated(
        "Use LibraryService.Search, with FilterType.TOP_LEVEL_ARCHIVED_ITEMS in the search request filters",
        replaceWith = ReplaceWith("search"),
    )
    fun searchArchivedItems(
        searchRequest: SearchRequest,
        callback: Callback<SearchResult>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.searchArchivedItems",
    ) {
        delegate.libraryItemSearch(
            searchRequest.copy(
                // Ensure TOP_LEVEL_ARCHIVED_ITEMS filter is included in the search request filters
                filters = searchRequest.filters.toMutableList().apply {
                    if (!contains(FilterType.TOP_LEVEL_ARCHIVED_ITEMS)) {
                        add(FilterType.TOP_LEVEL_ARCHIVED_ITEMS)
                    }
                }.toTypedArray(),
            ),
        )
    }.toDestructor()

    /**
     * Add a listener that will be called when a new archived item is added/removed/updated.
     *
     * ### Note
     * When a user logs out these callbacks are not cleared. This must be done
     * before logging out, or you risk showing the next user to log in the last user's items.
     *
     * @see removeArchiveChangeListener
     *
     * @param callback The callback to register
     */
    fun addArchiveChangeListener(callback: Callback<FolderChangeSet>) =
        runTask { delegate.addArchiveChangeListener(callback) }

    /**
     * Remove an archive item's listener
     *
     * @see addArchiveChangeListener
     *
     * @param callback The callback to unregister
     */
    fun removeArchiveChangeListener(callback: Callback<FolderChangeSet>) =
        delegate.removeArchiveChangeListener(callback)

    /**
     * Clear all archive items listeners
     */
    fun removeAllArchiveChangeListeners() =
        delegate.removeAllArchiveChangeListeners()

    /**
     * Create a new folder in the user's library
     *
     * @see getRootFolder
     *
     * @param folderTitle The title of the folder
     * @param parentFolder The parent folder
     * @param callback Callback that will be called with the id of the newly created folder
     */
    fun createFolder(
        folderTitle: String,
        parentFolder: FolderReference,
        callback: Callback<FolderReference>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.createFolder",
    ) {
        delegate.createFolder(
            folderTitle,
            parentFolder,
        )
    }.toDestructor()

    /**
     * Get the count of items that match the given filter.
     *
     * @param filter The filter to apply to the items.
     * @param callback A function that will be called when the job is complete. It takes a Long parameter that represents the total number of items in the library that match the given filter.
     *
     * @return Destructor object that can be used to destruct the job when needed.
     */
    fun getCount(
        filter: FilterType,
        callback: Callback<Long>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "LibraryService.getCount",
    ) {
        delegate.getCount(filter)
    }.toDestructor()
}
