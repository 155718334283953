package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.onEach

/**
 * A buffered Flow that has a [BufferObserver] within
 * [producerExceptionBehavior] specifies the behavior of the flow should an exception arise.
 * See [ProducerExceptionBehavior] for more information.
 */
internal fun <T> Flow<T>.bufferWithObserver(
    capacity: Int,
    onBufferOverflow: BufferOverflow = BufferOverflow.SUSPEND,
    producerExceptionBehavior: ProducerExceptionBehavior,
    bufferObserver: BufferObserver<T>,
): Flow<T> =
    this@bufferWithObserver
        .onEach {
            bufferObserver.onItemAddedToBuffer(it)
        }
        .bufferWithExceptionBehavior(
            capacity = capacity,
            onBufferOverflow = onBufferOverflow,
            producerExceptionBehavior = producerExceptionBehavior,
        )
