package com.speechify.client.api.services.importing.models

import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.addProperties
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.util.boundary.asMutableBoundaryMap
import kotlin.js.JsExport

/**
 * Represents the metadata supplied by SDK consumers during the importation of content.
 */
@JsExport
open class ImportableContentMetadata(
    open val contentSubType: String = "unknown",
    open val importFlow: ImportFlow = ImportFlow.Unknown,
    open val contentHostname: String? = null,
) {

    internal val telemetryPropertiesInternalMap: MutableMap<String, Any> = mutableMapOf()

    /**
     * [telemetryProperties] map of key-value to be sent to `telemetry` as properties.
     */
    val telemetryProperties: BoundaryMap<Any> = telemetryPropertiesInternalMap.asMutableBoundaryMap()
}

@JsExport
enum class ImportFlow(val eventReportingValue: String) {
    HomeScreen("home_screen"),
    HomeScreenSuggestions("home_screen_suggestions"),
    PlusButtonModal("plus_button_modal"),
    PlusButtonModalDragAndDrop("plus_button_modal_drag_and_drop"),
    PlusButtonModalSelectFiles("plus_button_modal_select_files"),
    LibraryScreenSuggestions("library_screen_suggestions"),
    LibraryDragAndDrop("library_drag_and_drop"),
    ShareExtension("share_extension"),
    Bookstore("bookstore"),
    PillPlayer("pill_player"),
    LibrarySelectFiles("library_select_files"),
    BrowserExtensionSharing("browser_extension_sharing"),
    PillPlayerSaveToLibrary("pill_player_save_to_library"),
    FirstImportFlow("first_import_flow"),
    AppIntentSiriSuggestion("app_intent_siri_suggestion"),
    ListeningScreen("listening_screen"),
    Unknown("unknown"),
}

internal fun TelemetryEventBuilder.addImportableContentMetadataProperties(
    importableContentMetadata: ImportableContentMetadata?,
) {
    if (importableContentMetadata == null) return
    this.addProperties(
        *importableContentMetadata.telemetryProperties
            .entries()
            .map { it.first to it.second }
            .toTypedArray(),
    )
}
