package com.speechify.client.internal.services.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.db.QueryResult
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

/**
 * Returns a [Flow] that emits the current result in the first item, and then allows to listen to the changes (see [asFlow]).
 */
internal fun <T : Any> Query<T>.asFlowOfListWithCurrentAndChanges(): Flow<List<T>> =
    /**
     * [asFlow] KDoc is a bit vague whether it returns the current result, but _"emits the query result, and emits a new result every time the database changes"_ can be found in [Online docs](https://cashapp.github.io/sqldelight/2.0.0/multiplatform_sqlite/coroutines/#flow)
     */
    asFlow()
        /** Interestingly, there is [app.cash.sqldelight.coroutines.mapToList], but it requires a
         *  [kotlin.coroutines.CoroutineContext], so not using it here. We are fine with just running in the flow
         *  consumer's context. (the [app.cash.sqldelight.coroutines.mapToList] is advertised [here](https://cashapp.github.io/sqldelight/2.0.0/multiplatform_sqlite/coroutines/#flow))
         */
        .map { it.awaitAsList() }

internal suspend fun <T : Any> Query<T>.awaitAsFirstOrNull(): T? =
    /**
     * Based on [app.cash.sqldelight.async.coroutines.awaitAsOneOrNull]
     */
    execute { cursor ->
        // If the cursor isn't async, we want to preserve the blocking semantics and execute it synchronously
        when (val next = cursor.next()) {
            is QueryResult.AsyncValue -> {
                QueryResult.AsyncValue {
                    if (!next.await()) return@AsyncValue null
                    val value = mapper(cursor)
                    value
                }
            }

            is QueryResult.Value -> {
                if (!next.value) return@execute QueryResult.Value(null)
                val value = mapper(cursor)
                QueryResult.Value(value)
            }
        }
    }.await()
