package com.speechify.client.api.adapters.imageConversion

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.File
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
abstract class ImageConverter {

    /**
     * Ensures that the [inputImage] fits [targetMaxWidth] (resizes if necessary, leaves intact if not already smaller),
     * and ensures the format is JPEG with [targetQualityPercent].
     *
     * @return the [File] of the converted image. It should have [File.contentType] of `"image/jpeg"`.
     */
    protected abstract fun cappedWidthJpeg(
        inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        /**
         * The maximum width of the output image. If the input image width is smaller than this,
         * it should be returned as-is.
         */
        targetMaxWidth: Int,
        /**
         * The quality of the output image, in the range [0, 100].
         */
        targetQualityPercent: Int,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    /**
     * The method to use in SDK to call [cappedWidthJpeg]
     */
    internal suspend fun cappedWidthJpeg(
        inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        targetMaxWidth: Int,
        targetQualityPercent: Int,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> = suspendCoroutine {
        cappedWidthJpeg(inputImage, targetMaxWidth, targetQualityPercent, it::resume)
    }

    protected abstract fun convertToWithQualityPercent(
        inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        /**
         * Represents the image format.
         */
        imageFormat: ImageFormat,
        /**
         * The quality of the output image, in the range [0, 100].
         */
        targetQualityPercent: Int,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    /**
     * The method to use in SDK to call [coConvertToWithQualityPercent]
     */
    internal suspend fun coConvertToWithQualityPercent(
        inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        imageFormat: ImageFormat,
        targetQualityPercent: Int,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> = suspendCoroutine {
        convertToWithQualityPercent(inputImage, imageFormat, targetQualityPercent, it::resume)
    }
}
