// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

import com.speechify.client.internal.util.collections.ComparableFromItemAndComparator

internal fun <T> MutableList<T>.replaceWith(
    replacement: List<T>,
) {
    clear()
    addAll(replacement)
}

/**
 * A convenience overload of [List.subList] for returning everything till the end.
 */
internal fun <T> List<T>.subListFrom(
    /**
     * Index to start from, inclusive.
     */
    fromIndex: Int,
) =
    subList(
        fromIndex = fromIndex,
        toIndex = this.size,
    )

/**
 * A convenience overload of [List.subList] for returning everything from the beginning.
 */
internal fun <T> List<T>.subListToInclusive(
    /**
     * Index to finish at, inclusive.
     */
    toIndexInclusive: Int,
) =
    subList(
        fromIndex = 0,
        toIndex = toIndexInclusive + 1,
    )

internal fun <T> List<T>.subListByRemovingCountAtEnd(
    countOfItemsToRemoveFromEnd: Int,
) =
    this.subListToInclusive(
        toIndexInclusive = this.lastIndex - countOfItemsToRemoveFromEnd,
    )

internal fun <T> List<T>.subListByRemovingCountAtStart(
    countOfItemsToRemoveFromStart: Int,
) =
    this.subListFrom(
        fromIndex = countOfItemsToRemoveFromStart,
    )

/**
 * A variant of [kotlin.collections.binarySearchBy] (which is a version of the binary search which allows searching
 * not by the entire item of the list, but merely its derivative) which allows the [key] to not be [Comparable], but
 * rather have the comparison work off the provided [comparator].
 *
 * NOTE: This leads to a large number of temporary objects being allocated, so should only be used when no other
 * alternative exists.
 *
 * For understandability, the result is changed to [BinarySearchResult], rather the [Int] convention of other `binarySearch*`
 * list methods.
 */
internal fun <Item, Key> List<Item>.binarySearchBy(
    key: Key,
    getKey: Item.() -> Key,
    comparator: Comparator<Key>,
    fromIndex: Int = 0,
    toIndex: Int = size,
): BinarySearchResult =
    binarySearchBy(
        key = ComparableFromItemAndComparator(
            item = key,
            comparator = comparator,
        ),
        fromIndex = fromIndex,
        toIndex = toIndex,
        selector = {
            ComparableFromItemAndComparator(
                item = it.getKey(),
                comparator = comparator,
            )
        },
    ).let { BinarySearchResult.fromIntResult(it) }

/**
 * Clearer intent than the integer one produced by [binarySearch]
 */
internal sealed class BinarySearchResult {
    companion object {
        fun fromIntResult(binarySearchIntResult: Int) =
            if (binarySearchIntResult >= 0) {
                ExactMatch(binarySearchIntResult)
            } else {
                InsertionPointMatch(-binarySearchIntResult - 1)
            }
    }
    class ExactMatch(val exactMatchIndex: Int) : BinarySearchResult()

    class InsertionPointMatch(val insertionPointIndex: Int) : BinarySearchResult()
}

/**
 * A version of [toTypedArray] usable where [T] is non-`reified`, e.g. in a generic class, where `reified` can't be
 * used.
 */
fun <T> List<T>.toTypedArraySeedingTypeFrom(emptyTypedArray: Array<T>): Array<T> {
    val result = emptyTypedArray.copyOf(newSize = this.size)
    for ((index, value) in this.withIndex()) {
        result[index] = value
    }
    @Suppress(
        /* The `T` is now non-nullable because all the elements of non-nullable list have been copied. */
        "UNCHECKED_CAST",
    )
    return result as Array<T>
}
