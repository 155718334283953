package com.speechify.client.api.util

import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.internal.util.extensions.strings.splitInTwoOnFirst
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * NOTE: In JS to create a MimeType from the full string you should use `MimeType.fromFullValue(...)`.
 * (other runtimes can see this function as a constructor overload)
 */
@JsExport
open class MimeType constructor(
    /**
     * E.g. `text/html` when the full value is `text/html; charset=utf-8`
     */
    val typeSubtype: String,
    /**
     * E.g. `charset=utf-8` when the full value is `text/html; charset=utf-8`
     *
     * As per [MIME spec](https://www.rfc-editor.org/rfc/rfc2045#section-5.1) or [data URL spec](https://www.rfc-editor.org/rfc/rfc2397#section-3)
     */
    val parameters: MimeTypeParameters = MimeTypeParameters(null),
) {
    /**
     * E.g. `text/html; charset=utf-8` (when parameter is `charset=utf-8`), or `text/html` (when parameter is `null`).
     */
    val fullString: String
        get() = "$typeSubtype${parameters.fullString?.let { "; $it" } ?: ""}"

    @JsName("fromFullValue")
    constructor(
        /**
         * For example `text/html; charset=utf-8`
         */
        fullValue: String,
    ) : this(
        parsedFullValue = fullValue.splitInTwoOnFirst(Regex(";\\s*")),
    )

    private constructor(parsedFullValue: Pair<String, String?>) : this(
        typeSubtype = parsedFullValue.first,
        parameters = MimeTypeParameters(parsedFullValue.second),
    )
}

@JsExport
class MimeTypeParameters internal constructor(val fullString: String?) {

    val charset: String?
        get() {
            /**
             * Extracts key-value pairs from parameters and filter the charset encoding.
             * Returns null if charset not find.
             */
            return fullString?.split(Regex(";\\s*"))
                ?.map { it.splitInTwoOnFirst("=") }
                ?.find { it.first.lowercase() == "charset" }?.second?.trim()
        }
}

sealed class MimeTypeOfListenableContent(
    typeSubtype: String,
    parameters: MimeTypeParameters?,
) : MimeType(typeSubtype) {
    companion object {
        object StaticMimeTypes {
            object PDF : MimeTypeOfListenableContent(typeSubtype = "application/pdf", parameters = null)
            object EPUB : MimeTypeOfListenableContent(typeSubtype = "application/epub+zip", parameters = null)
        }
    }

    class Html(
        parameters: MimeTypeParameters?,
    ) : MimeTypeOfListenableContent("text/html", parameters)

    class Text(
        parameters: MimeTypeParameters?,
    ) : MimeTypeOfListenableContent("text/plain", parameters)
}

internal fun MimeType?.ensureListenableMimeType(contentTypeForFallback: ContentType?): MimeTypeOfListenableContent =
    when (this?.typeSubtype) {
        "application/pdf" -> MimeTypeOfListenableContent.Companion.StaticMimeTypes.PDF
        "application/epub+zip" -> MimeTypeOfListenableContent.Companion.StaticMimeTypes.EPUB
        "text/html" -> MimeTypeOfListenableContent.Html(parameters = parameters)
        "text/plain", "text/markdown" -> MimeTypeOfListenableContent.Text(parameters = parameters)
        else -> {
            when (contentTypeForFallback) {
                /* `SCAN` shouldn't really happen here as it doesn't have a single file content. We treat it the same
                 *  way as null` because both produce an exception that will give all the error data for developer.
                 */
                ContentType.SCAN,
                null,
                -> throw IllegalArgumentException(
                    "MimeType.ensureListenableMimeType: '${this?.fullString}' is not a listenable mime type" +
                        "${contentTypeForFallback?.let { " (contentTypeForFallback: $it)" }}",
                )

                /* Valid types with some file content. TODO: consider using a sealed type to group them (even an interface could do) */
                ContentType.PDF -> MimeTypeOfListenableContent.Companion.StaticMimeTypes.PDF
                ContentType.HTML -> MimeTypeOfListenableContent.Html(parameters = this?.parameters)

                ContentType.TXT -> MimeTypeOfListenableContent.Text(parameters = this?.parameters)

                ContentType.EPUB, ContentType.SPEECHIFY_BOOK ->
                    MimeTypeOfListenableContent.Companion.StaticMimeTypes.EPUB
                ContentType.DOCX -> TODO(
                    "Add a new 'MimeTypeOfListenableContent.StaticMimeTypes' when" +
                        " introducing support for .docx",
                )
            }
        }
    }
