package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.wrapSyncCall
import com.speechify.client.internal.time.DateTime
import kotlin.js.JsExport
import kotlin.js.JsName

typealias FirebaseTimestamp = Any

@JsExport
interface FirebaseTimestampAdapter {
    @JsName("fromMillis")
    fun fromMillis(d: BoundaryTimestamp): FirebaseTimestamp

    @JsName("now")
    fun now(): FirebaseTimestamp
}

@JsExport
class BoundaryTimestamp internal constructor(private val dateTime: DateTime) {
    @Suppress("NON_EXPORTABLE_TYPE")
    val asLong: Long get() = dateTime.asMillisecondsLong()
    val asDouble: Double get() = dateTime.asMillisecondsDouble()
}

internal fun FirebaseTimestampAdapter.traced() =
    if (Log.isDebugLoggingEnabled) FirebaseTimestampAdapterTraced(this) else this

internal class FirebaseTimestampAdapterTraced(private val firebaseTimestampAdapter: FirebaseTimestampAdapter) :
    FirebaseTimestampAdapter {
    override fun fromMillis(d: BoundaryTimestamp): FirebaseTimestamp {
        return wrapSyncCall({ "[$it] FirebaseTimestampAdapter.fromMillis($d)" }) {
            firebaseTimestampAdapter.fromMillis(d)
        }
    }

    override fun now(): FirebaseTimestamp {
        return wrapSyncCall({ "[$it] FirebaseTimestampAdapter.now()" }) {
            firebaseTimestampAdapter.now()
        }
    }
}
