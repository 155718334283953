package com.speechify.client.api.content.view.web

import com.speechify.client.api.adapters.html.DOMElement
import com.speechify.client.api.adapters.html.DOMTextNode
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ObjectRef

internal class DOMWebPageView(
    private val element: DOMElement,
    override val sourceUrl: String?,
) : WebPageView {

    // TODO(anson): this is smelly - if we can statically initialize this, why do we need it at all?
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    override fun getWebPage(): WebPage {
        val builder = WebPageNode.builder(
            element.tagName,
            element.attributes.map { WebPageElementAttribute(it) },
            ObjectRef(element.rawRefObject),
        ) {
            this.buildChildren(element)
        }

        return WebPage(
            root = builder.build(),
            sourceUrl,
        )
    }
}

/**
 * Adds all children and their descendants as children of the Element via the builder.
 */
internal fun WebPageNode.Builder.Element.buildChildren(element: DOMElement) {
    element.children.forEach {
        when (it) {
            is DOMElement -> {
                this.addElement(
                    it.tagName,
                    it.attributes.map { attr -> WebPageElementAttribute(attr) },
                    ObjectRef(it.rawRefObject),
                ) {
                    this.buildChildren(it)
                }
            }
            is DOMTextNode -> this.addText(it.text, ObjectRef(it.rawRefObject))
        }
    }
}
