package com.speechify.client.internal.util.text.groupingToSentences.internal

/**
 * Pertains to any 'spans' in a text which have a terminating sequence.
 * A common part between a 'sentence' (which only requires a terminator), and other 'text structure spans that do
 * require an opener sequence as well (e.g. parenthesis, brackets, quotes etc.)
 */
internal abstract class TextStructureSpanDefinition(
    /**
     * NOTE: `*MidString` so the regex should not cover the cases of 'end of string', so for example for English `.`
     * it should include a `\s` whitespace (to not terminate on `google.com`), but also **not use `$`** in the Regex.
     * This is because an end of a string may just result from chunking.
     */
    val regexOfTerminatorMidString: Regex,
)

internal fun CharSequence.getMatchingSpanTerminatorAt(
    spanDefinition: TextStructureSpanDefinition,
    startIndex: Int,
): SpanFind? =
    spanDefinition.regexOfTerminatorMidString.matchAt(
        input = this,
        index = startIndex,
    )?.let { match ->
        SpanFind(
            range = match.range,
        )
    }

internal open class SpanFind(
    val range: IntRange,
)

internal class SpanStartFind<TDefinition : TextStructureSpanDefinition>(
    val definition: TDefinition,
    range: IntRange,
) : SpanFind(
    range = range,
)
