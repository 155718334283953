package com.speechify.client.internal.services.book

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coGetObjectOrNullIfNotExists
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.importing.models.TextualCount
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlinx.serialization.Serializable

private fun getPagesContentStatsRef(itemId: SpeechifyContentId) = "items/$itemId/pagesContentStats"

private fun getPageContentStatsPath(itemId: SpeechifyContentId, pageId: PageContentStatsId): PathInCollection =
    PathInCollection(getPagesContentStatsRef(itemId), pageId)

private typealias PageContentStatsId = String

internal class PlatformBookPageContentStatsService(
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val libraryFirebaseDataFetcher: LibraryFirebaseDataFetcher,
) {

    internal suspend fun getAllContentStatsPages(
        bookId: SpeechifyContentId,
    ): Result<Array<FirestorePageContentStatsModel>> {
        val contentStatsDocuments = firebaseFirestoreService.queryDocuments(getPagesContentStatsRef(bookId))
            .coFetch()
            .orDefaultWith {
                emptyArray()
            }
        return contentStatsDocuments.mapNotNull { contentStatsPage ->
            when (contentStatsPage) {
                is FirebaseFirestoreDocumentSnapshot.Exists -> {
                    contentStatsPage.value<FirestorePageContentStatsModel>()
                        .orDefaultWith { null }
                }

                FirebaseFirestoreDocumentSnapshot.NotExists -> null
            }
        }.toTypedArray().successfully()
    }

    private suspend fun getContentStatsPage(
        bookId: SpeechifyContentId,
        pageIndex: Int,
    ): FirestorePageContentStatsModel? {
        val docPath = getPageContentStatsPath(
            itemId = bookId,
            pageId = "$pageIndex",
        )
        return firebaseFirestoreService.coGetObjectOrNullIfNotExists<FirestorePageContentStatsModel>(docPath)
            .toNullable()
    }

    internal suspend fun addContentStatsPage(
        bookId: SpeechifyContentId,
        pageContentStats: FirestorePageContentStatsModel,
    ): Result<Unit> {
        // skip adding the content stats for a page if it already exists.
        if (getContentStatsPage(bookId, pageContentStats.bookPageIndex) != null) {
            return Unit.successfully()
        }

        return firebaseFirestoreService.coSetDocument(
            path = getPageContentStatsPath(bookId, "${pageContentStats.bookPageIndex}"),
            value = buildPageContentStatsFirestorePayload(
                bookPageIndex = pageContentStats.bookPageIndex,
                textualCount = TextualCount(
                    wordCount = pageContentStats.wordCount,
                    charCount = pageContentStats.charCount,
                ),
            ).toBoundaryMap(),
        )
    }

    internal suspend fun updateFirebaseContentStats(
        bookId: SpeechifyContentId,
        wordCount: Int,
        charCount: Int,
    ): Result<Unit> {
        return libraryFirebaseDataFetcher.updateItemDataFromParams(
            itemId = bookId,
            payload = mapOf(
                RecordProperties.wordCount.keyId to wordCount,
                RecordProperties.charCount.keyId to charCount,
            ).toBoundaryMap(),
        )
    }

    internal suspend fun getFirestoreContentStatsOrNull(
        bookId: String,
    ): ContentStats? {
        val libraryListenableContentOrNull = libraryFirebaseDataFetcher.getLibraryItem(bookId)
            .orDefaultWith { null } as? LibraryItem.Content
        return libraryListenableContentOrNull?.totalWords
            ?.let { ContentStats(EstimatedCount(it, 1.0)) }
    }

    private fun buildPageContentStatsFirestorePayload(
        bookPageIndex: Int,
        textualCount: TextualCount,
    ) = mapOf(
        "bookPageIndex" to bookPageIndex,
        RecordProperties.wordCount.keyId to textualCount.wordCount,
        RecordProperties.charCount.keyId to textualCount.charCount,
    )
}

@Serializable
internal class FirestorePageContentStatsModel(
    val bookPageIndex: Int,
    val wordCount: Int,
    val charCount: Int,
)
