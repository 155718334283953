package com.speechify.client.helpers.content.standard.book.heuristics.v2

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.util.images.BoundingBox
import kotlin.math.abs

internal fun BookPageTextContentItem.getText(): String = text.text

internal fun List<BoundingBox>.unionBoxes(): BoundingBox {
    var boundingBox = this.first()
    for (i in 1 until this.size) boundingBox = boundingBox.union(this[i])
    return boundingBox
}

internal fun Int.isNeighborIndex(that: Int) = (abs(this - that) == 1)
