package com.speechify.client.api.services.scannedbook

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.scannedbook.models.FirestoreScannedBook
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.services.scannedbook.models.ScannedBook
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithTelemetryLoggingErrors
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import kotlin.js.JsExport

/**
 * The Scanned Book Service manages the Scanned Books that Users create by scanning physical books, and uploading
 * the images and results of on-device OCR so that they can listen to the content in Speechify.
 *
 * To import a new scanned book to the user's library, use the [ImportService]
 */
@JsExport
class ScannedBookService internal constructor(
    private val platformScannedBookService: PlatformScannedBookService,
) : WithScope() {

    /**
     * Deletes a Scanned Book
     * @param bookId The ID of the book to be deleted
     * @param callback Notifies the app the success/failure of the deletion of the item
     */
    fun deleteBook(
        bookId: SpeechifyContentId,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) {
        callback.fromCoWithTelemetryLoggingErrors(scope, telemetryEventName = "ScannedBookService.deleteBook") {
            it.addProperty("bookId", bookId)
            platformScannedBookService.deleteBook(bookId)
        }
    }

    /**
     * Replaces a page with a new scanned page
     * @param bookId The ID of the book of which the page is to be replaced
     * @param file The Scanned Image of the page
     * @param pageId The ID of the page that is to be replaced
     * @param callback Notifies the app the success/failure of the replace page operation
     */
    fun replacePage(
        bookId: SpeechifyContentId,
        pageId: String,
        file: OCRFile,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) {
        callback.fromCoWithTelemetryLoggingErrors(scope, telemetryEventName = "ScannedBookService.replacePage") {
            it.addProperty("bookId", bookId)
            it.addProperty("pageId", pageId)
            platformScannedBookService.replacePage(bookId, pageId, file)
        }
    }

    /**
     * Reorders the pages in the scanned book
     * @param bookId The ID of the book of which the pages are to be reordered
     * @param pageOrder An array specifying the page ordering of the book
     * @param callback Notifies the app the success/failure of the reordering of pages
     */
    fun reorderPages(
        bookId: SpeechifyContentId,
        pageOrder: Array<String>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) {
        callback.fromCoWithTelemetryLoggingErrors(scope, telemetryEventName = "ScannedBookService.reorderPages") {
            it.addProperty("bookId", bookId)
            platformScannedBookService.reorderPages(bookId, pageOrder)
        }
    }

    /**
     * Deletes the specified pages in the scanned book
     * @param scannedBookId The ID of the book of which the pages are to be deleted
     * @param pageIds An array specifying the page Ids that are to be deleted
     * @param callback Notifies the app the success/failure of the deletion of pages
     */
    fun deletePages(
        bookId: SpeechifyContentId,
        pageIds: Array<String>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) {
        callback.fromCoWithTelemetryLoggingErrors(scope, telemetryEventName = "ScannedBookService.deletePages") {
            it.addProperty("bookId", bookId)
            it.addProperty("numberOfPages", pageIds.size)
            platformScannedBookService.deletePages(bookId, pageIds)
        }
    }

    /**
     * Adds new pages to the Scanned Book
     * @param bookId The ID of the book of which the pages are to be added to
     * @param pages Array of Scanned Images to add to the book
     * @param callback Notifies the app the success/failure of the addition of pages
     */
    fun addPages(
        bookId: SpeechifyContentId,
        pages: Array<OCRFile>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) {
        callback.fromCoWithTelemetryLoggingErrors(scope, telemetryEventName = "ScannedBookService.addPages") {
            it.addProperty("bookId", bookId)
            it.addProperty("numberOfPages", pages.size)
            platformScannedBookService.addPages(bookId, pages)
        }
    }

    /**
     * Gets a [ScannedBook] instance from the Scanned Book ID
     * - The Scanned Book ID can be found in the uri field of a library item
     * @param bookId The ID corresponding to the desired Scanned Book
     * @param callback Notifies the app the success/failure - and contains a ScannedBook instance
     */
    fun getScannedBookFromBookId(bookId: SpeechifyContentId, callback: Callback<ScannedBook>) =
        callback.fromCoWithTelemetryLoggingErrors(
            scope,
            telemetryEventName = "ScannedBookService.getScannedBookFromId",
        ) {
            it.addProperty("bookId", bookId)
            coGetScannedBookFromBookId(bookId)
        }

    internal suspend fun coGetScannedBookFromBookId(bookId: SpeechifyContentId): Result<FirestoreScannedBook> {
        return platformScannedBookService.getScannedBookFromScannedBookId(bookId)
    }
}
