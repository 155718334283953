package com.speechify.client.api.adapters.pdf.search

import com.speechify.client.api.util.images.BoundingBox
import kotlin.js.JsExport

@JsExport
data class PdfSearchResult(
    val pageIndex: Int?,
    val boundingBoxes: Array<BoundingBox>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PdfSearchResult

        if (pageIndex != other.pageIndex) return false
        if (!boundingBoxes.contentEquals(other.boundingBoxes)) return false

        return true
    }

    override fun hashCode(): Int {
        var result = pageIndex ?: 0
        result = 31 * result + boundingBoxes.contentHashCode()
        return result
    }
}
