package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
enum class SubscriptionStatus(val status: String) {
    /**
     * The subscription is currently active, and will auto renew at the end of the billing cycle / trial period.
     * The user should have access to their premium features.
     */
    ACTIVE("active"),

    /**
     * The subscription is expired, and wasn't renewed.
     * The user should not have access to any premium features.
     */
    EXPIRED("expired"),

    /**
     * The user cancelled the subscription, it will no longer auto renew, but it will still be valid till the expiry date.
     * The user should have access to their premium features.
     */
    CANCELED("canceled"),
}
