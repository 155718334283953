package com.speechify.client.reader.core

import com.speechify.client.api.util.boundary.observableValue.asFlow
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class TitleHelper internal constructor(
    scope: CoroutineScope,
    internal val title: Flow<String>,
) : Helper<TitleState>(scope) {
    override val stateFlow: StateFlow<TitleState> = title.map { TitleState(title = it) }.stateInHelper(
        initialValue = TitleState(title = null),
    )
    override val initialState = stateFlow.value

    fun setTitle(title: String) {
        dispatch(SetTitle(title))
    }

    internal companion object {
        internal fun create(scope: CoroutineScope, readingBundle: ReadingBundle): TitleHelper {
            return TitleHelper(scope, readingBundle.content.title.asFlow()).also {
                it.commands
                    .onEachInstance<SetTitle> {
                        readingBundle.contentTitle.set(it.title)
                    }
                    .launchIn(scope)
            }
        }
    }
}

@JsExport
data class TitleState(val title: String?)

internal data class SetTitle(val title: String)
