package com.speechify.client.bundlers.reading.epub

import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import kotlin.js.JsExport

@JsExport
class EpubReadingBundle internal constructor(
    dependencies: Dependencies,
    bundlingSourceTelemetryEventBuilder: TelemetryEventBuilder?,
    bundleMetadata: BundleMetadata?,
    predecessorBundle: ReadingBundle? = null,
) : ReadingBundle(
    dependencies = dependencies,
    bundlingSourceTelemetryEventBuilder = bundlingSourceTelemetryEventBuilder,
    bundleMetadata = bundleMetadata,
    predecessorBundle = predecessorBundle,
) {
    override val content: ContentBundle.EpubBundleV3 =
        listeningBundle.contentBundle as? ContentBundle.EpubBundleV3
            ?: throw RuntimeException(
                "Unsupported content bundle type ${listeningBundle.contentBundle::class.simpleName}",
            )
}
