package com.speechify.client.api.util.io

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.internal.services.file.models.FileBase
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * Represents the SDK-consumer-controlled most efficient way (ideally, native to the programming language used), to carry
 * binary content that needs to be read 'randomly', i.e. the bytes can be read starting from any index, without
 * incurring any significant performance or memory penalty due to the index value given. (typically a native 'blob' or
 * 'file' in the target programming language)
 *
 * The value will typically be passed intact to various SDK consumer components, thanks to which they will be free to
 * access the internal fields of these objects for the most efficient processing (hence the use of [BinaryContentReadableInChunksWithNativeAPI] markup interface).
 *
 * Where the SDK needs to read the contents, it will use the methods defined in the base [BinaryContentReadableRandomlyMultiplatformAPI]
 * type. They should not be used by the SDK consumers where a native input is accepted, because they are not the most
 * efficient, as they don't support reusing arrays, or [zero-copy-reading](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamBYOBReader)).
 */
expect open class BinaryContentReadableRandomly : BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI

/**
 * Binary data whose bytes can be 'randomly accessed', i.e. any of its bits can be retrieved at any time
 * (and repeatedly) by requesting it using its index.
 */
@JsExport
abstract class BinaryContentReadableRandomlyMultiplatformAPI : BinaryContentReadableInChunks {
    /**
     * The total bytes count of the data.
     */
    abstract fun getSizeInBytes(callback: Callback<Int>)

    /**
     * Asynchronously obtain the slice of bytes in the data starting at startIndex up to (but not including) endIndex
     */
    abstract fun getBytes(
        startIndex: Int,
        /**
         * NOTE: This is exclusive, i.e. the byte at this index will not be included in the result.
         */
        endIndex: Int,
        callback: Callback<ByteArray>,
    )
}

suspend fun BinaryContentReadableRandomlyMultiplatformAPI
.coGetSizeInBytes(): Result<Int> = suspendCoroutine { cont ->
    getSizeInBytes(cont::resume)
}

internal suspend fun BinaryContentReadableRandomlyMultiplatformAPI.coGetBytes(
    startIndex: Int,
    endIndex: Int,
): Result<ByteArray> =
    suspendCoroutine { cont -> getBytes(startIndex, endIndex, cont::resume) }

/**
 * Warning: Using this function may lead to Out Of Memory errors - similarly to [BinaryContentReadableSequentiallyMultiplatformAPI.readAllBytesToSingleArray].
 * To prevent the problem, pass the [this] value directly to consumers, so they can read the content in chunks.
 */
internal suspend fun BinaryContentReadableRandomlyMultiplatformAPI.coGetAllBytes(): Result<ByteArray> =
    coGetBytes(0, coGetSizeInBytes().orReturn { return it })

/**
 * Groups `*ReadableRandomly` types that have both native API, and Multiplatform API.
 */
@JsExport
abstract class BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI :
    BinaryContentReadableRandomlyMultiplatformAPI(),
    BinaryContentReadableInChunksWithNativeAPI

/**
 * Binary data that has information about its type (in the [mimeType]) and can be randomly accessed.
 * (it can be backed by any kind of memory, e.g. process-memory, on-disk, etc)
 */
@JsExport
sealed class BinaryContentWithMimeTypeReadableRandomlyMultiplatformAPI :
    BinaryContentReadableRandomlyMultiplatformAPI(),
    BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI> {
    abstract override val mimeType: MimeType

    override val binaryContent: BinaryContentReadableRandomlyMultiplatformAPI
        get() = this
}

@Deprecated(
    "Pass the [this] value directly to consumers, so they can read the content in chunks using native API.",
)
internal fun <
    BC : BinaryContentReadableRandomlyMultiplatformAPI,
    C : BinaryContentWithMimeTypeReadableInChunks<BC>,
    >
C.toFile(
    /**
     * Exposed especially to maintain historical behavior of [File.contentType] being stripped of charset
     * parameter (it's hard to find out if anything relies on this).
     * #StrippingParameterFromContentTypeToMaintainHistoricalBehavior
     */
    shouldContentTypeLoseParameter: Boolean = false,
): File =
    object : FileBase() {
        override val contentTypeOrNull: String?
            get() = this@toFile.mimeType?.fullString
        override val mimeTypeOrNull: MimeType?
            get() = this@toFile.mimeType

        override fun getSizeInBytes(
            callback: Callback<Int>,
        ) = callback.fromCo {
            this@toFile.binaryContent.coGetSizeInBytes()
        }

        override fun getBytes(
            startIndex: Int,
            endIndex: Int,
            callback: Callback<ByteArray>,
        ) = callback.fromCo {
            this@toFile.binaryContent.coGetBytes(
                startIndex = startIndex,
                endIndex = endIndex,
            )
        }
    }

internal fun BinaryContentReadableRandomlyMultiplatformAPI.toFileWithMimeType(
    mimeType: MimeType,
): File =
    object : FileBase() {
        override val contentTypeOrNull: String
            get() = mimeType.fullString
        override val mimeTypeOrNull: MimeType
            get() = mimeType

        override fun getSizeInBytes(
            callback: Callback<Int>,
        ) = callback.fromCo {
            this@toFileWithMimeType.coGetSizeInBytes()
        }

        override fun getBytes(
            startIndex: Int,
            endIndex: Int,
            callback: Callback<ByteArray>,
        ) = callback.fromCo {
            this@toFileWithMimeType.coGetBytes(
                startIndex = startIndex,
                endIndex = endIndex,
            )
        }
    }
