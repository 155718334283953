package com.speechify.client.api.diagnostics

internal actual fun setupUnhandledExceptionHandlerSpecificToCompilationTarget(
    errorLog: ErrorLogForUnhandledExceptionHandler,
) {
    /* Nothing for now. We could consider registering a handler under [`unhandledrejection`](https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event)
       but in JS we are tenants of the same runtime as the product app, and it may have added its own handlers
       (and it really should) - ours would only make sense as a fallback if there is none (so we'd need a way to detect
       this), but then the message, apart from reporting the unhandled exception, should mostly recommend making the
       product adding their own global handler.
     */
}

internal actual val localDiagnosticOutputReporterName: String =
    "com.speechify.client.api.diagnostics.${
    /* TODO remove hardcoded namespace and use `class.qualifiedName` once kotlin supports it for JS */
    ConsoleDiagnosticReporter::class.simpleName
    }"
