package com.speechify.client.api.adapters.ocr

import com.speechify.client.api.util.images.Viewport
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * OCR Adapter for scanned books
 */
@Serializable
@JsExport
data class OCRResult(
    /**
     * Raw text content from the OCR adapter
     */
    val rawText: String,
    /**
     * [OCRTextContent] array from the OCR adapter
     */
    val textContent: Array<OCRTextContent>,
    /**
     * Image dimensions of an OCR page, encoded as a [Viewport] instance
     */
    val imageDimensions: Viewport,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as OCRResult

        if (rawText != other.rawText) return false
        if (!textContent.contentEquals(other.textContent)) return false
        if (imageDimensions != other.imageDimensions) return false

        return true
    }

    override fun hashCode(): Int {
        var result = rawText.hashCode()
        result = 31 * result + textContent.contentHashCode()
        result = 31 * result + imageDimensions.hashCode()
        return result
    }

    override fun toString(): String {
        return "OcrResult(rawText='$rawText', textContent=${textContent.contentToString()}," +
            " imageDimensions=$imageDimensions)"
    }
}

@JsExport
class OCRTextResult(
    val rawText: String,
    val textContent: Array<OCRTextContent>,
)
