package com.speechify.client.api.content

import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.internal.util.findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch
import kotlin.math.max
import kotlin.math.min

internal data class FillerContentSlice internal constructor(
    override val start: ContentCursor,
    override val end: ContentCursor,
    override val text: String,
    override val metadata: TextMetadata = TextMetadata(),
) : ContentSlice {

    // Since this didn't originate from any text content, we expose an empty range
    // We expose [0, 0) instead of [0, -1) because both are empty but the former has fewer platform-specific edge cases (in JS, string.slice(0, -1) returns the entire string)
    override val range: BoundaryPair<Int, Int> = BoundaryPair(0, 0)

    override val length: Int get() = text.length

    override val slices: Array<ContentSlice> by lazy { arrayOf(this) }

    override val rootElement: ContentElementReference get() = start.getParentElement()

    override fun getFirstIndexOfCursor(cursor: ContentCursor): Int {
        return when {
            (cursor.isAfter(this.end)) -> length - 1
            length > 0 -> 0
            else -> -1
        }
    }

    override fun getLastIndexOfCursor(cursor: ContentCursor): Int {
        return when {
            cursor.isAfterOrAt(this.start) -> length - 1
            length > 0 -> 0
            else -> -1
        }
    }

    override fun getFirstCursorAtIndex(characterIndex: Int): ContentCursor {
        return start
    }

    override fun getLastCursorAtIndex(characterIndex: Int): ContentCursor {
        return end
    }

    override fun containsCursor(cursor: ContentCursor): Boolean {
        return !this.start.isAfter(cursor) && !this.end.isBefore(cursor)
    }

    override fun replaceAll(pattern: String, replacement: (match: String) -> String): ContentText =
        sequence {
            var currentIndex = 0
            for (
            range in findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(
                pattern,
                this@FillerContentSlice.text,
            )
            ) {
                yield(text.substring(currentIndex, range.startIndex))
                yield(replacement(text.substring(range.startIndex, range.endIndexExclusive)))
                currentIndex = range.endIndexExclusive
            }
            if (currentIndex < text.length) {
                yield(
                    if (currentIndex == 0) {
                        text
                    } else {
                        text.substring(currentIndex, text.length)
                    },
                )
            }
        }.filter { it.isNotEmpty() }
            .toList()
            .let {
                return FillerContentSlice(
                    start,
                    end,
                    it.joinToString(""),
                )
            }

    override fun split(pattern: String): Array<ContentText> {
        if (this.length == 0) return arrayOf(this)

        // Return match range or range of first capture group if available
        val ranges = findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text)

        val (lastEnd, results) = ranges.fold(0 to listOf<ContentText>()) { acc, range ->
            val text = this.text.substring(acc.first, range.startIndex)
            range.endIndexExclusive to (acc.second + FillerContentSlice(start, end, text))
        }
        return if (lastEnd <= this.text.lastIndex) {
            (results + this.slice(lastEnd, this.length)).toTypedArray()
        } else {
            results.toTypedArray()
        }
    }

    override fun matchAll(pattern: String): Array<ContentText> {
        return findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text).map {
            FillerContentSlice(
                start,
                end,
                text.substring(it.startIndex, it.endIndexExclusive),
            )
        }.toTypedArray()
    }

    override fun withMetadata(newMetadata: TextMetadata): FillerContentSlice {
        return FillerContentSlice(start, end, text, newMetadata)
    }

    override fun slice(startIndex: Int, endIndex: Int): ContentSlice {
        val clippedStart = max(0, minOf(text.length - 1, endIndex, startIndex))
        val clippedEnd = min(text.length, maxOf(0, endIndex, clippedStart))
        return FillerContentSlice(
            start,
            end,
            text.substring(clippedStart, clippedEnd),
        )
    }

    override fun withText(text: String): ContentSlice {
        return FillerContentSlice(start, end, text)
    }

    companion object {
        /**
         * Construct a filler slice originating from a single [cursor]
         */
        fun fromCursor(cursor: ContentCursor, text: String): FillerContentSlice {
            return FillerContentSlice(cursor, cursor, text)
        }

        fun createPrefixFillerForText(prefixText: String, contentText: ContentText): FillerContentSlice =
            fromCursor(contentText.start, prefixText)

        fun createSuffixFillerForText(contentText: ContentText, suffixText: String): FillerContentSlice =
            fromCursor(contentText.end, suffixText)

        /**
         * Construct a filler slice spanning the entire element with the [text] provided
         */
        fun fromElementReference(
            elementReference: ContentElementReference,
            text: String,
        ): FillerContentSlice {
            return FillerContentSlice(elementReference.start, elementReference.end, text)
        }
    }
}
