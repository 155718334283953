@file:OptIn(ExperimentalJsExport::class)

package com.speechify.client.api.util

import com.speechify.client.api.diagnostics.Log
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
interface Destructible {
    fun destroy()
}

/**
 * Use where the client needs to await for the finish of any concurrent Jobs (awaits for the cooperation over the
 * cancellation)
 */
internal interface AsyncDestructible {
    suspend fun destroyAndAwaitFinish()
}

/**
 * A try-with-resources pattern primitive, equivalent of [`use` that Kotlin has available for JVM](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/use.html).
 * (The try-with-resources pattern is for example described for Java [here](https://docs.oracle.com/javase/tutorial/essential/exceptions/tryResourceClose.html)
 * and Java has the special `try () {}` syntax for it, which [Kotlin doesn't have at the moment](https://discuss.kotlinlang.org/t/kotlin-needs-try-with-resources/214/7).)
 * @param suppressExceptionFromDestroyEvenOnSuccess - When true, an exception (default is `false`, as per all other
 * languages' try-with-resources solutions).
 */
// TODO consider using expect/actual to reuse Java's readily available logic (e.g. like [here](https://stackoverflow.com/a/53356104))
internal inline fun <DestructibleVal : Destructible, BlockResult> DestructibleVal.use(
    suppressExceptionFromDestroyEvenOnSuccess: Boolean = false,
    block: (DestructibleVal) -> BlockResult,
):
    BlockResult {
    /* Inspired by [this](https://stackoverflow.com/questions/53355873/is-there-a-kotlin-multiplatform-feature-or-pattern-that-can-help-to-implement-a)
       But we prevent a double invoke of `destroy()` on exception from `destroy()` (by not doing a `destroy()` inside
        the try).
     */
    val result = try {
        block(this)
    } catch (first: Throwable) {
        try {
            destroy()
        } catch (second: Throwable) {
            first.addSuppressed(second)
        }
        throw first
    }
    if (suppressExceptionFromDestroyEvenOnSuccess) {
        try {
            destroy()
        } catch (e: Throwable) {
            Log.e(
                message = "Got exception from `destroy()` after a block that finished successfully." +
                    " The exception that is logged here will be suppressed, as it was explicitly requested that it" +
                    "does not interrupt the flow.",
                exception = e,
                sourceAreaId = "Destructible.use",
            )
            // Don't rethrow intentionally, as per the above log message
        }
    } else {
        // In this flow, a throw from `destroy` will normally interrupt control flow and propagate the exception
        destroy()
    }
    return result
}

/**
 * A version of [use] for when the destructible(s) are not available at the time of the call of [use], but later.
 */
internal inline fun <R> useWithLateDestructibles(
    suppressExceptionFromDestroyEvenOnSuccess: Boolean = false,
    block: (context: UseWithLateDestructiblesContext) -> R,
): R {
    val destructibles = mutableListOf<Destructible>()
    val compositeDestructible = object : Destructible {
        override fun destroy() {
            destructibles.destroyAllCapturingAllExceptionsUnderFirst()
        }
    }

    return compositeDestructible.use(
        suppressExceptionFromDestroyEvenOnSuccess = suppressExceptionFromDestroyEvenOnSuccess,
    ) {
        block(
            /* context = */ object : UseWithLateDestructiblesContext {
                override fun addDestructible(destructible: Destructible) {
                    destructibles.add(destructible)
                }
            },
        )
    }
}

internal interface UseWithLateDestructiblesContext {
    fun addDestructible(destructible: Destructible)
}

private fun Iterable<Destructible>.destroyAllCapturingAllExceptionsUnderFirst() {
    var firstException: Throwable? = null
    for (destructible in this) {
        try {
            destructible.destroy()
        } catch (e: Throwable) {
            if (firstException == null) {
                firstException = e
            } else {
                firstException.addSuppressed(e)
            }
        }
    }
    if (firstException != null) {
        throw firstException
    }
}
