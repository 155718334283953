package com.speechify.client.api.util

import kotlin.js.JsExport

@JsExport
class LazyLoadedItems<T> internal constructor(
    @Suppress("NON_EXPORTABLE_TYPE") val items: Array<T>,
    val hasMoreItems: Boolean,
    private val itemsPerPage: Int,
    private val getMoreItems: (currentItems: Array<T>, callback: Callback<Array<T>>) -> Unit,
) {
    fun loadMoreItems(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<LazyLoadedItems<T>>) {
        if (hasMoreItems) {
            getMoreItems(items) { newItems ->
                when (newItems) {
                    is Result.Success -> callback(
                        LazyLoadedItems(
                            items + newItems.value,
                            hasMoreItems = newItems.value.size >= itemsPerPage,
                            itemsPerPage,
                            getMoreItems,
                        ).successfully(),
                    )
                    is Result.Failure -> callback(newItems)
                }
            }
        } else {
            callback(LazyLoadedItems(items, false, itemsPerPage, getMoreItems).successfully())
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as LazyLoadedItems<*>

        if (!items.contentEquals(other.items)) return false
        if (hasMoreItems != other.hasMoreItems) return false

        return true
    }

    override fun hashCode(): Int {
        var result = items.contentHashCode()
        val hasMoreItemsAsInt = if (hasMoreItems) 1 else 0
        result = 31 * result + hasMoreItemsAsInt
        return result
    }
}
