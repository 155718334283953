package com.speechify.client.helpers.content.standard.book.heuristics.v2.models

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.util.images.BoundingBox

internal data class ContentLine(
    val box: BoundingBox,
    val rawTextContentItems: List<BookPageTextContentItem>,
    val position: LinePosition,
) {
    val plainText = rawTextContentItems.joinToString(" ") { it.text.text }
}

internal enum class LinePosition {
    VERTICAL,
    HORIZONTAL,
}
