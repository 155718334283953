package com.speechify.client.reader.core

import com.speechify.client.internal.util.extensions.collections.flows.debounceInstances
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow

internal class ReadingLocationHelper(
    scope: CoroutineScope,
) : Helper<ReadingLocationState>(scope = scope) {
    override val stateFlow = MutableStateFlow(ReadingLocationState(location = null))
    override val initialState = stateFlow.value

    init {
        commands
            .debounceInstances<ReadingLocationCommand.SetReadingLocation>(periodMillis = 100)
            .onEachInstance<ReadingLocationCommand.SetReadingLocation> {
                stateFlow.value = ReadingLocationState(location = it.location)
            }
            .launchInHelper()
    }
}

internal sealed class ReadingLocationCommand {
    data class SetReadingLocation(val location: SerialLocation)
}

internal data class ReadingLocationState(
    val location: SerialLocation?,
)
