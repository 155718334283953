package com.speechify.client.api.util.images

import com.speechify.client.api.util.boundary.BoundaryPair
import kotlin.js.JsExport
import kotlin.math.atan2

// Defines a 2D coordinate transform. Notation copied from CSS
// https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix()
@kotlinx.serialization.Serializable
@JsExport
data class CoordinateTransform(
    val a: Double,
    val b: Double,
    val c: Double,
    val d: Double,
    val tx: Double,
    val ty: Double,
) {

    val matrix: Array<Double> get() = arrayOf(a, b, c, d, tx, ty)

    fun scaleBy(scale: Double): CoordinateTransform {
        return scaleIndividually(scale, scale)
    }

    fun scaleIndividually(widthFactor: Double, heightFactor: Double): CoordinateTransform {
        return CoordinateTransform(
            a * widthFactor,
            b,
            c,
            d * heightFactor,
            tx * widthFactor,
            ty * heightFactor,
        )
    }

    fun translate(x: Double, y: Double): CoordinateTransform {
        return CoordinateTransform(a, b, c, d, tx + x, ty + y)
    }

    fun apply(x: Double, y: Double): BoundaryPair<Double, Double> {
        return BoundaryPair(a * x + c * y + tx, b * x + d * y + ty)
    }

    fun getAngle(): Double {
        val p1 = apply(0.0, 0.0)
        val p2 = apply(1.0, 0.0)
        val dx = p2.first - p1.first
        val dy = p2.second - p1.second
        return atan2(dy, dx)
    }

    companion object {
        val identity = CoordinateTransform(1.0, 0.0, 0.0, 1.0, 0.0, 0.0)
        fun fromMatrix(matrix: Array<Double>): CoordinateTransform {
            check(matrix.size == 6)
            return CoordinateTransform(
                matrix[0],
                matrix[1],
                matrix[2],
                matrix[3],
                matrix[4],
                matrix[5],
            )
        }

        fun fromMatrixWithFlippedViewport(
            matrix: Array<Double>,
            viewport: Viewport,
        ): CoordinateTransform {
            check(matrix.size == 6)
            return CoordinateTransform(
                matrix[0],
                matrix[1],
                matrix[2],
                matrix[3],
                matrix[4],
                viewport.height - matrix[5],
            )
        }
    }
}

@JsExport
object CoordinateTransformUtils {
    fun fromMatrix(matrix: Array<Double>): CoordinateTransform {
        return CoordinateTransform.fromMatrix(matrix)
    }

    fun fromMatrixWithFlippedViewport(matrix: Array<Double>, viewport: Viewport):
        CoordinateTransform {
        return CoordinateTransform.fromMatrixWithFlippedViewport(matrix, viewport)
    }
}
