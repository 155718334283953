package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.sync.AtomicBool
import com.speechify.client.internal.sync.BlockingWrappingMutex
import com.speechify.client.internal.util.TryGetResult
import com.speechify.client.internal.util.extensions.collections.filterValueIsInstance
import com.speechify.client.internal.util.extensions.collections.mapValues
import com.speechify.client.internal.util.extensions.collections.zipEnsuringSameLength
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue

/**
 * Wraps a [MutableMap] into a thread-safe version (a bit reduced in functionality).
 * An equivalent of [JVM's `synchronizedMap()`](https://docs.oracle.com/javase/8/docs/api/java/util/Collections.html#synchronizedMap-java.util.Map-)
 */
internal fun <K, V> MutableMap<K, V>.asBlockingThreadsafeMap(): BlockingThreadsafeMap<K, V> =
    this.asMutableMapWithBasics()
        .asBlockingThreadsafeMap()

internal fun <K, V> MutableMapWithBasicsThreadUnsafe<K, V>.asBlockingThreadsafeMap(): BlockingThreadsafeMap<K, V> =
    BlockingThreadsafeMap(this)

/**
 * A markup interface that allows requiring a thread-safe map.
 */
internal interface ThreadSafeMapWithBasics<K, V> :
    /** Implement just the `Basic` map, so that we don't need to implement complex things like the mutable
     * [MutableMap.entries] set, which would require quite some code to be thread-safe. */
        MutableMapWithBasics<K, V>,
        ThreadSafeMutableMapSyncOperations<K, V>

/**
 * Marks a thread-safety of the simple synchronous operations of a map.
 *
 * See also [ThreadSafeMutableMapAsyncOperations].
 */
internal interface ThreadSafeMutableMapSyncOperations<K, V> :
    MapRetrieval<K, V>,
    MutableMapRemoval<K, V>,
    MutableMapInsertionOrFailure<K, V>,
    MapRetrievalOfNullable<K, V>

/**
 * A map that is only filled while getting values from it.
 * A good foundation for a very simple map-based cache.
 *
 * NOTE: All implementations must be thread-safe!
 * A sync-version of [MapFilledWhileAsyncGetting].
 */
internal interface MapFilledWhileGetting<K, V> :
    MutableMapInsertionOrFailure<K, V> {
    fun getOrPut(
        key: K,
        defaultValue: () -> V,
    ): V

    override fun add(key: K, value: V) {
        val wasDefaultValueEvaluated = AtomicBool(false)
        getOrPut(
            key = key,
            defaultValue = {
                wasDefaultValueEvaluated.set(true)
                value
            },
        )
            .ignoreValue()

        if (wasDefaultValueEvaluated.get().not()) {
            throw IllegalStateException("Key $key already has a value")
        }
    }
}

/**
 * A map that is only filled while getting values from it.
 * A good foundation for a very simple map-based cache.
 *
 * NOTE: All implementations must be thread-safe!
 * A sync-version of [MapFilledWhileAsyncGettingWithMulti].
 */
internal interface MapFilledWhileGettingWithMulti<K, V> :
    MapFilledWhileGetting<K, V> {
    /**
     * A version of [MapFilledWhileGetting.getOrPut] that minimizes locks - useful when always producing multiple values at once.
     */
    fun getOrPutMulti(
        keys: List<K>,
        produceMissingValues: (keysOfMissingEntries: List<K>) -> List<V>,
    ): List<V>

    override fun getOrPut(
        key: K,
        defaultValue: () -> V,
    ): V =
        getOrPutMulti(listOf(key)) { listOf(defaultValue()) }.single()
}

/**
 * Use this if you need a thread safe map that does not require suspending when obtaining a value.
 * If you require suspending, use [LockingThreadsafeMap] instead.
 */
internal class BlockingThreadsafeMap<K, V>(
    backingMap: MutableMapWithBasicsThreadUnsafe<K, V> = mutableMapOf<K, V>()
        .asMutableMapWithBasics(),
) :
    ThreadSafeMapWithBasics<K, V>,
    MapFilledWhileGetting<K, V>,
    MapFilledWhileGettingWithMulti<K, V> {

    internal constructor(
        initialEntries: List<Pair<K, V>>,
    ) : this(
        backingMap = mutableMapOf(
            *initialEntries.toTypedArray(),
        )
            .asMutableMapWithBasics(),
    )

    private val lockedMap: BlockingWrappingMutex<MutableMapWithBasicsThreadUnsafe<K, V>> =
        BlockingWrappingMutex.of(backingMap)

    override fun getOrPutMulti(
        keys: List<K>,
        produceMissingValues: (keysOfMissingEntries: List<K>) -> List<V>,
    ): List<V> {
        if (keys.isEmpty()) return emptyList()

        return lockedMap.locked { map ->
            val indexedKeys = keys.withIndex()

            /* Hold onto the queried entries, to only look up the underlying map once: */
            val entryTries = indexedKeys
                .map { indexedKey ->
                    indexedKey to map.getNullableThreadUnsafe(indexedKey.value)
                }
                .toList()

            val successfulTries = entryTries
                .map { (indexedKey, tryResult) ->
                    IndexedValue(
                        index = indexedKey.index,
                        value = tryResult,
                    )
                }
                .filterValueIsInstance<TryGetResult.Success<V>>()
                .mapValues { it.value }

            val missingKeysIndexed = entryTries
                .mapNotNull { (indexedKey, tryResult) ->
                    when (tryResult) {
                        is TryGetResult.Success -> null
                        TryGetResult.Unsuccessful -> indexedKey
                    }
                }

            if (missingKeysIndexed.isEmpty()) {
                /* All keys present - just return all queried values: */
                return@locked successfulTries
                    .map { indexedValue ->
                        indexedValue.value
                    }
                    .toList()
            } else {
                /**
                 * [missingKeysIndexed] is not empty, so need to produce values for them.
                 */
                val producedValuesByIndexedKeys =
                    produceMissingValues(
                        missingKeysIndexed.map { it.value },
                    )
                        .zipEnsuringSameLength(missingKeysIndexed)

                for ((producedValue, indexedKey) in producedValuesByIndexedKeys) {
                    map.put(
                        key = indexedKey.value,
                        value = producedValue,
                    )
                }

                val result = (
                    successfulTries +
                        (
                            producedValuesByIndexedKeys.map { (producedValue, indexedKey) ->
                                IndexedValue(
                                    index = indexedKey.index,
                                    value = producedValue,
                                )
                            }
                            )
                    )
                    .sortedBy { it.index }
                    .map { it.value }
                    .toList()
                return@locked result
            }
        }
    }

    inline fun update(
        key: K,
        crossinline getNewValue: (oldValue: V?) -> V,
    ) = lockedMap.locked {
        it[key] = getNewValue(it[key])
    }

    override fun get(key: K): V? =
        lockedMap.locked {
            it[key]
        }

    override fun remove(key: K): V? =
        lockedMap.locked {
            it.remove(key)
        }

    override fun put(key: K, value: V) =
        lockedMap.locked {
            it.put(key, value)
        }

    fun putAll(from: Map<out K, V>) =
        lockedMap.locked {
            for ((key, value) in from) {
                it.put(key, value)
            }
        }

    override val entries: List<Pair<K, V>>
        get() =
            lockedMap.locked {
                it.entries.toMap()
            }
                .toList()

    override fun clear() =
        lockedMap.locked {
            it.clear()
        }

    override fun getNullable(key: K): TryGetResult<V> =
        lockedMap.locked {
            it.getNullableThreadUnsafe(key)
        }

    /**
     * An internal implementation of [getNullable] - only safe if used inside the lock over [this].
     */
    private fun MutableMapWithBasicsThreadUnsafe<K, V>.getNullableThreadUnsafe(
        key: K,
    ): TryGetResult<V> =
        when (val value = this@getNullableThreadUnsafe[key]) {
            null -> {
                if (this@getNullableThreadUnsafe.containsKey(key)) {
                    @Suppress(
                        /**
                         * Using `as V` instead of `!!` is a way to #SupportHoldingNullValues
                         * The `null as V` will be safe if put inside a lock, because [this] is strongly typed to be [V],
                         * so if this is what `[key]` returned while `containsKey(key)` was true, then V must be nullable.
                         *
                         */
                        "UNCHECKED_CAST",
                    )
                    TryGetResult.Success(
                        value = null as V,
                    )
                } else {
                    TryGetResult.Unsuccessful
                }
            }
            else -> TryGetResult.Success(value)
        }
}
