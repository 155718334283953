package com.speechify.client.api.content

import com.speechify.client.internal.util.findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch
import kotlin.math.round

internal data class CompositeContentText internal constructor(private val _slices: List<ContentSlice>) : ContentText {

    override val slices: Array<ContentSlice> by lazy { _slices.toTypedArray() }

    override val start: ContentCursor get() = slices.first().start

    override val end: ContentCursor get() = slices.last().end

    override val length: Int get() = slices.sumOf { it.length }

    override val text: String get() = slices.joinToString("") { it.text }

    override fun replaceAll(pattern: String, replacement: (match: String) -> String): ContentText {
        if (length == 0) return this

        var prevStart = 0
        val subSplits = mutableListOf<ContentText>()
        for (range in findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text)) {
            subSplits.add(this.slice(prevStart, range.startIndex))
            val replacementText = replacement(this.text.substring(range.startIndex, range.endIndexExclusive))
            subSplits.add(this.slice(range.startIndex, range.endIndexExclusive).withText(replacementText))
            prevStart = range.endIndexExclusive
        }

        return ContentTextUtils.concat(subSplits + this.slice(prevStart, length))
    }

    override fun split(pattern: String): Array<ContentText> {
        if (this.length == 0) return arrayOf(this)

        val ranges = findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text)
        var prevStart = 0
        val splits = ranges.map { range ->
            val thisPrevStart = prevStart
            prevStart = range.endIndexExclusive
            if (range.endIndexExclusive >= this.length) {
                val normalSplit = this.slice(thisPrevStart, range.startIndex)
                val padding =
                    fromSlices(listOf(FillerContentSlice(this.end, this.end, "")))
                return@map ContentTextUtils.concat(sequenceOf(normalSplit, padding))
            } else {
                return@map this.slice(thisPrevStart, range.startIndex)
            }
        }.toTypedArray()

        // Last item is the text remaining after the end of the last matched range
        return splits + this.slice(prevStart, this.length)
    }

    override fun matchAll(pattern: String): Array<ContentText> {
        val ranges = findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text)
        return ranges.map { range -> this.slice(range.startIndex, range.endIndexExclusive) }.toTypedArray()
    }

    override fun slice(startIndex: Int, endIndex: Int): ContentText {
        // Iterate through slices, taking sub-slices of all the ones in the requested range
        val (_, newSlices) = slices.fold(0 to mutableListOf<ContentSlice>()) { acc, slice ->
            val (currentSliceStartIndex, newSlices) = acc
            val currentSliceLength = slice.length
            val currentSliceEndIndex = currentSliceStartIndex + currentSliceLength
            if (currentSliceEndIndex > startIndex && currentSliceStartIndex < endIndex ||

                // Trivial slice should be taken from the slice containing the start index
                (
                    startIndex == endIndex &&
                        startIndex >= currentSliceStartIndex &&
                        startIndex < currentSliceEndIndex
                    )
            ) {
                newSlices.add(slice.slice(startIndex - currentSliceStartIndex, endIndex - currentSliceStartIndex))
                return@fold currentSliceEndIndex to newSlices
            } else {
                return@fold currentSliceEndIndex to newSlices
            }
        }

        // If no segments matched, add an empty slice spanning the content
        return if (newSlices.isEmpty()) {
            FillerContentSlice(start, end, "")
        } else {
            fromSlices(newSlices)
        }
    }

    override fun getFirstIndexOfCursor(cursor: ContentCursor): Int {
        if (length == 0) return -1

        // Scan slices, looking for the first slice with positions greater than cursor
        val (index, _) = slices
            .fold(-1 to false) { scanState: Pair<Int, Boolean>, slice: ContentSlice ->
                val (previousCharIndex, shouldStopAtNextCharIndex) = scanState
                when {
                    // When we find that slice
                    !cursor.isAfter(slice.end) -> {
                        // If slice is empty handle edge case. Otherwise this slice has the char we want
                        if (slice.length == 0) {
                            // First index of our cursor is the char *before* if there is one
                            if (previousCharIndex > -1) {
                                return previousCharIndex
                            } // Otherwise keep scanning but stop at next char we find
                            else {
                                return@fold previousCharIndex to true
                            }
                        } else {
                            return previousCharIndex + 1 + slice.getFirstIndexOfCursor(cursor)
                        }
                    }

                    // If we found the slice but it was empty, stop at the first char of this one if it isn't empty
                    shouldStopAtNextCharIndex && slice.length > 0 -> return previousCharIndex + 1

                    // Otherwise keep scanning
                    else ->
                        return@fold previousCharIndex + slice.length to
                            shouldStopAtNextCharIndex
                }
            }

        return index
    }

    override fun getLastIndexOfCursor(cursor: ContentCursor): Int {
        if (length == 0) return -1

        // Scan slices backwards, looking for first slice with positions less than cursor
        val (index, _) = _slices.asReversed()
            .fold(length to false) { scanState: Pair<Int, Boolean>, slice: ContentSlice ->
                val (nextCharIndex, shouldStopAtPreviousCharIndex) = scanState
                when {
                    // When we find that slice
                    !cursor.isBefore(slice.start) -> {
                        // If slice is empty, handle edge case. Otherwise this slice has the char we want
                        if (slice.length == 0) {
                            // Last index of our cursor is the char *after* if there is one
                            if (nextCharIndex < length) {
                                return nextCharIndex
                            } // Otherwise keep scanning but stop at the next char we find
                            else {
                                return@fold nextCharIndex to true
                            }
                        } else {
                            return nextCharIndex - slice.length + slice.getLastIndexOfCursor(cursor)
                        }
                    }

                    // If we found the slice but it was empty, stop at the last char of this one if it isn't empty
                    shouldStopAtPreviousCharIndex && slice.length > 0 -> return nextCharIndex - 1

                    // Otherwise keep scanning backward
                    else ->
                        return@fold nextCharIndex - slice.length to
                            shouldStopAtPreviousCharIndex
                }
            }
        return index
    }

    override fun getFirstCursorAtIndex(characterIndex: Int): ContentCursor {
        var sliceOffset = 0
        // Find the slice that contains characterIndex, and ask it for the cursor
        slices.forEach { slice: ContentSlice ->
            if (sliceOffset + slice.length > characterIndex) {
                return slice.getFirstCursorAtIndex(characterIndex - sliceOffset)
            }
            sliceOffset += slice.length
        }

        // We're at the end of the text
        return this.end
    }

    override fun getLastCursorAtIndex(characterIndex: Int): ContentCursor {
        var sliceOffset = 0
        // Find the slice that contains characterIndex, and ask it for the cursor
        slices.forEach { slice: ContentSlice ->
            if (sliceOffset + slice.length > characterIndex) {
                return slice.getLastCursorAtIndex(characterIndex - sliceOffset)
            }
            sliceOffset += slice.length
        }

        // We're at the end of the text
        return this.end
    }

    override fun containsCursor(cursor: ContentCursor): Boolean {
        return !start.isAfter(cursor) && !end.isBefore(cursor)
    }

    override fun withText(text: String): ContentText {
        val oldLength = this.length
        val newLength = text.length

        val scaleFactor = if (oldLength > 0) newLength.toDouble() / oldLength.toDouble() else 1.0
        var segmentStart = 0
        return fromSlices(
            this.slices.map { slice ->
                val segmentLength = slice.length
                val scaledSegmentLength = round(segmentLength * scaleFactor)
                val segmentEnd = (segmentStart + scaledSegmentLength).toInt()
                val newText = text.substring(segmentStart, segmentEnd)
                segmentStart = segmentEnd
                return@map slice.withText(newText)
            },
        )
    }

    companion object {
        internal fun fromSlices(slices: List<ContentSlice>): ContentText {
            check(slices.isNotEmpty()) { "Cannot create ContentText from an empty list of slices!" }
            return if (slices.size == 1) {
                slices.first()
            } else {
                CompositeContentText(slices)
            }
        }
    }
}
