package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.FillerContentSlice
import com.speechify.client.api.content.StaticContentIndexBase
import com.speechify.client.api.content.getCursorPositionAsLengthFraction
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.getAllBlocks
import com.speechify.client.api.content.view.standard.getContentTexts
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.util.text.groupingToWords.wordCount

/**
 * A [ContentIndex] that works by loading all the content of a [StandardView] up front.
 *
 * WARNING: THIS WILL BE A BIG PROBLEM FOR LARGE CONTENT.
 */
internal class EagerStandardIndex(
    private val standardView: StandardView,
) : StaticContentIndexBase() {

    lateinit var allText: ContentText

    override fun getCursorFromProgress(
        progress: ProgressFraction,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        val text = getAllText().orReturn { return@fromCo it }
        val charIndex = (text.length * progress).toInt()
        return@fromCo text.getFirstCursorAtIndex(charIndex).successfully()
    }

    override fun getProgressFromCursor(cursor: ContentCursor, callback: Callback<ProgressFraction>) = callback.fromCo {
        return@fromCo getAllText()
            .orReturn { return@fromCo it }
            .getCursorPositionAsLengthFraction(cursor)
            .successfully()
    }

    override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
        val text = getAllText().orReturn { return@fromCo it }
        return@fromCo ContentStats(
            EstimatedCount(text.text.wordCount(), 1.0),
            EstimatedCount(text.text.length, 1.0),
        ).successfully()
    }

    private suspend fun getAllText(): Result<ContentText> {
        if (::allText.isInitialized) {
            return allText.successfully()
        }

        val texts = standardView
            .getAllBlocks()
            .orReturn { return it }
            .flatMap {
                it.getContentTexts()
            }

        val text = if (texts.isEmpty()) {
            FillerContentSlice(
                standardView.start,
                standardView.end,
                "",
            )
        } else {
            ContentTextUtils.concat(texts)
        }
        allText = text
        return text.successfully()
    }

    override fun destroy() = Unit
}
