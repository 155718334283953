package com.speechify.client.api.telemetry

import kotlin.js.JsExport

/**
 * Options to configure the telemetry behaviour.
 *
 * **Note:**
 *
 * If the values passed in [withFlushPeriodMilliseconds] and [withMaxEventBufferSize] methods are not within the
 * accepted range, they are ignored in favor of a default value.
 *
 * | param | min | max | default
 * | ---   | --- | --- | --- |
 * | flushPeriodMilliseconds | 10_000 | 30_000 | [DEFAULT_FLUSH_PERIOD_MS] |
 * | maxEventBufferSize | 10 | [DEFAULT_MAX_EVENT_BUFFER_SIZE] | [DEFAULT_MAX_EVENT_BUFFER_SIZE] |
 */
@JsExport
class TelemetryOptions {

    var flushPeriodMilliseconds: Int = DEFAULT_FLUSH_PERIOD_MS
        private set(value) {
            if (value in 10_000..30_000) field = value
        }

    var maxEventBufferSize: Int = DEFAULT_MAX_EVENT_BUFFER_SIZE
        private set(value) {
            if (value in 10..DEFAULT_MAX_EVENT_BUFFER_SIZE) field = value
        }

    /**
     * The period in milliseconds at which telemetry events are flushed to the backend. Initial default is [DEFAULT_FLUSH_PERIOD_MS] ms
     * with the acceptable range 10,000 ms to 30,000 ms inclusive.
     *
     * If the value is not between the accepted range, this method call is ignored.
     */
    fun withFlushPeriodMilliseconds(periodMilliseconds: Int) = apply { flushPeriodMilliseconds = periodMilliseconds }

    /**
     * The maximum number of telemetry events that can be buffered between flushes. Initial default is [DEFAULT_MAX_EVENT_BUFFER_SIZE] with the
     * acceptable range 10 to 40 inclusive.
     *
     * If the value is not between the accepted range, this method call is ignored.
     */
    fun withMaxEventBufferSize(size: Int) = apply { maxEventBufferSize = size }
}
