package com.speechify.client.reader.classic

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.internal.util.IdGenerator
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.HoveredSentenceHelperCommand
import com.speechify.client.reader.core.PlaybackCommand
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ReadingLocationCommand
import com.speechify.client.reader.core.RelativeNavigationIntent
import com.speechify.client.reader.core.RobustLocation
import com.speechify.client.reader.core.SelectionGranularity
import com.speechify.client.reader.core.SelectionHelperCommand
import com.speechify.client.reader.core.SerialLocation
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlin.js.JsExport

@JsExport
sealed class ClassicBlock {

    /**
     * An identifier that will be stable for this block, even if the [ClassicView] state changes to include additional
     * blocks. This helps clients improve rendering performance, since they can trust that if they have already
     * rendered a [ClassicBlock] with a given [key], they can skip re-rendering if they receive it again from a state
     * update in the [ClassicView].
     */
    val key: String = IdGenerator.getGuidAsString()

    internal abstract fun containsLocation(location: SerialLocation): Boolean
    internal abstract fun isLocationBeforeOrAtEnd(location: SerialLocation): Boolean
    internal abstract fun isLocationBefore(location: SerialLocation): Boolean
    internal abstract fun isLocationAfter(location: SerialLocation): Boolean

    class Paragraph internal constructor(
        private val scope: CoroutineScope,
        private val readerFeatures: Flow<ReaderFeatures>,
        private val builder: ClassicBlockBuilder.Text,
    ) : ClassicBlock() {
        val formattedText: FormattedText by lazy {
            builder.buildText(scope, readerFeatures)
        }
        val formattingTree: FormattingTree by lazy {
            builder.buildTree(scope, readerFeatures)
        }

        override fun containsLocation(location: SerialLocation): Boolean {
            return builder.containsLocation(location)
        }

        override fun isLocationBeforeOrAtEnd(location: SerialLocation) = builder.isLocationBeforeOrAtEnd(location)
        override fun isLocationBefore(location: SerialLocation) = builder.isLocationBefore(location)
        override fun isLocationAfter(location: SerialLocation) = builder.isLocationAfter(location)
    }

    class Heading internal constructor(
        private val scope: CoroutineScope,
        private val readerFeatures: Flow<ReaderFeatures>,
        private val builder: ClassicBlockBuilder.Text,
        val level: Int,
    ) : ClassicBlock() {
        val formattedText: FormattedText by lazy {
            builder.buildText(scope, readerFeatures)
        }
        val formattingTree: FormattingTree by lazy {
            builder.buildTree(scope, readerFeatures)
        }

        override fun containsLocation(location: SerialLocation): Boolean {
            return builder.containsLocation(location)
        }

        override fun isLocationBeforeOrAtEnd(location: SerialLocation) = builder.isLocationBeforeOrAtEnd(location)
        override fun isLocationBefore(location: SerialLocation) = builder.isLocationBefore(location)
        override fun isLocationAfter(location: SerialLocation) = builder.isLocationAfter(location)
    }

    sealed class Image() : ClassicBlock() {
        abstract val height: Int?
        abstract val width: Int?
        abstract val altText: String?

        // cut from initial scope bc minor feature and added complexity to algorithms
        // abstract val link: Link?
        override fun containsLocation(location: SerialLocation): Boolean {
            // TODO: impl containsLocation on the image builder and pass thru here
            return false
        }

        override fun isLocationBeforeOrAtEnd(location: SerialLocation): Boolean {
            // TODO: impl isLocationBeforeOrAtEnd on the image builder and pass thru here
            return false
        }

        override fun isLocationBefore(location: SerialLocation): Boolean {
            // TODO: impl isLocationBefore on the image builder and pass thru here
            return false
        }

        override fun isLocationAfter(location: SerialLocation): Boolean {
            // TODO: impl isLocationAfter on the image builder and pass thru here
            return false
        }

        class Local(
            override val height: Int?,
            override val width: Int?,
            override val altText: String?,
            @Suppress("NON_EXPORTABLE_TYPE")
            val data: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        ) : Image()

        class Remote(
            override val height: Int?,
            override val width: Int?,
            override val altText: String?,
            val url: String,
        ) : Image()
    }

    /**
     * A list structure which can hold different items, including another list. It should be used by accessing either
     * [flat] or [nested], depending on which representation is best suited for the client platform. Since these are
     * calculated lazily, no additional memory or processing is used for the representation which is not being used.
     */
    class List internal constructor(
        internal val builder: ClassicBlockBuilder.List,
        internal val style: Style?,
        internal val scope: CoroutineScope,
        internal val readerFeatures: Flow<ReaderFeatures>,
    ) : ClassicBlock() {
        // TODO: Include more styles if necessary
        enum class Style {
            None,
            Number,
            Dash,
            Bullet,
            Circle,
            Square,
        }

        /**
         * Returns the list as a flat representation where all items are on the same level, but nested ones
         * are identified by an increase in the indent level
         */
        val flat: FlatList by lazy {
            val flatListItems = mutableListOf<FlatList.Item>()
            builder.items.forEach { item ->
                flatListItems.addAll(
                    builder.buildFlatListItems(item.elements, 0, style, scope, readerFeatures),
                )
            }
            FlatList(flatListItems.toTypedArray())
        }

        /**
         * Returns the list as a nested structure where each item can also contain another list
         */
        val nested: NestedList by lazy {
            val listItems = builder.items.map {
                NestedList.Item(
                    builder.buildNestedItems(it.elements.toTypedArray(), scope, readerFeatures).toTypedArray(),
                )
            }.toTypedArray()
            NestedList(listItems, style)
        }

        /**
         * A flat representation of a List
         */
        class FlatList(val items: Array<Item>) {
            /**
             * A flat list item which contains only text. The [indentLevel] is used to determine the indentation of
             * an item, helping with displaying nested sub-lists. All items in the root list have an indent level of 0.
             * The indent level of a sub-list is the indent level of the parent list + 1
             */
            class Item internal constructor(
                val indentLevel: Int,
                val style: Style,
                private val builder: ClassicBlockBuilder.Text,
                private val scope: CoroutineScope,
                private val readerFeatures: Flow<ReaderFeatures>,
            ) {

                // Clients who rely on the flat list, only need the formatted text, not the entire formatting tree
                val formattedText: FormattedText by lazy {
                    builder.buildText(scope, readerFeatures)
                }
            }
        }

        /**
         * A Nest list item can hold multiple elements. These elements can either be a [NestedList.Text] or
         * another [NestedList]
         * We use this interface to make sure that an `Item` only contains valid objects
         */
        interface NestedListItemContent

        /**
         * A list which items can contain another [NestedList], making it similar to how HTML represents UL elements
         */
        class NestedList internal constructor(val items: Array<Item>, val style: Style?) : NestedListItemContent {
            /**
             * A nested list item which contains only text
             */
            class Text internal constructor(
                private val builder: ClassicBlockBuilder.Text,
                private val scope: CoroutineScope,
                private val readerFeatures: Flow<ReaderFeatures>,
            ) : NestedListItemContent {
                // Clients that rely on the nested list only need the formatted tree, not the formatted text
                val formattingTree: FormattingTree by lazy {
                    builder.buildTree(scope, readerFeatures)
                }
            }

            /**
             * A nested list item which can hold multiple elements. An element is either a [Text] or
             * another [NestedList]
             */
            class Item internal constructor(val elements: Array<NestedListItemContent>)
        }

        override fun containsLocation(location: SerialLocation): Boolean =
            builder.items.any { it.containsLocation(location) }

        override fun isLocationBeforeOrAtEnd(location: SerialLocation): Boolean =
            builder.items.all { it.isLocationBeforeOrAtEnd(location) }

        override fun isLocationBefore(location: SerialLocation): Boolean =
            builder.items.all { it.isLocationBefore(location) }

        override fun isLocationAfter(location: SerialLocation): Boolean =
            builder.items.all { it.isLocationAfter(location) }
    }

    class Table(val rows: Array<Row>) : ClassicBlock() {

        override fun containsLocation(location: SerialLocation): Boolean {
            return rows.any { it.cells.any { it.builder.containsLocation(location) } }
        }

        override fun isLocationBeforeOrAtEnd(location: SerialLocation) = rows.any { row ->
            row.cells.all { it.builder.isLocationBeforeOrAtEnd(location) }
        }

        override fun isLocationBefore(location: SerialLocation) = rows.any { row ->
            row.cells.all { it.builder.isLocationBefore(location) }
        }

        override fun isLocationAfter(location: SerialLocation) = rows.any { row ->
            row.cells.all { it.builder.isLocationAfter(location) }
        }

        class Row(val cells: Array<Cell>) {
            // TODO: add heading cell modeling
            class Cell internal constructor(
                private val scope: CoroutineScope,
                private val readerFeatures: Flow<ReaderFeatures>,
                internal val builder: ClassicBlockBuilder.Text,
                val rowSpan: Int,
                val colSpan: Int,
                val isHeader: Boolean,
            ) {
                val formattedText: FormattedText by lazy {
                    builder.buildText(scope, readerFeatures)
                }
                val formattingTree: FormattingTree by lazy {
                    builder.buildTree(scope, readerFeatures)
                }
            }
        }
    }
}

@JsExport
sealed class FormattingTree {
    data class Text(
        internal val dispatch: CommandDispatch,
        internal val contentText: ContentText,
        val featuresHelper: ClassicTextFeaturesHelper,
    ) : FormattingTree() {
        val text: String get() = contentText.text

        // text-based interactions for controlling other aspects of the experience
        fun tapToJump(charIndex: Int, withRelativeNavigation: RelativeNavigationIntent) {
            dispatch(
                PlaybackCommand.TapToJump(
                    location = SerialLocation(contentText.getFirstCursorAtIndex(characterIndex = charIndex)),
                    relativeNavigationIntent = withRelativeNavigation,
                ),
            )
        }

        /**
         * Starts playback from a word or sentence in this text that contains the specified [charIndex],
         * based on the given [withRelativeNavigation] intent.
         *
         * The active playback locations are communicated to
         * [listeners of `ClassicTextFeaturesHelper` state changes][ClassicTextFeaturesHelper.addStateChangeListener].
         *
         * @param charIndex The index of the character in this text from which playback should start.
         * @param withRelativeNavigation The intent specifying navigation relative to the [charIndex]
         *   before starting playback.
         * @param enableAutoscroll `true` to enable autoscroll and scroll to the playback location.
         *   `false` by default.
         */
        fun tapToPlay(
            charIndex: Int,
            withRelativeNavigation: RelativeNavigationIntent,
            enableAutoscroll: Boolean = false,
        ) {
            dispatch(
                PlaybackCommand.TapToPlay(
                    location = SerialLocation(contentText.getFirstCursorAtIndex(characterIndex = charIndex)),
                    relativeNavigationIntent = withRelativeNavigation,
                    enableAutoscroll = enableAutoscroll,
                ),
            )
        }

        fun clearSelection() {
            dispatch(
                SelectionHelperCommand.ClearSelection,
            )
        }

        /**
         * Selects a character or a word in this text at the specified [charIndex],
         * based on the given selection [granularity].
         *
         * The resulting boundaries of the active selection are communicated to
         * [listeners of `ClassicTextFeaturesHelper` state changes][ClassicTextFeaturesHelper.addStateChangeListener].
         *
         * @param charIndex The index of the character in this text to select.
         * @param granularity The selection granularity, determining whether a single character or the entire word
         *   containing the specified character should be selected. [SelectionGranularity.CHARACTER] by default.
         */
        fun select(charIndex: Int, granularity: SelectionGranularity = SelectionGranularity.CHARACTER) {
            dispatch(
                SelectionHelperCommand.Select(
                    location = SerialLocation(
                        contentText.getFirstCursorAtIndex(charIndex),
                    ),
                    granularity = granularity,
                ),
            )
        }

        fun hoverSentence(charIndex: Int) {
            dispatch(
                HoveredSentenceHelperCommand.HoverSentence(
                    SerialLocation(
                        contentText.getFirstCursorAtIndex(charIndex),
                    ),
                ),
            )
        }

        fun clearHoveredSentence() {
            dispatch(HoveredSentenceHelperCommand.ClearHoveredSentence)
        }

        fun markAsCurrentlyReading() {
            dispatch(
                ReadingLocationCommand.SetReadingLocation(
                    location = SerialLocation(
                        cursor = contentText.start,
                    ),
                ),
            )
        }
    }

    data class Element(
        val formatting: Formatting?,
        val children: Array<FormattingTree>,
    ) : FormattingTree()
}

@JsExport
class FormattedText internal constructor(
    private val dispatch: CommandDispatch,
    internal val contentText: ContentText,
    val formatting: Array<FormattedRange>,
    val featuresHelper: ClassicTextFeaturesHelper,
) {
    val text: String get() = contentText.text

    // text-based interactions for controlling other aspects of the experience
    fun tapToJump(charIndex: Int, withRelativeNavigation: RelativeNavigationIntent) {
        dispatch(
            PlaybackCommand.TapToJump(
                location = SerialLocation(contentText.getFirstCursorAtIndex(characterIndex = charIndex)),
                relativeNavigationIntent = withRelativeNavigation,
            ),
        )
    }

    /**
     * Starts playback from a word or sentence in this text that contains the specified [charIndex],
     * based on the given [withRelativeNavigation] intent.
     *
     * The active playback locations are communicated to
     * [listeners of `ClassicTextFeaturesHelper` state changes][ClassicTextFeaturesHelper.addStateChangeListener].
     *
     * @param charIndex The index of the character in this text from which playback should start.
     * @param withRelativeNavigation The intent specifying navigation relative to the [charIndex]
     *   before starting playback.
     * @param enableAutoscroll `true` to enable autoscroll and scroll to the playback location.
     *   `false` by default.
     */
    fun tapToPlay(charIndex: Int, withRelativeNavigation: RelativeNavigationIntent, enableAutoscroll: Boolean = false) {
        dispatch(
            PlaybackCommand.TapToPlay(
                location = SerialLocation(contentText.getFirstCursorAtIndex(characterIndex = charIndex)),
                relativeNavigationIntent = withRelativeNavigation,
                enableAutoscroll = enableAutoscroll,
            ),
        )
    }

    fun clearSelection() {
        dispatch(
            SelectionHelperCommand.ClearSelection,
        )
    }

    /**
     * Selects a character or a word in this text at the specified [charIndex],
     * based on the given selection [granularity].
     *
     * The resulting boundaries of the active selection are communicated to
     * [listeners of `ClassicTextFeaturesHelper` state changes][ClassicTextFeaturesHelper.addStateChangeListener].
     *
     * @param charIndex The index of the character in this text to select.
     * @param granularity The selection granularity, determining whether a single character or the entire word
     *   containing the specified character should be selected. [SelectionGranularity.CHARACTER] by default.
     */
    fun select(charIndex: Int, granularity: SelectionGranularity = SelectionGranularity.CHARACTER) {
        dispatch(
            SelectionHelperCommand.Select(
                location = SerialLocation(
                    contentText.getFirstCursorAtIndex(charIndex),
                ),
                granularity = granularity,
            ),
        )
    }

    fun hoverSentence(charIndex: Int) {
        dispatch(
            HoveredSentenceHelperCommand.HoverSentence(
                SerialLocation(
                    contentText.getFirstCursorAtIndex(charIndex),
                ),
            ),
        )
    }

    fun clearHoveredSentence() {
        dispatch(HoveredSentenceHelperCommand.ClearHoveredSentence)
    }

    fun markAsCurrentlyReading() {
        dispatch(
            ReadingLocationCommand.SetReadingLocation(
                location = SerialLocation(
                    cursor = contentText.start,
                ),
            ),
        )
    }
}

@JsExport
data class FormattedRange(
    val range: IndexRange,
    val formatting: Formatting,
) {
    internal fun offsetBy(offset: Int): FormattedRange = copy(
        range = range.offsetBy(offset),
    )

    internal fun joinedWith(other: FormattedRange): FormattedRange {
        check(other.formatting == formatting) { "Cannot join ranges with different formatting " }
        check(other.range.startIndex == 0) { "Cannot join range when second does not start at 0" }
        return this.copy(
            range = IndexRange(this.range.startIndex, this.range.endIndexExclusive + other.range.endIndexExclusive),
        )
    }
}

@JsExport
sealed class Link {
    class Internal internal constructor(
        internal val location: RobustLocation,
    ) : Link()

    class External(val url: String) : Link()
}

@JsExport
sealed class Formatting {
    object Italics : Formatting()

    object Underline : Formatting()

    object Bold : Formatting()

    object Code : Formatting()

    data class Linked(val link: Link) : Formatting()

    internal fun spanning(text: String): FormattedRange {
        return FormattedRange(
            range = IndexRange.spanning(text),
            formatting = this,
        )
    }

    override fun toString(): String {
        return this::class.simpleName ?: super.toString()
    }
}
