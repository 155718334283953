package com.speechify.client.api.util
/** Objects that _materialize_ aborts (AKA cancellations).
 *
 * Where these are needed:
 * * Interop with processes on platforms which don't have implicit cancellations, e.g. JS has explicit `AbortSignal`
 *   (triggered from `AbortController`, so we need to convert to it, so we need an object which materializes the
 *   cancellation (the hereby `AbortReceiverAsync` triggered from `AbortableStateAsync`). If the process has a single
 *   expected result, it is preferred to use these constructs inside `suspendCancellableCoroutine`, just to convert
 *   to a _materialized_ abort, by linking it via the [`invokeOnCancellation`](https://kotlinlang.org/api/kotlinx.coroutines/kotlinx-coroutines-core/kotlinx.coroutines/-cancellable-continuation/invoke-on-cancellation.html).
 * * Interop with program that doesn't finish with a single result (so `suspendCancellableCoroutine` is not feasible,
 *   and whose `Job` we don't have access to (could also be because it runs outside Kotlin).
 *
 * Where it doesn't make sense to use them:
 * * When both controller and the job are in Kotlin idiomatic suspend functions. Use `Job.cancel()` to cancel jobs
 *   of any kind (and make sure the process-to-be-cancelled cooperates).
 */

import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.LatchFlow
import com.speechify.client.internal.toDestructible
import kotlinx.coroutines.CancellationException
import kotlin.js.JsExport

@JsExport
sealed class AbortState<out Cause> {
    object NotAborted : AbortState<Nothing>()

    class Aborted<Cause>(val cause: Cause) : AbortState<Cause>()
}

/**
 * Like [AbortController](https://developer.mozilla.org/en-US/docs/Web/API/AbortController)
 *
 * TODO - It seems JS `AbortController`s have [just been introduced in `kotlinx.js`](https://github.com/JetBrains/kotlin-wrappers/pull/1663/files)
 *   Perhaps we can provide easy convertibility there (although BTW automatic cancelling of fetch [is still not in kotlin](https://github.com/Kotlin/kotlinx.coroutines/issues/3390#issuecomment-1227343806)
 */
@JsExport
interface AbortProducer<Cause> {
    fun abort(
        cause: Cause,
    )
}

/**
 * The read-only access to the 'aborted' state and event.
 *
 * Like [AbortSignal](https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal)
 */
@JsExport
interface AbortReceiver<Cause> {
    val isAborted: Boolean
        get() = abortState !== AbortState.NotAborted /* TODO - see if this default
          interface method works in all platforms (JS has problems?) */

    val abortState: AbortState<Cause>
}

/**
 * The read-only access to the 'aborted' state and event.
 *
 * Like [AbortSignal](https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal)
 *
 * The receiver of this object should subscribe to this parameter's
 * [AbortReceiverAsync.onAborted], to prevent any asynchronous tasks that it started from completing, for example cancelling any started timeouts
 * (in JS using `resetTimeout`), UI observers awaiting the content or web requests. This is important for preventing
 * memory leaks, performance, as well as clarity of diagnostics to developers (so that logs are not littered with noise
 * from old orphaned requests).
 */
@JsExport
abstract class AbortReceiverAsync<Cause> : AbortReceiver<Cause> {

    /**
     * Using this method allows taking advantage of Coroutine Context cancellation to stop the wait.
     */
    internal abstract suspend fun waitForAbortOrCancellationException(): Cause

    /**
     * NOTE: When no longer waiting on the abort, you need to call [Destructible.destroy] on the result to prevent
     * memory-leaks.
     */
    fun onAborted(
        handler: (Cause) -> Unit,
    ): Destructible =
        launchTask {
            val abortCause: Cause = try {
                waitForAbortOrCancellationException()
            } catch (cancellationException: CancellationException) {
                /* We don't log the `cancellationException` here, because it means the destructor which we returned was
                 called while the abort still didn't happen. So the caller just doesn't want us to wait on the abort.
                 So we leave, never calling the `handler`. */
                return@launchTask
            }

            handler(abortCause)
        }.toDestructible()
}

internal class AbortableStateAsync<Cause> {
    val receiver: AbortReceiverAsync<Cause> = AbortReceiverImpl()

    val producer: AbortProducer<Cause> = AbortProducerImpl()

    private val isAbortedLatch: LatchFlow<AbortState<Cause>> =
        LatchFlow(
            AbortState.NotAborted,
        )

    private inner class AbortProducerImpl : AbortProducer<Cause> {
        override fun abort(cause: Cause) {
            isAbortedLatch.value = AbortState.Aborted(cause)
        }
    }

    private inner class AbortReceiverImpl : AbortReceiverAsync<Cause>() {
        override val abortState: AbortState<Cause>
            get() = isAbortedLatch.value

        override suspend fun waitForAbortOrCancellationException(): Cause {
            val abortedState = isAbortedLatch.getChangedValue() as AbortState.Aborted<Cause>
            return abortedState.cause
        }
    }
}
