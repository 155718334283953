package com.speechify.client.api.adapters.ocr

import kotlinx.coroutines.sync.Semaphore
import kotlinx.coroutines.sync.withPermit

/**
 * The maximum number of concurrent OCR requests that can be pending at any single time (SDK will guard this).
 * This is shared between OCRAdapter and OCRableImage to ensure that the maximum number of concurrent OCR requests
 */
internal class OCRMaxConcurrencyGuard(
    maxConcurrency: Int,
) {
    init {
        check(maxConcurrency > 0) {
            "`maxConcurrency` must be > 0 but was $maxConcurrency."
        }
    }

    /**
     * Runs the block ensuring that the maximum number of concurrent OCR requests is not exceeded
     */
    suspend fun <T> runOCREnsuringConcurrencyLimit(block: suspend () -> T): T {
        return maxConcurrencyGuardingSemaphore.withPermit { block() }
    }

    /**
     * The semaphore that guards the maximum number of concurrent OCR requests
     */
    private val maxConcurrencyGuardingSemaphore: Semaphore = Semaphore(maxConcurrency)
}
