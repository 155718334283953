package com.speechify.client.reader.fixedlayoutbook.overlay

import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageContent
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageRegion

/**
 * Find the overlays based on the character indexes,
 * The simplest version to be used for most of the highlights.
 */
internal class CursorBasedFLOverlayStrategy : FixedLayoutOverlayStrategy {
    override suspend fun computeOverlay(computeOverlayContext: ComputeOverlayContext): List<FixedLayoutPageRegion> {
        val overlappingItems = computeOverlayContext.items.filter {
            it.text.hasNontrivialIntersectionWith(
                computeOverlayContext.content,
            )
        }
        return overlappingItems.map { value ->
            FixedLayoutPageRegion.fromPageTextContent(
                content = FixedLayoutPageContent(
                    text = value.text.text,
                    fontFamily = value.fontFamily,
                    normalizedBoundingBox = value.normalizedBox,
                ),
                startIndex = value.text.getFirstIndexOfCursor(computeOverlayContext.content.start),
                endIndexExclusive = value.text.getLastIndexOfCursor(computeOverlayContext.content.end) + 1,
            )
        }
    }
}
