package com.speechify.client.api.content.txt

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.txt.PlainTextView
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import kotlin.js.JsExport

@JsExport
data class InMemoryPlainTextView(private val text: String) : PlainTextView {
    override val start: ContentCursor
        get() = ContentElementReference.forRoot().start

    override val end: ContentCursor
        get() = ContentElementReference.forRoot().end

    override fun getLength(callback: Callback<Int>) {
        callback(getLength().successfully())
    }

    internal fun getLength(): Int = text.length

    override fun getSlice(start: Int, end: Int, callback: Callback<ContentText>) {
        callback(getSlice(start, end).successfully())
    }

    override fun getCursor(charIndex: Int, callback: Callback<ContentCursor>) = callback.fromCo {
        ContentTextPosition(ContentElementReference.forRoot(), charIndex).successfully()
    }

    internal fun getSlice(start: Int, end: Int): ContentText {
        val slice = text.substring(start until end)
        return TextElementContentSlice(
            ContentElementReference.forRoot(),
            range = start to start + slice.length,
            text = slice,
        )
    }
}
