package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentSlice
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.FillerContentSlice
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.Line
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.LineOfOnePart
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItem
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItemLines
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItemOfOnePart
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.TextWithRef
import com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.TopLevelItem
import com.speechify.client.internal.util.extensions.collections.flatMapInterleaved
import com.speechify.client.internal.util.extensions.collections.zipFullLeft
import com.speechify.client.internal.util.extensions.intentSyntax.applyWithCast
import kotlin.js.JsExport

internal fun ContentElementReference.createChildrenBlocksFactory() =
    ChildrenBlocksFactory(this)

internal class ChildrenBlocksFactory(
    private val referenceOfParentBlock: ContentElementReference,
) {
    private var nextBlockIdx = 0

    fun <Result : StandardBlock> createNextChildBlock(
        build: SingleBlockExpression.() -> Result,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ) =
        SingleBlockExpression(referenceOfParentBlock.getChild(nextBlockIdx++, ref)).build()
}

internal fun ContentElementReference.createAsAllChildren(
    topLevelItems: Iterable<TopLevelItem>,
): Iterable<StandardBlock> =
    this.createChildrenBlocksFactory().createAll(topLevelItems)

internal fun ChildrenBlocksFactory.createAll(
    topLevelItems: Iterable<TopLevelItem>,
): Iterable<StandardBlock> = sequence {
    for (topLevelItem in topLevelItems)
        yield(this@createAll.create(topLevelItem))
}.toList()

internal fun ChildrenBlocksFactory.create(
    topLevelItem: TopLevelItem,
): StandardBlock =
    createNextChildBlock(
        {
            when (topLevelItem) {
                /* TODO - Make StandardView support 'unfinished blocks' - allow to combine parts of blocks of
                 *  one chunk with the ones of the next ones. #TODOSupportSpeechOfUnfinishedBlocks */
                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.Heading ->
                    HeadingFromParts(topLevelItem.parts)

                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ParagraphParts ->
                    ParagraphFromParts(topLevelItem.parts)

                is com.speechify.client.helpers.content.standard.streamable.items
                .topLevelItems.ParagraphPartsWithImplicitWhitespace,
                ->
                    ParagraphFromParts(topLevelItem.partsWithNoWhitespace, separator = " ")

                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.List ->
                    ListFromItems(topLevelItem.listItems)

                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ParagraphLines ->
                    ParagraphFromLines(topLevelItem.lines)

                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.HeadingLines ->
                    HeadingFromLines(topLevelItem.lines)

                is com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ListFromItemsLines,
                ->
                    ListFromItemLines(topLevelItem.listItems)
            }
        },
    )

@JsExport
interface StandardViewProducer<out TSelf : StandardViewProducer<TSelf>> {
    /**
     * Constructs a new builder by appending a [StandardBlock.Paragraph] containing the specified text.
     */
    fun addParagraphFromSingleText(textWithRef: TextWithRef): TSelf

    /**
     * Constructs a new builder by appending a [StandardBlock.Paragraph] constructed by joining the specified
     * [texts] with the specified [separator].
     *
     * NOTE: Use [addParagraphFromParts] if you have a collections of items containing both  [ObjectRef] and [texts].
     */
    fun addParagraphFromChunks(
        texts: Array<String>,
        refs: Array<ObjectRef<Any?>>,
        separator: String = "",
    ): TSelf

    fun addParagraphFromParts(
        parts: Array<TextWithRef>,
        separator: String = "",
    ): TSelf

    fun addParagraphFromLines(
        vararg lines: TextWithRef,
    ): TSelf

    /**
     * Constructs a new builder by appending a [StandardBlock.Heading] containing the specified text.
     */
    fun addHeading(text: String, ref: ObjectRef<Any?>): TSelf

    /**
     * Constructs a new builder by appending a [StandardBlock.List] containing the specified text items as
     * [StandardBlock.Paragraph]s
     */
    fun addList(items: Array<String>, refs: Array<ObjectRef<Any?>>): TSelf
}

@JsExport
abstract class StandardViewProducerBase<
    out TSelf : StandardViewProducer<TSelf>,
    >(
    val referenceOfParentBlock: ContentElementReference = ContentElementReference.forRoot(),
) : StandardViewProducer<TSelf> {
    /**
     * Constructs a new builder by appending a [StandardBlock.Paragraph] containing the specified text.
     *
     * This function can't be in the interface of [StandardViewProducer] because of it's `ref` parameter
     * being optional, and Kotlin's limitation of:
     * `An overriding function is not allowed to specify default values for its parameters`.
     * The closest one to this in the [StandardViewProducer] is [StandardViewProducer.addParagraphFromSingleText].
     */
    fun addParagraph(
        text: String,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): TSelf =
        addParagraphFromSingleText(TextWithRef(text, ref))

    override fun addParagraphFromSingleText(textWithRef: TextWithRef): TSelf = applyWithCast {
        addBlock({ Paragraph(textWithRef.text) }, textWithRef.ref)
    }

    /**
     * Constructs a new builder by appending a [StandardBlock.Paragraph] constructed by joining the specified
     * [texts] with the specified [separator].
     *
     * NOTE: Use [addParagraphFromParts] if you have a collections of items containing both  [ObjectRef] and [texts].
     */
    override fun addParagraphFromChunks(
        texts: Array<String>,
        refs: Array<ObjectRef<Any?>>,
        separator: String,
    ): TSelf = applyWithCast {
        check(texts.size == refs.size) { "text and refs arrays must be equal length" }
        this.addParagraphFromParts(
            texts.zip(refs).map { TextWithRef(text = it.first, ref = it.second) }.toTypedArray(),
            separator,
        )
    }

    override fun addParagraphFromParts(
        parts: Array<TextWithRef>,
        separator: String,
    ): TSelf = applyWithCast {
        this.addBlock(
            {
                ParagraphFromParts(parts, separator)
            },
        )
    }

    override fun addParagraphFromLines(
        vararg lines: TextWithRef,
    ): TSelf = applyWithCast {
        this.addBlock(
            {
                ParagraphFromLines(
                    lines = lines.map { LineOfOnePart(it) }.toTypedArray(),
                )
            },
        )
    }

    /**
     * Constructs a new builder by appending a [StandardBlock.Heading] containing the specified text.
     */
    override fun addHeading(text: String, ref: ObjectRef<Any?>): TSelf = applyWithCast {
        addBlock({ Heading(text) }, ref)
    }

    /**
     * Constructs a new builder by appending a [StandardBlock.List] containing the specified text items as
     * [StandardBlock.Paragraph]s
     */
    override fun addList(items: Array<String>, refs: Array<ObjectRef<Any?>>): TSelf = applyWithCast {
        addBlock(
            {
                ListFromItems(
                    listItems = items
                        .zipFullLeft(refs)
                        .map { TextWithRef(it.first, it.second ?: ObjectRef(null)) }
                        .map {
                            ListItemOfOnePart(it)
                        }
                        .toTypedArray(),
                )
            },
        )
    }

    protected abstract fun appendBlocks(newBlocks: Iterable<StandardBlock>)

    private val childrenBlocksFactory = referenceOfParentBlock.createChildrenBlocksFactory()

    /**
     * Every method for adding a block goes through this function.
     */
    private fun addBlock(
        build: SingleBlockExpression.() -> StandardBlock,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StandardBlock {
        val block = childrenBlocksFactory.createNextChildBlock(build, ref)
        appendBlocks(listOf(block))
        return block
    }
}

internal class SingleBlockExpression(
    private val referenceForThisBlock: ContentElementReference,
) {
    fun Heading(text: String) =
        StandardBlock.Heading(
            text = referenceForThisBlock.createSingleChildTextElement(text),
        )

    @Suppress("FunctionName")
    fun HeadingFromParts(
        parts: Array<TextWithRef>,
        separator: String = "",
    ) =
        StandardBlock.Heading(
            text = referenceForThisBlock.createSingleChildTextContentFromParts(
                parts = parts,
                separator = separator,
            ),
        )

    @Suppress("FunctionName")
    fun HeadingFromLines(
        lines: Array<Line>,
    ) =
        StandardBlock.Heading(
            text = referenceForThisBlock.createChildrenContentTextFromLines(lines),
        )

    fun Paragraph(text: String) =
        StandardBlock.Paragraph(
            text = referenceForThisBlock.createSingleChildTextElement(text),
        )

    @Suppress("FunctionName")
    fun ParagraphFromParts(
        parts: Array<TextWithRef>,
        separator: String = "",
    ) =
        StandardBlock.Paragraph(
            text = referenceForThisBlock.createSingleChildTextContentFromParts(
                parts,
                separator = separator,
            ),
        )

    @Suppress("FunctionName")
    fun ParagraphFromLines(
        lines: Array<Line>,
    ): StandardBlock.Paragraph {
        return StandardBlock.Paragraph(
            text = referenceForThisBlock.createChildrenContentTextFromLines(lines),
        )
    }

    @Suppress("FunctionName")
    fun ListFromItems(
        listItems: Array<ListItem>,
    ): StandardBlock.List {
        val childrenSlicesBuilder = referenceForThisBlock.createChildrenSlicesBuilder()

        return StandardBlock.List(
            isNumbered = false,
            items = sequence {
                for (item in listItems) {
                    yield(
                        StandardBlock.Paragraph(
                            text = ContentTextUtils.concat(
                                childrenSlicesBuilder.createNextTextElementsSlicesFromParts(item.parts),
                            ),
                        ),
                    )
                }
            }.toList().toTypedArray(),
        )
    }

    @Suppress("FunctionName")
    fun ListFromItemLines(
        listItems: Array<ListItemLines>,
    ): StandardBlock.List {
        val items = sequence {
            val childrenFactory = referenceForThisBlock.createChildrenBlocksFactory()
            for (listItem in listItems) {
                yield(
                    childrenFactory.createNextChildBlock(
                        { ParagraphFromLines(listItem.lines) },
                        listItem.ref,
                    ),
                )
            }
        }

        return StandardBlock.List(
            isNumbered = false,
            items = items.toList().toTypedArray(),
        )
    }
}

private fun ContentElementReference.createSingleChildTextElement(text: String): ContentText =
    TextElementContentSlice.fromTextElement(
        elementReference = this,
        text,
    )

private fun ContentElementReference.createSingleChildTextContentFromParts(
    parts: Array<TextWithRef>,
    separator: String,
): ContentText =
    ContentTextUtils.Format.joinWithFillerSeparator(
        createChildrenSlicesBuilder()
            .createNextTextElementsSlicesFromParts(parts),
        separator,
    )

/**
 * This function avoids creating many temporary `ContentText` instances from intermediate concatenations (each line can
 * itself be composed of parts, which need to be concatenated without any 'filler', and then the lines needs to be
 * concatenated with a whitespace 'filler').
 */
private fun ContentElementReference.createChildrenContentTextFromLines(
    lines: Array<Line>,
): ContentText =
    ContentTextUtils.concat(
        sequence {
            val builder = this@createChildrenContentTextFromLines.createChildrenSlicesBuilder()

            val allSlicesWithSpaceInterleavingLines = lines.flatMapInterleaved(
                transform = { line ->
                    line.parts.map { part ->
                        builder.createNextTextElementContentSlice(
                            part.text,
                            part.ref,
                        )
                    }
                },
                getSeparator = { _, next ->
                    FillerContentSlice.createPrefixFillerForText(
                        prefixText = " ",
                        contentText = next,
                    )
                },
            )

            for (slice in allSlicesWithSpaceInterleavingLines)
                yield(slice)
        },
    )

internal fun ContentElementReference.createChildrenSlicesBuilder() =
    ChildrenTextSlicesBuilder(this)

internal class ChildrenTextSlicesBuilder(private val parentElementReference: ContentElementReference) {
    private var nextElementIndexExclusive = 0
    private var nextSliceStartIdxInclusive = 0

    fun createNextTextElementsSlicesFromParts(textElements: Array<TextWithRef>): List<ContentSlice> =
        sequence {
            for (textElement in textElements) {
                yield(createNextTextElementContentSlice(textElement.text, textElement.ref))
            }
        }.toList()

    fun createNextTextElementContentSlice(text: String, ref: ObjectRef<Any?>): ContentSlice {
        val thisSliceEndIdxExclusive = nextSliceStartIdxInclusive + text.length
        try {
            return if (text.isEmpty()) {
                FillerContentSlice.fromCursor(
                    cursor = parentElementReference.getChild(nextElementIndexExclusive, ref)
                        .getPosition(characterIndex = 0),
                    text = text,
                )
            } else {
                TextElementContentSlice(
                    elementReference = parentElementReference.getChild(nextElementIndexExclusive, ref),
                    range = nextSliceStartIdxInclusive to thisSliceEndIdxExclusive,
                    text = text,
                )
            }
        } finally {
            nextSliceStartIdxInclusive = thisSliceEndIdxExclusive
            ++nextElementIndexExclusive
        }
    }
}
