package com.speechify.client.api.adapters.localsynthesis

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.toException
import com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.isActive
import kotlin.coroutines.resume
import kotlin.js.JsExport

/**
 * The implementation may be a singleton, and state like [pause] and [resume] potentially persists even when
 * not playing (such is the case in web browsers' `localSynthesis` object), so SDK will use only ever use one instance
 * of this interface (or one-instance-at-a-time).
 *
 * NOTE: Some tests for this class are ignored due to their flaky nature.
 * For more details, see [LocalPlayerTest].
 */
@JsExport
interface LocalSpeechSynthesisPlayer {

    fun speak(utterance: LocalSynthesisUtterance, onEvent: Callback<LocalSpeechSynthesisEvent>)

    /**
     * NOTE: This will also work when not during [speak], in which case it will make the next [speak] call suspend
     * until a [resume].
     *
     * NOTE: [cancel] will also remove the paused state.
     */
    fun pause()

    /**
     * Removes paused state (from a [pause] call), if present, thus unblocking any running or pending [speak] calls.
     */
    fun resume()

    /**
     * Stops the playback and removes paused state (from a [pause] call), if present.
     */
    fun cancel()
}

/**
 * Represents the [LocalSpeechSynthesisAdapter.speak] as a [kotlinx.coroutines.flow.Flow], which will speak
 * when the flow is collected.
 * The flow will contain events that occurred during the speaking, and will finish when the speech has finished.
 *
 * #UsageOfFlowForPlaybackOfSameContent:
 * NOTE: although the _coldness_ of the flow means that the utterance will be spoken on each
 * [kotlinx.coroutines.flow.Flow.collect], which can also be used for retries, etc., this is actually not the main
 * reason it is useful to represent speaking as a flow. The main reason is rather the ability to use flow API on the
 * pipeline of events, with its full richness of flow operators, stateful `flow {}` wrappers, and deferring the decision
 * on allocating replay-buffers for shared flows (if all functionality can be put into the single flow pipeline, then
 * there's no need for replays, and thus no need for a shared flow at all).
 */
internal fun LocalSpeechSynthesisPlayer.speakingColdFlow(utterance: LocalSynthesisUtterance):
    Flow<LocalSpeechSynthesisEvent> =
    callbackFlowNeverThrowingToProducer(
        /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
         *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
         *  until no more items coming is ensured)
         */
        /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
         *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
         *  until no more items coming is ensured)
         */
        shouldLogErrorIfCancellationPreventedDelivery = false,
        sourceAreaId = "LocalSpeechSynthesisPlayer.speakingColdFlow",
        bufferCapacity = Channel.UNLIMITED, /* Don't use the default buffer size, but unlimited, not to lose any value
        if the consumer is slower than we in processing the events. */
    ) {
        speak(
            utterance,
            onEvent = { result ->
                if (!this.isActive) return@speak

                when (result) {
                    is Result.Failure -> {
                        closeAbnormallyWithFailure(result.error.toException())
                    }
                    is Result.Success<LocalSpeechSynthesisEvent> -> {
                        when (val event = result.value) {
                            is LocalSpeechSynthesisEvent.Ended,
                            is LocalSpeechSynthesisEvent.Canceled,
                            -> {
                                send(event)
                                /** TODO - consider [com.speechify.client.internal.util.collections.flows.ProducerScopeForNeverThrowingCallbackFlow.closeByCancellingProducerAndConsumerImmediately]
                                 *   (need to analyze if this is the desired behavior)
                                 */
                                /** TODO - consider [com.speechify.client.internal.util.collections.flows.ProducerScopeForNeverThrowingCallbackFlow.closeByCancellingProducerAndConsumerImmediately]
                                 *   (need to analyze if this is the desired behavior)
                                 */
                                this@callbackFlowNeverThrowingToProducer
                                    .closeByCancellingProducerAndConsumerImmediately()
                            }
                            is LocalSpeechSynthesisEvent.Started,
                            is LocalSpeechSynthesisEvent.Paused,
                            is LocalSpeechSynthesisEvent.Progressed,
                            -> {
                                send(event)
                            }
                        }
                    }
                }
            },
        )
        awaitClose {
            Log.d(
                "LocalSpeechSynthesisAdapter.speakingColdFlow: Flow closed. Cleaning up with `cancel()`",
                sourceAreaId = "LocalSpeechSynthesisAdapter.speakingColdFlow",
            )
            this@speakingColdFlow.cancel()
        }
        Log.d(
            "LocalSpeechSynthesisAdapter.speakingColdFlow: Flow exiting.`",
            sourceAreaId = "LocalSpeechSynthesisAdapter.speakingColdFlow",
        )
    }

internal fun LocalSpeechSynthesisPlayer.traced() =
    if (Log.isDebugLoggingEnabled) LocalSpeechSynthesisPlayerTraced(this) else this

internal class LocalSpeechSynthesisPlayerTraced(
    private val localSpeechSynthesisPlayer: LocalSpeechSynthesisPlayer,
) : LocalSpeechSynthesisPlayer {
    override fun speak(utterance: LocalSynthesisUtterance, onEvent: Callback<LocalSpeechSynthesisEvent>) {
        val areaId = "LocalSpeechSynthesisPlayerTraced.speak"
        val (uuid, taggedCallback) = onEvent.uuidCallback(areaId = areaId)
        Log.d(
            "[$uuid] CALL $areaId($utterance)",
            sourceAreaId = "LocalSpeechSynthesisPlayerTraced.speak",
        )
        localSpeechSynthesisPlayer.speak(utterance, taggedCallback)
    }

    override fun pause() {
        Log.d(
            "CALL LocalSpeechSynthesisPlayerTraced.pause()",
            sourceAreaId = "LocalSpeechSynthesisPlayerTraced.pause",
        )
        localSpeechSynthesisPlayer.pause()
    }

    override fun resume() {
        Log.d(
            "CALL LocalSpeechSynthesisPlayerTraced.resume()",
            sourceAreaId = "LocalSpeechSynthesisPlayerTraced.resume",
        )
        localSpeechSynthesisPlayer.resume()
    }

    override fun cancel() {
        Log.d(
            "CALL LocalSpeechSynthesisPlayerTraced.cancel()",
            sourceAreaId = "LocalSpeechSynthesisPlayerTraced.cancel",
        )
        localSpeechSynthesisPlayer.cancel()
    }
}
