package com.speechify.client.helpers.constants

import com.speechify.client.api.audio.VoiceGender
import com.speechify.client.api.audio.VoiceSpec
import kotlin.js.JsExport

@JsExport
@Suppress("unused")
object SpeechifyVoiceSpecifications {
    /*
    No Android voices here Because Android uses a special way to grab local voices, depending on the device - see:
    https://github.com/SpeechifyInc/speechify-android/blob/8028f25b08d27f12722428ed4011a178821339f0/app/src/main/java/com/cliffweitzman/speechify2/common/tts/AppTextToSpeech.kt
    */
    // WEB Voices
    val ALEX = VoiceSpec.Local(
        id = "Alex",
        displayName = "Alex",
    )

    val MS_DAVID = VoiceSpec.Local(
        id = "Microsoft David - English (United States)",
        displayName = "Microsoft David - English (United States)",
    )

    val ALICE = VoiceSpec.Local(
        id = "Alice",
        displayName = "Alice",
    )

    val ALVA = VoiceSpec.Local(
        id = "Alva",
        displayName = "Alva",
    )

    val AMELIE = VoiceSpec.Local(
        id = "Amelie",
        displayName = "Amelie",
    )

    val ANNA = VoiceSpec.Local(
        id = "Anna",
        displayName = "Anna",
    )

    val BRUCE = VoiceSpec.Local(
        id = "Bruce",
        displayName = "Bruce",
    )

    val CARMIT = VoiceSpec.Local(
        id = "Carmit",
        displayName = "Carmit",
    )

    val DAMAYANTI = VoiceSpec.Local(
        id = "Damayanti",
        displayName = "Damayanti",
    )

    val DANIEL = VoiceSpec.Local(
        id = "Daniel",
        displayName = "Daniel",
    )

    val DIEGO = VoiceSpec.Local(
        id = "Diego",
        displayName = "Diego",
    )

    val ELLEN = VoiceSpec.Local(
        id = "Ellen",
        displayName = "Ellen",
    )

    val FIONA = VoiceSpec.Local(
        id = "Fiona",
        displayName = "Fiona",
    )

    val FRED = VoiceSpec.Local(
        id = "Fred",
        displayName = "Fred",
    )

    val IOANA = VoiceSpec.Local(
        id = "Ioana",
        displayName = "Ioana",
    )

    val JOANA = VoiceSpec.Local(
        id = "Joana",
        displayName = "Joana",
    )

    val JORGE = VoiceSpec.Local(
        id = "Jorge",
        displayName = "Jorge",
    )

    val JUAN = VoiceSpec.Local(
        id = "Juan",
        displayName = "Juan",
    )

    val KANYA = VoiceSpec.Local(
        id = "Kanya",
        displayName = "Kanya",
    )

    val KAREN = VoiceSpec.Local(
        id = "Karen",
        displayName = "Karen",
    )

    val KATE = VoiceSpec.Local(
        id = "Kate",
        displayName = "Kate",
    )

    val KYOKO = VoiceSpec.Local(
        id = "Kyoko",
        displayName = "Kyoko",
    )

    val LAURA = VoiceSpec.Local(
        id = "Laura",
        displayName = "Laura",
    )

    val LEKHA = VoiceSpec.Local(
        id = "Lekha",
        displayName = "Lekha",
    )

    val LUCA = VoiceSpec.Local(
        id = "Luca",
        displayName = "Luca",
    )

    val LUCIANA = VoiceSpec.Local(
        id = "Luciana",
        displayName = "Luciana",
    )

    val MAGED = VoiceSpec.Local(
        id = "Maged",
        displayName = "Maged",
    )

    val MARISKA = VoiceSpec.Local(
        id = "Mariska",
        displayName = "Mariska",
    )

    val MEI_JIA = VoiceSpec.Local(
        id = "Mei-Jia",
        displayName = "Mei-Jia",
    )

    val MELINA = VoiceSpec.Local(
        id = "Melina",
        displayName = "Melina",
    )

    val MILENA = VoiceSpec.Local(
        id = "Milena",
        displayName = "Milena",
    )

    val MOIRA = VoiceSpec.Local(
        id = "Moira",
        displayName = "Moira",
    )

    val MONICA = VoiceSpec.Local(
        id = "Monica",
        displayName = "Monica",
    )

    val NORA = VoiceSpec.Local(
        id = "Nora",
        displayName = "Nora",
    )

    val OLIVER = VoiceSpec.Local(
        id = "Oliver",
        displayName = "Oliver",
    )

    val PAULINA = VoiceSpec.Local(
        id = "Paulina",
        displayName = "Paulina",
    )

    val RISHI = VoiceSpec.Local(
        id = "Rishi",
        displayName = "Rishi",
    )

    /**
     * Samantha seems to be unstable - it doesn't work for Anson on MacOS 11.4 M1, Chrome 103.0.5060.134.
     * This was confirmed using the Speechify Chrome Extension as well on the same machine.
     **/
    val SAMANTHA = VoiceSpec.Local(
        id = "Samantha",
        displayName = "Samantha",
    )

    val SARA = VoiceSpec.Local(
        id = "Sara",
        displayName = "Sara",
    )

    val SATU = VoiceSpec.Local(
        id = "Satu",
        displayName = "Satu",
    )

    val SERENA = VoiceSpec.Local(
        id = "Serena",
        displayName = "Serena",
    )

    val SIN_JI = VoiceSpec.Local(
        id = "Sin-ji",
        displayName = "Sin-ji",
    )

    val TESSA = VoiceSpec.Local(
        id = "Tessa",
        displayName = "Tessa",
    )

    val THOMAS = VoiceSpec.Local(
        id = "Thomas",
        displayName = "Thomas",
    )

    val TING_TING = VoiceSpec.Local(
        id = "Ting-Ting",
        displayName = "Ting-Ting",
    )

    val TOM = VoiceSpec.Local(
        id = "Tom",
        displayName = "Tom",
    )

    val VEENA = VoiceSpec.Local(
        id = "Veena",
        displayName = "Veena",
    )

    val VICTORIA = VoiceSpec.Local(
        id = "Victoria",
        displayName = "Victoria",
    )

    val XANDER = VoiceSpec.Local(
        id = "Xander",
        displayName = "Xander",
    )

    val YELDA = VoiceSpec.Local(
        id = "Yelda",
        displayName = "Yelda",
    )

    val YUNA = VoiceSpec.Local(
        id = "Yuna",
        displayName = "Yuna",
    )

    val YURI = VoiceSpec.Local(
        id = "Yuri",
        displayName = "Yuri",
    )

    val ZOSIA = VoiceSpec.Local(
        id = "Zosia",
        displayName = "Zosia",
    )

    val ZUZANA = VoiceSpec.Local(
        id = "Zuzana",
        displayName = "Zuzana",
    )

    // IOS Voices
    val MAGED_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Maged-compact",
        displayName = "Maged",
    )

    val ZUZANA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Zuzana-compact",
        displayName = "Zuzana",
    )

    val SARA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Sara-compact",
        displayName = "Sara",
    )

    val ANNA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Anna-compact",
        displayName = "Anna",
    )

    val HELENA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_de-DE_compact",
        displayName = "Helena",
    )

    val MARTIN_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_de-DE_compact",
        displayName = "Martin",
    )

    val MELINA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Melina-compact",
        displayName = "Melina",
    )

    val CATHERINE_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_en-AU_compact",
        displayName = "Catherine",
    )

    val GORDON_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_en-AU_compact",
        displayName = "Gordon",
    )

    val KAREN_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Karen-compact",
        displayName = "Karen",
    )

    val ARTHUR_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_en-GB_compact",
        displayName = "Arthur",
    )

    val MARTHA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_en-GB_compact",
        displayName = "Martha",
    )

    val MOIRA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Moira-compact",
        displayName = "Moira",
    )

    val RISHI_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Rishi-compact",
        displayName = "Rishi",
    )

    val AARON_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_en-US_compact",
        displayName = "Aaron",
    )

    val FRED_IOS = VoiceSpec.Local(
        id = "com.apple.speech.synthesis.voice.Fred",
        displayName = "Fred",
    )

    val NICKY_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_en-US_compact",
        displayName = "Nicky",
    )

    val SAMANTHA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Samantha-compact",
        displayName = "Samantha",
    )

    val TESSA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Tessa-compact",
        displayName = "Tessa",
    )

    val MONICA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Monica-compact",
        displayName = "Mónica",
    )

    val PAULINA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Paulina-compact",
        displayName = "Paulina",
    )

    val SATU_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Satu-compact",
        displayName = "Satu",
    )

    val AMELIE_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Amelie-compact",
        displayName = "Amélie",
    )

    val DANIEL_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_fr-FR_compact",
        displayName = "Daniel",
    )

    val MARIE_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_fr-FR_compact",
        displayName = "Marie",
    )

    val THOMAS_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Thomas-compact",
        displayName = "Thomas",
    )

    val CARMIT_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Carmit-compact",
        displayName = "Carmit",
    )

    val LEKHA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Lekha-compact",
        displayName = "Lekha",
    )

    val MARISKA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Mariska-compact",
        displayName = "Mariska",
    )

    val DAMAYANTI_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Damayanti-compact",
        displayName = "Damayanti",
    )

    val ALICE_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Alice-compact",
        displayName = "Alice",
    )

    val HATTORI_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_ja-JP_compact",
        displayName = "Hattori",
    )

    val KYOKO_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Kyoko-compact",
        displayName = "Kyoko",
    )

    val O_REN_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_ja-JP_compact",
        displayName = "O-ren",
    )

    val YUNA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Yuna-compact",
        displayName = "Yuna",
    )

    val ELLEN_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Ellen-compact",
        displayName = "Ellen",
    )

    val XANDER_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Xander-compact",
        displayName = "Xander",
    )

    val NORA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Nora-compact",
        displayName = "Nora",
    )

    val ZOSIA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Zosia-compact",
        displayName = "Zosia",
    )

    val LUCIANA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Luciana-compact",
        displayName = "Luciana",
    )

    val JOANA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Joana-compact",
        displayName = "Joana",
    )

    val IOANA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Ioana-compact",
        displayName = "Ioana",
    )

    val MILENA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Milena-compact",
        displayName = "Milena",
    )

    val LAURA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Laura-compact",
        displayName = "Laura",
    )

    val ALVA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Alva-compact",
        displayName = "Alva",
    )

    val KANYA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Kanya-compact",
        displayName = "Kanya",
    )

    val YELDA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Yelda-compact",
        displayName = "Yelda",
    )

    val LI_MU_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_male_zh-CN_compact",
        displayName = "Li-mu",
    )

    val TIAN_TIAN_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Ting-Ting-compact",
        displayName = "Tian-Tian",
    )

    val YU_SHU_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.siri_female_zh-CN_compact",
        displayName = "Yu-shu",
    )

    val SIN_JI_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Sin-Ji-compact",
        displayName = "Sin-Ji",
    )

    val MEI_JIA_IOS = VoiceSpec.Local(
        id = "com.apple.ttsbundle.Mei-Jia-compact",
        displayName = "Mei-Jia",
    )

    val ALEX_IOS = VoiceSpec.Local(
        id = "com.apple.speech.voice.Alex",
        displayName = "Alex",
    )

    val GWYNETH = VoiceSpec.ResembleIO(
        name = "Gwyneth",
        displayName = "Gwyneth",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
    )

    val NARRATOR_RESEMBLE = VoiceSpec.ResembleIO(
        name = "Narrator",
        displayName = "Narrator",
        languageCode = "en-GB",
        gender = VoiceGender.MALE,
    )

    val WAVENET_C_EN_US = VoiceSpec.GoogleWavenet(
        name = "Wavenet-C",
        displayName = "Mary",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
    )

    val WAVENET_D_EN_US = VoiceSpec.GoogleWavenet(
        name = "Wavenet-D",
        displayName = "Nate",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val WAVENET_A_DE_DE = VoiceSpec.GoogleWavenet(
        name = "Wavenet-A",
        displayName = "Sofia",
        languageCode = "de-DE",
        gender = VoiceGender.FEMALE,
    )

    val WAVENET_B_DE_DE = VoiceSpec.GoogleWavenet(
        name = "Wavenet-B",
        displayName = "Lukas",
        languageCode = "de-DE",
        gender = VoiceGender.MALE,
    )

    val WAVENET_A_PT_BR = VoiceSpec.GoogleWavenet(
        name = "Wavenet-A",
        displayName = "Adriana",
        languageCode = "pt-BR",
        gender = VoiceGender.FEMALE,
    )

    val WAVENET_B_PT_PT = VoiceSpec.GoogleWavenet(
        name = "Wavenet-B",
        displayName = "Santiago",
        languageCode = "pt-PT",
        gender = VoiceGender.MALE,
    )

    val WAVENET_B_ES_ES = VoiceSpec.GoogleWavenet(
        name = "Wavenet-B",
        displayName = "Alejandro",
        languageCode = "es-ES",
        gender = VoiceGender.MALE,
    )

    val WAVENET_C_ES_ES = VoiceSpec.GoogleWavenet(
        name = "Wavenet-C",
        displayName = "Isabella",
        languageCode = "es-ES",
        gender = VoiceGender.FEMALE,
    )

    val JOANNA = VoiceSpec.AmazonPolly(
        name = "Joanna",
        displayName = "Joanna",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val JOANNA_LFR = VoiceSpec.AmazonPolly(
        name = "Joanna",
        displayName = "Joanna",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL_LFR,
    )

    val MATTHEW = VoiceSpec.AmazonPolly(
        name = "Matthew",
        displayName = "Matthew",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val SALLI = VoiceSpec.AmazonPolly(
        name = "Salli",
        displayName = "Salli",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val JOEY = VoiceSpec.AmazonPolly(
        name = "Joey",
        displayName = "Joey",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val WAVENET_A_EN_GB = VoiceSpec.GoogleWavenet(
        name = "Wavenet-A",
        displayName = "Sarah",
        languageCode = "en-GB",
        gender = VoiceGender.FEMALE,
    )

    val WAVENET_B_EN_GB = VoiceSpec.GoogleWavenet(
        name = "Wavenet-B",
        displayName = "Michael",
        languageCode = "en-GB",
        gender = VoiceGender.MALE,
    )

    val WAVENET_A_UK_UA = VoiceSpec.GoogleWavenet(
        name = "Wavenet-A",
        displayName = "Yulia",
        languageCode = "uk-UA",
        gender = VoiceGender.FEMALE,
    )

    val AMY = VoiceSpec.AmazonPolly(
        name = "Amy",
        displayName = "Amy",
        languageCode = "en-GB",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val BRIAN = VoiceSpec.AmazonPolly(
        name = "Brian",
        displayName = "Brian",
        languageCode = "en-GB",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val OLIVIA = VoiceSpec.AmazonPolly(
        name = "Olivia",
        displayName = "Olivia",
        languageCode = "en-AU",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val RUSSELL_POLLY = VoiceSpec.AmazonPolly(
        name = "Russell",
        displayName = "Russell",
        languageCode = "en-AU",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val PRESIDENTIAL = VoiceSpec.Speechify(
        name = "Presidential",
        displayName = "Mr. President",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val NARRATOR_SPEECHIFY = VoiceSpec.Speechify(
        name = "Narrator",
        displayName = "Narrator",
        languageCode = "en-GB",
        gender = VoiceGender.MALE,
    )

    val RUSSELL_SPEECHIFY = VoiceSpec.Speechify(
        name = "Russell",
        displayName = "Russell",
        languageCode = "en-GB",
        gender = VoiceGender.MALE,
    )

    val CRAIG = VoiceSpec.Speechify(
        name = "Craig",
        displayName = "Craig",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val ERIC = VoiceSpec.Speechify(
        name = "Eric",
        displayName = "Eric",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val JAMES = VoiceSpec.Speechify(
        name = "James",
        displayName = "James",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val HANK = VoiceSpec.Speechify(
        name = "Hank",
        displayName = "Hank",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val NEIL = VoiceSpec.Speechify(
        name = "Neil",
        displayName = "Neil",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val CLIFF = VoiceSpec.Speechify(
        name = "Cliff",
        displayName = "Cliff",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val HENRY = VoiceSpec.Speechify(
        name = "Henry",
        displayName = "Henry",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val DWIGHT = VoiceSpec.Speechify(
        name = "rainn-wilson",
        displayName = "Dwight",
        languageCode = "en-US",
        gender = VoiceGender.MALE,
    )

    val LAURIE = VoiceSpec.Speechify(
        name = "Laurie",
        displayName = "Laurie",
        languageCode = "en-US",
        gender = VoiceGender.FEMALE,
    )

    val ZEINA = VoiceSpec.AmazonPolly(
        name = "Zeina",
        displayName = "Zeina",
        languageCode = "ar-EG",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val ZHIYU = VoiceSpec.AmazonPolly(
        name = "Zhiyu",
        displayName = "Zhiyu",
        languageCode = "zh-CN",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val NAJA = VoiceSpec.AmazonPolly(
        name = "Naja",
        displayName = "Naja",
        languageCode = "da-DK",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val RUBEN = VoiceSpec.AmazonPolly(
        name = "Ruben",
        displayName = "Ruben",
        languageCode = "nl-NL",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val LOTTE = VoiceSpec.AmazonPolly(
        name = "Lotte",
        displayName = "Lotte",
        languageCode = "nl-NL",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val LEA = VoiceSpec.AmazonPolly(
        name = "Lea",
        displayName = "Lea",
        languageCode = "fr-FR",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val MATHIEU = VoiceSpec.AmazonPolly(
        name = "Mathieu",
        displayName = "Mathieu",
        languageCode = "fr-FR",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val CHANTAL = VoiceSpec.AmazonPolly(
        name = "Chantal",
        displayName = "Chantal",
        languageCode = "fr-CA",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val VICKI = VoiceSpec.AmazonPolly(
        name = "Vicki",
        displayName = "Vicki",
        languageCode = "de-DE",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val HANS = VoiceSpec.AmazonPolly(
        name = "Hans",
        displayName = "Hans",
        languageCode = "de-DE",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val ADITI = VoiceSpec.AmazonPolly(
        name = "Aditi",
        displayName = "Aditi",
        languageCode = "hi-IN",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val CARLA = VoiceSpec.AmazonPolly(
        name = "Carla",
        displayName = "Carla",
        languageCode = "it-IT",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val GIORGIO = VoiceSpec.AmazonPolly(
        name = "Giorgio",
        displayName = "Giorgio",
        languageCode = "it-IT",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val MIZUKI = VoiceSpec.AmazonPolly(
        name = "Mizuki",
        displayName = "Mizuki",
        languageCode = "ja-JP",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val TAKUMI = VoiceSpec.AmazonPolly(
        name = "Takumi",
        displayName = "Takumi",
        languageCode = "ja-JP",
        gender = VoiceGender.MALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val SEOYEON = VoiceSpec.AmazonPolly(
        name = "Seoyeon",
        displayName = "Seoyeon",
        languageCode = "ko-KR",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
    )

    val LIV = VoiceSpec.AmazonPolly(
        name = "Liv",
        displayName = "Liv",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "nb-NO",
        gender = VoiceGender.FEMALE,
    )

    val EWA = VoiceSpec.AmazonPolly(
        name = "Ewa",
        displayName = "Ewa",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pl-PL",
        gender = VoiceGender.FEMALE,
    )

    val MAJA = VoiceSpec.AmazonPolly(
        name = "Maja",
        displayName = "Maja",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pl-PL",
        gender = VoiceGender.FEMALE,
    )

    val JACEK = VoiceSpec.AmazonPolly(
        name = "Jacek",
        displayName = "Jacek",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pl-PL",
        gender = VoiceGender.MALE,
    )

    val JAN = VoiceSpec.AmazonPolly(
        name = "Jan",
        displayName = "Jan",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pl-PL",
        gender = VoiceGender.MALE,
    )

    val CAMILA = VoiceSpec.AmazonPolly(
        name = "Camila",
        displayName = "Camila",
        languageCode = "pt-BR",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val RICARDO = VoiceSpec.AmazonPolly(
        name = "Ricardo",
        displayName = "Ricardo",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pt-BR",
        gender = VoiceGender.MALE,
    )

    val INES = VoiceSpec.AmazonPolly(
        name = "Ines",
        displayName = "Ines",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pt-PT",
        gender = VoiceGender.FEMALE,
    )

    val CRISTIANO = VoiceSpec.AmazonPolly(
        name = "Cristiano",
        displayName = "Cristiano",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "pt-PT",
        gender = VoiceGender.MALE,
    )

    val TATYANA = VoiceSpec.AmazonPolly(
        name = "Tatyana",
        displayName = "Tatyana",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "ru-RU",
        gender = VoiceGender.FEMALE,
    )

    val MAXIM = VoiceSpec.AmazonPolly(
        name = "Maxim",
        displayName = "Maxim",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "ru-RU",
        gender = VoiceGender.MALE,
    )

    val ENRIQUE = VoiceSpec.AmazonPolly(
        name = "Enrique",
        displayName = "Enrique",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "es-ES",
        gender = VoiceGender.MALE,
    )

    val MIA = VoiceSpec.AmazonPolly(
        name = "Mia",
        displayName = "Mia",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "es-MX",
        gender = VoiceGender.FEMALE,
    )

    val LUPE = VoiceSpec.AmazonPolly(
        name = "Lupe",
        displayName = "Lupe",
        languageCode = "es-US",
        gender = VoiceGender.FEMALE,
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.NEURAL,
    )

    val MIGUEL = VoiceSpec.AmazonPolly(
        name = "Miguel",
        displayName = "Miguel",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "es-US",
        gender = VoiceGender.MALE,
    )

    val ASTRID = VoiceSpec.AmazonPolly(
        name = "Astrid",
        displayName = "Astrid",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "sv-SE",
        gender = VoiceGender.FEMALE,
    )

    val FILIZ = VoiceSpec.AmazonPolly(
        name = "Filiz",
        displayName = "Filiz",
        pollyEngine = VoiceSpec.AmazonPolly.PollyEngine.STANDARD,
        languageCode = "tr-TR",
        gender = VoiceGender.FEMALE,
    )
}
