package com.speechify.client.api.audio

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.toException
import com.speechify.client.internal.util.extensions.collections.sendToUnlimited
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.takeWhile
import kotlinx.coroutines.launch
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * A [Player] is created from a single [Utterance], and is responsible for playing it back as audio.
 *
 * It encapsulates playback control of a single [Utterance], and emits [PlayerEvent] as the
 * utterance is played.
 */
internal abstract class Player : Destructible {
    /** The utterance this player was created from. */
    abstract val utterance: Utterance

    val events: SharedFlow<PlayerEvent> get() =
        eventsSink

    protected val eventsSink = MutableSharedFlow<PlayerEvent>(
        onBufferOverflow = BufferOverflow.DROP_OLDEST,
        replay = 10,
    )

    abstract suspend fun getCurrentCursor(): ContentCursor

    /**
     * Start playing audio from the specified cursor. If the cursor is not contained within the Utterance content, the Player will begin from the closest position within the content.
     *
     * This method will always try to play, even if the utterance is empty or if the cursor is at the end of the utterance. Which means that, if uninterrupted, the player should produce an `Ended` event
     */
    abstract fun play()

    /**
     * Stop playing audio.
     */
    abstract fun stop()

    abstract fun seek(to: ContentCursor)

    /**
     * Update the playback options without stopping playback.
     */
    abstract fun updateOptions(
        newOptions: PlayerOptions,
    )

    abstract fun setSpeed(speed: Float)

    abstract fun setVolume(volume: Float)

    abstract fun getOptions(callback: (PlayerOptions) -> Unit)

    abstract fun isPlaying(): Boolean

    override fun destroy() {
        eventsSink.tryEmit(PlayerEvent.Destroyed)
    }
}

/**
 * Represents the [Player.play] as a [kotlinx.coroutines.flow.Flow], which will speaks when the flow is collected.
 * The flow will contain events that occurred during the speaking, and will finish when the speech has finished.
 *
 * See also #UsageOfFlowForPlaybackOfSameContent
 */
internal fun Player.playingColdFlow(): Flow<PlayerEvent.InPlayEvent> = callbackFlow {
    val eventsHandlingJob = launch(
        CoroutineName(
            "playingColdFlow",
        ),
    ) {
        events
            .takeWhile {
                if (it is PlayerEvent.Error) {
                    close(it.error.toException())
                    false
                } else {
                    true
                }
            }
            .takeWhile {
                it !is PlayerEvent.Ended /* #HandledPlayerEventEnd */
            }
            .onEach {
                this@callbackFlow.sendToUnlimited(
                    when (it) {
                        is PlayerEvent.Paused -> it
                        is PlayerEvent.Progressed -> it
                        is PlayerEvent.Started -> it
                        is PlayerEvent.Destroyed -> it
                        is PlayerEvent.Ended -> throw Error(
                            "PlayerEvent.Ended has no AudioControllerEvent",
                        ) /* This is handled earlier in #HandledPlayerEventEnd. Cannot use
                                          `AudioControllerEvent.Ended` because it means 'end of entire content',
                                           while players just play a single utterance. */
                        is PlayerEvent.Error -> throw Error(
                            "PlayerEvent.Error should not reach here. A likely regression.",
                        ) /* This is handled in #HandledPlayerEventError above, so that there is a
                                         single place for handling errors which stop (or never start) the playing
                                          (which is what PlayerEvent.Error means). */
                    },
                )
            }
            .takeWhile { it !is PlayerEvent.Destroyed } /* Need to terminate the flow on this one as well,so that this
             job doesn't hang.
             */
            .collect()

        close()
        Log.d(
            "Player.playingColdFlow: `player.events.collect` returned",
            sourceAreaId = "Player.playingColdFlow",
        )
    }

    play()
    awaitClose {
        eventsHandlingJob.cancel()
    }
}.buffer(
    capacity = Int.MAX_VALUE, /* Don't use the default buffer size, but unlimited, not to lose any value if the
        consumer is slower than we in processing the events. */
)

internal suspend fun Player.coGetOptions(): PlayerOptions = suspendCoroutine { cont -> getOptions(cont::resume) }
