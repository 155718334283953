// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

import kotlin.collections.plus as plusOnNotNullables

/**
 * Version of the operator of the same name but allowing nulls on both sides.
 * @return `null` if both [this] and [another] are null.
 */
operator fun <T> Set<T>?.plus(another: Set<T>?): Set<T>? =
    @Suppress("IfThenToElvis") // It's more readable without Elvis
    if (this == null) {
        another
    } else if (another == null) {
        this
    } else {
        this.plusOnNotNullables(another)
    }

/**
 * Version of the operator of the same name but allowing nulls on both sides.
 * @return `null` if both [this] and [another] are null.
 */
operator fun <T> Array<T>?.plus(another: Array<T>?): Array<T>? =
    @Suppress("IfThenToElvis") // It's more readable without Elvis
    if (this == null) {
        another
    } else if (another == null) {
        this
    } else {
        this.plusOnNotNullables(another)
    }
