package com.speechify.client.api.telemetry

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyVersions
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.toException
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.DiagnosticsService
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.sync.AtomicDroppingFixedList
import com.speechify.client.internal.sync.AtomicInt
import com.speechify.client.internal.sync.ReadOnlyJobInfo
import com.speechify.client.internal.sync.SingleJobMutexByCancelling
import com.speechify.client.internal.util.extensions.collections.firstNotNull
import com.speechify.client.internal.util.extensions.collections.flows.suspendUntilNotNull
import com.speechify.client.internal.util.extensions.collections.flows.suspendUntilNull
import com.speechify.client.internal.util.extensions.collections.windowedToPairsWithPrevious
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorScope
import com.speechify.client.internal.util.extensions.coroutines.launchAndWaitWithoutFailingOnError
import com.speechify.client.internal.util.extensions.coroutines.launchCoroutineScope
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.delay
import kotlinx.coroutines.ensureActive
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flatMapMerge
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.job
import kotlinx.coroutines.plus
import kotlinx.coroutines.withContext
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlin.coroutines.cancellation.CancellationException
import kotlin.coroutines.coroutineContext
import kotlin.js.JsExport
import kotlin.time.Duration.Companion.milliseconds

const val DEFAULT_FLUSH_PERIOD_MS: Int = 10000 /* Don't use `_` in the literal as then KDoc will not inline
 the value, at least in the current version :( */

/**
 * We chose a sufficiently large number where in normal operations we would never exceed it.
 * At the same time it can't be truly infinite lest a bug on the backend / long lasting connection issues cause OOM.
 */
const val DEFAULT_MAX_EVENT_BUFFER_SIZE: Int = 2500

/**
 * Provides a mechanism to report telemetry events to Speechify for analysis.
 * It is disabled by default but is recommended to be enabled - use [enable] to do so.
 *
 * NOTE: [enable] can only be be called **after** calling [com.speechify.client.api.diagnostics.SpeechifySDKDiagnostics.setupDiagnosticReporter],
 * because it starts background jobs that need error reporting.
 */
@JsExport
object SpeechifySDKTelemetry {
    /** The scope must be created Lazily because [createTopLevelCoroutineScope] throws if [com.speechify.client.api.diagnostics.SpeechifySDKDiagnostics.setupDiagnosticReporter]
     * wasn't called before it.
     * This surfaces especially in JavaScript, where mere **importing** of this [SpeechifySDKTelemetry] object into the
     * file starts the initialization of it, leading to an exception even if it is the same file
     * that has a call to [com.speechify.client.api.diagnostics.SpeechifySDKDiagnostics.setupDiagnosticReporter],
     * because the initialization happens before the JS file gets to its commands (and typically it _will_ be the
     * same file that sets-up diagnostics and telemetry).
     */
    private val lazyScope = lazy {
        createTopLevelCoroutineScope(
            contextToMerge = CoroutineName(SpeechifySDKTelemetryClassName),
        )
    }

    /**
     * Enables collecting the telemetry events and sending them to the backend. This is reversible by calling [disable].
     *
     * NOTE: This can also be used for dynamically changing the [options] without losing any events.
     *
     * NOTE: this can only be be called **after**
     * [com.speechify.client.api.diagnostics.SpeechifySDKDiagnostics.setupDiagnosticReporter], because it starts
     * background jobs that need error reporting.
     *
     * NOTE: The collection of events starts immediately, but sending will commence as soon as a
     * [com.speechify.client.api.SpeechifyClient] initializes the required dependencies.
     * Here note that, because [SpeechifySDKTelemetry] is a static component, any new instance of
     * [com.speechify.client.api.SpeechifyClient] will take over the telemetry sending. Doing this should always involve
     * [com.speechify.client.api.SpeechifyClient.destroy] of the old client (to stop the telemetry sending) and
     * if it doesn't then a non-terminating error will be logged to advise that it wasn't done.
     */
    fun enable(options: TelemetryOptions = TelemetryOptions()) {
        /** We can't start the control jobs on `init` as per doc of [lazyControlJob], but this [enable], which is the
         necessary entry point to telemetry, is the last moment where we must ensure all the control jobs are
         started. */
        lazyControlJob.start()
        optionsOrNullIfDisabledMutable.value = options

        /* Change the queue size immediately to make it immediately workable. First attempt was to implement a listener
         on [optionsOrNullIfDisabledMutable] but then this loses events from the immediate time after enabling.
         */
        userTelemetryEvents.setMaxSizePreservingExcess(
            newMaxSize = options.maxEventBufferSize,
        )
    }

    /**
     * Disables telemetry collection as well as sending of it to the backend. This is reversible by calling
     * [enable].
     *
     * Note that calling it does not clear the queue of collected events - if there are any there, they may be sent when
     * telemetry is re-enabled. If clearing _is_ intended, then [clearQueue] should be called as well.
     */
    fun disable() {
        optionsOrNullIfDisabledMutable.value = null
        userTelemetryEvents.setMaxSizePreservingExcess(
            /** The 0 also effectively disables collection, although there may also be other checks on
             [enabled] that prevent collection, for example to prevent even creating events and thus prevent any
             performance impact.
             */
            newMaxSize = 0,
        )
        /** Don't clear the queue as per intent (in KDoc) */
        /** Don't clear the [setClientDependencies] to make everything restartable by plain call to [enable] */
    }

    /**
     * Answers whether telemetry is enabled.
     *
     * NOTE: Regarding detailed information, this only answers whether telemetry is being collected to a queue, and
     * whether the SDK consumer has expressed the intent for telemetry to be enabled (with a call to [enable]).
     * It does **not** answer whether the sending worker is running - this will only start at some point during
     * initialization of the client and can be learned from [reporterToBackendJobInfo].
     */
    val enabled get() = optionsOrNullIfDisabled != null

    /**
     * The options that are used by telemetry. If telemetry is disabled, this is `null`.
     */
    val optionsOrNullIfDisabled: TelemetryOptions? get() = optionsOrNullIfDisabledFlow.value

    internal val optionsOrNullIfDisabledFlow: StateFlow<TelemetryOptions?> get() = optionsOrNullIfDisabledMutable

    /**
     * Information about the job that is sending the telemetry events to the backend.
     */
    val reporterToBackendJobInfo: ReadOnlyJobInfo? get() = reporterToBackendJobInfoFlow.value

    internal val reporterToBackendJobInfoFlow: StateFlow<ReadOnlyJobInfo?> get() = reporterToBackendMutex.currentJobInfo

    /**
     * Clears the queue of collected events. This can be used if telemetry is not going to be used anymore to save
     * memory or truly needs to start a new life entirely.
     */
    fun clearQueue() {
        userTelemetryEvents.takeAll()
    }

    /**
     * The current size of the queue of collected events.
     */
    val queueSize get() = userTelemetryEvents.size

    /**
     * The entry point to add events to the queue.
     */
    internal fun report(event: ReportableEvent) {
        if (!enabled) {
            return // Drop the event
        }

        mockableTelemetryReporterReport(event)
    }

    /**
     * Indirection to allow mocking of [report] - see [mockableTelemetryReporterReport].
     */
    internal fun reportImpl(event: ReportableEvent) {
        userTelemetryEvents.add(userIdForReport.value to event)
    }

    /**
     * Used by [report] to capture the exact user id that was current at the time of calling the [report].
     */
    private val userIdForReport: MutableStateFlow<UserId?> = MutableStateFlow(null)

    /**
     * Sets the dependencies in use by telemetry.
     *
     * If telemetry is [enabled], then this starts the telemetry reporter using the given dependencies (if a previous
     * one was already running, it will be cancelled and the new one will be started, but an error will be logged, as
     * this should be done with intent by calling [unsetClientDependencies] first).
     */
    internal fun setClientDependencies(
        telemetryClientDependencies: TelemetryClientDependencies,
    ) {
        clientDependenciesOrNullIfSendingDisabled.value = telemetryClientDependencies
    }

    /**
     * Unsets the dependencies in use by telemetry.
     *
     * This makes the sending to backend stop. This should especially be used on 'destroy' of the dependencies,
     * as then the telemetry will lose its references to them and will stop using them.
     * Note that this does not clear the queue of collected events - they will be sent if telemetry is re-started.
     * Likewise, the events will still be collected on the queue unless [disable] is called.
     * This allows to support telemetry of the events during switch of dependencies or users, but if clearing is
     * intended, then [clearQueue] should be called as well.
     */
    internal fun unsetClientDependencies() {
        clientDependenciesOrNullIfSendingDisabled.value = null
    }

    /**
     * Clears the queue of collected events and stops the sending to backend.
     *
     * Use when it's necessary to start completely anew.
     * NOTE: All events in the queue will be lost.
     */
    internal suspend fun resetAllAndWaitToClear() {
        unsetClientDependencies()
        disable()

        // The cancellation is not immediate, so wait for it to complete
        reporterToBackendJobInfoFlow
            .map { it?.nullIf { it.isCompleted } }
            .suspendUntilNull()
        clearQueue()
    }

    /**
     * Contains the dependencies required for telemetry to be working. Can be provided after the telemetry is enabled.
     * `null` represents the state when telemetry is not configured (yet, or after a client destroy during clients'
     * change).
     */
    private val clientDependenciesOrNullIfSendingDisabled =
        MutableStateFlow<TelemetryClientDependencies?>(null)

    /**
     * The thread-safe device for ensuring that only one job is running at a time for sending the events to the backend.
     */
    private val reporterToBackendMutex = SingleJobMutexByCancelling()

    /**
     * This 'control job' is essential to the life of telemetry. It must be started on first call to [enabled] at
     * the latest, because it controls applying of all settings to state flows and starting/stopping of the
     * backend-reporting.
     *
     * The intent was to run this in `init {` but it has to be started lazily especially because it uses [lazyScope],
     * which throws if executed during initialization, as per the docs of [lazyScope].
     */
    private val lazyControlJob by lazy {
        lazyScope.value.launchCoroutineScope(start = CoroutineStart.LAZY) {
            /* Using a `coroutineScope` to group all parallel work below under one Job */
            /** The below flow listening is for reacting to changes to the queue-size-cap - just propagate it onto the
             * `userTelemetryEvents` queue.
             */

            /** The below flow listening is for making `userIdForReport` be a single state, accurate and constantly
             available for the [report] function, despite the fact that the [AuthService] carrying the user dependencies
             can change, as well as they can have their user changing.
             */
            clientDependenciesOrNullIfSendingDisabled
                .map {
                    it?.firebaseAuthService
                }
                .distinctUntilChanged() /* `distinct` so changes of other dependencies don't rerun this */
                .flatMapMerge {
                    it?.getCurrentUserOrNullFlowLoggingErrors(
                        usageIdForLog = "SpeechifySDKTelemetry.userIdForReport",
                    )
                        ?: flowOf(null)
                }
                .onEach {
                    userIdForReport.value = it?.uid
                }
                .launchIn(scope = this) /* Use `launch` and not `collect` not to block as we have more jobs to create.
                  Not using supervisor scope here because the work is meant to live forever, while if it ever crashes,
                  the whole telemetry becomes uncontrollable so should stop everything (by failing the parent scope)
                  #KillTelemetryIfOutOfControl */

            /** The below flow listening is for controlling whether the backend-sender Job, held in
             [reporterToBackendMutex] is running or not (it should not run when telemetry is [disable] or when the
             dependencies are not provided yet through [setClientDependencies] or have been removed). */

            val scopeWithPreventFailingOnError = this.createChildSupervisorScope()
            /** Re the caveat from docs of
             [createChildSupervisorScope] that this scope never completes by itself, this scope is fine to not be
             completed, as it lasts forever (or until it's cancelled/failed by the `this` parent scope). */
            clientDependenciesOrNullIfSendingDisabled
                .combine(
                    flow = optionsOrNullIfDisabledMutable
                        .map { it?.flushPeriodMilliseconds },
                    transform = { clientDependenciesOrNullIfSendingDisabled, flushPeriodMillisecondsOrNullIfDisabled ->
                        if (flushPeriodMillisecondsOrNullIfDisabled == null ||
                            clientDependenciesOrNullIfSendingDisabled == null
                        ) {
                            null /* Including `flushPeriodMilliseconds` in the check only to produce a
                             `null` in such case, which effectively causes this to be also considered as terminating for
                              the backend-sending Job.
                             */
                        } else {
                            clientDependenciesOrNullIfSendingDisabled
                        }
                    },
                )
                .distinctUntilChanged()
                /** Use `distinctUntilChanged` to prevent cancelling the running job if
                 for example [enable] was called to just change the values of `optionsOrNullIfDisabled` (they apply
                 dynamically without a restart). */
                .windowedToPairsWithPrevious()
                .onEach { (prev, clientDependenciesOrNullIfSendingDisabled) ->
                    reporterToBackendMutex.cancelCurrentJobAndJoin()
                    if (clientDependenciesOrNullIfSendingDisabled == null) {
                        return@onEach
                    }

                    reporterToBackendMutex
                        .replaceWithNewJobIn(
                            scope = scopeWithPreventFailingOnError +
                                /** Use a failure-preventing scope for running
                                 the backend-sending Job, as we don't want to fail the entire telemetry [lazyScope] - we
                                 want to be able to restart anew in case the job failed (by calling [setClientDependencies]
                                 with new dependencies).
                                 */
                                CoroutineName(
                                    /* Add a coroutine name for ease of debugging */
                                    "GenId=${clientDependenciesOrNullIfSendingDisabled.generationJobId}",
                                ),
                            onOldJobNotCancelled = { oldJobInfo, newJobInfo ->
                                Log.e(
                                    DiagnosticEvent(
                                        message = "Old telemetry reporter was still running when a new start" +
                                            " was requested. Was some cleanup missed? (e.g. new" +
                                            " `SpeechifyClient` created without destroying the old one)",
                                        properties = mapOf("oldJobInfo" to oldJobInfo, "newJobInfo" to newJobInfo),
                                        sourceAreaId = "SpeechifySDKTelemetry.reporterToBackendMutex",
                                    ),
                                )
                            },
                            block = {
                                Log.dEvent {
                                    DiagnosticEvent(
                                        sourceAreaId = SpeechifySDKTelemetryClassName,
                                        message = "Started backend-reporting `job` because" +
                                            " dependencies or options changed - see `previous` and `now` props",
                                        properties = mapOf(
                                            "job" to coroutineContext.job,
                                            "previous" to (prev ?: "null"),
                                            "now" to clientDependenciesOrNullIfSendingDisabled,
                                        ),
                                    )
                                }
                                try {
                                    loopOfPeriodicSendingOfTelemetryToBackend(
                                        telemetryClientDependencies = clientDependenciesOrNullIfSendingDisabled,
                                    )
                                } catch (e: Throwable) {
                                    Log.dEvent {
                                        DiagnosticEvent.withNullableError(
                                            sourceAreaId = SpeechifySDKTelemetryClassName,
                                            message = "Backend-reporting `job` " +
                                                (
                                                    if (e is CancellationException) {
                                                        "was cancelled"
                                                    } else {
                                                        "failed"
                                                    }
                                                    ),
                                            nativeError = e.nullIf { e is CancellationException },
                                            properties = mapOf(
                                                "job" to coroutineContext.job,
                                            ),
                                        )
                                    }
                                    throw e
                                }
                            },
                        )
                }
                .launchIn(
                    scope = this, /* Not using supervisor scope here for same reason as #KillTelemetryIfOutOfControl */
                )
        }
    }
}

/**
 * Holds the options currently configured, used dynamically during the loop of sending telemetry to the backend.
 */
private val optionsOrNullIfDisabledMutable = MutableStateFlow<TelemetryOptions?>(null)

/**
 * The queue of events which were 'reported' from the code to here, and are still to be reported to the organization by
 * the 'sender to backend' job ([loopOfPeriodicSendingOfTelemetryToBackend]).
 */
private val userTelemetryEvents = AtomicDroppingFixedList<Pair<UserId?, ReportableEvent>>(
    /* Start by dropping everything, but let's have this collection here already, to be agnostic to initialization
    racing */
    maxSize = 0,
)

/**
 * The main logic for 'polling events and sending to backend' worker.
 */
/* #InternalForTests */
internal suspend fun loopOfPeriodicSendingOfTelemetryToBackend(
    telemetryClientDependencies: TelemetryClientDependencies,
): Nothing {
    val firebaseAuthService = telemetryClientDependencies.firebaseAuthService
    val diagnosticsService = telemetryClientDependencies.diagnosticsService
    val clientConfig = telemetryClientDependencies.clientConfig

    /* Wait and don't start reporting until there is a user, because user seems to be involved in
       `reportDiagnostics.reportDiagnostics`, so it's likely needed - else we would lose events.
       TODO - investigate if sending events truly needs a user or consider allowing to not have
        a user - there could be a case for sending events even before there's a user to be notified
        of exceptions preventing from getting the user.
        Refactor `reportDiagnostics` based on the outcome:
         * if it needs a user, make it take the user token, so this waiting is clearly
           justified in code
         * if it doesn't need a user, remove this wait
     */
    firebaseAuthService.getCurrentUserOrNullFlowLoggingErrors(
        usageIdForLog = "$SpeechifySDKTelemetryClassName.loopOfPeriodicSendingOfTelemetryToBackend",
    )
        .suspendUntilNotNull()

    var delayDuration = optionsOrNullIfDisabledMutable.firstNotNull().flushPeriodMilliseconds.milliseconds
    var backoffMultiplier = 1.0
    while (true) {
        coroutineContext.ensureActive()

        withContext(NonCancellable) {
            delayDuration = /* Eval the delay every time to reflect any dynamic changes to it */
                optionsOrNullIfDisabledMutable.value?.flushPeriodMilliseconds?.milliseconds
                    ?: delayDuration /* `null` is indicative of a cancellation. Likely this never happens
                     as it was just checked, but perhaps it's a one-in-million race. To err-on-side-of-safety
                     and not lose events on cancellation (as per intent of `NonCancellable`), let's go one last time,
                     using the last value */

            /* `NonCancellable` to send any telemetry events remaining during cancel. The while loop will stop
             after this iteration in the event of a cancellation, because ["ensureActive"](https://kotlinlang.org/api/kotlinx.coroutines/kotlinx-coroutines-core/kotlinx.coroutines/ensure-active.html)
             will throw a CancellationException */
            delay(delayDuration * backoffMultiplier)

            val userTelemetryEventsToSend = userTelemetryEvents.takeAll()
                .groupBy(
                    keySelector = { g -> g.first },
                    valueTransform = { (_, event) -> event },
                )

            for ((userId, events) in userTelemetryEventsToSend) {
                launchAndWaitWithoutFailingOnError {
                    val payload = TelemetrySessionAndEventsPayload(
                        session = TelemetrySessionProperties(
                            uid = userId,
                            app = clientConfig.appEnvironment,
                            appVersion = clientConfig.appVersion,
                            sdkVersion = SpeechifyVersions.SDK_VERSION,
                        ),
                        events = events.map { it.toJsonForReport() },
                    )

                    when (val result = diagnosticsService.reportDiagnostics(payload)) {
                        is Result.Success -> {
                            Log.dEvent {
                                DiagnosticEvent(
                                    sourceAreaId = SpeechifySDKTelemetryClassName,
                                    message = "Sent `payload` of `size` events to backend in `job`",
                                    properties = mapOf(
                                        "payload" to payload,
                                        "size" to events.size,
                                        "job" to coroutineContext.job,
                                    ),
                                )
                            }

                            // Reset the multiplier now that we can send again.
                            backoffMultiplier = 1.0
                        }
                        is Result.Failure -> {
                            Log.dEvent {
                                DiagnosticEvent(
                                    sourceAreaId = SpeechifySDKTelemetryClassName,
                                    nativeError = result.error.toException(),
                                    message = "Failed to send `payload` of `size` events to backend in `job`",
                                    properties = mapOf(
                                        "payload" to payload,
                                        "size" to events.size,
                                        "job" to coroutineContext.job,
                                    ),
                                )
                            }

                            // Put the events back in the queue for sending in the next try.
                            userTelemetryEvents.prependAll(events.map { userId to it })

                            // Also increase the back off.
                            backoffMultiplier *= 1.2
                        }
                    }
                }
            }
        }
    }
}

/**
 * This var ensures the `!!` below fails-fast to the developer if they for some reason change to a class without a name
 */
@Suppress("PrivatePropertyName")
private val SpeechifySDKTelemetryClassName = SpeechifySDKTelemetry::class.simpleName!!

internal data class TelemetryClientDependencies(
    val clientConfig: ClientConfig,
    val firebaseAuthService: AuthService,
    val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    val diagnosticsService: DiagnosticsService,
    /**
     * For debugging purposes. The name will form part of the coroutine name of the job that sends the telemetry to the
     * backend.
     */
    val generationJobId: String = "DefaultGenerationId${defaultGenerationIdCount.getAndIncrement()}",
)

private val defaultGenerationIdCount = AtomicInt(0)

@Serializable
internal data class TelemetrySessionAndEventsPayload(
    val session: TelemetrySessionProperties,
    val events: List<JsonElement>,
)

@Serializable
internal data class TelemetrySessionProperties(
    val uid: String?,
    val app: AppEnvironment,
    val appVersion: String,
    val sdkVersion: String,
)

/**
 * Extracted for tests-only, due to not much success with mocking [SpeechifySDKTelemetry.report] directly based on
 * instructions [here](https://stackoverflow.com/questions/49762409/mock-static-java-methods-using-mockk) or [here](https://mockk.io/#extension-functions)
 * (probably because of the [SpeechifySDKTelemetry] indirection), so found workaround from [here](https://stackoverflow.com/a/64606538)
 *
 * NOTE: you need to mock and unmock this function after each test (using
 * `mockkStatic(::mockableTelemetryReporterReport)` and `unmockkStatic(::mockableTelemetryReporterReport)`).
 */
internal fun mockableTelemetryReporterReport(event: ReportableEvent) =
    SpeechifySDKTelemetry.reportImpl(event)
