package com.speechify.client.api.content

import kotlin.js.JsExport

/**
 * A reference to an element in a content tree. This gives us a way to represent "where" text came from in the original content by modeling its natural structure.
 */
@JsExport
open class ContentElementReference internal constructor(
    val path: List<Int>,
    val ref: ObjectRef<Any?> = ObjectRef(null),
) : ContentStartAndEndCursors {

    override val start: ContentCursor get() = ContentElementBoundary(this, ContentBoundary.START)

    override val end: ContentCursor get() = ContentElementBoundary(this, ContentBoundary.END)

    fun compareTo(element: ContentElementReference): ContentRelativeOrder {
        val minSize = minOf(this.path.size, element.path.size)
        for (index in 0 until minSize) {
            val thisPathIndex = this.path[index]
            val otherPathIndex = element.path[index]
            when {
                thisPathIndex < otherPathIndex -> return ContentRelativeOrder.BEFORE
                thisPathIndex > otherPathIndex -> return ContentRelativeOrder.AFTER
            }
        }

        /* Elements are equal when they are identical *or* one of them contains the other
         * WARNING: This is #FootgunOfParentsEqualToChildren - TODO - remove this footgun. See - https://linear.app/speechify-inc/issue/PLT-2754/cursors-footgun-arbitrary-cursors-and-cursors-of-their-ancestors-are
         */
        return ContentRelativeOrder.EQUAL
    }

    /**
     * Get the [ContentTextPosition] at the specified character index contained in this element
     */
    open fun getPosition(characterIndex: Int): ContentTextPosition {
        return ContentTextPosition(this, characterIndex)
    }

    /**
     * Get a reference to a child of this element
     */
    fun getChild(childIndex: Int, ref: ObjectRef<Any?> = ObjectRef(null)): ContentElementReference {
        return ContentElementReference(path + childIndex, ref)
    }

    /**
     * Get a reference to a descendant of this reference by specifying a child traversal path
     */
    fun getDescendant(path: List<Int>, ref: ObjectRef<Any?> = ObjectRef(null)): ContentElementReference {
        return ContentElementReference(this.path + path, ref)
    }

    override fun toString(): String {
        return "ContentElementReference(path=$path, ref=$ref)"
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ContentElementReference

        if (path != other.path) return false
        if (ref != other.ref) return false

        return true
    }

    override fun hashCode(): Int {
        var result = path.hashCode()
        result = 31 * result + ref.hashCode()
        return result
    }

    companion object {
        /**
         * Get a reference to the root element of a content tree
         */
        fun forRoot(ref: ObjectRef<Any?> = ObjectRef(null)): ContentElementReference =
            fromPath(listOf(), ref)

        /**
         * Get a reference to an element by specifying a child traversal path from the root
         */
        internal fun fromPath(path: List<Int>, ref: ObjectRef<Any?> = ObjectRef(null)): ContentElementReference {
            return ContentElementReference(path, ref)
        }
    }
}

/**
 * Static methods for working with [ContentElementReference] instances
 */
internal object ContentElementReferenceUtils {
    fun forRoot(ref: ObjectRef<Any?> = ObjectRef(null)): ContentElementReference {
        return ContentElementReference.forRoot(ref)
    }

    fun fromPath(
        path: List<Int>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ) = ContentElementReference.fromPath(path = path, ref = ref)
}
