package com.speechify.client.reader.core

import com.speechify.client.api.services.subscription.SubscriptionService
import com.speechify.client.api.services.subscription.models.Entitlements
import com.speechify.client.api.services.subscription.models.Subscription
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class PremiumStateHelper private constructor(
    scope: CoroutineScope,
    entitlementsFlow: Flow<Entitlements>,
    subscriptionsFlow: Flow<Array<Subscription>>,
) : Helper<PremiumState>(scope) {
    override val stateFlow: StateFlow<PremiumState> =
        combine(entitlementsFlow, subscriptionsFlow) { entitlement, subscriptions -> entitlement to subscriptions }
            .map { (entitlement, subscriptions) ->
                PremiumState.Ready(
                    isPremium = entitlement.isPremium,
                    hasTTSCapabilities = subscriptions.find { it.plan.hasTTS } != null,
                )
            }.stateInHelper(initialValue = PremiumState.NotReady)

    override val initialState = stateFlow.value

    internal companion object {
        internal fun create(
            scope: CoroutineScope,
            subscriptionService: SubscriptionService,
        ): PremiumStateHelper {
            return PremiumStateHelper(
                scope = scope,
                entitlementsFlow = subscriptionService.getEntitlementsFlowOfResults()
                    .map { it.toNullable() }
                    .filterNotNull(),
                subscriptionsFlow = subscriptionService.getSubscriptionsFlowOfResults()
                    .map { it.toNullable() }.filterNotNull(),
            )
        }
    }
}

@JsExport
sealed class PremiumState {
    data class Ready(val isPremium: Boolean, val hasTTSCapabilities: Boolean) : PremiumState()

    object NotReady : PremiumState()
}
