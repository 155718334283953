package com.speechify.client.helpers.content.standard.book.heuristics.v2.models

import com.speechify.client.helpers.content.standard.book.heuristics.v2.unionBoxes

internal data class ContentBlock(
    val lines: List<ContentLine>,
    val linesPosition: LinePosition,
) {
    val rawTextContentItems = lines.flatMap { it.rawTextContentItems }
    val plainText = lines.joinToString(" ") { it.plainText }
    val box = lines.map { it.box }.unionBoxes()
}
