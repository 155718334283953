package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flow

/**
 * Almost like [kotlinx.coroutines.flow.SharedFlow] but without any [kotlinx.coroutines.flow.SharedFlow.replayCache], so
 * also applicable to non-shared cold flows.
 */
internal interface FlowThatFinishesOnlyThroughCollectionCancel<T> : Flow<T> {
    override suspend fun collect(collector: FlowCollector<T>): Nothing
}

/**
 * A drop-in replacement of [kotlinx.coroutines.flow.flow] that returns a [FlowThatFinishesOnlyThroughCollectionCancel].
 */
internal fun <T> flowThatFinishesOnlyThroughCollectionCancel(
    block: suspend FlowCollector<T>.() -> Nothing,
): FlowThatFinishesOnlyThroughCollectionCancel<T> =
    flow(
        block = block,
    )
        .flowThatFinishesOnlyThroughCollectionCancelStronglyTyped(
            sourceAreaId = "flowThatFinishesOnlyThroughCollectionCancel",
        )

internal fun <T> Flow<T>.flowThatFinishesOnlyThroughCollectionCancelStronglyTyped(
    sourceAreaId: String,
):
    FlowThatFinishesOnlyThroughCollectionCancel<T> =
    object : FlowThatFinishesOnlyThroughCollectionCancel<T> {
        override suspend fun collect(collector: FlowCollector<T>): Nothing {
            this@flowThatFinishesOnlyThroughCollectionCancelStronglyTyped.collect(collector)
            throw IllegalStateException(
                /** This will never be reached because in the above [callbackFlowNeverThrowingToProducer] block
                 * there's no [ProducerScopeForNeverThrowingCallbackFlow.close], so the only way to finish
                 * this flow is by cancelling the collector, which should make the above `collect` throw.
                 */
                "Bug in `$sourceAreaId` - report to SDK maintainers",
            )
        }
    }

/**
 * An equivalent of [kotlinx.coroutines.flow.distinctUntilChanged] that does not lose the
 * [FlowThatFinishesOnlyThroughCollectionCancel] type.
 */
internal fun <T> FlowThatFinishesOnlyThroughCollectionCancel<T>
.distinctUntilChangedThatFinishesOnlyThroughCollectionCancel(): FlowThatFinishesOnlyThroughCollectionCancel<T> =
    this
        .distinctUntilChanged()
        .flowThatFinishesOnlyThroughCollectionCancelStronglyTyped(
            sourceAreaId = "FlowThatFinishesOnlyThroughCollectionCancel.distinctUntilChanged",
        )
