package com.speechify.client.api.services.library.models

import com.speechify.client.api.util.MimeType
import kotlin.js.JsExport

@JsExport
enum class RecordType {
    PDF,
    FILE,
    EXAMPLE,
    WEB,
    TXT,
    SCAN,
    EPUB,
    SPEECHIFY_BOOK,
    UNKNOWN,
    ;

    companion object {
        fun fromMimeType(mimeType: MimeType): RecordType {
            return when (mimeType.typeSubtype.lowercase()) {
                "application/pdf" -> PDF
                "text/plain" -> TXT
                "application/epub+zip" -> EPUB
                else -> WEB
            }
        }
    }
}
