package com.speechify.client.bundlers

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.bundlers.content.ContentBundler
import com.speechify.client.bundlers.content.SpeechifyContentBundler
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.bundlers.reading.UniversalSourcesReadingBundler
import com.speechify.client.bundlers.reading.book.BookReadingBundler
import com.speechify.client.bundlers.reading.classic.ClassicReadingBundler
import com.speechify.client.bundlers.reading.embedded.EmbeddedReadingBundler
import com.speechify.client.bundlers.reading.importing.AllImportableContentImporterFactory
import com.speechify.client.internal.services.userDocumentSettings.BookBundleConfigService
import kotlin.js.JsExport

/**
 * A way to create (Bundlers)[com.speechify.client.bundlers] with customized configuration
 */
@JsExport
class BundlerFactory internal constructor(
    private val speechifyClient: SpeechifyClient,
    private val bundlerPlugins: BundlerPlugins,
    private val bundlerFactoryConfig: BundlerFactoryConfig,
    private val eventsTrackerAdapter: EventsTrackerAdapter,
) {

    internal val listeningBundler =
        ListeningBundler(
            speechifyClient,
            bundlerPlugins,
            bundlerFactoryConfig.listeningBundlerConfig,
            bundlerFactoryConfig.contentBundlerConfig,
        )

    private val importService = speechifyClient.importService

    private val contentBundler = ContentBundler(
        speechifyClient.adaptersProvider,
        bundlerFactoryConfig.contentBundlerConfig,
        bundlerPlugins.bookViewFactory,
        speechifyClient.bookEditingService,
        AllImportableContentImporterFactory(
            importService,
            speechifyClient.libraryService.delegate,
        ),
        speechifyClient.platformBookPageContentStatsService,
        epubChapterContentStatsService = speechifyClient.epubChapterContentStatsService,
        /**
         * Note: When ml-parsing is enabled, we ensure the use of
         * [com.speechify.client.helpers.content.index.ApproximateBookIndexV2] to enhance performance and reduce
         * listening latency.
         */
        useApproximateBookIndexV2 = speechifyClient.clientConfig.options.useApproximateBookIndexV2 ||
            bundlerFactoryConfig.contentBundlerConfig.options.mlParsingMode == MLParsingMode.ForceEnable,
        epubVersion = speechifyClient.clientConfig.options.epubVersion,
        epubSpeechifyBookVersion = speechifyClient.clientConfig.options.epubSpeechifyBookVersion,
        bookBundleConfigService = BookBundleConfigService(
            userProfileService = speechifyClient.userProfileService,
            contentBundlerConfig = bundlerFactoryConfig.contentBundlerConfig,
        ),
    )

    internal val speechifyContentBundler = SpeechifyContentBundler(
        speechifyClient,
        contentBundler,
        speechifyClient.adaptersProvider.blobStorage,
        speechifyClient.adaptersProvider.ocrAdapter,
    )

    fun getContentBundler(): ContentBundler = contentBundler

    fun getListeningBundler(): ListeningBundler = listeningBundler

    /**
     * See [UniversalSourcesReadingBundler].
     */
    val universalSourcesReadingBundler = UniversalSourcesReadingBundler(
        listeningBundler = listeningBundler,
        contentBundler = contentBundler,
        speechifyClient = speechifyClient,
        speechifyContentBundler = speechifyContentBundler,
        eventsTrackerAdapter = eventsTrackerAdapter,
    )

    val classicReadingBundler by lazy { createClassicReadingBundler() }

    fun createClassicReadingBundler(): ClassicReadingBundler {
        return ClassicReadingBundler(
            speechifyClient,
            contentBundler = contentBundler,
            speechifyContentBundler = speechifyContentBundler,
            listeningBundler = listeningBundler,
            config = bundlerFactoryConfig.classicReadingBundlerConfig,
            eventsTrackerAdapter = eventsTrackerAdapter,
        )
    }

    val bookReadingBundler by lazy { createBookReadingBundler() }

    fun createBookReadingBundler(): BookReadingBundler {
        return BookReadingBundler(
            speechifyClient,
            contentBundler = contentBundler,
            speechifyContentBundler = speechifyContentBundler,
            listeningBundler = listeningBundler,
            bookReadingBundlerConfig = bundlerFactoryConfig.bookReadingBundlerConfig,
            eventsTrackerAdapter = eventsTrackerAdapter,
        )
    }

    val embeddedReadingBundler: EmbeddedReadingBundler
        by lazy { createEmbeddedReadingBundler() }

    fun createEmbeddedReadingBundler(): EmbeddedReadingBundler {
        return EmbeddedReadingBundler(
            contentBundler = contentBundler,
            listeningBundler = listeningBundler,
            config = bundlerFactoryConfig.embeddedReadingBundlerConfig,
            eventsTrackerAdapter = eventsTrackerAdapter,
            clientConfig = speechifyClient.clientConfig,
            firebaseFunctionsService = speechifyClient.firebaseFunctionsService,
        )
    }
}
