package com.speechify.client.reader.core

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import kotlinx.serialization.Serializable

/**
 * Opaque representation of a location in content, enabling client to pass unambiguous representation of location
 * between different areas of the Reader program without exposing the underlying representation to clients.
 *
 * DesignIntent: replace [ContentCursor] entirely in the public API surface with this opaque representation, providing
 * additional helpers as necessary to perform the functions for which clients currently manipulate cursors directly.
 * This will provide correct feedback for us to spot gaps in our APIs that cause clients to perpetrate hacks that
 * interpret internal representations of cursors, making our code and integrations more robust.
 */
@Serializable
internal data class SerialLocation internal constructor(
    internal val cursor: ContentCursor,
) {

    // This is not a hack from a design standpoint, as this will always be achievable in steady state
    fun toRobustLocation(): RobustLocation {
        return RobustLocation.fromHack(this)
    }
}

// TODO: change this represeentation to something else
@Serializable
internal data class RobustLocation internal constructor(
    internal val hack: SerialLocation,
) {
    companion object {
        fun fromHack(location: SerialLocation): RobustLocation {
            return RobustLocation(hack = location)
        }
    }
}

internal data class SerialSpan(
    val serialStart: SerialLocation,
    val serialEnd: SerialLocation,
) : Content {

    override val start: ContentCursor get() = this@SerialSpan.serialStart.cursor
    override val end: ContentCursor get() = this@SerialSpan.serialEnd.cursor
}

internal abstract class ApproximateSpan {
    abstract val approximateStart: ApproximateLocation
    abstract val approximateEnd: ApproximateLocation

    companion object {
        fun from(approximateStart: ApproximateLocation, approximateEnd: ApproximateLocation): ApproximateSpan {
            return object : ApproximateSpan() {
                override val approximateStart: ApproximateLocation = approximateStart
                override val approximateEnd: ApproximateLocation = approximateEnd
            }
        }
    }
}

@Serializable
data class ApproximateLocation internal constructor(
    internal val progressFraction: Double,
)
