package com.speechify.client.reader.classic

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.hasNontrivialIntersection
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.slice
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.HighlightsHelperCommand
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.SearchState
import com.speechify.client.reader.core.Selection
import com.speechify.client.reader.core.SelectionHandle
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

/**
 * A helper that emits updates to the appearance of the associated content in [ClassicReader] mode.
 *
 * Each emitted state specifies how the associated content should be displayed or handled at that moment.
 * States are not meant to be combined with previous ones - a new state fully replaces any prior state.
 *
 * @see FormattedText.featuresHelper
 * @see FormattingTree.Text.featuresHelper
 */
@JsExport
class ClassicTextFeaturesHelper internal constructor(
    scope: CoroutineScope,
    internal val readerFeatures: Flow<ReaderFeatures>,
    internal val contentText: ContentText,
) : Helper<ClassicTextFeatures>(scope) {
    // observable feature state
    override val stateFlow: StateFlow<ClassicTextFeatures> = readerFeatures.map {
        val foundSearchState = it.searchState as? SearchState.MatchesFound
        ClassicTextFeatures(
            wordHighlight = it.speakingWord?.let { wordContent ->
                contentText.intersectionRange(wordContent)?.let { range ->
                    val isWordEndsWithWhiteSpace = contentText.slice(range).text.endsWith(" ")
                    IndexRange(range.first, range.last + if (isWordEndsWithWhiteSpace) 0 else 1)
                }
            },
            sentenceHighlight = it.speakingSentence?.let { sentenceContent ->
                contentText.intersectionRange(sentenceContent)?.let { range ->
                    val isSentenceEndsWithWhiteSpace = contentText.slice(range).text.endsWith(" ")
                    IndexRange(range.first, range.last + if (isSentenceEndsWithWhiteSpace) 0 else 1)
                }
            },
            selection = it.selection?.let {
                val selectionHandles = contentText.findBoundaryHandles(it)
                contentText.intersectionRange(it)?.let { range ->
                    val selectedText = contentText.slice(range).text
                    val isSelectedTextEndsWithWhiteSpace = selectedText.endsWith(" ")
                    val isSelectedTextStartsWithWhiteSpace = selectedText.startsWith(" ")
                    ClassicTextSelection(
                        IndexRange(
                            range.first + if (isSelectedTextStartsWithWhiteSpace) 1 else 0,
                            range.last + if (isSelectedTextEndsWithWhiteSpace) 0 else 1,
                        ),
                        selectionHandles?.first,
                        selectionHandles?.second,
                    )
                }
            },
            userHighlights = it.highlights.items
                .map { it.highlight }
                .filter { highlight -> highlight.span.hasNontrivialIntersectionWith(contentText) }
                .mapNotNull { highlight ->
                    contentText.intersectionRange(highlight.span)?.let { range ->
                        ClassicTextUserHighlight(
                            dispatch = dispatch,
                            userHighLight = highlight,
                            range = IndexRange(range.first, range.last + 1),
                        )
                    }
                }.toTypedArray(),
            navigationIntent = it.navigationIntent?.let {
                val cursor = it.location.hack.cursor
                if (this.contentText.contains(cursor)) {
                    FineClassicNavigationIntent(
                        dispatch = dispatch,
                        resolvedIntent = it,
                        charIndex = contentText.getLastIndexOfCursor(cursor),
                    )
                } else {
                    null
                }
            },
            hoveredSentence = it.hoveredSentence?.let {
                contentText.intersectionRange(it)?.let { range ->
                    IndexRange(range.first, range.last + 1)
                }
            },
            // TODO: Check the matches that cover multiple contentTexts
            searchMatches = foundSearchState?.let { found ->
                // TODO: Avoid checking every match, use binary search if the matches startCursor are sorted
                found.matches.mapNotNull { match ->
                    if (match === found.focusedMatch) {
                        null
                    } else {
                        contentText.intersectionRange(match)?.let { range ->
                            IndexRange(range.first, range.last + 1)
                        }
                    }
                }.toTypedArray()
            },
            focusedSearchMatch = foundSearchState?.focusedMatch?.let { match ->
                contentText.intersectionRange(match)?.let { range ->
                    IndexRange(range.first, range.last + 1)
                }
            },
        )
    }.stateInHelper(initialValue = ClassicTextFeatures.empty)

    override val initialState = stateFlow.value

    private fun ContentText.intersectionRange(other: Selection): IntRange? {
        if (!hasNontrivialIntersection(start, end, other.start, other.end)) return null
        val thisStart = this.getFirstIndexOfCursor(other.start) + startOffsetFor(other)
        val thisEnd = this.getLastIndexOfCursor(other.end) + endOffsetFor(other)
        return thisStart..thisEnd
    }

    private fun ContentText.startOffsetFor(
        content: Selection,
    ) = if (contains(content.start)) content.startOffset else 0

    private fun ContentText.endOffsetFor(
        content: Selection,
    ) = if (contains(content.end)) content.endOffset else 0
}

/**
 * Defines how the associated text content should appear in [ClassicReader] mode.
 *
 * To receive updates on how text content should appear,
 * [subscribe to the ClassicTextFeaturesHelper][ClassicTextFeaturesHelper.addStateChangeListener] linked to it.
 */
@JsExport
data class ClassicTextFeatures(
    /**
     * The index range of the word currently being spoken in the associated text content.
     * `null` if no word in the associated text content is being spoken.
     */
    val wordHighlight: IndexRange?,

    /**
     * The index range of the sentence currently being spoken in the associated text content.
     * `null` if no sentence in the associated text content is being spoken.
     */
    val sentenceHighlight: IndexRange?,

    /**
     * The currently active user selection in the associated text content.
     * `null` if no text in the associated text content is selected.
     */
    val selection: ClassicTextSelection?,

    /**
     * The highlights added by the user in the associated text content.
     * Empty if no text in the associated text content is highlighted.
     */
    val userHighlights: Array<ClassicTextUserHighlight>,

    /**
     * The intent to center a specific [char][FineClassicNavigationIntent.charIndex] in the associated text content
     * on the user's display.
     * `null` if no centering action is requested for the associated text content.
     */
    val navigationIntent: FineClassicNavigationIntent?,

    /**
     * The index range of the sentence currently hovered over in the associated text content.
     * `null` if no sentence in the associated text content is hovered over by the user.
     */
    val hoveredSentence: IndexRange?,

    /**
     * The index ranges in the associated text content that match the search query.
     * `null` or an empty array if no matches were found in the associated text content.
     */
    val searchMatches: Array<IndexRange>?,

    /**
     * The index range of the focused match in the associated text content.
     * `null` if no matched text in the associated text content is focused.
     */
    val focusedSearchMatch: IndexRange?,
) {
    internal companion object {
        internal val empty = ClassicTextFeatures(
            wordHighlight = null,
            sentenceHighlight = null,
            selection = null,
            userHighlights = emptyArray(),
            navigationIntent = null,
            hoveredSentence = null,
            searchMatches = null,
            focusedSearchMatch = null,
        )
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ClassicTextFeatures

        if (wordHighlight != other.wordHighlight) return false
        if (sentenceHighlight != other.sentenceHighlight) return false
        if (selection != other.selection) return false
        if (!userHighlights.contentEquals(other.userHighlights)) return false
        if (navigationIntent != other.navigationIntent) return false
        if (hoveredSentence != other.hoveredSentence) return false
        if (searchMatches != null) {
            if (other.searchMatches == null) return false
            if (!searchMatches.contentEquals(other.searchMatches)) return false
        } else if (other.searchMatches != null) return false
        if (focusedSearchMatch != other.focusedSearchMatch) return false

        return true
    }

    override fun hashCode(): Int {
        var result = wordHighlight?.hashCode() ?: 0
        result = 31 * result + (sentenceHighlight?.hashCode() ?: 0)
        result = 31 * result + (selection?.hashCode() ?: 0)
        result = 31 * result + userHighlights.contentHashCode()
        result = 31 * result + (navigationIntent?.hashCode() ?: 0)
        result = 31 * result + (hoveredSentence?.hashCode() ?: 0)
        result = 31 * result + (searchMatches?.contentHashCode() ?: 0)
        result = 31 * result + (focusedSearchMatch?.hashCode() ?: 0)
        return result
    }
}

@JsExport
data class ClassicTextSelection internal constructor(
    val indexRange: IndexRange,
    val startHandle: SelectionHandle?,
    val endHandle: SelectionHandle?,
)

@JsExport
data class ClassicTextUserHighlight internal constructor(
    internal val dispatch: CommandDispatch,
    internal val userHighLight: UserHighlight,
    val range: IndexRange,
) {
    val highlight: UserHighlight = userHighLight

    val key get() = userHighLight.id

    fun delete() {
        dispatch(HighlightsHelperCommand.DeleteHighlight(userHighLight.id))
    }

    fun updateColor(colorToken: UserHighlight.Style.ColorToken) {
        dispatch(
            HighlightsHelperCommand.UpdateColor(
                userHighLight.id,
                colorToken,
            ),
        )
    }

    fun updateNote(note: String?) {
        dispatch(
            HighlightsHelperCommand.UpdateNote(
                userHighLight.id,
                note = note,
            ),
        )
    }
}
