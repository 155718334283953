package com.speechify.client.api.content.view.book

import com.speechify.client.api.util.images.Viewport
import kotlin.js.JsExport

@JsExport
data class BookPageMetadata(
    val pageIndex: Int,
    val viewport: Viewport,
)
