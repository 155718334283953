package com.speechify.client.api.telemetry

/*
* TelemetryContentType is used for listening dashboard
* This should only be used when no discernible type available
*/
internal enum class TelemetryContentType(val contentType: String) {
    FileOfUnknownMimeType("File Of Unknown Mime Type"),
    UrlOfUnknownMimeType("Url Of Unknown Mime Type"),

    // Scanned Book content usually has multiple files so this is used.
    ScannedBook("Scanned Book"),
    SharedContent("Shared Content"),
}
