package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
data class StripeSubscriptionPaymentIntent(
    val clientSecret: String? = null,
)

@JsExport
sealed class SubscriptionCreationResponse {
    data class Generic(
        val message: String? = null,
        val err: String? = null,
    ) : SubscriptionCreationResponse()

    data class Stripe(
        val message: String? = null,
        val err: String? = null,
        val paymentIntent: StripeSubscriptionPaymentIntent? = null,
    ) : SubscriptionCreationResponse()
}
