package com.speechify.client.helpers.content.standard.dynamic.contentProviders

import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.util.AbortReceiverAsync
import com.speechify.client.api.util.Destructible
import com.speechify.client.helpers.content.standard.HasInfoOfContentLimitedLife
import com.speechify.client.helpers.content.standard.HasInfoOfEffectOfPullingContentOnUser
import com.speechify.client.helpers.content.standard.streamable.StreamableContentChunksBuilder
import com.speechify.client.internal.util.collections.flows.MutableSharedFlowConflatedEmittableSynchronously
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.flow.Flow
import kotlin.js.JsExport

/**
 * The simplest 'Dynamic content provider', one for 'immutable, always live content'. What these mean:
 * * 'immutable' means the user cannot edit it, or it does not live-update itself (use
 *   [DynamicContentProviderForMutableLimitedLifeContent] if you want support for these)
 * * 'always live' means that the content's UI representation **does not** employ memory-saving strategies, e.g.
 *   removing chunks when user navigates to new ones (use [DynamicContentProviderForImmutableLimitedLifeContent] if it
 *   doesn't).
 *   Examples of such sources include web pages with 'show more' or 'autoload on scroll to the end', where adding of
 *   content by such interaction never removes the existing content.
 *   Because in such sources content never becomes removed, it doesn't need to get re-created, so the interface can
 *   be very simple - there's no need for methods related to handling this situation.
 */
@JsExport
interface DynamicContentProviderForImmutableAlwaysLiveContent : DynamicContentProviderBase {
    override fun isAlive(ref: ObjectRef<Any?>): Boolean =
        true
}

/**
 * The more advanced 'Dynamic content provider' (the simpler one being
 * [DynamicContentProviderForImmutableAlwaysLiveContent]), one for 'immutable, limited life content'. What these mean:
 * * 'immutable' means the user cannot edit it, or it does not live-update itself (use
 *   [DynamicContentProviderForMutableLimitedLifeContent] if you want support for these)
 * * 'limited life' means that the content's UI representation **does** employ memory-saving strategies, e.g. removing
 *   chunks when user navigates to new ones (use [DynamicContentProviderForImmutableAlwaysLiveContent] if it doesn't).
 *   Examples of such sources include: web pages with 'show more' or 'autoload on scroll to the end',
 *   where adding of content by such interaction can also remove part of the existing content.
 */
@JsExport
interface DynamicContentProviderForImmutableLimitedLifeContent : DynamicContentProviderBase {
    /**
     * Returns the information whether the referenced element, produced earlier by the [getContent] to
     * as the association with the text is still alive for the purpose of highlighting.
     * If this function returns `false`, the player will then try to refresh the entire chunk using [tryGetResurrectedContent]
     * (see there for continued narration).
     */
    override fun isAlive(ref: ObjectRef<Any?>): Boolean

    /**
     * This function should respond with the chunk at [chunkIndex] (by using [responseProducer]'s
     * [TryGetAliveContentResponseProducer.FoundAliveContent]) only if it has its representation currently in the app
     * (its old representation was previously declared stale by [isAlive] returning true on one of the references
     * it contained).
     * If the chunk doesn't currently have a representation (e.g. user scrolled away and the chunk was released to
     * save memory), then it should respond with [TryGetAliveContentResponseProducer.FoundNoAliveContent]. In such case
     * the reader will still read the text passed originally in [getContent] with the old reference. **NOTE**: This
     * means that the client's highlighting infrastructure should also expect references to already-deleted
     * representations of the text (typically, it should use the same logic as in [isAlive] to inhibit itself from
     * rendering the highlighting).
     *
     * @param chunkIndex - the index of the chunk to return.
     * @param responseProducer - the implementation is to use this interface to provide the response - *NOTE: it must
     * give only one response!*
     * @param builder - the implementation can use this to build the contents with minimum code via the builder pattern
     * (a single place to discover various types of supported content and no need to import any constructors; helps to
     * produce a response with a single expression, without any variables).
     * @param abortThisRequestReceiver - The implementation should subscribe to this parameter's
     * [AbortReceiverAsync.onAborted], to act on abandonment of the request to clean up any side effects were made to
     * handle it, for example cancelling any started timeouts (in JS using `resetTimeout`), UI observers awaiting the
     * content or web requests. This is important for preventing memory leaks, performance, as well as clarity of
     * diagnostics to developers (so that logs are not littered with noise from old orphaned requests).
     */
    fun tryGetResurrectedContent(
        chunkIndex: Int,
        responseProducer: TryGetAliveContentResponseProducer,
        builder: StreamableContentChunksBuilder,
        abortThisRequestReceiver: AbortReceiverAsync<Throwable?>,
    )
}

/**
 * The interface for [DynamicContentProviderForMutableLimitedLifeContent] with SDK-internal members.
 */
internal interface DynamicContentProviderForMutableLimitedLifeContentWithSDKInternals :
    DynamicContentProviderBase {

    val mutationsFlow: Flow<Unit>
}

/**
 * The most advanced 'Dynamic content provider' (the simpler ones being
 * [DynamicContentProviderForImmutableAlwaysLiveContent] and [DynamicContentProviderForImmutableLimitedLifeContent]),
 * one for 'mutable content'. What this means:
 * * 'mutable' means the user can edit it, or the content live-updates itself
 * * 'limited life' means that the content's UI representation **does** employ memory-saving strategies, e.g. removing
 *   chunks when user navigates to new ones. This also means that content loaded previously can get live-updated
 *   when outside of UI view.
 *   Examples of such sources include: Google Docs.
 *
 * # Consequences for the user experience
 * Informed by this interfaces' _mutable_ characteristic, the playing infrastructure will take strategies that can vary
 * between (SDK may evolve the approach):
 * * reduced ahead buffering, to ensure it speaks actual content which, in turn, would reduce the quality of experience
 *   when on slow network/offline.
 * * sacrificing the responsiveness of rewinding to previous content, to always speak the actual content (by minimizing
 *   the cache of the content 'behind')
 * * being more CPU intensive, (e.g. requesting fresh content a lot), but less memory. TODO - consider adding a members
 *   for notifying 'contentChanged' events, if the CPU becomes an issue.
 */
@JsExport
abstract class DynamicContentProviderForMutableLimitedLifeContent :
    HasInfoOfContentLimitedLife,
    DynamicContentProviderForMutableLimitedLifeContentWithSDKInternals {
    /**
     * Returns the information whether the referenced element, produced earlier by the [getContent] to
     * as the association with the text is still alive for the purpose of highlighting.
     * If this function returns `false`, the player's highlighting stack ([com.speechify.client.helpers.features.CurrentWordAndSentenceOverlayHelper])
     * will try to refresh the references for the [com.speechify.client.helpers.features.CurrentWordAndSentenceOverlayEvent]
     * that it emits (the mutable content provider will see this in a form of another call to [DynamicContentProviderBase.getContent] asking from the chunk
     * currently being played - #MutableContentResurrectsViaGetContent).
     */
    abstract override fun isAlive(ref: ObjectRef<Any?>): Boolean

    /**
     * Notifies that a mutation has occurred to the content.
     * This is intended to cause the playback to inspect its buffered audio and regenerate utterances where the text
     * has changed.
     */
    protected fun notifyOfMutation() {
        mutationsFlowMutable.tryEmit(Unit)
            /* `ignore` if no-one is listening, to prevent signalling some old mutation when the subscriber got the
             * content already after the mutation (#IgnoreIfNoOneIsListeningToMutations) */
            .ignoreValue()
    }

    @JsExport.Ignore
    override val mutationsFlow: Flow<Unit> get() =
        mutationsFlowMutable

    /**
     * The `private` flow for encapsulating into two sides which only see their allowed actions: producer and consumer
     */
    private val mutationsFlowMutable =
        MutableSharedFlowConflatedEmittableSynchronously<Unit>()
}

/**
 * The common interface of all dynamic content providers (also equivalent to the most basic provider,
 * the [DynamicContentProviderForImmutableAlwaysLiveContent]).
 */
@JsExport
interface DynamicContentProviderBase :
    HasInfoOfEffectOfPullingContentOnUser,
    HasInfoOfContentLimitedLife,
    Destructible {
    /**
     * @param requestInfo - it tells the implementor which page is needed (especially through
     * [PointerToChunkFraction.chunkIndex]). `null` means a request for a starting chunk which should be decided by
     * the implementation of the interface (allows to start from wherever the user is currently scrolled). To make this
     * decision, the implementation must use [ContentResponseProducer.MatchingChunkWithEstimate] with
     * `estimatedChunksCountBefore` to indicate the estimated actual index.
     * It also gives information about the nature of the request - see [ContentRequestInfo].
     * @param responseProducer - the implementation is to use this interface to provide the response - *NOTE: it must
     * give only one response!*
     * @param builder - the implementation can use this to build the contents with minimum code via the builder pattern
     * (a single place to discover various types of supported content and no need to import any constructors; helps to
     * produce a response with a single expression, without any variables).
     * @param abortThisRequestReceiver - The implementation should subscribe to this parameter's
     * [AbortReceiverAsync.onAborted], to act on abandonment of the request to clean up any side effects were made to
     * handle it, for example cancelling any started timeouts (in JS using `resetTimeout`), UI observers awaiting the
     * content or web requests. This is important for preventing memory leaks, performance, as well as clarity of
     * diagnostics to developers (so that logs are not littered with noise from old orphaned requests).
     */
    fun getContent(
        requestInfo: ContentRequestInfo,
        responseProducer: ContentResponseProducer,
        builder: StreamableContentChunksBuilder,
        abortThisRequestReceiver: AbortReceiverAsync<Throwable?>,
    )
}
