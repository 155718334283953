package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentBlock
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingContext
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage

internal class ParsingPipeline<I, O>(private val current: ParsingPipelineStage<I, O>) {

    /**
     * Each stage modifies the input data and returns the processed data.
     * The order of the stages:
     *  - IdentifyPlatform - determines whether the content belongs to a mobile or web platform and updates the context accordingly.
     *
     *   TODO: Support right-to-left parsing.
     *   TODO: The algorithm is not stable for drop caps.
     *  - ProcessHorizontalLines - groups words into HORIZONTAL lines; required for mobile only.
     *  - ProcessVerticalLines - groups words into VERTICAL lines; required for mobile only.
     *
     *    TODO: Vertical lines may be ordered in the opposite top-down order.
     *  - SortLineContent - sorts words within horizontal/vertical lines in right-to-left or bottom-to-top order.
     *  - LinesToBlocks - merges lines into blocks.
     *
     *    TODO: Vertical blocks may be ordered in the opposite right-to-left order.
     *  - SortBlockContent - sorts lines within a block in top-to-bottom order, and for vertical blocks, in left-to-right order.
     *  - UnionBlocksColumn - merges blocks that belong to the same column.
     *  - SortBlocks - performs the general right-to-left, top-to-bottom block sorting.
     */
    companion object {
        fun startPipeline(
            content: List<BookPageTextContentItem>,
            logger: Logger? = null,
        ): List<ContentBlock> {
            val context = ParsingContext(content, logger)
            val pipeline =
                ParsingPipeline(ProcessHorizontalLines(context, logger))
                    .pipe(ProcessVerticalLines(context, logger))
                    .pipe(SortLineContent(logger))
                    .pipe(LinesToBlocks(logger))
                    .pipe(SortBlockContent(logger))
                    .pipe(UnionBlocksColumn(logger))
                    .pipe(SortBlocks(logger))

            return pipeline.execute(content)
        }
    }

    private fun <NewO> pipe(next: ParsingPipelineStage<O, NewO>): ParsingPipeline<I, NewO> {
        return ParsingPipeline(
            object : ParsingPipelineStage<I, NewO> {
                override fun process(input: I): NewO {
                    return next.process(current.process(input))
                }
            },
        )
    }

    private fun execute(input: I): O {
        return current.process(input)
    }
}
