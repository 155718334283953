package com.speechify.client.api.content.ml

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed class MLParsingMode {

    @Serializable
    @SerialName("ForceEnable")
    object ForceEnable : MLParsingMode()

    @Serializable
    @SerialName("ForceDisable")
    object ForceDisable : MLParsingMode()
}
