package com.speechify.client.api.adapters.ocr

import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
abstract class OCRAdapter(
    /**
     * The maximum number of concurrent OCR requests that can be pending at any single time (SDK will guard this).
     * Use it to speed up user experience, but choose this keeping in mind the CPU usage for local OCR (utilizing more
     * CPU cores will speed up the experience, but overloading it will actually slow it down), and the load on service
     * for remote OCR (services may have limit on requests-per-period or just get slowed by overload).
     */
    maxConcurrency: Int,
) {
    init {
        check(maxConcurrency > 0) {
            "`OCRAdapter.maxConcurrency` must be > 0 but was $maxConcurrency." +
                " Please specify it in `OCRAdapter`'s constructor."
        }
    }

    /**
     * NOTE: `null` should be returned(`Result.Success(null)` exactly for the callback) when OCR service returns no
     * result and no error, or when the service is not available for the user and this is not a bug or failure (e.g.
     * OCR is only for paying users and the user is not a paying one).
     * Should still return `Result.Failure` if there was an error and this was the reason for no
     * OCR result, so that an error is reported to developers, as it could have been due to bad request.
     */
    // protected, as this is just to implement the functionality - SDK should use the other accessible members.
    protected abstract fun runOCR(
        binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        ocrSource: OcrSource,
        callback: Callback<OCRResult?>,
    )

    internal suspend fun runOCR(
        binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        ocrSource: OcrSource,
    ): Result<OCRResult?> =
        ocrMaxConcurrencyGuard.runOCREnsuringConcurrencyLimit {
            debugCallAndResultWithUuid("OCRAdapter.runOCR($binaryContent)") {
                suspendCoroutine {
                    runOCR(binaryContent, ocrSource, it::resume)
                }
            }
        }

    /**
     * Used to ensure that the maximum number of concurrent OCR requests
     */
    internal val ocrMaxConcurrencyGuard = OCRMaxConcurrencyGuard(maxConcurrency)
}

@JsExport
enum class OcrSource {
    ScannedBookPage,
    ImageBasedPdfPage,
}
