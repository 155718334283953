package com.speechify.client.api.content

class TransientContentElementReference(
    internal val originalContentElementReference: ContentElementReference,
    pathAddition: Int,
) : ContentElementReference(
    // We unwrap to the original reference in case the original reference is also a transient reference.
    // Not doing this would cause the content sorting to be wrong since the original transient path would take
    // precedence over the later added path addition.
    originalContentElementReference.originalReference().path + pathAddition,
    originalContentElementReference.originalReference().ref,
) {

    override fun getPosition(characterIndex: Int): ContentTextPosition {
        return TransientContentTextPosition(
            originalContentElementReference.originalReference().getPosition(characterIndex),
            this,
        )
    }

    override fun toString(): String {
        return "TransientContentElementReference(originalContentElementReference=$originalContentElementReference)"
    }
}

/**
 * A wrapper around a [ContentTextPosition].
 * This is used to make cursors appear as linear even if the underlying content is not.
 *
 */
internal class TransientContentTextPosition(
    internal val originalContentTextPosition: ContentTextPosition,
    transientContentElementReference: TransientContentElementReference,
) : ContentTextPosition(transientContentElementReference, originalContentTextPosition.characterIndex) {

    override fun toString(): String {
        return "TransientContentTextPosition(originalContentTextPosition=$originalContentTextPosition)"
    }
}

private fun ContentElementReference.originalReference(): ContentElementReference {
    return when (this) {
        is TransientContentElementReference -> this.originalContentElementReference
        else -> this
    }
}
