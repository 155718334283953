package com.speechify.client.api.adapters.events

import com.benasher44.uuid.uuid4
import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.audio.AudioControllerEvent
import com.speechify.client.api.audio.getEventsColdFlow
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.boundary.observableValue.asFlow
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.CharactersListenedEventProperties
import com.speechify.client.bundlers.reading.EmbeddedBundleMetadata
import com.speechify.client.bundlers.reading.EventType
import com.speechify.client.bundlers.reading.FileImportedEventProperties
import com.speechify.client.bundlers.reading.ListeningFinishedEventProperties
import com.speechify.client.bundlers.reading.ListeningStartedEventProperties
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.SpeedEventProperties
import com.speechify.client.bundlers.reading.VoiceEventProperties
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.helpers.ui.controls.PlayPauseButton
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.services.DetectLanguageOfTextPayload
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.library.models.ContentAccess
import com.speechify.client.internal.util.extensions.collections.flows.PreviousAndCurrentValues
import com.speechify.client.internal.util.extensions.collections.flows.currentAndPreviousValues
import com.speechify.client.internal.util.extensions.collections.flows.emitFirstValueAsPairWhere
import com.speechify.client.internal.util.www.parseUrl
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChangedBy
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.last
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.flow.transform
import kotlin.math.absoluteValue
import kotlin.random.Random
import kotlin.time.Duration.Companion.seconds

internal fun reportEventsFromFlows(
    dependencies: ReadingBundle.Dependencies,
    contentBundle: ContentBundle,
    playbackControls: PlaybackControls,
    speechifyClient: SpeechifyClient,
    eventsTrackerAdapter: EventsTrackerAdapter,
    bundleMetadataFlow: Flow<BundleMetadata?>,
) {
    val random: Random = Random
    val sessionIdFlow = MutableStateFlow<String>("")
    val contentLanguageFlow = MutableStateFlow<String?>(null)
    val languageDetectionText = MutableStateFlow<String>("")

    fun extractHostname(
        libraryItem: LibraryItem.Content?,
        bundleMetadata: ImportableContentMetadata?,
    ): String? {
        if (bundleMetadata?.contentHostname != null) {
            return bundleMetadata.contentHostname
        }
        return libraryItem?.sourceUrl?.let { extractHostname(it) }
    }

    fun String.toSnakeCase(): String {
        return this.replace("-", "_").lowercase()
    }

    fun determineContentType(contentType: ContentType?, contentSubType: String?): String {
        if (contentSubType == null) {
            return contentType?.name?.lowercase()?.toSnakeCase() ?: "unknown"
        }

        if (contentSubType == "ai_chat") {
            return ContentType.TXT.name.lowercase()
        }

        if (contentSubType == "gmail") {
            return ContentType.HTML.name.lowercase()
        }

        return contentType?.name?.lowercase()?.toSnakeCase() ?: "unknown"
    }

    fun sendListeningStartedEvent(
        libraryItem: LibraryItem.Content?,
        playbackState: PlaybackControls.State,
        title: String,
        contentSubType: String?,
        voiceName: String = "unknown",
        voiceId: String = "unknown",
        bundleMetadata: BundleMetadata?,
    ) {
        val extractedContentSubtype = extractContentSource(libraryItem, contentSubType ?: "unknown")
        val properties = ListeningStartedEventProperties(
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = voiceName,
            voice_id = voiceId,
            voice_speed_initial = playbackState.wordsPerMinute,
            content_name = title,
            // We assume that if we don't have a library item, it is a web page using chrome extension for now
            content_type = determineContentType(libraryItem?.contentType, contentSubType),
            content_source = extractedContentSubtype,
            content_hostname = extractHostname(libraryItem, bundleMetadata),
            content_uri = libraryItem?.sourceStoredUrl,
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningStarted.eventName,
            properties.toMap(),
        )
    }

    fun sendListeningStartedEvent(
        libraryItem: LibraryItem.DeviceLocalContent?,
        playbackState: PlaybackControls.State,
        title: String,
        contentSubType: String?,
        voiceName: String = "unknown",
        voiceId: String = "unknown",
        bundleMetadata: BundleMetadata?,
    ) {
        val contentHostname = if (bundleMetadata?.contentHostname == null) {
            extractHostname(libraryItem?.sourceUrl)
        } else {
            bundleMetadata.contentHostname
        }
        val extractedContentSubtype = extractContentSource(libraryItem, contentSubType ?: "unknown")
        val properties = ListeningStartedEventProperties(
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = voiceName,
            voice_id = voiceId,
            voice_speed_initial = playbackState.wordsPerMinute,
            content_name = title,
            // We assume that if we don't have a library item, it is a web page using chrome extension for now
            content_type = determineContentType(libraryItem?.contentType, contentSubType),
            content_source = extractedContentSubtype,
            content_hostname = contentHostname,
            content_uri = libraryItem?.uri.toString(),
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningStarted.eventName,
            properties.toMap(),
        )
    }

    fun sendListeningStartedEvent(
        playbackState: PlaybackControls.State,
        title: String,
        voiceName: String = "unknown",
        voiceId: String = "unknown",
        bundleMetadata: BundleMetadata?,
    ) {
        val properties = ListeningStartedEventProperties(
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = voiceName,
            voice_id = voiceId,
            voice_speed_initial = playbackState.wordsPerMinute,
            content_name = title,
            content_type = determineContentType(bundleMetadata?.contentType, bundleMetadata?.contentSubType),
            content_source = bundleMetadata?.contentSubType,
            content_hostname = extractHostname(null, bundleMetadata),
            content_uri = null,
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningStarted.eventName,
            properties.toMap(),
        )
    }

    suspend fun sendListeningFinishedEvent(
        libraryItem: LibraryItem.Content?,
        playbackState: PlaybackControls.State,
        initialVoiceSpeed: Int,
        initialVoiceName: String,
        initialVoiceId: String,
        title: String,
        contentSubType: String?,
        characters: Int,
        words: Int,
        bundleMetadata: BundleMetadata?,
        firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    ) {
        val extractedContentSubtype = extractContentSource(libraryItem, contentSubType ?: "unknown")
        val contentLanguage = contentLanguageFlow.value
            ?: detectLanguageFromText(languageDetectionText.value, firebaseFunctionsService)
        val properties = ListeningFinishedEventProperties(
            characters = characters,
            words = words,
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = initialVoiceName,
            voice_speed_initial = initialVoiceSpeed,
            voice_name_final = playbackState.voiceOfCurrentUtterance?.displayName ?: initialVoiceName,
            voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: initialVoiceId,
            voice_speed_final = playbackState.wordsPerMinute,
            content_language = contentLanguage,
            content_name = title,
            content_type = determineContentType(libraryItem?.contentType, extractedContentSubtype),
            content_source = extractedContentSubtype,
            content_hostname = extractHostname(libraryItem, bundleMetadata),
            content_uri = libraryItem?.sourceStoredUrl,
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningFinished.eventName,
            properties.toMap(),
        )
    }

    suspend fun sendListeningFinishedEvent(
        libraryItem: LibraryItem.DeviceLocalContent?,
        playbackState: PlaybackControls.State,
        initialVoiceSpeed: Int,
        initialVoiceName: String,
        initialVoiceId: String,
        title: String,
        contentSubType: String?,
        characters: Int,
        words: Int,
        bundleMetadata: BundleMetadata?,
    ) {
        val extractedContentSubtype = extractContentSource(libraryItem, contentSubType ?: "unknown")
        val contentLanguage = contentLanguageFlow.value ?: detectLanguageFromText(
            languageDetectionText.value,
            speechifyClient.firebaseFunctionsService,
        )
        val contentHostname = if (bundleMetadata?.contentHostname == null) {
            extractHostname(libraryItem?.sourceUrl)
        } else {
            bundleMetadata.contentHostname
        }
        val properties = ListeningFinishedEventProperties(
            characters = characters,
            words = words,
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = initialVoiceName,
            voice_speed_initial = initialVoiceSpeed,
            voice_name_final = playbackState.voiceOfCurrentUtterance?.displayName ?: initialVoiceName,
            voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: initialVoiceId,
            voice_speed_final = playbackState.wordsPerMinute ?: initialVoiceSpeed,
            content_language = contentLanguage,
            content_name = title,
            content_type = determineContentType(libraryItem?.contentType, extractedContentSubtype),
            content_source = extractedContentSubtype,
            content_hostname = contentHostname,
            content_uri = libraryItem?.uri.toString(),
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningFinished.eventName,
            properties.toMap(),
        )
    }

    suspend fun sendListeningFinishedEvent(
        playbackState: PlaybackControls.State,
        initialVoiceSpeed: Int,
        initialVoiceName: String,
        initialVoiceId: String,
        title: String,
        characters: Int,
        words: Int,
        bundleMetadata: BundleMetadata?,
    ) {
        val contentLanguage = contentLanguageFlow.value ?: detectLanguageFromText(
            languageDetectionText.value,
            speechifyClient.firebaseFunctionsService,
        )
        val properties = ListeningFinishedEventProperties(
            characters = characters,
            words = words,
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            voice_name_initial = initialVoiceName,
            voice_speed_initial = initialVoiceSpeed,
            voice_name_final = playbackState.voiceOfCurrentUtterance?.displayName ?: initialVoiceName,
            voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: initialVoiceId,
            voice_speed_final = playbackState.wordsPerMinute ?: initialVoiceSpeed,
            content_language = contentLanguage,
            content_name = title,
            // We assume that if we don't have a library item, it is a web page using chrome extension for now
            content_type = determineContentType(bundleMetadata?.contentType, bundleMetadata?.contentSubType),
            content_source = bundleMetadata?.contentSubType ?: "unknown",
            content_hostname = extractHostname(null, bundleMetadata),
            content_uri = null,
            session_id = sessionIdFlow.value,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningFinished.eventName,
            properties.toMap(),
        )
    }

    fun sendFileImportedEvent(
        contentImporterState: ContentImporterState.ImportedToLibrary,
        importableContentMetadata: ImportableContentMetadata,
    ) {
        val properties = FileImportedEventProperties(
            app_platform = speechifyClient.clientConfig.appEnvironment.name.lowercase(),
            app_version = speechifyClient.clientConfig.appVersion,
            import_flow = importableContentMetadata.importFlow.eventReportingValue,
            content_name = contentImporterState.libraryItem.title,
            // TODO: Technically, contentType can't be null, but I received null/undefined from our example webapp
            // To be investigated
            content_type = contentImporterState.libraryItem.contentType?.name?.lowercase() ?: "unknown",
            content_source = importableContentMetadata.contentSubType,
            content_hostname = extractHostname(
                contentImporterState.libraryItem,
                importableContentMetadata,
            ),
            content_uri = contentImporterState.libraryItem.sourceStoredUrl,
        )
        eventsTrackerAdapter.track(
            EventType.FileImported.eventName,
            properties.toMap(),
        )
    }

    val textListenedHelper = TextListenedHelper(
        playbackControls.audioController.getEventsColdFlow()
            .filterIsInstance<AudioControllerEvent.Playing>(),
        dependencies.scope,
    )

    initializeLanguageDetectionFlow(
        dependencies.scope,
        playbackControls,
        languageDetectionText,
        contentLanguageFlow,
        speechifyClient.firebaseFunctionsService,
    )

    // Playback state flow
    val playbackFlow = playbackControls.stateFlow.buffer(capacity = Channel.UNLIMITED)
        .distinctUntilChangedBy { it.playPauseButton }
        .emitFirstValueAsPairWhere {
            it.voiceOfCurrentUtterance != null
        }

    val voiceChangeFlow = playbackControls.stateFlow.distinctUntilChangedBy { it.voiceOfCurrentUtterance }

    val importFlow = contentBundle.importer.stateFlow.filterNotNull()
    // We need to store the last value of the import flow since it gets destroyed
    // when the dependencies.scope gets destroyed, and otherwise we don't emit
    // the `Text Listened` event if the user navigates to a different page while listening.
    val importFlowValue = importFlow.stateIn(
        scope = dependencies.scope,
        started = SharingStarted.Eagerly,
        initialValue = null,
    )

    val listeningFlow = playbackFlow.currentAndPreviousValues()
        .combine(contentBundle.title.asFlow()) { playbackState, title ->
            playbackState to title.trim()
        }

    suspend fun handleListeningFinished(
        importState: ContentImporterState?,
        playbackState: PreviousAndCurrentValues<Pair<PlaybackControls.State?, PlaybackControls.State>,
            Pair<PlaybackControls.State?, PlaybackControls.State>,>,
        title: String,
        bundleMetadata: BundleMetadata?,
    ) {
        val textListened = textListenedHelper.textListenedEventsFlow
            .value
        var listeningFinishedTriggered = false
        if (textListened.totalCharactersListened > 0) {
            when (importState) {
                is ContentImporterState.ImportedToLibrary -> {
                    sendListeningFinishedEvent(
                        importState.libraryItem,
                        playbackState.current.second,
                        playbackState.current.first?.wordsPerMinute ?: 0,
                        playbackState.current.first?.voiceOfCurrentUtterance?.displayName ?: "unknown",
                        playbackState.current.first?.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                        title,
                        bundleMetadata?.contentSubType,
                        textListened.totalCharactersListened,
                        textListened.totalWordsListened,
                        bundleMetadata,
                        speechifyClient.firebaseFunctionsService,
                    )
                    listeningFinishedTriggered = true
                }
                is ContentImporterState.Importing -> {
                    sendListeningFinishedEvent(
                        importState.libraryItem,
                        playbackState.current.second,
                        playbackState.current.first?.wordsPerMinute ?: 0,
                        playbackState.current.first?.voiceOfCurrentUtterance?.displayName ?: "unknown",
                        playbackState.current.first?.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                        title,
                        bundleMetadata?.contentSubType,
                        textListened.totalCharactersListened,
                        textListened.totalWordsListened,
                        bundleMetadata,
                    )
                    listeningFinishedTriggered = true
                }
                is ContentImporterState.NotImported -> {
                    sendListeningFinishedEvent(
                        playbackState.current.second,
                        playbackState.current.first?.wordsPerMinute ?: 0,
                        playbackState.current.first?.voiceOfCurrentUtterance?.displayName ?: "unknown",
                        playbackState.current.first?.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                        title,
                        textListened.totalCharactersListened,
                        textListened.totalWordsListened,
                        bundleMetadata,
                    )
                    listeningFinishedTriggered = true
                }
                else -> {
                    Log.e("Invalid import state", "EventsFlowInitializer")
                }
            }

            textListenedHelper.resetTotalCharactersAndWordsListened()
        }

        // We need to check that we first triggered the ListeningFinished event sot hat we don't send `Text Listened`
        // without it. This can happen when we start importing a file in the library
        if (textListened.charactersListened > 0 && listeningFinishedTriggered) {
            sendTextListenedEvent(
                playbackState.current.second.voiceOfCurrentUtterance?.displayName ?: "unknown",
                playbackState.current.second.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                playbackState.current.second.wordsPerMinute,
                textListened.charactersListened,
                textListened.wordsListened,
                speechifyClient.clientConfig,
                eventsTrackerAdapter,
            )
            textListenedHelper.resetCharactersAndWordsListened()
        }
    }

    var lastListeningFlowValues: Pair<
        PreviousAndCurrentValues<Pair<PlaybackControls.State?, PlaybackControls.State>,
            Pair<PlaybackControls.State?, PlaybackControls.State>,>,
        String,>? = null

    listeningFlow.drop(1)
        .combine(bundleMetadataFlow) { (playbackState, title), bundleMetadata ->
            bundleMetadata to Pair(playbackState, title)
        }
        .onEach { (bundleMetadata, listeningData) ->
            val (playbackState, title) = listeningData
            lastListeningFlowValues = listeningData
            val (voiceName, voiceId) = if (playbackState.current.second.voiceOfCurrentUtterance != null) {
                playbackState.current.second.voiceOfCurrentUtterance!!.displayName to
                    playbackState.current.second.voiceOfCurrentUtterance!!.idQualified
            } else {
                // We delay the start event until we have the voice
                val voice = voiceChangeFlow
                    .filter { it.voiceOfCurrentUtterance != null }
                    .first().voiceOfCurrentUtterance!!
                voice.displayName to voice.idQualified
            }
            val importState = importFlow.stateIn(dependencies.scope).value
            val trackListeningStartedFunction = when (importState) {
                is ContentImporterState.ImportedToLibrary -> { ->
                    sendListeningStartedEvent(
                        importState.libraryItem,
                        playbackState.current.second,
                        title,
                        bundleMetadata?.contentSubType,
                        voiceName,
                        voiceId,
                        bundleMetadata,
                    )
                }

                is ContentImporterState.Importing -> { ->
                    sendListeningStartedEvent(
                        importState.libraryItem,
                        playbackState.current.second,
                        title,
                        bundleMetadata?.contentSubType,
                        voiceName,
                        voiceId,
                        bundleMetadata,
                    )
                }

                is ContentImporterState.NotImported -> { ->
                    sendListeningStartedEvent(
                        playbackState.current.second,
                        title,
                        voiceName,
                        voiceId,
                        bundleMetadata,
                    )
                }

                else -> { ->
                    // This should never happen since we are filtering them a bit further up
                    Log.e("Invalid import state", "EventsFlowInitializer")
                }
            }

            when (playbackState.current.second.playPauseButton) {
                is PlayPauseButton.ShowPause -> {
                    if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowPlay ||
                        playbackState.previous.second.playPauseButton is PlayPauseButton.ShowRestart
                    ) {
                        // We only emit the event if previously we had a showPlay or showRestart so that we don't emit
                        // on buffering or voice change
                        sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                        trackListeningStartedFunction.invoke()
                    } else if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowBuffering &&
                        sessionIdFlow.value.isBlank()
                    ) {
                        // This is a special use case where the user is starting listening automatically after import
                        // but with a local voice
                        sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                        trackListeningStartedFunction.invoke()
                    }
                }

                is PlayPauseButton.ShowBuffering -> {
                    // We only emit if we don't have a previous session ID yet, meaning that playback has just started
                    if (sessionIdFlow.value.isBlank()) {
                        sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                        trackListeningStartedFunction.invoke()
                    }
                }

                is PlayPauseButton.ShowPlay, PlayPauseButton.ShowRestart -> {
                    // We only emit the listening finished if previously we had content playing.
                    if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowPause &&
                        sessionIdFlow.value.isNotBlank()
                    ) {
                        handleListeningFinished(
                            importState,
                            playbackState,
                            title,
                            bundleMetadata,
                        )

                        // We reset the sessionId so that we can guarantee triggering in case of voice change while
                        // paused
                        sessionIdFlow.emit("")
                    }
                }
            }
        }
        .onCompletion {
            if (lastListeningFlowValues != null) {
                val (playbackState, title) = lastListeningFlowValues!!
                if (playbackState.current.second.playPauseButton == PlayPauseButton.ShowPause) {
                    val bundleMetadata = bundleMetadataFlow.last()
                    val importState = importFlowValue.value
                    handleListeningFinished(
                        importState,
                        playbackState,
                        title,
                        bundleMetadata,
                    )
                }
            }
        }
        .launchIn(dependencies.scope)

    startVoiceAndSpeedEventsFlow(
        playbackControls,
        textListenedHelper,
        speechifyClient.clientConfig,
        eventsTrackerAdapter,
        dependencies.scope,
    )

    // If the first state is already `ImportedToLibrary`, we don't need to trigger the event since the item is already
    // in the user's library
    if (contentBundle.importer.stateFlow.value !is ContentImporterState.ImportedToLibrary) {
        contentBundle.importer.stateFlow
            .filterNotNull()
            .filter { it is ContentImporterState.ImportedToLibrary }
            .combine(bundleMetadataFlow.filterNotNull()) { importedToLibraryState, importableContentMetadata ->
                importedToLibraryState to importableContentMetadata
            }.onEach { (importedToLibraryState, importableContentMetadata) ->
                sendFileImportedEvent(
                    importedToLibraryState as ContentImporterState.ImportedToLibrary,
                    importableContentMetadata,
                )
            }.launchIn(
                // The dependencies.scope gets destroyed when the reading bundle gets destroyed
                // Because of this, if the import is still in progress while the reading bundle is destroyed,
                // the event will not be sent
                // Example: User opens another library item while the previous item is still importing
                speechifyClient.importService.importScope,
            )
    }
}

internal fun initializeEventsFlowForEmbeddedReadingBundle(
    playbackControls: PlaybackControls,
    contentBundle: ContentBundle,
    bundleMetadata: EmbeddedBundleMetadata,
    clientConfig: ClientConfig,
    eventsTrackerAdapter: EventsTrackerAdapter,
    firebaseFunctionsService: FirebaseFunctionsServiceImpl,
) {
    val contentLanguageFlow = MutableStateFlow<String?>(null)
    val languageDetectionText = MutableStateFlow<String>("")

    fun sendListeningStartedEvent(
        playbackState: PlaybackControls.State,
        title: String,
        contentSubType: String,
        voiceName: String = "unknown",
        voiceId: String,
        sessionId: String,
    ) {
        val extractedContentSubtype = extractContentSource(null, contentSubType)
        val properties = ListeningStartedEventProperties(
            app_platform = clientConfig.appEnvironment.name.lowercase(),
            app_version = clientConfig.appVersion,
            voice_name_initial = voiceName,
            voice_speed_initial = playbackState.wordsPerMinute,
            content_name = title,
            content_type = bundleMetadata.contentType.name.lowercase(),
            content_source = extractedContentSubtype,
            content_hostname = extractHostname(bundleMetadata.contentUri),
            content_uri = null,
            session_id = sessionId,
            voice_id = voiceId,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningStarted.eventName,
            properties.toMap(),
        )
    }

    suspend fun sendListeningFinishedEvent(
        playbackState: PlaybackControls.State,
        title: String,
        contentSubType: String,
        initialVoiceSpeed: Int,
        initialVoice: String,
        initialVoiceId: String,
        characters: Int,
        words: Int,
        sessionId: String,
    ) {
        val extractedContentSubtype = extractContentSource(null, contentSubType)
        val contentLanguage = contentLanguageFlow.value
            ?: detectLanguageFromText(languageDetectionText.value, firebaseFunctionsService)
        val properties = ListeningFinishedEventProperties(
            characters = characters,
            words = words,
            app_platform = clientConfig.appEnvironment.name.lowercase(),
            app_version = clientConfig.appVersion,
            voice_name_initial = initialVoice,
            voice_speed_initial = initialVoiceSpeed,
            voice_name_final = playbackState.voiceOfCurrentUtterance?.displayName ?: initialVoice,
            voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: initialVoiceId,
            voice_speed_final = playbackState.wordsPerMinute ?: initialVoiceSpeed,
            content_language = contentLanguage,
            content_name = title,
            content_type = bundleMetadata.contentType.name.lowercase(),
            content_source = extractedContentSubtype,
            content_hostname = extractHostname(bundleMetadata.contentUri),
            content_uri = null,
            session_id = sessionId,
        )
        eventsTrackerAdapter.track(
            EventType.ListeningFinished.eventName,
            properties.toMap(),
        )
    }

    suspend fun handleListeningFinished(
        textListenedHelper: TextListenedHelper,
        playbackState: PlaybackControls.State,
        title: String,
        contentSubType: String,
        initialVoiceSpeed: Int,
        initialVoice: String,
        initialVoiceId: String,
        sessionId: String,
    ) {
        val textListened = textListenedHelper.textListenedEventsFlow
            .value
        var listeningFinishedTriggered = false
        if (textListened.totalCharactersListened > 0) {
            sendListeningFinishedEvent(
                playbackState,
                title,
                contentSubType,
                initialVoiceSpeed,
                initialVoice,
                initialVoiceId,
                textListened.totalCharactersListened,
                textListened.totalWordsListened,
                sessionId,
            )
            listeningFinishedTriggered = true
            textListenedHelper.resetTotalCharactersAndWordsListened()
        }

        // We need to check that we first triggered the ListeningFinished event sot hat we don't send `Text Listened`
        // without it. This can happen when we start importing a file in the library
        if (textListened.charactersListened > 0 && listeningFinishedTriggered) {
            sendTextListenedEvent(
                playbackState.voiceOfCurrentUtterance?.displayName ?: "unknown",
                playbackState.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                playbackState.wordsPerMinute,
                textListened.charactersListened,
                textListened.wordsListened,
                clientConfig,
                eventsTrackerAdapter,
            )
            textListenedHelper.resetCharactersAndWordsListened()
        }
    }

    initializeLanguageDetectionFlow(
        playbackControls.scopeForChildren,
        playbackControls,
        languageDetectionText,
        contentLanguageFlow,
        firebaseFunctionsService,
    )

    val playbackFlow = playbackControls.stateFlow.buffer(capacity = Channel.UNLIMITED)
        .distinctUntilChangedBy { it.playPauseButton }
        .emitFirstValueAsPairWhere {
            it.voiceOfCurrentUtterance != null
        }

    val listeningFlow = playbackFlow.currentAndPreviousValues()
        .combine(contentBundle.title.asFlow()) { playbackState, title ->
            playbackState to title.trim()
        }

    val voiceChangeFlow = playbackControls.stateFlow.distinctUntilChangedBy { it.voiceOfCurrentUtterance }
    val sessionIdFlow = MutableStateFlow("")
    val random: Random = Random
    val textListenedHelper = TextListenedHelper(
        playbackControls.audioController.getEventsColdFlow()
            .filterIsInstance<AudioControllerEvent.Playing>(),
        playbackControls.scopeForChildren,
    )

    var lastListeningFlowValues: Pair<
        PreviousAndCurrentValues<Pair<PlaybackControls.State?, PlaybackControls.State>,
            Pair<PlaybackControls.State?, PlaybackControls.State>,>,
        String,>? = null

    // The first instance will always be a ShowPlay, so we ignore it
    listeningFlow.drop(1).onEach { (playbackState, title) ->
        val (voiceName, voiceId) = if (playbackState.current.second.voiceOfCurrentUtterance != null) {
            playbackState.current.second.voiceOfCurrentUtterance!!.displayName to
                playbackState.current.second.voiceOfCurrentUtterance!!.idQualified
        } else {
            // We delay the start event until we have the voice
            val voice = voiceChangeFlow
                .filter { it.voiceOfCurrentUtterance != null }
                .first().voiceOfCurrentUtterance!!
            voice.displayName to voice.idQualified
        }
        when (playbackState.current.second.playPauseButton) {
            is PlayPauseButton.ShowPause -> {
                if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowPlay ||
                    playbackState.previous.second.playPauseButton is PlayPauseButton.ShowRestart
                ) {
                    // We only emit the event if previously we had a showPlay or showRestart so that we don't emit
                    // on buffering or voice change
                    sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                    sendListeningStartedEvent(
                        playbackState.current.second,
                        title,
                        bundleMetadata.contentSubType,
                        voiceName,
                        voiceId,
                        sessionIdFlow.value,
                    )
                } else if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowBuffering &&
                    sessionIdFlow.value.isBlank()
                ) {
                    // This is a special use case where the user is starting listening automatically after import
                    // but with a local voice
                    sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                    sendListeningStartedEvent(
                        playbackState.current.second,
                        title,
                        bundleMetadata.contentSubType,
                        voiceName,
                        voiceId,
                        sessionIdFlow.value,
                    )
                }
            }

            is PlayPauseButton.ShowBuffering -> {
                // We just started listening, so trigger listening started. This way, we won't trigger for voice change
                if (lastListeningFlowValues == null) {
                    sessionIdFlow.emit(uuid4().toString() + "_" + random.nextInt().absoluteValue)
                    sendListeningStartedEvent(
                        playbackState.current.second,
                        title,
                        bundleMetadata.contentSubType,
                        voiceName,
                        voiceId,
                        sessionIdFlow.value,
                    )
                }
            }

            is PlayPauseButton.ShowPlay, PlayPauseButton.ShowRestart -> {
                if (playbackState.previous.second.playPauseButton is PlayPauseButton.ShowPause) {
                    handleListeningFinished(
                        textListenedHelper,
                        playbackState.current.second,
                        title,
                        bundleMetadata.contentSubType,
                        playbackState.current.first?.wordsPerMinute ?: 0,
                        playbackState.current.first?.voiceOfCurrentUtterance?.displayName ?: "unknown",
                        playbackState.current.first?.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                        sessionIdFlow.value,
                    )
                    sessionIdFlow.emit("")
                }
            }
        }
        lastListeningFlowValues = playbackState to title.trim()
    }.onCompletion {
        if (lastListeningFlowValues != null) {
            val (playbackState, title) = lastListeningFlowValues!!
            if (playbackState.current.second.playPauseButton == PlayPauseButton.ShowPause) {
                handleListeningFinished(
                    textListenedHelper,
                    playbackState.current.second,
                    title,
                    bundleMetadata.contentSubType,
                    playbackState.current.first?.wordsPerMinute ?: 0,
                    playbackState.current.first?.voiceOfCurrentUtterance?.displayName ?: "unknown",
                    playbackState.current.first?.voiceOfCurrentUtterance?.idQualified ?: "unknown",
                    sessionIdFlow.value,
                )
            }
        }
    }
        .launchIn(playbackControls.scopeForChildren)

    startVoiceAndSpeedEventsFlow(
        playbackControls,
        textListenedHelper,
        clientConfig,
        eventsTrackerAdapter,
        playbackControls.scopeForChildren,
    )
}

private fun startVoiceAndSpeedEventsFlow(
    playbackControls: PlaybackControls,
    textListenedHelper: TextListenedHelper,
    clientConfig: ClientConfig,
    eventsTrackerAdapter: EventsTrackerAdapter,
    scope: CoroutineScope,
) {
    fun sendSpeedChangedEvent(
        playbackState: PlaybackControls.State,
        oldSpeed: Int,
    ) {
        val speedSelectedProperties = SpeedEventProperties(
            app_platform = clientConfig.appEnvironment.name.lowercase(),
            app_version = clientConfig.appVersion,
            previous_speed = oldSpeed,
            new_speed = playbackState.wordsPerMinute,
            voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: "unknown",
        )
        eventsTrackerAdapter.track(
            EventType.SpeedSelected.eventName,
            speedSelectedProperties.toMap(),
        )
    }

    fun sendVoiceChangedEvent(
        playbackState: PlaybackControls.State,
        oldVoice: String,
        oldVoiceId: String,
    ) {
        val voiceProperties = VoiceEventProperties(
            app_platform = clientConfig.appEnvironment.name.lowercase(),
            app_version = clientConfig.appVersion,
            previous_voice = oldVoice,
            previous_voice_id = oldVoiceId,
            new_voice = playbackState.voiceOfCurrentUtterance?.displayName ?: "unknown",
            new_voice_id = playbackState.voiceOfCurrentUtterance?.idQualified ?: "unknown",
        )
        eventsTrackerAdapter.track(
            EventType.VoiceSelected.eventName,
            voiceProperties.toMap(),
        )
    }

    val voiceChangeFlow = playbackControls.stateFlow.distinctUntilChangedBy { it.voiceOfCurrentUtterance }

    playbackControls.stateFlow
        .distinctUntilChangedBy {
            it.wordsPerMinute
        }
        .debounce(1.seconds)
        .currentAndPreviousValues()
        .onEach { (oldPlaybackState, newPlaybackState) ->
            if (oldPlaybackState.wordsPerMinute != newPlaybackState.wordsPerMinute) {
                val charactersListened = textListenedHelper.textListenedEventsFlow
                    .value.charactersListened
                val wordsListened = textListenedHelper.textListenedEventsFlow
                    .value.wordsListened
                sendSpeedChangedEvent(newPlaybackState, oldPlaybackState.wordsPerMinute)
                if (charactersListened > 0 && newPlaybackState.playPauseButton is PlayPauseButton.ShowPause) {
                    if (newPlaybackState.voiceOfCurrentUtterance == null) {
                        // We delay the event until we have the voice
                        voiceChangeFlow
                            .filter { it.voiceOfCurrentUtterance != null }
                            .first()
                            .let {
                                sendTextListenedEvent(
                                    // It is safe since we are filtering out voiceOfCurrentUtterance == null
                                    it.voiceOfCurrentUtterance!!.displayName,
                                    it.voiceOfCurrentUtterance.idQualified,
                                    oldPlaybackState.wordsPerMinute,
                                    charactersListened,
                                    wordsListened,
                                    clientConfig,
                                    eventsTrackerAdapter,
                                )
                            }
                    } else {
                        sendTextListenedEvent(
                            // It is safe since we are filtering out voiceOfCurrentUtterance == null
                            newPlaybackState.voiceOfCurrentUtterance.displayName,
                            newPlaybackState.voiceOfCurrentUtterance.idQualified,
                            oldPlaybackState.wordsPerMinute,
                            charactersListened,
                            wordsListened,
                            clientConfig,
                            eventsTrackerAdapter,
                        )
                    }
                    textListenedHelper.resetCharactersAndWordsListened()
                }
            }
        }.launchIn(scope)

    playbackControls.stateFlow
        .distinctUntilChangedBy {
            it.voiceOfCurrentUtterance
        }
        .filter { it.voiceOfCurrentUtterance != null }
        .currentAndPreviousValues()
        .onEach { (oldPlaybackState, newPlaybackState) ->
            val charactersListened = textListenedHelper.textListenedEventsFlow
                .value.charactersListened
            val wordsListened = textListenedHelper.textListenedEventsFlow
                .value.wordsListened
            sendVoiceChangedEvent(
                playbackState = newPlaybackState,
                // It is safe since we are filtering out voiceOfCurrentUtterance == null
                oldVoice = oldPlaybackState.voiceOfCurrentUtterance!!.displayName,
                oldVoiceId = oldPlaybackState.voiceOfCurrentUtterance.idQualified,
            )
            if (charactersListened > 0) {
                sendTextListenedEvent(
                    oldPlaybackState.voiceOfCurrentUtterance.displayName,
                    oldPlaybackState.voiceOfCurrentUtterance.idQualified,
                    newPlaybackState.wordsPerMinute, // The newPlaybackState has the correct words per minute
                    charactersListened,
                    wordsListened,
                    clientConfig,
                    eventsTrackerAdapter,
                )
                textListenedHelper.resetCharactersAndWordsListened()
            }
        }.launchIn(scope)
}

private fun sendTextListenedEvent(
    voiceName: String,
    voiceId: String,
    wordsPerMinute: Int,
    charactersListened: Int,
    wordsListened: Int,
    clientConfig: ClientConfig,
    eventsTrackerAdapter: EventsTrackerAdapter,
) {
    val charactersListenedProperties = CharactersListenedEventProperties(
        app_platform = clientConfig.appEnvironment.name.lowercase(),
        app_version = clientConfig.appVersion,
        characters = charactersListened,
        words = wordsListened,
        voice = voiceName,
        voice_id = voiceId,
        speed = wordsPerMinute,
    )
    eventsTrackerAdapter.track(
        EventType.TextListened.eventName,
        charactersListenedProperties.toMap(),
    )
}

private fun extractContentSource(libraryItem: LibraryItem?, contentSubType: String): String {
    if (libraryItem == null) {
        return contentSubType
    }

    if (libraryItem is LibraryItem.Content &&
        libraryItem.contentType == ContentType.SPEECHIFY_BOOK
    ) {
        return if (libraryItem.contentAccess == null || libraryItem.contentAccess == ContentAccess.FULL) {
            "bookstore"
        } else {
            "book_preview"
        }
    }

    val savedContentSubType = libraryItem.analyticsProperties.get("contentSubType")
    return savedContentSubType ?: contentSubType
}

internal fun reportEventsFromImportServiceFlows(
    scope: CoroutineScope,
    clientConfig: ClientConfig,
    importedItemDataForReportingFlow: Flow<ImportedItemDataForReporting>,
    eventsTrackerAdapter: EventsTrackerAdapter,
) {
    fun sendFileImportedEvent(
        item: ImportedItemDataForReporting,
    ) {
        val contentHostname = if (item.metadata?.contentHostname == null) {
            extractHostname(item.sourceUrl) ?: "unknown"
        } else {
            item.metadata.contentHostname
        }
        val properties = FileImportedEventProperties(
            app_platform = clientConfig.appEnvironment.name.lowercase(),
            app_version = clientConfig.appVersion,
            import_flow = item.metadata?.importFlow?.eventReportingValue ?: "unknown",
            content_name = item.title,
            content_type = item.contentType?.name?.lowercase() ?: "unknown",
            content_source = item.metadata?.contentSubType,
            content_hostname = contentHostname,
            content_uri = item.sourceStoredUrl,
        )
        eventsTrackerAdapter.track(
            EventType.FileImported.eventName,
            properties.toMap(),
        )
    }

    importedItemDataForReportingFlow.filterNotNull()
        .onEach { item ->
            sendFileImportedEvent(item)
        }.launchIn(scope)
}

private fun initializeLanguageDetectionFlow(
    scope: CoroutineScope,
    playbackControls: PlaybackControls,
    languageDetectionText: MutableStateFlow<String>,
    contentLanguageFlow: MutableStateFlow<String?>,
    firebaseFunctionsServiceImpl: FirebaseFunctionsServiceImpl,
) {
    // We detect tha language. However, since this can be a costly operation, we only do it until
    // we have a text of sufficient length, after which we keep the detected language
    playbackControls.stateFlow
        .distinctUntilChangedBy { it.sentenceAndWordLocation }
        .filter { it.playPauseButton is PlayPauseButton.ShowPause }
        .onEach {
            if (contentLanguageFlow.value == null) {
                var sentenceUsedForLanguageDetection = languageDetectionText.value
                val currentSentence = it.sentenceAndWordLocation?.sentence?.text
                if (currentSentence != null) {
                    sentenceUsedForLanguageDetection += " $currentSentence"
                    languageDetectionText.tryEmit(sentenceUsedForLanguageDetection)
                }
                if (sentenceUsedForLanguageDetection.length > 300) {
                    val response = firebaseFunctionsServiceImpl
                        .detectLanguageOfText(DetectLanguageOfTextPayload(sentenceUsedForLanguageDetection))
                    response.ifSuccessful {
                        contentLanguageFlow.tryEmit(it.iso639_3LanguageCode)
                    }
                }
            }
        }
        .launchIn(scope)
}

private suspend fun detectLanguageFromText(
    text: String,
    firebaseFunctionsService: FirebaseFunctionsServiceImpl,
): String {
    if (text.isEmpty()) {
        return "und"
    }

    val response = firebaseFunctionsService.detectLanguageOfText(DetectLanguageOfTextPayload(text))
    return response.map {
        it.iso639_3LanguageCode
    }.orReturn { return "und" }
}

internal data class ImportedItemDataForReporting(
    val title: String,
    val sourceStoredUrl: String,
    val contentType: ContentType?,
    val sourceUrl: String?,
    val metadata: ImportableContentMetadata?,
)

private data class TextListened(
    val charactersListened: Int,
    val totalCharactersListened: Int,
    val wordsListened: Int,
    val totalWordsListened: Int,
)

private class TextListenedHelper(
    audioControllerEventsFlow: Flow<AudioControllerEvent.Playing>,
    scope: CoroutineScope,
) {
    private var charactersListened: Int = 0
    private var totalCharactersListened: Int = 0
    private var wordsListened: Int = 0
    private var totalWordsListened: Int = 0

    private var lastSeenWord = ""
    private var lastSeenWordCursorEnd: ContentCursor? = null

    val textListenedEventsFlow = audioControllerEventsFlow
        .transform { playingEvent ->
            val currentWord = playingEvent.sentenceAndWordLocation?.word

            // For some reason, not all words are emitted. Let's find the words between the current one and the
            // previous one so that we make sure we count them as well
            if (currentWord != null && lastSeenWord != currentWord.text) {
                val wordsBetween = if (lastSeenWordCursorEnd != null) {
                    // We need a +1 since getLastIndexOfCursor() cuts the last character
                    val startIndex = playingEvent.sentenceAndWordLocation.sentence.getLastIndexOfCursor(
                        lastSeenWordCursorEnd!!,
                    ) + 1
                    val endIndex = playingEvent.sentenceAndWordLocation.sentence
                        .getLastIndexOfCursor(currentWord.end) + 1
                    val textBetween = playingEvent.sentenceAndWordLocation.sentence.slice(startIndex, endIndex).text
                    textBetween.trim().split(Regex("\\s+"))
                } else {
                    listOf(currentWord.text)
                }

                charactersListened += wordsBetween.sumOf { it.length }
                totalCharactersListened += wordsBetween.sumOf { it.length }

                // Add the current word length
                wordsListened += wordsBetween.size
                totalWordsListened += wordsBetween.size
                lastSeenWord = currentWord.text
                lastSeenWordCursorEnd = playingEvent.sentenceAndWordLocation.word.end

                emit(
                    value = TextListened(
                        charactersListened,
                        totalCharactersListened,
                        wordsListened,
                        totalWordsListened,
                    ),
                )
            }
        }.stateIn(
            scope = scope,
            started = SharingStarted.Eagerly,
            initialValue = TextListened(0, 0, 0, 0),
        )

    fun resetCharactersAndWordsListened() {
        charactersListened = 0
        wordsListened = 0
    }

    fun resetTotalCharactersAndWordsListened() {
        totalCharactersListened = 0
        totalWordsListened = 0
    }
}

private fun extractHostname(url: String?): String? {
    if (url == null) {
        return null
    }
    return parseUrl(url)?.authority?.host
}
