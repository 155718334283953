package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformGetRewardBalanceResponse(
    val usdCents: Int,
    val claimedRewards: Int,
    val usersReferred: Int,
    val totalAudiobookCredits: Int,
)
