package com.speechify.client.bundlers.reading.embedded

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.bundlers.content.createBundleForHtmlDomElement
import com.speechify.client.bundlers.reading.EmbeddedBundleMetadata
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import org.w3c.dom.Element

@Deprecated("Use `createBundleForHtmlDomElement`", ReplaceWith("createBundleForHtmlDomElement"))
@JsExport
fun EmbeddedReadingBundler.createBundleForHtmlElement(
    htmlElement: Element,
    bundleMetadata: EmbeddedBundleMetadata,
    callback: Callback<EmbeddedReadingBundle>,
) = createBundleForHtmlDomElement(
    htmlElement = htmlElement,
    options = HtmlContentLoadOptions(
        sourceUrl = null,
        isEntireDocument = null,
        isPostContentExtraction = null,
        isPostJavaScriptExecution = null,
    ),
    bundleMetadata = bundleMetadata,
    callback = callback,
)

/**
 * Create an [EmbeddedReadingBundle] from the specified real Browser DOM element, without any filtering or modification.
 * See [EmbeddedReadingBundler.createBundleForHtmlFromSdkElement] for a version that uses an abstract interface as an input,
 * thus allowing for filtering or modification.
 */
@JsExport
fun EmbeddedReadingBundler.createBundleForHtmlDomElement(
    htmlElement: Element,
    options: HtmlContentLoadOptions,
    bundleMetadata: EmbeddedBundleMetadata,
    callback: Callback<EmbeddedReadingBundle>,
) = callback.fromCo {
    Log.d(
        "EmbeddedReadingBundler uses a ContentImporter that imports HTMLElement as a string via element.outerHTML. " +
            "Computed styles may be lost!",
        sourceAreaId = "EmbeddedReadingBundler.createBundleForHtmlDomElement",
    )

    createEmbeddedBundle(
        contentBundle = contentBundler.createBundleForHtmlDomElement(
            htmlElement = htmlElement,
            options = options,
            bundleMetadata = bundleMetadata,
        )
            .orReturn { return@fromCo it },
        bundleMetadata = bundleMetadata,
    )
}
