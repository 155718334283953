package com.speechify.client.api.services.library.models

import kotlin.js.JsExport

@JsExport
enum class ItemType(val type: String) {
    Content("content"),
    Folder("folder"),
    All("all"),
}
