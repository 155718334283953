package com.speechify.client.api.content.epubV3.builders

import com.speechify.client.api.adapters.archiveFiles.ZipArchiveView
import com.speechify.client.api.content.epub.EpubNavigation
import com.speechify.client.api.content.epubV3.EpubV3
import com.speechify.client.api.content.epubV3.Item
import com.speechify.client.api.content.epubV3.Link
import com.speechify.client.api.content.epubV3.PackageDocument
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.coGetSizeInBytes
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow

// Todo handle encrypted epub files.
internal class EpubV3Builder(
    private val packageDocument: PackageDocument,
    private val zipArchiveView: ZipArchiveView,
    private val navigation: EpubNavigation?,
) {
    suspend fun build(): EpubV3 {
        // Compute links
        val (readingOrder, resources) = EpubResourceBuilder(
            packageDocument.spine,
            packageDocument.manifest,
        ).build()

        val coverImage = getCoverImage(packageDocument.coverItem, zipArchiveView)

        // Build Publication object
        return EpubV3(
            opfFilePath = packageDocument.path,
            readingOrder = readingOrder,
            resources = resources,
            navigation = navigation,
            guide = packageDocument.guide,
            coverImage = coverImage,
            zipArchiveView = zipArchiveView,
            title = packageDocument.title,
            chapterIndexesToEstimatedContentRatios = chapterIndexesToEstimatedContentRatios(readingOrder),
        )
    }

    private suspend fun getCoverImage(
        imageCoverItem: Item?,
        zipArchiveView: ZipArchiveView,
    ): BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>? {
        if (imageCoverItem?.mediaType == null) return null
        val zipFileEntry = zipArchiveView.entries.find {
            it.path.endsWith(imageCoverItem.href)
        } ?: return null
        return zipFileEntry.coCreateBinaryContentReadableRandomly().withMimeType(MimeType("text/xml"))
    }

    private suspend fun chapterIndexesToEstimatedContentRatios(readingOrder: List<Link>): Map<Int, Double> {
        val chapterContentSizes = readingOrder.mapIndexed { index, link ->
            val zipFileEntry = zipArchiveView.entries.find {
                it.path.endsWith(link.href.path)
            } ?: return@mapIndexed index to 0.0
            val size = zipFileEntry.coCreateBinaryContentReadableRandomly()
                .coGetSizeInBytes().orThrow().toDouble()
            index to size
        }.toMap()
        val totalSize = chapterContentSizes.values.sum()
        return chapterContentSizes.mapValues { (it.value / totalSize) * 100 }
    }
}
