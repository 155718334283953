package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.helpers.features.ListeningProgress
import kotlin.Any
import kotlin.Long
import kotlin.String

public class LocalListeningProgressQueries(
  driver: SqlDriver,
  private val localListeningProgressAdapter: LocalListeningProgress.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getLocalListeningProgress(speechifyUri: SpeechifyURI, mapper: (
    speechifyUri: SpeechifyURI,
    listeningProgress: ListeningProgress?,
    lastUpdatedTime: Long,
  ) -> T): Query<T> = GetLocalListeningProgressQuery(speechifyUri) { cursor ->
    mapper(
      localListeningProgressAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { localListeningProgressAdapter.listeningProgressAdapter.decode(it)
          },
      cursor.getLong(2)!!
    )
  }

  public fun getLocalListeningProgress(speechifyUri: SpeechifyURI): Query<LocalListeningProgress> =
      getLocalListeningProgress(speechifyUri) { speechifyUri_, listeningProgress, lastUpdatedTime ->
    LocalListeningProgress(
      speechifyUri_,
      listeningProgress,
      lastUpdatedTime
    )
  }

  public suspend
      fun insertOrReplaceListeningProgress(localListeningProgress: LocalListeningProgress) {
    driver.execute(1_993_344_927,
        """INSERT OR REPLACE INTO localListeningProgress VALUES (?, ?, ?)""", 3) {
          bindString(0,
              localListeningProgressAdapter.speechifyUriAdapter.encode(localListeningProgress.speechifyUri))
          bindString(1, localListeningProgress.listeningProgress?.let {
              localListeningProgressAdapter.listeningProgressAdapter.encode(it) })
          bindLong(2, localListeningProgress.lastUpdatedTime)
        }.await()
    notifyQueries(1_993_344_927) { emit ->
      emit("localListeningProgress")
    }
  }

  public suspend fun removeLocalListeningProgress(speechifyUri: SpeechifyURI) {
    driver.execute(-847_104_432, """DELETE FROM localListeningProgress WHERE speechifyUri = ?""", 1)
        {
          bindString(0, localListeningProgressAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(-847_104_432) { emit ->
      emit("localListeningProgress")
    }
  }

  private inner class GetLocalListeningProgressQuery<out T : Any>(
    public val speechifyUri: SpeechifyURI,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("localListeningProgress", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("localListeningProgress", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_366_127_716,
        """SELECT * FROM localListeningProgress WHERE speechifyUri = ?""", mapper, 1) {
      bindString(0, localListeningProgressAdapter.speechifyUriAdapter.encode(speechifyUri))
    }

    override fun toString(): String = "localListeningProgress.sq:getLocalListeningProgress"
  }
}
