package com.speechify.client.internal.services.ml

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.adapters.ocr.OCRResult
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.FileFromString
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.parse
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.ml.models.MLParsedPageResponse
import com.speechify.client.internal.util.extensions.coroutines.LifoSemaphore
import com.speechify.client.internal.util.time.measureTimedValueWithStartAndEnd
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

private const val pdfPageExtractedTextItemsServerKey = "pdfPageExtractedTextItems"
private const val pdfPageImageServerKey = "pdfPageImage"

/**
 * Responsible for building requests and invoking the ML-Parsing Cloud function,
 * which orders and classifies the content of a Book page.
 * Note: The Cloud Function returns both parsedContent and/or rawContent. If raw content is not provided,
 * the CF will perform OCR and return it within the result.
 */
internal class MLPageParsingWithRemoteOCRService internal constructor(
    private val httpClient: HttpClient,
    private val appEnvironment: AppEnvironment,
    private val appVersion: String,
    private val authService: AuthService,
    private val platformFirebaseFunctionsUrl: String,
) {
    companion object {
        /**
         * The maximum number of concurrently ml-parsing http requests.
         */
        private const val MAXIMUM_PARALLEL_PAGE_PARSING = 1
    }

    private val maxConcurrencyGuardingLifoSemaphore = LifoSemaphore(MAXIMUM_PARALLEL_PAGE_PARSING)

    /**
     * Receives a [pdfPageRawExtractedData] which the extracted raw TextItems from pdf page,
     * [pdfPageImage] pdf page image as `BinaryContentWithMimeTypeReadableInChunks` data, [shouldRunOcrFallback]
     * and provides [ParsedContentAndOCRResultResponse] a parsed page response which includes OCR results.
     */
    suspend fun parsePage(
        pdfPageRawExtractedData: PDFPageRawExtractedData,
        pdfPageImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        pageIndex: Int,
        shouldRunOcrFallback: Boolean,
    ): ParsedContentAndOCRResultResponse {
        val entries = mutableMapOf<
            String,
            BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>,
            >(
            pdfPageImageServerKey to pdfPageImage,
        )
        // if no `pdfPageExtractedTextItems` is provided server-side will run OCR.
        if (!shouldRunOcrFallback) {
            entries[pdfPageExtractedTextItemsServerKey] = FileFromString(
                contentType = "text/plain; charset=utf-8",
                stringValue = Json.encodeToString(pdfPageRawExtractedData),
            )
        }
        return maxConcurrencyGuardingLifoSemaphore.withPermit {
            val requestMeasurement = measureTimedValueWithStartAndEnd {
                val token = authService.getCurrentUserIdentityToken().orThrow().token
                httpClient.post(url = "$platformFirebaseFunctionsUrl/sdk-http-ml-parse-page") {
                    this.formData(entries)
                    this.header("X-Speechify-Client", appEnvironment.name)
                    this.header("X-Speechify-Client-Version", appVersion)
                    this.header("Authorization", "Bearer $token")
                    this.header("should-run-ocr-fallback", "$shouldRunOcrFallback")
                }.parse<ParsedContentAndOCRResultResponse>().orThrow()
            }
            Log.d(
                DiagnosticEvent(
                    "pageIndex:  $pageIndex, " +
                        "duration: ${requestMeasurement.durationWithStartAndEndTime.duration}.",
                    sourceAreaId = "MLPageParsingWithRemoteOCRService.runMLExcludingTimeForPendingParallelRequests",
                ),
            )
            requestMeasurement.value
        }
    }
}

@Serializable
internal class ParsedContentAndOCRResultResponse(
    val ocrResult: OCRResult?,
    val parsedContent: MLParsedPageResponse?,
)
