package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.File
import com.speechify.client.internal.caching.FileId
import com.speechify.client.internal.services.file.models.InMemoryFile
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.jvm.JvmInline

@JsExport
interface FirebaseStorageAdapter {
    /**
     * @param ref a gs:// URI locating the upload destination
     * @param data the data to upload
     * @param contentType the MIME type of the data
     */
    @Deprecated("Use putFile instead", ReplaceWith("putFile(ref, InMemoryFile(contentType, data), callback)"))
    fun put(
        ref: GoogleCloudStorageUri,
        data: ByteArray,
        contentType: String,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor {
        return putFile(ref, InMemoryFile(contentType, data), callback)
    }

    /**
     * @param ref a gs:// URI locating the upload destination
     * @param file the file to upload
     */
    @Deprecated("Use putBinaryContent instead")
    fun putFile(
        ref: GoogleCloudStorageUri,
        file: File,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor

    fun putBinaryContent(
        /**
         * A gs:// URI locating the upload destination - see [GoogleCloudStorageUri].
         */
        ref: GoogleCloudStorageUri,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,

        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    ): Destructor

    /**
     * @param ref a gs:// URI locating the file to download
     */
    fun getDownloadUrl(ref: GoogleCloudStorageUri, callback: Callback<String>)

    /**
     * @param ref a gs:// URI locating the upload destination
     * Should return a [SDKError.ResourceNotFound] if the file does not exist in the bucket yet.
     */
    fun getMetadata(ref: GoogleCloudStorageUri, callback: Callback<FullMetadata>)

    fun delete(ref: GoogleCloudStorageUri, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>)
}

/**
 * A `gs://` URI locating a payload in Google Cloud Storage
 */
typealias GoogleCloudStorageUri = String

/**
 * A `gs://` URI locating a payload in Google Cloud Storage
 * A strongly-typed `value class` allowing to convey semantics of the string value, using `value class` not to sacrifice performance.
 */
@JvmInline
internal value class GoogleCloudStorageUriFileId(
    private val googleCloudStorageUri: GoogleCloudStorageUri,
) : FileId {
    override val stringValue: String
        get() = googleCloudStorageUri
}

internal suspend fun FirebaseStorageAdapter.coPutFile(
    ref: GoogleCloudStorageUri,
    file: File,
): Result<Unit> =
    suspendCancellableCoroutine { cont ->
        val destructor = putFile(ref, file, cont::resume)
        cont.invokeOnCancellation {
            destructor.invoke()
        }
    }

internal suspend fun FirebaseStorageAdapter.coPutBinaryContent(
    ref: GoogleCloudStorageUri,
    binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
): Result<Unit> =
    suspendCancellableCoroutine { cont ->
        val destructor = putBinaryContent(ref, binaryContent, cont::resume)
        cont.invokeOnCancellation {
            destructor.invoke()
        }
    }

internal suspend fun FirebaseStorageAdapter.coGetMetadata(
    ref: GoogleCloudStorageUri,
): Result<FullMetadata> =
    suspendCoroutine { cont -> getMetadata(ref, cont::resume) }

internal suspend fun FirebaseStorageAdapter.coGetDownloadUrl(ref: GoogleCloudStorageUri) = suspendCoroutine { cont ->
    getDownloadUrl(ref, cont::resume)
}

internal suspend fun FirebaseStorageAdapter.coDelete(ref: GoogleCloudStorageUri) = suspendCoroutine { cont ->
    delete(ref, cont::resume)
}

internal fun FirebaseStorageAdapter.traced(): FirebaseStorageAdapter =
    if (Log.isDebugLoggingEnabled) FirebaseStorageAdapterTraced(this) else this

internal class FirebaseStorageAdapterTraced(private val firebaseStorageAdapter: FirebaseStorageAdapter) :
    FirebaseStorageAdapter {
    override fun put(
        ref: GoogleCloudStorageUri,
        data: ByteArray,
        contentType: String,
        callback: Callback<Unit>,
    ): Destructor {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.put($ref, $data, $contentType)",
            sourceAreaId = "FirebaseStorageAdapter.put",
        )
        return firebaseStorageAdapter.put(ref, data, contentType, taggedCallback)
    }

    override fun putFile(ref: GoogleCloudStorageUri, file: File, callback: Callback<Unit>): Destructor {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.put($ref, $file)",
            sourceAreaId = "FirebaseStorageAdapter.putFile",
        )
        return firebaseStorageAdapter.putFile(ref, file, taggedCallback)
    }

    override fun putBinaryContent(
        ref: GoogleCloudStorageUri,
        binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<Unit>,
    ): Destructor {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.putBinaryContent($ref, $binaryContent)",
            sourceAreaId = "FirebaseStorageAdapter.putBinaryContent",
        )
        return firebaseStorageAdapter.putBinaryContent(ref, binaryContent, taggedCallback)
    }

    override fun getDownloadUrl(ref: GoogleCloudStorageUri, callback: Callback<String>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.getDownloadUrl($ref)",
            sourceAreaId = "FirebaseStorageAdapter.getDownloadUrl",
        )
        firebaseStorageAdapter.getDownloadUrl(ref, taggedCallback)
    }

    override fun getMetadata(ref: GoogleCloudStorageUri, callback: Callback<FullMetadata>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.getMetadata($ref)",
            sourceAreaId = "FirebaseStorageAdapter.getMetadata",
        )
        firebaseStorageAdapter.getMetadata(ref, taggedCallback)
    }

    override fun delete(ref: GoogleCloudStorageUri, callback: Callback<Unit>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseStorageAdapter.delete($ref)",
            sourceAreaId = "FirebaseStorageAdapter.delete",
        )
        firebaseStorageAdapter.delete(ref, taggedCallback)
    }
}
