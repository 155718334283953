package com.speechify.client.reader.core

import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.helpers.features.WordsListenedHelper
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.util.extensions.collections.flows.throttleFirst
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class ListenedWordsCountHelper internal constructor(
    scope: CoroutineScope,
    playbackControls: PlaybackControls,
) : Helper<WordsListenedState>(scope) {

    private val wordsListenedEventsFlow = WordsListenedHelper(playbackControls.audioController).wordsListenedEventsFlow
    private val playbackStateWithAvailableVoiceFlow =
        playbackControls.stateFlow.filter { it.voiceOfCurrentUtterance != null }

    override val stateFlow: StateFlow<WordsListenedState> = wordsListenedEventsFlow
        .combine(playbackStateWithAvailableVoiceFlow) { wordsListenedEvent, playbackState ->
            wordsListenedEvent to playbackState
        }
        .throttleFirst(5000)
        .map {
            val (wordsListenedEvent, playbackState) = it
            val (premiumWordCount, freeWordCount) = wordsListenedEvent.stats.fold(Pair(0, 0)) { acc, stat ->
                if (stat.voice.isPremium) {
                    acc.first + stat.count to acc.second
                } else {
                    acc.first to acc.second + stat.count
                }
            }
            WordsListenedState(
                totalPremiumWordsListened = premiumWordCount,
                totalFreeWordsListened = freeWordCount,
                currentPlayingVoice = playbackState.voiceOfCurrentUtterance,
                currentPlayingSpeedInWordsPerMinute = playbackState.wordsPerMinute,
            )
        }.stateInHelper(
            initialValue = WordsListenedState(
                totalPremiumWordsListened = 0,
                totalFreeWordsListened = 0,
                currentPlayingSpeedInWordsPerMinute = playbackControls.state.wordsPerMinute,
                currentPlayingVoice = playbackControls.state.voiceOfCurrentUtterance,
            ),
        )

    override val initialState = stateFlow.value
}

@JsExport
data class WordsListenedState internal constructor(
    val totalPremiumWordsListened: Int,
    val totalFreeWordsListened: Int,
    val currentPlayingSpeedInWordsPerMinute: Int,
    val currentPlayingVoice: VoiceSpecOfAvailableVoice?,
)
