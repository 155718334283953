package com.speechify.client.internal.services.ocr

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.adapters.ocr.OcrSource
import com.speechify.client.api.content.scannedbook.ScannedBookBookPage
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.scannedbook.models.FirestoreScannedBookPage
import com.speechify.client.api.services.scannedbook.models.LocalPage
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.bundlers.content.IsBookPageContentEmpty
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.bundlers.reading.importing.ContentPostImportActionsManager
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.userDocumentSettings.UserProfileService
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.withContext

private const val OCR_IMAGE_COMPRESSION_TARGET_PERCENT_PORTRAIT = 30
private const val OCR_IMAGE_SCALE_PORTRAIT = 1.0
private const val OCR_IMAGE_COMPRESSION_TARGET_PERCENT_LANDSCAPE = 50
private const val OCR_IMAGE_SCALE_LANDSCAPE = 1.1

/**
 * Manages the execution of OCR fallback for a [BookPage] and stores it in Firestore.
 * It verifies whether the BookPage already has OCR content saved in Firestore before initiating the OCR process again.
 */
internal class BookPageOCRFallbackService internal constructor(
    private val postImportActionsManager: ContentPostImportActionsManager,
    private val ocrAdapter: OCRAdapter,
    private val scannedBookService: PlatformScannedBookService,
    private val clientConfig: ClientConfig,
    private val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
    private val bookPageOCRRequirementFlow: MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>>,
    private val convertImage: suspend (
        imageInput: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        targetQualityPercent: Int,
    ) -> Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    private val userProfileService: UserProfileService,
) {

    internal suspend fun runOcrFallback(bookPage: BookPageDelegate): Result<List<BookPageTextContentItem>?> {
        // Notify early in the process through a flow that the OCR requirement is needed for this bookPage.
        // This encompasses cases where OCR has been previously executed and saved but is requested again.
        bookPageOCRRequirementFlow.emit(bookPage.pageIndex to bookPage.getRawTextContentItems().orThrow().isEmpty())

        // First we check if we previously already ran OCR on this page.
        val importerState = postImportActionsManager.state
        if (importerState is ContentImporterState.Imported) {
            val scannedPage = scannedBookService.getPageByPageIndex(importerState.uri.id, bookPage.pageIndex).orReturn {
                return it
            }

            if (scannedPage != null) {
                val localPage = FirestoreScannedBookPage(scannedPage, clientConfig, firebaseStorageCache)
                val scannedBookBookPage = ScannedBookBookPage(bookPage.pageIndex, localPage)
                return scannedBookBookPage.getRawTextContentItems()
            }
        }

        suspend fun getOcrImageScale(): Double {
            val uri = (postImportActionsManager.state as? ContentImporterState.Imported)?.uri
            val document = uri?.let { userProfileService.userDocumentsMap.get()[it.id] }
            return when {
                document?.ocrImageScale != null -> document.ocrImageScale!!
                bookPage.getMetadata().viewport.width > bookPage.getMetadata().viewport.height ->
                    OCR_IMAGE_SCALE_LANDSCAPE
                else -> OCR_IMAGE_SCALE_PORTRAIT
            }
        }

        suspend fun getOcrImageQuality(): Int {
            val uri = (postImportActionsManager.state as? ContentImporterState.Imported)?.uri
            val document = uri?.let { userProfileService.userDocumentsMap.get()[it.id] }
            return when {
                document?.ocrImageQuality != null -> document.ocrImageQuality!!
                bookPage.getMetadata().viewport.width > bookPage.getMetadata().viewport.height ->
                    OCR_IMAGE_COMPRESSION_TARGET_PERCENT_LANDSCAPE
                else -> OCR_IMAGE_COMPRESSION_TARGET_PERCENT_PORTRAIT
            }
        }

        return withTelemetry("BookPageOCRFallbackService.runOcrFallback") { telemetryEventBuilder ->
            telemetryEventBuilder.addProperty("pageIndex", bookPage.pageIndex)

            // For pages in landscape, we need to increase the scale so that we have better OCR results.
            // Pages that are in landscape usually mean that we have a scanned book where two pages are scanned together
            val scale = getOcrImageScale()
            val targetQualityPercent = getOcrImageQuality()

            val imageFile = bookPage.getImage(BookPageRequestOptions(scale)).orReturn {
                return@withTelemetry it
            }
            val convertedImage = telemetryEventBuilder.addMeasurement(
                measurementName = "BookPageOCRFallbackService.convertImage",
            ) {
                convertImage(imageFile, targetQualityPercent).orReturn {
                    return@withTelemetry it
                }
            }

            val ocrResult = ocrAdapter.runOCR(convertedImage, OcrSource.ImageBasedPdfPage).map {
                /* If OCR returned no text either, then just fall-back to the original result
               and don't persist - not persisting is especially expected because the OCR adapter `null` result
               may also mean that OCR is not available for the user. TODO: consider prevention of double-OCRing
               for when there is no text - would need to have the OCR adapter tell SDK that it is not available
               to user (either through a property on the adapter or by a special result), or the SDK would need to
               make the assumption that unsubscribed users should not have access to even on-device OCR.
                 */
                it ?: return@withTelemetry null.successfully()
            }.orReturn { return@withTelemetry it }

            // If we already performed OCR, even when a cancellation is requested, still cache the result
            // so we don't have to redo it the next time.
            withContext(NonCancellable) {
                val ocrFile = OCRFile(ocrResult, imageFile)
                val localPage = LocalPage(ocrFile)
                val scannedBookBookPage = ScannedBookBookPage(bookPage.pageIndex, localPage)

                postImportActionsManager.queueTaskAfterImport { uri ->
                    scannedBookService.addPageForOcrFallback(uri.id, scannedBookBookPage.pageIndex, ocrResult)
                        .onFailure { e ->
                            Log.e(
                                DiagnosticEvent(
                                    message = "BookPageOCRFallbackService.runOcrFallback: Failed to store OCR result.",
                                    error = ErrorInfoForDiagnostics(e.errorNative),
                                    properties = SdkBoundaryMap.of(
                                        "pageIndex" to scannedBookBookPage.pageIndex,
                                    ),
                                    sourceAreaId = "BookPageOCRFallbackService.runOcrFallback",
                                ),
                            )
                        }
                }

                scannedBookBookPage.getRawTextContentItems()
            }
        }
    }
}
