package com.speechify.client.api.adapters.pdf

import com.speechify.client.api.util.images.Viewport
import kotlin.js.JsExport

/**
 * Data describing this page
 */
@JsExport
data class PDFPageMetadata(
    /**
     * The original pixel dimensions of this page
     */
    val viewport: Viewport,
)
