package com.speechify.client.helpers.content.standard.dynamic

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.helpers.content.standard.ContentMutationsInfo
import com.speechify.client.helpers.content.standard.ContentSequenceCharacteristics
import com.speechify.client.helpers.content.standard.StandardBlocksFlowProvider
import com.speechify.client.helpers.content.standard.StandardBlocksFlowProviderFromFullContentFlow
import com.speechify.client.helpers.content.standard.StandardViewFromFlowBase
import com.speechify.client.helpers.content.standard.getMinimalContentContainingCursor
import kotlinx.coroutines.flow.Flow

internal open class DynamicStandardViewFromFlowOfOnDemandContent(
    referenceOfParentBlock: ContentElementReference,
    standardBlocksFlowProvider: StandardBlocksFlowProvider,
) : StandardViewFromFlowBase(
    referenceOfParentBlock = referenceOfParentBlock,
    standardBlocksFlowProvider = standardBlocksFlowProvider,
),
    ContentSequenceCharacteristics by standardBlocksFlowProvider {

    internal constructor(
        referenceOfParentBlock: ContentElementReference,
        fullContentFlow: Flow<StandardBlock>,
        contentMutationsInfo: ContentMutationsInfo,
        contentSequenceCharacteristics: ContentSequenceCharacteristics,
    ) : this(
        referenceOfParentBlock = referenceOfParentBlock,
        standardBlocksFlowProvider = StandardBlocksFlowProviderFromFullContentFlow(
            flowFromStart = fullContentFlow,
            contentMutationsInfo = contentMutationsInfo,
            contentSequenceCharacteristics = contentSequenceCharacteristics,
        ),
    )

    override suspend fun getBlocksAroundCursor(cursor: ContentCursor): List<StandardBlock> =
        standardBlocksFlowProvider.getMinimalContentContainingCursor(cursor)

    override fun destroy() = Unit // NOOP
}
