// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map

/**
 * Useful to lose the fingerprint of [StateFlow] to signal that the logic is prepared of any kind of flow
 * (e.g. lose the [StateFlow.collect] which never completes).
 */
internal fun <T> StateFlow<T>.asPureFlow(): Flow<T> =
    this

/**
 * In contrast to `map {}.stateIn(...)` this one allows to change the underlying type [T] to [R], without starting a
 * new coroutine.
 */
internal fun <T, R> StateFlow<T>.mapStateFlow(transform: (T) -> R): StateFlow<R> {
    /** [StateFlow] is claimed to be unstable for inheritance, but inheriting is the only way we can implement this
     * mapping without starting a new coroutine.
     * And we're doing it successfully in more places #InheritingStateFlowDespiteBeingUnstable
     */
    return object : StateFlow<R> {
        override val replayCache: List<R>
            get() = this@mapStateFlow.replayCache.map(transform)

        override suspend fun collect(collector: FlowCollector<R>): Nothing {
            this@mapStateFlow.map(transform).collect(collector)
            throw IllegalStateException("Should never reach here") // Because `StateFlow` never finishes
        }

        override val value: R
            get() = transform(this@mapStateFlow.value)
    }
}
