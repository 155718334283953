package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.util.images.verticalDistanceTo
import kotlin.js.JsExport

@JsExport
object FixedLayoutReaderUtils {
    fun groupPageRegionsIntoLines(
        regions: Array<FixedLayoutPageRegion>,
    ): Array<Array<FixedLayoutPageRegion>> =
        regions.fold(mutableListOf<MutableList<FixedLayoutPageRegion>>()) { lines, region ->
            val line = lines.find {
                it.first().estimatedNormalizedBoundingBox
                    .verticalDistanceTo(region.estimatedNormalizedBoundingBox) <
                    region.estimatedNormalizedBoundingBox.height / 3
            }
            if (line == null) {
                lines.add(mutableListOf(region))
            } else {
                line.add(region)
            }
            lines
        }.map { it.toTypedArray() }.toTypedArray()
}
