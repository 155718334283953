package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.internal.util.collections.flows.MutableSharedFlowThatFinishes
import com.speechify.client.internal.util.collections.flows.SharedFlowThatFinishes
import com.speechify.client.internal.util.coroutines.ChildCoroutineErrorBehavior
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.cancellable
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.withContext

/**
 * Converts the normal flow of import progress events into a MutableSharedFlowThatFinishes in the current scope.
 *
 * The flow returned here is cancellable by cancelling the owning job, if not cancelled it will always either fail due
 * to an error or run until completion.
 */
internal fun <T> Flow<T>.shareFinishingAlwaysReturningLastItemIn(
    onError: ChildCoroutineErrorBehavior,
    scope: CoroutineScope,
): SharedFlowThatFinishes<T> =
    this.shareFinishingAlwaysReturningLastItemWithJobIn(
        onError = onError,
        scope = scope,
    ).sharedFlowThatFinishes

internal fun <T> Flow<T>.shareFinishingAlwaysReturningLastItemWithJobIn(
    onError: ChildCoroutineErrorBehavior,
    scope: CoroutineScope,
): SharedFlowWithOwningJob<T> {
    val sharedFlow = MutableSharedFlowThatFinishes<T>(
        /* We use replayWithMinimumOne = 2, since 1 means the completion event is replayed, 2 means the completion event +
         * the last actual value is replayed.
         */
        replayWithMinimumOne = 2,
    )

    return SharedFlowWithOwningJob(
        owningJob = this@shareFinishingAlwaysReturningLastItemWithJobIn
            .onEach {
                sharedFlow.emit(it)
            }
            .cancellable()
            .onCompletion { err ->
                withContext(NonCancellable) {
                    if (err != null) {
                        sharedFlow.fail(err)
                    } else {
                        sharedFlow.finish()
                    }
                }
            }
            .launchWithOnErrorBehaviorIn(
                onError = onError,
                scope = scope,
            ),
        sharedFlowThatFinishes = sharedFlow,
    )
}

internal class SharedFlowWithOwningJob<T>(
    val owningJob: Job,
    val sharedFlowThatFinishes: SharedFlowThatFinishes<T>,
)
