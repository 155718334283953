package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.editing.EditingBookPage
import com.speechify.client.api.content.hasNontrivialIntersection
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.coGetImage
import com.speechify.client.api.content.view.book.coGetTextContent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Point
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.util.extensions.collections.flows.flatMapLatestForEachInstance
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.HoveredSentenceHelperCommand
import com.speechify.client.reader.core.PlaybackCommand
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ReadingLocationCommand
import com.speechify.client.reader.core.RelativeNavigationIntent
import com.speechify.client.reader.core.Selection
import com.speechify.client.reader.core.SelectionGranularity
import com.speechify.client.reader.core.SelectionHelperCommand
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.fixedlayoutbook.overlay.ComputeOverlayContext
import com.speechify.client.reader.fixedlayoutbook.overlay.FixedLayoutOverlayStrategy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterIsInstance
import kotlin.js.JsExport

/**
 * A helper that emits updates to the appearance of the associated page in [FixedLayoutBookReader] mode.
 *
 * Each emitted state specifies how the associated page should be displayed or handled at that moment.
 * States are not meant to be combined with previous ones - a new state fully replaces any prior state.
 *
 * @see FixedLayoutPageView.InFocus.pageHelper
 * @see FixedLayoutPageView.Hidden.pageHelper
 */
@JsExport
class FixedLayoutPageHelper internal constructor(
    scope: CoroutineScope,
    internal val pageNoFeatures: FixedLayoutPageNoFeatures,
    private val readerFeatures: Flow<ReaderFeatures>,

    /**
     * Passing the pageIndex directly instead of relying on [FixedLayoutPageNoFeatures.page.pageIndex],
     * because the EditingBookPage component modifies the page indexes after hiding pages.
     * To ensure accuracy, we're passing the original book page index, as clients now display hidden pages as well.
     */
    val pageIndex: Int,
    private val overlayStrategy: FixedLayoutOverlayStrategy,
) : Helper<FixedLayoutPage>(scope) {

    val viewport = pageNoFeatures.viewport

    private val pageNoFeaturesFlow = MutableStateFlow(pageNoFeatures)

    override val stateFlow: StateFlow<FixedLayoutPage> =
        combine(pageNoFeaturesFlow, readerFeatures) { pageNoFeatures, readerFeatures ->
            // We first check for intersection with the page so we don't scan boxes on pages that we know don't
            // have any relevant overlays
            val items = pageNoFeatures.page.coGetTextContent().orThrow()
            val wordHighlight =
                readerFeatures.speakingWord
                    ?.nullIf { !this.hasNontrivialIntersectionWith(pageNoFeatures) }
                    ?.let {
                        overlayStrategy.computeOverlay(
                            ComputeOverlayContext(it, items, pageNoFeatures.page),
                        )
                    }

            val sentenceHighlight = readerFeatures.speakingSentence
                ?.nullIf { !this.hasNontrivialIntersectionWith(pageNoFeatures) }
                ?.let {
                    overlayStrategy.computeOverlay(
                        ComputeOverlayContext(it, items, pageNoFeatures.page),
                    )
                }

            val selectionHighlight = readerFeatures.selection
                ?.nullIf { !hasNontrivialIntersection(start, end, pageNoFeatures.start, pageNoFeatures.end) }
                ?.let { selection ->
                    val selectionHandles = findBoundaryHandles(items, selection)
                    FixedLayoutSelectionRegion(
                        overlayStrategy.computeOverlay(
                            ComputeOverlayContext(
                                selection,
                                items,
                                pageNoFeatures.page,
                                (selection as? Selection)?.startPoint,
                                (selection as? Selection)?.endPoint,
                            ),
                        ).toTypedArray(),
                        selectionHandles?.first,
                        selectionHandles?.second,
                    )
                }

            val userHighlights = readerFeatures.highlights.items
                .filter { it.highlight.span.hasNontrivialIntersectionWith(pageNoFeatures) }
                .map {
                    it to overlayStrategy.computeOverlay(
                        ComputeOverlayContext(it.highlight.span, items, pageNoFeatures.page),
                    )
                }

            val hoveredSentenceHighlight = readerFeatures.hoveredSentence
                ?.nullIf { !this.hasNontrivialIntersectionWith(pageNoFeatures) }
                ?.let { overlayStrategy.computeOverlay(ComputeOverlayContext(it, items, pageNoFeatures.page)) }

            FixedLayoutPage(
                page = pageNoFeatures,
                features = FixedLayoutPageFeatures(
                    wordHighlight = wordHighlight?.toTypedArray(),
                    sentenceHighlight = sentenceHighlight?.toTypedArray(),
                    selection = selectionHighlight,
                    userHighlights = userHighlights.map { (highlightItem, overlays) ->
                        FixedLayoutUserHighlight(
                            dispatch = dispatch,
                            userHighlight = highlightItem.highlight,
                            regions = overlays.toTypedArray(),
                        )
                    }.toTypedArray(),
                    navigationIntent = readerFeatures.navigationIntent?.nullIf {
                        !pageNoFeatures.contains(this.location.hack.cursor)
                    }?.let {
                        val region = computePageRegion(items, it.location.hack.cursor)
                            // if we know the target is on this page but can't resolve to a region, pick the full page
                            ?: FixedLayoutPageRegion.fromNormalizedBoundingBox(
                                BoundingBox.fromDimensionsAndCoordinates(
                                    width = 1.0,
                                    height = 1.0,
                                    left = 0.0,
                                    top = 0.0,
                                ),
                            )
                        FineFixedLayoutBookNavigationIntent(
                            dispatch = dispatch,
                            resolvedIntent = it,
                            region = region,
                        )
                    },
                    hoveredSentence = hoveredSentenceHighlight?.toTypedArray(),
                ),
            )
        }.stateInHelper(
            initialValue = FixedLayoutPage(
                page = pageNoFeatures,
                features = FixedLayoutPageFeatures.empty(),
            ),
        )
    override val initialState = stateFlow.value

    init {
        commands
            .filterIsInstance<FixedLayoutPageHelperCommand>()
            .filter { it.pageIndex == this.pageIndex }
            .onEachInstance<FixedLayoutPageHelperCommand.SetZoomLevel> {
                val pageNoOverlays = pageNoFeaturesFlow.value
                val page = pageNoOverlays.page
                val options = BookPageRequestOptions(scale = it.zoomLevel)
                val image = page.coGetImage(options).orThrow()
                pageNoFeaturesFlow.value = FixedLayoutPageNoFeatures(
                    page = page,
                    zoomLevel = options.scale,
                    image = image,
                    regionsOfInterest = page.regionsOfInterest
                        .map { FixedLayoutPageRegion.fromNormalizedBoundingBox(it) }
                        .toTypedArray(),
                )
            }
            .onEachInstance<FixedLayoutPageHelperCommand.TapToJump> {

                val pageNoOverlays = pageNoFeaturesFlow.value
                val (location, locationInToleranceLimit) = resolvePagePoint(
                    pageNoOverlays.page,
                    it.normalizedLeft,
                    it.normalizedTop,
                    it.activationTolerance,
                )
                if (locationInToleranceLimit) {
                    dispatch(PlaybackCommand.TapToJump(location, it.relativeNavigationIntent))
                } else {
                    Log.d("Ignoring tap outside tolerance", sourceAreaId = "FixedLayoutPageHelper")
                }
            }
            .onEachInstance<FixedLayoutPageHelperCommand.TapToPlay> {

                val pageNoOverlays = pageNoFeaturesFlow.value
                val (location, locationInToleranceLimit) = resolvePagePoint(
                    pageNoOverlays.page,
                    it.normalizedLeft,
                    it.normalizedTop,
                    it.activationTolerance,
                )
                if (locationInToleranceLimit) {
                    dispatch(PlaybackCommand.TapToPlay(location, it.relativeNavigationIntent, it.enableAutoscroll))
                } else {
                    Log.d("Ignoring tap outside tolerance", sourceAreaId = "FixedLayoutPageHelper")
                }
            }
            .onEachInstance<FixedLayoutPageHelperCommand.HoverSentence> {
                val pageNoOverlays = pageNoFeaturesFlow.value
                val (location, locationInToleranceLimit) = resolvePagePoint(
                    pageNoOverlays.page,
                    it.normalizedLeft,
                    it.normalizedTop,
                    it.activationTolerance,
                )
                if (locationInToleranceLimit) {
                    dispatch(HoveredSentenceHelperCommand.HoverSentence(location))
                } else {
                    dispatch(HoveredSentenceHelperCommand.ClearHoveredSentence)
                }
            }
            .flatMapLatestForEachInstance<FixedLayoutPageHelperCommand.Select> {
                val pageNoOverlays = pageNoFeaturesFlow.value

                dispatch(
                    SelectionHelperCommand.SelectWithPoint(
                        Point(it.normalizedLeft, it.normalizedTop),
                        pageNoOverlays.page,
                        viewport,
                        it.granularity,
                    ),
                )
            }
            .launchInHelper()
    }

    internal fun setZoomLevel(zoomLevel: Double) {
        dispatch(FixedLayoutPageHelperCommand.SetZoomLevel(zoomLevel, this.pageIndex))
    }

    fun tapToJump(
        normalizedLeft: Double,
        normalizedTop: Double,
        activationTolerance: Double,
        relativeNavigationIntent: RelativeNavigationIntent,
    ) {
        dispatch(
            FixedLayoutPageHelperCommand.TapToJump(
                normalizedLeft,
                normalizedTop,
                activationTolerance,
                relativeNavigationIntent,
                this.pageIndex,
            ),
        )
    }

    /**
     * Starts playback from a word or sentence on this page that contains the specified
     * [normalizedLeft] and [normalizedTop] coordinates, based on the given [relativeNavigationIntent].
     *
     * The active playback locations are communicated to
     * [listeners of `FixedLayoutPageHelper` state changes][FixedLayoutPageHelper.addStateChangeListener].
     *
     * @param normalizedLeft The normalized left coordinate of the location on this page from which playback should start.
     * @param normalizedTop The normalized top coordinate of the location on this page from which playback should start.
     * @param activationTolerance The maximum allowable distance from textual content that the specified
     *   [normalizedLeft] and [normalizedTop] coordinates can have to activate playback.It should be given as a percentage of textual content height.
     * @param relativeNavigationIntent The intent specifying navigation relative to the specified location
     *   before starting playback.
     * @param enableAutoscroll `true` to enable autoscroll and scroll to the playback location.
     *   `false` by default.
     */
    fun tapToPlay(
        normalizedLeft: Double,
        normalizedTop: Double,
        activationTolerance: Double,
        relativeNavigationIntent: RelativeNavigationIntent,
        enableAutoscroll: Boolean = false,
    ) {
        dispatch(
            FixedLayoutPageHelperCommand.TapToPlay(
                normalizedLeft,
                normalizedTop,
                activationTolerance,
                relativeNavigationIntent,
                this.pageIndex,
                enableAutoscroll,
            ),
        )
    }

    /**
     * Selects a character or a word on this page at the specified [normalizedLeft] and [normalizedTop] coordinates,
     * based on the given selection [granularity].
     *
     * The resulting boundaries of the active selection are communicated to
     * [listeners of `FixedLayoutPageHelper` state changes][FixedLayoutPageHelper.addStateChangeListener].
     *
     * @param normalizedLeft The normalized left coordinate of the character on this page to select.
     * @param normalizedTop The normalized top coordinate of the character on this page to select.
     * @param granularity The selection granularity, determining whether a single character or the entire word
     *   matching the specified coordinates should be selected. [SelectionGranularity.CHARACTER] by default.
     */
    fun select(
        normalizedLeft: Double,
        normalizedTop: Double,
        granularity: SelectionGranularity = SelectionGranularity.CHARACTER,
    ) {
        dispatch(
            FixedLayoutPageHelperCommand.Select(normalizedLeft, normalizedTop, this.pageIndex, granularity),
        )
    }

    fun clearSelection() {
        dispatch(SelectionHelperCommand.ClearSelection)
    }

    fun hoverSentence(normalizedLeft: Double, normalizedTop: Double, activationTolerance: Double) {
        dispatch(
            FixedLayoutPageHelperCommand.HoverSentence(
                normalizedLeft,
                normalizedTop,
                activationTolerance,
                this.pageIndex,
            ),
        )
    }

    fun clearHoveredSentence() {
        dispatch(HoveredSentenceHelperCommand.ClearHoveredSentence)
    }

    fun markAsCurrentlyReading() {
        dispatch(
            ReadingLocationCommand.SetReadingLocation(
                location = SerialLocation(
                    cursor = pageNoFeatures.page.start,
                ),
            ),
        )
    }

    override fun destroy() {
        super.destroy()
        pageNoFeatures.page.destroy()
        // NOTE: this doesn't clean up the image that we rendered, but it's possible we can rely on GC for this
    }
}

internal class FixedLayoutPageNoFeatures(
    internal val page: BookPage,
    val zoomLevel: Double,
    val image: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    val regionsOfInterest: Array<FixedLayoutPageRegion>,
) : Content by page {
    val viewport get() = page.getMetadata().viewport
}

internal sealed class FixedLayoutPageHelperCommand {
    abstract val pageIndex: Int

    data class SetZoomLevel(val zoomLevel: Double, override val pageIndex: Int) : FixedLayoutPageHelperCommand()
    data class TapToJump(
        val normalizedLeft: Double,
        val normalizedTop: Double,
        val activationTolerance: Double,
        val relativeNavigationIntent: RelativeNavigationIntent,
        override val pageIndex: Int,
    ) : FixedLayoutPageHelperCommand()

    data class TapToPlay(
        val normalizedLeft: Double,
        val normalizedTop: Double,
        val activationTolerance: Double,
        val relativeNavigationIntent: RelativeNavigationIntent,
        override val pageIndex: Int,
        val enableAutoscroll: Boolean,
    ) : FixedLayoutPageHelperCommand()

    data class Select(
        val normalizedLeft: Double,
        val normalizedTop: Double,
        override val pageIndex: Int,
        val granularity: SelectionGranularity,
    ) :
        FixedLayoutPageHelperCommand()

    data class HoverSentence(
        val normalizedLeft: Double,
        val normalizedTop: Double,
        val activationTolerance: Double,
        override val pageIndex: Int,
    ) : FixedLayoutPageHelperCommand()
}

val BookPage.regionsOfInterest: Array<BoundingBox>
    get() = when (this) {
        is EditingBookPage -> this.regionsOfInterest
        else -> emptyArray()
    }
