package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.ProducerScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.channelFlow

/* NOTE: `channelFlow` and `callBackFlow` [mostly do the same thing](https://stackoverflow.com/a/59194216),
 * hence we tackle both here.
 */

/**
 * Note: apart from 'whole numbers' (0 or positive), values for this type can also be expressed semantically through
 * const such as [Channel.RENDEZVOUS], [Channel.UNLIMITED], where some are even negative: [Channel.CONFLATED], [Channel.BUFFERED].
 */
typealias BufferCapacity = Int

/**
 * A convenience version of [callbackFlow] that tries to remind the caller to specify a buffer capacity to avoid
 * unexpected loss of items.
 */
internal fun <T> callbackFlowWithCapacityRequired(
    // Chose `null` to also represent unlimited capacity, so that this common case doesn't require imports of the distant [Channel] type.
    /**
     * Specify `null` for unlimited capacity.
     * NOTE: Unlimited capacity is valid and, while it can lead to out-of-memory failures, failing this way may be the
     * preferred behavior over causing data inconsistency, possibly even data corruption).
     * Take note however, that adding such a buffer completely removes the consumer's ability to communicate its
     * backpressure to the producer. Consider [channelFlowWithoutItemsLoss] in such case).
     *
     * See [BufferCapacity] for values possible here other than `null`.
     */
    capacityOrNullIfUnlimited: BufferCapacity?,
    block: suspend ProducerScope<T>.() -> Unit,
): Flow<T> =
    callbackFlow(block = block)
        .buffer(capacityOrNullIfUnlimited ?: Channel.UNLIMITED)

/**
 * A convenience version of [channelFlow] that tries to remind the caller to specify a buffer capacity to avoid
 * unexpected loss of items.
 */
internal fun <T> channelFlowWithCapacityRequired(
    /**
     * Specify `null` for unlimited capacity.
     * NOTE: Unlimited capacity is valid and, while it can lead to out-of-memory failures, failing this way may be the
     * preferred behavior over causing data inconsistency, possibly even data corruption).
     * Take note however, that adding such a buffer completely removes the consumer's ability to communicate its
     * backpressure to the producer. Consider [channelFlowWithoutItemsLoss] in such case).
     *
     * See [BufferCapacity] for values possible here other than `null`.
     */
    capacityOrNullIfUnlimited: BufferCapacity?,
    /**
     * NOTE: When specified [capacityOrNullIfUnlimited] as [Channel.CONFLATED], this must be omitted, or specified
     * [BufferOverflow.SUSPEND] (as per sources of [buffer])
     */
    onBufferOverflow: BufferOverflow = BufferOverflow.SUSPEND,
    block: suspend ProducerScope<T>.() -> Unit,
): Flow<T> =
    channelFlow(block = block)
        .buffer(
            capacity = capacityOrNullIfUnlimited ?: Channel.UNLIMITED,
            /*
             */
            onBufferOverflow = onBufferOverflow,
        )
