package com.speechify.client.internal.util.extensions.collections

import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.transform

/**
 * Groups items sharing the same key, but only if they are adjacent.
 * A popular member of the group-by family, but there's no such function in Kotlin (though may want to watch [this SO for updates](https://stackoverflow.com/questions/65355091/split-a-list-into-groups-of-consecutive-elements-based-on-a-condition-in-kotlin))
 */
internal fun <Item, Key> Sequence<Item>.groupConsecutiveBy(
    keySelector: (Item) -> Key,
) = groupConsecutiveBy(keySelector, valueTransform = { it })

/**
 * Version of [groupConsecutiveBy] with a [valueTransform]
 */
internal fun <Item, Key, ValueFromItem> Sequence<Item>.groupConsecutiveBy(
    keySelector: (Item) -> Key,
    valueTransform: (Item) -> ValueFromItem,
):
    Sequence<Pair<Key, List<ValueFromItem>>> = sequence {
    /* Inspired by [this](https://stackoverflow.com/a/65357359), but rewrote to support returning the key, and to use
     `sequence`, of which the iterator is only traversed once. */
    val iterator = this@groupConsecutiveBy.iterator()
    var hasNext = iterator.hasNext()

    if (!hasNext) {
        return@sequence
    }

    // Internal state for the inner function
    var currItem = iterator.next()
    var currKey: Key = keySelector(currItem)
    var prevKeyToYield: Key = currKey

    fun getItemsWhileKeySameAndSetHasNext(): Pair<Key, List<ValueFromItem>> = sequence {
        do {
            yield(valueTransform(currItem))

            hasNext = iterator.hasNext()
            prevKeyToYield = currKey
            if (!hasNext) {
                break
            } else {
                currItem = iterator.next()
                currKey = keySelector(currItem)
                if (currKey != prevKeyToYield) {
                    break
                } else {
                    continue
                }
            }
        } while (true)
    }
        // Need to evaluate eagerly, as this also sets the prevKeyToYield
        .toList()
        .let {
            Pair(prevKeyToYield, it)
        }

    while (hasNext) {
        yield(getItemsWhileKeySameAndSetHasNext())
    }
}

internal inline fun <Item, Key> Flow<Item>.groupConsecutiveBy(
    crossinline keySelector: (Item) -> Key,
):
    Flow<Pair<Key, List<Item>>> {
    var currentKey: Key? = null
    var currentGrouping: MutableList<Item>? = null

    return this@groupConsecutiveBy.transform<Item, Pair<Key, List<Item>>> {
        val newKey = keySelector(it)

        when (val currentGroupingEnsuredNotNull = currentGrouping) {
            null -> { // First item
                currentKey = newKey
                currentGrouping = mutableListOf(it)
            }

            else -> { // All other items
                if (currentKey == newKey) {
                    currentGroupingEnsuredNotNull.add(it)
                } else {
                    emit(currentKey!! to currentGroupingEnsuredNotNull)
                    currentGrouping = mutableListOf(it)
                    currentKey = newKey
                }
            }
        }
    }.onCompletion {
        ifNotNull(currentGrouping) {
            @Suppress("UNCHECKED_CAST") /* The `currentKey as Key` is safe, because a non-null grouping means
             `currentKey` is definitely `Key` (though it can be `null`, hence why we don't use `!!`). */
            emit(
                value =
                currentKey as Key to it,
            )
        }
    }
}
