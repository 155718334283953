package com.speechify.client.api.content.pdf

import com.speechify.client.api.adapters.pdf.PDFDocumentAdapter
import com.speechify.client.api.adapters.pdf.PDFOutline
import com.speechify.client.api.adapters.pdf.PDFPageAdapter
import com.speechify.client.api.adapters.pdf.coGetOutline
import com.speechify.client.api.adapters.pdf.coGetPages
import com.speechify.client.api.adapters.pdf.coSearch
import com.speechify.client.api.adapters.pdf.search.PdfSearchOptions
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentElementReferenceUtils
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.book.BaseBookPageWrapper
import com.speechify.client.api.content.view.book.BaseBookView
import com.speechify.client.api.content.view.book.BookMetadata
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.content.view.book.translateToUsableCursor
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.runCatchingToSdkResult
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.helpers.content.standard.book.LineGroup
import com.speechify.client.internal.services.ml.BookPageMLParsingWithRemoteOCRService
import com.speechify.client.internal.services.ocr.BookPageOCRFallbackService
import kotlinx.coroutines.flow.StateFlow

/**
 * A [BookView] backed by a PDF file.
 */
internal class PDFBookView internal constructor(
    private val adapter: PDFDocumentAdapter,
    private val pdfBookPageFactory: PDFBookPageFactory,
    override val mlParsingModeFlow: StateFlow<MLParsingMode>,
    override val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy>,
) : BaseBookView() {
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    init {
        initializeBookViewFlows()
    }

    override fun getMetadata(): BookMetadata =
        BookMetadata(
            numberOfPages = adapter.getMetadata().numberOfPages,
        )

    override fun getPages(
        pageIndexes: Array<Int>,
        callback: Callback<Array<BookPage>>,
    ) = callback.fromCo {
        getPages(pageIndexes)
    }

    internal suspend fun getPages(pageIndexes: Array<Int>): Result<Array<BookPage>> =
        runCatchingToSdkResult {
            pageCache.getOrPutMulti(
                keys = pageIndexes.toList(),
                produceMissingValues = { keysOfMissingEntries ->
                    adapter.coGetPages(keysOfMissingEntries.toTypedArray()).orThrow().map {
                        pdfBookPageFactory.createBookPage(
                            pdfPageAdapter = it,
                            getSurroundingLineGroups = ::getSurroundingLineGroups,
                        ) { bookPageIndex, parsedPageContent ->
                            this@PDFBookView.notifyTextContentRetrieved(bookPageIndex, parsedPageContent)
                        }
                    }
                },
            ).toTypedArray()
        }

    override fun getPageIndex(cursor: ContentCursor): Int =
        when (cursor) {
            // NOTE(anson): positions in Books will always be rooted at TextContentItems, so the parent path will always contain a page index
            is ContentTextPosition -> cursor.element.path[0]
            is ContentElementBoundary ->
                cursor.element.path.firstOrNull() ?: when (cursor.boundary) {
                    is ContentBoundary.START -> 0
                    is ContentBoundary.END -> adapter.getMetadata().numberOfPages - 1
                }
        }

    override fun search(
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        searchOptions: BookSearchOptions,
        callback: Callback<Array<BookSearchResult>>,
    ) =
        callback.fromCo {
            adapter.coSearch(text, startPageIndex, endPageIndex, PdfSearchOptions(searchOptions.caseSensitive))
                .map { result ->
                    result.map {
                        BookSearchResult(
                            pageIndex = it.pageIndex,
                            boundingBoxes = it.boundingBoxes,
                        )
                    }.toTypedArray()
                }
        }

    override suspend fun translateToUsableCursor(originalCursor: ContentCursor): ContentCursor? {
        val pageIndex = getPageIndex(originalCursor)
        val bookPage = coGetPages(arrayOf(pageIndex)).orReturn { return null }.single()
        return bookPage.translateToUsableCursor(originalCursor)
    }

    override suspend fun getTableOfContents() = adapter.coGetOutline().map { it.toTableOfContents() }.toNullable()

    override fun destroy() {
        super.destroy()
        adapter.destroy()
    }
}

/**
 * Matches non whitespace, non control characters.
 */
val VALID_TEXT_REGEX = Regex("[^\\s\\u0000-\\u0020]")

internal interface PDFBookPageFactory {
    fun createBookPage(
        pdfPageAdapter: PDFPageAdapter,
        getSurroundingLineGroups: suspend (pageIndex: Int, pageOffset: Int) -> Result<List<List<LineGroup>>>,
        notifyTextContentRetrieved: suspend (pageIndex: Int, ParsedPageContent) -> Unit,
    ): BookPage
}

internal class PDFBookPageFactoryImpl internal constructor(
    private val contentSortingStrategy: ContentSortingStrategy,
    private val bookPageOCRFallbackService: BookPageOCRFallbackService,
    private val bookPageMLParsingWithRemoteOCRService: BookPageMLParsingWithRemoteOCRService,
    private val mlParsingModeFlow: StateFlow<MLParsingMode>,
    private val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy>,
) : PDFBookPageFactory {
    override fun createBookPage(
        pdfPageAdapter: PDFPageAdapter,
        getSurroundingLineGroups: suspend (pageIndex: Int, pageOffset: Int) -> Result<List<List<LineGroup>>>,
        notifyTextContentRetrieved: suspend (pageIndex: Int, ParsedPageContent) -> Unit,
    ): BookPage {
        val pdfBookPage =
            PDFBookPage(
                adapter = pdfPageAdapter,
                contentSortingStrategy = contentSortingStrategy,
            )

        return BaseBookPageWrapper(
            bookPageDelegate = pdfBookPage,
            runOcrFallback = bookPageOCRFallbackService::runOcrFallback,
            mlParsingModeFlow = mlParsingModeFlow,
            getSurroundingLineGroups = getSurroundingLineGroups,
            getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR =
            bookPageMLParsingWithRemoteOCRService::getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR,
            notifyTextContentRetrieved = notifyTextContentRetrieved,
            ocrFallbackStrategyFlow = ocrFallbackStrategyFlow,
        )
    }
}

private fun PDFOutline.toTableOfContents() = if (entries.isNotEmpty()) {
    val groupedEntries = entries.groupBy { it.attributes.targetPageIndex }
    val entries = groupedEntries.flatMap { (targetPageIndex, entryList) ->
        val start = ContentElementReferenceUtils.fromPath(listOf(targetPageIndex)).start
        if (entryList.size > 1) {
            listOf(
                TableOfContents.Entry(
                    content = TableOfContents.Entry.Content.MultipleMerged(
                        entryList.map { entry ->
                            TableOfContents.Entry.Section(
                                title = entry.title,
                                hierarchyLevel = entry.hierarchyLevel,
                            )
                        },
                    ),
                    start = TableOfContents.Entry.Start.Resolved(cursor = start),
                    attributes = TableOfContents.Entry.Attributes(
                        targetPageIndex = targetPageIndex,
                    ),
                ),
            )
        } else {
            entryList.map { entry ->
                TableOfContents.Entry(
                    content = TableOfContents.Entry.Content.Single(
                        TableOfContents.Entry.Section(
                            title = entry.title,
                            hierarchyLevel = entry.hierarchyLevel,
                        ),
                    ),
                    start = TableOfContents.Entry.Start.Resolved(cursor = start),
                    attributes = TableOfContents.Entry.Attributes(
                        targetPageIndex = targetPageIndex,
                    ),
                )
            }
        }
    }
    TableOfContents(entries = entries)
} else {
    null
}
