package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.content.ContentElementReferenceUtils
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.reader.core.BookEditorCommand
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.EditBookState
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.NavigationCommand
import com.speechify.client.reader.core.NavigationIntent
import com.speechify.client.reader.core.PlaybackState
import com.speechify.client.reader.core.ReadingLocationState
import com.speechify.client.reader.core.SerialLocation
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlin.js.JsExport

@JsExport
class BookThumbnailsHelper internal constructor(
    scope: CoroutineScope,
    private val bookView: BookView,
    editBookFlow: Flow<EditBookState>,
    playbackStateFlow: Flow<PlaybackState>,
    readingLocationFlow: Flow<ReadingLocationState>,
) : Helper<BookThumbnailState>(scope) {
    private val actualBookView = when (bookView) {
        is EditingBookView -> bookView.originalBookView
        else -> bookView
    }

    private val defaultState = BookThumbnailState(
        (0 until actualBookView.getMetadata().numberOfPages).map { pageIndex ->
            BookThumbnailItem(
                dispatch = dispatch,
                start = SerialLocation(cursor = ContentElementReferenceUtils.fromPath(path = listOf(pageIndex)).start),
                thumbnailImageHelper = ThumbnailImageHelper(
                    scope = scope,
                    bookView = actualBookView,
                    pageIndex = pageIndex,
                ),
                pageIndex = pageIndex,
                isHidden = when (bookView) {
                    is EditingBookView -> bookView.bookEdits.pages[pageIndex].hidden
                    else -> false
                },
                isListeningHere = false,
                isReadingHere = false,
            )
        }.toTypedArray(),
    )

    override val initialState: BookThumbnailState get() = defaultState
    override val stateFlow: StateFlow<BookThumbnailState> =
        combine(
            editBookFlow,
            playbackStateFlow,
            readingLocationFlow,
        ) { editState, playbackLocation, readingLocation ->
            defaultState.withStateUpdates(
                editState = editState,
                playbackLocation = playbackLocation,
                readingLocation = readingLocation,
            )
        }.stateInHelper(initialValue = initialState)
}

@JsExport
data class BookThumbnailState(
    val bookThumbnails: Array<BookThumbnailItem>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as BookThumbnailState

        return bookThumbnails.contentEquals(other.bookThumbnails)
    }

    override fun hashCode(): Int {
        return bookThumbnails.contentHashCode()
    }
}

@JsExport
data class BookThumbnailItem internal constructor(
    private val dispatch: CommandDispatch,
    internal val start: SerialLocation,
    val thumbnailImageHelper: ThumbnailImageHelper,
    val pageIndex: Int,
    val isHidden: Boolean,
    val isReadingHere: Boolean,
    val isListeningHere: Boolean,
) {
    fun hidePage() {
        dispatch(BookEditorCommand.HidePages(arrayOf(pageIndex)))
        dispatch(BookEditorCommand.SaveEdits)
    }

    fun showPage() {
        dispatch(BookEditorCommand.ShowPages(arrayOf(pageIndex)))
        dispatch(BookEditorCommand.SaveEdits)
    }

    fun goToPage() {
        dispatch(
            NavigationCommand.NavigateTo(
                intent = NavigationIntent.GoToPage(
                    pageIndex = pageIndex,
                    start = start.toRobustLocation(),
                ),
            ),
        )
    }
}

private fun BookThumbnailState.withStateUpdates(
    editState: EditBookState,
    playbackLocation: PlaybackState,
    readingLocation: ReadingLocationState,
): BookThumbnailState {
    val listeningHere = bookThumbnails.lastOrNull {
        it.start.cursor.isBeforeOrAt(playbackLocation.location.cursor)
    }
    val readingHere = readingLocation.location?.let { location ->
        bookThumbnails.lastOrNull {
            it.start.cursor.isBeforeOrAt(location.cursor)
        }
    }

    return this.copy(
        bookThumbnails = this.bookThumbnails.map { thumbnail ->
            thumbnail.copy(
                isHidden = when (editState) {
                    is EditBookState.Ready -> editState.pagesToEdit.getOrNull(thumbnail.pageIndex)
                        ?.isHidden
                        ?: thumbnail.isHidden
                    else -> thumbnail.isHidden
                },
                isReadingHere = thumbnail == readingHere,
                isListeningHere = thumbnail == listeningHere,

            )
        }.toTypedArray(),
    )
}
