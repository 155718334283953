package com.speechify.client.reader.epub

private val speechifyHorizontalPagesEpubCssConfig = """
:root {
	--Speechify__colWidth: 45em;
	--Speechify__colCount: 1;
	--Speechify__colGap: 0;
	--Speechify__viewportWidth: 100%
}

:root {
	position: relative;
	-webkit-column-width: var(--Speechify__colWidth);
	-moz-column-width: var(--Speechify__colWidth);
	column-width: var(--Speechify__colWidth);
	-webkit-column-count: var(--Speechify__colCount);
	-moz-column-count: var(--Speechify__colCount);
	column-count: var(--Speechify__colCount);
	-webkit-column-gap: var(--Speechify__colGap);
	-moz-column-gap: var(--Speechify__colGap);
	column-gap: var(--Speechify__colGap);
	-moz-column-fill: auto;
	column-fill: auto;
	width: var(--Speechify__viewportWidth);
	max-width: var(--Speechify__viewportWidth);
	min-width: var(--Speechify__viewportWidth);
	padding: 0 !important;
	margin: 0 !important;
	font-size: 100% !important;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
	-webkit-perspective: 1;
	-webkit-touch-callout: none
}
:root {
	--Speechify__maxMediaWidth: 100%;
	--Speechify__boxSizingMedia: border-box;
	--Speechify__boxSizingTable: border-box;
}

body {
	width: 100%;
	margin: 0 auto !important;
	box-sizing: border-box
}

@media screen and (min-width:60em),
screen and (min-device-width:36em) and (max-device-width:47em) and (orientation:landscape) {
	:root {
		--Speechify__colWidth: 20em;
		--Speechify__colCount: 2;
	}
}

@media screen and (min-width:60em),
screen and (min-device-width:36em) and (max-device-width:47em) and (orientation:landscape) {

	:root[style*="--USER__colCount: 1"],
	:root[style*="--USER__colCount: 2"],
	:root[style*="--USER__colCount:1"],
	:root[style*="--USER__colCount:2"] {
		-webkit-column-count: var(--USER__colCount);
		-moz-column-count: var(--USER__colCount);
		column-count: var(--USER__colCount)
	}

	:root[style*="--USER__colCount: 1"],
	:root[style*="--USER__colCount:1"] {
		--Speechify__colWidth: 100vw
	}

	:root[style*="--USER__colCount: 2"],
	:root[style*="--USER__colCount:2"] {
		--Speechify__colWidth: auto
	}
}



:root[style*="--USER__fontSize"] {
	font-size: var(--USER__fontSize) !important
}

img {
	object-fit: contain;
	width: auto;
	height: auto;
	max-width: var(--Speechify__maxMediaWidth);
	box-sizing: var(--Speechify__boxSizingMedia);
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid
}

/* Selection configuration */

:root {
    --USER__selectionBackgroundColor: 'auto';
}

::selection {
    background-color: var(--USER__selectionBackgroundColor);
}

/* Pagination configuration: vertical or horizontal */

:root {
    --USER__layoutPaginationOrientation: horizontal;
}

:root[style*="--USER__layoutPaginationOrientation: vertical"],
	:root[style*="--USER__layoutPaginationOrientation:vertical"] {
		--Speechify__colWidth: auto;
        --Speechify__colCount: auto;
	}

:root[style*="--USER__layoutPaginationOrientation: horizontal"],
	:root[style*="--USER__layoutPaginationOrientation:horizontal"] {
		--Speechify__colWidth: 45em;
	    --Speechify__colCount: 1;
	}

/* Text and Background Color*/

:root {
    --USER__textColor: 'auto';
    --USER__backgroundColor: 'auto';
}

body {
    color: var(--USER__textColor);
	background-color: var(--USER__backgroundColor);
}

/* Text Adjustments configs */
:root {
    --USER__lineSpacingInEmUnit: normal;
    --USER__letterSpacingInEmUnit: normal;
    --USER__wordSpacingInEmUnit: normal;
    --USER__horizontalMarginsInEmUnit: 0em;
    --USER__fontWeight: normal;
    --USER__textAlign: default;
}

body {
    line-height: var(--USER__lineSpacingInEmUnit);
    letter-spacing: var(--USER__letterSpacingInEmUnit);
    word-spacing: var(--USER__wordSpacingInEmUnit);
    padding: 0 var(--USER__horizontalMarginsInEmUnit);
    font-weight: var(--USER__fontWeight);
    text-align: var(--USER__textAlign);
    min-height: auto;
}

/* Apply text-align: inherit ONLY for valid values,
when default is passed as value it will take original from publisher's css */

:root[style*="--USER__textAlign: start"],
:root[style*="--USER__textAlign: end"],
:root[style*="--USER__textAlign: center"],
:root[style*="--USER__textAlign: justify"] {
    :is(p, h1, h2, h3, h4, h5, h6, span, div, ol, li) {
        text-align: inherit;
    }
}
""".trimIndent()

// JavaScript to inject a viewport meta and the [speechifyHorizontalPagesEpubCss] into the <head>
internal val uiAndPaginationConfigurations = """
            (function() {
                document.documentElement.style.setProperty('--USER__colCount', 'auto');
                document.documentElement.style.setProperty('--USER__fontSize', '150%');

                let meta = document.createElement('meta');
                meta.name = "viewport";
                meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no";
                document.head.appendChild(meta);

                let style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = `$speechifyHorizontalPagesEpubCssConfig`
                document.head.appendChild(style);
            })();
        """

internal val utilJavascriptFunctions = """
    function getNodePath(node) {
        const path = [];
        while (node) {
            const parent = node.parentNode;
            if (!parent || node.nodeName.toLowerCase() === "body") break; // body node reached

            // Find index of the current node among its siblings
            const index = Array.prototype.indexOf.call(parent.childNodes, node);
            path.unshift(index); // Add to the start of the array
            node = parent; // Move up to the parent node
        }
        return path;
    }

    function findNode(path) {
        let currentElement = window.document.body; // Start from the <body>.
        for (let i = 0; i < path.length; i++) {
            if (currentElement && currentElement.childNodes && currentElement.childNodes[path[i]]) {
                currentElement = currentElement.childNodes[path[i]];
            }
        }
        return currentElement;
    }

    // Function to update the position and polygon points of an existing view {topLeft, width, height, points}
    function filterOutRectsWithInnerRects(rects) {
        return rects.filter((rect, i) =>
            !rects.some((otherRect, j) =>
                i !== j && // Skip comparing the rect with itself
                otherRect.x >= rect.x &&
                otherRect.y >= rect.y &&
                otherRect.x + otherRect.width <= rect.x + rect.width &&
                otherRect.y + otherRect.height <= rect.y + rect.height
            )
        );
    }

    // Function to smooth out the vertical gap between rectangles
    function removeHorizontalGaps(rects) {
        // Loop through the list and check for vertical gaps
        for (let i = 0; i < rects.length - 1; i++) {
            let currentRect = rects[i];
            let nextRect = rects[i + 1];

            // Check if there's a vertical gap between the rectangles
            let verticalGap = nextRect.y - (currentRect.y + currentRect.height);

            // If there's a gap, move the next rectangle closer to the current one
            if (verticalGap > 0) {
                nextRect.y = currentRect.y + currentRect.height; // Adjust the y position of the next rect
                nextRect.height = nextRect.height + verticalGap;
            }
        }

        return rects;
    }

    // Function to group boxes by pages.
    function groupBoxesByPages(rects) {
        const pageWidth = window.innerWidth;
        const pageGroups = [];

        rects.forEach(rect => {
            const pageIndex = Math.floor((rect.x + window.scrollX) / pageWidth);
            if (!pageGroups[pageIndex]) {
                pageGroups[pageIndex] = [];
            }
            pageGroups[pageIndex].push(new Box(new Point(rect.left + window.scrollX, rect.top + window.scrollY), rect.width, rect.height));
        });
        return pageGroups.filter(Boolean);
    }

    // Get the bounding rectangles for the range of nodes.
    function getRects(startNode, endNode, charIndexStart, charIndexEnd) {
        function cleanTextContent(node) {
            // Since browsers do have well-defined "whitespace-collapsing" behavior between HTML -> rendered text,
            // we replace all tabs and newlines with a space (or remove them entirely with an empty string)
            // SDK already doing this in our WebPageStandardView see: [https://github.com/SpeechifyInc/multiplatform-sdk/blob/f07c0c9706cf14428db0455601afec43cdb05ea3/multiplatform-sdk/src/commonMain/kotlin/com/speechify/client/helpers/content/standard/html/WebPageStandardView.kt#L409-L420]
            var textContent = node.textContent.replace(/[\t\n]+/g, " ");

            // we tim start if the text is after a <br> tag. Similar to what SDK do in WebPageStandardView here [https://github.com/SpeechifyInc/multiplatform-sdk/blob/main/multiplatform-sdk/src/commonMain/kotlin/com/speechify/client/helpers/content/standard/html/WebPageStandardView.kt#L392-L392]
            if (node.previousSibling && node.previousSibling.nodeName.toLowerCase() === "br") {
                console.error('before', textContent);
                textContent = textContent.trimStart();
                console.error('after', textContent);
            }
            node.textContent = textContent;
        }

        function countLeadingNonBreakingSpaces(text) {
            const nbspChars = "\u00A0\u202F\u2007\u2060"; // Common NBSP.
            const regex = new RegExp(`^[${'$'}{nbspChars}]+`);
            const match = text.match(regex);
            return match ? match[0].length : 0;
        }

        function cleanupRectangles(rects) {
            let uniqueRects = [];

            rects.forEach((rect) => {
                // Ignore rectangles with zero width or height
                if (rect.width === 0 || rect.height === 0) return;

                let duplicate = uniqueRects.find((r) =>
                    (r.top === rect.top && r.bottom === rect.bottom && r.left <= rect.left && r.right >= rect.right)
                );

                if (duplicate) {
                    // Keep the rect with the smallest width and height
                    if (rect.width * rect.height < duplicate.width * duplicate.height) {
                        uniqueRects.splice(uniqueRects.indexOf(duplicate), 1, rect);
                    }
                } else {
                    uniqueRects.push(rect);
                }
            });

            return uniqueRects;
        }
        cleanTextContent(startNode);
        cleanTextContent(endNode);

        const range = document.createRange();

        let startIndex = Math.min(charIndexStart + countLeadingNonBreakingSpaces(startNode.textContent), startNode.textContent.length);
        let endIndex = Math.min(charIndexEnd + countLeadingNonBreakingSpaces(endNode.textContent), endNode.textContent.length);
        range.setStart(startNode, startIndex);
        range.setEnd(endNode, endIndex);

        const rectangles = [...range.getClientRects()];
        range.detach();
        return cleanupRectangles(rectangles);
    }

    // update sentence highlight color
    function updateWordHighlightColor(hexColor) {
        try {
            const div = document.getElementById('speechifyWordHighlight');
            const svg = div.querySelector("svg");
            const polygons = svg.querySelectorAll('polygon');
            polygons.forEach((polygon) => {
                polygon.setAttribute("fill", `${'$'}{hexColor}`);
                polygon.setAttribute("stroke", `${'$'}{hexColor}`);
            });
        } catch (error) {
            console.error("updateWordHighlightColor - An error occurred:", error);
        }
    }

    // update sentence highlight color
    function updateSentenceHighlightColor(hexColor) {
        try {
            const div = document.getElementById('speechifySentenceHighlight');
            const svg = div.querySelector("svg");
            const polygons = svg.querySelectorAll('polygon');
            polygons.forEach((polygon) => {
                polygon.setAttribute("fill", `${'$'}{hexColor}`);
                polygon.setAttribute("stroke", `${'$'}{hexColor}`);
            });
        } catch (error) {
            console.error("updateSentenceHighlightColor - An error occurred:", error);
        }
    }

    //clear word and Sentence Highlight
    function clearWordAndSentenceHighlight() {
        const wordHighlightSpan = document.getElementById('speechifyWordHighlight');
        if (wordHighlightSpan) {
            Object.assign(wordHighlightSpan.style, {
                width: `0px`,
                height: `0px`,
                overflow: "hidden",
            });
        }
        const sentenceHighlightDiv = document.getElementById('speechifySentenceHighlight');
        if (sentenceHighlightDiv) {
            Object.assign(sentenceHighlightDiv.style, {
                width: `0px`,
                height: `0px`,
                overflow: "hidden",
            });
        }
    }
""".trimIndent()

internal val wordHighlightScript: String = """
    function highlightWord(startNodePath, endNodePath, charIndexStart, charIndexEnd, hexColor) {
      try {
        const startNode = findNode(startNodePath);
        const endNode = findNode(endNodePath);

        if (!startNode || !endNode) {
          console.warn('highlightWord: Start or End element not found!');
          return null;
        }

        // Get bounding rectangles
        const rects = getRects(startNode, endNode, charIndexStart, charIndexEnd);
        let boxesByPage = groupBoxesByPages(rects);

        const span = document.getElementById('speechifyWordHighlight');
        if (!span) {
          console.warn(`highlightWord: Element with key speechifySentenceHighlight not found.`);
          return null;
        }

        // flatten the group of boxes.
        let flattenBoxes = boxesByPage.flat();

        const { minX, maxX, minY, maxY } = flattenBoxes.reduce(
          (acc, { minX, maxX, minY, maxY }) => ({
            minX: Math.min(acc.minX, minX),
            maxX: Math.max(acc.maxX, maxX),
            minY: Math.min(acc.minY, minY),
            maxY: Math.max(acc.maxY, maxY),
          })
        );

        const width = maxX - minX;
        const height = maxY - minY;
        // // Update div styles
        Object.assign(span.style, {
          left: `${'$'}{minX}px`,
          top: `${'$'}{minY}px`,
          width: `${'$'}{width}px`,
          height: `${'$'}{height}px`,
          transition: "left 0.1s, top 0.05s, width 0.05s",
        });

        // Update SVG attributes
        const svg = span.querySelector("svg");
        if (svg) {
          // remove all children
          svg.innerHTML = '';
          svg.setAttribute("width", width);
          svg.setAttribute("height", height);
          svg.setAttribute("viewBox", `0 0 ${'$'}{width} ${'$'}{height}`);
        }
        boxesByPage.forEach((boxes) => {
          const polygon = new Polygon(boxes);
          // Generate points string relative to the bounding box
          const points = polygon.vertices.reduce(
            (acc, curr) => acc + ` ${'$'}{curr.x-minX},${'$'}{curr.y-minY}`,
            ""
          ).trim();

          // Create the polygon element
          const polygonElement = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          Object.assign(polygonElement.style, {
            pointerEvents: "none",
          });
          polygonElement.setAttribute("fill", `${'$'}{hexColor}`);
          polygonElement.setAttribute("stroke", `${'$'}{hexColor}`);
          polygonElement.setAttribute("stroke-width", 2);
          polygonElement.setAttribute("stroke-linejoin", "round");
          polygonElement.setAttribute("points", points);

          // Append polygon to SVG and SVG to div
          svg.appendChild(polygonElement);
        });
      } catch (error) {
        console.error("highlightWord: - An error occurred:", error);
      }
    }
""".trimIndent()

internal val injectWordHighlightSpanScript = """
    (function() {
        const span = document.createElement("span");
        span.id = 'speechifyWordHighlight';
        Object.assign(span.style, {
            position: "absolute",
            left: `0px`,
            top: `0px`,
            height: `0px`,
            width: `0px`,
            pointerEvents: "none",
        });
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("width", 0);
        svg.setAttribute("height", 0);
        svg.setAttribute("viewBox", `-1 -1 2 2`);
        Object.assign(svg.style, {
            position: "absolute",
            mixBlendMode: "darken",
            pointerEvents: "none",
            left: "0px",
            top: "0px",
        });
        span.appendChild(svg);
        document.body.appendChild(span);
    })();
""".trimIndent()

internal val sentenceHighlightDivInjectionScript = """
    // Function to create the desired view
    (function() {
      // Create the outer div
      const div = document.createElement("div");
      div.id = 'speechifySentenceHighlight';
      Object.assign(div.style, {
        position: "absolute",
        left: `0px`,
        top: `0px`,
        height: `0px`,
        width: `0px`,
        pointerEvents: "none",
      });

      // Create the SVG element
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", 0);
      svg.setAttribute("height", 0);
      svg.setAttribute("viewBox", `-1 -1 2 2`);
      Object.assign(svg.style, {
        position: "absolute",
        mixBlendMode: "darken",
        pointerEvents: "none",
        left: "0px",
        top: "0px",
      });

      div.appendChild(svg);

      // Append the div to the body (or any specific container)
      document.body.appendChild(div);
    })();
""".trimIndent()

// JS version of MP SDK version of Polygon [com.speechify.client.helpers.features.Polygon]
val javascriptUtils = """

        const epsilon = 0.0001;

        class Point {
            constructor(x, y) {
                this.x = x;
                this.y = y;
            }

            distanceTo(other) {
                const xDiff = (other.x - this.x);
                const yDiff = (other.y - this.y);
                return Math.sqrt(xDiff ** 2 + yDiff ** 2);
            }
        }

        class Box {
            constructor(topLeft, width, height) {
                this.topLeft = topLeft;
                this.width = width;
                this.height = height;
                this.minX = topLeft.x;
                this.maxX = this.minX + width;
                this.minY = topLeft.y;
                this.maxY = this.minY + height;
            }
        }

        class Polygon {

            constructor(boxes) {
                this.vertices = this.computeBoundingPolygonVertices(this.smoothenRows(this.expand(boxes)));
            }

            get boundingBox() {
                if (this.vertices.length === 0) return new Box(new Point(0, 0), 0, 0);

                const minX = Math.min(...this.vertices.map(v => v.x));
                const maxX = Math.max(...this.vertices.map(v => v.x));
                const width = maxX - minX;

                const minY = Math.min(...this.vertices.map(v => v.y));
                const maxY = Math.max(...this.vertices.map(v => v.y));
                const height = maxY - minY;

                return new Box(new Point(minX, minY), width, height);
            }

            computeBoundingPolygonVertices(boxes) {
                const yCoords = this.allYCoords(boxes).sort((a, b) => a - b);
                let prevLeftCoord = 0;
                let prevRightCoord = 0;

                const points = [];
                yCoords.forEach((yCoord, index) => {
                    const leftCoord = this.minXCoord(yCoord, boxes);
                    const rightCoord = this.maxXCoord(yCoord, boxes);

                    if (index === 0) {
                        points.push(new Point(leftCoord, yCoord));
                        points.push(new Point(rightCoord, yCoord));
                    } else {
                        if (leftCoord !== prevLeftCoord) {
                            points.unshift(new Point(prevLeftCoord, yCoord));
                        }
                        points.unshift(new Point(leftCoord, yCoord));

                        if (rightCoord !== prevRightCoord) {
                            points.push(new Point(prevRightCoord, yCoord));
                        }
                        points.push(new Point(rightCoord, yCoord));
                    }

                    prevLeftCoord = leftCoord;
                    prevRightCoord = rightCoord;
                });
                return this.skippingCollinear(points).filter((point, index, self) =>
                    index === self.findIndex((p) => p === point)
                );
            }

            skippingCollinear(points) {
                const result = [...points];
                let i = 0;

                while (i < result.length - 1) {

                    if (this.areCollinear(this.tripleAt(result, i))) {
                        result.splice(i, 1);
                    } else {
                        i++;
                    }
                }

                return result;
            }

            tripleAt(points, index){
                const previous = index === 0 ? points.length - 1 : index - 1;
                const next = (index + 1) % points.length;
                return [points[previous], points[index], points[next]];
            }

            expand(boxes) {
                return boxes.map(it => new Box(new Point(it.topLeft.x - 1, it.topLeft.y - 1), it.width + 2, it.height + 2));
            }

            smoothenRows(boxes){
                const grouped = boxes.reduce((acc, box) => {
                    const key = `${'$'}{box.minY}|${'$'}{box.maxY}`;
                    if (!acc[key]) acc[key] = [];
                    acc[key].push(box);
                    return acc;
                }, {});

                return Object.values(grouped).map(rowBoxes => {
                    const minX = Math.min(...rowBoxes.map(b => b.minX));
                    const maxX = Math.max(...rowBoxes.map(b => b.maxX));
                    const minY = Math.min(...rowBoxes.map(b => b.minY));
                    const maxY = Math.max(...rowBoxes.map(b => b.maxY));
                    return new Box(new Point(minX, minY), maxX - minX, maxY - minY);
                });
            }

            allYCoords(boxes) {
                return boxes
                    .flatMap(box => [box.minY, box.maxY]);
            }

            minXCoord(y, boxes) {
                const rectangles = this.rectanglesAt(y, boxes);
                return rectangles.length > 0
                    ? Math.min(...rectangles.map(rect => rect.minX))
                    : 0;
            }

            maxXCoord(y, boxes) {
                const rectangles = this.rectanglesAt(y, boxes);
                return rectangles.length > 0
                    ? Math.max(...rectangles.map(rect => rect.maxX))
                    : 0;
            }

            rectanglesAt(y, boxes) {
                const boxesExcludingBottomLines = this.boxesExcludingBottomLinesAt(y, boxes);

                if (boxesExcludingBottomLines.length === 0) {
                    // There are only rectangle bottom lines so we need to consider them.
                    return this.boxesIncludingBottomLinesAt(y, boxes);
                } else {
                    // There are rectangles that are not closing here, so ignore those that are closing.
                    return boxesExcludingBottomLines;
                }
            }

            boxesExcludingBottomLinesAt(y, boxes) {
                return boxes.filter(box =>
                    box.minY <= y && box.maxY > y + epsilon
                );
            }

            boxesIncludingBottomLinesAt(y, boxes) {
                return boxes.filter(box =>
                    box.minY <= y && Math.abs(box.maxY - y) < epsilon
                );
            }

            areCollinear(points) {
                if (points.length !== 3) throw new Error("Only 3 points can be checked for colinearity");

                const areaOfTriangle = points[0].x * (points[1].y - points[2].y) +
                    points[1].x * (points[2].y - points[0].y) +
                    points[2].x * (points[0].y - points[1].y);

                return Math.abs(areaOfTriangle) < epsilon;
            }
        }
""".trimIndent()

internal val highlightSentenceScript = """
    function highlightSentence(startNodePath, endNodePath, charIndexStart, charIndexEnd, hexColor) {
      try {
        const startNode = findNode(startNodePath);
        const endNode = findNode(endNodePath);

        if (!startNode || !endNode) {
          console.warn('Start or End element not found!');
          return null;
        }

        // Get bounding rectangles
        const rects = getRects(startNode, endNode, charIndexStart, charIndexEnd);
        let boxesByPage = groupBoxesByPages(rects);

        const div = document.getElementById('speechifySentenceHighlight');
        if (!div) {
          console.warn(`Sentence highlight Element with key speechifySentenceHighlight not found.`);
          return null;
        }

        // flatten the group of boxes.
        let flattenBoxes = boxesByPage.flat();

        const { minX, maxX, minY, maxY } = flattenBoxes.reduce(
          (acc, { minX, maxX, minY, maxY }) => ({
            minX: Math.min(acc.minX, minX),
            maxX: Math.max(acc.maxX, maxX),
            minY: Math.min(acc.minY, minY),
            maxY: Math.max(acc.maxY, maxY),
          })
        );

        const width = maxX - minX;
        const height = maxY - minY;
        // // Update div styles
        Object.assign(div.style, {
          left: `${'$'}{minX}px`,
          top: `${'$'}{minY}px`,
          width: `${'$'}{width}px`,
          height: `${'$'}{height}px`,
        });

        // Update SVG attributes
        const svg = div.querySelector("svg");
        if (svg) {
          // remove all children
          svg.innerHTML = '';
          svg.setAttribute("width", width + 2);
          svg.setAttribute("height", height + 2);
          svg.setAttribute("viewBox", `-1 -1 ${'$'}{width + 2} ${'$'}{height + 2}`);
        }
        boxesByPage.forEach((boxes) => {
          const polygon = new Polygon(boxes);
          // Generate points string relative to the bounding box
          const points = polygon.vertices.reduce(
            (acc, curr) => acc + ` ${'$'}{curr.x-minX},${'$'}{curr.y-minY}`,
            ""
          ).trim();

          // Create the polygon element
          const polygonElement = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          Object.assign(polygonElement.style, {
            pointerEvents: "none",
          });
          polygonElement.setAttribute("fill", `${'$'}{hexColor}`);
          polygonElement.setAttribute("stroke", `${'$'}{hexColor}`);
          polygonElement.setAttribute("stroke-width", 2);
          polygonElement.setAttribute("stroke-linejoin", "round");
          polygonElement.setAttribute("points", points);

          // Append polygon to SVG and SVG to div
          svg.appendChild(polygonElement);
        });
      } catch (error) {
        console.error("HighlightSentence - An error occurred:", error);
      }
    }
""".trimIndent()

internal val clickEventListenerJSScript = """
    function getPathToTextNode(event) {
        let clickedNode = event.target;

        // If the clicked node isn't a TEXT_NODE, get the closest one using a range
        if (clickedNode.nodeType !== Node.TEXT_NODE) {
            const range = document.caretRangeFromPoint(event.clientX, event.clientY);
            if (range && range.startContainer.nodeType === Node.TEXT_NODE) {
                clickedNode = range.startContainer;
            } else {
                clickedNode = null; // No valid text node found
            }
        }

        if (clickedNode) {
            const path = getNodePath(clickedNode);
            return path;
        } else {
            return [];
        }
    }

    function getCharIndex(event) {
        const range = document.caretRangeFromPoint(event.clientX, event.clientY);
        if (!range) return 0;
        return range.startOffset;
    }

    document.addEventListener('click', function(event) {
        if (hasTextSelection) {
            hasTextSelection = false;
            event.preventDefault();
            return null;
        }
        let element = event.target;
        // ignore href links
        let path = getPathToTextNode(event);
        if (path.length === 0) {
            event.preventDefault();
            return null;
        }
        let charIndex = getCharIndex(event);
        let data = {
                    "nodePathExcludingHtmlAndBody": path,
                    "charIndex": charIndex,
                };
        let message = {
                "action": 'TapToPlay',
                "data": data,
            };
        window.Speechify.postMessage(JSON.stringify(message));
        event.preventDefault();
    });
""".trimIndent()

internal val selectionJSScript = """
     var hasTextSelection = false;
     document.addEventListener('selectionchange', (e) => {
         const selection = window.getSelection();
         hasTextSelection = selection && selection.toString().length > 0;
         if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
           const selectedText = selection.toString();
           if (selectedText) {
                const anchorNodePath =  getNodePath(selection.anchorNode);
                const focusNodePath =  getNodePath(selection.focusNode);
                let anchor = {
                    "nodePathExcludingHtmlAndBody": anchorNodePath,
                    "charIndex": selection.anchorOffset,
                };
                let focus = {
                    "nodePathExcludingHtmlAndBody": focusNodePath,
                    "charIndex": selection.focusOffset,
                };
                let data = {
                    "anchor": anchor,
                    "focus": focus,
                };
                let message = {
                    "action": 'OnSelectionChanged',
                    "data": data,
                };
                Speechify.postMessage(JSON.stringify(message));
           }
         } else {
            Speechify.postMessage(JSON.stringify(
                 {
                    "action": 'ClearSelection'
                 }
            ));
         }
       });
""".trimIndent()

internal val scrollUtilJSScripts = """
    function scrollToEpubLocation(nodePath, charIndex) {
      try {
        const pagination = getComputedStyle(document.documentElement)
            .getPropertyValue('--USER__layoutPaginationOrientation').trim();
        const isHorizontalPagination = pagination === 'horizontal';
        // skip scrolling to an element if pagination is not Horizontal.
        if (!isHorizontalPagination) return null;

        const textNode = findNode(nodePath);
        if (!textNode || textNode.nodeType !== Node.TEXT_NODE) {
          return null;
        }
        if (charIndex < 0 || charIndex >= textNode.length) {
          return null;
        }

        const viewportWidth = window.innerWidth;
        const range = document.createRange();
        range.setStart(textNode, charIndex);
        range.setEnd(textNode, charIndex + 1);

        const rect = range.getBoundingClientRect();
        range.detach(); // Clean up the range to prevent memory leaks

        const elementLeft = rect.left + window.scrollX;

        // Calculate the page number (0-based index)
        const pageIndex = Math.floor(elementLeft / viewportWidth);

        // Scroll to the page
        window.scrollTo({
          left: pageIndex * viewportWidth,
          behavior: 'smooth'
        });
      } catch (error) {
        console.warn("scrollToEpubLocation - An error occurred:", error);
      }
    }

    function scrollToNextPage() {
        const pagination = getComputedStyle(document.documentElement)
            .getPropertyValue('--USER__layoutPaginationOrientation').trim();
        const isHorizontalPagination = pagination === 'horizontal';
        if (!isHorizontalPagination) return null;
        const viewportWidth = window.innerWidth;
        const elementLeft = window.scrollX;
        if ((viewportWidth + elementLeft) >= window.document.scrollingElement.scrollWidth) {
            let message = {
                "action": 'GoToNextChapter'
            };
            window.Speechify.postMessage(JSON.stringify(message));
        } else {
            // Calculate the page number (0-based index)
            const pageIndex = Math.round(elementLeft / viewportWidth);
            // Scroll to the page
            smoothScrollToX((pageIndex + 1) * viewportWidth, 250);
        }
    }

    function scrollToPreviousPage() {
        const pagination = getComputedStyle(document.documentElement)
            .getPropertyValue('--USER__layoutPaginationOrientation').trim();
        const isHorizontalPagination = pagination === 'horizontal';
        if (!isHorizontalPagination) return null;
        const viewportWidth = window.innerWidth;
        const elementLeft = window.scrollX;
        if (elementLeft <= 0) {
          let message = {
            "action": 'GoToPreviousChapter'
          };
          window.Speechify.postMessage(JSON.stringify(message));
        } else {
            // Calculate the page number (0-based index)
            const pageIndex = Math.round(elementLeft / viewportWidth);
            // Scroll to the page
            smoothScrollToX((pageIndex - 1) * viewportWidth, 250);
        }
    }

    function smoothScrollToX(targetX, duration) {
      const startX = window.scrollX; // Current scroll position
      const distance = targetX - startX; // Distance to scroll
      let startTime = null;

      function step(timestamp) {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1); // Cap progress at 1

        // Ease-in-out function
        const ease = 0.5 - Math.cos(progress * Math.PI) / 2;

        window.scrollTo(startX + distance * ease, 0);

        if (elapsed < duration) {
          requestAnimationFrame(step); // Continue animation
        }
      }

      requestAnimationFrame(step);
    }

    // This function is to calculate a normalized y position of epubLocation.
    // This is useful for vertical pagination where we have webview laid out to match its content,
    // thus we give clients back the normalized y postion to scroll to it, similar to what we have for
    // FixedLayoutBookReader.
    function getNormalizedTextOverlayTopYAndHeightFromEpubLocation(nodePath, charIndex) {
        try {
            const textNode = findNode(nodePath);
            if (!textNode || textNode.nodeType !== Node.TEXT_NODE) {
                return null;
            }
            if (charIndex < 0 || charIndex >= textNode.length) {
                return null;
            }

            const range = document.createRange();
            range.setStart(textNode, charIndex);
            range.setEnd(textNode, charIndex + 1);

            const rect = range.getBoundingClientRect();
            range.detach(); // Clean up the range to prevent memory leaks

            const contentHeight = document.documentElement.scrollHeight;
            // Normalize the scroll position, ensuring it's between 0 and 1
            let epubTextOverlayTopYAndHeight = {
                normalizedTextOverlayTopYPosition: Math.max(0, Math.min(1, rect.top / contentHeight)),
                normalizedTextOverlayHeight: Math.max(0, Math.min(1, rect.height / contentHeight)),
            }
            return epubTextOverlayTopYAndHeight;
        } catch (error) {
            console.warn("getNormalizedTextOverlayTopYAndHeightFromEpubLocation - An error occurred:", error);
            return null;
        }
    }
""".trimIndent()

internal val selectionHighlightDivInjectionScript = """
    // Function to create the desired view
    (function() {
      // Create the outer div
      const div = document.createElement("div");
      div.id = 'speechifySelectionHighlight';
      Object.assign(div.style, {
        position: "absolute",
        left: `0px`,
        top: `0px`,
        height: `0px`,
        width: `0px`,
        transitionProperty: "left, width",
        transitionDuration: "0.05s",
        pointerEvents: "none",
      });

      // Create the SVG element
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", 202);
      svg.setAttribute("height", 52);
      svg.setAttribute("viewBox", `-1 -1 202 52`);
      Object.assign(svg.style, {
        position: "absolute",
        mixBlendMode: "darken",
        pointerEvents: "none",
      });

      // Create the polygon element
      const polygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
      Object.assign(polygon.style, {
        pointerEvents: "none",
      });
      polygon.setAttribute("fill", "red");
      polygon.setAttribute("stroke", "red");
      polygon.setAttribute("fill-opacity", "0.5");
      polygon.setAttribute("stroke-opacity", "0.5");
      polygon.setAttribute("stroke-width", 2);
      polygon.setAttribute("stroke-linejoin", "round");
      polygon.style.transition = "all 0.05s ease"; // Added transition for smooth animation

      // Append polygon to SVG and SVG to div
      svg.appendChild(polygon);
      div.appendChild(svg);

      // Append the div to the body (or any specific container)
      document.body.appendChild(div);
    })();
""".trimIndent()

internal val selectionHighlightScript = """
    function highlightSelection(startNodePath, endNodePath, charIndexStart, charIndexEnd) {
      try {
        const startNode = findNode(startNodePath);
        const endNode = findNode(endNodePath);

        if (!startNode || !endNode) {
          console.warn('highlightSelection - Start or End element not found!');
          return null;
        }

        const range = document.createRange();
        range.setStart(startNode, charIndexStart);
        range.setEnd(endNode, charIndexEnd);
        const rects = filterOutRectsWithInnerRects(Array.from(range.getClientRects()));

        const selectionPolygon = new Polygon(
          Array.from(rects).map((rect) =>
            new Box(new Point(rect.left + window.scrollX, rect.top + window.scrollY), rect.width, rect.height)
          )
        );
        const { topLeft, width, height } = selectionPolygon.boundingBox;
        const points = selectionPolygon.vertices.reduce(
          (acc, curr) => acc + ` ${'$'}{curr.x - topLeft.x},${'$'}{curr.y - topLeft.y}`,
          ""
        );
        const div = document.getElementById('speechifySelectionHighlight');
        if (!div) {
          console.warn(`Selection highlight Element with key speechifySentenceHighlight not found.`);
          return null;
        }

        // Update div styles
        Object.assign(div.style, {
          left: `${'$'}{topLeft.x}px`,
          top: `${'$'}{topLeft.y}px`,
          width: `${'$'}{width}px`,
          height: `${'$'}{height}px`,
        });

        // Update SVG attributes
        const svg = div.querySelector("svg");
        if (svg) {
          svg.setAttribute("width", width + 2);
          svg.setAttribute("height", height + 2);
          svg.setAttribute("viewBox", `-1 -1 ${'$'}{width + 2} ${'$'}{height + 2}`);
        }

        // Update polygon points
        const polygon = div.querySelector("polygon");
        if (polygon) {
          polygon.setAttribute("points", points);
        }
      } catch (error) {
        console.warn("highlightSelection - An error occurred:", error);
      }
    }
""".trimIndent()

val getFocusAndAnchorCoordinatesOfCurrentSelectionScript = """
function getSelectionCoordinates() {
   const selection = window.getSelection();
   if (!selection.rangeCount) return null;

   const range = selection.getRangeAt(0);
   const rects = range.getClientRects();

   if (rects.length === 0) return null;

   const anchorRect = rects[0];
   const focusRect = rects[rects.length - 1];
   let message = {
      anchor: {
         x: anchorRect.left,
         y: anchorRect.top
      },
      focus: {
         x: focusRect.right,
         y: focusRect.bottom
      }
   }
   return message;
}
""".trimIndent()

internal val createUserHighlightDivElementScript = """
    // Function to create the desired view
    function createUserHighlightDivElement(highlightId) {
        // Create the outer div
        const div = document.createElement("div");
        div.id = highlightId;
        Object.assign(div.style, {
            position: "absolute",
            left: `0px`,
            top: `0px`,
            height: `0px`,
            width: `0px`,
            pointerEvents: "none",
        });
        // Append the div to the body (or any specific container)
        document.body.appendChild(div);
        return div;
    }
""".trimIndent()

internal val userHighlightsScript = """
function setOrUpdateUserHighlight(
    highlightId,
    startNodePath,
    endNodePath,
    charIndexStart,
    charIndexEnd,
    colorHexCode
) {
    const startNode = findNode(startNodePath);
    const endNode = findNode(endNodePath);

    if (!startNode || !endNode) {
        console.warn('userHighlight - Start or End element not found!');
        return null;
    }

    const range = document.createRange();
    range.setStart(startNode, charIndexStart);
    range.setEnd(endNode, charIndexEnd);
    const rects = filterOutRectsWithInnerRects(Array.from(range.getClientRects()));
    const smoothenRects = removeHorizontalGaps(rects);
    let div = document.getElementById(highlightId);
    if (!div) {
        div = createUserHighlightDivElement(highlightId)
    }
    // remove all children
    div.innerHTML = '';
    smoothenRects.forEach(rect => {
        // Create highlight element for each rect
        const highlight = document.createElement('div');
        Object.assign(highlight.style, {
            position: "absolute",
            mixBlendMode: "darken",
            pointerEvents: "none",
            backgroundColor: colorHexCode,
            left: rect.left + 'px',
            top: rect.top + 'px',
            width: rect.width + 'px',
            height: rect.height + 'px',
        });
        div.appendChild(highlight)
    });
}
""".trimIndent()
