package com.speechify.client.internal.services.subscription

import com.speechify.client.api.services.subscription.models.Coupon
import com.speechify.client.api.services.subscription.models.CouponPlanType
import com.speechify.client.api.services.subscription.models.CouponType
import com.speechify.client.internal.services.subscription.models.PlatformCoupon

internal object PlatformCouponTransformer {
    fun toCoupon(platformCoupon: PlatformCoupon): Coupon? = with(platformCoupon) {
        val planTypeName: CouponPlanType = when (planType) {
            "monthly" -> CouponPlanType.MONTHLY
            "annual" -> CouponPlanType.YEARLY
            "all" -> CouponPlanType.ALL
            else -> CouponPlanType.ALL
        }
        if (amountOff == null) {
            Coupon(planTypeName, CouponType.Percentage, percentOff ?: return null)
        } else {
            if (percentOff != null) {
                return null
            } else {
                Coupon(planTypeName, CouponType.Amount, amountOff)
            }
        }
    }
}
