package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow

/**
 * A convenience factory for creating a [MutableSharedFlow]-equivalent of a Channel with
 * [kotlinx.coroutines.channels.Channel.CONFLATED] capacity.
 *
 * You can use [MutableSharedFlow.tryEmit] on this flow, and it will always succeed (always return true).
 *
 * This flow is similar to [kotlinx.coroutines.flow.MutableStateFlow] in that it does not remember previous values, but
 * this one doesn't remember any value - it sends them to any listening collectors (if any) and forgets.
 * NOTE: Consequently, unlike [kotlinx.coroutines.flow.MutableStateFlow], this flow will always send a value to current
 * collectors, even if it's the same as the previous one.
 */
@Suppress("FunctionName")
internal fun <T> MutableSharedFlowConflatedEmittableSynchronously(): MutableSharedFlow<T> =
    MutableSharedFlow(
        /* `0` replay and `RENDEZVOUS` to achieve #IgnoreIfNoOneIsListeningToMutations */
        replay = 0,
        /** Need to give `extraBufferCapacity = 1` and use `DROP_OLDEST`. Using `0` is only allowed in `SUSPEND` but,
         for some reason when it's used the items put into [MutableSharedFlow.tryEmit] don't reach even the currently
         listening collectors. Using `1` is fine though, because `extraBufferCapacity` also gets forgotten if there
         are no subscribers (so it doesn't act as a replay). */
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST,
    )
