package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.SentenceAndWordLocation
import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.ValueWithStringRepresentation
import com.speechify.client.api.content.slice
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.extensions.collections.windowedToBatchesOfAimedSizeSum
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.intentSyntax.alsoIfNull
import com.speechify.client.internal.util.text.groupingToSentences.internal.sentenceTerminators.sentenceTerminationSpanDefinition
import com.speechify.client.internal.util.text.groupingToWords.getWordsWithPunctuationAsIndexRanges
import kotlinx.coroutines.flow.Flow
import kotlin.js.JsExport

/**
 * A sentence of speakable text. This text was likely transformed from the original content, but you can trust that the [text] field has retained the ability to map each character back to a [ContentCursor] in the original content, and vice-versa.
 *
 * Note: you are guaranteed that a SpeechSentence is a syntactically valid, speakable "sentence". For now, this means:
 * 1. The text ends with a sentence-terminating punctuation mark.
 */
@Suppress("DataClassPrivateConstructor")
@JsExport
data class SpeechSentence private constructor(
    val text: ContentText,
) :
    ValueWithStringRepresentation {
    val start: ContentCursor = text.start
    val end: ContentCursor = text.end
    override val textRepresentation: String
        get() = text.textRepresentation

    fun replaceAll(
        pattern: String,
        replacement: (match: String) -> String,
    ) = fromText(text.replaceAll(pattern, replacement))

    private fun getWordsWithPunctuation(
        from: ContentCursor,
        to: ContentCursor,
    ): Sequence<ContentText> =
        getWordsWithPunctuationAsIndexRanges(
            from = from,
            to = to,
        )
            .map { range ->
                text.slice(range)
            }

    private fun getWordsWithPunctuationAsIndexRanges(
        from: ContentCursor,
        to: ContentCursor,
    ): Sequence<IntRange> {
        val fromIndex = text.getFirstIndexOfCursor(from)

        return text.text.getWordsWithPunctuationAsIndexRanges(
            fromIndex = fromIndex,
            toIndexInclusive = run {
                val toCursorIndex = text.getLastIndexOfCursor(to)
                /* Quirks due to #ContentTextEndCursorIsAtLastCharacter :
                 * TODO some of below decisions may be undesired, but the code preserves the original behavior,
                 *  which is guarded by tests
                 **/

                /** If [from] and [to] point at the same character, due to #ContentTextEndCursorIsAtLastCharacter this
                 * is ambiguous especially for the case of a one-letter-text, but to preserve existing behavior is to
                 * return no words (even if there's actually a 1-letter word in the string).
                 */
                if (toCursorIndex == fromIndex) return@getWordsWithPunctuationAsIndexRanges emptySequence<IntRange>()

                if (toCursorIndex == text.text.lastIndex) {
                    /** If [to] points at the last character, to preserve existing behavior is to include the last
                     * character so that the last word is also returned (there is no other way to request end of
                     * the string).
                     */
                    return@run toCursorIndex
                } else {
                    /** If [to] doesn't point at the last character, to preserve existing behavior is to
                     * treat the index pointed by cursor as 'exclusive', so to give inclusive we need a  `-1` from that.
                     */
                    return@run toCursorIndex - 1
                }
            },
        )
    }

    fun withRemovedContentOfMetadata(enrichment: TextEnrichment): SpeechSentence? {
        return text.slices.filterNot { it.metadata.textEnrichments.contains(enrichment) }.nullIf { isEmpty() }?.let {
            fromTextWithoutEnforcingTerminator(ContentTextUtils.concat(it))
        }
    }

    internal fun wordCount(from: ContentCursor, to: ContentCursor): Int =
        getWordsWithPunctuationAsIndexRanges(from, to).count()

    internal fun words(from: ContentCursor, to: ContentCursor): List<ContentText> =
        getWordsWithPunctuation(from, to)
            .toList()

    /**
     * NOTE: There are different edge-cases in this function, with different behavior:
     * - when the cursor is on whitespace between the words, the `OrFirstAfter` behavior
     *   applies, so the first word after whitespace will be returned (whether this ever happens is unknown - ideally,
     *   speech synthesis should be reporting locations that aren't whitespace).
     * - If the cursor is at the end of the sentence, the last word of the sentence will be returned.
     * - If the cursor isn't within the sentence, `null` will be returned.
     */
    internal fun getWordWithPunctuationAtCursorOrFirstAfter(cursor: ContentCursor): ContentText? {
        var lastSeenWord: ContentText? = null

        for (word in getWordsWithPunctuationSequence()) {
            if (word.end.isAfterOrAt(cursor)) {
                return word
            }
            lastSeenWord = word
        }

        // If we got here, the cursor is either at the end of the sentence, or not within the sentence at all.

        if (lastSeenWord != null && lastSeenWord.end.isEqual(cursor)) {
            return lastSeenWord
        }

        return null
    }

    internal fun getWordsWithPunctuationSequence(): Sequence<ContentText> =
        text.text.getWordsWithPunctuationAsIndexRanges()
            .map { range ->
                text.slice(range)
            }

    /**
     * Get a new [SpeechSentence] containing only content between the provided [start] and [end] (inclusive).
     */
    internal fun slice(start: ContentCursor, end: ContentCursor): SpeechSentence? =
        /* `EnforcingTerminator` may not be needed by every use of this function - it's done to preserve the original
         behavior */
        fromTextWithEnforcingTerminator(
            text.slice(
                startIndex = text.getFirstIndexOfCursor(start),
                endIndex = text.getLastIndexOfCursor(end) + 1,
            ),
        )

    /**
     * Split this [SpeechSentence] into multiple [SpeechSentence]s, each containing at most [maxCharsCount] characters.
     */
    internal fun splitOnMaxCharsCount(maxCharsCount: Int): Sequence<SpeechSentence> = sequence {
        fun sliceLocal(startIndex: Int, endIndexExclusive: Int): SpeechSentence =
            /** [fromTextWithoutEnforcingTerminator] is used to avoid complexity of exceeding `maxCharsCount` and
             contradiction with rendered text.
             Also, there may be benefit of 'forced split' being distinguishable for any consumer of this value
             (e.g. synthesis may choose not to apply a sentence-ending tone).
             */
            fromTextWithoutEnforcingTerminator(text.slice(startIndex, endIndexExclusive))!!

        if (text.length < maxCharsCount) { // Optimization and simplification to preserve the same instance
            yield(this@SpeechSentence)
            return@sequence
        }

        var currentIndexExclusive = 0
        while (currentIndexExclusive < text.length) {
            val attemptedEndIndexExclusive = currentIndexExclusive + maxCharsCount
            if (attemptedEndIndexExclusive >= text.length) {
                // Reached the end already, so we don't need to correct for whitespace. Just yield the rest and exit
                yield(
                    sliceLocal(
                        startIndex = currentIndexExclusive,
                        endIndexExclusive = text.length,
                    ),
                )
                break
            } else {
                // This isn't yet the end, so let's try to prevent splitting in the middle of a word

                val lastWhitespaceIdx = text.text.subSequence(
                    startIndex = 0,
                    endIndex = attemptedEndIndexExclusive,
                ).indexOfLast { it.isWhitespace() }.nullIf { this < 0 }
                if (lastWhitespaceIdx == null) {
                    // No whitespace found at all (very unusual - long text with no whitespace), so split at the limit
                    yield(
                        sliceLocal(
                            startIndex = currentIndexExclusive,
                            endIndexExclusive = attemptedEndIndexExclusive,
                        ),
                    )
                    currentIndexExclusive = attemptedEndIndexExclusive
                } else {
                    yield(
                        sliceLocal(
                            startIndex = currentIndexExclusive,
                            endIndexExclusive = lastWhitespaceIdx,
                        ),
                    )
                    currentIndexExclusive = lastWhitespaceIdx + 1 // Don't repeat the whitespace
                }
            }
        }
    }

    companion object {
        fun fromText(text: ContentText): SpeechSentence? =
            /* `EnforcingTerminator` may not be needed by every use of this function - it's done to preserve the
             original behavior */
            fromTextWithEnforcingTerminator(text)

        fun fromTextWithEnforcingTerminator(text: ContentText): SpeechSentence? {
            if (text.length == 0) return null
            return SpeechSentence(text.withDefaultSentenceTerminator("."))
        }

        fun fromTextWithoutEnforcingTerminator(text: ContentText): SpeechSentence? {
            if (text.length == 0) return null
            return SpeechSentence(text)
        }
    }
}

/**
 * NOTE: This function is opinionated with regard to whether punctuation should be part of the location in the content:
 * The punctuation *is* included in the specified bounds, and that is including the [SentenceAndWordLocation.word]
 * (which means that "So, it's done." will indicate words as: `So,` `it's` `done.`").
 *
 * NOTE: Not-found behaviors (when the [cursor] is not on one of the sentences/words):
 * - Returns `null` if no [SpeechSentence] of [this]' collection is at or after [cursor].
 *   The situation will be logged with [DiagnosticEvent.sourceAreaId] of [logNotFoundSourceAreaId]
 * - Returns `null` in [SentenceAndWordLocation.word] if no word of the sentence is at or after [cursor].
 *   The situation will be logged with [DiagnosticEvent.sourceAreaId] of [logNotFoundSourceAreaId]
 */
internal fun Iterable<SpeechSentence>.getSentenceAndWordLocationInContentAtCursorOrFirstAfter(
    cursor: ContentCursor,
    /**
     * The [DiagnosticEvent.sourceAreaId] to use when logging that the sentence/word wasn't found.
     */
    logNotFoundSourceAreaId: String,
    logNotFoundExtraProperties: (() -> Map<String, Any>)? = null,
): SentenceAndWordLocation? =
    firstOrNull {
        it.text.end.isAfterOrAt(cursor)
    }
        ?.let { sentenceWithCursor ->
            SentenceAndWordLocation(
                sentence = sentenceWithCursor.text,
                word = sentenceWithCursor.getWordWithPunctuationAtCursorOrFirstAfter(
                    cursor = cursor,
                )
                    .alsoIfNull {
                        Log.e(
                            DiagnosticEvent(
                                sourceAreaId = logNotFoundSourceAreaId,
                                message = "Sentence was found, but none of its words has content on or after {cursor}.",
                                properties = mapOf(
                                    *logNotFoundExtraProperties?.invoke()?.entries?.map { it.toPair() }?.toTypedArray()
                                        ?: emptyArray(),
                                    "sentenceText" to sentenceWithCursor.text.textRepresentation,
                                    "utteranceCursorIndex" to sentenceWithCursor.text
                                        .getFirstIndexOfCursor(cursor),
                                    "cursor" to cursor,
                                ),
                            ),
                        )
                    },
            )
        }
        .alsoIfNull {
            Log.e(
                DiagnosticEvent(
                    sourceAreaId = logNotFoundSourceAreaId,
                    message = "No sentence has content on or after {cursor}.",
                    properties = mapOf(
                        *logNotFoundExtraProperties?.invoke()?.entries?.map { it.toPair() }?.toTypedArray()
                            ?: emptyArray(),
                        "cursor" to cursor,
                    ),
                ),
            )
        }

/**
 * @param aimedCharsCountInBatch - the length may end up smaller or larger - see doc of [windowedToBatchesOfAimedSizeSum].
 */
internal fun Flow<SpeechSentence>.windowedToBatchesOfAimedCharsCountSum(aimedCharsCountInBatch: Int) =
    windowedToBatchesOfAimedSizeSum(
        itemSize = { sentence -> sentence.text.length },
        aimedSizeSumInEachBatch = aimedCharsCountInBatch,
    )

internal fun ContentText.withDefaultSentenceTerminator(terminator: String): ContentText {
    return if (sentenceTerminationSpanDefinition.isTerminatorAtEndOf(this.text)) {
        this
    } else {
        ContentTextUtils.Format.concatFillerTerminatorIfNotEmpty(this, terminatorIfNotEmpty = terminator)
    }
}
