package com.speechify.client.internal.coroutines

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext

/** Use to run a new Job, so that it won't be cancelled and any uncaught errors land in
 * the current context's [kotlinx.coroutines.CoroutineExceptionHandler], as well as preventing hangs
 * of any outer `coroutineScope` when the value has not resolved yet.
 * Effectively, this does not respect structured concurrency.
 **/
internal fun CoroutineContext.launchTopLevelJobWithSameContext(
    /**
     * Give a name so that any exceptions can be distinguished when reported (will be part of `coroutineContext[Job].toString()`)
     **/
    coroutineName: String,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
) {
    val newTopLevelJob = Job()

    CoroutineScope(
        this +
            CoroutineName(
                coroutineName,
            ) +
            newTopLevelJob,
    ).launch(
        start = start,
    ) {
        try {
            block()
        } catch (e: CancellationException) {
            newTopLevelJob.cancel(e)
        }

        /* The block managed to resolve or the result was already propagated - clean-up the
         `resultHandlingParentJob` for completeness (though it's probably going to be garbage-collected because all
          references to it should be lost from here on)
         */
        newTopLevelJob.complete()
    }
}
