package com.speechify.client.api.audio.caching

import com.speechify.client.api.audio.SynthesizeResponse
import com.speechify.client.api.content.JoinedTextWithMap
import com.speechify.client.api.content.ValueWithStringRepresentation
import com.speechify.client.internal.util.collections.flows.SharedFlowThatFinishes
import com.speechify.client.internal.util.extensions.collections.BatchTransformResultWithRealignment
import com.speechify.client.internal.util.extensions.collections.allSublistsFromAllItemsToSingleFirstItem
import com.speechify.client.internal.util.extensions.collections.flows.firstNotNullOfOrNull
import com.speechify.client.internal.util.extensions.collections.flows.sharePullingOnlyWhenNeededIn
import com.speechify.client.internal.util.extensions.collections.subListByRemovingCountAtStart
import com.speechify.client.internal.util.extensions.coroutines.coroutineScopeForSingleExpression
import com.speechify.client.internal.util.extensions.strings.getStartingIndexIfEndsWithOrNull
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.flatMapConcat
import kotlinx.coroutines.flow.map

internal abstract class MediaSynthesisCache<
    JoinedSentencesType : ValueWithStringRepresentation,
    SentenceType : ValueWithStringRepresentation,
    > {

    protected abstract suspend fun getEntriesContainingSentence(
        sentenceText: String,
    ): Flow<CacheEntry>?

    protected interface CacheEntry {
        val joinedText: String
        suspend fun getSynthesisResult(): SynthesizeResponse
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    suspend fun getCachedEntryOrNull(
        inputSentencesAndWhitespaceSeparators: JoinedTextWithMap<JoinedSentencesType, SentenceType>,
    ): BatchTransformResultWithRealignment<SentenceType, SynthesizeResponse>? = coroutineScopeForSingleExpression {
        /* Optimization: get the utterances containing the sentence only once */
        val potentialUtterancesStartingAtInput: SharedFlowThatFinishes<CacheEntry> = getEntriesContainingSentence(
            sentenceText = inputSentencesAndWhitespaceSeparators.constituentParts.first().textRepresentation,
        )
            /* Using `sharePullingOnlyWhenNeededIn` to lazily explore the entries, but cache whatever was found
             * for each iteration (`sharePullingOnlyWhenNeededIn` has unlimited capacity, so just acts as a memoization
             * of the items up to the point of exploration).
             */
            ?.sharePullingOnlyWhenNeededIn(
                scope = this@coroutineScopeForSingleExpression,
            )
            ?: return@coroutineScopeForSingleExpression null

        return@coroutineScopeForSingleExpression inputSentencesAndWhitespaceSeparators
            .mapOfConstituentPartsToJoinedText
            /** Testing `allSublists`, by cutting from the end, because even if we find a cache entry with the input's
             *  starting sentence, we still need to see if it has the ending which ends where the input ends, because
             * of #LimitationOfMediaFilesCutFromStartCausingGlitches.
             * Notably, this also means that due to inability to cut from the end, we can't reuse a cache entry that has
             * more content after the requested window, and consequently can render download-audio unusable if the
             * window is ever reduced with respect to what it was when the audio was downloaded.
             * TODO This could be solved by (#TODOPersistentCacheBecomesUnusableIfAimedCharsCountInBatchIsReduced):
             *  - making the [com.speechify.client.api.audio.SingleSpecsMediaSynthesisService.synthesizingColdFlow] not
             *    be limited by the window cut using [com.speechify.client.internal.util.extensions.collections.windowedToBatchesOfAimedSizeSumWithMapAndRealignment]
             *    but rather allow the window to be 'pulled', and thus reaching the end of the cache entry.
             *    This can be achieved by developing something more advanced [com.speechify.client.internal.util.extensions.collections.windowedToBatchesOfAimedSizeSumWithMapAndRealignment]
             *    where the content gets pulled to reach `aimedCharsCountInBatch`, but can be pulled more.
             *  - persisting the window with the audio download and specifying it for the synthesis.
             *    - Downside is that it would be rather easy to break.
             *  - solving the inability to cut at the end.
             *    - Downside would be producing a lot of utterances that need to be stitched, likely producing
             *    audible glitches.
             */
            .allSublistsFromAllItemsToSingleFirstItem()
            .asFlow()
            .flatMapConcat { inputSubListOfSentences ->
                potentialUtterancesStartingAtInput
                    .map { synthesisResult ->
                        inputSubListOfSentences to synthesisResult
                    }
            }.firstNotNullOfOrNull { (inputSubListOfSentences, cacheEntryWithSentence) ->
                val lastIndexInCurrentTriedInputTextPartInclusive =
                    inputSubListOfSentences.last().rangeInWhole.last

                cacheEntryWithSentence.joinedText.getStartingIndexIfEndsWithOrNull(
                    /* So we now have a `cacheEntryWithSentence`, and we need
                        to discard it if it doesn't have the same ending (again, because of
                        #LimitationOfMediaFilesCutFromStartCausingGlitches - we can
                        only reuse a cache entry if it ends with the input). This is the reason we here
                        check `AtEnd` only, and not `Contains` or anything else.
                     */
                    expectedEnding = inputSentencesAndWhitespaceSeparators
                        .joinedText
                        .textRepresentation
                        .substring(
                            startIndex = 0,
                            endIndex = lastIndexInCurrentTriedInputTextPartInclusive + 1,
                        ),
                )?.let { startingIndexInCachedEntry ->
                    /* This means a cache hit now - we have the same ending.
                       Now just need to cut the entry accordingly (unless it's an `ExactMatch`).
                     */
                    val synthesisResult = cacheEntryWithSentence.getSynthesisResult()
                    if (
                        startingIndexInCachedEntry == 0 &&
                        inputSentencesAndWhitespaceSeparators.joinedText.textRepresentation.lastIndex ==
                        cacheEntryWithSentence.joinedText.lastIndex
                    ) {
                        BatchTransformResultWithRealignment.ExactMatch(
                            result = synthesisResult,
                        )
                    } else {
                        BatchTransformResultWithRealignment.MatchOfShorterInput(
                            result = synthesisResult.copy(
                                speechMarks = synthesisResult.speechMarks.slice(
                                    startIndex = startingIndexInCachedEntry,
                                    endIndex =
                                    cacheEntryWithSentence
                                        .joinedText.length,
                                    /* `length` because here also, because
                                     #LimitationOfMediaFilesCutFromStartCausingGlitches we never
                                     cut at the end - we go to the very end */
                                ),
                            ),
                            inputItemsConsumedInThisBatch = inputSubListOfSentences
                                .map { it.constituentPart },
                            remainingUnmatchedItems = inputSentencesAndWhitespaceSeparators
                                .constituentParts.subListByRemovingCountAtStart(
                                    inputSubListOfSentences.size,
                                ),
                        )
                    }
                }
            }
    }

    abstract suspend fun putCachedEntry(
        inputSentencesAndWhitespaceSeparators: JoinedTextWithMap<JoinedSentencesType, SentenceType>,
        newSynthesisResult: SynthesizeResponse,
    )
}

internal class ActualSentence(override val textRepresentation: String) : ValueWithStringRepresentation
