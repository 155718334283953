package com.speechify.client.api.audio

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentStartAndEndCursors
import com.speechify.client.api.content.view.speech.CursorQuery
import com.speechify.client.api.content.view.speech.PlaybackNavigationToContentTranslator
import com.speechify.client.api.content.view.speech.SpeechFlowProvider
import com.speechify.client.api.content.view.speech.SpeechView
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.toSDKError
import com.speechify.client.helpers.audio.controller.AudioControllerCommand
import com.speechify.client.helpers.audio.controller.root.RootActor
import com.speechify.client.internal.actor.Actor
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.runTask
import com.speechify.client.internal.util.extensions.collections.flows.BufferObserver
import com.speechify.client.internal.util.extensions.collections.sendToUnlimited
import com.speechify.client.internal.util.extensions.collections.trySendEnsuringLoggingOfFailure
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.callbackFlow
import kotlin.js.JsExport

private typealias VoiceTransformer = (Voice) -> Voice

/**
 * The AudioController is the engine of the listening experience. It is designed to be lightweight and efficient for large content, and provides an expressive Cursor-oriented navigation API.
 */
@JsExport
class AudioController private constructor(
    speechFlowProvider: SpeechFlowProvider,
    private val playbackNavigationToContentTranslator: PlaybackNavigationToContentTranslator,
    /**
     * Note: This is parametrized to support custom TTS sources, but in the normal scenario is backed by
     * [UtteranceFlowProviderFromSpeechFlow].
     * TODO: Unify all [UtteranceFlowProvider]s into [UtteranceFlowProviderFromSpeechFlow] and remove this parameter
     *  by making [com.speechify.client.api.content.view.speech.SpeechFlow] more expressive, and able to convey what is
     *  necessary by those providers (e.g. different voices for different parts, including prerecorded audio, etc.).
     *  This will make [speechFlowProvider] the main source of content.
     *  #TODOUnifyUtteranceFlowProviders
     */
    utteranceFlowProvider: UtteranceFlowProvider,
    /**
     * The cursors delimiting the whole content traversed by the [UtteranceFlowProvider].
     * TODO - consider not requiring them, and just use the value of [com.speechify.client.api.content.ContentElementReference.forRoot].
     *  #ConsiderNotRequiringStartAndEndCursorsOfWhole
     */
    startAndEndCursorsOfWhole: ContentStartAndEndCursors,
    initialOptions: AudioControllerOptions,
    voicesOfPreferenceStateProvider: VoicesOfPreferenceStateProvider,
    voiceFactoryFromSpec: VoiceFactoryFromSpec,
    scope: CoroutineScope,
) {
    /**
     * A convenience constructor from a [SpeechView].
     */
    internal constructor(
        speechView: SpeechView,
        /**
         * Note: This is parametrized to support custom TTS sources, but in the normal scenario is backed by
         * [UtteranceFlowProviderFromSpeechFlow].
         * TODO: Unify all [UtteranceFlowProvider]s into [UtteranceFlowProviderFromSpeechFlow] and remove this parameter
         *  by making [com.speechify.client.api.content.view.speech.SpeechFlow] more expressive, and able to convey what is
         *  necessary by those providers (e.g. different voices for different parts, including prerecorded audio, etc.).
         *  #TODOUnifyUtteranceFlowProviders
         */
        utteranceFlowProvider: UtteranceFlowProvider,
        initialOptions: AudioControllerOptions,
        voicesOfPreferenceStateProvider: VoicesOfPreferenceStateProvider,
        voiceFactoryFromSpec: VoiceFactoryFromSpec,
        scope: CoroutineScope,
    ) : this(
        playbackNavigationToContentTranslator = speechView,
        speechFlowProvider = speechView,
        utteranceFlowProvider = utteranceFlowProvider,
        startAndEndCursorsOfWhole = speechView,
        initialOptions = initialOptions,
        voicesOfPreferenceStateProvider = voicesOfPreferenceStateProvider,
        voiceFactoryFromSpec = voiceFactoryFromSpec,
        scope = scope,
    )

    private val bufferEventsMutable =
        @JsExport.Ignore
        object : UtteranceBufferEvents, BufferObserver<Utterance> {

            // A shared flow because the initial value doesn't come in until the first [onItemAddedToBuffer]
            // event is invoked.
            private val furthestBufferItemFlowMutable = MutableSharedFlow<Utterance>()

            override suspend fun onItemAddedToBuffer(item: Utterance) {
                furthestBufferItemFlowMutable.emit(item)
            }

            override val furthestBufferItemFlow: SharedFlow<Utterance>
                get() = furthestBufferItemFlowMutable
        }

    internal val bufferEvents: UtteranceBufferEvents = bufferEventsMutable

    internal val actor = RootActor.createStarted(
        speechFlowProvider = speechFlowProvider,
        utteranceFlowProvider = utteranceFlowProvider,
        startAndEndCursorsOfWhole = startAndEndCursorsOfWhole,
        initialOptions = initialOptions,
        coroutineScope = scope,
        /* TODO - Consider:
             * not having any replays but just the buffer, making events once observed no longer observable.
               This is so that there is a way to not get duplicate events on a sequence of
               `addEventListener()` -> `destroy the listener` -> `addEventListener()`
               Currently it's pretty surprising. See #ActorsSubscribeGetReplays for related places in code
               * but having an infinite buffer, so still no events are lost
             * or just switch to a flow-style API, because a breaking change like this is almost impossible
               to communicate, or find out if there is any reliance on it. We really have it coming
               in many places, that a _boundary_ version of 'SharedFlow' (which is hot, by definition, so
               moment of subscription matters) would help a lot, so the natural thing to do is to finally
               write one, and replace little critters like above with such flows, while adding @Deprecated
               attribute pointers.
         */
        bufferAndReplaySize = Actor.DEFAULT_BUFFER_AND_REPLAY_SIZE,
        voicesOfPreferenceStateProvider = voicesOfPreferenceStateProvider,
        voiceFactoryFromSpec = voiceFactoryFromSpec,
        bufferObserver = bufferEventsMutable,
    )

    val isPlaying: Boolean
        get() = actor.isPlaying

    /**
     * The voice to prefer for this document. If `null`, the preferences from [com.speechify.client.bundlers.listening.VoicePreferences]
     * is used.
     */
    val voiceOfPreferenceOverride: VoiceSpecOfAvailableVoice?
        get() = actor.voiceOfPreferenceOverrideMutable.value

    fun destroy() {
        /** TODO - refactor to make the destruction happen in the component responsible for creating it
         *   ([com.speechify.client.bundlers.content.ContentBundle] should perhaps lose the
         *   [com.speechify.client.bundlers.content.ContentBundle.speechView] and it should be created
         *   in the [com.speechify.client.bundlers.listening.ListeningBundle] where it could be destroyed together
         *   with the [AudioController]. Or if it's required there, then it shouldn't be destroyed here)
         */
        this.playbackNavigationToContentTranslator.destroy()
        try {
            runTask { actor.stop() }
        } catch (cancellationException: CancellationException) {
            // no op , we neither want to propagate this to client nor in analytics
        }
    }

    /**
     * NOTE: If you unsubscribe and subscribe again, the new subscription will get duplicate events because there
     * is a replay of an internally decided number of events.
     */
    // SDK DEVS NOTE: See #ActorsSubscribeGetReplays for code related to replays mentioned in KDoc (and where the replay is decided)
    fun addEventListener(
        callback: CallbackNoError<AudioControllerEvent>,
    ): Destructor =
        this.actor.subscribe(callback)

    internal fun coAddEventListener(callback: suspend (AudioControllerEvent) -> Unit) = this.actor.coSubscribe(callback)

    /**
     * Use [playFromCursor] if you already have the cursor.
     */
    fun play(cursorQuery: CursorQuery, onResolvedCursor: ((ContentCursor) -> Unit)? = null) = launchTask { // launchTask is needed because cursor resolution is `suspend`
        val cursor = cursorQuery.resolveToCursorOrReportError()
        onResolvedCursor?.invoke(cursor)
        playFromCursor(cursor)
    }.ignoreValue()

    /**
     * A simpler variant of [play] to go directly to a cursor.
     */
    fun playFromCursor(cursor: ContentCursor) =
        sendWithLogging(
            audioControllerCommand = AudioControllerCommand.Play(
                cursor = cursor,
            ),
        )

    /**
     * Use [seekToCursor] if you already have a cursor.
     */
    fun seek(cursorQuery: CursorQuery, onResolvedCursor: ((ContentCursor) -> Unit)? = null) = launchTask { // launchTask is needed because cursor resolution is `suspend`
        val cursor = cursorQuery.resolveToCursorOrReportError()
        onResolvedCursor?.invoke(cursor)
        seekToCursor(cursor)
    }.ignoreValue()

    /**
     * A simpler variant of [seek] to go directly to a cursor.
     */
    fun seekToCursor(cursor: ContentCursor) =
        sendWithLogging(
            audioControllerCommand = AudioControllerCommand.Seek(
                cursor = cursor,
            ),
        )

    /**
     * A simpler variant of [seek] to go directly to a cursor.
     */
    fun seekToCursorWithoutImplyingUserInteraction(cursor: ContentCursor) {
        sendWithLogging(
            audioControllerCommand = AudioControllerCommand.Seek(
                cursor = cursor,
                impliesUserInteraction = false,
            ),
        )
    }

    fun pause() = sendWithLogging(AudioControllerCommand.Pause)

    fun resume() = sendWithLogging(AudioControllerCommand.Resume)

    fun setSpeed(speedInWordsPerMinute: Int) =
        sendWithLogging(AudioControllerCommand.SetSpeed(speedInWordsPerMinute))

    fun setVoice(
        availableVoice: VoiceSpecOfAvailableVoice,
    ) =
        sendWithLogging(
            AudioControllerCommand.SetVoice(
                voice = availableVoice,
            ),
        )

    private fun sendWithLogging(audioControllerCommand: AudioControllerCommand) {
        /** TODO - try to ensure that SDK consumers don't crash if we throw here, and just use
         *  [com.speechify.client.internal.util.extensions.collections.sendEnsuringReceivedOrBuffered].
         */
        actor.trySendEnsuringLoggingOfFailure(
            item = audioControllerCommand,
            sourceAreaId = "AudioController.commandSend",
            shouldLogItem = true,
        )
    }

    private suspend fun CursorQuery.resolveToCursorOrReportError(): ContentCursor {
        val cursor = try {
            playbackNavigationToContentTranslator.getCursor(query = this).orThrow()
        } catch (e: Exception) {
            try {
                actor.emitErrorEvent(AudioControllerEvent.Errored(e.toSDKError()))
            } catch (exceptionWhileEmittingEvents: Throwable) {
                e.addSuppressed(exceptionWhileEmittingEvents)
            }
            throw e
        }
        return cursor
    }
}

/**
 * NOTE: Each subsequent collection of the flow will have some events from the previous flow due
 * to #ActorsSubscribeGetReplays
 */
internal fun AudioController.getEventsColdFlow(): Flow<AudioControllerEvent> = callbackFlow {
    val removeListener = this@getEventsColdFlow.addEventListener {
        sendToUnlimited(it)
    }

    awaitClose {
        removeListener()
    }
}.buffer(capacity = Channel.UNLIMITED)

internal interface UtteranceBufferEvents {
    /**
     * A way to expose the furthest buffer item inside a buffered flow of Utterances to consumers, while
     * encapsulating read and write operations done by the [BufferObserver]. See [AudioController.bufferEventsMutable]
     * for a usage of this.
     */
    val furthestBufferItemFlow: SharedFlow<Utterance>
}
