package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.wrapSyncCall
import kotlin.js.JsExport
import kotlin.js.JsName

typealias FirebaseFieldValue = Any

@JsExport
interface FirebaseFieldValueAdapter {
    /** This needs to return a firebase.firestore.FieldValue.increment or platform specific equivalent. */
    @JsName("increment")
    fun increment(amount: Int): FirebaseFieldValue
}

internal fun FirebaseFieldValueAdapter.traced() =
    if (Log.isDebugLoggingEnabled) FirebaseFieldValueAdapterTraced(this) else this

internal class FirebaseFieldValueAdapterTraced(private val firebaseFieldValueAdapter: FirebaseFieldValueAdapter) :
    FirebaseFieldValueAdapter {
    override fun increment(amount: Int): FirebaseFieldValue {
        return wrapSyncCall({ "[$it] FirebaseFieldValueAdapter.increment($amount)" }) {
            firebaseFieldValueAdapter.increment(amount)
        }
    }
}
