package com.speechify.client.api.services.logging

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.boundary.BoundaryMap
import kotlin.js.JsExport

/**
 * Service exposed for SDK consumers for logging diagnostic information such as errors and informational messages.
 *
 * @constructor Creates an instance of [LoggingService]. This constructor is internal and should only
 * be accessed within the SDK to ensure controlled creation of this service.
 * Consumers can access the instance via [SpeechifyClient.loggingService].
 */
@JsExport
class LoggingService internal constructor() {

    /**
     * Logs an error event with the specified details. This includes the name of the event,
     * optional properties that provide additional context, an optional exception, and an
     * optional source area ID to identify the source of the error. All error logs are also forwarded to telemetry.
     *
     * It is recommended to use concise, descriptive names for events and capture detailed context in `properties`,
     * avoiding the embedding of context through string interpolation. This method enhances the clarity and
     * searchability of our logging system, improving error tracking.
     *
     * @param name The name of the event to log. String-interpolated or overly verbose names are not recommended. This is a required parameter.
     * @param properties An optional [BoundaryMap] of properties providing additional context
     * about the error. Defaults to null.
     * @param exception An optional [Throwable] representing the error that occurred. Defaults to null.
     * @param sourceAreaId An optional string identifying the area in the client where the error
     * originated. This is a required parameter.
     */
    fun logError(
        name: String,
        properties: BoundaryMap<Any>? = null,
        exception: Throwable? = null,
        sourceAreaId: String,
    ) {
        Log.e(
            diagnosticEvent(
                name = name,
                properties = properties,
                exception = exception,
                sourceAreaId = sourceAreaId,
            ),
        )
    }

    /**
     * Logs an informational event with the specified details. Similar to [logError], it allows
     * logging of events with a name, optional properties for additional context, an optional
     * exception, and an optional source area ID. To enable telemetry logging for informational events,
     *  the [ClientOptions.enableSendingInfoDiagnosticLogsToTelemetry] function must be invoked explicitly.
     *
     * It is recommended to use concise, descriptive names for events and capture detailed context in `properties`,
     * avoiding the embedding of context through string interpolation. This method enhances the clarity and
     * searchability of our logging system, improving error tracking.
     *
     * @param name The name of the event to log. String-interpolated or overly verbose names are not recommended. This is a required parameter.
     * @param properties An optional [BoundaryMap] of properties providing additional context
     * about the error. Defaults to null.
     * @param exception An optional [Throwable] representing the error that occurred. Defaults to null.
     * @param sourceAreaId An optional string identifying the area in the client where the error
     * originated. This is a required parameter.
     */
    fun logInfo(
        name: String,
        properties: BoundaryMap<Any>? = null,
        exception: Throwable? = null,
        sourceAreaId: String,
    ) {
        Log.i(
            diagnosticEvent(
                name = name,
                properties = properties,
                exception = exception,
                sourceAreaId = sourceAreaId,
            ),
        )
    }

    private fun diagnosticEvent(
        name: String,
        properties: BoundaryMap<Any>?,
        exception: Throwable?,
        sourceAreaId: String,
    ) = DiagnosticEvent(
        message = "client_$name",
        error = exception?.let { ErrorInfoForDiagnostics(nativeError = it) },
        sourceAreaId = "client_$sourceAreaId",
        properties = properties,
    )
}
