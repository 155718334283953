package com.speechify.client.bundlers.reading.book

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.view.book.OCRRequirementEvent
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * Everything you need to deliver a Book Original Mode reading experience.
 */
@JsExport
class BookReadingBundle internal constructor(
    dependencies: Dependencies,
    bundlingSourceTelemetryEventBuilder: TelemetryEventBuilder?,
    bundleMetadata: BundleMetadata?,
    predecessorBundle: ReadingBundle? = null,
) : ReadingBundle(
    dependencies = dependencies,
    bundlingSourceTelemetryEventBuilder = bundlingSourceTelemetryEventBuilder,
    bundleMetadata = bundleMetadata,
    predecessorBundle = predecessorBundle,
) {
    // Override to narrow down to `ContentBundle.BookBundle`
    override val content: ContentBundle.BookBundle =
        listeningBundle.contentBundle as? ContentBundle.BookBundle
            ?: throw RuntimeException(
                "Unsupported content bundle type ${listeningBundle.contentBundle::class.simpleName}",
            )

    // Override to narrow down to non-nullable
    override val bookContent: ContentBundle.BookBundle
        get() = content

    @JsName("getRegionsOfInterestForPage")
    fun getRegionsOfInterestForPage(pageIndex: Int, callback: Callback<Array<BoundingBox>>) = callback.fromCo() {
        when (content.bookView) {
            is EditingBookView -> content.bookView.bookEdits.pages[pageIndex].regions
            else -> emptyArray()
        }.successfully()
    }

    fun addBookPageOCRRequirementListener(callback: Callback<OCRRequirementEvent>): Destructor = callback
        .multiShotFromFlowIn(
            flow = bookContent.bookPageOCRRequirementFlow.map {
                OCRRequirementEvent(
                    speechifyURI = contentImporter.state.speechifyURIIfReadyOrNull,
                    bookPageIndex = it.first,
                    numberOfPages = bookContent.bookView.getMetadata().numberOfPages,
                ).successfully()
            },
            scope = dependencies.scope,
        )::destroy
}

private val ContentImporterState.speechifyURIIfReadyOrNull: SpeechifyURI?
    get() = when (this) {
        is ContentImporterState.ImportedToLibrary -> this.uri
        is ContentImporterState.Importing -> this.libraryItem.uri
        else -> null
    }
