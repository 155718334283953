// ktlint-disable filename

/* Extensions grouped into this file apply to any scalar values and collections alike */

package com.speechify.client.internal.util.extensions.intentSyntax

import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract

/**
 * For marking an expression as intentionally unused, e.g. when it's a value that's only used for its side effects.
 *
 * Especially useful to be able to use `=` expression functions syntax where the underlying expression returns a
 * non-[Unit].
 */
@Suppress("UnusedReceiverParameter")
internal fun <Val> Val.ignoreValue(): Unit = Unit

internal inline fun <Val> Val.nullIf(predicateOnThis: Val.() -> Boolean): Val? =
    this.let { if (it.predicateOnThis()) null else it }

/**
 * A variant of [nullIf] for values already nullable, where the check needs to be on non-null.
 */
internal inline fun <Val> Val?.nullIfNotNullAnd(predicateOnThis: Val.() -> Boolean): Val? =
    this?.let { if (it.predicateOnThis()) null else it }

@OptIn(ExperimentalContracts::class)
internal inline fun <Val> Val?.isNotNullAnd(predicateOnThis: Val.() -> Boolean): Boolean {
    contract {
        returns(true) implies (this@isNotNullAnd !== null)
    }

    return this?.let { it.predicateOnThis() } ?: false
}

internal inline fun <reified Val, reified TypeToCheck : Val> Val.isOfTypeAnd(
    predicate: (value: TypeToCheck) -> Boolean,
): Boolean =
    this is TypeToCheck && predicate(this)

@OptIn(ExperimentalContracts::class)
internal inline fun <Val> Val?.isNullOr(predicateOnThis: Val.() -> Boolean): Boolean {
    contract {
        returns(false) implies (this@isNullOr !== null)
    }

    return this == null || this.let { it.predicateOnThis() }
}

/**
 * Leaves [this] intact if [param] is null.
 * Projects it with [block], if [param] is not null (in the [block] [param] is the `it`, while [this] is still this)
 */
internal fun <Value, Param> Value.runIfParamNotNullOrLeave(
    param: Param?,
    block: Value.(param: Param) -> Value,
): Value =
    if (param == null) {
        this
    } else {
        this.block(param)
    }

/**
 * Similar to [runIfParamNotNullOrLeave], but for when there's no extra information to use in the [transform]
 * (so it's implemented with the `it` first parameter, like a [let], and not the `this` first parameter, like a [run])
 */
internal inline fun <Value> Value.letIfTrueOrLeave(
    shouldTransform: Boolean,
    transform: (value: Value) -> Value,
): Value =
    if (!shouldTransform) {
        this
    } else {
        transform(this)
    }

/**
 * Can be used to workaround Kotlin's lack of [self type](https://discuss.kotlinlang.org/t/self-types/371), e.g. for
 * 'builder' pattern with inheritance and common logic in base class (another workaround could be writing the base logic
 * in extension methods with generic `this`, but such extension methods don't get exported to JS).
 */
internal inline fun <Val, RealVal : Val> Val.applyWithCast(crossinline block: RealVal.() -> Unit): RealVal {
    @Suppress("UNCHECKED_CAST")
    this as RealVal
    this.block()
    return this
}

/**
 * A convenience function to implement equivalence check for nullable values.
 */
internal inline fun <T> T?.isEquivalentBy(
    other: T?,
    isEquivalentNotNull: T.(T) -> Boolean,
) =
    if (this == null && other == null) {
        true
    } else if (this == null || other == null) {
        false
    } else {
        this.isEquivalentNotNull(other)
    }
