package com.speechify.client.internal.services.library.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebasePageItem(
    val storagePath: String? = null,
    val index: Int? = null,
    val createdAt: DateTime = DateTime.fromSeconds(-1),
    val updatedAt: DateTime = DateTime.fromSeconds(-1),
)
