package com.speechify.client.bundlers

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.imageConversion.ImageConverter
import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.adapters.pdf.PDFDocumentAdapter
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.pdf.PDFBookPageFactoryImpl
import com.speechify.client.api.content.pdf.PDFBookView
import com.speechify.client.api.content.scannedbook.ScannedBookBookPageFactory
import com.speechify.client.api.content.scannedbook.ScannedBookBookView
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.services.scannedbook.models.ScannedBook
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.bundlers.content.IsBookPageContentEmpty
import com.speechify.client.bundlers.reading.book.BookReadingBundlerOptions
import com.speechify.client.bundlers.reading.importing.ContentPostImportActionsManager
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.services.book.PlatformMLParsedBookPageService
import com.speechify.client.internal.services.ml.BookPageMLParsingWithRemoteOCRService
import com.speechify.client.internal.services.ml.MLPageParsingWithRemoteOCRService
import com.speechify.client.internal.services.ocr.BookPageOCRFallbackService
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.userDocumentSettings.UserProfileService
import com.speechify.client.internal.util.image.platformPreferredImageFormat
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.StateFlow

internal class BookViewFactory(
    private val bookReadingBundlerOptions: BookReadingBundlerOptions,
    private val ocrAdapter: OCRAdapter,
    private val scannedBookService: PlatformScannedBookService,
    private val clientConfig: ClientConfig,
    private val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
    private val platformMLParsedBookPageService: PlatformMLParsedBookPageService,
    private val imageConverter: ImageConverter,
    private val mlPageParsingWithRemoteOCRService: MLPageParsingWithRemoteOCRService,
    private val mlParsingModeFlow: StateFlow<MLParsingMode>,
    private val userProfileService: UserProfileService,
) {
    fun createFromPdf(
        source: PDFDocumentAdapter,
        postImportActionsManager: ContentPostImportActionsManager,
        bookPageOCRRequirementFlow: MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>>,
        ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy>,
    ): BookView {
        val bookPageOCRFallbackService = BookPageOCRFallbackService(
            postImportActionsManager = postImportActionsManager,
            ocrAdapter = ocrAdapter,
            scannedBookService = scannedBookService,
            clientConfig = clientConfig,
            firebaseStorageCache = firebaseStorageCache,
            bookPageOCRRequirementFlow = bookPageOCRRequirementFlow,
            convertImage = { image, targetQualityPercent ->
                imageConverter.coConvertToWithQualityPercent(
                    inputImage = image,
                    imageFormat = platformPreferredImageFormat,
                    targetQualityPercent = targetQualityPercent,
                )
            },
            userProfileService = userProfileService,
        )

        val bookPageMLParsingWithRemoteOCRService = BookPageMLParsingWithRemoteOCRService(
            platformMLParsedBookPageService = platformMLParsedBookPageService,
            mlPageParsingWithRemoteOCRService = mlPageParsingWithRemoteOCRService,
            postImportActionsManager = postImportActionsManager,
            convertImage = { image, targetQualityPercent ->
                imageConverter.coConvertToWithQualityPercent(
                    inputImage = image,
                    imageFormat = platformPreferredImageFormat,
                    targetQualityPercent = targetQualityPercent,
                )
            },
            scannedBookService = scannedBookService,
            bookPageOCRRequirementFlow = bookPageOCRRequirementFlow,
            minimalSDKVersionToClearMLParsingCaches = bookReadingBundlerOptions.minimalSDKVersionToClearMLParsingCaches,
            userProfileService = userProfileService,
        )

        return PDFBookView(
            adapter = source,
            pdfBookPageFactory = PDFBookPageFactoryImpl(
                contentSortingStrategy = bookReadingBundlerOptions.contentSortingStrategy,
                mlParsingModeFlow = mlParsingModeFlow,
                bookPageOCRFallbackService = bookPageOCRFallbackService,
                bookPageMLParsingWithRemoteOCRService = bookPageMLParsingWithRemoteOCRService,
                ocrFallbackStrategyFlow = ocrFallbackStrategyFlow,
            ),
            mlParsingModeFlow = mlParsingModeFlow,
            ocrFallbackStrategyFlow = ocrFallbackStrategyFlow,
        )
    }

    fun createFromScannedBook(
        source: ScannedBook,
        postImportActionsManager: ContentPostImportActionsManager,
    ): BookView {
        val bookPageMLParsingWithRemoteOCRService = BookPageMLParsingWithRemoteOCRService(
            platformMLParsedBookPageService = platformMLParsedBookPageService,
            mlPageParsingWithRemoteOCRService = mlPageParsingWithRemoteOCRService,
            postImportActionsManager = postImportActionsManager,
            convertImage = { image, targetQuality ->
                imageConverter.coConvertToWithQualityPercent(
                    inputImage = image,
                    imageFormat = platformPreferredImageFormat,
                    targetQualityPercent = targetQuality,
                )
            },
            scannedBookService = scannedBookService,
            bookPageOCRRequirementFlow = MutableSharedFlow(),
            minimalSDKVersionToClearMLParsingCaches = bookReadingBundlerOptions.minimalSDKVersionToClearMLParsingCaches,
            userProfileService = userProfileService,
        )
        return ScannedBookBookView(
            scannedBook = source,
            scannedBookBookPageFactory = ScannedBookBookPageFactory(
                bookPageMLParsingWithRemoteOCRService = bookPageMLParsingWithRemoteOCRService,
                mlParsingModeFlow = mlParsingModeFlow,
            ),
            mlParsingModeFlow = mlParsingModeFlow,
        )
    }
}
