package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.Deferred

/**
 * A map of 'pending items', which does not store completed items, so it's good for adding thread-safety to a cache
 * without making it grow indefinitely, and thus letting cache be added to higher layers where semantics of the items
 * are known, and thus it's possible to determine if caching needed and with what strategy.
 */
internal interface ThreadSafeMapOfPendingItemsOnly<K, V> :
    MapRetrievalIncludingPending<K, V>,
    MutableMapInsertionOrFailureAsync<K, V>

internal fun <K, V> threadSafeMapOfPendingItemsOnly(): ThreadSafeMapOfPendingItemsOnly<K, V> =
    object : ThreadSafeMapOfPendingItemsOnly<K, V> {
        private val pendingWritesBackingMap: ThreadSafeMutableMapAsyncOperations<K, V> = LockingThreadsafeMap()
        override suspend fun add(key: K, value: suspend () -> V) =
            pendingWritesBackingMap.add(
                key = key,
                value = {
                    try {
                        value()
                    } finally {
                        pendingWritesBackingMap.removeIncludingPending(key)
                            .ignoreValue()
                    }
                },
            )

        override fun getIncludingPending(key: K): Deferred<V>? =
            pendingWritesBackingMap.getIncludingPending(key)
    }

/**
 * A 'pending-items-only' version of [LockingThreadsafeMapBy].
 */
internal fun <KRich, KWithSemanticEqual, Value> threadSafeMapOfPendingItemsOnlyBy(
    getEquatableKey: (keyRich: KRich) -> KWithSemanticEqual,
): ThreadSafeMapOfPendingItemsOnly<KRich, Value> =
    object : ThreadSafeMapOfPendingItemsOnly<KRich, Value> {
        private val backingMap = threadSafeMapOfPendingItemsOnly<KWithSemanticEqual, Value>()
        override fun getIncludingPending(key: KRich): Deferred<Value>? =
            backingMap.getIncludingPending(getEquatableKey(key))

        override suspend fun add(key: KRich, value: suspend () -> Value): Value =
            backingMap.add(
                key = getEquatableKey(key),
                value = value,
            )
    }
