package com.speechify.client.internal.services.importing.models

/**
 * Represents a summary of content, including an optional excerpt and textual statistics.
 */
internal data class ContentSummaryInfo(
    /**
     * A snippet or segment of the content.
     */
    val excerpt: String?,
    /**
     * An instance of [TextualCount] containing word and character counts.
     */
    val textualCount: TextualCount?,
)

/**
 * Data class representing textual information with counts.
 */
internal data class TextualCount(
    /**
     * see [com.speechify.client.internal.services.importing.models.RecordProperties.wordCount]
     */
    val wordCount: Int,
    /**
     * see [com.speechify.client.internal.services.importing.models.RecordProperties.charCount]
     */
    val charCount: Int,
)
