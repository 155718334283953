package com.speechify.client.api.services.adoption.models

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.services.adoption.models.FirebaseUserUsageDataEntry
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.time.ISO8601DateString
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlin.js.JsExport

@JsExport
data class UserUsageDataEntry(
    /**
     * The last version of the platform type the user have used.
     */
    val lastVersion: String,

    /**
     * The last visit ISO Date
     */
    val lastVisitISODate: ISO8601DateString,
) {
    internal constructor(raw: FirebaseUserUsageDataEntry) : this(
        raw.lastVersion,
        raw.lastVisitDateTime.toIsoString(),
    )
}

fun UserUsageDataEntry.toBoundaryMap(): BoundaryMap<Any?> = SdkBoundaryMap.of(
    "last_version" to lastVersion,
    "last_visit" to DateTime.fromIsoString(lastVisitISODate).asSeconds(),
)
