// ktlint-disable filename
package com.speechify.client.internal.util.extensions.coroutines

import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob

/**
 * Creates a started [CompletableJob] unassociated with the code that controls it, that is a child of [this] [Job] and
 * has properties of a 'supervisor job' (see KDoc for [SupervisorJob], of which this function is a shortcut).
 *
 * (NOTE: A version of this function which *is* associated with some running code is the
 * [kotlinx.coroutines.supervisorScope], available in any suspend function).
 *
 * # Typical use
 * This function should be used by components that need to outlive their children and, for example, be able to recover
 * from their failure (e.g. by just offering to start a child again).
 *
 * # Reminder - #DangerOfNeverCompleting
 * When creating a job in this way, you **may cause the parent job to never complete**. Remember that since it's not
 * associated with a running code, it is not going to complete by itself (except in cases of cancellation/failing of the
 * parent), so consider if you need to complete it yourself with [CompletableJob.complete] or
 * [CompletableJob.completeExceptionally].
 * Only in cases where the job lives forever until cancelling of it through the parent, there is no need to complete
 * it.
 *
 * # In a nutshell: The SupervisorJob concept and its role in _structured concurrency_
 * A `SupervisorJob` prevents any further children from failing their parent `Job` (like the [this] `Job` in this
 * function), when they themselves fail, but still, linking it with such a parent brings structured concurrency with the
 * outside context. That is: cancelling [this] `Job` or any of its ancestors will cancel the child `Job` returned by
 * this function, as well all its children.
 *
 * See [SupervisorJob] and [kotlinx.coroutines.Job] for the underlying mechanisms and an explanation from Kotlin itself.
 */
internal fun Job.createChildSupervisorJob(): CompletableJob =
    SupervisorJob(parent = this)

/**
 * A version of [createChildSupervisorJob] but without the 'supervisor' behavior, so a failure of any child of the
 * returned [CompletableJob] will.
 *
 * Reminder - see [Job.createChildSupervisorJob] for caveats, especially the #DangerOfNeverCompleting
 */
internal fun Job.createChildCompletableJob(): CompletableJob =
    Job(parent = this)
