package com.speechify.client.api.adapters.ocr

import kotlin.js.JsExport

@Deprecated("Use OCRAdapter directly instead.")
@JsExport
abstract class AbstractOCRAdapter(
    /**
     * The maximum number of concurrent OCR requests that can be pending at any single time (SDK will guard this).
     * Use it to produce , but choose this keeping in mind the CPU usage for local OCR (utilizing more CPU cores will
     * speed up the experience, but overloading it will actually slow it down), and the load on service for
     * remote OCR (services may have limit on requests-per-period or just get slowed by overload).
     */
    maxConcurrency: Int,
) : OCRAdapter(
    maxConcurrency = maxConcurrency,
)
