package com.speechify.client.internal.services

import com.speechify.client.api.adapters.http.HttpRequestBodyData
import com.speechify.client.api.adapters.http.consumeBodyAsByteArray
import com.speechify.client.api.telemetry.TelemetrySessionAndEventsPayload
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.success
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.highlight.models.CreateHighlightPayload
import com.speechify.client.internal.services.highlight.models.DeleteHighlightPayload
import com.speechify.client.internal.services.highlight.models.MergeHighlightsPayload
import com.speechify.client.internal.services.highlight.models.UpdateHighlightPayload
import com.speechify.client.internal.services.importing.models.CopyLibraryItemV1Payload
import com.speechify.client.internal.services.importing.models.CopyLibraryItemV2Payload
import com.speechify.client.internal.services.importing.models.CreateFileFromWebLinkPayload
import com.speechify.client.internal.services.library.models.LibrarySearchPayload
import com.speechify.client.internal.services.library.models.PlatformSearchResult
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer

class FirebaseFunctionsServiceImpl internal constructor(
    private val httpClient: HttpClient,
    private val authService: AuthService,
    private val functionsBaseUrl: String,
) {
    internal inner class FirebaseFunction<I, T> internal constructor(
        private val name: String,
        private val transformer: (ByteArray?) -> Result<T>,
        private val isLegacy: Boolean,
    ) {
        internal suspend operator fun invoke() = __private_call(null)

        internal suspend inline operator fun <reified Input : I> invoke(obj: Input): Result<T> =
            __private_call(Json.encodeToString(obj).encodeToByteArray())

        // this is to get around the fact that inline functions can't call private functions
        @Suppress("FunctionName")
        private suspend fun __private_call(body: ByteArray?): Result<T> {
            val firebaseToken =
                this@FirebaseFunctionsServiceImpl
                    .authService
                    .getCurrentUserIdentityToken()
                    .orReturn { return it }

            val url = "${this@FirebaseFunctionsServiceImpl.functionsBaseUrl}/$name"
            return httpClient.post(url) {
                this.httpRequestBodyData = HttpRequestBodyData.BodyBytes(body)
                this.header(
                    "Authorization",
                    if (isLegacy) firebaseToken.token else "Bearer ${firebaseToken.token}",
                )
                if (body != null) {
                    this.header("Content-Type", "application/json")
                }
            }.then {
                if (it.ok) {
                    transformer(
                        it.consumeBodyAsByteArray(
                            sourceAreaIdForInefficienciesWarnings = url,
                        )
                            .orReturn { failure -> return failure },
                    )
                } else {
                    Result.Failure(
                        SDKError.HttpError(
                            it.status,
                            "firebase function '$name' failed",
                        ),
                    )
                }
            }
        }
    }

    internal val createFileFromWebLink get() = this.FirebaseFunction<CreateFileFromWebLinkPayload, Unit>(
        "sdk-createFileFromWebLink",
        TransformTo::unit,
        true,
    )

    internal val copyLibraryItemV1
        get() =
            this.FirebaseFunction<CopyLibraryItemV1Payload, CopyLibraryItemV1Response>(
                "sdk-copyLibraryItem",
                TransformTo.deserialize(),
                true,
            )

    internal val copyLibraryItemV2
        get() = this.FirebaseFunction<CopyLibraryItemV2Payload, Unit>("sdk-copyLibraryItemV2", TransformTo::unit, true)

    internal val transferLibrary
        get() = this.FirebaseFunction<Map<String, String>, Unit>("sdk-transferLibrary", TransformTo::unit, true)

    internal val archiveLibraryItem
        get() =
            this.FirebaseFunction<Map<String, String>, Unit>(
                "sdk-firestore-archiveLibraryItem",
                TransformTo::unit,
                false,
            )

    internal val removeLibraryItem
        get() =
            this.FirebaseFunction<Map<String, String>, Unit>(
                "sdk-firestore-removeLibraryItem",
                TransformTo::unit,
                false,
            )

    internal val restoreLibraryItem
        get() =
            this.FirebaseFunction<Map<String, String>, Unit>(
                "sdk-firestore-restoreLibraryItem",
                TransformTo::unit,
                false,
            )

    internal val restoreAllArchivedItems
        get() =
            this.FirebaseFunction<Nothing, Unit>(
                "sdk-firestore-restoreAllLibraryItems",
                TransformTo::unit,
                false,
            )

    internal val removeAllArchivedItems
        get() =
            this.FirebaseFunction<Nothing, Unit>(
                "sdk-firestore-removeAllArchivedLibraryItems",
                TransformTo::unit,
                false,
            )

    internal val addDefaultLibraryItems
        get() =
            this.FirebaseFunction<Nothing, Unit>(

                "sdk-firestore-addDefaultItemsToCurrentLibrary",
                TransformTo::unit,
                false,
            )

    internal val libraryItemSearch
        get() =
            this.FirebaseFunction<LibrarySearchPayload, PlatformSearchResult>(
                name = "sdk-http-es-libraryItemSearch",
                TransformTo.deserialize(
                    ignoreUnknownKeys = true,
                ),
                false,
            )

    internal val addItemHighlight
        get() =
            this.FirebaseFunction<CreateHighlightPayload, Unit>(
                "sdk-firestore-addItemHighlight",
                TransformTo::unit,
                false,
            )

    internal val mergeItemHighlights
        get() =
            this.FirebaseFunction<MergeHighlightsPayload, Unit>(
                "sdk-firestore-mergeItemHighlights",
                TransformTo::unit,
                false,
            )

    internal val deleteItemHighlight
        get() =
            this.FirebaseFunction<DeleteHighlightPayload, Unit>(
                "sdk-firestore-removeItemHighlight",
                TransformTo::unit,
                false,
            )

    internal val updateItemHighlight
        get() =
            this.FirebaseFunction<UpdateHighlightPayload, Unit>(
                "sdk-firestore-updateItemHighlight",
                TransformTo::unit,
                false,
            )

    internal suspend fun reportDiagnostics(
        telemetrySessionAndEventsPayload: TelemetrySessionAndEventsPayload,
    ): Result<Unit> = reportDiagnosticsFn(telemetrySessionAndEventsPayload)

    internal val detectLanguageOfText get() =
        this.FirebaseFunction<DetectLanguageOfTextPayload, DetectLanguageOfTextResponse>(
            name = "sdk-http-detectLanguageOfText",
            TransformTo.deserialize(ignoreUnknownKeys = true),
            false,
        )

    /* TODO - turn the `FirebaseFunction` beast into just a that takes the parameters, to make things simpler to read
        and mock
     */
    private val reportDiagnosticsFn =
        this.FirebaseFunction<TelemetrySessionAndEventsPayload, Unit>(
            name = "sdk-http-reportClientDiagnostics",
            TransformTo::unit,
            isLegacy = false,
        )
}

// Cloud function output transformers
internal object TransformTo {
    fun unit(
        @Suppress("UNUSED_PARAMETER") body: ByteArray?,
    ): Result.Success<Unit> = success()

    fun string(body: ByteArray?): Result<String> =
        body?.decodeToString()?.successfully()
            ?: Result.Failure(
                SDKError.Serialization(
                    SerializationException("null body"),
                    "(null)",
                    String::class,
                ),
            )

    inline fun <reified T> deserialize(ignoreUnknownKeys: Boolean = false): (ByteArray?)
    -> Result<T> {
        /* have to capture serializer here, otherwise, if done inside inner
        function will result in "throwMarkerError..." exception being thrown in JS */
        val serializer = serializer<T>()
        return inner@{
            val string =
                it?.decodeToString() ?: return@inner Result.Failure(
                    SDKError.Serialization(
                        SerializationException("empty body"),
                        "",
                        T::class,
                    ),
                )
            try {
                Json { this.ignoreUnknownKeys = ignoreUnknownKeys }.decodeFromString(
                    serializer,
                    string,
                ).successfully()
            } catch (e: SerializationException) {
                Result.Failure(
                    SDKError.Serialization(
                        e,
                        string,
                        T::class,
                    ),
                )
            }
        }
    }
}

@Serializable
internal data class CopyLibraryItemV1Response(
    val recordId: String,
)

@Serializable
internal data class DetectLanguageOfTextPayload(val text: String)

@Serializable
internal data class DetectLanguageOfTextResponse(
    val iso639_3LanguageCode: String,
)
