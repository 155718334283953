package com.speechify.client.internal.services.scannedbook

import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.CoordinateTransform
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.importing.models.set
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import com.speechify.client.internal.util.boundary.toBoundaryMap

internal fun createLegacyScannedPageFirestorePayload(
    storagePath: String,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    index: Int,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.of(
        "index" to index,
        "createdAt" to now,
        "storagePath" to storagePath,
        "updatedAt" to now,
    )
}

internal fun buildScannedBookFirestorePayload(
    owner: String,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    imageUrl: String,
    textContent: Array<OCRTextContent>,
    viewport: Viewport,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()

    return SdkBoundaryMap.of(
        RecordProperties.owner.key.toPairWithVal(owner),
        "createdAt" to now,
        "imageUrl" to imageUrl,
        "textContent" to textContentToBoundaryMapArray(textContent),
        "viewport" to viewport.toFirestorePayload().toBoundaryMap(),
    )
}

internal fun textContentToBoundaryMapArray(textContent: Array<OCRTextContent>): Array<BoundaryMap<Any>> {
    return textContent.map {
        SdkBoundaryMap.of(
            "boundingBox" to boundingBoxToBoundaryMap(it.boundingBox),
            "text" to it.text,
        )
    }.toTypedArray()
}

internal fun Viewport.toFirestorePayload() = mapOf(
    "width" to this.width,
    "height" to this.height,
)

internal fun boundingBoxToBoundaryMap(boundingBox: BoundingBox): BoundaryMap<Any?> {
    return SdkBoundaryMap.of(
        "width" to boundingBox.width,
        "height" to boundingBox.height,
        "transform" to coordinateTransformToBoundaryMap(boundingBox.transform),
    )
}

internal fun coordinateTransformToBoundaryMap(coordinateTransform: CoordinateTransform): BoundaryMap<Any?> {
    return SdkBoundaryMap.of(
        "a" to coordinateTransform.a,
        "b" to coordinateTransform.b,
        "c" to coordinateTransform.c,
        "d" to coordinateTransform.d,
        "tx" to coordinateTransform.tx,
        "ty" to coordinateTransform.ty,
    )
}

internal fun orderedPageIdsToBoundaryMap(
    orderedPageIds: Sequence<String>,
): Array<BoundaryMap<String>> {
    return orderedPageIds
        .map { SdkBoundaryMap.of(RecordProperties.pageId.key.toPairWithVal(it)) }
        .toList()
        .toTypedArray()
}

internal fun BoundaryMap<Any?>.libraryItemWithScannedBookFields(
    orderedPageIds: Sequence<String>,
): BoundaryMap<Any?> {
    return apply {
        this[RecordProperties.scannedBookFields] = SdkBoundaryMap.of(
            RecordProperties.pageOrdering.key.toPairWithVal(orderedPageIdsToBoundaryMap(orderedPageIds)),
        )
    }
}

internal fun libraryItemWithScannedBookFields(
    orderedPageIds: Sequence<String>,
): BoundaryMap<Any?> {
    return SdkBoundaryMap.of(
        RecordProperties.scannedBookFields.key.toPairWithVal(
            SdkBoundaryMap.of(
                RecordProperties.pageOrdering.key.toPairWithVal(orderedPageIdsToBoundaryMap(orderedPageIds)),
            ),
        ),
    )
}
