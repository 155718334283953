package com.speechify.client.internal.util.collections.flows

import com.speechify.client.internal.sync.ReadOnlyJobInfo
import com.speechify.client.internal.sync.toReadOnlyJobInfo
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch

/**
 * Similar to [kotlinx.coroutines.flow.flowOn], but just for observing the flow consumptions as jobs
 * (the jobs are created as children to the consumer jobs and also awaited, so no new concurrency is started,
 * but the consumption is still able to cancel the job).
 */
internal fun <T> Flow<T>.flowOnObservableJobs(
    /**
     * Use this to observe the lifecycle of the flow's consumptions.
     * Note - this will execute for every consumption of the flow through its [Flow.collect].
     */
    receiveJobInfo: (collectingJob: ReadOnlyJobInfo) -> Unit,
) = channelFlowWithoutItemsLoss {
    coroutineScope {
        receiveJobInfo(
            launch {
                this@flowOnObservableJobs
                    .collect {
                        emit(it)
                    }
            }.toReadOnlyJobInfo(),
        )
    }
}
