package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.TransientContentElementReference
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.internal.util.collections.median
import com.speechify.client.internal.util.eqWithTolerance
import kotlin.math.abs
import kotlin.math.min

internal fun isSameLine(a: BoundingBox, b: BoundingBox, pageSize: Viewport): Boolean {
    // The pageSize is interpreted to be in PDF points.
    val sameLineTolerance = 1.0 / pageSize.height
    return (
        a.top.eqWithTolerance(b.top, sameLineTolerance) ||
            a.bottom.eqWithTolerance(b.bottom, sameLineTolerance) ||
            (a.top..a.bottom).contains(b.centerY) ||
            (b.top..b.bottom).contains(a.centerY)
        ) &&
        // We don't want to mix 90° rotated text into the same line.
        a.transform.getAngle().eqWithTolerance(b.transform.getAngle(), 0.01)
}

internal fun isSameColumn(a: BoundingBox, b: BoundingBox, pageSize: Viewport): Boolean {
    // The pageSize is interpreted to be in PDF points.
    val sameColumnTolerance = 1.0 / pageSize.width
    return a.left.eqWithTolerance(b.left, sameColumnTolerance) ||
        (a.left..a.right).contains(b.centerX) ||
        (b.left..b.right).contains(a.centerX)
}

/**
 * One line on the page. Line in this context could be just a single line of text like the header. Or an entire section
 * of columns on the page.
 * The internal sorting of content in this grouping is to read text in columns.
 */
internal data class LayoutLine(val groups: MutableList<TextGroup> = mutableListOf()) {

    // We're not dealing with a huge amount of items so recalculating this is fine.
    val boundingBox: BoundingBox
        get() = groups.map { it.boundingBox }.reduce { acc, boundingBox ->
            return@reduce acc.union(boundingBox)
        }

    /** This sorts the groups by column and then by y. Matching the order they should be read in within the line. */
    fun getSortedGroups(pageSize: Viewport): List<TextGroup> {
        val pageDirection = inferDominantTextDirectionFromPossiblyUnorderedTextFragments(
            groups.asSequence().flatMap { it.lines }.flatMap { it.texts },
        ) { it.source.text.text }
        // Group the text groups into columns and sort elements in the same column from top to bottom
        val columns = groupTextGroupsByColumn(groups, pageSize)
        val columnsWithContentSorted = sortColumnsContent(columns)

        // Sort columns based on page direction
        columnsWithContentSorted.sortBy {
            when (pageDirection) {
                null, TextDirection.LeftToRight -> it.first().boundingBox.left
                TextDirection.RightToLeft -> -1 * it.first().boundingBox.right
            }
        }

        return columnsWithContentSorted.flatten()
    }

    private fun groupTextGroupsByColumn(groups: List<TextGroup>, pageSize: Viewport):
        MutableList<MutableList<TextGroup>> {
        val columns = mutableListOf<MutableList<TextGroup>>()
        groups.forEach { group ->
            columns.find {
                it.any { groupItem -> isSameColumn(group.boundingBox, groupItem.boundingBox, pageSize) }
            }?.add(
                group,
            )
                ?: columns.add(mutableListOf(group))
        }
        return columns
    }

    private fun sortColumnsContent(columns: List<MutableList<TextGroup>>):
        MutableList<List<TextGroup>> {
        return columns.map { column ->
            val sortedColumn = column.sortedWith { a, b ->
                a.boundingBox.top.compareTo(b.boundingBox.top)
            }

            sortedColumn
        }.toMutableList()
    }
}

/**
 * This takes the groups of lines we created and sorts them into the order they should be read in.
 * This uses the PageLayout class internally.
 */
internal fun orderGroupsOnPage(groups: List<TextGroup>, pageSize: Viewport): List<TextGroup> {
    // If we find two groups with super similar edges we want to try to group them in to one layout line,
    // this is to ensure that plain 2 column layouts don't get split into 2xX grids instead.
    fun getSimilarityAdjustedBottomPadding(a: BoundingBox, b: BoundingBox): Double {
        return (
            1.0 - min(
                abs(a.left - b.left) + abs(a.right - b.right),
                1.0,
            )
            ) * (4.0 / pageSize.height)
    }

    val pendingLayoutLines = mutableListOf<LayoutLine>()

    // The padding is represented as a fixed value of points on the unnormalized page.
    // We divide by page height to get the normalized value to use.
    val bottomPadding = (4.0 / pageSize.height)
    groups.sortedWith { a, b -> a.boundingBox.top.compareTo(b.boundingBox.top) }.forEach { group ->
        // We check if there is already a line this group can go into.
        // This check is based only on the bottom edge of the group.
        // Overlap of the top edge with other lines will be considered at a later step.
        val lineToAddTo = pendingLayoutLines.find {
            val similarityAdjustedPadding = getSimilarityAdjustedBottomPadding(it.boundingBox, group.boundingBox)

            // We want to make sure that content in the same column (matching left and right edges)
            // lands in the same line to avoid content being grouped in a 2xX grid.
            val isSameColumn = it.groups.any { g ->
                group.boundingBox.left.eqWithTolerance(g.boundingBox.left, 0.0025) &&
                    group.boundingBox.right.eqWithTolerance(g.boundingBox.right, 0.0025)
            }
            val sameColumnAdjustment = if (isSameColumn) 0.01 else 0.0

            (
                it.boundingBox.bottom +
                    bottomPadding +
                    similarityAdjustedPadding +
                    sameColumnAdjustment
                ) > group.boundingBox.bottom
        }
        if (lineToAddTo == null) {
            pendingLayoutLines.add(LayoutLine(mutableListOf(group)))
        } else {
            lineToAddTo.groups.add(group)
        }
    }

    // Now that we have all groups sorted into their preliminary layout lines, we merge lines where the top edge
    // overlaps with the line above it. This is a separate step because we want to make sure we don't merge lines
    // that each only contain 1 group. This prevents content from being columnized that does not need to be considered
    // as such.
    // We run this loop until all overlaps are resolved, this way we prevent the reading order from being broken if
    // in an earlier iteration a group was moved to a separate line.
    val finalLayoutLines = mutableListOf<LayoutLine>()
    do {
        val initialSize = finalLayoutLines.size
        finalLayoutLines.clear()
        while (pendingLayoutLines.isNotEmpty()) {
            val newLayoutLine = LayoutLine(pendingLayoutLines.removeAt(0).groups.toMutableList())
            finalLayoutLines.add(newLayoutLine)

            val linesToRemove = mutableListOf<LayoutLine>()
            for (line in pendingLayoutLines) {
                // Only consider lines with more than 1 column for merging.
                // This prevents cases where we create new columns that aren't necessary for
                // detecting an okay reading order.
                if (line.groups.size == 1 && newLayoutLine.groups.size == 1) {
                    continue
                }

                // We want to make sure that content in the same column (matching left and right edges)
                // lands in the same line to avoid content being grouped in a 2xX grid.
                val isSameColumn = line.groups.any {
                    newLayoutLine.groups.any { g ->
                        it.boundingBox.left.eqWithTolerance(g.boundingBox.left, 0.0025) &&
                            it.boundingBox.right.eqWithTolerance(g.boundingBox.right, 0.0025)
                    }
                }
                val sameColumnAdjustment = if (isSameColumn) 0.01 else 0.0

                val similarityAdjustedPadding =
                    getSimilarityAdjustedBottomPadding(line.boundingBox, newLayoutLine.boundingBox)
                if (line.boundingBox.top < (
                    newLayoutLine.boundingBox.bottom +
                        bottomPadding +
                        similarityAdjustedPadding +
                        sameColumnAdjustment
                    )
                ) {
                    newLayoutLine.groups.addAll(line.groups)
                    linesToRemove.add(line)
                }
            }

            pendingLayoutLines.removeAll(linesToRemove)
        }

        pendingLayoutLines.addAll(finalLayoutLines)
    } while (finalLayoutLines.size != initialSize && finalLayoutLines.size > 1)
    // For the final sorting we now simply need to consider first the lines, and then the internal sorting inside
    // each line.
    return finalLayoutLines.sortedBy { it.boundingBox.top }.flatMap { it.getSortedGroups(pageSize) }
}

/**
 * This unwraps the structure we built back into a simple list of BookPageTextContentItem.
 */
internal fun unwrapGroupsIntoTexts(groups: List<TextGroup>): List<BookPageTextContentItem> {
    val originalOffsets = mutableListOf<Int>()
    val sortedOffsets = mutableListOf<Int>()
    var i = 0
    val result = groups.flatMap { group ->
        group.lines.flatMap { line ->
            /*
             Assume that prior stages have ordered the items according to correct dominant direction for this line,
             and make one final pass to reorder the items using knowledge of how the Unicode Bidirectional Algorithm
             might have reordered the original text during layout.
             */
            line.itemsInMostLikelySourceTextOrderAccountingForBidirectionalTextRendering()
                .map {
                    val newIndex = i++
                    originalOffsets += newIndex - it.originalIndex
                    sortedOffsets += newIndex - it.sortedIndex
                    val slice = it.source.text as TextElementContentSlice
                    val newElement = TransientContentElementReference(slice.element, newIndex)
                    it.source.copy(text = slice.copy(element = newElement))
                }
        }
    }

    // In theory, we could analyze how much we moved around content and put a threshold where we consider it faulty
    // and fall back to the original order.
    Log.dEvent {
        DiagnosticEvent(
            sourceAreaId = "LayoutDetectionModel#unwrapGroupsIntoTexts",
            message = "Original offsets: ${originalOffsets.median()}\nSorted offsets: ${sortedOffsets.median()}",
        )
    }

    return result
}
