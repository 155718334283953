package com.speechify.client.api.audio.caching

import com.speechify.client.api.audio.SynthesizeResponse
import com.speechify.client.api.content.JoinedTextWithMap
import com.speechify.client.api.content.ValueWithStringRepresentation
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.AtomicLong
import com.speechify.client.internal.sync.WrappingMutex
import com.speechify.client.internal.sync.add
import com.speechify.client.internal.time.DateTime
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow

internal class MediaSynthesisCacheInMemory<
    JoinedSentencesType : ValueWithStringRepresentation,
    SentenceType : ValueWithStringRepresentation,
    >(private val cacheCapacityInCharsOfText: Int) : MediaSynthesisCache<
    JoinedSentencesType,
    SentenceType,
    > () {

    override suspend fun getEntriesContainingSentence(
        sentenceText: String,
    ): Flow<MediaSynthesisCache.CacheEntry>? =
        cachedEntries.locked {
            /* deliberately making the read
            lock small to allow parallel requests. This does create a race condition only for the same contents,
             but they wouldn't happen in normal use case (except for repetitive content) and even if they did,
             the consequences would be benign: we would just end up with the last one to write to the cache */
            it[sentenceText]
                ?.entriesWithThisSentence
                ?.toList()
        }
            ?.map { cacheEntry ->
                object : MediaSynthesisCache.CacheEntry {
                    override val joinedText: String get() =
                        cacheEntry.inputSentencesWithSeparators.joinedText.textRepresentation

                    override suspend fun getSynthesisResult(): SynthesizeResponse =
                        cacheEntry.synthesisResult
                }
            }
            ?.asFlow()

    override suspend fun putCachedEntry(
        inputSentencesAndWhitespaceSeparators: JoinedTextWithMap<JoinedSentencesType, SentenceType>,
        newSynthesisResult: SynthesizeResponse,
    ) {
        fun getEntrySize(cacheEntry: CacheEntry<JoinedSentencesType, SentenceType>) =
            cacheEntry.inputSentencesWithSeparators.joinedText.textRepresentation.length

        val newEntry = CacheEntry(
            inputSentencesWithSeparators = inputSentencesAndWhitespaceSeparators,
            synthesisResult = newSynthesisResult,
        )

        val newEntrySize = getEntrySize(newEntry).toLong()

        val newSize = cachedEntries.locked {
            for (sentence in newEntry.inputSentencesWithSeparators.constituentParts) {
                val cacheEntriesContainingTheSentence = it
                    .getOrPut(sentence.textRepresentation) { CacheEntriesContainingASentence() }
                cacheEntriesContainingTheSentence.entriesWithThisSentence.add(newEntry)
            }
            return@locked cacheSizeInCharsContent.addAndGet(newEntrySize)
        }

        if (newSize > cacheCapacityInCharsOfText) { // Spawn the cleaning in a new thread, not to block the return
            launchTask {
                Log.dEvent {
                    DiagnosticEvent(
                        sourceAreaId = "SingleSpecsMediaSynthesisService.withCache",
                        message = "Cache {newSize} exceeded {capacityInChars}. Cleaning up",
                        properties = mapOf(
                            "capacityInChars" to cacheCapacityInCharsOfText,
                            "newSize" to newSize,
                        ),
                    )
                }
                cachedEntries.locked { cachedEntries ->
                    var freedSize = 0

                    class EntryInfo(
                        val containingEntriesObj: CacheEntriesContainingASentence<JoinedSentencesType, SentenceType>,
                        val entry: CacheEntry<JoinedSentencesType, SentenceType>,
                    )

                    val allEntries = cachedEntries.entries.flatMap { sentenceEntry ->
                        sentenceEntry.value.entriesWithThisSentence.map {
                            EntryInfo(
                                containingEntriesObj = sentenceEntry.value,
                                entry = it,
                            )
                        }
                    }
                        .groupBy { it.entry }
                        .entries

                    // remove from all the sentences
                    for ((entry, entryInfos) in allEntries.sortedBy { it.key.creationTime }) {
                        for (entryInfo in entryInfos)
                            entryInfo.containingEntriesObj.entriesWithThisSentence.remove(entryInfo.entry)
                        val thisEntrySize = getEntrySize(entry)
                        cacheSizeInCharsContent.add(-thisEntrySize.toLong())
                        freedSize += thisEntrySize

                        if (freedSize >= newEntrySize) {
                            break
                        }
                    }

                    Log.dEvent {
                        DiagnosticEvent(
                            sourceAreaId = "SingleSpecsMediaSynthesisService.withCache",
                            message = "Finished releasing {freedSize} of cache. Size of cache is {cacheSize}",
                            properties = mapOf(
                                "freedSize" to freedSize,
                                "cacheSizeCounter" to cacheSizeInCharsContent.get(),
                                "cacheSizeComputed" to cachedEntries
                                    .values
                                    .flatMap { it.entriesWithThisSentence }
                                    .distinct()
                                    .sumOf { getEntrySize(it) },
                            ),
                        )
                    }
                }
            }
        }
    }

    class CacheEntry<
        JoinedSentencesType : ValueWithStringRepresentation,
        SentenceType : ValueWithStringRepresentation,
        >(
        val inputSentencesWithSeparators: JoinedTextWithMap<JoinedSentencesType, SentenceType>,
        val synthesisResult: SynthesizeResponse,
    ) {
        val creationTime: DateTime = DateTime.now()
    }

    class CacheEntriesContainingASentence<
        JoinedSentencesType : ValueWithStringRepresentation,
        SentenceType : ValueWithStringRepresentation,
        >(
        val entriesWithThisSentence: MutableSet<CacheEntry<JoinedSentencesType, SentenceType>> = mutableSetOf(),
    )

    private val cachedEntries = WrappingMutex.of(
        mutableMapOf<String, CacheEntriesContainingASentence<JoinedSentencesType, SentenceType>>(),
    )
    private val cacheSizeInCharsContent = AtomicLong(0)
}
