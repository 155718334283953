package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.bundlers.reading.importing.ContentPostImportActionsManager
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.services.epub.EpubChapterContentStatsService
import com.speechify.client.internal.services.epub.FirestoreChapterContentStatsModel
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.collections.median
import com.speechify.client.internal.util.text.groupingToWords.wordCount
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.shareIn
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlin.math.abs
import kotlin.math.sqrt

internal class ApproximateEpubV2Index(
    standardView: EpubStandardViewV2,
    private val epubChapterContentStatsService: EpubChapterContentStatsService,
    private val postImportActionsManager: ContentPostImportActionsManager,
) : BaseEpubV2ContentIndex(standardView = standardView) {

    // Source of truth of the [ContentStats] - replay to 1 to emit the content stats for the initial pages.
    override val contentStatsFlow = MutableSharedFlow<ContentStats>(replay = 1)

    private val metadata by lazy {
        standardView.view.getMetadata()
    }

    private val buildInitialChaptersToLoad = coLazy {
        val totalChapters = standardView.view.getMetadata().numberOfChapters
        val effectiveLastChapter = (totalChapters * 0.85).toInt()
        val contentDistribution = analyzeContentDistribution(standardView.view.getChaptersEstimatedContentRatios())
        buildSet {
            when {
                totalChapters > 20 -> {
                    if (contentDistribution.isUniform) {
                        // For uniform content, take evenly distributed samples
                        addDistributedSamples(
                            currentSamples = emptyList(),
                            effectiveLastChapter = effectiveLastChapter,
                            sampleCount = 5,
                        )
                    } else {
                        // For varied content, take significant chapters from anywhere
                        val significantChapters = contentDistribution.largeChapters
                            .filter { it.key <= effectiveLastChapter }
                            .take(4)
                            .map { it.key }

                        addAll(significantChapters)

                        // Add one distributed sample if we found less than 4 significant chapters
                        if (significantChapters.size < 4) {
                            addDistributedSamples(
                                currentSamples = this.toList(),
                                effectiveLastChapter = effectiveLastChapter,
                                sampleCount = 1,
                            )
                        }
                    }
                }

                totalChapters > 8 -> {
                    if (contentDistribution.isUniform) {
                        // Take two evenly spaced samples for uniform content
                        val step = totalChapters / 3
                        add(step)
                        add(step * 2)
                    } else {
                        // Take the 2-3 largest chapters from anywhere
                        contentDistribution.largeChapters
                            .filter { it.key <= effectiveLastChapter }
                            .take(3)
                            .forEach { add(it.key) }
                    }
                }

                else -> {
                    // For small books, take the largest chapter
                    contentDistribution.largeChapters
                        .firstOrNull()
                        ?.let { add(it.key) }
                        // If no significant chapters found, take middle chapter
                        ?: run { if (totalChapters > 1) add(totalChapters / 2) }
                }
            }
        }.filter { it < totalChapters }.toSet()
    }

    private val firestoreContentStatsCacheOrNull = coLazy {
        when (val importState = postImportActionsManager.state) {
            is ContentImporterState.ImportedToLibrary ->
                epubChapterContentStatsService.getFirestoreContentStatsOrNull(importState.uri.id)

            else -> null
        }
    }

    private val initialFirestoreContentStatsListCache = coLazy {
        when (val importState = postImportActionsManager.state) {
            is ContentImporterState.ImportedToLibrary ->
                epubChapterContentStatsService.getAllContentStatsChapters(itemId = importState.uri.id)
                    .map { it.toMutableList() }
                    .orDefaultWith { mutableListOf() }

            else -> mutableListOf()
        }
    }

    private val updatedContentStatsChaptersFlow = flow {
        val initialFirestoreContentStatsChapters = initialFirestoreContentStatsListCache.get()
        if (initialFirestoreContentStatsChapters.isNotEmpty()) {
            emit(initialFirestoreContentStatsChapters)
        }

        val totalChapters = metadata.numberOfChapters

        // Launch concurrent requests for initial chapters
        buildInitialChaptersToLoad.getWithCancellation().map { chapterIndex ->
            launchTask {
                standardView.getBlocksForChapter(chapterIndex)
            }
        }

        // Process chapters as they come in
        standardView.parsedChapterContentFlow.collect { (chapterIndex, parsedChapterContent) ->
            if (initialFirestoreContentStatsChapters.none { it.chapterIndex == chapterIndex }) {
                val blocks = parsedChapterContent.blocks
                val chapterStats = FirestoreChapterContentStatsModel(
                    chapterIndex = chapterIndex,
                    wordCount = blocks.sumOf { it.text.text.wordCount() },
                    charCount = blocks.sumOf { it.text.text.length },
                )

                initialFirestoreContentStatsChapters.add(chapterStats)

                launchTask {
                    addContentStatsChapterToFirestore(chapterStats)
                }

                val shouldEmit = initialFirestoreContentStatsChapters.size >= totalChapters ||
                    initialFirestoreContentStatsChapters.sumOf { it.wordCount } > 0

                if (shouldEmit) {
                    emit(initialFirestoreContentStatsChapters)
                }
            }
        }
    }.shareIn(scope, started = SharingStarted.Lazily)

    init {
        scope.launch {
            val firebaseContentStats = firestoreContentStatsCacheOrNull.get()
            if (firebaseContentStats != null) {
                contentStatsFlow.emit(firebaseContentStats)
                return@launch
            }

            // Wait for all sampled chapters to be processed and take the first such emission
            val chapters = updatedContentStatsChaptersFlow.first { chapters ->
                buildInitialChaptersToLoad.getWithCancellation().all { sampleIndex ->
                    chapters.any { it.chapterIndex == sampleIndex }
                }
            }

            val contentStats = chapters.toContentStats(standardView.view.getMetadata().numberOfChapters)
            contentStatsFlow.emit(contentStats)
        }

        // Moved [saving-to-firestore] code to another coroutine for code clarity and single responsibility.
        scope.launch {
            // Used debounce here to reduce unnecessary updates to firestore.
            updatedContentStatsChaptersFlow.debounce(2000).collectLatest {
                val totalNumberOfChapters = metadata.numberOfChapters

                // Save to firestore in case: Parsed enough pages OR already parsed all the chapters.
                val shouldUpdateFirestoreContentStats = it.size >= MIN_CHAPTERS_TO_UPDATE_FIRESTORE_CONTENT_STATS ||
                    it.size == totalNumberOfChapters
                if (shouldUpdateFirestoreContentStats) {
                    val contentStats = it.toContentStats(totalNumberOfChapters)
                    updateFirestoreTextualCount(contentStats)
                }
            }
        }
    }

    // Returns null because [ApproximateEpubIndex] doesn't provide content stats based on fixed number of chapters.
    override suspend fun getStatsIncludingPending(): ContentStats? = null

    private fun analyzeContentDistribution(
        chapterSizes: Map<Int, Double>,
    ): ContentDistribution {
        val avgSize = chapterSizes.values.average()
        val stdDev = chapterSizes.values.stdDev()

        val isUniform = stdDev / avgSize < 0.5

        val significantThreshold = if (isUniform) {
            avgSize * 1.2
        } else {
            avgSize * 1.5
        }

        val largeChapters = chapterSizes.entries
            .filter { it.value > significantThreshold }
            .sortedByDescending { it.value }

        return ContentDistribution(
            avgChapterSize = avgSize,
            significantThreshold = significantThreshold,
            largeChapters = largeChapters,
            isUniform = isUniform,
        )
    }

    private fun Collection<Double>.stdDev(): Double {
        val avg = average()
        val variance = map { (it - avg) * (it - avg) }.average()
        return sqrt(variance)
    }

    private fun MutableSet<Int>.addDistributedSamples(
        currentSamples: List<Int>,
        effectiveLastChapter: Int,
        sampleCount: Int,
    ) {
        val remainingSampleCount = maxOf(0, sampleCount - currentSamples.size)
        if (remainingSampleCount <= 0) return

        val step = effectiveLastChapter / (remainingSampleCount + 1)
        var nextSamplePoint = step
        var addedSamples = 0

        while (addedSamples < remainingSampleCount && nextSamplePoint < effectiveLastChapter) {
            if (isGoodSamplePoint(nextSamplePoint, currentSamples, step)) {
                add(nextSamplePoint)
                addedSamples++
            }
            nextSamplePoint += step
        }
    }

    private fun isGoodSamplePoint(
        point: Int,
        existingSamples: List<Int>,
        minimumDistance: Int,
    ): Boolean = existingSamples.none { existing ->
        abs(existing - point) < minimumDistance / 2
    }

    private suspend fun addContentStatsChapterToFirestore(chapterContentStats: FirestoreChapterContentStatsModel) =
        // Adding content stats chapter to firestore, even if the scope job is canceled.
        withContext(NonCancellable) {
            when (val importState = postImportActionsManager.state) {
                is ContentImporterState.ImportedToLibrary -> epubChapterContentStatsService.addContentStatsChapter(
                    itemId = importState.uri.id,
                    chapterContentStats = chapterContentStats,
                ).orReturn { return@withContext }

                else -> postImportActionsManager.queueTaskAfterImport {
                    epubChapterContentStatsService.addContentStatsChapter(
                        itemId = it.id,
                        chapterContentStats = chapterContentStats,
                    )
                }
            }
        }

    private suspend fun updateFirestoreTextualCount(contentStats: ContentStats) =
        // update the firebase textualCount, even if the scope job is canceled.
        withContext(NonCancellable) {
            when (val importState = postImportActionsManager.state) {
                is ContentImporterState.ImportedToLibrary -> {
                    epubChapterContentStatsService.updateFirebaseContentStats(
                        itemId = importState.uri.id,
                        wordCount = contentStats.estimatedWordCount.count,
                        charCount = contentStats.estimatedCharCount.count,
                    )
                }

                else -> {
                    postImportActionsManager.queueTaskAfterImport {
                        epubChapterContentStatsService.updateFirebaseContentStats(
                            itemId = it.id,
                            wordCount = contentStats.estimatedWordCount.count,
                            charCount = contentStats.estimatedCharCount.count,
                        )
                    }
                }
            }
        }

    private companion object {
        const val MIN_CHAPTERS_TO_UPDATE_FIRESTORE_CONTENT_STATS = 5
    }
}

private data class ContentDistribution(
    val avgChapterSize: Double,
    val significantThreshold: Double, // Dynamic threshold based on distribution
    val largeChapters: List<Map.Entry<Int, Double>>, // Chapters significantly above average
    val isUniform: Boolean, // Whether content is evenly distributed
)

/**
 * Calculates the content stats based on the word and char count of chapter.
 */
private fun List<FirestoreChapterContentStatsModel>.toContentStats(totalNumberOfChapters: Int) =
    if (totalNumberOfChapters <= size) {
        ContentStats(
            estimatedWordCount = EstimatedCount(count = sumOf { it.wordCount }, confidence = 1.0),
            estimatedCharCount = EstimatedCount(count = sumOf { it.charCount }, confidence = 1.0),
        )
    } else {
        val confidence = size.toDouble() / totalNumberOfChapters
        val (wordCountSum, charCountSum) = fold(0 to 0) { (wordSum, charSum), page ->
            wordSum + page.wordCount to charSum + page.charCount
        }

        val medianWordCountValue = map { it.wordCount }.median()
        val medianCharCountValue = map { it.charCount }.median()

        val unreachedChaptersCount = totalNumberOfChapters - size
        ContentStats(
            estimatedWordCount = EstimatedCount(
                count = wordCountSum + unreachedChaptersCount * medianWordCountValue,
                confidence = confidence,
            ),
            estimatedCharCount = EstimatedCount(
                count = charCountSum + unreachedChaptersCount * medianCharCountValue,
                confidence = confidence,
            ),
        )
    }
