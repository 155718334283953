package com.speechify.client.api.content.view.book

import com.speechify.client.api.SpeechifyURI
import kotlin.js.JsExport

@JsExport
class OCRRequirementEvent internal constructor(
    val speechifyURI: SpeechifyURI? = null,
    val bookPageIndex: Int,
    val numberOfPages: Int,
)
