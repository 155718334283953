package com.speechify.client.api.content

import com.speechify.client.helpers.features.ProgressFraction
import kotlin.js.JsExport

/**
 * An abstraction for any rich object that has representation as a string.
 *
 * This interface allows signalling in contracts that the code only works on the textual data
 * and thus also guaranteeing that the scope of interest is limited to the textual data
 * (and does not, for example, involve rich content model with cursors, etc., represented by [ContentText]).
 */
@JsExport
interface ValueWithStringRepresentation {
    /**
     * Get the text representing this instance
     */
    val textRepresentation: String
}

/**
 * Text that remembers the position(s) it came from in the original content, even after transformation
 */
@JsExport
interface ContentText : Content, ValueWithStringRepresentation {

    /**
     * The length of the text in this extract.
     */
    val length: Int

    /**
     * The contiguous ranges of content (possibly transformed) contained in this extract
     */
    val slices: Array<ContentSlice>

    /**
     * Get the text (possibly transformed from the original) contained in this extract
     */
    val text: String

    override val textRepresentation: String
        get() = text

    /**
     * Get the index of the first character in this extract that originated from this cursor.
     *
     * Note: due to transformation and recombination, a given cursor might originate more than one character in this extract
     */
    fun getFirstIndexOfCursor(cursor: ContentCursor): Int

    /**
     * Get the index of the last character in this extract that originated from this cursor
     *
     * Note: due to transformation and recombination, a given cursor might originate more than one character in this extract
     */
    fun getLastIndexOfCursor(cursor: ContentCursor): Int

    /**
     * Get the first cursor in the original content that might have originated this character.
     *
     * Note: due to transformation and recombination, there might not be a unique origin of each character
     */
    fun getFirstCursorAtIndex(characterIndex: Int): ContentCursor

    /**
     * Get the last cursor in the original content that might have originated this character.
     *
     * Note: due to transformation and recombination, there might not be a unique origin of each character
     */
    fun getLastCursorAtIndex(characterIndex: Int): ContentCursor

    /**
     * Indicates whether this cursor is between the [start] and [end], *not* whether this cursor originated a character in this extract.
     */
    fun containsCursor(cursor: ContentCursor): Boolean

    /**
     * Get a new extract from this extract between the specified indexes, end-exclusive.
     */
    fun slice(startIndex: Int, endIndex: Int): ContentText

    /**
     * Replace all matches of the regular expression [pattern] according to the [replacement] rule provided. If the
     * [pattern] contains matched capture groups, this method will apply the [replacement] rule to the first matched
     * capture group of each match. Otherwise, the rule will be applied to the entire match.
     *
     * This contract was chosen because Safari doesn't support lookbehinds at time of writing.
     * Grep for #RationaleForRegexCaptureGroupBehavior for more info.
     */
    fun replaceAll(pattern: String, replacement: ((match: String) -> String)): ContentText

    /**
     * Split the text at each match of the regular expression [pattern]. If the [pattern] contains matched capture
     * groups, this method will split on the first matched capture group of each match. Otherwise, it will split on the
     * entire match.
     *
     * This contract was chosen because Safari doesn't support lookbehinds at time of writing.
     * Grep for #RationaleForRegexCaptureGroupBehavior for more info.
     */
    fun split(pattern: String): Array<ContentText>

    /**
     * Find all matches the regular expression [pattern]. If the [pattern] contains matched capture groups, this method
     * will return the first matched capture group of each match. Otherwise, it will return the entirety of each match.
     *
     * This contract was chosen because Safari doesn't support lookbehinds at time of writing.
     * Grep for #RationaleForRegexCaptureGroupBehavior for more info.
     */
    fun matchAll(pattern: String): Array<ContentText>

    /**
     * Replace the text, retaining all the originating positions
     */
    fun withText(text: String): ContentText
}

fun ContentText?.isEquivalent(
    b: ContentText?,
): Boolean =
    this.isEquivalentByStartAndEndCursors(b) &&
        this?.text == b?.text

/**
 * A convenience version of [ContentText.slice] that takes a [range].
 */
internal fun ContentText.slice(
    range: IntRange,
): ContentText = slice(
    startIndex = range.first,
    endIndex = range.last + 1,
)

internal fun ContentText.slice(
    start: ContentCursor,
    end: ContentCursor,
): ContentText {
    val startIndex = this.getFirstIndexOfCursor(start)
    val endIndex = this.getLastIndexOfCursor(end) + 1
    return slice(startIndex, endIndex)
}

/**
 * Calculate <0,1> fraction of length that the [cursor] points at.
 */
internal fun ContentText.getCursorPositionAsLengthFraction(cursor: ContentCursor): ProgressFraction {
    val length = length

    if (length == 0 || length == 1) {
        return 1.0 /* Use `1.0`, as opposed to, e.g. `0.0` (probably the only other option), to allow detecting empty
         text through this result - if `0.0` was returned for `start`, then one wouldn't be able to tell from such a
         result that there is no content.
         */
    }

    val characterIndex = getFirstIndexOfCursor(cursor)
    return characterIndex.toDouble() / (length - 1)
}

operator fun ContentText.contains(cursor: ContentCursor): Boolean = containsCursor(cursor)
