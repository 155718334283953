package com.speechify.client.api.adapters.localsynthesis

import com.speechify.client.api.audio.VoiceGender
import kotlin.js.JsExport

@JsExport
data class VoiceRef(val get: Any)

@JsExport
data class LocalSynthesisVoice(
    val id: String,
    val displayName: String,
    val languageCode: String,
    val gender: VoiceGender,
    val source: VoiceRef,
)
