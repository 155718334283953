package com.speechify.client.bundlers.reading.importing

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.launchAsync
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorJob
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

/**
 * Provides nothing, however, it represents a [ContentImporterState.ImportedToLibrary] state of content that
 * already exists in the user's library.
 */
internal class AlreadyImportedToLibraryImporter(
    internal val uri: SpeechifyURI,
    /* Set to improve efficiency of loading by saving a request on resolving the library item. */
    libraryItem: LibraryItem.Content,
) : ContentImporter() {

    override val stateFlow: StateFlow<ContentImporterState> =
        MutableStateFlow(ContentImporterState.ImportedToLibrary(uri, libraryItem))

    private val mutex: Mutex = Mutex()

    private val scope = createTopLevelCoroutineScope()

    override suspend fun startImport(options: ImportOptions): Result<SpeechifyURI> =
        uri.successfully()

    override fun setEditsSaveAction(saveEditsAction: suspend (uri: SpeechifyURI) -> Unit): Deferred<SpeechifyURI> =
        launchAsync { // Launch immediately since the content is already imported.
            saveEditsAction(uri)
            return@launchAsync uri
        }

    override fun queueTaskAfterImport(task: suspend (uri: SpeechifyURI) -> Unit) {
        scope.launch(
            // Use `SupervisorJob` to prevent failing the scope if one task fails.
            context = scope.coroutineContext.job.createChildSupervisorJob(),
        ) {
            /** [Mutex.lock] is a FIFO queue as documented there. */
            mutex.withLock {
                task(uri)
            }
        }
    }
}

/**
 * Provides nothing, however, it represents a [ContentImporterState.SpeechifyGlobalResource] state of content that
 * already exists on Speechify like audio books.
 */
internal class SpeechifyGlobalResourceImporter(
    val uri: SpeechifyURI,
) : ContentImporter() {
    override val stateFlow: StateFlow<ContentImporterState> =
        MutableStateFlow(ContentImporterState.SpeechifyGlobalResource(uri))

    override suspend fun startImport(options: ImportOptions): Result<SpeechifyURI> = uri.successfully()

    override fun setEditsSaveAction(saveEditsAction: suspend (uri: SpeechifyURI) -> Unit): Deferred<SpeechifyURI> {
        throw IllegalStateException("queueTaskAfterImport is not supported for SpeechifyGlobalResourceImporter")
    }

    override fun queueTaskAfterImport(task: suspend (uri: SpeechifyURI) -> Unit) {
        throw IllegalStateException("queueTaskAfterImport is not supported for SpeechifyGlobalResourceImporter")
    }
}
