package com.speechify.client.api.util.io

import com.speechify.client.internal.util.collections.arrays.toUint8Array
import com.speechify.client.internal.util.collections.asyncIterable.asAsyncIterable
import js.core.AsyncIterable
import js.typedarrays.Uint8Array
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

/**
 * Allows to read [BinaryContentReadableSequentiallyMultiplatformAPI] as an [AsyncIterable] of [Uint8Array]s.
 */
/*
 * TODO - try to make it an extension method (currently compilation error claims the target class is in a
 *  different package, which doesn't seem to be the case)
 */
@JsExport
fun asyncIterableFromBinaryContentReadableSequentially(
    binaryContentReadableSequentially: BinaryContentReadableSequentiallyMultiplatformAPI,
): AsyncIterable<Uint8Array> =
    (binaryContentReadableSequentially as Flow<ByteArray>)
        .map { it.toUint8Array() }
        .asAsyncIterable()
