package com.speechify.client.internal.util.collections.asyncIterable

import com.speechify.client.internal.util.extensions.collections.sendEnsuringReceivedOrBuffered
import com.speechify.client.internal.util.extensions.coroutines.createTopLevelCoroutineScopeWithCompletableJob
import com.speechify.client.internal.util.io.blob.extensions.ValueWrapper
import js.core.AsyncIterable
import js.core.AsyncIterator
import js.core.JsIterator
import js.core.Symbol
import js.core.Void
import js.core.get
import js.promise.PromiseResult
import js.typedarrays.Int8Array
import js.typedarrays.Uint8Array
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.produceIn

internal fun <T> AsyncIterable<T>.asFlow(): Flow<T> = flow {
    val iterator: AsyncIterator<T> = this@asFlow[Symbol.asyncIterator]()
    while (true) {
        val next = iterator.next().await()
        if (next.done) break
        try {
            emit(
                @Suppress(
                    /* It has to be a `YieldResult` because the `ReturnResult` has `done=true` */
                    "UNCHECKED_CAST_TO_EXTERNAL_INTERFACE",
                )
                (next as JsIterator.YieldResult<T>).value,
            )
            /** The `return` and `throw` statements below are what _"tells the iterator that the caller does not intend
             * to make any more next() calls and can perform any cleanup actions"_](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols#the_iterator_protocol)
             */
        } catch (e: CancellationException) {
            iterator.returnSafelyInvoke(null)
            throw e
        } catch (e: Throwable) {
            iterator.throwSafelyInvoke(e)
            throw e
        }
    }

    iterator.returnSafelyInvoke(null)
}

/**
 * Invokes the [AsyncIterator.throw], making sure the `this` is passed, so that there's no risk of error of:
 * `TypeError: Method [AsyncGenerator].prototype.return called on incompatible receiver undefined`
 * (the generator functions don't allow calling without `this`).
 */
internal fun <T> AsyncIterator<T>.throwSafelyInvoke(arg: Throwable) =
    this.`throw`.asDynamic()?.apply(this, arg)

/**
 * Invokes the [AsyncIterator.return], making sure the `this` is passed, so that there's no risk of error of:
 * `TypeError: Method [AsyncGenerator].prototype.throw called on incompatible receiver undefined`
 * (the generator functions don't allow calling without `this`).
 */
internal fun <T> AsyncIterator<T>.returnSafelyInvoke(arg: PromiseResult<Void>?) =
    this.`return`.asDynamic()?.apply(this, arg)

internal fun <T> Flow<T>.asAsyncIterable(): AsyncIterable<T> =
    createAsyncIterableFromGetAsyncIterator(
        getAsyncIterator = {
            val (scope, completableJob) = createTopLevelCoroutineScopeWithCompletableJob(
                /* `shouldFailIfAnyChildFails` makes no difference here, because we don't reuse the scope */
                shouldFailIfAnyChildFails = true,
            )
            val channel = this
                .map { ValueWrapper(it) }
                .buffer(capacity = Channel.RENDEZVOUS)
                .produceIn(scope)

            return@createAsyncIterableFromGetAsyncIterator createAsyncIteratorFromGetNext(
                getNext = {
                    scope.async<SDKLocalJsYieldResult<T>> {
                        val resultOfNext = channel.receiveCatching()
                        return@async if (resultOfNext.isSuccess) {
                            SDKLocalJsYieldResult<T>(
                                done = false,
                                valueOrNullIfDone = resultOfNext.getOrThrow().value,
                            )
                        } else {
                            when (val exception = resultOfNext.exceptionOrNull()) {
                                null -> {
                                    completableJob.complete()
                                    SDKLocalJsYieldResult(
                                        done = true,
                                        valueOrNullIfDone = null,
                                    )
                                }
                                else -> {
                                    completableJob.completeExceptionally(exception)
                                    throw exception
                                }
                            }
                        }
                    }
                },
            )
        },
    )

/**
 * This function is for internal SDK testing from Node.js environment
 * (see #TestWhetherValueWrapperCanBeRemovedFromChannel where it is called)
 */
@Suppress("FunctionName")
@JsExport
fun SDK_Internal_function_for_testing__assertWrappingValueNeededForChannel(
    /* Need this `any` because, strangely, without parameters there's a compilation error */
    any: Any? = null,
) {
    /** Most strangely, the problem occurs when [Uint8Array] is specified here, while there's no problem if it's [Int8Array]!!! */
    val channel = Channel<Uint8Array>(
        capacity = 1,
    )

    /** Sending the value works just fine */
    channel.sendEnsuringReceivedOrBuffered(Uint8Array(0))

    /** But trying to retrieve it gives a [ClassCastException]!!! */
    try {
        channel.tryReceive().getOrThrow()
    } catch (t: ClassCastException) {
        /** This [ClassCastException] catch is the assertion. It's the sign of the quirk that needs working-around.
         * We `return` without throwing to signify assertion being true.
         */
        return
    }

    /** Throw if [ClassCastException] wasn't thrown */
    throw Exception(
        "No [ClassCastException] occurred. It may be that Kotlin or a library update made the workaround unnecessary." +
            "Check if the workaround is still needed, and remove it if it's not, or fix the test.",
    )
}
