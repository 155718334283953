package com.speechify.client.internal.http

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyVersions.SDK_VERSION
import com.speechify.client.api.adapters.http.BrowserIdentityUserAgentProvider
import com.speechify.client.api.adapters.http.FALLBACK_USER_AGENT
import com.speechify.client.api.adapters.http.HttpClientAdapter
import com.speechify.client.api.adapters.http.HttpMethod
import com.speechify.client.api.adapters.http.HttpRequestBodyData
import com.speechify.client.api.adapters.http.HttpResponse
import com.speechify.client.api.adapters.http.HttpResponseWithBody
import com.speechify.client.api.adapters.http.HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI
import com.speechify.client.api.adapters.http.HttpResponseWithBodyAsByteArray
import com.speechify.client.api.adapters.http.ResponseBodyInterface
import com.speechify.client.api.adapters.http.ResponseHeader
import com.speechify.client.api.adapters.http.consumeBodyAsByteArray
import com.speechify.client.api.adapters.http.getHeaderOrNull
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.audio.SpeechSynthesisRequestTimeout
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Result.Success
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.boundary.toBoundaryPair
import com.speechify.client.api.util.io.BinaryContentReadableInChunks
import com.speechify.client.api.util.io.BinaryContentReadableInChunksWithNativeAPI
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableSequentially
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toFailureFromSuccessIf
import com.speechify.client.api.util.toSdkResult
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.diagnostics.enriching.enrichErrorsByPropertyAdd
import com.speechify.client.internal.util.extensions.intentSyntax.flatten
import com.speechify.client.internal.util.extensions.intentSyntax.getExceptionOrReturn
import com.speechify.client.internal.util.extensions.intentSyntax.mapExceptionTypeToSuccess
import com.speechify.client.internal.util.extensions.intentSyntax.mapSuccessToExceptionIfNotNull
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import com.speechify.client.internal.util.www.UrlString
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.delay
import kotlinx.coroutines.withContext
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationException
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.js.JsExport
import kotlin.js.JsName
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime
import kotlin.time.TimeSource
import kotlin.time.measureTimedValue

internal object SpeechifyHeaders {
    const val APP_VERSION = "speechify-app-version"
    const val SDK_VERSION = "speechify-sdk-version"
    const val APP_ENVIRONMENT = "speechify-app-environment"
    const val X_SPEECHIFY_CLIENT = "X-Speechify-Client"
    const val X_SPEECHIFY_CLIENT_VERSION = "X-Speechify-Client-Version"
}

/**
 * A simple ergonomic [HttpClientAdapter] wrapper
 *
 * ## Examples
 * ```kt
 * val client: HttpClient = ...
 *
 * client.get(URL, callback = { it: Result<HttpResponse> -> })
 *
 * client.get(URL, {
 *      parameter("id", 1)
 * }) { it: Result<HttpResponse> -> }
 *
 * client.post(URL, {
 *      bodyJson(User(name, password))
 * } { it: Result<HttpResponse> -> }
 * ```
 *
 */
internal open class HttpClient(
    private val clientWithMiddleware: HttpClientWithMiddleware,
    private val browserIdentityUserAgentProvider: BrowserIdentityUserAgentProvider,
    private val clientConfig: ClientConfig,
) {

    /**
     * The user agent to send when trying to spoof a browser, for example when downloading web pages for import.
     * We cache this in case the underlying implementation is expensive, and so we only log one error.
     */
    private val browserIdentifyUserAgent = coLazy {
        try {
            browserIdentityUserAgentProvider.getUserAgent()
        } catch (e: Exception) {
            Log.e(
                DiagnosticEvent(
                    message = "Failed to get user agent",
                    nativeError = e,
                    sourceAreaId = "HttpClientWithMiddleware.userAgent",
                ),
            )

            FALLBACK_USER_AGENT
        }
    }

    suspend fun get(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.GET,
            url = url,
            build = build,
        )

    suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> get(
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>> =
        requestBodyAsNativeBinaryContent(
            method = HttpMethod.GET,
            url = url,
            requiredResponseBodyInterface = requiredResponseBodyInterface,
        ) { build() }

    suspend fun post(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        post(
            url,
            canRetryOnResponseNotReceived = null,
            shouldAbortOnCoroutineCancellation = false,
            expectedResponseBodyByteCountBelow = null,
            isErrorResponseAllowingRetry = null,
            telemetryEventBuilder = null,
            build,
        )

    suspend fun post(
        url: String,
        canRetryOnResponseNotReceived: Boolean? = null,
        shouldAbortOnCoroutineCancellation: Boolean,
        expectedResponseBodyByteCountBelow: Int? = null,
        isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
        telemetryEventBuilder: TelemetryEventBuilder? = null,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.POST,
            url = url,
            canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
            shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
            expectedResponseBodyByteCountBelow = expectedResponseBodyByteCountBelow,
            isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
            telemetryEventBuilder = telemetryEventBuilder,
        ) { build() }

    suspend fun patch(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.PATCH,
            url = url,
        ) { build() }

    suspend fun put(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.PUT,
            url = url,
        ) { build() }

    suspend fun delete(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.DELETE,
            url = url,
        ) { build() }

    suspend fun head(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.HEAD,
            url = url,
        ) { build() }

    suspend fun options(
        url: String,
        build: QueryBuilder.() -> Unit = {},
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithByteArrayBody(
            method = HttpMethod.OPTIONS,
            url = url,
        ) { build() }

    private suspend fun requestWithByteArrayBody(
        method: HttpMethod,
        url: String,
        canRetryOnResponseNotReceived: Boolean? = null,
        isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
        /** If `false`, every `cancelAndJoin()` will need to wait for this call to finish.
         *
         * NOTE: the callers can still prevent the abort with `withContext(NonCancellable)`
         */
        shouldAbortOnCoroutineCancellation: Boolean = false,
        expectedResponseBodyByteCountBelow: Int? = null,
        telemetryEventBuilder: TelemetryEventBuilder? = null,
        build: QueryBuilder.() -> Unit,
    ): Result<HttpResponseWithBodyAsByteArray> =
        requestWithTransform(
            method = method,
            url = url,
            requiredResponseBodyInterface = ResponseBodyInterface.Companion.BinaryContentReadableSequentially,
            readAndTransformResponse = { response ->
                HttpResponseWithBodyAsByteArray(
                    status = response.status,
                    headers = response.headers,
                    body = response.consumeBodyAsByteArray(
                        sourceAreaIdForInefficienciesWarnings = "HttpClient.request of url $url",
                        expectedBodyByteCountBelow = expectedResponseBodyByteCountBelow,
                    )
                        .orThrow(),
                )
            },
            canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
            isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
            shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
            telemetryEventBuilder = telemetryEventBuilder,
            build = build,
        )

    private suspend fun <
        B : BinaryContentReadableInChunksWithNativeAPI,
        > requestBodyAsNativeBinaryContent(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        canRetryOnResponseNotReceived: Boolean? = null,
        isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
        /** If `false`, every `cancelAndJoin()` will need to wait for this call to finish.
         *
         * NOTE: the callers can still prevent the abort with `withContext(NonCancellable)`
         */
        shouldAbortOnCoroutineCancellation: Boolean = false,
        telemetryEventBuilder: TelemetryEventBuilder? = null,
        build: QueryBuilder.() -> Unit,
    ): Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>> =
        requestWithTransform(
            method = method,
            url = url,
            requiredResponseBodyInterface = requiredResponseBodyInterface,
            readAndTransformResponse = { it },
            canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
            isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
            shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
            telemetryEventBuilder = telemetryEventBuilder,
            build = build,
        )
            .orReturn { return@requestBodyAsNativeBinaryContent it }
            .let {
                it as HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>
            }
            .successfully()

    /**
     * The main request function, that includes retry logic, and allows some reading-and-transform inside the retry
     * loop (see [readAndTransformResponse]).
     */
    private suspend fun <
        B : BinaryContentReadableInChunksWithNativeAPI,
        TransformedB,
        > requestWithTransform(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        /**
         * Allows to transform the native responses to something else. The function will be performed inside the retry
         * loop, so that, for example, any connectivity problems while reading the response stream will be able to
         * retry (if all conditions for retrying are met, e.g. the request is safe to retry, and the failure didn't
         * fall outside the time-window after which further making the user wait is not allowed).
         */
        readAndTransformResponse: suspend (HttpResponseWithBody<B>) -> TransformedB,
        canRetryOnResponseNotReceived: Boolean? = null,
        isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
        /** If `false`, every `cancelAndJoin()` will need to wait for this call to finish.
         *
         * NOTE: the callers can still prevent the abort with `withContext(NonCancellable)`
         */
        shouldAbortOnCoroutineCancellation: Boolean = false,
        telemetryEventBuilder: TelemetryEventBuilder? = null,
        build: QueryBuilder.() -> Unit,
    ): Result<TransformedB> {
        val builder = QueryBuilder().apply {
            /** TODO - consider moving these to callers of request methods specific to endpoints (if repeatedly needed
             *   put in a common function, or even wrappers of [HttpClientWithMiddleware] if need be), and make the
             *   [HttpClient] methods just extension methods on [HttpClientWithMiddleware]
             *   #TODO_Make_HttpClientWithMiddleware_usable_directly
             */
            if (url.contains(clientConfig.platformPaymentServiceUrl)) {
                // Custom headers for visibility which clients are making calls to our endpoints
                header(
                    SpeechifyHeaders.APP_ENVIRONMENT,
                    when (clientConfig.appEnvironment) {
                        AppEnvironment.DESKTOP_EXTENSION, AppEnvironment.SAFARI_EXTENSION -> "EXTENSION"
                        else -> clientConfig.appEnvironment.name
                    },
                )
                header(SpeechifyHeaders.APP_VERSION, clientConfig.appVersion)
                header(SpeechifyHeaders.SDK_VERSION, SDK_VERSION)
            } else if (url.contains(clientConfig.platformAudioServiceUrl)) {
                header(
                    SpeechifyHeaders.X_SPEECHIFY_CLIENT,
                    when (clientConfig.appEnvironment) {
                        AppEnvironment.ANDROID -> "Android"
                        AppEnvironment.API -> "API"
                        AppEnvironment.DESKTOP_EXTENSION -> "DesktopExtension"
                        AppEnvironment.IOS -> "iOS"
                        AppEnvironment.SAFARI_EXTENSION -> "SafariExtension"
                        AppEnvironment.SOUND_BITES -> "SoundBites"
                        AppEnvironment.WEB_APP -> "WebApp"
                        AppEnvironment.PLATFORM_EXPORT_SERVICE -> "PlatformExportService"
                        AppEnvironment.LANDING_PAGE -> "LandingPage"
                        AppEnvironment.MAC_APP -> "MacApp"
                    },
                )
                header(SpeechifyHeaders.X_SPEECHIFY_CLIENT_VERSION, clientConfig.appVersion)
            }
            build()
        }

        return runCatching {
            /** Catching just to obtain a SDK [Result] easily and thus conform to the historical behavior.
             Once #TODO_Make_HttpClientWithMiddleware_usable_directly is done, the usages can be made not needing
             the SDK [Result] and thus be devoid of catching.
             */
            clientWithMiddleware.sendAndGetResponseIncludingErrorResponse( /**
                 `IncludingErrorResponse`, so passing Error responses even to the top-level caller, as it was done
                 historically. When `#TODO_Make_HttpClientWithMiddleware_usable_directly` is addressed, it will be
                 possible to switch them to `clientWithMiddleware` where they can directly use
                 [sendAndGetResponseThrowingOnErrors] to ensure error reporting.
                 */
                method = method,
                url = url,
                requiredResponseBodyInterface = requiredResponseBodyInterface,
                headers = builder.headers,
                parameters = builder.parameters,
                httpRequestBodyData = builder.httpRequestBodyData,
                canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
                isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
                shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
                telemetryEventBuilder = telemetryEventBuilder,
            )
                .let { response -> readAndTransformResponse(response) }
        }
            .enrichErrorsByPropertyAdd(
                "url" to url,
            )
            .toSdkResult()
    }

    class QueryBuilder {
        var httpRequestBodyData: HttpRequestBodyData? = null

        internal val parameters: MutableMap<String, String> = mutableMapOf()
        internal val headers: HeaderMap = HeaderMap()

        fun parameter(k: String, v: String) {
            parameters[k] = v
        }

        fun header(k: String, v: String) {
            headers[k] = v
        }

        fun auth(scheme: String, token: String) {
            headers["Authorization"] = "$scheme $token"
        }

        /**
         * Adds a body to the request, also adds the header `Content-Type: application/json`
         */
        inline fun <reified T> bodyJson(t: T) {
            header("Content-Type", "application/json")
            httpRequestBodyData = HttpRequestBodyData.BodyBytes(
                body = Json.encodeToString(t).encodeToByteArray(),
            )
        }

        fun formData(
            entries: Map<
                String,
                BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableInChunks>,
                >,
        ) {
            this.httpRequestBodyData = HttpRequestBodyData.MultipartFormData(
                entries = entries.toBoundaryMap(),
            )
        }
    }

    data class WebFileMetadata(val contentType: String, val size: Int?, val acceptsByteRangeRequests: Boolean)

    suspend fun getFileMetadata(url: String): Result<WebFileMetadata> {
        val resp = head(url).orReturn { return it }
        if (!resp.ok) {
            return Result.Failure(
                SDKError.HttpError(
                    resp.status,
                    "status not in range 200-299 while fetching metadata",
                ),
            )
        }
        val contentType = resp.headers["Content-Type"] ?: "text/plain"
        val size = resp.headers["Content-Length"]?.toIntOrNull()
        val acceptsByteRangeRequests = resp.headers["Accept-Ranges"] == "bytes"
        return Success(WebFileMetadata(contentType, size, acceptsByteRangeRequests))
    }

    suspend fun getBinaryContentReadableRandomly(
        url: UrlString,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> =
        getBinaryContentReadableInChunks(
            url = url,
            requiredResponseBodyInterface = ResponseBodyInterface.Companion.BinaryContentReadableRandomly,
        )

    suspend fun getBinaryContentReadableSequentially(
        url: UrlString,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>> =
        getBinaryContentReadableInChunks(
            url = url,
            requiredResponseBodyInterface = ResponseBodyInterface.Companion.BinaryContentReadableSequentially,
        )

    /**
     * Downloads a file from the given URL, making sure that a memory-efficient interface is used
     * (by limiting the [B] to subtypes of [BinaryContentReadableInChunks]).
     */
    private suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> getBinaryContentReadableInChunks(
        url: UrlString,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<B>> {
        // We include the user agent when downloading files to prevent websites from rejecting us during HTML import.
        val userAgent = browserIdentifyUserAgent.get()
        return get(
            url = url,
            requiredResponseBodyInterface = requiredResponseBodyInterface,
            build = {
                if (userAgent != null) {
                    this.headers["User-Agent"] = userAgent
                }
            },
        )
            .toFailureFromSuccessIf(
                predicate = { it.ok.not() },
                errorForFailure = { response ->
                    SDKError.HttpError(
                        response.status,
                        "'$url' couldn't be downloaded",
                    )
                },
            )
            .orReturn { return it }
            .let { response ->
                /** Convert to non-nullable body [BinaryContentWithMimeTypeFromNativeReadableInChunks]: */
                val body = response.body
                    ?: return Result.Failure(
                        SDKError.OtherException(
                            /* Use an exception to know the entry-point via the stacktrace */
                            Exception("The response was successful, but had no body")
                                .apply { addCustomProperty("url", url) },
                        ),
                    )
                body.withMimeType(response.mimeType)
                    .successfully()
            }
    }
}

private val jsonDecoder = Json { ignoreUnknownKeys = true }

@Suppress("FunctionName")
internal fun <T> __jsonDecoder(deserializationStrategy: DeserializationStrategy<T>): (bytes: ByteArray) -> T = {
    jsonDecoder.decodeFromString(deserializationStrategy, it.decodeToString())
}

internal suspend inline fun <reified T> Result<HttpResponse>.parse(): Result<T> = parse(__jsonDecoder(serializer()))
internal suspend inline fun <reified T> Result<HttpResponse>.parse(
    decoder: (ByteArray) -> T,
    sourceAreaIdForInefficienciesWarnings: String = "parse.withDecoder",
): Result<T> {
    val response = this.orReturn { return it }

    return when (response.status) {
        in (200 until 400) -> response.parse(
            sourceAreaIdForInefficienciesWarnings = sourceAreaIdForInefficienciesWarnings,
            decoder = decoder,
        )

        else -> __handleParseError(
            response = response,
            bodyByteArray = response.consumeBodyAsByteArray(
                sourceAreaIdForInefficienciesWarnings = "parse.errorResponseBody",
            )
                .orReturn { return it },
        )
    }
}

internal suspend inline fun <reified T> HttpResponse.parse(
    sourceAreaIdForInefficienciesWarnings: String = "parse.jsonBody",
): Result<T> =
    parse(
        decoder = __jsonDecoder(serializer()),
        sourceAreaIdForInefficienciesWarnings = sourceAreaIdForInefficienciesWarnings,
    )

internal suspend inline fun <reified T> HttpResponse.parse(
    decoder: (ByteArray) -> T,
    sourceAreaIdForInefficienciesWarnings: String,
): Result<T> {
    val bodyAsByteArray = this.consumeBodyAsByteArray(
        sourceAreaIdForInefficienciesWarnings = sourceAreaIdForInefficienciesWarnings,
    )
        .orReturn { return it }
        ?: return Result.Failure(SDKError.Serialization(SerializationException("null body"), "(null)", T::class))

    return try {
        decoder(bodyAsByteArray).successfully()
    } catch (e: SerializationException) {
        Result.Failure(SDKError.Serialization(e, bodyAsByteArray, T::class))
    }
}

internal suspend fun Result<HttpResponse>.asUnitResult(): Result<Unit> {
    val response = when (this) {
        is Result.Failure -> return this
        is Success -> this.value
    }
    return when (response.status) {
        in (200 until 400) ->
            Success(Unit)

        else -> __handleParseError(
            response,
            response.consumeBodyAsByteArray(
                sourceAreaIdForInefficienciesWarnings = "asUnitResult.errorResponseBody",
            )
                .orReturn { return it },
        )
    }
}

// Reduce bundle size, by moving parts of the non-generic code to non-inline functions
suspend fun __handleParseError(response: HttpResponse, bodyByteArray: ByteArray?): Result.Failure {
    val body = if (bodyByteArray == null) {
        "no body"
    } else {
        try {
            jsonDecoder.decodeFromString<Error>(bodyByteArray.decodeToString()).message
        } catch (e: SerializationException) {
            bodyByteArray.decodeToString()
        }
    }
    return Result.Failure(
        SDKError.HttpError(
            response.status,
            message = "Body: $body",
            response,
        ),
    )
}

@kotlinx.serialization.Serializable
private data class Error(val message: String, val code: String? = null, val statusCode: Short? = null)

@JsExport
data class HeaderMap private constructor(
    private val map: MutableMap<String, String>,
) : BoundaryMap<String> {

    @JsName("createEmpty")
    internal constructor() : this(mutableMapOf())

    override fun hasKey(key: String): Boolean = map.containsKey(key.lowercase())

    override fun get(key: String): String? = map[key.lowercase()]

    override fun set(key: String, value: String) {
        map[key.lowercase()] = value
    }

    override fun keys(): Array<String> = map.keys.toTypedArray()

    override fun entries(): Array<BoundaryPair<String, String>> = map.entries.map { it.toBoundaryPair() }.toTypedArray()

    companion object {
        internal fun fromMap(m: BoundaryMap<String>): HeaderMap {
            val map = HeaderMap()
            for (k in m.keys()) {
                map[k] = m[k]!!
            }
            return map
        }

        internal fun fromMap(m: Map<String, String>): HeaderMap =
            fromMap(m.toBoundaryMap())
    }
}

/**
 * Contains the single method for HTTP requests, with all options, where any middleware behavior can be added
 * (inside the method or as a wrapper).
 * TODO: This class could be made usable directly, but currently some areas need the extra behavior that is being added
 *  in outer classes - see #TODO_Make_HttpClientWithMiddleware_usable_directly
 */
internal class HttpClientWithMiddleware(
    private val adapter: HttpClientAdapter,
) {

    /**
     * This function is for ease of implementation only, and is not intended to be called directly. To use the client,
     * rather than calling this method, call [sendAndGetResponseThrowingOnErrors] or
     * [sendAndGetResponseIncludingErrorResponse] extension methods to make the decision around errors a clearly
     * intentful one.
     *
     * The reason this function returns [kotlin.Result] is especially so that uses where there's no throw don't get
     * "'exception thrown' breakpoints" triggered, and so that any performance overhead of 'throwing and catching' is
     * avoided - #ReturningResultNotThrowingConvention
     *
     * NOTE: On retries behavior: If a failure was retried but none of the retries ended in success, then the exception
     * in the [kotlin.Result] returned will be for the *first* failure, but it will have non-empty
     * [Throwable.suppressedExceptions] from *later* retries. The rationale for this is especially because the first
     * exception may be the only one caused by the root cause. #ExceptionFromFirstTryIsTheOnePropagated
     *
     * @exception [HttpReceivedUnsuccessfulResponseError] returned when response was received, but it had a unsuccessful
     * status code.
     * @exception [Throwable] returned when there was an attempt to send the request, but there was
     * no response or the response is unknown, for example connectivity with service was lost while reading the
     * response, parsing encountered a bug, etc.
     */
    @OptIn(ExperimentalTime::class)
    suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> send(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        headers: HeaderMap?,
        parameters: Map<String, String>?,
        httpRequestBodyData: HttpRequestBodyData?,
        /**
         * If there was no response and the value is:
         * * `true` - retries will happen.
         * * `null` - retries will happen based on [method]'s [HttpMethod.canRetryOnResponseNotReceived].
         * * `false` - retries will not happen, regardless of [method]'s [HttpMethod.canRetryOnResponseNotReceived].
         */
        canRetryOnResponseNotReceived: Boolean? = null,
        /**
         * This is only consulted when the response did not return a `Retry-After` header that SDK understood.
         * Use to permit retrying using the default backoff strategy that is also used for no-response
         * (e.g. network errors).
         */
        isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
        /** If `false`, every `cancelAndJoin()` will need to wait for this call to finish.
         *
         * NOTE: the callers can still prevent the abort with `withContext(NonCancellable)`
         */
        shouldAbortOnCoroutineCancellation: Boolean = false,
        telemetryEventBuilder: TelemetryEventBuilder? = null,
    ): kotlin.Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>> {
        /**
         * The information we need for retries telemetry.
         */
        class FailedAttemptData(
            val duration: Duration,
            val exception: Throwable?,
        )

        /**
         * Failed attempts, ordered chronologically. First item in this list is the first failure.
         */
        val failedAttemptsFromFirstToLast = mutableListOf<FailedAttemptData>()

        return runCatching {
            withContext(
                if (shouldAbortOnCoroutineCancellation) {
                    EmptyCoroutineContext /* Empty, so, no change, so that cancellation will reach the below
             `suspendCancellableCoroutine`.*/
                } else {
                    NonCancellable
                },
                /* `NonCancellable` makes cancellations not reach the `suspendCancellableCoroutine` */
            ) {
                /**
                 * The hard limit that we don't want the user to wait longer than
                 * (the user's wait still may exceed this if any individual request takes a long time).
                 *
                 * Equivalent to [Polly's `TimeoutPolicy` outside of `RetryPolicy`](https://github.com/App-vNext/Polly/wiki/Timeout#combining-timeout-with-retries).
                 */
                val ceaseRetriesIfDurationOver =
                    /** TODO - this could be parametrized and just have a default, but for now [SpeechSynthesisRequestTimeout]
                     *   is a good start, as HTTP retries were introduced for AudioServer's speech synthesis and the
                     *   duration there is already a good conservative start for any use case (it's short so doesn't
                     *   make the user wait too long).
                     */
                    SpeechSynthesisRequestTimeout

                /**
                 * NOTE: This is the default wait after first failure. The actual may be:
                 * * less than this, if waiting this much would make us exceed [ceaseRetriesIfDurationOver]
                 * * more that this, if specified by the server in a `Retry-After` header of the response
                 */
                val firstDefaultBackoffWait = 1.5.seconds

                /**
                 * To prevent hammering the server with immediate retries. Don't make this one negligible.
                 *
                 * NOTE: This also means that we need to be more than [minimumBackoffWait] before [ceaseRetriesIfDurationOver]
                 * or we will give up retrying.
                 */
                val minimumBackoffWait = 0.5.seconds

                /**
                 * The function deciding the method of incrementation of the backoff.
                 */
                fun getNextBackoffWait(currentBackoffWait: Duration): Duration =
                    /* Exponential backoff. Lower exponent than the [Polly's default of 2](https://github.com/Polly-Contrib/Polly.Contrib.WaitAndRetry#wait-and-retry-with-exponential-back-off)
                     because we have a relatively high [firstDefaultBackoffWait] and low [ceaseRetriesIfDurationOver] */
                    currentBackoffWait * 1.3

                val startTime = TimeSource.Monotonic.markNow()
                val ceaseRetriesAfter = startTime + ceaseRetriesIfDurationOver

                /**
                 * The first exception that we got, if any. This will be the one returned if we don't succeed, as per
                 * #ExceptionFromFirstTryIsTheOnePropagated.
                 */
                var firstError: Throwable? = null

                /**
                 * This default backoff will only be used and incremented if we don't have a `Retry-After` from the server.
                 */
                var currentDefaultBackoffWait: Duration = firstDefaultBackoffWait

                do {
                    val (result, thisAttemptDuration) = measureTimedValue {
                        runCatching {
                            adapter.coSend(
                                method = method,
                                url = url,
                                requiredResponseBodyInterface = requiredResponseBodyInterface,
                                headers = headers ?: HeaderMap(),
                                parameters = parameters ?: emptyMap(),
                                httpRequestBodyData = httpRequestBodyData,
                            )
                        }
                    }

                    val thisAttemptException = result
                        .mapSuccessToExceptionIfNotNull {
                            if (it.ok) {
                                null
                            } else {
                                HttpReceivedUnsuccessfulResponseError(response = it)
                            }
                        }
                        .getExceptionOrReturn {
                            // This is where the success happens:
                            return@withContext result
                        }

                    if (thisAttemptException is CancellationException) {
                        /* Cancellations mean the user won't care about the result, so we don't need to retry.
                           Notably this is one exception to the #ExceptionFromFirstTryIsTheOnePropagated rule, but
                           the only right thing to keep the Kotlin cancellation convention, and quite sensible to
                           ignore anyway - the user won't raise issue to investigate as they don't care.
                         */
                        return@withContext result
                    }

                    failedAttemptsFromFirstToLast += FailedAttemptData(
                        duration = thisAttemptDuration,
                        exception = thisAttemptException,
                    )

                    if (firstError == null) {
                        firstError = thisAttemptException
                    } else {
                        firstError.addSuppressed(thisAttemptException)
                    }

                    fun getErrorToReturn():
                        kotlin.Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>> {
                        /* Don't throw, as per #ReturningResultNotThrowingConvention */
                        return kotlin.Result.failure(
                            /** We decide to return the first failure as per #ExceptionFromFirstTryIsTheOnePropagated,
                             though its [Throwable.suppressedExceptions] will still contain all outcomes of the
                             retries. */
                            firstError,
                        )
                    }

                    if (ceaseRetriesAfter.hasPassedNow()) {
                        return@withContext getErrorToReturn()
                    }

                    val remainingDurationForRetries = ceaseRetriesIfDurationOver - thisAttemptDuration

                    val thisResponse = (thisAttemptException as? HttpReceivedUnsuccessfulResponseError)?.response

                    val retryAfter =
                        thisResponse?.getHeaderOrNull(ResponseHeader.RetryAfter)
                            ?: run {
                                val shouldRetryWithDefaultWait =
                                    if (thisResponse != null) {
                                        isErrorResponseAllowingRetry?.invoke(thisResponse) == true
                                    } else {
                                        canRetryOnResponseNotReceived ?: method.canRetryOnResponseNotReceived
                                    }

                                // Bail out entirely if no retry is allowed
                                if (!shouldRetryWithDefaultWait) {
                                    return@withContext getErrorToReturn()
                                }

                                return@run currentDefaultBackoffWait
                                    .coerceAtMost(
                                        remainingDurationForRetries
                                            .coerceAtLeast(
                                                minimumBackoffWait,
                                            ),
                                    )
                                    .also {
                                        currentDefaultBackoffWait = getNextBackoffWait(currentDefaultBackoffWait)
                                    }
                            }

                    if (retryAfter > remainingDurationForRetries) {
                        return@withContext getErrorToReturn()
                    }

                    delay(retryAfter)
                } while (true)
                @Suppress(
                    "UNREACHABLE_CODE", // Seems to be the only way to make compiler understand the return type.
                )
                throw IllegalStateException(
                    "Unreachable", // If this is reached, then there's a bug in the code above.
                )
            }
        }.flatten()
            .also { result ->
                if (telemetryEventBuilder != null) {
                    /**
                     * Shared telemetry field - thanks to this one we can filter telemetry where retries happened on a
                     * single field (when it's there, there were retries).
                     */
                    val failedAttemptsCountPropertyKey = "failedAttemptsCount"
                    result.fold(
                        onSuccess = {
                            if (failedAttemptsFromFirstToLast.size > 0) {
                                /** Let's only produce retry telemetry fields for
                                 where there were any retries to allow filter on existence of fields, and also because telemetry
                                 fields already exist for success in [com.speechify.client.api.telemetry.withTelemetry],
                                 e.g. duration.
                                 */
                                telemetryEventBuilder.addProperty(
                                    key = failedAttemptsCountPropertyKey,
                                    value = failedAttemptsFromFirstToLast.size,
                                )
                                telemetryEventBuilder.addProperty(
                                    key = "failedAttemptsDurationMs",
                                    value = failedAttemptsFromFirstToLast.sumOf { it.duration.inWholeMilliseconds },
                                )
                                telemetryEventBuilder.addProperty(
                                    key = "failureMessages",
                                    value = failedAttemptsFromFirstToLast
                                        .mapNotNull { it.exception?.message }
                                        .distinct()
                                        .let {
                                            "${it.first()}${if (it.size <= 1) "" else " and ${it.size - 1} more"}"
                                        },
                                )
                            }
                        },
                        onFailure = {
                            // Cancellations mean the user won't care about the result, so let's leave ASAP.
                            if (it is CancellationException) {
                                return@fold
                            }

                            if (failedAttemptsFromFirstToLast.size > 1) {
                                /** For errors, let's also not produce retry telemetry fields when there were no retries,
                                 to allow filter on existence of fields, and also because telemetry fields for failure
                                 already exist in [com.speechify.client.api.telemetry.withTelemetry], e.g. error message and
                                 duration.
                                 */
                                telemetryEventBuilder.addProperty(
                                    key = failedAttemptsCountPropertyKey,
                                    value = failedAttemptsFromFirstToLast.size,
                                )

                                val distinctMessages = failedAttemptsFromFirstToLast
                                    .mapNotNull { it.exception?.message }
                                    .distinct()
                                if (distinctMessages.size > 1) {
                                    /** Add error messages only if different were found, as the first one already gets reported
                                     by [com.speechify.client.api.telemetry.withTelemetry] because this is a failure case.
                                     */
                                    telemetryEventBuilder.addProperty(
                                        key = "retryErrorMessagesNotSameAsFirst",
                                        value = distinctMessages
                                            .drop(1)
                                            .let {
                                                "${it.first()}${if (it.size <= 1) "" else " and ${it.size - 1} more"}"
                                            },
                                    )
                                }
                            }
                        },
                    )
                }
            }
    }
}

/**
 * The overload that returns [HttpResponse] **even in case it is not successful**.
 * Mostly useful to internal code - higher level code should prefer [sendAndGetResponseThrowingOnErrors] to ensure
 * correct reporting of errors.
 *
 * @exception [Throwable] when there was an attempt to send the request, but there was no response or the response is
 * unknown, for example connectivity with service was lost while reading the response, parsing encountered a bug, etc.
 *
 * See [HttpClientWithMiddleware.send] for documentation of parameters.
 */
internal suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> HttpClientWithMiddleware
.sendAndGetResponseIncludingErrorResponse(
    method: HttpMethod,
    url: String,
    requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
    headers: HeaderMap?,
    parameters: Map<String, String>?,
    httpRequestBodyData: HttpRequestBodyData?,
    canRetryOnResponseNotReceived: Boolean? = null,
    isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
    shouldAbortOnCoroutineCancellation: Boolean = false,
    telemetryEventBuilder: TelemetryEventBuilder? = null,
): HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B> =
    send(
        method = method,
        url = url,
        requiredResponseBodyInterface = requiredResponseBodyInterface,
        headers = headers,
        parameters = parameters,
        httpRequestBodyData = httpRequestBodyData,
        canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
        isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
        shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
        telemetryEventBuilder = telemetryEventBuilder,
    )
        .mapExceptionTypeToSuccess(
            getSuccessFromExceptionOrNull = { unsuccessfulResponseError: HttpReceivedUnsuccessfulResponseError ->
                unsuccessfulResponseError.response
                    as HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>
            },
        )
        .getOrThrow()

/**
 * The overload that only returns [HttpResponse] **if it was successful**. Should be preferred by higher level code to
 * ensure correct reporting of errors. A `catch (e: HttpReceivedUnsuccessfulResponseError)` can
 * be added for translating of known responses and `throw e` should be used to propagate the unknown ones to the
 * caller and, ultimately, diagnostic reporters.
 *
 * @exception [HttpReceivedUnsuccessfulResponseError] returned when response was received, but it had a successful
 * status code.
 * @exception [Throwable] returned when there was an attempt to send the request, but there was
 * no response or the response is unknown, for example connectivity with service was lost while reading the
 * response, parsing encountered a bug, etc.
 *
 * See [HttpClientWithMiddleware.send] for documentation of parameters.
 */
internal suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> HttpClientWithMiddleware
.sendAndGetResponseThrowingOnErrors(
    method: HttpMethod,
    url: String,
    requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
    headers: HeaderMap?,
    parameters: Map<String, String>?,
    httpRequestBodyData: HttpRequestBodyData?,
    canRetryOnResponseNotReceived: Boolean? = null,
    isErrorResponseAllowingRetry: ((HttpResponse) -> Boolean)? = null,
    shouldAbortOnCoroutineCancellation: Boolean = false,
    telemetryEventBuilder: TelemetryEventBuilder? = null,
): HttpResponse =
    send(
        method = method,
        url = url,
        requiredResponseBodyInterface = requiredResponseBodyInterface,
        headers = headers,
        parameters = parameters,
        httpRequestBodyData = httpRequestBodyData,
        canRetryOnResponseNotReceived = canRetryOnResponseNotReceived,
        isErrorResponseAllowingRetry = isErrorResponseAllowingRetry,
        shouldAbortOnCoroutineCancellation = shouldAbortOnCoroutineCancellation,
        telemetryEventBuilder = telemetryEventBuilder,
    )
        .getOrThrow()

internal class HttpReceivedUnsuccessfulResponseError(
    val response: HttpResponseWithBody<*>,
) : Throwable("HTTP response unsuccessful status of: ${response.status}")

/**
 * For SDK internal use (the only reason it's public is that compiler doesn't allow to have internal base classes).
 * Contains the single entry point to the HTTP adapter in the form of an SDK idiomatic `suspend` function.
 */
abstract class HttpClientAdapterRawBase {
    /**
     * NOTE: The method will throw if it was not capable of getting a response. This may include:
     * * network issue causing communication problem (NOTE: it's not known if the request was received and
     *   processed by the service or not)
     * * no internet connection on client device (NOTE: it's not known if the request was received and
     *   processed by the service or not)
     * * invalid server address (hostname, IP or port)
     * * error in establishing secure connection (e.g. certificate validation)
     * * service is down
     */
    internal abstract suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> coSend(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        headers: HeaderMap,
        parameters: Map<String, String>,
        httpRequestBodyData: HttpRequestBodyData?,
    ): HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>
}
