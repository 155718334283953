package com.speechify.client.api.content

import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.boundary.toBoundary
import com.speechify.client.internal.util.findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch
import kotlin.js.JsExport
import kotlin.math.ceil
import kotlin.math.floor
import kotlin.math.max
import kotlin.math.min

/**
 * A [TextElementContentSlice] is a representation of [text] originating from a contiguous [range] underneath a single [element] in the original content. The [text] is not constrained in any way by the size of the [range] or the original text in that range - in this way, the [TextElementContentSlice] lets us model arbitrary transformations of content while retaining a mapping between each character of the resulting [text] and indexes from the original [range].
 * @param element A reference to the [ContentElementReference] containing the content from which this text "originated"
 * @param range A reference to the specific slice of text contained by the [element] from which this text "originated"
 * @param text An arbitrary string
 *
 * To illustrate this model, consider the content "WORD".
 *
 * Initially,
 * positions:  [W|O|R|D]
 * characters: [W|O|R|D]
 *
 * Then we can expand it to "PLATFORM" - observe that each position now maps to 2 characters
 * positions:  [ W | O | R | D ]
 * characters: [P|L|A|T|F|O|R|M]
 *
 * Alternately, we can contract it to "HI" - observe that 2 positions now map to each character
 * positions:  [W|O|R|D]
 * characters: [ H | I ]
 *
 * The [TextElementContentSlice] maps positions to character indexes, and vice versa.
 * These mappings aren't unique, so we offer APIs to get the first and last mapping each way.
 * Conceptually, we compute the mapping by
 * 1. "lining up" the two arrays, scaled equally
 * 2. following the line from the first/last bounds of an index across to the other array
 *
 */
@JsExport
data class TextElementContentSlice(
    internal val element: ContentElementReference,
    override val range: BoundaryPair<Int, Int>,
    override val text: String,
    override val metadata: TextMetadata = TextMetadata(),
) : ContentSlice {
    internal constructor(
        elementReference: ContentElementReference,
        range: Pair<Int, Int>,
        text: String,
        textMetadata: TextMetadata = TextMetadata(),
    ) : this(elementReference, range.toBoundary(), text, textMetadata)

    // Store these since we'll be using them a lot in inner loops
    override val start = element.getPosition(range.first)

    /**
     *  NOTE: The `end` cursor for [ContentText] is the last character! This is not the same as a typical Range,
     * or the `ContentElementReference.end`. #ContentTextEndCursorIsAtLastCharacter.
     */
    override val end = element.getPosition(range.second - 1)

    override val length: Int get() = text.length

    override val slices: Array<ContentSlice> by lazy { arrayOf(this) }

    override val rootElement: ContentElementReference get() = element

    override fun getFirstIndexOfCursor(cursor: ContentCursor): Int {
        val characterIndex = cursor.getClosestPositionBetween(start, end).characterIndex
        val rangeLength = range.second - range.first
        val textLength = text.length

        // Range cannot be empty so no zero div error
        val textMappingExpansionRatio = textLength.toDouble() / rangeLength.toDouble()
        val indexOfClosestPositionInSlice = characterIndex - start.characterIndex
        val lowerBoundOfMappedPosition = indexOfClosestPositionInSlice * textMappingExpansionRatio

        // NOTE(anson) See diagrams for intuition about use of floor here
        val indexInMappedText = floor(lowerBoundOfMappedPosition)
        return indexInMappedText.toInt().coerceAtMost(text.lastIndex)
    }

    override fun getLastIndexOfCursor(cursor: ContentCursor): Int {
        val position = cursor.getClosestPositionBetween(start, end)
        val rangeLength = range.second - range.first
        val textLength = text.length

        // Range cannot be empty so no zero div error
        val textMappingExpansionRatio = textLength.toDouble() / rangeLength.toDouble()
        val indexOfClosestPositionInSlice = position.characterIndex - start.characterIndex
        val upperBoundOfMappedPosition =
            (indexOfClosestPositionInSlice + 1) * textMappingExpansionRatio

        // NOTE(anson) See diagrams for intuition about use of ceil and minus one here
        val indexInMappedText = ceil(upperBoundOfMappedPosition) - 1
        return indexInMappedText.toInt().coerceAtMost(text.lastIndex)
    }

    override fun getFirstCursorAtIndex(characterIndex: Int): ContentTextPosition {
        return if ((characterIndex <= 0) || this.text.isEmpty()) {
            this.start
        } else {
            val rangeLength = range.second - range.first
            val textLength = this.text.length

            // No zero div because we know text is nonempty
            val step = rangeLength.toDouble() / textLength.toDouble()
            val clippedIndex = characterIndex.coerceIn(0..this.text.lastIndex)

            // NOTE(anson) See diagrams for intuition about use of floor here
            val offset = floor(clippedIndex * step).toInt()
            element.getPosition(start.characterIndex + offset)
        }
    }

    override fun getLastCursorAtIndex(characterIndex: Int): ContentTextPosition {
        return if ((characterIndex >= this.length - 1) || this.text.isEmpty()) {
            this.end
        } else {
            val rangeLength = range.second - range.first
            val textLength = this.text.length

            // No zero div because we know text is nonempty
            val step = rangeLength.toDouble() / textLength
            val clippedIndex = characterIndex.coerceIn(0..this.text.lastIndex)

            // NOTE(anson) See diagrams for intuition about use of ceil and minus one here
            val offset = ceil((clippedIndex + 1) * step).toInt() - 1
            element.getPosition(start.characterIndex + offset)
        }
    }

    override fun containsCursor(cursor: ContentCursor): Boolean {
        return !cursor.isBefore(this.start) && !cursor.isAfter(this.end)
    }

    override fun replaceAll(pattern: String, replacement: (match: String) -> String): ContentText =
        sequence {
            var currentStartIdxInclusive = 0

            val wholeElement = this@TextElementContentSlice

            for (
            range in findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(
                pattern,
                this@TextElementContentSlice.text,
            )
            ) {
                yield(wholeElement.slice(currentStartIdxInclusive, range.startIndex))

                yield(
                    wholeElement.slice(range.startIndex, range.endIndexExclusive)
                        .withText(
                            replacement(
                                this@TextElementContentSlice.text.substring(
                                    range.startIndex,
                                    range.endIndexExclusive,
                                ),
                            ),
                        ),
                )

                currentStartIdxInclusive = range.endIndexExclusive
            }

            // yield the remaining unmatched text at the end, if any
            if (currentStartIdxInclusive < wholeElement.length) {
                yield(
                    if (currentStartIdxInclusive == 0) {
                        wholeElement
                    } else {
                        wholeElement.slice(currentStartIdxInclusive, wholeElement.length)
                    },
                )
            }
        }
            .filterNot { it.text.isEmpty() }
            .toList()
            .let {
                if (it.isEmpty()) {
                    this@TextElementContentSlice.withText("")
                } else {
                    CompositeContentText.fromSlices(it)
                }
            }

    override fun split(pattern: String): Array<ContentText> {
        if (this.length == 0) return arrayOf(this)

        // Return match range or range of first capture group if available
        val ranges = findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text)

        val (lastEnd, results) = ranges.fold(0 to listOf<ContentText>()) { acc, range ->
            range.endIndexExclusive to (acc.second + this.slice(acc.first, range.startIndex))
        }
        return if (lastEnd <= this.text.lastIndex) {
            (results + this.slice(lastEnd, this.length)).toTypedArray()
        } else {
            results.toTypedArray()
        }
    }

    override fun matchAll(pattern: String): Array<ContentText> {
        return findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(pattern, this.text).map {
            this.slice(it.startIndex, it.endIndexExclusive)
        }.toList().toTypedArray()
    }

    /**
     * Take a sub-slice of this slice, using the usual array slice indexing semantics.
     * @param startIndex the first index to include in the slice
     * @param endIndex the index _after_ the last index to include in the slice
     * @return a new [TextElementContentSlice] containing all the content in this one from [startIndex, endIndex)
     */
    override fun slice(startIndex: Int, endIndex: Int): TextElementContentSlice {
        val startPosition = this.getFirstCursorAtIndex(startIndex)
        val endPosition = this.getLastCursorAtIndex((endIndex - 1).coerceAtLeast(startIndex))
        val range = startPosition.characterIndex to endPosition.characterIndex + 1
        val clippedStart = max(0, minOf(text.lastIndex, endIndex, startIndex))
        val clippedEnd = min(text.length, maxOf(0, endIndex, clippedStart))
        return TextElementContentSlice(element, range, text.substring(clippedStart, clippedEnd), metadata)
    }

    /**
     * Transform this slice by mapping it to new text, retaining the originating [element] and [range]
     * @param text the new text
     * @return a new slice with the [text] provided but the same range and [element] as this instance
     */
    override fun withText(text: String): ContentSlice {
        return TextElementContentSlice(element, range, text, metadata)
    }

    override fun withMetadata(newMetadata: TextMetadata): TextElementContentSlice {
        return TextElementContentSlice(element, range, text, newMetadata)
    }

    override fun toString() =
        "TextElementContentSlice(range=$range, start=$start, end=$end, text=$text, metadata=$metadata)"

    companion object {
        /**
         * Construct a [TextElementContentSlice] from [elementReference] to a content and all the [text] it contains
         * @param elementReference a reference to an element in the content tree
         * @param text all the text contained by [elementReference] in the content tree
         * @return a slice representing the content provided
         */
        fun fromTextElement(
            elementReference: ContentElementReference,
            text: String,
            metadata: TextMetadata = TextMetadata(),
        ): TextElementContentSlice {
            val range = 0 to text.length
            return TextElementContentSlice(elementReference, range, text, metadata)
        }
    }
}
