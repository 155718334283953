package com.speechify.client.reader.core

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.content.view.book.coSearch
import com.speechify.client.api.util.Destructible
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import com.speechify.client.bundlers.reading.epub.EpubReadingBundle
import com.speechify.client.internal.util.extensions.collections.flows.mapStateFlow
import com.speechify.client.reader.classic.ClassicReader
import com.speechify.client.reader.classic.ClassicViewHelper
import com.speechify.client.reader.core.utils.SelectionUtils
import com.speechify.client.reader.epub.EpubReader
import com.speechify.client.reader.epub.EpubReaderConfig
import com.speechify.client.reader.epub.EpubViewHelper
import com.speechify.client.reader.fixedlayoutbook.BookThumbnailsHelper
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutBookReader
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutBookViewHelper
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutReaderConfig
import com.speechify.client.reader.fixedlayoutbook.overlay.getOverlayStrategy
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.filterIsInstance
import kotlin.js.JsExport

/**
 * The [ListeningExperience] affords all the features of the Speechify listening experience within a single context
 * with a single lifecycle. We expose functionality for specific _reading experiences_ via the [Reader] factory APIs,
 * which ensure that [Reader]s are constructed efficiently with as much shared state as possible, and can be disposed
 * safely without destroying the shared state.
 */
@JsExport
class ListeningExperience internal constructor(
    internal val scope: ReaderScope,
    val titleHelper: TitleHelper,
    val importHelper: ImportHelper,
    val playbackHelper: PlaybackHelper,
    val scrubberHelper: ScrubberHelper,
    val userHighlightsHelper: UserHighlightsHelper,
    val selectionHelper: SelectionHelper,
    val selectionUtils: SelectionUtils,
    val autoscrollHelper: AutoscrollHelper,
    val hoveredSentenceHelper: HoveredSentenceHelper,
    val analyticsHelper: AnalyticsHelper,
    val listenedWordsCountHelper: ListenedWordsCountHelper,
    val premiumStateHelper: PremiumStateHelper,
    val skipSettingsHelper: SkipSettingsHelper,
    val tableOfContentsHelper: TableOfContentsHelper,
    val bookEditorHelper: BookEditorHelper,
    val bookTextEditorHelper: BookTextEditorHelper,
    val isEditingFeatureAvailable: Boolean,
    val downloadAudioHelper: DownloadAudioHelper,
    val paywallHelper: PaywallHelper,
    val contextualActionHelper: ContextualActionHelper,
    val searchHelper: SearchHelper,

    internal val navigationHelper: NavigationHelper,
    internal val focusHelper: FocusHelper,
    internal val readingLocationHelper: ReadingLocationHelper,
    internal val coreCommandsHelper: CoreCommandsHelper,
    internal val bundle: ReadingBundle,
    internal val appEnvironment: AppEnvironment,
) : Destructible {

    val isClassicReaderAvailable: Boolean = true
    fun createClassicReader(): ClassicReader {
        return ClassicReader(
            scope = scope.createChildReaderScope(),
            bundle = bundle,
            titleHelper = titleHelper,
            importHelper = importHelper,
            playbackHelper = playbackHelper,
            scrubberHelper = scrubberHelper,
            selectionHelper = selectionHelper,
            focusHelper = focusHelper,
            autoscrollHelper = autoscrollHelper,
            navigationHelper = navigationHelper,
            userHighlightsHelper = userHighlightsHelper,
            hoveredSentenceHelper = hoveredSentenceHelper,
            analyticsHelper = analyticsHelper,
            listenedWordsCountHelper = listenedWordsCountHelper,
            premiumStateHelper = premiumStateHelper,
            skipSettingsHelper = skipSettingsHelper,
            tableOfContentsHelper = tableOfContentsHelper,
            paywallHelper = paywallHelper,
            contextualActionHelper = contextualActionHelper,
            selectionUtils = selectionUtils,
            searchHelper = searchHelper,
            viewHelper = ClassicViewHelper(
                scope,
                standardView = bundle.content.standardView,
                contentIndex = bundle.content.contentIndex,
                playbackStateFlow = playbackHelper.stateFlow,
                selectionStateFlow = selectionHelper.stateFlow,
                highlightsInView = userHighlightsHelper.stateFlow,
                navigationIntentsFlow = navigationHelper.intentsFlow,
                hoveredSentenceStateFlow = hoveredSentenceHelper.stateFlow,
                readingLocationStateFlow = readingLocationHelper.stateFlow,
                searchStateFlow = searchHelper.stateFlow,
            ),
        )
    }

    val isFixedLayoutBookReaderAvailable: Boolean = bundle is BookReadingBundle
    fun createFixedLayoutBookReader(config: FixedLayoutReaderConfig): FixedLayoutBookReader {
        check(bundle is BookReadingBundle) { "Unable to create FixedLayoutBookReader from non-book Bundle" }
        return FixedLayoutBookReader(
            scope = scope.createChildReaderScope(),
            bundle = bundle,
            titleHelper = titleHelper,
            importHelper = importHelper,
            playbackHelper = playbackHelper,
            scrubberHelper = scrubberHelper,
            selectionHelper = selectionHelper,
            focusHelper = focusHelper,
            autoscrollHelper = autoscrollHelper,
            navigationHelper = navigationHelper,
            userHighlightsHelper = userHighlightsHelper,
            hoveredSentenceHelper = hoveredSentenceHelper,
            analyticsHelper = analyticsHelper,
            listenedWordsCountHelper = listenedWordsCountHelper,
            premiumStateHelper = premiumStateHelper,
            skipSettingsHelper = skipSettingsHelper,
            tableOfContentsHelper = tableOfContentsHelper,
            paywallHelper = paywallHelper,
            contextualActionHelper = contextualActionHelper,
            searchHelper = searchHelper,
            bookThumbnailsHelper = BookThumbnailsHelper(
                scope = scope,
                bookView = bundle.content.bookView,
                editBookFlow = bookEditorHelper.stateFlow,
                playbackStateFlow = playbackHelper.stateFlow,
                readingLocationFlow = readingLocationHelper.stateFlow,
            ),
            selectionUtils = selectionUtils,
            viewHelper = FixedLayoutBookViewHelper(
                scope,
                bookView = bundle.content.bookView,
                playbackStateFlow = playbackHelper.stateFlow,
                selectionStateFlow = selectionHelper.stateFlow,
                highlightsInView = userHighlightsHelper.stateFlow,
                navigationIntentsFlow = navigationHelper.intentsFlow,
                hoveredSentenceStateFlow = hoveredSentenceHelper.stateFlow,
                searchStateFlow = searchHelper.stateFlow,
                bookEditorHelper = bookEditorHelper,
                initialLocation = navigationHelper.initialLocation,
                initialConfig = config,
                /* Currently we are making overlayStrategy internal to sdk,
                but if required for any performance comparison we should allow the platforms to set it up.
                 */
                overlayStrategy = getOverlayStrategy(appEnvironment) { text, startIndex, endIndex, options ->
                    bundle.content.bookView.coSearch(text, startIndex, endIndex, options)
                },
            ),
        )
    }

    val isEpubReaderAvailable: Boolean = bundle is EpubReadingBundle
    fun createEpubReader(epubReaderConfig: EpubReaderConfig): EpubReader {
        check(bundle is EpubReadingBundle) { "Unable to create EpubReader from non-epub Bundle" }
        return EpubReader(
            scope = scope.createChildReaderScope(),
            bundle = bundle,
            titleHelper = titleHelper,
            importHelper = importHelper,
            playbackHelper = playbackHelper,
            scrubberHelper = scrubberHelper,
            selectionHelper = selectionHelper,
            focusHelper = focusHelper,
            autoscrollHelper = autoscrollHelper,
            navigationHelper = navigationHelper,
            userHighlightsHelper = userHighlightsHelper,
            hoveredSentenceHelper = hoveredSentenceHelper,
            analyticsHelper = analyticsHelper,
            listenedWordsCountHelper = listenedWordsCountHelper,
            premiumStateHelper = premiumStateHelper,
            skipSettingsHelper = skipSettingsHelper,
            tableOfContentsHelper = tableOfContentsHelper,
            paywallHelper = paywallHelper,
            contextualActionHelper = contextualActionHelper,
            searchHelper = searchHelper,
            selectionUtils = selectionUtils,
            viewHelper = EpubViewHelper(
                scope,
                epubViewV3 = bundle.content.epubView,
                playbackStateFlow = playbackHelper.stateFlow,
                selectionStateFlow = selectionHelper.stateFlow,
                highlightsInView = userHighlightsHelper.stateFlow,
                navigationIntentsFlow = navigationHelper.intentsFlow,
                hoveredSentenceStateFlow = hoveredSentenceHelper.stateFlow,
                searchStateFlow = searchHelper.stateFlow,
                initialLocation = playbackHelper.initialState.location,
                initialEpubReaderConfig = epubReaderConfig,
            ),
        )
    }

    companion object {
        fun fromBundle(
            speechifyClient: SpeechifyClient,
            readingBundle: ReadingBundle,
            initialNavigationIntent: NavigationIntent,
        ): ListeningExperience {
            val scope = createTopLevelReaderScope()
            val titleHelper = TitleHelper.create(scope, readingBundle)
            val importHelper = ImportHelper.create(scope, readingBundle)
            val navigationHelper = NavigationHelper(
                scope,
                TocEntryTargetResolverFactory.createResolver(standardView = readingBundle.content.standardView),
                playbackState = readingBundle.playbackControls.stateFlow.mapStateFlow { it.toPlaybackState() },
                startOfMainContentFlow = readingBundle.contentBundle.startOfMainContentFlow,
                initialNavigationIntent = initialNavigationIntent,
            )
            val playbackHelper = PlaybackHelper(
                scope,
                readingBundle.playbackControls,
                initialLocation = navigationHelper.initialLocation,
            )
            val scrubberHelper = ScrubberHelper(scope, readingBundle.playbackControls)
            val selectionHelper = SelectionHelper(scope, readingBundle.content.standardView)
            val focusHelper = FocusHelper(scope)
            val autoscrollHelper =
                AutoscrollHelper(scope, playbackState = playbackHelper.stateFlow, initialIsEnabled = false)

            val readingLocationHelper = ReadingLocationHelper(scope = scope)
            val tableOfContentsHelper = TableOfContentsHelper(
                scope = scope,
                tableOfContentsFlow = readingBundle.content.tableOfContentsFlow,
                playbackStateFlow = playbackHelper.stateFlow,
                readingLocationFlow = readingLocationHelper.stateFlow,
            )
            val bookEditorHelpersProvider = BookEditorHelpersProvider(scope = scope, readingBundle = readingBundle)

            val highlightsHelper = UserHighlightsHelper(
                scope,
                readingBundle.contentBundle.coImporter.stateFlow,
                speechifyClient.userHighlightsService,
                selectionHelper.stateFlow,
                readingBundle.content.standardView,
                bookEditorHelpersProvider.bookEditorHelper.stateFlow,
            )
            val selectionUtils = SelectionUtils(
                readingBundle.content.standardView,
                selectionHelper.stateFlow,
                highlightsHelper.stateFlow,
            )
            val hoveredSentenceHelper = HoveredSentenceHelper(scope, readingBundle.content.speechView)
            val analyticsHelper = AnalyticsHelper(scope, readingBundle)
            val listenedWordsCountHelper = ListenedWordsCountHelper(
                scope = scope,
                playbackControls = readingBundle.playbackControls,
            )
            val premiumStateHelper = PremiumStateHelper.create(
                scope = scope,
                subscriptionService = speechifyClient.subscriptionService,
            )
            val skipSettingsHelper = SkipSettingsHelper(
                scope = scope,
                readingBundle = readingBundle,
            )
            val coreCommandsHelper = CoreCommandsHelper(scope = scope)
            val downloadAudioHelper = DownloadAudioHelper(
                scope = scope,
                offlineAvailabilityManager = speechifyClient.offlineAvailabilityManager,
                readingBundle = readingBundle,
            )
            val paywallHelper = PaywallHelper(
                scope = scope,
                speechifyClient = speechifyClient,
                readingBundle = readingBundle,
            )

            val contextualActionHelper = ContextualActionHelper(
                scope = scope,
                playbackStateFlow = playbackHelper.stateFlow,
                focusStateFlow = focusHelper.stateFlow.filterIsInstance<FocusState.Ready>(),
                startOfMainContentFlow = readingBundle.contentBundle.startOfMainContentFlow,
            )

            val searchHelper = SearchHelper(
                scope = scope,
                searcher = readingBundle.contentBundle.searcher,
                readingLocationFlow = readingLocationHelper.stateFlow,
            )

            return ListeningExperience(
                scope = scope,
                bundle = readingBundle,
                titleHelper = titleHelper,
                importHelper = importHelper,
                playbackHelper = playbackHelper,
                scrubberHelper = scrubberHelper,
                selectionHelper = selectionHelper,
                selectionUtils = selectionUtils,
                focusHelper = focusHelper,
                autoscrollHelper = autoscrollHelper,
                navigationHelper = navigationHelper,
                userHighlightsHelper = highlightsHelper,
                hoveredSentenceHelper = hoveredSentenceHelper,
                analyticsHelper = analyticsHelper,
                listenedWordsCountHelper = listenedWordsCountHelper,
                premiumStateHelper = premiumStateHelper,
                skipSettingsHelper = skipSettingsHelper,
                tableOfContentsHelper = tableOfContentsHelper,
                readingLocationHelper = readingLocationHelper,
                bookEditorHelper = bookEditorHelpersProvider.bookEditorHelper,
                bookTextEditorHelper = bookEditorHelpersProvider.bookTextEditorHelper,
                isEditingFeatureAvailable = bookEditorHelpersProvider.isEditingFeatureAvailable,
                coreCommandsHelper = coreCommandsHelper,
                downloadAudioHelper = downloadAudioHelper,
                paywallHelper = paywallHelper,
                contextualActionHelper = contextualActionHelper,
                searchHelper = searchHelper,
                appEnvironment = speechifyClient.clientConfig.appEnvironment,
            )
        }
    }

    override fun destroy() {
        scope.cancel()
    }
}
