package com.speechify.client.internal.util.collections.maps

internal class MutableMapBy<KRich, KWithSemanticEqual, Value>(
    private val getEquatableKey: (keyRich: KRich) -> KWithSemanticEqual,
) : MutableMapWithBasicsThreadUnsafe<KRich, Value> {
    private val backingMap = mutableMapOf<KWithSemanticEqual, RichKeyWithValue<KRich, Value>>()
        .asMutableMapWithBasics()

    class RichKeyWithValue<KRich, Value>(
        val keyRich: KRich,
        val value: Value,
    )

    override fun get(key: KRich): Value? =
        backingMap[getEquatableKey(key)]?.value

    override fun put(key: KRich, value: Value): Value? =
        backingMap.put(getEquatableKey(key), RichKeyWithValue(key, value))?.value

    override val entries: Iterable<Pair<KRich, Value>>
        get() = backingMap.entries.map { (_, value) ->
            value.keyRich to value.value
        }

    override fun remove(key: KRich): Value? =
        backingMap.remove(getEquatableKey(key))?.value

    override fun containsKey(key: KRich): Boolean =
        backingMap.containsKey(getEquatableKey(key))

    override fun clear() =
        backingMap.clear()
}
