package com.speechify.client.bundlers.reading

import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import org.w3c.files.Blob

/**
 * Creates a bundle for a given [Blob] (Note: a [org.w3c.files.File] is also a [Blob]).
 * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
 * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
 */
@JsExport
fun UniversalSourcesReadingBundler.createBundleForBlob(
    /**
     * Note: a [org.w3c.files.File] is also a [Blob].
     */
    blob: Blob,
    importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
    bundleMetadata: BundleMetadata,
    callback: Callback<ReadingBundle>,
) =
    createBundleForBinaryContent(
        content = BinaryContentReadableRandomly(blob),
        mimeType = MimeType(fullValue = blob.type),
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
        callback = callback,
    )
