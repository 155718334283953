package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisVoice
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.view.speech.Speech
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully

internal class LocalVoice(
    override val voiceSpec: VoiceSpec.LocalAvailable,
    private val localSpeechSynthesisPlayerFactory: LocalSpeechSynthesisAdapter,
) : Voice(), HasLocalVoiceIdentity {
    override val localSynthesisVoice: LocalSynthesisVoice get() =
        voiceSpec.localSynthesisVoice

    override fun synthesize(
        speech: Speech,
        callback: Callback<Array<Utterance>>,
    ) = callback.fromCo {
        val compiled = ContentTextUtils.join(speech.sentences.map { it.text }, " ")
        val utterance = LocalUtterance(
            localSynthesisVoice,
            speech,
            compiled,
            localSpeechSynthesisPlayerFactory,
            voiceMetadata = metadata,
        ) as Utterance
        return@fromCo arrayOf(utterance).successfully()
    }
}

internal fun VoiceSpec.LocalAvailable.toVoiceMetadataAsLocal(): VoiceMetadataOfLocalVoice =
    VoiceMetadataOfLocalVoice(
        // TODO(anson) will need to think harder about these IDs - maybe namespaced like local.ios.id etc?
        id = this.getMetadataId(),
        displayName = displayName,
        gender = localSynthesisVoice.gender,
        languageCode = localSynthesisVoice.languageCode,
        avatarUrl = avatarUrl,
        previewAudioSentence = previewAudioSentence,
        isPremium = false,
        engine = "local",
        spec = this,
    )

internal fun VoiceSpec.LocalAvailable.getMetadataId(): String =
    localSynthesisVoice.id
