package com.speechify.client.api.content.pdf

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
enum class ContentSortingStrategy {
    None,
    ExperimentalV1,
    ExperimentalV2,
}
