package com.speechify.client.api.adapters.ocr

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.sync.CoLazy
import com.speechify.client.internal.sync.DeferredTryGetResult
import com.speechify.client.internal.sync.coLazy
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * `OCRablImage` is an abstract class that gives the SDK the ability to request OCR and ImageDimension for a page.
 *
 * This class does not contain the actual image file. To get the image file, use the `getFile` method in the [LazyOCRFiles] class.
 * This class is able to return OCRTextResult and ImageDimension on demand
 *
 * TODO: Consider putting the actual image file here as well
 *
 */
@JsExport
abstract class OCRableImage {
    internal val isOcrTextResultReady get() = ocrTextResult.tryGetValue() is DeferredTryGetResult.Success

    /**
     * The `CoLazy` delegate is used to ensure that the OCR text result is lazily initialized and then cached for future use.
     */
    private val ocrTextResult: CoLazy<Result<OCRTextResult>> = coLazy {
        getOcrTextResult()
    }

    /**
     * Provides a hot flow that emits OCR text recognition results.
     * This flow serves as a listener and only emits data when new OCR text results are available.
     */
    internal val ocrTextResultFlow by lazy { ocrTextResult.valueFlow }

    /**
     * Allows SDK to request an OCR for this page. The SDK will cache the result, so there's no need to
     * add caching in the implementation.
     */
    protected abstract fun getOcrTextResult(callback: Callback<OCRTextResult>)

    /**
     * Allows SDK to request an ImageDimension for this page.
     */
    abstract fun getImageDimensions(): Viewport

    /**
     * Can be used by consumers to get the cached OCR result.
     */
    internal suspend fun getOcrTextResultCached(ocrMaxConcurrencyGuard: OCRMaxConcurrencyGuard) =
        ocrMaxConcurrencyGuard.runOCREnsuringConcurrencyLimit { ocrTextResult.get() }

    private suspend fun getOcrTextResult() = suspendCoroutine { getOcrTextResult(it::resume) }
}

/**
 * Converts the OCRablImage to an OCRResult
 */
internal suspend fun OCRableImage.toOcrResult(
    ocrMaxConcurrencyGuard: OCRMaxConcurrencyGuard,
): Result<OCRResult> {
    val ocrTextResult = getOcrTextResultCached(ocrMaxConcurrencyGuard).orReturn { return it }
    return OCRResult(
        ocrTextResult.rawText,
        ocrTextResult.textContent,
        getImageDimensions(),
    ).successfully()
}

/**
 * Converts the OCRablImage to an OCRResult
 */
internal fun OCRResult.toOcrableImage(): OCRableImage {
    return object : OCRableImage() {
        override fun getImageDimensions(): Viewport = imageDimensions

        override fun getOcrTextResult(callback: Callback<OCRTextResult>) {
            callback.fromCo {
                OCRTextResult(rawText, textContent).successfully()
            }
        }
    }
}

/**
 * Converts the OCRResult to an OCRablImage
 * Also calls [OCRableImage.getOcrTextResultCached] to complete the job associated with [OCRableImage.ocrTextResult]
 * before returning the [OCRableImage]
 */
internal suspend fun OCRResult.toOCRableImageWithCompletingTextResultJob(
    maxConcurrencyGuard: OCRMaxConcurrencyGuard,
): OCRableImage {
    return toOcrableImage().apply {
        getOcrTextResultCached(maxConcurrencyGuard)
    }
}
