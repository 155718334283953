package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
interface FirebaseService {
    @JsName("getAuth")
    fun getAuth(): FirebaseAuthService

    @JsName("getFirestore")
    fun getFirestore(): FirebaseFirestoreService

    @JsName("getStorage")
    fun getStorage(): FirebaseStorageAdapter

    @JsName("getTimestampFactory")
    fun getTimestampFactory(): FirebaseTimestampAdapter

    @JsName("getFieldValueFactory")
    fun getFieldValueFactory(): FirebaseFieldValueAdapter
}

internal fun FirebaseService.traced() = if (Log.isDebugLoggingEnabled) FirebaseServiceTraced(this) else this

internal class FirebaseServiceTraced(private val firebaseService: FirebaseService) : FirebaseService {
    override fun getAuth(): FirebaseAuthService = firebaseService.getAuth().traced()

    override fun getFirestore(): FirebaseFirestoreService = firebaseService.getFirestore().traced()

    override fun getStorage(): FirebaseStorageAdapter = firebaseService.getStorage().traced()

    override fun getTimestampFactory(): FirebaseTimestampAdapter = firebaseService.getTimestampFactory().traced()

    override fun getFieldValueFactory(): FirebaseFieldValueAdapter = firebaseService.getFieldValueFactory().traced()
}
