@file:OptIn(FlowPreview::class)

package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.firstOrNull

/**
 * Efficient all check stopping the flow early as soon as any item doesn't match.
 */
internal suspend fun <T> Flow<T>.all(maxConcurrency: Int, predicate: suspend (T) -> Boolean): Boolean {
    return this
        // This will change the order of the items but for checking if all match it doesn't matter.
        .mapConcurrentlyLosingOrder(maxConcurrency = maxConcurrency) {
            predicate(it)
            // This allows us to stop early as soon as any item is false.
        }.firstOrNull { matchesPredicate -> !matchesPredicate } ?: true
}

/**
 * Efficient any check stopping the flow early as soon as any item matches.
 */
internal suspend fun <T> Flow<T>.any(maxConcurrency: Int, predicate: suspend (T) -> Boolean): Boolean {
    return !this.all(maxConcurrency = maxConcurrency) {
        !predicate(it)
    }
}
