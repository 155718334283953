package com.speechify.client.internal.services.subscription

import com.speechify.client.api.services.subscription.models.Entitlements
import com.speechify.client.api.services.subscription.models.OneClickRenewalStatus
import com.speechify.client.api.services.subscription.models.RenewalFrequency
import com.speechify.client.api.services.subscription.models.RenewalStatus
import com.speechify.client.api.services.subscription.models.Subscription
import com.speechify.client.api.services.subscription.models.SubscriptionAndEntitlements
import com.speechify.client.api.services.subscription.models.SubscriptionPlan
import com.speechify.client.api.services.subscription.models.SubscriptionPlanEntitlements
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.subscription.models.PlatformEntitlementsResponse
import com.speechify.client.internal.services.subscription.models.PlatformOneClickRenewalStatus
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionAndEntitlementResponse
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionPlanResponse
import com.speechify.client.internal.services.subscription.models.PlatformSubscriptionResponse
import com.speechify.client.internal.services.subscription.models.toSubscriptionDiscounts
import com.speechify.client.internal.services.subscription.models.toSubscriptionSource
import com.speechify.client.internal.services.subscription.models.toSubscriptionStatus

internal object PlatformDataTransformer {
    internal fun statusToOneClickRenewalStatus(
        status: PlatformOneClickRenewalStatus,
    ): Result<OneClickRenewalStatus> {
        if (!status.isAvailable) {
            return OneClickRenewalStatus.Unavailable.successfully()
        }

        val price = status.price
            ?: return buildMissingFieldFailure("price", "oneClickRenewalStatus")
        val currency = status.currency
            ?: return buildMissingFieldFailure("currency", "oneClickRenewalStatus")
        val productId = status.productId
            ?: return buildMissingFieldFailure("productId", "oneClickRenewalStatus")
        val source = status.source
            ?: return buildMissingFieldFailure("source", "oneClickRenewalStatus")
        val periodUnit = status.periodUnit
            ?: return buildMissingFieldFailure("periodUnit", "oneClickRenewalStatus")
        val periodCount = status.periodCount
            ?: return buildMissingFieldFailure("periodCount", "oneClickRenewalStatus")
        val subscriptionId = status.subscriptionId
            ?: return buildMissingFieldFailure("subscriptionId", "oneClickRenewalStatus")
        val originalPrice = status.originalPrice
            ?: return buildMissingFieldFailure("originalPrice", "oneClickRenewalStatus")
        val willChargeUser = status.willChargeUser
            ?: return buildMissingFieldFailure("originalPrice", "oneClickRenewalStatus")

        return OneClickRenewalStatus.Available(
            price,
            originalPrice,
            currency,
            productId,
            source,
            periodUnit,
            periodCount,
            subscriptionId,
            willChargeUser,
        ).successfully()
    }

    internal fun parseSubscriptionAndEntitlementsResponse(
        response: PlatformSubscriptionAndEntitlementResponse,
    ): SubscriptionAndEntitlements =
        SubscriptionAndEntitlements(
            subscriptions = response.subscriptions.map {
                parseSubscription(it)
            }.toTypedArray(),
            entitlements = parseEntitlements(response.entitlements),
        )

    private fun parseSubscription(subscriptionsInfo: PlatformSubscriptionResponse): Subscription {
        val subscriptionStatus = subscriptionsInfo.toSubscriptionStatus()
        val discounts = subscriptionsInfo.toSubscriptionDiscounts()

        val renewalStatus = when (subscriptionsInfo.renewalStatus) {
            "active" -> RenewalStatus.ACTIVE
            "expired" -> RenewalStatus.EXPIRED
            "grace" -> RenewalStatus.GRACE
            "missed_payment" -> RenewalStatus.MISSED_PAYMENT
            else -> RenewalStatus.ACTIVE
        }

        return Subscription(
            id = subscriptionsInfo.id,
            status = subscriptionStatus,
            isOnTrial = subscriptionsInfo.isOnTrial,
            discounts = discounts,
            lastPaymentAt = subscriptionsInfo.lastPaymentAt,
            renewsAt = subscriptionsInfo.renewsAt,
            renewalStatus = renewalStatus,
            currency = when (subscriptionsInfo.localCurrency) {
                null -> "usd"
                else -> subscriptionsInfo.localCurrency
            },
            subscribedAt = subscriptionsInfo.subscribedAt,
            plan = parseSubscriptionPlan(subscriptionsInfo.plan),
        )
    }

    private fun parseSubscriptionPlan(subscriptionPlan: PlatformSubscriptionPlanResponse): SubscriptionPlan {
        val renewalFrequency = when (subscriptionPlan.renewalFrequency) {
            "monthly" -> RenewalFrequency.MONTHLY
            "weekly" -> RenewalFrequency.WEEKLY
            "quarterly" -> RenewalFrequency.QUARTERLY
            else -> RenewalFrequency.YEARLY
        }

        val productTypes = subscriptionPlan.productTypes ?: arrayOf()
        val entitlements = SubscriptionPlanEntitlements(
            wordsLimit = subscriptionPlan.wordsLimit,
            audiobookCredits = subscriptionPlan.audiobookCredits,
            avatarsSeconds = subscriptionPlan.avatarsSeconds,
            dubbingSeconds = subscriptionPlan.dubbingSeconds,
            voiceoverSeconds = subscriptionPlan.voiceoverSeconds,
            ttsExportSeconds = subscriptionPlan.ttsExportSeconds,
        )

        return SubscriptionPlan(
            source = subscriptionPlan.toSubscriptionSource(),
            name = subscriptionPlan.name,
            price = subscriptionPlan.price,
            renewalFrequency = renewalFrequency,
            conversionId = subscriptionPlan.conversionId,
            discountIds = subscriptionPlan.discountIds ?: arrayOf(),
            hasTrial = subscriptionPlan.hasTrial,
            initialTrialDurationDays = subscriptionPlan.initialTrialDurationDays,
            productTypes = productTypes,
            entitlements = entitlements,
        )
    }

    private fun parseEntitlements(entitlements: PlatformEntitlementsResponse): Entitlements {
        // This is currently not implemented in the platform but was extracted from the SubscriptionsFirebaseDataTransformer
        // @See: com/speechify/client/internal/services/subscription/SubscriptionsFirebaseDataTransformer.kt:261
        val maxSpeedInWordsPerMinute = when (entitlements.isPremium) {
            true -> 900
            else -> Entitlements.NONE.maxSpeedInWordsPerMinute
        }

        return Entitlements(
            isPremium = entitlements.isPremium,
            hdWordsLeft = entitlements.hdWordsLeft,
            nextHDWordsGrant = entitlements.nextHDWordsGrant,
            nextHDWordsGrantDate = entitlements.nextHDWordsGrantDate,
            lastHdWordsGrantDate = entitlements.lastHDWordsGrantDate,
            audiobookCreditsLeft = entitlements.audiobookCreditsLeft,
            lastAudiobookCreditsGrantDate = entitlements.lastAudiobookCreditsGrantDate,
            maxSpeedInWordsPerMinute = maxSpeedInWordsPerMinute,
        )
    }
}
