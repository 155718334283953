package com.speechify.client.api.content.epubV3

import com.speechify.client.api.adapters.archiveFiles.ZipArchiveView
import com.speechify.client.api.adapters.xml.XMLDOMElement
import com.speechify.client.api.adapters.xml.XMLDOMTextNode
import com.speechify.client.api.content.epub.EpubGuideReference
import com.speechify.client.api.content.epub.EpubNavigation
import com.speechify.client.api.content.epub.EpubStartOfMainContent
import com.speechify.client.api.content.epub.ignorableChapterFilenames
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.FileFromString
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.coGetUrl
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.replaceWithSuspend
import com.speechify.client.internal.webview.createResourceUrl
import kotlinx.serialization.SerialName

internal data class EpubV3(
    val zipArchiveView: ZipArchiveView,
    val opfFilePath: String,
    val readingOrder: List<Link> = emptyList(),
    val resources: List<Link> = emptyList(),
    val navigation: EpubNavigation?,
    val guide: List<EpubGuideReference>,
    val coverImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?,
    val title: String?,
    val chapterIndexesToEstimatedContentRatios: Map<Int, Double>,
) {
    val startOfMainContent by lazy {
        val href =
            navigation?.landmarks?.firstOrNull { it.type.lowercase() == "bodymatter" }?.href
                ?: guide.firstOrNull { it.type.lowercase() == "text" }?.href

        val specifiedByAuthor = href?.let {
            // Remove path and fragment to get the filename
            val filename = href.substringAfterLast("/").substringBeforeLast("#")
            val chapterIndex = readingOrder.indexOfFirst { chapter ->
                chapter.href.path.endsWith(filename)
            }
            EpubStartOfMainContent(
                chapterIndex = chapterIndex,
                fragment = if (it.contains("#")) it.substringAfter("#") else null,
            )
        }

        val inferred = readingOrder.indexOfFirst { !it.isProbablyFrontOrBackMatterThatWeShouldIgnore }
            .nullIf { this == -1 }
            ?.let {
                EpubStartOfMainContent(
                    chapterIndex = it,
                    fragment = null,
                )
            }

        if (specifiedByAuthor != null && inferred != null && inferred.chapterIndex > specifiedByAuthor.chapterIndex) {
            inferred
        } else {
            specifiedByAuthor ?: inferred
        }
    }

    /**
     * Lazily initializes a map that associates CSS file paths with their updated versions as data urls in base64
     * format, containing updated font as data urls as well.
     * This is useful because CSS file points to local fonts and those css are used in all the chapters,
     * Thus calculating those css files lazily is a win.
     *
     * @return A lazily computed map of CSS resource paths to base64 URLs with updated font links as data urls as well.
     */
    val mapOfCssFilesAsDataUrlsWithEmbeddedFonts = coLazy {
        // Filter epub resources to select only CSS files.
        val cssLinks = resources.filter { it.mediaType?.typeSubtype == "text/css" }
        // Map each CSS path to an updated css as data URL with modified font paths.
        cssLinks.mapNotNull { cssLink ->
            // Locate the corresponding css resource entry in the ZIP archive
            val resourceEntry = zipArchiveView.entries.find {
                it.path.endsWith(cssLink.href.path)
            } ?: return@mapNotNull null
            val cssFile = resourceEntry.coCreateBinaryContentReadableRandomly()
            // Read the CSS file as a string
            val cssAsString = cssFile.coGetAllBytes()
                .orThrow()
                .decodeToString()
            // Replace relative font paths with updated fonts as data urls.
            val cssWithUpdatedFonts = cssAsString.replaceWithSuspend(
                Regex("""(src):url\("(.+?)"\)"""),
            ) { match ->
                val (attr, path) = match.destructured
                val fullPath = path.replace("../", "")
                val fontResourceEntry = zipArchiveView.entries.find {
                    it.path.endsWith(fullPath)
                } ?: return@replaceWithSuspend """$attr:url("$path")"""
                // Generate a data URL for the font resource file.
                val fontDataUrl = createResourceUrl(fontResourceEntry.coCreateBinaryContentReadableRandomly())
                """$attr:url("$fontDataUrl")"""
            }
            // Generate a data URL for the updated CSS content
            val updatedCssFileAsDataUrl = FileFromString(
                contentType = "text/css",
                stringValue = cssWithUpdatedFonts,
            ).coGetUrl().orThrow()
            // Map the original path to the new data URL
            resourceEntry.path to updatedCssFileAsDataUrl
        }.toMap()
    }
}

internal data class Link(
    val href: Href,
    val mediaType: MimeType? = null,
    val title: String? = null,
    val children: List<Link> = listOf(),
)

internal data class Href(val path: String)

internal data class PackageDocument(
    val path: String,
    val epubVersion: Double,
    val uniqueIdentifierId: String?,
    val coverItem: Item?,
    val manifest: List<Item>,
    val spine: Spine,
    val guide: List<EpubGuideReference>,
    val title: String?,
) {

    companion object {
        fun parse(document: XMLDOMElement, filePath: String): PackageDocument? {
            val epubVersion = document.attributes.find { it.first == "version" }?.second?.toDoubleOrNull() ?: 0.0
            val uniqueIdentifierId = document.attributes.find { it.first == "unique-identifier" }?.second
            val manifestElement = document.children
                .filterIsInstance<XMLDOMElement>()
                .find { it.tagName.lowercase() == "manifest" }
                ?: return null
            val spineElement = document.children
                .filterIsInstance<XMLDOMElement>()
                .find { it.tagName.lowercase() == "spine" }
                ?: return null

            val metadataElement = document.children
                .filterIsInstance<XMLDOMElement>()
                .find { it.tagName.lowercase() == "metadata" }

            val coverImageContentId = metadataElement?.children?.filterIsInstance<XMLDOMElement>()
                ?.find { it.tagName.lowercase() == "meta" && it.attributes.any { pair -> pair.second == "cover" } }
                ?.attributes?.find { it.first == "content" }?.second

            val coverItem = coverImageContentId?.let {
                manifestElement.children.filterIsInstance<XMLDOMElement>().find {
                    it.attributes.any { it.first == "id" && it.second == coverImageContentId }
                }?.let { Item.parse(it) }
            }
            // Parse guide references
            val guideElement = document.children
                .filterIsInstance<XMLDOMElement>()
                .find { it.tagName.lowercase() == "guide" }

            val guideReferences = guideElement?.children
                ?.filterIsInstance<XMLDOMElement>()
                ?.filter { it.tagName.lowercase() == "reference" }
                ?.mapNotNull { element ->
                    val type = element.attributes.find { it.first == "type" }?.second ?: return@mapNotNull null
                    val href = element.attributes.find { it.first == "href" }?.second ?: return@mapNotNull null
                    val title = element.attributes.find { it.first == "title" }?.second

                    EpubGuideReference(type, title, href)
                } ?: emptyList()

            val title = metadataElement?.children?.filterIsInstance<XMLDOMElement>()
                ?.firstOrNull { it.tagName.lowercase() == "dc:title" }
                ?.children?.filterIsInstance<XMLDOMTextNode>()
                ?.firstOrNull()?.text

            return PackageDocument(
                path = filePath,
                epubVersion = epubVersion,
                uniqueIdentifierId = uniqueIdentifierId,
                coverItem = coverItem,
                manifest = manifestElement.children.filterIsInstance<XMLDOMElement>().mapNotNull { Item.parse(it) },
                spine = Spine.parse(spineElement, epubVersion),
                guide = guideReferences,
                title = title,
            )
        }
    }
}

internal data class Item(
    val href: String,
    val id: String?,
    val mediaType: String?,
    val properties: String?,
) {
    companion object {
        fun parse(element: XMLDOMElement): Item? {
            val href = element.attributes.find { it.first == "href" }?.second ?: return null
            val id = element.attributes.find { it.first == "id" }?.second
            val mediaType = element.attributes.find { it.first == "media-type" }?.second
            val properties = element.attributes.find { it.first == "properties" }?.second
            return Item(
                href = href,
                id = id,
                mediaType = mediaType,
                properties = properties,
            )
        }
    }
}

internal data class Spine(
    val itemRefs: List<ItemRef>,
    val readingDirection: ReadingDirection?,
    val toc: String? = null,
) {
    companion object {
        fun parse(element: XMLDOMElement, epubVersion: Double): Spine {
            val itemRefs = element.children.filterIsInstance<XMLDOMElement>().mapNotNull {
                ItemRef.parse(
                    it,
                )
            }
            val pageReadingDirection =
                when (element.attributes.find { it.first == "page-progression-direction" }?.second) {
                    "rtl" -> ReadingDirection.RTL
                    "ltr" -> ReadingDirection.LTR
                    else -> null // null or "default"
                }
            val ncx = if (epubVersion < 3.0) element.attributes.find { it.first == "toc" }?.second else null
            return Spine(itemRefs, pageReadingDirection, ncx)
        }
    }
}

internal enum class ReadingDirection(val value: String) {
    /** Right to left */
    @SerialName("rtl")
    RTL("rtl"),

    /** Left to right */
    @SerialName("ltr")
    LTR("ltr"),
    ;
}

internal data class ItemRef(
    val idRef: String,
) {
    companion object {
        fun parse(element: XMLDOMElement): ItemRef? {
            val idRef = element.attributes.find { it.first == "idref" }?.second ?: return null
            return ItemRef(idRef)
        }
    }
}

private val Link.isProbablyFrontOrBackMatterThatWeShouldIgnore: Boolean
    get() {
        val filename = href.path.substringBefore(".").lowercase()
        return ignorableChapterFilenames.any {
            val pattern = it.lowercase()
            filename.endsWith(pattern) || filename.startsWith(pattern)
        }
    }
