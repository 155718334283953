package com.speechify.client.internal.sync

import kotlinx.coroutines.Job
import kotlin.js.JsExport

internal fun Job.toReadOnlyJobInfo() =
    ReadOnlyJobInfo(this)

/**
 * Exposes information about a job in a read-only way.
 */
@JsExport
class ReadOnlyJobInfo internal constructor(
    private val job: Job,
) {
    /**
     * See [Job.isActive] for the meaning of this property.
     */
    val isActive: Boolean
        get() = job.isActive

    /**
     * See [Job.isCancelled] for the meaning of this property.
     */
    val isCancelled: Boolean
        get() = job.isCancelled

    /**
     * See [Job.isCompleted] for the meaning of this property.
     */
    val isCompleted: Boolean
        get() = job.isCompleted

    /**
     * Allows for awaiting the completion of the job.
     */
    internal suspend fun awaitFinish() =
        job.join()

    /**
     * The name that was given to the job. This is not necessarily unique.
     *
     * (In the SDK it can be influenced through [kotlinx.coroutines.CoroutineName] in
     * [kotlin.coroutines.coroutineContext]).
     */
    val name: String
        get() = job.toString()

    override fun toString(): String =
        name
}
