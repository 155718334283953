package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.view.standard.StandardBlocks
import kotlin.math.max
import kotlin.math.min

internal open class StandardBlockChunking(val allBlocks: StandardBlocks, val chunkSize: Int) {

    // We chunk the blocks here so we can send smaller groups to callers.
    protected val chunkedBlocks by lazy {
        allBlocks.blocks.toList().chunked(chunkSize).toList()
    }

    open fun getBlocksAroundCursor(
        cursor: ContentCursor,
    ): StandardBlocks {
        return if (allBlocks.blocks.size > chunkSize * 4) {
            // Try to reduce the amount of content callers have to work with by filtering
            // only the blocks around the cursor.
            if (cursor is ContentElementBoundary && cursor.element.path.isEmpty()) {
                val newBlocks = when (cursor.boundary) {
                    is ContentBoundary.START -> {
                        allBlocks.blocks.take(chunkSize)
                    }
                    is ContentBoundary.END -> {
                        allBlocks.blocks.takeLast(chunkSize)
                    }
                }
                return StandardBlocks(
                    blocks = newBlocks.toTypedArray(),
                    start = newBlocks.first().start,
                    end = newBlocks.last().end,
                )
            }

            val containingBlockIndex = allBlocks.blocks.indexOfFirst {
                it.start.isBeforeOrAt(cursor) && it.end.isAfterOrAt(cursor)
            }

            if (containingBlockIndex == -1) {
                // The cursor is not in any of the blocks, so just return all the blocks.
                return allBlocks
            }

            // We take the current chunk and the two neighboring chunks.
            val currentChunkIndex = containingBlockIndex / chunkSize
            val chunksToTake =
                ((max(0, currentChunkIndex - 1))..(min(chunkedBlocks.size - 1, currentChunkIndex + 1)))
            val chunks = chunkedBlocks.slice(chunksToTake)
            val newBlocks = chunks.flatten()

            StandardBlocks(
                blocks = newBlocks.toTypedArray(),
                start = newBlocks.first().start,
                end = newBlocks.last().end,
            )
        } else {
            allBlocks
        }
    }
}
