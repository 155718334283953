package com.speechify.client.reader.core

import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import kotlinx.coroutines.CoroutineScope

internal class BookEditorHelpersProvider(
    scope: CoroutineScope,
    readingBundle: ReadingBundle,
) {

    internal val isEditingFeatureAvailable = readingBundle is BookReadingBundle

    internal val bookEditorHelper by lazy {
        BookEditorHelper(scope, readingBundle)
    }

    internal val bookTextEditorHelper by lazy {
        BookTextEditorHelper(scope, readingBundle)
    }
}
