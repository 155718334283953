package com.speechify.client.internal.util.extensions.collections

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

/**
 * Constructs all sublist, starting from a list containing just the 'single first item', adding the following items,
 * until the list of all items.
 *
 * Can also be thought of as a version of [windowedStartingWithSmaller] that grows indefinitely - use with a terminal
 * condition.
 */
internal fun <T> Flow<T>.allSublistsFromSingleFirstItemToAllItems(): Flow<List<T>> = flow {
    val list = mutableListOf<T>()
    collect {
        list.add(it)
        emit(
            list
                /**
                 * Copy the list to support even multithreaded consumption:
                 */
                .toList(),
        )
    }
}

internal fun <T> Iterable<T>.allSublistsFromAllItemsToSingleFirstItem(): Sequence<List<T>> =
    asSequence().allSublistsFromAllItemsToSingleFirstItem()

internal fun <T> Sequence<T>.allSublistsFromAllItemsToSingleFirstItem(): Sequence<List<T>> = sequence {
    val list = this@allSublistsFromAllItemsToSingleFirstItem.toList()

    for (endIndex in list.lastIndex downTo 0) {
        yield(
            list.subListToInclusive(
                toIndexInclusive = endIndex,
            ),
        )
    }
}

internal fun <T> List<T>.allSublistsFromAllItemsToSingleLastItem(): Sequence<List<T>> = sequence {
    for (idx in 0..lastIndex) {
        yield(
            subListFrom(idx),
        )
    }
}
