package com.speechify.client.bundlers.listening

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.audio.AudioControllerOptions
import com.speechify.client.api.audio.MediaVoice
import com.speechify.client.api.audio.UtteranceFlowProviderFromSingleStaticUtterance
import com.speechify.client.api.audio.UtteranceFlowProviderFromSpeechFlow
import com.speechify.client.api.audio.Voice
import com.speechify.client.api.audio.VoiceFactoryFromSpec
import com.speechify.client.api.audio.VoiceOfPreferenceForOfflineProvider
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.audio.VoicesOfPreferenceStateProviderFromConfig
import com.speechify.client.api.audio.VoicesOfPreferenceStateProviderFromStaticValue
import com.speechify.client.api.audio.caching.getIdForDb
import com.speechify.client.api.audio.toVoiceFactoryFromSpec
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.audiobook.AudiobookChapter
import com.speechify.client.api.services.audiobook.NarratedAudiobookPlaceholderVoiceSpec
import com.speechify.client.api.services.audiobook.getUtteranceForAudiobookChapter
import com.speechify.client.api.services.library.offline.toVoiceAudioDownloadInfo
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.BundlerPlugins
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentBundlerConfig
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.db.DbBoolean
import com.speechify.client.internal.services.db.DbService
import com.speechify.client.internal.sqldelight.DownloadedAudioForItem
import com.speechify.client.internal.util.extensions.coroutines.coroutineScopeForSingleExpression
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.first
import kotlin.js.JsExport

/**
 * A way to get pre-initialized audio components for listening to any [ContentBundle].
 *
 * Clients will probably prefer to use one of the Reading Bundlers, but this serves a valuable purpose of abstracting
 * the initialization of the listening-specific features of the Speechify experience.
 */
@JsExport
class ListeningBundler(
    private val clientServices: SpeechifyClient,
    internal val bundlerPlugins: BundlerPlugins,
    internal val config: ListeningBundlerConfig,
    private val contentBundlerConfig: ContentBundlerConfig,
) {

    init {
        checkVoiceSpecifications()
    }

    /**
     * Create a [ListeningBundle] for the [contentBundle] provided.
     */
    fun createBundleForContent(contentBundle: ContentBundle, callback: Callback<ListeningBundle>) {
        callback.fromCoWithErrorLogging(
            sourceAreaId = "ListeningBundler.createBundleForContent",
        ) {
            coCreateBundleForContent(contentBundle, null, createTopLevelCoroutineScope())
        }
    }

    internal suspend fun coCreateBundleForContent(
        contentBundle: ContentBundle,
        startingCursor: ContentCursor? = null,
        scope: CoroutineScope,
    ): Result<ListeningBundle> {
        val telemetryEventBuilder = currentTelemetryEvent()

        return when (contentBundle) {
            // Treat audiobooks specially because they are built from a single artifact with both text and audio, rather
            // than the usual text to speech flow.
            is ContentBundle.AudioBookChapterBundle -> {
                val audioController = AudioController(
                    utteranceFlowProvider = UtteranceFlowProviderFromSingleStaticUtterance(
                        suspend {
                            getUtteranceForAudiobookChapter(
                                clientServices,
                                // This cast is currently safe, since we only provide books with aligned chapters
                                contentBundle.audioBookChapter as AudiobookChapter.Aligned,
                            )
                        },
                    ),
                    speechView = contentBundle.speechView,
                    initialOptions = AudioControllerOptions(
                        speedInWordsPerMinute = config.defaultSpeedWPM,

                        // The audio file is generally big, so let's start loading it ASAP
                        bufferOnInit = true,
                        startingCursor = startingCursor,
                        contentTransformOptions = contentBundlerConfig.options,
                        utteranceBufferSizeOption = config.options,
                    ),
                    voicesOfPreferenceStateProvider = VoicesOfPreferenceStateProviderFromStaticValue(
                        voiceSpec = NarratedAudiobookPlaceholderVoiceSpec,
                    ),
                    voiceFactoryFromSpec = object : VoiceFactoryFromSpec {
                        @JsExport.Ignore
                        override suspend fun createVoice(spec: VoiceSpecOfAvailableVoice): Voice =
                            throw IllegalStateException(
                                /** ... because the `utteranceFlowProvider` specified above never uses a [Voice]
                                 * to synthesize.
                                 */
                                "createVoice should not be called for `AudioBookChapterBundle",
                            )
                    },
                    scope = scope,
                )
                ListeningBundle(
                    config = config,
                    audioController = audioController,
                    contentBundle = contentBundle,
                    voicesAvailableFromConfig = listOf(NarratedAudiobookPlaceholderVoiceSpec),
                    voicesAudioDownloads = emptyArray(),
                    hasDetectedGapsInDownloadedAudioThisListeningSessionFlow = MutableStateFlow(false).asStateFlow(),
                ).successfully()
            }

            else -> {
                val downloadedAudioForThisBundle = getDownloadedAudiosForBundle(contentBundle)
                // Compute once here in case it's expensive to query the adapter's voice list
                val voiceSpecAvailabilityProvider = clientServices.adaptersProvider.voiceSpecAvailabilityProvider

                val voices = telemetryEventBuilder.addMeasurement("createAllVoices") {
                    config.allVoices.mapNotNull { spec ->
                        voiceSpecAvailabilityProvider
                            .getSpecOfAvailableVoiceOrNull(
                                specToTry = spec,
                            )
                    }
                }
                val hasDetectedGapsInDownloadedAudioThisListeningSessionFlow = MutableStateFlow(false)

                val voicesOfPreferenceStateProvider = VoicesOfPreferenceStateProviderFromConfig(
                    voicePreferences = config.voicePreferences,
                    allVoicesForNoPreference = voices,
                    subscriptionService = clientServices.subscriptionService,
                    voiceSpecAvailabilityProvider = voiceSpecAvailabilityProvider,
                )
                ListeningBundle(
                    config = config,
                    audioController = AudioController(
                        speechView = contentBundle.speechView,
                        utteranceFlowProvider = UtteranceFlowProviderFromSpeechFlow,
                        initialOptions = AudioControllerOptions(
                            speedInWordsPerMinute = config.defaultSpeedWPM,
                            bufferOnInit = config.options.immediateAudioCacheWarming,
                            startingCursor = startingCursor,
                            contentTransformOptions = contentBundlerConfig.options,
                            utteranceBufferSizeOption = config.options,
                        ),
                        voicesOfPreferenceStateProvider = VoicesOfPreferenceStateProviderFromConfig(
                            voicePreferences = config.voicePreferences,
                            allVoicesForNoPreference = voices,
                            subscriptionService = clientServices.subscriptionService,
                            voiceSpecAvailabilityProvider = voiceSpecAvailabilityProvider,
                        ),
                        voiceFactoryFromSpec = bundlerPlugins.voiceFactory.toVoiceFactoryFromSpec(
                            speechSynthesisConfig = config,
                        ).let { originalVoiceFactory ->
                            object : VoiceFactoryFromSpec {
                                @JsExport.Ignore
                                override suspend fun createVoice(spec: VoiceSpecOfAvailableVoice): Voice =
                                    originalVoiceFactory.createVoice(spec)
                                        .withPersistentCachingEnabledIfRequestedForVoice(
                                            downloadedAudioForThisBundle = downloadedAudioForThisBundle,
                                            hasDetectedGapsInDownloadedAudioThisListeningSessionFlow =
                                            hasDetectedGapsInDownloadedAudioThisListeningSessionFlow,
                                            voiceOfPreferenceForOfflineProvider =
                                            object : VoiceOfPreferenceForOfflineProvider {
                                                @JsExport.Ignore
                                                override suspend fun getPreferredOfflineVoice(): Voice =
                                                    originalVoiceFactory.createVoice(
                                                        spec =
                                                        /** Need [coroutineScopeForSingleExpression] because a plain
                                                         * [kotlinx.coroutines.coroutineScope] would not return.
                                                         */
                                                        coroutineScopeForSingleExpression {
                                                            voicesOfPreferenceStateProvider
                                                                .getPreferredVoiceForOfflineStateIn(scope = this)
                                                                .value
                                                        },
                                                    )
                                            },
                                            dbService = clientServices.dbService,
                                        )
                            }
                        },
                        scope = scope,
                    ),
                    contentBundle = contentBundle,
                    voicesAvailableFromConfig = voices,
                    voicesAudioDownloads = downloadedAudioForThisBundle
                        .map {
                            it.toVoiceAudioDownloadInfo()
                        }
                        .toTypedArray(),
                    hasDetectedGapsInDownloadedAudioThisListeningSessionFlow =
                    hasDetectedGapsInDownloadedAudioThisListeningSessionFlow.asStateFlow(),
                ).successfully()
            }
        }
    }

    private fun Voice.withPersistentCachingEnabledIfRequestedForVoice(
        downloadedAudioForThisBundle: List<DownloadedAudioForItem>,
        hasDetectedGapsInDownloadedAudioThisListeningSessionFlow: MutableStateFlow<Boolean>,
        voiceOfPreferenceForOfflineProvider: VoiceOfPreferenceForOfflineProvider,
        dbService: DbService,
    ): Voice {
        if (this !is MediaVoice) {
            return this
        }

        val downloadedAudioForVoice = downloadedAudioForThisBundle.findForVoice(
            voice = this.voiceSpec,
        )

        return if (downloadedAudioForVoice != null) {
            this.withPersistentCachingEnabled(
                downloadedAudioForItem = downloadedAudioForVoice,
                onGapInPersistentAudioDetected = { _ ->
                    hasDetectedGapsInDownloadedAudioThisListeningSessionFlow.value = true
                    clientServices.dbService.getVoiceCacheQueries().setVoiceHasGapsInAudio(
                        hasGapsInAudio = DbBoolean(true),
                        documentUri = downloadedAudioForVoice.documentUri,
                        voiceId = downloadedAudioForVoice.voiceId,
                    )
                },
                voiceOfPreferenceForOfflineProvider = voiceOfPreferenceForOfflineProvider,
                offlineModeStatusFlowProvider = clientServices.adaptersProvider.offlineModeStatusFlowProvider,
                dbService = dbService,
            )
        } else {
            this
        }
    }

    private fun List<DownloadedAudioForItem>.findForVoice(
        voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer,
    ): DownloadedAudioForItem? =
        this.find { vod ->
            vod.downloadOptions.voice.getIdForDb() == voice.getIdForDb()
        }

    private suspend fun getDownloadedAudiosForBundle(
        contentBundle: ContentBundle,
    ): List<DownloadedAudioForItem> {
        // We check the importer state first since only fully imported items can have a voice downloaded.
        val importerState = contentBundle.coImporter.state
        val uri = if (importerState is ContentImporterState.ImportedToLibrary) {
            importerState.uri
        } else {
            null
        }

        // Then we can fetch all voices that are downloaded for this item. This includes non fully downloaded voices
        // as well.
        return if (uri != null) {
            clientServices.offlineAvailabilityManager.observeDownloadedAudioForUri(uri)
                .first()
        } else {
            emptyList()
        }
    }

    private fun checkVoiceSpecifications() {
        checkDuplicateDisplayNames()
    }

    private fun checkDuplicateDisplayNames() {
        val dupes = mutableSetOf<String>()
        config.allVoices.fold(emptySet<String>()) { nameSet, spec ->
            if (nameSet.contains(spec.displayName)) {
                dupes.add(spec.displayName)
            }
            nameSet + spec.displayName
        }
        if (dupes.isNotEmpty()) {
            Log.w(
                DiagnosticEvent(
                    message = "Duplicate voice display names detected",
                    sourceAreaId = "Voice Configuration",
                    properties = mapOf(
                        "duplicateDisplayNames" to dupes.joinToString(", "),
                    ),
                ),
            )
        }
    }
}
