package com.speechify.client.internal.services.importing

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.services.importing.models.CopyLibraryItemV1Payload
import com.speechify.client.internal.services.importing.models.CopyLibraryItemV2Payload
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import com.speechify.client.internal.util.collections.toNullIfValueNull
import com.speechify.client.internal.util.www.UrlString

internal fun buildCopyLibraryItemV1Payload(itemId: String): CopyLibraryItemV1Payload {
    return CopyLibraryItemV1Payload(
        currentRecordUid = itemId,
    )
}

internal fun buildCopyLibraryItemV2Payload(
    itemId: String,
    newItemId: String,
): CopyLibraryItemV2Payload {
    return CopyLibraryItemV2Payload(
        currentRecordUid = itemId,
        newRecordUid = newItemId,
    )
}

/**
 * This method is used to create an item with status as processing
 */
internal fun createProcessingLibraryItemToFirestorePayload(
    appEnvironment: AppEnvironment,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    owner: String,
    lastUpdatedPageId: String?,
    title: String?,
    recordType: String,
    parentFolderId: String?,
    customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    analyticsProperties: BoundaryMap<String>,
    initialStatus: String = "processing",
    createdBySDKVersion: String,
    createdByAppVersion: String,
    excerpt: String?,
    wordCount: Int?,
    charCount: Int?,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.ofNotNull(
        "admins" to emptyArray<String>(),
        "client" to appEnvironment.toString(),
        "createdAt" to now,
        "guests" to emptyArray<String>(),
        "isArchived" to false,
        "isArchivedV2" to false,
        "isRemoved" to false,
        RecordProperties.isRemovedV2.key.toPairWithVal(false),
        "isTopLevelArchivedItem" to false,
        ("lastUpdatedPageId" to lastUpdatedPageId).toNullIfValueNull(),
        "owner" to owner,
        "parentFolderId" to parentFolderId,
        "recordType" to recordType,
        *title?.let {
            arrayOf(
                "title" to it,
                "titleLowercase" to it.lowercase(),
            )
        }.orEmpty(),
        "status" to initialStatus,
        "type" to "record",
        "updatedAt" to now,
        "users" to emptyArray<String>(),
        /**
         * 'lastListenedAt' need to be initialized with null to be present in search results before first listening.
         */
        "lastListenedAt" to null,
        RecordProperties.analyticsProperties.key.toPairWithVal(analyticsProperties),
        RecordProperties.hasPageEdits.key.toPairWithVal(false),
        RecordProperties.createdBySDKVersion.key.toPairWithVal(createdBySDKVersion),
        RecordProperties.createdByAppVersion.key.toPairWithVal(createdByAppVersion),
        *customProperties.toList().toTypedArray(),
        RecordProperties.excerpt.key.toPairWithVal(excerpt),
        RecordProperties.wordCount.key.toPairWithVal(wordCount),
        RecordProperties.charCount.key.toPairWithVal(charCount),
    )
}

/**
 * This method is used to update a processing item with status as success.
 */
internal fun updateLibraryItemToFirestorePayload(
    lastUpdatedPageId: String?,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    title: String?,
    uriField: String? = null,
    coverImagePath: UrlString?,
    sourceURL: String?,
    sourceStoredURL: String?,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.of(
        RecordProperties.lastUpdatedPageId.key.toPairWithVal(lastUpdatedPageId),
        "updatedAt" to now,
        "status" to "success",
        *title?.let {
            arrayOf(
                "title" to it,
                "titleLowercase" to it.lowercase(),
            )
        }.orEmpty(),
        "uri" to uriField,
        RecordProperties.coverImagePath.key.toPairWithVal(coverImagePath),
        RecordProperties.sourceURL.key.toPairWithVal(sourceURL),
        RecordProperties.sourceStoredURL.key.toPairWithVal(sourceStoredURL),
    )
}

/**
 * Will only update those fields that are not null. Always updates the last updatedAt timestamp.
 */
internal fun partialUpdateLibraryItemToFirestorePayload(
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    coverImagePath: UrlString? = null,
    lastUpdatedPageId: String? = null,
    title: String? = null,
    sourceURL: String? = null,
    sourceStoredURL: String? = null,
    status: String? = null,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.ofNotNull(
        "updatedAt" to now,
        RecordProperties.coverImagePath.key.toPairWithVal(coverImagePath).toNullIfValueNull(),
        RecordProperties.lastUpdatedPageId.key.toPairWithVal(lastUpdatedPageId).toNullIfValueNull(),
        *title?.let {
            arrayOf(
                "title" to it,
                "titleLowercase" to it.lowercase(),
            )
        }.orEmpty(),
        RecordProperties.sourceURL.key.toPairWithVal(sourceURL).toNullIfValueNull(),
        RecordProperties.sourceStoredURL.key.toPairWithVal(sourceStoredURL).toNullIfValueNull(),
        ("status" to status).toNullIfValueNull(),
    )
}

internal fun createLibraryItemToFirestorePayload(
    owner: String,
    recordType: String,
    appEnvironment: AppEnvironment,
    parentFolderId: String?,
    analyticsProperties: BoundaryMap<String>,
    customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    lastUpdatedPageId: String?,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
    title: String?,
    uriField: String? = null,
    coverImagePath: UrlString?,
    sourceURL: String?,
    sourceStoredURL: String? = null,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.ofNotNull(
        "admins" to emptyArray<String>(),
        "client" to appEnvironment.toString(),
        RecordProperties.coverImagePath.key.toPairWithVal(coverImagePath),
        "createdAt" to now,
        "guests" to emptyArray<String>(),
        "isArchived" to false,
        "isArchivedV2" to false,
        "isRemoved" to false,
        RecordProperties.isRemovedV2.key.toPairWithVal(false),
        "isTopLevelArchivedItem" to false,
        "lastUpdatedPageId" to lastUpdatedPageId,
        "owner" to owner,
        "parentFolderId" to parentFolderId,
        /**
         * 'lastListenedAt' need to be initialized with null to be present in search results before first listening.
         */
        "lastListenedAt" to null,
        "recordType" to recordType,
        RecordProperties.sourceURL.key.toPairWithVal(sourceURL),
        // We don't want to overwrite and URL that might have been set on the backend, so we drop nulls here.
        RecordProperties.sourceStoredURL.key.toPairWithVal(sourceStoredURL).toNullIfValueNull(),
        "status" to "processing",
        *title?.let {
            arrayOf(
                "title" to it,
                "titleLowercase" to it.lowercase(),
            )
        }.orEmpty(),
        "type" to "record",
        "updatedAt" to now,
        "users" to emptyArray<String>(),
        "uri" to uriField,
        RecordProperties.analyticsProperties.key.toPairWithVal(analyticsProperties),
        RecordProperties.hasPageEdits.key.toPairWithVal(false),
        *customProperties.toList().toTypedArray(),
    )
}

internal fun successfulCreateLibraryItemToFirestorePayload(
    lastUpdatedPageId: String,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.of(
        "lastUpdatedPageId" to lastUpdatedPageId,
        "updatedAt" to now,
        "status" to "success",
    )
}

internal fun setLibraryItemStatusToErrorPayload(
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.of(
        "status" to "error",
        "updatedAt" to now,
    )
}

internal fun createPagesFirestorePayload(
    storagePath: String,
    firebaseTimestampAdapter: FirebaseTimestampAdapter,
): BoundaryMap<Any?> {
    val now = firebaseTimestampAdapter.now()
    return SdkBoundaryMap.of(
        "createdAt" to now,
        "index" to 0,
        "storagePath" to storagePath,
        "updatedAt" to now,
    )
}

internal fun convertStringToHtmlDocument(txtString: String, title: String): String {
    /* ktlint-disable */
    return "<!doctype html><html lang=\"en\"><head><meta charset=\"utf-8\"><meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><title>${
    convertStringToHtmlFragment(
        title,
    )
    }</title></head><body>${convertStringToHtmlFragment(txtString)}</body></html>"
}

private fun convertStringToHtmlFragment(txtString: String) =
    txtString.replace(Regex("[<&]")) {
        when (it.value) {
            "<" -> "&lt;"
            "&" -> "&amp;"
            else -> throw Exception("Unknown match ${it.value}")
        }
    }

internal fun getTitleFromTxtString(txtString: String): String {
    val splitText = txtString.split(" ")
    return if (splitText.size >= 6)
        splitText.subList(0, 6).joinToString(separator = " ", postfix = "...")
    else
        splitText.joinToString(separator = " ")
}
