package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.last
import kotlinx.coroutines.flow.map
import kotlin.math.floor

internal abstract class BaseEpubV2ContentIndex(
    open val standardView: EpubStandardViewV2,
) : WithScope(), ContentIndex {
    private val metadata by lazy { standardView.view.getMetadata() }

    // Cache chapter position information for better performance
    // Each chapter stores where it starts and how much of the book it takes up
    // Example: For a book with 3 chapters of sizes 20%, 30%, and 50%:
    // Chapter 0: startProgress=0.0, sizeFraction=0.20
    // Chapter 1: startProgress=0.20, sizeFraction=0.30
    // Chapter 2: startProgress=0.50, sizeFraction=0.50
    private val chapterToStartInfo = coLazy {
        standardView.view.getChaptersEstimatedContentRatios().entries
            .sortedBy { it.key }
            .fold(mutableMapOf<Int, ChapterStartInfo>()) { map, (chapterIndex, percentage) ->
                val startProgress = map.values.sumOf { it.sizeFraction }
                map[chapterIndex] = ChapterStartInfo(
                    startProgress = startProgress,
                    sizeFraction = percentage / 100.0,
                )
                map
            }
    }

    protected abstract val contentStatsFlow: Flow<ContentStats>

    override val contentAmountStateFlow: ExternalStateChangesFlow
        get() = contentStatsFlow.map {}

    override fun getCursorFromProgress(
        progress: ProgressFraction,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        if (progress == 0.0) return@fromCo standardView.start.successfully()

        // Find which chapter contains the given progress value
        // For progress 0.45 (45% through book):
        // - Chapter 0: 0.0-0.20 ❌
        // - Chapter 1: 0.20-0.50 ✅ (contains 0.45)
        // - Chapter 2: 0.50-1.0 ❌
        val chapterToStartInfo = chapterToStartInfo.getWithCancellation()
        val chapterIndex = chapterToStartInfo.entries.find { (_, info) ->
            progress >= info.startProgress && progress < (info.startProgress + info.sizeFraction)
        }?.key ?: chapterToStartInfo.keys.last()

        val chapterInfo = getChapterStartInfo(chapterIndex)
        val chapterBlocks = standardView.getBlocksForChapter(chapterIndex).blocks.toList()

        // 2. Calculate progress within this chapter
        // - Chapter 1 starts at 20% (0.20)
        // - Chapter 1 is 30% of book (0.30)
        // - inChapterProgress = (0.45 - 0.20) / 0.30 = 0.833
        // - We're 83.3% through Chapter 1
        val inChapterProgress = ((progress - chapterInfo.startProgress) / chapterInfo.sizeFraction)
            .coerceIn(0.0, 1.0)

        getCursorFromProgressInChapter(
            chapterText = chapterBlocks.map { it.text },
            chapterIndex = chapterIndex,
            progress = inChapterProgress,
        ).successfully()
    }

    override fun getProgressFromCursor(
        cursor: ContentCursor,
        callback: Callback<ProgressFraction>,
    ) = callback.fromCo {
        if (cursor.isEqual(standardView.start)) return@fromCo 0.0.successfully()

        val chapterIndex = standardView.view.getChapterIndex(cursor = cursor)
        val chapter = standardView.getBlocksForChapter(chapterIndex = chapterIndex)

        getChapterToGlobalProgressFromCursor(
            chapterIndex = chapterIndex,
            chapterText = chapter.blocks.toList().map { it.text },
            cursor = cursor,
        ).successfully()

        /*
        Example for cursor -> progress conversion:
        If book has 3 chapters:
        Chapter 0: 20%
        Chapter 1: 30%
        Chapter 2: 50%
        And cursor is at 60% through Chapter 1:
        - Chapter 1 starts at 20% (0.20)
        - Chapter 1 is 30% of book (0.30)
        - We're 60% through chapter text (0.60)
        - Final calculation: 0.20 + (0.60 × 0.30) = 0.38
        - Meaning we're 38% through the entire book
        */
    }

    override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
        contentStatsFlow.first().successfully()
    }

    override suspend fun getStatsIncludingPending(): ContentStats? = contentStatsFlow.last()

    override fun destroy() {
        scope.cancel()
    }

    private fun getCursorFromProgressInChapter(
        chapterText: List<ContentText>,
        chapterIndex: Int,
        progress: Double,
    ): ContentCursor {
        // Handle empty chapter case
        if (chapterText.isEmpty()) {
            return ContentElementReference.fromPath(path = listOf(chapterIndex)).start
        }

        // For a chapter with 1000 characters and progress 0.833:
        // 1. Find the character index: floor(0.833 * 1000) = 833
        // 2. Return cursor pointing to the 833rd character
        val allChapterText = ContentTextUtils.concat(chapterText)
        val chapterChars = allChapterText.length

        val progressCharIndex = floor(progress * chapterChars).toInt()
            .coerceIn(minimumValue = 0, maximumValue = chapterChars)

        return allChapterText.getFirstCursorAtIndex(characterIndex = progressCharIndex)
    }

    private suspend fun getChapterToGlobalProgressFromCursor(
        chapterIndex: Int,
        chapterText: List<ContentText>,
        cursor: ContentCursor,
    ): Double {
        val chapterInfo = getChapterStartInfo(chapterIndex)

        if (chapterText.isEmpty()) return chapterInfo.startProgress

        // Convert cursor position to progress within the chapter
        // For 1000 chars chapter, cursor at char 600:
        // progressWithinChapter = 600/1000 = 0.60
        val allChapterText = ContentTextUtils.concat(chapterText)
        val progressWithinChapter = if (allChapterText.length > 1) {
            allChapterText.getLastIndexOfCursor(cursor).toDouble() / (allChapterText.length - 1).toDouble()
        } else {
            0.0
        }

        // Convert chapter progress to global progress
        // For chapter starting at 20% and taking 30% of book:
        // 0.20 + (0.60 × 0.30) = 0.38 (38% through book)
        return chapterInfo.startProgress + (progressWithinChapter * chapterInfo.sizeFraction)
    }

    private suspend fun getChapterStartInfo(chapterIndex: Int): ChapterStartInfo {
        return chapterToStartInfo.getWithCancellation()[chapterIndex] ?: ChapterStartInfo(0.0, 0.0)
    }
}

private data class ChapterStartInfo(
    val startProgress: Double, // Where this chapter begins in the book (0.0-1.0)
    val sizeFraction: Double, // How much of the book this chapter takes up (0.0-1.0)
)
