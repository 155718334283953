package com.speechify.client.internal.services.subscription.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseUserAudiobookCredits(
    val creditsLeft: Int = 0,
    val lastDecrementedAt: DateTime? = null,
    val lastGrantedAt: DateTime? = null,
)
