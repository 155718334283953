package com.speechify.client.api.adapters.pdf

import com.speechify.client.api.adapters.pdf.search.PdfSearchOptions
import com.speechify.client.api.adapters.pdf.search.PdfSearchResult
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * An efficient, minimal wrapper for accessing the text and images contained in a PDF.
 */
@JsExport
interface PDFDocumentAdapter : Destructible {
    /**
     * Get metadata describing the PDF document
     */
    fun getMetadata(): PDFDocumentMetadata

    /**
     * Get wrappers for accessing the text and images contained in the specified pages of a PDF.
     */
    fun getPages(pageIndexes: Array<Int>, callback: Callback<Array<PDFPageAdapter>>)

    val title: String
    fun getThumbnail(
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    fun getOutline(callback: Callback<PDFOutline>)

    /**
     * search for the [text] in the pdf document, respecting the constraints set by [searchOptions]
     *
     * @param text string to search
     * @param startPageIndex starting index of page to search, inclusive
     * @param endPageIndex end index of page to search, inclusive
     * @param searchOptions optional configuration for the search
     * @param callback to provide the array of [PdfSearchResult] for the string that matches exactly with [text]
     *
     * @see PdfSearchOptions
     */
    fun search(
        text: String,
        startPageIndex: Int,
        endPageIndex: Int,
        searchOptions: PdfSearchOptions,
        callback: Callback<Array<PdfSearchResult>>,
    )
}

internal suspend fun PDFDocumentAdapter.coGetPages(pageIndexes: Array<Int>) =
    suspendCoroutine { cont -> getPages(pageIndexes, cont::resume) }

internal suspend fun PDFDocumentAdapter.coGetThumbnail() =
    suspendCoroutine { cont -> getThumbnail(cont::resume) }
        .orThrow()

internal suspend fun PDFDocumentAdapter.coGetOutline() = suspendCoroutine { getOutline(it::resume) }

internal suspend fun PDFDocumentAdapter.coSearch(
    text: String,
    startPageIndex: Int,
    endPageIndex: Int,
    searchOptions: PdfSearchOptions,
) = suspendCoroutine { cont ->
    search(
        text,
        startPageIndex,
        endPageIndex,
        searchOptions,
        cont::resume,
    )
}
