package com.speechify.client.reader.core

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.sample
import kotlinx.coroutines.flow.scan
import kotlin.js.JsExport
import kotlin.time.Duration.Companion.seconds

@JsExport
class PaywallHelper internal constructor(
    scope: CoroutineScope,
    speechifyClient: SpeechifyClient,
    readingBundle: ReadingBundle,
) : Helper<PaywallState>(scope) {

    override val initialState: PaywallState = PaywallState.NotReady

    private val entitlementsFlow = speechifyClient.subscriptionService.getEntitlementsFlowOfResults()
        .map { it.toNullable() }
        .filterNotNull()

    private val bookReadingBundleFlow = flowOf(readingBundle)
        .filterIsInstance<BookReadingBundle>()

    @OptIn(ExperimentalCoroutinesApi::class)
    private val loadedPagesIndexesFlow = bookReadingBundleFlow.flatMapLatest {
        it.bookContent.bookView.parsedPageContentFlow.map { it.first }
    }.scan(mutableSetOf<BookPageIndex>()) { accumulator, value ->
        accumulator.add(value)
        accumulator
    }

    @OptIn(ExperimentalCoroutinesApi::class, FlowPreview::class)
    private val emptyOCRedPagesIndexesFlow = bookReadingBundleFlow.flatMapLatest {
        /**
         * applying filter here to pick only OCR-ed pages with literally empty text content, which typically
         * correspond to image-based PDF files.
         * Clients prefer not to count pages OCRed based on SDK heuristics.
         */
        it.bookContent.bookPageOCRRequirementFlow.filter { it.second }.map { it.first }
    }.scan(mutableSetOf<BookPageIndex>()) { accumulator, value ->
        accumulator.add(value)
        accumulator
    }.sample(1.0.seconds)

    override val stateFlow: StateFlow<PaywallState> = combine(
        loadedPagesIndexesFlow,
        emptyOCRedPagesIndexesFlow,
        entitlementsFlow,
    ) { loadedPagesIndexes, emptyOCRedPagesIndexes, entitlement ->

        PaywallState.Ready(
            isHdWordsQuotaExhausted = entitlement.hdWordsLeft <= 0,
            numberOfLoadedPagesSoFar = (loadedPagesIndexes + emptyOCRedPagesIndexes).size,
            numberOfOCRedEmptyPagesSoFar = emptyOCRedPagesIndexes.size,
        )
    }.stateInHelper(initialValue = initialState)
}

@JsExport
sealed class PaywallState {
    data class Ready(
        val isHdWordsQuotaExhausted: Boolean,
        val numberOfLoadedPagesSoFar: Int,
        val numberOfOCRedEmptyPagesSoFar: Int,
    ) : PaywallState()

    object NotReady : PaywallState()
}
