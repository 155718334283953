package com.speechify.client.internal.services.file.models

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.fromBlock
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.successfully
import kotlin.js.JsExport

/**
 * A convenience interface for implementing files that groups the common logic for it around expecting a non-null [contentType].
 */
abstract class FileBase : File() {
    final override val contentType: String get() = contentTypeOrNull ?: "application/octet-stream"

    override val mimeType: MimeType get() = mimeTypeOrNull ?: MimeType(contentType)

    abstract val contentTypeOrNull: String?

    abstract val mimeTypeOrNull: MimeType?
}

@JsExport
open class InMemoryFile
/**
 * Constructor taking both the [mimeType] and [contentType], especially for maintaining historical behavior
 * (see [File.contentType]).
 */
constructor(
    override val mimeTypeOrNull: MimeType?,
    override val contentTypeOrNull: String?,
    val bytes: ByteArray,
) : FileBase() {
    /**
     * Constructor overload taking just the [contentType].
     * NOTE: [contentType] will include the full MIME type (including metadata, e.g. `; charset=utf-8`).
     */
    internal constructor(
        contentType: String?,
        bytes: ByteArray,
    ) : this(
        mimeType = contentType?.let { MimeType(it) },
        bytes = bytes,
    )

    /**
     * Constructor overload taking just the [mimeType].
     * NOTE: [contentType] will include the full MIME type (including metadata, e.g. `; charset=utf-8`).
     */
    internal constructor(
        mimeType: MimeType?,
        bytes: ByteArray,
    ) : this(
        mimeTypeOrNull = mimeType,
        contentTypeOrNull = mimeType?.fullString,
        bytes = bytes,
    )

    override fun getSizeInBytes(callback: Callback<Int>) = callback.fromBlock {
        bytes.size
            .successfully()
    }

    override fun getBytes(
        startIndex: Int,
        endIndex: Int,
        callback: Callback<ByteArray>,
    ) = callback.fromBlock {
        if (startIndex == 0 && endIndex == bytes.size) {
            /** [ByteArray.sliceArray] makes a copy of the array, so let's save from copying if requesting the entire
             * contents.
             */
            return@fromBlock bytes
                .successfully()
        } else {
            bytes.sliceArray(startIndex until endIndex)
                .successfully()
        }
    }
}
