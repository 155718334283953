package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisVoiceBySpecProvider

/**
 * Component for translating [VoiceSpec]s into [VoiceSpecOfAvailableVoice]s (or null, if not available).
 */
internal class VoiceSpecAvailabilityProvider(
    private val localSpeechSynthesisVoiceBySpecProvider: LocalSpeechSynthesisVoiceBySpecProvider,
) {
    /**
     * Translates [VoiceSpec]s into [VoiceSpecOfAvailableVoice]s (or null, if not available).
     */
    suspend fun getSpecOfAvailableVoiceOrNull(
        /**
         * The [VoiceSpec] to try to find an available voice for.
         */
        specToTry: VoiceSpec,
    ): VoiceSpecOfAvailableVoice? =
        when (specToTry) {
            is VoiceSpecOfAvailableVoice -> specToTry
            is VoiceSpec.Local -> {
                localSpeechSynthesisVoiceBySpecProvider.getSpecWithDetailsOrNull(specToTry)
            }
        }
}
