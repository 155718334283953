package com.speechify.client.internal.services.highlight

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.SpeechifyVersions
import com.speechify.client.api.adapters.firebase.CollectionReference
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.PersistedUserHighlight
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.services.library.models.toPersistedStyle
import com.speechify.client.api.services.library.models.toUserHighlight
import com.speechify.client.api.util.Result
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.highlight.models.CreateHighlightPayload
import com.speechify.client.internal.services.highlight.models.DeleteHighlightPayload
import com.speechify.client.internal.services.highlight.models.MergeHighlightsPayload
import com.speechify.client.internal.services.highlight.models.NewHighlightRequestData
import com.speechify.client.internal.services.highlight.models.UpdateHighlightPayload
import com.speechify.client.internal.services.highlight.models.UpdateHighlightRequestData
import com.speechify.client.internal.services.library.CursorSurgeon
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.mapNotNull

internal fun getUserHighlightsCollectionRef(): CollectionReference =
    "itemHighlights"

/**
 * A mediator layer to interact with firestore, Consider it like a repository.
 */
internal class UserHighlightsService internal constructor(
    private val firestoreService: FirebaseFirestoreService,
    private val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
) {

    /**
     * be careful while using this, If needed, use the upper layer helper to get this info , otherwise
     * multiple calls to this may cause duplicate read queries on FireStore as we are not caching the
     * response from firestore to share among multiple clients of this function.
     */
    internal fun observeHighlights(itemId: SpeechifyContentId): Flow<List<UserHighlight>> {
        val collectionRef = getUserHighlightsCollectionRef()
        return firestoreService.observeDocumentAsFlow(
            collectionRef = collectionRef,
            documentRef = itemId,
            mapExists = ::toUserHighlights,
            valueForNotExists = {
                Result.Success(emptyList())
            },
        ).mapNotNull { result ->
            when (result) {
                is Result.Success -> {
                    result.value
                }

                is Result.Failure -> {
                    Log.e(result.error, sourceAreaId = "UserHighlightsService.observeHighlights")
                    null
                }
            }
        }
    }

    private fun toUserHighlights(snapshot: FirebaseFirestoreDocumentSnapshot.Exists): Result<List<UserHighlight>> {
        return snapshot
            .value<Map<String, List<PersistedUserHighlight>>>()
            .map { it["highlights"] }
            .map { persistedHighlights ->
                persistedHighlights?.map {
                    it.toUserHighlight()
                } ?: emptyList()
            }
    }

    internal suspend fun createHighlight(
        itemId: SpeechifyContentId,
        highlight: UserHighlight,
    ): Result<Unit> {
        return firebaseFunctionsService.addItemHighlight(
            CreateHighlightPayload(
                itemId,
                userHighlightCreatePayload(highlight),
            ),
        )
    }

    internal suspend fun mergeHighlights(
        itemId: SpeechifyContentId,
        newHighlight: UserHighlight,
        highlightsToMerge: List<UserHighlight>,
    ): Result<Unit> {
        return firebaseFunctionsService.mergeItemHighlights(
            MergeHighlightsPayload(
                itemId,
                highlightsToMerge.map { it.id }.toTypedArray(),
                userHighlightCreatePayload(newHighlight),
            ),
        )
    }

    internal suspend fun deleteHighlight(
        itemId: SpeechifyContentId,
        highlight: UserHighlight,
    ): Result<Unit> {
        return firebaseFunctionsService.deleteItemHighlight(
            DeleteHighlightPayload(
                itemId,
                highlight.id,
            ),
        )
    }

    internal suspend fun updateHighlight(
        itemId: SpeechifyContentId,
        highlight: UserHighlight,
    ): Result<Unit> {
        return firebaseFunctionsService.updateItemHighlight(
            UpdateHighlightPayload(
                itemId,
                userHighlightUpdatePayload(highlight),
            ),
        )
    }

    private fun userHighlightCreatePayload(
        userHighlight: UserHighlight,
    ): NewHighlightRequestData {
        return NewHighlightRequestData(
            userHighlight.id,
            userHighlight.text,
            CursorSurgeon.destructure(userHighlight.robustStart.hack.cursor),
            CursorSurgeon.destructure(userHighlight.robustEnd.hack.cursor),
            userHighlight.style.toPersistedStyle(),
            userHighlight.note,
            userHighlight.pageIndex,
            SpeechifyVersions.SDK_VERSION,
            SpeechifyVersions.SDK_VERSION,
        )
    }

    private fun userHighlightUpdatePayload(
        userHighlight: UserHighlight,
    ): UpdateHighlightRequestData {
        return UpdateHighlightRequestData(
            userHighlight.id,
            userHighlight.text,
            CursorSurgeon.destructure(userHighlight.robustStart.hack.cursor),
            CursorSurgeon.destructure(userHighlight.robustEnd.hack.cursor),
            userHighlight.style.toPersistedStyle(),
            userHighlight.note,
            userHighlight.pageIndex,
            SpeechifyVersions.SDK_VERSION,
        )
    }
}
