package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.json.JsonElementProvider
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.successfully
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlin.js.JsExport

private val jsonFormatter = Json {
    coerceInputValues = true
    ignoreUnknownKeys = true
    allowSpecialFloatingPointValues = true /* While `NaN`s are invalid JSON, such a JSON actually gets
                     served by Firestore, so we should allow it (even to just be able to clean the NaN's up
                     in SDK - it needs to at least succeed deserializing).
                     */
}

@JsExport
data class SnapshotRef(val get: Any)

@JsExport
interface HasSnapshotRef {
    val snapshotRef: SnapshotRef
}

@JsExport
interface HasUri {
    val uri: SpeechifyURI
}

@JsExport
sealed class FirebaseFirestoreDocumentSnapshot {
    val exists get() = this is Exists

    object NotExists : FirebaseFirestoreDocumentSnapshot()

    data class Exists(
        /** The id of the document in Firestore. */
        val key: String,
        /** A [JsonElementProvider] representing the data of the document. */
        val data: JsonElementProvider,
        /** A reference to the original snapshot object. */
        val snapshotRef: SnapshotRef,
    ) :
        FirebaseFirestoreDocumentSnapshot() {
        /**
         * Deserialize the value.
         *
         * ### Errors
         * - [SDKError.Serialization] when serialization fails
         */
        internal inline fun <reified T> value(): Result<T> {
            return try {
                jsonFormatter.decodeFromJsonElement<T>(this.data.toJsonElement()).successfully()
            } catch (e: SerializationException) {
                Result.Failure(SDKError.Serialization(e, this.data, T::class))
            }
        }
    }
}
