package com.speechify.client.helpers.features

import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.content.SentenceAndWordLocation
import com.speechify.client.api.content.view.speech.SpeechFlowProvider
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.helpers.ui.overlays.ContentOverlayProvider
import com.speechify.client.helpers.ui.overlays.ContentOverlayRange
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlowMutable
import com.speechify.client.internal.util.collections.flows.externalStateChangesFlow
import com.speechify.client.internal.util.extensions.intentSyntax.letIfTrueOrLeave
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.transform
import kotlin.js.JsExport

@JsExport
open class WordAndSentenceOverlay<ContentRef : Any?> internal constructor(
    val sentenceOverlayRanges: Array<ContentOverlayRange<ContentRef>>,
    val wordOverlayRanges: Array<ContentOverlayRange<ContentRef>>,
)

@JsExport
class CurrentWordAndSentenceOverlayEvent<ContentRef : Any?> internal constructor(
    sentenceOverlayRanges: Array<ContentOverlayRange<ContentRef>>,
    wordOverlayRanges: Array<ContentOverlayRange<ContentRef>>,
) : WordAndSentenceOverlay<ContentRef>(
    sentenceOverlayRanges = sentenceOverlayRanges,
    wordOverlayRanges = wordOverlayRanges,
)

/**
 * A helper that efficiently provides live updates with the [ContentOverlayRange]s
 * required for "current word and sentence highlighting" features.
 */
@JsExport
class CurrentWordAndSentenceOverlayHelper<ContentRef : Any?> constructor(
    playbackControls: PlaybackControls,
    private val overlayProvider: ContentOverlayProvider<ContentRef>,
) {
    /**
     * Register a [listener] to be called with the overlay ranges (provided by [overlayProvider])
     * for the "current" word and sentence, based on the events emitted by the [AudioController].
     */
    fun addEventListener(
        listener: CallbackNoError<CurrentWordAndSentenceOverlayEvent<ContentRef>>,
    ): Destructor =
        listener
            .multiShotFromFlowIn(
                flow = speechLocationInContentFlow
                    .combine(
                        externalRenderingChangesFlow,
                        transform = { speechLocationInContent, _ ->
                            speechLocationInContent /* Not using the other flow-values - they are `Unit`, and we just
                                    use the `externalRenderingChangesFlow` to trigger a re-computation */
                        },
                    )
                    .transform { speechLocationInContent ->
                        val overlayEventOrNullIfUnknownLocation = try {
                            speechLocationInContent
                                /** The below is needed to resurrect any 'killed' [com.speechify.client.api.content.ContentElementReference.ref]s,
                                 *  in case they have 'limited-life' (as defined in [com.speechify.client.helpers.content.standard.HasInfoOfContentLimitedLife])
                                 *  This achieves restoring of the highlight more promptly than just when taking a new piece of content
                                 *  for constructing an utterance for synthesis: thanks to it, it will refresh as soon
                                 *  as highlighting moves to a new word.
                                 */
                                ?.letIfTrueOrLeave(
                                    shouldTransform = !speechFlowProvider
                                        /**
                                         * Surfaced the entire function of [com.speechify.client.helpers.content.standard.HasInfoOfContentLimitedLife.isAlive],
                                         * to only refresh when the content is killed. BTW, always re-fetching it (which
                                         * would lead to correct behavior - just finding the same references again) was
                                         * already causing anemic highlighting in Google Docs.
                                         */
                                        .isAlive(
                                            ref = speechLocationInContent.sentence.start.getParentElement().ref,
                                        ),
                                    transform = { originalLocation ->
                                        val updatedSentence = speechFlowProvider
                                            .getFullSentencesFlowFromSentenceContaining(
                                                startingPoint = originalLocation.sentence.start,
                                            )
                                            .firstOrNull { sentence ->
                                                sentence.start == originalLocation.sentence.start
                                            }
                                            ?: return@letIfTrueOrLeave originalLocation

                                        SentenceAndWordLocation(
                                            sentence = updatedSentence.text,
                                            word = originalLocation.word
                                                ?.let { originalWord ->
                                                    updatedSentence.getWordsWithPunctuationSequence()
                                                        .firstOrNull { word -> word.start == originalWord.start }
                                                        ?: originalWord
                                                },
                                        )
                                    },
                                )
                                ?.toWordAndSentenceOverlayEvent()
                        } catch (e: Throwable) {
                            Log.e(
                                DiagnosticEvent(
                                    sourceAreaId = "CurrentWordAndSentenceOverlayHelper",
                                    message = "Failed to get overlays due to {error}. Falling back to not updating " +
                                        "the overlays.",
                                    nativeError = e,
                                ),
                            )
                            return@transform
                        }

                        emit(
                            value = overlayEventOrNullIfUnknownLocation
                                /* When no known location is found, remove the overlay: */
                                ?: CurrentWordAndSentenceOverlayEvent(
                                    sentenceOverlayRanges = emptyArray(),
                                    wordOverlayRanges = emptyArray(),
                                ),
                        )
                    },
                scope = parentScope,
            )::destroy

    private val speechFlowProvider: SpeechFlowProvider =
        playbackControls.listeningBundle.contentBundle.speechView

    /**
     * Call this after rendering new overlay components that could pertain to the current sentence/word.
     *
     * Causes the overlay components to be re-determined, and the listeners to be re-called.
     */
    fun signalRenderingChanged() {
        /** TODO - one could link this with [com.speechify.client.helpers.ui.overlays.RenderedContentOverlayProvider]'s
         *   mutation methods so that SDK consumers don't have to call this at all, but would need to refactor and make
         *   sure it is efficient in practice - would need to test changes to see if they pertain to the current
         *   highlight.
         */
        externalRenderingChangesFlow.signalChange()
    }

    private val parentScope = playbackControls.scopeForChildren

    private val speechLocationInContentFlow = playbackControls
        .stateFlow
        .map { it.sentenceAndWordLocation }
        .distinctUntilChanged()

    private val externalRenderingChangesFlow: ExternalStateChangesFlowMutable =
        externalStateChangesFlow()

    private fun SentenceAndWordLocation.toWordAndSentenceOverlayEvent():
        CurrentWordAndSentenceOverlayEvent<ContentRef> =
        CurrentWordAndSentenceOverlayEvent(
            sentenceOverlayRanges = overlayProvider.getOverlayRanges(sentence),
            wordOverlayRanges = word
                ?.let { word -> overlayProvider.getOverlayRanges(word) }
                ?: arrayOf(),
        )

    internal /* #InternalForTests */
    fun getWordAndSentenceOverlay(
        sentenceAndWordLocation: SentenceAndWordLocation,
    ): WordAndSentenceOverlay<ContentRef> =
        sentenceAndWordLocation.toWordAndSentenceOverlayEvent()
}
