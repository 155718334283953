package com.speechify.client.api.content.view.book.parser

import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.book.HeadersAndFooters
import com.speechify.client.helpers.content.standard.book.Line
import com.speechify.client.helpers.content.standard.book.LineGroup
import com.speechify.client.helpers.content.standard.book.LineStats
import com.speechify.client.helpers.content.standard.book.detectFootnotes
import com.speechify.client.helpers.content.standard.book.toParsedBookPageTextGroup

internal class HeuristicsBookPageParser(
    // #InternalForTesting
    internal val bookPageDelegate: BookPageDelegate,
    private val getSurroundingLineGroups: suspend (pageIndex: Int, pageOffset: Int) -> Result<List<List<LineGroup>>>,
) : BookPageParser {

    private val pageIndex: Int
        get() = bookPageDelegate.pageIndex

    private val bookPageMetadata
        get() = bookPageDelegate.getMetadata()

    override suspend fun parse(
        rawTextContentItems: List<BookPageTextContentItem>,
    ): Result<ParsedPageContent> {
        val lines = Line.groupsFrom(rawTextContentItems.filterNotTo(mutableListOf()) { it.text.text.isBlank() })
        val stats = LineStats.of(lines)
        val currentPageLineGroups = LineGroup.groupsFrom(lines, stats)

        // Now we can find headers, using the content of neighbouring pages.
        val headersAndFooters = HeadersAndFooters.detectIn(
            lineGroups = currentPageLineGroups,
            surroundingLineGroups = getSurroundingLineGroups(pageIndex, 2).orReturn { return it },
            stats = stats,
            pageSize = bookPageMetadata.viewport,
        )

        // We can simplify the detection if we ignore already detected headers and footers.
        val linesWithoutHeadersAndFooters = currentPageLineGroups -
            headersAndFooters.footers.toSet() -
            headersAndFooters.headers.toSet()
        val footnotes = detectFootnotes(linesWithoutHeadersAndFooters, stats, bookPageMetadata.viewport)
        val textItemsGroupedAndOrdered =
            currentPageLineGroups.toParsedBookPageTextGroup(headersAndFooters, footnotes).toList()

        return ParsedPageContent(textItemsGroupedAndOrdered).successfully()
    }
}
