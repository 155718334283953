package com.speechify.client.api.adapters.ocr

import com.speechify.client.api.util.images.BoundingBox
import kotlin.js.JsExport

@kotlinx.serialization.Serializable
@JsExport
data class OCRTextContent(
    /**
     * The bounding box of the text. These coordinates are defined with reference to viewport provided by the page.
     * NOTE: the origin of the viewport is at the upper left of the image.
     */
    val boundingBox: BoundingBox,

    /**
     * The text of the page
     */
    val text: String,
)
