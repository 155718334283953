package com.speechify.client.bundlers.reading.embedded

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.adapters.events.initializeEventsFlowForEmbeddedReadingBundle
import com.speechify.client.api.adapters.html.DOMElement
import com.speechify.client.api.content.StandardViewWithIndex
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.ensureListenableMimeType
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentBundler
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload
import com.speechify.client.bundlers.content.createBundleForHtmlFromSdkElement
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.bundlers.reading.BasicReadingBundleWithContent
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.EmbeddedBundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.ReadingBundlerBase
import com.speechify.client.bundlers.reading.dynamic.DynamicStandardViewReadingBundle
import com.speechify.client.helpers.content.standard.dynamic.DynamicStandardView
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import kotlin.js.JsExport

/**
 * A way to get a [EmbeddedReadingBundle] from any content, taking care of all the setup for an reading experience in
 * which you're reading the content *where* you found it, rather than rendering our own view of the content.
 *
 * Examples include:
 * - Browser extensions
 * - Speechify API integrations (as of July 2022)
 */
@JsExport
class EmbeddedReadingBundler internal constructor(
    internal val contentBundler: ContentBundler,
    override val listeningBundler: ListeningBundler,
    internal val config: EmbeddedReadingBundlerConfig,
    internal val eventsTrackerAdapter: EventsTrackerAdapter,
    internal val clientConfig: ClientConfig,
    internal val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
) : ReadingBundlerBase() {
    internal suspend fun createEmbeddedBundle(
        contentBundle: ContentBundle,
        bundleMetadata: EmbeddedBundleMetadata,
    ): Result<EmbeddedReadingBundle> {
        val playbackControls = contentBundle.createPlaybackControls()
            .orReturn { return it }

        initializeEventsFlowForEmbeddedReadingBundle(
            playbackControls,
            contentBundle,
            bundleMetadata,
            clientConfig,
            eventsTrackerAdapter,
            firebaseFunctionsService,
        )

        return EmbeddedReadingBundle(
            playbackControls = playbackControls,
        )
            .successfully()
    }

    /**
     * Creates a [EmbeddedReadingBundle] for the [standardView] provided.
     */
    fun createBundleForStandardView(
        standardView: StandardView,
        bundleMetadata: EmbeddedBundleMetadata,
        callback: Callback<BasicReadingBundleWithContent<ContentBundle>>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "EmbeddedReadingBundler.createBundleForStandardView",
    ) {
        return@fromCoWithErrorLogging when (standardView) {
            is DynamicStandardView -> {
                createBundleForDynamicStandardView(
                    standardView = standardView,
                    bundleMetadata = bundleMetadata,
                )
                    .successfully()
            }

            else -> {
                createEmbeddedBundle(
                    contentBundle = createContentBundleFromStandardView<StandardView>(standardView),
                    bundleMetadata = bundleMetadata,
                )
            }
        }
    }

    /**
     * Creates a specialized [DynamicStandardViewReadingBundle] for the [dynamicStandardView] provided,
     * with dynamic-content-specific capabilities.
     *
     * See [DynamicStandardView] constructors on how to create one.
     * See [DynamicStandardViewReadingBundle] for capabilities of such bundles.
     */
    fun createBundleForDynamicStandardView(
        dynamicStandardView: DynamicStandardView,
        bundleMetadata: EmbeddedBundleMetadata,
        callback: Callback<DynamicStandardViewReadingBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "EmbeddedReadingBundler.createBundleForDynamicStandardView",
    ) {
        createBundleForDynamicStandardView(
            standardView = dynamicStandardView,
            bundleMetadata = bundleMetadata,
        )
            .successfully()
    }

    @JsExport.Ignore
    suspend fun createBundleForDynamicStandardView(
        standardView: DynamicStandardView,
        bundleMetadata: EmbeddedBundleMetadata,
    ): DynamicStandardViewReadingBundle {
        val contentBundle = createContentBundleFromStandardView(
            standardView = standardView,
        )
        val playbackControls = contentBundle.createPlaybackControls()
            .orThrow()
        initializeEventsFlowForEmbeddedReadingBundle(
            playbackControls = playbackControls,
            contentBundle = contentBundle,
            bundleMetadata = bundleMetadata,
            clientConfig = clientConfig,
            eventsTrackerAdapter = eventsTrackerAdapter,
            firebaseFunctionsService = firebaseFunctionsService,
        )

        return DynamicStandardViewReadingBundle(
            playbackControls = playbackControls,
            contentBundle = contentBundle,
        )
    }

    /**
     * A variant of HTML-input bundle creation that takes the abstract SDK [DOMElement] as input, allowing
     * for custom implementations of the DOM tree, including generating it from documents of a different format,
     * or filtering/manipulating existing DOM trees.
     */
    fun createBundleForHtmlFromSdkElement(
        htmlElement: DOMElement,
        options: HtmlContentLoadOptions,
        importStartChoice: ImportStartChoice,
        bundleMetadata: EmbeddedBundleMetadata,
        callback: Callback<EmbeddedReadingBundle>,
    ) = callback.fromCo {
        createEmbeddedBundle(
            contentBundle = contentBundler.createBundleForHtmlFromSdkElement(
                htmlElement = htmlElement,
                options = options,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
            bundleMetadata = bundleMetadata,
        )
    }

    /**
     * Creates a [ReadingBundle] for the binary content specified in [content].
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForBinaryContent(
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        content: BinaryContentReadableRandomly,
        /**
         * See [MimeType] for how to create one.
         */
        mimeType: MimeType,
        bundleMetadata: EmbeddedBundleMetadata,
        callback: Callback<EmbeddedReadingBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "EmbeddedReadingBundler.createBundleForBinaryContent",
    ) {
        createEmbeddedBundle(
            contentBundle = contentBundler.coCreateBundleForUnimportedBinaryContent(
                payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                    content = content,
                    mimeType = mimeType.ensureListenableMimeType(contentTypeForFallback = null),
                    sourceUrl = null,
                )
                    .orReturn { return@fromCoWithErrorLogging it },
                deviceLocalContent = null,
                importStartChoice = ImportStartChoice.DoNotStart,
                bundleMetadata = bundleMetadata,
            )
                .orReturn { return@fromCoWithErrorLogging it },
            bundleMetadata = bundleMetadata,
        )
    }

    private suspend fun <TStandardView : StandardView> createContentBundleFromStandardView(
        standardView: TStandardView,
    ): ContentBundle.StandardBundle<TStandardView> =
        if (standardView is StandardViewWithIndex) {
            contentBundler.coCreateBundleForStandardViewWithIndex(standardView)
        } else {
            contentBundler.coCreateBundleForStandardView(
                standardView = standardView,
                sourceUrl = null,
                bundleMetadata = null,
            )
        }

    private suspend fun ContentBundle.createPlaybackControls(): Result<PlaybackControls> =
        createPlaybackControls(
            contentBundle = this,
            startingCursor = this.standardView.start,
            scope = createTopLevelCoroutineScope(),
        )
}
