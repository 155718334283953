@file:OptIn(ExperimentalJsExport::class)

package com.speechify.client.api.util

import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
interface Service : Destructible {

    // Default destructor is NOOP
    override fun destroy() { }
}
