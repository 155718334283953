package com.speechify.client.api.adapters.firebase

import kotlin.js.JsExport
import kotlin.js.JsName

/* Based on Firebase's [DocumentChangeType](https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/firestore-types/index.d.ts#L431) */
@JsExport
enum class DocumentChangeType {
    Added,
    Removed,
    Modified,
}

/*
 Holds the changed document and the type of change (i.e. `Added`, `Removed` and `Modified`), based on Firebase's [DocumentChange](https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/firestore-types/index.d.ts#L433-L438) */
@JsExport
data class DocumentChange(
    val type: DocumentChangeType,
    val doc: FirebaseFirestoreDocumentSnapshot.Exists,
    val oldIndex: Int,
    val newIndex: Int,
) {
    fun stringForTelemetry() = "DocumentChange(type=$type, doc=${doc.key}, oldIndex=$oldIndex, newIndex=$newIndex)"
}

/*
 Based on Firebase's [QuerySnapshot](https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/firestore-types/index.d.ts#L412-L429) from ["View changes between snapshots"](https://firebase.google.com/docs/firestore/query-data/listen#web-version-8-namespaced) */
@JsExport
interface FirebaseFirestoreQuerySnapshot {
    @JsName("docChanges")
    fun docChanges(includeMetadataChanges: Boolean?): Array<DocumentChange>
}
