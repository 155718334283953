package com.speechify.client.api.services.scannedbook.models

import com.speechify.client.api.adapters.ocr.OCRMaxConcurrencyGuard
import com.speechify.client.api.adapters.ocr.OCRResult
import com.speechify.client.api.adapters.ocr.OCRableImage
import com.speechify.client.api.adapters.ocr.toOcrResult
import com.speechify.client.api.adapters.ocr.toOcrableImage
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing
import com.speechify.client.internal.util.collections.maps.LockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.LockingThreadsafeMapWithUpdateCallback
import com.speechify.client.internal.util.extensions.collections.flows.takeUntil
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class OCRFile(
    /**
     * The result from running OCR on the [source] file.
     */
    val ocrResult: OCRResult,
    /**
     * The original *image* file the [ocrResult] was derived from
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    val source: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
)

@JsExport
abstract class LazyOCRFiles {
    // TODO: Consider switching to BlockingThreadsafeMap.
    internal val ocrableImageCache = LockingThreadsafeMapWithUpdateCallback<Int, OCRableImage>()

    private val fileCache = LockingThreadsafeMap<
        Int,
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        >()

    /**
     * This flow will be emitted when new OCRableImage are available.
     * This collection of flow will not trigger any OCR.
     */
    internal val flowOfOcrableImagesWhenAvailable: Flow<List<IndexedValue<OCRableImage>>> =
        ocrableImageCache.mapUpdateEventFlow.map { entryMap ->
            entryMap.map { entry ->
                IndexedValue(entry.key, entry.value)
            }
        }

    /**
     * Tells the SDK how many pages there are.
     */
    abstract val lastIndex: Int

    /**
     * Allows SDK to request an OCR on a specific page. The SDK will cache the result, so there's no need to
     * add caching in the implementation.
     */
    protected abstract fun getOcrableImage(
        index: Int,
        callback: Callback<OCRableImage>,
    )

    /**
     * Allows SDK to request the image on a specific page. The SDK will cache the result, so there's no need to
     * add caching in the implementation.
     */
    protected abstract fun getFile(
        index: Int,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    /**
     * Use only this member to read the values, so that cache is used.
     */
    internal suspend fun getOcrableImageCached(
        index: Int,
    ): OCRableImage = ocrableImageCache.getOrPut(
        key = index,
        defaultValue = {
            coGetOcrableImage(index)
        },
    )

    internal fun getCachedOcrableImageOrNullAsync(
        index: Int,
    ): Deferred<OCRableImage>? = ocrableImageCache.getIncludingPending(index)

    /**
     * Use only this member to read the values, so that cache is used.
     */
    internal suspend fun getFileCached(
        index: Int,
    ): BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly> =
        fileCache.getOrPut(
            key = index,
            defaultValue = {
                coGetFile(index)
            },
        )

    private suspend fun coGetOcrableImage(index: Int): OCRableImage =
        /* This will throw, and it's fine, as we only read this via `fromCo`, and this way we get stacktrace pointing at the entry point. */
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing { continuation ->
            getOcrableImage(
                index = index,
                callback = continuation::resume,
            )
        }

    private suspend fun coGetFile(index: Int):
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly> =
        /* This will throw, and it's fine, as we only read this via `fromCo`, and this way we get stacktrace pointing at the entry point. */
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing { continuation ->
            getFile(
                index = index,
                callback = continuation::resume,
            )
        }
}

val LazyOCRFiles.numberOfFiles
    get() = lastIndex + 1

internal val LazyOCRFiles.allFilesAsFlow
    get() = (0..lastIndex).asFlow().map { index ->
        getFileCached(index)
    }

internal fun LazyOCRFiles.getAllOCRFilesAsFlow(
    ocrMaxConcurrencyGuard: OCRMaxConcurrencyGuard,
) = (0..lastIndex).asFlow().map { index ->
    val ocrResult = getOcrableImageCached(index).toOcrResult(ocrMaxConcurrencyGuard).orThrow()
    val file = getFileCached(index)
    OCRFile(
        ocrResult = ocrResult,
        source = file,
    )
}

/**
 * Helper class for when you have a list of [OCRFile]s, and you want to use them as [LazyOCRFiles].
 */
internal class LazyOCRFilesFromList(
    private val list: List<OCRFile>,
) : LazyOCRFiles() {
    override val lastIndex: Int
        get() = list.lastIndex

    override fun getOcrableImage(
        index: Int,
        callback: Callback<OCRableImage>,
    ) {
        val ocrablImage = list[index].ocrResult
        callback(ocrablImage.toOcrableImage().successfully())
    }

    override fun getFile(
        index: Int,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) {
        callback(list[index].source.successfully())
    }
}

/**
 * A hot flow of OCRResults from all OCRableImages provided by [LazyOCRFiles].
 * This flow emits OCRResults as soon as they become available and closes once all files have been processed.
 * Collecting this flow does not initiate OCR processing.
 * Useful for processing OCRResults immediately upon availability without triggering the underlying OCR tasks.
 */
internal val LazyOCRFiles.flowOfOcrableResultWhenAvailable: Flow<IndexedValue<OCRResult>>
    get() = run {
        val waitingForTextResult = mutableSetOf<Int>()
        val receivedTextResult = mutableSetOf<Int>()

        return flow {
            flowOfOcrableImagesWhenAvailable.collect { list ->
                list.forEach { (index, ocrableImage) ->
                    if (!waitingForTextResult.add(index)) return@forEach
                    coroutineScope {
                        ocrableImage.ocrTextResultFlow.first().ifSuccessful { ocrTextResult ->
                            receivedTextResult.add(index)
                            emit(
                                IndexedValue(
                                    index,
                                    OCRResult(
                                        rawText = ocrTextResult.rawText,
                                        textContent = ocrTextResult.textContent,
                                        imageDimensions = ocrableImage.getImageDimensions(),
                                    ),
                                ),
                            )
                        }
                    }
                }
            }
        }.takeUntil {
            receivedTextResult.size == numberOfFiles
        }
    }
