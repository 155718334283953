package com.speechify.client.reader.epub

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.ResolvedNavigationIntent
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

internal class EpubNavigationHelper internal constructor(
    scope: CoroutineScope,
    intentsFlow: Flow<ResolvedNavigationIntent>,
) : Helper<EpubNavigationState>(scope) {
    private val lastFineIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    private val lastCoarseIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    override val stateFlow: StateFlow<EpubNavigationState> =
        combine(
            intentsFlow,
            lastFineIntentConsumed,
            lastCoarseIntentConsumed,
        ) { intent, lastFineConsumed, lastCoarseConsumed ->
            EpubNavigationState(
                coarseIntent = intent.nullIf { this == lastCoarseConsumed },
                fineIntent = intent.nullIf { this == lastFineConsumed },
            )
        }.stateInHelper(
            initialValue = EpubNavigationState(
                coarseIntent = null,
                fineIntent = null,
            ),
        )

    override val initialState = stateFlow.value

    val fineIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.fineIntent }
    val coarseIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.coarseIntent }

    init {
        commands
            .onEachInstance<EpubNavigationCommand.ConsumeCoarseIntent> {
                lastCoarseIntentConsumed.value = it.intent.resolvedIntent
            }
            .onEachInstance<EpubNavigationCommand.ConsumeFineIntent> {
                lastFineIntentConsumed.value = it.intent
            }
            .launchInHelper()
    }
}

internal sealed class EpubNavigationCommand {
    data class ConsumeCoarseIntent(val intent: CoarseEpubNavigationIntent) : EpubNavigationCommand()

    data class ConsumeFineIntent(val intent: ResolvedNavigationIntent) : EpubNavigationCommand()
}

internal data class EpubNavigationState(
    val fineIntent: ResolvedNavigationIntent?,
    val coarseIntent: ResolvedNavigationIntent?,
)

@JsExport
data class CoarseEpubNavigationIntent internal constructor(
    private val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val chapterIndex: Int,
) {
    val intent = resolvedIntent.intent

    fun consume() {
        dispatch(EpubNavigationCommand.ConsumeCoarseIntent(this))
    }
}

/**
 * Represents an intent to fine navigate within a chapter of an ePub Document.
 *
 * @property normalizedTextOverlayTopYPosition The normalized Y position of the current word overlay's top edge.
 * @property normalizedTextOverlayHeight The normalized height of the current word overlay.
 */
@JsExport
data class FineEpubNavigationIntent internal constructor(
    internal val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val normalizedTextOverlayTopYPosition: Double,
    val normalizedTextOverlayHeight: Double,
) {
    val intent = resolvedIntent.intent

    fun consume() {
        dispatch(EpubNavigationCommand.ConsumeFineIntent(resolvedIntent))
    }
}
