package com.speechify.client.reader.core

import com.speechify.client.api.content.TransientContentTextPosition
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.coGetImage
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.book.coGetTextContent
import com.speechify.client.api.content.view.standard.coGetBlocksAroundCursor
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.editing.PageContent
import com.speechify.client.api.editing.PageContentPart
import com.speechify.client.api.editing.PageContentPartKind
import com.speechify.client.api.editing.coFindTextContentFromCursor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import com.speechify.client.internal.util.extensions.collections.flows.onEachPairInstance
import com.speechify.client.internal.util.extensions.collections.flows.onEachTripleInstance
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageRegion
import com.speechify.client.reader.fixedlayoutbook.regionsOfInterest
import com.speechify.client.reader.fixedlayoutbook.resolvePagePoint
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flowOf
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
class BookTextEditorHelper internal constructor(
    scope: CoroutineScope,
    readingBundle: ReadingBundle,
) : Helper<BookTextEditState>(scope) {

    private val _editorState = MutableStateFlow<BookEditor?>(null)

    private val internalReadyStateFlow: MutableSharedFlow<BookTextEditState.Ready> = MutableSharedFlow(replay = 1)
    override val stateFlow: StateFlow<BookTextEditState> =
        internalReadyStateFlow.stateInHelper(
            initialValue = if (readingBundle is BookReadingBundle) {
                BookTextEditState.NotReady
            } else {
                BookTextEditState.NotAvailable
            },
        )

    override val initialState = stateFlow.value

    init {
        // skip any commands if readingBundle is not a BookReadingBundle.
        flowOf(readingBundle).filterIsInstance<BookReadingBundle>().combine(commands) { bookReadingBundle, command ->
            bookReadingBundle to command
        }.onEachPairInstance<BookReadingBundle, BookTextEditCommand.RequestTextEditor> { bookReadingBundle, command ->
            val editor = bookReadingBundle.content.createEditor()
            _editorState.value = editor
            val bookPage = editor.editedBookView
                .coGetPages(arrayOf(command.currentPageIndex))
                .orThrow().single()
            internalReadyStateFlow.emit(
                BookTextEditState.Ready(
                    bookPageIndex = command.currentPageIndex,
                    viewport = bookPage.getMetadata().viewport,
                    image = bookPage.coGetImage(BookPageRequestOptions(scale = 1.0))
                        .orThrow(),
                    editableParagraphs = editor.coGetEditableTextContent(command.currentPageIndex)
                        .orThrow()
                        .content
                        .mapIndexed { index, pageContentPart ->
                            PageParagraph(
                                index = index,
                                text = pageContentPart.text,
                                kind = pageContentPart.kind,
                            )
                        }.toTypedArray(),
                    contentRegionsRemainingAfterEdits = bookPage.coGetTextContent()
                        .orThrow()
                        .filterNot { it.text.text.isEmpty() }
                        .map {
                            FixedLayoutPageRegion.fromNormalizedBoundingBox(it.normalizedBox)
                        }.toTypedArray(),
                    regionsOfInterest = bookPage.regionsOfInterest
                        .map { FixedLayoutPageRegion.fromNormalizedBoundingBox(it) }
                        .toTypedArray(),
                    textEditingNavigationIntent = null,
                    totalPages = editor.originalBookView.getMetadata().numberOfPages,
                ),
            )
        }.combine(_editorState) { (bookReadingBundle, command), editor ->
            // skip the rest of commands if editor is null.
            if (editor == null) null else Triple(bookReadingBundle, editor, command)
        }.filterNotNull().onEachTripleInstance<BookReadingBundle, BookEditor, BookTextEditCommand.SetEditedParagraphs>
        { bookReadingBundle, editor, command ->
            val state = internalReadyStateFlow.first()
            editor.setTextContent(
                state.bookPageIndex,
                PageContent(
                    state.bookPageIndex,
                    command.editedParagraphs.map { PageContentPart(it.text, it.kind) }.toTypedArray(),
                ),
            )
            editor.save {}
            // emit via a flow that the edits had been changed to reflect the new regions in the listening screen.
            bookReadingBundle.content.bookView.bookEditsFlow.emit(BookEdits(editor.currentState.pages))

            // Trigger a reload action after edit.
            dispatch(
                BookTextEditCommand.TriggerReloadAfterTextEdit(
                    location = RobustLocation.fromHack(
                        SerialLocation(cursor = bookReadingBundle.playbackControls.state.latestPlaybackCursor),
                    ),
                ),
            )
        }.onEachTripleInstance<BookReadingBundle, BookEditor, BookTextEditCommand.Cancel> { _, editor, _ ->
            editor.destroy()
            _editorState.value = null
        }.onEachTripleInstance<BookReadingBundle, BookEditor, BookTextEditCommand.TapToEdit>
        { bookReadingBundle, editor, command ->
            val state = internalReadyStateFlow.first()
            val bookPage = bookReadingBundle.content.bookView
                .coGetPages(arrayOf(state.bookPageIndex))
                .orThrow()
                .single()
            val (serialLocation, locationInToleranceLimit) = resolvePagePoint(
                bookPage,
                command.normalizedLeft,
                command.normalizedTop,
                command.activationTolerance,
            )
            if (locationInToleranceLimit.not()) {
                Log.d("Ignoring tap outside tolerance", sourceAreaId = "BookTextEditorHelper")
                return@onEachTripleInstance
            }

            // get the current TextPart index from the cursor.
            val partIndex = editor.coFindTextContentFromCursor(serialLocation.cursor).orThrow()

            // find the charIndex
            val actualCursor = when (serialLocation.cursor) {
                is TransientContentTextPosition -> serialLocation.cursor.originalContentTextPosition
                else -> serialLocation.cursor
            }
            val blocks = bookReadingBundle.content.standardView.coGetBlocksAroundCursor(actualCursor).orThrow()

            val block = blocks.blocks.find { it.contains(actualCursor) }
                ?: throw IllegalArgumentException("Cursor $actualCursor was not found in BookPage content.")

            internalReadyStateFlow.emit(
                state.copy(
                    textEditingNavigationIntent = TextEditingNavigationIntent(
                        paragraphIndex = partIndex.partIndex,
                        charIndex = block.text.getFirstIndexOfCursor(actualCursor),
                    ),
                ),
            )
        }.onEachTripleInstance<BookReadingBundle, BookEditor, BookTextEditCommand.ResetTextEdits> { _, editor, _ ->
            val state = internalReadyStateFlow.first()
            editor.resetTextContent(state.bookPageIndex)
            internalReadyStateFlow.emit(
                state.copy(
                    editableParagraphs = editor.coGetEditableTextContent(state.bookPageIndex)
                        .orThrow()
                        .content
                        .mapIndexed { index, pageContentPart ->
                            PageParagraph(
                                index = index,
                                text = pageContentPart.text,
                                kind = pageContentPart.kind,
                            )
                        }.toTypedArray(),
                ),
            )
        }.onEachTripleInstance<BookReadingBundle, BookEditor, BookTextEditCommand.SeekToPageStart>
        { bookReadingBundle, _, _ ->
            val state = internalReadyStateFlow.first()
            val bookPage = bookReadingBundle.content.bookView
                .coGetPages(arrayOf(state.bookPageIndex))
                .orThrow()
                .single()
            val (serialLocation) = resolvePagePoint(bookPage, 0.0, 0.0, 0.0)
            dispatch(
                PlaybackCommand.PlayFrom(
                    location = serialLocation,
                ),
            )
        }.launchInHelper()
    }

    fun requestTextEditing(currentPageIndex: Int) {
        dispatch(BookTextEditCommand.RequestTextEditor(currentPageIndex))
    }

    fun tapToEditText(
        normalizedLeft: Double,
        normalizedTop: Double,
        activationTolerance: Double,
    ) {
        dispatch(
            BookTextEditCommand.TapToEdit(
                normalizedLeft,
                normalizedTop,
                activationTolerance,
            ),
        )
    }

    fun resetTextEdits() = dispatch(BookTextEditCommand.ResetTextEdits)

    fun setEditedParagraphs(editedParagraphs: Array<PageParagraph>) {
        dispatch(BookTextEditCommand.SetEditedParagraphs(editedParagraphs.toList()))
    }

    fun seekToPageStart() = dispatch(BookTextEditCommand.SeekToPageStart)

    fun cancel() = dispatch(BookTextEditCommand.Cancel)
}

internal sealed class BookTextEditCommand {
    data class RequestTextEditor(val currentPageIndex: Int) : BookTextEditCommand()
    data class SetEditedParagraphs(val editedParagraphs: List<PageParagraph>) : BookTextEditCommand()
    data class TapToEdit(
        val normalizedLeft: Double,
        val normalizedTop: Double,
        val activationTolerance: Double,
    ) : BookTextEditCommand()

    object ResetTextEdits : BookTextEditCommand()

    object SeekToPageStart : BookTextEditCommand()

    object Cancel : BookTextEditCommand()

    class TriggerReloadAfterTextEdit(val location: RobustLocation) : BookTextEditCommand()
}

@JsExport
sealed class BookTextEditState {
    data class Ready(
        val bookPageIndex: Int,
        val viewport: Viewport,
        @Suppress("NON_EXPORTABLE_TYPE")
        val image: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        val editableParagraphs: Array<PageParagraph>,
        val contentRegionsRemainingAfterEdits: Array<FixedLayoutPageRegion>,
        val regionsOfInterest: Array<FixedLayoutPageRegion>,
        // Holds the paragraphIndex, and the charIndex near to where user tapped on the original FixedLayoutPage.
        val textEditingNavigationIntent: TextEditingNavigationIntent?,
        val totalPages: Int,
    ) : BookTextEditState() {
        override fun hashCode(): Int {
            var result = editableParagraphs.contentHashCode()
            result = 31 * result + contentRegionsRemainingAfterEdits.contentHashCode()
            result = 31 * result + regionsOfInterest.contentHashCode()
            result = 31 * result + bookPageIndex.hashCode()
            result = 31 * result + viewport.hashCode()
            result = 31 * result + image.hashCode()
            result = 31 * result + (textEditingNavigationIntent?.hashCode() ?: 0)
            return result
        }

        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Ready

            if (!editableParagraphs.contentEquals(other.editableParagraphs)) return false
            if (!contentRegionsRemainingAfterEdits.contentEquals(other.contentRegionsRemainingAfterEdits)) return false
            if (!regionsOfInterest.contentEquals(other.regionsOfInterest)) return false
            if (bookPageIndex != other.bookPageIndex) return false
            if (viewport != other.viewport) return false
            if (image != other.image) return false
            if (textEditingNavigationIntent != other.textEditingNavigationIntent) return false

            return true
        }
    }

    object NotReady : BookTextEditState()

    object NotAvailable : BookTextEditState()
}

@JsExport
data class TextEditingNavigationIntent(
    val paragraphIndex: Int,
    val charIndex: Int,
)

@JsExport
data class PageParagraph(
    val index: Int,
    val text: String,
    val kind: PageContentPartKind,
)

private suspend fun BookEditor.coGetEditableTextContent(pageIndex: Int): Result<PageContent> =
    suspendCoroutine {
        getEditableTextContent(pageIndex, it::resume)
    }
