package com.speechify.client.reader.core.utils

import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.slice
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksBetweenCursors
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.reader.core.SelectionState
import com.speechify.client.reader.core.UserHighlightsList
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

/**
 * This Utility class provides the result for various synchronous style functions which
 * needs to perform some operation once the selection is done
 */
@JsExport
class SelectionUtils internal constructor(
    private val standardView: StandardView,
    private val selectionFlow: StateFlow<SelectionState>,
    private val userHighlightsFlow: StateFlow<UserHighlightsList>,
) {

    /**
     * @return The array of highlights overlapping with the current selection
     */
    fun getOverlappingHighlightsWithSelection(callback: Callback<Array<UserHighlight>>) = callback.invoke(
        getOverlappingHighlightsWithSelection(),
    )

    /**
     * @return The string from the selection done by user.
     */
    fun getSelectedText(callback: Callback<String>) = callback.fromCo {
        coGetSelectedText()
    }

    private suspend fun coGetSelectedText(): Result<String> {
        return selectionFlow.value.selection?.let {
            val start = if (it.anchor.cursor.isBefore(it.focus.cursor)) it.anchor else it.focus
            val end = if (it.anchor.cursor.isBefore(it.focus.cursor)) it.focus else it.anchor
            val blocks = standardView.coGetBlocksBetweenCursors(start.cursor, end.cursor).orReturn { return it }
            val textList = blocks.blocks
                .filter {
                    it.hasNontrivialIntersectionWith(start.cursor, end.cursor)
                }
                .map { it.text.slice(start.cursor, end.cursor) }.toList()
            if (textList.isEmpty()) {
                // if reaching here this could be related to ml parsing
                // see https://linear.app/speechify-inc/issue/CXP-4902/investigate-and-fix-copy-text-stops-working-sometimes
                return Result.Failure(SDKError.OtherMessage("No text found for selection"))
            }

            return ContentTextUtils.join(
                textList,
                "\n",
            ).text.successfully()
        } ?: Result.Failure(SDKError.OtherMessage("No content is selected"))
    }

    private fun getOverlappingHighlightsWithSelection(): Result<Array<UserHighlight>> {
        val currentSelection = selectionFlow.value.selection ?: return emptyArray<UserHighlight>().successfully()
        /**
         * find all the highlights whose start or end falls within the range of
         * selection start and end
         */
        return userHighlightsFlow.value.items.map { it.highlight }
            .filter {
                (
                    it.robustStart.hack.cursor.isBeforeOrAt(currentSelection.end) &&
                        it.robustEnd.hack.cursor.isAfterOrAt(currentSelection.start)
                    )
            }
            .toTypedArray().successfully()
    }
}
