package com.speechify.client.bundlers.reading.importing

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithErrorLoggingGetJob
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.toDestructor
import com.speechify.client.internal.util.extensions.collections.firstInstanceOf
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

typealias ContentImporterListener = (ContentImporterState) -> Unit

@JsExport
interface ContentImporterStateInfo {
    /**
     * Reflects the current state of the [ContentImporter] including the URI of the imported content (if the returned
     * instance is [ContentImporterState.Imported] state).
     */
    val state: ContentImporterState
}

/**
 * SDK internal-use only (it was made `public` only because JS compiler wouldn't allow a class with `@JsExport` to inherit from it)
 *
 * Component that allows to queue actions to be performed when the import is complete.
 */
abstract class ContentPostImportActionsManager : ContentImporterStateInfo {
    /**
     * Allows to save edits after the original non-edited document is saved.
     *
     * The action will be performed when import is attempted, and will defer updating the state to
     * [ContentImporterState.Imported] for after the action is finished.
     *
     * Calling this method multiple times will cause the new action to overwrite the previous action, so the previous
     * one will not be performed.
     *
     * Calling this method after the import has already been attempted will cause the action to be performed
     * immediately.
     *
     * DANGER: Setting this method more than once after the import is already in progress will cause a race-condition
     * and **may cause loss of data** (multiple database writes racing over network). Preventing this is the
     * responsibility of the caller, and can be done by using the resulting [Deferred] (e.g. UI can block saving more
     * edits when it is not resolved, or queue the saves).
     *
     * @return a [Deferred] that will be resolved when the edits are saved. It may also be used to attempt to cancel
     * the save, and it will communicate cancellation or failure.
     */
    internal abstract fun setEditsSaveAction(saveEditsAction: suspend (uri: SpeechifyURI) -> Unit):
        Deferred<SpeechifyURI>

    /**
     * Allows you to run tasks after the import is complete, and a URI has been determined.
     *
     * This action will be performed when the import is attempted, and will defer updating the state to
     * [ContentImporterState.Imported] for after the action is finished.
     *
     * Calling this method multiple times will cause a new task to be queued, all tasks that are queued will be run
     * before import is marked as done, or immediately if the content was already imported.
     */
    internal abstract fun queueTaskAfterImport(task: suspend (uri: SpeechifyURI) -> Unit)
}

/**
 * Provides importing for an instance of content, and tracks the state of the import, only allowing it to happen once.
 */
@JsExport
abstract class ContentImporter : ContentPostImportActionsManager(), ContentImporterStateInfo {
    override val state: ContentImporterState
        get() = stateFlow.value ?: ContentImporterState.NotImported(null)

    internal abstract val stateFlow: StateFlow<ContentImporterState?>

    /**
     * Register listener to receive the current value and any updates of the current state of the [ContentImporter].
     *
     * @param listener is a function that will be called with the value of [state]
     */
    fun addStateChangeListener(listener: ContentImporterListener): Destructor {
        val subscriber = launchTask {
            stateFlow
                .filterNotNull()
                .collectLatest { it.let(listener) }
        }
        return {
            subscriber.cancel()
        }
    }

    /**
     * Starts a non-blocking import based on the bundle.
     *
     * Recommended the caller register a listener using [addStateChangeListener] to receive updates on progress state
     * and/or check [state]
     *
     * @param options are the importing options
     * @param callback will be called with the [SpeechifyURI] of the result of import, or a failure if unable to initiate the import. Note that there may be backend processing ongoing even after this callback indicates successful initiation.
     */
    fun startImport(
        options: ImportOptions,
        callback: Callback<SpeechifyURI>,
    ): Destructor {
        return callback.fromCoWithErrorLoggingGetJob(sourceAreaId = "ContentImporter.startImport") {
            return@fromCoWithErrorLoggingGetJob startImport(options)
        }.toDestructor()
    }

    internal abstract suspend fun startImport(options: ImportOptions): Result<SpeechifyURI>
}

internal suspend fun ContentImporter.suspendUntilImportedGetUri() =
    stateFlow
        .firstInstanceOf<ContentImporterState.Imported>().uri

internal suspend fun ContentImporter.suspendUntilAnyUri() =
    stateFlow.map {
        when (it) {
            is ContentImporterState.ImportedToLibrary -> it.libraryItem.uri
            is ContentImporterState.SpeechifyGlobalResource -> null
            is ContentImporterState.Importing -> it.libraryItem.uri
            is ContentImporterState.NotImported -> it.libraryItem?.uri
            is ContentImporterState.Starting -> null
            null -> null
        }
    }.filterNotNull().first()

@JsExport
sealed class ContentImporterState {
    data class NotImported(
        val libraryItem: LibraryItem.DeviceLocalContent?,
    ) : ContentImporterState()

    data class Starting(val libraryItem: LibraryItem.DeviceLocalContent?) : ContentImporterState()

    class Importing(
        val options: ImportOptions,
        val libraryItem: LibraryItem.DeviceLocalContent,
    ) : ContentImporterState()

    sealed class Imported(val uri: SpeechifyURI) : ContentImporterState()

    /**
     * A Speechify resource that is not stored per-user, but in Speechify's global store, such as an
     * audiobook chapter. */
    class SpeechifyGlobalResource(uri: SpeechifyURI) : Imported(uri)

    /**
     * A resource that is imported into the users library, such as a PDF or HTML file.
     */
    class ImportedToLibrary(uri: SpeechifyURI, val libraryItem: LibraryItem.Content) : Imported(uri)

    override fun toString(): String = this::class.simpleName ?: this::class.toString()
}
