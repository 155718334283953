package com.speechify.client.internal.util.collections.arrays

import com.speechify.client.internal.util.io.arrayBuffer.extensions.toByteArray
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array
import js.typedarrays.Uint8Array
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

/**
 * #MissingInt8ArrayToByteArrayConversion - as per
 * https://slack-chats.kotlinlang.org/t/467728/is-there-a-way-to-convert-arraybuffer-to-a-bytearray
 * and https://youtrack.jetbrains.com/issue/KT-30098?project=kt
 */
internal fun Int8Array.toByteArray(): ByteArray =
    this.unsafeCast<ByteArray>()

/**
 * Reverse of [Int8Array.toByteArray] and a #MissingInt8ArrayToByteArrayConversion
 */
internal fun ByteArray.toInt8Array(): Int8Array =
    this.unsafeCast<Int8Array>()

internal fun Uint8Array.toByteArray(): ByteArray =
    this.buffer.toByteArray()

internal fun ByteArray.toUint8Array(): Uint8Array =
    Uint8Array(this.toInt8Array().buffer)

internal fun Int8Array.toBlob(contentType: String?): Blob =
    Blob(
        blobParts = arrayOf(
            /** [Int8Array] is acceptable as per[docs on `Blob` constructor parameters](https://developer.mozilla.org/en-US/docs/Web/API/Blob/Blob#parameters) */
            this,
        ),
        options = BlobPropertyBag(
            type = contentType,
        ),
    )

internal fun ArrayBuffer.toBlob(contentType: String?): Blob =
    Blob(
        blobParts = arrayOf(
            /** [ArrayBuffer] is acceptable as per[docs on `Blob` constructor parameters](https://developer.mozilla.org/en-US/docs/Web/API/Blob/Blob#parameters) */
            this,
        ),
        options = BlobPropertyBag(
            type = contentType,
        ),
    )
