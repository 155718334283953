package com.speechify.client.api.adapters.pdf

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * Adapter providing an efficient, minimal wrapper for accessing the text and images contained in a PDF
 */
@JsExport
interface PDFAdapterFactory {
    fun getPDFDocumentAdapter(
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        content: BinaryContentReadableRandomly,
        password: String? = null,
        callback: Callback<PDFDocumentAdapter>,
    )
}

internal suspend fun PDFAdapterFactory.coGetPDFDocumentAdapter(
    content: BinaryContentReadableRandomly,
    password: String? = null,
) = suspendCoroutine { cont ->
    getPDFDocumentAdapter(content, password, cont::resume)
}

internal fun PDFAdapterFactory.traced() = if (Log.isDebugLoggingEnabled) PDFAdapterFactoryTraced(this) else this

internal class PDFAdapterFactoryTraced(private val pdfAdapterFactory: PDFAdapterFactory) : PDFAdapterFactory {
    override fun getPDFDocumentAdapter(
        content: BinaryContentReadableRandomly,
        password: String?,
        callback: Callback<PDFDocumentAdapter>,
    ) = callback.fromCo {
        debugCallAndResultWithUuid("PDFAdapterFactory.getPDFDocumentAdapter($content)") {
            pdfAdapterFactory.coGetPDFDocumentAdapter(content, password)
        }
    }
}
