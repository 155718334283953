package com.speechify.client.internal.services.epub

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coGetObjectOrNullIfNotExists
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.importing.models.TextualCount
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlinx.serialization.Serializable

private typealias ChapterContentStatsId = String

internal interface EpubChapterContentStatsService {
    suspend fun getAllContentStatsChapters(itemId: SpeechifyContentId): Result<Array<FirestoreChapterContentStatsModel>>
    suspend fun addContentStatsChapter(
        itemId: SpeechifyContentId,
        chapterContentStats: FirestoreChapterContentStatsModel,
    ): Result<Unit>

    suspend fun updateFirebaseContentStats(
        itemId: SpeechifyContentId,
        wordCount: Int,
        charCount: Int,
    ): Result<Unit>

    suspend fun getFirestoreContentStatsOrNull(
        itemId: SpeechifyContentId,
    ): ContentStats?
}
internal class PlatformEpubChapterContentStatsService(
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val libraryFirebaseDataFetcher: LibraryFirebaseDataFetcher,
) : EpubChapterContentStatsService {
    override suspend fun getAllContentStatsChapters(
        itemId: SpeechifyContentId,
    ) = firebaseFirestoreService.queryDocuments(collectionRef = getChaptersContentStatsRef(itemId = itemId))
        .coFetch()
        .orDefaultWith { emptyArray() }
        .mapNotNull { contentStatsChapters ->
            when (contentStatsChapters) {
                is FirebaseFirestoreDocumentSnapshot.Exists ->
                    contentStatsChapters
                        .value<FirestoreChapterContentStatsModel>()
                        .orDefaultWith { null }

                is FirebaseFirestoreDocumentSnapshot.NotExists -> null
            }
        }
        .toTypedArray()
        .successfully()

    override suspend fun addContentStatsChapter(
        itemId: SpeechifyContentId,
        chapterContentStats: FirestoreChapterContentStatsModel,
    ): Result<Unit> {
        if (getContentStatsChapter(itemId = itemId, chapterIndex = chapterContentStats.chapterIndex) != null) {
            return Unit.successfully()
        }

        return firebaseFirestoreService.coSetDocument(
            path = getChapterContentStatsPath(itemId = itemId, chapterId = "${chapterContentStats.chapterIndex}"),
            value = buildChapterContentStatsFirestorePayload(
                chapterIndex = chapterContentStats.chapterIndex,
                textualCount = TextualCount(
                    wordCount = chapterContentStats.wordCount,
                    charCount = chapterContentStats.charCount,
                ),
            ).toBoundaryMap(),
        )
    }

    override suspend fun updateFirebaseContentStats(
        itemId: SpeechifyContentId,
        wordCount: Int,
        charCount: Int,
    ) = libraryFirebaseDataFetcher.updateItemDataFromParams(
        itemId = itemId,
        payload = mapOf(
            RecordProperties.wordCount.keyId to wordCount,
            RecordProperties.charCount.keyId to charCount,
        ).toBoundaryMap(),
    )

    override suspend fun getFirestoreContentStatsOrNull(
        itemId: SpeechifyContentId,
    ): ContentStats? {
        val libraryListenableContentOrNull = libraryFirebaseDataFetcher.getLibraryItem(itemId)
            .orDefaultWith { null } as? LibraryItem.Content

        return libraryListenableContentOrNull?.totalWords
            ?.let { ContentStats(EstimatedCount(it, 1.0)) }
    }

    private suspend fun getContentStatsChapter(
        itemId: SpeechifyContentId,
        chapterIndex: Int,
    ) = firebaseFirestoreService
        .coGetObjectOrNullIfNotExists<FirestoreChapterContentStatsModel>(
            getChapterContentStatsPath(
                itemId = itemId,
                chapterId = "$chapterIndex",
            ),
        )
        .toNullable()

    private fun buildChapterContentStatsFirestorePayload(
        chapterIndex: Int,
        textualCount: TextualCount,
    ) = mapOf(
        "chapterIndex" to chapterIndex,
        RecordProperties.wordCount.keyId to textualCount.wordCount,
        RecordProperties.charCount.keyId to textualCount.charCount,
    )

    private companion object {
        fun getChaptersContentStatsRef(itemId: SpeechifyContentId) = "items/$itemId/chaptersContentStats"
        fun getChapterContentStatsPath(
            itemId: SpeechifyContentId,
            chapterId: ChapterContentStatsId,
        ) = PathInCollection(
            collectionPath = getChaptersContentStatsRef(itemId = itemId),
            documentPath = chapterId,
        )
    }
}

@Serializable
internal class FirestoreChapterContentStatsModel(
    val chapterIndex: Int,
    val wordCount: Int,
    val charCount: Int,
)
