package com.speechify.client.internal.util.collections.flows

import com.speechify.client.internal.sync.AtomicInt
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.map

internal typealias ExternalStateChangesFlow = Flow<Unit>

internal interface ExternalStateChangesFlowMutable : ExternalStateChangesFlow {
    fun signalChange()
}

/**
 * Use to signal that some state has changed, but the event carries no more information apart from that - the state
 * lives outside this flow (such a way of signalling can be especially useful when the state itself is abstracted and
 * can be accessed via different ways of evaluation, e.g. getProgressFromCursor, getCursorFromProgress).
 */
internal fun externalStateChangesFlow(): ExternalStateChangesFlowMutable {
    val count = AtomicInt(0) // Need something unique because MutableStateFlow conflates same values
    val baseFlow = MutableStateFlow(count.getAndIncrement())
    return object : Flow<Unit> by (baseFlow.map { }), ExternalStateChangesFlowMutable {
        override fun signalChange() {
            baseFlow.value = count.getAndIncrement()
        }
    }
}
