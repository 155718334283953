package com.speechify.client.api.audio

import kotlin.math.floor
import kotlin.math.max
import kotlin.math.min

/**
 * A Speech Marks data structure that requires a set of "chunks" that provide time and char bounds for each word in the content.
 */
@kotlinx.serialization.Serializable
data class SpeechMarksImpl(internal val chunks: List<SpeechMarksChunk>) : SpeechMarks {
    init {
        require(chunks.isNotEmpty()) { "chunks can't be empty" }
    }

    override val startTimeInMilliseconds: Int
        get() = chunks.first().startTimeInMilliseconds

    override val endTimeInMilliseconds: Int
        get() = chunks.last().endTimeInMilliseconds

    override fun getStartTimeAtCharacterIndex(characterIndex: Int): Int {
        val (start, _) = getTimeBoundsOfCharIndex(characterIndex)
        return start
    }

    override fun getEndTimeAtCharacterIndex(characterIndex: Int): Int {
        val (_, end) = getTimeBoundsOfCharIndex(characterIndex)
        return end
    }

    override fun getCharacterIndexAtTime(timeInMilliseconds: Int): Int {
        if (chunks.isEmpty()) return 0

        val chunk = getChunkForTime(timeInMilliseconds)
        if (chunk.startTimeInMilliseconds >= chunk.endTimeInMilliseconds) {
            return chunk.startCharacterIndex
        }

        val clippedTime =
            min(chunk.endTimeInMilliseconds, max(chunk.startTimeInMilliseconds, timeInMilliseconds))
        val chunkDuration = chunk.endTimeInMilliseconds - chunk.startTimeInMilliseconds
        val chunkLength = chunk.endCharacterIndex - chunk.startCharacterIndex
        val scaleFactor = (clippedTime - chunk.startTimeInMilliseconds).toDouble() / chunkDuration
        return chunk.startCharacterIndex + floor(chunkLength * scaleFactor).toInt()
    }

    override fun slice(
        startIndex: Int,
        endIndex: Int,
    ): SpeechMarks {
        return SlicedSpeechMarks(this, startIndex, endIndex)
    }

    private fun getTimeBoundsOfCharIndex(characterIndex: Int): Pair<Int, Int> {
        val chunk = getChunkForCharacterIndex(characterIndex)
        return when {
            /**
             * This seems to be catering especially for the `==` case
             *  TODO a `>` should rather be validated in the initialization of [SpeechMarksChunk], which
             *   would then leave just `==` to be handled here.
             */
            chunk.startCharacterIndex >= chunk.endCharacterIndex ->
                chunk.startTimeInMilliseconds to chunk.endTimeInMilliseconds

            /**
             * Also quite an invalid case of looking for an index before this chunk.
             */
            characterIndex < chunk.startCharacterIndex ->
                chunk.startTimeInMilliseconds to chunk.startTimeInMilliseconds

            /** Here we are getting asked about the place beyond the end of the chunk (Note that
             * [com.speechify.client.api.audio.SpeechMarksChunk.endCharacterIndex] is exclusive, just like
             * [SlicedSpeechMarks.endIndex]). We need to prevent the adding of 'character duration' in the
             * 'upperBound' below, not to go beyond the end of the chunk - just return the same boundary.
             */
            characterIndex >= chunk.endCharacterIndex ->
                chunk.endTimeInMilliseconds to chunk.endTimeInMilliseconds

            else -> {
                val chunkDuration = chunk.endTimeInMilliseconds - chunk.startTimeInMilliseconds
                val chunkLength = chunk.endCharacterIndex - chunk.startCharacterIndex

                val characterDuration: Double = chunkDuration.toDouble() / chunkLength

                val characterDistanceFromStart: Int = characterIndex - chunk.startCharacterIndex

                val lowerBound =
                    chunk.startTimeInMilliseconds + floor(characterDistanceFromStart * characterDuration).toInt()

                lowerBound to (lowerBound + characterDuration).toInt()
            }
        }
    }

    private fun getChunkForCharacterIndex(characterIndex: Int): SpeechMarksChunk =
        // Get the first chunk ending after the specified char index
        chunks.find {
            /** TODO reconcile `ending after` with the fact that the check is `<=`, while [com.speechify.client.api.audio.SpeechMarksChunk.endCharacterIndex]
             *   is exclusive - so this will potentially return next chunk (especially in scripts that don't separate words with any whitespace).
             *   Analyze usages and establish what is the desired behavior.
             */
            characterIndex <= it.endCharacterIndex
        }
            ?: chunks.last()

    private fun getChunkForTime(timeInMilliseconds: Int): SpeechMarksChunk {
        // Get the first chunk ending after the specified time
        return chunks.find { timeInMilliseconds <= it.endTimeInMilliseconds } ?: chunks.last()
    }

    /**
     * A Speech Marks data structure that is derived from another Speech Marks, providing the illusion that the content has been "sliced" to the specified range.
     */
    internal data class SlicedSpeechMarks(
        val speechMarks: SpeechMarks,
        /**
         * Index into the text where the chunk starts.
         * Inclusive, so the first character of the chunk is exactly [startIndex].
         */
        val startIndex: Int,
        /**
         * Index into the text where the chunk ends.
         * Exclusive, so the last character of the chunk is [endIndex] - 1.
         */
        val endIndex: Int,
    ) : SpeechMarks {
        override val startTimeInMilliseconds: Int
            by lazy { speechMarks.getStartTimeAtCharacterIndex(startIndex) }

        override val endTimeInMilliseconds: Int
            by lazy { speechMarks.getEndTimeAtCharacterIndex(endIndex - 1) }

        override fun getStartTimeAtCharacterIndex(characterIndex: Int): Int {
            val sliceLength = endIndex - startIndex
            val clippedCharacterIndex = characterIndex.coerceAtLeast(0).coerceAtMost(sliceLength)
            return speechMarks.getStartTimeAtCharacterIndex(
                characterIndex = clippedCharacterIndex + this.startIndex,
            )
        }

        override fun getEndTimeAtCharacterIndex(characterIndex: Int) =
            /** TODO: Investigate why `Start` is used for `End` (bug?) Should this not be similar to
             *  [getStartTimeAtCharacterIndex] but using `speechMarks`.[getEndTimeAtCharacterIndex]?
             */
            getStartTimeAtCharacterIndex(characterIndex)

        override fun getCharacterIndexAtTime(timeInMilliseconds: Int): Int {
            val originalCharAtTime = speechMarks.getCharacterIndexAtTime(timeInMilliseconds)
            val lastIndex = max(startIndex, endIndex - 1)
            return (originalCharAtTime - this.startIndex).coerceAtMost(lastIndex)
        }

        override fun slice(startIndex: Int, endIndex: Int): SpeechMarks {
            return SlicedSpeechMarks(this, startIndex, endIndex)
        }
    }
}
