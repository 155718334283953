package com.speechify.client.api.services.library.models

import com.speechify.client.helpers.features.ListeningProgress
import kotlin.js.JsExport

@JsExport
data class UpdateLibraryItemParams(
    val title: String? = null,
    val coverImageUrl: String? = null,
    val listeningProgress: ListeningProgress? = null,
)
