package com.speechify.client.reader.fixedlayoutbook.overlay

import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.distanceTo
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageContent
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageRegion

/**
 * Find the overlay using the text context and finding its bounding box from the underlying item with the help of searching the content in
 * file(e.g. pdf).
 * This would be more expensive as compared to other strategies as it could involve searching like in case of PDFs search with
 * pdf library in use. but this could provide better precision.
 */
internal class SearchBasedFLOverlayStrategy(
    private val search: suspend (
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        searchOptions: BookSearchOptions,
    ) -> Result<Array<BookSearchResult>>,
) : FixedLayoutOverlayStrategy {
    override suspend fun computeOverlay(computeOverlayContext: ComputeOverlayContext): List<FixedLayoutPageRegion> {
        val overlappingItems = computeOverlayContext.items.filter {
            it.text.hasNontrivialIntersectionWith(
                computeOverlayContext.content,
            )
        }
        val viewPort = computeOverlayContext.bookPage.getMetadata().viewport
        return overlappingItems.mapIndexed { index, value ->
            val isFirst = index == 0
            val isLast = index == overlappingItems.size - 1

            val targetStartIndex = value.text.getFirstIndexOfCursor(computeOverlayContext.content.start)
            val targetEndIndex = value.text.getLastIndexOfCursor(computeOverlayContext.content.end) + 1

            val region = FixedLayoutPageRegion.fromPageTextContent(
                content = FixedLayoutPageContent(
                    text = value.text.text,
                    fontFamily = value.fontFamily,
                    normalizedBoundingBox = value.normalizedBox,
                ),
                startIndex = targetStartIndex,
                endIndexExclusive = targetEndIndex,
            )

            // Process the region if it's the first or last item, no need to do search for all in between boxes
            if (isLast || isFirst) {
                val targetText = value.text.text.substring(targetStartIndex, targetEndIndex)
                when (
                    val searchResult = search(
                        targetText,
                        computeOverlayContext.bookPage.pageIndex,
                        computeOverlayContext.bookPage.pageIndex,
                        BookSearchOptions(),
                    )
                ) {
                    // gracefully handle the failure
                    is Result.Failure -> {
                        region
                    }

                    is Result.Success -> {
                        val pageSearchResult =
                            searchResult.value.filter {
                                it.pageIndex == computeOverlayContext.bookPage.pageIndex
                            }.flatMap {
                                it.boundingBoxes.toList()
                            }
                        val normalizedBox = pageSearchResult.minByOrNull {
                            it.distanceTo(
                                region.estimatedNormalizedBoundingBox.unnormalize(viewPort),
                            )
                        }?.normalize(viewPort) ?: region.estimatedNormalizedBoundingBox

                        FixedLayoutPageRegion(normalizedBox)
                    }
                }
            } else {
                region
            }
        }
    }
}
