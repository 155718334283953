package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetImage
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.reader.core.Helper
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class ThumbnailImageHelper internal constructor(
    scope: CoroutineScope,
    private val bookView: BookView,
    private val pageIndex: Int,
) : Helper<ThumbnailImageState>(scope) {

    init {
        commands
            .onEachInstance<ThumbnailImageCommand.LoadImage> { command ->
                val image = bookView.coGetPages(arrayOf(pageIndex))
                    .orThrow()
                    .single()
                    .coGetImage(BookPageRequestOptions(command.scale))
                    .orThrow()
                stateFlow.update { ThumbnailImageState.Ready(image) }
            }
            .onEachInstance<ThumbnailImageCommand.ClearImage> { command ->
                stateFlow.update { ThumbnailImageState.NotReady }
            }
            .launchInHelper()
    }

    fun loadImage(scale: Double) {
        dispatch(ThumbnailImageCommand.LoadImage(scale))
    }

    fun clearImage() {
        dispatch(ThumbnailImageCommand.ClearImage)
    }

    sealed class ThumbnailImageCommand {
        data class LoadImage(val scale: Double) : ThumbnailImageCommand()
        object ClearImage : ThumbnailImageCommand()
    }

    override val stateFlow: MutableStateFlow<ThumbnailImageState> = MutableStateFlow(ThumbnailImageState.NotReady)
    override val initialState: ThumbnailImageState = ThumbnailImageState.NotReady
}

@JsExport
sealed class ThumbnailImageState {
    @Suppress("NON_EXPORTABLE_TYPE")
    data class Ready(
        val image: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    ) : ThumbnailImageState()

    object NotReady : ThumbnailImageState()
}
