package com.speechify.client.api.services.library.offline

import com.speechify.client.api.adapters.firebase.CollectionReference
import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.GoogleCloudStorageUriFileId
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coGetCollection
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.services.ebook.EncryptedDownloadService
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.isCausedByConnectionError
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.toNullSuccessIfResourceNotFound
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.async
import kotlinx.coroutines.cancelAndJoin

internal sealed class LibraryItemResource {

    internal class FirebaseStorageLibraryItemResource(
        val uri: GoogleCloudStorageUriFileId,
        scope: CoroutineScope,
        private val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
    ) : LibraryItemResource() {

        // We track
        private val downloadDeferred = scope.async(start = CoroutineStart.LAZY) {
            firebaseStorageCache.getBinaryContent(uri).ignoreValue()
        }

        override suspend fun download() {
            downloadDeferred.await()
        }

        override suspend fun isAvailableOffline(): Boolean {
            return firebaseStorageCache.isFileCached(uri)
        }

        override suspend fun abortOrUndo() {
            if (downloadDeferred.isActive) {
                downloadDeferred.cancelAndJoin()
            }

            if (downloadDeferred.isCompleted) {
                firebaseStorageCache.deleteFileFromCache(uri).ignoreValue()
            }
        }

        override suspend fun removeFromCache(): Boolean {
            return firebaseStorageCache.deleteFileFromCache(uri)
        }

        override suspend fun getSubItems(shouldQueryCacheOnly: Boolean): List<LibraryItemResource> = emptyList()

        override fun toString(): String {
            return "FirebaseStorageLibraryItemResource(uri=$uri)"
        }
    }

    internal class EncryptedLibraryItemResource(
        val itemId: String,
        private val downloadService: EncryptedDownloadService,
    ) : LibraryItemResource() {

        override suspend fun download() {
            downloadService.downloadAndStore(itemId)
        }

        override suspend fun abortOrUndo() {
            downloadService.abortOrUndo(itemId)
        }

        override suspend fun isAvailableOffline(): Boolean {
            return downloadService.isAvailableOffline(itemId)
        }

        override suspend fun removeFromCache(): Boolean {
            return downloadService.removeFromCache(itemId)
        }

        override suspend fun getSubItems(shouldQueryCacheOnly: Boolean): List<LibraryItemResource> = emptyList()
    }

    internal class FirebaseCollectionLibraryItemResource(
        val collectionRef: CollectionReference,
        private val firestoreService: FirebaseFirestoreService,
    ) : LibraryItemResource() {

        override suspend fun download() {
            getSubCollectionItems(DataSource.SERVER).ignoreValue()
        }

        override suspend fun isAvailableOffline(): Boolean {
            // We can just return true here, since we check for the sub values anyway.
            return true
        }

        override suspend fun abortOrUndo() {
            // Do nothing since we can't evict data from the firestore cache.
        }

        override suspend fun removeFromCache(): Boolean {
            // We can't remove the item from the Firestore cache.
            // This is a limitation of the Firestore cache itself.
            return false
        }

        override suspend fun getSubItems(
            /**
             * For docs on result behavior depending on this value, see [DataSource.CACHE].
             */
            shouldQueryCacheOnly: Boolean,
        ): List<LibraryItemResource> {
            return getSubCollectionItems(
                if (shouldQueryCacheOnly) {
                    DataSource.CACHE
                } else {
                    DataSource.SERVER
                },
            ).map { subItem ->
                val subItemExists = subItem as FirebaseFirestoreDocumentSnapshot.Exists
                val subItemId = subItemExists.key.split("/").last()
                FirebaseObjectLibraryItemResource(
                    PathInCollection(collectionRef, subItemId),
                    true,
                    firestoreService,
                )
            }
        }

        private suspend fun getSubCollectionItems(
            /**
             * For docs on result behavior depending on this value, see the parameter of the same name in [FirebaseFirestoreService.getCollection].
             */
            dataSource: DataSource,
        ) =
            firestoreService.coGetCollection(collectionRef, dataSource)
                .toNullSuccessIfResourceNotFound()
                .orThrow() ?: emptyArray()

        override fun toString(): String {
            return "FirebaseCollectionLibraryItemResource(collectionRef=$collectionRef)"
        }
    }

    internal class FirebaseObjectLibraryItemResource(
        val pathInCollection: PathInCollection,
        /**
         * When set to true failures while fetching the item not existing will be treated as an error, when set
         * to false non-existent items are ignored.
         */
        val required: Boolean,
        private val firestoreService: FirebaseFirestoreService,
    ) : LibraryItemResource() {
        override suspend fun download() {
            firestoreService.coGetDocument(
                pathInCollection.collectionPath,
                pathInCollection.documentPath,
                DataSource.SERVER,
            ).run {
                if (required) {
                    orThrow()
                } else {
                    toNullSuccessIfResourceNotFound().toNullSuccessIfConnectionError().orThrow()
                }
            }
                // No need to do anything, a successful get is enough to cache the data.
                .ignoreValue()
        }

        override suspend fun isAvailableOffline(): Boolean {
            val documentOrNull = firestoreService.coGetDocument(
                pathInCollection.collectionPath,
                pathInCollection.documentPath,
                DataSource.CACHE,
            ).toNullSuccessIfResourceNotFound()
                .toNullSuccessIfConnectionError()
                .orThrow()

            return if (required) {
                documentOrNull != null
            } else {
                // When not required not failing is enough to indicate success.
                true
            }
        }

        override suspend fun abortOrUndo() {
            // Do nothing since we can't evict data from the firestore cache.
        }

        override suspend fun removeFromCache(): Boolean {
            // We can't remove the item from the Firestore cache.
            // This is a limitation of the Firestore cache itself.
            return false
        }

        override suspend fun getSubItems(shouldQueryCacheOnly: Boolean): List<LibraryItemResource> = emptyList()

        override fun toString(): String {
            return "FirebaseObjectLibraryItemResource(pathInCollection=$pathInCollection, required=$required)"
        }
    }

    /**
     * Downloads this resource.
     */
    abstract suspend fun download()

    /**
     * Cleans this resource from the cache, or cancels the running download.
     */
    abstract suspend fun abortOrUndo()

    /**
     * Returns true if this resource is available offline, false otherwise.
     */
    abstract suspend fun isAvailableOffline(): Boolean

    /**
     * Removes this resource from the cache.
     * Returns true if the item was removed, false if nothing was removed.
     */
    abstract suspend fun removeFromCache(): Boolean

    abstract suspend fun getSubItems(shouldQueryCacheOnly: Boolean): List<LibraryItemResource>
}

private inline fun <T : Any> Result<T?>.toNullSuccessIfConnectionError(): Result<T?> =
    if (this is Result.Failure && this.error.isCausedByConnectionError()) {
        Result.Success(null)
    } else {
        this
    }
