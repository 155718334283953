package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.internal.util.extensions.collections.allSublistsFromSingleFirstItemToAllItems
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.withIndex

/**
 * Note: The last list will be the complete one, and thus will be equivalent to calling [mapConcurrentlyEnMassePreservingOrder].
 * WARNING: The intermediate lists will have 'gaps' - they will be missing the items that are still pending.
 */
internal suspend fun <T, R> Iterable<T>.mapConcurrentlyPreservingOrderReturningIntermediateSparseLists(
    maxConcurrency: Int,
    transformer: suspend (T) -> R,
): Flow<List<R>> =
    asFlow()
        .withIndex()
        .mapConcurrentlyLosingOrder(
            maxConcurrency = maxConcurrency,
        ) {
            IndexedValue(
                index = it.index,
                value = transformer(it.value),
            )
        }
        .allSublistsFromSingleFirstItemToAllItems()
        .map { allItemsCompletedSoFar ->
            allItemsCompletedSoFar
                .sortedBy { indexedValue -> indexedValue.index }
                .map { indexedValue -> indexedValue.value }
        }
