// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

/**
 * When both [Pair.first] and [Pair.second] are the same type, this function can be used to map both items.
 */
internal inline fun <T, R> Pair<T, T>.mapPairItems(mapper: (T) -> R): Pair<R, R> =
    mapper(first) to mapper(second)

internal fun <K, V> Sequence<Pair<K, V?>>.filterNotNullSecond(): Sequence<Pair<K, V>> =
    filter { it.second != null }
        .map { it.first to it.second!! }

internal fun <K, V> List<Pair<K, V?>>.filterNotNullSecond(): List<Pair<K, V>> =
    asSequence()
        .filterNotNullSecond()
        .toList()

internal fun <K, V> Array<out Pair<K, V?>>.filterNotNullSecond(): Array<Pair<K, V>> =
    toList()
        .filterNotNullSecond()
        .toTypedArray()
