package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentDirection
import kotlin.js.JsExport

// TODO(anson): add extension functions to ContentCursor so clients don't have to construct builder for fromCursor use-case

@JsExport
@Suppress("DataClassPrivateConstructor")
data class CursorQuery private constructor(
    internal val startingPoint: CursorQueryStartingPoint,
    internal val scans: List<CursorQueryScan> = listOf(),
) {
    companion object {
        /**
         * Get a [CursorQuery] that will evaluate to the start of the content
         */
        fun fromStart() = CursorQuery(CursorQueryStartingPoint.Start)

        /**
         * Get a [CursorQuery] that will evaluate to the end of the content
         */
        fun fromEnd() = CursorQuery(CursorQueryStartingPoint.End)

        /**
         * Get a [CursorQuery] that will evaluate to the [cursor] provided
         */
        fun fromCursor(cursor: ContentCursor) = CursorQuery(CursorQueryStartingPoint.Cursor(cursor))
    }

    fun scanCharsBackward(chars: Int): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Chars(
                ContentDirection.BACKWARD,
                chars,
            ),
        )
    }

    fun scanCharsForward(chars: Int): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Chars(
                ContentDirection.FORWARD,
                chars,
            ),
        )
    }

    fun scanBackwardToWordStart(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Words(
                ContentDirection.BACKWARD,
                ContentBoundary.START,
                skipping,
            ),
        )
    }

    fun scanBackwardToWordEnd(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Words(
                ContentDirection.BACKWARD,
                ContentBoundary.END,
                skipping,
            ),
        )
    }

    fun scanForwardToWordStart(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Words(
                ContentDirection.FORWARD,
                ContentBoundary.START,
                skipping,
            ),
        )
    }

    fun scanForwardToWordEnd(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Words(
                ContentDirection.FORWARD,
                ContentBoundary.END,
                skipping,
            ),
        )
    }

    fun scanBackwardToSentenceStart(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Sentences(
                ContentDirection.BACKWARD,
                ContentBoundary.START,
                skipping,
            ),
        )
    }

    fun scanBackwardToSentenceEnd(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Sentences(
                ContentDirection.BACKWARD,
                ContentBoundary.END,
                skipping,
            ),
        )
    }

    fun scanForwardToSentenceStart(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Sentences(
                ContentDirection.FORWARD,
                ContentBoundary.START,
                skipping,
            ),
        )
    }

    fun scanForwardToSentenceEnd(skipping: Int = 0): CursorQuery {
        return CursorQuery(
            this.startingPoint,
            this.scans + CursorQueryScan.Sentences(
                ContentDirection.FORWARD,
                ContentBoundary.END,
                skipping,
            ),
        )
    }
}
