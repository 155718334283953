package com.speechify.client.api.content.view.epub

import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.epubV3.EpubV3
import com.speechify.client.api.content.view.web.WebPageElementAttribute
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.buildChildren
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.toFileWithMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.sync.coLazy

internal class EpubChapterV3(
    internal val epubV3: EpubV3,
    private val htmlParser: HTMLParser,
    override val index: Int,
) : EpubChapter {

    override val link = epubV3.readingOrder[index]
    override val start: ContentCursor = ContentElementReference.forRoot().getChild(index).start
    override val end: ContentCursor = ContentElementReference.forRoot().getChild(index).end

    private val chapterContent = coLazy {
        val zipFileEntry = epubV3.zipArchiveView.entries.find { it.path.endsWith(link.href.path) }
            ?: throw IllegalStateException("EpubChapter - Chapter is not found. Path: ${link.href.path}")

        val xhtmlChapterFile = zipFileEntry.coCreateBinaryContentReadableRandomly().toFileWithMimeType(
            MimeType("text/html"),
        )
        val element = htmlParser.coParseAsDOM(xhtmlChapterFile).orThrow()
        val builder = WebPageNode.Builder.Element(
            ContentElementReference.fromPath(listOf(index), ObjectRef(element.rawRefObject)),
            element.tagName,
            element.attributes.map { WebPageElementAttribute(it) },
        )
        builder.buildChildren(element)

        val root = builder.build()

        val chapterNavPoints = epubV3.navigation?.flattenedTocNavPoints?.filter {
            it.navPoint.chapterIndex == index
        }.orEmpty()

        EpubChapterContent(
            link = link,
            root = root,
            navPointsToTocEntries = resolveChapterNavPoints(
                navPoints = chapterNavPoints,
                root = root,
            ),
        )
    }

    override suspend fun getContent(): EpubChapterContent {
        return chapterContent.getWithCancellation()
    }
}
