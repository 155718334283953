package com.speechify.client.reader.epub

import com.speechify.client.reader.epub.TextAlignment.Default
import kotlin.js.JsExport

@JsExport
data class EpubTextAdjustmentsConfig(
    val lineSpacing: TextAdjustmentValue,
    val letterSpacing: TextAdjustmentValue,
    val wordSpacing: TextAdjustmentValue,
    val horizontalMargins: TextAdjustmentValue,
    val fontWeight: FontWeight,
    val textAlignment: TextAlignment,
)

/**
 * Represents the value that could be applied to the Text adjustments configs.
 */
@JsExport
sealed class TextAdjustmentValue {

    /**
     * Represents the default text adjustment made by the publisher.
     * Default value of text-adjustments is "normal".
     * See: [MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/line-height)
     */
    object Default : TextAdjustmentValue() {
        const val VALUE: String = "normal"
    }

    /**
     * Represents the CSS "em" unit, chosen for its shared usage across text adjustment fields.
     */
    data class EMUnit(val value: Double) : TextAdjustmentValue()

    /**
     * Represents a pixel-based unit for text adjustments fields.
     */
    data class PXUnit(val value: Double) : TextAdjustmentValue()
}

@JsExport
enum class FontWeight {
    Normal,
    Bold,
    Lighter,
    Bolder,
}

/**
 * Enum representing different text alignment options.
 * [Default] Uses the publisher's default text alignment settings.
 */
@JsExport
enum class TextAlignment {
    Default,
    Start,
    End,
    Center,
    Justify,
}
