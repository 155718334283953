package com.speechify.client.api.services.personalvoice

import com.speechify.client.api.audio.VoiceGender
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
class PersonalVoice(
    val id: String,
    val displayName: String,
    val avatarUrl: String?,
    val gender: VoiceGender,
    val languageCode: String,
)

@JsExport
data class CreatePersonalVoiceRequest(
    val sampleUrl: String,
    val displayName: String,
    val gender: VoiceGender,
    val avatarUrl: String? = null,
    val languageCode: String,
    val source: String? = null,
    val consent: PersonalVoiceCreationConsent? = null,
)

@JsExport
@Serializable
data class PersonalVoiceCreationConsent(
    val fullName: String,
    val email: String,
)

//
// https://dev.voices.api.speechify.dev/documentation
//

@Serializable
internal data class HttpCreatePersonalVoiceRequest(
    val sampleUrl: String,
    val avatarUrl: String?,
    val meta: HttpCreatePersonalVoiceMeta,
    val source: String,
    val consent: PersonalVoiceCreationConsent? = null,
)

@Serializable
internal data class HttpPersonalVoiceAvatar(
    val public: String? = null,
)

@Serializable
/**
 * Properties describing the voice.
 *
 * As of Dec 5, 2023 the Voices Server does not actually enforce any schema on these properties, and inconsistency
 * between the values provided by different client products has caused user-facing bugs, see
 * https://speechifyworkspace.slack.com/archives/C03JLSQMBEJ/p1701358125461179?thread_ts=1701337115.226649&cid=C03JLSQMBEJ.
 *
 * As a result, we don't make any assumptions about the metadata value we receive from the server, though we do
 * attempt to pick out the fields that are known to be provided by some of the clients to provide a nice experience
 * when possible.
 */
internal data class HttpCreatePersonalVoiceMeta(
    val locale: String? = null,
    val gender: String? = null,
    val displayName: String? = null, // used by SDK clients, as of Dec 5, 2023
    val name: String? = null, // used by VoiceOvers client, as of Dec 5, 2023
    val avatar: HttpPersonalVoiceAvatar? = null,
)

@Serializable
internal data class HttpPersonalVoice(
    val id: String,
    val meta: HttpCreatePersonalVoiceMeta,
) {
    fun toPersonalVoice(): PersonalVoice = PersonalVoice(
        id = id,
        displayName = meta.displayName ?: meta.name ?: "Unknown",
        avatarUrl = meta.avatar?.public,
        gender = meta.gender?.uppercase()?.let {
            try {
                VoiceGender.valueOf(it.uppercase())
            } catch (e: Exception) {
                Log.w(
                    DiagnosticEvent(
                        message = "Unexpected value provided for voice gender",
                        sourceAreaId = "HttpPersonalVoice.toPersonalVoice",
                        properties = mapOf("gender" to it),
                    ),
                )
                VoiceGender.UNSPECIFIED
            }
        } ?: VoiceGender.UNSPECIFIED,
        languageCode = meta.locale ?: "en-US",
    )
}

internal typealias HttpGetPersonalVoiceListResponseBody = List<HttpPersonalVoice>

@Serializable
data class HttpDeletePersonalVoiceResponse(
    val voice_id: String,
)
