package com.speechify.client.bundlers.listening

import com.speechify.client.api.audio.AudioConfig
import com.speechify.client.api.audio.AudioMediaFormat
import com.speechify.client.api.audio.SpeechSynthesisConfig
import com.speechify.client.api.audio.TextToSpeechAudioCacheCapacityOption
import com.speechify.client.api.audio.TextToSpeechAudioContextInclusionOption
import com.speechify.client.api.audio.UtteranceBufferSizeOption
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.collections.flows.SharedFlowFromCallback
import com.speechify.client.helpers.constants.SpeechifyVoiceSpecifications
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

/**
 * Configuration options for the [ListeningBundler]
 */
@JsExport
class ListeningBundlerConfig(
    /**
     * Configuration options for Audio functionality
     */
    override val audioConfig: AudioConfig = AudioConfig(AudioMediaFormat.MP3),

    /**
     * The default Listening speed
     */
    val defaultSpeedWPM: Int,
    /**
     * To initialize the listening experience with your own configuration of voices, use this parameter
     * to include [VoiceSpec]s of your choice. [VoiceSpec] reference data can be also
     * grabbed from [SpeechifyVoiceSpecifications].
     * For more specific information, see [VoiceSpec].
     */
    val allVoices: Array<VoiceSpec>,

    /**
     * See [VoicePreferences].
     */
    val voicePreferences: VoicePreferences,

    /**
     * Container for all *optional* [ListeningBundler] configurations.
     */
    val options: ListeningBundlerOptions,
) : SpeechSynthesisConfig,
    TextToSpeechAudioCacheCapacityOption by options,
    TextToSpeechAudioContextInclusionOption by options

/**
 * A component exposing user's voice preferences, for when the content does not specify the voice and
 * [com.speechify.client.helpers.ui.controls.PlaybackControls.setVoice] was not used on a listened document.
 *
 * The preferences can be specified entirely statically (see the constructor with static values, in JS it's a factory
 * function), dynamically (by implementing [SharedFlowFromCallback]s and passing it to the constructor) or mixed
 * (by passing [com.speechify.client.api.util.collections.flows.SharedFlowFromCallbackWithStaticValue] where appropriate).
 *
 * Notable features:
 * - Dynamic providing of the voice can include providing it entirely Just-In-Time, when the voice is needed,
 *   including asking the user. This is thanks to the fact that the [VoicePreference]'s method of [SharedFlowFromCallback.getValueAndSubscribeAndGetCancel]
 *   is asynchronous, and will only be queried when the voice is needed, so the implementation can determine the voice at that
 *   moment using asynchronous code.
 * - The [VoicePreference] API also allows to change the voice at any time, and this will result in an
 *   immediate change of the voice in the bundle that uses these preferences. This means that the preference can come
 *   from a UI control that is per-bundle, but equally it can be a global preference that is changed in user's settings.
 */
@JsExport
class VoicePreferences(
    /**
     * This voice will be used when all are true:
     * - user has premium features, even in form of a trial ([com.speechify.client.api.services.subscription.models.Entitlements.isPremium]
     * = `true`)
     * - and device has internet connection ([com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider]'s
     *   current state is [com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider.OfflineModeStatus.ONLINE])
     * - and the content does not specify the voice
     * - and [com.speechify.client.helpers.ui.controls.PlaybackControls.setVoice] was not used on a listened document.
     */
    val defaultPremiumVoice: VoicePreference,
    /**
     * The voice that will be used when:
     * - user has no premium features and is outside of trial ([com.speechify.client.api.services.subscription.models.Entitlements.isPremium]
     * = `false`)
     * - and the content does not specify the voice or the voice specified by the content is not available for free
     * - and [com.speechify.client.helpers.ui.controls.PlaybackControls.setVoice] was not used on a listened document
     *   (so callers of [com.speechify.client.helpers.ui.controls.PlaybackControls.setVoice] need to make sure to only
     *   use free voices for users with [com.speechify.client.api.services.subscription.models.Entitlements.isPremium] = false).
     *
     * NOTE: This voice must work without internet connection - this is the current business strategy, and the API
     * was not opened up for free online voices, as this would also require a separate decision for 'free offline voices'
     * and a separate for 'free online voices'.
     */
    val defaultFreeOfflineVoice: VoicePreference,

    /**
     * NOTE: The voices returned here must work without internet connection. Specifying a voice that identifies an
     * online voice is only allowed if a fully-on-device synthesis of that voice is available on the given device (like
     * the Speechify on-device AI voices released for some iOS devices). NOTE: But even when on-device premium voices
     * are available, the voice specified here *can* be a free voice, because this member is meant to reflect the user's
     * preference for offline voice, so if the user prefers a free one, it should be returned here.
     *
     * This voice will be used when all are true:
     * - user has premium features, even in form of a trial ([com.speechify.client.api.services.subscription.models.Entitlements.isPremium]
     * = `true`)
     * - and device has no internet connection ([com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider]'s
     *   current state is [com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider.OfflineModeStatus.OFFLINE])
     * - and the content being listened had offline-audio downloaded for the specified voice, but the playback
     *   encountered content that was not downloaded yet, either because the download did not reach there yet, or user
     *   applied changes to the text after downloading or used different skip/transformation options, or the synthesis
     *   became out of date due to improvements of the Speechify app.
     *   Whether this has happened on an item can be queried or communicated to the user by [com.speechify.client.bundlers.listening.ListeningBundle.hasDetectedGapsInDownloadedAudioThisListeningSession]
     *   or on library-level [com.speechify.client.api.services.library.models.LibraryItem.Content.audioDownloads]'s
     *   [com.speechify.client.api.services.library.models.ContentItemAudioDownloadsInfo.hasGaps]
     *   TESTING: This situation can be tested by inducing it via [com.speechify.client.api.audio.SpeechifySDKTestingAudioDownload.shouldSimulateGaps] = true
     * NOTE: For documents which never had audio-download started, this voice will not be used as a fallback.
     * - TODO - in the future, this could also be used to make SDK responsible for falling back to offline-voices for
     *    both preemptively for the duration of the device being offline, as well as
     *    (though this would need to be accompanied by API analysis, to make sure the fallback is communicated well
     *    to the users - perhaps there should be a way for users to opt out of this fallback)
     */
    val defaultOfflineVoice: VoicePreference = defaultFreeOfflineVoice,
) {
    /**
     * For use when one specific default voice is to be used, it's available offline and free (or the code where it is
     * used only runs for users that have access to it).
     */
    internal constructor(
        staticDefaultFreeOfflineVoice: VoiceSpec,
    ) : this(
        defaultFreeOfflineVoice = VoicePreferenceWithStaticValue(staticDefaultFreeOfflineVoice),
    )

    internal constructor(
        defaultFreeOfflineVoice: VoicePreference,
    ) : this(
        defaultPremiumVoice = defaultFreeOfflineVoice,
        defaultFreeOfflineVoice = defaultFreeOfflineVoice,
        defaultOfflineVoice = defaultFreeOfflineVoice,
    )
}

/**
 * Implementors must override the [SharedFlowFromCallback.getValueAndSubscribeAndGetCancel] method.
 *
 * Note: `null` can be returned as a last resort to have SDK search for any available voice but, for this to work,
 * [ListeningBundlerConfig.allVoices] needs to be populated with at least one available voice.
 * It can be used when user's last preferred voice is no longer available, but the SDK consumers are encouraged to
 * choose a voice then, e.g. matching their previous' voice languages, accent and gender.
 */
@JsExport
abstract class VoicePreference : SharedFlowFromCallback<VoiceSpec?>()

@JsExport
class VoicePreferenceWithStaticValue(
    private val staticValue: VoiceSpec?,
) : VoicePreference() {
    override fun getValueAndSubscribeAndGetCancel(
        receiveItem: CallbackNoError<VoiceSpec?>,
    ): Destructor {
        receiveItem(staticValue)
        return {}
    }
}

/**
 * Container for all *optional* [ListeningBundler] configurations.
 */
@JsExport
class ListeningBundlerOptions :
    UtteranceBufferSizeOption,
    TextToSpeechAudioCacheCapacityOption,
    TextToSpeechAudioContextInclusionOption {
    /**
     * Allows to override the maximum amount of synthesized audio to cache in memory, expressed in characters of text.
     * When `null`, the default of [textToSpeechAudioCacheInMemoryCapacityInCharsOfTextOverrideDefault] is used.
     */
    var textToSpeechAudioCacheInMemoryCapacityInCharsOfTextOverride: Int?
        get() =
            SpeechAudioCacheInMemoryCapacityInCharsOfTextFlowMutable.value
        set(value) {
            SpeechAudioCacheInMemoryCapacityInCharsOfTextFlowMutable.value =
                value ?: textToSpeechAudioCacheInMemoryCapacityInCharsOfTextOverrideDefault
        }

    @JsExport.Ignore
    override val textToSpeechAudioCacheInMemoryCapacityInCharsOfTextFlow: StateFlow<Int> get() =
        SpeechAudioCacheInMemoryCapacityInCharsOfTextFlowMutable

    /**
     * Encapsulated mutable [textToSpeechAudioCacheInMemoryCapacityInCharsOfTextFlow].
     */
    private val SpeechAudioCacheInMemoryCapacityInCharsOfTextFlowMutable: MutableStateFlow<Int> =
        MutableStateFlow(
            textToSpeechAudioCacheInMemoryCapacityInCharsOfTextOverrideDefault,
        )

    /**
     * Allows to override if the preceding context should be included for audio synthesis. Defaults to false.
     */
    var textToSpeechIncludePrecedingContextForAudioSynthesisOverride: Boolean
        get() =
            IncludePrecedingContextForAudioSynthesisFlowMutable.value
        set(value) {
            IncludePrecedingContextForAudioSynthesisFlowMutable.value = value
        }

    @JsExport.Ignore
    override val textToSpeechIncludePrecedingContextForAudioSynthesis: StateFlow<Boolean>
        get() = IncludePrecedingContextForAudioSynthesisFlowMutable

    /**
     * Encapsulated mutable [textToSpeechIncludePrecedingContextForAudioSynthesis].
     */
    private val IncludePrecedingContextForAudioSynthesisFlowMutable: MutableStateFlow<Boolean> =
        MutableStateFlow(false)

    /**
     * Whether the audio controller should start buffering audio immediately on instantiation
     *
     * DEFAULT: false
     *
     * NOTE: this is expensive if you create reading/listening bundles very often!
     */
    internal var immediateAudioCacheWarming = false

    /**
     * Enable playback engine to execute TTS prior to calling `play()` to reduce buffering time.
     */
    fun enableImmediateAudioCacheWarming(): ListeningBundlerOptions {
        immediateAudioCacheWarming = true
        return this
    }

    var utteranceBufferSize: Int
        get() = utteranceBufferSizeFlowMutable.value
        set(value) {
            utteranceBufferSizeFlowMutable.value = value
        }

    /**
     * Buffer size for utterances - defaults to 20, which approximates to about 5 minutes of listening content at 200WPM.
     * Does not apply to dynamic content — dynamic content will always have a buffer size of zero [kotlinx.coroutines.channels.Channel.RENDEZVOUS],
     * see [com.speechify.client.api.audio.UtteranceFlowProviderFromSpeechFlow.getUtterancesFlow]
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val utteranceBufferSizeFlow: StateFlow<Int> get() =
        utteranceBufferSizeFlowMutable

    private val utteranceBufferSizeFlowMutable =
        MutableStateFlow(20)
}

const val textToSpeechAudioCacheInMemoryCapacityInCharsOfTextOverrideDefault =
    /* 100_000 characters is a decent size document.
     * The point is not to even cover it entirely. Just a region to which the user may come back.
     */
    50_000
