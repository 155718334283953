package com.speechify.client.internal.services.subscription.models

import com.speechify.client.api.services.subscription.models.PlatformRenewalFrequency
import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformCreatePayPalSubscriptionBody(
    val userId: String,
    val subscriptionId: String,
    val renewalFrequency: PlatformRenewalFrequency,
    val email: String?,
    val hasTrial: Boolean?,
)
