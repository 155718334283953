package com.speechify.client.api.util.io

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.collections.flows.CallbackFlowSourceFromCollectWithResult
import com.speechify.client.api.util.collections.flows.FlowFromCallbackFlowSource
import com.speechify.client.api.util.fromCoGetDestructible
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.ItemsReadableSequentiallyMultiplatformAPI
import com.speechify.client.internal.util.collections.arrays.isPlatformWithSparseArrays
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.flow
import kotlin.js.JsExport

/**
 * Represents the SDK-consumer-controlled most efficient way (ideally, native to the programming language used), to carry
 * binary content that needs to be read, but only sequentially (typically a native 'stream' in the target programming language).
 *
 * The value will typically be passed intact to various SDK consumer components (especially the [com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter]), thanks to which they will be free to
 * access the internal fields of these objects for the most efficient processing (hence the use of [BinaryContentReadableInChunksWithNativeAPI] markup interface).
 *
 * Where the SDK needs to read the contents, it will use the [BinaryContentReadableSequentially.collect] method.
 * It should not be used by the SDK consumers where a native input is accepted, because it is not the most
 * efficient, as it doesn't support reusing arrays, or [zero-copy-reading](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamBYOBReader)).
 */
expect class BinaryContentReadableSequentially :
    BinaryContentReadableSequentiallyWithMultiplatformAndNativeAPI

/**
 * A binary content that can be read sequentially, and provides a Multiplatform API for it.
 */
@JsExport
abstract class BinaryContentReadableSequentiallyMultiplatformAPI :
    FlowFromCallbackFlowSource<ByteArray>(),
    ItemsReadableSequentiallyMultiplatformAPI<ByteArray>,
    CallbackFlowSourceFromCollectWithResult<ByteArray>,
    BinaryContentReadableInChunks {

    /**
     * SDK consumers should only use this to read the bytes when there's no native API available.
     * See [CallbackFlowSourceFromCollectWithResult] and its [CallbackFlowSourceFromCollectWithResult.collect] for
     * documentation.
     */
    abstract override fun collect(
        collectOne: CallbackNoError<ByteArray>,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        complete: Callback<Unit>,
    ): Destructible
}

class BinaryContentReadableSequentiallyMultiplatformAPIFromFlow(
    private val sourceFlow: Flow<ByteArray>,
) : BinaryContentReadableSequentiallyMultiplatformAPI() {
    override suspend fun collect(collector: FlowCollector<ByteArray>) =
        sourceFlow.collect(collector)

    override fun collect(
        collectOne: CallbackNoError<ByteArray>,
        complete: Callback<Unit>,
    ): Destructible = complete.fromCoGetDestructible {
        collect(
            collector = {
                collectOne(it)
            },
        )
            .successfully()
    }
}

/**
 * Groups `*ReadableSequentially` types that have both native API, and Multiplatform API.
 */
@JsExport
abstract class BinaryContentReadableSequentiallyWithMultiplatformAndNativeAPI :
    BinaryContentReadableSequentiallyMultiplatformAPI(),
    BinaryContentReadableInChunksWithNativeAPI

/**
 * Warning: This may lead to Out Of Memory errors, so should not be performed on large data.
 * (to avoid this, read the sequence using the [BinaryContentReadableSequentiallyMultiplatformAPI.collect]).
 */
internal suspend fun BinaryContentReadableSequentiallyMultiplatformAPI.readAllBytesToSingleArray(
    sourceAreaIdForWarningOnInefficientArrayCopy: String,
    expectedByteCountBelow: Int? = null,
    /**
     * Suppress the warning when the total size is insignificant.
     * NOTE: this parameter will be ignored in case of "[expectedByteCountBelow] is specified and there was a
     * resize beyond [expectedByteCountBelow]", so that the warnings serve as feedback to adjust
     * [expectedByteCountBelow].
     */
    suppressWarningForTotalByteCountBelow: Int? = 2 * 1024 * 1024,
): ByteArray {
    var result: ByteArray? = null
    var countOfArrayResizes = 0
    var actualDataSizeOrNullIfNoResizes: Int? = null
    try {
        collect { chunk ->
            result =
                /* Use `let` to capture `result` into a `val`, to use smart casting of non-nulls. */
                result.let result@{ result ->
                    if (result == null) {
                        /* Optimizing for the case where there's just one chunk - preventing any copying. */
                        return@result chunk
                    } else {
                        val previousDataSize: Int
                        val destinationArrayWithSizePlus =
                            /* Use `let` to capture `result` into a `val`, to use smart casting of non-nulls. */
                            actualDataSizeOrNullIfNoResizes.let destinationArrayWithSizePlus@{ currentActualDataSize ->
                                if (currentActualDataSize == null) {
                                    /* This is the first resize. Let's make it generous. */

                                    /** [currentActualDataSize] `null` was used to detect first resize, but there's already some
                                     *  size is the [result]'s size, so mark that:
                                     */
                                    previousDataSize = result.size

                                    ++countOfArrayResizes
                                    return@destinationArrayWithSizePlus result.copyOf(
                                        newSize = result.size +
                                            (
                                                expectedByteCountBelow
                                                    ?: (
                                                        /** [expectedByteCountBelow] was not specified, but let's still be more
                                                         *  generous for the first resize, and not be just one chunk. */
                                                        (3 * (result.size + chunk.size))
                                                            /** But also use [coerceAtMost] not to go out-of-memory here in case chunks are big. */
                                                            .coerceAtMost(2 * 1024 * 1024)
                                                        )
                                                )
                                                .coerceAtLeast(chunk.size),
                                    )
                                } else {
                                    /* This is not the first resize. Let's see if another resize is needed */

                                    /** In case of non-null [currentActualDataSize], we use it to get [previousDataSize], and not the [result] size like above */
                                    previousDataSize = currentActualDataSize

                                    if (result.size >= currentActualDataSize + chunk.size) {
                                        /* No resize needed. Just return the same array because it's sufficient. */
                                        return@destinationArrayWithSizePlus result
                                    } else {
                                        ++countOfArrayResizes
                                        return@destinationArrayWithSizePlus result.copyOf(
                                            newSize = currentActualDataSize +
                                                (currentActualDataSize * 0.2).toInt()
                                                    .coerceAtLeast(chunk.size),
                                        )
                                    }
                                }
                            }

                        actualDataSizeOrNullIfNoResizes = previousDataSize + chunk.size
                        return@result chunk.copyInto(
                            destination = destinationArrayWithSizePlus,
                            destinationOffset = previousDataSize,
                        )
                    }
                }
        }

        return result
            /* Use `let` to capture `result` into a `val`, to use smart casting of non-nulls. */
            .let { finalResultArray ->
                if (finalResultArray == null) {
                    return ByteArray(0)
                } else {
                    /* Use `let` to capture `result` into a `val`, to use smart casting of non-nulls. */
                    actualDataSizeOrNullIfNoResizes.let { actualDataSizeOrNullIfNoResizes ->
                        if (actualDataSizeOrNullIfNoResizes == null) {
                            /** There were no resizes, so truncation NOT needed */
                            finalResultArray
                        } else {
                            /** There were resizes, so truncation IS needed ([ByteArray.copyOf] does truncate) */
                            finalResultArray.copyOf(newSize = actualDataSizeOrNullIfNoResizes)
                        }
                    }
                }
            }
    } finally {
        val resultBytesCount = actualDataSizeOrNullIfNoResizes ?: result?.size ?: 0

        /* Let's warn if the array was resized more than once, and the result is large to possibly contribute
         * significantly to out-of-memory errors. */
        if (
            (countOfArrayResizes > 0) &&
            /** Don't warn if size wasn't larger than [expectedByteCountBelow] (to give feedback for adjusting it) or
             * larger than [suppressWarningForTotalByteCountBelow] (to ignore insignificant payloads of all default
             * cases, with unspecified [expectedByteCountBelow]). */
            (resultBytesCount > (expectedByteCountBelow ?: suppressWarningForTotalByteCountBelow ?: 0)) &&
            isPlatformWithSparseArrays.not()
        ) {
            Log.w(
                DiagnosticEvent(
                    sourceAreaId = sourceAreaIdForWarningOnInefficientArrayCopy,
                    message = "Reading a large BinaryContentReadableSequentially into a single ByteArray " +
                        "inefficiently, by resizing the array as needed and copying each chunk in memory. See " +
                        "{sourceAreaId} if it can be refactored not to work on a single array.",
                    properties = mapOf(
                        "resultBytesCount" to resultBytesCount,
                        "countOfArrayResizes" to countOfArrayResizes,
                    ),
                ),
            )
        }
    }
}

internal fun File.toBinaryContentWithMimeTypeReadableSequentially():
    BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI> {
    val file = this@toBinaryContentWithMimeTypeReadableSequentially
    return object : BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI> {
        override val binaryContent: BinaryContentReadableSequentiallyMultiplatformAPI =
            file.toBinaryContentReadableSequentially()
        override val mimeType: MimeType
            get() = file.mimeType
    }
}

internal fun BinaryContentReadableRandomlyMultiplatformAPI.toBinaryContentReadableSequentially(
    maxChunkSize: Int = 8 * 1024, /* kotlin.io.DEFAULT_BUFFER_SIZE */
):
    BinaryContentReadableSequentiallyMultiplatformAPI {
    val contentReadableRandomly = this

    return BinaryContentReadableSequentiallyMultiplatformAPIFromFlow(
        sourceFlow = flow {
            var currentOffset = 0
            val totalBytesCount = contentReadableRandomly.coGetSizeInBytes()
                .orThrow()
            while (currentOffset < totalBytesCount) {
                val chunk = contentReadableRandomly.coGetBytes(
                    startIndex = currentOffset,
                    endIndex = (currentOffset + maxChunkSize).coerceAtMost(totalBytesCount),
                )
                    .orThrow()

                currentOffset += chunk.size
                emit(chunk)
            }
        },
    )
}
