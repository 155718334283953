package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentCursor

internal sealed class CursorQueryStartingPoint {
    object Start : CursorQueryStartingPoint()
    object End : CursorQueryStartingPoint()
    data class Cursor(val cursor: ContentCursor) : CursorQueryStartingPoint()

    override fun toString() = "${this::class.simpleName ?: this::class}"
}
