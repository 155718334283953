package com.speechify.client.internal.services.subscription

import com.speechify.client.api.adapters.firebase.FirebaseFieldValueAdapter
import com.speechify.client.api.services.subscription.models.RenewalFrequency
import com.speechify.client.api.services.subscription.models.SubscriptionPlan
import com.speechify.client.api.services.subscription.models.SubscriptionPlanEntitlements
import com.speechify.client.api.services.subscription.models.SubscriptionSource
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.subscription.models.FirebaseSubscriptionPlan
import com.speechify.client.internal.services.subscription.models.FirebaseUserRewards
import com.speechify.client.internal.services.subscription.models.toSubscriptionSource
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.boundary.SdkBoundaryMap

internal fun buildMissingFieldFailure(field: String, vararg from: String): Result.Failure {
    return Result.Failure(
        SDKError.OtherMessage(
            "field '$field' missing from '${from.joinToString(" and ") { "'$it'" }}",
        ),
    )
}

internal object SubscriptionsFirebaseDataTransformer {
    internal fun planToSubscriptionPlan(
        subscriptionPlan: FirebaseSubscriptionPlan,
        fallbackSource: SubscriptionSource? = null,
        fallbackProductId: String? = null,
    ): Result<SubscriptionPlan> {
        val source = subscriptionPlan.source?.toSubscriptionSource()
            ?: fallbackSource
            ?: return buildMissingFieldFailure(
                "source",
                "subscriptionPlan",
            )

        val productId = subscriptionPlan.name ?: fallbackProductId ?: return buildMissingFieldFailure(
            "productId",
            "subscriptionPlan",
        )

        val renewalFrequency = when (subscriptionPlan.period) {
            "monthly" -> RenewalFrequency.MONTHLY
            "weekly" -> RenewalFrequency.WEEKLY
            "quarterly" -> RenewalFrequency.QUARTERLY
            else -> RenewalFrequency.YEARLY
        }

        val hasTrial = subscriptionPlan.trial ?: false

        val trialDays = if (hasTrial) {
            // We default to 3 days since that is the most common duration we have.
            subscriptionPlan.trialPeriod ?: 3
        } else {
            null
        }

        val productTypes = subscriptionPlan.productTypes ?: arrayOf()
        val entitlements = subscriptionPlan.entitlements

        return SubscriptionPlan(
            source = source,
            name = productId,
            price = subscriptionPlan.price,
            renewalFrequency = renewalFrequency,
            conversionId = subscriptionPlan.conversionId,
            discountIds = subscriptionPlan.discounts ?: arrayOf(),
            hasTrial = hasTrial,
            initialTrialDurationDays = trialDays,
            productTypes = productTypes,
            entitlements = SubscriptionPlanEntitlements(
                wordsLimit = entitlements.wordsLimit ?: 0,
                audiobookCredits = entitlements.audiobookCredits ?: 0,
                avatarsSeconds = entitlements.avatarsSeconds ?: 0,
                dubbingSeconds = entitlements.dubbingSeconds ?: 0,
                voiceoverSeconds = entitlements.voiceoverSeconds ?: 0,
                ttsExportSeconds = entitlements.ttsExportSeconds ?: 0,
            ),
        ).successfully()
    }

    fun userRewardsToSavePayload(resource: FirebaseUserRewards): BoundaryMap<Any?> {
        with(resource) {
            return SdkBoundaryMap.of(
                "premiumWords" to premiumWords,
            )
        }
    }

    fun deductUserHdWordsPayload(
        wordsToDeduct: Int,
        now: DateTime,
        fieldValueAdapter: FirebaseFieldValueAdapter,
    ): BoundaryMap<Any?> {
        return SdkBoundaryMap.of(
            "hdWordsLeft" to fieldValueAdapter.increment(-wordsToDeduct),
            "lastDecrementedAt" to now.asSeconds(),
        )
    }
}
