package com.speechify.client.reader.core

import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.speech.CursorQuery
import com.speechify.client.helpers.ui.controls.PlayPauseButton
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.util.extensions.collections.flows.mapStateFlow
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collect
import kotlin.js.JsExport

@JsExport
class PlaybackHelper internal constructor(
    scope: CoroutineScope,
    internal val playbackControls: PlaybackControls,
    internal val initialLocation: CompletableDeferred<RobustLocation>,
) : Helper<PlaybackState>(scope) {
    override val stateFlow: StateFlow<PlaybackState> = playbackControls.stateFlow.mapStateFlow { it.toPlaybackState() }
    override val initialState = stateFlow.value

    init {
        launchInHelper {
            val location = initialLocation.await()
            playbackControls.audioController.seekToCursor(cursor = location.hack.cursor)

            // We intentionally wait for the initial location before subscribing to commands.
            // This deferred initialization pattern allows clients to set up the UI scaffolding
            // while the location loads, rather than blocking the entire `ListeningExperience` construction.
            // It ensures commands are only processed after proper initialization.

            commands
                .onEachInstance<PlaybackCommand.PlayFrom> {
                    playbackControls.audioController.seekToCursor(
                        it.location.cursor,
                    )
                    dispatchAutoScrollHelperCommand(it.location.cursor, enableAutoscroll = false)
                }
                .onEachInstance<PlaybackCommand.TapToJump> {
                    val query = it.location.toCursorQuery(it.relativeNavigationIntent)
                    playbackControls.audioController.seek(query) { cursor ->
                        dispatchAutoScrollHelperCommand(cursor, enableAutoscroll = false)
                    }
                }
                .onEachInstance<PlaybackCommand.TapToPlay> {
                    val query = it.location.toCursorQuery(it.relativeNavigationIntent)
                    playbackControls.audioController.play(query) { cursor ->
                        dispatchAutoScrollHelperCommand(cursor, it.enableAutoscroll)
                    }
                }
                .onEachInstance<PlaybackCommand.SkipToMainContent> {
                    val query = CursorQuery.fromCursor(it.location.cursor).scanBackwardToWordStart()
                    playbackControls.audioController.seek(query) { cursor ->
                        dispatchAutoScrollHelperCommand(cursor, enableAutoscroll = false)
                    }
                }
                .onEachInstance<PlaybackCommand.Pause> { playbackControls.pause() }
                .onEachInstance<PlaybackCommand.Restart> { playbackControls.restart() }
                .onEachInstance<PlaybackCommand.PressPlayPause> { playbackControls.pressPlayPause() }
                .onEachInstance<PlaybackCommand.SetSpeed> { playbackControls.setSpeed(it.wordsPerMinute) }
                .onEachInstance<PlaybackCommand.SetVoice> { playbackControls.setVoice(it.availableVoice) }
                .onEachInstance<PlaybackCommand.SkipBackwards> { playbackControls.skipBackwards() }
                .onEachInstance<PlaybackCommand.SkipForwards> { playbackControls.skipForwards() }
                .onEachInstance<PlaybackCommand.ReloadContent> { playbackControls.reloadContent() }
                .onEachInstance<CoreCommand.ReloadContent> { playbackControls.reloadContent() }
                .collect()
        }
    }

    fun skipForwards() {
        dispatch(PlaybackCommand.SkipForwards)
    }

    fun skipBackwards() {
        dispatch(PlaybackCommand.SkipBackwards)
    }

    fun pressPlayPause() {
        dispatch(PlaybackCommand.PressPlayPause)
    }

    fun setVoice(availableVoice: VoiceSpecOfAvailableVoice) {
        dispatch(PlaybackCommand.SetVoice(availableVoice))
    }

    fun setSpeed(wordsPerMinute: Int) {
        dispatch(PlaybackCommand.SetSpeed(wordsPerMinute))
    }

    fun pause() {
        dispatch(PlaybackCommand.Pause)
    }

    fun restart() {
        dispatch(PlaybackCommand.Restart)
    }

    private fun SerialLocation.toCursorQuery(relativeNavigationIntent: RelativeNavigationIntent): CursorQuery =
        CursorQuery.fromCursor(this.cursor).let { query ->
            when (relativeNavigationIntent) {
                is RelativeNavigationIntent.GoToStartOfThisWord -> query.scanBackwardToWordStart()
                is RelativeNavigationIntent.GoToStartOfThisSentence -> query.scanBackwardToSentenceStart()
                is RelativeNavigationIntent.None -> query
            }
        }

    /** To ensure that AutoscrollHelper has the latest location and can navigate to it. */
    private fun dispatchAutoScrollHelperCommand(cursor: ContentCursor, enableAutoscroll: Boolean) {
        val location = SerialLocation(cursor)
        dispatch(
            if (enableAutoscroll) {
                AutoscrollHelperCommand.EnableWithLocation(location)
            } else {
                AutoscrollHelperCommand.UpdateLocation(location)
            },
        )
    }
}

internal sealed class PlaybackCommand {
    object PressPlayPause : PlaybackCommand()
    object Pause : PlaybackCommand()
    object Restart : PlaybackCommand()
    object SkipForwards : PlaybackCommand()
    object SkipBackwards : PlaybackCommand()
    data class SetSpeed(val wordsPerMinute: Int) : PlaybackCommand()
    data class SetVoice(val availableVoice: VoiceSpecOfAvailableVoice) : PlaybackCommand()
    data class PlayFrom(val location: SerialLocation) : PlaybackCommand()
    data class TapToJump(
        val location: SerialLocation,
        val relativeNavigationIntent: RelativeNavigationIntent,
    ) : PlaybackCommand()

    data class TapToPlay(
        val location: SerialLocation,
        val relativeNavigationIntent: RelativeNavigationIntent,
        val enableAutoscroll: Boolean,
    ) : PlaybackCommand()

    data class SkipToMainContent(val location: SerialLocation) : PlaybackCommand()
    object ReloadContent : PlaybackCommand()
}

@JsExport
data class PlaybackState internal constructor(
    internal val location: SerialLocation,
    internal val speakingSentence: ContentText?,
    internal val speakingWord: ContentText?,
    val playPauseButton: PlayPauseButton,
    val voice: VoiceSpecOfAvailableVoice?,
    val speedInWordsPerMinute: Int,
)

internal fun PlaybackControls.State.toPlaybackState() = PlaybackState(
    location = SerialLocation(latestPlaybackCursor),
    speakingSentence = sentenceAndWordLocation?.sentence,
    speakingWord = sentenceAndWordLocation?.word,
    playPauseButton = playPauseButton,
    voice = voiceOfCurrentUtterance,
    speedInWordsPerMinute = wordsPerMinute,
)
