package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map

/**
 * Creates a Flow that has the option to not discard the buffer on error,
 * deferring the throwing of the error downstream to happen when the consumer
 * consumes the errored item - which generally should happen after the buffer is drained.
 * TODO: add a variable that remembers that there was an exception on the producer side, and then, on the consumer side,
 * if the collection finished without getting to the exception, we could Log.e it
 * https://github.com/SpeechifyInc/multiplatform-sdk/pull/1152#discussion_r1354326057
 */
internal fun <T> Flow<T>.bufferWithExceptionBehavior(
    capacity: Int,
    onBufferOverflow: BufferOverflow,
    producerExceptionBehavior: ProducerExceptionBehavior = ProducerExceptionBehavior.FailAndCancelConsumerImmediately,
): Flow<T> {
    return when (producerExceptionBehavior) {
        ProducerExceptionBehavior.FailAndCancelConsumerImmediately -> this.buffer(
            capacity = capacity,
            onBufferOverflow = onBufferOverflow,
        )

        ProducerExceptionBehavior.SuppressAndDeferFailureToConsumer -> {
            flow {
                try {
                    this@bufferWithExceptionBehavior
                        .collect {
                            emit(WrappedFlowItem.Available(it))
                        }
                } catch (e: CancellationException) {
                    throw e
                } catch (e: Throwable) {
                    emit(WrappedFlowItem.Exception(e))
                }
            }.buffer(capacity = capacity, onBufferOverflow = onBufferOverflow).map {
                when (it) {
                    is WrappedFlowItem.Available -> it.item
                    is WrappedFlowItem.Exception -> throw it.exception
                }
            }
        }
    }
}

/**
 * Wrapper for items emitted by a flow to handle items and exceptions uniformly.
 */
private sealed class WrappedFlowItem<out Item> {

    /** Represents a successfully emitted item. */
    class Available<out Item>(val item: Item) : WrappedFlowItem<Item>()

    /** Represents an exception that occurred during emission. */
    class Exception(val exception: Throwable) : WrappedFlowItem<Nothing>()
}

internal enum class ProducerExceptionBehavior {
    FailAndCancelConsumerImmediately, SuppressAndDeferFailureToConsumer
}
