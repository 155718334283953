package com.speechify.client.internal.services.library

import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject

internal sealed class SearchFilter(
    open val field: String,
    open val operator: Operator,
    open val value: JsonElement,
) {

    class Eq(
        field: String,
        value: String,
    ) : SearchFilter(field, Operator.EQ, JsonPrimitive(value))

    class OneOf(
        field: String,
        values: List<String>,
    ) : SearchFilter(field, Operator.IN, JsonArray(values.map { JsonPrimitive(it) }))

    class IsTrue(
        field: String,
    ) : SearchFilter(field, Operator.EQ, JsonPrimitive(true))

    class IsFalse(
        field: String,
    ) : SearchFilter(field, Operator.EQ, JsonPrimitive(false))

    enum class Operator(val op: String) {
        IN("in"),
        EQ("eq"),
    }

    fun toJson() = buildJsonObject {
        put("field", JsonPrimitive(field))
        put("value", value)
        put("operator", JsonPrimitive(operator.op))
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SearchFilter

        return field == other.field &&
            operator == other.operator &&
            value == other.value
    }

    override fun hashCode(): Int {
        var result = field.hashCode()
        result = 31 * result + operator.hashCode()
        result = 31 * result + value.hashCode()
        return result
    }
}
