package com.speechify.client.api

import kotlin.js.JsExport

@JsExport
class SDKVersion(val major: Int, val minor: Int, val patch: Int) {

    /**
     * Converts the version to a long for easier comparisons.
     *
     * The resulting number uses a fixed-width representation:
     * - Major: Up to 3 digits
     * - Minor and patch: Always 3 digits each
     * Assumes no value exceeds 999.
     */
    internal fun toLong(): Long {
        return major * 1_000_000L + minor * 1_000 + patch
    }
}

internal fun appVersionFromStringOrFallbackToDefault(version: String): SDKVersion {
    val cleanVersion = version.substringBefore('-') // Handle versions with suffixes like "-alpha"
    val (major, minor, patch) = cleanVersion.split(".").map { it.toInt() }
    return SDKVersion(major, minor, patch)
}
