package com.speechify.client.bundlers.reading

import com.speechify.client.api.services.library.LibraryServiceDelegate
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.telemetry.SpeechifySDKTelemetry
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.builderWithPrefixedProperties
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.multiShotFromSuspendFlowIn
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.listening.ListeningBundle
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.helpers.audio.controller.root.BundlingToPlaybackLatencyTelemetryTracker
import com.speechify.client.helpers.features.ListeningProgressTracker
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.AtomicBool
import com.speechify.client.internal.time.nowInMillisecondsFromEpoch
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlin.js.JsExport

/**
 * This basic contract is sufficient for text-to-speech when the content is not being scraped from an already presented
 * source, e.g. a web page open in a browser, being read by browser extension or the API for third party
 * web pages integrating Speechify service there.
 */
@JsExport
abstract class BasicReadingBundle {
    abstract val playbackControls: PlaybackControls
}

@JsExport
abstract class BasicReadingBundleWithContent<
    out TContentBundle : ContentBundle,
    > : BasicReadingBundle(), BasicReadingBundleWithContentInternalForSDK<TContentBundle>

internal interface BasicReadingBundleWithContentInternalForSDK<
    out TContentBundle : ContentBundle,
    > {
    val contentBundle: TContentBundle
}

/**
 * The subtypes of this bundle, apart from [ReadingBundle.playbackControls], also allow SDK consumers to read the
 * [content] through the bundle.
 * This is especially when the content is not presented already (like on a web page open in browser), but needs to still
 * be rendered.
 * The rendering can be performed from the simplified [ContentBundle.standardView], which can especially serve a
 * 'focus mode' layout (AKA 'classic mode'), but it can be an experience more tailored to the type of content, when
 * it is available e.g. through [bookContent].
 */
@JsExport
abstract class ReadingBundleWithContent : BasicReadingBundleWithContent<ContentBundle>() {
    abstract val content: ContentBundle

    override val contentBundle: ContentBundle get() =
        content

    /**
     * Returns the book content bundle if the content is a book, `null` otherwise.
     */
    open val bookContent: ContentBundle.BookBundle? get() =
        content as? ContentBundle.BookBundle
}

/**
 * A full-featured subtype of [ReadingBundleWithContent] which offers features related to library items (also for
 * yet-unimported items, but here, the features will be available once the item is imported):
 * * getting/setting the title of the library item (via [contentTitle])
 * * saving of user's last reading location (via [listeningProgressTracker])
 * * getting analytic properties for the item (via [getAnalytics])
 */
@JsExport
abstract class ReadingBundle internal constructor(
    internal val dependencies: Dependencies,
    /**
     * We pass the builder so any properties that get added after this call are also reflected.
     */
    private val bundlingSourceTelemetryEventBuilder: TelemetryEventBuilder?,
    /**
     * We provide the bundle metadata at this point, which includes the
     * [com.speechify.client.bundlers.reading.BundleMetadata.disableInitializationTelemetry] used for determining
     * whether to log certain telemetry events. For example, events like those in [reportUIReadyToListen].
     */
    private val bundleMetadata: BundleMetadata?,
    /**
     * Keep a reference to the predecessor bundle here in case it exists (edited book, etc) - in order to be able to destroy
     * it upon destruction of the current bundle
     */
    private val predecessorBundle: ReadingBundle?,
) : ReadingBundleWithContent(), Destructible {

    private var reportedReadyToListen = AtomicBool(false)

    /**
     * Groups common dependencies of all [ReadingBundle]s.
     */
    internal interface Dependencies {
        val playbackControls: PlaybackControls
        val listeningProgressTracker: ListeningProgressTracker

        @Suppress(
            "NON_EXPORTABLE_TYPE", // The warning was a compiler shortcoming? (the type is internal!)
        )
        val libraryService: LibraryServiceDelegate

        @Suppress(
            "NON_EXPORTABLE_TYPE", // The warning was a compiler shortcoming? (the type is internal!)
        )
        val scope: CoroutineScope
    }

    override val content: ContentBundle
        get() = dependencies.playbackControls.listeningBundle.contentBundle

    override val playbackControls: PlaybackControls get() = dependencies.playbackControls
    val listeningBundle: ListeningBundle get() = dependencies.playbackControls.listeningBundle
    val listeningProgressTracker: ListeningProgressTracker get() = dependencies.listeningProgressTracker
    private val libraryService: LibraryServiceDelegate get() = dependencies.libraryService

    /**
     * Extracts the title from the content, giving priority to the title passed in the import options
     */
    @Deprecated("Use `contentTitle` instead", ReplaceWith("contentTitle"))
    val contentTitleExtractor: ContentTitleExtractor get() =
        @Suppress("DEPRECATION")
        listeningBundle.contentBundle.titleExtractor

    /**
     * See [MutableObservableContentTitle] for semantics of this member.
     */
    val contentTitle: MutableObservableContentTitle get() = listeningBundle.contentBundle.title

    /**
     * Lets you import the bundle to the user's library, optionally setting its title
     */
    val contentImporter: ContentImporter get() = listeningBundle.contentBundle.importer

    fun observeLibraryItemChanges(callback: Callback<LibraryItem?>): Destructor =
        callback.multiShotFromSuspendFlowIn(
            flowProvider = {
                contentImporter.stateFlow.map { state ->
                    when (state) {
                        is ContentImporterState.Importing -> state.libraryItem.uri.id
                        is ContentImporterState.ImportedToLibrary -> state.libraryItem.uri.id
                        is ContentImporterState.NotImported -> state.libraryItem?.uri?.id
                        is ContentImporterState.Starting -> state.libraryItem?.uri?.id
                        else -> null
                    }
                }.filterNotNull().flatMapLatest { id ->
                    libraryService.observeLibraryItem(id)
                }
            },
            scope = dependencies.scope,
        )::destroy

    fun getAnalytics(callback: Callback<BundleAnalytics?>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ReadingBundle.getAnalytics",
    ) {
        when (val state = contentImporter.state) {
            is ContentImporterState.Imported ->
                libraryService
                    .getItemFromFirestore(state.uri.id)
                    .map { BundleAnalytics(it.analyticsProperties) }

            is ContentImporterState.Importing -> BundleAnalytics(state.options.analyticsProperties).successfully()
            else -> Result.Success(null)
        }
    }

    /**
     * Call this method when everything in the UI is prepared for the user to start listening.
     * This includes all the UI being rendered, the content being presented to the user, and the play
     * button being operational.
     * Safe to be called multiple times, the first call will send the Telemetry event.
     */
    fun reportUIReadyToListen() = dependencies.scope.launch {
        val shouldReport = reportedReadyToListen.compareAndSet(expect = false, set = true) &&
            bundleMetadata?.disableTelemetryOfCreateToUIReady != true
        if (!shouldReport) return@launch
        val bundlingSourceTelemetryEvent = bundlingSourceTelemetryEventBuilder?.build()
        val bundlingStartTime = bundlingSourceTelemetryEvent?.startTime ?: return@launch
        val timeReady = nowInMillisecondsFromEpoch()
        val telemetryEventBuilder = bundlingSourceTelemetryEvent.builderWithPrefixedProperties(
            message = "Latency.ReadingBundleCreationAndUIRendering",
            prefix = "bundling",
        )
            .setStartAndEndtime(bundlingStartTime, timeReady)
        SpeechifySDKTelemetry.report(telemetryEventBuilder.build())

        BundlingToPlaybackLatencyTelemetryTracker.currentBundlingToPlaybackLatencyTelemetryTracker()
            ?.setTimeWhenUIWasReady(timeReady)
    }.ignoreValue()

    private fun destroyPredecessorBundle() {
        predecessorBundle?.destroy()
    }

    override fun destroy() =
        /* Use `launchTask` and not the `dependencies.scope.launch` here, since we will be destroying that one, and it could
         * cause a cancel to any async tasks and make this routine not perform all the actions it intends. */
        launchTask {
            withContext(NonCancellable) {
                try {
                    /* Destroy the predecessor bundles (if it exists, for example: an edited book, which has a "predecessor" bundle
                     on which the edited bundle is based), before destroying this bundle */
                    destroyPredecessorBundle()
                    /* Stop any coroutines first, so that they get normal [CancellationExceptions], and not
                       unexpected behavior of using destroyed objects (equivalent of a `finally` block).
                       TODO - to achieve full cooperation, try `dependencies.scope.coroutineContext.job.cancelAndJoin`
                        (would need to test - the cancellation should not lead to a hang - could also add logging to detect hangs) */
                    dependencies.scope.cancel("ReadingBundle destroyed")

                    // TODO - the playback should ideally be using a single [CoroutineScope], and thus be cancelled by the above
                    // Start with stopping playback, as this is what the user especially requires.
                    // First the processes that depend on playback
                    listeningProgressTracker.destroy()
                    playbackControls.destroy()
                    // Then the playback itself
                    listeningBundle.destroyPlaybackLeaveContent()

                    /* We want to now destroy the content, but there may be background processes that we don't want to abort,
                       so wait for them.
                     */

                    /* Wait for imports to finish, as the current contract is for imports not to be aborted here (consider a
                       separate API for SDK consumers explicitly requesting that, e.g. a flag here or separate methods). */
                    contentImporter.stateFlow.first { it !is ContentImporterState.Importing }
                    /* Then finally destroy of the ContentBundle (the content is no longer expected to be in a working state,
                       because nothing should be using it).
                    */
                } finally {
                    content.destroy()
                }
            }
        }
            .ignoreValue()
}
