package com.speechify.client.internal.services.library

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.FirebaseDynamicLinksConfig
import com.speechify.client.api.adapters.firebase.Collections
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.parse
import com.speechify.client.internal.services.library.models.PlatformAndroidInfo
import com.speechify.client.internal.services.library.models.PlatformDynamicLinkInfoBody
import com.speechify.client.internal.services.library.models.PlatformFirebaseDirectLinksBody
import com.speechify.client.internal.services.library.models.PlatformIosInfo
import com.speechify.client.internal.services.library.models.PlatformSocialMetaTagInfo
import com.speechify.client.internal.services.library.models.ShortLinkResponse
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

internal class PlatformShareService(
    private val client: HttpClient,
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val clientConfig: ClientConfig,
) {
    internal suspend fun startSharing(file: LibraryItem.Content): Result<String> {
        val fileItemId = file.uri.id
        val shareLink = "${clientConfig.speechifyWebAppUrl}/share/$fileItemId"

        val response = client.post(
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
        ) {
            parameter("key", clientConfig.firebaseDynamicLinksConfig.apiKey)

            bodyJson(
                createPlatformFirebaseDirectLinksBody(
                    shareLink,
                    clientConfig.firebaseDynamicLinksConfig,
                    file,
                ),
            )
        }.parse<ShortLinkResponse>().orReturn { return it }

        firebaseFirestoreService.coUpdateDocument(
            Collections.ITEMS.collectionRef,
            fileItemId,
            SdkBoundaryMap.of(
                "isShared" to true,
            ),
        ).orReturn { return it }

        return response.shortLink.successfully()
    }

    internal suspend fun stopSharing(fileItemId: String): Result<Unit> {
        /* handle the case of when an item was shared using the v1 approach, which entailed
         * copying an item to the `sharedItems` collection */
        val sharedItemIdsResult = suspendCoroutine { cont ->
            getSharedItemIds(
                fileItemId,
                cont::resume,
            )
        }
        if (sharedItemIdsResult is Result.Success && sharedItemIdsResult.value.isNotEmpty()) {
            for (id in sharedItemIdsResult.value) {
                suspendCoroutine { cont ->
                    firebaseFirestoreService.deleteDocument(
                        Collections.SHARED_ITEMS.collectionRef,
                        id,
                        cont::resume,
                    )
                }
            }
        } else {
            // ignored as this file was not shared v1
        }

        ensureFirestoreDocExists(Collections.ITEMS.collectionRef, fileItemId).orReturn { return it }
        return firebaseFirestoreService.coUpdateDocument(
            Collections.ITEMS.collectionRef,
            fileItemId,
            SdkBoundaryMap.of(
                "isShared" to false,
            ),
        )
    }

    internal suspend fun isSharedItemV1(sharedItemId: String): Result<Unit> {
        return ensureFirestoreDocExists(Collections.SHARED_ITEMS.collectionRef, sharedItemId)
    }

    private fun getSharedItemIds(
        originalFileItemId: String,
        callback: Callback<List<String>>,
    ) {
        firebaseFirestoreService.queryDocuments(
            Collections.SHARED_ITEMS.collectionRef,
        ).where("originalCopy", DocumentQueryBuilder.Operator.EQ, originalFileItemId)
            .fetch { docsResult ->
                when (docsResult) {
                    is Result.Success -> {
                        callback(
                            docsResult.value.asSequence()
                                .filterIsInstance<FirebaseFirestoreDocumentSnapshot.Exists>()
                                .map { d -> d.key }
                                .toList()
                                .successfully(),
                        )
                    }
                    is Result.Failure -> callback(docsResult)
                }
            }
    }

    private suspend fun ensureFirestoreDocExists(
        collectionRef: String,
        fileItemId: String,
    ): Result<Unit> {
        val itemDoc = firebaseFirestoreService.coGetDocument(
            collectionRef,
            fileItemId,
        ).orReturn { return it }

        if (!itemDoc.exists) {
            return Result.Failure(
                SDKError.ResourceNotFound(
                    fileItemId,
                    "Cannot be found in the $collectionRef collection",
                ),
            )
        }

        return Unit.successfully()
    }

    private fun createPlatformFirebaseDirectLinksBody(
        shareLink: String,
        firebaseDynamicLinksConfig: FirebaseDynamicLinksConfig,
        file: LibraryItem.Content,
    ): PlatformFirebaseDirectLinksBody {
        // If the cover image is a Base64-encoded image, the data is too long for Firebase Dynamic Links
        fun safeCoverImageUrl(url: String?) = if (url.isNullOrEmpty() || url.startsWith("data:")) null else url

        return PlatformFirebaseDirectLinksBody(
            PlatformDynamicLinkInfoBody(
                firebaseDynamicLinksConfig.domainUriPrefix,
                shareLink,
                PlatformAndroidInfo(firebaseDynamicLinksConfig.androidPackageName, null),
                // hardcoding the App Store ID here since all platforms will need this for share links to work
                // on iOS correctly, see discussion: https://speechifyworkspace.slack.com/archives/C01U61Q67UM/p1691747066143839
                PlatformIosInfo(firebaseDynamicLinksConfig.iosBundleId, null, "1209815023"),
                PlatformSocialMetaTagInfo(file.title, file.excerpt, safeCoverImageUrl(file.coverImageUrl)),
            ),
        )
    }
}
