package com.speechify.client.internal.coroutines.fromNonCancellableAPIs

import com.speechify.client.api.util.Result
import com.speechify.client.api.util.toKotlinResult

/**
 * A version of [suspendCancellableCoroutineForNonCancellableAPIByDetach] useful for SDK-[Result] style APIs, where
 * it needs to be translated to a `suspend` function that is meant to throw for [Result.Failure] (and thus be a Kotlin
 * idiomatic function with only the [T] as its return type).
 *
 * NOTE: [Result.Failure]s will be translated to exceptions and thrown.
 *
 * @throws [Throwable] if the SDK result is a [Result.Failure].
 */
internal suspend fun <T> suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing(
    /**
     * An extra action for when the cancellation leaves the Job running.
     * Useful for reporting wasted resources to developers.
     */
    onCancellationLeavingJobRunning: () -> Unit = {},
    /**
     * Same semantics as [kotlinx.coroutines.suspendCancellableCoroutine]'s parameter, just a better name than `block`:)
     */
    connectToResume: (SdkResultContinuation<T>) -> Unit,
): T =
    suspendCancellableCoroutineForNonCancellableAPIByDetach(
        onCancellationLeavingJobRunning = onCancellationLeavingJobRunning,
        connectToResume = { nativeContinuation ->
            connectToResume(
                SdkResultContinuation { sdkResult ->
                    nativeContinuation.resumeWith(
                        sdkResult.toKotlinResult(),
                    )
                },
            )
        },
    )
