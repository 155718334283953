package com.speechify.client.api.services.personalvoice

import com.speechify.client.api.audio.VoiceMetadata
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.parse
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.util.extensions.strings.nullIfEmpty
import kotlin.js.JsExport

@JsExport
class PersonalVoiceService internal constructor(
    private val authService: AuthService,
    private val httpClient: HttpClient,
    private val platformVoicesServiceUrl: String,
) {

    /**
     * Gets the list of personal voices accessible to the currently logged-in user
     */
    fun getVoices(callback: Callback<Array<PersonalVoice>>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "PersonalVoiceService.getVoices",
    ) {
        getVoices().successfully()
    }

    private suspend fun getVoices(): Array<PersonalVoice> {
        val token = authService.getCurrentUserIdentityToken().orThrow().token
        val response = httpClient.get("$platformVoicesServiceUrl/v0/personal-voices") {
            header("Authorization", "Bearer $token")
        }.orThrow()
        val voices = response.parse<HttpGetPersonalVoiceListResponseBody>().orThrow()
        return voices.map { it.toPersonalVoice() }.toTypedArray()
    }

    /**
     * Initiates creation of a personal voice
     */
    fun createVoice(
        request: CreatePersonalVoiceRequest,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<Unit>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "PersonalVoiceService.createVoice",
    ) {
        createVoice(request).successfully()
    }

    private suspend fun createVoice(
        request: CreatePersonalVoiceRequest,
    ) {
        val token = authService.getCurrentUserIdentityToken().orThrow().token
        val body = HttpCreatePersonalVoiceRequest(
            sampleUrl = request.sampleUrl,
            avatarUrl = request.avatarUrl,
            meta = HttpCreatePersonalVoiceMeta(
                displayName = request.displayName,
                locale = request.languageCode,
                gender = request.gender.name.lowercase(),
            ),
            source = request.source.nullIfEmpty() ?: "recording",
            consent = request.consent,
        )
        httpClient.post("$platformVoicesServiceUrl/v0/voices") {
            header("Authorization", "Bearer $token")
            bodyJson(body)
        }.parse<HttpPersonalVoice>().orThrow()
    }

    /**
     * Deletes a personal voice
     */
    fun deleteVoice(
        voiceId: String,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<Unit>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "PersonalVoiceService.deleteVoice",
    ) {
        deleteVoice(voiceId).successfully()
    }

    private suspend fun deleteVoice(voiceId: String) {
        val token = authService.getCurrentUserIdentityToken().orThrow().token
        httpClient.delete("$platformVoicesServiceUrl/v0/voices/$voiceId") {
            header("Authorization", "Bearer $token")
        }.parse<HttpDeletePersonalVoiceResponse>().orThrow()
    }

    /**
     * Gets a list of Personal Voices in the form of [VoiceSpec]'s that are ready to be plugged into be listened to
     */
    fun getPersonalVoiceSpecs(
        callback: Callback<Array<VoiceSpecOfAvailableVoice>>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "PersonalVoiceService.getPersonalVoiceSpecs",
    ) {
        getPersonalVoiceSpecs().successfully()
    }

    private suspend fun getPersonalVoiceSpecs(): Array<VoiceSpecOfAvailableVoice> {
        val personalVoices = getVoices()
        return personalVoices.map { it.toVoiceSpec() }.toTypedArray()
    }

    /**
     * Deletes a personal voice given a [VoiceMetadata].
     *
     * This exists to enable client integration patterns in which the interaction to delete a personal voice is afforded
     * from a Voice Menu that directly presents Voices obtained from the obtained from the bundle.
     *
     * While awkward, this integration pattern is difficult to avoid because picking a voice amounts to calling
     * `PlaybackControls.setVoice`, which requires a Voice, which can only be obtained from the bundle.
     *
     * If `PlaybackControls.setVoice` accepted a `VoiceSpec` instead, clients would have
     */
    fun deleteVoiceFromVoiceMetadata(voiceMetadata: VoiceMetadata, callback: Callback<Unit>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "PersonalVoiceService.deleteVoiceFromVoiceMetadata",
        ) {
            deleteVoiceFromVoiceMetadata(voiceMetadata).successfully()
        }

    private suspend fun deleteVoiceFromVoiceMetadata(voiceMetadata: VoiceMetadata) {
        val spec = voiceMetadata.spec
        require(spec is VoiceSpec.Speechify)
        require(spec.name.startsWith("PVL:"))
        val personalVoiceId = spec.name.substringAfter("PVL:")
        deleteVoice(personalVoiceId)
    }
}

@JsExport
fun PersonalVoice.toVoiceSpec(): VoiceSpecOfAvailableVoice {
    // Contract stated by Tyler here https://speechifyworkspace.slack.com/archives/C02U3MSCW4E/p1691784933614569
    val name = "PVL:${this.id}"
    return VoiceSpec.Speechify(
        displayName = this.displayName,
        gender = this.gender,
        languageCode = this.languageCode,
        name = name,
        avatarUrl = this.avatarUrl,
    )
}
