package com.speechify.client.api.adapters.pdf

import kotlin.js.JsExport

@JsExport

/**
 * Describes a PDF outline with a list of entries.
 *
 * @property entries An array of [Entry] objects that define the outline structure.
 */
data class PDFOutline(val entries: Array<Entry>) {

    /**
     * Represents an individual entry in the PDF outline.
     *
     * @property title The title of the entry.
     * @property hierarchyLevel The hierarchy level of this entry in the outline structure.
     * This value should start from 1, where 1 represents the top-level entry in the hierarchy.
     * @property attributes The attributes associated with this entry, such as target page index.
     */
    data class Entry(
        val title: String,
        val hierarchyLevel: Int,
        val attributes: Attributes,
    ) {
        /**
         * Represents the attributes of an entry in the PDF outline.
         *
         * @property targetPageIndex The index of the target page for this entry.
         */
        data class Attributes(
            val targetPageIndex: Int,
        )
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PDFOutline

        return entries.contentEquals(other.entries)
    }

    override fun hashCode(): Int {
        return entries.contentHashCode()
    }
}
