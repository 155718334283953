@file:OptIn(ExperimentalTime::class)

package com.speechify.client.internal.util.time

import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract
import kotlin.time.ComparableTimeMark
import kotlin.time.Duration
import kotlin.time.ExperimentalTime
import kotlin.time.TimeSource

internal class ItemWithPreviousConsumptionMeasurement<T>(
    val currentItem: T,
    /**
     * `null` if this is the first item in the flow.
     */
    val previousItemWithConsumptionMeasurement: TimedValueWithStartAndEnd<T>?,
)

/**
 * A version of [kotlin.time.TimedValue] that, apart from [kotlin.time.TimedValue.duration] also contains information
 * about the time of start and end.
 */
internal class TimedValueWithStartAndEnd<out T>(
    val value: T,
    val durationWithStartAndEndTime: DurationWithStartAndEndTime<T>,
)

internal class DurationWithStartAndEndTime<out T>(
    val startTimeMark: ComparableTimeMark,
    val duration: Duration,
) {
    val endTimeMark: ComparableTimeMark
        get() = startTimeMark + duration
}

/**
 * A version of [kotlin.time.measureTimedValue] that also contains information about the time of the event.
 */
@OptIn(ExperimentalContracts::class, ExperimentalTime::class)
internal inline fun <T> measureTimedValueWithStartAndEnd(
    block: () -> T,
): TimedValueWithStartAndEnd<T> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    val startTimeMark = TimeSource.Monotonic.markNow()
    val result = block()
    val duration = startTimeMark.elapsedNow()
    return TimedValueWithStartAndEnd(
        value = result,
        durationWithStartAndEndTime = DurationWithStartAndEndTime(
            startTimeMark = startTimeMark,
            duration = duration,
        ),
    )
}
