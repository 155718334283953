package com.speechify.client.api

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.account.AccountSettingsService
import com.speechify.client.api.services.adoption.EcosystemAdoptionService
import com.speechify.client.api.services.audio.AudioService
import com.speechify.client.api.services.audiobook.AudiobookLibraryService
import com.speechify.client.api.services.ebook.EbookService
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.LibraryService
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.api.services.logging.LoggingService
import com.speechify.client.api.services.personalvoice.PersonalVoiceService
import com.speechify.client.api.services.scannedbook.ScannedBookService
import com.speechify.client.api.services.subscription.SubscriptionService
import com.speechify.client.api.telemetry.SpeechifySDKTelemetry
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.DeviceResourceManager
import com.speechify.client.bundlers.BundlerFactory
import com.speechify.client.bundlers.BundlerFactoryConfig
import com.speechify.client.bundlers.BundlerPlugins
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.ReportUserChangesToPaymentServerService
import com.speechify.client.internal.services.book.PlatformBookPageContentStatsService
import com.speechify.client.internal.services.book.PlatformMLParsedBookPageService
import com.speechify.client.internal.services.db.DbService
import com.speechify.client.internal.services.editing.BookEditingService
import com.speechify.client.internal.services.epub.EpubChapterContentStatsService
import com.speechify.client.internal.services.highlight.UserHighlightsService
import com.speechify.client.internal.services.ml.MLPageParsingWithRemoteOCRService
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.userDocumentSettings.UserProfileService
import com.speechify.client.internal.time.DateTime
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlin.js.JsExport

@JsExport
class SpeechifyClient internal constructor(
    override val clientConfig: ClientConfig,
    override val adaptersProvider: AdaptersProvider,
    override val audiobookLibraryService: AudiobookLibraryService,
    override val subscriptionService: SubscriptionService,
    override val importService: ImportService,
    override val libraryService: LibraryService,
    override val offlineAvailabilityManager: OfflineAvailabilityManager,
    override val scannedBookService: ScannedBookService,
    override val personalVoiceService: PersonalVoiceService,
    override val accountSettingsService: AccountSettingsService,
    override val loggingService: LoggingService,
    internal val audioService: AudioService,
    internal val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
    private val reportUserChangesToPaymentServerService: ReportUserChangesToPaymentServerService,
    internal val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    override val ecosystemAdoptionService: EcosystemAdoptionService,
    internal val bookEditingService: BookEditingService,
    internal val userHighlightsService: UserHighlightsService,
    internal val platformScannedBookService: PlatformScannedBookService,
    internal val dbService: DbService,
    internal val platformMLParsedBookPageService: PlatformMLParsedBookPageService,
    override val deviceResourceManager: DeviceResourceManager,
    internal val ebookService: EbookService,
    internal val mlPageParsingWithRemoteOCRService: MLPageParsingWithRemoteOCRService,
    internal val platformBookPageContentStatsService: PlatformBookPageContentStatsService,
    internal val epubChapterContentStatsService: EpubChapterContentStatsService,
    internal val userProfileService: UserProfileService,
) : SpeechifyClientServices, Destructible {

    private val offlineStatusUpdaterFromImportFinish =
        OfflineStatusUpdaterFromImportFinish(importService, offlineAvailabilityManager)

    override fun destroy() {
        DateTime
        subscriptionService.destroy()
        reportUserChangesToPaymentServerService.destroy()
        ecosystemAdoptionService.destroy()
        offlineAvailabilityManager.destroy()
        SpeechifySDKTelemetry.unsetClientDependencies()
        offlineStatusUpdaterFromImportFinish.destroy()
    }

    /*
        Creates a [BundlerFactory]
     */
    fun createBundlerFactory(
        bundlerFactoryConfig: BundlerFactoryConfig,
        plugins: (BundlerPlugins) -> BundlerPlugins = { it },
    ): BundlerFactory =
        BundlerFactory(
            speechifyClient = this,
            bundlerPlugins = createDefaultPlugins(
                bundlerFactoryConfig = bundlerFactoryConfig,
                transformPlugins = plugins,
            ),
            bundlerFactoryConfig = bundlerFactoryConfig,
            eventsTrackerAdapter = adaptersProvider.eventsTrackerAdapter,
        )

    internal fun createDefaultPlugins(
        bundlerFactoryConfig: BundlerFactoryConfig,
        transformPlugins: (BundlerPlugins) -> BundlerPlugins = { it },
    ): BundlerPlugins =
        BundlerPlugins.defaultPluginsFromClient(this, bundlerFactoryConfig)
            .let(transformPlugins)

    /**
     * Responsible for making sure that the availability status stays in sync as the user performs imports.
     */
    class OfflineStatusUpdaterFromImportFinish(
        importService: ImportService,
        private val offlineAvailabilityManager: OfflineAvailabilityManager,
    ) : WithScope() {

        init {
            importService
                .flowOfFinishedImportEvents
                .onEach {
                    try {
                        offlineAvailabilityManager.refreshOfflineAvailabilityStatusIfCached(it.uri)
                    } catch (e: Throwable) {
                        Log.e(
                            DiagnosticEvent(
                                "Failed to refresh offline availability status in response to import finishing.",
                                sourceAreaId = "OfflineStatusUpdaterFromImportFinish.flowCollection",
                                nativeError = e,
                            ),
                        )
                    }
                }
                .launchIn(this.scope)
        }
    }
}
