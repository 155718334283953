package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentStats
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.emptyFlow

internal class SpeechifyBookIndex(standardView: EpubStandardViewV2) : BaseEpubV2ContentIndex(standardView) {
    override val contentStatsFlow = emptyFlow<ContentStats>()
    override val contentAmountStateFlow: ExternalStateChangesFlow get() = MutableStateFlow(Unit)

    override suspend fun getStatsIncludingPending() = null
}
