package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.toRawOrderedTextItems
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.bundlers.reading.importing.ContentPostImportActionsManager
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.services.book.FirestorePageContentStatsModel
import com.speechify.client.internal.services.book.PlatformBookPageContentStatsService
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.collections.median
import com.speechify.client.internal.util.text.groupingToWords.wordCount
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.shareIn
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext

private const val MIN_PAGES_TO_UPDATE_FIRESTORE_CONTENT_STATS = 6

/**
 * A new variant of [ApproximateBookIndexV1] that updates its estimate based on the book page parsing results that are
 * computed by the main listening.
 */
@OptIn(FlowPreview::class)
internal class ApproximateBookIndexV2(
    override val book: BookView,
    private val platformBookPageContentStatsService: PlatformBookPageContentStatsService,
    private val postImportActionsManager: ContentPostImportActionsManager,
) : BaseBookContentIndex(book) {

    private val firestoreContentStatsCacheOrNull = coLazy {
        when (val importState = postImportActionsManager.state) {
            is ContentImporterState.ImportedToLibrary -> {
                platformBookPageContentStatsService.getFirestoreContentStatsOrNull(importState.uri.id)
            }

            else -> null
        }
    }

    private val initialFirestoreContentStatsListCache = coLazy {
        when (val importState = postImportActionsManager.state) {
            is ContentImporterState.ImportedToLibrary -> {
                platformBookPageContentStatsService.getAllContentStatsPages(bookId = importState.uri.id)
                    .map { it.toMutableList() }
                    .orDefaultWith { mutableListOf() }
            }

            else -> mutableListOf()
        }
    }

    private val updatedContentStatsPagesFlow = flow {
        val initialFirestoreContentStatsPages = initialFirestoreContentStatsListCache.get()
        if (initialFirestoreContentStatsPages.isNotEmpty()) {
            emit(initialFirestoreContentStatsPages)
        }
        book.parsedPageContentFlow.collect {
            val (bookPageIndex, parsedPageContent) = it
            if (initialFirestoreContentStatsPages.none { it.bookPageIndex == bookPageIndex }) {

                val rawTextContentItems = parsedPageContent.toRawOrderedTextItems()
                val pageContentStats = FirestorePageContentStatsModel(
                    bookPageIndex = bookPageIndex,
                    wordCount = rawTextContentItems.sumOf { it.text.text.wordCount() },
                    charCount = rawTextContentItems.sumOf { it.text.text.length },
                )

                initialFirestoreContentStatsPages.add(pageContentStats)
                // Add the new content stats page asynchronously to not block the updatedContentStatsPagesFlow collectors.
                launchTask {
                    addContentStatsPageToFirestore(pageContentStats)
                }
            }
            // If: no content reached yet AND we didn't parse all the pages yet, we don't emit.
            // This is helpful for books with blank pages at the start.
            val shouldEmit = initialFirestoreContentStatsPages.size >= book.getMetadata().numberOfPages ||
                initialFirestoreContentStatsPages.sumOf { it.wordCount } > 0
            if (shouldEmit) {
                emit(initialFirestoreContentStatsPages)
            }
        }
    }.shareIn(scope, started = SharingStarted.Lazily)

    init {
        scope.launch {
            /**
             * The value take = 2 is used here because the [contentStatsFlow] is configured to emit ContentStats
             * changes only twice (This ensures that we account for the initial parsed pages content
             * when the flow is launched).
             *
             * With this configuration, the content stats will not be updated incrementally in real-time.
             * Instead, updates will occur upon each relaunch if new content stats are computed.
             *
             * Note: This decision was made by PM under this thread: [https://speechifyworkspace.slack.com/archives/C06CXHFT8LE/p1722449107138969?thread_ts=1721998894.325289&cid=C06CXHFT8LE]
             */
            updatedContentStatsPagesFlow.take(2).collect {
                val firebaseContentStats = firestoreContentStatsCacheOrNull.get()
                // Emit static content stats if they exist in firestore.
                if (firebaseContentStats != null) {
                    contentStatsFlow.emit(firebaseContentStats)
                } else {
                    contentStatsFlow.emit(it.toContentStats(book.getMetadata().numberOfPages))
                }
            }
        }

        // Moved [saving-to-firestore] code to another coroutine for code clarity and single responsibility.
        scope.launch {
            // Used debounce here to reduce unnecessary updates to firestore.
            updatedContentStatsPagesFlow.debounce(2000).collectLatest {
                val totalNumberOfPages = book.getMetadata().numberOfPages

                // Save to firestore in case: Parsed enough pages OR already parsed all the pages.
                val shouldUpdateFirestoreContentStats = it.size >= MIN_PAGES_TO_UPDATE_FIRESTORE_CONTENT_STATS ||
                    it.size == totalNumberOfPages
                if (shouldUpdateFirestoreContentStats) {
                    updateFirestoreTextualCount(it.toContentStats(totalNumberOfPages))
                }
            }
        }
    }

    // Source of truth of the [ContentStats] - replay to 1 to emit the content stats for the initial pages.
    override val contentStatsFlow = MutableSharedFlow<ContentStats>(replay = 1)

    // Returns null because [ApproximateBookIndexV2] doesn't provide content stats based on fixed number of pages.
    override suspend fun getStatsIncludingPending(): ContentStats? = null

    private suspend fun addContentStatsPageToFirestore(pageContentStats: FirestorePageContentStatsModel) =
        // Adding content stats page to firestore, even if the scope job is canceled.
        withContext(NonCancellable) {
            when (val importState = postImportActionsManager.state) {
                is ContentImporterState.ImportedToLibrary -> {
                    platformBookPageContentStatsService.addContentStatsPage(
                        bookId = importState.uri.id,
                        pageContentStats = pageContentStats,
                    ).orReturn { return@withContext }
                }

                else -> {
                    postImportActionsManager.queueTaskAfterImport {
                        platformBookPageContentStatsService.addContentStatsPage(
                            bookId = it.id,
                            pageContentStats = pageContentStats,
                        )
                    }
                }
            }
        }

    private suspend fun updateFirestoreTextualCount(contentStats: ContentStats) =
        // update the firebase textualCount, even if the scope job is canceled.
        withContext(NonCancellable) {
            when (val importState = postImportActionsManager.state) {
                is ContentImporterState.ImportedToLibrary -> {
                    platformBookPageContentStatsService.updateFirebaseContentStats(
                        bookId = importState.uri.id,
                        wordCount = contentStats.estimatedWordCount.count,
                        charCount = contentStats.estimatedCharCount.count,
                    )
                }

                else -> {
                    postImportActionsManager.queueTaskAfterImport {
                        platformBookPageContentStatsService.updateFirebaseContentStats(
                            bookId = it.id,
                            wordCount = contentStats.estimatedWordCount.count,
                            charCount = contentStats.estimatedCharCount.count,
                        )
                    }
                }
            }
        }
}

/**
 * Calculates the content stats based on the word and char count of page.
 */
private fun List<FirestorePageContentStatsModel>.toContentStats(totalNumberOfPages: Int) =
    if (totalNumberOfPages <= size) {
        ContentStats(
            estimatedWordCount = EstimatedCount(count = sumOf { it.wordCount }, confidence = 1.0),
            estimatedCharCount = EstimatedCount(count = sumOf { it.charCount }, confidence = 1.0),
        )
    } else {
        val confidence = size.toDouble() / totalNumberOfPages
        val (wordCountSum, charCountSum) = fold(0 to 0) { (wordSum, charSum), page ->
            wordSum + page.wordCount to charSum + page.charCount
        }

        val medianWordCountValue = map { it.wordCount }.median()
        val medianCharCountValue = map { it.charCount }.median()

        val unreachedPagesCount = totalNumberOfPages - size
        ContentStats(
            estimatedWordCount = EstimatedCount(
                count = wordCountSum + unreachedPagesCount * medianWordCountValue,
                confidence = confidence,
            ),
            estimatedCharCount = EstimatedCount(
                count = charCountSum + unreachedPagesCount * medianCharCountValue,
                confidence = confidence,
            ),
        )
    }
