package com.speechify.client.internal.time

import kotlinx.serialization.Serializable
import kotlin.js.Date

@Serializable(with = DateTimeSerializer::class)
actual class DateTime private constructor(private val date: Date) : Comparable<DateTime> {
    actual constructor(
        year: Int,
        month: Month,
        day: Int,
        hour: Int,
        minute: Int,
        second: Int,
        milliseconds: Milliseconds,
    ) : this(
        Date(Date.UTC(year, month.ordinal, day, hour, minute, second, milliseconds)),
    )

    actual companion object {
        actual val EPOCH: DateTime = fromMilliseconds(0.0)
        actual fun now(): DateTime = DateTime(Date())
        actual fun fromSeconds(s: Seconds): DateTime = fromMilliseconds(s * 1000L)
        actual fun fromSeconds(s: Double): DateTime = fromMilliseconds(s * 1000)
        actual fun fromMilliseconds(s: Milliseconds): DateTime = DateTime(Date(s))
        actual fun fromMilliseconds(s: Double): DateTime = DateTime(Date(s))
        actual fun fromIsoString(s: String): DateTime = DateTime(Date(s))
        actual val MAX = fromMilliseconds(Long.MAX_VALUE)
    }

    override fun compareTo(other: DateTime): Int = date.getTime().compareTo(other.date.getTime())

    actual operator fun plus(d: Duration): DateTime =
        DateTime(Date(asMillisecondsLong() + d.inWholeMilliseconds))

    actual operator fun minus(d: Duration): DateTime =
        DateTime(Date(asMillisecondsLong() - d.inWholeMilliseconds))

    actual fun asMillisecondsLong(): Milliseconds = date.getTime().toLong()
    actual fun asMillisecondsDouble(): Double = date.getTime()
    actual fun asSeconds(): Seconds = (asMillisecondsLong() / 1000L).toInt()
    actual fun toIsoString(): String = date.toISOString()

    actual val year: Int get() = date.getUTCFullYear()
    actual val month: Month get() = Month.fromOrdinal(date.getUTCMonth())!!
    actual val day: Int get() = date.getUTCDate()
    actual val hour: Int = date.getUTCHours()
    actual val minute: Int get() = date.getUTCMinutes()
    actual val second: Int get() = date.getUTCSeconds()
    actual val millisecond: Milliseconds get() = date.getUTCMilliseconds().toLong()

    actual override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as DateTime

        return asSeconds() == other.asSeconds()
    }

    actual override fun hashCode(): Int {
        var result = date.hashCode()
        result = 31 * result + hour
        return result
    }

    actual override fun toString(): String {
        return "DateTime(year=$year, month=$month, day=$day, hour=$hour, minute=$minute," +
            " second=$second | milis: ${asMillisecondsLong()})"
    }
}
