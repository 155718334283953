package com.speechify.client.api.adapters.blobstorage

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentially
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPIFromFlow
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.coGetBytes
import com.speechify.client.api.util.io.toFile
import com.speechify.client.api.util.successfully
import kotlinx.coroutines.flow.map

internal class BlobStorageAdapterWithEncryption(
    private val blobStorageAdapter: BlobStorageAdapter,
    private val encoderForLocalPersistence: ChunkableEncoder,
) : BlobStorageAdapter() {
    /**
     * Prefix the blob with the encoder's ID, so that if we change encoders, we won't risk discovering files encoded
     * with a different encoder, which would decode to garbage.
     */
    private fun BlobStorageKey.withEncoderVersionInfo(): BlobStorageKey =
        withEncoderVersionInfo(encoderForLocalPersistence.id)

    internal companion object {
        fun BlobStorageKey.withEncoderVersionInfo(encoderId: String): BlobStorageKey {
            return BlobStorageKey("$encoderId:$originalKey")
        }
    }

    @Deprecated("Use coGetBlob instead", replaceWith = ReplaceWith("coGetBlob(key)"))
    override suspend fun getBytes(
        key: BlobStorageKey,
    ): Result<BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>?> =
        blobStorageAdapter.coGetBlob(
            key = key.withEncoderVersionInfo(),
        )
            .orReturn { return it }
            ?.toFile()
            ?.decodeWith(encoderForLocalPersistence)
            .successfully()

    override fun getBlob(
        key: BlobStorageKey,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?>,
    ) =
        throw UnsupportedOperationException("Not supported for encrypted blob storage")

    override fun putBlobByMove(
        key: BlobStorageKey,
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException("Not supported for encrypted blob storage")

    override fun putBlob(
        key: BlobStorageKey,
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException("Not supported for encrypted blob storage")

    override fun putSequenceGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException("Not supported for encrypted blob storage. Need to use coPutBytes.")

    override suspend fun coPutBytesGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    ): Result<BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>> {
        val unencryptedContent = content.binaryContent
        val encryptedContent = BinaryContentReadableSequentiallyMultiplatformAPIFromFlow(
            sourceFlow = unencryptedContent
                .map { chunk ->
                    encoderForLocalPersistence.encodeChunk(chunk)
                },
        )

        val encryptedContentInStorage = blobStorageAdapter.coPutBytesGetReadableRandomly(
            key = key.withEncoderVersionInfo(),
            content = object :
                BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI> {
                override val binaryContent: BinaryContentReadableSequentiallyMultiplatformAPI = encryptedContent
                override val mimeType: MimeType?
                    get() = content.mimeType
            },
        )
            .orReturn { return it }

        return encryptedContentInStorage
            .decodeWith(encoderForLocalPersistence)
            .successfully()
    }

    override fun putBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
        callback: Callback<Unit>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coPutBytesGetReadableRandomly], which calls the wrapped instance */
        )

    internal override suspend fun coDeleteBlob(
        key: BlobStorageKey,
    ): Result<Boolean> = blobStorageAdapter.coDeleteBlob(key = key.withEncoderVersionInfo())

    override fun deleteBlob(
        key: BlobStorageKey,
        callback: Callback<Boolean>,
    ) = callback.fromCo {
        blobStorageAdapter.coDeleteBlob(
            key = key.withEncoderVersionInfo(),
        )
    }

    internal override suspend fun fileExists(key: BlobStorageKey): Boolean =
        blobStorageAdapter.fileExists(key.withEncoderVersionInfo())
}

/**
 * An encoder/decoder pair that can be used to encode/decode a file according to a scheme meeting the following criteria:
 * - data = decode(encode(data))
 * - length(data) = length(encode(data))
 * - encode(concat(slices...)) = concat(slices.map(encode)) for any slices... covering the entire data
 *
 * This restricts us to simple schemes, but this is sufficient for our purposes to protect Audiobook assets.
 */
internal interface ChunkableEncoder {
    val id: String
    fun encodeChunk(data: ByteArray): ByteArray
    fun decodeChunk(data: ByteArray): ByteArray
}

/**
 * Wraps this file in the [encoder], which decodes each slice of bytes upon request via [File.getBytes].
 */
internal fun BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>
.decodeWith(encoder: ChunkableEncoder):
    BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI> {
    return object : BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI> {

        override val binaryContent: BinaryContentReadableRandomlyMultiplatformAPI =
            object : BinaryContentReadableRandomlyMultiplatformAPI() {
                override fun getSizeInBytes(callback: Callback<Int>) =
                    this@decodeWith.binaryContent.getSizeInBytes(callback)
                override fun getBytes(startIndex: Int, endIndex: Int, callback: Callback<ByteArray>) = callback.fromCo {
                    this@decodeWith.binaryContent.coGetBytes(startIndex, endIndex).map { encoder.decodeChunk(it) }
                }
            }

        override val mimeType: MimeType?
            get() = this@decodeWith.mimeType
    }
}
