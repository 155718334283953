package com.speechify.client.internal.services.importing

import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.getAllBlocksAsFlow
import com.speechify.client.api.content.view.standard.getContentTextsSequence
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.internal.services.importing.models.ContentSummaryInfo
import com.speechify.client.internal.services.importing.models.TextualCount
import com.speechify.client.internal.util.text.groupingToWords.wordCount
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.flatMapConcat
import kotlinx.coroutines.flow.takeWhile

/**
 * Maximum length of the excerpt in characters.
 */
internal const val MAX_EXCERPT_LENGTH = 175

/**
 * Extracts the content summary info from the OCRFiles.
 */
internal fun Iterable<OCRFile>.getContentSummaryInfo(): ContentSummaryInfo {
    val textualCount = this.fold(TextualCount(0, 0)) { acc, ocrFile ->
        TextualCount(
            wordCount = acc.wordCount + ocrFile.ocrResult.rawText.wordCount(),
            charCount = acc.charCount + ocrFile.ocrResult.rawText.length,
        )
    }
    return ContentSummaryInfo(
        excerpt = this.getExcerpt(),
        textualCount = textualCount,
    )
}

/**
 * Extracts the content summary info from the ImportableContentPayload.
 */
internal suspend fun ImportableContentPayload.ImportableContentPayloadOfSingleBlob.getContentSummaryInfo():
    ContentSummaryInfo {
    return ContentSummaryInfo(
        excerpt = parsedContentsForImport.contentModels.await().standardView.getExcerpt(),
        textualCount = parsedContentsForImport.contentModels.await().contentIndex.getTextualCountOrNull(),
    )
}

@OptIn(ExperimentalCoroutinesApi::class)
private suspend fun StandardView.getExcerpt(): String {
    val excerptBuilder = StringBuilder()

    getAllBlocksAsFlow()
        .flatMapConcat { block ->
            block.getContentTextsSequence().asFlow()
        }
        .takeWhile { excerptBuilder.length < MAX_EXCERPT_LENGTH }
        .collect { contentText ->
            excerptBuilder.append(contentText.text).append(" ")
        }

    return excerptBuilder.toString().withEnsuredLengthOfExcerpt()
}

private suspend fun ContentIndex.getTextualCountOrNull(): TextualCount? {
    val stats = getStatsIncludingPending() ?: return null

    return TextualCount(
        wordCount = stats.estimatedWordCount.count,
        charCount = stats.estimatedCharCount.count,
    )
}

/**
 * Extracts an excerpt from plain string.
 */
private fun String.withEnsuredLengthOfExcerpt(): String {
    return this.trim().take(MAX_EXCERPT_LENGTH)
}

/**
 * Extracts an excerpt from OCRFile.
 */
private fun Iterable<OCRFile>.getExcerpt(): String? {
    return this.firstOrNull()?.ocrResult?.rawText?.replace(Regex("\r?\n"), " ")?.withEnsuredLengthOfExcerpt()
}
