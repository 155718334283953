package com.speechify.client.api.content.view.speech

import kotlin.js.JsExport

@JsExport
object SpeechQueryBuilder {
    fun fromBounds(start: CursorQuery, end: CursorQuery): SpeechQuery {
        return SpeechQuery(start, end)
    }

    fun fromSentenceAroundCursor(cursor: CursorQuery): SpeechQuery {
        return SpeechQuery(
            cursor.scanBackwardToSentenceStart(),
            cursor.scanForwardToSentenceEnd(),
        )
    }

    fun forEntireContent(): SpeechQuery {
        return SpeechQuery(
            CursorQueryBuilder.fromStart(),
            CursorQueryBuilder.fromEnd(),
        )
    }
}
