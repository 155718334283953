package com.speechify.client.api.content.pdf

import com.speechify.client.api.adapters.pdf.PDFPageAdapter
import com.speechify.client.api.adapters.pdf.PDFPageImageOptions
import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.adapters.pdf.coGetImage
import com.speechify.client.api.adapters.pdf.coGetTextBetween
import com.speechify.client.api.adapters.pdf.coGetTextContent
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageMetadata
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.TextSourceType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.book.BoundingBoxedText
import com.speechify.client.helpers.content.standard.book.ensureOrderOnPage
import com.speechify.client.helpers.content.standard.book.heuristics.v2.HeuristicsParserV2

/**
 * A [BookPageDelegate] backed by a PDF page.
 */
internal class PDFBookPage internal constructor(
    private val adapter: PDFPageAdapter,
    private val contentSortingStrategy: ContentSortingStrategy,
) : BookPageDelegate() {

    override val start: ContentCursor =
        ContentElementReference.forRoot().getChild(adapter.pageIndex).start
    override val end: ContentCursor =
        ContentElementReference.forRoot().getChild(adapter.pageIndex).end
    override val pageIndex: Int = adapter.pageIndex

    override fun getMetadata(): BookPageMetadata = BookPageMetadata(pageIndex, adapter.getMetadata().viewport)

    /**
     * Calls back with the image of the page. Images are cached per request [options] for performance.
     */
    override suspend fun getImage(options: BookPageRequestOptions):
        Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> =
        adapter.coGetImage(PDFPageImageOptions(options.scale))

    // internal for testing
    internal suspend fun getUnsortedRawTextContentItems(): Result<List<BookPageTextContentItem>> {
        val pageElement = ContentElementReference.forRoot().getChild(pageIndex)
        val metadata = getMetadata()
        val textContent = adapter.coGetTextContent().orReturn { return it }
        var prevIndex = 0
        val bookPageItemTextContentItems =
            textContent
                .mapTo(mutableListOf()) { BoundingBoxedText.fromPdf(it) }
                .let { mutableListOf(it) } // just here to replace columniseBook without disrupting surrounding code
                .map { column ->
                    column.asSequence().map { it.source }.fold(mutableListOf<ContentItem>()) { acc, item ->
                        val range = Pair(prevIndex, prevIndex + item.text.length)
                        val text = TextElementContentSlice(pageElement, range, item.text)
                        prevIndex = range.second
                        val bookPageItem = ContentItem(text, item.box, item.fontFamily)

                        acc.add(bookPageItem)
                        acc
                    }.map {
                        it.toBookPageContentItem(metadata.viewport)
                    }.toTypedArray()
                }

        return bookPageItemTextContentItems.first().toList().successfully()
    }

    override suspend fun getRawTextContentItems(): Result<List<BookPageTextContentItem>> {
        val finalTextContent = getUnsortedRawTextContentItems().orReturn { return it }
        val metadata = getMetadata()
        val textItemsOrdered =
            when (contentSortingStrategy) {
                ContentSortingStrategy.ExperimentalV1 -> ensureOrderOnPage(finalTextContent, metadata.viewport)
                ContentSortingStrategy.ExperimentalV2 ->
                    HeuristicsParserV2.sort(finalTextContent)

                ContentSortingStrategy.None -> finalTextContent
            }
        return textItemsOrdered.successfully()
    }

    override suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult {
        val metadata = getMetadata()
        return boxes.map { it.unnormalize(metadata.viewport) }.run {
            adapter
                .coGetTextBetween(
                    this,
                ).orThrow()
        }
    }

    override fun destroy() {
        adapter.destroy()
    }
}

private data class ContentItem(
    val text: TextElementContentSlice,
    val box: BoundingBox,
    val fontFamily: String?,
) {
    fun toBookPageContentItem(viewport: Viewport) =
        BookPageTextContentItem(
            text = text,
            box = box.normalize(viewport),
            fontFamily = fontFamily,
            textSourceType = TextSourceType.DIGITAL_TEXT,
            type = null,
        )
}
