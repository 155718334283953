package com.speechify.client.internal.webview

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import org.w3c.dom.MessageEvent
import org.w3c.dom.url.URL
import kotlin.coroutines.CoroutineContext

actual typealias NativeWebView = JSWebView

actual fun <T> runOnMainThread(context: CoroutineContext, block: () -> T): T {
    return block.invoke()
}

actual fun createResourceUrl(binaryContentReadableRandomly: BinaryContentReadableRandomly): String {
    return URL.createObjectURL(binaryContentReadableRandomly.blob)
}

actual fun getParentDirectoryPathFromFile(binaryContentReadableRandomly: BinaryContentReadableRandomly): String? {
    // on web we are using local blobs to represent embedded file resources.
    return null
}

@JsExport
class JSWebView(
    val iframe: dynamic,
    private val webViewListener: WebViewListener,
) : WebView() {

    init {
        val Speechify = object {
            @JsName("postMessage")
            fun postMessage(message: String) {
                iframe.contentWindow?.postMessage(message, "*")
            }
        }
        iframe.addEventListener("load") {
            iframe.contentWindow.Speechify = Speechify
            iframe.contentWindow.addEventListener("message") { event: MessageEvent ->
                (event.data as? String)?.run {
                    onJSMessageReceived?.invoke(this)
                }
            }
        }
    }

    override fun loadDataWithBaseURL(
        baseUrl: String?,
        data: String,
        mimeType: String?,
        encoding: String?,
        callback: Callback<Unit>,
    ) {
        iframe.srcdoc = data
        iframe.addEventListener("load") {
            callback.invoke(Result.Success(Unit))
        }
    }

    override fun evaluateJavaScript(script: String, callback: ((String?) -> Unit)?) {
        val iframeWindow = iframe.contentWindow
        val result = iframeWindow.eval(script)
        val resultAsJsonString = if (result) {
            JSON.stringify(result)
        } else {
            null
        }
        callback?.invoke(resultAsJsonString)
    }

    override fun destroy() {
        // no-op
    }
}
