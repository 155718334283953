package com.speechify.client.`internal`.sqldelight

import com.speechify.client.`internal`.services.db.DbBoolean
import com.speechify.client.`internal`.services.db.DbOcrFile
import com.speechify.client.`internal`.services.importing.models.ImportType
import com.speechify.client.`internal`.time.DateTime
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.util.MimeType
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.features.ListeningProgress
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public data class GetScannedPendingImports(
  public val speechifyUri: SpeechifyURI,
  public val primaryFileBlobStorageKey: BlobStorageKey?,
  public val scannedPages: List<DbOcrFile>,
  public val sourceURL: String?,
  public val importOptions: ImportOptions?,
  public val htmlContentLoadOptions: HtmlContentLoadOptions?,
  public val lastErrorStackTrace: String?,
  public val wasLastErrorConnectionError: DbBoolean?,
  public val importType: ImportType,
  public val attemptsPerformedCount: Int,
  public val owner: String,
  public val lastUpdatedAt: DateTime,
  public val listeningProgress: ListeningProgress?,
  public val mimeType: MimeType?,
)
