package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.coerceAtLeast
import com.speechify.client.api.content.coerceAtMost
import com.speechify.client.api.content.hasUniqueEndCursor
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.emptyStandardBlocksFrom
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.extensions.collections.allSublistsFromSingleFirstItemToAllItems
import com.speechify.client.internal.util.extensions.collections.flows.firstIfFulfillingOrLastOrNull
import com.speechify.client.internal.util.extensions.collections.flows.onFirst
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.dropWhile
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.takeWhile
import kotlinx.coroutines.flow.toList

internal interface StandardBlocksFlowProvider : ContentProvider {
    suspend fun getFlowStartingBeforeCursorUnlessNoContentBefore(
        cursor: ContentCursor,
    ): Flow<StandardBlock>
}

internal suspend fun StandardBlocksFlowProvider.getMinimalContentContainingCursor(
    cursor: ContentCursor,
): List<StandardBlock> =
    getFlowStartingBeforeCursorUnlessNoContentBefore(cursor)
        /* This is especially needed because we have 'pull' notifications to the upstream [onDemandPusher], and want
           to minimize stale content to maximize chance of picking up edits, so we don't want to push when the consumption
           doesn't really need a block.
        */
        .allSublistsFromSingleFirstItemToAllItems()
        .firstIfFulfillingOrLastOrNull { /* `first`, to minimize how much we pull, as each pull is expensive. */
            val last = it.last()
            when (it.size) {
                1 -> {
                    /* Don't go further than the first block, if this is a request for the [StandardView.start] cursor.
                     This is to maximize laziness - not pulling on content when absolutely not needed, as it may mean
                     stale content.*/
                    last.start.isAfter(cursor) ||
                        (
                            last.start.isEqual(cursor) && last.hasUniqueEndCursor() /* NOTE: But only allow `isEqual` in
                           the check, when there will be some different end cursor. This is needed especially because
                            #ContentTextEndCursorIsAtLastCharacter, which especially means that a one-character block
                            has the same `start` and `end` cursor. So we must return more, or else the consumer will not
                            have a new cursor to query forward.
                             */
                            )
                }

                2 -> {
                    last.hasUniqueEndCursor() /* Here as well, when the cursor is not
                     unique, we must skip and return third item, or else there will be no way to continue. */
                }

                else /* it.size == 3 */ -> {
                    /* When there are 3 items it means no smaller minimum numbers were found. With 3 we are however
                     unconditionally sure that we have unique cursors both in forward and backward direction (if this
                     wasn't the case, it would not be a sane `StandardBlocksFlowProvider` provider). */
                    true
                }
            }
        }
        ?: listOf()

internal suspend fun StandardBlocksFlowProvider.getBlocksBetweenCursors(
    start: ContentCursor,
    end: ContentCursor,
): StandardBlocks =
    getFlowStartingBeforeCursorUnlessNoContentBefore(start).takeWhile {
        it.start.isBeforeOrAt(end)
    }
        .toList()
        .let {
            StandardBlocks(
                blocks = it.toTypedArray(),
                start = it.firstOrNull()?.start?.coerceAtMost(start) ?: start,
                end = it.lastOrNull()?.end?.coerceAtLeast(end) ?: end,
            )
        }

internal class StandardBlocksFlowProviderFromFullContentFlow(
    private val flowFromStart: Flow<StandardBlock>,
    override val contentMutationsInfo: ContentMutationsInfo,
    contentSequenceCharacteristics: ContentSequenceCharacteristics,
) :
    StandardBlocksFlowProvider,
    ContentSequenceCharacteristics by contentSequenceCharacteristics {
    override suspend fun getFlowStartingBeforeCursorUnlessNoContentBefore(cursor: ContentCursor): Flow<StandardBlock> =
        flowFromStart.dropUntilBeforeCursorUnlessNoContentBefore(cursor)
}

internal fun Flow<StandardBlock>.dropUntilBeforeCursorUnlessNoContentBefore(
    cursor: ContentCursor,
): Flow<StandardBlock> = flow {
    var lastBlock: StandardBlock? = null

    this@dropUntilBeforeCursorUnlessNoContentBefore
        .dropWhile {
            if (it.end.isBefore(cursor)) {
                lastBlock = it /* We may still want this one because we need to support 'scanning back':
                 * if the next turns out to be starting precisely on the cursor.
                 * or everything is before the cursor.
                 */
                true
            } else {
                false
            }
        }
        .onFirst { firstBlock ->
            if (firstBlock.start.isEqual(cursor)) {
                ifNotNull(lastBlock) {
                    emit(it)
                }
            }
            lastBlock = null
        }
        .collect {
            emit(it)
        }

    ifNotNull(lastBlock) { /* This means there were no blocks after the cursor at all. Return the
        last one if there is anything in there, because it means all content was 'before' [cursor]. Likely this could
        mean the [cursor] is the [StandardView.end], and the content is merely before it. We need to return something,
        as per the requirement of [StandardView], e.g to support scanning backwards.
        */
        emit(it)
    }
}

internal abstract class StandardViewFromFlowBase(
    protected val referenceOfParentBlock: ContentElementReference,
    protected val standardBlocksFlowProvider: StandardBlocksFlowProvider,
) : StandardView {

    internal constructor(
        referenceOfParentBlock: ContentElementReference = ContentElementReference.forRoot(),
        fullContentFlow: Flow<StandardBlock>,
        contentSequenceCharacteristics: ContentSequenceCharacteristics,
        contentMutationsInfo: ContentMutationsInfo,
    ) : this(
        referenceOfParentBlock = referenceOfParentBlock,
        standardBlocksFlowProvider = StandardBlocksFlowProviderFromFullContentFlow(
            flowFromStart = fullContentFlow,
            contentMutationsInfo = contentMutationsInfo,
            contentSequenceCharacteristics = contentSequenceCharacteristics,
        ),
    )

    override fun getBlocksAroundCursor(cursor: ContentCursor, callback: Callback<StandardBlocks>) = callback.fromCo {
        val blocksFound = getBlocksAroundCursor(cursor)
        if (blocksFound.isNotEmpty()) {
            StandardBlocks(
                blocks = blocksFound.toTypedArray(),
                start = blocksFound.first().start
                    .coerceAtMost(cursor),
                /* TODO - not sure if `coerceAtMost` is needed, but sort-of makes sense - we
                                      want to tell that we searched all the way from cursor despite the first block being after it */
                end = blocksFound.last().end,
            )
                .successfully()
        } else {
            emptyStandardBlocksFrom(cursor)
                .successfully()
        }
    }

    protected abstract suspend fun getBlocksAroundCursor(cursor: ContentCursor): List<StandardBlock>

    override val start: ContentCursor
        get() = referenceOfParentBlock.start
    override val end: ContentCursor
        get() = referenceOfParentBlock.end

    override fun getBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
        callback: Callback<StandardBlocks>,
    ) = callback.fromCo {
        standardBlocksFlowProvider.getBlocksBetweenCursors(start, end).successfully()
    }
}
