package com.speechify.client.bundlers.content

import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.File

/**
 * An interface to a value that carries either:
 * - a [File]
 * - or a [BinaryContentReadableRandomly] with a [com.speechify.client.api.util.MimeType]' as a payload.
 *
 * It is implemented by the known [ListenableBinaryContentPayload], but this interface has no knowledge of the
 * semantic type, so can also be used via [GenericFilePayload] and [GenericBinaryContentReadableRandomlyWithMimeTypePayload]
 * for carrying binary data of unspecified semantics through.
 */
sealed interface BinaryContentWithMimeTypePayload<
    BCM : BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>,
    BC : BinaryContentReadableRandomlyMultiplatformAPI,
    > {
    val contentWithMimeType: BCM

    interface BinaryContentReadableRandomlyWithMimeTypePayload :
        BinaryContentWithMimeTypePayload<
            BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
            BinaryContentReadableRandomly,
            >

    interface FilePayload :
        BinaryContentWithMimeTypePayload<
            File,
            BinaryContentReadableRandomlyMultiplatformAPI,
            >
}

/**
 * A generic implementation of [BinaryContentWithMimeTypePayload] that carries a [File] as a payload,
 * without any knowledge of the semantic type of the file.
 */
internal class GenericFilePayload(
    override val contentWithMimeType: File,
) : BinaryContentWithMimeTypePayload.FilePayload

/**
 * A generic implementation of [GenericBinaryContentReadableRandomlyWithMimeTypePayload] that carries a
 * '[BinaryContentReadableRandomly] with a [com.speechify.client.api.util.MimeType]' as a payload
 * without any knowledge of the semantic type of the file.
 */
internal class GenericBinaryContentReadableRandomlyWithMimeTypePayload(
    override val contentWithMimeType:
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
) : BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload
