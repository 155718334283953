package com.speechify.client.internal.sync

import com.speechify.client.internal.getGlobalScopeWithContext
import com.speechify.client.internal.util.extensions.coroutines.tryGetValue
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.sync.Semaphore
import kotlinx.coroutines.sync.withPermit

// source: https://github.com/Kotlin/kotlinx.coroutines/issues/706 with more functionality added
/**
 * Suspending version of the stdlib `lazy`
 */
internal class CoLazy<T>(
    private val scope: CoroutineScope = getGlobalScopeWithContext(),
    private val supplier: suspend () -> T,
) {

    private val withCancellationSemaphore = Semaphore(1)

    /** Used to count how many non-cancellable calls are running. */
    private val gettersWaiting = AtomicInt(0)

    private val valueDeferredFlow: MutableStateFlow<Deferred<T>> = MutableStateFlow(
        scope.async(start = CoroutineStart.LAZY) {
            supplier()
        },
    )
    private var valueDeferred: Deferred<T>
        get() = valueDeferredFlow.value
        set(value) {
            valueDeferredFlow.value = value
        }

    /**
     * Emits outcomes of valueDeferred tasks, capturing either results or errors upon completion.
     * Note: This flow does not initiate tasks but only observes their completions.
     */
    internal val valueFlow: Flow<T> = valueDeferredFlow.flatMapLatest { t ->
        callbackFlow {
            val handle = t.invokeOnCompletion { error ->
                if (error == null) {
                    trySend(t.getCompleted())
                } else {
                    close(error)
                }
            }
            awaitClose {
                handle.dispose()
            }
        }
    }

    /**
     * Gets the value, blocking the current thread until the value is ready.
     * If this call is cancelled fetching the value will still continue in the background.
     * Use [getWithCancellation] if you want cancelling this call to also cancel getting the item.
     */
    suspend fun get(): T {
        try {
            gettersWaiting.getAndIncrement()
            return valueDeferred.await()
        } finally {
            gettersWaiting.getAndDecrement()
        }
    }

    /**
     * When this call is cancelled the underlying deferred is cancelled and a new one is created.
     * This allows you to stop the computation and restart it later.
     */
    // We use a semaphore here since awaiting on the deferred or waiting on the semaphore is the same,
    // but we only want one thread to be modifying the deferred at a time.
    suspend fun getWithCancellation(): T = withCancellationSemaphore.withPermit {
        try {
            return valueDeferred.await()
        } catch (e: CancellationException) {
            // Only if no one has called get() do we want to cancel the whole deferred.
            if (gettersWaiting.value == 0 && !valueDeferred.isCompleted) {
                valueDeferred.cancel(e)
                valueDeferred = scope.async(start = CoroutineStart.LAZY) {
                    supplier()
                }
            }
            throw e
        }
    }

    /**
     * Returns the value only if it is already available.
     * Returns [DeferredTryGetResult.NotAvailableYet] if it is not yet available.
     */
    fun tryGetValue(): DeferredTryGetResult<T> =
        valueDeferred.tryGetValue()

    /**
     * Used to clear the cached state.
     * Note: the first access after calling this function, will lead to re-computation of the state.
     */
    internal fun cancelAndClearState() {
        valueDeferredFlow.value.cancel()
        valueDeferredFlow.value = scope.async(start = CoroutineStart.LAZY) {
            supplier()
        }
    }

    internal fun cancel() {
        valueDeferredFlow.value.cancel()
    }
}

internal fun <T : Any> CoLazy<T>.getValueOrNull(): T? =
    when (val tryResult = tryGetValue()) {
        is DeferredTryGetResult.Success<T> -> tryResult.value
        is DeferredTryGetResult.NotAvailableYet -> null
    }

/**
 * Suspending version of the stdlib `lazy`
 */
internal fun <T> coLazy(
    scope: CoroutineScope = getGlobalScopeWithContext(),
    supplier: suspend () -> T,
) = CoLazy(scope, supplier)
