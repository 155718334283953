package com.speechify.client.reader.core

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.SearchMatch
import com.speechify.client.api.content.Searcher
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.sync.AtomicRef
import com.speechify.client.internal.sync.set
import com.speechify.client.internal.util.extensions.collections.flows.debounceInstances
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.coroutines.createTopLevelCoroutineScopeWithCompletableSupervisorJob
import com.speechify.client.reader.classic.ClassicTextFeaturesHelper
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.cancellable
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.updateAndGet
import kotlinx.coroutines.launch
import kotlinx.coroutines.withTimeout
import kotlin.coroutines.cancellation.CancellationException
import kotlin.js.JsExport
import kotlin.time.Duration.Companion.seconds

/**
 * A helper for searching a query within the document currently displayed to the user.
 *
 * **Note:** Ensure that the searcher's [state] is not [SearchState.NotAvailable] before making requests.
 */
@JsExport
class SearchHelper internal constructor(
    scope: CoroutineScope,
    private val searcher: Searcher?,
    internal val readingLocationFlow: StateFlow<ReadingLocationState>,
) : Helper<SearchState>(scope) {
    private val _stateFlow = MutableStateFlow(initialState)
    private val activeSearchJob = AtomicRef<Job?>(null)

    /*
     * The helper scope uses Dispatchers.Unconfined, which starts execution of a launched coroutine
     * on the caller thread until it reaches a suspension point. This can result in long-running execution,
     * potentially slowing down the handling of the `commands` flow.
     *
     * Dispatchers.Default is used here because launching a new search should return immediately
     * and run in parallel with any previously launched searches that have not yet reached a suspension point
     * and, therefore, have not been canceled.
     */
    private val searcherScope: CoroutineScope = createTopLevelCoroutineScopeWithCompletableSupervisorJob(
        contextToMerge = Dispatchers.Default + CoroutineName("Searcher"),
        parentJob = this.scope.coroutineContext[Job],
    ).first

    /** A flow that emits the current state of the searcher. See [SearchState] for details. */
    override val stateFlow: StateFlow<SearchState>
        get() = _stateFlow

    /**
     * The initial state of the searcher, which is either [SearchState.Idle]
     * if search is supported for the document or [SearchState.NotAvailable] if it is not.
     */
    override val initialState: SearchState
        get() = if (searcher == null) {
            SearchState.NotAvailable
        } else {
            SearchState.Idle
        }

    init {
        commands
            .onEachInstance<SearchCommand> {
                checkSearchIsAvailable()
            }
            .debounceInstances<SearchCommand.Search>(200)
            .onEachInstance<SearchCommand.Search> { command ->
                val query = command.query
                val currentState = stateFlow.value
                if (currentState.queryEquals(query)) {
                    // the same query string, ignore
                    return@onEachInstance
                }

                // cancel any ongoing search before starting a new one
                activeSearchJob.value?.cancel()

                if (query.isEmpty()) {
                    // the new query is empty, return to `Idle` state
                    _stateFlow.value = SearchState.Idle
                    return@onEachInstance
                }

                _stateFlow.value = SearchState.Loading(query)

                val startCursor = readingLocationFlow.value.location?.cursor
                val newSearchJob = launchSearch(query, startCursor)

                activeSearchJob.set(newSearchJob)
                newSearchJob.invokeOnCompletion {
                    // release the job upon completion (whether normal, canceled, or failed)
                    activeSearchJob.compareAndSet(newSearchJob, null)
                }
            }
            .onEachInstance<SearchCommand.FocusNextMatch> {
                val newState = _stateFlow.updateAndGet { currentState ->
                    // FocusNextMatch is possible only on found matches
                    if (currentState !is SearchState.MatchesFound) return@onEachInstance
                    currentState.focusNextMatch()
                }

                newState as SearchState.MatchesFound

                dispatchNavigationCommandTo(newState.focusedMatch)
            }
            .onEachInstance<SearchCommand.FocusPreviousMatch> {
                val newState = _stateFlow.updateAndGet { currentState ->
                    // FocusPreviousMatch is possible only on found matches and final results
                    if (currentState !is SearchState.MatchesFound || !currentState.isFinalResult) return@onEachInstance
                    currentState.focusPreviousMatch()
                }

                newState as SearchState.MatchesFound

                dispatchNavigationCommandTo(newState.focusedMatch)
            }
            .onEachInstance<SearchCommand.Cancel> {
                _stateFlow.value = SearchState.Idle
                activeSearchJob.value?.cancel()
            }
            .launchInHelper()
    }

    /**
     * Starts scanning the document for occurrences of the specified [query].
     *
     * - If a search is already in progress, it is canceled before starting a new one.
     * - If the query of the active search is equal to the specified [query], this request is ignored,
     *   and the existing search continues.
     * - If the specified [query] is empty, the searcher is reset, equivalent to calling [cancel].
     *
     * Search progress can be observed through [stateFlow].
     * Match highlights for found matches are communicated to
     * listeners of state changes in the corresponding feature helper, e.g., [ClassicTextFeaturesHelper].
     *
     * Note: If search is not supported for the document ([SearchState.NotAvailable]),
     * calling this function will cause an [IllegalStateException].
     */
    fun search(query: String) {
        dispatch(SearchCommand.Search(query))
    }

    /**
     * Moves the focus to the next match.
     *
     * - If there are no matches, nothing happens.
     * - If the currently focused match is the last match, focus is moved to the first match.
     *
     * The updated search state can be observed through [stateFlow].
     * Updated match highlights are communicated to
     * listeners of state changes in the corresponding feature helper, e.g., [ClassicTextFeaturesHelper].
     *
     * Note: If search is not supported for the document ([SearchState.NotAvailable]),
     * calling this function will cause an [IllegalStateException].
     */
    fun focusNextMatch() {
        dispatch(SearchCommand.FocusNextMatch)
    }

    /**
     * Moves the focus to the previous match.
     *
     * - If there are no matches, nothing happens.
     * - If the currently focused match is the first match:
     *     - If the search has [finalized][SearchState.MatchesFound.isFinalResult], focus moves to the last match.
     *     - Otherwise, nothing happens.
     *
     * The updated search state can be observed through [stateFlow].
     * Updated match highlights are communicated to
     * listeners of state changes in the corresponding feature helper, e.g., [ClassicTextFeaturesHelper].
     *
     * Note: If search is not supported for the document ([SearchState.NotAvailable]),
     * calling this function will cause an [IllegalStateException].
     */
    fun focusPreviousMatch() {
        dispatch(SearchCommand.FocusPreviousMatch)
    }

    /**
     * Cancels the search and resets the search state to [SearchState.Idle].
     *
     * If a search is in progress, it is canceled.
     *
     * The updated search state can be observed through [stateFlow].
     * Cleared match highlights are communicated to
     * listeners of state changes in the corresponding feature helper, e.g., [ClassicTextFeaturesHelper].
     *
     * Note: If search is not supported for the document ([SearchState.NotAvailable]),
     * calling this function will cause an [IllegalStateException].
     */
    fun cancel() {
        dispatch(SearchCommand.Cancel)
    }

    // private functions

    private fun launchSearch(query: String, startCursor: ContentCursor?): Job {
        val resultsFlow = searcher!!.search(query, startCursor)
            .cancellable()
            .onEach { results ->
                if (results.isEmpty()) return@onEach

                val currentState = stateFlow.value
                // if a new search was launched concurrently, gracefully ignore results of this search
                if (currentState.isInProgress && currentState.queryEquals(query)) {
                    val newState = currentState.newStateWithFoundMatches(results)

                    if (_stateFlow.compareAndSet(currentState, newState)) {
                        if (currentState is SearchState.Loading && newState is SearchState.MatchesFound) {
                            dispatchNavigationCommandTo(newState.focusedMatch)
                        }
                    }
                }
            }.onCompletion { cause ->
                if (cause is CancellationException && cause !is TimeoutCancellationException) return@onCompletion
                if (cause != null) {
                    // log the exception and continue with finalizing the search result
                    Log.e("Unexpected exception in search", cause, "SearchHelper.launchSearch.onCompletion")
                }

                val currentState = stateFlow.value
                // if a new search was launched concurrently, gracefully ignore completion of this search
                if (currentState.isInProgress && currentState.queryEquals(query)) {
                    val newState = currentState.toFinalState()

                    _stateFlow.compareAndSet(currentState, newState)
                }
            }

        return searcherScope.launch {
            // a timeout period to ensure the search job is canceled if it takes too long
            withTimeout(10.seconds) {
                resultsFlow.collect {}
            }
        }
    }

    private fun dispatchNavigationCommandTo(searchMatch: SearchMatch) {
        dispatch(
            NavigationCommand.NavigateTo(
                NavigationIntent.GoToSearchMatch(
                    SerialLocation(searchMatch.start).toRobustLocation(),
                ),
            ),
        )
    }

    private fun checkSearchIsAvailable() {
        check(stateFlow.value !== SearchState.NotAvailable) { "Search is not supported for this content type" }
    }
}

/**
 * The state of the searcher.
 *
 * The state [NotAvailable] indicates that currently search is not supported for this content type,
 * and this state is _terminal_.
 * Otherwise, if the state of the searcher is `S` and a new query is searched, the state changes as follows:
 * ```
 *          S
 *        /   \
 *     Idle   Loading
 *             /   \
 *     NotFound   MatchesFound*
 *                      |
 *                 MatchesFound (final)
 * ```
 * - `MatchesFound*` represents zero or more intermediate results before reaching the final state.
 * - The state returns to `Idle` if a search is canceled or the new query is empty.
 */
@JsExport
sealed class SearchState {
    /**
     * No searcher is available for the document, meaning search is not yet supported for this content type.
     */
    object NotAvailable : SearchState()

    /**
     * The searcher is idle - either no query was provided or the search was canceled.
     */
    object Idle : SearchState()

    /**
     * The searcher is scanning the document for occurrences of the [query].
     * The search is in progress, and no matches have been found yet.
     */
    data class Loading internal constructor(val query: String) : SearchState() {
        init {
            require(query.isNotEmpty()) { "Query must not be empty" }
        }
    }

    /**
     * The searcher has finished scanning the document and found no matches for the [query].
     * No further results will be provided for this query.
     */
    data class NotFound internal constructor(val query: String) : SearchState() {
        init {
            require(query.isNotEmpty()) { "Query must not be empty" }
        }
    }

    /**
     * The searcher has found matches for the [query].
     *
     * The searcher scans the document in chunks to improve response time.
     * As a result, multiple instances of [MatchesFound] may occur within the same search request.
     * Intermediate instances have [isFinalResult] set to `false`, indicating that additional matches
     * may still be found. The final instance occurs once the entire document has been scanned
     * and will have [isFinalResult] set to `true`.
     */
    data class MatchesFound internal constructor(
        /** The query that produced these matches. */
        val query: String,

        /** Whether this is the final search result (no additional matches will be found). */
        val isFinalResult: Boolean,

        /** The index of the currently focused match. */
        val focusedMatchIndex: Int,

        /** The list of matches found in this search stage. */
        internal val matches: List<SearchMatch>,
    ) : SearchState() {

        /** The total number of matches found in this search stage. */
        val matchesCount: Int
            get() = matches.size

        init {
            require(query.isNotEmpty()) { "Query must not be empty" }
            require(matches.isNotEmpty()) { "Found matches must not be empty" }
            require(focusedMatchIndex >= 0) {
                "focusedMatchIndex must be non-negative, but was $focusedMatchIndex"
            }
            require(focusedMatchIndex < matchesCount) {
                "focusedMatchIndex($focusedMatchIndex) must be less than matchesCount($matchesCount)"
            }
        }

        /** Returns a new [MatchesFound] with the focus moved to the next match. */
        internal fun focusNextMatch(): MatchesFound {
            return copy(focusedMatchIndex = (focusedMatchIndex + 1) % matchesCount)
        }

        /** Returns a new [MatchesFound] with the focus moved to the previous match. */
        internal fun focusPreviousMatch(): MatchesFound {
            return copy(focusedMatchIndex = (matchesCount + focusedMatchIndex - 1) % matchesCount)
        }

        /** The currently focused match. */
        internal val focusedMatch: SearchMatch
            get() = matches[focusedMatchIndex]
    }
}

private fun SearchState.newStateWithFoundMatches(newMatches: List<SearchMatch>): SearchState =
    when (this) {
        is SearchState.Loading -> {
            SearchState.MatchesFound(
                query,
                isFinalResult = false,
                focusedMatchIndex = 0,
                matches = newMatches,
            )
        }
        is SearchState.MatchesFound -> {
            check(!this.isFinalResult) { "Found new matches after search results were finalized" }
            this.copy(matches = matches + newMatches)
        }
        else -> error("Unexpected search state: $this")
    }

private fun SearchState.toFinalState(): SearchState =
    when (this) {
        is SearchState.Loading -> {
            SearchState.NotFound(query)
        }
        is SearchState.MatchesFound -> {
            check(!this.isFinalResult) { "Search results were already finalized" }
            this.copy(isFinalResult = true)
        }
        else -> error("Unexpected search state: $this")
    }

private val SearchState.isInProgress: Boolean
    get() = this is SearchState.Loading ||
        this is SearchState.MatchesFound && !isFinalResult

private fun SearchState.queryEquals(otherQuery: String): Boolean =
    when (this) {
        is SearchState.Loading -> {
            query == otherQuery
        }
        is SearchState.NotFound -> {
            query == otherQuery
        }
        is SearchState.MatchesFound -> {
            query == otherQuery
        }
        else -> false
    }

internal sealed class SearchCommand {
    object Cancel : SearchCommand()
    object FocusNextMatch : SearchCommand()
    object FocusPreviousMatch : SearchCommand()
    data class Search(val query: String) : SearchCommand()
}
