package com.speechify.client.api.services.audio

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.internal.services.auth.AuthService

/**
 * A v2 provider for authorization tokens that will be used when authenticating with the audio server.
 * The main change from v1 is that it uses [AuthService] to get the firebase user identity token to use in requests
 * instead of calling sign/verify endpoints from audio server.
 *
 * See [the audio server's docs](https://audio.docs.speechify.dev/synthesis/authorization.html) on how authorization
 * works.
 */
internal class AuthorizationTokenProviderV2(private val authService: AuthService) : AuthorizationTokenProvider() {
    override val scheme: AuthorizationHeaderScheme
        get() = AuthorizationHeaderScheme.Bearer

    override fun getValidToken(callback: Callback<String>) = callback.fromCo {
        authService.getCurrentUserIdentityToken().map { it.token }
    }
}
