package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.api.util.images.verticalDistanceTo
import com.speechify.client.helpers.content.standard.book.heuristics.v2.isNeighborIndex
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentLine
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.LinePosition
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingContext
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.RawContentType
import com.speechify.client.helpers.content.standard.book.heuristics.v2.unionBoxes
import com.speechify.client.helpers.content.standard.book.isAlmostEqual
import kotlin.math.max

/**
 * Defines the maximum horizontal search distance for adjacent words,
 * expressed as a multiple of the symbol width.
 */
private const val VERTICAL_SEARCH_DISTANCE = 1

/**
 * Defines the extended horizontal search distance for neighboring words,
 * expressed as a multiple of the symbol width.
 */
private const val VERTICAL_SEARCH_DISTANCE_FOR_NEIGHBORS = 2

/**
 * Defines the tolerance for considering two words to be on the same column.
 */
private const val COLUMN_ALIGNMENT_TOLERANCE = 0.4

/**
 * Needs to EXCLUDE horizontal placed symbols that are "slim" like "I" that could treat as a vertical symbol
 * */
private const val EXCLUDE_SHORTER_SYMBOL_LENGTH = 2

/**
 * Needs to INCLUDE horizontal placed symbols that are "slim" like "I" that could treat as a vertical symbol
 * */
private const val INCLUDE_SHORTER_SYMBOL_LENGTH = 3

internal class ProcessVerticalLines(private val context: ParsingContext, private val logger: Logger?) :
    ParsingPipelineStage<List<ContentLine>, List<ContentLine>> {
    private val builtLines: MutableList<ContentLine> = mutableListOf()

    override fun process(input: List<ContentLine>): List<ContentLine> {
        when (context.platform) {
            RawContentType.LINES -> processWebLines(input)
            RawContentType.WORDS -> processMobileLines(input)
        }

        logBuiltLines()

        return builtLines
    }

    /** Here is no need to run deep search since web is already built in lines*/
    private fun processWebLines(input: List<ContentLine>) {
        for (line in input)
            when (line.isPossibleVertical()) {
                true -> builtLines.add(line.copy(position = LinePosition.VERTICAL))
                false -> builtLines.add(line)
            }
    }

    private fun processMobileLines(input: List<ContentLine>) {
        val visited = mutableSetOf<Int>()
        val indexedLines = input.mapIndexed { index, line -> index to line }

        for ((index, line) in indexedLines) {
            if (index !in visited) {
                if (line.isPossibleVertical()) {
                    val newVerticalLine = mutableListOf<ContentLine>()
                    runDeepTopBottomLineSearch(newVerticalLine, index to line, indexedLines, visited)

                    // Here could be added one-three length symbols as a lines, for example 'I' that is placed vertical,
                    // but not count as vertical ones before. So we need to remove than to avoid dublicates
                    builtLines.removeAll(newVerticalLine)
                    builtLines.add(newVerticalLine.buildVerticalLine())
                } else {
                    builtLines.add(line)
                }
            }
        }
    }

    private fun runDeepTopBottomLineSearch(
        newLine: MutableList<ContentLine>,
        current: Pair<Int, ContentLine>,
        indexedLines: List<Pair<Int, ContentLine>>,
        visited: MutableSet<Int>,
    ) {
        val (currentIndex, currentLine) = current
        if (!visited.add(currentIndex)) return
        newLine.add(currentLine)

        val neighbors = current.findNeighbors(indexedLines).filter { (index, _) -> index !in visited }

        // Ensure we didn't count random horizontal lines
        val verticalNeighbors =
            neighbors.filter { (_, line) ->
                line.isPossibleVertical() || line.plainText.length <= INCLUDE_SHORTER_SYMBOL_LENGTH
            }

        verticalNeighbors.forEach { runDeepTopBottomLineSearch(newLine, it, indexedLines, visited) }
    }

    private fun Pair<Int, ContentLine>.findNeighbors(indexedLines: List<Pair<Int, ContentLine>>):
        List<Pair<Int, ContentLine>> {
        val (thisIndex, thisLine) = this
        val thisSymbolHeight = thisLine.getVerticalSymbolTolerance()
        val baseEps = thisSymbolHeight * VERTICAL_SEARCH_DISTANCE
        val neighborEps = thisSymbolHeight * VERTICAL_SEARCH_DISTANCE_FOR_NEIGHBORS

        return indexedLines.filter { (thatIndex, thatLine) ->
            if (thisIndex == thatIndex) return@filter false

            val distanceThreshold = if (thisIndex.isNeighborIndex(thatIndex)) neighborEps else baseEps
            val verticalDistance = thisLine.box.verticalDistanceTo(thatLine.box)
            val isCloseEnough = verticalDistance < distanceThreshold
            isCloseEnough && thisLine.isOnTheSameColumn(thatLine)
        }
    }

    private fun ContentLine.isOnTheSameColumn(line: ContentLine): Boolean {
        val tolerance = getVerticalSymbolTolerance() * COLUMN_ALIGNMENT_TOLERANCE

        val isLeftIsAlmostEqual = this.box.left.isAlmostEqual(line.box.left, tolerance)
        val isRightIsAlmostEqual = this.box.right.isAlmostEqual(line.box.right, tolerance)
        return isLeftIsAlmostEqual && isRightIsAlmostEqual
    }

    private fun ContentLine.getVerticalSymbolTolerance() =
        max(box.width, box.height / plainText.length)

    private fun List<ContentLine>.buildVerticalLine(): ContentLine {
        require(this.isNotEmpty()) { "Unexpected content size" }

        val boundingBox = this.map { it.box }.unionBoxes()
        val rawContent = this.flatMap { it.rawTextContentItems }
        return ContentLine(boundingBox, rawContent, LinePosition.VERTICAL)
    }

    private fun ContentLine.isPossibleVertical(): Boolean =
        // Exclude "I" symbols
        this.plainText.length > EXCLUDE_SHORTER_SYMBOL_LENGTH && this.box.height > this.box.width

    private fun logBuiltLines() {
        logger?.log("Built vertical lines:")
        builtLines.forEach { logger?.log("'${it.plainText}', positional: ${it.position.name}") }
    }
}
