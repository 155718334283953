package com.speechify.client.api.services.subscription.models

import com.speechify.client.internal.time.ISO8601DateString
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class Entitlements(
    /**
     * Should customer have premium features?
     *
     * NOTE: `true` will be returned also during trial, and when subscription was cancelled but there is still time left.
     */
    val isPremium: Boolean,
    // How many HD words customer still has until the next HD words refill?
    val hdWordsLeft: Int,
    // How many words are granted with the next grant?
    // Could be 0 if subscription is canceled, or it is the "unlimited" plan.
    val nextHDWordsGrant: Int,
    // When will the next HD words refill happen?
    // Can be `null` if subscription is canceled, or it is the "unlimited" plan.
    val nextHDWordsGrantDate: ISO8601DateString?,
    /**
     * Date indicating when the last HD words refill happened.
     * Can be `null` if the user doesn't have a subscription,
     */
    val lastHdWordsGrantDate: ISO8601DateString?,
    /**
     * The maximum listening speed a user can have
     */
    val maxSpeedInWordsPerMinute: Int,

    /* userAudiobookCredits */
    val audiobookCreditsLeft: Int,
    val lastAudiobookCreditsGrantDate: ISO8601DateString?,
) {
    companion object {
        val NONE = Entitlements(
            isPremium = false,
            hdWordsLeft = 0,
            nextHDWordsGrant = 0,
            nextHDWordsGrantDate = null,
            lastHdWordsGrantDate = null,
            maxSpeedInWordsPerMinute = 220,
            audiobookCreditsLeft = 0,
            lastAudiobookCreditsGrantDate = null,
        )
    }
}
