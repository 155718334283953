package com.speechify.client.bundlers.content

import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.util.MimeTypeOfListenableContent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.InMemoryByteArrayBinaryContent
import com.speechify.client.api.util.io.toFileWithMimeType
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload.Html
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload.Pdf

/**
 * A carrier for a payload of any content that can be listened to and is held in a single
 * [com.speechify.client.api.util.io.BinaryContentReadableInChunks] (accompanied by a
 * [com.speechify.client.api.util.MimeType]), or more specifically, any of its subtypes.
 * .
 * One usefulness of this class is that it readily contains information about the content type, and it does this in a
 * type-safe way through the sealed hierarchy that it forms (see the subclasses of [Pdf], [Html], etc.), which enables
 * to have single entry point through many layers of the code to take the payload, and then safely split the
 * logic exhaustively using an exhaustive `when` check.
 */
internal sealed class ListenableBinaryContentPayload<
    BCM : BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>,
    BC : BinaryContentReadableRandomlyMultiplatformAPI,
    out ContentBundleType : ContentBundle,
    > {
    abstract val contentWithMimeType: BCM
    abstract val sourceUrl: String?

    companion object {
        fun createForBinaryContentWithNativeApi(
            content: BinaryContentReadableRandomly,
            mimeType: MimeTypeOfListenableContent,
            sourceUrl: String?,
            password: String? = null,
        ): Result<ListenableBinaryContentPayload<*, *, *>> =
            when (mimeType) {
                MimeTypeOfListenableContent.Companion.StaticMimeTypes.PDF -> {
                    Pdf(
                        contentWithMimeType = content.withMimeType(mimeType),
                        sourceUrl = sourceUrl,
                        password = password,
                    ).successfully()
                }

                MimeTypeOfListenableContent.Companion.StaticMimeTypes.EPUB -> {
                    Epub(
                        contentWithMimeType = content.withMimeType(mimeType),
                        sourceUrl = sourceUrl,
                    ).successfully()
                }

                else ->
                    createForBinaryContentWithMultiplatformAPI(
                        content = content,
                        mimeType = mimeType,
                        sourceUrl = sourceUrl,
                    )
            }

        fun createForBinaryContentWithMultiplatformAPI(
            content: BinaryContentReadableRandomlyMultiplatformAPI,
            mimeType: MimeTypeOfListenableContent,
            sourceUrl: String?,
        ): Result<ListenableBinaryContentPayload<*, *, *>> {
            return when (mimeType) {
                is MimeTypeOfListenableContent.Html -> {
                    Html(
                        contentWithMimeType = content.toFileWithMimeType(mimeType = mimeType),
                        sourceUrl = sourceUrl,
                    ).successfully()
                }

                is MimeTypeOfListenableContent.Text -> {
                    PlainTextOrMarkdown(
                        contentWithMimeType = content.toFileWithMimeType(mimeType = mimeType),
                        sourceUrl = sourceUrl,
                    ).successfully()
                }

                is MimeTypeOfListenableContent.Companion.StaticMimeTypes.EPUB -> {
                    SpeechifyBook(content.toFileWithMimeType(mimeType), sourceUrl).successfully()
                }

                else -> Result.Failure(
                    /* Use an exception to know the entry-point via the stacktrace */
                    SDKError.OtherException(
                        Exception(
                            "File mime type ${mimeType.typeSubtype} not supported" +
                                " (full content type ${mimeType.fullString})",
                        ),
                    ),
                )
            }
        }
    }

    abstract val libraryItemContentType: ContentType

    class Pdf(
        override val contentWithMimeType:
            BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        override val sourceUrl: String?,
        val password: String? = null,
    ) : ListenableBinaryContentPayload<
        BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomly>,
        BinaryContentReadableRandomly,
        ContentBundle.BookBundle,
        >(),
        BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload {
        override val libraryItemContentType: ContentType
            get() = ContentType.PDF
    }

    class PlainTextOrMarkdown(
        override val contentWithMimeType: File,
        override val sourceUrl: String?,
    ) : ListenableBinaryContentPayload<
        File,
        BinaryContentReadableRandomlyMultiplatformAPI,
        ContentBundle.PlainTextBundle,
        >(),
        BinaryContentWithMimeTypePayload.FilePayload {
        override val libraryItemContentType: ContentType
            get() = ContentType.TXT
    }

    class Html(
        override val contentWithMimeType: File,
        override val sourceUrl: String?,
    ) : ListenableBinaryContentPayload<
        File,
        BinaryContentReadableRandomlyMultiplatformAPI,
        ContentBundle.WebPageBundle,
        >(),
        BinaryContentWithMimeTypePayload.FilePayload {
        override val libraryItemContentType: ContentType
            get() = ContentType.HTML
    }

    class Epub(
        override val contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<
            BinaryContentReadableRandomly,>,
        override val sourceUrl: String?,
    ) : ListenableBinaryContentPayload<
        BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomly>,
        BinaryContentReadableRandomlyMultiplatformAPI,
        ContentBundle.WebPageBundle,
        >(),
        BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload {
        override val libraryItemContentType: ContentType
            get() = ContentType.EPUB
    }

    class SpeechifyBook(
        override val contentWithMimeType: InMemoryByteArrayBinaryContent,
        override val sourceUrl: String?,
    ) :
        ListenableBinaryContentPayload<InMemoryByteArrayBinaryContent,
            BinaryContentReadableRandomly, ContentBundle.WebPageBundle,>() {
        override val libraryItemContentType: ContentType
            get() = ContentType.SPEECHIFY_BOOK
    }
}
