package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.`internal`.services.db.DbBoolean
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.audio.caching.VoiceIdForDb
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.services.library.offline.AudioDownloadOptions
import kotlin.Double
import kotlin.Long
import kotlin.String

public data class DownloadedAudioForItem(
  public val documentUri: SpeechifyURI,
  public val voiceId: VoiceIdForDb,
  public val downloadOptions: AudioDownloadOptions,
  public val sdkVersionAtCreation: String,
  public val hasGapsInAudio: DbBoolean,
  public val downloadProgress: Double,
  public val startProgressFraction: Double,
  public val startCursor: ContentCursor,
  public val endProgressFraction: Double,
  public val endCursor: ContentCursor,
) {
  public class Adapter(
    public val documentUriAdapter: ColumnAdapter<SpeechifyURI, String>,
    public val voiceIdAdapter: ColumnAdapter<VoiceIdForDb, String>,
    public val downloadOptionsAdapter: ColumnAdapter<AudioDownloadOptions, String>,
    public val hasGapsInAudioAdapter: ColumnAdapter<DbBoolean, Long>,
    public val startCursorAdapter: ColumnAdapter<ContentCursor, String>,
    public val endCursorAdapter: ColumnAdapter<ContentCursor, String>,
  )
}
