package com.speechify.client.api.services.account.models

import com.speechify.client.api.services.account.models.ReadingExperience.PRE_Q3_2022_LEGACY
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Data structure holding persistent settings for a user account.
 * This contains structured and type-safe data (the top-level properties) as well as
 * free-form data (the data stored under [customSettings]).
 *
 * Products are free to use customSettings however they like but care should be taken for products to
 * avoid collisions with other products, thus a recommended but unenforced practice might be to always prefix
 * their custom settings with the app environment (e.g `ios_preferred_volume`). Even though [customSettings]
 * only permits string values, products are free to serialize object trees into it, e.g.
 * ```
 * ios_reader_display="{fontFamily: Helvetica, lineSpacing: 1.5}"
 * ```
 *
 * @see [com.speechify.client.api.services.account.AccountSettingsService]
 */
@JsExport
@Serializable
data class AccountSettings(
    val readingExperience: ReadingExperience = PRE_Q3_2022_LEGACY,
    val customSettings: BoundaryMap<String>,
)

fun AccountSettings.toBoundaryMap(): BoundaryMap<Any?> = SdkBoundaryMap.of(
    "readingExperience" to readingExperience.name,
    "customSettings" to customSettings,
)
