package com.speechify.client.reader.core

import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetImage
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import com.speechify.client.internal.util.extensions.collections.flows.onEachPairInstance
import com.speechify.client.internal.util.extensions.collections.flows.onEachTripleInstance
import com.speechify.client.internal.util.updateItemsAtIndices
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flowOf
import kotlin.js.JsExport

@JsExport
class BookEditorHelper internal constructor(
    scope: CoroutineScope,
    readingBundle: ReadingBundle,
) : Helper<EditBookState>(scope) {

    private val editorState = MutableStateFlow<BookEditor?>(null)

    private val internalReadyStateFlow: MutableSharedFlow<EditBookState.Ready> = MutableSharedFlow(replay = 1)
    override val stateFlow: StateFlow<EditBookState> =
        internalReadyStateFlow.stateInHelper(
            initialValue = if (readingBundle is BookReadingBundle) {
                EditBookState.NotReady
            } else {
                EditBookState.NotAvailable
            },
        )

    override val initialState = stateFlow.value

    init {
        // skip any commands if readingBundle is not a BookReadingBundle.
        flowOf(readingBundle).filterIsInstance<BookReadingBundle>().combine(commands) { bookReadingBundle, command ->
            bookReadingBundle to command
        }.onEachPairInstance<BookReadingBundle, BookEditorCommand.EditBook> { bookReadingBundle, command ->
            val editor = readingBundle.bookContent?.createEditor()
                ?: throw IllegalStateException("BookEditorHelper Couldn't create a BookEditor instance!")
            editorState.value = editor
            internalReadyStateFlow.emit(
                EditBookState.Ready(
                    pagesToEdit = editor.currentState.pages
                        .mapIndexed { index, page ->
                            val bookPage = editor.originalBookView.coGetPages(arrayOf(index)).orThrow().single()
                            PagesEditWithImagePreview(
                                pageIndex = bookPage.pageIndex,
                                isHidden = page.hidden,
                                regions = page.regions,
                                hasTextEdits = page.replacementPageContent != null,
                                viewport = bookPage.getMetadata().viewport,
                                imageProvider = ImageProvider(
                                    bookView = editor.originalBookView,
                                    pageIndex = index,
                                ),
                            )
                        }.toTypedArray(),
                    totalPages = editor.originalBookView.getMetadata().numberOfPages,
                ),
            )
        }.combine(editorState) { (bookReadingBundle, command), editor ->
            // skip the rest of commands if editor is null.
            if (editor == null) null else Triple(bookReadingBundle, editor, command)
        }
            .filterNotNull()
            .onEachTripleInstance<BookReadingBundle, BookEditor, BookEditorCommand.HidePages> { _, editor, command ->
                command.pagesIndices.forEach {
                    editor.hidePage(it)
                }
                val state = internalReadyStateFlow.first()
                internalReadyStateFlow.emit(
                    state.copy(
                        pagesToEdit = state.pagesToEdit.updateItemsAtIndices(command.pagesIndices.toSet()) {
                            it.copy(isHidden = true)
                        },
                    ),
                )
                // clears the selection
                dispatch(SelectionHelperCommand.ClearSelection)
            }.onEachTripleInstance<BookReadingBundle, BookEditor, BookEditorCommand.ShowPages> { _, editor, command ->
                command.pagesIndices.forEach {
                    editor.showPage(it)
                }

                val state = internalReadyStateFlow.first()
                internalReadyStateFlow.emit(
                    state.copy(
                        pagesToEdit = state.pagesToEdit.updateItemsAtIndices(command.pagesIndices.toSet()) {
                            it.copy(isHidden = false)
                        },
                    ),
                )
                // clears the selection
                dispatch(SelectionHelperCommand.ClearSelection)
            }.onEachTripleInstance<BookReadingBundle, BookEditor, BookEditorCommand.SetRegionsOfInterests>
            { _, editor, command ->
                // reset any edit text before setting any region of interests.
                command.pagesIndices.forEach {
                    editor.resetTextContent(it)
                }
                val regions = command.regions.toTypedArray()
                command.pagesIndices.forEach {
                    editor.setRegionsOfInterest(it, regions)
                }
                val state = internalReadyStateFlow.first()
                internalReadyStateFlow.emit(
                    state.copy(
                        pagesToEdit = state.pagesToEdit.updateItemsAtIndices(command.pagesIndices.toSet()) {
                            it.copy(regions = regions)
                        },
                    ),
                )
            }.onEachTripleInstance<BookReadingBundle, BookEditor, BookEditorCommand.ResetRegionsOfInterests>
            { _, editor, command ->
                command.pagesIndices.forEach {
                    editor.resetRegionsOfInterest(it)
                }
                val state = internalReadyStateFlow.first()
                internalReadyStateFlow.emit(
                    state.copy(
                        pagesToEdit = state.pagesToEdit.updateItemsAtIndices(command.pagesIndices.toSet()) {
                            it.copy(regions = emptyArray())
                        },
                    ),
                )
            }.onEachTripleInstance<BookReadingBundle, BookEditor, BookEditorCommand.SaveEdits>
            { bookReadingBundle, editor, _ ->
                editor.save {}
                // emit via a flow that the edits had been changed to reflect the new regions in the listening screen.
                bookReadingBundle.content.bookView.bookEditsFlow.emit(BookEdits(editor.currentState.pages))

                // Trigger a reload action after edit.
                dispatch(
                    BookEditorCommand.TriggerReloadAfterEdit(
                        location = RobustLocation.fromHack(
                            SerialLocation(cursor = bookReadingBundle.playbackControls.state.latestPlaybackCursor),
                        ),
                    ),
                )
            }.launchInHelper()
        // request BookEditor at the startup
        launchInHelper {
            dispatch(BookEditorCommand.EditBook)
        }
    }

    override fun destroy() {
        super.destroy()
        editorState.value?.destroy()
    }

    fun editBook() = dispatch(BookEditorCommand.EditBook)

    fun hidePages(pagesIndices: Array<Int>) = dispatch(BookEditorCommand.HidePages(pagesIndices))

    fun showPages(pagesIndices: Array<Int>) = dispatch(BookEditorCommand.ShowPages(pagesIndices))

    fun setRegionsOfInterests(pagesIndices: Array<Int>, regions: Array<BoundingBox>) =
        dispatch(BookEditorCommand.SetRegionsOfInterests(pagesIndices, regions.toList()))

    fun resetRegionsOfInterests(pagesIndices: Array<Int>) =
        dispatch(BookEditorCommand.ResetRegionsOfInterests(pagesIndices))

    fun setTitle(title: String) = dispatch(SetTitle(title = title))

    fun saveEdits() = dispatch(BookEditorCommand.SaveEdits)

    /**
     * **WARNING** This API is not recommended, it's built for clients that want to own the state of edits in their side
     * and once they are done editing, they send them back to SDK as bulk of edits in 1 single call with a callback
     * approach.
     *
     * Note: we still prefer to use SDK APIS above over this one to apply edits, and SDK will populate an updated
     * reactive state after each action.
     *
     * TODO to remove this once iOS migrates the scan flow module to reactive one that supports state flow instead
     *  of callback-approach.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    fun applyBatchEdits(pagesEdits: Array<PageEdits>, callback: Callback<Unit>) =
        callback.fromCo(scope = scope) {
            val editor = editorState.value ?: return@fromCo Result.Failure(
                error = SDKError.OtherException(
                    IllegalStateException("Editor shouldn't be null at this state."),
                ),
            )
            val state = internalReadyStateFlow.first()
            // in case regions are modified, reset any edit text before setting those region of interests.
            pagesEdits.forEach {
                if (!it.regions.contentEquals(state.pagesToEdit[it.pageIndex].regions)) {
                    editor.resetTextContent(it.pageIndex)
                }
            }
            val updatedPagesToEdit = pagesEdits.map { pageEdits ->
                editor.setRegionsOfInterest(pageEdits.pageIndex, pageEdits.regions)
                when (pageEdits.isHidden) {
                    true -> editor.hidePage(pageEdits.pageIndex)
                    false -> editor.showPage(pageEdits.pageIndex)
                }
                state.pagesToEdit[pageEdits.pageIndex].copy(
                    regions = pageEdits.regions,
                    isHidden = pageEdits.isHidden,
                    hasTextEdits = editor.currentState.pages[pageEdits.pageIndex].replacementPageContent != null,
                )
            }
            internalReadyStateFlow.emit(
                state.copy(pagesToEdit = updatedPagesToEdit.toTypedArray()),
            )

            // clears the selection
            dispatch(SelectionHelperCommand.ClearSelection)
            dispatch(BookEditorCommand.SaveEdits)
            Unit.successfully()
        }
}

internal sealed class BookEditorCommand {
    object EditBook : BookEditorCommand()
    class HidePages(val pagesIndices: Array<Int>) : BookEditorCommand()
    class ShowPages(val pagesIndices: Array<Int>) : BookEditorCommand()
    class SetRegionsOfInterests(val pagesIndices: Array<Int>, val regions: List<BoundingBox>) : BookEditorCommand()
    class ResetRegionsOfInterests(val pagesIndices: Array<Int>) : BookEditorCommand()
    object SaveEdits : BookEditorCommand()
    class TriggerReloadAfterEdit(val location: RobustLocation) : BookEditorCommand()
}

@JsExport
sealed class EditBookState {
    data class Ready(
        val pagesToEdit: Array<PagesEditWithImagePreview>,
        val totalPages: Int,
    ) : EditBookState() {
        override fun hashCode(): Int {
            return pagesToEdit.contentHashCode()
        }

        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false
            other as Ready
            return pagesToEdit.contentEquals(other.pagesToEdit)
        }
    }

    object NotReady : EditBookState()

    object NotAvailable : EditBookState()
}

@JsExport
data class PagesEditWithImagePreview(
    val pageIndex: Int,
    val isHidden: Boolean,
    val regions: Array<BoundingBox>,
    val hasTextEdits: Boolean,
    val viewport: Viewport,
    val imageProvider: ImageProvider,
) {
    override fun hashCode(): Int {
        var result = regions.contentHashCode()
        result = 31 * result + pageIndex.hashCode()
        result = 31 * result + isHidden.hashCode()
        result = 31 * result + viewport.hashCode()
        result = 31 * result + hasTextEdits.hashCode()
        return result
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false
        other as PagesEditWithImagePreview
        if (pageIndex != other.pageIndex) return false
        if (isHidden != other.isHidden) return false
        if (viewport != other.viewport) return false
        if (hasTextEdits != other.hasTextEdits) return false
        if (!regions.contentEquals(other.regions)) return false
        return true
    }
}

@JsExport
data class PageEdits(
    val pageIndex: Int,
    val isHidden: Boolean,
    val regions: Array<BoundingBox> = emptyArray(),
) {
    override fun hashCode(): Int {
        var result = regions.contentHashCode()
        result = 31 * result + isHidden.hashCode()
        result = 31 * result + pageIndex.hashCode()
        return result
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false
        other as PageEdits
        if (isHidden != other.isHidden) return false
        if (pageIndex != other.pageIndex) return false
        if (!regions.contentEquals(other.regions)) return false
        return true
    }
}

@JsExport
class ImageProvider internal constructor(
    private val bookView: BookView,
    private val pageIndex: Int,
) {
    fun getImage(
        options: BookPageRequestOptions,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) = callback.fromCo {
        bookView.coGetPages(arrayOf(pageIndex))
            .orThrow()
            .single()
            .coGetImage(options)
            .orThrow().successfully()
    }
}
