package com.speechify.client.api.audio

import kotlin.js.JsExport

@JsExport
data class AudioConfig(
    override val audioMediaFormat: AudioMediaFormat?,
) : AudioMediaFormatOptions

/* Interface extracted especially to be able to document the options once and for SDK dev to quickly find where it is
 actually used.
 */
@JsExport
interface AudioMediaFormatOptions {
    /**
     * NOTE: `null` means 'decided by Audio Server' (as per [docs](https://audio.docs.speechify.dev/synthesis/overview.html#formats))
     * but it can be specified only where the device supports any format (e.g. Safari browser doesn't support OGG at the
     * time of writing).
     */
    val audioMediaFormat: AudioMediaFormat?
}
