package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.book.toRawOrderedTextItems
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.last
import kotlinx.coroutines.flow.map
import kotlin.math.floor

/**
 * A base Book content Index class that holds the common logic between the [ApproximateBookIndexV1]
 * and [ApproximateBookIndexV2].
 */
internal abstract class BaseBookContentIndex(
    open val book: BookView,
) : WithScope(), ContentIndex {

    protected abstract val contentStatsFlow: Flow<ContentStats>

    override fun getCursorFromProgress(
        progress: ProgressFraction,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        val metadata = book.getMetadata()
        val numPages = metadata.numberOfPages
        val pageIndex = if (progress == 1.0) numPages - 1 else floor(numPages * progress).toInt()
        val page = book.coGetPages(arrayOf(pageIndex))
            .orReturn { return@fromCo it }
            .single()
        val content = page.getStableParsedPageContent().orReturn { return@fromCo it }.toRawOrderedTextItems()
        return@fromCo getCursorFromProgressHelper(
            metadata.numberOfPages,
            pageIndex,
            content.asSequence().map(BookPageTextContentItem::text).toList(),
            progress,
        ).successfully()
    }

    override fun getProgressFromCursor(cursor: ContentCursor, callback: Callback<ProgressFraction>) = callback.fromCo {
        // Optimization especially useful for the important case of opening the book for the first time
        if (cursor.isEqual(book.start)) {
            return@fromCo 0.0.successfully()
        }

        val pageIndex = book.getPageIndex(cursor)

        val page = book.coGetPages(arrayOf(pageIndex))
            .orReturn { return@fromCo it }
            .single()
        val content = page.getStableParsedPageContent().orReturn { return@fromCo it }.toRawOrderedTextItems()
        return@fromCo getProgressFromCursorHelper(
            book.getMetadata().numberOfPages,
            pageIndex,
            content.asSequence().map(BookPageTextContentItem::text).toList(),
            cursor,
        ).successfully()
    }

    override val contentAmountStateFlow: ExternalStateChangesFlow
        get() = contentStatsFlow.map {}

    override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
        contentStatsFlow.first().successfully()
    }

    override suspend fun getStatsIncludingPending(): ContentStats? =
        contentStatsFlow.last()

    override fun destroy() {
        scope.cancel()
    }
}

internal fun getCursorFromProgressHelper(
    numberOfPages: Int,
    pageIndex: Int,
    pageText: List<ContentText>,
    progress: Double,
): ContentCursor {
    if (pageText.isEmpty()) return ContentElementReference.forRoot().getChild(pageIndex).start
    val concatenatedPageText = ContentTextUtils.concat(pageText)
    val pageChars = concatenatedPageText.length
    val pageProgress = numberOfPages * progress - pageIndex.toDouble()
    val progressCharIndex = floor(pageProgress * pageChars).toInt()
    return concatenatedPageText.getFirstCursorAtIndex(progressCharIndex)
}

internal fun getProgressFromCursorHelper(
    numberOfPages: Int,
    pageIndex: Int,
    pageText: List<ContentText>,
    cursor: ContentCursor,
): Double {
    val progressThroughPages = pageIndex.toDouble() / numberOfPages.toDouble()
    if (pageText.isEmpty()) {
        return when (cursor) {
            is ContentTextPosition -> progressThroughPages
            is ContentElementBoundary -> when (cursor.boundary) {
                is ContentBoundary.START -> progressThroughPages
                is ContentBoundary.END -> (pageIndex + 1).toDouble() / numberOfPages.toDouble()
            }
        }
    }
    val text = ContentTextUtils.concat(pageText)
    val progressWithinPage = if (text.length > 1) {
        (text.getLastIndexOfCursor(cursor)).toDouble() / (text.length - 1).toDouble()
    } else {
        // Prevent NaN from single char pages without breaking existing progress behaviour.
        0.0
    }

    return progressThroughPages + progressWithinPage / numberOfPages
}
