package com.speechify.client.api.audio

@kotlinx.serialization.Serializable
data class SpeechMarksChunk(
    /**
     * Index into the text where the chunk starts.
     * Inclusive, so the first character of the chunk is exactly [startCharacterIndex].
     *
     * See [com.speechify.client.api.services.audio.AudioServerResponse.TextChunkToAudioChunkMapping.start]
     */
    val startCharacterIndex: Int,
    /**
     * Index into the text where the chunk ends.
     * Exclusive, so the last character of the chunk is [endCharacterIndex] - 1.
     * See [com.speechify.client.api.services.audio.AudioServerResponse.TextChunkToAudioChunkMapping.end]
     */
    val endCharacterIndex: Int,
    val startTimeInMilliseconds: Int,
    val endTimeInMilliseconds: Int,
)
