package com.speechify.client.api.services.importing

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import org.w3c.files.Blob

/**
 * Creates a bundle for a given [Blob] (Note: a [org.w3c.files.File] is also a [Blob]).
 */
@JsExport
fun ImportService.importBlobByUpload(
    /**
     * Note: a [org.w3c.files.File] is also a [Blob].
     */
    blob: Blob,
    options: ImportOptions,
    importableContentMetadata: ImportableContentMetadata,
    callback: Callback<SpeechifyURI>,
) =
    importFileByUpload(
        file = BinaryContentReadableRandomly(blob),
        mimeType = MimeType(blob.type),
        options = options,
        importableContentMetadata = importableContentMetadata,
        callback = callback,
    )

/**
 * Import a batch of [Blob]s (Note: a [org.w3c.files.File] is also a [Blob]).
 */
@JsExport
fun ImportService.importBlobsByBatchUpload(
    importFilesBatchOperationInputs: Array<ImportFilesBatchOperationInput<Blob>>,
    progressCallback: Callback<BatchOperationProgressResult<SpeechifyURI>>,
    completionCallback: Callback<Array<Result<SpeechifyURI>>>,
) = importFilesByBatchUpload(
    importFilesBatchOperationInputs.map {
        ImportFilesBatchOperationInput(
            BinaryContentReadableRandomly(it.file),
            mimeType = it.mimeType,
            options = it.options,
            importableContentMetadata = it.importableContentMetadata,
        )
    }.toTypedArray(),

    progressCallback = progressCallback,
    completionCallback = completionCallback,
)
