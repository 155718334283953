package com.speechify.client.api.content.view.web

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.internal.util.extensions.strings.isLowerCase

/**
 * A [WebPage] contains the model of the web page prepared for efficient processing by the Speechify products.
 */
internal data class WebPage(
    val root: WebPageNode.Element,
    val sourceUrl: String?,
    val tableOfContents: TableOfContents? = null,
) : Content by root

/**
 * A [WebPageNode] contains a DOM model of HTML content prepared for processing by the Speechify products in a manner
 * that is more efficient than accessing the data directly from the raw structures from the parser.
 * This includes: preparing [ContentElementReference]s for each node, wrapping the text content into the origin-tracking
 * [ContentText], preparing collections for allowing repeated lookups to be done efficiently, etc.
 */
internal sealed class WebPageNode : Content {
    /**
     * Models an Element of the DOM.
     */
    data class Element internal constructor(
        internal val ref: ContentElementReference,
        val tagName: String,
        val attributes: Array<WebPageElementAttribute>,
        val children: Array<WebPageNode>,
    ) : WebPageNode() {
        override val start: ContentCursor = ref.start
        override val end: ContentCursor = ref.end

        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Element

            if (ref != other.ref) return false
            if (tagName != other.tagName) return false
            if (!attributes.contentEquals(other.attributes)) return false
            if (!children.contentEquals(other.children)) return false

            return true
        }

        override fun hashCode(): Int {
            var result = ref.hashCode()
            result = 31 * result + tagName.hashCode()
            result = 31 * result + attributes.contentHashCode()
            result = 31 * result + children.contentHashCode()
            return result
        }
    }

    /**
     * Models a DOM TextNode.
     */
    data class Text internal constructor(
        private val ref: ContentElementReference,
        val rawText: String,
    ) :
        WebPageNode() {
        val text: ContentText = TextElementContentSlice.fromTextElement(ref, rawText)
        override val start: ContentCursor = text.start
        override val end: ContentCursor = text.end
    }

    companion object {
        /**
         * Returns a [Builder] with the root node created according to the data provided.
         */
        fun builder(
            tagName: String,
            attributes: List<WebPageElementAttribute>,
            ref: ObjectRef<Any?>,
            path: List<Int> = emptyList(),
            body: Builder.Element.() -> Unit = {},
        ) = if (path.isEmpty()) {
            Builder.Element(ContentElementReference.forRoot(ref), tagName, attributes).apply(body)
        } else {
            Builder.Element(ContentElementReference.fromPath(path, ref), tagName, attributes).apply(body)
        }
    }

    /**
     * The [Builder] and its constituents provide a DSL for constructing recursive [WebPageNode] models.
     */
    sealed class Builder {
        /**
         * Constructs a [WebPageNode] based on the build steps accumulated on the builder
         */
        abstract fun build(): WebPageNode

        /**
         * Builder for an [Element]
         */
        class Element internal constructor(
            private val reference: ContentElementReference,
            private val tagName: String,
            private val attributes: List<WebPageElementAttribute>,
        ) : Builder() {
            private val children: MutableList<Builder> = mutableListOf()

            fun addElement(
                tagName: String,
                attributes: List<WebPageElementAttribute> = listOf(),
                ref: ObjectRef<Any?>,
                body: Element.() -> Unit = {},
            ): Element = apply {
                children += Element(
                    reference.getChild(children.size, ref),
                    tagName,
                    attributes,
                ).apply(body)
            }

            fun addText(
                text: String,
                ref: ObjectRef<Any?>,
            ): Element = apply {
                children += Text(reference.getChild(children.size, ref), text)
            }

            override fun build(): WebPageNode.Element = Element(
                reference,
                tagName,
                attributes.toTypedArray(),
                children.map { it.build() }.toTypedArray(),
            )
        }

        /**
         * Builder for a [Text]
         */
        class Text internal constructor(
            private val ref: ContentElementReference,
            val text: String,
        ) :
            Builder() {
            override fun build(): WebPageNode.Text {
                return WebPageNode.Text(ref, text)
            }
        }
    }
}

internal fun WebPageNode.Element.getAttribute(name: String): String? {
    return this.attributes.find { it.name == name }?.value
}

internal fun WebPageNode.Element.hasAttribute(name: String, value: String): Boolean {
    return this.hasAttribute(name = name) { it == value }
}

internal fun WebPageNode.Element.hasAttribute(name: String, predicate: (String) -> Boolean): Boolean {
    return this.attributes.any { it.name == name && predicate(it.value) }
}

internal fun Array<WebPageElementAttribute>.getValueOfLowercaseAttr(attributeNameLowerCase: String): String? =
    this.getLowercaseAttr(attributeNameLowerCase)?.value

internal fun Array<WebPageElementAttribute>.getLowercaseAttr(attributeNameLowerCase: String): WebPageElementAttribute? {
/* TODO - optimize by turning `attributes` into a map (consider doing it lazily
    especially to save the work on contents of entire skipped branches)
*/
    require(attributeNameLowerCase.isLowerCase()) {
        "$attributeNameLowerCase must be specified as lowercase to keep all code consistent."
    }
    return this.firstOrNull { it.name.lowercase() == attributeNameLowerCase }
}

/**
 * Represents a single entry in the attributes map for a Web Page element.
 */
internal data class WebPageElementAttribute(val name: String, val value: String) {
    internal constructor(pair: BoundaryPair<String, String>) : this(name = pair.first, value = pair.second)
}
