/* The `JvmName` is a workaround for [KT-21186](https://youtrack.jetbrains.com/issue/KT-21186/MPP-Duplicate-JVM-class-name-with-same-named-commontarget-files-with-top-level-functions).
Else we get compilation error for JVM (`compileTestKotlinJvm`) when this file has both `expect` and non-`expect` functions:
`Duplicate JVM class name '.../CoroutinesKt' ...`
*/
@file:kotlin.jvm.JvmName("CoroutinesJvm")

package com.speechify.client.internal

import com.speechify.client.api.diagnostics.ErrorLogForUnhandledExceptionHandler
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.sdkErrorMessagePromptToInvestigateOwnCallbacksAndIncludeAllEventData
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Destructor
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorJob
import com.speechify.client.internal.util.extensions.coroutines.createTopLevelCoroutineScopeWithCompletableJob
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlinx.coroutines.plus
import kotlin.coroutines.CoroutineContext
import kotlin.js.JsExport

/**
 * A convenience overload of [launchTask], returning a [Destructor] through which the task can be cancelled.
 *
 * @param contextToMerge Can be used to add context data, e.g. `CoroutineName("some name")`.
 */
internal fun runTask(contextToMerge: CoroutineContext? = null, task: suspend CoroutineScope.() -> Unit): Destructor {
    val job = launchTask(contextToMerge) { task() }
    return {
        job.cancel()
    }
}

fun launchTask(
    /**
     * Can be used to add context data, e.g. `CoroutineName("some name")`.
     */
    contextToMerge: CoroutineContext? = null,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    task: suspend CoroutineScope.() -> Unit,
): Job {
    return getGlobalScopeWithContext(contextToMerge).launch(
        start = start,
    ) {
        task()
    }
}

/**
 * Preferred over [runTask], as it doesn't use [GlobalScope], but rather creates own top-level scope
 * (using [createTopLevelCoroutineScope]).
 */
internal fun launchTopLevel(
    /**
     * Can be used to add context data, e.g. `CoroutineName("some name")`.
     */
    contextToMerge: CoroutineContext? = null,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    task: suspend CoroutineScope.() -> Unit,
): Destructible {
    val (topLevelScope, jobToComplete) = createTopLevelCoroutineScopeWithCompletableJob(
        /* `shouldFailIfAnyChildFails` makes no difference here, because we don't reuse the scope */
        shouldFailIfAnyChildFails = true,
        contextToMerge = contextToMerge,
    )
    topLevelScope.launch(
        start = start,
    ) {
        task()
        jobToComplete.complete()
    }

    /*
     * Return the outer job, so that it's that one that gets cancelled and, in this way, everything is cleaned up.
     */
    return topLevelScope.coroutineContext.job
        .toDestructible()
}

/**
 * Use for coroutines that produce a value - equivalent to [CoroutineScope.async] but on the standalone global scope.
 *
 * @param contextToMerge Can be used to add context data, e.g. `CoroutineName("some name")`.
 */
fun <T> launchAsync(
    contextToMerge: CoroutineContext? = null,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    task: suspend CoroutineScope.() -> T,
): Deferred<T> {
    return getGlobalScopeWithContext(contextToMerge).async(
        start = start,
    ) {
        task()
    }
}

@OptIn(DelicateCoroutinesApi::class)
internal fun getGlobalScopeWithContext(
    contextToMerge: CoroutineContext? = null,
) = GlobalScope + createTopLevelScopesContext(contextToMerge)

internal fun Job.toDestructor(): Destructor = toDestructible()::destroy

internal fun Job.toDestructible(): Destructible = object : Destructible {
    override fun destroy() {
        this@toDestructible.cancel()
    }
}

/**
 * Can be used directly as a `Mixin` like: `class MyClass : Destructible by DestructibleByScope()`.
 */
open class DestructibleByScope(
    protected val scope: CoroutineScope,
) : Destructible {
    override fun destroy() {
        scope.cancel()
    }
}

@JsExport
abstract class WithScope internal constructor(
    scope: CoroutineScope = createTopLevelCoroutineScope(),
) : DestructibleByScope(
    scope = scope.let {
        it + it.coroutineContext.job.createChildSupervisorJob()
    },
)

internal fun createTopLevelCoroutineScope(
    /**
     * If `true` and any child scope fails, this will also fail this scope, also making it unable to produce children.
     * If `false`, this scope will be still able to produce children after any of them fails.
     *
     * See [SupervisorJob] and [kotlinx.coroutines.Job] for the underlying mechanisms and an explanation from Kotlin itself.
     */
    /* TODO consider removing the default here, surveying the usages and using appropriate choice */
    shouldFailIfAnyChildFails: Boolean = true,
    contextToMerge: CoroutineContext? = null,
): CoroutineScope {
    val (scope, _) = createTopLevelCoroutineScopeWithCompletableJob(
        shouldFailIfAnyChildFails = shouldFailIfAnyChildFails,
        contextToMerge = contextToMerge,
    )
    return scope
}

/**
 * Creates the context to use in the top level scopes - ensures that all unhandled errors are reported to
 * developers using the globally registered [com.speechify.client.api.diagnostics.DiagnosticReporter].
 */
internal fun createTopLevelScopesContext(
    contextToMerge: CoroutineContext? = null,
): CoroutineContext {
    var context: CoroutineContext = unhandledExceptionInCoroutineHandler
    if (contextToMerge != null) context += contextToMerge
    return context
}

internal expect fun newSingleThreadContextDispatcher(name: String): CoroutineDispatcher

private val unhandledExceptionInCoroutineHandler: CoroutineExceptionHandler by lazy {
    /* Eagerly getting the queueless reporters (DiagnosticReporter) as soon as the handler is needed for the first time,
     * as this is the last chance for throwing straight to the caller, (we are still in the same thread as them)
     * to advise them if they haven't registered one yet.
     */
    Log.ensureQueuelessReportersReady()

    // Narrow down to the interface appropriate for unhandled exceptions.
    val errorLog = Log as ErrorLogForUnhandledExceptionHandler

    CoroutineExceptionHandler { context, exception ->
        errorLog.logUnhandledException(
            exception = exception,
            message = "Unhandled exception in an asynchronous task." +
                " $sdkErrorMessagePromptToInvestigateOwnCallbacksAndIncludeAllEventData",
            properties = listOfNotNull(
                context[CoroutineName]?.let { "CoroutineName" to it.name },
                context[Job]?.let { "Job" to it.toString() },
            )
                .toMap(),
            unhandledExceptionsSourceId = "unhandledExceptionInCoroutineHandler",
        )
    }
}
