package com.speechify.client.api.adapters.archiveFiles

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.orThrow
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * A [ZipFileEntry] is an entry in the Zip Archive, which contains the path to the file inside the archive,
 * with createBinaryContentReadableRandomly creating the content at that path (whether by lazily extracting or not).
 * See sample implementation at Web Example (for JS target) or JVM test (for JVM target).
 */
@JsExport
abstract class ZipFileEntry {
    abstract val path: String
    protected abstract fun createBinaryContentReadableRandomly(
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<BinaryContentReadableRandomly>,
    )

    internal suspend fun coCreateBinaryContentReadableRandomly(): BinaryContentReadableRandomly {
        return suspendCoroutine { cont ->
            createBinaryContentReadableRandomly(cont::resume)
        }.orThrow()
    }
}

/**
 * This exposes a "View" of the archive — containing a list of [ZipFileEntry]'s that the client can choose
 * to implement however they see fit, with the ability to destroy resources that are created and are no longer used.
 */
@JsExport
abstract class ZipArchiveView {

    abstract val entries: Array<ZipFileEntry>

    protected abstract fun destroy(
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<Unit>,
    )

    internal suspend fun coDestroy() {
        return suspendCoroutine { cont ->
            destroy(cont::resume)
        }.orThrow()
    }
}

@JsExport
abstract class ArchiveFilesAdapter {

    protected abstract fun createViewOfZip(
        @Suppress("NON_EXPORTABLE_TYPE")
        data: BinaryContentReadableRandomly,
        callback: Callback<ZipArchiveView>,
    )

    protected abstract fun createViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(
        @Suppress("NON_EXPORTABLE_TYPE")
        data: ByteArray,
        callback: Callback<ZipArchiveView>,
    )

    protected abstract fun createViewOfZipFromByteArrayAndEncryptAnyTemporaryCreatedFiles(
        @Suppress("NON_EXPORTABLE_TYPE")
        data: ByteArray,
        callback: Callback<ZipArchiveView>,
    )

    internal suspend fun coCreateViewOfZip(data: BinaryContentReadableRandomly): ZipArchiveView {
        return suspendCoroutine { cont ->
            createViewOfZip(data, cont::resume)
        }.orThrow()
    }

    internal suspend fun coCreateViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(data: ByteArray):
        ZipArchiveView {
        return suspendCoroutine { cont ->
            createViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(data, cont::resume)
        }.orThrow()
    }

    internal suspend fun coCreateViewOfZipFromByteArrayAndEncryptAnyTemporaryCreatedFiles(data: ByteArray):
        ZipArchiveView {
        return suspendCoroutine { cont ->
            createViewOfZipFromByteArrayAndEncryptAnyTemporaryCreatedFiles(data, cont::resume)
        }.orThrow()
    }
}
