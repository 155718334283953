package com.speechify.client.bundlers.reading.classic

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.adapters.html.DOMElement
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.TextReader
import com.speechify.client.api.util.boundary.coReadAll
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.fromCoWithTelemetryLoggingErrors
import com.speechify.client.api.util.io.HtmlFileFromString
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentBundler
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload
import com.speechify.client.bundlers.content.SpeechifyContentBundler
import com.speechify.client.bundlers.content.createBundleForHtmlFromSdkElement
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.ReadingBundler
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import kotlin.js.JsExport

/**
 * A way to get a [ClassicReadingBundle] from any content, taking care of all the setup for a Classic Mode experience.
 */
@JsExport
class ClassicReadingBundler internal constructor(
    override val speechifyClient: SpeechifyClient,
    internal val contentBundler: ContentBundler,
    override val speechifyContentBundler: SpeechifyContentBundler,
    override val listeningBundler: ListeningBundler,
    internal val config: ClassicReadingBundlerConfig,
    private val eventsTrackerAdapter: EventsTrackerAdapter,
) : ReadingBundler() {

    internal suspend fun coCreateClassicBundle(
        contentBundle: ContentBundle,
        startingCursor: ContentCursor = contentBundle.standardView.start,
        bundleMetadata: BundleMetadata?,
        predecessorBundle: ReadingBundle? = null,
    ): Result<ClassicReadingBundle> =
        ClassicReadingBundle(
            dependencies = createDependencies(
                contentBundle,
                startingCursor,
                bundleMetadata,
                eventsTrackerAdapter,
            )
                .orReturn { return it },
            bundlingSourceTelemetryEventBuilder = currentTelemetryEvent(),
            bundleMetadata = bundleMetadata,
            predecessorBundle = predecessorBundle,
        ).successfully()

    /**
     * Creates a [ClassicReadingBundle] for the [standardView] provided.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForStandardView(
        standardView: StandardView,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ClassicReadingBundle>,
    ) =
        callback.fromCoWithTelemetryLoggingErrors(
            telemetryEventName = "ClassicReadingBundle.createBundleForStandardView",
        ) { telemetryEventBuilder ->
            telemetryEventBuilder.addUUID()
            coCreateClassicBundle(
                contentBundler.coCreateBundleForStandardView(
                    standardView = standardView,
                    sourceUrl = null,
                    importStartChoice = importStartChoice,
                    bundleMetadata = bundleMetadata,
                ),
                bundleMetadata = bundleMetadata,
            )
        }

    /**
     * Creates a [ClassicReadingBundle] for the specified plain [text].
     * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForPlainText(
        text: String,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ClassicReadingBundle>,
    ) =
        callback.fromCoWithTelemetryLoggingErrors(
            scope,
            telemetryEventName = "ClassicReadingBundler.createBundleForPlainText",
        ) {
            it.addUUID()
            it.addProperty("contentLength", text.length)
            coCreateClassicBundle(
                contentBundle = contentBundler.createBundleForPlainText(
                    text,
                    sourceUrl = null,
                    importStartChoice,
                    bundleMetadata = bundleMetadata,
                ),
                bundleMetadata = bundleMetadata,
            )
        }

    /**
     * @param text - The reader for the text. For a single string use [com.speechify.client.api.util.boundary.TextReaderFromString]
     * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForHtmlContent(
        text: TextReader,
        options: HtmlContentLoadOptions,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ClassicReadingBundle>,
    ) =
        callback.fromCoWithTelemetryLoggingErrors(
            scope,
            telemetryEventName = "ClassicReadingBundler.createBundleForHtmlContent",
        ) {
            it.addUUID()
            coCreateBundleForHtmlContent(text, options, it, importStartChoice, bundleMetadata)
        }

    /**
     * A variant of HTML-input bundle creation that takes the abstract SDK [DOMElement] as input, allowing
     * for custom implementations of the DOM tree, including generating it from documents of a different format,
     * or filtering/manipulating existing DOM trees.
     */
    fun createBundleForHtmlFromSdkElement(
        htmlElement: DOMElement,
        options: HtmlContentLoadOptions,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata,
        callback: Callback<ClassicReadingBundle>,
    ) = callback.fromCo {
        coCreateClassicBundle(
            contentBundle = contentBundler.createBundleForHtmlFromSdkElement(
                htmlElement = htmlElement,
                options = options,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
            bundleMetadata = bundleMetadata,
        )
    }

    private suspend fun coCreateBundleForHtmlContent(
        text: TextReader,
        options: HtmlContentLoadOptions,
        telemetryEventBuilder: TelemetryEventBuilder?,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata,
    ): Result<ClassicReadingBundle> =
        coCreateClassicBundle(
            contentBundle = contentBundler.createBundleForHtmlPayload(
                payload = ListenableBinaryContentPayload.Html(
                    contentWithMimeType = HtmlFileFromString(text.coReadAll().orReturn { return it }),
                    sourceUrl = options.sourceUrl,
                ),
                options = options,
                deviceLocalContent = null,
                importStartChoice = importStartChoice,
                bundleMetadata = bundleMetadata,
            ),
            bundleMetadata = bundleMetadata,
        )

    fun createBundleForOcrFiles(
        ocrFiles: Array<OCRFile>,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<ClassicReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        scope,
        telemetryEventName = "ClassicReadingBundler.createBundleFromOcrFiles",
    ) {
        it.addUUID()
        it.addProperty("numberOfPages", ocrFiles.size)
        coCreateClassicBundle(
            contentBundle = contentBundler
                .createBundleForScannedBook(
                    ocrFiles = ocrFiles,
                    deviceLocalContent = null,
                    importStartChoice = importStartChoice,
                    bundleMetadata = bundleMetadata,
                ),
            bundleMetadata = bundleMetadata,
        )
    }

    fun createForEditedBook(
        editor: BookEditor,
        /**
         * The "predecessor" (pre-edits) bundle which the new, edited bundle is based on. This also translates
         * current progress into the new bundle's progress.
         */
        predecessorBundle: ReadingBundle,
        bundleMetadata: BundleMetadata?,
        callback: Callback<ClassicReadingBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ClassicReadingBundler.createForEditedBook",
    ) {
        coCreateClassicBundle(
            contentBundle = contentBundler.createForEditedBook(
                edits = editor,
            ),
            bundleMetadata = bundleMetadata,
            startingCursor = predecessorBundle.playbackControls.state.latestPlaybackCursor,
            predecessorBundle = predecessorBundle,
        )
    }
}
