package com.speechify.client.internal.services.auth

import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.Service
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.subscription.PlatformFetcher
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * Note: Some tests for this class are ignored due to their flaky nature.
 * For more details, see [ReportUserChangesToPaymentServerServiceTest].
 */
internal class ReportUserChangesToPaymentServerService(
    firebaseAuthService: AuthService,
    private val platformFetcher: PlatformFetcher,
    private val keyValueStore: LocalKeyValueStorageAdapter,
) : Service {

    private val scope = createTopLevelCoroutineScope()

    init {
        firebaseAuthService
            .currentUserOrNullFlow
            .filterIsInstance<Result.Success<FirebaseAuthUser?>>()
            .map { it.value }
            .filterNotNull()
            .onEach { user ->
                val newHash = listOf(user.uid, user.email, user.displayName, user.isAnonymous)
                    .joinToString(":")

                val lastHashResult = suspendCoroutine {
                    keyValueStore.getSingle(LAST_USER_STATE_REPORTED_TO_PAYMENT_SERVER_KEY, it::resume)
                }

                when (lastHashResult) {
                    is Result.Success -> {
                        val hash = lastHashResult.value.decodeToString()
                        if (hash != newHash) {
                            // Update PS with the new data.
                            updateUserData(newHash)
                        }
                    }
                    is Result.Failure -> {
                        if (lastHashResult.error is SDKError.ResourceNotFound) {
                            // If there is no data yet also update PS.
                            updateUserData(newHash)
                        } else {
                            Log.e(failure = lastHashResult, sourceAreaId = "ReportUserChangesToPaymentServerService")
                        }
                    }
                }
            }
            .launchIn(scope)
    }

    private suspend fun updateUserData(hash: String) {
        platformFetcher.updateUser().orReturn {
            Log.e(failure = it, sourceAreaId = "ReportUserChangesToPaymentServerService.updateUserData")
            return
        }

        val safeResult = suspendCoroutine {
            // We store it so the next time we don't have to call Payment Server.
            keyValueStore.putSingle(
                LAST_USER_STATE_REPORTED_TO_PAYMENT_SERVER_KEY,
                hash.encodeToByteArray(),
                it::resume,
            )
        }

        safeResult.onFailure {
            Log.e(failure = it, sourceAreaId = "ReportUserChangesToPaymentServerService.updateUserData")
        }
    }

    override fun destroy() {
        scope.cancel()
    }

    companion object {
        const val LAST_USER_STATE_REPORTED_TO_PAYMENT_SERVER_KEY = "LAST_USER_STATE_REPORTED_TO_PAYMENT_SERVER"
    }
}
