package com.speechify.client.helpers.content.standard.streamable

import com.speechify.client.api.content.ObjectRef
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.Line
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.LineOfOnePart
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItemLines
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.TextWithRef
import com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ParagraphLines
import com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ParagraphParts
import com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.TopLevelItem
import kotlin.js.JsExport

@JsExport
open class StreamableContentChunk(val items: Array<TopLevelItem>)

/**
 * TODO document more
 *
 * # On the special property of being able to handle 'streamable content'
 * One extra feature over [com.speechify.client.helpers.content.standard.StandardViewProducer], and the reason for
 * the 'streamable' adjective, is that these components allow passing items that are partial (part of a paragraph), or
 * even **without the information whether they are partial or not**. Some content mediums are like this, for example
 * when receiving a book page by page, where the end of page causes a hard cut on a paragraph (it's also the way Google
 * Docs makes content available in chunks).
 * In such a situation, the content can be received already, and it will be the player pipeline making a best-effort
 * guess in connection with the start of the next page, to establish if this was also the end of the paragraph.
 */
@JsExport
class StreamableContentChunksBuilder(
    items: Array<TopLevelItem> = emptyArray(),
) : StreamableContentChunk(items) {
    internal operator fun plus(topLevelItem: TopLevelItem) =
        StreamableContentChunksBuilder(items + topLevelItem)

    internal operator fun plus(topLevelItems: Array<TopLevelItem>) =
        StreamableContentChunksBuilder(items + topLevelItems)

    /**
     * To add items created outside the builder pattern (e.g. created constructor calls).
     */
    @Suppress("FunctionName")
    fun WithItems(itemsToAdd: Array<TopLevelItem>): StreamableContentChunksBuilder =
        this + itemsToAdd

    /**
     * Creates a single chunk from multiple builder results.
     */
    @Suppress("FunctionName")
    fun Chunk(
        itemsToAdd: Array<StreamableContentChunksBuilder>,
    ): StreamableContentChunksBuilder =
        this + itemsToAdd.flatMap { it.items.asIterable() }.toTypedArray()

    /**
     * To be used only for when part of a chunk is needed.
     */
    @Suppress("FunctionName")
    fun ChunkPart(
        builderResult: Array<StreamableContentChunksBuilder>,
    ): Array<TopLevelItem> =
        builderResult.flatMap { it.items.asIterable() }.toTypedArray()

    /**
     * Use when the paragraph:
     * * has lines which need whitespace (use [ParagraphParts] or [ParagraphOfOnePart] if it doesn't)
     * * the lines don't have parts, e.g. hyperlinks, emphases (use [ParagraphLinesComplex] if it does)
     */
    @Suppress("FunctionName")
    fun ParagraphLinesSimple(
        lines: Array<TextWithRef>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            ParagraphLines(
                lines = lines.map { LineOfOnePart(it) }.toTypedArray(),
                ref = ref,
            )

    /**
     * Use when the paragraph:
     * * has lines which need whitespace (use [ParagraphParts] or [ParagraphOfOnePart] if it doesn't)
     * * each line has parts, e.g. hyperlinks, emphases (use [ParagraphLinesSimple] if it does)
     */
    @Suppress("FunctionName")
    fun ParagraphLinesComplex(
        lines: Array<Line>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            ParagraphLines(
                lines = lines,
                ref = ref,
            )

    /**
     * Use when the paragraph:
     * * doesn't come broken into lines, so doesn't need whitespace between the [parts]
     *   (use `ParagraphLines*` if it does)
     * * has parts, e.g. hyperlinks, emphases (use [ParagraphOfOnePart] if it doesn't)
     * * the parts' text includes whitespace (use [ParagraphPartsWithImplicitWhitespace] if they don't)
     */
    @Suppress("FunctionName")
    fun ParagraphParts(
        parts: Array<TextWithRef>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ParagraphParts(
                parts = parts,
                ref = ref,
            )

    /**
     * Use when the paragraph:
     * * doesn't come broken into lines, so doesn't need whitespace between the [partsWithNoWhitespace]
     *   (use `ParagraphLines*` if it does)
     * * has parts, e.g. hyperlinks, emphases (use [ParagraphOfOnePart] if it doesn't)
     * * the parts' text doesn't include whitespace (use [ParagraphParts] if they don't)
     */
    @Suppress("FunctionName")
    fun ParagraphPartsWithImplicitWhitespace(
        partsWithNoWhitespace: Array<TextWithRef>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            com.speechify.client.helpers.content.standard.streamable.items.topLevelItems
                .ParagraphPartsWithImplicitWhitespace(
                    partsWithNoWhitespace = partsWithNoWhitespace,
                    ref = ref,
                )

    /**
     * Use when the paragraph doesn't come broken into any parts, so:
     * * doesn't have lines which need whitespace (use `ParagraphLines*` if it does)
     * * doesn't have parts, e.g. hyperlinks, emphases (use [ParagraphParts] if it does)
     */
    @Suppress("FunctionName")
    fun ParagraphOfOnePart(
        part: TextWithRef,
    ): StreamableContentChunksBuilder =
        ParagraphParts(
            parts = arrayOf(part),
            ref = part.ref,
        )

    @Suppress("FunctionName")
    fun HeadingLines(
        lines: Array<Line>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.HeadingLines(
                lines = lines,
                ref = ref,
            )

    @Suppress("FunctionName")
    fun ListFromItemsLines(
        items: Array<ListItemLines>,
        ref: ObjectRef<Any?> = ObjectRef(null),
    ): StreamableContentChunksBuilder =
        this +
            com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.ListFromItemsLines(
                listItems = items,
                ref = ref,
            )
}
