package com.speechify.client.helpers.features

import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.audio.AudioControllerEvent
import com.speechify.client.api.audio.VoiceId
import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.audio.getEventsColdFlow
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.util.collections.maps.MutableMapBy
import com.speechify.client.internal.util.collections.maps.ThreadSafeMapWithBasics
import com.speechify.client.internal.util.collections.maps.asBlockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.set
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.shareIn
import kotlinx.coroutines.flow.transform
import kotlin.js.JsExport

@JsExport
class WordsListened(
    val voice: VoiceSpecOfAvailableVoice,
    val count: Int,
)

@JsExport
class WordsListenedEvent(val stats: Array<WordsListened>)

@JsExport
class WordsListenedHelper(
    audioController: AudioController,
) : WordsListenedHelperImpl(
    playingEventsFlow = audioController
        .getEventsColdFlow()
        .filterIsInstance<AudioControllerEvent.Playing>()
        .buffer(
            capacity = Channel.UNLIMITED,
        ),
)

/**
 * [WordsListenedHelperImpl] had to be extracted from [WordsListenedHelper] to prevent breaking change to clients.
 *  TODO - provide factory methods from bundle, [AudioController] or [com.speechify.client.helpers.ui.controls.PlaybackControls]
 *   make it easy for SDK consumers to discover this feature and decouple from [AudioController].
 */
@JsExport
open class WordsListenedHelperImpl internal constructor(
    playingEventsFlow: Flow<AudioControllerEvent.Playing>,
) : WithScope() {
    private val wordsListened: ThreadSafeMapWithBasics<VoiceSpecOfAvailableVoice, Int> =
        MutableMapBy<VoiceSpecOfAvailableVoice, VoiceId, Int>(
            getEquatableKey = { voice -> voice.idQualified },
        )
            .asBlockingThreadsafeMap()

    private var lastSeenWord = ""

    internal val wordsListenedEventsFlow: Flow<WordsListenedEvent> = playingEventsFlow
        .transform { playingEvent ->
            val voice = playingEvent.voice
            val currentWord = playingEvent.sentenceAndWordLocation?.word
            if (voice != null && currentWord != null && lastSeenWord != currentWord.text) {
                wordsListened[voice] = (wordsListened[voice] ?: 0) + 1
                lastSeenWord = currentWord.text

                emit(
                    value = WordsListenedEvent(
                        wordsListened
                            .entries
                            .map { (voice, count) -> WordsListened(voice, count) }
                            .toTypedArray(),
                    ),
                )
            }
        }
        .shareIn(
            scope = scope,
            started = SharingStarted.Eagerly,
        )

    fun addEventListener(listener: CallbackNoError<WordsListenedEvent>): Destructor =
        listener
            .multiShotFromFlowIn(
                scope = scope,
                flow = wordsListenedEventsFlow,
            )::destroy

    override fun destroy() {
        super.destroy()
        wordsListened.clear()
        lastSeenWord = ""
    }
}
