package com.speechify.client.api.services.audio

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing
import kotlin.js.JsExport

/**
 * The scheme to use when authenticating with the audio server
 *
 * @see AuthorizationTokenProvider
 */
@JsExport
enum class AuthorizationHeaderScheme {
    /**
     * Scheme used for [API Keys](https://audio.docs.speechify.dev/synthesis/authorization.html#api-key) or
     * [Subscription Tokens](https://audio.docs.speechify.dev/synthesis/authorization.html#subscription-token)
     */
    Bearer,

    /**
     * Scheme used for [App Check](https://audio.docs.speechify.dev/synthesis/authorization.html#app-check)
     */
    AppCheck,
    ;

    override fun toString(): String = name
}

/**
 * A provider for authorization tokens that will be used when authenticating with the audio server.
 *
 * See [the audio server's docs](https://audio.docs.speechify.dev/synthesis/authorization.html) on how authorization
 * works.
 */
/*
 * #TODOAudioServerTokenManagementInSDK
 * TODO: There may be opportunity to simplify this for SDK consumers and have the SDK take care of the token management
 *  (removing the need for this class) because:
 *  - [mobile products currently don't even authenticate users to AudioServer](https://speechifyworkspace.slack.com/archives/C03JLSQMBEJ/p1682523338355029?thread_ts=1682501091.886079&cid=C03JLSQMBEJ)
 *  - and there is [an idea to solve it by switching them to SubscriptionToken](https://speechifyworkspace.slack.com/archives/C03JLSQMBEJ/p1682527100664569?thread_ts=1682501091.886079&cid=C03JLSQMBEJ)
 *  and because `SubscriptionToken` is generated by the AudioServer and needs to be managed
 *  (created, cached, refreshed, retried on error), then there's a case for SDK being responsible for that.
 */
@JsExport
abstract class AuthorizationTokenProvider {
    abstract val scheme: AuthorizationHeaderScheme

    @Deprecated("Use `getValidToken` instead", ReplaceWith("getValidToken()"))
    protected open fun getToken(): String {
        throw UnsupportedOperationException(
            "`getToken` SDK implementation is not to be called.",
            /* ... it's just a placeholder for overriding by legacy implementations */
        )
    }

    /**
     * Return the authentication token to match with the [scheme] in order to authenticate with the audio server
     *
     * *Note:* This method will be called frequently, so consider caching the result.
     */
    protected open fun getValidToken(callback: Callback<String>) = callback.fromCo {
        @Suppress("DEPRECATION")
        getToken()
            .successfully()
    }

    internal suspend fun getValidToken(): String =
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing {
            getValidToken(it::resume)
        }
}
