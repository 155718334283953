package com.speechify.client.api.util.boundary.ranges

import kotlin.js.JsExport
import kotlin.math.abs

internal fun kotlin.ranges.IntRange.toBoundary(): IntRange =
    IntRange(
        kotlinIntRange = this,
    )

internal fun kotlin.ranges.IntRange.toBoundaryNonEmpty(): IntRangeNonEmpty =
    IntRangeNonEmpty(
        kotlinIntRange = this,
    )

@JsExport
open class IntRange
internal constructor(
    private val kotlinIntRange: kotlin.ranges.IntRange,
) {
    /**
     * The first value in the range.
     * Equivalent to [kotlin.ranges.IntProgression.first] and [kotlin.ranges.IntRange.start].
     */
    val first: Int
        get() =
            kotlinIntRange.first

    /**
     * The last value in the range (inclusive).
     * Equivalent to [kotlin.ranges.IntProgression.last] and [kotlin.ranges.IntRange.endInclusive].
     */
    val last: Int
        get() =
            kotlinIntRange.last

    /**
     * The number of values in the range.
     * The number is positive or zero.
     */
    val length: Int
        get() =
            abs(kotlinIntRange.last - kotlinIntRange.first + 1)

    val isEmpty: Boolean
        get() =
            kotlinIntRange.isEmpty()
}

/**
 * A version of [IntRange] that is guaranteed to have [isEmpty]=false and [length]>0.
 */
@JsExport
class IntRangeNonEmpty internal constructor(
    kotlinIntRange: kotlin.ranges.IntRange,
) : IntRange(
    kotlinIntRange = kotlinIntRange,
) {
    init {
        require(kotlinIntRange.isEmpty().not()) {
            "IntRangeNonEmpty must be non-empty"
        }
    }
}
