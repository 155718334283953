package com.speechify.client.reader.fixedlayoutbook.overlay

import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageContent
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageRegion

/**
 *  Find the overlays based on the exact point information from the viewPort.If we don't have the points data available
 *  it will use the fallback strategy to compute the overlay. Like [SearchBasedFLOverlayStrategy] is a good option for now if we want
 *  precise overlay boxes with the fallback.
 */
internal class PointsBasedFLOverlayStrategy(private val fallbackStrategy: FixedLayoutOverlayStrategy) :
    FixedLayoutOverlayStrategy {
    override suspend fun computeOverlay(computeOverlayContext: ComputeOverlayContext): List<FixedLayoutPageRegion> {
        val startPoint = computeOverlayContext.startPoint
        val endPoint = computeOverlayContext.endPoint

        return if (startPoint == null || endPoint == null) {
            fallbackStrategy.computeOverlay(computeOverlayContext)
        } else {
            val overlappingItems = computeOverlayContext.items.filter {
                it.text.hasNontrivialIntersectionWith(
                    computeOverlayContext.content,
                )
            }
            overlappingItems.mapIndexed { index, value ->
                val isFirst = index == 0
                val isLast = index == overlappingItems.size - 1
                FixedLayoutPageRegion.fromPageTextContent(
                    content = FixedLayoutPageContent(
                        text = value.text.text,
                        fontFamily = value.fontFamily,
                        normalizedBoundingBox = value.normalizedBox,
                    ),
                    startIndex = value.text.getFirstIndexOfCursor(computeOverlayContext.content.start),
                    endIndexExclusive = value.text.getLastIndexOfCursor(computeOverlayContext.content.end) + 1,
                    startPoint = if (isFirst) computeOverlayContext.startPoint else null,
                    endPoint = if (isLast) computeOverlayContext.endPoint else null,
                )
            }
        }
    }
}
