package com.speechify.client.api.adapters.blobstorage

import com.juul.indexeddb.Key
import com.juul.indexeddb.openDatabase
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableSequentially
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNative
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.readAllBytesToSingleArray
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.collections.arrays.toInt8Array
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import com.speechify.client.internal.util.io.arrayBuffer.extensions.toByteArray
import com.speechify.client.internal.util.io.blob.extensions.arrayBuffer
import js.core.jso

private const val METADATA_STORAGE = "metadata"
private const val BLOB_STORAGE = "blobs"

private external interface Metadata {
    var contentType: String?
}

@Deprecated("Use BlobStorageAdapter instead")
@JsExport
abstract class AbstractBlobStorage : BlobStorageAdapter()

private fun BlobStorageKey.toIndexDbKey(): Key {
    return Key(originalKey)
}

/**
 * Implementation of [BlobStorageAdapter] using the [IndexedDB API](https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API)
 * - [the W3C standard](https://w3c.github.io/IndexedDB/).
 */
@JsExport
class IndexDbBlobStorage : BlobStorageAdapter() {
    private val db = coLazy {
        openDatabase(STORAGE_NAME, 1) { database, _, _ ->
            database.createObjectStore(BLOB_STORAGE)
            database.createObjectStore(METADATA_STORAGE)
        }
    }

    private suspend fun loadMetadata(key: BlobStorageKey): Metadata? {
        return db.get().transaction(METADATA_STORAGE) {
            objectStore(METADATA_STORAGE).get(key.toIndexDbKey()).unsafeCast<Metadata?>()
        }
    }

    private suspend fun loadDataFromDb(key: BlobStorageKey): ByteArray? {
        return db.get().transaction(BLOB_STORAGE) {
            objectStore(BLOB_STORAGE).get(key.toIndexDbKey()) as ByteArray?
        }
    }

    override fun getBlob(
        key: BlobStorageKey,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?>,
    ) = callback.fromCo {
        getBlob(key)
    }

    private suspend fun getBlob(
        key: BlobStorageKey,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?> {
        val metadata = loadMetadata(key)
            ?: return null
                .successfully()

        val byteArray = loadDataFromDb(key)
            ?: return Result.Failure(
                /* Use an exception to know the entry-point via the stacktrace */
                SDKError.OtherException(
                    Exception(
                        "Data not found, while metadata was found (for {key})",
                    )
                        .apply {
                            addCustomProperty("key", key)
                            addCustomProperty("contentType", metadata.contentType.toString())
                        },
                ),
            )

        return BinaryContentWithMimeTypeFromNative(
            mimeType = metadata.contentType?.let { MimeType(fullValue = it) },
            binaryContent = BinaryContentReadableRandomly(
                int8Array = byteArray.toInt8Array(),
                contentType = metadata.contentType,
            ),
        )
            .successfully()
    }

    override fun putBlobByMove(
        key: BlobStorageKey,
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        /**
         * Using [putBlob] because in JavaScript the storage always serializes the payload to new form (unlike on
         * filesystems, where files can be put in a new place just by moving), so here there's no 'move', just copy.
         */
        putBlob(
            key = key,
            contentWithMimeType = contentToMove,
            callback = callback,
        )

    override fun putBlob(
        key: BlobStorageKey,
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) = callback.fromCo {
        putByteArrayGetAsReadableRandomly(
            key = key,
            contentType = contentWithMimeType.mimeType?.fullString,
            byteArray = contentWithMimeType.binaryContent.blob
                /** The first implementation was working on [ByteArray], so to continue, we're just reading the blob
                 *  to a single array and reusing the [putByteArrayGetAsReadableRandomly] but:
                 *  TODO it seems that [e.g. blob can be stored directly, at least in some browsers](https://stackoverflow.com/a/65584804),
                 *   so perhaps worth trying if switching to it gives any benefits, like smaller memory use (here while writing, but maybe especially when retrieved so that it can then not read the entire content).
                 * #TODOCanJsStorageBeOptimized
                 */
                .arrayBuffer()
                .toByteArray(),
        )
            .successfully()
    }

    override fun putSequenceGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) = callback.fromCo {
        return@fromCo putByteArrayGetAsReadableRandomly(
            key = key,
            contentType = content.mimeType?.fullString,
            /** The first implementation was working on [ByteArray], so to continue, we're just reading the blob
             *  to a single array and reusing the [putByteArrayGetAsReadableRandomly] but:
             * TODO perhaps worth trying if reading from [com.speechify.client.api.util.io.BinaryContentReadableSequentiallyFromReadableStream.readableStream]
             *  gives any benefits, like smaller memory use during this put, or using a `blob` so that retrieval can then not read the entire content.
             * See #TODOCanJsStorageBeOptimized
             */
            byteArray = content.binaryContent.readAllBytesToSingleArray(
                sourceAreaIdForWarningOnInefficientArrayCopy = "IndexDbBlobStorage.putSequenceGetReadableRandomly",
            ),
        )
            .successfully()
    }

    override fun putBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    ) = callback.fromCo {
        return@fromCo putByteArrayGetAsReadableRandomly(
            key = key,
            contentType = content.mimeType?.fullString,
            /** Again, the first implementation was to just work on the array, so to continue, just reading to a single
             *  array and reusing the [putByteArrayGetAsReadableRandomly] but:
             *  TODO perhaps worth trying if using something different here, [e.g. a blob](https://stackoverflow.com/a/65584804)
             *   gives any benefits, like smaller memory use during this put or using a `blob` so that retrieval can then not read the entire content.
             * #TODOCanJsStorageBeOptimized
             */
            byteArray = content.binaryContent.readAllBytesToSingleArray(
                sourceAreaIdForWarningOnInefficientArrayCopy = "IndexDbBlobStorage.putBytes",
            ),
        )
            .ignoreValue()
            .successfully()
    }

    private suspend fun putByteArrayGetAsReadableRandomly(
        key: BlobStorageKey,
        contentType: String?,
        byteArray: ByteArray,
    ): BinaryContentReadableRandomly =
        db.get().writeTransaction(BLOB_STORAGE, METADATA_STORAGE) {
            objectStore(BLOB_STORAGE).put(byteArray, key.toIndexDbKey())
            objectStore(METADATA_STORAGE).put(
                jso<Metadata> { this.contentType = contentType },
                key.toIndexDbKey(),
            )

            /** In the future we can consider releasing memory here and rather returning an implementation that
             * doesn't have the bytes in memory, but for now we keep it simple.
             */
            return@writeTransaction BinaryContentReadableRandomly(
                int8Array = byteArray.toInt8Array(),
                contentType = contentType,
            )
        }

    override fun deleteBlob(
        key: BlobStorageKey,
        callback: Callback<Boolean>,
    ) = callback.fromCo {
        db.get().writeTransaction(BLOB_STORAGE, METADATA_STORAGE) {
            objectStore(BLOB_STORAGE).run {
                val exists = this.count(key.toIndexDbKey()) > 0
                delete(key.toIndexDbKey())
                exists.successfully()
            }
            objectStore(METADATA_STORAGE).run {
                val exists = count(key.toIndexDbKey()) > 0
                delete(key.toIndexDbKey())
                exists.successfully()
            }
        }
    }
}
