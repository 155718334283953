package com.speechify.client.helpers.ui.overlays

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ObjectRef
import kotlin.js.JsExport

/**
 * The [ContentOverlayRange] represents a relation between an instance of [ContentText] with a foreign object and an index range.
 *
 * This is useful for modeling things like "where in the original content did this [ContentText] come from?" and "where was this [ContentText] rendered on the screen?"
 */
@JsExport
data class ContentOverlayRange<ContentRef : Any?>(
    /**
     * Some foreign object
     */
    val ref: ObjectRef<ContentRef>,

    /**
     * The start index of the relation between a [ContentText] and the [ref]
     */
    val startIndex: Int,

    /**
     * The end index (non-inclusive) of the relation between a [ContentText] and the [ref]
     */
    val endIndex: Int,
)
