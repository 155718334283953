package com.speechify.client.api.util.io

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.fromCoGetDestructible
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.arrays.toByteArray
import com.speechify.client.internal.util.collections.asyncIterable.asFlow
import js.core.AsyncIterable
import js.typedarrays.Uint8Array

@JsExport
actual open class BinaryContentReadableSequentially(
    /**
     * [ReadableStream<Uint8Array>](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream) is a built-in result of `fetch`.
     * [Uint8Array] because [this is the result type of the `ReadableStream` from fetch](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#fetch_stream)
     */
    @Suppress("MemberVisibilityCanBePrivate")
    val readableStreamOfBytes:
    /* TODO: Consider using [ReadableStream<Uint8Array>](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream)
     *  when available as a type here (e.g. on a Kotlin update), because that one [has methods for more performant reading, e.g. zero-copy `ReadableStreamBYOBReader`](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamBYOBReader#examples) */
        AsyncIterable<Uint8Array>,
) : BinaryContentReadableSequentiallyWithMultiplatformAndNativeAPI() {
    override fun collect(
        collectOne: CallbackNoError<ByteArray>,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        complete: Callback<Unit>,
    ): Destructible = complete.fromCoGetDestructible {
        readableStreamOfBytes
            .asFlow()
            .collect { chunk ->
                collectOne(chunk.toByteArray())
            }
        return@fromCoGetDestructible Unit
            .successfully()
    }
}
