@file:OptIn(FlowPreview::class)

package com.speechify.client.internal.util.collections.maps

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope

/**
 * This map persistently caches the last values in a [BlobStorageAdapter] and loads them on startup.
 * When an item is requested whose value came from the disk cache, we also run the operation required
 * to fetch the real value.
 *
 * This means that the best way to consume these values is to listen to the Flow to be notified once the real value
 * is obtained in favor of only reading the value from the returned flow once.
 *
 * Furthermore, only real values are written back to the disk cache, so anything that was fetched previously
 * but not refreshed in the current session will be lost and needs to be recalculated. This is a conscious decision
 * to avoid the cache growing indefinitely, and to avoid drift between the cached values and the real values.
 */
internal class LockingMapWithSubscribableValues<V> constructor(
    private val scope: CoroutineScope,
) : MapWithSubscribableValues<V> {

    private val backingMap = LockingThreadsafeMap<String, MutableStateFlow<V?>>()

    override suspend fun getSubscribableValueOrPut(key: String, defaultValue: suspend () -> V): StateFlow<V?> {
        val existingValue = backingMap.getOrPut(key) {
            val flow = createValueFlowForKey()

            // We trigger updating the cache with the real value in the background.
            scope.launch {
                supervisorScope {
                    flow.value = defaultValue()
                }
            }

            flow
        }

        return existingValue.asStateFlow()
    }

    override suspend fun getSubscribableValue(key: String): StateFlow<V?>? {
        return backingMap.getIncludingPending(key)?.await()
    }

    override suspend fun put(key: String, value: V) {
        backingMap.getOrPut(key) {
            createValueFlowForKey()
        }.value = value
    }

    private fun createValueFlowForKey(): MutableStateFlow<V?> {
        return MutableStateFlow(null)
    }
}
