package com.speechify.client.internal.sync

import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue

internal expect class AtomicInt(value: Int = 0) {
    fun get(): Int
    fun set(newInt: Int)
    fun getAndAdd(delta: Int): Int
    fun addAndGet(delta: Int): Int

    fun getAndIncrement(): Int
    fun getAndDecrement(): Int
}

internal var AtomicInt.value: Int
    get() = get()
    set(value) = set(value)

internal operator fun AtomicInt.plus(other: AtomicInt): Int = get() + other.get()

internal fun AtomicInt.incrementAndGet(): Int =
    addAndGet(1)

internal fun AtomicInt.decrementAndGet(): Int =
    addAndGet(-1)

/**
 * Use to indicate intentful lack of interest in the result of the increment.
 */
internal fun AtomicInt.increment() {
    getAndIncrement()
}

/**
 * Use to indicate intentful lack of interest in the result of the increment.
 */
internal fun AtomicInt.decrement() =
    getAndDecrement()
        .ignoreValue()

/**
 * Use to indicate intentful lack of interest in the result of the addition.
 */
internal fun AtomicInt.add(delta: Int) {
    addAndGet(delta)
}
