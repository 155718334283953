package com.speechify.client.bundlers.reading

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlin.js.JsExport

@JsExport
enum class EventType(val eventName: String) {
    ListeningStarted("Listening Started"),
    ListeningFinished("Listening Finished"),
    SpeedSelected("Speed Selected"),
    VoiceSelected("Voice Selected"),
    TextListened("Text Listened"),
    FileImported("File Imported"),
}

@JsExport
abstract class EventProperties() {
    abstract fun toMap(): BoundaryMap<Any?>
}

@JsExport
open class ListeningStartedEventProperties(
    open val app_platform: String,
    open val app_version: String,
    open val voice_name_initial: String,
    open val voice_speed_initial: Int,
    open val content_type: String,
    open val content_source: String?,
    open val content_name: String,
    open val content_uri: String?,
    open val content_hostname: String?,
    open val session_id: String,
    open val voice_id: String,
) : EventProperties() {

    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "app_platform" to app_platform,
            "app_version" to app_version,
            "voice_name_initial" to voice_name_initial,
            "voice_speed_initial" to voice_speed_initial,
            "content_type" to content_type,
            "content_source" to content_source,
            "content_name" to content_name,
            "content_uri" to content_uri,
            "content_hostname" to content_hostname,
            "session_id" to session_id,
            "voice_id" to voice_id,
        ).toBoundaryMap()
    }
}

@JsExport
data class ListeningFinishedEventProperties(
    val characters: Int,
    val words: Int,
    override val app_platform: String,
    override val app_version: String,
    override val voice_name_initial: String,
    override val voice_speed_initial: Int,
    val voice_name_final: String? = null,
    val voice_speed_final: Int? = null,
    val content_language: String,
    override val content_type: String,
    override val content_source: String?,
    override val content_name: String,
    override val content_uri: String?,
    override val content_hostname: String?,
    override val session_id: String,
    override val voice_id: String,
) : ListeningStartedEventProperties(
    app_platform, app_version, voice_name_initial, voice_speed_initial,
    content_type, content_source, content_name, content_uri, content_hostname,
    session_id, voice_id,
) {
    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "characters" to characters,
            "words" to words,
            "app_platform" to app_platform,
            "app_version" to app_version,
            "voice_name_initial" to voice_name_initial,
            "voice_speed_initial" to voice_speed_initial,
            "voice_name_final" to voice_name_final,
            "voice_speed_final" to voice_speed_final,
            "content_language" to content_language,
            "content_type" to content_type,
            "content_source" to content_source,
            "content_name" to content_name,
            "content_uri" to content_uri,
            "content_hostname" to content_hostname,
            "session_id" to session_id,
            "voice_id" to voice_id,
        ).toBoundaryMap()
    }
}

@JsExport
data class SpeedEventProperties(
    val app_platform: String,
    val app_version: String,
    val previous_speed: Int,
    val new_speed: Int,
    val voice_id: String,
) : EventProperties() {
    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "app_platform" to app_platform,
            "app_version" to app_version,
            "previous_speed" to previous_speed,
            "new_speed" to new_speed,
            "voice_id" to voice_id,
        ).toBoundaryMap()
    }
}

@JsExport
data class VoiceEventProperties(
    val app_platform: String,
    val app_version: String,
    val previous_voice: String,
    val previous_voice_id: String,
    val new_voice: String,
    val new_voice_id: String,
) : EventProperties() {
    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "app_platform" to app_platform,
            "app_version" to app_version,
            "previous_voice" to previous_voice,
            "previous_voice_id" to previous_voice_id,
            "new_voice" to new_voice,
            "new_voice_id" to new_voice_id,
        ).toBoundaryMap()
    }
}

@JsExport
data class CharactersListenedEventProperties(
    val app_platform: String,
    val app_version: String,
    val characters: Int,
    val words: Int,
    val voice: String,
    val voice_id: String,
    val speed: Int,
) : EventProperties() {
    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "app_platform" to app_platform,
            "app_version" to app_version,
            "characters" to characters,
            "words" to words,
            "voice" to voice,
            "voice_id" to voice_id,
            "speed" to speed,
        ).toBoundaryMap()
    }
}

@JsExport
data class FileImportedEventProperties(
    val app_platform: String,
    val app_version: String,
    val import_flow: String,
    val content_type: String,
    val content_source: String?,
    val content_name: String,
    val content_uri: String?,
    val content_hostname: String?,
) : EventProperties() {
    override fun toMap(): BoundaryMap<Any?> {
        return mapOf(
            "app_platform" to app_platform,
            "app_version" to app_version,
            "import_flow" to import_flow,
            "content_type" to content_type,
            "content_source" to content_source,
            "content_name" to content_name,
            "content_uri" to content_uri,
            "content_hostname" to content_hostname,
        ).toBoundaryMap()
    }
}
