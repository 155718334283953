package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.NavigationIntent
import com.speechify.client.reader.core.ResolvedNavigationIntent
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

internal class FixedLayoutBookNavigationHelper internal constructor(
    scope: CoroutineScope,
    intentsFlow: Flow<ResolvedNavigationIntent>,
) : Helper<FixedLayoutBookNavigationState>(scope) {
    private val lastFineIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    private val lastCoarseIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    override val stateFlow: StateFlow<FixedLayoutBookNavigationState> =
        combine(
            intentsFlow,
            lastFineIntentConsumed,
            lastCoarseIntentConsumed,
        ) { intent, lastFineConsumed, lastCoarseConsumed ->
            FixedLayoutBookNavigationState(
                coarseIntent = intent.nullIf { this == lastCoarseConsumed },
                fineIntent = intent.nullIf { this == lastFineConsumed },
            )
        }.stateInHelper(
            initialValue = FixedLayoutBookNavigationState(
                coarseIntent = null,
                fineIntent = null,
            ),
        )

    override val initialState = stateFlow.value

    val fineIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.fineIntent }
    val coarseIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.coarseIntent }

    init {
        commands
            .onEachInstance<FixedLayoutBookNavigationCommand.ConsumeCoarseIntent> {
                lastCoarseIntentConsumed.value = it.intent.resolvedIntent
            }
            .onEachInstance<FixedLayoutBookNavigationCommand.ConsumeFineIntent> {
                lastFineIntentConsumed.value = it.intent.resolvedIntent
            }
            .launchInHelper()
    }
}

internal sealed class FixedLayoutBookNavigationCommand {
    data class ConsumeCoarseIntent(val intent: CoarseFixedLayoutBookNavigationIntent) :
        FixedLayoutBookNavigationCommand()

    data class ConsumeFineIntent(val intent: FineFixedLayoutBookNavigationIntent) : FixedLayoutBookNavigationCommand()
}

internal data class FixedLayoutBookNavigationState(
    val fineIntent: ResolvedNavigationIntent?,
    val coarseIntent: ResolvedNavigationIntent?,
)

@JsExport
data class FineFixedLayoutBookNavigationIntent internal constructor(
    internal val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val region: FixedLayoutPageRegion,
) {
    val intent: NavigationIntent get() = resolvedIntent.intent

    fun consume() {
        dispatch(FixedLayoutBookNavigationCommand.ConsumeFineIntent(this))
    }
}

@JsExport
data class CoarseFixedLayoutBookNavigationIntent internal constructor(
    internal val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val pageIndex: Int,
) {
    val intent = resolvedIntent.intent

    fun consume() {
        dispatch(FixedLayoutBookNavigationCommand.ConsumeCoarseIntent(this))
    }
}
