package com.speechify.client.internal.util.collections.asyncIterable

import js.core.AsyncIterable
import js.core.AsyncIterator
import js.core.JsIterator
import js.core.Symbol
import js.core.Void
import js.promise.PromiseResult
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.asPromise
import kotlin.js.Promise

/**
 * A typesafe utility, because creating an [AsyncIterable] involves some boilerplate that is tricky (whether a function
 * should return another function or a result).
 */
internal fun <T> createAsyncIterableFromGetAsyncIterator(
    getAsyncIterator: () -> AsyncIterator<T>,
): AsyncIterable<T> {
    val result = js("{}")
    result[Symbol.asyncIterator] = getAsyncIterator
    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    return result as AsyncIterable<T>
}

/**
 * A typesafe utility, because creating an [AsyncIterator] involves some boilerplate that is tricky (whether a function
 * should return another function or a result).
 */
internal fun <T> createAsyncIteratorFromGetNext(
    getNext: () -> Deferred<SDKLocalJsYieldResult<T>>,
): AsyncIterator<T> =
    object : AsyncIterable.Iterator<T> {
        override val next: () -> Promise<JsIterator.Result<T, Void>>
            get() = {
                getNext()
                    .asPromise()
                    .then { it.toJsIteratorYieldResult() }
            }
        override val `return`: ((value: PromiseResult<Void>?) -> Promise<JsIterator.Result<T, Void>>)?
            get() = null
        override val `throw`: ((e: Throwable?) -> Promise<JsIterator.Result<T, Void>>)?
            get() = null
    }

/**
 * Workaround for [JsIterator.Result] being a sealed interface in the `js.core` package.
 */
internal class SDKLocalJsYieldResult<V>(
    override val done: Boolean,
    val valueOrNullIfDone: V?,
) : PackageLocalJsResult.YieldResult<V> {
    @Suppress("UNCHECKED_CAST", "UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    fun toJsIteratorYieldResult() =
        this as JsIterator.YieldResult<V>

    override val value: V
        get() = if (done) {
            throw IllegalStateException("value is not available when done=true")
        } else {
            valueOrNullIfDone!!
        }
}

/**
 * Copy of [JsIterator.Result].
 */
private sealed external interface PackageLocalJsResult<out T, out TReturn> {
    val done: Boolean

    /**
     * Copy of [JsIterator.YieldResult].
     */
    interface YieldResult<out TYield> : PackageLocalJsResult<TYield, Void> {
        val value: TYield
    }
}
