package com.speechify.client.internal.sync

import com.speechify.client.internal.util.extensions.coroutines.hasValue
import com.speechify.client.internal.util.extensions.coroutines.tryGetValue
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job
import kotlinx.coroutines.job
import kotlinx.coroutines.withTimeoutOrNull
import kotlin.time.Duration

internal class CoLateInit<V>(parent: Job? = null) {
    constructor(parentScope: CoroutineScope) : this(parent = parentScope.coroutineContext.job)

    /**
     * Use in code paths where the value is actually initialized from the start.
     */
    constructor(initializedValue: V) : this() {
        this.value = initializedValue
    }

    private val deferredValue = CompletableDeferred<V>(parent = parent)

    val isInitializationPending
        get() = deferredValue.isActive

    /**
     * Returns the value only if it is already available.
     * Returns [DeferredTryGetResult.NotAvailableYet] if it is not yet available.
     *
     * @throws CancellationException passed to [cancel] if it was called.
     * @throws CancellationException passed to [completeExceptionally] if it was called.
     */
    fun tryGetValue(): DeferredTryGetResult<V> =
        deferredValue.tryGetValue()

    val hasValue
        get() = deferredValue.hasValue

    suspend fun valueInited() =
        deferredValue.await()

    @OptIn(ExperimentalCoroutinesApi::class)
    var value: V
        /**
         * Throws if the value is not initialized.
         */
        get() =
            deferredValue.getCompleted()

        /**
         * Can only be assigned to once, else will throw [ValueAlreadyInitializedError]. Use [setValueIfNotSet] if this
         * is not desired.
         *
         * @throws ValueAlreadyInitializedError
         */
        set(value) {
            deferredValue.complete(value).let {
                if (!it) throw ValueAlreadyInitializedError(unsuccessfulValue = value)
            }
        }

    fun setValueIfNotSet(value: V) {
        trySetValueIfNotSet(value) // Ignore return value, as per the intent of the caller
    }

    fun trySetValueIfNotSet(value: V): Boolean =
        deferredValue.complete(value)

    fun completeExceptionally(exception: Throwable) =
        deferredValue.completeExceptionally(exception)

    fun cancel(cancellationCause: CancellationException? = null) =
        deferredValue.cancel(cause = cancellationCause)

    class ValueAlreadyInitializedError(val unsuccessfulValue: Any?) : Error("Value of the latch has already changed")
}

internal suspend fun <Value> CoLateInit<Value>.valueInitedWithTimeout(millis: Long): Value? =
    withTimeoutOrNull(millis) { valueInited() }

internal suspend fun <Value> CoLateInit<Value>.valueInitedWithTimeout(duration: Duration): Value? =
    withTimeoutOrNull(timeout = duration) { valueInited() }

internal fun <Value : Any> CoLateInit<Value>.valueInitedOrNull(): Value? =
    if (hasValue) value else null
