package com.speechify.client.internal.coroutines.fromNonCancellableAPIs

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.coroutines.launchTopLevelJobWithSameContext
import com.speechify.client.internal.util.collections.maps.mapOfNotNullValues
import com.speechify.client.internal.util.extensions.collections.flows.emitEnsuringReceivedOrBuffered
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.Continuation
import kotlin.coroutines.CoroutineContext

/**
 * Translates a 'callback-style' operation with a non-cancelable API to a cancellable suspending coroutine.
 * On cancellation the caller will simply detach from awaiting on the operation - **leaving the operation
 * running** (the only thing possible if cancellations are to be supported), but any errors from the operation will be
 * reported using the current context's [kotlinx.coroutines.CoroutineExceptionHandler] (unlike if
 * [kotlinx.coroutines.suspendCancellableCoroutine] was used directly on a non-cancellable API).
 *
 * Example when this is especially needed is when there is a need to apply a timeout with specified-duration over a
 * non-cancellable API, the reason for the exceeding that timeout will typically arrive in the form of an exception,
 * later than the desired timeout. This function ensures that this reason for the delay is eventually reported to
 * developers.
 *
 * NOTE: cancellable APIs should be generally preferred (and then best used with [kotlinx.coroutines.suspendCancellableCoroutine])
 * because **this function leaves the operation still running after the cancellation**.
 * But when the underlying API isn't cancellable, then using this function is better that using
 * [kotlinx.coroutines.suspendCancellableCoroutine] directly, because that one also leaves the operation running (since
 * there's no cancellation API), but only this one makes sure that any errors thrown by the operation are not lost.
 *
 * NOTE: If you don't need cancellations to cause a detach, then you should use [kotlin.coroutines.suspendCoroutine].
 * Such a call will not interrupt when the containing coroutine receives a cancellation, correctly reflecting the fact
 * that the API is non-cancellable.
 */
internal suspend fun <T> suspendCancellableCoroutineForNonCancellableAPIByDetach(
    /**
     * An extra action for when the cancellation leaves the Job running.
     * Useful for reporting wasted resources to developers.
     */
    onCancellationLeavingJobRunning: () -> Unit = {},
    /**
     * Same semantics as [kotlinx.coroutines.suspendCancellableCoroutine]'s parameter, just a better name than `block`:)
     */
    connectToResume: (Continuation<T>) -> Unit,
): T {
    val callerOutcomeChannel = MutableSharedFlow<CallerResultOutcome>(
        replay = 1,
        extraBufferCapacity = 0,
        onBufferOverflow = BufferOverflow.SUSPEND,
    )

    /**
     * Need this `exceptionObservedInResume` because, for some reason, at least in JVM, some exceptions passed to
     * [kotlin.coroutines.Continuation] get wrapped by some empty exception (see #UnwrappingExceptionNeededForSomeExceptions)
     */
    var exceptionObservedInResume: Throwable? = null
    val result = try {
        suspendCancellableCoroutine<T> { nativeContinuation ->
            connectToResume(
                object : Continuation<T> {
                    override val context: CoroutineContext
                        get() = nativeContinuation.context

                    override fun resumeWith(result: Result<T>) {
                        ifNotNull(result.exceptionOrNull()) { exception ->
                            exceptionObservedInResume = exception

                            /** We want to run a new Job, so that it won't be cancelled and any uncaught errors land in
                             * the current context's [kotlinx.coroutines.CoroutineExceptionHandler], as well as preventing hangs
                             * of any outer `coroutineScope` when the value has not resolved yet.
                             * Effectively, we do not respect structured concurrency (using [kotlinx.coroutines.suspendCancellableCoroutine]
                             * would do the same).
                             **/
                            nativeContinuation.context.launchTopLevelJobWithSameContext(
                                /* Give a name so that these exception can be distinguished when reported (will be part of `coroutineContext[Job].toString()`) */
                                coroutineName =
                                "suspendCancellableCoroutineForNonCancellableAPIByDetach.reportException",
                            ) {
                                when (callerOutcomeChannel.first()) {
                                    CallerResultOutcome.NotPropagatedDueToCancellation -> {
                                        throw exception
                                    }
                                    CallerResultOutcome.Propagated -> {
                                        // The result was passed to the caller so nothing to do here
                                    }
                                }
                            }
                        }
                        nativeContinuation.resumeWith(result)
                    }
                },
            )
        }
    } catch (cancellationException: CancellationException) {
        callerOutcomeChannel.emitEnsuringReceivedOrBuffered(CallerResultOutcome.NotPropagatedDueToCancellation)
        onCancellationLeavingJobRunning()
        /* Rethrow the `cancellationException` just to behave Kotlin-idiomatically */
        throw cancellationException
    } catch (e: Throwable) {
        callerOutcomeChannel.emitEnsuringReceivedOrBuffered(CallerResultOutcome.Propagated)
        /* Getting other exceptions than cancellations here indicates that the producer threw something, but
          still inside the timeout - the exception will go to the caller, so no need to report it here.
         */
        val cause = e.cause
        if (cause != null && cause == exceptionObservedInResume) {
            spyableOnUnwrappingException(e)
            throw cause
        } else {
            if (e != exceptionObservedInResume) {
                Log.d(
                    DiagnosticEvent(
                        sourceAreaId = "suspendCancellableCoroutineForNonCancellableAPIByDetach",
                        message = "An unexpected scenario. Exception observed in resume is neither same as nor the" +
                            " `cause` of the exception outside of the `suspendCancellableCoroutine`. Could be a " +
                            "change in Kotlin behavior or runtime difference.",
                        /** This can be investigated if the `exceptionObservedInResume`
                         can be removed. If this is a platform difference, then this log can be suppressed in specific
                         platforms. */
                        nativeError = e,
                        properties = mapOfNotNullValues(
                            "exceptionObservedInResume" to exceptionObservedInResume,
                        ),
                    ),
                )
            }

            throw e
        }
    }
    callerOutcomeChannel.emitEnsuringReceivedOrBuffered(CallerResultOutcome.Propagated)
    return result
}

private enum class CallerResultOutcome {
    Propagated,
    NotPropagatedDueToCancellation,
}

/**
 * Used for spying on in tests which detect if a change in Kotlin made #UnwrappingExceptionNeededInSuspendCancellableCoroutine unnecessary.
 */
internal fun spyableOnUnwrappingException(e: Throwable) {
}
