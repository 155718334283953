package com.speechify.client.api.services.adoption

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.services.adoption.models.UserUsageData
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Service
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.adoption.EcosystemAdoptionDataFetcher
import com.speechify.client.internal.services.adoption.EcosystemAdoptionDataTransformer
import com.speechify.client.internal.services.auth.AuthService
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach

internal class EcosystemAdoptionDelegate internal constructor(
    private val authService: AuthService,
    private val clientConfig: ClientConfig,
    private val ecosystemAdoptionDataFetcher: EcosystemAdoptionDataFetcher,
) : Service {

    private val scope = createTopLevelCoroutineScope()

    init {
        authService
            .currentUserOrNullFlow
            .filterIsInstance<Result.Success<FirebaseAuthUser?>>()
            .map { it.value }
            .filterNotNull()
            .onEach { user ->
                logUsage(user)
            }
            .launchIn(scope)
    }

    private suspend fun logUsage(user: FirebaseAuthUser): Result<Unit> {
        // Ignore writing anonymous user usage data
        if (user.isAnonymous) {
            return Unit.successfully()
        }

        val docResult = getUserUsageData().orReturn { return it }

        docResult.setForPlatform(clientConfig.appEnvironment, clientConfig.appVersion)

        ecosystemAdoptionDataFetcher.updateUserUsageData(user.uid, docResult)

        return Unit.successfully()
    }

    suspend fun getUserUsageData(): Result<UserUsageData> {
        val user = authService.getCurrentUser().orReturn { return it }
        return ecosystemAdoptionDataFetcher.getUserUsageData(user.uid).map {
            EcosystemAdoptionDataTransformer.toUserUsageData(it)
        }
    }

    override fun destroy() {
        scope.cancel()
    }
}
