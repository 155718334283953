package com.speechify.client.bundlers.content

import com.speechify.client.api.adapters.html.DOMElementFromW3CElement
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.HtmlFileFromString
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import org.w3c.dom.Element
import org.w3c.files.Blob

@JsExport
@Deprecated("Use `createBundleForHtmlDomElement`", ReplaceWith("createBundleForHtmlDomElement"))
fun ContentBundler.createBundleForHtmlElement(
    htmlElement: Element,
    importStartChoice: ImportStartChoice,
    bundleMetadata: BundleMetadata? = null,
    callback: Callback<ContentBundle.WebPageBundle>,
) = callback.fromCo {
    val options = HtmlContentLoadOptions(
        sourceUrl = null,
        isEntireDocument = null,
        isPostContentExtraction = null,
        isPostJavaScriptExecution = null,
    )
    this@createBundleForHtmlElement.createBundleForHtmlDomElement(
        htmlElement = htmlElement,
        options = options,
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
    )
}

/**
 * Get the [ContentBundle] for a Browser DOM element.
 * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
 */
@JsExport
fun ContentBundler.createBundleForHtmlDomElement(
    htmlElement: Element,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice,
    bundleMetadata: BundleMetadata? = null,
    callback: Callback<ContentBundle.WebPageBundle>,
) = callback.fromCo {
    this@createBundleForHtmlDomElement.createBundleForHtmlDomElement(
        htmlElement = htmlElement,
        options = options,
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
    )
}

internal suspend fun ContentBundler.createBundleForHtmlDomElement(
    htmlElement: Element,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
    bundleMetadata: BundleMetadata?,
): Result<ContentBundle.WebPageBundle> =
    createBundleForParsedHtml(
        htmlSerialized = HtmlFileFromString(htmlElement.outerHTML),
        htmlElement = DOMElementFromW3CElement(htmlElement),
        options = options,
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
    )
        .successfully()

/**
 * Creates a bundle for a given [Blob] (Note: a [org.w3c.files.File] is also a [Blob]).
 * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
 * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
 */
@JsExport
fun ContentBundler.createBundleForBlob(
    /**
     * Note: a [org.w3c.files.File] is also a [Blob].
     */
    blob: Blob,
    importStartChoice: ImportStartChoice,
    bundleMetadata: BundleMetadata? = null,
    callback: Callback<ContentBundle>,
) =
    createBundleForBinaryContent(
        content = BinaryContentReadableRandomly(blob),
        mimeType = MimeType(fullValue = blob.type),
        importStartChoice = importStartChoice,
        bundleMetadata = bundleMetadata,
        callback = callback,
    )
