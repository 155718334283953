package com.speechify.client.internal.services.library.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformFirebaseDirectLinksBody(
    val dynamicLinkInfo: PlatformDynamicLinkInfoBody,
)

@Serializable
internal data class PlatformDynamicLinkInfoBody(
    val domainUriPrefix: String,
    val link: String,
    val androidInfo: PlatformAndroidInfo,
    val iosInfo: PlatformIosInfo,
    val socialMetaTagInfo: PlatformSocialMetaTagInfo,
)

@Serializable
internal data class PlatformSocialMetaTagInfo(
    val socialTitle: String,
    val socialDescription: String?,
    val socialImageLink: String?,
)

@Serializable
internal data class PlatformIosInfo(
    val iosBundleId: String,
    val iosFallbackLink: String?,
    val iosAppStoreId: String,
)

@Serializable
internal data class PlatformAndroidInfo(
    val androidPackageName: String,
    val androidFallbackLink: String?,
)

@Serializable
internal data class ShortLinkResponse(
    val shortLink: String,
)
