package com.speechify.client.api.adapters.json

import js.core.Object
import js.core.Record
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject

@JsExport
actual abstract class BoundaryRecord(val data: Record<String, Any>) : JsonElementProvider {

    /**
     * This method is for internal use only, and should not be called.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    actual override fun toJsonElement(): JsonElement {
        return anyToJsonElement(data)
    }

    private fun anyToJsonElement(any: Any?): JsonElement {
        if (any == null) {
            return JsonNull
        }

        return when (any) {
            is Array<*> -> {
                buildJsonArray {
                    for (item in any) {
                        add(anyToJsonElement(item))
                    }
                }
            }
            is Object -> {
                buildJsonObject {
                    for ((key, value) in Object.entries(any)) {
                        put(key, anyToJsonElement(value))
                    }
                }
            }
            is String -> {
                JsonPrimitive(any)
            }
            is Number -> {
                JsonPrimitive(any)
            }
            is Boolean -> {
                JsonPrimitive(any)
            }
            else -> {
                mapUnsupportedValue(any).toJsonElement()
            }
        }
    }
    actual abstract fun mapUnsupportedValue(any: Any?): SpeechifyJsonElement
}
