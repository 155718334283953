package com.speechify.client.reader.core

import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.helpers.ui.controls.PlaybackTime
import com.speechify.client.internal.util.extensions.collections.flows.mapStateFlow
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

@JsExport
class ScrubberHelper internal constructor(
    scope: CoroutineScope,
    internal val playbackControls: PlaybackControls,
) : Helper<ScrubberState>(scope) {
    override val stateFlow: StateFlow<ScrubberState> = playbackControls.stateFlow.mapStateFlow {
        ScrubberState(
            progressFraction = it.uiPlaybackProgressFraction,
            estimatedPlaybackTime = it.playbackTime,
            furthestBufferProgressFraction = it.furthestBufferProgressFraction,
        )
    }
    override val initialState = stateFlow.value

    init {
        commands
            .onEachInstance<ScrubberCommand.Grab> { playbackControls.scrubber.grab() }
            .onEachInstance<ScrubberCommand.DragTo> { playbackControls.scrubber.scrub(it.progressFraction) }
            .onEachInstance<ScrubberCommand.Release> { playbackControls.scrubber.release() }
            .launchInHelper()
    }

    fun grab() {
        dispatch(ScrubberCommand.Grab)
    }

    fun dragTo(progressFraction: ProgressFraction) {
        dispatch(ScrubberCommand.DragTo(progressFraction))
    }

    fun release() {
        dispatch(ScrubberCommand.Release)
    }
}

internal sealed class ScrubberCommand {
    object Grab : ScrubberCommand()
    data class DragTo(val progressFraction: ProgressFraction) : ScrubberCommand()
    object Release : ScrubberCommand()
}

@JsExport
data class ScrubberState(
    val progressFraction: ProgressFraction,
    val estimatedPlaybackTime: PlaybackTime,
    val furthestBufferProgressFraction: ProgressFraction,
)
