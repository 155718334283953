package com.speechify.client.api.services.subscription.models

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class RewardBalance(
    /** The users unspent balance of usd cents. */
    val usdCents: Int,
    /** The number of rewards the user already claimed. */
    val claimedRewards: Int,
    /** The number of users the current user referred. */
    val usersReferred: Int,
    /** The total number of audiobook credits the user has earned. Spending them does not decrease this. */
    val totalAudiobookCredits: Int,
)
