package com.speechify.client.internal.util.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.serialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

interface SerializableEnum {
    val value: String
}

abstract class CustomEnumSerializer<E>(
    private val enumValues: Array<E>,
) : KSerializer<E> where E : Enum<E>, E : SerializableEnum {

    override val descriptor: SerialDescriptor = serialDescriptor<String>()

    override fun serialize(encoder: Encoder, value: E) {
        encoder.encodeString(value.value)
    }

    override fun deserialize(decoder: Decoder): E {
        val stringValue = decoder.decodeString()
        return enumValues.find { it.value == stringValue }
            ?: throw SerializationException("Unknown value: $stringValue")
    }
}
