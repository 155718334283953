package com.speechify.client.internal.services.scannedbook

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI.Companion.intoUri
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.GoogleCloudStorageUriFileId
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coDeleteDocument
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.adapters.ocr.OCRResult
import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.services.scannedbook.models.FirestoreScannedBook
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.success
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toSdkResult
import com.speechify.client.bundlers.content.GenericBinaryContentReadableRandomlyWithMimeTypePayload
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer
import com.speechify.client.internal.services.library.getParsedLibraryItemFirestoreDocument
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService.FirestoreScannedBookPageModel.Companion.NO_IMAGE_URL_PLACEHOLDER_STRING
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.IdGenerator
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

internal fun getScannedPagesCollectionRef(itemId: SpeechifyContentId) = "items/$itemId/scannedPages"

internal fun getScannedPagePath(itemId: SpeechifyContentId, pageId: SpeechifyContentId): PathInCollection =
    PathInCollection(getScannedPagesCollectionRef(itemId), pageId)

internal class PlatformScannedBookService(
    private val authService: AuthService,
    private val clientConfig: ClientConfig,
    private val firebaseStorage: ReadWriteThroughCachedFirebaseStorage,
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
    private val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    private val idGenerator: IdGenerator,
    private val libraryFirebaseDataFetcher: LibraryFirebaseDataFetcher,
) {
    internal suspend fun deleteBook(itemId: String): Result<Unit> {
        val libraryItem = getFirebaseInternalScannedBook(itemId).orReturn { return it }
        libraryItem.scannedBookFields.pageOrdering.map { it.pageId }
            .forEach { pageId ->
                firebaseFirestoreService.coDeleteDocument(
                    getScannedPagePath(itemId, pageId),
                ).orReturn { return it }
            }
        return firebaseFunctionsService.removeLibraryItem(
            LibraryFirebaseTransformer.buildLibraryManagementFunctionPayload(itemId),
        )
    }

    internal suspend fun replacePage(scannedBookId: String, pageId: String, file: OCRFile): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }

        val imageStorageLink = uploadFileToBucket(file.source, scannedBookId, pageId).orReturn { return it }

        val ocrResult = file.ocrResult
        val scannedBookFirestorePayload = buildScannedBookFirestorePayload(
            owner = user.uid,
            firebaseTimestampAdapter = firebaseTimestampAdapter,
            textContent = ocrResult.textContent,
            imageUrl = imageStorageLink,
            viewport = ocrResult.imageDimensions,
        )

        return firebaseFirestoreService.coSetDocument(
            getScannedPagePath(scannedBookId, pageId),
            scannedBookFirestorePayload,
        )
    }

    internal suspend fun deletePages(scannedBookId: String, pageIds: Array<String>): Result<Unit> {
        val libraryItem = getFirebaseInternalScannedBook(scannedBookId)
            .orReturn { return it }

        val newOrderedPageIds = libraryItem.scannedBookFields.pageOrdering
            .asSequence()
            .filter { it.pageId !in pageIds }
            .map { it.pageId }

        libraryFirebaseDataFetcher.updateItemDataFromParams(
            scannedBookId,
            SdkBoundaryMap.empty<Any?>().libraryItemWithScannedBookFields(orderedPageIds = newOrderedPageIds),
        ).orReturn { return it }

        pageIds.forEach { pageId ->
            firebaseFirestoreService.coDeleteDocument(
                getScannedPagePath(scannedBookId, pageId),
            ).orReturn { return it }
        }
        return success()
    }

    internal suspend fun addPages(scannedBookId: String, pages: Array<OCRFile>): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }
        val libraryItem = getFirebaseInternalScannedBook(scannedBookId).orReturn { return it }

        val orderedPageIds = libraryItem.scannedBookFields.pageOrdering
            .mapTo(mutableListOf()) { it.pageId }

        val lastIndex = orderedPageIds.lastIndex
        val newPageResults = pages.mapIndexed { index, page ->
            kotlin.runCatching {
                addPageGetId(user, scannedBookId, page.ocrResult, page.source)
            }.toSdkResult().orReturn { return it }
        }
        orderedPageIds.addAll(newPageResults.map { it.pageId })

        return libraryFirebaseDataFetcher.updateItemDataFromParams(
            scannedBookId,
            SdkBoundaryMap.empty<Any?>()
                .libraryItemWithScannedBookFields(orderedPageIds = orderedPageIds.asSequence()),
        )
    }

    /**
     * This adds a new page for the OCR fallback feature when looking at scanned pages in PDFs.
     */
    internal suspend fun addPageForOcrFallback(scannedBookId: String, pageIndex: Int, page: OCRResult): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }
        val libraryItem = getFirebaseInternalScannedBook(scannedBookId).orReturn { return it }
        val orderedPageIds = libraryItem.scannedBookFields.pageOrdering
            .mapTo(mutableListOf()) { it.pageId }

        val existingPage = orderedPageIds.getOrNull(pageIndex)
        if (existingPage != null && existingPage != PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER) {
            // OCR page results already exist.
            return Unit.successfully()
        }

        // We don't support a sparse tracking of scanned pages yet, so just fill the list to the correct size.
        while (orderedPageIds.size <= pageIndex) {
            orderedPageIds.add(PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER)
        }
        val newPageResult = runCatching {
            addPageGetId(
                user,
                scannedBookId,
                page,
                null,
            )
        }.toSdkResult().orReturn { return it }
        orderedPageIds[pageIndex] = newPageResult.pageId

        return libraryFirebaseDataFetcher.updateItemDataFromParams(
            scannedBookId,
            SdkBoundaryMap.empty<Any?>()
                .libraryItemWithScannedBookFields(orderedPageIds = orderedPageIds.asSequence()),
        )
    }

    internal suspend fun addPageWithId(
        pageId: String,
        user: FirebaseAuthUser,
        scannedBookId: String,
        pageContent: OCRResult,
        pageImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>? = null,
    ): Result<UploadPageResult> {
        val imageUrl = if (pageImage != null) {
            uploadFileToBucket(pageImage, scannedBookId, pageId).orThrow()
        } else {
            // Since we can always grab the image from the PDF no need to store it.
            // To not break older clients from parsing this we still write a value.
            // Once all clients are updated to support nullable image urls, we can remove writing this value.
            NO_IMAGE_URL_PLACEHOLDER_STRING
        }

        firebaseFirestoreService.coSetDocument(
            path = getScannedPagePath(scannedBookId, pageId),
            value = buildScannedBookFirestorePayload(
                owner = user.uid,
                firebaseTimestampAdapter = firebaseTimestampAdapter,
                textContent = pageContent.textContent,
                imageUrl = imageUrl,
                viewport = pageContent.imageDimensions,
            ),
        ).orThrow()

        return UploadPageResult(
            pageId = pageId,
            pageImageUrl = imageUrl,
        ).successfully()
    }

    internal suspend fun addPageGetId(
        user: FirebaseAuthUser,
        scannedBookId: String,
        pageContent: OCRResult,
        pageImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>? = null,
    ): Result<UploadPageResult> = addPageWithId(
        pageId = idGenerator.getGuidAsString(),
        user = user,
        scannedBookId = scannedBookId,
        pageContent = pageContent,
        pageImage = pageImage,
    )

    internal class UploadPageResult(
        val pageId: String,
        val pageImageUrl: String,
    )

    @Serializable
    internal data class FirestoreScannedBookPageModel(
        val createdAt: DateTime,
        /**
         * URL pointing at our cloud storage bucket where the page image is stored,
         * or [NO_IMAGE_URL_PLACEHOLDER_STRING] if the page is from the OCR fallback of a PDF.
         *
         * Warning don't read this instead use [imageUrlOrNull]
         */
        private val imageUrl: String? = null,
        val owner: String,
        val textContent: List<OCRTextContent>,
        val viewport: Viewport,
    ) {

        /**
         * URL pointing at our cloud storage bucket where the page image is stored,
         * or `null` if the page is from the OCR fallback of a PDF.
         */
        @Transient
        val imageUrlOrNull = if (imageUrl == NO_IMAGE_URL_PLACEHOLDER_STRING) null else imageUrl

        companion object {
            const val NO_IMAGE_URL_PLACEHOLDER_STRING = "NO_IMAGE_UPLOADED"
        }
    }

    internal suspend fun getPage(scannedBookId: String, pageId: String): Result<FirestoreScannedBookPageModel> {
        val docPath = getScannedPagePath(scannedBookId, pageId)
        val snapshot = firebaseFirestoreService
            .coGetDocument(docPath)
            .orReturn { return it }

        return if (snapshot is FirebaseFirestoreDocumentSnapshot.Exists) {
            snapshot.value()
        } else {
            Result.Failure(SDKError.ResourceNotFound(docPath, "Scanned Page not found on firestore"))
        }
    }

    /**
     * This gets a scanned page by its page index. For pages that haven't been scanned null will be returned.
     * This is mostly intended to be used by the OCR fallback feature.
     */
    internal suspend fun getPageByPageIndex(scannedBookId: String, pageIndex: Int):
        Result<FirestoreScannedBookPageModel?> {
        val libraryItem = getFirebaseInternalScannedBook(scannedBookId).orReturn { return it }
        val orderedPageIds = libraryItem.scannedBookFields.pageOrdering
            .mapTo(mutableListOf()) { it.pageId }
        val pageId = orderedPageIds.getOrNull(pageIndex)
            ?: return Result.Success(null)
        if (pageId == PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER) {
            return Result.Success(null)
        }

        return getPage(scannedBookId, pageId)
    }

    internal suspend fun reorderPages(scannedBookId: String, pageOrder: Array<String>): Result<Unit> {
        return libraryFirebaseDataFetcher.updateItemDataFromParams(
            scannedBookId,
            SdkBoundaryMap.empty<Any?>().libraryItemWithScannedBookFields(orderedPageIds = pageOrder.asSequence()),
        )
    }

    internal suspend fun getScannedBookFromScannedBookId(
        scannedBookId: SpeechifyContentId,
    ): Result<FirestoreScannedBook> {
        val firebaseInternalScannedBook = getFirebaseInternalScannedBook(scannedBookId).orReturn { return it }
        val pagesOrderingArray = firebaseInternalScannedBook.scannedBookFields.pageOrdering.map { it.pageId }
        return FirestoreScannedBook(
            SpeechifyEntityType.SCANNED_BOOK.intoUri(scannedBookId),
            { getPage(scannedBookId, it) },
            pagesOrderingArray.size,
            pagesOrderingArray,
            clientConfig,
            firebaseStorage,
        ).successfully()
    }

    private suspend fun getFirebaseInternalScannedBook(
        scannedBookId: SpeechifyContentId,
    ): Result<FirebaseInternalScannedBook> = firebaseFirestoreService
        .getParsedLibraryItemFirestoreDocument<FirebaseInternalScannedBook>(scannedBookId).map {
            val telemetryEventBuilder = currentTelemetryEvent()
            if (it.scannedBookFields.pageOrdering.isEmpty()) {
                // This should not happen, but we can still try to present something to the user.
                telemetryEventBuilder?.addProperty("fallbackOnMissingScannedPageOrderingSucceeded", true)
                val scannedPages = firebaseFirestoreService.queryDocuments(getScannedPagesCollectionRef(scannedBookId))
                    // This should give us the order the user intended (or something close to it).
                    .orderBy("createdAt", DocumentQueryBuilder.Direction.Ascending)
                    .coFetch().orDefaultWith {
                        telemetryEventBuilder?.addProperty("fallbackOnMissingScannedPageOrderingSucceeded", false)
                        emptyArray()
                    }
                it.copy(
                    scannedBookFields = ScannedBookFields(
                        pageOrdering = scannedPages.mapNotNull { page ->
                            when (page) {
                                is FirebaseFirestoreDocumentSnapshot.Exists -> ScannedBookFields.OrderingEntry(
                                    pageId = page.key,
                                )

                                FirebaseFirestoreDocumentSnapshot.NotExists -> null
                            }
                        },
                    ),
                )
            } else {
                it
            }
        }

    private suspend fun uploadFileToBucket(
        file: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        id: SpeechifyContentId,
        pageId: String,
    ): Result<String> {
        val user = authService.getCurrentUser().orReturn { return it }

        val storageBucketPath = GoogleCloudStorageUriFileId(
            "gs://${clientConfig.googleProjectId}.appspot.com/" +
                "multiplatform/scannedbook/images/${user.uid}/$id/pages/$pageId",
        )

        firebaseStorage.putFile(storageBucketPath, GenericBinaryContentReadableRandomlyWithMimeTypePayload(file))

        return firebaseStorage.getDownloadUrl(storageBucketPath).successfully()
    }

    companion object {
        /**
         * Since the tracking between page index and page ID is not sparse we need to track something for pages that
         * aren't scanned (like in the PDF OCR fallback). We set this string instead of the usual generated UUID
         * to indicate that there is no scanned content for this page.
         */
        const val PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER = "PAGE_OF_ORIGINAL_DOC"
    }
}
