package com.speechify.client.api.content.view.epub

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.epub.EpubNavPoint
import com.speechify.client.api.content.epub.EpubNavigation
import com.speechify.client.api.content.epub.EpubStartOfMainContent
import com.speechify.client.api.content.startofmaincontent.RawStartOfMainContent
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.getAttribute
import com.speechify.client.api.util.Destructible

internal interface EpubView : Content, Destructible {
    val initialNavPointsToTocEntries: Map<EpubNavPoint, OrderedTocEntry>

    fun getMetadata(): EpubMetadata
    fun getChapterIndex(cursor: ContentCursor): Int
    suspend fun getChapters(indexes: List<Int>): List<EpubChapter>
    suspend fun getMainContentStartCursor(remoteStartMainOfContent: RawStartOfMainContent.Epub?): ContentCursor?
    suspend fun getChaptersEstimatedContentRatios(): Map<Int, Double>

    suspend fun resolveMainContentStartCursor(
        remoteStartMainOfContent: RawStartOfMainContent.Epub?,
        resolveRemoteStartMainOfContent: (
            staticStartOfMainContent: RawStartOfMainContent.Epub,
        ) -> EpubStartOfMainContent?,
        localParsedStartOfMainContent: EpubStartOfMainContent?,
    ): ContentCursor? {
        val mainContentStartFromRemoteOrFallbackToLocal = remoteStartMainOfContent?.let {
            resolveRemoteStartMainOfContent(remoteStartMainOfContent)
        } ?: localParsedStartOfMainContent ?: return null

        if (mainContentStartFromRemoteOrFallbackToLocal.chapterIndex == 0 &&
            mainContentStartFromRemoteOrFallbackToLocal.fragment == null
        ) {
            return null
        }

        val chapter = getChapters(indexes = listOf(mainContentStartFromRemoteOrFallbackToLocal.chapterIndex)).single()
        val root = chapter.getContent().root

        fun findBodyElement(node: WebPageNode): WebPageNode.Element? =
            when (node) {
                is WebPageNode.Element -> {
                    if (node.tagName.lowercase() == "body") {
                        node
                    } else {
                        node.children.firstNotNullOfOrNull { findBodyElement(it) }
                    }
                }

                is WebPageNode.Text -> null
            }

        fun findFirstTextNode(node: WebPageNode): WebPageNode.Text? {
            // First pass: find non-empty text node
            fun findNonEmptyTextNode(currentNode: WebPageNode): WebPageNode.Text? {
                when (currentNode) {
                    is WebPageNode.Text -> {
                        if (currentNode.text.text.isNotBlank()) return currentNode
                    }

                    is WebPageNode.Element -> {
                        for (child in currentNode.children) {
                            findNonEmptyTextNode(child)?.let { return it }
                        }
                    }
                }
                return null
            }

            // Second pass: find any text node (including empty)
            fun findAnyTextNode(currentNode: WebPageNode): WebPageNode.Text? {
                when (currentNode) {
                    is WebPageNode.Text -> return currentNode
                    is WebPageNode.Element -> {
                        for (child in currentNode.children) {
                            findAnyTextNode(child)?.let { return it }
                        }
                    }
                }
                return null
            }

            return findNonEmptyTextNode(node) ?: findAnyTextNode(node)
        }

        fun findFirstTextNodeAfter(startNode: WebPageNode): WebPageNode.Text? {
            var foundStartNode = false

            fun traverse(node: WebPageNode): WebPageNode.Text? {
                when (node) {
                    is WebPageNode.Text -> {
                        if (foundStartNode && node.text.text.isNotBlank()) {
                            return node
                        }
                    }

                    is WebPageNode.Element -> {
                        // Check if this element is or contains our start node
                        if (node === startNode || node.children.any { it === startNode }) {
                            foundStartNode = true
                        }

                        for (child in node.children) {
                            traverse(child)?.let { return it }
                        }
                    }
                }
                return null
            }

            return traverse(root)
        }

        if (mainContentStartFromRemoteOrFallbackToLocal.fragment == null) {
            // Search in body if found, otherwise fallback to root
            return (
                findBodyElement(root)?.let {
                    findFirstTextNode(it)
                } ?: findFirstTextNode(root)
                )?.start ?: root.start
        }

        fun findNodeWithId(node: WebPageNode, targetId: String): WebPageNode? {
            if (node is WebPageNode.Element) {
                // Check if current node has matching ID
                if (node.getAttribute("id") == targetId) {
                    return node
                }
                // Depth-first search in children; return early if found
                for (child in node.children) {
                    findNodeWithId(child, targetId)?.let { return it }
                }
            }
            return null
        }

        // Find the node with the specified ID
        val nodeWithFragmentId = findNodeWithId(root, mainContentStartFromRemoteOrFallbackToLocal.fragment)
            ?: return root.start

        // Try to find first non-empty text node within fragment node
        val firstNonEmptyInFragment = when (nodeWithFragmentId) {
            is WebPageNode.Text -> if (nodeWithFragmentId.text.text.isNotBlank()) nodeWithFragmentId else null
            is WebPageNode.Element -> findFirstTextNode(nodeWithFragmentId)?.takeIf { it.text.text.isNotBlank() }
        }

        // If no non-empty text found within fragment, look for next non-empty text in document
        val firstNonEmptyAfterFragment = firstNonEmptyInFragment
            ?: findFirstTextNodeAfter(nodeWithFragmentId)?.takeIf { it.text.text.isNotBlank() }

        return firstNonEmptyAfterFragment?.start ?: root.start
    }
}

internal fun EpubNavigation?.toTocEntries(): Map<EpubNavPoint, OrderedTocEntry> {
    if (this == null) {
        return emptyMap()
    }

    val resultMap = mutableMapOf<EpubNavPoint, OrderedTocEntry>()
    var currentPosition = 0

    fun mapNavPoint(navPoint: EpubNavPoint, level: Int): TableOfContents.Entry {
        return TableOfContents.Entry(
            content = TableOfContents.Entry.Content.Single(
                TableOfContents.Entry.Section(
                    title = navPoint.label,
                    hierarchyLevel = level,
                ),
            ),
            start = TableOfContents.Entry.Start.Unresolved.EpubChapter(navPoint),
            attributes = TableOfContents.Entry.Attributes(
                targetPageIndex = null,
            ),
        )
    }

    fun processNavPoints(navPoints: List<EpubNavPoint>, level: Int) {
        navPoints.forEach { navPoint ->
            val entry = mapNavPoint(navPoint, level)
            resultMap[navPoint] = OrderedTocEntry(
                entry = entry,
                rank = currentPosition++,
                chapterIndex = navPoint.chapterIndex,
            )

            if (navPoint.children.isNotEmpty()) {
                processNavPoints(navPoint.children, level + 1)
            }
        }
    }

    processNavPoints(navPoints = tocNavPoints, level = 1)
    return resultMap
}
