package com.speechify.client.bundlers.reading.book

import com.speechify.client.api.SDKVersion
import com.speechify.client.api.content.pdf.ContentSortingStrategy
import kotlin.js.JsExport

@JsExport
class BookReadingBundlerConfig(
    /**
     * Container for all *optional* [BookReadingBundler] configurations.
     */
    val options: BookReadingBundlerOptions,
)

@JsExport
interface BookReadingContentProcessingOptions {
    /**
     * When this is set to true the content in a PDF / scanned book will be reordered using our own internal sorting
     * implementation.
     *
     * This can help in cases where users observe listening jumping around the page / ignoring columnized text.
     */
    val contentSortingStrategy: ContentSortingStrategy
}

/**
 * Container for all *optional* [BookReadingBundler] configurations.
 */
@JsExport
data class BookReadingBundlerOptions(
    override val contentSortingStrategy: ContentSortingStrategy,
) : BookReadingContentProcessingOptions {

    internal var minimalSDKVersionToClearMLParsingCaches: SDKVersion? = null

    fun ignoreCachedMLParsingResultsWithSDKVersionLowerThan(sdkVersion: SDKVersion) {
        minimalSDKVersionToClearMLParsingCaches = sdkVersion
    }
}
