package com.speechify.client.helpers.content.standard.dynamic.contentProviders

import com.speechify.client.helpers.content.standard.streamable.StreamableContentChunk
import com.speechify.client.helpers.content.standard.streamable.items.topLevelItems.TopLevelItem
import kotlin.js.JsExport

@JsExport
class ContentRequestInfo(
    /**
     * Coordinates of the requested content.
     */
    val coordinates: PointerToChunkFraction?,

    /**
     * Whether this request comes as a user seeking into this location or not (e.g. playing continuing).
     * This allows good UX when user has no-auto-scroll, that is, allows not require another click from the user, to
     * accept the new speech position, if it was the user himself who was scrubbing.
     */
    val isUserSeeking: Boolean,
)

@JsExport
class PointerToChunkFraction(
    val chunkIndex: Int,
    /**
     * A [0-1] indicating the position into the chunk.
     * This is approximate.
     */
    val fractionIntoChunk: Double,
)

@JsExport
class MidContentStartInfo(
    /**
     * The content from which playback should start.
     */
    val startItemAndFollowingItems: Array<TopLevelItem>,
    /**
     * NOTE: This is the content **before** the start item.
     */
    val unusedItemsBeforeStartInThisChunk: Array<TopLevelItem>,
)

@JsExport
interface TryGetAliveContentResponseProducer {
    fun FoundAliveContent(chunk: StreamableContentChunk)
    fun FoundNoAliveContent()
}

@JsExport
interface ContentResponseProducer {
    fun MatchingChunk(
        chunk: StreamableContentChunk,
        /**
         * Possible values:
         * * `null` if it's not known whether it's the last chunk. In this case, scrubber will not show the extra
         * content length in advance, but a request for next chunk will be issued when reaching near the end of
         * this chunk, and only upon finding the next chunk there will the scrubber indicate the new content.
         * (On next request, if it turns out only later that this was the last chunk, then [EarlyLastChunk] needs to be
         * used.)
         * * `true` if it is known it's the last chunk. Scrubber will not show the extra
         * content length and there will be no request for the next chunk.
         * * `false` if it is known this isn't the last chunk. Scrubber will show the extra content length and there
         * will be a request for the next chunk.
         */
        isLastChunk: Boolean? = null,
    )

    fun MatchingChunkWithEstimate(
        estimatedChunksCountBefore: Int?,
        estimatedChunksCountAfter: Int?,
        chunk: StreamableContentChunk,
    )

    /* TEMPORARY PRIVATIZATION UNTIL - #TODOSupportMoreThanOneChunk
    fun MatchingChunks(
        chunks: Array<StreamableContentChunk>,
        isLastChunks: Boolean? = null,
    )
     */

    /* TEMPORARY PRIVATIZATION UNTIL - #TODOSupportMoreThanOneChunk
    fun MatchingChunksWithEstimate(
        estimatedChunksCountBefore: Int?,
        estimatedChunksCountAfter: Int?,
        chunks: Array<StreamableContentChunk>,
    )
    */

    /**
     * Corrects the estimated content
     */
    fun EarlyLastChunk(
        actualLastChunkIdx: Int,
        chunk: StreamableContentChunk,
    )

    /**
     * Corrects the estimated content
     */
    /* TEMPORARY PRIVATIZATION UNTIL - #TODOSupportMoreThanOneChunk
    fun EarlyLastChunks(
        actualStartIndex: Int,
        chunks: Array<StreamableContentChunk>,
    )
    */
}
