// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.channels.ChannelResult
import kotlinx.coroutines.channels.ClosedSendChannelException
import kotlinx.coroutines.channels.ReceiveChannel
import kotlinx.coroutines.channels.SendChannel
import kotlin.coroutines.cancellation.CancellationException

/**
 * A non-suspending version of [SendChannel.send] for unlimited capacity channels which will only throw on closed
 * ([ClosedSendChannelException]) or cancelled channel ([CancellationException]).
 * Use it when your channel has unlimited capacity to indicate that the channel being unlimited is the reason why
 * you are not handling the result from the `trySend`.
 *
 * @throws CancellationException
 * @throws ClosedSendChannelException
 */
internal fun <E> SendChannel<E>.sendToUnlimited(item: E): Unit =
/*
 (NOTE: ["[SendChannel.trySend] always succeeds"](https://github.com/Kotlin/kotlinx.coroutines/blob/3c83c0cfea619f68f1eb323773d1f057f294023f/kotlinx-coroutines-core/common/src/channels/LinkedListChannel.kt#L12)
  only refers to never throwing - it can still return an unsuccessful [ChannelResult], e.g. when the channel is closed
  or cancelled.
 */
    this.sendEnsuringReceivedOrBuffered(item)

/**
 * A non-suspending version of [SendChannel.send] that makes sure the item is received or buffered.
 * NOTE: When the channel is expected to be unlimited, use [sendToUnlimited] instead, to mark the expectation.
 * This one is still good for direct use where there may be other reasons that a `send` must succeed (e.g. there's
 * sufficient buffer), making sure that the reason is reported.
 * NOTE: This is especially safer than just using [SendChannel.trySend] and then [kotlinx.coroutines.channels.ChannelResult.exceptionOrNull]
 * because that one will return `null` when the cause for sending failure is due to the buffer being full (as per docs of [kotlinx.coroutines.channels.ChannelResult.isClosed]).
 */
internal fun <E> SendChannel<E>.sendEnsuringReceivedOrBuffered(item: E): Unit =
    this.asChannelWithSends()
        .sendEnsuringReceivedOrBuffered(item)

/**
 * All the 'sending' methods of [SendChannel].
 * An interface extracted for interface-segregation. To narrow-down the type of [SendChannel] to only the one that has
 * sending methods (such narrowing is desired e.g. for [com.speechify.client.internal.actor.Actor]).
 */
internal interface ChannelSendInterface<E> : ChannelWithTrySend<E> {
    /**
     * [SendChannel.send] (The suspending version of [trySend]).
     */
    suspend fun send(element: E)
}

internal fun <E> SendChannel<E>.asChannelWithSends(): ChannelSendInterface<E> =
    object : ChannelWithTrySend<E>, ChannelSendInterface<E> {
        override fun trySend(element: E): ChannelResult<Unit> =
            this@asChannelWithSends.trySend(element)

        override suspend fun send(element: E) =
            this@asChannelWithSends.send(element)
    }

internal interface ChannelWithTrySend<E> {
    /**
     * [SendChannel.trySend].
     */
    fun trySend(element: E): ChannelResult<Unit>
}

internal fun <E> ChannelWithTrySend<E>.sendEnsuringReceivedOrBuffered(item: E): Unit =
    this.trySend(item)
        .getOrThrow()
        .ignoreValue()

/**
 * A version of [SendChannel.trySend] that makes sure any failure is logged.
 *
 * NOTE: Prefer [sendEnsuringReceivedOrBuffered] to propagate the failure to the caller, and try to only use this one as
 * a temporary measure while it's not known if the caller will have a critical problem when your function throws,
 * (in the long run the caller should be made resilient, e.g. reporting the error, and you should switch to [sendEnsuringReceivedOrBuffered]).
 */
internal fun <E> ChannelWithTrySend<E>.trySendEnsuringLoggingOfFailure(
    item: E,
    sourceAreaId: String,
    shouldLogItem: Boolean,
): Unit =
    try {
        sendEnsuringReceivedOrBuffered(item)
    } catch (e: Throwable) {
        Log.e(
            DiagnosticEvent(
                sourceAreaId = sourceAreaId,
                nativeError = e,
                properties = if (shouldLogItem) mapOf("item" to (item ?: "null")) else null,
            ),
        )
    }

internal fun <E> SendChannel<E>.trySendEnsuringLoggingOfFailure(
    item: E,
    sourceAreaId: String,
    shouldLogItem: Boolean,
): Unit =
    this
        .asChannelWithSends()
        .trySendEnsuringLoggingOfFailure(
            item = item,
            sourceAreaId = sourceAreaId,
            shouldLogItem = shouldLogItem,
        )

/**
 * Same purpose as [sendToUnlimited], but a convenience overload for an iterator.
 *
 * @throws CancellationException
 * @throws ClosedSendChannelException
 */
internal fun <E> SendChannel<E>.sendAllToUnlimited(items: Iterator<E>) {
    for (item in items)
        this.sendToUnlimited(item)
}

/**
 * Unlike [ReceiveChannel.receiveOrNull], this one will throw on failure.
 * Unlike [ReceiveChannel.receive], this one will not throw, but return `null` when the channel was closed.
 */
internal suspend fun <E> ReceiveChannel<E>.receiveOrNullThrowingOnFailure(): E? =
    receiveCatching()
        .getOrNullThrowingOnFailure()

internal fun <E> ChannelResult<E>.getOrNullThrowingOnFailure(): E? {
    val result = this.getOrNull()
    if (result != null) {
        return result
    }

    when (val exception = this.exceptionOrNull()) {
        null -> return null
        else -> throw exception
    }
}
