package com.speechify.client.api.audio

import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerOptions
import kotlin.js.JsExport

@JsExport
data class PlayerOptions(
    val speed: Float,
    val volume: Float,
) {
    fun toLocalPlayerOptions() = LocalMediaPlayerOptions(
        speed = speed,
        volume = volume,
    )

    companion object {
        fun fromLocalPlayerOptions(l: LocalMediaPlayerOptions) = PlayerOptions(
            speed = l.speed,
            volume = l.volume,
        )
    }
}
