package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.util.extensions.collections.BinarySearchResult
import com.speechify.client.internal.util.extensions.collections.binarySearchBy

/**
 * A map allowing to find entries near a key that doesn't itself exist in the map.
 *
 * Equivalent of [Java NavigableMap](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html) and used
 * as a guidance for the API naming and shape (but implementing only the methods needed).
 *
 * DEROGATIONS:
 *  1. The methods returning a sub-map (e.g. `headMap` or `tailMap`) return a read-only version of it (but still without
 *    copying it, so still memory efficient), so not like in [_"The returned map is backed by this map, so changes in the returned map are reflected in this map, and vice-versa."_](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#tailMap-K-)
 *    This is because the implementation uses a sorted list (as suggested [here](https://github.com/JetBrains/kotlin-native/issues/1208#issuecomment-355749841).
 *    so Kotlin doesn't seem to have anything easier) which makes it hard to implement such live updating across
 *    instances.
 */
internal class NavigableMutableMap<Key, Value>(
    keyComparator: Comparator<Key>,
    private val theListMutable: MutableList<Entry<Key, Value>> = mutableListOf(),
) : NavigableMapBase<Key, Value>(
    keyComparator = keyComparator,
    theList = theListMutable,
    fromIndex = 0,
) {
    override val toIndexExclusive: Int
        get() = theList.size

    /**
     * @throws NavigableMapBase.KeyAlreadyPresentError when an element with this key has already been added
     */
    fun add(key: Key, value: Value) {
        val newEntry = Entry(key = key, value = value)
        val binarySearchResult = theList.binarySearch(
            element = newEntry,
            comparator = entryComparator,
            fromIndex = fromIndex,
        ).let { BinarySearchResult.fromIntResult(it) }

        when (binarySearchResult) {
            is BinarySearchResult.ExactMatch ->
                throw KeyAlreadyPresentError(unsuccessfulValue = newEntry)

            is BinarySearchResult.InsertionPointMatch -> {
                theListMutable.add(index = binarySearchResult.insertionPointIndex, newEntry)
            }
        }
    }

    fun put(key: Key, value: Value) {
        val newEntry = Entry(key = key, value = value)
        val binarySearchResult = theList.binarySearch(
            element = newEntry,
            comparator = entryComparator,
            fromIndex = fromIndex,
        ).let { BinarySearchResult.fromIntResult(it) }

        when (binarySearchResult) {
            is BinarySearchResult.ExactMatch ->
                theListMutable[binarySearchResult.exactMatchIndex] = newEntry

            is BinarySearchResult.InsertionPointMatch ->
                theListMutable.add(index = binarySearchResult.insertionPointIndex, newEntry)
        }
    }

    private val entryComparator: Comparator<Entry<Key, Value>> =
        Comparator { a, b -> keyComparator.compare(a.key, b.key) }
}

internal class ReadOnlyNavigableMap<Key, Value>(
    keyComparator: Comparator<Key>,
    theList: List<Entry<Key, Value>>,
    fromIndex: Int,
    override val toIndexExclusive: Int,
) : NavigableMapBase<Key, Value>(
    keyComparator = keyComparator,
    theList = theList,
    fromIndex = fromIndex,
)

internal abstract class NavigableMapBase<Key, Value>(
    protected val keyComparator: Comparator<Key>,
    protected val theList: List<Entry<Key, Value>>,
    protected val fromIndex: Int,
) {
    protected abstract val toIndexExclusive: Int

    class KeyAlreadyPresentError(val unsuccessfulValue: Any?) : Error("An element with this key has already been added")

    /**
     * Returns a view of the portion of this map whose keys are greater than or equal to fromKeyInclusive.
     *
     * Equivalent of [JVM NavigableMap.tailMap](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#tailMap-K-boolean-)
     */
    fun tailMap(fromKey: Key, inclusive: Boolean): ReadOnlyNavigableMap<Key, Value> =
        ReadOnlyNavigableMap(
            keyComparator = keyComparator,
            theList = theList,
            fromIndex = theList.binarySearchBy(
                key = fromKey,
                getKey = { key },
                comparator = keyComparator,
                fromIndex = fromIndex,
                toIndex = toIndexExclusive,
            ).let {
                when (it) {
                    is BinarySearchResult.ExactMatch ->
                        if (inclusive) it.exactMatchIndex else it.exactMatchIndex + 1

                    is BinarySearchResult.InsertionPointMatch ->
                        it.insertionPointIndex // Start from the next
                }
            },
            toIndexExclusive = toIndexExclusive,
        )

    /**
     * Returns a view of the portion of this map whose keys are greater than or equal to fromKeyInclusive.
     *
     * Equivalent of [JVM NavigableMap.headMap](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#headMap-K-boolean-)
     */
    fun headMap(fromKey: Key, inclusive: Boolean): ReadOnlyNavigableMap<Key, Value> =
        ReadOnlyNavigableMap(
            keyComparator = keyComparator,
            theList = theList,
            fromIndex = fromIndex,
            toIndexExclusive = theList.binarySearchBy(
                key = fromKey,
                getKey = { key },
                comparator = keyComparator,
                fromIndex = fromIndex,
                toIndex = toIndexExclusive,
            ).let {
                when (it) {
                    is BinarySearchResult.ExactMatch ->
                        if (inclusive) it.exactMatchIndex else it.exactMatchIndex - 1
                    is BinarySearchResult.InsertionPointMatch ->
                        it.insertionPointIndex - 1
                }
            },
        )

    /**
     * Returns a key-value mapping associated with the greatest key less than or equal to the given key, or null if there is no such key.
     *
     * Equivalent of [JVM NavigableMap.floorEntry](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#floorEntry-K-)
     */
    fun floorEntry(fromKeyInclusive: Key): Entry<Key, Value>? {
        val searchResult = theList.binarySearchBy(
            key = fromKeyInclusive,
            getKey = { key },
            comparator = keyComparator,
            fromIndex = fromIndex,
            toIndex = toIndexExclusive,
        )

        return when (searchResult) {
            is BinarySearchResult.ExactMatch -> theList[searchResult.exactMatchIndex]
            is BinarySearchResult.InsertionPointMatch -> {
                if (searchResult.insertionPointIndex == 0) {
                    null
                } else {
                    theList[searchResult.insertionPointIndex - 1]
                }
            }
        }
    }

    /**
     * Returns a key-value mapping associated with the least key greater than or equal to the given key, or null if there is no such key.
     *
     * Equivalent of [JVM NavigableMap.ceilingEntry](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#ceilingEntry-K-)
     */
    fun ceilingEntry(fromKeyInclusive: Key): Entry<Key, Value>? {
        val searchResult = theList.binarySearchBy(
            key = fromKeyInclusive,
            getKey = { key },
            comparator = keyComparator,
            fromIndex = fromIndex,
            toIndex = toIndexExclusive,
        )

        return when (searchResult) {
            is BinarySearchResult.ExactMatch -> theList[searchResult.exactMatchIndex]
            is BinarySearchResult.InsertionPointMatch -> {
                if (searchResult.insertionPointIndex == toIndexExclusive) {
                    null
                } else {
                    theList[searchResult.insertionPointIndex]
                }
            }
        }
    }

    /**
     * Returns a key-value mapping associated with the greatest key strictly less than the given key, or null if there is no such key.
     *
     * Equivalent of [JVM NavigableMap.lowerEntry](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#lowerEntry-K-)
     */
    fun lowerEntry(fromKeyExclusive: Key): Entry<Key, Value>? {
        val searchResult = theList.binarySearchBy(
            key = fromKeyExclusive,
            getKey = { key },
            comparator = keyComparator,
            fromIndex = fromIndex,
            toIndex = toIndexExclusive,
        )

        val index = when (searchResult) {
            is BinarySearchResult.ExactMatch -> searchResult.exactMatchIndex - 1
            is BinarySearchResult.InsertionPointMatch -> searchResult.insertionPointIndex - 1
        }

        return if (index >= 0) theList[index] else null
    }

    /**
     * Returns a key-value mapping associated with the least key strictly greater than the given key, or null if there is no such key.
     *
     * Equivalent of [JVM NavigableMap.higherEntry](https://docs.oracle.com/javase/8/docs/api/java/util/NavigableMap.html#higherEntry-K-)
     */
    fun higherEntry(fromKeyExclusive: Key): Entry<Key, Value>? {
        val searchResult = theList.binarySearchBy(
            key = fromKeyExclusive,
            getKey = { key },
            comparator = keyComparator,
            fromIndex = fromIndex,
            toIndex = toIndexExclusive,
        )

        val index = when (searchResult) {
            is BinarySearchResult.ExactMatch -> searchResult.exactMatchIndex + 1
            is BinarySearchResult.InsertionPointMatch -> searchResult.insertionPointIndex
        }

        return if (index < toIndexExclusive) theList[index] else null
    }

    operator fun get(key: Key): Value? =
        getEntry(key)?.value

    fun getEntry(key: Key): Entry<Key, Value>? {
        val searchResult = theList.binarySearchBy(
            key = key,
            getKey = { this.key },
            comparator = keyComparator,
            fromIndex = fromIndex,
            toIndex = toIndexExclusive,
        )

        return when (searchResult) {
            is BinarySearchResult.ExactMatch -> theList[searchResult.exactMatchIndex]
            is BinarySearchResult.InsertionPointMatch -> null
        }
    }

    val entries: List<Entry<Key, Value>>
        get() = theList.subList(fromIndex = fromIndex, toIndex = toIndexExclusive)

    val values: List<Value>
        get() = theList.subList(fromIndex = fromIndex, toIndex = toIndexExclusive)
            .map { it.value }

    class Entry<Key, Value>(val key: Key, val value: Value)
}
