package com.speechify.client.api.services.scannedbook.models

import com.speechify.client.api.adapters.ocr.OCRMaxConcurrencyGuard
import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.adapters.ocr.OCRableImage
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromBlock
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.WithScope

internal class LocalScannedBook internal constructor(private val ocrFiles: Array<OCRFile>) : ScannedBook {
    override val numberOfPages: Int = ocrFiles.size

    override fun getPage(pageIndex: Int, callback: Callback<ScannedBookPage>) = callback.fromCo {
        LocalPage(ocrFiles[pageIndex]).successfully()
    }

    override fun destroy() { /* Noop */
    }
}

internal class LocalPage(
    private val ocrFile: OCRFile,
) : WithScope(),
    ScannedBookPage {
    override fun getViewport(): Viewport =
        ocrFile.ocrResult.imageDimensions

    override fun getContent(callback: Callback<Array<OCRTextContent>>) = callback.fromBlock {
        ocrFile.ocrResult.textContent.successfully()
    }

    override fun getImage(
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) {
        callback(ocrFile.source.successfully())
    }
}

internal class LocalPageLazy(
    private val pageIndex: Int,
    private val ocrablImage: OCRableImage,
    // TODO: Remove this dependency and introduce a better way to retrieve the file
    private val lazyOCRFiles: LazyOCRFiles,
    private val ocrMaxConcurrencyGuard: OCRMaxConcurrencyGuard,
) : WithScope(),
    ScannedBookPage {
    override fun getViewport(): Viewport = ocrablImage.getImageDimensions()

    override fun getContent(callback: Callback<Array<OCRTextContent>>) = callback.fromCo {
        ocrablImage.getOcrTextResultCached(ocrMaxConcurrencyGuard).map { it.textContent }
    }

    override fun getImage(
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) = callback.fromCo {
        lazyOCRFiles.getFileCached(pageIndex).successfully()
    }
}
