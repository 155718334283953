package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.slice
import com.speechify.client.internal.util.text.groupingToSentences.getSentencesAsIndexRanges
import kotlin.js.JsExport

/**
 * Speakable text consisting of a sequence of [sentences]. Each sentence uses [ContentText] to represent "where" that text came from in the original content, even after transformations.
 */
@JsExport
class Speech internal constructor(
    /**
     * The cursor marking the start of the speakable content
     */
    override val start: ContentCursor,

    /**
     * The cursor marking the end of the speakable content
     */
    override val end: ContentCursor,

    /**
     * A sequence of speakable sentences.
     */
    val sentences: Array<SpeechSentence>,
) : Content {

    internal constructor(
        sentencesWithAtLeastOne: List<SpeechSentence>,
    ) : this(
        start = sentencesWithAtLeastOne.first().start,
        end = sentencesWithAtLeastOne.last().end,
        sentences = sentencesWithAtLeastOne.toTypedArray(),
    )

    /**
     * Replace all matches within a [Speech]'s sentences of the regular expression
     * [pattern] according to the [replacement] rule provided. Note: this does **NOT**
     * work across a sentence boundary!
     */
    fun replaceAll(pattern: String, replacement: ((match: String) -> String)): Speech {
        return Speech(
            this.start,
            this.end,
            this.sentences.mapNotNull {
                it.replaceAll(pattern, replacement)
            }.toTypedArray(),
        )
    }

    /**
     * Visit all the content slices present in this speech, and apply [visitor] to them
     * if the visitor returns `null` the slice is removed from the speech, if it returns a slice, the original slice
     * will be replaced with the new returned one.
     *
     * The number of sentences may decrease after applying this operation as an empty sentence is not allowed.
     */
    fun withRemovedContentOfMetadata(enrichment: TextEnrichment): Speech {
        return Speech(
            this.start,
            this.end,
            this.sentences.mapNotNull { it.withRemovedContentOfMetadata(enrichment) }.toTypedArray(),
        )
    }

    companion object {
        internal fun fromSentences(sentences: List<SpeechSentence>): Speech {
            if (sentences.isEmpty()) {
                throw IllegalArgumentException("Speech must have at least one sentence")
            }
            return Speech(sentences)
        }

        fun empty(start: ContentCursor, end: ContentCursor): Speech {
            return Speech(start, end, arrayOf())
        }
    }

    override fun toString() =
        "${this::class.simpleName ?: this::class.toString()}[start=$start, end=$end](${sentences.contentToString()})"
}

/**
 * How many words are in this [Speech], between the [from] and [to] cursors
 */
internal fun Speech.wordCount(from: ContentCursor, to: ContentCursor) =
    sentences.sumOf { it.wordCount(from, to) }

/**
 * Returns the words in this [Speech], between the [from] and [to] cursors
 */
internal fun Speech.words(from: ContentCursor, to: ContentCursor) =
    sentences.flatMap { it.words(from, to) }

/**
 * Get a slice of speech between two cursors (inclusive of the end).
 * @return a new [Speech] containing the same sentences, but sliced to only contain content between the provided [start] and [end] cursors.
 */
internal fun Speech.slice(start: ContentCursor, end: ContentCursor): Speech {
    val newSentences = sentences.flatMap {
        if (it.text.hasNontrivialIntersectionWith(start, end)) {
            return@flatMap listOfNotNull(it.slice(start, end))
        } else {
            listOf()
        }
    }
    return if (newSentences.isNotEmpty()) {
        Speech(start, end, newSentences.toTypedArray())
    } else {
        Speech.empty(start, end)
    }
}

/**
 * Get the length of the speech, as though the [sentences] were concatenated without any delimiters.
 */
internal fun Speech.length(): Int {
    return sentences.sumOf { it.text.length }
}

/**
 * Utility functions for constructing [Speech]
 */
internal object SpeechUtils {
    fun fromText(text: ContentText): Speech {
        val sentences = textToSentences(text)
        return Speech(text.start, text.end, sentences.toTypedArray())
    }

    internal fun textToSentences(text: ContentText): List<SpeechSentence> =
        text
            .textRepresentation
            .getSentencesAsIndexRanges()
            .map { sentenceRange ->
                text.slice(
                    range = sentenceRange,
                )
            }
            .map { contentText ->
                splitByLineTerminators(contentText)
            }
            .flatten()
            .map { contentText ->
                /** TODO - don't enforce the terminator here, as it's not always correct, especially because:
                 *   - using the `.` is not always correct e.g. for different languages - see [com.speechify.client.internal.util.text.groupingToSentences.internal.sentenceTerminators.SentenceSpanDefinition]
                 *   - headings can have no terminator
                 *   The adding of `.` is currently employed as a solution to audio-synthesis gluing together two
                 *   sentences across paragraphs or headings, but this should be solved with SSML [`<s>` tags](https://www.w3.org/TR/speech-synthesis11/#edef_sentence)
                 *   or even the [`<p>` tags](https://www.w3.org/TR/speech-synthesis11/#edef_paragraph) (which the
                 *   current sentences-as-only-tokens is not able to support).
                 **/
                SpeechSentence.fromTextWithEnforcingTerminator(
                    contentText,
                )
            }
            .filterNotNull()
            .toList()

    /**
     * This method will split a provided ContentText based on line terminators so that we have better sentence splitting.
     * If more than one line break is encountered, we split it into multiple sentences. If only one line break is encountered,
     * we will append a comma. This will make speech more natural and less confusing.
     *
     * See https://linear.app/speechify-inc/issue/CXP-3836/sdk-sentence-parsing-from-standardblock-should-treat-double-newline-as
     */
    internal fun splitByLineTerminators(inputText: ContentText): List<ContentText> {
        return inputText
            // split sentences wherever there are 2 or more newlines consecutively
            // because a pause is appropriate given the visual break in the text
            .split("\n{2,}")
            // Remove any single newlines left within the sentences
            // because there are too many edge cases where single newlines are used for line-wrapping for us to
            // put a pause here with confidence
            .map { it.replaceAll("\n") { " " } }
            .toList()
    }
}

/**
 * Note that this will not render the correct layout for production use, e.g. will not have line-breaks.
 * It's just going to concatenate every sentence using a `" "` separator.
 */
internal fun Speech.textToStringForDebugging(sentencesSeparator: String = " "): String =
    sentences.joinToString(sentencesSeparator) { it.text.text }
