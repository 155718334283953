package com.speechify.client.api.util

internal fun SDKError.isCausedByConnectionError(): Boolean =
    this.getCauseThatMatchesOrNull(
        predicate = { it is SDKError.ConnectionError },
    ) != null

internal fun Throwable.isCausedByConnectionError(): Boolean =
    this.getCauseThatMatchesOrNull(
        predicate = { it is SDKError.ConnectionError },
    ) != null
