package com.speechify.client.internal.util.extensions.collections

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

/**
 * The [Pair.first] will be `null` for the first item, and then populated with the previous item.
 */
internal fun <T> Flow<T>.windowedToPairsWithPrevious(): Flow<Pair<T?, T>> =
    windowedStartingWithSmaller(maxSize = 2)
        .map {
            if (it.size == 1) {
                Pair(null, it[0])
            } else {
                Pair(it[0], it[1])
            }
        }
