package com.speechify.client.reader.core

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.startofmaincontent.StartOfMainContent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.internal.util.extensions.collections.firstNotNull
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.first
import kotlin.js.JsExport

internal class NavigationHelper internal constructor(
    scope: CoroutineScope,
    private val tocEntryTargetResolver: TocEntryTargetResolver,
    playbackState: Flow<PlaybackState>,
    startOfMainContentFlow: Flow<StartOfMainContent>,
    initialNavigationIntent: NavigationIntent,
) : Helper<Unit>(scope) {
    /**
     * This is unused. See [intentsFlow] for a flow of intents that readers can consume independently
     */
    override val stateFlow: MutableStateFlow<Unit> = MutableStateFlow(Unit)
    override val initialState = stateFlow.value

    // replay 1 so subscribers can catch the initial intent even if they subscribe after it was fired
    val intentsFlow: MutableSharedFlow<ResolvedNavigationIntent> = MutableSharedFlow(replay = 1)

    val initialLocation = CompletableDeferred<RobustLocation>()

    init {
        launchInHelper {
            resolveInitialLocation(
                initialNavigationIntent = initialNavigationIntent,
                playbackState = playbackState,
                startOfMainContentFlow = startOfMainContentFlow,
            ).let {
                initialLocation.complete(it)
                intentsFlow.emit(
                    ResolvedNavigationIntent(
                        location = it,
                        intent = initialNavigationIntent,
                    ),
                )
            }

            // We intentionally wait for the initial location before subscribing to commands.
            // This deferred initialization pattern allows clients to set up the UI scaffolding
            // while the location loads, rather than blocking the entire `ListeningExperience` construction.
            // It ensures commands are only processed after proper initialization.

            commands
                .onEachInstance<NavigationCommand> { command ->
                    when (command) {
                        is NavigationCommand.NavigateTo -> {
                            val location = when (command.intent) {
                                is NavigationIntent.GoToCurrentPlaybackLocation -> resolveCurrentPlaybackLocation(
                                    navigationIntent = command.intent,
                                    playbackState = playbackState,
                                    startOfMainContentFlow = startOfMainContentFlow,
                                )

                                is NavigationIntent.GoToPage -> command.intent.start
                                is NavigationIntent.GoToUserHighlight -> command.intent.userHighlight.robustStart
                                is NavigationIntent.GoToTableOfContentsEntry -> command.intent.target.resolveLocation(
                                    tocEntryTargetResolver,
                                )

                                is NavigationIntent.GoToChapter -> {
                                    command.intent.start
                                }

                                is NavigationIntent.GoToSearchMatch -> {
                                    command.intent.location
                                }
                            }

                            dispatch(NavigationCommand.GoTo(location ?: return@onEachInstance, command.intent))
                        }

                        is NavigationCommand.GoTo -> {
                            intentsFlow.emit(ResolvedNavigationIntent(command.location, command.intent))
                        }
                    }
                }
                .onEachInstance<CoreCommand.ReloadContent> {
                    dispatch(NavigationCommand.NavigateTo(NavigationIntent.GoToCurrentPlaybackLocation()))
                }
                .collect()
        }
    }

    private suspend fun resolveInitialLocation(
        initialNavigationIntent: NavigationIntent,
        playbackState: Flow<PlaybackState>,
        startOfMainContentFlow: Flow<StartOfMainContent>,
    ) = when (initialNavigationIntent) {
        is NavigationIntent.GoToCurrentPlaybackLocation -> resolveCurrentPlaybackLocation(
            navigationIntent = initialNavigationIntent,
            playbackState = playbackState,
            startOfMainContentFlow = startOfMainContentFlow,
        )

        else -> throw IllegalArgumentException(
            "Unsupported initial navigation intent: $initialNavigationIntent",
        )
    }

    private suspend fun resolveCurrentPlaybackLocation(
        navigationIntent: NavigationIntent.GoToCurrentPlaybackLocation,
        playbackState: Flow<PlaybackState>,
        startOfMainContentFlow: Flow<StartOfMainContent>,
    ) = if (navigationIntent is NavigationIntent.GoToCurrentPlaybackLocation.SkippingToMainContent) {
        resolveStartOfMainContentFallingBackToCurrentPlaybackLocation(
            playbackState = playbackState,
            startOfMainContentFlow = startOfMainContentFlow,
        )
    } else {
        playbackState.firstNotNull().location.toRobustLocation()
    }

    private suspend fun resolveStartOfMainContentFallingBackToCurrentPlaybackLocation(
        playbackState: Flow<PlaybackState>,
        startOfMainContentFlow: Flow<StartOfMainContent>,
    ) = when (val startOfMainContent = startOfMainContentFlow.first()) {
        is StartOfMainContent.NotAvailable -> SerialLocation(
            playbackState.firstNotNull().location.cursor,
        ).toRobustLocation()

        is StartOfMainContent.NotReady -> {
            startOfMainContentFlow
                .filterIsInstance<StartOfMainContent.Ready>()
                .first()
                .cursor
                .let {
                    getMainContentStartOrCurrentPlaybackLocation(
                        playbackCursor = playbackState.firstNotNull().location.cursor,
                        startOfMainContentCursor = it,
                    )
                }
        }

        is StartOfMainContent.Ready -> getMainContentStartOrCurrentPlaybackLocation(
            playbackCursor = playbackState.firstNotNull().location.cursor,
            startOfMainContentCursor = startOfMainContent.cursor,
        )
    }

    private fun getMainContentStartOrCurrentPlaybackLocation(
        playbackCursor: ContentCursor,
        startOfMainContentCursor: ContentCursor,
    ) = if (playbackCursor.isBefore(startOfMainContentCursor)) {
        SerialLocation(startOfMainContentCursor).toRobustLocation()
    } else {
        SerialLocation(playbackCursor).toRobustLocation()
    }
}

internal sealed class NavigationCommand {
    data class NavigateTo(val intent: NavigationIntent) : NavigationCommand()

    /**
     * Enables other helpers to resolve intents and communicate them directly
     */
    data class GoTo(val location: RobustLocation, val intent: NavigationIntent) : NavigationCommand()
}

internal data class ResolvedNavigationIntent(
    val location: RobustLocation,
    val intent: NavigationIntent,
)

@JsExport
sealed class NavigationIntent {
    /**
     * Navigation intent that navigates to the current playback location.
     * When [skipToMainContentIfBeforePlayback] is true, it attempts to move to the start of the main content,
     * falling back to the current playback location in two cases:
     * 1. When the start of main content is not defined for the current content
     * 2. When the current playback position is already after the start of main content
     *
     * @property skipToMainContentIfBeforePlayback Whether to attempt moving to start of main content
     */
    open class GoToCurrentPlaybackLocation : NavigationIntent() {

        /**
         * Navigation intent that attempts to move to the start of main content,
         * falling back to the current playback location in two cases:
         * 1. When the start of main content is not defined for the current content
         * 2. When the current playback position is already after the start of main content
         */
        class SkippingToMainContent : GoToCurrentPlaybackLocation()
    }

    class GoToPage internal constructor(val pageIndex: Int, internal val start: RobustLocation) : NavigationIntent()

    class GoToUserHighlight internal constructor(
        val userHighlight: UserHighlight,
    ) : NavigationIntent()

    class GoToTableOfContentsEntry internal constructor(internal val target: TocEntryTarget) : NavigationIntent()

    class GoToChapter internal constructor(
        val chapterIndex: Int,
        internal val start: RobustLocation,
    ) : NavigationIntent()

    class GoToSearchMatch internal constructor(
        internal val location: RobustLocation,
    ) : NavigationIntent()
}

@JsExport
/**
 * A [RelativeNavigationIntent] expresses the intent to navigate to some location relative to some other "current"
 * location that is implied by the context in which this intent is used.
 */
sealed class RelativeNavigationIntent {
    object GoToStartOfThisWord : RelativeNavigationIntent()
    object GoToStartOfThisSentence : RelativeNavigationIntent()

    /**
     * The intent to remain at the current position. We model explicitly because sometimes this leads to more
     * readable client code given differing support for default args and keyword args on different platforms
     */
    object None : RelativeNavigationIntent()
}

private suspend fun TocEntryTarget.resolveLocation(tocEntryTargetResolver: TocEntryTargetResolver) = when (this) {
    is TocEntryTarget.Resolved -> location
    is TocEntryTarget.Unresolved -> try {
        val resolved = tocEntryTargetResolver.resolveTarget(unresolvedTarget = this)
        resolved.location
    } catch (throwable: Throwable) {
        Log.e(
            message = "Failed to resolve Table of Contents Entry",
            exception = throwable,
            sourceAreaId = "TocEntryTarget.resolveLocation",
        )
        null
    }
}
