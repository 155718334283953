package com.speechify.client.internal.util.extensions.collections

fun <First, Second> List<First>.zipEnsuringSameLength(
    second: Iterable<Second>,
): List<Pair<First, Second>> {
    val secondAsList = second.toList()
    if (this.size != secondAsList.size) {
        throw IllegalArgumentException(
            "Inputs were not the same length while this was expected. First: ${this.size}, " +
                "second: ${secondAsList.size}",
        )
    }

    return this.zip(secondAsList)
}
