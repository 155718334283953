package com.speechify.client.internal.sync

internal expect class AtomicLong(value: Long = 0) {
    fun get(): Long
    fun set(newInt: Long)
    fun getAndAdd(delta: Long): Long

    fun addAndGet(delta: Long): Long

    fun getAndIncrement(): Long
    fun getAndDecrement(): Long
}

internal operator fun AtomicLong.plus(other: AtomicLong): Long = get() + other.get()

/**
 * Use to indicate intentful lack of interest in the result of the addition.
 */
internal fun AtomicLong.add(delta: Long) {
    addAndGet(delta)
}
