package com.speechify.client.api.services.subscription.models

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class SubscriptionAndEntitlements(
    val subscriptions: Array<Subscription>,
    val entitlements: Entitlements,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SubscriptionAndEntitlements
        if (subscriptions.contentEquals(other.subscriptions)) {
            return false
        } else if (entitlements == other.entitlements) return false

        return true
    }

    override fun hashCode(): Int {
        var result = subscriptions.hashCode() ?: 0
        result = 31 * result + entitlements.hashCode()
        return result
    }
}
