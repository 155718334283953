package com.speechify.client.api.diagnostics

import com.speechify.client.api.util.boundary.toPair

@JsExport
class ConsoleDiagnosticReporter : DiagnosticReporter() {
    override fun reportError(event: DiagnosticEvent) {
        console.error(*getConsoleLogArgs(event))
    }

    override fun reportWarning(event: DiagnosticEvent) {
        console.warn(*getConsoleLogArgs(event))
    }

    override fun reportInfo(event: DiagnosticEvent) {
        console.info(*getConsoleLogArgs(event))
    }

    private fun getConsoleLogArgs(event: DiagnosticEvent): Array<Any> =
        (
            listOf(
                event.sourceAreaId?.let { "$it:" },
                event.message,
            ) +
                event.properties?.let {
                    listOf(
                        "\nProperties:",
                        kotlin.js.json(
                            *it.entries()
                                .map { bp -> bp.toPair() }
                                .toTypedArray(),
                        ),
                    )
                }.orEmpty() +
                event.error?.let {
                    listOf(
                        "\nError:",
                        it.nativeError, /* Add the Error object itself, so that the developer can inspect it using the
                         console-built-in Error object viewer showing all its props. */
                        "\nError full text: " + it.getFullErrorString(), /* Add `getFullErrorString` especially
                         because JS has no 'suppressed exceptions', while this one covers them */
                    )
                }.orEmpty()
            )
            .filterNotNull().toTypedArray()
}
