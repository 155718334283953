package com.speechify.client.internal.services.file.models

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.file.models.SpeechifyFile
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.io.File

internal class SpeechifyFileImpl(
    private val file: File,
    override val uri: SpeechifyURI,
) :
    SpeechifyFile() {
    override val contentType: String
        get() = file.contentType

    override fun getSizeInBytes(callback: Callback<Int>) {
        file.getSizeInBytes(callback)
    }

    override fun getBytes(startIndex: Int, endIndex: Int, callback: Callback<ByteArray>) {
        file.getBytes(startIndex, endIndex, callback)
    }
}
