// ktlint-disable filename
package com.speechify.client.internal.util.extensions.strings

import com.speechify.client.internal.util.extensions.intentSyntax.nullIf

internal fun String?.nullIfEmpty(): String? =
    this?.nullIf { isEmpty() }

/**
 * Prevents the footgun of `this.all { it.isLowerCase() }` which returns false on:
 * * `"h1"`, because `'1'.isLowerCase() == false`.
 * * letters that have no case, e.g. `'漢'.isLetter() == true` but `'漢'.isLowerCase() == false`
 */
internal fun String.isLowerCase(): Boolean =
    this == this.lowercase()

/**
 * Returns a sequence of substrings each shorter by continuously taking substrings _after_ the first occurrence of the
 * [delimiter] in the last returned string, until the delimiter isn't found.
 */
internal fun String.toSequenceOfShorterSubstringsAfter(delimiter: String): Sequence<String> =
    generateSequence(this@toSequenceOfShorterSubstringsAfter) {
        it.substringAfterOrNull(delimiter)
    }

/**
 * A version of [substringAfter] that makes it easy, when delimiter isn't found, to fall back to a different behavior or
 * different value than a string.
 *
 * @return `null` if the delimiter was not found
 */
internal fun String.substringAfterOrNull(delimiter: String): String? =
    this.substringAfter(delimiter).let {
        if (it == this) {
            null
        } else {
            it
        }
    }

internal fun String.getStartingIndexIfEndsWithOrNull(
    expectedEnding: String,
): Int? =
    if (this.endsWith(expectedEnding)) {
        this.lastIndex - expectedEnding.lastIndex
    } else {
        null
    }

internal fun Iterable<String>.characterCount() =
    sumOf { it.length }

internal fun String.getSimilarityTo(other: String): Double {
    fun getLevenshteinDistance(X: String, Y: String): Int {
        val m = X.length
        val n = Y.length
        val T = Array(m + 1) { IntArray(n + 1) }
        for (i in 1..m) {
            T[i][0] = i
        }
        for (j in 1..n) {
            T[0][j] = j
        }
        var cost: Int
        for (i in 1..m) {
            for (j in 1..n) {
                cost = if (X[i - 1] == Y[j - 1]) 0 else 1
                T[i][j] = kotlin.math.min(
                    kotlin.math.min(T[i - 1][j] + 1, T[i][j - 1] + 1),
                    T[i - 1][j - 1] + cost,
                )
            }
        }
        return T[m][n]
    }

    val maxLength = kotlin.math.max(this.length, other.length)
    return if (maxLength > 0) {
        (maxLength * 1.0 - getLevenshteinDistance(this, other)) / maxLength * 1.0
    } else {
        1.0
    }
}
