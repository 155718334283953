package com.speechify.client.api.util

/**
 * Checks if this byte is the first byte in a utf-8 character.
 *
 * Aka if it's ascii, or it's the first byte of a multibyte character
 */
internal fun Byte.isUtf8CharBoundary() = this >= -0x40 // this is equivalent to `this < 128 || this >= 192`
// source: https://doc.rust-lang.org/src/core/num/mod.rs.html#805-807

internal fun Byte.characterLength() = with(this.toUInt()) {
    if (this < 0b1000_0000U) { // ascii
        1
    } else if (this and 0b1110_0000U == 0b1100_0000U) {
        2
    } else if (this and 0b1111_0000U == 0b1110_0000U) {
        3
    } else if (this and 0b1111_1000U == 0b1111_0000U) {
        4
    } else if (this and 0b1111_1100U == 0b1111_1000U) {
        5
    } else if (this and 0b1111_1110U == 0b1111_1100U) {
        6
    } else {
        throw IllegalArgumentException("$this is not the start of a utf8 character")
    }
}
