package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.SearchMatch
import com.speechify.client.api.content.Searcher
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.txt.PlainTextView
import com.speechify.client.api.content.view.txt.coGetLength
import com.speechify.client.api.content.view.txt.coGetSlice
import com.speechify.client.api.content.view.txt.getCursor
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.sync.coLazy
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlin.js.JsExport

@JsExport
class PlainTextStandardView(private val view: PlainTextView) :
    StandardView,
    Content by view,
    ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent,
    Searcher {

    private val fullText = coLazy {
        val size = view.coGetLength().orThrow()
        view.coGetSlice(0, size).orThrow()
    }

    override fun getBlocksAroundCursor(
        cursor: ContentCursor,
        callback: Callback<StandardBlocks>,
    ) =
        callback.fromCo {
            val (startIndex, endIndex) = when (cursor) {
                is ContentElementBoundary -> {
                    when (cursor.boundary) {
                        is ContentBoundary.START -> {
                            (0 to getParagraphStart(fullText.get().text, 0, 5))
                        }
                        is ContentBoundary.END -> {
                            (
                                getParagraphStartBackwards(fullText.get().text, 0, 5) to
                                    fullText.get().length
                                )
                        }
                    }
                }
                is ContentTextPosition -> {
                    (
                        // We go a maximum of 10 paragraphs before the cursor
                        getParagraphStartBackwards(fullText.get().text, cursor.characterIndex, 10) to
                            // We go a maximum of 10 paragraphs after the cursor
                            getParagraphStart(fullText.get().text, cursor.characterIndex, 10)
                        )
                }
            }
            val textSlice = fullText.get().slice(startIndex, endIndex)
            return@fromCo parse(textSlice).successfully()
        }

    override fun getBlocksBetweenCursors(start: ContentCursor, end: ContentCursor, callback: Callback<StandardBlocks>) {
        callback.fromCo { getBlocksBetweenCursors(start, end) }
    }

    @JsExport.Ignore
    override fun search(query: String, startCursor: ContentCursor?): Flow<List<SearchMatch>> = flow {
        var searchMatches = mutableListOf<SearchMatch>()

        suspend fun emitMatches() {
            if (searchMatches.isNotEmpty()) {
                emit(searchMatches)
                searchMatches = mutableListOf()
            }
        }

        suspend fun addMatch(matchIndex: Int) {
            val start = view.getCursor(matchIndex).orThrow()
            val end = view.getCursor(matchIndex + query.length - 1).orThrow()
            searchMatches.add(SearchMatch(start, end))
        }

        fun String.matchesQueryAt(index: Int): Boolean =
            regionMatches(
                thisOffset = index,
                other = query,
                otherOffset = 0,
                length = query.length,
                ignoreCase = true,
            )

        fun Int.onOverflow(default: Int): Int =
            if (this < 0) default else this

        /** Emits occurrences of [query] in the substring from [startIndex] (inclusive) to [endIndex] (exclusive). */
        suspend fun String.scan(startIndex: Int, endIndex: Int, chunkSize: Int): Int {
            var index = startIndex
            var chuckEnd = (startIndex + chunkSize).onOverflow(endIndex)
            var firstMatchIndex = -1
            while (index <= endIndex - query.length) {
                if (index > chuckEnd) {
                    emitMatches()
                    chuckEnd = (index + chunkSize).onOverflow(endIndex)
                }
                if (this.matchesQueryAt(index)) {
                    addMatch(index)
                    if (firstMatchIndex == -1) {
                        firstMatchIndex = index
                    }
                    index += query.length
                } else {
                    index += 1
                }
            }
            // emit remaining matches
            emitMatches()

            return firstMatchIndex
        }

        val scanChuckSize = 2 shl 16
        val startIndex = startCursor?.let { characterIndexFromCursor(it) } ?: 0
        val text = fullText.get().text

        // start scanning from startIndex to the end of the text
        val firstMatchIndex = text.scan(startIndex, text.length, scanChuckSize)

        // start scanning from the beginning of the text to startIndex
        val scanEndIndex = (startIndex + query.length - 1).onOverflow(text.length).let {
            if (firstMatchIndex < 0) it else minOf(it, firstMatchIndex) // to avoid overlapping with a found match
        }
        text.scan(0, scanEndIndex, scanChuckSize)
    }

    override fun destroy() = Unit // NOOP

    private suspend fun getBlocksBetweenCursors(start: ContentCursor, end: ContentCursor): Result<StandardBlocks> {
        val startIdx = indexOfLinePreviousLineTerminator(fullText.get().text, characterIndexFromCursor(start))
        val endIdx = indexOfLineTerminator(fullText.get().text, characterIndexFromCursor(end))
        val textBetweenBlocks = fullText.get().slice(startIdx, endIdx)
        return parse(textBetweenBlocks).successfully()
    }

    fun getParagraphStart(text: String, startFrom: Int, numParagraphs: Int): Int {
        var end = startFrom
        var paragraphCount = 0

        while (paragraphCount < numParagraphs) {
            val lineTerminatorIdx = indexOfLineTerminator(text, end + 1)
            if (end + 1 < lineTerminatorIdx) {
                // We only increase the number of paragraphs if the next character is not also a line terminator
                // This way we avoid returning fewer paragraphs than requested
                paragraphCount++
            }
            end = lineTerminatorIdx
            if (end >= text.length) {
                break
            }
        }
        return end
    }

    private fun getParagraphStartBackwards(text: String, startFrom: Int, numParagraphs: Int): Int {
        var end = startFrom
        var paragraphCount = 0

        while (paragraphCount < numParagraphs) {
            val lineTerminatorIdx = indexOfLinePreviousLineTerminator(text, end - 1)
            if (end - 1 > lineTerminatorIdx) {
                // We only increase the number of paragraphs if the previous character is not also a line terminator
                // This way we avoid returning fewer paragraphs than requested
                paragraphCount++
            }
            end = lineTerminatorIdx
            if (end <= 0) {
                break
            }
        }
        return end
    }

    private suspend fun characterIndexFromCursor(cursor: ContentCursor): Int {
        val index = when (cursor) {
            is ContentTextPosition -> cursor.characterIndex
            is ContentElementBoundary -> {
                when (cursor.boundary) {
                    is ContentBoundary.START -> 0
                    is ContentBoundary.END -> view.coGetLength().orDefaultWith { 0 }
                }
            }
        }

        return index
    }

    private fun parse(t: ContentText): StandardBlocks {
        val blocks = mutableListOf<StandardBlock.Paragraph>()
        var charCount = 0
        while (charCount < t.length) {
            val end = indexOfLineTerminator(t.text, charCount)
            if (end > charCount) {
                blocks.add(StandardBlock.Paragraph(t.slice(charCount, end)))
            }
            charCount = end + 1
        }
        return StandardBlocks(blocks.toTypedArray(), t.start, t.end)
    }
}

private fun indexOfLineTerminator(text: String, start: Int): Int {
    if (start >= text.length || text.isEmpty()) return text.length
    for (charIndex in start until text.length) {
        if (isLineTerminator(text[charIndex])) {
            return charIndex
        }
    }
    return text.length
}

private fun indexOfLinePreviousLineTerminator(text: String, start: Int): Int {
    if (start < 0 || text.isEmpty()) return 0
    for (charIndex in start downTo 0) {
        if (isLineTerminator(text[charIndex])) {
            return charIndex
        }
    }
    return 0
}

private fun isLineTerminator(char: Char) = char == '\n' || char == '\r'
