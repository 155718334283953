package com.speechify.client.reader.epub

import com.speechify.client.api.adapters.archiveFiles.ZipFileEntry
import com.speechify.client.api.content.Content
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.content.view.epub.EpubChapter
import com.speechify.client.api.content.view.epub.EpubViewV3
import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.replaceWithSuspend
import com.speechify.client.internal.webview.NativeWebView
import com.speechify.client.internal.webview.WebResourceRequest
import com.speechify.client.internal.webview.WebResourceResponse
import com.speechify.client.internal.webview.WebView
import com.speechify.client.internal.webview.WebViewListener
import com.speechify.client.internal.webview.coEvaluateJavaScript
import com.speechify.client.internal.webview.coLoadDataWithBaseURL
import com.speechify.client.internal.webview.createResourceUrl
import com.speechify.client.internal.webview.getParentDirectoryPathFromFile
import com.speechify.client.internal.webview.runOnMainThread
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.PlaybackCommand
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ReadingLocationCommand
import com.speechify.client.reader.core.SelectionHelperCommand
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.core.dispatch
import com.speechify.client.reader.epub.EpubViewHelper.EpubReaderViewCommand
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.distinctUntilChangedBy
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.withContext
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import kotlin.js.JsExport

@OptIn(ExperimentalSerializationApi::class)
private val JSON = Json {
    explicitNulls = false
}

@OptIn(FlowPreview::class)
@JsExport
class EpubChapterHelper internal constructor(
    internal val epubChapter: EpubChapter,
    private val epubViewV3: EpubViewV3,
    private val epubReaderConfigFlow: StateFlow<EpubReaderConfig>,
    scope: CoroutineScope,
    readerFeatures: Flow<ReaderFeatures>,
) : Helper<EpubChapterView>(scope), WebViewListener {

    val chapterIndex = epubChapter.index
    private val link = epubChapter.link

    // Using runOnMainThread which use [runBlocking] under the hood,
    // since we are instantiating a UI component.
    private val webView: NativeWebView = runOnMainThread {
        epubViewV3.webViewAdapter.createWebView(this@EpubChapterHelper as WebViewListener)
    }

    private suspend fun computeChapterRangeLocation(
        content: Content,
    ) = EpubRangeLocation(
        anchor = epubViewV3.cursorToEpubLocation(content.start),
        focus = epubViewV3.cursorToEpubLocation(content.end),
    )

    override val initialState: EpubChapterView = EpubChapterView(
        webView = webView,
        selectionCoordinates = null,
        navigationIntent = null,
        webViewContentHeightInPx = null,
    )

    private val _stateFlow = MutableStateFlow(initialState)
    override val stateFlow: StateFlow<EpubChapterView> = _stateFlow.asStateFlow()

    // This flow will not be emitting when the layout is set to vertical pagination.
    // this is helpful to prevent extra calculation.
    private val fineNavigationIntentFlowForHorizontalPagination = readerFeatures.mapNotNull {
        if (epubReaderConfigFlow.value.epubPaginationOrientation.isHorizontal()) {
            it.navigationIntent
        } else {
            null
        }
    }

    /**
     * Helps determine the content height.
     * Some platforms require attaching the WebView to a container first to obtain the correct height. E.g: iOS.
     */
    private val isWebViewAttachedToSuperViewStateFlow = MutableStateFlow(false)

    init {
        launchInHelper {
            // Find the chapter file entry in the zip archive of the EPUB.
            val chapterFileEntry: ZipFileEntry = epubViewV3.epubV3.zipArchiveView.entries.find {
                it.path.endsWith(link.href.path)
            } ?: throw IllegalStateException("EpubChapter Helper Could not find the chapter file!")

            // Map any relative URLs (like images, CSS) within the chapter content to valid resource URLs
            // according to the platform's requirements.
            val content: String = chapterFileEntry.resolveRelativeResourceUrls()

            // Find the OPF file (the Open Packaging Format file) in the EPUB, which typically
            // resides in the root directory.
            val epubOPFFile: ZipFileEntry = epubViewV3.epubV3.zipArchiveView.entries.find {
                it.path.endsWith(epubViewV3.epubV3.opfFilePath)
            } ?: return@launchInHelper

            val fileDirectoryAsBaseUrl: String? =
                getParentDirectoryPathFromFile(epubOPFFile.coCreateBinaryContentReadableRandomly())

            // Load content into the web view.
            withContext(Dispatchers.Main) {
                webView.coLoadDataWithBaseURL(
                    baseUrl = fileDirectoryAsBaseUrl,
                    data = content,
                    mimeType = link.mediaType?.typeSubtype ?: "text/html",
                    encoding = "UTF-8",
                )
                injectConfigurationScripts()
                jsRemoveAnyVideoAndAudioElements()
            }
            /**
             * Callback invoked when a JavaScript message is received from the web view.
             */
            webView.onJSMessageReceived = { message ->
                when (val jsPostMessage = JSON.decodeFromString<JSPostMessage>(message)) {
                    is JSPostMessage.ClearSelection -> {
                        dispatch(SelectionHelperCommand.ClearSelection)
                    }

                    is JSPostMessage.GoToNextChapter -> {
                        val nextChapterIndex = (chapterIndex + 1).coerceAtMost(epubViewV3.totalChapters - 1)
                        dispatch(EpubReaderViewCommand.GoToNextChapter(nextChapterIndex))
                        dispatch(EpubChapterHelperCommand.ScrollToFirstPage(chapterIndex = nextChapterIndex))
                    }

                    is JSPostMessage.GoToPreviousChapter -> {
                        val previousChapterIndex = (chapterIndex - 1).coerceAtLeast(0)
                        dispatch(EpubReaderViewCommand.GoToNextChapter(chapterIndex = previousChapterIndex))
                        dispatch(EpubChapterHelperCommand.ScrollToLastPage(chapterIndex = previousChapterIndex))
                    }

                    is JSPostMessage.OnSelectionChanged -> {
                        dispatch(
                            EpubChapterHelperCommand.SetOrUpdateSelection(
                                chapterIndex = chapterIndex,
                                epubRangeLocation = jsPostMessage.data,
                            ),
                        )
                    }

                    is JSPostMessage.TapToPlay -> {
                        when (epubReaderConfigFlow.value.tapActionIntent) {
                            EpubTapActionIntent.Jump -> {
                                dispatch(
                                    EpubChapterHelperCommand.TapToJump(
                                        chapterIndex = chapterIndex,
                                        epubLocation = jsPostMessage.data,
                                    ),
                                )
                            }

                            EpubTapActionIntent.Play -> {
                                dispatch(
                                    EpubChapterHelperCommand.TapToPlay(
                                        chapterIndex = chapterIndex,
                                        epubLocation = jsPostMessage.data,
                                    ),
                                )
                            }
                        }
                    }
                }
            }
            commands
                .filterIsInstance<EpubChapterHelperCommand>()
                .filter { it.chapterIndex == chapterIndex }
                .onEachInstance<EpubChapterHelperCommand.ScrollToNextPage> {
                    jsScrollToNextPage()
                }
                .onEachInstance<EpubChapterHelperCommand.ScrollToPreviousPage> {
                    jsScrollToPreviousPage()
                }
                .onEachInstance<EpubChapterHelperCommand.ScrollToFirstPage> {
                    jsScrollToContentStart()
                }
                .onEachInstance<EpubChapterHelperCommand.ScrollToLastPage> {
                    jsScrollToContentEnd()
                }
                .onEachInstance<EpubChapterHelperCommand.TapToPlay> {
                    val cursor = epubViewV3.epubLocationToCursor(it.chapterIndex, it.epubLocation)
                    dispatch(
                        PlaybackCommand.TapToPlay(
                            location = SerialLocation(cursor),
                            relativeNavigationIntent =
                            epubReaderConfigFlow.value.relativeNavigationIntentOfTapUserAction,
                            // TODO: Provide a relevant value for enableAutoscroll
                            enableAutoscroll = false,
                        ),
                    )
                }.onEachInstance<EpubChapterHelperCommand.TapToJump> {
                    val cursor = epubViewV3.epubLocationToCursor(it.chapterIndex, it.epubLocation)
                    dispatch(
                        PlaybackCommand.TapToJump(
                            location = SerialLocation(cursor),
                            relativeNavigationIntent =
                            epubReaderConfigFlow.value.relativeNavigationIntentOfTapUserAction,
                        ),
                    )
                }.onEachInstance<EpubChapterHelperCommand.SetOrUpdateSelection> {
                    val anchorCursor = epubViewV3.epubLocationToCursor(it.chapterIndex, it.epubRangeLocation.anchor)
                    val focusCursor = epubViewV3.epubLocationToCursor(it.chapterIndex, it.epubRangeLocation.focus)
                    val (orderedAnchorCursor, orderedFocusCursor) = if (anchorCursor.isAfter(focusCursor)) {
                        focusCursor to anchorCursor
                    } else {
                        anchorCursor to focusCursor
                    }
                    dispatch(
                        SelectionHelperCommand.SetOrUpdateEntireSelection(
                            anchor = SerialLocation(orderedAnchorCursor),
                            focus = SerialLocation(orderedFocusCursor),
                        ),
                    )
                }.onEachInstance<EpubChapterHelperCommand.ClearWordAndSentenceHighlight> {
                    jsClearWordAndSentenceHighlight()
                }.launchInHelper()

            fineNavigationIntentFlowForHorizontalPagination.onEach {
                it.nullIf {
                    !epubChapter.contains(this.location.hack.cursor)
                }?.let {
                    val epubLocation = epubViewV3.cursorToEpubLocation(it.location.hack.cursor)
                    jsScrollToEpubLocation(epubLocation)
                    dispatch(EpubNavigationCommand.ConsumeFineIntent(intent = it))
                }
            }.launchInHelper()

            readerFeatures.map { it.highlights }.onEach {
                it.items
                    .filter { it.highlight.span.hasNontrivialIntersectionWith(epubChapter) }
                    .forEach {
                        jsSetOrUpdateUserHighlight(it.highlight)
                    }
            }.launchInHelper()

            // UI clients appearance configs for epub chapters.
            epubReaderConfigFlow.map { it.selectionBGHexColor }.distinctUntilChanged().onEach {
                jsSetSelectionBGColor(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.fontScale }.distinctUntilChanged().onEach {
                jsSetFontScale(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.wordHighlightHexColor }.distinctUntilChanged().onEach {
                jsSetWordHighlightBGColor(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.sentenceHighlightHexColor }.distinctUntilChanged().onEach {
                jsSetSentenceHighlightBGColor(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.textColor }.distinctUntilChanged().onEach {
                jsSetTextColor(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.backgroundColor }.distinctUntilChanged().onEach {
                jsSetBackgroundColor(it)
            }.launchInHelper()

            epubReaderConfigFlow.map { it.epubPaginationOrientation }.distinctUntilChanged().onEach {
                jsSetLayoutPaginationOrientation(it)
            }.launchInHelper()

            val epubTextAdjustmentsConfigFlow = epubReaderConfigFlow.map { it.epubTextAdjustmentsConfig }

            epubTextAdjustmentsConfigFlow.map { it.lineSpacing }
                .distinctUntilChanged()
                .onEach {
                    jsSetLineSpacing(it)
                }.launchInHelper()

            epubTextAdjustmentsConfigFlow.map { it.letterSpacing }
                .distinctUntilChanged()
                .onEach {
                    jsSetLetterSpacing(it)
                }.launchInHelper()

            epubTextAdjustmentsConfigFlow.map { it.wordSpacing }
                .distinctUntilChanged()
                .onEach {
                    jsSetWordSpacing(it)
                }.launchInHelper()

            epubTextAdjustmentsConfigFlow.map { it.horizontalMargins }
                .distinctUntilChanged()
                .onEach {
                    jsSetHorizontalMargins(it)
                }.launchInHelper()

            epubTextAdjustmentsConfigFlow.map { it.fontWeight }
                .distinctUntilChanged()
                .onEach {
                    jsSetFontWeight(it)
                }.launchInHelper()

            epubTextAdjustmentsConfigFlow.map { it.textAlignment }
                .distinctUntilChanged()
                .onEach {
                    jsSetTextAlignment(it)
                }.launchInHelper()

            readerFeatures.map { it.selection }.distinctUntilChanged().onEach {
                val selectionCoordinates = it?.nullIf {
                    !this.hasNontrivialIntersectionWith(epubChapter)
                }?.let {
                    computeChapterRangeLocation(it)
                }?.let {
                    jsGetSelectionCoordinates()
                }
                _stateFlow.update {
                    it.copy(selectionCoordinates = selectionCoordinates)
                }
            }.launchInHelper()

            combine(
                epubReaderConfigFlow
                    .filter { it.epubPaginationOrientation.isVertical() }
                    .distinctUntilChangedBy { it.fontScale },
                epubTextAdjustmentsConfigFlow,
                isWebViewAttachedToSuperViewStateFlow.filter { it },
            ) { _, _, _ -> }
                .onEach {
                    val contentHeight = jsGetWebViewContentHeightInPx()
                    _stateFlow.update {
                        it.copy(
                            webViewContentHeightInPx = contentHeight,
                        )
                    }
                }
                /**
                 * Important note: We wait here for the content to be fully laid out and provide the content height
                 * to the client before navigating to a specific location.
                 * This applies only to vertical layouts where the client needs to navigate to
                 * a specific Y position on the screen.
                 */
                .combine(

                    readerFeatures.mapNotNull {
                        // This flow will not be emitting when the layout is set to horizontal pagination.
                        // this is helpful to prevent extra calculation.
                        if (epubReaderConfigFlow.value.epubPaginationOrientation.isVertical()) {
                            it.navigationIntent
                        } else {
                            null
                        }
                    }
                        /**
                         *  Delay here because WebView needs a short delay to fully load static chapter content
                         *  and its resources.
                         *  The navigation intent might depend on WebView's size, which can
                         *  change during loading.
                         *  Ideally, navigation intents should be updated whenever the WebView size changes.
                         *  For now, this workaround ensures navigation works correctly after content loads.
                         */
                        .debounce(200),
                ) { _, fineNavigation ->
                    fineNavigation
                }.distinctUntilChanged().onEach {
                    it.let { navIntent ->
                        val chapterIndexFromCursor = epubViewV3.getChapterIndex(navIntent.location.hack.cursor)
                        navIntent.nullIf {
                            chapterIndexFromCursor != chapterIndex
                        }?.let {
                            val epubLocation = epubViewV3.cursorToEpubLocation(navIntent.location.hack.cursor)
                            jsGetNormalizedTextOverlayTopYAndHeightFromEpubLocation(epubLocation)
                        }?.let { topYAndHeight ->
                            FineEpubNavigationIntent(
                                dispatch = dispatch,
                                resolvedIntent = navIntent,
                                normalizedTextOverlayTopYPosition = topYAndHeight.normalizedTextOverlayTopYPosition,
                                normalizedTextOverlayHeight = topYAndHeight.normalizedTextOverlayHeight,
                            )
                        }
                    }?.let { nav ->
                        _stateFlow.update {
                            it.copy(navigationIntent = nav)
                        }
                    }
                }.launchInHelper()

            readerFeatures.mapNotNull { it.speakingWord }.onEach {
                it.nullIf {
                    !this.hasNontrivialIntersectionWith(epubChapter)
                }?.let {
                    computeChapterRangeLocation(it)
                }?.let {
                    jsHighlightWord(it)
                }
            }.launchInHelper()

            readerFeatures.mapNotNull { it.speakingSentence }.onEach {
                it.nullIf {
                    !this.hasNontrivialIntersectionWith(epubChapter)
                }?.let {
                    computeChapterRangeLocation(it)
                }?.let {
                    jsHighlightSentence(it)
                }
            }.launchInHelper()
        }
    }

    /**
     * Resolves any relative URLs (e.g., images, CSS) within the content of this zip file entry (epub chapter)
     * into valid, platform-specific resource URLs.
     */
    private suspend fun ZipFileEntry.resolveRelativeResourceUrls(): String {
        val contentFile = this.coCreateBinaryContentReadableRandomly()
        val chapterContentAsString = contentFile.coGetAllBytes()
            .orThrow()
            .decodeToString()
        val chapterContentWithNewResourcesLinks = chapterContentAsString
            .replaceWithSuspend(Regex("""(src|href)="(.+?)"""")) { match ->
                val (attr, path) = match.destructured
                val isEpubResourceFile = epubViewV3.epubV3.readingOrder.none {
                    it.href.path.endsWith(path)
                }
                // We don't change the paths that point to a local chapters, for example in the TOC chapter.
                if (!isEpubResourceFile) {
                    return@replaceWithSuspend """$attr="$path""""
                }
                val fullPath = path.replace("../", "")
                val resourceEntry = epubViewV3.epubV3.zipArchiveView.entries.find {
                    it.path.endsWith(fullPath)
                } ?: return@replaceWithSuspend """$attr="$path""""

                val cssFileAsDataUrlWithEmbeddedFonts = epubViewV3.epubV3.mapOfCssFilesAsDataUrlsWithEmbeddedFonts
                    .getWithCancellation()[resourceEntry.path]
                return@replaceWithSuspend when {
                    cssFileAsDataUrlWithEmbeddedFonts != null -> """$attr="$cssFileAsDataUrlWithEmbeddedFonts""""
                    else -> {
                        val url = createResourceUrl(resourceEntry.coCreateBinaryContentReadableRandomly())
                        """$attr="$url""""
                    }
                }
            }
        return chapterContentWithNewResourcesLinks
    }

    private suspend fun injectConfigurationScripts() {
        (
            uiAndPaginationConfigurations +
                utilJavascriptFunctions +
                wordHighlightScript +
                javascriptUtils +
                injectWordHighlightSpanScript +
                sentenceHighlightDivInjectionScript +
                highlightSentenceScript +
                clickEventListenerJSScript +
                selectionJSScript +
                scrollUtilJSScripts +
                selectionHighlightDivInjectionScript +
                selectionHighlightScript +
                getFocusAndAnchorCoordinatesOfCurrentSelectionScript +
                createUserHighlightDivElementScript +
                userHighlightsScript
            ).also {
            withContext(Dispatchers.Main) {
                webView.coEvaluateJavaScript(it)
            }
        }
    }

    private suspend fun jsSetFontScale(scale: Double) {
        val jsScript = "document.documentElement.style.setProperty('--USER__fontSize', '${scale * 100}%');"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetSelectionBGColor(colorHexCode: String) {
        val jsScript =
            "document.documentElement.style.setProperty('--USER__selectionBackgroundColor', '$colorHexCode');"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetWordHighlightBGColor(colorHexCode: String) {
        val jsScript = "updateWordHighlightColor('$colorHexCode');"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetSentenceHighlightBGColor(colorHexCode: String) {
        val jsScript = "updateSentenceHighlightColor('$colorHexCode');"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsHighlightWord(epubRangeLocation: EpubRangeLocation) {
        val jsScript = """
                        highlightWord(
                            ${epubRangeLocation.anchor.jsPath},
                            ${epubRangeLocation.focus.jsPath},
                            ${epubRangeLocation.anchor.charIndex},
                            ${epubRangeLocation.focus.charIndex + 1},
                            '${epubReaderConfigFlow.value.wordHighlightHexColor}'
                        );
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsHighlightSentence(epubRangeLocation: EpubRangeLocation) {
        val jsScript = """
                        highlightSentence(
                            ${epubRangeLocation.anchor.jsPath},
                            ${epubRangeLocation.focus.jsPath},
                             ${epubRangeLocation.anchor.charIndex},
                             ${epubRangeLocation.focus.charIndex + 1},
                             '${epubReaderConfigFlow.value.sentenceHighlightHexColor}');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsScrollToEpubLocation(epubLocation: EpubLocation) {
        val jsScript = """
                        scrollToEpubLocation(${epubLocation.jsPath}, ${epubLocation.charIndex});
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsScrollToContentEnd() {
        val jsScript = "window.scrollTo(document.scrollingElement.scrollWidth, 0);"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsScrollToContentStart() {
        val jsScript = "window.scrollTo(0, 0);"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsScrollToNextPage() {
        val jsScript = "scrollToNextPage();"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsScrollToPreviousPage() {
        val jsScript = "scrollToPreviousPage();"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsUpdateSelectionHighlight(epubRangeLocation: EpubRangeLocation) {
        /* This commented out for now, because we are using the Native WebView Selection */
        /*val jsScript = """
                        highlightSelection(
                            ${epubRangeLocation.anchor.jsPath},
                            ${epubRangeLocation.focus.jsPath},
                             ${epubRangeLocation.anchor.charIndex},
                             ${epubRangeLocation.focus.charIndex});
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }*/
    }

    private suspend fun jsGetSelectionCoordinates(): EpubSelectionCoordinates? {
        val stringResult = withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript("getSelectionCoordinates()")
        }

        return stringResult?.let {
            JSON.decodeFromString<EpubSelectionCoordinates>(it)
        }
    }

    private suspend fun jsSetOrUpdateUserHighlight(highlight: UserHighlight) {
        val anchor = epubViewV3.cursorToEpubLocation(highlight.robustStart.hack.cursor)
        val focus = epubViewV3.cursorToEpubLocation(highlight.robustEnd.hack.cursor)
        val colorTokens = epubReaderConfigFlow.value.userHighlightColorTokens
        val colorHexCode = highlight.style.colorToken?.let { highlightColorToken ->
            colorTokens.find { it.first == highlightColorToken }?.second
        } ?: colorTokens.find {
            it.first == epubReaderConfigFlow.value.userHighlightFallbackColorToken
            // Validation for colorTokens is done when initializing EpubReaderConfig, so this !! is safe.
        }!!.second
        val jsScript = """
            setOrUpdateUserHighlight(
              '${highlight.id}',
              ${anchor.jsPath},
              ${focus.jsPath},
              ${anchor.charIndex},
              ${focus.charIndex},
              '$colorHexCode'
            );
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetLayoutPaginationOrientation(orientation: EpubPaginationOrientation) {
        val jsScript = """
            document.documentElement.style.setProperty(
                '--USER__layoutPaginationOrientation',
                '${orientation.name.lowercase()}'
            );
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsGetNormalizedTextOverlayTopYAndHeightFromEpubLocation(
        epubLocation: EpubLocation,
    ): EpubTextOverlayTopYAndHeight? {
        val jsScript =
            "getNormalizedTextOverlayTopYAndHeightFromEpubLocation(${epubLocation.jsPath}, ${epubLocation.charIndex});"
        val stringResult = withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(script = jsScript)
        }
        return stringResult?.let {
            JSON.decodeFromString<EpubTextOverlayTopYAndHeight>(it)
        }
    }

    private suspend fun jsGetWebViewContentHeightInPx(): Double? {
        return withContext(Dispatchers.Main) {
            val jsGetContentHeight = """
                 Math.ceil(window.document.documentElement.offsetHeight) + 1
            """.trimIndent()
            val height = webView.coEvaluateJavaScript(jsGetContentHeight)
            height?.toDouble()
        }
    }

    private suspend fun jsSetTextColor(colorHexCode: String?) {
        val jsScript = """
            document.documentElement.style.setProperty('--USER__textColor', '${colorHexCode ?: "auto"}');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetBackgroundColor(colorHexCode: String?) {
        val jsScript = """
            document.documentElement.style.setProperty('--USER__backgroundColor', '${colorHexCode ?: "auto"}');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetLineSpacing(lineSpacing: TextAdjustmentValue) {
        val value = when (lineSpacing) {
            TextAdjustmentValue.Default -> TextAdjustmentValue.Default.VALUE
            is TextAdjustmentValue.EMUnit -> "${lineSpacing.value}em"
            is TextAdjustmentValue.PXUnit -> "${lineSpacing.value}px"
        }
        val jsScript = """
            document.documentElement.style.setProperty('--USER__lineSpacingInEmUnit', '$value');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetLetterSpacing(letterSpacing: TextAdjustmentValue) {
        val value = when (letterSpacing) {
            TextAdjustmentValue.Default -> TextAdjustmentValue.Default.VALUE
            is TextAdjustmentValue.EMUnit -> "${letterSpacing.value}em"
            is TextAdjustmentValue.PXUnit -> "${letterSpacing.value}px"
        }
        val jsScript = """
            document.documentElement.style.setProperty('--USER__letterSpacingInEmUnit', '$value');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetWordSpacing(wordSpacing: TextAdjustmentValue) {
        val value = when (wordSpacing) {
            TextAdjustmentValue.Default -> TextAdjustmentValue.Default.VALUE
            is TextAdjustmentValue.EMUnit -> "${wordSpacing.value}em"
            is TextAdjustmentValue.PXUnit -> "${wordSpacing.value}px"
        }
        val jsScript = """
            document.documentElement.style.setProperty('--USER__wordSpacingInEmUnit', '$value');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetHorizontalMargins(margin: TextAdjustmentValue) {
        val value = when (margin) {
            TextAdjustmentValue.Default -> "unset"
            is TextAdjustmentValue.EMUnit -> "${margin.value}em"
            is TextAdjustmentValue.PXUnit -> "${margin.value}px"
        }
        val jsScript = """
            document.documentElement.style.setProperty('--USER__horizontalMarginsInEmUnit', '$value');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetFontWeight(fontWeight: FontWeight) {
        val jsScript = """
            document.documentElement.style.setProperty('--USER__fontWeight', '${fontWeight.name.lowercase()}');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsSetTextAlignment(textAlignment: TextAlignment) {
        val jsScript = """
            document.documentElement.style.setProperty('--USER__textAlign', '${textAlignment.name.lowercase()}');
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    // Remove any embedded <video> or <audio> elements.
    private suspend fun jsRemoveAnyVideoAndAudioElements() {
        val jsScript = """
            document.querySelectorAll('video, audio').forEach(el => el.remove());
        """.trimIndent()
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    private suspend fun jsClearWordAndSentenceHighlight() {
        val jsScript = "clearWordAndSentenceHighlight();"
        withContext(Dispatchers.Main) {
            webView.coEvaluateJavaScript(jsScript)
        }
    }

    override fun onInterceptRequest(request: WebResourceRequest, callback: Callback<WebResourceResponse?>) =
        callback.fromCo {
            val resourceZipEntry = epubViewV3.epubV3.zipArchiveView.entries.find {
                request.url.lowercase().endsWith(it.path.lowercase())
            } ?: return@fromCo com.speechify.client.api.util.Result.Success(null)

            val content = resourceZipEntry.coCreateBinaryContentReadableRandomly().coGetAllBytes()
                .orThrow()
            WebResourceResponse(
                content = content,
            ).successfully()
        }

    override fun onSwipeLeft() {
        dispatch(EpubChapterHelperCommand.ScrollToNextPage(chapterIndex))
    }

    override fun onSwipeRight() {
        dispatch(EpubChapterHelperCommand.ScrollToPreviousPage(chapterIndex))
    }

    override fun onAttached() {
        isWebViewAttachedToSuperViewStateFlow.value = true
    }

    override fun onDetached() {
        isWebViewAttachedToSuperViewStateFlow.value = false
    }

    fun markAsCurrentlyReading() {
        dispatch(
            ReadingLocationCommand.SetReadingLocation(
                location = SerialLocation(
                    cursor = epubChapter.start,
                ),
            ),
        )
    }

    override fun destroy() {
        scope.cancel()
        super.destroy()
        webView.destroy()
    }
}

internal sealed class EpubChapterHelperCommand {
    abstract val chapterIndex: Int

    data class ScrollToFirstPage(override val chapterIndex: Int) : EpubChapterHelperCommand()
    data class ScrollToLastPage(override val chapterIndex: Int) : EpubChapterHelperCommand()
    data class ScrollToNextPage(override val chapterIndex: Int) : EpubChapterHelperCommand()
    data class ScrollToPreviousPage(override val chapterIndex: Int) : EpubChapterHelperCommand()
    data class TapToPlay(override val chapterIndex: Int, val epubLocation: EpubLocation) :
        EpubChapterHelperCommand()

    data class TapToJump(override val chapterIndex: Int, val epubLocation: EpubLocation) :
        EpubChapterHelperCommand()

    data class SetOrUpdateSelection(override val chapterIndex: Int, val epubRangeLocation: EpubRangeLocation) :
        EpubChapterHelperCommand()

    data class ClearWordAndSentenceHighlight(override val chapterIndex: Int) : EpubChapterHelperCommand()
}

@Serializable
internal data class EpubLocation(
    // Path to a node element in the DOM, excluding the indexes of the html and body elements.
    // Only the html and body elements' indexes vary between platforms. For example:
    // - On Android WebView, line breaks are kept outside the HTML tag which makes it's index bigger than 0.
    // - On iOS WebView, they are not makes its index equal to 0.
    val nodePathExcludingHtmlAndBody: List<Int>,
    val charIndex: Int,
) {
    internal val jsPath: String = nodePathExcludingHtmlAndBody.joinToString(", ", "[", "]")
}

@Serializable
internal data class EpubRangeLocation(
    val anchor: EpubLocation,
    val focus: EpubLocation,
)

@JsExport
data class EpubChapterView internal constructor(
    val webView: WebView,
    val selectionCoordinates: EpubSelectionCoordinates?,
    val navigationIntent: FineEpubNavigationIntent?,
    val webViewContentHeightInPx: Double?,
)

/**
 * Represents the coordinates of a text selection within the ePub chapter webView.
 *
 * This data class captures the positions of the anchor and focus points of a text selection.
 *
 * - The `anchor` represents the starting point of the selection, corresponding to the top-left
 *   corner of the first rectangle in the selection range.
 * - The `focus` represents the endpoint of the selection, corresponding to the bottom-right
 *   corner of the last rectangle in the selection range.
 */
@JsExport
@Serializable
data class EpubSelectionCoordinates(
    val anchor: Point,
    val focus: Point,
)

@JsExport
@Serializable
data class Point(val x: Double, val y: Double)

@Serializable(with = JSPostMessageSerializer::class)
private sealed class JSPostMessage {
    abstract val action: Action

    @Serializable(with = ActionSerializer::class)
    enum class Action {
        TapToPlay,
        OnSelectionChanged,
        ClearSelection,
        GoToNextChapter,
        GoToPreviousChapter,
        UNKNOWN, ;

        companion object {
            fun fromString(value: String) = values().find { it.name == value.uppercase() } ?: UNKNOWN
        }
    }

    @Serializable
    data class TapToPlay(
        override val action: Action,
        val data: EpubLocation,
    ) : JSPostMessage()

    @Serializable
    data class OnSelectionChanged(
        override val action: Action,
        val data: EpubRangeLocation,
    ) : JSPostMessage()

    @Serializable
    data class ClearSelection(
        override val action: Action,
    ) : JSPostMessage()

    @Serializable
    data class GoToNextChapter(
        override val action: Action,
    ) : JSPostMessage()

    @Serializable
    data class GoToPreviousChapter(
        override val action: Action,
    ) : JSPostMessage()
}

private object ActionSerializer : KSerializer<JSPostMessage.Action> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Action", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: JSPostMessage.Action) {
        encoder.encodeString(value.name)
    }

    override fun deserialize(decoder: Decoder): JSPostMessage.Action {
        return JSPostMessage.Action.fromString(decoder.decodeString())
    }
}

private object JSPostMessageSerializer : JsonContentPolymorphicSerializer<JSPostMessage>(JSPostMessage::class) {
    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<JSPostMessage> {
        val action = element.jsonObject["action"]?.jsonPrimitive?.content
        return when (action) {
            "TapToPlay" -> JSPostMessage.TapToPlay.serializer()
            "OnSelectionChanged" -> JSPostMessage.OnSelectionChanged.serializer()
            "ClearSelection" -> JSPostMessage.ClearSelection.serializer()
            "GoToNextChapter" -> JSPostMessage.GoToNextChapter.serializer()
            "GoToPreviousChapter" -> JSPostMessage.GoToPreviousChapter.serializer()
            else -> JSPostMessage.serializer()
        }
    }
}

@Serializable
private data class EpubTextOverlayTopYAndHeight(
    val normalizedTextOverlayTopYPosition: Double,
    val normalizedTextOverlayHeight: Double,
)
