package com.speechify.client.bundlers.content

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.withStaticContentStats
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.googleCloudStorageUriFileIdFromUrl
import com.speechify.client.api.services.audiobook.AudiobookChapter
import com.speechify.client.api.services.audiobook.AudiobookChapterFileId
import com.speechify.client.api.services.ebook.SpeechifyBookBinaryData
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.telemetry.LibraryItemContentTypeTelemetryProp
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.MimeTypeOfListenableContent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.ensureListenableMimeType
import com.speechify.client.api.util.io.readAsByteArrayFile
import com.speechify.client.api.util.io.toFailureIfNoContentType
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.importing.BinaryContentImporterFactory
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.bundlers.reading.importing.SpeechifyGlobalResourceImporter
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.services.file.models.InMemoryFile
import com.speechify.client.internal.services.importing.ImportableContentPayload
import com.speechify.client.internal.services.importing.models.ItemRequiringImport
import com.speechify.client.internal.services.importing.models.LazyOCRFilesFromDbOcrFile
import com.speechify.client.internal.util.extensions.intentSyntax.nullIfNotNullAnd
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * A way to get a [ContentBundle] for any resource within the Speechify Platform.
 */
internal class SpeechifyContentBundler internal constructor(
    private val clientServices: SpeechifyClient,
    private val contentBundler: ContentBundler,
    private val blobStorageAdapter: BlobStorageAdapter,
    private val ocrAdapter: OCRAdapter,
) {

    internal suspend fun coCreateBundleForResource(
        uri: SpeechifyURI,
        bundleMetadata: BundleMetadata?,
        /**
         * Can be used to inspect the retrieved [LibraryItem] for this [uri].
         * TODO refactor non-library items out of here, and make this method return a [LibraryItem.ListenableContent]
         *  instead if [receiveLibraryItem]. (#TODORefactorNonLibraryItemsOutOfCreateBundleForResource)
         *  (consider turning [SpeechifyEntityType] into a sealed class with a subtype for library-item-based documents).
         */
        receiveLibraryItem: suspend (libraryItem: LibraryItem.ListenableContent) -> Unit = {},
    ): Result<ContentBundle> =
        when (uri.type) {
            SpeechifyEntityType.LIBRARY_ITEM -> {
                val item = clientServices.libraryService.delegate.getItemFromFirestoreOrLocalFromUri(uri)
                when (item) {
                    is LibraryItem.Folder -> {
                        Result.Failure(SDKError.OtherMessage("Folders not yet supported for bundling"))
                    }

                    is LibraryItem.Content -> {
                        createBundleForContentItem(item, bundleMetadata)
                            .also {
                                receiveLibraryItem(item)
                            }
                    }
                    is LibraryItem.DeviceLocalContent -> createBundleForDeviceLocalContentItem(item, bundleMetadata)
                        .also {
                            receiveLibraryItem(item)
                        }
                    is LibraryItem.ListenableContent -> throw IllegalArgumentException("Cannot bundle $item")
                }
            }
            /** TODO once #TODORefactorNonLibraryItemsOutOfCreateBundleForResource is done, consider consolidating
             *   it into one case, and call [receiveLibraryItem] for the result.
             */
            SpeechifyEntityType.SCANNED_BOOK -> {
                val item = clientServices.libraryService.delegate.getItemFromFirestoreOrLocalFromUri(uri)
                when (item) {
                    is LibraryItem.Folder -> {
                        Result.Failure(SDKError.OtherMessage("Folders not yet supported for bundling"))
                    }

                    is LibraryItem.Content -> {
                        createBundleForScannedBook(item)
                            .also {
                                receiveLibraryItem(item)
                            }
                    }
                    is LibraryItem.DeviceLocalContent -> createBundleForDeviceLocalContentItem(item, bundleMetadata)
                        .also {
                            receiveLibraryItem(item)
                        }
                    is LibraryItem.ListenableContent -> throw IllegalArgumentException("Cannot bundle $item")
                }
            }
            SpeechifyEntityType.AUDIOBOOK_CHAPTER -> {
                createBundleForAudioBookChapter(uri)
            }
            SpeechifyEntityType.FOLDER ->
                throw IllegalArgumentException("Cannot bundle folders")
        }
            .orReturn { return it }
            .apply {
                /** Set the title at the end - so late because there are many branches above, but also because there are
                 wrapping bundles, which re-start the title inference (due to the way it is implemented to start in
                 each instance of [ContentBundle].
                 */
                val title = title ?: clientServices.libraryService.delegate.getItemFromFirestore(uri.id)
                    .orReturn { return it }
                    .title
                bundle.title.set(title)
            }.bundle
            .successfully()

    internal suspend fun coCreateBundleForLibraryItem(
        item: LibraryItem.ListenableContent,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundle> = when (item.uri.type) {
        SpeechifyEntityType.LIBRARY_ITEM -> {
            when (item) {
                is LibraryItem.Content -> {
                    createBundleForContentItem(item, bundleMetadata)
                }
                is LibraryItem.DeviceLocalContent -> {
                    createBundleForDeviceLocalContentItem(item, bundleMetadata)
                }
                // Kotlin doesn't recognize that this is exhaustive.
                else -> throw IllegalArgumentException("Cannot bundle $item")
            }
        }

        SpeechifyEntityType.AUDIOBOOK_CHAPTER -> {
            createBundleForAudioBookChapter(item.uri)
        }

        SpeechifyEntityType.SCANNED_BOOK -> {
            when (item) {
                is LibraryItem.Content -> {
                    createBundleForScannedBook(item)
                }
                is LibraryItem.DeviceLocalContent -> {
                    createBundleForDeviceLocalContentItem(item, bundleMetadata = bundleMetadata)
                }
                // Kotlin doesn't recognize that this is exhaustive.
                else -> throw IllegalArgumentException("Cannot bundle $item")
            }
        }

        SpeechifyEntityType.FOLDER ->
            throw IllegalArgumentException("Cannot bundle folders")
    }
        .orReturn { return it }
        .apply {
            /** Set the title at the end - so late because there are many branches above, but also because there are
             wrapping bundles, which re-start the title inference (due to the way it is implemented to start in
             each instance of [ContentBundle].
             */
            val title = title ?: item.title
            bundle.title.set(title)
        }.bundle
        .successfully()

    private suspend fun createBundleForContentItem(
        item: LibraryItem.Content,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundleAndTitle> {
        val telemetryEventBuilder = currentTelemetryEvent()
        telemetryEventBuilder?.addProperty(LibraryItemContentTypeTelemetryProp.toPairWithVal(item.contentType))

        if (!item.isInListenableState) {
            return Result.Failure(SDKError.ContentNotInListenableState(item))
        }

        val bundleResult = run {
            if (item.contentType == ContentType.SPEECHIFY_BOOK) {
                val binaryContent = telemetryEventBuilder.addMeasurement("downloadResource") {
                    clientServices.ebookService.getEbookContentForLibraryItem(item.uri.id)
                }

                when (binaryContent) {
                    is SpeechifyBookBinaryData.EncryptedEbookData ->
                        return@run contentBundler.coCreateBundleForImportedBinaryContent(
                            item.uri,
                            item,
                            payload = ListenableBinaryContentPayload.createForBinaryContentWithMultiplatformAPI(
                                content = binaryContent.data.binaryContent,
                                mimeType = binaryContent.data.mimeType.ensureListenableMimeType(
                                    contentTypeForFallback = item.contentType,
                                ),
                                sourceUrl = item.sourceUrl,
                            )
                                .orReturn { return it },
                            bundleMetadata = bundleMetadata,
                        )

                    is SpeechifyBookBinaryData.PasswordProtectedPdfData ->
                        return@run contentBundler.coCreateBundleForImportedBinaryContent(
                            item.uri,
                            item,
                            payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                                content = binaryContent.data.binaryContent,
                                mimeType = binaryContent.data.mimeType.ensureListenableMimeType(
                                    contentTypeForFallback = item.contentType,
                                ),
                                sourceUrl = item.sourceUrl,
                                password = binaryContent.password,
                            )
                                .orReturn { return it },
                            bundleMetadata = bundleMetadata,
                        )
                }
            }
            if (
            /* For these formats, go straight to source file if available
          TODO do this for all other library items that have 'Instantly listenable' source,
            because [‘You must be able to get an improved listening experience on all the content in your Library’](https://www.notion.so/fresh-hoodie-9f1/The-Golden-Principles-of-the-Speechify-Experience-750a1679c30942a9801587eb3380f912)
        */
                setOf(
                    ContentType.PDF,
                    ContentType.HTML,
                    ContentType.TXT,
                    ContentType.EPUB,
                ).contains(item.contentType) &&
                item.sourceStoredUrl != null
            ) {
                val binaryContent = telemetryEventBuilder.addMeasurement("downloadResource") {
                    clientServices.firebaseStorageCache
                        .getBinaryContent(
                            clientServices.clientConfig
                                .googleCloudStorageUriFileIdFromUrl(item.sourceStoredUrl),
                        )
                }
                return@run contentBundler.coCreateBundleForImportedBinaryContent(
                    item.uri,
                    item,
                    payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                        content = binaryContent.binaryContent,
                        mimeType = binaryContent.mimeType.ensureListenableMimeType(
                            contentTypeForFallback = item.contentType,
                        ),
                        sourceUrl = item.sourceUrl,
                    )
                        .orReturn { return it },
                    bundleMetadata = bundleMetadata,
                )
            }

            // Legacy PDFs only have sourceUrl, and it points to the original content, stored in our GCS bucket
            if (item.contentType == ContentType.PDF && item.sourceUrl != null) {
                return@run contentBundler.coCreateBundleForImportedBinaryContent(
                    item.uri,
                    item,
                    payload = telemetryEventBuilder.addMeasurement("downloadResource") {
                        ListenableBinaryContentPayload.Pdf(
                            contentWithMimeType = run {
                                val url = item.sourceUrl

                                clientServices.adaptersProvider.httpClient.getBinaryContentReadableRandomly(
                                    url = url,
                                )
                                    /* TODO investigate if `toFailureIfNoContentType` is still needed.
                                     *  It is there just to keep historical behavior.
                                     */
                                    .toFailureIfNoContentType(url)
                                    .orReturn { return it }
                            },
                            sourceUrl = item.sourceUrl,
                        )
                    },
                    bundleMetadata = bundleMetadata,
                )
            }

            // Otherwise go to the extracted "pages"
            val pageUrls = suspendCoroutine {
                clientServices.libraryService.getLegacyPageURLs(
                    item.uri.id,
                    it::resume,
                )
            }.orReturn { return it }

            if (pageUrls.isEmpty()) {
                throw IllegalStateException(
                    "createBundleForContentItem: Library item in invalid state - was considered legacy, but the " +
                        "legacy `pages` sub-collection is empty",
                )
            }

            // Since legacy imported pages all contain HTML **without enclosing tags like <html>, <body>, etc**, the simplest way to support them is to concatenate all of them into a single HTML file
            val file =
                clientServices.adaptersProvider.httpClient.createFileForUrlsByEagerlyConcatenating(
                    pageUrls,
                )
                    .orReturn { return it }

            return@run contentBundler.coCreateBundleForImportedBinaryContent(
                uri = item.uri,
                libraryItem = item,
                payload = ListenableBinaryContentPayload.createForBinaryContentWithMultiplatformAPI(
                    content = file,
                    mimeType = MimeTypeOfListenableContent.Html(parameters = null),
                    sourceUrl = item.sourceUrl,
                )
                    .orReturn { return it },
                bundleMetadata = bundleMetadata,
            )
        }.orReturn { return it }

        return ContentBundleAndTitle(
            when (
                val statsFromDatabase = item.getContentStats()
                    .nullIfNotNullAnd {
                        /* Behave as if there was no stats if 0 so they get re-computed - for now this is a quick
                           solution to PDFs that need OCRing which have been imported without OCRing,
                           but TODO - [we still need a solution for the fact that database has the wrong value](https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1682080623698869?thread_ts=1680889816.336829&cid=C02LEG7AEGM)
                         */
                        estimatedWordCount.count == 0
                    }
            ) {
                null -> bundleResult
                else -> bundleResult.let { bundle ->
                    bundle.withContentIndex { contentIndex ->
                        contentIndex.withStaticContentStats(statsFromDatabase)
                    }
                }.also {
                    Log.d(
                        "Using static ContentStats from the library item model: $statsFromDatabase",
                        sourceAreaId = "SpeechifyContentBundler.createBundleForContentItem",
                    )
                }
            },
            item.title,
        )
            .successfully()
    }

    private suspend fun createBundleForDeviceLocalContentItem(
        item: LibraryItem.DeviceLocalContent,
        bundleMetadata: BundleMetadata?,
    ): Result<ContentBundleAndTitle> {
        val contentBundle = when (val itemRequiringImport = item.underlyingItemRequiringImport) {
            is ItemRequiringImport.FileImport -> {
                val content = blobStorageAdapter.coGetBlob(itemRequiringImport.primaryFileBlobStorageKey).orThrow()
                    ?: throw NullPointerException("Couldn't get ${itemRequiringImport.primaryFileBlobStorageKey}")
                val payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                    content = content.binaryContent,
                    mimeType = content.mimeType.ensureListenableMimeType(contentTypeForFallback = item.contentType),
                    sourceUrl = item.sourceUrl,
                ).orThrow()
                contentBundler.coCreateBundleForUnimportedBinaryContent(
                    payload = payload,
                    deviceLocalContent = item,
                    importStartChoice = ImportStartChoice.DoNotStart,
                    bundleMetadata = bundleMetadata,
                )
            }
            is ItemRequiringImport.ScannedPagesImport -> {
                val ocrFiles = LazyOCRFilesFromDbOcrFile(
                    initialScannedPages = itemRequiringImport.scannedPages,
                    blobStorageAdapter = blobStorageAdapter,
                    ocrAdapter = ocrAdapter,
                    obtainScannedPageForImportingItem = { index ->
                        clientServices.importService.getScannedPagesFor(item.uri, index)
                    },
                )

                clientServices.importService.saveOcrResultIntoDbWhenAvailable(
                    files = ocrFiles,
                    uri = itemRequiringImport.speechifyUri,
                )

                contentBundler.createBundleForOcrFilesLazily(
                    lazyOcrFiles = ocrFiles,
                    deviceLocalContent = item,
                    importStartChoice = ImportStartChoice.DoNotStart,
                    bundleMetadata = bundleMetadata,
                ).successfully()
            }
            is ItemRequiringImport.UrlImport -> {
                val data = if (itemRequiringImport.primaryFileBlobStorageKey != null) {
                    blobStorageAdapter.coGetBlob(itemRequiringImport.primaryFileBlobStorageKey).orThrow()
                        ?: throw NullPointerException("Couldn't get ${itemRequiringImport.primaryFileBlobStorageKey}")
                } else {
                    throw UnsupportedOperationException("Cannot bundle URL import where file was not downloaded yet.")
                }
                val payload = ListenableBinaryContentPayload.createForBinaryContentWithNativeApi(
                    content = data.binaryContent,
                    mimeType = data.mimeType.ensureListenableMimeType(contentTypeForFallback = item.contentType),
                    sourceUrl = item.sourceUrl,
                ).orThrow()
                contentBundler.coCreateBundleForUnimportedBinaryContent(
                    payload = payload,
                    deviceLocalContent = item,
                    importStartChoice = ImportStartChoice.DoNotStart,
                    bundleMetadata = bundleMetadata,
                )
            }
        }.orThrow()

        return ContentBundleAndTitle(
            contentBundle,
            item.title,
        )
            .successfully()
    }

    private suspend fun createBundleForAudioBookChapter(uri: SpeechifyURI): Result<ContentBundleAndTitle> {
        val id = uri.id
        val chapter = clientServices.audiobookLibraryService.coGetAudiobookChapter(id).orReturn { return it }
        check(chapter is AudiobookChapter.Aligned) { "Bundling only supported for aligned chapters." }
        val chapterHtml = clientServices.audiobookLibraryService
            .getChapterFile(
                chapter = chapter,
                fileId = AudiobookChapterFileId(chapter.contentUri),
            )

        return (
            ContentBundleAndTitle(
                contentBundler.coCreateBundleForBinaryContent(
                    ListenableBinaryContentPayload.createForBinaryContentWithMultiplatformAPI(
                        content = chapterHtml,
                        mimeType = MimeTypeOfListenableContent.Text(parameters = null),
                        sourceUrl = null,
                    ).orThrow(),
                    deviceLocalContent = null,
                    importerFactory =
                    object : BinaryContentImporterFactory {
                        override fun createContentImporter(
                            payload: ImportableContentPayload.ImportableContentPayloadOfSingleBlob,
                            deviceLocalContent: LibraryItem.DeviceLocalContent?,
                            customProperties: Sequence<Pair<String, Any>>,
                            importStartChoice: ImportStartChoice,
                            bundleMetadata: BundleMetadata?,
                        ): ContentImporter = SpeechifyGlobalResourceImporter(uri)
                    },
                    importStartChoice = ImportStartChoice.DoNotStart,
                    bundleMetadata = null,
                )
                    .orReturn { return it }
                    .let { contentBundle ->
                        ContentBundle.AudioBookChapterBundle(
                            contentBundlerOptions = contentBundle.contentBundlerOptions,
                            standardView = contentBundle.standardView,
                            speechView = contentBundle.speechView,
                            contentIndex = when (val wordCount = chapter.wordCount) {
                                null -> contentBundle.contentIndex
                                else -> contentBundle.contentIndex.withStaticContentStats(
                                    ContentStats(
                                        estimatedWordCount = EstimatedCount(
                                            count = wordCount,
                                            confidence = 1.0,
                                        ),
                                    ),
                                )
                            },
                            searcher = null,
                            audioBookChapter = chapter,
                            contentImporter = contentBundle.importer,
                        )
                    },
                chapter.title,
            )
            ).successfully()
    }

    private suspend fun createBundleForScannedBook(libraryItem: LibraryItem.Content): Result<ContentBundleAndTitle> {
        val scannedBook = currentTelemetryEvent().addMeasurement("fetchScannedBook") {
            clientServices
                .scannedBookService
                .coGetScannedBookFromBookId(libraryItem.uri.id)
                .orReturn { return it }
        }
        return ContentBundleAndTitle(
            bundle = contentBundler.createBundleForScannedBookAlreadyImported(scannedBook, libraryItem),
            title = null,
        )
            .successfully()
    }
}

/**
 * Returns [ContentStats] that are precomputed on the backend and saved on the library item, if available
 */
private fun LibraryItem.Content.getContentStats(): ContentStats? {
    return if (totalWords != null) {
        ContentStats(
            estimatedWordCount = EstimatedCount(totalWords, 1.0),
        )
    } else {
        null
    }
}

private data class ContentBundleAndTitle(
    val bundle: ContentBundle,
    val title: String?,
)

/**
 * Creates a file by eagerly loading and concatenating the content of each URL, in the order specified
 * Fails if files do not all have same content type
 */
internal suspend fun HttpClient.createFileForUrlsByEagerlyConcatenating(
    urls: List<String>,
): Result<InMemoryFile> = coroutineScope {
    val downloads = urls
        // Download the files in parallel
        .map { url ->
            async {
                this@createFileForUrlsByEagerlyConcatenating
                    .getBinaryContentReadableRandomly(url)
                    .orReturn { return@async it }
                    .readAsByteArrayFile()
            }
        }
        .awaitAll()
        // Fail and return early if any of the pages fail to download
        .map { result -> result.orReturn { return@coroutineScope it } }

    if (downloads.isEmpty()) {
        throw IllegalArgumentException("createFileForUrlsByEagerlyConcatenating: The `urls` were empty")
    }

    val mimeTypes = downloads
        .map { it.mimeType }

    run {
        val mimeTypesSubtypes = mimeTypes
            .map { it.typeSubtype }

        // Fail if mixed content type
        if (mimeTypesSubtypes.distinct().size > 1) {
            return@coroutineScope Result.Failure(
                SDKError.OtherMessage(
                    "Cannot create concatenated file from mixed content types: $mimeTypesSubtypes",
                ),
            )
        }
    }

    val content = downloads
        .map { it.bytes }
        .reduce { acc: ByteArray, bytes -> acc + bytes }

    val mimeType = mimeTypes.first()

    InMemoryFile(
        /** Strip the [com.speechify.client.api.util.MimeType.parameter] for [InMemoryFile.contentType] to maintain historic behavior like in #StrippingParameterFromContentTypeToMaintainHistoricalBehavior */
        contentTypeOrNull = mimeType.typeSubtype,
        mimeTypeOrNull = mimeType,
        bytes = content,
    ).successfully()
}
