package com.speechify.client.bundlers.reading

import com.speechify.client.api.services.importing.models.ImportFlow
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.services.importing.models.addImportableContentMetadataProperties
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.telemetry.CustomContentSubTypeTelemetryProp
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import kotlin.js.JsExport

/**
 * Represents the metadata provided by SDK consumers while bundle creation.
 * Don't put things that SDK have to manage in [telemetryProperties] or [customContentProperties]
 * Use explicit contracted properties instead, e.g: [disableInitializationTelemetry]`.
 */
@JsExport
open class BundleMetadata(
    override val contentSubType: String,
    open val contentType: ContentType,
    override val importFlow: ImportFlow = ImportFlow.Unknown,
    override val contentHostname: String? = null,
) : ImportableContentMetadata(
    contentSubType,
    importFlow,
    contentHostname,
) {

    /**
     * A boolean indicating whether report UI ready to Telemetry or not.
     */
    var disableTelemetryOfCreateToUIReady: Boolean = false

    /**
     * A boolean indicating whether report Playing to Telemetry or not.
     */
    var disableTelemetryOfCreateToPlaying: Boolean = false

    /**
     * Shorthand that disables at once [disableTelemetryOfCreateToUIReady] and [disableTelemetryOfCreateToPlaying]
     */
    var disableTelemetryOfCreate: Boolean
        get() = disableTelemetryOfCreateToUIReady && disableTelemetryOfCreateToPlaying
        set(value) {
            disableTelemetryOfCreateToUIReady = value
            disableTelemetryOfCreateToPlaying = value
        }

    /**
     * Use this for allowing to distinguish between use cases of the same bundling API, by specifying here a unique
     * string for each use case. Especially useful for general purpose bundle APIs that can be used for reading from
     * various sources. For example SDK consumers can use a single bundle type of [com.speechify.client.bundlers.reading.embedded.EmbeddedReadingBundler.createBundleForDynamicStandardView]
     * to implement Speechify experience on Google Docs or reading from customers' content on third party web apps like
     * their Gmail or Kindle accounts. By providing a value identifying which of these data sources this is, it's going
     * to be possible to filter diagnostic information or persisted data based on that value.
     */
    var customContentSubType: String?
        get() = telemetryPropertiesInternalMap[CustomContentSubTypeTelemetryProp.keyId]?.toString()
        set(value) {
            if (value == null) {
                telemetryPropertiesInternalMap.remove(CustomContentSubTypeTelemetryProp.keyId)
            } else {
                telemetryPropertiesInternalMap[CustomContentSubTypeTelemetryProp.keyId] = value
            }
        }
}

internal fun TelemetryEventBuilder.addBundleMetadataProperties(bundleMetadata: BundleMetadata?) {
    if (bundleMetadata == null) return
    this.addImportableContentMetadataProperties(bundleMetadata)
}
