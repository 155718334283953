package com.speechify.client.internal.util.extensions.collections

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.collections.maps.MutableMapInsertion

/**
 * Adds a key-value pair to the map if the key is not already present in the map.
 * Returns [false] if the key was nod added because it was already used and has a different value.
 * Returns [true] if the key was added, or already exists with the same value.
 */
internal fun <K, V> MutableMap<K, V>.tryAdd(key: K, value: V): Boolean {
    val existingValue = this[key] ?: run {
        this[key] = value
        return true
    }

    return existingValue == value
}

/**
 * Puts the given key-value pair into the map.
 * Returns [true] if the key was already present but of a different value.
 * Returns [false] if the key was not present or had the same value.
 */
internal fun <K, V> MutableMap<K, V>.putAndGetWasDifferent(key: K, value: V): Boolean {
    val existingValue = this.put(key, value)
    return existingValue != null && existingValue != value
}

/**
 * [putAndGetWasDifferent] for the SDK-specific narrow map interface of [MutableMapInsertion].
 */
internal fun <K, V> MutableMapInsertion<K, V>.putAndGetWasDifferent(key: K, value: V): Boolean {
    val existingValue = this.put(key, value)
    return existingValue != null && existingValue != value
}
internal fun <K : Any, V : Any> MutableMap<K, V>.putAllLogErrorIfDifferentExisted(
    entries: Iterable<Pair<K, V>>,
    sourceAreaId: String,
) {
    for ((key, value) in entries) {
        putLogErrorIfDifferentExisted(
            key = key,
            value = value,
            sourceAreaId = sourceAreaId,
        )
    }
}

internal fun <K : Any, V : Any> MutableMap<K, V>.putLogErrorIfDifferentExisted(key: K, value: V, sourceAreaId: String) {
    val previousValue = this.put(key, value)
    if (previousValue != null && previousValue != value) {
        Log.e(
            DiagnosticEvent(
                message = "Tried to add property with same key but different value to map.",
                sourceAreaId = sourceAreaId,
                properties = mapOf(
                    "key" to key,
                    "previousValue" to previousValue,
                    "newValue" to value,
                ),
            ),
        )
    }
}
