package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksAroundCursor
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import kotlin.js.JsExport

/**
 * Attempts to fetch StandardBlocks for [suggestedBlocksSize], from the [standardView], while skipping
 * empty blocks if encountered.
 *
 * **Example usage to get the next set of blocks after the current**:
 * ```
 * val blocksFetcher = StandardBlocksFetcher(standardView, 5)
 * var previousEndCursor: ContentCursor? = null
 * previousEndCursor = previousEndCursor ?: standardView.start
 * val standardBlocks = blocksFetcher.getBlocksForSuggestedSizeAroundCursor(endCursor)
 * /* filter standardBlocks after `endCursor` and render */
 * previousEndCursor = standardBlocks.end
 * ```
 * @param standardView the standard view from which you require [StandardBlocks].
 * @param suggestedBlocksSize suggested size of the blocks, however, it **may not be reached**
 * if the cursor is **close to the start or end** of the [standardView].
 * */
@JsExport
class StandardBlocksFetcher(
    private val standardView: StandardView,
    private val suggestedBlocksSize: Int,
) {

    fun getBlocksForSuggestedSizeAroundCursor(cursor: ContentCursor, callback: Callback<StandardBlocks>) =
        callback.fromCo { coGetBlocksForSuggestedBlockSizeAroundCursor(cursor) }

    internal suspend fun coGetBlocksForSuggestedBlockSizeAroundCursor(cursor: ContentCursor): Result<StandardBlocks> {
        var standardBlocks = standardView.coGetBlocksAroundCursor(cursor).orReturn {
            return it
        }
        if (standardBlocks.blocks.size >= suggestedBlocksSize) {
            return standardBlocks.successfully()
        }

        for (i in 0 until suggestedBlocksSize) {
            val before = getBefore(standardBlocks.start).orReturn {
                return it
            }

            val afterMore = getAfter(standardBlocks.end).orReturn {
                return it
            }

            val blocks = before.blocks + standardBlocks.blocks + afterMore.blocks
            standardBlocks = StandardBlocks(blocks, before.start, afterMore.end)
            if (standardBlocks.blocks.size >= suggestedBlocksSize) {
                break
            }
        }
        return standardBlocks.successfully()
    }

    internal suspend fun getAfter(
        cursor: ContentCursor,
    ): Result<StandardBlocks> {
        val moreStandardBlocks = standardView.coGetBlocksAroundCursor(cursor).orReturn {
            return it
        }

        val blocksToAdd = moreStandardBlocks.blocks.filter { it.start.isAfter(cursor) }

        return StandardBlocks(
            blocksToAdd.toTypedArray(),
            cursor,
            moreStandardBlocks.end,
        ).successfully()
    }

    internal suspend fun getBefore(
        cursor: ContentCursor,
    ): Result<StandardBlocks> {
        val moreStandardBlocks = standardView.coGetBlocksAroundCursor(cursor).orReturn {
            return it
        }

        val blocksToAdd = moreStandardBlocks.blocks.filter { it.end.isBefore(cursor) }

        return StandardBlocks(
            blocksToAdd.toTypedArray(),
            moreStandardBlocks.start,
            cursor,
        ).successfully()
    }
}
