package com.speechify.client.reader.core

import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class SkipSettingsHelper internal constructor(
    scope: CoroutineScope,
    private val readingBundle: ReadingBundle,
) : Helper<SkipSettingsState>(scope) {

    private val contentBundlerOptions = readingBundle.content.contentBundlerOptions

    override val stateFlow: MutableStateFlow<SkipSettingsState> = MutableStateFlow(
        SkipSettingsState(
            shouldSkipHeaders = contentBundlerOptions.shouldSkipHeaders,
            shouldSkipFooters = contentBundlerOptions.shouldSkipFooters,
            shouldSkipFootnotes = contentBundlerOptions.shouldSkipFootnotes,
            shouldSkipBraces = contentBundlerOptions.preSpeechTransformOptions.shouldSkipBraces,
            shouldSkipCitations = contentBundlerOptions.preSpeechTransformOptions.shouldSkipCitations,
            shouldSkipParentheses = contentBundlerOptions.preSpeechTransformOptions.shouldSkipParentheses,
            shouldSkipBrackets = contentBundlerOptions.preSpeechTransformOptions.shouldSkipBrackets,
            shouldSkipUrls = contentBundlerOptions.preSpeechTransformOptions.shouldSkipUrls,
            mlParsingMode = contentBundlerOptions.mlParsingMode,
            ocrFallbackStrategy = contentBundlerOptions.ocrFallbackStrategy,
            shouldSkipCaptions = contentBundlerOptions.shouldSkipCaptions,
        ),
    )

    override val initialState = stateFlow.value

    init {
        commands
            .onEachInstance<SkipSettingsCommand.SetShouldSkipHeaders> {
                contentBundlerOptions.shouldSkipHeaders = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipHeaders = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipFooters> {
                contentBundlerOptions.shouldSkipFooters = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipFooters = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipFootnotes> {
                contentBundlerOptions.shouldSkipFootnotes = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipFootnotes = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipBraces> {
                contentBundlerOptions.preSpeechTransformOptions.shouldSkipBraces = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipBraces = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipCitations> {
                contentBundlerOptions.preSpeechTransformOptions.shouldSkipCitations = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipCitations = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipParentheses> {
                contentBundlerOptions.preSpeechTransformOptions.shouldSkipParentheses = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipParentheses = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipBrackets> {
                contentBundlerOptions.preSpeechTransformOptions.shouldSkipBrackets = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipBrackets = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetShouldSkipUrls> {
                contentBundlerOptions.preSpeechTransformOptions.shouldSkipUrls = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipUrls = it.value)
                }
            }.onEachInstance<SkipSettingsCommand.SetShouldSkipCaptions> {
                contentBundlerOptions.shouldSkipCaptions = it.value
                stateFlow.update { oldState ->
                    oldState.copy(shouldSkipCaptions = it.value)
                }
            }
            .onEachInstance<SkipSettingsCommand.SetMLParsingMode> {
                contentBundlerOptions.mlParsingMode = it.value
                stateFlow.update { oldState ->
                    oldState.copy(mlParsingMode = it.value)
                }
                // Ask to reload content.
                dispatch(
                    SkipSettingsCommand.SkipSettingChanged(
                        location =
                        RobustLocation(
                            SerialLocation(
                                readingBundle.playbackControls.state.latestPlaybackCursor,
                            ),
                        ),
                    ),
                )
            }.onEachInstance<SkipSettingsCommand.SetOcrFallbackStrategy> {
                contentBundlerOptions.ocrFallbackStrategy = it.value
                stateFlow.update { oldState ->
                    oldState.copy(ocrFallbackStrategy = it.value)
                }
                // Ask to reload content.
                dispatch(
                    SkipSettingsCommand.SkipSettingChanged(
                        location =
                        RobustLocation(
                            SerialLocation(
                                readingBundle.playbackControls.state.latestPlaybackCursor,
                            ),
                        ),
                    ),
                )
            }.launchInHelper()
    }

    fun setShouldSkipHeaders(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipHeaders(value = value))
    }

    fun setShouldSkipFooters(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipFooters(value = value))
    }

    fun setShouldSkipFootnotes(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipFootnotes(value = value))
    }

    fun setShouldSkipBraces(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipBraces(value = value))
    }

    fun setShouldSkipCitations(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipCitations(value = value))
    }

    fun setShouldSkipParentheses(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipParentheses(value = value))
    }

    fun setShouldSkipBrackets(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipBrackets(value = value))
    }

    fun setShouldSkipUrls(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipUrls(value = value))
    }

    fun setShouldSkipCaptions(value: Boolean) {
        dispatch(SkipSettingsCommand.SetShouldSkipCaptions(value = value))
    }

    fun setMLParsingMode(value: MLParsingMode) {
        dispatch(SkipSettingsCommand.SetMLParsingMode(value = value))
    }

    fun setOcrFallbackStrategy(value: OcrFallbackStrategy) {
        dispatch(SkipSettingsCommand.SetOcrFallbackStrategy(value = value))
    }
}

internal sealed class SkipSettingsCommand {
    data class SetShouldSkipHeaders(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipFooters(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipFootnotes(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipBraces(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipCitations(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipParentheses(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipBrackets(val value: Boolean) : SkipSettingsCommand()
    data class SetShouldSkipUrls(val value: Boolean) : SkipSettingsCommand()
    data class SetMLParsingMode(val value: MLParsingMode) : SkipSettingsCommand()
    data class SetOcrFallbackStrategy(val value: OcrFallbackStrategy) : SkipSettingsCommand()
    data class SkipSettingChanged(val location: RobustLocation) : SkipSettingsCommand()
    data class SetShouldSkipCaptions(val value: Boolean) : SkipSettingsCommand()
}

@JsExport
data class SkipSettingsState(
    val shouldSkipHeaders: Boolean,
    val shouldSkipFooters: Boolean,
    val shouldSkipFootnotes: Boolean,
    val shouldSkipBraces: Boolean,
    val shouldSkipCitations: Boolean,
    val shouldSkipParentheses: Boolean,
    val shouldSkipBrackets: Boolean,
    val shouldSkipUrls: Boolean,
    val shouldSkipCaptions: Boolean,
    val mlParsingMode: MLParsingMode,
    val ocrFallbackStrategy: OcrFallbackStrategy,
)
