package com.speechify.client.helpers.content.standard.epub

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.helpers.content.standard.StandardBlockChunking
import kotlin.math.max
import kotlin.math.min

internal class EpubStandardBlockChunking(
    allBlocks: StandardBlocks,
    chunkSize: Int,
) : StandardBlockChunking(
    allBlocks = allBlocks,
    chunkSize = chunkSize,
) {
    override fun getBlocksAroundCursor(
        cursor: ContentCursor,
    ): StandardBlocks {
        return if (allBlocks.blocks.size > chunkSize * 4) {
            // Try to reduce the amount of content callers have to work with by filtering
            // only the blocks around the cursor.
            if (cursor is ContentElementBoundary && cursor.element.path.isEmpty()) {
                val newBlocks = when (cursor.boundary) {
                    is ContentBoundary.START -> {
                        allBlocks.blocks.take(chunkSize)
                    }

                    is ContentBoundary.END -> {
                        allBlocks.blocks.takeLast(chunkSize)
                    }
                }
                return StandardBlocks(
                    blocks = newBlocks.toTypedArray(),
                    start = newBlocks.first().start,
                    end = newBlocks.last().end,
                )
            }

            val containingBlockIndex = allBlocks.blocks.indexOfFirstOrNull {
                // This block search follows the existing logic from `StandardBlockChunking`,
                // which is designed for cases where the cursor's path is clearly defined.
                // The block is identified if the cursor lies within the block's start and end boundaries.
                it.start.isBeforeOrAt(cursor) && it.end.isAfterOrAt(cursor)
            } ?: allBlocks.blocks.indexOfFirstOrNull {
                // In some cases, such as with a Table of Contents (ToC) entry, the cursor provides a less precise
                // reference, pointing to a broader section of the document rather than a specific heading or paragraph
                // (e.g., the start of the file). In these instances, we need to locate the first block in the
                // tree that aligns with the cursor's position.
                //
                // cursor.path = [0, 1, 2]
                // block.start.path = [0, 1, 2, 5, 43]
                it.start.isAfterOrAt(cursor)
            }

            if (containingBlockIndex == null) {
                // The cursor is not in any of the blocks, so just return all the blocks.
                return allBlocks
            }

            // We take the current chunk and the two neighboring chunks.
            val currentChunkIndex = containingBlockIndex / chunkSize
            val chunksToTake =
                ((max(0, currentChunkIndex - 1))..(min(chunkedBlocks.size - 1, currentChunkIndex + 1)))
            val chunks = chunkedBlocks.slice(chunksToTake)
            val newBlocks = chunks.flatten()

            StandardBlocks(
                blocks = newBlocks.toTypedArray(),
                start = newBlocks.first().start,
                end = newBlocks.last().end,
            )
        } else {
            allBlocks
        }
    }
}

internal fun <T> Array<T>.indexOfFirstOrNull(predicate: (T) -> Boolean): Int? {
    val index = this.indexOfFirst(predicate)
    return if (index == -1) null else index
}

internal fun <T> Array<T>.indexOfLastOrNull(predicate: (T) -> Boolean): Int? {
    val index = this.indexOfLast(predicate)
    return if (index == -1) null else index
}
