package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.audio.caching.VoiceIdForDb
import kotlin.Long
import kotlin.String

public data class SentenceIndex(
  public val documentUri: SpeechifyURI,
  public val voiceId: VoiceIdForDb,
  public val sentenceText: String,
  public val synthesisResultId: Long,
  public val utteranceSentencesIndexOfThisSentence: Long,
  public val utteranceSentencesTotalCount: Long,
) {
  public class Adapter(
    public val documentUriAdapter: ColumnAdapter<SpeechifyURI, String>,
    public val voiceIdAdapter: ColumnAdapter<VoiceIdForDb, String>,
  )
}
