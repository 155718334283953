package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformUpdateBillingDataErrorResponse(
    val message: String? = null,
    val detail: PlatformUpdateBillingDataErrorDetailResponse? = null,
)

@Serializable
internal data class PlatformUpdateBillingDataErrorDetailResponse(
    val error: String? = null,
    val code: String? = null,
)
