package com.speechify.client.internal.services.library.models

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

@Serializable
internal data class LibrarySearchPayload(
    val title: String,
    val page: Int,
    val pageSize: Int? = null,
    val filters: List<JsonElement> = emptyList(),
)

@Serializable
internal data class PlatformSearchResult(
    val results: List<FirebaseLibraryItem>,
    val hasMore: Boolean,
    val page: Int,
)
