package com.speechify.client.api.services.account.models

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class BillingData(
    val countryCode: String,
    val stateCode: String,
    val postalCode: String,
    val city: String,
)

fun BillingData.toBoundaryMap(): BoundaryMap<Any?> = SdkBoundaryMap.of(
    "countryCode" to countryCode,
    "stateCode" to stateCode,
    "postalCode" to postalCode,
    "city" to city,
)
