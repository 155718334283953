package com.speechify.client.helpers.content.speech

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentDirection
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.contains
import com.speechify.client.api.content.view.speech.CursorQuery
import com.speechify.client.api.content.view.speech.CursorQueryStartingPoint
import com.speechify.client.api.content.view.speech.Speech
import com.speechify.client.api.content.view.speech.SpeechUtils
import com.speechify.client.api.content.view.speech.SpeechView
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksAroundCursor
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.ContentSequenceCharacteristics
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.sync.AtomicCircularFixedList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import kotlin.js.JsExport

/**
 * An adapter that accepts a [StandardView] and behaves like a [SpeechView]
 */
@JsExport
class StandardSpeechView internal constructor(
    val view: StandardView,
    private val contentTransformOptions: ContentTransformOptions,
    private val scope: CoroutineScope = createTopLevelCoroutineScope(),
) : SpeechView(),
    Destructible,
    ContentSequenceCharacteristics by view {
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    private val speechByBlockCache = AtomicCircularFixedList<Pair<StandardBlocks, Speech>>(20)

    init {
        scope.launch {
            contentTransformOptions.contentTransformOptionsChanged
                .collect {
                    // Invalidate our Speech Cache if anything affecting the output changes.
                    speechByBlockCache.clear()
                }
        }
    }

    override fun getStartingPoint(
        query: CursorQuery,
    ): Result<ContentCursor> =
        when (query.startingPoint) {
            is CursorQueryStartingPoint.Start -> view.start
            is CursorQueryStartingPoint.End -> view.end
            is CursorQueryStartingPoint.Cursor ->
                query.startingPoint.cursor
        }
            .successfully()

    override suspend fun getSpeechContainingCursor(cursor: ContentCursor): Result<Speech> {
        val blocks = view.coGetBlocksAroundCursor(cursor).orReturn { return it }
        val cachedSpeech = speechByBlockCache.find { it.first == blocks }
        if (cachedSpeech != null) {
            return cachedSpeech.second.successfully()
        }
        val speech = getSpeechFromStandardBlocks(
            blocks,
            contentTransformOptions,
        )

        // Caching the Speech for the blocks is valuable since computing the Speech is quite expensive
        // due to running a regex for sentence splitting on the entire text of the blocks.
        speechByBlockCache.add(blocks to speech)

        return speech.successfully()
    }

    override suspend fun getNextSpeech(
        cursor: ContentCursor,
        direction: ContentDirection,
    ): Result<Speech?> {
        val speech = getSpeechContainingCursor(cursor)
            .orReturn { return it }
        return when (direction) {
            ContentDirection.FORWARD -> {
                if (speech.end.isAfter(cursor)) {
                    val sentences = speech
                        .sentences
                        .dropWhile { it.text.contains(cursor) || it.text.end.isBeforeOrAt(cursor) }
                    Speech(
                        start = sentences.firstOrNull()?.start ?: speech.start,
                        end = sentences.lastOrNull()?.end ?: speech.end,
                        sentences.toTypedArray(),
                    )
                } else {
                    null
                }
            }

            ContentDirection.BACKWARD -> {
                if (speech.start.isBefore(cursor)) {
                    val sentences = speech.sentences
                        .takeWhile { cursor !in it.text && it.text.end.isBefore(cursor) }
                    Speech(
                        start = sentences.firstOrNull()?.start ?: speech.start,
                        end = sentences.lastOrNull()?.end ?: speech.end,
                        sentences.toTypedArray(),
                    )
                } else {
                    null
                }
            }
        }.successfully()
    }

    override fun destroy() {
        scope.cancel()
        speechByBlockCache.clear()
    }
}

// TODO: ensure we put sentence break after StandardElement.Paragraph and StandardElement.Heading
// see https://www.loom.com/share/3d7cf78311d843b793b5ad59501903c7?sid=a0fc410d-2b38-42e1-acca-6398a9aeaa9c
internal fun getSpeechFromStandardBlocks(
    standardBlocks: StandardBlocks,
    contentTransformOptions: ContentTransformOptions,
): Speech {
    val sentences = standardBlocks.blocks.flatMap {
        when (it) {
            is StandardBlock.Caption -> if (contentTransformOptions.shouldSkipCaptionsFlow.value) {
                listOf()
            } else {
                SpeechUtils.textToSentences(it.text)
            }
            is StandardBlock.Paragraph -> SpeechUtils.textToSentences(it.text)
            is StandardBlock.Heading -> SpeechUtils.textToSentences(it.text)
            is StandardBlock.List -> it.items.flatMap { item ->
                SpeechUtils.textToSentences(item.text)
            }

            is StandardBlock.Footer ->
                if (contentTransformOptions.shouldSkipFootersFlow.value) {
                    listOf()
                } else {
                    SpeechUtils.textToSentences(it.text)
                }

            is StandardBlock.Header ->
                if (contentTransformOptions.shouldSkipHeadersFlow.value) {
                    listOf()
                } else {
                    SpeechUtils.textToSentences(it.text)
                }

            is StandardBlock.Footnote ->
                if (contentTransformOptions.shouldSkipFootnotesFlow.value) {
                    listOf()
                } else {
                    SpeechUtils.textToSentences(it.text)
                }
        }
    }
    return if (sentences.isNotEmpty()) {
        Speech(standardBlocks.start, standardBlocks.end, sentences.toTypedArray())
    } else {
        Speech.empty(standardBlocks.start, standardBlocks.end)
    }
}
