package com.speechify.client.api.services.library.models

import com.speechify.client.api.services.library.models.UserHighlight.Style
import com.speechify.client.internal.services.library.CursorSurgeon
import com.speechify.client.internal.services.library.DestructuredCursor
import com.speechify.client.internal.time.DateTime
import com.speechify.client.reader.core.RobustLocation
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.core.SerialSpan
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Representing the domain object used in SDK to represent a highlight, same is exposed
 * to clients of SDK
 *
 * @param id unique identifier of the highlight
 * @param text highlighted text
 * @param robustStart [RobustLocation] for the start of highlight
 * @param robustEnd [RobustLocation] for the end of highlight
 * @param style [Style] applied to this highlight
 * @param note  text attached as note with the highlight
 * @param pageIndex index of page number where the highlight starts in original book view if present else null
 * @param isPageHidden true if [pageIndex] is available and it is hidden, otherwise false
 *
 * Note: for [pageIndex] and [isPageHidden] we are considering the page where the highlighting starts
 */
@JsExport
data class UserHighlight internal constructor(
    val id: String,
    val text: String,
    internal val robustStart: RobustLocation,
    internal val robustEnd: RobustLocation,
    val style: Style,
    val note: String?,
    val pageIndex: Int? = null,
    val isPageHidden: Boolean = false,
) {

    internal val span: SerialSpan = SerialSpan(robustStart.hack, robustEnd.hack)

    /**
     * @param [colorToken] a predefined token for the highlight colors
     * Note: Allowing [colorToken] to be null for backward/forward compatibility,
     * For example: if a highlight saved in server(firestore) does not have a matching enum value from [ColorToken],
     * in that case we will map [colorToken] to null
     *
     * @see [ColorToken]
     */
    data class Style(
        val colorToken: ColorToken?,
    ) {
        enum class ColorToken {
            YELLOW,
            GREEN,
            PINK,
            PURPLE,
            BLUE,
        }
    }
}

internal fun Style.toPersistedStyle(): PersistedUserHighlight.PersistedStyle {
    return colorToken?.let {
        PersistedUserHighlight.PersistedStyle(colorToken.name)
    } ?: throw IllegalArgumentException("Color can not be null to persist a highlight")
}

/**
 * Representing the highlight saved in Firestore
 */
@Serializable
internal data class PersistedUserHighlight(
    val id: String,
    val robustStart: DestructuredCursor,
    val robustEnd: DestructuredCursor,
    val text: String,
    val style: PersistedStyle,
    val note: String?,
    val pageIndex: Int? = null,
    val sdkVersionAtCreation: String,
    val sdkVersionAtUpdate: String,
    val createdAt: DateTime,
    val updatedAt: DateTime,
) {
    @Serializable
    data class PersistedStyle(
        @SerialName("colorToken")
        val colorTokenValue: String,
    )
}

internal fun PersistedUserHighlight.PersistedStyle.toUserHighlightStyle(): Style {
    return Style(Style.ColorToken.values().find { it.name == colorTokenValue })
}

internal fun PersistedUserHighlight.toUserHighlight(): UserHighlight {
    return UserHighlight(
        id = id,
        text = text,
        robustStart = RobustLocation(SerialLocation(CursorSurgeon.assemble(robustStart))),
        robustEnd = RobustLocation(SerialLocation(CursorSurgeon.assemble(robustEnd))),
        style = style.toUserHighlightStyle(),
        note = note,
        pageIndex = pageIndex,
    )
}
