package com.speechify.client.internal.services.subscription.models

import com.speechify.client.api.services.subscription.models.PlatformRenewalFrequency
import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformCreateStripeSubscriptionBody(
    val userId: String,
    val paymentMethodId: String,
    val renewalFrequency: PlatformRenewalFrequency,
    val email: String?,
    val hasTrial: Boolean?,
    val coupon: String?,
    val trialLength: Int? = 3,
    val subscriptionCurrency: String? = null,
    val planId: String? = null,
    val confirmPaymentOnClient: Boolean? = false,
)
