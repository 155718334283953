package com.speechify.client.internal.sync

/**
 * Interface that allows for common code regardless of implementation.
 */
internal interface AtomicVal<T> {
    val value: T
    fun swap(with: T): T

    fun compareAndSet(expected: T, with: T): Boolean
}

/**
 * Updates the value with the [getNewValue] and returns the previous value, but only if the [isMatch] returns false.
 * Can be used to implement a simple one-element cache.
 */
internal inline fun <T> AtomicVal<T>.getOrUpdateIfNoMatch(
    isMatch: (existingValue: T) -> Boolean,
    getNewValue: (existingValue: T) -> T,
): T {
    while (true) {
        val prevValue = value
        if (isMatch(prevValue)) {
            return prevValue
        }
        val nextValue = getNewValue(prevValue)
        if (compareAndSet(prevValue, nextValue)) {
            return nextValue
        }
        /** The value was changed by another thread - we can't return [nextValue]. We need to go back to the beginning
         * of the loop to test again. */
    }
}

/**
 * A version of [getOrUpdateIfNoMatch] that never returns a null.
 * Can be used to implement a simple one-element cache.
 */
internal inline fun <T : Any> AtomicVal<T?>.getOrUpdateIfNoMatchOrNull(
    isMatch: (existingValue: T) -> Boolean,
    getNewValue: (existingValue: T?) -> T,
): T =
    getOrUpdateIfNoMatch(
        isMatch = { existingValue -> existingValue != null && isMatch(existingValue) },
        getNewValue = { existingValue -> getNewValue(existingValue) },
    )!!

/**
 * Equivalent of [kotlinx.coroutines.flow.getAndUpdate] for [AtomicRef].
 */
internal inline fun <T> AtomicVal<T>.getAndUpdate(
    function: (T) -> T,
): T {
    while (true) {
        val prevValue = value
        val nextValue = function(prevValue)
        if (compareAndSet(prevValue, nextValue)) {
            return prevValue
        }
    }
}

/**
 * NOTE: If [T] is a nullable primitive, use [AtomicRefOfNullablePrimitive].
 * If it is a non-nullable primitive, use the respective `*Atomic` type, e.g. [AtomicInt], [AtomicLong], [AtomicBool].
 */
internal expect class AtomicRef<T> constructor(
    initialValue: T,
) : AtomicVal<T> {
    override val value: T
    override fun swap(with: T): T

    /**
     * Compare current reference with `expected` and if they are equal, then the current reference is set to `with` and
     * returns true, otherwise, it will _not_ update and return false
     */
    override fun compareAndSet(expected: T, with: T): Boolean
}

/**
 * Used to signify that ignoring the previous value is intended.
 */
internal fun <T> AtomicRef<T>.set(newValue: T) {
    swap(with = newValue)
}

/**
 * Used to signify that ignoring not setting of the value is intended.
 */
internal fun <T> AtomicRef<T>.compareAndSetIgnoringNoMatch(
    expected: T,
    newValue: T,
) {
    compareAndSet(
        expected = expected,
        with = newValue,
    )
}

internal fun <T> AtomicVal<T>.compareAndSetOrThrow(
    expected: T,
    with: T,
    errorMessage: () -> Any,
) =
    compareAndSet(expected = expected, with = with)
        .also { wasSet ->
            check(wasSet, errorMessage)
        }

/** Use this class instead of [AtomicRef] when the reference [T] is intended to be a nullable primitive,
 *  like a [Int], [Long], [Double], etc to prevent a foot-gun of JVM, in that it will work for small integers of
 *  -128 to 127, but will fail on those beyond that, because boxing happens underneath [AtomicRef], and the
 *  references to the same numbers will not be considered equal (see [_"This method will always cache values in the
  * range -128 to 127, inclusive"_](https://docs.oracle.com/javase/7/docs/api/java/lang/Integer.html#valueOf(int))).
 *
 *  (see tests of this class in [AtomicRefOfNullablePrimitiveTest] for demonstration of the problem)
 *
 * NOTE: If it is a non-nullable primitive, use the respective `*Atomic` type, e.g.
 * [AtomicInt], [AtomicLong], [AtomicBool].
 */
internal class AtomicRefOfNullablePrimitive<T>(initialValue: T?) : AtomicVal<T?> {
    private val innerRef = AtomicRef(initialValue)

    override fun compareAndSet(expected: T?, with: T?): Boolean {
        val firstFoundValue = innerRef.value
        if (firstFoundValue
            != /* the `==` is crucial - we want a comparison by value */
            expected
        ) {
            return false /* we can return because `innerRef.compareAndSet` would have a chance to return `false` too
            (this also covers CPU code reordering, because note that reading the `innerRef.value` has
            'volatile' property in JVM), so we can . */
        }

        // So we now have the same value 'by reference', and can use it with the [AtomicRef] safely
        return innerRef.compareAndSet(
            expected = firstFoundValue, /* `firstFoundValue` is the same as `expected` */
            with = with,
        )
    }

    override val value: T?
        get() = innerRef.value

    override fun swap(with: T?): T =
        TODO(
            "Implement swap",
            /* NOTE: Cannot use `innerRef.swap` because this would be an entry point through which different references
             of the same primitive could be introduced. Would need to implement it using a looped `compareAndSet`.
             */
        )
}
