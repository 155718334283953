package com.speechify.client.internal.util.io.blob.extensions

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import js.buffer.ArrayBuffer
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.files.Blob
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.js.Promise

/** Needed because for some reason current Kotlin types don't have it even though [it's there](https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer) */
internal suspend fun Blob.arrayBuffer(): ArrayBuffer =
    let {
        it.asDynamic().arrayBuffer() as Promise<ArrayBuffer>
    }
        .awaitWithBugFixOfKotlinBugClassCastException()

/**
 * Strangely, this is needed instead of [kotlinx.coroutines.await] at least when the [Promise] holds an [ArrayBuffer],
 * or else some Node.js tests fail with [ClassCastException] - see [SDK_Internal_function_for_testing__assertWrappingValueNeededForPromise] below.
 * Possible causes:
 * - [Maybe Webpack is old](https://youtrack.jetbrains.com/issue/KT-50356/Kotlin-JS-Promise.await-causes-ClassCastException)  (although, on a cursory look we do use some 5.X packages, and  [5.0 is said there that fixes the issue](https://youtrack.jetbrains.com/issue/KT-50356/Kotlin-JS-Promise.await-causes-ClassCastException#focus=Comments-27-5712575.0-0))
 * - Or maybe node is old (the problem was observed in node runtime)
 * - Or maybe Kotlin compiler is old, as it was known to also cause `ClassCastException` [here](https://github.com/Kotlin/kotlinx.coroutines/issues/3009#issuecomment-963245900) and [here](https://github.com/Kotlin/kotlinx.coroutines/issues/2623#issuecomment-813988265) (note the Kotlin team there said that the’re not going to fix this one, and people should just move to the new compiler)
 */
internal suspend fun <T> Promise<T>.awaitWithBugFixOfKotlinBugClassCastException(): T =
    suspendCancellableCoroutine { cont ->
        this.then(
            onFulfilled = { cont.resume(ValueWrapper(it)) },
            onRejected = { cont.resumeWithException(it) },
        )
    }
        .value

/**
 * For working-around some Node.js test failures with [ClassCastException] - see tests #TestWhetherValueWrapperCanBeRemoved
 */
internal class ValueWrapper<T>(val value: T)

/**
 * This function is for internal SDK testing from Node.js environment
 * (see #TestWhetherValueWrapperCanBeRemovedFromPromise where it is called)
 */
@Suppress("FunctionName")
@JsExport
fun SDK_Internal_function_for_testing__assertWrappingValueNeededForPromise(
    promise: Promise<ArrayBuffer>,
    callback: Callback<Throwable>,
): Unit = launchTask {
    suspendCancellableCoroutine<ArrayBuffer> { cont ->
        promise.then(
            onFulfilled = { arrayBuffer ->
                try {
                    cont.resume(arrayBuffer)
                } catch (e: Throwable) {
                    console.error("got error", e)
                    callback(e.successfully())
                    throw e
                }
            },
            onRejected = {
                cont.resumeWithException(it)
            },
        )
    }
}
    .ignoreValue()
