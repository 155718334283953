package com.speechify.client.api.content

import com.speechify.client.api.content.epub.EpubNavPoint

internal class TableOfContents(val entries: List<Entry>) {
    data class Entry(
        val content: Content,
        val start: Start,
        val attributes: Attributes,
    ) {
        sealed class Content {
            data class Single(val section: Section) : Content()
            data class MultipleMerged(val sections: List<Section>) : Content()
        }

        sealed class Start {
            data class Resolved(val cursor: ContentCursor) : Start()
            sealed class Unresolved : Start() {
                data class EpubChapter(val navPoint: EpubNavPoint) : Unresolved()
            }
        }
        data class Section(
            val title: String,
            val hierarchyLevel: Int,
        )
        data class Attributes(
            val targetPageIndex: Int?,
        )
    }
}
