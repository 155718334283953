package com.speechify.client.api.services.subscription.models

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Contains the pricing information for a Speechify subscription.
 */
@Serializable
@JsExport
data class SubscriptionPricingResult(
    /** The ISO code of the currency the subscription is priced in. */
    val currencyISO: String,
    /** Price of the subscription in cents. For currencies that have no cents, like Japanese Yen, or South Korean Won, this is the price without cents already. */
    val priceCents: Int,
)
