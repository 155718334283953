package com.speechify.client.api.content.scannedbook

import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageMetadata
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.TextSourceType
import com.speechify.client.api.services.scannedbook.models.ScannedBookPage
import com.speechify.client.api.services.scannedbook.models.coGetContent
import com.speechify.client.api.services.scannedbook.models.coGetImage
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.book.BoundingBoxedText

internal class ScannedBookBookPage internal constructor(
    override val pageIndex: Int,
    private val scannedBookPage: ScannedBookPage,
) : BookPageDelegate() {
    override val start: ContentCursor = ContentElementReference.forRoot().getChild(pageIndex).start
    override val end: ContentCursor = ContentElementReference.forRoot().getChild(pageIndex).end

    override fun getMetadata(): BookPageMetadata =
        BookPageMetadata(
            pageIndex = pageIndex,
            viewport = scannedBookPage.getViewport(),
        )

    override suspend fun getImage(options: BookPageRequestOptions):
        Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> =
        scannedBookPage.coGetImage()

    override fun destroy() {
        // NOOP
    }

    override suspend fun getRawTextContentItems(): Result<List<BookPageTextContentItem>> {
        val pageElement = ContentElementReference.forRoot().getChild(pageIndex)
        val metadata = getMetadata()
        val content = scannedBookPage.coGetContent().orReturn { return it }
        var prevIndex = 0
        /*
            Sorting is disabled since it doesn't work well when bounding boxes aren't aligned on the y-axis.

            For example:
            text: "A Simple PDF"

            bounding boxes: ["A", y: 66], ["Simple", y: 64], ["PDF", y: 67]

            sorted becomes "Simple A PDF", even if they all belong to the same line
         */

        val bookPageItemTextContentItems =
            content
                .mapTo(mutableListOf()) { BoundingBoxedText.fromOcr(it) }
                .let { listOf(it) }
                .map { column ->
                    column.map { item ->
                        val range = Pair(prevIndex, prevIndex + item.source.text.length)
                        val text = TextElementContentSlice(pageElement, range, item.source.text)
                        prevIndex = range.second
                        BookPageTextContentItem(
                            text = text,
                            box = item.box.normalize(metadata.viewport),
                            fontFamily = null,
                            textSourceType = TextSourceType.IMAGE,
                            type = null,
                        )
                    }
                }
                .first()
        return bookPageItemTextContentItems.successfully()
    }

    override suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult {
        return TextInBoundingBoxResult("")
    }
}
