package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.api.audio.caching.CachedSynthesisResponse
import com.speechify.client.api.audio.caching.VoiceIdForDb
import kotlin.ByteArray
import kotlin.Long
import kotlin.String

public data class SynthesisResult(
  public val synthesisResultId: Long,
  public val synthesisResultUUID: String,
  public val voiceId: VoiceIdForDb,
  public val audioData: ByteArray,
  public val synthesisMetadata: CachedSynthesisResponse,
) {
  public class Adapter(
    public val voiceIdAdapter: ColumnAdapter<VoiceIdForDb, String>,
    public val synthesisMetadataAdapter: ColumnAdapter<CachedSynthesisResponse, String>,
  )
}
