package com.speechify.client.internal.util.extensions.collections.maps

import com.speechify.client.internal.util.extensions.collections.filterNotNullSecond

internal fun <K, V> MutableMap<K, V>.putEntriesFromPairs(
    vararg pairs: Pair<K, V>,
) {
    for (p in pairs) {
        put(p.first, p.second)
    }
}

/**
 * Similar to [kotlin.collections.listOfNotNull], which filters arguments which evaluated to `null`, but for the
 * [MutableMap.put] method.
 */
internal fun <K, V> MutableMap<K, V>.putEntryFromPairsNotNull(
    vararg pairs: Pair<K, V>?,
) =
    putEntriesFromPairs(
        *pairs.filterNotNull().toTypedArray(),
    )

internal fun <K, V> MutableMap<K, V>.putEntryFromPairsOfNotNullValues(
    vararg pairs: Pair<K, V?>,
) =
    putEntriesFromPairs(
        *pairs.filterNotNullSecond(),
    )
