package com.speechify.client.internal.services.subscription

import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.DocumentChangeType
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFieldValueAdapter
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coGetObjectOrNullIfNotExists
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toNullSuccessIfResourceNotFound
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataTransformer.deductUserHdWordsPayload
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataTransformer.userRewardsToSavePayload
import com.speechify.client.internal.services.subscription.models.FirebaseSubscriptionGracePeriod
import com.speechify.client.internal.services.subscription.models.FirebaseSubscriptionPlan
import com.speechify.client.internal.services.subscription.models.FirebaseSubscriptionsInfo
import com.speechify.client.internal.services.subscription.models.FirebaseUserAudiobookCredits
import com.speechify.client.internal.services.subscription.models.FirebaseUserData
import com.speechify.client.internal.services.subscription.models.FirebaseUserHdWords
import com.speechify.client.internal.services.subscription.models.FirebaseUserRewards
import com.speechify.client.internal.time.DateTime
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlin.math.max

internal typealias FirebaseSubscriptionIdAndInfo = Pair<String, FirebaseSubscriptionsInfo>

internal class SubscriptionsFirebaseDataFetcher(
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val firebaseFieldValueAdapter: FirebaseFieldValueAdapter,
    private val shouldUpdateFirestoreWhenSubtractingHdWords: Boolean = true,
) {
    internal suspend fun getSubscriptionsInfo(
        userId: String,
        source: DataSource = DataSource.DEFAULT,
    ): Result<FirebaseSubscriptionIdAndInfo> =
        // TODO(marcel): We need a better way to find the subscriptionInfo
        // that corresponds to the userSubscriptions record of the user
        firebaseFirestoreService
            .queryDocuments("subscriptionsInfo")
            .where("userId", DocumentQueryBuilder.Operator.EQ, userId)
            .orderBy("expiresAt", DocumentQueryBuilder.Direction.Descending)
            .coFetch(source)
            .then {
                val doc = it.firstOrNull()
                if (doc == null) {
                    return Result.Failure(
                        SDKError.ResourceNotFound(
                            userId,
                            "querying subscriptionsInfo with userid '$userId' " +
                                "returned an empty list",
                        ),
                    ).also {
                        Log.d(
                            message = { it.toString() },
                            sourceAreaId = "SubscriptionsFirebaseDataFetcher.getSubscriptionsInfo",
                        ) /* Logging as 'debug' as this `Failure` is 'regular control
                         flow' (so also happens a lot) so normally doesn't need logging, but adding a debug as it
                         could help with troubleshooting a user's issue */
                    }
                }
                if (doc is FirebaseFirestoreDocumentSnapshot.Exists) {
                    doc.value<FirebaseSubscriptionsInfo>().map { doc.key to it }
                } else {
                    Result.Failure(
                        SDKError.ResourceNotFound(
                            userId,
                            "subscriptionInfo not found in firestore",
                        ),
                    )
                }
            }

    internal fun observeSubscriptionsInfo(
        userId: String,
    ): Flow<Result<FirebaseSubscriptionIdAndInfo>> = firebaseFirestoreService
        .queryDocuments("subscriptionsInfo")
        .where("userId", DocumentQueryBuilder.Operator.EQ, userId)
        .orderBy("expiresAt", DocumentQueryBuilder.Direction.Descending)
        .observeAsFlow(
            sourceAreaId = "SubscriptionsFirebaseDataFetcher.observeSubscriptionsInfo",
        )
        .map { result ->
            val querySnapshot = result.orReturn { return@map it }
            val docChange = querySnapshot
                .docChanges(null)
                .firstOrNull()
            docChange?.let {
                if (docChange.type != DocumentChangeType.Removed) {
                    docChange.doc.value<FirebaseSubscriptionsInfo>().map {
                        docChange.doc.key to it
                    }
                } else {
                    Result.Failure(
                        SDKError.ResourceNotFound(
                            userId,
                            "subscriptionsInfo for user not found in firestore",
                        ),
                    )
                }
            }
                ?: Result.Failure(
                    SDKError.ResourceNotFound(
                        userId,
                        "subscriptionsInfo for user not found in firestore",
                    ),
                )
        }

    internal suspend fun getSubscriptionPlan(
        planId: String,
    ): Result<FirebaseSubscriptionPlan> =
        firebaseFirestoreService
            .coGetDocument("subscriptionPlans", planId)
            .then { doc ->
                if (doc is FirebaseFirestoreDocumentSnapshot.Exists) {
                    doc.value()
                } else {
                    return Result.Failure(
                        SDKError.ResourceNotFound(
                            planId,
                            "could not find subscription plan with planId '$planId'",
                        ),
                    ).also { Log.e(it, sourceAreaId = "SubscriptionsFirebaseDataFetcher.getSubscriptionPlan") }
                }
            }

    internal fun observeSubscriptionPlan(
        planId: String,
    ): Flow<Result<FirebaseSubscriptionPlan>> = firebaseFirestoreService
        .observeDocumentAsFlow(
            collectionRef = "subscriptionPlans",
            documentRef = planId,
            mapExists = {
                it.value<FirebaseSubscriptionPlan>()
            },
            valueForNotExists = {
                Result.Failure(
                    SDKError.ResourceNotFound(
                        planId,
                        "could not find subscription plan with planId '$planId'",
                    ),
                ).also { Log.e(it, sourceAreaId = "SubscriptionsFirebaseDataFetcher.observeSubscriptionPlan") }
            },
        )

    internal suspend fun getUserHdWords(
        userId: String,
        dataSource: DataSource = DataSource.DEFAULT,
    ): Result<FirebaseUserHdWords> =
        firebaseFirestoreService.coGetDocument("userHdWords", userId, dataSource)
            .then { doc ->
                if (doc is FirebaseFirestoreDocumentSnapshot.Exists) {
                    doc.value()
                } else {
                    return Result.Failure(
                        SDKError.ResourceNotFound(
                            userId,
                            "could not find user hd words for user '$userId'",
                        ),
                    )
                }
            }

    internal fun observeUserHdWords(
        documentRef: String,
    ): Flow<Result<FirebaseUserHdWords>> = firebaseFirestoreService
        .observeDocumentAsFlow(
            collectionRef = "userHdWords",
            documentRef = documentRef,
            mapExists = {
                it.value<FirebaseUserHdWords>()
            },
            valueForNotExists = {
                Result.Failure(
                    SDKError.ResourceNotFound(
                        documentRef,
                        "userHdWords not found in firestore",
                    ),
                )
            },
        )

    internal suspend fun subtractHDWords(userId: String, numWords: Int): Result<Unit> {
        /**
         * Starting from using audio server v2, all hd words management was moved to the audio server.
         */
        if (!shouldUpdateFirestoreWhenSubtractingHdWords) return Unit.successfully()

        val now = DateTime.now()
        val userHdWords = getUserHdWords(userId)
            .toNullSuccessIfResourceNotFound()
            .orDefaultWith {
                Log.e(
                    error = it,
                    message = "Failed to fetch User HD words.",
                    sourceAreaId = "SubscriptionsFirebaseDataFetcher.subtractHDWords",
                )
                null
            }

        val subscriptionWordsDeducted = numWords.coerceAtMost(userHdWords?.hdWordsLeft ?: 0)
        if (subscriptionWordsDeducted > 0) {
            firebaseFirestoreService.coUpdateDocument(
                "userHdWords",
                userId,
                deductUserHdWordsPayload(subscriptionWordsDeducted, now, firebaseFieldValueAdapter),
            ).orReturn { return it }
        }

        val rewardWordsToDeduct = numWords - subscriptionWordsDeducted
        if (rewardWordsToDeduct > 0) {
            val userRewards = getUserRewards(userId)
                .orReturn {
                    return it
                }
                ?: return Result.Failure(
                    SDKError.OtherMessage(
                        NO_REWARDS_TO_DEDUCT_MESSAGE,
                    ),
                )

            val newUserRewards = userRewards.copy(
                premiumWords = max(userRewards.premiumWords - rewardWordsToDeduct, 0),
            )
            firebaseFirestoreService.coUpdateDocument(
                "userRewards",
                userId,
                userRewardsToSavePayload(newUserRewards),
            ).orReturn { return it }
        }

        return Unit.successfully()
    }

    internal suspend fun getUserRewards(
        userId: String,
    ): Result<FirebaseUserRewards?> {
        val snapshot = firebaseFirestoreService.coGetDocument("userRewards", userId)
            .orReturn { return it }

        return if (snapshot is FirebaseFirestoreDocumentSnapshot.Exists) {
            snapshot.value()
        } else {
            Result.Success(null)
        }
    }

    internal fun observeUserRewards(
        userId: String,
    ): Flow<Result<FirebaseUserRewards>> = firebaseFirestoreService
        .observeDocumentAsFlow(
            collectionRef = "userRewards",
            documentRef = userId,
            mapExists = {
                it.value<FirebaseUserRewards>()
            },
            valueForNotExists = {
                FirebaseUserRewards(0).successfully()
            },
        )

    /**
     * NOTE: Returns `null` if the user has no subscription.
     */
    internal suspend fun getUserData(userId: String): Result<FirebaseUserData?> =
        getSubscriptionsInfo(userId).then { (subscriptionId, info) ->
            getUserHdWords(userId)
                .also {
                    it.onFailure { error ->
                        Log.e(
                            failure = error,
                            message = "Failed to load user HD words for user",
                            sourceAreaId = "SubscriptionsFirebaseDataFetcher.getUserData",
                        )
                    }
                }
                .then { hdWords ->
                    val productId = info.productId
                    if (productId == null) {
                        buildMissingFieldFailure(
                            "productId",
                            "subscriptionsInfo",
                        )
                    } else {
                        getSubscriptionPlan(productId).map { plan ->
                            FirebaseUserData(subscriptionId, info, plan, hdWords)
                        }
                    }
                }
        }
            .toNullSuccessIfResourceNotFound()

    internal suspend fun getGracePeriod(
        source: String,
        dataSource: DataSource = DataSource.DEFAULT,
    ): FirebaseSubscriptionGracePeriod {
        // In case we fail we can safely fall back to 3 days.
        val defaultGracePeriod = FirebaseSubscriptionGracePeriod(gracePeriodDays = 3)

        val snapshot = firebaseFirestoreService
            .coGetDocument("subscriptionGracePeriod", source, dataSource)
            .orReturn { error ->
                Log.e(
                    error = error.error,
                    message = "Failed to load grace period for source",
                    sourceAreaId = "SubscriptionsFirebaseDataFetcher.getGracePeriod",
                    properties = mapOf("source" to source),
                )
                return defaultGracePeriod
            }

        return if (snapshot is FirebaseFirestoreDocumentSnapshot.Exists) {
            snapshot.value<FirebaseSubscriptionGracePeriod>().orDefaultWith { error ->
                Log.e(
                    error = error,
                    message = "Failed to parse grace period for source",
                    sourceAreaId = "SubscriptionsFirebaseDataFetcher.getGracePeriod",
                    properties = mapOf("source" to source),
                )
                defaultGracePeriod
            }
        } else {
            Log.e(
                message = "Found no grace period for source",
                properties = mapOf("source" to source),
                sourceAreaId = "SubscriptionsFirebaseDataFetcher.getGracePeriod",
            )
            defaultGracePeriod
        }
    }

    fun observeGracePeriod(
        source: String,
    ): Flow<Result<FirebaseSubscriptionGracePeriod>> {
        // In case we fail we can safely fall back to 3 days.
        val defaultGracePeriod = FirebaseSubscriptionGracePeriod(gracePeriodDays = 3)
        return firebaseFirestoreService
            .observeDocumentAsFlow(
                collectionRef = "subscriptionGracePeriod",
                documentRef = source,
                mapExists = {
                    it.value<FirebaseSubscriptionGracePeriod>().orDefaultWith { error ->
                        Log.e(
                            error = error,
                            message = "Failed to parse grace period for source",
                            sourceAreaId = "SubscriptionsFirebaseDataFetcher.getGracePeriod",
                            properties = mapOf("source" to source),
                        )
                        defaultGracePeriod
                    }.successfully()
                },
                valueForNotExists = {
                    Log.e(
                        message = "Found no grace period for source",
                        properties = mapOf("source" to source),
                        sourceAreaId = "SubscriptionsFirebaseDataFetcher.observeGracePeriod",
                    )
                    defaultGracePeriod.successfully()
                },
            )
    }

    internal suspend fun getUserAudiobookCredits(userId: String): Result<FirebaseUserAudiobookCredits?> =
        firebaseFirestoreService
            .coGetObjectOrNullIfNotExists<FirebaseUserAudiobookCredits>(
                PathInCollection(
                    collectionPath = "userAudiobookCredits",
                    documentPath = userId,
                ),
            )

    fun observeUserAudioBookCredits(
        userId: UserId,
    ): Flow<Result<FirebaseUserAudiobookCredits>> = firebaseFirestoreService
        .observeDocumentAsFlow(
            collectionRef = "userAudiobookCredits",
            documentRef = userId,
            mapExists = {
                it.value<FirebaseUserAudiobookCredits>()
            },
            valueForNotExists = {
                FirebaseUserAudiobookCredits(0).successfully()
            },
        )

    companion object {
        internal const val NO_REWARDS_TO_DEDUCT_MESSAGE =
            "Tried to subtract HD words for user with no rewards left."
    }
}
