package com.speechify.client.reader.core

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.services.library.offline.AudioDownloadOptions
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.util.collections.flows.onCompletionSuccessfully
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.resume
import kotlin.js.JsExport

@JsExport
class DownloadAudioHelper internal constructor(
    scope: CoroutineScope,
    offlineAvailabilityManager: OfflineAvailabilityManager,
    readingBundle: ReadingBundle,
) : Helper<DownloadAudioState>(scope) {

    override val stateFlow: MutableStateFlow<DownloadAudioState> = MutableStateFlow(DownloadAudioState.NotAvailable)
    override val initialState: DownloadAudioState = stateFlow.value

    // Needed to keep track of the current downloading Job, in order to provide a way
    // for SDK consumers to cancel it via a Command.
    private var currentDownloadJob: Job? = null

    init {
        commands.onEachInstance<DownloadAudioCommand.GetAudioDownloadStatus> {
            val speechifyUri = when (val importState = readingBundle.contentImporter.state) {
                is ContentImporterState.ImportedToLibrary -> importState.uri
                is ContentImporterState.Importing -> importState.libraryItem.uri
                is ContentImporterState.Imported -> importState.uri
                else -> null
            } ?: return@onEachInstance
            val downloadsForItem = offlineAvailabilityManager.observeDownloadedAudioForUri(speechifyUri)
                .first()
            val hasGaps = offlineAvailabilityManager.observeItemHavingGapsInAudio(speechifyUri)
                .first()
            downloadsForItem.find { it.documentUri == speechifyUri }?.let {
                DownloadAudioState.Available(
                    it.documentUri,
                    it.downloadProgress,
                    it.downloadOptions,
                    hasGaps,
                )
            }?.also {
                stateFlow.value = it
            }
        }.onEachInstance<DownloadAudioCommand.StartDownloadForOptions> { command ->
            val speechifyUri = when (val importState = readingBundle.contentImporter.state) {
                is ContentImporterState.ImportedToLibrary -> importState.uri
                is ContentImporterState.Importing -> importState.libraryItem.uri
                is ContentImporterState.Imported -> importState.uri
                else -> null
            } ?: throw IllegalStateException(
                "Cannot download audio for an item that has not been imported into the library.",
            )
            currentDownloadJob = offlineAvailabilityManager.makeLibraryItemAvailableOfflineFlow(
                uri = speechifyUri,
                audioDownloadOptions = command.audioDownloadOptions,
            ).onEach { updatedProgress ->
                stateFlow.update {
                    DownloadAudioState.InProgress(
                        uri = speechifyUri,
                        progress = updatedProgress,
                    )
                }
            }.catch { throwable ->
                stateFlow.update {
                    DownloadAudioState.Failed(throwable = throwable)
                }
            }.onCompletionSuccessfully {
                // to check again the availability and update the state.
                dispatch(DownloadAudioCommand.GetAudioDownloadStatus)
            }.launchIn(scope)
        }.onEachInstance<DownloadAudioCommand.CancelAndRemoveDownloadedAudio> {
            val speechifyUri = when (val importState = readingBundle.contentImporter.state) {
                is ContentImporterState.ImportedToLibrary -> importState.uri
                is ContentImporterState.Importing -> importState.libraryItem.uri
                is ContentImporterState.Imported -> importState.uri
                else -> null
            } ?: throw IllegalStateException(
                "Cannot cancel/remove download audio for an item that has not been imported into the library.",
            )
            currentDownloadJob?.cancel()

            val downloadsForItem = offlineAvailabilityManager.observeDownloadedAudioForUri(speechifyUri).first()
            downloadsForItem.forEach {
                offlineAvailabilityManager.coRemoveLibraryItemDownloadedAudioForVoice(
                    it.documentUri,
                    it.downloadOptions.voice,
                )
            }
            stateFlow.update {
                DownloadAudioState.NotAvailable
            }
        }.launchInHelper()

        // Check the Audio Download state in the startup.
        dispatch(DownloadAudioCommand.GetAudioDownloadStatus)
    }

    fun startDownload(audioDownloadOptions: AudioDownloadOptions?) {
        dispatch(DownloadAudioCommand.StartDownloadForOptions(audioDownloadOptions))
    }

    fun cancelOrRemoveDownloadedAudio() {
        dispatch(DownloadAudioCommand.CancelAndRemoveDownloadedAudio)
    }
}

internal sealed class DownloadAudioCommand {
    object GetAudioDownloadStatus : DownloadAudioCommand()
    class StartDownloadForOptions(
        val audioDownloadOptions: AudioDownloadOptions?,
    ) : DownloadAudioCommand()

    object CancelAndRemoveDownloadedAudio : DownloadAudioCommand()
}

@JsExport
sealed class DownloadAudioState {

    data class Available(
        val uri: SpeechifyURI,
        val progress: ProgressFraction,
        val options: AudioDownloadOptions,
        val hasGaps: Boolean,
    ) : DownloadAudioState() {
        val isDownloadCompleted get() = progress == 1.0
    }

    data class InProgress(
        val uri: SpeechifyURI,
        val progress: ProgressFraction,
    ) : DownloadAudioState()

    object NotAvailable : DownloadAudioState()

    data class Failed(
        val throwable: Throwable,
    ) : DownloadAudioState()
}

private suspend fun OfflineAvailabilityManager.coRemoveLibraryItemDownloadedAudioForVoice(
    documentUri: SpeechifyURI,
    voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer,
) = suspendCancellableCoroutine {
    removeLibraryItemDownloadedAudioForVoice(documentUri, voice, it::resume)
}
