package com.speechify.client.internal.services.adoption

import com.speechify.client.api.services.adoption.models.UserUsageData
import com.speechify.client.api.services.adoption.models.UserUsageDataEntry
import com.speechify.client.internal.services.adoption.models.FirebaseUserUsageData

internal object EcosystemAdoptionDataTransformer {
    internal fun toUserUsageData(firebaseUserUsageData: FirebaseUserUsageData): UserUsageData {
        return UserUsageData(
            android = firebaseUserUsageData.android?.let { UserUsageDataEntry(it) },
            ios = firebaseUserUsageData.ios?.let { UserUsageDataEntry(it) },
            webApp = firebaseUserUsageData.webApp?.let { UserUsageDataEntry(it) },
            chromeExtension = firebaseUserUsageData.chromeExtension?.let { UserUsageDataEntry(it) },
            safariExtension = firebaseUserUsageData.safariExtension?.let { UserUsageDataEntry(it) },
            macApp = firebaseUserUsageData.macApp?.let { UserUsageDataEntry(it) },
        )
    }
}
