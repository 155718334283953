package com.speechify.client.internal.util.extensions.throwable

import com.benasher44.uuid.uuid4
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.ErrorUuidTelemetryProp
import com.speechify.client.api.util.SDKErrorException
import com.speechify.client.internal.memory.WeakMap
import com.speechify.client.internal.sync.BlockingWrappingMutex
import com.speechify.client.internal.util.collections.KeyWithValueType
import com.speechify.client.internal.util.extensions.collections.putAllLogErrorIfDifferentExisted
import com.speechify.client.internal.util.extensions.collections.putAndGetWasDifferent
import com.speechify.client.internal.util.extensions.collections.putLogErrorIfDifferentExisted
import kotlinx.coroutines.CancellationException

private typealias CustomProperties = Map<String, Any>
private typealias MutableCustomProperties = MutableMap<String, Any>

/**
 * We use a weak map to avoid leaking memory. This allows us to efficiently add information to any exception.
 */
private val CUSTOM_PROPERTIES_OF_ALL_EXCEPTIONS_MAP = BlockingWrappingMutex.of(
    WeakMap<Throwable, MutableCustomProperties>(),
)

/**
 * This puts the custom properties in a separate task, but is thread safe to be used.
 */
internal fun Throwable.putCustomProperty(key: String, value: Any) {
    when (this) {
        is SDKErrorException -> this.sdkError.putCustomProperty(key, value)
        else -> this@putCustomProperty.runWithMutableCustomProperties { map ->
            map[key] = value
        }
    }
}

/**
 * This adds the custom properties in a separate task, but is thread safe to be used, because,
 * not to lose any information, it follows semantics of [com.speechify.client.internal.util.collections.maps.MutableMapInsertionOrFailure.add],
 * that is, will log an error if the key is already used and not update the value.
 */
internal fun Throwable.addCustomProperty(key: String, value: Any) {
    when (this) {
        is SDKErrorException -> this.sdkError.addCustomProperty(key, value)
        else ->
            this@addCustomProperty.runWithMutableCustomProperties { map ->
                if (map.putAndGetWasDifferent(key, value)) {
                    Log.e(
                        DiagnosticEvent(
                            message = "Tried to add custom property with same key but different value. " +
                                "Use putCustomProperty sibling function if this is an intended change of this " +
                                "property's value. Otherwise, use a unique key.",
                            nativeError = this@addCustomProperty,
                            sourceAreaId = "ExceptionCustomProperties.addCustomProperty",
                            properties = mapOf(
                                "key" to key,
                                "newValue" to value,
                            ),
                        ),
                    )
                }
            }
    }
}

/**
 * Returns a map of custom properties that can be used to report the error.
 * This goes through the entire chain of errors and collects all custom properties from all exceptions.
 */
internal fun Throwable.getCustomPropertiesFromEntireChain(): CustomProperties {
    val visitedThrowables: MutableSet<Throwable> = mutableSetOf()
    val result = mutableMapOf<String, Any>()
    fun Throwable.addCustomPropertiesFromEntireChainRecursive(path: String?) {
        if (!visitedThrowables.add(this)) {
            Log.e(
                DiagnosticEvent(
                    message = "A Circular reference was encountered at {path} in {rootException}. " +
                        if (this is CancellationException) {
                            "The circular reference is on a CancellationException, which may mean a " +
                                "clean-up-on-exception did not happen due to using suspend functions which " +
                                "are cancellable. If the clean up must happen on cancellations as well, " +
                                "then it should use `withContext(NonCancellable)`."
                        } else {
                            "Please investigate."
                        },
                    sourceAreaId = "getCustomPropertiesFromEntireChain",
                    properties = mapOf(
                        "path" to path.toString(),
                        "rootException" to this@getCustomPropertiesFromEntireChain.stackTraceToString(),
                        "rootExceptionMessage" to this@getCustomPropertiesFromEntireChain.message.toString(),
                    ),
                ),
            )
            return
        }

        val pathWithDotIfNeeded = path?.plus(".") ?: ""

        for ((index, e) in suppressedExceptions.withIndex()) {
            e.addCustomPropertiesFromEntireChainRecursive(path = "${pathWithDotIfNeeded}suppressed[$index]")
        }

        cause?.addCustomPropertiesFromEntireChainRecursive(path = "${pathWithDotIfNeeded}cause")

        for ((key, value) in this.getCustomProperties()) {
            result.putLogErrorIfDifferentExisted(
                key = key,
                value = value,
                sourceAreaId = "getCustomPropertiesFromEntireChain(path=$path)",
            )
        }
        visitedThrowables.remove(element = this)
    }
    addCustomPropertiesFromEntireChainRecursive(path = null)
    return result
}

@Suppress("UNCHECKED_CAST")
internal fun <T : Any> Throwable.getCustomPropertyFromTopMostInEntireChainOrNull(
    key: KeyWithValueType<T>,
): T? =
    getCustomPropertyFromTopMostInEntireChainOrNull(key.keyId) as T?

internal fun Throwable.getCustomPropertyFromTopMostInEntireChainOrNull(
    key: String,
): Any? =
    this.asChainFromTopLevelToRootCause
        .firstNotNullOfOrNull {
            it.getCustomPropertiesOnExceptionOnly()
                ?.get(key)
        }

internal fun Throwable.getOrSetErrorUUID(): String {
    return when (this) {
        is SDKErrorException -> {
            sdkError.getOrSetErrorUUID()
        }
        else -> {
            this@getOrSetErrorUUID.runWithMutableCustomProperties { map ->
                map.getOrPut(ErrorUuidTelemetryProp.keyId) { uuid4().toString() } as String
            }
        }
    }
}

internal fun Throwable.addValueToPropertyList(key: String, value: Any) {
    when (this) {
        is SDKErrorException -> {
            sdkError.addValueToPropertyList(key, value)
        }
        else -> {
            this@addValueToPropertyList.runWithMutableCustomProperties { map ->
                val list = map[key] as? MutableList<Any> ?: mutableListOf<Any>().also { map[key] = it }
                list.add(value)
            }
        }
    }
}

private fun Throwable.getCustomProperties(): CustomProperties {
    val result = this.getCustomPropertiesOnExceptionOnly()
        .orEmpty()
        .toMutableMap()

    if (this@getCustomProperties is SDKErrorException) {
        result.putAllLogErrorIfDifferentExisted(
            entries = sdkError.customProperties.entries,
            sourceAreaId = "Throwable.getCustomProperties.sdkError",
        )
    }

    return result
}

/**
 * Gets a read-only copy of the exception's own custom properties (not including any [SDKErrorException.sdkError]'s
 * properties).
 * The collection returned is a copy of the mutable collection, so this method is thread-safe.
 */
private fun Throwable.getCustomPropertiesOnExceptionOnly(): CustomProperties? =
    CUSTOM_PROPERTIES_OF_ALL_EXCEPTIONS_MAP.locked { allExceptionsPropertiesMap ->
        allExceptionsPropertiesMap.get(this@getCustomPropertiesOnExceptionOnly)
            /* Copy the map for thread-safety */
            ?.toMap()
    }

/**
 * Gets the existing map for an exception or creates a new one and attaches it to the exception.
 */
private fun <T> Throwable.runWithMutableCustomProperties(block: (MutableCustomProperties) -> T): T =
    CUSTOM_PROPERTIES_OF_ALL_EXCEPTIONS_MAP.locked { allExceptionsPropertiesMap ->
        block(
            allExceptionsPropertiesMap.get(this@runWithMutableCustomProperties)
                ?: (
                    mutableMapOf<String, Any>()
                        .also { newMap -> allExceptionsPropertiesMap.put(this@runWithMutableCustomProperties, newMap) }
                    ),
        )
    }
