package com.speechify.client.helpers.content.standard.dynamic

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentCursorComparator
import com.speechify.client.api.content.ContentIndex
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.coGetStats
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.getContentTexts
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.dynamic.contentProviders.PointerToChunkFraction
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.sync.BlockingWrappingMutex
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import com.speechify.client.internal.util.collections.flows.externalStateChangesFlow
import com.speechify.client.internal.util.collections.maps.NavigableMutableMap
import com.speechify.client.internal.util.extensions.collections.partitionToFirstAndRestOrNull
import com.speechify.client.internal.util.extensions.intentSyntax.isNullOr
import com.speechify.client.internal.util.text.groupingToWords.wordCount
import kotlin.math.roundToInt

/**
 * Provides the indexing (estimating total content size, translating size-relative
 * percentages to pointers into the content) without being given the entire content, but merely via as much content
 * as it is given.
 * This is achieved by operating on _chunks_ (just like [DynamicStandardView], and holding a sparse list of
 * [ChunksEntry] items, each of which can contain count of chunks ahead which have not yet been loaded (
 * [ChunksEntry.estimatedUnobservedChunksAfter] and [ChunksEntry.estimatedUnobservedChunksBefore]). If these counts are
 * non-null, then the index will represent the unloaded chunks with a chunk of an average size (and thus the total
 * estimate will account for the unloaded chunks, and it will be possible to use the scrubber to scroll into them).
 */
abstract class DynamicContentIndexBase : ContentIndex {

    /**
     * Adds or replaces chunks, beginning at [startingChunkIdx].
     */
    fun putChunks(
        chunks: Iterable<Iterable<StandardBlock>>,
        startingChunkIdx: Int,
        estimatedTotalChunksBefore: Int?,
        estimatedTotalChunksAfter: Int?,
        resetIndexToOnlyThisInfo: Boolean = false,
    ) {
        val chunksResolved = chunks.toList()
        if (chunksResolved.count() > 1) {
            TODO(
                /* #TODOSupportMoreThanOneChunk: */
                "Submitting more than one chunk at a time is not supported yet.",
            ) /* Would need to implement cases of putting multi-chunk-entry, but later putting part of this entry, e.g.
             by or rewriting the logic of DynamicContentIndex for ChunksEntry to contain just 1 chunk). */
        }
        val chunk = chunksResolved.lastOrNull()?.toList() ?: throw IllegalArgumentException(
            "There needs to be at least one chunk in the content",
        )
        val key = chunk.map { it.end }.lastOrNull() ?: throw IllegalArgumentException(
            /* See #SolvingRequirementOfNonEmptyChunks for any places that try to address this upfront, where the underlying
             source can actually produce empty chunks. */
            "There needs to be at least one piece of content in a chunk",
        )

        chunksMapByEndCursorMutex.locked { mapAtLockEntry ->
            val map = if (resetIndexToOnlyThisInfo) {
                createEmptyChunksMapByEndCursor()
                    .also {
                        this.changeMutexSubject(
                            newValue = it,
                        )
                    }
            } else {
                mapAtLockEntry
            }
            val existentChunkOfSameKey = map[key]
            val higherChunk = map.higherEntry(key)?.value
            val lowerChunk = map.lowerEntry(key)?.value
            val originalLowerEstimatedUnobservedChunksAfter: Int? = lowerChunk?.estimatedUnobservedChunksAfter
            val originalHigherEstimatedUnobservedChunksBefore: Int? = higherChunk?.estimatedUnobservedChunksBefore

            val estimatedUnobservedChunksBefore =
                if (lowerChunk != null) {
                    val unobservedChunksFromLowerToCurrent =
                        startingChunkIdx - (lowerChunk.startingChunkIdx + lowerChunk.chunks.count())
                    lowerChunk.estimatedUnobservedChunksAfter = unobservedChunksFromLowerToCurrent
                    unobservedChunksFromLowerToCurrent
                } else {
                    estimatedTotalChunksBefore
                        ?: (
                            if (existentChunkOfSameKey != null) { /* If we are replacing the already existent one,
                            we must take from it, as the previous will have just the count 'up to the existent one' */
                                existentChunkOfSameKey.estimatedUnobservedChunksBefore
                            } else if (higherChunk != null && originalHigherEstimatedUnobservedChunksBefore != null) {
                                    /* The chunk we are adding is first and has no estimate, but the 'previously first' could have
                                   had it. Let's make sure we don't shrink the estimate. */
                                /* TODO - UNIT TEST THIS CASE? (BECAUSE SDK CONSUMERS MAY OR MAY NOT USE IT)
                                     It will be pretty hard and brittle on this level, so consider
                                     [the initiative to create the infrastructure for Public API-based unit tests](https://www.notion.so/fresh-hoodie-9f1/Q4-Retro-0a16c0e753a94671a81dce633a549c5e#a9c9e5e2283e4235ad3a98cf07fe1c88)
                                 */
                                (
                                    originalHigherEstimatedUnobservedChunksBefore -
                                        (higherChunk.startingChunkIdx - startingChunkIdx) - /* subtract how much is
                                         between the two */
                                        chunks.count() /* and also subtract the actual in this chunk */
                                    ).coerceAtLeast(
                                    0,
                                )
                            } else {
                                null
                            }
                            )
                }

            val estimatedUnobservedChunksAfter =
                if (higherChunk != null) {
                    val unobservedChunksFromCurrentToHigher =
                        higherChunk.startingChunkIdx - (startingChunkIdx + chunks.count())

                    higherChunk.estimatedUnobservedChunksBefore = unobservedChunksFromCurrentToHigher

                    unobservedChunksFromCurrentToHigher
                } else {
                    estimatedTotalChunksAfter
                        ?: (
                            if (existentChunkOfSameKey != null) { /* If we are replacing the already existent one,
                            we must take from it, as the previous will have just the count 'up to the existent one' */
                                existentChunkOfSameKey.estimatedUnobservedChunksAfter
                            } else if (lowerChunk != null && originalLowerEstimatedUnobservedChunksAfter != null) {
                                    /* The chunk we are adding is last and has no estimate, but the 'previously last' could have
                           had it. Let's make sure we don't shrink the estimate. */
                                (
                                    originalLowerEstimatedUnobservedChunksAfter -
                                        (
                                            startingChunkIdx -
                                                (lowerChunk.startingChunkIdx + lowerChunk.chunks.count())
                                            ) - /* subtract how much is between the two*/
                                        chunks.count() /* and also subtract the actual in this chunk */
                                    ).coerceAtLeast(
                                    0,
                                )
                            } else {
                                null
                            }
                            )
                }

            map.put(
                key = key,
                value = ChunksEntry(
                    chunks = chunksResolved,
                    startingChunkIdx = startingChunkIdx,
                    estimatedUnobservedChunksBefore = estimatedUnobservedChunksBefore,
                    estimatedUnobservedChunksAfter = estimatedUnobservedChunksAfter,
                ),
            )
        }

        contentAmountChangesFlowMutable.signalChange()
    }

    /**
     * Returns a cursor for content in a chunk that has not been loaded yet, but has been estimated to exist
     * (with coordinates specified in [pointerToEstimatedChunk]). Implementation can verify the truthiness of this
     * by updating the index and returning a cursor closest to the [pointerToEstimatedChunk]).
     */
    internal abstract suspend fun getCursorForEstimatedChunk(pointerToEstimatedChunk: PointerToChunkFraction):
        Result<ContentCursor>

    private val contentAmountChangesFlowMutable = externalStateChangesFlow()

    override val contentAmountStateFlow: ExternalStateChangesFlow
        get() = contentAmountChangesFlowMutable

    override fun getCursorFromProgress(
        progress: ProgressFraction,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        return@fromCo coGetCursorFromProgress(progress)
    }

    override fun getProgressFromCursor(cursor: ContentCursor, callback: Callback<ProgressFraction>) = callback.fromCo {
        getProgressFromCursorSync(cursor).successfully()
    }

    internal open suspend fun coGetCursorFromProgress(progress: Double): Result<ContentCursor> =
        getCursorWithCoordsFromProgress(progress).orReturn { return it }.contentCursor.successfully()

    protected class CursorResultWithChunkCoords(
        val contentCursor: ContentCursor,
        val coords: PointerToChunkFraction,
    )

    protected suspend fun getCursorWithCoordsFromProgress(progress: Double): Result<CursorResultWithChunkCoords> {
        val resultOfIndexSearch =
            chunksMapByEndCursorMutex.locked { chunksMapByEndCursor ->

                val averageWordCountInChunk = chunksMapByEndCursor.values.averageWordCountInChunk
                fun getEstimatedWordCountForChunksCount(estimatedChunksCount: Int) =
                    getEstimatedWordCountForChunksCount(
                        estimatedChunksCount = estimatedChunksCount,
                        averageWordCountInChunk = averageWordCountInChunk,
                    )

                val totalWordCount = chunksMapByEndCursor.getSumOfEstimatedAndRealWordCount(
                    averageWordCountInChunk = averageWordCountInChunk,
                )

                val estimatedWordToHit = totalWordCount * progress

                var lastProgressInWordCount = 0.0
                var lastChunksEntry: ChunksEntry? = null

                fun getRemainingProgressAsPointerIntoEstimatedChunks(): PointerToChunkFraction {
                    val baseChunkIdx = lastChunksEntry?.endingChunkIdx?.let { it + 1 } ?: 0
                    val remainingChunksCountDouble =
                        (estimatedWordToHit - lastProgressInWordCount) / averageWordCountInChunk

                    val chunkIdx = remainingChunksCountDouble.toInt()

                    return PointerToChunkFraction(
                        chunkIndex = (baseChunkIdx + chunkIdx),
                        fractionIntoChunk = remainingChunksCountDouble - chunkIdx,
                    )
                }

                for (chunksEntry in chunksMapByEndCursor.values) {
                    val progressAccountingForBeforeChunksInWords =
                        lastProgressInWordCount + getEstimatedWordCountForChunksCount(
                            chunksEntry.estimatedUnobservedChunksBefore ?: 0,
                        )
                    if (progressAccountingForBeforeChunksInWords > estimatedWordToHit) {
                        val contentPointerOnEstimatedChunk = getRemainingProgressAsPointerIntoEstimatedChunks()

                        return@locked ResultOfIndexSearch.EstimationResult(contentPointerOnEstimatedChunk)
                            .successfully()
                    } else {
                        lastProgressInWordCount = progressAccountingForBeforeChunksInWords
                        for ((chunkLocalIndex, chunk) in chunksEntry.chunks.withIndex()) {
                            val wordNumberRelativeToNow = estimatedWordToHit - lastProgressInWordCount
                            val findContentTextResult = chunk.allContentTexts
                                .getContentTextContainingWordNumber(
                                    wordNumber = wordNumberRelativeToNow.roundToInt(),
                                )
                            when (findContentTextResult) {
                                is ResultOfGetContentTextContainingWordNumber.FoundContentText ->
                                    return@locked ResultOfIndexSearch.FoundCursor(
                                        cursor = findContentTextResult.contentText.start,
                                        coords = PointerToChunkFraction(
                                            chunkIndex = chunksEntry.startingChunkIdx + chunkLocalIndex,
                                            fractionIntoChunk = wordNumberRelativeToNow /
                                                chunk.allContentTexts.wordCount,
                                        ),
                                    )
                                        .successfully()

                                is ResultOfGetContentTextContainingWordNumber.ScannedToEnd ->
                                    lastProgressInWordCount += findContentTextResult.numberOfWords
                            }
                        }
                    }

                    lastChunksEntry = chunksEntry
                }

                if (lastChunksEntry == null) {
                    throw Error(
                        "No content chunks added, but got a request for content pointer. The case isn't supported",
                    )
                    /* #CannotSeekUsingPercentageProgressBeforeAnyContentAdded - Supporting this case isn't needed at
                     the moment (cursor-from-progress is only needed for scrubbing which should only have
                     degrees-of-freedom when there's some content).*/
                } else {
                    val contentPointerOnEstimatedChunk = getRemainingProgressAsPointerIntoEstimatedChunks()
                    when (val estimatedUnobservedChunksAfter = lastChunksEntry.estimatedUnobservedChunksAfter) {
                        is Any -> {
                            val lastPossibleIndex = lastChunksEntry.endingChunkIdx + estimatedUnobservedChunksAfter

                            if (lastPossibleIndex >= contentPointerOnEstimatedChunk.chunkIndex) {
                                return@locked ResultOfIndexSearch.EstimationResult(contentPointerOnEstimatedChunk)
                                    .successfully()
                            } else if (/* Translate 100% into 100% of last possible chunk, not to scare implementor of
                        `getCursorForEstimatedChunk` with indexes that were explicitly claimed impossible by estimates
                         */
                                (
                                    contentPointerOnEstimatedChunk.chunkIndex == lastPossibleIndex + 1
                                    ) &&
                                contentPointerOnEstimatedChunk.fractionIntoChunk <= 0.000001 /* There's a division, so
                             instead of 0.0, let's account for some floating-point imprecision (although it wasn't
                             observed during development)
                           */
                            ) {
                                return@locked ResultOfIndexSearch.EstimationResult(
                                    PointerToChunkFraction(
                                        chunkIndex = contentPointerOnEstimatedChunk.chunkIndex - 1,
                                        fractionIntoChunk = 1.0,
                                    ),
                                ).successfully()
                            } else {
                                return@locked Result.Failure(
                                    SDKError.OtherMessage(
                                        "Estimation would have requested a chunk" +
                                            " index ${contentPointerOnEstimatedChunk.chunkIndex}, which is beyond the" +
                                            " last possible index of $lastPossibleIndex",
                                    ),
                                )
                            }
                        }

                        else ->
                            return@locked ResultOfIndexSearch.EstimationResult(contentPointerOnEstimatedChunk)
                                .successfully()
                    }
                }
            }.orReturn { return@getCursorWithCoordsFromProgress it }

        return when (resultOfIndexSearch) {
            is ResultOfIndexSearch.FoundCursor ->
                CursorResultWithChunkCoords(
                    contentCursor = resultOfIndexSearch.cursor,
                    coords = resultOfIndexSearch.coords,
                ).successfully()

            is ResultOfIndexSearch.EstimationResult -> CursorResultWithChunkCoords(
                contentCursor = getCursorForEstimatedChunk(
                    resultOfIndexSearch.pointerToChunkFraction,
                ).orReturn { return it },
                coords = resultOfIndexSearch.pointerToChunkFraction,
            ).successfully()
        }
    }

    internal fun isChunkIdxPossible(chunkIdx: Int): Boolean =
        chunksMapByEndCursorMutex.locked { chunksMapByEndCursor ->
            val firstIndexEntry = chunksMapByEndCursor.values.firstOrNull() ?: throw UnsupportedOperationException(
                "There should be no request for `isChunkIdxPossible` on an" +
                    "empty index.",
            ) /* It should never happen. If it does, strongly consider refactoring, as this functions semantics would
                 have to become blurry (it isn't known if such an index is possible or not).
                  */

            if (chunkIdx < firstIndexEntry.startingChunkIdx /* It's a chunk even further than the first one. Check
             estimates */
            ) {
                return@locked firstIndexEntry.estimatedUnobservedChunksBefore.isNullOr { // `null` means 'keep trying', so it's possible
                    firstIndexEntry.startingChunkIdx - this <= chunkIdx /* If estimate is specific, then chunk idx is
                     only possible
                  if within that estimate
                */
                }
            }

            val lastIndexEntry = chunksMapByEndCursor.values.last() /* Will never throw, as we just had the `first` */
            if (
                chunkIdx > lastIndexEntry.endingChunkIdx /* It's a chunk index even further than the last one.
                 Check estimates */
            ) {
                return@locked lastIndexEntry.estimatedUnobservedChunksAfter.isNullOr { // `null` means 'keep trying', so it's possible
                    (this + lastIndexEntry.endingChunkIdx) >= chunkIdx /* If estimate is specific, then chunk idx is
                     only possible if within that estimate
                    */
                }
            }

            // Else, it's within estimates
            return@locked true
        }

    private sealed class ResultOfIndexSearch {
        class FoundCursor(
            val cursor: ContentCursor,
            val coords: PointerToChunkFraction,
        ) : ResultOfIndexSearch()

        class EstimationResult(val pointerToChunkFraction: PointerToChunkFraction) : ResultOfIndexSearch()
    }

    internal fun getProgressFromCursorSync(cursor: ContentCursor): Double =
        (
            1.0 - /* Subtract from one, as below we calculate how much is left, not how much is read
             */(
                chunksMapByEndCursorMutex.locked { chunksMap ->
                    chunksMap.tailMap(cursor, inclusive = true)
                        .let { submapStartingAtChunkContaining ->
                            val averageWordCountInChunk = chunksMap.values.averageWordCountInChunk
                            val firstChunksEntry = submapStartingAtChunkContaining.values.firstOrNull()
                            if (firstChunksEntry === null) {
                                0.0
                            } else {
                                val rest = submapStartingAtChunkContaining.values.drop(1)
                                val (textContainingCursor, textsAfterCursor) = firstChunksEntry.allActualTexts
                                    .dropWhile { it.end.isBefore(cursor) }
                                    .partitionToFirstAndRestOrNull()
                                    ?: throw IllegalStateException(
                                        "No text found but somehow it contains the cursor.",
                                    /* To the best of
                                                                           author's understanding, this would mean a bug in the SDK, possibly due to
                                                                           changes to implementation of this index. Perhaps ...
                                                                            Or the author's tired brain didn't realize that it can occur in normal use
                                                                            case.
                                                                            */
                                    )

                                val excerptAfterCursorFromFragmentContainingIt =
                                    textContainingCursor.slice(
                                        startIndex = textContainingCursor.getFirstIndexOfCursor(cursor),
                                        endIndex = textContainingCursor.length,
                                    )

                                val actualWordCountInFirst = excerptAfterCursorFromFragmentContainingIt.text
                                    .wordCount() +
                                    textsAfterCursor.wordCount

                                val estimatedAndActualAfterFirst = rest.let {
                                    if (it.isEmpty()) {
                                        getEstimatedWordCountForChunksCount(
                                            estimatedChunksCount = firstChunksEntry.estimatedUnobservedChunksAfter ?: 0,
                                            averageWordCountInChunk = averageWordCountInChunk,
                                        )
                                    } else {
                                        getSumOfEstimatedAndRealWordCount(
                                            chunksEntries = it,
                                            averageWordCountInChunk = averageWordCountInChunk,
                                        )
                                    }
                                }
                                val totalEstimatedAndRealWordCount =
                                    chunksMap.getSumOfEstimatedAndRealWordCount(averageWordCountInChunk)

                                (
                                    (actualWordCountInFirst + estimatedAndActualAfterFirst.toDouble()) /
                                        totalEstimatedAndRealWordCount
                                            .coerceAtLeast(1)
                                    ) /* `coerceAtLeast` to prevent infinity before things loaded - make progress 0
                                     rather */
                            }
                        }
                }
                )
            )

    override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
        ContentStats(
            estimatedWordCount = EstimatedCount(
                chunksMapByEndCursorMutex.locked {
                    it.getSumOfEstimatedAndRealWordCount(
                        it.values.averageWordCountInChunk,
                    )
                },
                0.5,
            ),
        )
            .successfully()
    }

    override suspend fun getStatsIncludingPending(): ContentStats =
        coGetStats().orThrow()

    private val chunksMapByEndCursorMutex = BlockingWrappingMutex.of(
        createEmptyChunksMapByEndCursor(),
    )

    private fun createEmptyChunksMapByEndCursor() = NavigableMutableMap<ContentCursor, ChunksEntry>(
        keyComparator = ContentCursorComparator,
    )

    private fun NavigableMutableMap<ContentCursor, ChunksEntry>.getSumOfEstimatedAndRealWordCount(
        averageWordCountInChunk: Double,
    ): Int =
        getSumOfEstimatedAndRealWordCount(
            chunksEntries = values,
            averageWordCountInChunk = averageWordCountInChunk,
        )

    private fun getSumOfEstimatedAndRealWordCount(
        chunksEntries: Iterable<ChunksEntry>,
        averageWordCountInChunk: Double,
    ): Int {
        fun getEstimatedWordCountForChunksCount(estimatedChunksCount: Int) =
            getEstimatedWordCountForChunksCount(
                estimatedChunksCount = estimatedChunksCount,
                averageWordCountInChunk = averageWordCountInChunk,
            )

        return (
            chunksEntries.sumOf {
                it.allActualTexts.wordCount + getEstimatedWordCountForChunksCount(
                    it.estimatedUnobservedChunksBefore ?: 0,
                )
            } + getEstimatedWordCountForChunksCount(chunksEntries.lastOrNull()?.estimatedUnobservedChunksAfter ?: 0)
            )
            .roundToInt()
    }

    private fun getEstimatedWordCountForChunksCount(
        estimatedChunksCount: Int,
        averageWordCountInChunk: Double,
    ): Double {
        return averageWordCountInChunk * estimatedChunksCount
    }
}

private class ChunksEntry(
    val chunks: Iterable<Iterable<StandardBlock>>,
    val startingChunkIdx: Int,
    var estimatedUnobservedChunksBefore: Int?,
    var estimatedUnobservedChunksAfter: Int?,
) {
    val endingChunkIdx
        get() = startingChunkIdx +
            chunks.count().also { check(it > 0) { "Zero length chunks aren't supported" } } - 1

    // memoize due to performance issues on CE
    // https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1707915028599319
    val wordCount: Int by lazy {
        this.chunks.sumOf { it.allContentTexts.wordCount }
    }

    // memoize due to performance issues on CE
    // https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1707915028599319
    val chunkCount: Int by lazy {
        this.chunks.count()
    }
}

private val Iterable<ChunksEntry>.allActualTexts
    get() =
        allStandardBlocks.allContentTexts // TODO - optimize?

private val Iterable<ChunksEntry>.allStandardBlocks
    get() =
        flatMap { it.allStandardBlocks }

private val ChunksEntry.allActualTexts
    get() =
        listOf(this).allActualTexts

private val ChunksEntry.allStandardBlocks
    get() =
        this.chunks.flatten()

private val Iterable<ChunksEntry>.averageWordCountInChunk: Double
    get() = this.sumOf { it.wordCount }.toDouble() / this.sumOf { it.chunkCount }
        .coerceAtLeast(1) /* To produce 0, rather than infinity for empty */

internal val Iterable<StandardBlock>.allContentTexts
    get() =
        flatMap { it.getContentTexts() }

internal val Iterable<ContentText>.wordCount
    get() =
        sumOf { it.text.wordCount() }

internal fun Iterable<ContentText>.getContentTextContainingWordNumber(
    wordNumber: Int,
): ResultOfGetContentTextContainingWordNumber {
    var progressInWordCount = 0
    for (contentText in this) {
        val progressIncludingThisText = progressInWordCount + contentText.text.wordCount()
        if (progressIncludingThisText > wordNumber) {
            return ResultOfGetContentTextContainingWordNumber.FoundContentText(
                contentText = contentText,
            )
        } else {
            progressInWordCount = progressIncludingThisText
        }
    }

    return ResultOfGetContentTextContainingWordNumber.ScannedToEnd(numberOfWords = progressInWordCount)
}

internal sealed class ResultOfGetContentTextContainingWordNumber {
    class FoundContentText(val contentText: ContentText) : ResultOfGetContentTextContainingWordNumber()

    class ScannedToEnd(val numberOfWords: Int) : ResultOfGetContentTextContainingWordNumber()
}
