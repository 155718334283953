package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.helpers.features.ListeningProgress
import kotlin.Long
import kotlin.String

public data class LocalListeningProgress(
  public val speechifyUri: SpeechifyURI,
  public val listeningProgress: ListeningProgress?,
  public val lastUpdatedTime: Long,
) {
  public class Adapter(
    public val speechifyUriAdapter: ColumnAdapter<SpeechifyURI, String>,
    public val listeningProgressAdapter: ColumnAdapter<ListeningProgress, String>,
  )
}
