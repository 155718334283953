package com.speechify.client.internal.util.extensions.throwable

internal inline fun Throwable.throwAfterRunAddingToSuppressed(
    blockToRun: () -> Unit,
): Nothing {
    try {
        blockToRun()
    } catch (e: Throwable) {
        this.addSuppressed(e)
    }
    throw this
}
