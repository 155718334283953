package com.speechify.client.api.adapters.db

import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.adapters.keyvalue.coGetSingle
import com.speechify.client.api.adapters.keyvalue.coPutSingle
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.sqldelight.Database
import com.speechify.client.internal.util.extensions.numbers.toByteEnsuringLossless
import com.speechify.client.internal.util.extensions.numbers.toIntEnsuringLossless
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * A base for [AbstractSqlDriverFactory] implementations whose drivers don't have a built-in migration mechanism.
 *
 * Android and iOS Drivers do have that mechanism, but for some JS drivers it doesn't happen, so SDK needs to
 * manage the versioning and execution of migrations.
 * (See JavaScript subclasses of the hereby class vs those of [AbstractSqlDriverFactory] to see that e.g.
 * [SqlDriverFactoryUsingWebWorker] requires this), while some behave like Android and iOS (e.g. [SqlDriverFactoryForNodeJsBase]).
 */
abstract class SqlDriverFactoryForMigrationlessDriverBase(
    private val dependencies: SqlDriverFactoryForMigrationlessDriverDependenciesBase,
) : AbstractSqlDriverFactory() {
    override suspend fun createSqlDriver(localKeyValueStorageAdapter: LocalKeyValueStorageAdapter): SqlDriver {
        return dependencies.createInternalSqlDriver().also {
            val lastMigrationVersionStorage = dependencies.getLastMigrationVersionStorage(
                localKeyValueStorageAdapter = localKeyValueStorageAdapter,
            )
            val lastMigrationVersion = lastMigrationVersionStorage.getLastMigrationVersion()

            if (lastMigrationVersion == null) {
                dependencies.coEnsureDbDoesNotExist()
            }

            val versionPostMigration = Database.Schema.version

            Database.Schema.migrate(
                driver = it,
                oldVersion = (lastMigrationVersion ?: 0).toLong(),
                newVersion = versionPostMigration,
            ).await()

            lastMigrationVersionStorage.setLastMigrationVersion(
                newValue = versionPostMigration
                    .toIntEnsuringLossless(),
            )
        }
    }
}

/**
 * See the members docs for the concerns to implement, especially [ensureDbDoesNotExist]
 */
@JsExport
abstract class SqlDriverFactoryForMigrationlessDriverDependenciesBase {
    /**
     * The [ensureDbDoesNotExist] function is invoked to remove the database file under
     * specific circumstances. This occurs when the website's local storage lacks the database version,
     * particularly after the website's local storage was cleared.
     * You can refer to the implementation example in the web demo app in `sql.ts` file.
     */
    @Suppress("NON_EXPORTABLE_TYPE") // `Unit` exports just fine
    abstract fun ensureDbDoesNotExist(callback: Callback<Unit>)

    open fun getLastMigrationVersionStorage(
        localKeyValueStorageAdapter: LocalKeyValueStorageAdapter,
    ): SqlLastMigrationVersionStorage =
        @JsExport.Ignore
        object : SqlLastMigrationVersionStorage() {
            override fun getLastMigrationVersion(callback: Callback<Int?>) = callback.fromCo {
                localKeyValueStorageAdapter
                    .coGetSingle(LAST_MIGRATION_VERSION_KEY)
                    ?.single()
                    ?.toInt()
                    .successfully()
            }

            override fun setLastMigrationVersion(
                newValue: Int,
                callback: Callback<Unit>,
            ) = callback.fromCo {
                localKeyValueStorageAdapter.coPutSingle(
                    key = LAST_MIGRATION_VERSION_KEY,
                    data = newValue
                        .toByteEnsuringLossless(
                            exceptionOnBeyondMax = { maxValue ->
                                TODO("Time to fix this, adding support for versions larger than $maxValue")
                            },
                        )
                        .let {
                            ByteArray(
                                size = 1,
                                init = { _ -> it },
                            )
                        },
                )
            }
        }

    internal abstract fun createInternalSqlDriver(): SqlDriver

    internal companion object {
        const val LAST_MIGRATION_VERSION_KEY = "sql_driver_factory_last_migration_version"
    }
}

@JsExport
abstract class SqlLastMigrationVersionStorage {
    protected abstract fun getLastMigrationVersion(callback: Callback<Int?>)
    protected abstract fun setLastMigrationVersion(
        newValue: Int,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    )

    @JsExport.Ignore
    internal suspend fun getLastMigrationVersion(): Int? =
        suspendCoroutine {
            getLastMigrationVersion(it::resume)
        }
            .orThrow()

    @JsExport.Ignore
    internal suspend fun setLastMigrationVersion(
        newValue: Int,
    ): Unit =
        suspendCoroutine {
            setLastMigrationVersion(
                newValue = newValue,
                callback = it::resume,
            )
        }
            .orThrow()
}

internal suspend fun SqlDriverFactoryForMigrationlessDriverDependenciesBase.coEnsureDbDoesNotExist(): Result<Unit> =
    suspendCoroutine { continuation ->
        this.ensureDbDoesNotExist { continuation.resume(it) }
    }
