package com.speechify.client.reader.fixedlayoutbook.overlay

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.util.Result
import com.speechify.client.bundlers.content.BookPageIndex

/**
 * Factory to create the object of [FixedLayoutOverlayStrategy] that will decide the logic to compute the overlays to be drawn for FixedLayout View
 */
internal fun getOverlayStrategy(
    appEnvironment: AppEnvironment,
    search: suspend (
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        bookSearchOptions: BookSearchOptions,
    ) -> Result<Array<BookSearchResult>>,
): FixedLayoutOverlayStrategy {
    return when (appEnvironment) {
        AppEnvironment.DESKTOP_EXTENSION,
        AppEnvironment.SAFARI_EXTENSION,
        AppEnvironment.WEB_APP,
        -> PointsBasedFLOverlayStrategy(
            fallbackStrategy = SearchBasedFLOverlayStrategy(search),
        )

        else -> PointsBasedFLOverlayStrategy(
            fallbackStrategy = CursorBasedFLOverlayStrategy(),
        )
    }
}
