package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class OneClickRenewalStatus {
    data class Available(
        val price: Float,
        val originalPrice: Float,
        val currency: String,
        val productId: String,
        val source: String,
        val periodUnit: String,
        val periodCount: Int,
        val subscriptionId: String,
        /**
         * If this is false it is safe to apply the renewal without user confirmation as the user will not be charged,
         * anything. This is for example the case if the renewal will just un-cancel an already paid for subscription.
         */
        val willChargeUser: Boolean,
    ) : OneClickRenewalStatus()

    object Unavailable : OneClickRenewalStatus()
}
