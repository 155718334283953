package com.speechify.client.api.util

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.telemetry.withTelemetryInContext
import com.speechify.client.internal.getGlobalScopeWithContext
import com.speechify.client.internal.launchTopLevel
import com.speechify.client.internal.toDestructible
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import com.speechify.client.internal.util.runCatchingSilencingErrorsLoggingThem
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlin.jvm.JvmName

typealias Callback<T> = (result: Result<T>) -> Unit
typealias CoCallback<T> = suspend CoroutineScope.(result: Result<T>) -> Unit

/**
 * NOTE: Avoid using for single-shot callbacks (as it has no way of communicating that the response will never come)
 * and only use for multi-shot when there is also a way to communicate error, e.g. by throwing on some method called
 * by the consumer, like it is done in `Flow.onEach( { successItem } ).collect()` (the `collect` will fail with an
 * error).
 */
typealias CallbackNoError<T> = (value: T) -> Unit

typealias SuspendCallbackNoError<T> = suspend (value: T) -> Unit

/**
 * Convert kotlin idiomatic suspense functions to [this] callback, making sure it always gets called, even if an exception occurred.
 * The function allows to use code blocks which use the kotlin suspend functions with their modern non-nested syntax.
 *
 * NOTE: use it in `=` style functions, and put it in the same line as the `) =`, and indentation will be single level,
 * just like for a plain function (kotlin formatter treats it like a plain block decorator, similar to `by lazy`).
 */
/* Public, since it's used by platforms with Kotlin (e.g. Android) */
fun <T> Callback<T>.fromCo(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> Result<T>,
) =
    fromCoGetJob(
        scope = scope,
        start = start,
        coroutine = coroutine,
    )
        /* Making `fromCo` a [Unit]-returning  function, so it can be used in SDK's ubiquitous (Callback) -> Unit
         functions with the `=` syntax, so there's no extra indentation. */
        .ignoreValue()

/**
 * A version of [fromCo] to use when the [Job] is needed (e.g. to be able to cancel it).
 */
fun <T> Callback<T>.fromCoGetJob(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> Result<T>,
): Job =
    (scope ?: getGlobalScopeWithContext()).launch(
        start = start,
    ) {
        this@fromCoGetJob(runCatching { coroutine() }.flatten())
    }

/**
 * A version of [fromCoGetJob] that doesn't use [GlobalScope], but rather creates own top-level scope.
 */
fun <T> Callback<T>.fromCoGetDestructible(
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> Result<T>,
): Destructible = launchTopLevel(
    start = start,
) {
    this@fromCoGetDestructible(runCatching { coroutine() }.flatten())
}

/**
 * Convert kotlin idiomatic suspense functions to [this] callback, making sure it always gets called, even if an exception occurred.
 * The function allows to use code blocks which use the kotlin suspend functions with their modern non-nested syntax.
 *
 * This version of the function will also log the error to developers / telemetry and should only be used on the boundary
 * between the SDK and the application.
 *
 * WARNING: This wrapper loses the outermost exception's type, so only use it where there is no contract on the exception type
 * (or use a contract on 'any exception in chain is of type', like the [com.speechify.client.api.util.isCausedByConnectionError]).
 *
 * NOTE: use it in `=` style functions, and put it in the same line as the `) =`, and indentation will be single level,
 * just like for a plain function (kotlin formatter treats it like a plain block decorator, similar to `by lazy`).
 */
internal
/*
 * NOTE on `inline` here: This is desired especially so that the exception created here gets a call stack that includes
 * the caller - see [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/922#discussion_r1151668719)
 * #InlineForCallerInStacktrace
 */
inline
fun <T> Callback<T>.fromCoWithErrorLoggingGetJob(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    sourceAreaId: String,
    crossinline shouldSilenceError: (error: SDKError) -> Boolean = { false },
    crossinline coroutine: suspend CoroutineScope.() -> Result<T>,
): Job {
    return (scope ?: getGlobalScopeWithContext()).launch(
        start = start,
    ) {
        val result = runCatching {
            coroutine()
        }
            .flatten()
            .also {
                it.onFailure { failure ->
                    if (
                        failure.isExceptionType<CancellationException>() ||
                        shouldSilenceError(failure.error)
                    ) {
                        return@onFailure
                    }

                    Log.SpeechifyStoredLog.e(
                        DiagnosticEvent(
                            nativeError = failure.error
                                /* We wrap the exception here so the stack contains the call site. we otherwise would miss this
                                 * frame from the stack trace.
                                 */
                                .toExceptionWithCallerStackTrace(),
                            sourceAreaId = sourceAreaId,
                        ),
                    )
                }
            }
        try {
            this@fromCoWithErrorLoggingGetJob(
                result,
            )
        } catch (e: Throwable) {
            throw Exception(
                "Exception thrown from callback function. " +
                    "This may be a bug in SDK consumer, so it's strongly advised to investigate on SDK consumer end. " +
                    "See details in `cause`.",
                e,
            )
        }
    }
}

/**
 * Convert kotlin idiomatic suspense functions to [this] callback, making sure it always gets called, even if an exception occurred.
 * The function allows to use code blocks which use the kotlin suspend functions with their modern non-nested syntax.
 *
 * This version of the function will also log the error to developers / telemetry and should only be used on the boundary
 * between the SDK and the application.
 *
 * WARNING: This wrapper loses the outermost exception's type, so only use it where there is no contract on the exception type
 * (or use a contract on 'any exception in chain is of type', like the [com.speechify.client.api.util.isCausedByConnectionError]).
 *
 * NOTE: use it in `=` style functions, and put it in the same line as the `) =`, and indentation will be single level,
 * just like for a plain function (kotlin formatter treats it like a plain block decorator, similar to `by lazy`).
 */
internal
/*
 * NOTE on `inline` here: This is desired especially so that the exception created here gets a call stack that includes
 * the caller - see [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/922#discussion_r1151668719)
 * #InlineForCallerInStacktrace
 */
inline
fun <T> Callback<T>.fromCoWithErrorLogging(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    sourceAreaId: String,
    crossinline shouldSilenceError: (error: SDKError) -> Boolean = { false },
    crossinline coroutine: suspend CoroutineScope.() -> Result<T>,
): Unit = fromCoWithErrorLoggingGetJob(scope, start, sourceAreaId, shouldSilenceError, coroutine).ignoreValue()

/**
 * A version of [fromCo] for [CallbackNoError] callbacks (error will be reported to developers).
 */
/* Public, since it's used by platforms with Kotlin (e.g. Android) */
fun <T> CallbackNoError<T>.fromCoNoError(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> T,
): Unit =
    (
        { result: Result<T> ->
            this@fromCoNoError.invoke(
                result.orThrow(), /* The `fromCo` that follows will make the exception be passed to infrastructure
                 (reported to developers as an unhandled exception). */
            )
        }
        )
        .fromCo(
            scope = scope,
            start = start,
            coroutine = {
                coroutine().successfully()
            },
        )

/**
 * A version of [fromCoNoError] for `suspend` functions.
 */
fun <T> SuspendCallbackNoError<T>.fromCoNoError(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> T,
) =
    fromCoNoErrorGetJob(
        scope = scope,
        start = start,
        coroutine = coroutine,
    )
        /* Making `fromCo` a [Unit]-returning  function, so it can be used in SDK's ubiquitous (Callback) -> Unit
         functions with the `=` syntax, so there's no extra indentation. */
        .ignoreValue()

/**
 * A version of [fromCo] to use when the [Job] is needed (e.g. to be able to cancel it).
 */
fun <T> SuspendCallbackNoError<T>.fromCoNoErrorGetJob(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    coroutine: suspend CoroutineScope.() -> T,
): Job =
    (scope ?: getGlobalScopeWithContext()).launch(
        start = start,
    ) {
        this@fromCoNoErrorGetJob(coroutine())
    }

/**
 * A version of [fromCoWithTelemetryLoggingErrors] which returns the job,
 * so that it can be cancelled or inspected for progress
 */
internal fun <T> Callback<T>.fromCoWithTelemetryLoggingErrorsGetJob(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    telemetryEventName: String,
    sourceAreaId: String = telemetryEventName,
    shouldSilenceError: (error: SDKError) -> Boolean = { false },
    block: suspend CoroutineScope.(TelemetryEventBuilder) -> Result<T>,
): Job =
    fromCoWithErrorLoggingGetJob(
        scope = scope,
        start = start,
        sourceAreaId = sourceAreaId,
        shouldSilenceError = shouldSilenceError,
    ) {
        withTelemetryInContext(
            telemetryEventName = telemetryEventName,
        ) {
            block(it)
        }
    }

/**
 * [fromCoWithErrorLogging] plus [withTelemetry], saving one level of indentation.
 */
internal fun <T> Callback<T>.fromCoWithTelemetryLoggingErrors(
    scope: CoroutineScope? = null,
    start: CoroutineStart = defaultCoroutineStart,
    telemetryEventName: String,
    sourceAreaId: String = telemetryEventName,
    shouldSilenceError: (error: SDKError) -> Boolean = { false },
    block: suspend CoroutineScope.(TelemetryEventBuilder) -> Result<T>,
) = fromCoWithTelemetryLoggingErrorsGetJob(
    scope = scope,
    start = start,
    telemetryEventName = telemetryEventName,
    sourceAreaId = sourceAreaId,
    shouldSilenceError = shouldSilenceError,
    block = block,
).ignoreValue()

/**
 * A version of [fromCo] that doesn't spawn a coroutine. For use when the implementation doesn't need suspend calls.
 */
internal inline fun <T> Callback<T>.fromBlock(block: () -> Result<T>) {
    this@fromBlock(runCatching(block).flatten())
}

/**
 * Allows to easily expose a [Flow] as a function of a signature:
 * ```kotlin
 * fun subscribeAndGetCancel(receiveItem: Callback<T>): Destructor
 * ```
 * NOTE: that the `receiveItem` is a [Callback]. There's also a version of this function with [CallbackNoError]
 * see #ErrorAndNoErrorMultiShotFromFlowIn.
 *
 * Through its documentation (read `NOTE:`s below), and parameters like [notifyOfFlowCancel], it tries to ensure
 * informed decisions about behavior on error, also suggesting alternatives.
 *
 * The function a new coroutine in [scope] that will treat the [this] callback as a multi-shot callback and collect the
 * [flow] into it, making sure the callback also gets notified of a terminal error.
 *
 * NOTE: First call of [this] callback with an error is also a terminating one (there will be no more calls).
 *
 * NOTE: With the [this] being of type [Callback] that can accept [Result.Failure], it will also receive errors, so that
 * the consumer decides whether to e.g. log them (they will not be logged otherwise).
 * As such, consider if the use case really needs to control the errors. Use [CallbackNoError] type for your callback if
 * it doesn't need to control errors, so they are passed to the infrastructure to be reported to developers.
 *
 * @return A [Destructible] that can be used to disconnect the callback, stopping the notifications of it. The same
 * will happen if the [scope] is cancelled or the [flow] finishes, whether successfully or due to an error (see also
 * [notifyOfFlowCancel] for what happens in the error scenarios).
 */
internal fun <T> Callback<T>.multiShotFromFlowIn(
    flow: Flow<Result<T>>,
    scope: CoroutineScope,
    start: CoroutineStart = defaultCoroutineStart,
    /**
     * Whether to notify the callback of cancellations that come from the [flow] itself.
     *
     * NOTE: As per the most common scenario, the function does not communicate cancellations from
     * [Destructible.destroy] on the return value, or cancellation of [scope] (these typically happen to clean-up child
     * coroutines on a cancel of the encompassing object), because they are deemed to be caused by the SDK consumer.
     *
     * NOTE: When `true` is chosen, the consumer can still detect if the result is such a cancellation using [com.speechify.client.api.util.boundary.deferred.CancellationUtils.isCancellationResult].
     */
    notifyOfFlowCancel: Boolean,
): Destructible =
    scope.launch(
        start = start,
    ) {
        try {
            flow
                .collect {
                    this@multiShotFromFlowIn(it)
                }
        } catch (ex: Exception) {
            if (ex is CancellationException) {
                if (
                    coroutineContext.job.isCancelled || // `job.isCancelled` will also be true when `scope` is cancelled
                    !notifyOfFlowCancel
                ) {
                    /* If the cancellation came from a `destroy` or `notifyOfFlowCancel` is `false`, then
                     we can just let the infrastructure ignore it */
                    throw ex
                }
                /* But if the cancellation hasn't come from `destroy`, then it came from the flow, and
                   `notifyOfFlowCancel` true means we have yet to communicate the cancellation to the callback, so
                    we continue:
                 */
            }

            // Unknown exceptions, or yet-unnoticed cancellations:
            this@multiShotFromFlowIn(ex.toResultFailure())
            // Not rethrowing, as it was given to the callback to decide its fate
        }
    }
        .toDestructible()

/**
 * A variant of [multiShotFromFlowIn] with [flow] being directly of type [T] and not "[Result] of [T]".
 * See [multiShotFromFlowIn] for documentation.
 */
@JvmName("multiShotFromFlowInOnPlainFlow")
internal fun <T> Callback<T>.multiShotFromFlowIn(
    flow: Flow<T>,
    scope: CoroutineScope,
    start: CoroutineStart = defaultCoroutineStart,
    /**
     * See [multiShotFromFlowIn] for documentation.
     */
    notifyOfFlowCancel: Boolean,
): Destructible =
    multiShotFromFlowIn(
        flow = flow.map { it.successfully() },
        scope = scope,
        start = start,
        notifyOfFlowCancel = notifyOfFlowCancel,
    )

/**
 * Allows to easily expose a [Flow] as a function of a signature:
 * ```kotlin
 * fun subscribeAndGetCancel(receiveItem: CallbackNoError<T>): Destructor
 * ```
 * NOTE: that the `receiveItem` is a [CallbackNoError]. There's also a version of this function with [Callback]
 * see #ErrorAndNoErrorMultiShotFromFlowIn.
 * The signatures that this function covers are like the SDK's [com.speechify.client.internal.util.collections.flows.CallbackBasedProducer], but where such a type
 * wasn't used and instead a bespoke function with own name was exposed).
 *
 * It ensures that relevant errors are reported to developers (e.g. doesn't report cancellations).
 *
 * The function starts a new coroutine in [scope] that will treat the [this] callback as a multi-shot callback, collecting the
 * [flow] by notifying it.
 *
 * NOTE: With the [CallbackNoError] being the [this] callback type, there's no way to have the callback control the
 * errors or report them, so consider if the use case needs such control (use [Callback] type if it does). Here, the
 * errors will just be passed to the infrastructure to be reported to developers.
 *
 * @return A [Destructible] that can be used to disconnect the callback, stopping the notifications of it. The same
 * will happen if the [scope] is cancelled or the [flow] finishes, whether successfully or due to an error.
 */
internal fun <T> CallbackNoError<T>.multiShotFromFlowIn(
    flow: Flow<T>,
    scope: CoroutineScope,
    start: CoroutineStart = defaultCoroutineStart,
): Destructible =
    scope.launch(
        start = start,
    ) {
        flow
            .collect {
                this@multiShotFromFlowIn(it)
            }
    }
        .toDestructible()

internal fun <T> CallbackNoError<T>.multiShotFromSuspendFlowIn(
    flowProvider: suspend () -> Flow<T>,
    scope: CoroutineScope,
    start: CoroutineStart = defaultCoroutineStart,
): Destructible =
    scope.launch(
        start = start,
    ) {
        val flow = flowProvider()
        flow.collect {
            this@multiShotFromSuspendFlowIn(it)
        }
    }
        .toDestructible()

/**
 * For debugging, sometimes [CoroutineStart.UNDISPATCHED] gives a better stack trace (if exception is thrown before
 * suspension point).
 */
private val defaultCoroutineStart = CoroutineStart.DEFAULT

/**
 * Wraps the callback so any errors thrown inside are caught and logged instead of propagated to the caller.
 * Use this for callbacks that you pass to the SDK consumer, so that we can be notified about crashes in the callback
 * and not crash the application.
 */
internal fun <T> Callback<T>.catchingErrors(
    /**
     * The [DiagnosticEvent.sourceAreaId] to use for logging, to be able to identify the source of the error.
     */
    sourceAreaId: String,
    /**
     * See parameter of the same name in [runCatchingSilencingErrorsLoggingThem] for documentation.
     */
    shouldIgnoreCancellationExceptions: Boolean,
    /**
     * The [DiagnosticEvent.properties] to add to the error.
     */
    properties: Map<String, Any>? = null,
    /**
     * You can entirely override the logging behavior by providing this function.
     * It effectively makes this function purpose just grouping similar cases where we are preventing a throw at
     * all costs (e.g. because the caller does not expect an error and will crash otherwise).
     */
    overrideLogging: ((exception: Throwable, sourceAreaId: String, properties: Map<String, Any>?) -> Unit)? = null,
): Callback<T> =
    { result ->
        runCatchingSilencingErrorsLoggingThem(
            sourceAreaId = sourceAreaId,
            shouldIgnoreCancellationExceptions = shouldIgnoreCancellationExceptions,
            properties = properties,
            overrideLogging = overrideLogging,
        ) {
            this(result)
        }
    }

fun <T>Callback<T>.toNoError(): CallbackNoError<T> {
    return {
        this(it.successfully())
    }
}
fun <T>CallbackNoError<T>.toCallbackIgnoringErrors(): Callback<T> {
    return {
        it.ifSuccessful { value -> this(value) }
    }
}
