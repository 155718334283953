package com.speechify.client.reader.core

import com.speechify.client.api.content.startofmaincontent.StartOfMainContent
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterIsInstance
import kotlin.js.JsExport

@JsExport
class ContextualActionHelper internal constructor(
    scope: CoroutineScope,
    playbackStateFlow: Flow<PlaybackState>,
    focusStateFlow: Flow<FocusState.Ready>,
    startOfMainContentFlow: Flow<StartOfMainContent?>,
) : Helper<ContextualActionView>(scope = scope) {

    override val stateFlow = combine(
        playbackStateFlow,
        focusStateFlow.filterIsInstance<FocusState.Ready>(),
        startOfMainContentFlow,
    ) { playbackState, focusState, startOfMainContent ->
        ContextualActionView(
            availableActions = createAvailableContextualActions(
                playbackState = playbackState,
                focusState = focusState,
                startOfMainContent = startOfMainContent,
            ),
        )
    }.stateInHelper(initialValue = ContextualActionView())

    override val initialState = stateFlow.value

    private fun createAvailableContextualActions(
        playbackState: PlaybackState,
        focusState: FocusState.Ready,
        startOfMainContent: StartOfMainContent?,
    ) = buildList {
        createSkipToMainContentAction(playbackState, startOfMainContent)?.let { add(it) }
        createQuickBackAction(playbackState, focusState)?.let { add(it) }
    }.toTypedArray()

    private fun createSkipToMainContentAction(
        playbackState: PlaybackState,
        startOfMainContent: StartOfMainContent?,
    ) = if (startOfMainContent is StartOfMainContent.Ready && playbackState.location.cursor.isBefore(
            startOfMainContent.cursor,
        )
    ) {
        ContextualAction.SkipToMainContent(
            dispatch = dispatch,
            start = RobustLocation(
                hack = SerialLocation(cursor = startOfMainContent.cursor),
            ),
        )
    } else {
        null
    }

    private fun createQuickBackAction(
        playbackState: PlaybackState,
        focusState: FocusState.Ready,
    ) = when {
        playbackState.location.cursor.isBefore(focusState.start.cursor) ->
            ContextualAction.QuickBack.RelativePlaybackLocation.BeforeFocus
        playbackState.location.cursor.isAfter(focusState.end.cursor) ->
            ContextualAction.QuickBack.RelativePlaybackLocation.AfterFocus
        else -> null
    }?.let {
        ContextualAction.QuickBack(
            dispatch = dispatch,
            relativePlaybackLocation = it,
        )
    }
}

@JsExport
data class ContextualActionView internal constructor(
    val availableActions: Array<ContextualAction> = emptyArray(),
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ContextualActionView
        return availableActions.contentEquals(other.availableActions)
    }

    override fun hashCode(): Int = availableActions.contentHashCode()
}

@JsExport
sealed class ContextualAction {
    abstract fun execute()

    data class SkipToMainContent internal constructor(
        private val dispatch: CommandDispatch,
        private val start: RobustLocation,
    ) : ContextualAction() {
        override fun execute() {
            dispatch(PlaybackCommand.SkipToMainContent(location = start.hack))
        }
    }

    /**
     * The "QuickBack" UX is a CTA that appears when the current playback location is outside the viewport of the reader.
     *
     * Successfully implementing the [QuickBack] UX is a bit subtle, because the SDK does not have fine-grained
     * information about the positioning of specific UI elements in the reader viewport.
     *
     * When the [QuickBack] contextual action is present, this means that the current playback position is not within
     * any of the content elements (ClassicBlocks, FixedLayoutPages, etc) that are currently loaded in the main Reader
     * view. As such, the contextual action here is the only way for you to know where it is, and we provide the
     * "relative location" so you know whether your UI should indicate that it is "before" or "after" the current
     * reading location.
     *
     * When the [QuickBack] contextual action is NOT present, this means the current playback position is SOMEWHERE
     * within the content elements (ClassicBlocks, FixedLayoutPages, etc) that are currently loaded in the main Reader
     * view. It is then the client's responsibility to use a UI component intersection method to find out whether
     * the currently-playing word (see `ClassicTextFeatures.wordHighlight`, `FixedLayoutPageFeatures.wordHighlight`) is
     * inside or outside the viewport and display the appropriate UI.
     */
    data class QuickBack internal constructor(
        private val dispatch: CommandDispatch,
        val relativePlaybackLocation: RelativePlaybackLocation,
    ) : ContextualAction() {
        override fun execute() {
            dispatch(
                NavigationCommand.NavigateTo(
                    NavigationIntent.GoToCurrentPlaybackLocation(),
                ),
            )
        }

        sealed class RelativePlaybackLocation {
            object BeforeFocus : RelativePlaybackLocation()
            object AfterFocus : RelativePlaybackLocation()
        }
    }
}
