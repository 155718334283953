package com.speechify.client.bundlers

import com.speechify.client.bundlers.content.ContentBundlerConfig
import com.speechify.client.bundlers.listening.ListeningBundlerConfig
import com.speechify.client.bundlers.reading.book.BookReadingBundlerConfig
import com.speechify.client.bundlers.reading.classic.ClassicReadingBundlerConfig
import com.speechify.client.bundlers.reading.embedded.EmbeddedReadingBundlerConfig
import kotlin.js.JsExport

/**
 * Configuration for initializing the [BundlerFactory]
 */
@JsExport
data class BundlerFactoryConfig(
    val classicReadingBundlerConfig: ClassicReadingBundlerConfig,
    val bookReadingBundlerConfig: BookReadingBundlerConfig,
    val embeddedReadingBundlerConfig: EmbeddedReadingBundlerConfig,
    val listeningBundlerConfig: ListeningBundlerConfig,
    val contentBundlerConfig: ContentBundlerConfig,
)
