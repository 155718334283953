// ktlint-disable filename
package com.speechify.client.internal.util

/**
 * Encodes a string to be used as a [text node](https://developer.mozilla.org/en-US/docs/Web/API/Text) in an HTML
 * document.
 *
 * (NOTE - don't use for [attribute nodes](https://developer.mozilla.org/en-US/docs/Web/API/Attr), as it doesn't encode
 * quotes)
 */
internal inline fun CharSequence.encodeToXmlTextNode(
    /**
     * Use to add custom logic when a character is replaced.
     */
    crossinline onReplace: (original: MatchResult, replaceWith: String) -> Unit = { _, _ -> },
): String =
    /* Use a single `replace`, to reduce allocations of memory for the intermediate strings and number of search
       passes
     */
    replace(
        regex = textNodeCharsToEscapeRegex,
        transform = { matchResult ->
            val targetValue = textNodeEscapeRules[matchResult.value]
                ?: throw IllegalStateException("Unexpected match result: $matchResult") /* Impossible unless there's
                a bug in this very file */
            onReplace(matchResult, targetValue)
            targetValue
        },
    )

private val textNodeEscapeRules = mapOf(
    // Based on https://stackoverflow.com/questions/7381974/which-characters-need-to-be-escaped-in-html
    "&" to "&amp;",
    "<" to "&lt;",
    ">" to "&gt;",
)

// Store the regex to reuse any compilation work
private val textNodeCharsToEscapeRegex = Regex(textNodeEscapeRules.keys.joinToString(separator = "|"))

/**
 * NOTE: This must be used inside attribute values quoted with `"` (not included here), because it doesn't encode single
 * quotes.
 */
internal fun String.encodeForDoubleQuotedXmlAttributeValue(): String =
    replace(
        regex = xmlAttributeValueDoubleQuotedCharsToEscapeRegex,
        transform = { matchResult ->
            val targetValue = xmlAttributeValueDoubleQuotedEscapeRules[matchResult.value]
                /* Impossible unless there's a bug in this very file */
                ?: throw IllegalStateException("Unexpected match result: $matchResult")
            targetValue
        },
    )
private val xmlAttributeValueDoubleQuotedEscapeRules = mapOf(
    // Based on https://stackoverflow.com/questions/9187946/escaping-inside-html-tag-attribute-value/9189067#9189067
    "&" to "&amp;",
    "<" to "&lt;",
    "\"" to "&quot;",
)

// Store the regex to reuse any compilation work
private val xmlAttributeValueDoubleQuotedCharsToEscapeRegex =
    Regex(xmlAttributeValueDoubleQuotedEscapeRules.keys.joinToString(separator = "|"))
