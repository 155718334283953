package com.speechify.client.api.util

import com.speechify.client.api.adapters.firebase.DocumentChange
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreQuerySnapshot
import com.speechify.client.internal.util.collections.flows.FlowThatFinishesOnlyThroughCollectionCancel
import com.speechify.client.internal.util.collections.flows.flowFromCallbackProducer
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.transform

internal interface IFirestoreRecordUpdatesFlowFactory {
    fun create(): Flow<List<DocumentChange>>
}

internal class FirestoreRecordUpdatesFlowFactory internal constructor(
    private val observeImpl: (
        DocumentQueryBuilder.DocumentQuery,
        Callback<FirebaseFirestoreQuerySnapshot>,
    ) -> Destructor,
    private val query: DocumentQueryBuilder.DocumentQuery,
) : IFirestoreRecordUpdatesFlowFactory {
    override fun create(): Flow<List<DocumentChange>> {
        val snapshotFlow = observeAsFlow(
            observeImpl = observeImpl,
            query = query,
            sourceAreaId = "FirestoreRecordUpdatesFlowFactory",
        )
        return snapshotFlow.transform { snapshotResult ->
            val snapshot = snapshotResult.orThrow()
            emit(snapshot.docChanges(includeMetadataChanges = null).toList())
        }
    }
}

// Borrowed from [FirebaseFirestoreService] (https://github.com/SpeechifyInc/multiplatform-sdk/blob/ea606ebdbf9392dc3f023c084b2c2f3e0f4bf1dc/multiplatform-sdk/src/commonMain/kotlin/com/speechify/client/api/adapters/firebase/FirebaseFirestoreService.kt#L454-L454)
// though it's coupled with QueryBuilder so lifting it out fow now.
private fun observeAsFlow(
    sourceAreaId: String,
    diagnosticProperties: Map<String, Any>? = null,
    observeImpl: (DocumentQueryBuilder.DocumentQuery, Callback<FirebaseFirestoreQuerySnapshot>) -> Destructor,
    query: DocumentQueryBuilder.DocumentQuery,
): FlowThatFinishesOnlyThroughCollectionCancel<Result<FirebaseFirestoreQuerySnapshot>> =
    flowFromCallbackProducer(
        callbackBasedProducer = { callback ->
            observeImpl(query, callback)
        },
        shouldLogErrorIfCancellationPreventedDelivery = false,
        sourceAreaId = sourceAreaId,
        diagnosticProperties = mapOf(
            "query" to query.toString(),
        ) + (diagnosticProperties ?: emptyMap()),
    )
