package com.speechify.client.api.adapters.encription

import com.speechify.client.api.util.Result
import okio.ByteString.Companion.encodeUtf8
import kotlin.js.JsExport

@JsExport
abstract class EncryptionAdapter {
    internal fun sha256(value: String): ByteArray {
        return value.encodeUtf8().sha256().toByteArray()
    }

    abstract fun encryptWithAes256cbc(data: ByteArray, key: ByteArray): Result<ByteArray>

    abstract fun decryptAes256cbc(data: ByteArray, key: ByteArray): Result<ByteArray>
}
