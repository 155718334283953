package com.speechify.client.api.content.view.epub

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.epub.Epub
import com.speechify.client.api.content.epub.EpubNavPoint
import com.speechify.client.api.content.epub.EpubNavigation
import com.speechify.client.api.content.view.web.WebPage
import com.speechify.client.api.content.view.web.WebPageElementAttribute
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.buildChildren
import com.speechify.client.api.content.view.web.getAttribute

internal class EpubViewV1(
    private val epub: Epub,
) : EpubViewAsWebPage {
    // TODO(anson): this is smelly - if we can statically initialize this, why do we need it at all?
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end
    override val sourceUrl: String? get() = null

    override fun getWebPage(): WebPage {
        val element = epub.htmlContent
        val builder = WebPageNode.builder(
            element.tagName,
            element.attributes.map { WebPageElementAttribute(it) },
            ObjectRef(element.rawRefObject),
        ) {
            this.buildChildren(element)
        }

        val root = builder.build()

        return WebPage(
            root = root,
            sourceUrl = sourceUrl,
            tableOfContents = epub.navigation?.toTableOfContents(root = root),
        )
    }
}

private fun EpubNavigation.toTableOfContents(
    root: WebPageNode.Element,
): TableOfContents {
    val idToNodeMap = mutableMapOf<String, WebPageNode>()

    fun populateIdMap(node: WebPageNode) {
        when (node) {
            is WebPageNode.Element -> {
                node.getAttribute("id")?.let { id ->
                    idToNodeMap[id] = node
                }
                node.children.forEach { populateIdMap(it) }
            }
            is WebPageNode.Text -> {} // Do nothing for text nodes
        }
    }

    populateIdMap(root)

    fun mapNavPoint(navPoint: EpubNavPoint, level: Int): TableOfContents.Entry? {
        val node = idToNodeMap[navPoint.id]
        return TableOfContents.Entry(
            content = TableOfContents.Entry.Content.Single(
                TableOfContents.Entry.Section(
                    title = navPoint.label,
                    hierarchyLevel = level,
                ),
            ),
            start = node?.start.let { TableOfContents.Entry.Start.Resolved(cursor = node?.start ?: return null) },
            attributes = TableOfContents.Entry.Attributes(targetPageIndex = null),
        )
    }

    fun traverseNavPoints(navPoints: List<EpubNavPoint>, level: Int): List<TableOfContents.Entry> {
        return navPoints.flatMap { navPoint ->
            listOfNotNull(mapNavPoint(navPoint, level)) + traverseNavPoints(navPoint.children, level + 1)
        }
    }

    val entries = traverseNavPoints(tocNavPoints, 1)
    return TableOfContents(entries)
}
