package com.speechify.client.internal.text

/**
 * The set of bidirectional character types, which govern how characters influence the layout of surrounding text.
 *
 * The types are defined [here](https://unicode.org/reports/tr9/#Table_Bidirectional_Character_Types).
 */
internal enum class CharDirectionality {
    Neutral,
    Weak,
    StrongLeftToRight,
    StrongRightToLeft,
    ExplicitFormatting,
}

/**
 * Returns this character's bidirectional character type, which governs how this character influences the layout of surrounding text.
 *
 * The types are defined [here](https://unicode.org/reports/tr9/#Table_Bidirectional_Character_Types), and assigned to characters in the [authoritative database](https://www.unicode.org/Public/UCD/latest/ucd/UnicodeData.txt).
 */
internal val Char.directionality: CharDirectionality
    get() = directionalTypeRanges.find { it.first.contains(this.code) }?.second ?: CharDirectionality.StrongLeftToRight

/*
 * Compress unicode ranges with common bidirectional class to aid lookup of code -> class
 * Generated by generate_unicode_directionality_range_list.py.
 */
private val directionalTypeRanges: List<Pair<IntRange, CharDirectionality>> = listOf(
/* <control> ... <control> */
    0x0000..0x0008 to CharDirectionality.Weak,

/* <control> ... <control> */
    0x0009..0x000D to CharDirectionality.Neutral,

/* <control> ... <control> */
    0x000E..0x001B to CharDirectionality.Weak,

/* <control> ... QUOTATION MARK */
    0x001C..0x0022 to CharDirectionality.Neutral,

/* NUMBER SIGN ... PERCENT SIGN */
    0x0023..0x0025 to CharDirectionality.Weak,

/* AMPERSAND ... ASTERISK */
    0x0026..0x002A to CharDirectionality.Neutral,

/* PLUS SIGN ... COLON */
    0x002B..0x003A to CharDirectionality.Weak,

/* SEMICOLON ... COMMERCIAL AT */
    0x003B..0x0040 to CharDirectionality.Neutral,

/* LATIN CAPITAL LETTER A ... LATIN CAPITAL LETTER Z */
    0x0041..0x005A to CharDirectionality.StrongLeftToRight,

/* LEFT SQUARE BRACKET ... GRAVE ACCENT */
    0x005B..0x0060 to CharDirectionality.Neutral,

/* LATIN SMALL LETTER A ... LATIN SMALL LETTER Z */
    0x0061..0x007A to CharDirectionality.StrongLeftToRight,

/* LEFT CURLY BRACKET ... TILDE */
    0x007B..0x007E to CharDirectionality.Neutral,

/* <control> ... <control> */
    0x007F..0x0084 to CharDirectionality.Weak,

/* <control> ... <control> */
    0x0085..0x0085 to CharDirectionality.Neutral,

/* <control> ... NO-BREAK SPACE */
    0x0086..0x00A0 to CharDirectionality.Weak,

/* INVERTED EXCLAMATION MARK ... INVERTED EXCLAMATION MARK */
    0x00A1..0x00A1 to CharDirectionality.Neutral,

/* CENT SIGN ... YEN SIGN */
    0x00A2..0x00A5 to CharDirectionality.Weak,

/* BROKEN BAR ... COPYRIGHT SIGN */
    0x00A6..0x00A9 to CharDirectionality.Neutral,

/* FEMININE ORDINAL INDICATOR ... FEMININE ORDINAL INDICATOR */
    0x00AA..0x00AA to CharDirectionality.StrongLeftToRight,

/* LEFT-POINTING DOUBLE ANGLE QUOTATION MARK ... NOT SIGN */
    0x00AB..0x00AC to CharDirectionality.Neutral,

/* SOFT HYPHEN ... SOFT HYPHEN */
    0x00AD..0x00AD to CharDirectionality.Weak,

/* REGISTERED SIGN ... MACRON */
    0x00AE..0x00AF to CharDirectionality.Neutral,

/* DEGREE SIGN ... SUPERSCRIPT THREE */
    0x00B0..0x00B3 to CharDirectionality.Weak,

/* ACUTE ACCENT ... ACUTE ACCENT */
    0x00B4..0x00B4 to CharDirectionality.Neutral,

/* MICRO SIGN ... MICRO SIGN */
    0x00B5..0x00B5 to CharDirectionality.StrongLeftToRight,

/* PILCROW SIGN ... CEDILLA */
    0x00B6..0x00B8 to CharDirectionality.Neutral,

/* SUPERSCRIPT ONE ... SUPERSCRIPT ONE */
    0x00B9..0x00B9 to CharDirectionality.Weak,

/* MASCULINE ORDINAL INDICATOR ... MASCULINE ORDINAL INDICATOR */
    0x00BA..0x00BA to CharDirectionality.StrongLeftToRight,

/* RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK ... INVERTED QUESTION MARK */
    0x00BB..0x00BF to CharDirectionality.Neutral,

/* LATIN CAPITAL LETTER A WITH GRAVE ... LATIN CAPITAL LETTER O WITH DIAERESIS */
    0x00C0..0x00D6 to CharDirectionality.StrongLeftToRight,

/* MULTIPLICATION SIGN ... MULTIPLICATION SIGN */
    0x00D7..0x00D7 to CharDirectionality.Neutral,

/* LATIN CAPITAL LETTER O WITH STROKE ... LATIN SMALL LETTER O WITH DIAERESIS */
    0x00D8..0x00F6 to CharDirectionality.StrongLeftToRight,

/* DIVISION SIGN ... DIVISION SIGN */
    0x00F7..0x00F7 to CharDirectionality.Neutral,

/* LATIN SMALL LETTER O WITH STROKE ... MODIFIER LETTER SMALL Y */
    0x00F8..0x02B8 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER PRIME ... MODIFIER LETTER DOUBLE PRIME */
    0x02B9..0x02BA to CharDirectionality.Neutral,

/* MODIFIER LETTER TURNED COMMA ... MODIFIER LETTER REVERSED GLOTTAL STOP */
    0x02BB..0x02C1 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER LEFT ARROWHEAD ... MODIFIER LETTER LOW ACUTE ACCENT */
    0x02C2..0x02CF to CharDirectionality.Neutral,

/* MODIFIER LETTER TRIANGULAR COLON ... MODIFIER LETTER HALF TRIANGULAR COLON */
    0x02D0..0x02D1 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER CENTRED RIGHT HALF RING ... MODIFIER LETTER CROSS ACCENT */
    0x02D2..0x02DF to CharDirectionality.Neutral,

/* MODIFIER LETTER SMALL GAMMA ... MODIFIER LETTER SMALL REVERSED GLOTTAL STOP */
    0x02E0..0x02E4 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER EXTRA-HIGH TONE BAR ... MODIFIER LETTER UNASPIRATED */
    0x02E5..0x02ED to CharDirectionality.Neutral,

/* MODIFIER LETTER DOUBLE APOSTROPHE ... MODIFIER LETTER DOUBLE APOSTROPHE */
    0x02EE..0x02EE to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER LOW DOWN ARROWHEAD ... MODIFIER LETTER LOW LEFT ARROW */
    0x02EF..0x02FF to CharDirectionality.Neutral,

/* COMBINING GRAVE ACCENT ... COMBINING LATIN SMALL LETTER X */
    0x0300..0x036F to CharDirectionality.Weak,

/* GREEK CAPITAL LETTER HETA ... GREEK SMALL LETTER ARCHAIC SAMPI */
    0x0370..0x0373 to CharDirectionality.StrongLeftToRight,

/* GREEK NUMERAL SIGN ... GREEK LOWER NUMERAL SIGN */
    0x0374..0x0375 to CharDirectionality.Neutral,

/* GREEK CAPITAL LETTER PAMPHYLIAN DIGAMMA ... GREEK SMALL REVERSED DOTTED LUNATE SIGMA SYMBOL */
    0x0376..0x037D to CharDirectionality.StrongLeftToRight,

/* GREEK QUESTION MARK ... GREEK QUESTION MARK */
    0x037E..0x037E to CharDirectionality.Neutral,

/* GREEK CAPITAL LETTER YOT ... GREEK CAPITAL LETTER YOT */
    0x037F..0x037F to CharDirectionality.StrongLeftToRight,

/* GREEK TONOS ... GREEK DIALYTIKA TONOS */
    0x0384..0x0385 to CharDirectionality.Neutral,

/* GREEK CAPITAL LETTER ALPHA WITH TONOS ... GREEK CAPITAL LETTER ALPHA WITH TONOS */
    0x0386..0x0386 to CharDirectionality.StrongLeftToRight,

/* GREEK ANO TELEIA ... GREEK ANO TELEIA */
    0x0387..0x0387 to CharDirectionality.Neutral,

/* GREEK CAPITAL LETTER EPSILON WITH TONOS ... GREEK LUNATE EPSILON SYMBOL */
    0x0388..0x03F5 to CharDirectionality.StrongLeftToRight,

/* GREEK REVERSED LUNATE EPSILON SYMBOL ... GREEK REVERSED LUNATE EPSILON SYMBOL */
    0x03F6..0x03F6 to CharDirectionality.Neutral,

/* GREEK CAPITAL LETTER SHO ... CYRILLIC THOUSANDS SIGN */
    0x03F7..0x0482 to CharDirectionality.StrongLeftToRight,

/* COMBINING CYRILLIC TITLO ... COMBINING CYRILLIC MILLIONS SIGN */
    0x0483..0x0489 to CharDirectionality.Weak,

/* CYRILLIC CAPITAL LETTER SHORT I WITH TAIL ... ARMENIAN FULL STOP */
    0x048A..0x0589 to CharDirectionality.StrongLeftToRight,

/* ARMENIAN HYPHEN ... LEFT-FACING ARMENIAN ETERNITY SIGN */
    0x058A..0x058E to CharDirectionality.Neutral,

/* ARMENIAN DRAM SIGN ... HEBREW POINT METEG */
    0x058F..0x05BD to CharDirectionality.Weak,

/* HEBREW PUNCTUATION MAQAF ... HEBREW PUNCTUATION MAQAF */
    0x05BE..0x05BE to CharDirectionality.StrongRightToLeft,

/* HEBREW POINT RAFE ... HEBREW POINT RAFE */
    0x05BF..0x05BF to CharDirectionality.Weak,

/* HEBREW PUNCTUATION PASEQ ... HEBREW PUNCTUATION PASEQ */
    0x05C0..0x05C0 to CharDirectionality.StrongRightToLeft,

/* HEBREW POINT SHIN DOT ... HEBREW POINT SIN DOT */
    0x05C1..0x05C2 to CharDirectionality.Weak,

/* HEBREW PUNCTUATION SOF PASUQ ... HEBREW PUNCTUATION SOF PASUQ */
    0x05C3..0x05C3 to CharDirectionality.StrongRightToLeft,

/* HEBREW MARK UPPER DOT ... HEBREW MARK LOWER DOT */
    0x05C4..0x05C5 to CharDirectionality.Weak,

/* HEBREW PUNCTUATION NUN HAFUKHA ... HEBREW PUNCTUATION NUN HAFUKHA */
    0x05C6..0x05C6 to CharDirectionality.StrongRightToLeft,

/* HEBREW POINT QAMATS QATAN ... HEBREW POINT QAMATS QATAN */
    0x05C7..0x05C7 to CharDirectionality.Weak,

/* HEBREW LETTER ALEF ... HEBREW PUNCTUATION GERSHAYIM */
    0x05D0..0x05F4 to CharDirectionality.StrongRightToLeft,

/* ARABIC NUMBER SIGN ... ARABIC NUMBER MARK ABOVE */
    0x0600..0x0605 to CharDirectionality.Weak,

/* ARABIC-INDIC CUBE ROOT ... ARABIC-INDIC FOURTH ROOT */
    0x0606..0x0607 to CharDirectionality.Neutral,

/* ARABIC RAY ... ARABIC RAY */
    0x0608..0x0608 to CharDirectionality.StrongRightToLeft,

/* ARABIC-INDIC PER MILLE SIGN ... ARABIC-INDIC PER TEN THOUSAND SIGN */
    0x0609..0x060A to CharDirectionality.Weak,

/* AFGHANI SIGN ... AFGHANI SIGN */
    0x060B..0x060B to CharDirectionality.StrongRightToLeft,

/* ARABIC COMMA ... ARABIC COMMA */
    0x060C..0x060C to CharDirectionality.Weak,

/* ARABIC DATE SEPARATOR ... ARABIC DATE SEPARATOR */
    0x060D..0x060D to CharDirectionality.StrongRightToLeft,

/* ARABIC POETIC VERSE SIGN ... ARABIC SIGN MISRA */
    0x060E..0x060F to CharDirectionality.Neutral,

/* ARABIC SIGN SALLALLAHOU ALAYHE WASSALLAM ... ARABIC SMALL KASRA */
    0x0610..0x061A to CharDirectionality.Weak,

/* ARABIC SEMICOLON ... ARABIC LETTER YEH */
    0x061B..0x064A to CharDirectionality.StrongRightToLeft,

/* ARABIC FATHATAN ... ARABIC THOUSANDS SEPARATOR */
    0x064B..0x066C to CharDirectionality.Weak,

/* ARABIC FIVE POINTED STAR ... ARABIC LETTER DOTLESS QAF */
    0x066D..0x066F to CharDirectionality.StrongRightToLeft,

/* ARABIC LETTER SUPERSCRIPT ALEF ... ARABIC LETTER SUPERSCRIPT ALEF */
    0x0670..0x0670 to CharDirectionality.Weak,

/* ARABIC LETTER ALEF WASLA ... ARABIC LETTER AE */
    0x0671..0x06D5 to CharDirectionality.StrongRightToLeft,

/* ARABIC SMALL HIGH LIGATURE SAD WITH LAM WITH ALEF MAKSURA ... ARABIC END OF AYAH */
    0x06D6..0x06DD to CharDirectionality.Weak,

/* ARABIC START OF RUB EL HIZB ... ARABIC START OF RUB EL HIZB */
    0x06DE..0x06DE to CharDirectionality.Neutral,

/* ARABIC SMALL HIGH ROUNDED ZERO ... ARABIC SMALL HIGH MADDA */
    0x06DF..0x06E4 to CharDirectionality.Weak,

/* ARABIC SMALL WAW ... ARABIC SMALL YEH */
    0x06E5..0x06E6 to CharDirectionality.StrongRightToLeft,

/* ARABIC SMALL HIGH YEH ... ARABIC SMALL HIGH NOON */
    0x06E7..0x06E8 to CharDirectionality.Weak,

/* ARABIC PLACE OF SAJDAH ... ARABIC PLACE OF SAJDAH */
    0x06E9..0x06E9 to CharDirectionality.Neutral,

/* ARABIC EMPTY CENTRE LOW STOP ... ARABIC SMALL LOW MEEM */
    0x06EA..0x06ED to CharDirectionality.Weak,

/* ARABIC LETTER DAL WITH INVERTED V ... ARABIC LETTER REH WITH INVERTED V */
    0x06EE..0x06EF to CharDirectionality.StrongRightToLeft,

/* EXTENDED ARABIC-INDIC DIGIT ZERO ... EXTENDED ARABIC-INDIC DIGIT NINE */
    0x06F0..0x06F9 to CharDirectionality.Weak,

/* ARABIC LETTER SHEEN WITH DOT BELOW ... SYRIAC LETTER ALAPH */
    0x06FA..0x0710 to CharDirectionality.StrongRightToLeft,

/* SYRIAC LETTER SUPERSCRIPT ALAPH ... SYRIAC LETTER SUPERSCRIPT ALAPH */
    0x0711..0x0711 to CharDirectionality.Weak,

/* SYRIAC LETTER BETH ... SYRIAC LETTER PERSIAN DHALATH */
    0x0712..0x072F to CharDirectionality.StrongRightToLeft,

/* SYRIAC PTHAHA ABOVE ... SYRIAC BARREKH */
    0x0730..0x074A to CharDirectionality.Weak,

/* SYRIAC LETTER SOGDIAN ZHAIN ... THAANA LETTER WAAVU */
    0x074D..0x07A5 to CharDirectionality.StrongRightToLeft,

/* THAANA ABAFILI ... THAANA SUKUN */
    0x07A6..0x07B0 to CharDirectionality.Weak,

/* THAANA LETTER NAA ... NKO LETTER JONA RA */
    0x07B1..0x07EA to CharDirectionality.StrongRightToLeft,

/* NKO COMBINING SHORT HIGH TONE ... NKO COMBINING DOUBLE DOT ABOVE */
    0x07EB..0x07F3 to CharDirectionality.Weak,

/* NKO HIGH TONE APOSTROPHE ... NKO LOW TONE APOSTROPHE */
    0x07F4..0x07F5 to CharDirectionality.StrongRightToLeft,

/* NKO SYMBOL OO DENNEN ... NKO EXCLAMATION MARK */
    0x07F6..0x07F9 to CharDirectionality.Neutral,

/* NKO LAJANYALAN ... NKO LAJANYALAN */
    0x07FA..0x07FA to CharDirectionality.StrongRightToLeft,

/* NKO DANTAYALAN ... NKO DANTAYALAN */
    0x07FD..0x07FD to CharDirectionality.Weak,

/* NKO DOROME SIGN ... SAMARITAN LETTER TAAF */
    0x07FE..0x0815 to CharDirectionality.StrongRightToLeft,

/* SAMARITAN MARK IN ... SAMARITAN MARK DAGESH */
    0x0816..0x0819 to CharDirectionality.Weak,

/* SAMARITAN MODIFIER LETTER EPENTHETIC YUT ... SAMARITAN MODIFIER LETTER EPENTHETIC YUT */
    0x081A..0x081A to CharDirectionality.StrongRightToLeft,

/* SAMARITAN MARK EPENTHETIC YUT ... SAMARITAN VOWEL SIGN A */
    0x081B..0x0823 to CharDirectionality.Weak,

/* SAMARITAN MODIFIER LETTER SHORT A ... SAMARITAN MODIFIER LETTER SHORT A */
    0x0824..0x0824 to CharDirectionality.StrongRightToLeft,

/* SAMARITAN VOWEL SIGN SHORT A ... SAMARITAN VOWEL SIGN U */
    0x0825..0x0827 to CharDirectionality.Weak,

/* SAMARITAN MODIFIER LETTER I ... SAMARITAN MODIFIER LETTER I */
    0x0828..0x0828 to CharDirectionality.StrongRightToLeft,

/* SAMARITAN VOWEL SIGN LONG I ... SAMARITAN MARK NEQUDAA */
    0x0829..0x082D to CharDirectionality.Weak,

/* SAMARITAN PUNCTUATION NEQUDAA ... MANDAIC LETTER AIN */
    0x0830..0x0858 to CharDirectionality.StrongRightToLeft,

/* MANDAIC AFFRICATION MARK ... MANDAIC GEMINATION MARK */
    0x0859..0x085B to CharDirectionality.Weak,

/* MANDAIC PUNCTUATION ... ARABIC VERTICAL TAIL */
    0x085E..0x088E to CharDirectionality.StrongRightToLeft,

/* ARABIC POUND MARK ABOVE ... ARABIC HALF MADDA OVER MADDA */
    0x0890..0x089F to CharDirectionality.Weak,

/* ARABIC LETTER BEH WITH SMALL V BELOW ... ARABIC SMALL FARSI YEH */
    0x08A0..0x08C9 to CharDirectionality.StrongRightToLeft,

/* ARABIC SMALL HIGH FARSI YEH ... DEVANAGARI SIGN ANUSVARA */
    0x08CA..0x0902 to CharDirectionality.Weak,

/* DEVANAGARI SIGN VISARGA ... DEVANAGARI LETTER HA */
    0x0903..0x0939 to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI VOWEL SIGN OE ... DEVANAGARI VOWEL SIGN OE */
    0x093A..0x093A to CharDirectionality.Weak,

/* DEVANAGARI VOWEL SIGN OOE ... DEVANAGARI VOWEL SIGN OOE */
    0x093B..0x093B to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI SIGN NUKTA ... DEVANAGARI SIGN NUKTA */
    0x093C..0x093C to CharDirectionality.Weak,

/* DEVANAGARI SIGN AVAGRAHA ... DEVANAGARI VOWEL SIGN II */
    0x093D..0x0940 to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI VOWEL SIGN U ... DEVANAGARI VOWEL SIGN AI */
    0x0941..0x0948 to CharDirectionality.Weak,

/* DEVANAGARI VOWEL SIGN CANDRA O ... DEVANAGARI VOWEL SIGN AU */
    0x0949..0x094C to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI SIGN VIRAMA ... DEVANAGARI SIGN VIRAMA */
    0x094D..0x094D to CharDirectionality.Weak,

/* DEVANAGARI VOWEL SIGN PRISHTHAMATRA E ... DEVANAGARI OM */
    0x094E..0x0950 to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI STRESS SIGN UDATTA ... DEVANAGARI VOWEL SIGN UUE */
    0x0951..0x0957 to CharDirectionality.Weak,

/* DEVANAGARI LETTER QA ... DEVANAGARI LETTER VOCALIC LL */
    0x0958..0x0961 to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI VOWEL SIGN VOCALIC L ... DEVANAGARI VOWEL SIGN VOCALIC LL */
    0x0962..0x0963 to CharDirectionality.Weak,

/* DEVANAGARI DANDA ... BENGALI ANJI */
    0x0964..0x0980 to CharDirectionality.StrongLeftToRight,

/* BENGALI SIGN CANDRABINDU ... BENGALI SIGN CANDRABINDU */
    0x0981..0x0981 to CharDirectionality.Weak,

/* BENGALI SIGN ANUSVARA ... BENGALI LETTER HA */
    0x0982..0x09B9 to CharDirectionality.StrongLeftToRight,

/* BENGALI SIGN NUKTA ... BENGALI SIGN NUKTA */
    0x09BC..0x09BC to CharDirectionality.Weak,

/* BENGALI SIGN AVAGRAHA ... BENGALI VOWEL SIGN II */
    0x09BD..0x09C0 to CharDirectionality.StrongLeftToRight,

/* BENGALI VOWEL SIGN U ... BENGALI VOWEL SIGN VOCALIC RR */
    0x09C1..0x09C4 to CharDirectionality.Weak,

/* BENGALI VOWEL SIGN E ... BENGALI VOWEL SIGN AU */
    0x09C7..0x09CC to CharDirectionality.StrongLeftToRight,

/* BENGALI SIGN VIRAMA ... BENGALI SIGN VIRAMA */
    0x09CD..0x09CD to CharDirectionality.Weak,

/* BENGALI LETTER KHANDA TA ... BENGALI LETTER VOCALIC LL */
    0x09CE..0x09E1 to CharDirectionality.StrongLeftToRight,

/* BENGALI VOWEL SIGN VOCALIC L ... BENGALI VOWEL SIGN VOCALIC LL */
    0x09E2..0x09E3 to CharDirectionality.Weak,

/* BENGALI DIGIT ZERO ... BENGALI LETTER RA WITH LOWER DIAGONAL */
    0x09E6..0x09F1 to CharDirectionality.StrongLeftToRight,

/* BENGALI RUPEE MARK ... BENGALI RUPEE SIGN */
    0x09F2..0x09F3 to CharDirectionality.Weak,

/* BENGALI CURRENCY NUMERATOR ONE ... BENGALI ISSHAR */
    0x09F4..0x09FA to CharDirectionality.StrongLeftToRight,

/* BENGALI GANDA MARK ... BENGALI GANDA MARK */
    0x09FB..0x09FB to CharDirectionality.Weak,

/* BENGALI LETTER VEDIC ANUSVARA ... BENGALI ABBREVIATION SIGN */
    0x09FC..0x09FD to CharDirectionality.StrongLeftToRight,

/* BENGALI SANDHI MARK ... GURMUKHI SIGN BINDI */
    0x09FE..0x0A02 to CharDirectionality.Weak,

/* GURMUKHI SIGN VISARGA ... GURMUKHI LETTER HA */
    0x0A03..0x0A39 to CharDirectionality.StrongLeftToRight,

/* GURMUKHI SIGN NUKTA ... GURMUKHI SIGN NUKTA */
    0x0A3C..0x0A3C to CharDirectionality.Weak,

/* GURMUKHI VOWEL SIGN AA ... GURMUKHI VOWEL SIGN II */
    0x0A3E..0x0A40 to CharDirectionality.StrongLeftToRight,

/* GURMUKHI VOWEL SIGN U ... GURMUKHI SIGN UDAAT */
    0x0A41..0x0A51 to CharDirectionality.Weak,

/* GURMUKHI LETTER KHHA ... GURMUKHI DIGIT NINE */
    0x0A59..0x0A6F to CharDirectionality.StrongLeftToRight,

/* GURMUKHI TIPPI ... GURMUKHI ADDAK */
    0x0A70..0x0A71 to CharDirectionality.Weak,

/* GURMUKHI IRI ... GURMUKHI EK ONKAR */
    0x0A72..0x0A74 to CharDirectionality.StrongLeftToRight,

/* GURMUKHI SIGN YAKASH ... GURMUKHI SIGN YAKASH */
    0x0A75..0x0A75 to CharDirectionality.Weak,

/* GURMUKHI ABBREVIATION SIGN ... GURMUKHI ABBREVIATION SIGN */
    0x0A76..0x0A76 to CharDirectionality.StrongLeftToRight,

/* GUJARATI SIGN CANDRABINDU ... GUJARATI SIGN ANUSVARA */
    0x0A81..0x0A82 to CharDirectionality.Weak,

/* GUJARATI SIGN VISARGA ... GUJARATI LETTER HA */
    0x0A83..0x0AB9 to CharDirectionality.StrongLeftToRight,

/* GUJARATI SIGN NUKTA ... GUJARATI SIGN NUKTA */
    0x0ABC..0x0ABC to CharDirectionality.Weak,

/* GUJARATI SIGN AVAGRAHA ... GUJARATI VOWEL SIGN II */
    0x0ABD..0x0AC0 to CharDirectionality.StrongLeftToRight,

/* GUJARATI VOWEL SIGN U ... GUJARATI VOWEL SIGN AI */
    0x0AC1..0x0AC8 to CharDirectionality.Weak,

/* GUJARATI VOWEL SIGN CANDRA O ... GUJARATI VOWEL SIGN AU */
    0x0AC9..0x0ACC to CharDirectionality.StrongLeftToRight,

/* GUJARATI SIGN VIRAMA ... GUJARATI SIGN VIRAMA */
    0x0ACD..0x0ACD to CharDirectionality.Weak,

/* GUJARATI OM ... GUJARATI LETTER VOCALIC LL */
    0x0AD0..0x0AE1 to CharDirectionality.StrongLeftToRight,

/* GUJARATI VOWEL SIGN VOCALIC L ... GUJARATI VOWEL SIGN VOCALIC LL */
    0x0AE2..0x0AE3 to CharDirectionality.Weak,

/* GUJARATI DIGIT ZERO ... GUJARATI ABBREVIATION SIGN */
    0x0AE6..0x0AF0 to CharDirectionality.StrongLeftToRight,

/* GUJARATI RUPEE SIGN ... GUJARATI RUPEE SIGN */
    0x0AF1..0x0AF1 to CharDirectionality.Weak,

/* GUJARATI LETTER ZHA ... GUJARATI LETTER ZHA */
    0x0AF9..0x0AF9 to CharDirectionality.StrongLeftToRight,

/* GUJARATI SIGN SUKUN ... ORIYA SIGN CANDRABINDU */
    0x0AFA..0x0B01 to CharDirectionality.Weak,

/* ORIYA SIGN ANUSVARA ... ORIYA LETTER HA */
    0x0B02..0x0B39 to CharDirectionality.StrongLeftToRight,

/* ORIYA SIGN NUKTA ... ORIYA SIGN NUKTA */
    0x0B3C..0x0B3C to CharDirectionality.Weak,

/* ORIYA SIGN AVAGRAHA ... ORIYA VOWEL SIGN AA */
    0x0B3D..0x0B3E to CharDirectionality.StrongLeftToRight,

/* ORIYA VOWEL SIGN I ... ORIYA VOWEL SIGN I */
    0x0B3F..0x0B3F to CharDirectionality.Weak,

/* ORIYA VOWEL SIGN II ... ORIYA VOWEL SIGN II */
    0x0B40..0x0B40 to CharDirectionality.StrongLeftToRight,

/* ORIYA VOWEL SIGN U ... ORIYA VOWEL SIGN VOCALIC RR */
    0x0B41..0x0B44 to CharDirectionality.Weak,

/* ORIYA VOWEL SIGN E ... ORIYA VOWEL SIGN AU */
    0x0B47..0x0B4C to CharDirectionality.StrongLeftToRight,

/* ORIYA SIGN VIRAMA ... ORIYA AI LENGTH MARK */
    0x0B4D..0x0B56 to CharDirectionality.Weak,

/* ORIYA AU LENGTH MARK ... ORIYA LETTER VOCALIC LL */
    0x0B57..0x0B61 to CharDirectionality.StrongLeftToRight,

/* ORIYA VOWEL SIGN VOCALIC L ... ORIYA VOWEL SIGN VOCALIC LL */
    0x0B62..0x0B63 to CharDirectionality.Weak,

/* ORIYA DIGIT ZERO ... ORIYA FRACTION THREE SIXTEENTHS */
    0x0B66..0x0B77 to CharDirectionality.StrongLeftToRight,

/* TAMIL SIGN ANUSVARA ... TAMIL SIGN ANUSVARA */
    0x0B82..0x0B82 to CharDirectionality.Weak,

/* TAMIL SIGN VISARGA ... TAMIL VOWEL SIGN I */
    0x0B83..0x0BBF to CharDirectionality.StrongLeftToRight,

/* TAMIL VOWEL SIGN II ... TAMIL VOWEL SIGN II */
    0x0BC0..0x0BC0 to CharDirectionality.Weak,

/* TAMIL VOWEL SIGN U ... TAMIL VOWEL SIGN AU */
    0x0BC1..0x0BCC to CharDirectionality.StrongLeftToRight,

/* TAMIL SIGN VIRAMA ... TAMIL SIGN VIRAMA */
    0x0BCD..0x0BCD to CharDirectionality.Weak,

/* TAMIL OM ... TAMIL NUMBER ONE THOUSAND */
    0x0BD0..0x0BF2 to CharDirectionality.StrongLeftToRight,

/* TAMIL DAY SIGN ... TAMIL AS ABOVE SIGN */
    0x0BF3..0x0BF8 to CharDirectionality.Neutral,

/* TAMIL RUPEE SIGN ... TAMIL RUPEE SIGN */
    0x0BF9..0x0BF9 to CharDirectionality.Weak,

/* TAMIL NUMBER SIGN ... TAMIL NUMBER SIGN */
    0x0BFA..0x0BFA to CharDirectionality.Neutral,

/* TELUGU SIGN COMBINING CANDRABINDU ABOVE ... TELUGU SIGN COMBINING CANDRABINDU ABOVE */
    0x0C00..0x0C00 to CharDirectionality.Weak,

/* TELUGU SIGN CANDRABINDU ... TELUGU SIGN VISARGA */
    0x0C01..0x0C03 to CharDirectionality.StrongLeftToRight,

/* TELUGU SIGN COMBINING ANUSVARA ABOVE ... TELUGU SIGN COMBINING ANUSVARA ABOVE */
    0x0C04..0x0C04 to CharDirectionality.Weak,

/* TELUGU LETTER A ... TELUGU LETTER HA */
    0x0C05..0x0C39 to CharDirectionality.StrongLeftToRight,

/* TELUGU SIGN NUKTA ... TELUGU SIGN NUKTA */
    0x0C3C..0x0C3C to CharDirectionality.Weak,

/* TELUGU SIGN AVAGRAHA ... TELUGU SIGN AVAGRAHA */
    0x0C3D..0x0C3D to CharDirectionality.StrongLeftToRight,

/* TELUGU VOWEL SIGN AA ... TELUGU VOWEL SIGN II */
    0x0C3E..0x0C40 to CharDirectionality.Weak,

/* TELUGU VOWEL SIGN U ... TELUGU VOWEL SIGN VOCALIC RR */
    0x0C41..0x0C44 to CharDirectionality.StrongLeftToRight,

/* TELUGU VOWEL SIGN E ... TELUGU AI LENGTH MARK */
    0x0C46..0x0C56 to CharDirectionality.Weak,

/* TELUGU LETTER TSA ... TELUGU LETTER VOCALIC LL */
    0x0C58..0x0C61 to CharDirectionality.StrongLeftToRight,

/* TELUGU VOWEL SIGN VOCALIC L ... TELUGU VOWEL SIGN VOCALIC LL */
    0x0C62..0x0C63 to CharDirectionality.Weak,

/* TELUGU DIGIT ZERO ... TELUGU SIGN SIDDHAM */
    0x0C66..0x0C77 to CharDirectionality.StrongLeftToRight,

/* TELUGU FRACTION DIGIT ZERO FOR ODD POWERS OF FOUR ... TELUGU FRACTION DIGIT THREE FOR EVEN POWERS OF FOUR */
    0x0C78..0x0C7E to CharDirectionality.Neutral,

/* TELUGU SIGN TUUMU ... KANNADA SIGN SPACING CANDRABINDU */
    0x0C7F..0x0C80 to CharDirectionality.StrongLeftToRight,

/* KANNADA SIGN CANDRABINDU ... KANNADA SIGN CANDRABINDU */
    0x0C81..0x0C81 to CharDirectionality.Weak,

/* KANNADA SIGN ANUSVARA ... KANNADA LETTER HA */
    0x0C82..0x0CB9 to CharDirectionality.StrongLeftToRight,

/* KANNADA SIGN NUKTA ... KANNADA SIGN NUKTA */
    0x0CBC..0x0CBC to CharDirectionality.Weak,

/* KANNADA SIGN AVAGRAHA ... KANNADA VOWEL SIGN OO */
    0x0CBD..0x0CCB to CharDirectionality.StrongLeftToRight,

/* KANNADA VOWEL SIGN AU ... KANNADA SIGN VIRAMA */
    0x0CCC..0x0CCD to CharDirectionality.Weak,

/* KANNADA LENGTH MARK ... KANNADA LETTER VOCALIC LL */
    0x0CD5..0x0CE1 to CharDirectionality.StrongLeftToRight,

/* KANNADA VOWEL SIGN VOCALIC L ... KANNADA VOWEL SIGN VOCALIC LL */
    0x0CE2..0x0CE3 to CharDirectionality.Weak,

/* KANNADA DIGIT ZERO ... KANNADA SIGN COMBINING ANUSVARA ABOVE RIGHT */
    0x0CE6..0x0CF3 to CharDirectionality.StrongLeftToRight,

/* MALAYALAM SIGN COMBINING ANUSVARA ABOVE ... MALAYALAM SIGN CANDRABINDU */
    0x0D00..0x0D01 to CharDirectionality.Weak,

/* MALAYALAM SIGN ANUSVARA ... MALAYALAM LETTER TTTA */
    0x0D02..0x0D3A to CharDirectionality.StrongLeftToRight,

/* MALAYALAM SIGN VERTICAL BAR VIRAMA ... MALAYALAM SIGN CIRCULAR VIRAMA */
    0x0D3B..0x0D3C to CharDirectionality.Weak,

/* MALAYALAM SIGN AVAGRAHA ... MALAYALAM VOWEL SIGN II */
    0x0D3D..0x0D40 to CharDirectionality.StrongLeftToRight,

/* MALAYALAM VOWEL SIGN U ... MALAYALAM VOWEL SIGN VOCALIC RR */
    0x0D41..0x0D44 to CharDirectionality.Weak,

/* MALAYALAM VOWEL SIGN E ... MALAYALAM VOWEL SIGN AU */
    0x0D46..0x0D4C to CharDirectionality.StrongLeftToRight,

/* MALAYALAM SIGN VIRAMA ... MALAYALAM SIGN VIRAMA */
    0x0D4D..0x0D4D to CharDirectionality.Weak,

/* MALAYALAM LETTER DOT REPH ... MALAYALAM LETTER VOCALIC LL */
    0x0D4E..0x0D61 to CharDirectionality.StrongLeftToRight,

/* MALAYALAM VOWEL SIGN VOCALIC L ... MALAYALAM VOWEL SIGN VOCALIC LL */
    0x0D62..0x0D63 to CharDirectionality.Weak,

/* MALAYALAM DIGIT ZERO ... MALAYALAM LETTER CHILLU K */
    0x0D66..0x0D7F to CharDirectionality.StrongLeftToRight,

/* SINHALA SIGN CANDRABINDU ... SINHALA SIGN CANDRABINDU */
    0x0D81..0x0D81 to CharDirectionality.Weak,

/* SINHALA SIGN ANUSVARAYA ... SINHALA LETTER FAYANNA */
    0x0D82..0x0DC6 to CharDirectionality.StrongLeftToRight,

/* SINHALA SIGN AL-LAKUNA ... SINHALA SIGN AL-LAKUNA */
    0x0DCA..0x0DCA to CharDirectionality.Weak,

/* SINHALA VOWEL SIGN AELA-PILLA ... SINHALA VOWEL SIGN DIGA AEDA-PILLA */
    0x0DCF..0x0DD1 to CharDirectionality.StrongLeftToRight,

/* SINHALA VOWEL SIGN KETTI IS-PILLA ... SINHALA VOWEL SIGN DIGA PAA-PILLA */
    0x0DD2..0x0DD6 to CharDirectionality.Weak,

/* SINHALA VOWEL SIGN GAETTA-PILLA ... THAI CHARACTER SARA A */
    0x0DD8..0x0E30 to CharDirectionality.StrongLeftToRight,

/* THAI CHARACTER MAI HAN-AKAT ... THAI CHARACTER MAI HAN-AKAT */
    0x0E31..0x0E31 to CharDirectionality.Weak,

/* THAI CHARACTER SARA AA ... THAI CHARACTER SARA AM */
    0x0E32..0x0E33 to CharDirectionality.StrongLeftToRight,

/* THAI CHARACTER SARA I ... THAI CURRENCY SYMBOL BAHT */
    0x0E34..0x0E3F to CharDirectionality.Weak,

/* THAI CHARACTER SARA E ... THAI CHARACTER MAIYAMOK */
    0x0E40..0x0E46 to CharDirectionality.StrongLeftToRight,

/* THAI CHARACTER MAITAIKHU ... THAI CHARACTER YAMAKKAN */
    0x0E47..0x0E4E to CharDirectionality.Weak,

/* THAI CHARACTER FONGMAN ... LAO VOWEL SIGN A */
    0x0E4F..0x0EB0 to CharDirectionality.StrongLeftToRight,

/* LAO VOWEL SIGN MAI KAN ... LAO VOWEL SIGN MAI KAN */
    0x0EB1..0x0EB1 to CharDirectionality.Weak,

/* LAO VOWEL SIGN AA ... LAO VOWEL SIGN AM */
    0x0EB2..0x0EB3 to CharDirectionality.StrongLeftToRight,

/* LAO VOWEL SIGN I ... LAO SEMIVOWEL SIGN LO */
    0x0EB4..0x0EBC to CharDirectionality.Weak,

/* LAO SEMIVOWEL SIGN NYO ... LAO KO LA */
    0x0EBD..0x0EC6 to CharDirectionality.StrongLeftToRight,

/* LAO TONE MAI EK ... LAO YAMAKKAN */
    0x0EC8..0x0ECE to CharDirectionality.Weak,

/* LAO DIGIT ZERO ... TIBETAN ASTROLOGICAL SIGN SGRA GCAN -CHAR RTAGS */
    0x0ED0..0x0F17 to CharDirectionality.StrongLeftToRight,

/* TIBETAN ASTROLOGICAL SIGN -KHYUD PA ... TIBETAN ASTROLOGICAL SIGN SDONG TSHUGS */
    0x0F18..0x0F19 to CharDirectionality.Weak,

/* TIBETAN SIGN RDEL DKAR GCIG ... TIBETAN MARK BSDUS RTAGS */
    0x0F1A..0x0F34 to CharDirectionality.StrongLeftToRight,

/* TIBETAN MARK NGAS BZUNG NYI ZLA ... TIBETAN MARK NGAS BZUNG NYI ZLA */
    0x0F35..0x0F35 to CharDirectionality.Weak,

/* TIBETAN MARK CARET -DZUD RTAGS BZHI MIG CAN ... TIBETAN MARK CARET -DZUD RTAGS BZHI MIG CAN */
    0x0F36..0x0F36 to CharDirectionality.StrongLeftToRight,

/* TIBETAN MARK NGAS BZUNG SGOR RTAGS ... TIBETAN MARK NGAS BZUNG SGOR RTAGS */
    0x0F37..0x0F37 to CharDirectionality.Weak,

/* TIBETAN MARK CHE MGO ... TIBETAN MARK CHE MGO */
    0x0F38..0x0F38 to CharDirectionality.StrongLeftToRight,

/* TIBETAN MARK TSA -PHRU ... TIBETAN MARK TSA -PHRU */
    0x0F39..0x0F39 to CharDirectionality.Weak,

/* TIBETAN MARK GUG RTAGS GYON ... TIBETAN MARK ANG KHANG GYAS */
    0x0F3A..0x0F3D to CharDirectionality.Neutral,

/* TIBETAN SIGN YAR TSHES ... TIBETAN LETTER RRA */
    0x0F3E..0x0F6C to CharDirectionality.StrongLeftToRight,

/* TIBETAN VOWEL SIGN AA ... TIBETAN SIGN RJES SU NGA RO */
    0x0F71..0x0F7E to CharDirectionality.Weak,

/* TIBETAN SIGN RNAM BCAD ... TIBETAN SIGN RNAM BCAD */
    0x0F7F..0x0F7F to CharDirectionality.StrongLeftToRight,

/* TIBETAN VOWEL SIGN REVERSED I ... TIBETAN MARK HALANTA */
    0x0F80..0x0F84 to CharDirectionality.Weak,

/* TIBETAN MARK PALUTA ... TIBETAN MARK PALUTA */
    0x0F85..0x0F85 to CharDirectionality.StrongLeftToRight,

/* TIBETAN SIGN LCI RTAGS ... TIBETAN SIGN YANG RTAGS */
    0x0F86..0x0F87 to CharDirectionality.Weak,

/* TIBETAN SIGN LCE TSA CAN ... TIBETAN SIGN INVERTED MCHU CAN */
    0x0F88..0x0F8C to CharDirectionality.StrongLeftToRight,

/* TIBETAN SUBJOINED SIGN LCE TSA CAN ... TIBETAN SUBJOINED LETTER FIXED-FORM RA */
    0x0F8D..0x0FBC to CharDirectionality.Weak,

/* TIBETAN KU RU KHA ... TIBETAN SYMBOL RDO RJE */
    0x0FBE..0x0FC5 to CharDirectionality.StrongLeftToRight,

/* TIBETAN SYMBOL PADMA GDAN ... TIBETAN SYMBOL PADMA GDAN */
    0x0FC6..0x0FC6 to CharDirectionality.Weak,

/* TIBETAN SYMBOL RDO RJE RGYA GRAM ... MYANMAR VOWEL SIGN AA */
    0x0FC7..0x102C to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN I ... MYANMAR VOWEL SIGN UU */
    0x102D..0x1030 to CharDirectionality.Weak,

/* MYANMAR VOWEL SIGN E ... MYANMAR VOWEL SIGN E */
    0x1031..0x1031 to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN AI ... MYANMAR SIGN DOT BELOW */
    0x1032..0x1037 to CharDirectionality.Weak,

/* MYANMAR SIGN VISARGA ... MYANMAR SIGN VISARGA */
    0x1038..0x1038 to CharDirectionality.StrongLeftToRight,

/* MYANMAR SIGN VIRAMA ... MYANMAR SIGN ASAT */
    0x1039..0x103A to CharDirectionality.Weak,

/* MYANMAR CONSONANT SIGN MEDIAL YA ... MYANMAR CONSONANT SIGN MEDIAL RA */
    0x103B..0x103C to CharDirectionality.StrongLeftToRight,

/* MYANMAR CONSONANT SIGN MEDIAL WA ... MYANMAR CONSONANT SIGN MEDIAL HA */
    0x103D..0x103E to CharDirectionality.Weak,

/* MYANMAR LETTER GREAT SA ... MYANMAR VOWEL SIGN VOCALIC RR */
    0x103F..0x1057 to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN VOCALIC L ... MYANMAR VOWEL SIGN VOCALIC LL */
    0x1058..0x1059 to CharDirectionality.Weak,

/* MYANMAR LETTER MON NGA ... MYANMAR LETTER MON BBE */
    0x105A..0x105D to CharDirectionality.StrongLeftToRight,

/* MYANMAR CONSONANT SIGN MON MEDIAL NA ... MYANMAR CONSONANT SIGN MON MEDIAL LA */
    0x105E..0x1060 to CharDirectionality.Weak,

/* MYANMAR LETTER SGAW KAREN SHA ... MYANMAR LETTER EASTERN PWO KAREN GHWA */
    0x1061..0x1070 to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN GEBA KAREN I ... MYANMAR VOWEL SIGN KAYAH EE */
    0x1071..0x1074 to CharDirectionality.Weak,

/* MYANMAR LETTER SHAN KA ... MYANMAR LETTER SHAN HA */
    0x1075..0x1081 to CharDirectionality.StrongLeftToRight,

/* MYANMAR CONSONANT SIGN SHAN MEDIAL WA ... MYANMAR CONSONANT SIGN SHAN MEDIAL WA */
    0x1082..0x1082 to CharDirectionality.Weak,

/* MYANMAR VOWEL SIGN SHAN AA ... MYANMAR VOWEL SIGN SHAN E */
    0x1083..0x1084 to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN SHAN E ABOVE ... MYANMAR VOWEL SIGN SHAN FINAL Y */
    0x1085..0x1086 to CharDirectionality.Weak,

/* MYANMAR SIGN SHAN TONE-2 ... MYANMAR SIGN SHAN COUNCIL TONE-3 */
    0x1087..0x108C to CharDirectionality.StrongLeftToRight,

/* MYANMAR SIGN SHAN COUNCIL EMPHATIC TONE ... MYANMAR SIGN SHAN COUNCIL EMPHATIC TONE */
    0x108D..0x108D to CharDirectionality.Weak,

/* MYANMAR LETTER RUMAI PALAUNG FA ... MYANMAR VOWEL SIGN AITON A */
    0x108E..0x109C to CharDirectionality.StrongLeftToRight,

/* MYANMAR VOWEL SIGN AITON AI ... MYANMAR VOWEL SIGN AITON AI */
    0x109D..0x109D to CharDirectionality.Weak,

/* MYANMAR SYMBOL SHAN ONE ... ETHIOPIC SYLLABLE FYA */
    0x109E..0x135A to CharDirectionality.StrongLeftToRight,

/* ETHIOPIC COMBINING GEMINATION AND VOWEL LENGTH MARK ... ETHIOPIC COMBINING GEMINATION MARK */
    0x135D..0x135F to CharDirectionality.Weak,

/* ETHIOPIC SECTION MARK ... ETHIOPIC SYLLABLE PWE */
    0x1360..0x138F to CharDirectionality.StrongLeftToRight,

/* ETHIOPIC TONAL MARK YIZET ... ETHIOPIC TONAL MARK KURT */
    0x1390..0x1399 to CharDirectionality.Neutral,

/* CHEROKEE LETTER A ... CHEROKEE SMALL LETTER MV */
    0x13A0..0x13FD to CharDirectionality.StrongLeftToRight,

/* CANADIAN SYLLABICS HYPHEN ... CANADIAN SYLLABICS HYPHEN */
    0x1400..0x1400 to CharDirectionality.Neutral,

/* CANADIAN SYLLABICS E ... CANADIAN SYLLABICS BLACKFOOT W */
    0x1401..0x167F to CharDirectionality.StrongLeftToRight,

/* OGHAM SPACE MARK ... OGHAM SPACE MARK */
    0x1680..0x1680 to CharDirectionality.Neutral,

/* OGHAM LETTER BEITH ... OGHAM LETTER PEITH */
    0x1681..0x169A to CharDirectionality.StrongLeftToRight,

/* OGHAM FEATHER MARK ... OGHAM REVERSED FEATHER MARK */
    0x169B..0x169C to CharDirectionality.Neutral,

/* RUNIC LETTER FEHU FEOH FE F ... TAGALOG LETTER HA */
    0x16A0..0x1711 to CharDirectionality.StrongLeftToRight,

/* TAGALOG VOWEL SIGN I ... TAGALOG SIGN VIRAMA */
    0x1712..0x1714 to CharDirectionality.Weak,

/* TAGALOG SIGN PAMUDPOD ... HANUNOO LETTER HA */
    0x1715..0x1731 to CharDirectionality.StrongLeftToRight,

/* HANUNOO VOWEL SIGN I ... HANUNOO VOWEL SIGN U */
    0x1732..0x1733 to CharDirectionality.Weak,

/* HANUNOO SIGN PAMUDPOD ... BUHID LETTER HA */
    0x1734..0x1751 to CharDirectionality.StrongLeftToRight,

/* BUHID VOWEL SIGN I ... BUHID VOWEL SIGN U */
    0x1752..0x1753 to CharDirectionality.Weak,

/* TAGBANWA LETTER A ... TAGBANWA LETTER SA */
    0x1760..0x1770 to CharDirectionality.StrongLeftToRight,

/* TAGBANWA VOWEL SIGN I ... TAGBANWA VOWEL SIGN U */
    0x1772..0x1773 to CharDirectionality.Weak,

/* KHMER LETTER KA ... KHMER INDEPENDENT VOWEL QAU */
    0x1780..0x17B3 to CharDirectionality.StrongLeftToRight,

/* KHMER VOWEL INHERENT AQ ... KHMER VOWEL INHERENT AA */
    0x17B4..0x17B5 to CharDirectionality.Weak,

/* KHMER VOWEL SIGN AA ... KHMER VOWEL SIGN AA */
    0x17B6..0x17B6 to CharDirectionality.StrongLeftToRight,

/* KHMER VOWEL SIGN I ... KHMER VOWEL SIGN UA */
    0x17B7..0x17BD to CharDirectionality.Weak,

/* KHMER VOWEL SIGN OE ... KHMER VOWEL SIGN AU */
    0x17BE..0x17C5 to CharDirectionality.StrongLeftToRight,

/* KHMER SIGN NIKAHIT ... KHMER SIGN NIKAHIT */
    0x17C6..0x17C6 to CharDirectionality.Weak,

/* KHMER SIGN REAHMUK ... KHMER SIGN YUUKALEAPINTU */
    0x17C7..0x17C8 to CharDirectionality.StrongLeftToRight,

/* KHMER SIGN MUUSIKATOAN ... KHMER SIGN BATHAMASAT */
    0x17C9..0x17D3 to CharDirectionality.Weak,

/* KHMER SIGN KHAN ... KHMER SIGN KOOMUUT */
    0x17D4..0x17DA to CharDirectionality.StrongLeftToRight,

/* KHMER CURRENCY SYMBOL RIEL ... KHMER CURRENCY SYMBOL RIEL */
    0x17DB..0x17DB to CharDirectionality.Weak,

/* KHMER SIGN AVAKRAHASANYA ... KHMER SIGN AVAKRAHASANYA */
    0x17DC..0x17DC to CharDirectionality.StrongLeftToRight,

/* KHMER SIGN ATTHACAN ... KHMER SIGN ATTHACAN */
    0x17DD..0x17DD to CharDirectionality.Weak,

/* KHMER DIGIT ZERO ... KHMER DIGIT NINE */
    0x17E0..0x17E9 to CharDirectionality.StrongLeftToRight,

/* KHMER SYMBOL LEK ATTAK SON ... MONGOLIAN NIRUGU */
    0x17F0..0x180A to CharDirectionality.Neutral,

/* MONGOLIAN FREE VARIATION SELECTOR ONE ... MONGOLIAN FREE VARIATION SELECTOR FOUR */
    0x180B..0x180F to CharDirectionality.Weak,

/* MONGOLIAN DIGIT ZERO ... MONGOLIAN LETTER ALI GALI INVERTED UBADAMA */
    0x1810..0x1884 to CharDirectionality.StrongLeftToRight,

/* MONGOLIAN LETTER ALI GALI BALUDA ... MONGOLIAN LETTER ALI GALI THREE BALUDA */
    0x1885..0x1886 to CharDirectionality.Weak,

/* MONGOLIAN LETTER ALI GALI A ... MONGOLIAN LETTER MANCHU ALI GALI BHA */
    0x1887..0x18A8 to CharDirectionality.StrongLeftToRight,

/* MONGOLIAN LETTER ALI GALI DAGALGA ... MONGOLIAN LETTER ALI GALI DAGALGA */
    0x18A9..0x18A9 to CharDirectionality.Weak,

/* MONGOLIAN LETTER MANCHU ALI GALI LHA ... LIMBU LETTER TRA */
    0x18AA..0x191E to CharDirectionality.StrongLeftToRight,

/* LIMBU VOWEL SIGN A ... LIMBU VOWEL SIGN U */
    0x1920..0x1922 to CharDirectionality.Weak,

/* LIMBU VOWEL SIGN EE ... LIMBU VOWEL SIGN AU */
    0x1923..0x1926 to CharDirectionality.StrongLeftToRight,

/* LIMBU VOWEL SIGN E ... LIMBU VOWEL SIGN O */
    0x1927..0x1928 to CharDirectionality.Weak,

/* LIMBU SUBJOINED LETTER YA ... LIMBU SMALL LETTER NGA */
    0x1929..0x1931 to CharDirectionality.StrongLeftToRight,

/* LIMBU SMALL LETTER ANUSVARA ... LIMBU SMALL LETTER ANUSVARA */
    0x1932..0x1932 to CharDirectionality.Weak,

/* LIMBU SMALL LETTER TA ... LIMBU SMALL LETTER LA */
    0x1933..0x1938 to CharDirectionality.StrongLeftToRight,

/* LIMBU SIGN MUKPHRENG ... LIMBU SIGN SA-I */
    0x1939..0x193B to CharDirectionality.Weak,

/* LIMBU SIGN LOO ... LIMBU QUESTION MARK */
    0x1940..0x1945 to CharDirectionality.Neutral,

/* LIMBU DIGIT ZERO ... NEW TAI LUE THAM DIGIT ONE */
    0x1946..0x19DA to CharDirectionality.StrongLeftToRight,

/* NEW TAI LUE SIGN LAE ... KHMER SYMBOL DAP-PRAM ROC */
    0x19DE..0x19FF to CharDirectionality.Neutral,

/* BUGINESE LETTER KA ... BUGINESE LETTER HA */
    0x1A00..0x1A16 to CharDirectionality.StrongLeftToRight,

/* BUGINESE VOWEL SIGN I ... BUGINESE VOWEL SIGN U */
    0x1A17..0x1A18 to CharDirectionality.Weak,

/* BUGINESE VOWEL SIGN E ... BUGINESE VOWEL SIGN O */
    0x1A19..0x1A1A to CharDirectionality.StrongLeftToRight,

/* BUGINESE VOWEL SIGN AE ... BUGINESE VOWEL SIGN AE */
    0x1A1B..0x1A1B to CharDirectionality.Weak,

/* BUGINESE PALLAWA ... TAI THAM CONSONANT SIGN MEDIAL RA */
    0x1A1E..0x1A55 to CharDirectionality.StrongLeftToRight,

/* TAI THAM CONSONANT SIGN MEDIAL LA ... TAI THAM CONSONANT SIGN MEDIAL LA */
    0x1A56..0x1A56 to CharDirectionality.Weak,

/* TAI THAM CONSONANT SIGN LA TANG LAI ... TAI THAM CONSONANT SIGN LA TANG LAI */
    0x1A57..0x1A57 to CharDirectionality.StrongLeftToRight,

/* TAI THAM SIGN MAI KANG LAI ... TAI THAM SIGN SAKOT */
    0x1A58..0x1A60 to CharDirectionality.Weak,

/* TAI THAM VOWEL SIGN A ... TAI THAM VOWEL SIGN A */
    0x1A61..0x1A61 to CharDirectionality.StrongLeftToRight,

/* TAI THAM VOWEL SIGN MAI SAT ... TAI THAM VOWEL SIGN MAI SAT */
    0x1A62..0x1A62 to CharDirectionality.Weak,

/* TAI THAM VOWEL SIGN AA ... TAI THAM VOWEL SIGN TALL AA */
    0x1A63..0x1A64 to CharDirectionality.StrongLeftToRight,

/* TAI THAM VOWEL SIGN I ... TAI THAM VOWEL SIGN OA BELOW */
    0x1A65..0x1A6C to CharDirectionality.Weak,

/* TAI THAM VOWEL SIGN OY ... TAI THAM VOWEL SIGN THAM AI */
    0x1A6D..0x1A72 to CharDirectionality.StrongLeftToRight,

/* TAI THAM VOWEL SIGN OA ABOVE ... TAI THAM COMBINING CRYPTOGRAMMIC DOT */
    0x1A73..0x1A7F to CharDirectionality.Weak,

/* TAI THAM HORA DIGIT ZERO ... TAI THAM SIGN CAANG */
    0x1A80..0x1AAD to CharDirectionality.StrongLeftToRight,

/* COMBINING DOUBLED CIRCUMFLEX ACCENT ... BALINESE SIGN SURANG */
    0x1AB0..0x1B03 to CharDirectionality.Weak,

/* BALINESE SIGN BISAH ... BALINESE LETTER HA */
    0x1B04..0x1B33 to CharDirectionality.StrongLeftToRight,

/* BALINESE SIGN REREKAN ... BALINESE SIGN REREKAN */
    0x1B34..0x1B34 to CharDirectionality.Weak,

/* BALINESE VOWEL SIGN TEDUNG ... BALINESE VOWEL SIGN TEDUNG */
    0x1B35..0x1B35 to CharDirectionality.StrongLeftToRight,

/* BALINESE VOWEL SIGN ULU ... BALINESE VOWEL SIGN RA REPA */
    0x1B36..0x1B3A to CharDirectionality.Weak,

/* BALINESE VOWEL SIGN RA REPA TEDUNG ... BALINESE VOWEL SIGN RA REPA TEDUNG */
    0x1B3B..0x1B3B to CharDirectionality.StrongLeftToRight,

/* BALINESE VOWEL SIGN LA LENGA ... BALINESE VOWEL SIGN LA LENGA */
    0x1B3C..0x1B3C to CharDirectionality.Weak,

/* BALINESE VOWEL SIGN LA LENGA TEDUNG ... BALINESE VOWEL SIGN TALING REPA TEDUNG */
    0x1B3D..0x1B41 to CharDirectionality.StrongLeftToRight,

/* BALINESE VOWEL SIGN PEPET ... BALINESE VOWEL SIGN PEPET */
    0x1B42..0x1B42 to CharDirectionality.Weak,

/* BALINESE VOWEL SIGN PEPET TEDUNG ... BALINESE MUSICAL SYMBOL DANG GEDE */
    0x1B43..0x1B6A to CharDirectionality.StrongLeftToRight,

/* BALINESE MUSICAL SYMBOL COMBINING TEGEH ... BALINESE MUSICAL SYMBOL COMBINING GONG */
    0x1B6B..0x1B73 to CharDirectionality.Weak,

/* BALINESE MUSICAL SYMBOL RIGHT-HAND OPEN DUG ... BALINESE PAMADA LANTANG */
    0x1B74..0x1B7E to CharDirectionality.StrongLeftToRight,

/* SUNDANESE SIGN PANYECEK ... SUNDANESE SIGN PANGLAYAR */
    0x1B80..0x1B81 to CharDirectionality.Weak,

/* SUNDANESE SIGN PANGWISAD ... SUNDANESE CONSONANT SIGN PAMINGKAL */
    0x1B82..0x1BA1 to CharDirectionality.StrongLeftToRight,

/* SUNDANESE CONSONANT SIGN PANYAKRA ... SUNDANESE VOWEL SIGN PANYUKU */
    0x1BA2..0x1BA5 to CharDirectionality.Weak,

/* SUNDANESE VOWEL SIGN PANAELAENG ... SUNDANESE VOWEL SIGN PANOLONG */
    0x1BA6..0x1BA7 to CharDirectionality.StrongLeftToRight,

/* SUNDANESE VOWEL SIGN PAMEPET ... SUNDANESE VOWEL SIGN PANEULEUNG */
    0x1BA8..0x1BA9 to CharDirectionality.Weak,

/* SUNDANESE SIGN PAMAAEH ... SUNDANESE SIGN PAMAAEH */
    0x1BAA..0x1BAA to CharDirectionality.StrongLeftToRight,

/* SUNDANESE SIGN VIRAMA ... SUNDANESE CONSONANT SIGN PASANGAN WA */
    0x1BAB..0x1BAD to CharDirectionality.Weak,

/* SUNDANESE LETTER KHA ... BATAK LETTER U */
    0x1BAE..0x1BE5 to CharDirectionality.StrongLeftToRight,

/* BATAK SIGN TOMPI ... BATAK SIGN TOMPI */
    0x1BE6..0x1BE6 to CharDirectionality.Weak,

/* BATAK VOWEL SIGN E ... BATAK VOWEL SIGN E */
    0x1BE7..0x1BE7 to CharDirectionality.StrongLeftToRight,

/* BATAK VOWEL SIGN PAKPAK E ... BATAK VOWEL SIGN EE */
    0x1BE8..0x1BE9 to CharDirectionality.Weak,

/* BATAK VOWEL SIGN I ... BATAK VOWEL SIGN O */
    0x1BEA..0x1BEC to CharDirectionality.StrongLeftToRight,

/* BATAK VOWEL SIGN KARO O ... BATAK VOWEL SIGN KARO O */
    0x1BED..0x1BED to CharDirectionality.Weak,

/* BATAK VOWEL SIGN U ... BATAK VOWEL SIGN U */
    0x1BEE..0x1BEE to CharDirectionality.StrongLeftToRight,

/* BATAK VOWEL SIGN U FOR SIMALUNGUN SA ... BATAK CONSONANT SIGN H */
    0x1BEF..0x1BF1 to CharDirectionality.Weak,

/* BATAK PANGOLAT ... LEPCHA VOWEL SIGN UU */
    0x1BF2..0x1C2B to CharDirectionality.StrongLeftToRight,

/* LEPCHA VOWEL SIGN E ... LEPCHA CONSONANT SIGN T */
    0x1C2C..0x1C33 to CharDirectionality.Weak,

/* LEPCHA CONSONANT SIGN NYIN-DO ... LEPCHA CONSONANT SIGN KANG */
    0x1C34..0x1C35 to CharDirectionality.StrongLeftToRight,

/* LEPCHA SIGN RAN ... LEPCHA SIGN NUKTA */
    0x1C36..0x1C37 to CharDirectionality.Weak,

/* LEPCHA PUNCTUATION TA-ROL ... SUNDANESE PUNCTUATION BINDU BA SATANGA */
    0x1C3B..0x1CC7 to CharDirectionality.StrongLeftToRight,

/* VEDIC TONE KARSHANA ... VEDIC TONE PRENKHA */
    0x1CD0..0x1CD2 to CharDirectionality.Weak,

/* VEDIC SIGN NIHSHVASA ... VEDIC SIGN NIHSHVASA */
    0x1CD3..0x1CD3 to CharDirectionality.StrongLeftToRight,

/* VEDIC SIGN YAJURVEDIC MIDLINE SVARITA ... VEDIC TONE RIGVEDIC KASHMIRI INDEPENDENT SVARITA */
    0x1CD4..0x1CE0 to CharDirectionality.Weak,

/* VEDIC TONE ATHARVAVEDIC INDEPENDENT SVARITA ... VEDIC TONE ATHARVAVEDIC INDEPENDENT SVARITA */
    0x1CE1..0x1CE1 to CharDirectionality.StrongLeftToRight,

/* VEDIC SIGN VISARGA SVARITA ... VEDIC SIGN VISARGA ANUDATTA WITH TAIL */
    0x1CE2..0x1CE8 to CharDirectionality.Weak,

/* VEDIC SIGN ANUSVARA ANTARGOMUKHA ... VEDIC SIGN ANUSVARA VAMAGOMUKHA WITH TAIL */
    0x1CE9..0x1CEC to CharDirectionality.StrongLeftToRight,

/* VEDIC SIGN TIRYAK ... VEDIC SIGN TIRYAK */
    0x1CED..0x1CED to CharDirectionality.Weak,

/* VEDIC SIGN HEXIFORM LONG ANUSVARA ... VEDIC SIGN ROTATED ARDHAVISARGA */
    0x1CEE..0x1CF3 to CharDirectionality.StrongLeftToRight,

/* VEDIC TONE CANDRA ABOVE ... VEDIC TONE CANDRA ABOVE */
    0x1CF4..0x1CF4 to CharDirectionality.Weak,

/* VEDIC SIGN JIHVAMULIYA ... VEDIC SIGN ATIKRAMA */
    0x1CF5..0x1CF7 to CharDirectionality.StrongLeftToRight,

/* VEDIC TONE RING ABOVE ... VEDIC TONE DOUBLE RING ABOVE */
    0x1CF8..0x1CF9 to CharDirectionality.Weak,

/* VEDIC SIGN DOUBLE ANUSVARA ANTARGOMUKHA ... MODIFIER LETTER SMALL THETA */
    0x1CFA..0x1DBF to CharDirectionality.StrongLeftToRight,

/* COMBINING DOTTED GRAVE ACCENT ... COMBINING RIGHT ARROWHEAD AND DOWN ARROWHEAD BELOW */
    0x1DC0..0x1DFF to CharDirectionality.Weak,

/* LATIN CAPITAL LETTER A WITH RING BELOW ... GREEK CAPITAL LETTER ALPHA WITH PROSGEGRAMMENI */
    0x1E00..0x1FBC to CharDirectionality.StrongLeftToRight,

/* GREEK KORONIS ... GREEK KORONIS */
    0x1FBD..0x1FBD to CharDirectionality.Neutral,

/* GREEK PROSGEGRAMMENI ... GREEK PROSGEGRAMMENI */
    0x1FBE..0x1FBE to CharDirectionality.StrongLeftToRight,

/* GREEK PSILI ... GREEK DIALYTIKA AND PERISPOMENI */
    0x1FBF..0x1FC1 to CharDirectionality.Neutral,

/* GREEK SMALL LETTER ETA WITH VARIA AND YPOGEGRAMMENI ... GREEK CAPITAL LETTER ETA WITH PROSGEGRAMMENI */
    0x1FC2..0x1FCC to CharDirectionality.StrongLeftToRight,

/* GREEK PSILI AND VARIA ... GREEK PSILI AND PERISPOMENI */
    0x1FCD..0x1FCF to CharDirectionality.Neutral,

/* GREEK SMALL LETTER IOTA WITH VRACHY ... GREEK CAPITAL LETTER IOTA WITH OXIA */
    0x1FD0..0x1FDB to CharDirectionality.StrongLeftToRight,

/* GREEK DASIA AND VARIA ... GREEK DASIA AND PERISPOMENI */
    0x1FDD..0x1FDF to CharDirectionality.Neutral,

/* GREEK SMALL LETTER UPSILON WITH VRACHY ... GREEK CAPITAL LETTER RHO WITH DASIA */
    0x1FE0..0x1FEC to CharDirectionality.StrongLeftToRight,

/* GREEK DIALYTIKA AND VARIA ... GREEK VARIA */
    0x1FED..0x1FEF to CharDirectionality.Neutral,

/* GREEK SMALL LETTER OMEGA WITH VARIA AND YPOGEGRAMMENI ... GREEK CAPITAL LETTER OMEGA WITH PROSGEGRAMMENI */
    0x1FF2..0x1FFC to CharDirectionality.StrongLeftToRight,

/* GREEK OXIA ... HAIR SPACE */
    0x1FFD..0x200A to CharDirectionality.Neutral,

/* ZERO WIDTH SPACE ... ZERO WIDTH JOINER */
    0x200B..0x200D to CharDirectionality.Weak,

/* LEFT-TO-RIGHT MARK ... LEFT-TO-RIGHT MARK */
    0x200E..0x200E to CharDirectionality.StrongLeftToRight,

/* RIGHT-TO-LEFT MARK ... RIGHT-TO-LEFT MARK */
    0x200F..0x200F to CharDirectionality.StrongRightToLeft,

/* HYPHEN ... PARAGRAPH SEPARATOR */
    0x2010..0x2029 to CharDirectionality.Neutral,

/* LEFT-TO-RIGHT EMBEDDING ... RIGHT-TO-LEFT OVERRIDE */
    0x202A..0x202E to CharDirectionality.ExplicitFormatting,

/* NARROW NO-BREAK SPACE ... TRIPLE PRIME */
    0x202F..0x2034 to CharDirectionality.Weak,

/* REVERSED PRIME ... HYPHEN BULLET */
    0x2035..0x2043 to CharDirectionality.Neutral,

/* FRACTION SLASH ... FRACTION SLASH */
    0x2044..0x2044 to CharDirectionality.Weak,

/* LEFT SQUARE BRACKET WITH QUILL ... MEDIUM MATHEMATICAL SPACE */
    0x2045..0x205F to CharDirectionality.Neutral,

/* WORD JOINER ... INVISIBLE PLUS */
    0x2060..0x2064 to CharDirectionality.Weak,

/* LEFT-TO-RIGHT ISOLATE ... POP DIRECTIONAL ISOLATE */
    0x2066..0x2069 to CharDirectionality.ExplicitFormatting,

/* INHIBIT SYMMETRIC SWAPPING ... SUPERSCRIPT ZERO */
    0x206A..0x2070 to CharDirectionality.Weak,

/* SUPERSCRIPT LATIN SMALL LETTER I ... SUPERSCRIPT LATIN SMALL LETTER I */
    0x2071..0x2071 to CharDirectionality.StrongLeftToRight,

/* SUPERSCRIPT FOUR ... SUPERSCRIPT MINUS */
    0x2074..0x207B to CharDirectionality.Weak,

/* SUPERSCRIPT EQUALS SIGN ... SUPERSCRIPT RIGHT PARENTHESIS */
    0x207C..0x207E to CharDirectionality.Neutral,

/* SUPERSCRIPT LATIN SMALL LETTER N ... SUPERSCRIPT LATIN SMALL LETTER N */
    0x207F..0x207F to CharDirectionality.StrongLeftToRight,

/* SUBSCRIPT ZERO ... SUBSCRIPT MINUS */
    0x2080..0x208B to CharDirectionality.Weak,

/* SUBSCRIPT EQUALS SIGN ... SUBSCRIPT RIGHT PARENTHESIS */
    0x208C..0x208E to CharDirectionality.Neutral,

/* LATIN SUBSCRIPT SMALL LETTER A ... LATIN SUBSCRIPT SMALL LETTER T */
    0x2090..0x209C to CharDirectionality.StrongLeftToRight,

/* EURO-CURRENCY SIGN ... COMBINING ASTERISK ABOVE */
    0x20A0..0x20F0 to CharDirectionality.Weak,

/* ACCOUNT OF ... ADDRESSED TO THE SUBJECT */
    0x2100..0x2101 to CharDirectionality.Neutral,

/* DOUBLE-STRUCK CAPITAL C ... DOUBLE-STRUCK CAPITAL C */
    0x2102..0x2102 to CharDirectionality.StrongLeftToRight,

/* DEGREE CELSIUS ... CADA UNA */
    0x2103..0x2106 to CharDirectionality.Neutral,

/* EULER CONSTANT ... EULER CONSTANT */
    0x2107..0x2107 to CharDirectionality.StrongLeftToRight,

/* SCRUPLE ... DEGREE FAHRENHEIT */
    0x2108..0x2109 to CharDirectionality.Neutral,

/* SCRIPT SMALL G ... SCRIPT SMALL L */
    0x210A..0x2113 to CharDirectionality.StrongLeftToRight,

/* L B BAR SYMBOL ... L B BAR SYMBOL */
    0x2114..0x2114 to CharDirectionality.Neutral,

/* DOUBLE-STRUCK CAPITAL N ... DOUBLE-STRUCK CAPITAL N */
    0x2115..0x2115 to CharDirectionality.StrongLeftToRight,

/* NUMERO SIGN ... SCRIPT CAPITAL P */
    0x2116..0x2118 to CharDirectionality.Neutral,

/* DOUBLE-STRUCK CAPITAL P ... DOUBLE-STRUCK CAPITAL R */
    0x2119..0x211D to CharDirectionality.StrongLeftToRight,

/* PRESCRIPTION TAKE ... VERSICLE */
    0x211E..0x2123 to CharDirectionality.Neutral,

/* DOUBLE-STRUCK CAPITAL Z ... DOUBLE-STRUCK CAPITAL Z */
    0x2124..0x2124 to CharDirectionality.StrongLeftToRight,

/* OUNCE SIGN ... OUNCE SIGN */
    0x2125..0x2125 to CharDirectionality.Neutral,

/* OHM SIGN ... OHM SIGN */
    0x2126..0x2126 to CharDirectionality.StrongLeftToRight,

/* INVERTED OHM SIGN ... INVERTED OHM SIGN */
    0x2127..0x2127 to CharDirectionality.Neutral,

/* BLACK-LETTER CAPITAL Z ... BLACK-LETTER CAPITAL Z */
    0x2128..0x2128 to CharDirectionality.StrongLeftToRight,

/* TURNED GREEK SMALL LETTER IOTA ... TURNED GREEK SMALL LETTER IOTA */
    0x2129..0x2129 to CharDirectionality.Neutral,

/* KELVIN SIGN ... BLACK-LETTER CAPITAL C */
    0x212A..0x212D to CharDirectionality.StrongLeftToRight,

/* ESTIMATED SYMBOL ... ESTIMATED SYMBOL */
    0x212E..0x212E to CharDirectionality.Weak,

/* SCRIPT SMALL E ... INFORMATION SOURCE */
    0x212F..0x2139 to CharDirectionality.StrongLeftToRight,

/* ROTATED CAPITAL Q ... FACSIMILE SIGN */
    0x213A..0x213B to CharDirectionality.Neutral,

/* DOUBLE-STRUCK SMALL PI ... DOUBLE-STRUCK CAPITAL PI */
    0x213C..0x213F to CharDirectionality.StrongLeftToRight,

/* DOUBLE-STRUCK N-ARY SUMMATION ... TURNED SANS-SERIF CAPITAL Y */
    0x2140..0x2144 to CharDirectionality.Neutral,

/* DOUBLE-STRUCK ITALIC CAPITAL D ... DOUBLE-STRUCK ITALIC SMALL J */
    0x2145..0x2149 to CharDirectionality.StrongLeftToRight,

/* PROPERTY LINE ... AKTIESELSKAB */
    0x214A..0x214D to CharDirectionality.Neutral,

/* TURNED SMALL F ... SYMBOL FOR SAMARITAN SOURCE */
    0x214E..0x214F to CharDirectionality.StrongLeftToRight,

/* VULGAR FRACTION ONE SEVENTH ... FRACTION NUMERATOR ONE */
    0x2150..0x215F to CharDirectionality.Neutral,

/* ROMAN NUMERAL ONE ... ROMAN NUMERAL ONE HUNDRED THOUSAND */
    0x2160..0x2188 to CharDirectionality.StrongLeftToRight,

/* VULGAR FRACTION ZERO THIRDS ... N-ARY SUMMATION */
    0x2189..0x2211 to CharDirectionality.Neutral,

/* MINUS SIGN ... MINUS-OR-PLUS SIGN */
    0x2212..0x2213 to CharDirectionality.Weak,

/* DOT PLUS ... COUNTERSINK */
    0x2214..0x2335 to CharDirectionality.Neutral,

/* APL FUNCTIONAL SYMBOL I-BEAM ... APL FUNCTIONAL SYMBOL ALPHA */
    0x2336..0x237A to CharDirectionality.StrongLeftToRight,

/* NOT CHECK MARK ... SOFTWARE-FUNCTION SYMBOL */
    0x237B..0x2394 to CharDirectionality.Neutral,

/* APL FUNCTIONAL SYMBOL QUAD ... APL FUNCTIONAL SYMBOL QUAD */
    0x2395..0x2395 to CharDirectionality.StrongLeftToRight,

/* DECIMAL SEPARATOR KEY SYMBOL ... PARENTHESIZED NUMBER TWENTY */
    0x2396..0x2487 to CharDirectionality.Neutral,

/* DIGIT ONE FULL STOP ... NUMBER TWENTY FULL STOP */
    0x2488..0x249B to CharDirectionality.Weak,

/* PARENTHESIZED LATIN SMALL LETTER A ... CIRCLED LATIN SMALL LETTER Z */
    0x249C..0x24E9 to CharDirectionality.StrongLeftToRight,

/* CIRCLED DIGIT ZERO ... MEDIUM BLACK CIRCLE */
    0x24EA..0x26AB to CharDirectionality.Neutral,

/* MEDIUM SMALL WHITE CIRCLE ... MEDIUM SMALL WHITE CIRCLE */
    0x26AC..0x26AC to CharDirectionality.StrongLeftToRight,

/* MARRIAGE SYMBOL ... LONG RIGHTWARDS SQUIGGLE ARROW */
    0x26AD..0x27FF to CharDirectionality.Neutral,

/* BRAILLE PATTERN BLANK ... BRAILLE PATTERN DOTS-12345678 */
    0x2800..0x28FF to CharDirectionality.StrongLeftToRight,

/* RIGHTWARDS TWO-HEADED ARROW WITH VERTICAL STROKE ... HELLSCHREIBER PAUSE SYMBOL */
    0x2900..0x2BFF to CharDirectionality.Neutral,

/* GLAGOLITIC CAPITAL LETTER AZU ... COPTIC SYMBOL KAI */
    0x2C00..0x2CE4 to CharDirectionality.StrongLeftToRight,

/* COPTIC SYMBOL MI RO ... COPTIC SYMBOL SHIMA SIMA */
    0x2CE5..0x2CEA to CharDirectionality.Neutral,

/* COPTIC CAPITAL LETTER CRYPTOGRAMMIC SHEI ... COPTIC SMALL LETTER CRYPTOGRAMMIC GANGIA */
    0x2CEB..0x2CEE to CharDirectionality.StrongLeftToRight,

/* COPTIC COMBINING NI ABOVE ... COPTIC COMBINING SPIRITUS LENIS */
    0x2CEF..0x2CF1 to CharDirectionality.Weak,

/* COPTIC CAPITAL LETTER BOHAIRIC KHEI ... COPTIC SMALL LETTER BOHAIRIC KHEI */
    0x2CF2..0x2CF3 to CharDirectionality.StrongLeftToRight,

/* COPTIC OLD NUBIAN FULL STOP ... COPTIC MORPHOLOGICAL DIVIDER */
    0x2CF9..0x2CFF to CharDirectionality.Neutral,

/* GEORGIAN SMALL LETTER AN ... TIFINAGH SEPARATOR MARK */
    0x2D00..0x2D70 to CharDirectionality.StrongLeftToRight,

/* TIFINAGH CONSONANT JOINER ... TIFINAGH CONSONANT JOINER */
    0x2D7F..0x2D7F to CharDirectionality.Weak,

/* ETHIOPIC SYLLABLE LOA ... ETHIOPIC SYLLABLE GYO */
    0x2D80..0x2DDE to CharDirectionality.StrongLeftToRight,

/* COMBINING CYRILLIC LETTER BE ... COMBINING CYRILLIC LETTER IOTIFIED BIG YUS */
    0x2DE0..0x2DFF to CharDirectionality.Weak,

/* RIGHT ANGLE SUBSTITUTION MARKER ... JAPANESE INDUSTRIAL STANDARD SYMBOL */
    0x2E00..0x3004 to CharDirectionality.Neutral,

/* IDEOGRAPHIC ITERATION MARK ... IDEOGRAPHIC NUMBER ZERO */
    0x3005..0x3007 to CharDirectionality.StrongLeftToRight,

/* LEFT ANGLE BRACKET ... POSTAL MARK FACE */
    0x3008..0x3020 to CharDirectionality.Neutral,

/* HANGZHOU NUMERAL ONE ... HANGZHOU NUMERAL NINE */
    0x3021..0x3029 to CharDirectionality.StrongLeftToRight,

/* IDEOGRAPHIC LEVEL TONE MARK ... IDEOGRAPHIC ENTERING TONE MARK */
    0x302A..0x302D to CharDirectionality.Weak,

/* HANGUL SINGLE DOT TONE MARK ... HANGUL DOUBLE DOT TONE MARK */
    0x302E..0x302F to CharDirectionality.StrongLeftToRight,

/* WAVY DASH ... WAVY DASH */
    0x3030..0x3030 to CharDirectionality.Neutral,

/* VERTICAL KANA REPEAT MARK ... VERTICAL KANA REPEAT MARK LOWER HALF */
    0x3031..0x3035 to CharDirectionality.StrongLeftToRight,

/* CIRCLED POSTAL MARK ... IDEOGRAPHIC TELEGRAPH LINE FEED SEPARATOR SYMBOL */
    0x3036..0x3037 to CharDirectionality.Neutral,

/* HANGZHOU NUMERAL TEN ... MASU MARK */
    0x3038..0x303C to CharDirectionality.StrongLeftToRight,

/* PART ALTERNATION MARK ... IDEOGRAPHIC HALF FILL SPACE */
    0x303D..0x303F to CharDirectionality.Neutral,

/* HIRAGANA LETTER SMALL A ... HIRAGANA LETTER SMALL KE */
    0x3041..0x3096 to CharDirectionality.StrongLeftToRight,

/* COMBINING KATAKANA-HIRAGANA VOICED SOUND MARK ... COMBINING KATAKANA-HIRAGANA SEMI-VOICED SOUND MARK */
    0x3099..0x309A to CharDirectionality.Weak,

/* KATAKANA-HIRAGANA VOICED SOUND MARK ... KATAKANA-HIRAGANA SEMI-VOICED SOUND MARK */
    0x309B..0x309C to CharDirectionality.Neutral,

/* HIRAGANA ITERATION MARK ... HIRAGANA DIGRAPH YORI */
    0x309D..0x309F to CharDirectionality.StrongLeftToRight,

/* KATAKANA-HIRAGANA DOUBLE HYPHEN ... KATAKANA-HIRAGANA DOUBLE HYPHEN */
    0x30A0..0x30A0 to CharDirectionality.Neutral,

/* KATAKANA LETTER SMALL A ... KATAKANA LETTER VO */
    0x30A1..0x30FA to CharDirectionality.StrongLeftToRight,

/* KATAKANA MIDDLE DOT ... KATAKANA MIDDLE DOT */
    0x30FB..0x30FB to CharDirectionality.Neutral,

/* KATAKANA-HIRAGANA PROLONGED SOUND MARK ... BOPOMOFO LETTER AH */
    0x30FC..0x31BF to CharDirectionality.StrongLeftToRight,

/* CJK STROKE T ... CJK STROKE Q */
    0x31C0..0x31E3 to CharDirectionality.Neutral,

/* KATAKANA LETTER SMALL KU ... PARENTHESIZED HANGUL CIEUC U */
    0x31F0..0x321C to CharDirectionality.StrongLeftToRight,

/* PARENTHESIZED KOREAN CHARACTER OJEON ... PARENTHESIZED KOREAN CHARACTER O HU */
    0x321D..0x321E to CharDirectionality.Neutral,

/* PARENTHESIZED IDEOGRAPH ONE ... CIRCLED NUMBER EIGHTY ON BLACK SQUARE */
    0x3220..0x324F to CharDirectionality.StrongLeftToRight,

/* PARTNERSHIP SIGN ... CIRCLED NUMBER THIRTY FIVE */
    0x3250..0x325F to CharDirectionality.Neutral,

/* CIRCLED HANGUL KIYEOK ... CIRCLED HANGUL HIEUH A */
    0x3260..0x327B to CharDirectionality.StrongLeftToRight,

/* CIRCLED KOREAN CHARACTER CHAMKO ... CIRCLED HANGUL IEUNG U */
    0x327C..0x327E to CharDirectionality.Neutral,

/* KOREAN STANDARD SYMBOL ... CIRCLED IDEOGRAPH NIGHT */
    0x327F..0x32B0 to CharDirectionality.StrongLeftToRight,

/* CIRCLED NUMBER THIRTY SIX ... CIRCLED NUMBER FIFTY */
    0x32B1..0x32BF to CharDirectionality.Neutral,

/* IDEOGRAPHIC TELEGRAPH SYMBOL FOR JANUARY ... IDEOGRAPHIC TELEGRAPH SYMBOL FOR DECEMBER */
    0x32C0..0x32CB to CharDirectionality.StrongLeftToRight,

/* SQUARE HG ... LIMITED LIABILITY SIGN */
    0x32CC..0x32CF to CharDirectionality.Neutral,

/* CIRCLED KATAKANA A ... SQUARE PC */
    0x32D0..0x3376 to CharDirectionality.StrongLeftToRight,

/* SQUARE DM ... SQUARE IU */
    0x3377..0x337A to CharDirectionality.Neutral,

/* SQUARE ERA NAME HEISEI ... SQUARE WB */
    0x337B..0x33DD to CharDirectionality.StrongLeftToRight,

/* SQUARE V OVER M ... SQUARE A OVER M */
    0x33DE..0x33DF to CharDirectionality.Neutral,

/* IDEOGRAPHIC TELEGRAPH SYMBOL FOR DAY ONE ... IDEOGRAPHIC TELEGRAPH SYMBOL FOR DAY THIRTY-ONE */
    0x33E0..0x33FE to CharDirectionality.StrongLeftToRight,

/* SQUARE GAL ... SQUARE GAL */
    0x33FF..0x33FF to CharDirectionality.Neutral,

/* <CJK Ideograph Extension A, First> ... <CJK Ideograph Extension A, Last> */
    0x3400..0x4DBF to CharDirectionality.StrongLeftToRight,

/* HEXAGRAM FOR THE CREATIVE HEAVEN ... HEXAGRAM FOR BEFORE COMPLETION */
    0x4DC0..0x4DFF to CharDirectionality.Neutral,

/* <CJK Ideograph, First> ... YI SYLLABLE YYR */
    0x4E00..0xA48C to CharDirectionality.StrongLeftToRight,

/* YI RADICAL QOT ... YI RADICAL KE */
    0xA490..0xA4C6 to CharDirectionality.Neutral,

/* LISU LETTER BA ... VAI SYLLABLE LENGTHENER */
    0xA4D0..0xA60C to CharDirectionality.StrongLeftToRight,

/* VAI COMMA ... VAI QUESTION MARK */
    0xA60D..0xA60F to CharDirectionality.Neutral,

/* VAI SYLLABLE NDOLE FA ... CYRILLIC LETTER MULTIOCULAR O */
    0xA610..0xA66E to CharDirectionality.StrongLeftToRight,

/* COMBINING CYRILLIC VZMET ... COMBINING CYRILLIC THOUSAND MILLIONS SIGN */
    0xA66F..0xA672 to CharDirectionality.Weak,

/* SLAVONIC ASTERISK ... SLAVONIC ASTERISK */
    0xA673..0xA673 to CharDirectionality.Neutral,

/* COMBINING CYRILLIC LETTER UKRAINIAN IE ... COMBINING CYRILLIC PAYEROK */
    0xA674..0xA67D to CharDirectionality.Weak,

/* CYRILLIC KAVYKA ... CYRILLIC PAYEROK */
    0xA67E..0xA67F to CharDirectionality.Neutral,

/* CYRILLIC CAPITAL LETTER DWE ... MODIFIER LETTER CYRILLIC SOFT SIGN */
    0xA680..0xA69D to CharDirectionality.StrongLeftToRight,

/* COMBINING CYRILLIC LETTER EF ... COMBINING CYRILLIC LETTER IOTIFIED E */
    0xA69E..0xA69F to CharDirectionality.Weak,

/* BAMUM LETTER A ... BAMUM LETTER KOGHOM */
    0xA6A0..0xA6EF to CharDirectionality.StrongLeftToRight,

/* BAMUM COMBINING MARK KOQNDON ... BAMUM COMBINING MARK TUKWENTIS */
    0xA6F0..0xA6F1 to CharDirectionality.Weak,

/* BAMUM NJAEMLI ... BAMUM QUESTION MARK */
    0xA6F2..0xA6F7 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER CHINESE TONE YIN PING ... MODIFIER LETTER STRESS AND LOW TONE */
    0xA700..0xA721 to CharDirectionality.Neutral,

/* LATIN CAPITAL LETTER EGYPTOLOGICAL ALEF ... LATIN SMALL LETTER INSULAR T */
    0xA722..0xA787 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER LOW CIRCUMFLEX ACCENT ... MODIFIER LETTER LOW CIRCUMFLEX ACCENT */
    0xA788..0xA788 to CharDirectionality.Neutral,

/* MODIFIER LETTER COLON ... SYLOTI NAGRI LETTER I */
    0xA789..0xA801 to CharDirectionality.StrongLeftToRight,

/* SYLOTI NAGRI SIGN DVISVARA ... SYLOTI NAGRI SIGN DVISVARA */
    0xA802..0xA802 to CharDirectionality.Weak,

/* SYLOTI NAGRI LETTER U ... SYLOTI NAGRI LETTER O */
    0xA803..0xA805 to CharDirectionality.StrongLeftToRight,

/* SYLOTI NAGRI SIGN HASANTA ... SYLOTI NAGRI SIGN HASANTA */
    0xA806..0xA806 to CharDirectionality.Weak,

/* SYLOTI NAGRI LETTER KO ... SYLOTI NAGRI LETTER GHO */
    0xA807..0xA80A to CharDirectionality.StrongLeftToRight,

/* SYLOTI NAGRI SIGN ANUSVARA ... SYLOTI NAGRI SIGN ANUSVARA */
    0xA80B..0xA80B to CharDirectionality.Weak,

/* SYLOTI NAGRI LETTER CO ... SYLOTI NAGRI VOWEL SIGN I */
    0xA80C..0xA824 to CharDirectionality.StrongLeftToRight,

/* SYLOTI NAGRI VOWEL SIGN U ... SYLOTI NAGRI VOWEL SIGN E */
    0xA825..0xA826 to CharDirectionality.Weak,

/* SYLOTI NAGRI VOWEL SIGN OO ... SYLOTI NAGRI VOWEL SIGN OO */
    0xA827..0xA827 to CharDirectionality.StrongLeftToRight,

/* SYLOTI NAGRI POETRY MARK-1 ... SYLOTI NAGRI POETRY MARK-4 */
    0xA828..0xA82B to CharDirectionality.Neutral,

/* SYLOTI NAGRI SIGN ALTERNATE HASANTA ... SYLOTI NAGRI SIGN ALTERNATE HASANTA */
    0xA82C..0xA82C to CharDirectionality.Weak,

/* NORTH INDIC FRACTION ONE QUARTER ... NORTH INDIC PLACEHOLDER MARK */
    0xA830..0xA837 to CharDirectionality.StrongLeftToRight,

/* NORTH INDIC RUPEE MARK ... NORTH INDIC QUANTITY MARK */
    0xA838..0xA839 to CharDirectionality.Weak,

/* PHAGS-PA LETTER KA ... PHAGS-PA LETTER CANDRABINDU */
    0xA840..0xA873 to CharDirectionality.StrongLeftToRight,

/* PHAGS-PA SINGLE HEAD MARK ... PHAGS-PA MARK DOUBLE SHAD */
    0xA874..0xA877 to CharDirectionality.Neutral,

/* SAURASHTRA SIGN ANUSVARA ... SAURASHTRA VOWEL SIGN AU */
    0xA880..0xA8C3 to CharDirectionality.StrongLeftToRight,

/* SAURASHTRA SIGN VIRAMA ... SAURASHTRA SIGN CANDRABINDU */
    0xA8C4..0xA8C5 to CharDirectionality.Weak,

/* SAURASHTRA DANDA ... SAURASHTRA DIGIT NINE */
    0xA8CE..0xA8D9 to CharDirectionality.StrongLeftToRight,

/* COMBINING DEVANAGARI DIGIT ZERO ... COMBINING DEVANAGARI SIGN AVAGRAHA */
    0xA8E0..0xA8F1 to CharDirectionality.Weak,

/* DEVANAGARI SIGN SPACING CANDRABINDU ... DEVANAGARI LETTER AY */
    0xA8F2..0xA8FE to CharDirectionality.StrongLeftToRight,

/* DEVANAGARI VOWEL SIGN AY ... DEVANAGARI VOWEL SIGN AY */
    0xA8FF..0xA8FF to CharDirectionality.Weak,

/* KAYAH LI DIGIT ZERO ... KAYAH LI LETTER OO */
    0xA900..0xA925 to CharDirectionality.StrongLeftToRight,

/* KAYAH LI VOWEL UE ... KAYAH LI TONE CALYA PLOPHU */
    0xA926..0xA92D to CharDirectionality.Weak,

/* KAYAH LI SIGN CWI ... REJANG LETTER A */
    0xA92E..0xA946 to CharDirectionality.StrongLeftToRight,

/* REJANG VOWEL SIGN I ... REJANG CONSONANT SIGN R */
    0xA947..0xA951 to CharDirectionality.Weak,

/* REJANG CONSONANT SIGN H ... HANGUL CHOSEONG SSANGYEORINHIEUH */
    0xA952..0xA97C to CharDirectionality.StrongLeftToRight,

/* JAVANESE SIGN PANYANGGA ... JAVANESE SIGN LAYAR */
    0xA980..0xA982 to CharDirectionality.Weak,

/* JAVANESE SIGN WIGNYAN ... JAVANESE LETTER HA */
    0xA983..0xA9B2 to CharDirectionality.StrongLeftToRight,

/* JAVANESE SIGN CECAK TELU ... JAVANESE SIGN CECAK TELU */
    0xA9B3..0xA9B3 to CharDirectionality.Weak,

/* JAVANESE VOWEL SIGN TARUNG ... JAVANESE VOWEL SIGN TOLONG */
    0xA9B4..0xA9B5 to CharDirectionality.StrongLeftToRight,

/* JAVANESE VOWEL SIGN WULU ... JAVANESE VOWEL SIGN SUKU MENDUT */
    0xA9B6..0xA9B9 to CharDirectionality.Weak,

/* JAVANESE VOWEL SIGN TALING ... JAVANESE VOWEL SIGN DIRGA MURE */
    0xA9BA..0xA9BB to CharDirectionality.StrongLeftToRight,

/* JAVANESE VOWEL SIGN PEPET ... JAVANESE CONSONANT SIGN KERET */
    0xA9BC..0xA9BD to CharDirectionality.Weak,

/* JAVANESE CONSONANT SIGN PENGKAL ... MYANMAR LETTER SHAN BHA */
    0xA9BE..0xA9E4 to CharDirectionality.StrongLeftToRight,

/* MYANMAR SIGN SHAN SAW ... MYANMAR SIGN SHAN SAW */
    0xA9E5..0xA9E5 to CharDirectionality.Weak,

/* MYANMAR MODIFIER LETTER SHAN REDUPLICATION ... CHAM LETTER HA */
    0xA9E6..0xAA28 to CharDirectionality.StrongLeftToRight,

/* CHAM VOWEL SIGN AA ... CHAM VOWEL SIGN OE */
    0xAA29..0xAA2E to CharDirectionality.Weak,

/* CHAM VOWEL SIGN O ... CHAM VOWEL SIGN AI */
    0xAA2F..0xAA30 to CharDirectionality.StrongLeftToRight,

/* CHAM VOWEL SIGN AU ... CHAM VOWEL SIGN UE */
    0xAA31..0xAA32 to CharDirectionality.Weak,

/* CHAM CONSONANT SIGN YA ... CHAM CONSONANT SIGN RA */
    0xAA33..0xAA34 to CharDirectionality.StrongLeftToRight,

/* CHAM CONSONANT SIGN LA ... CHAM CONSONANT SIGN WA */
    0xAA35..0xAA36 to CharDirectionality.Weak,

/* CHAM LETTER FINAL K ... CHAM LETTER FINAL NG */
    0xAA40..0xAA42 to CharDirectionality.StrongLeftToRight,

/* CHAM CONSONANT SIGN FINAL NG ... CHAM CONSONANT SIGN FINAL NG */
    0xAA43..0xAA43 to CharDirectionality.Weak,

/* CHAM LETTER FINAL CH ... CHAM LETTER FINAL SS */
    0xAA44..0xAA4B to CharDirectionality.StrongLeftToRight,

/* CHAM CONSONANT SIGN FINAL M ... CHAM CONSONANT SIGN FINAL M */
    0xAA4C..0xAA4C to CharDirectionality.Weak,

/* CHAM CONSONANT SIGN FINAL H ... MYANMAR SIGN PAO KAREN TONE */
    0xAA4D..0xAA7B to CharDirectionality.StrongLeftToRight,

/* MYANMAR SIGN TAI LAING TONE-2 ... MYANMAR SIGN TAI LAING TONE-2 */
    0xAA7C..0xAA7C to CharDirectionality.Weak,

/* MYANMAR SIGN TAI LAING TONE-5 ... TAI VIET LETTER HIGH O */
    0xAA7D..0xAAAF to CharDirectionality.StrongLeftToRight,

/* TAI VIET MAI KANG ... TAI VIET MAI KANG */
    0xAAB0..0xAAB0 to CharDirectionality.Weak,

/* TAI VIET VOWEL AA ... TAI VIET VOWEL AA */
    0xAAB1..0xAAB1 to CharDirectionality.StrongLeftToRight,

/* TAI VIET VOWEL I ... TAI VIET VOWEL U */
    0xAAB2..0xAAB4 to CharDirectionality.Weak,

/* TAI VIET VOWEL E ... TAI VIET VOWEL O */
    0xAAB5..0xAAB6 to CharDirectionality.StrongLeftToRight,

/* TAI VIET MAI KHIT ... TAI VIET VOWEL IA */
    0xAAB7..0xAAB8 to CharDirectionality.Weak,

/* TAI VIET VOWEL UEA ... TAI VIET VOWEL AN */
    0xAAB9..0xAABD to CharDirectionality.StrongLeftToRight,

/* TAI VIET VOWEL AM ... TAI VIET TONE MAI EK */
    0xAABE..0xAABF to CharDirectionality.Weak,

/* TAI VIET TONE MAI NUENG ... TAI VIET TONE MAI NUENG */
    0xAAC0..0xAAC0 to CharDirectionality.StrongLeftToRight,

/* TAI VIET TONE MAI THO ... TAI VIET TONE MAI THO */
    0xAAC1..0xAAC1 to CharDirectionality.Weak,

/* TAI VIET TONE MAI SONG ... MEETEI MAYEK VOWEL SIGN II */
    0xAAC2..0xAAEB to CharDirectionality.StrongLeftToRight,

/* MEETEI MAYEK VOWEL SIGN UU ... MEETEI MAYEK VOWEL SIGN AAI */
    0xAAEC..0xAAED to CharDirectionality.Weak,

/* MEETEI MAYEK VOWEL SIGN AU ... MEETEI MAYEK VOWEL SIGN VISARGA */
    0xAAEE..0xAAF5 to CharDirectionality.StrongLeftToRight,

/* MEETEI MAYEK VIRAMA ... MEETEI MAYEK VIRAMA */
    0xAAF6..0xAAF6 to CharDirectionality.Weak,

/* ETHIOPIC SYLLABLE TTHU ... MODIFIER LETTER SMALL TURNED W */
    0xAB01..0xAB69 to CharDirectionality.StrongLeftToRight,

/* MODIFIER LETTER LEFT TACK ... MODIFIER LETTER RIGHT TACK */
    0xAB6A..0xAB6B to CharDirectionality.Neutral,

/* CHEROKEE SMALL LETTER A ... MEETEI MAYEK VOWEL SIGN INAP */
    0xAB70..0xABE4 to CharDirectionality.StrongLeftToRight,

/* MEETEI MAYEK VOWEL SIGN ANAP ... MEETEI MAYEK VOWEL SIGN ANAP */
    0xABE5..0xABE5 to CharDirectionality.Weak,

/* MEETEI MAYEK VOWEL SIGN YENAP ... MEETEI MAYEK VOWEL SIGN SOUNAP */
    0xABE6..0xABE7 to CharDirectionality.StrongLeftToRight,

/* MEETEI MAYEK VOWEL SIGN UNAP ... MEETEI MAYEK VOWEL SIGN UNAP */
    0xABE8..0xABE8 to CharDirectionality.Weak,

/* MEETEI MAYEK VOWEL SIGN CHEINAP ... MEETEI MAYEK LUM IYEK */
    0xABE9..0xABEC to CharDirectionality.StrongLeftToRight,

/* MEETEI MAYEK APUN IYEK ... MEETEI MAYEK APUN IYEK */
    0xABED..0xABED to CharDirectionality.Weak,

/* MEETEI MAYEK DIGIT ZERO ... ARMENIAN SMALL LIGATURE MEN XEH */
    0xABF0..0xFB17 to CharDirectionality.StrongLeftToRight,

/* HEBREW LETTER YOD WITH HIRIQ ... HEBREW LETTER YOD WITH HIRIQ */
    0xFB1D..0xFB1D to CharDirectionality.StrongRightToLeft,

/* HEBREW POINT JUDEO-SPANISH VARIKA ... HEBREW POINT JUDEO-SPANISH VARIKA */
    0xFB1E..0xFB1E to CharDirectionality.Weak,

/* HEBREW LIGATURE YIDDISH YOD YOD PATAH ... HEBREW LETTER WIDE TAV */
    0xFB1F..0xFB28 to CharDirectionality.StrongRightToLeft,

/* HEBREW LETTER ALTERNATIVE PLUS SIGN ... HEBREW LETTER ALTERNATIVE PLUS SIGN */
    0xFB29..0xFB29 to CharDirectionality.Weak,

/* HEBREW LETTER SHIN WITH SHIN DOT ... ARABIC LIGATURE ALEF WITH FATHATAN ISOLATED FORM */
    0xFB2A..0xFD3D to CharDirectionality.StrongRightToLeft,

/* ORNATE LEFT PARENTHESIS ... ARABIC LIGATURE RAHIMAHUM ALLAAH */
    0xFD3E..0xFD4F to CharDirectionality.Neutral,

/* ARABIC LIGATURE TEH WITH JEEM WITH MEEM INITIAL FORM ... ARABIC LIGATURE NOON WITH JEEM WITH YEH FINAL FORM */
    0xFD50..0xFDC7 to CharDirectionality.StrongRightToLeft,

/* ARABIC LIGATURE SALAAMUHU ALAYNAA ... ARABIC LIGATURE SALAAMUHU ALAYNAA */
    0xFDCF..0xFDCF to CharDirectionality.Neutral,

/* ARABIC LIGATURE SALLA USED AS KORANIC STOP SIGN ISOLATED FORM ... RIAL SIGN */
    0xFDF0..0xFDFC to CharDirectionality.StrongRightToLeft,

/* ARABIC LIGATURE BISMILLAH AR-RAHMAN AR-RAHEEM ... ARABIC LIGATURE AZZA WA JALL */
    0xFDFD..0xFDFF to CharDirectionality.Neutral,

/* VARIATION SELECTOR-1 ... VARIATION SELECTOR-16 */
    0xFE00..0xFE0F to CharDirectionality.Weak,

/* PRESENTATION FORM FOR VERTICAL COMMA ... PRESENTATION FORM FOR VERTICAL HORIZONTAL ELLIPSIS */
    0xFE10..0xFE19 to CharDirectionality.Neutral,

/* COMBINING LIGATURE LEFT HALF ... COMBINING CYRILLIC TITLO RIGHT HALF */
    0xFE20..0xFE2F to CharDirectionality.Weak,

/* PRESENTATION FORM FOR VERTICAL TWO DOT LEADER ... WAVY LOW LINE */
    0xFE30..0xFE4F to CharDirectionality.Neutral,

/* SMALL COMMA ... SMALL COMMA */
    0xFE50..0xFE50 to CharDirectionality.Weak,

/* SMALL IDEOGRAPHIC COMMA ... SMALL IDEOGRAPHIC COMMA */
    0xFE51..0xFE51 to CharDirectionality.Neutral,

/* SMALL FULL STOP ... SMALL FULL STOP */
    0xFE52..0xFE52 to CharDirectionality.Weak,

/* SMALL SEMICOLON ... SMALL SEMICOLON */
    0xFE54..0xFE54 to CharDirectionality.Neutral,

/* SMALL COLON ... SMALL COLON */
    0xFE55..0xFE55 to CharDirectionality.Weak,

/* SMALL QUESTION MARK ... SMALL RIGHT TORTOISE SHELL BRACKET */
    0xFE56..0xFE5E to CharDirectionality.Neutral,

/* SMALL NUMBER SIGN ... SMALL NUMBER SIGN */
    0xFE5F..0xFE5F to CharDirectionality.Weak,

/* SMALL AMPERSAND ... SMALL ASTERISK */
    0xFE60..0xFE61 to CharDirectionality.Neutral,

/* SMALL PLUS SIGN ... SMALL HYPHEN-MINUS */
    0xFE62..0xFE63 to CharDirectionality.Weak,

/* SMALL LESS-THAN SIGN ... SMALL REVERSE SOLIDUS */
    0xFE64..0xFE68 to CharDirectionality.Neutral,

/* SMALL DOLLAR SIGN ... SMALL PERCENT SIGN */
    0xFE69..0xFE6A to CharDirectionality.Weak,

/* SMALL COMMERCIAL AT ... SMALL COMMERCIAL AT */
    0xFE6B..0xFE6B to CharDirectionality.Neutral,

/* ARABIC FATHATAN ISOLATED FORM ... ARABIC LIGATURE LAM WITH ALEF FINAL FORM */
    0xFE70..0xFEFC to CharDirectionality.StrongRightToLeft,

/* ZERO WIDTH NO-BREAK SPACE ... ZERO WIDTH NO-BREAK SPACE */
    0xFEFF..0xFEFF to CharDirectionality.Weak,

/* FULLWIDTH EXCLAMATION MARK ... FULLWIDTH QUOTATION MARK */
    0xFF01..0xFF02 to CharDirectionality.Neutral,

/* FULLWIDTH NUMBER SIGN ... FULLWIDTH PERCENT SIGN */
    0xFF03..0xFF05 to CharDirectionality.Weak,

/* FULLWIDTH AMPERSAND ... FULLWIDTH ASTERISK */
    0xFF06..0xFF0A to CharDirectionality.Neutral,

/* FULLWIDTH PLUS SIGN ... FULLWIDTH COLON */
    0xFF0B..0xFF1A to CharDirectionality.Weak,

/* FULLWIDTH SEMICOLON ... FULLWIDTH COMMERCIAL AT */
    0xFF1B..0xFF20 to CharDirectionality.Neutral,

/* FULLWIDTH LATIN CAPITAL LETTER A ... FULLWIDTH LATIN CAPITAL LETTER Z */
    0xFF21..0xFF3A to CharDirectionality.StrongLeftToRight,

/* FULLWIDTH LEFT SQUARE BRACKET ... FULLWIDTH GRAVE ACCENT */
    0xFF3B..0xFF40 to CharDirectionality.Neutral,

/* FULLWIDTH LATIN SMALL LETTER A ... FULLWIDTH LATIN SMALL LETTER Z */
    0xFF41..0xFF5A to CharDirectionality.StrongLeftToRight,

/* FULLWIDTH LEFT CURLY BRACKET ... HALFWIDTH KATAKANA MIDDLE DOT */
    0xFF5B..0xFF65 to CharDirectionality.Neutral,

/* HALFWIDTH KATAKANA LETTER WO ... HALFWIDTH HANGUL LETTER I */
    0xFF66..0xFFDC to CharDirectionality.StrongLeftToRight,

/* FULLWIDTH CENT SIGN ... FULLWIDTH POUND SIGN */
    0xFFE0..0xFFE1 to CharDirectionality.Weak,

/* FULLWIDTH NOT SIGN ... FULLWIDTH BROKEN BAR */
    0xFFE2..0xFFE4 to CharDirectionality.Neutral,

/* FULLWIDTH YEN SIGN ... FULLWIDTH WON SIGN */
    0xFFE5..0xFFE6 to CharDirectionality.Weak,

/* HALFWIDTH FORMS LIGHT VERTICAL ... REPLACEMENT CHARACTER */
    0xFFE8..0xFFFD to CharDirectionality.Neutral,

/* LINEAR B SYLLABLE B008 A ... AEGEAN WORD SEPARATOR LINE */
    0x10000..0x10100 to CharDirectionality.StrongLeftToRight,

/* AEGEAN WORD SEPARATOR DOT ... AEGEAN WORD SEPARATOR DOT */
    0x10101..0x10101 to CharDirectionality.Neutral,

/* AEGEAN CHECK MARK ... AEGEAN MEASURE THIRD SUBUNIT */
    0x10102..0x1013F to CharDirectionality.StrongLeftToRight,

/* GREEK ACROPHONIC ATTIC ONE QUARTER ... GREEK SINUSOID SIGN */
    0x10140..0x1018C to CharDirectionality.Neutral,

/* GREEK INDICTION SIGN ... NOMISMA SIGN */
    0x1018D..0x1018E to CharDirectionality.StrongLeftToRight,

/* ROMAN SEXTANS SIGN ... GREEK SYMBOL TAU RHO */
    0x10190..0x101A0 to CharDirectionality.Neutral,

/* PHAISTOS DISC SIGN PEDESTRIAN ... PHAISTOS DISC SIGN WAVY BAND */
    0x101D0..0x101FC to CharDirectionality.StrongLeftToRight,

/* PHAISTOS DISC SIGN COMBINING OBLIQUE STROKE ... PHAISTOS DISC SIGN COMBINING OBLIQUE STROKE */
    0x101FD..0x101FD to CharDirectionality.Weak,

/* LYCIAN LETTER A ... CARIAN LETTER UUU3 */
    0x10280..0x102D0 to CharDirectionality.StrongLeftToRight,

/* COPTIC EPACT THOUSANDS MARK ... COPTIC EPACT NUMBER NINE HUNDRED */
    0x102E0..0x102FB to CharDirectionality.Weak,

/* OLD ITALIC LETTER A ... OLD PERMIC LETTER IA */
    0x10300..0x10375 to CharDirectionality.StrongLeftToRight,

/* COMBINING OLD PERMIC LETTER AN ... COMBINING OLD PERMIC LETTER SII */
    0x10376..0x1037A to CharDirectionality.Weak,

/* UGARITIC LETTER ALPA ... MODIFIER LETTER SMALL S WITH CURL */
    0x10380..0x107BA to CharDirectionality.StrongLeftToRight,

/* CYPRIOT SYLLABLE A ... PHOENICIAN NUMBER THREE */
    0x10800..0x1091B to CharDirectionality.StrongRightToLeft,

/* PHOENICIAN WORD SEPARATOR ... PHOENICIAN WORD SEPARATOR */
    0x1091F..0x1091F to CharDirectionality.Neutral,

/* LYDIAN LETTER A ... KHAROSHTHI LETTER A */
    0x10920..0x10A00 to CharDirectionality.StrongRightToLeft,

/* KHAROSHTHI VOWEL SIGN I ... KHAROSHTHI SIGN VISARGA */
    0x10A01..0x10A0F to CharDirectionality.Weak,

/* KHAROSHTHI LETTER KA ... KHAROSHTHI LETTER VHA */
    0x10A10..0x10A35 to CharDirectionality.StrongRightToLeft,

/* KHAROSHTHI SIGN BAR ABOVE ... KHAROSHTHI VIRAMA */
    0x10A38..0x10A3F to CharDirectionality.Weak,

/* KHAROSHTHI DIGIT ONE ... MANICHAEAN LETTER TAW */
    0x10A40..0x10AE4 to CharDirectionality.StrongRightToLeft,

/* MANICHAEAN ABBREVIATION MARK ABOVE ... MANICHAEAN ABBREVIATION MARK BELOW */
    0x10AE5..0x10AE6 to CharDirectionality.Weak,

/* MANICHAEAN NUMBER ONE ... AVESTAN LETTER HE */
    0x10AEB..0x10B35 to CharDirectionality.StrongRightToLeft,

/* AVESTAN ABBREVIATION MARK ... LARGE ONE RING OVER TWO RINGS PUNCTUATION */
    0x10B39..0x10B3F to CharDirectionality.Neutral,

/* INSCRIPTIONAL PARTHIAN LETTER ALEPH ... HANIFI ROHINGYA MARK NA KHONNA */
    0x10B40..0x10D23 to CharDirectionality.StrongRightToLeft,

/* HANIFI ROHINGYA SIGN HARBAHAY ... RUMI FRACTION TWO THIRDS */
    0x10D24..0x10E7E to CharDirectionality.Weak,

/* YEZIDI LETTER ELIF ... YEZIDI LETTER ET */
    0x10E80..0x10EA9 to CharDirectionality.StrongRightToLeft,

/* YEZIDI COMBINING HAMZA MARK ... YEZIDI COMBINING MADDA MARK */
    0x10EAB..0x10EAC to CharDirectionality.Weak,

/* YEZIDI HYPHENATION MARK ... YEZIDI LETTER YOT WITH CIRCUMFLEX ABOVE */
    0x10EAD..0x10EB1 to CharDirectionality.StrongRightToLeft,

/* ARABIC SMALL LOW WORD SAKTA ... ARABIC SMALL LOW WORD MADDA */
    0x10EFD..0x10EFF to CharDirectionality.Weak,

/* OLD SOGDIAN LETTER ALEPH ... SOGDIAN INDEPENDENT SHIN */
    0x10F00..0x10F45 to CharDirectionality.StrongRightToLeft,

/* SOGDIAN COMBINING DOT BELOW ... SOGDIAN COMBINING STROKE BELOW */
    0x10F46..0x10F50 to CharDirectionality.Weak,

/* SOGDIAN NUMBER ONE ... OLD UYGHUR LETTER LESH */
    0x10F51..0x10F81 to CharDirectionality.StrongRightToLeft,

/* OLD UYGHUR COMBINING DOT ABOVE ... OLD UYGHUR COMBINING TWO DOTS BELOW */
    0x10F82..0x10F85 to CharDirectionality.Weak,

/* OLD UYGHUR PUNCTUATION BAR ... ELYMAIC LIGATURE ZAYIN-YODH */
    0x10F86..0x10FF6 to CharDirectionality.StrongRightToLeft,

/* BRAHMI SIGN CANDRABINDU ... BRAHMI SIGN CANDRABINDU */
    0x11000..0x11000 to CharDirectionality.StrongLeftToRight,

/* BRAHMI SIGN ANUSVARA ... BRAHMI SIGN ANUSVARA */
    0x11001..0x11001 to CharDirectionality.Weak,

/* BRAHMI SIGN VISARGA ... BRAHMI LETTER OLD TAMIL NNNA */
    0x11002..0x11037 to CharDirectionality.StrongLeftToRight,

/* BRAHMI VOWEL SIGN AA ... BRAHMI VIRAMA */
    0x11038..0x11046 to CharDirectionality.Weak,

/* BRAHMI DANDA ... BRAHMI PUNCTUATION LOTUS */
    0x11047..0x1104D to CharDirectionality.StrongLeftToRight,

/* BRAHMI NUMBER ONE ... BRAHMI NUMBER ONE THOUSAND */
    0x11052..0x11065 to CharDirectionality.Neutral,

/* BRAHMI DIGIT ZERO ... BRAHMI DIGIT NINE */
    0x11066..0x1106F to CharDirectionality.StrongLeftToRight,

/* BRAHMI SIGN OLD TAMIL VIRAMA ... BRAHMI SIGN OLD TAMIL VIRAMA */
    0x11070..0x11070 to CharDirectionality.Weak,

/* BRAHMI LETTER OLD TAMIL SHORT E ... BRAHMI LETTER OLD TAMIL SHORT O */
    0x11071..0x11072 to CharDirectionality.StrongLeftToRight,

/* BRAHMI VOWEL SIGN OLD TAMIL SHORT E ... BRAHMI VOWEL SIGN OLD TAMIL SHORT O */
    0x11073..0x11074 to CharDirectionality.Weak,

/* BRAHMI LETTER OLD TAMIL LLA ... BRAHMI LETTER OLD TAMIL LLA */
    0x11075..0x11075 to CharDirectionality.StrongLeftToRight,

/* BRAHMI NUMBER JOINER ... KAITHI SIGN ANUSVARA */
    0x1107F..0x11081 to CharDirectionality.Weak,

/* KAITHI SIGN VISARGA ... KAITHI VOWEL SIGN II */
    0x11082..0x110B2 to CharDirectionality.StrongLeftToRight,

/* KAITHI VOWEL SIGN U ... KAITHI VOWEL SIGN AI */
    0x110B3..0x110B6 to CharDirectionality.Weak,

/* KAITHI VOWEL SIGN O ... KAITHI VOWEL SIGN AU */
    0x110B7..0x110B8 to CharDirectionality.StrongLeftToRight,

/* KAITHI SIGN VIRAMA ... KAITHI SIGN NUKTA */
    0x110B9..0x110BA to CharDirectionality.Weak,

/* KAITHI ABBREVIATION SIGN ... KAITHI DOUBLE DANDA */
    0x110BB..0x110C1 to CharDirectionality.StrongLeftToRight,

/* KAITHI VOWEL SIGN VOCALIC R ... KAITHI VOWEL SIGN VOCALIC R */
    0x110C2..0x110C2 to CharDirectionality.Weak,

/* KAITHI NUMBER SIGN ABOVE ... SORA SOMPENG DIGIT NINE */
    0x110CD..0x110F9 to CharDirectionality.StrongLeftToRight,

/* CHAKMA SIGN CANDRABINDU ... CHAKMA SIGN VISARGA */
    0x11100..0x11102 to CharDirectionality.Weak,

/* CHAKMA LETTER AA ... CHAKMA LETTER HAA */
    0x11103..0x11126 to CharDirectionality.StrongLeftToRight,

/* CHAKMA VOWEL SIGN A ... CHAKMA VOWEL SIGN UU */
    0x11127..0x1112B to CharDirectionality.Weak,

/* CHAKMA VOWEL SIGN E ... CHAKMA VOWEL SIGN E */
    0x1112C..0x1112C to CharDirectionality.StrongLeftToRight,

/* CHAKMA VOWEL SIGN AI ... CHAKMA MAAYYAA */
    0x1112D..0x11134 to CharDirectionality.Weak,

/* CHAKMA DIGIT ZERO ... MAHAJANI LETTER RRA */
    0x11136..0x11172 to CharDirectionality.StrongLeftToRight,

/* MAHAJANI SIGN NUKTA ... MAHAJANI SIGN NUKTA */
    0x11173..0x11173 to CharDirectionality.Weak,

/* MAHAJANI ABBREVIATION SIGN ... MAHAJANI LIGATURE SHRI */
    0x11174..0x11176 to CharDirectionality.StrongLeftToRight,

/* SHARADA SIGN CANDRABINDU ... SHARADA SIGN ANUSVARA */
    0x11180..0x11181 to CharDirectionality.Weak,

/* SHARADA SIGN VISARGA ... SHARADA VOWEL SIGN II */
    0x11182..0x111B5 to CharDirectionality.StrongLeftToRight,

/* SHARADA VOWEL SIGN U ... SHARADA VOWEL SIGN O */
    0x111B6..0x111BE to CharDirectionality.Weak,

/* SHARADA VOWEL SIGN AU ... SHARADA SEPARATOR */
    0x111BF..0x111C8 to CharDirectionality.StrongLeftToRight,

/* SHARADA SANDHI MARK ... SHARADA EXTRA SHORT VOWEL MARK */
    0x111C9..0x111CC to CharDirectionality.Weak,

/* SHARADA SUTRA MARK ... SHARADA VOWEL SIGN PRISHTHAMATRA E */
    0x111CD..0x111CE to CharDirectionality.StrongLeftToRight,

/* SHARADA SIGN INVERTED CANDRABINDU ... SHARADA SIGN INVERTED CANDRABINDU */
    0x111CF..0x111CF to CharDirectionality.Weak,

/* SHARADA DIGIT ZERO ... KHOJKI VOWEL SIGN II */
    0x111D0..0x1122E to CharDirectionality.StrongLeftToRight,

/* KHOJKI VOWEL SIGN U ... KHOJKI VOWEL SIGN AI */
    0x1122F..0x11231 to CharDirectionality.Weak,

/* KHOJKI VOWEL SIGN O ... KHOJKI VOWEL SIGN AU */
    0x11232..0x11233 to CharDirectionality.StrongLeftToRight,

/* KHOJKI SIGN ANUSVARA ... KHOJKI SIGN ANUSVARA */
    0x11234..0x11234 to CharDirectionality.Weak,

/* KHOJKI SIGN VIRAMA ... KHOJKI SIGN VIRAMA */
    0x11235..0x11235 to CharDirectionality.StrongLeftToRight,

/* KHOJKI SIGN NUKTA ... KHOJKI SIGN SHADDA */
    0x11236..0x11237 to CharDirectionality.Weak,

/* KHOJKI DANDA ... KHOJKI ABBREVIATION SIGN */
    0x11238..0x1123D to CharDirectionality.StrongLeftToRight,

/* KHOJKI SIGN SUKUN ... KHOJKI SIGN SUKUN */
    0x1123E..0x1123E to CharDirectionality.Weak,

/* KHOJKI LETTER QA ... KHOJKI LETTER SHORT I */
    0x1123F..0x11240 to CharDirectionality.StrongLeftToRight,

/* KHOJKI VOWEL SIGN VOCALIC R ... KHOJKI VOWEL SIGN VOCALIC R */
    0x11241..0x11241 to CharDirectionality.Weak,

/* MULTANI LETTER A ... KHUDAWADI LETTER HA */
    0x11280..0x112DE to CharDirectionality.StrongLeftToRight,

/* KHUDAWADI SIGN ANUSVARA ... KHUDAWADI SIGN ANUSVARA */
    0x112DF..0x112DF to CharDirectionality.Weak,

/* KHUDAWADI VOWEL SIGN AA ... KHUDAWADI VOWEL SIGN II */
    0x112E0..0x112E2 to CharDirectionality.StrongLeftToRight,

/* KHUDAWADI VOWEL SIGN U ... KHUDAWADI SIGN VIRAMA */
    0x112E3..0x112EA to CharDirectionality.Weak,

/* KHUDAWADI DIGIT ZERO ... KHUDAWADI DIGIT NINE */
    0x112F0..0x112F9 to CharDirectionality.StrongLeftToRight,

/* GRANTHA SIGN COMBINING ANUSVARA ABOVE ... GRANTHA SIGN CANDRABINDU */
    0x11300..0x11301 to CharDirectionality.Weak,

/* GRANTHA SIGN ANUSVARA ... GRANTHA LETTER HA */
    0x11302..0x11339 to CharDirectionality.StrongLeftToRight,

/* COMBINING BINDU BELOW ... GRANTHA SIGN NUKTA */
    0x1133B..0x1133C to CharDirectionality.Weak,

/* GRANTHA SIGN AVAGRAHA ... GRANTHA VOWEL SIGN I */
    0x1133D..0x1133F to CharDirectionality.StrongLeftToRight,

/* GRANTHA VOWEL SIGN II ... GRANTHA VOWEL SIGN II */
    0x11340..0x11340 to CharDirectionality.Weak,

/* GRANTHA VOWEL SIGN U ... GRANTHA VOWEL SIGN VOCALIC LL */
    0x11341..0x11363 to CharDirectionality.StrongLeftToRight,

/* COMBINING GRANTHA DIGIT ZERO ... COMBINING GRANTHA LETTER PA */
    0x11366..0x11374 to CharDirectionality.Weak,

/* NEWA LETTER A ... NEWA VOWEL SIGN II */
    0x11400..0x11437 to CharDirectionality.StrongLeftToRight,

/* NEWA VOWEL SIGN U ... NEWA VOWEL SIGN AI */
    0x11438..0x1143F to CharDirectionality.Weak,

/* NEWA VOWEL SIGN O ... NEWA VOWEL SIGN AU */
    0x11440..0x11441 to CharDirectionality.StrongLeftToRight,

/* NEWA SIGN VIRAMA ... NEWA SIGN ANUSVARA */
    0x11442..0x11444 to CharDirectionality.Weak,

/* NEWA SIGN VISARGA ... NEWA SIGN VISARGA */
    0x11445..0x11445 to CharDirectionality.StrongLeftToRight,

/* NEWA SIGN NUKTA ... NEWA SIGN NUKTA */
    0x11446..0x11446 to CharDirectionality.Weak,

/* NEWA SIGN AVAGRAHA ... NEWA INSERTION SIGN */
    0x11447..0x1145D to CharDirectionality.StrongLeftToRight,

/* NEWA SANDHI MARK ... NEWA SANDHI MARK */
    0x1145E..0x1145E to CharDirectionality.Weak,

/* NEWA LETTER VEDIC ANUSVARA ... TIRHUTA VOWEL SIGN II */
    0x1145F..0x114B2 to CharDirectionality.StrongLeftToRight,

/* TIRHUTA VOWEL SIGN U ... TIRHUTA VOWEL SIGN VOCALIC LL */
    0x114B3..0x114B8 to CharDirectionality.Weak,

/* TIRHUTA VOWEL SIGN E ... TIRHUTA VOWEL SIGN E */
    0x114B9..0x114B9 to CharDirectionality.StrongLeftToRight,

/* TIRHUTA VOWEL SIGN SHORT E ... TIRHUTA VOWEL SIGN SHORT E */
    0x114BA..0x114BA to CharDirectionality.Weak,

/* TIRHUTA VOWEL SIGN AI ... TIRHUTA VOWEL SIGN AU */
    0x114BB..0x114BE to CharDirectionality.StrongLeftToRight,

/* TIRHUTA SIGN CANDRABINDU ... TIRHUTA SIGN ANUSVARA */
    0x114BF..0x114C0 to CharDirectionality.Weak,

/* TIRHUTA SIGN VISARGA ... TIRHUTA SIGN VISARGA */
    0x114C1..0x114C1 to CharDirectionality.StrongLeftToRight,

/* TIRHUTA SIGN VIRAMA ... TIRHUTA SIGN NUKTA */
    0x114C2..0x114C3 to CharDirectionality.Weak,

/* TIRHUTA SIGN AVAGRAHA ... SIDDHAM VOWEL SIGN II */
    0x114C4..0x115B1 to CharDirectionality.StrongLeftToRight,

/* SIDDHAM VOWEL SIGN U ... SIDDHAM VOWEL SIGN VOCALIC RR */
    0x115B2..0x115B5 to CharDirectionality.Weak,

/* SIDDHAM VOWEL SIGN E ... SIDDHAM VOWEL SIGN AU */
    0x115B8..0x115BB to CharDirectionality.StrongLeftToRight,

/* SIDDHAM SIGN CANDRABINDU ... SIDDHAM SIGN ANUSVARA */
    0x115BC..0x115BD to CharDirectionality.Weak,

/* SIDDHAM SIGN VISARGA ... SIDDHAM SIGN VISARGA */
    0x115BE..0x115BE to CharDirectionality.StrongLeftToRight,

/* SIDDHAM SIGN VIRAMA ... SIDDHAM SIGN NUKTA */
    0x115BF..0x115C0 to CharDirectionality.Weak,

/* SIDDHAM SIGN SIDDHAM ... SIDDHAM LETTER ALTERNATE U */
    0x115C1..0x115DB to CharDirectionality.StrongLeftToRight,

/* SIDDHAM VOWEL SIGN ALTERNATE U ... SIDDHAM VOWEL SIGN ALTERNATE UU */
    0x115DC..0x115DD to CharDirectionality.Weak,

/* MODI LETTER A ... MODI VOWEL SIGN II */
    0x11600..0x11632 to CharDirectionality.StrongLeftToRight,

/* MODI VOWEL SIGN U ... MODI VOWEL SIGN AI */
    0x11633..0x1163A to CharDirectionality.Weak,

/* MODI VOWEL SIGN O ... MODI VOWEL SIGN AU */
    0x1163B..0x1163C to CharDirectionality.StrongLeftToRight,

/* MODI SIGN ANUSVARA ... MODI SIGN ANUSVARA */
    0x1163D..0x1163D to CharDirectionality.Weak,

/* MODI SIGN VISARGA ... MODI SIGN VISARGA */
    0x1163E..0x1163E to CharDirectionality.StrongLeftToRight,

/* MODI SIGN VIRAMA ... MODI SIGN ARDHACANDRA */
    0x1163F..0x11640 to CharDirectionality.Weak,

/* MODI DANDA ... MODI DIGIT NINE */
    0x11641..0x11659 to CharDirectionality.StrongLeftToRight,

/* MONGOLIAN BIRGA WITH ORNAMENT ... MONGOLIAN TURNED SWIRL BIRGA WITH DOUBLE ORNAMENT */
    0x11660..0x1166C to CharDirectionality.Neutral,

/* TAKRI LETTER A ... TAKRI LETTER RRA */
    0x11680..0x116AA to CharDirectionality.StrongLeftToRight,

/* TAKRI SIGN ANUSVARA ... TAKRI SIGN ANUSVARA */
    0x116AB..0x116AB to CharDirectionality.Weak,

/* TAKRI SIGN VISARGA ... TAKRI SIGN VISARGA */
    0x116AC..0x116AC to CharDirectionality.StrongLeftToRight,

/* TAKRI VOWEL SIGN AA ... TAKRI VOWEL SIGN AA */
    0x116AD..0x116AD to CharDirectionality.Weak,

/* TAKRI VOWEL SIGN I ... TAKRI VOWEL SIGN II */
    0x116AE..0x116AF to CharDirectionality.StrongLeftToRight,

/* TAKRI VOWEL SIGN U ... TAKRI VOWEL SIGN AU */
    0x116B0..0x116B5 to CharDirectionality.Weak,

/* TAKRI SIGN VIRAMA ... TAKRI SIGN VIRAMA */
    0x116B6..0x116B6 to CharDirectionality.StrongLeftToRight,

/* TAKRI SIGN NUKTA ... TAKRI SIGN NUKTA */
    0x116B7..0x116B7 to CharDirectionality.Weak,

/* TAKRI LETTER ARCHAIC KHA ... AHOM LETTER ALTERNATE BA */
    0x116B8..0x1171A to CharDirectionality.StrongLeftToRight,

/* AHOM CONSONANT SIGN MEDIAL LA ... AHOM CONSONANT SIGN MEDIAL LIGATING RA */
    0x1171D..0x1171F to CharDirectionality.Weak,

/* AHOM VOWEL SIGN A ... AHOM VOWEL SIGN AA */
    0x11720..0x11721 to CharDirectionality.StrongLeftToRight,

/* AHOM VOWEL SIGN I ... AHOM VOWEL SIGN UU */
    0x11722..0x11725 to CharDirectionality.Weak,

/* AHOM VOWEL SIGN E ... AHOM VOWEL SIGN E */
    0x11726..0x11726 to CharDirectionality.StrongLeftToRight,

/* AHOM VOWEL SIGN AW ... AHOM SIGN KILLER */
    0x11727..0x1172B to CharDirectionality.Weak,

/* AHOM DIGIT ZERO ... DOGRA VOWEL SIGN II */
    0x11730..0x1182E to CharDirectionality.StrongLeftToRight,

/* DOGRA VOWEL SIGN U ... DOGRA SIGN ANUSVARA */
    0x1182F..0x11837 to CharDirectionality.Weak,

/* DOGRA SIGN VISARGA ... DOGRA SIGN VISARGA */
    0x11838..0x11838 to CharDirectionality.StrongLeftToRight,

/* DOGRA SIGN VIRAMA ... DOGRA SIGN NUKTA */
    0x11839..0x1183A to CharDirectionality.Weak,

/* DOGRA ABBREVIATION SIGN ... DIVES AKURU VOWEL SIGN O */
    0x1183B..0x11938 to CharDirectionality.StrongLeftToRight,

/* DIVES AKURU SIGN ANUSVARA ... DIVES AKURU SIGN CANDRABINDU */
    0x1193B..0x1193C to CharDirectionality.Weak,

/* DIVES AKURU SIGN HALANTA ... DIVES AKURU SIGN HALANTA */
    0x1193D..0x1193D to CharDirectionality.StrongLeftToRight,

/* DIVES AKURU VIRAMA ... DIVES AKURU VIRAMA */
    0x1193E..0x1193E to CharDirectionality.Weak,

/* DIVES AKURU PREFIXED NASAL SIGN ... DIVES AKURU MEDIAL RA */
    0x1193F..0x11942 to CharDirectionality.StrongLeftToRight,

/* DIVES AKURU SIGN NUKTA ... DIVES AKURU SIGN NUKTA */
    0x11943..0x11943 to CharDirectionality.Weak,

/* DIVES AKURU DOUBLE DANDA ... NANDINAGARI VOWEL SIGN II */
    0x11944..0x119D3 to CharDirectionality.StrongLeftToRight,

/* NANDINAGARI VOWEL SIGN U ... NANDINAGARI VOWEL SIGN AI */
    0x119D4..0x119DB to CharDirectionality.Weak,

/* NANDINAGARI VOWEL SIGN O ... NANDINAGARI SIGN VISARGA */
    0x119DC..0x119DF to CharDirectionality.StrongLeftToRight,

/* NANDINAGARI SIGN VIRAMA ... NANDINAGARI SIGN VIRAMA */
    0x119E0..0x119E0 to CharDirectionality.Weak,

/* NANDINAGARI SIGN AVAGRAHA ... ZANABAZAR SQUARE LETTER A */
    0x119E1..0x11A00 to CharDirectionality.StrongLeftToRight,

/* ZANABAZAR SQUARE VOWEL SIGN I ... ZANABAZAR SQUARE VOWEL SIGN O */
    0x11A01..0x11A06 to CharDirectionality.Weak,

/* ZANABAZAR SQUARE VOWEL SIGN AI ... ZANABAZAR SQUARE VOWEL SIGN AU */
    0x11A07..0x11A08 to CharDirectionality.StrongLeftToRight,

/* ZANABAZAR SQUARE VOWEL SIGN REVERSED I ... ZANABAZAR SQUARE VOWEL LENGTH MARK */
    0x11A09..0x11A0A to CharDirectionality.Weak,

/* ZANABAZAR SQUARE LETTER KA ... ZANABAZAR SQUARE LETTER KSSA */
    0x11A0B..0x11A32 to CharDirectionality.StrongLeftToRight,

/* ZANABAZAR SQUARE FINAL CONSONANT MARK ... ZANABAZAR SQUARE SIGN ANUSVARA */
    0x11A33..0x11A38 to CharDirectionality.Weak,

/* ZANABAZAR SQUARE SIGN VISARGA ... ZANABAZAR SQUARE CLUSTER-INITIAL LETTER RA */
    0x11A39..0x11A3A to CharDirectionality.StrongLeftToRight,

/* ZANABAZAR SQUARE CLUSTER-FINAL LETTER YA ... ZANABAZAR SQUARE CLUSTER-FINAL LETTER VA */
    0x11A3B..0x11A3E to CharDirectionality.Weak,

/* ZANABAZAR SQUARE INITIAL HEAD MARK ... ZANABAZAR SQUARE CLOSING DOUBLE-LINED HEAD MARK */
    0x11A3F..0x11A46 to CharDirectionality.StrongLeftToRight,

/* ZANABAZAR SQUARE SUBJOINER ... ZANABAZAR SQUARE SUBJOINER */
    0x11A47..0x11A47 to CharDirectionality.Weak,

/* SOYOMBO LETTER A ... SOYOMBO LETTER A */
    0x11A50..0x11A50 to CharDirectionality.StrongLeftToRight,

/* SOYOMBO VOWEL SIGN I ... SOYOMBO VOWEL SIGN OE */
    0x11A51..0x11A56 to CharDirectionality.Weak,

/* SOYOMBO VOWEL SIGN AI ... SOYOMBO VOWEL SIGN AU */
    0x11A57..0x11A58 to CharDirectionality.StrongLeftToRight,

/* SOYOMBO VOWEL SIGN VOCALIC R ... SOYOMBO VOWEL LENGTH MARK */
    0x11A59..0x11A5B to CharDirectionality.Weak,

/* SOYOMBO LETTER KA ... SOYOMBO CLUSTER-INITIAL LETTER SA */
    0x11A5C..0x11A89 to CharDirectionality.StrongLeftToRight,

/* SOYOMBO FINAL CONSONANT SIGN G ... SOYOMBO SIGN ANUSVARA */
    0x11A8A..0x11A96 to CharDirectionality.Weak,

/* SOYOMBO SIGN VISARGA ... SOYOMBO SIGN VISARGA */
    0x11A97..0x11A97 to CharDirectionality.StrongLeftToRight,

/* SOYOMBO GEMINATION MARK ... SOYOMBO SUBJOINER */
    0x11A98..0x11A99 to CharDirectionality.Weak,

/* SOYOMBO MARK TSHEG ... BHAIKSUKI VOWEL SIGN AA */
    0x11A9A..0x11C2F to CharDirectionality.StrongLeftToRight,

/* BHAIKSUKI VOWEL SIGN I ... BHAIKSUKI SIGN ANUSVARA */
    0x11C30..0x11C3D to CharDirectionality.Weak,

/* BHAIKSUKI SIGN VISARGA ... MARCHEN LETTER A */
    0x11C3E..0x11C8F to CharDirectionality.StrongLeftToRight,

/* MARCHEN SUBJOINED LETTER KA ... MARCHEN SUBJOINED LETTER ZA */
    0x11C92..0x11CA7 to CharDirectionality.Weak,

/* MARCHEN SUBJOINED LETTER YA ... MARCHEN SUBJOINED LETTER YA */
    0x11CA9..0x11CA9 to CharDirectionality.StrongLeftToRight,

/* MARCHEN SUBJOINED LETTER RA ... MARCHEN VOWEL SIGN AA */
    0x11CAA..0x11CB0 to CharDirectionality.Weak,

/* MARCHEN VOWEL SIGN I ... MARCHEN VOWEL SIGN I */
    0x11CB1..0x11CB1 to CharDirectionality.StrongLeftToRight,

/* MARCHEN VOWEL SIGN U ... MARCHEN VOWEL SIGN E */
    0x11CB2..0x11CB3 to CharDirectionality.Weak,

/* MARCHEN VOWEL SIGN O ... MARCHEN VOWEL SIGN O */
    0x11CB4..0x11CB4 to CharDirectionality.StrongLeftToRight,

/* MARCHEN SIGN ANUSVARA ... MARCHEN SIGN CANDRABINDU */
    0x11CB5..0x11CB6 to CharDirectionality.Weak,

/* MASARAM GONDI LETTER A ... MASARAM GONDI LETTER TRA */
    0x11D00..0x11D30 to CharDirectionality.StrongLeftToRight,

/* MASARAM GONDI VOWEL SIGN AA ... MASARAM GONDI VIRAMA */
    0x11D31..0x11D45 to CharDirectionality.Weak,

/* MASARAM GONDI REPHA ... MASARAM GONDI REPHA */
    0x11D46..0x11D46 to CharDirectionality.StrongLeftToRight,

/* MASARAM GONDI RA-KARA ... MASARAM GONDI RA-KARA */
    0x11D47..0x11D47 to CharDirectionality.Weak,

/* MASARAM GONDI DIGIT ZERO ... GUNJALA GONDI VOWEL SIGN UU */
    0x11D50..0x11D8E to CharDirectionality.StrongLeftToRight,

/* GUNJALA GONDI VOWEL SIGN EE ... GUNJALA GONDI VOWEL SIGN AI */
    0x11D90..0x11D91 to CharDirectionality.Weak,

/* GUNJALA GONDI VOWEL SIGN OO ... GUNJALA GONDI VOWEL SIGN AU */
    0x11D93..0x11D94 to CharDirectionality.StrongLeftToRight,

/* GUNJALA GONDI SIGN ANUSVARA ... GUNJALA GONDI SIGN ANUSVARA */
    0x11D95..0x11D95 to CharDirectionality.Weak,

/* GUNJALA GONDI SIGN VISARGA ... GUNJALA GONDI SIGN VISARGA */
    0x11D96..0x11D96 to CharDirectionality.StrongLeftToRight,

/* GUNJALA GONDI VIRAMA ... GUNJALA GONDI VIRAMA */
    0x11D97..0x11D97 to CharDirectionality.Weak,

/* GUNJALA GONDI OM ... MAKASAR ANGKA */
    0x11D98..0x11EF2 to CharDirectionality.StrongLeftToRight,

/* MAKASAR VOWEL SIGN I ... MAKASAR VOWEL SIGN U */
    0x11EF3..0x11EF4 to CharDirectionality.Weak,

/* MAKASAR VOWEL SIGN E ... MAKASAR END OF SECTION */
    0x11EF5..0x11EF8 to CharDirectionality.StrongLeftToRight,

/* KAWI SIGN CANDRABINDU ... KAWI SIGN ANUSVARA */
    0x11F00..0x11F01 to CharDirectionality.Weak,

/* KAWI SIGN REPHA ... KAWI VOWEL SIGN ALTERNATE AA */
    0x11F02..0x11F35 to CharDirectionality.StrongLeftToRight,

/* KAWI VOWEL SIGN I ... KAWI VOWEL SIGN VOCALIC R */
    0x11F36..0x11F3A to CharDirectionality.Weak,

/* KAWI VOWEL SIGN E ... KAWI VOWEL SIGN AI */
    0x11F3E..0x11F3F to CharDirectionality.StrongLeftToRight,

/* KAWI VOWEL SIGN EU ... KAWI VOWEL SIGN EU */
    0x11F40..0x11F40 to CharDirectionality.Weak,

/* KAWI SIGN KILLER ... KAWI SIGN KILLER */
    0x11F41..0x11F41 to CharDirectionality.StrongLeftToRight,

/* KAWI CONJOINER ... KAWI CONJOINER */
    0x11F42..0x11F42 to CharDirectionality.Weak,

/* KAWI DANDA ... TAMIL FRACTION DOWNSCALING FACTOR KIIZH */
    0x11F43..0x11FD4 to CharDirectionality.StrongLeftToRight,

/* TAMIL SIGN NEL ... TAMIL SIGN MUKKURUNI */
    0x11FD5..0x11FDC to CharDirectionality.Neutral,

/* TAMIL SIGN KAACU ... TAMIL SIGN VARAAKAN */
    0x11FDD..0x11FE0 to CharDirectionality.Weak,

/* TAMIL SIGN PAARAM ... TAMIL SIGN VAKAIYARAA */
    0x11FE1..0x11FF1 to CharDirectionality.Neutral,

/* TAMIL PUNCTUATION END OF TEXT ... EGYPTIAN HIEROGLYPH END WALLED ENCLOSURE */
    0x11FFF..0x1343F to CharDirectionality.StrongLeftToRight,

/* EGYPTIAN HIEROGLYPH MIRROR HORIZONTALLY ... EGYPTIAN HIEROGLYPH MIRROR HORIZONTALLY */
    0x13440..0x13440 to CharDirectionality.Weak,

/* EGYPTIAN HIEROGLYPH FULL BLANK ... EGYPTIAN HIEROGLYPH WIDE LOST SIGN */
    0x13441..0x13446 to CharDirectionality.StrongLeftToRight,

/* EGYPTIAN HIEROGLYPH MODIFIER DAMAGED AT TOP START ... EGYPTIAN HIEROGLYPH MODIFIER DAMAGED */
    0x13447..0x13455 to CharDirectionality.Weak,

/* ANATOLIAN HIEROGLYPH A001 ... BASSA VAH LETTER I */
    0x14400..0x16AED to CharDirectionality.StrongLeftToRight,

/* BASSA VAH COMBINING HIGH TONE ... BASSA VAH COMBINING HIGH-LOW TONE */
    0x16AF0..0x16AF4 to CharDirectionality.Weak,

/* BASSA VAH FULL STOP ... PAHAWH HMONG CONSONANT CAU */
    0x16AF5..0x16B2F to CharDirectionality.StrongLeftToRight,

/* PAHAWH HMONG MARK CIM TUB ... PAHAWH HMONG MARK CIM TAUM */
    0x16B30..0x16B36 to CharDirectionality.Weak,

/* PAHAWH HMONG SIGN VOS THOM ... MIAO LETTER RTE */
    0x16B37..0x16F4A to CharDirectionality.StrongLeftToRight,

/* MIAO SIGN CONSONANT MODIFIER BAR ... MIAO SIGN CONSONANT MODIFIER BAR */
    0x16F4F..0x16F4F to CharDirectionality.Weak,

/* MIAO LETTER NASALIZATION ... MIAO VOWEL SIGN UI */
    0x16F50..0x16F87 to CharDirectionality.StrongLeftToRight,

/* MIAO TONE RIGHT ... MIAO TONE BELOW */
    0x16F8F..0x16F92 to CharDirectionality.Weak,

/* MIAO LETTER TONE-2 ... NUSHU ITERATION MARK */
    0x16F93..0x16FE1 to CharDirectionality.StrongLeftToRight,

/* OLD CHINESE HOOK MARK ... OLD CHINESE HOOK MARK */
    0x16FE2..0x16FE2 to CharDirectionality.Neutral,

/* OLD CHINESE ITERATION MARK ... OLD CHINESE ITERATION MARK */
    0x16FE3..0x16FE3 to CharDirectionality.StrongLeftToRight,

/* KHITAN SMALL SCRIPT FILLER ... KHITAN SMALL SCRIPT FILLER */
    0x16FE4..0x16FE4 to CharDirectionality.Weak,

/* VIETNAMESE ALTERNATE READING MARK CA ... DUPLOYAN SIGN O WITH CROSS */
    0x16FF0..0x1BC9C to CharDirectionality.StrongLeftToRight,

/* DUPLOYAN THICK LETTER SELECTOR ... DUPLOYAN DOUBLE MARK */
    0x1BC9D..0x1BC9E to CharDirectionality.Weak,

/* DUPLOYAN PUNCTUATION CHINOOK FULL STOP ... DUPLOYAN PUNCTUATION CHINOOK FULL STOP */
    0x1BC9F..0x1BC9F to CharDirectionality.StrongLeftToRight,

/* SHORTHAND FORMAT LETTER OVERLAP ... ZNAMENNY PRIZNAK MODIFIER ROG */
    0x1BCA0..0x1CF46 to CharDirectionality.Weak,

/* ZNAMENNY NEUME KRYUK ... MUSICAL SYMBOL COMBINING SPRECHGESANG STEM */
    0x1CF50..0x1D166 to CharDirectionality.StrongLeftToRight,

/* MUSICAL SYMBOL COMBINING TREMOLO-1 ... MUSICAL SYMBOL COMBINING TREMOLO-3 */
    0x1D167..0x1D169 to CharDirectionality.Weak,

/* MUSICAL SYMBOL FINGERED TREMOLO-1 ... MUSICAL SYMBOL COMBINING FLAG-5 */
    0x1D16A..0x1D172 to CharDirectionality.StrongLeftToRight,

/* MUSICAL SYMBOL BEGIN BEAM ... MUSICAL SYMBOL COMBINING LOURE */
    0x1D173..0x1D182 to CharDirectionality.Weak,

/* MUSICAL SYMBOL ARPEGGIATO UP ... MUSICAL SYMBOL ARPEGGIATO DOWN */
    0x1D183..0x1D184 to CharDirectionality.StrongLeftToRight,

/* MUSICAL SYMBOL COMBINING DOIT ... MUSICAL SYMBOL COMBINING TRIPLE TONGUE */
    0x1D185..0x1D18B to CharDirectionality.Weak,

/* MUSICAL SYMBOL RINFORZANDO ... MUSICAL SYMBOL DEGREE SLASH */
    0x1D18C..0x1D1A9 to CharDirectionality.StrongLeftToRight,

/* MUSICAL SYMBOL COMBINING DOWN BOW ... MUSICAL SYMBOL COMBINING SNAP PIZZICATO */
    0x1D1AA..0x1D1AD to CharDirectionality.Weak,

/* MUSICAL SYMBOL PEDAL MARK ... MUSICAL SYMBOL KIEVAN FLAT SIGN */
    0x1D1AE..0x1D1E8 to CharDirectionality.StrongLeftToRight,

/* MUSICAL SYMBOL SORI ... GREEK INSTRUMENTAL NOTATION SYMBOL-54 */
    0x1D1E9..0x1D241 to CharDirectionality.Neutral,

/* COMBINING GREEK MUSICAL TRISEME ... COMBINING GREEK MUSICAL PENTASEME */
    0x1D242..0x1D244 to CharDirectionality.Weak,

/* GREEK MUSICAL LEIMMA ... GREEK MUSICAL LEIMMA */
    0x1D245..0x1D245 to CharDirectionality.Neutral,

/* KAKTOVIK NUMERAL ZERO ... MAYAN NUMERAL NINETEEN */
    0x1D2C0..0x1D2F3 to CharDirectionality.StrongLeftToRight,

/* MONOGRAM FOR EARTH ... TETRAGRAM FOR FOSTERING */
    0x1D300..0x1D356 to CharDirectionality.Neutral,

/* COUNTING ROD UNIT DIGIT ONE ... MATHEMATICAL BOLD SMALL OMEGA */
    0x1D360..0x1D6DA to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL BOLD PARTIAL DIFFERENTIAL ... MATHEMATICAL BOLD PARTIAL DIFFERENTIAL */
    0x1D6DB..0x1D6DB to CharDirectionality.Neutral,

/* MATHEMATICAL BOLD EPSILON SYMBOL ... MATHEMATICAL ITALIC SMALL OMEGA */
    0x1D6DC..0x1D714 to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL ITALIC PARTIAL DIFFERENTIAL ... MATHEMATICAL ITALIC PARTIAL DIFFERENTIAL */
    0x1D715..0x1D715 to CharDirectionality.Neutral,

/* MATHEMATICAL ITALIC EPSILON SYMBOL ... MATHEMATICAL BOLD ITALIC SMALL OMEGA */
    0x1D716..0x1D74E to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL BOLD ITALIC PARTIAL DIFFERENTIAL ... MATHEMATICAL BOLD ITALIC PARTIAL DIFFERENTIAL */
    0x1D74F..0x1D74F to CharDirectionality.Neutral,

/* MATHEMATICAL BOLD ITALIC EPSILON SYMBOL ... MATHEMATICAL SANS-SERIF BOLD SMALL OMEGA */
    0x1D750..0x1D788 to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL SANS-SERIF BOLD PARTIAL DIFFERENTIAL ... MATHEMATICAL SANS-SERIF BOLD PARTIAL DIFFERENTIAL */
    0x1D789..0x1D789 to CharDirectionality.Neutral,

/* MATHEMATICAL SANS-SERIF BOLD EPSILON SYMBOL ... MATHEMATICAL SANS-SERIF BOLD ITALIC SMALL OMEGA */
    0x1D78A..0x1D7C2 to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL SANS-SERIF BOLD ITALIC PARTIAL DIFFERENTIAL ... MATHEMATICAL SANS-SERIF BOLD ITALIC PARTIAL DIFFERENTIAL */
    0x1D7C3..0x1D7C3 to CharDirectionality.Neutral,

/* MATHEMATICAL SANS-SERIF BOLD ITALIC EPSILON SYMBOL ... MATHEMATICAL BOLD SMALL DIGAMMA */
    0x1D7C4..0x1D7CB to CharDirectionality.StrongLeftToRight,

/* MATHEMATICAL BOLD DIGIT ZERO ... MATHEMATICAL MONOSPACE DIGIT NINE */
    0x1D7CE..0x1D7FF to CharDirectionality.Weak,

/* SIGNWRITING HAND-FIST INDEX ... SIGNWRITING HEAD */
    0x1D800..0x1D9FF to CharDirectionality.StrongLeftToRight,

/* SIGNWRITING HEAD RIM ... SIGNWRITING AIR SUCKING IN */
    0x1DA00..0x1DA36 to CharDirectionality.Weak,

/* SIGNWRITING AIR BLOW SMALL ROTATIONS ... SIGNWRITING BREATH EXHALE */
    0x1DA37..0x1DA3A to CharDirectionality.StrongLeftToRight,

/* SIGNWRITING MOUTH CLOSED NEUTRAL ... SIGNWRITING EXCITEMENT */
    0x1DA3B..0x1DA6C to CharDirectionality.Weak,

/* SIGNWRITING SHOULDER HIP SPINE ... SIGNWRITING TORSO-FLOORPLANE TWISTING */
    0x1DA6D..0x1DA74 to CharDirectionality.StrongLeftToRight,

/* SIGNWRITING UPPER BODY TILTING FROM HIP JOINTS ... SIGNWRITING UPPER BODY TILTING FROM HIP JOINTS */
    0x1DA75..0x1DA75 to CharDirectionality.Weak,

/* SIGNWRITING LIMB COMBINATION ... SIGNWRITING LOCATION DEPTH */
    0x1DA76..0x1DA83 to CharDirectionality.StrongLeftToRight,

/* SIGNWRITING LOCATION HEAD NECK ... SIGNWRITING LOCATION HEAD NECK */
    0x1DA84..0x1DA84 to CharDirectionality.Weak,

/* SIGNWRITING LOCATION TORSO ... SIGNWRITING PARENTHESIS */
    0x1DA85..0x1DA8B to CharDirectionality.StrongLeftToRight,

/* SIGNWRITING FILL MODIFIER-2 ... SIGNWRITING ROTATION MODIFIER-16 */
    0x1DA9B..0x1DAAF to CharDirectionality.Weak,

/* LATIN SMALL LETTER FENG DIGRAPH WITH TRILL ... LATIN SMALL LETTER T WITH MID-HEIGHT LEFT HOOK */
    0x1DF00..0x1DF2A to CharDirectionality.StrongLeftToRight,

/* COMBINING GLAGOLITIC LETTER AZU ... COMBINING GLAGOLITIC LETTER FITA */
    0x1E000..0x1E02A to CharDirectionality.Weak,

/* MODIFIER LETTER CYRILLIC SMALL A ... MODIFIER LETTER CYRILLIC SMALL STRAIGHT U WITH STROKE */
    0x1E030..0x1E06D to CharDirectionality.StrongLeftToRight,

/* COMBINING CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I ... COMBINING CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I */
    0x1E08F..0x1E08F to CharDirectionality.Weak,

/* NYIAKENG PUACHUE HMONG LETTER MA ... NYIAKENG PUACHUE HMONG LETTER W */
    0x1E100..0x1E12C to CharDirectionality.StrongLeftToRight,

/* NYIAKENG PUACHUE HMONG TONE-B ... NYIAKENG PUACHUE HMONG TONE-D */
    0x1E130..0x1E136 to CharDirectionality.Weak,

/* NYIAKENG PUACHUE HMONG SIGN FOR PERSON ... TOTO LETTER A */
    0x1E137..0x1E2AD to CharDirectionality.StrongLeftToRight,

/* TOTO SIGN RISING TONE ... TOTO SIGN RISING TONE */
    0x1E2AE..0x1E2AE to CharDirectionality.Weak,

/* WANCHO LETTER AA ... WANCHO LETTER YIH */
    0x1E2C0..0x1E2EB to CharDirectionality.StrongLeftToRight,

/* WANCHO TONE TUP ... WANCHO TONE KOINI */
    0x1E2EC..0x1E2EF to CharDirectionality.Weak,

/* WANCHO DIGIT ZERO ... WANCHO DIGIT NINE */
    0x1E2F0..0x1E2F9 to CharDirectionality.StrongLeftToRight,

/* WANCHO NGUN SIGN ... WANCHO NGUN SIGN */
    0x1E2FF..0x1E2FF to CharDirectionality.Weak,

/* NAG MUNDARI LETTER O ... NAG MUNDARI SIGN OJOD */
    0x1E4D0..0x1E4EB to CharDirectionality.StrongLeftToRight,

/* NAG MUNDARI SIGN MUHOR ... NAG MUNDARI SIGN SUTUH */
    0x1E4EC..0x1E4EF to CharDirectionality.Weak,

/* NAG MUNDARI DIGIT ZERO ... ETHIOPIC SYLLABLE GURAGE PWEE */
    0x1E4F0..0x1E7FE to CharDirectionality.StrongLeftToRight,

/* MENDE KIKAKUI SYLLABLE M001 KI ... MENDE KIKAKUI DIGIT NINE */
    0x1E800..0x1E8CF to CharDirectionality.StrongRightToLeft,

/* MENDE KIKAKUI COMBINING NUMBER TEENS ... MENDE KIKAKUI COMBINING NUMBER MILLIONS */
    0x1E8D0..0x1E8D6 to CharDirectionality.Weak,

/* ADLAM CAPITAL LETTER ALIF ... ADLAM SMALL LETTER SHA */
    0x1E900..0x1E943 to CharDirectionality.StrongRightToLeft,

/* ADLAM ALIF LENGTHENER ... ADLAM NUKTA */
    0x1E944..0x1E94A to CharDirectionality.Weak,

/* ADLAM NASALIZATION MARK ... ARABIC MATHEMATICAL DOUBLE-STRUCK GHAIN */
    0x1E94B..0x1EEBB to CharDirectionality.StrongRightToLeft,

/* ARABIC MATHEMATICAL OPERATOR MEEM WITH HAH WITH TATWEEL ... PLAYING CARD TRUMP-21 */
    0x1EEF0..0x1F0F5 to CharDirectionality.Neutral,

/* DIGIT ZERO FULL STOP ... DIGIT NINE COMMA */
    0x1F100..0x1F10A to CharDirectionality.Weak,

/* DINGBAT CIRCLED SANS-SERIF DIGIT ZERO ... CIRCLED DOLLAR SIGN WITH OVERLAID BACKSLASH */
    0x1F10B..0x1F10F to CharDirectionality.Neutral,

/* PARENTHESIZED LATIN CAPITAL LETTER A ... CIRCLED WZ */
    0x1F110..0x1F12E to CharDirectionality.StrongLeftToRight,

/* COPYLEFT SYMBOL ... COPYLEFT SYMBOL */
    0x1F12F..0x1F12F to CharDirectionality.Neutral,

/* SQUARED LATIN CAPITAL LETTER A ... NEGATIVE CIRCLED LATIN CAPITAL LETTER Z */
    0x1F130..0x1F169 to CharDirectionality.StrongLeftToRight,

/* RAISED MC SIGN ... CIRCLED HUMAN FIGURE */
    0x1F16A..0x1F16F to CharDirectionality.Neutral,

/* NEGATIVE SQUARED LATIN CAPITAL LETTER A ... SQUARED VOD */
    0x1F170..0x1F1AC to CharDirectionality.StrongLeftToRight,

/* MASK WORK SYMBOL ... MASK WORK SYMBOL */
    0x1F1AD..0x1F1AD to CharDirectionality.Neutral,

/* REGIONAL INDICATOR SYMBOL LETTER A ... CIRCLED IDEOGRAPH ACCEPT */
    0x1F1E6..0x1F251 to CharDirectionality.StrongLeftToRight,

/* ROUNDED SYMBOL FOR FU ... WHITE UP-POINTING CHEVRON */
    0x1F260..0x1FBCA to CharDirectionality.Neutral,

/* SEGMENTED DIGIT ZERO ... SEGMENTED DIGIT NINE */
    0x1FBF0..0x1FBF9 to CharDirectionality.Weak,

/* <CJK Ideograph Extension B, First> ... <CJK Ideograph Extension H, Last> */
    0x20000..0x323AF to CharDirectionality.StrongLeftToRight,

/* LANGUAGE TAG ... VARIATION SELECTOR-256 */
    0xE0001..0xE01EF to CharDirectionality.Weak,

/* <Plane 15 Private Use, First> ... <Plane 16 Private Use, Last> */
    0xF0000..0x10FFFD to CharDirectionality.StrongLeftToRight,

)
