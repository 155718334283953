package com.speechify.client.api.adapters.localsynthesis

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
interface LocalSpeechSynthesisVoicesProvider {
    fun getAllVoices(callback: Callback<Array<LocalSynthesisVoice>>)
}

@JsExport
interface LocalSpeechSynthesisAdapter : LocalSpeechSynthesisVoicesProvider {
    fun createLocalSpeechSynthesisPlayer(callback: Callback<LocalSpeechSynthesisPlayer>)
}

/** TODO - make `LocalSpeechSynthesisPlayer` an `abstract class` and `LocalSpeechSynthesisPlayer.speak` protected, so
 *   that this is the only usage in SDK.
 */

internal suspend fun LocalSpeechSynthesisAdapter.coCreateLocalSpeechSynthesisPlayer() =
    suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
        onCancellationLeavingJobRunning = { /* TODO: Implement cancellation */ },
        connectToResume = { createLocalSpeechSynthesisPlayer(it::resume) },
    )

internal suspend fun LocalSpeechSynthesisVoicesProvider.coGetAllVoices(): Result<Array<LocalSynthesisVoice>> =
    suspendCoroutine {
        this@coGetAllVoices.getAllVoices(it::resume)
    }

internal fun LocalSpeechSynthesisAdapter.traced() =
    if (Log.isDebugLoggingEnabled) LocalSpeechSynthesisAdapterTraced(this) else this

internal class LocalSpeechSynthesisAdapterTraced(
    private val localSpeechSynthesisAdapter: LocalSpeechSynthesisAdapter,
) : LocalSpeechSynthesisAdapter {

    override fun getAllVoices(
        callback: Callback<Array<LocalSynthesisVoice>>,
    ) = callback.fromCo {
        debugCallAndResultWithUuid(areaId = "LocalSpeechSynthesisAdapterTraced.getAllVoices") {
            this@LocalSpeechSynthesisAdapterTraced.localSpeechSynthesisAdapter.coGetAllVoices()
        }
    }

    override fun createLocalSpeechSynthesisPlayer(
        callback: Callback<LocalSpeechSynthesisPlayer>,
    ) = callback.fromCo {
        debugCallAndResultWithUuid(areaId = "LocalSpeechSynthesisAdapterTraced.createLocalSpeechSynthesisPlayer") {
            this@LocalSpeechSynthesisAdapterTraced.localSpeechSynthesisAdapter.coCreateLocalSpeechSynthesisPlayer()
        }
    }
}
