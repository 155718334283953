package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.util.images.horizontalDistanceTo
import com.speechify.client.helpers.content.standard.book.heuristics.v2.isNeighborIndex
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentLine
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.LinePosition
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingContext
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.RawContentType
import com.speechify.client.helpers.content.standard.book.heuristics.v2.unionBoxes
import com.speechify.client.helpers.content.standard.book.isAlmostEqual
import kotlin.math.max

/**
 * Defines the maximum horizontal search distance for adjacent words,
 * expressed as a multiple of the symbol width.
 */
private const val HORIZONTAL_SEARCH_DISTANCE = 1

/**
 * Defines the extended horizontal search distance for neighboring words,
 * expressed as a multiple of the symbol width.
 */
private const val HORIZONTAL_SEARCH_DISTANCE_FOR_NEIGHBORS = 2

/**
 * Defines the tolerance for considering two words to be on the same line.
 * If the difference in their top or bottom positions is within 40% of the symbol height, they are treated as aligned.
 */
private const val LINE_ALIGNMENT_TOLERANCE = 0.4

internal class ProcessHorizontalLines(
    private val context: ParsingContext,
    private val logger: Logger?,
) :
    ParsingPipelineStage<List<BookPageTextContentItem>, List<ContentLine>> {
    private val builtLines: MutableList<ContentLine> = mutableListOf()

    override fun process(input: List<BookPageTextContentItem>): List<ContentLine> {
        when (context.platform) {
            RawContentType.LINES -> processLines(input)
            RawContentType.WORDS -> processWords(input)
        }

        logBuiltLines()
        return builtLines
    }

    /**
     * Creates a separate line for each content item without performing a neighbor search.
     */
    private fun processLines(raw: List<BookPageTextContentItem>) {
        for (content in raw) {
            val newLine = listOf(content).buildHorizontalLine()
            builtLines.add(newLine)
        }
    }

    /**
     * Groups words into horizontal lines based on proximity and alignment.
     */
    private fun processWords(raw: List<BookPageTextContentItem>) {
        val visited = mutableSetOf<Int>()
        val indexedWords = raw.mapIndexed { index, line -> index to line }

        for ((index, word) in indexedWords) {
            if (index !in visited) {
                val newLine = mutableListOf<BookPageTextContentItem>()
                runDeepLineSearch(newLine, index to word, indexedWords, visited)
                builtLines.add(newLine.buildHorizontalLine())
            }
        }
    }

    private fun runDeepLineSearch(
        newLine: MutableList<BookPageTextContentItem>,
        current: Pair<Int, BookPageTextContentItem>,
        indexedWords: List<Pair<Int, BookPageTextContentItem>>,
        visited: MutableSet<Int>,
    ) {
        val (currentIndex, currentWord) = current
        if (!visited.add(currentIndex)) return
        newLine.add(currentWord)

        val neighbors = current.findNeighbors(indexedWords).filter { (index, _) -> index !in visited }
        neighbors.forEach { runDeepLineSearch(newLine, it, indexedWords, visited) }
    }

    private fun Pair<Int, BookPageTextContentItem>.findNeighbors(
        indexedWords: List<Pair<Int, BookPageTextContentItem>>,
    ): List<Pair<Int, BookPageTextContentItem>> {
        val (thisIndex, thisWord) = this

        val thisSymbolWidth = thisWord.getHorizontalSymbolTolerance()
        val baseEps = thisSymbolWidth * HORIZONTAL_SEARCH_DISTANCE
        val neighborEps = thisSymbolWidth * HORIZONTAL_SEARCH_DISTANCE_FOR_NEIGHBORS

        return indexedWords.filter { (thatIndex, thatWord) ->
            if (thisIndex == thatIndex) return@filter false

            val distanceThreshold = if (thisIndex.isNeighborIndex(thatIndex)) neighborEps else baseEps
            val horizontalDistance = thisWord.normalizedBox.horizontalDistanceTo(thatWord.normalizedBox)
            val isCloseEnough = horizontalDistance < distanceThreshold
            isCloseEnough && thisWord.isOnTheSameLine(thatWord)
        }
    }

    private fun BookPageTextContentItem.getHorizontalSymbolTolerance() =
        max(normalizedBox.height, normalizedBox.width / text.length)

    private fun BookPageTextContentItem.isOnTheSameLine(word: BookPageTextContentItem): Boolean {
        val tolerance = getHorizontalSymbolTolerance() * LINE_ALIGNMENT_TOLERANCE

        val isTopIsAlmostEqual = this.normalizedBox.top.isAlmostEqual(word.normalizedBox.top, tolerance)
        val isBottomIsAlmostEqual = this.normalizedBox.bottom.isAlmostEqual(word.normalizedBox.bottom, tolerance)

        return isTopIsAlmostEqual || isBottomIsAlmostEqual
    }

    private fun List<BookPageTextContentItem>.buildHorizontalLine(): ContentLine {
        require(this.isNotEmpty()) { "Unexpected raw content size" }
        val boundingBox = this.map { it.normalizedBox }.unionBoxes()
        return ContentLine(boundingBox, this, LinePosition.HORIZONTAL)
    }

    private fun logBuiltLines() {
        logger?.log("Built horizontal lines:")
        builtLines.forEach { logger?.log(it.plainText) }
    }
}
