package com.speechify.client.api.content

import kotlinx.coroutines.flow.Flow

/**
 * A searcher that scans a document for matches.
 */
internal interface Searcher {
    /**
     * Scans a document for occurrences of the specified [query], starting at the given [startCursor].
     *
     * The search begins at [startCursor] and proceeds to the end of the document.
     * When the end is reached, it continues from the beginning of the document up to [startCursor].
     *
     * Note that the search is case-insensitive.
     *
     * @param query The text to search for.
     * @param startCursor The cursor position where the search begins. If null, the search starts from the beginning.
     * @return A flow emitting batches of found matches.
     */
    fun search(query: String, startCursor: ContentCursor?): Flow<List<SearchMatch>>
}

// TODO: make it internal
data class SearchMatch(
    override val start: ContentCursor,
    override val end: ContentCursor,
) : Content
