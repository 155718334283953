package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.internal.util.coroutines.ChildCoroutineErrorBehavior
import com.speechify.client.internal.util.coroutines.launchWithOnErrorBehavior
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect

/**
 * A variant of [kotlinx.coroutines.flow.launchIn] that allows specifying the [onError] behavior.
 */
internal fun <T> Flow<T>.launchWithOnErrorBehaviorIn(
    onError: ChildCoroutineErrorBehavior,
    scope: CoroutineScope,
): Job =
    scope.launchWithOnErrorBehavior(
        onError = onError,
    ) {
        this@launchWithOnErrorBehaviorIn
            .collect()
    }
