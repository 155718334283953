package com.speechify.client.bundlers.content

import com.speechify.client.api.audio.PreSpeechTransformOptions
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.services.library.offline.StaticContentTransformOptions
import com.speechify.client.bundlers.BundlerPlugins
import com.speechify.client.helpers.content.speech.ContentTransformOptions
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

/**
 * Container for all *optional* [ContentBundler] configurations.
 */
@JsExport
class ContentBundlerConfig(
    val options: ContentBundlerOptions,
)

/**
 * Container for all *optional* [ContentBundler] configurations.
 *
 * The settings are "live" in the sense that changing them during playback will be immediately reflected.
 * It is explicitly allowed to allow users to change these settings from the UI shown in the playback screen.
 */
@JsExport
class ContentBundlerOptions : ContentTransformOptions {
    /**
     * Default false because this is the current behavior at time of writing.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldUseRichBlocksParsingForHtmlContentFlow = MutableStateFlow(false)

    /**
     * Default false because this is the current behavior at time of writing.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldUseRichBlocksParsingForEpubContentFlow = MutableStateFlow(false)

    /**
     * Default true because this is the current behavior at time of writing.
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldSkipHeadersFlow = MutableStateFlow(true)

    /**
     * Default true because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldSkipFootersFlow = MutableStateFlow(true)

    /**
     * Default false because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldSkipFootnotesFlow = MutableStateFlow(false)

    /**
     * Default false because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val shouldSkipCaptionsFlow = MutableStateFlow(false)

    /**
     * Default ForceDisable because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val mlParsingModeFlow: MutableStateFlow<MLParsingMode> = MutableStateFlow(MLParsingMode.ForceDisable)

    /**
     * Default ForceDisable because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val ocrFallbackStrategyFlow: MutableStateFlow<OcrFallbackStrategy> =
        MutableStateFlow(OcrFallbackStrategy.ForceDisable)

    /**
     * Default empty because this is the current behavior at time of writing
     * For internal use only.
     */
    @Suppress("NON_EXPORTABLE_TYPE")
    override val preSpeechTransformationsFlow = MutableStateFlow(
        PreSpeechTransformOptions(
            initialShouldSkipBraces = false,
            initialShouldSkipCitations = false,
            initialShouldSkipParentheses = false,
            initialShouldSkipBrackets = false,
            initialShouldSkipUrls = false,
            customSentenceTransformer = null,
        ),
    )

    /**
     * Enables use of experimental rich blocks parsing for HTML content, populating each top-level block
     * with a tree of "inline" blocks for rendering rich formatting. The `StandardBlock.text` API still works all the
     * same for backward-compatibility, but there are probably subtle discrepancies between the behavior of that API
     * with this option disabled versus enabled.
     *
     * CHanging this during listening has no effect, it must be configured before a bundle is created
     */
    fun enableRichBlocksParsingForHtmlContent() = apply {
        shouldUseRichBlocksParsingForHtmlContentFlow.value = true
    }

    /**
     * Disables use of experimental rich blocks parsing for HTML content, reverting to the legacy
     * method that only captures the concatenated text of the top-level blocks.
     *
     * CHanging this during listening has no effect, it must be configured before a bundle is created
     */
    fun disableRichBlocksParsingForHtmlContent() = apply {
        shouldUseRichBlocksParsingForHtmlContentFlow.value = false
    }

    /**
     * Enables use of experimental rich blocks parsing for EPUB content, populating each top-level block
     * with a tree of "inline" blocks for rendering rich formatting. The `StandardBlock.text` API still works all the
     * same for backward-compatibility, but there are probably subtle discrepancies between the behavior of that API
     * with this option disabled versus enabled.
     *
     * CHanging this during listening has no effect, it must be configured before a bundle is created
     */
    fun enableRichBlocksParsingForEpubContent() = apply {
        shouldUseRichBlocksParsingForEpubContentFlow.value = true
    }

    /**
     * Disables use of experimental rich blocks parsing for EPUB content, reverting to the legacy
     * method that only captures the concatenated text of the top-level blocks.
     *
     * CHanging this during listening has no effect, it must be configured before a bundle is created
     */
    fun disableRichBlocksParsingForEpubContent() = apply {
        shouldUseRichBlocksParsingForEpubContentFlow.value = false
    }

    /**
     * Restores the default of skipping headers and footers.
     *
     * Changing this during listening has undefined behaviour, it should always be configured before a bundle
     * is created.
     */
    @Deprecated(
        "Use the direct setShouldSkipHeaders and setShouldSkipFooters setter instead",
        ReplaceWith("shouldSkipHeaders = true\nshouldSkipFooters = true"),
    )
    fun enableSkipHeadersAndFooters() = apply {
        shouldSkipHeadersFlow.value = true
        shouldSkipFootersFlow.value = true
    }

    /**
     * Disables skipping of text within Scanned Books and PDFs that is detected as a
     * repeated "header" or "footer", which are almost never useful to read aloud.
     *
     * This is enabled by default because:
     * - This is the current behavior
     * - This method of exposing this setting for client control is only a band-aid,
     *   giving *some* control but not enough to configure the setting dynamically
     *   during the listening experience in the same way that we let clients control
     *   other rules via [BundlerPlugins.preSpeechTransformOptions].
     * - We intend to do additional work to support the dynamic control mentioned above,
     *   and prefer to consolidate breakage-coordination into that release, rather than
     *   both this one and that one.
     */
    @Deprecated(
        "Use the direct setShouldSkipHeaders and setShouldSkipFooters setter instead",
        ReplaceWith("shouldSkipHeaders = false\nshouldSkipFooters = false"),
    )
    fun disableSkipHeadersAndFooters() = apply {
        shouldSkipHeadersFlow.value = false
        shouldSkipFootersFlow.value = false
    }

    /**
     * Restores the default of skipping headers.
     *
     * Changing this during listening has undefined behaviour, it should always be configured before a bundle
     * is created.
     */
    @Deprecated(
        "Use the direct setShouldSkipHeaders setter instead",
        ReplaceWith("shouldSkipHeaders = true"),
    )
    fun enableSkipHeaders() = apply {
        shouldSkipHeadersFlow.value = true
    }

    /**
     * Disables skipping of text within Scanned Books and PDFs that is detected as a
     * repeated "header" which are almost never useful to read aloud.
     *
     * This is enabled by default because:
     * - This is the current behavior
     * - This method of exposing this setting for client control is only a band-aid,
     *   giving *some* control but not enough to configure the setting dynamically
     *   during the listening experience in the same way that we let clients control
     *   other rules via [BundlerPlugins.preSpeechTransformOptions].
     * - We intend to do additional work to support the dynamic control mentioned above,
     *   and prefer to consolidate breakage-coordination into that release, rather than
     *   both this one and that one.
     */
    @Deprecated(
        "Use the direct setShouldSkipHeaders setter instead",
        ReplaceWith("shouldSkipHeaders = false"),
    )
    fun disableSkipHeaders() = apply {
        shouldSkipHeadersFlow.value = false
    }

    /**
     * Enables or disables skipping of text within Scanned Books and PDFs that is detected as a
     * repeated "header" which are almost never useful to read aloud.
     *
     * This is enabled by default because:
     * - This is the current behavior
     * - This method of exposing this setting for client control is only a band-aid,
     *   giving *some* control but not enough to configure the setting dynamically
     *   during the listening experience in the same way that we let clients control
     *   other rules via [BundlerPlugins.preSpeechTransformOptions].
     * - We intend to do additional work to support the dynamic control mentioned above,
     *   and prefer to consolidate breakage-coordination into that release, rather than
     *   both this one and that one.
     */
    var shouldSkipHeaders: Boolean
        get() = shouldSkipHeadersFlow.value
        set(value) {
            shouldSkipHeadersFlow.value = value
        }

    /**
     * Restores the default of skipping footnotes.
     *
     * Changing this during listening has undefined behaviour, it should always be configured before a bundle
     * is created.
     */
    @Deprecated(
        "Use the direct setShouldSkipFooters setter instead",
        ReplaceWith("shouldSkipFooters = true"),
    )
    fun enableSkipFooters() = apply {
        shouldSkipFootersFlow.value = true
    }

    /**
     * Disables skipping of text within Scanned Books and PDFs that is detected as a
     * repeated "footer" which are almost never useful to read aloud.
     *
     * This is enabled by default because:
     * - This is the current behavior
     * - This method of exposing this setting for client control is only a band-aid,
     *   giving *some* control but not enough to configure the setting dynamically
     *   during the listening experience in the same way that we let clients control
     *   other rules via [BundlerPlugins.preSpeechTransformOptions].
     * - We intend to do additional work to support the dynamic control mentioned above,
     *   and prefer to consolidate breakage-coordination into that release, rather than
     *   both this one and that one.
     */
    @Deprecated(
        "Use the direct setShouldSkipFooters setter instead",
        ReplaceWith("shouldSkipFooters = false"),
    )
    fun disableSkipFooters() = apply {
        shouldSkipFootersFlow.value = false
    }

    /**
     * Enables or disables skipping of text within Scanned Books and PDFs that is detected as a
     * repeated "footer" which are almost never useful to read aloud.
     *
     * This is enabled by default because:
     * - This is the current behavior
     * - This method of exposing this setting for client control is only a band-aid,
     *   giving *some* control but not enough to configure the setting dynamically
     *   during the listening experience in the same way that we let clients control
     *   other rules via [BundlerPlugins.preSpeechTransformOptions].
     * - We intend to do additional work to support the dynamic control mentioned above,
     *   and prefer to consolidate breakage-coordination into that release, rather than
     *   both this one and that one.
     */
    var shouldSkipFooters: Boolean
        get() = shouldSkipFootersFlow.value
        set(value) {
            shouldSkipFootersFlow.value = value
        }

    /**
     * Enables (experimental) support for skipping text that looks like footnotes.
     * E.g.:
     * 1. This is a footnote referencing a citation.
     * Support is experimental because:
     * - Detection rate is around 40% in our (quite small) test set of PDFs
     * - There is a non 0 chance of false positives leading to skipping of text that the user wants to read.
     */
    @Deprecated(
        "Use the direct setShouldSkipFootnotes setter instead",
        ReplaceWith("shouldSkipFootnotes = true"),
    )
    fun enableSkippingFootnotes() = apply {
        shouldSkipFootnotesFlow.value = true
    }

    /**
     * Disables the experimental support for skipping text that looks like footnotes.
     *
     * Changing this during listening has undefined behaviour, it should always be configured before a bundle
     * is created.
     */
    @Deprecated(
        "Use the direct setShouldSkipFootnotes setter instead",
        ReplaceWith("shouldSkipFootnotes = false"),
    )
    fun disableSkippingFootnotes() = apply {
        shouldSkipFootnotesFlow.value = false
    }

    /**
     * Enables or disables (experimental) support for skipping text that looks like footnotes.
     * E.g.:
     * 1. This is a footnote referencing a citation.
     * Support is experimental because:
     * - Detection rate is around 40% in our (quite small) test set of PDFs
     * - There is a non 0 chance of false positives leading to skipping of text that the user wants to read.
     */
    var shouldSkipFootnotes: Boolean
        get() = shouldSkipFootnotesFlow.value
        set(value) {
            shouldSkipFootnotesFlow.value = value
        }

    var shouldSkipCaptions: Boolean
        get() = shouldSkipCaptionsFlow.value
        set(value) {
            shouldSkipCaptionsFlow.value = value
        }

    /**
     * Set ML Parsing Mode ForceDisable/ForceEnable.
     */
    var mlParsingMode: MLParsingMode
        get() = mlParsingModeFlow.value
        set(value) {
            mlParsingModeFlow.value = value
        }

    /**
     * Set OCR Fallback Strategy.
     */
    var ocrFallbackStrategy: OcrFallbackStrategy
        get() = ocrFallbackStrategyFlow.value
        set(value) {
            ocrFallbackStrategyFlow.value = value
        }

    /**
     * Sets a new [PreSpeechTransformOptions]. When this is called during playback the changes
     * are immediately applied re-synthesising the currently spoken sentence if necessary.
     *
     * The transformations are applied in the order they are provided. Returning `null` from any of the transformations
     * will make the entire sentence be skipped, and the following transformations not be evaluated.
     *
     * ## Example
     * ```js
     * const transformers : ((p0: SpeechSentence, p1: LanguageIdentity) => Nullable<SpeechSentence>)[] = [];
     *  transformers.push((sentence) => {
     *    return sentence.replaceAll("(.+)", s => {
     *      return s.split(" ").map((word, index) => index % 2 === 0 ? "HiHi" : word).join(" ");
     *    });
     *  });
     *  transformers.push((sentence) => {
     *    return sentence.replaceAll("(.+)", s => {
     *      return s.split(" ").map((word, index) => index % 2 === 0 ? word : "HaHa").join(" ");
     *    });
     *  });
     *
     *  contentBundlerOptions.setPreSpeechTransformOptions(PreSpeechTransformOptions.Companion.withSentenceTransformers(transformers));
     * ```
     */
    var preSpeechTransformOptions: PreSpeechTransformOptions
        get() = preSpeechTransformationsFlow.value
        set(value) { preSpeechTransformationsFlow.value = value }

    /**
     * Allows you to get a immutable copy of the current content transform options.
     * This is for example used when downloading a voice for offline use
     */
    fun asStaticContentTransformOptions(): StaticContentTransformOptions = StaticContentTransformOptions(
        shouldSkipFooters = shouldSkipFooters,
        shouldSkipHeaders = shouldSkipHeaders,
        shouldSkipFootnotes = shouldSkipFootnotes,
        shouldSkipCaptions = shouldSkipCaptions,
        preSpeechTransformOptions = preSpeechTransformOptions,
    )

    /**
     * For internal use only.
     */
    @OptIn(ExperimentalCoroutinesApi::class)
    @Suppress("NON_EXPORTABLE_TYPE")
    override val contentTransformOptionsChanged: ExternalStateChangesFlow =
        // We combine everything here so we can use distinctUntilChanged to avoid multiple calls when first collecting
        // this flow.
        preSpeechTransformationsFlow
            // This allows us to notify on changes to the underlying transformers, even if the options object itself
            // doesn't change.
            .flatMapLatest { it.currentSkippingSettings }
            .combine(shouldSkipHeadersFlow) { a, b -> a to b }
            .combine(shouldSkipFootersFlow) { a, b -> a to b }
            .combine(shouldSkipFootnotesFlow) { a, b -> a to b }
            .combine(shouldSkipCaptionsFlow) { a, b -> a to b }
            .combine(mlParsingModeFlow) { a, b -> a to b }
            .combine(ocrFallbackStrategyFlow) { a, b -> a to b }
            .distinctUntilChanged()
            .map { }
}
