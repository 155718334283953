package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.mapNotNull

/**
 * A flow version of [Sequence.firstNotNullOfOrNull].
 */
suspend fun <T, R : Any> Flow<T>.firstNotNullOfOrNull(
    transform: suspend (item: T) -> R?,
): R? =
    mapNotNull(transform = transform)
        .firstOrNull()
