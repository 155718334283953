// ktlint-disable filename
package com.speechify.client.helpers.content.standard.dynamic

import com.speechify.client.api.util.ClassToWorkaroundKotlinLackOfTypeScriptInterfaces
import com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderForImmutableAlwaysLiveContent
import com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderForImmutableLimitedLifeContent
import com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderForMutableLimitedLifeContent

/**
 * A way for TypeScript/JavaScript to implement [DynamicContentProviderForMutableLimitedLifeContent] (see there
 * for documentation).
 */
@JsExport
abstract class DynamicContentProviderForMutableLimitedLifeContentBase :
    /** This one doesn't need a [ClassToWorkaroundKotlinLackOfTypeScriptInterfaces] because
     [DynamicContentProviderForMutableLimitedLifeContent] is already an abstract class. */
        DynamicContentProviderForMutableLimitedLifeContent()

/**
 * A way for TypeScript/JavaScript to implement [DynamicContentProviderForImmutableLimitedLifeContent] (see there
 * for documentation).
 *
 * See [ClassToWorkaroundKotlinLackOfTypeScriptInterfaces] for the reason why we have to use this base class.
 */
@JsExport
abstract class DynamicContentProviderForImmutableLimitedLifeContentBase :
    ClassToWorkaroundKotlinLackOfTypeScriptInterfaces,
    DynamicContentProviderForImmutableLimitedLifeContent

/**
 * A way for TypeScript/JavaScript to implement [DynamicContentProviderForImmutableAlwaysLiveContent] (see there
 * for documentation).
 *
 * See [ClassToWorkaroundKotlinLackOfTypeScriptInterfaces] for the reason why we have to use this base class.
 */
@JsExport
abstract class DynamicContentProviderForImmutableAlwaysLiveContentBase :
    ClassToWorkaroundKotlinLackOfTypeScriptInterfaces,
    DynamicContentProviderForImmutableAlwaysLiveContent
