package com.speechify.client.internal.services.userDocumentSettings

import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.jsonPrimitive

@Serializable(with = SettingsValueSerializer::class)
internal sealed class SettingsValue {
    @Serializable
    data class StringValue(val value: String) : SettingsValue()

    @Serializable
    data class IntValue(val value: Int) : SettingsValue()

    @Serializable
    data class DoubleValue(val value: Double) : SettingsValue()
}

internal object SettingsValueSerializer : KSerializer<SettingsValue> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("SettingsValue", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: SettingsValue) {
        when (value) {
            is SettingsValue.StringValue -> encoder.encodeString(value.value)
            is SettingsValue.IntValue -> encoder.encodeInt(value.value)
            is SettingsValue.DoubleValue -> encoder.encodeDouble(value.value)
        }
    }

    override fun deserialize(decoder: Decoder): SettingsValue {
        val input = decoder as? JsonDecoder ?: throw IllegalArgumentException("Expected JsonDecoder")
        val element = input.decodeJsonElement()

        return when {
            element.jsonPrimitive.isString -> SettingsValue.StringValue(element.jsonPrimitive.content)
            element.jsonPrimitive.content.toIntOrNull() != null -> SettingsValue.IntValue(
                element.jsonPrimitive.content.toInt(),
            )
            element.jsonPrimitive.content.toDoubleOrNull() != null -> SettingsValue.DoubleValue(
                element.jsonPrimitive.content.toDouble(),
            )
            else -> throw IllegalArgumentException("Cannot deserialize value: $element")
        }
    }
}

private inline fun SettingsValue.asStringOrNull() = (this as? SettingsValue.StringValue)?.value
private inline fun SettingsValue.asIntOrNull() = (this as? SettingsValue.IntValue)?.value
private inline fun SettingsValue.asDoubleOrNull() = (this as? SettingsValue.DoubleValue)?.value

@Serializable
internal data class UserDocumentSettings(
    @SerialName("items")
    val items: Map<String, Document>,
) {
    @Serializable
    data class Document(
        @SerialName("version")
        val version: Int,
        @SerialName("settings")
        private val settings: Map<String, SettingsValue>,
    ) {
        val ocrStrategy: OcrFallbackStrategy?
            get() = when (val strategy = settings["escape-hatches.ocrstrategy"]?.asStringOrNull()) {
                "force_disable" -> OcrFallbackStrategy.ForceDisable
                "conservative_legacy" -> OcrFallbackStrategy.ConservativeLegacyStrategy
                "experimental" -> OcrFallbackStrategy.ExperimentalStrategy
                "force_enable" -> OcrFallbackStrategy.ForceEnable
                null -> null
                else -> {
                    Log.e(
                        DiagnosticEvent(
                            message = "Received unknown strategy.",
                            sourceAreaId = "userDocumentSettings",
                            properties = SdkBoundaryMap.of("strategy" to strategy),
                        ),
                    )
                    null
                }
            }

        val ocrImageScale: Double?
            get() = settings["escape-hatches.ocrimagescale"]?.asDoubleOrNull()

        val ocrImageQuality: Int?
            get() = settings["escape-hatches.ocrimagequality"]?.asIntOrNull()
    }
}
