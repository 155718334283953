package com.speechify.client.api.content

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.collections.maps.memoizedEnsuringSingleExecution
import com.speechify.client.internal.util.extensions.strings.splitInTwoOnFirst
import kotlin.js.JsExport

/**
 * Holds the identifiers of a language (any more info about the language, such as name, or images representing a
 * language should be added to subtype or a composite type of this one).
 *
 * This class attempts to be maximally helpful to developers in explaining which of the various standards ids of a
 * language is provided in a value (with links to the standards).
 */
@JsExport
class LanguageIdentity(
    /**
     * As per [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag)
     * E.g. `en-US`, `en`, `cmn` etc.
     */
    val ietfTag: String,

    /**
     * As per [IETF language tag's syntax](https://en.wikipedia.org/wiki/IETF_language_tag#Syntax_of_language_tags)
     * E.g. `en`, `pl`, `cmn` etc.
     *
     * NOTE: The audio server voices language codes can be:
     * * mainly two-letter [ISO-639-1](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
     * * however there exist some three-letter [ISO-639-3](https://en.wikipedia.org/wiki/Wikipedia:WikiProject_Languages/List_of_ISO_639-3_language_codes_used_locally_by_Linguist_List) codes, e.g. "arb" and "cmn".
     * * TODO - check if it includes [three letter ISO-639-2](https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes#iso-codes)
     *   which are separate from ISO-639-3 (e.g. "cmn" is in ISO-639-3, not in ISO-639-2; "fin" is in ISO-639-2, not in ISO-639-3)
     * and **this mixture is permitted by the standard**, because IETF tag allows all of: [_"two-letter language code from ISO 639-1 (2002) or a three-letter code from ISO 639-2 (1998), ISO 639-3 (2007) or ISO 639-5 (2008)"_](https://en.wikipedia.org/wiki/IETF_language_tag#Syntax_of_language_tags:~:text=two%2Dletter%20language%20code%20from%20ISO%20639%2D1%20(2002)%20or%20a%20three%2Dletter%20code%20from%20ISO%20639%2D2%20(1998))
     *
     * Casing: This part is always lowercase (to follow [_"the specification recommends using the same case as in the Language
     * Subtag Registry, where region subtags are UPPERCASE, script subtags are Title Case, and all other subtags are
     * lowercase"_](https://en.wikipedia.org/wiki/IETF_language_tag#Syntax_of_language_tags))
     */
    /* TODO - figure out if we can provide a version of this member with a guaranteed more specific standard. */
    val ietfTagPrimaryLanguageSubtag: String = ietfTag.splitInTwoOnFirst("-").first.lowercase(),
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as LanguageIdentity

        return ietfTag == other.ietfTag
    }

    override fun hashCode(): Int {
        return ietfTag.hashCode()
    }
}

internal fun LanguageIdentity.tryGetDisplayName(): String? =
    tryGetDisplayNameMemoizedFn(this)

/**
 * Memoize, so that we don't duplicate logs of unknown languages (see #LoggingOfUnknownLanguages).
 */
private val tryGetDisplayNameMemoizedFn =
    LanguageIdentity::tryGetDisplayNameUnmemoized.memoizedEnsuringSingleExecution()

/**
 * Warning - this will log each time an unknown language is encountered (see #LoggingOfUnknownLanguages).
 */
private fun LanguageIdentity.tryGetDisplayNameUnmemoized(): String? {
    val ietfPrimaryTagLowercase = ietfTagPrimaryLanguageSubtag /** NOTE: It's already lower-cased as per
     [LanguageIdentity.ietfTagPrimaryLanguageSubtag] */
    return when {
        /** NOTE: We get a mixture of standards here, See: [LanguageIdentity.ietfTagPrimaryLanguageSubtag] */
        // Codes retrieved from here: https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
        ietfPrimaryTagLowercase.startsWith("ab") || ietfPrimaryTagLowercase.startsWith("abk") -> "Abkhazian"
        ietfPrimaryTagLowercase.startsWith("aa") || ietfPrimaryTagLowercase.startsWith("aar") -> "Afar"
        ietfPrimaryTagLowercase.startsWith("af") || ietfPrimaryTagLowercase.startsWith("afr") -> "Afrikaans"
        ietfPrimaryTagLowercase.startsWith("ak") || ietfPrimaryTagLowercase.startsWith("aka") -> "Akan"
        ietfPrimaryTagLowercase.startsWith("sq") || ietfPrimaryTagLowercase.startsWith("sqi") -> "Albanian"
        ietfPrimaryTagLowercase.startsWith("am") || ietfPrimaryTagLowercase.startsWith("amh") -> "Amharic"
        ietfPrimaryTagLowercase.startsWith("ar") || ietfPrimaryTagLowercase.startsWith("ara") -> "Arabic"
        ietfPrimaryTagLowercase.startsWith("an") || ietfPrimaryTagLowercase.startsWith("arg") -> "Aragonese"
        ietfPrimaryTagLowercase.startsWith("hy") || ietfPrimaryTagLowercase.startsWith("hye") -> "Armenian"
        ietfPrimaryTagLowercase.startsWith("as") || ietfPrimaryTagLowercase.startsWith("asm") -> "Assamese"
        ietfPrimaryTagLowercase.startsWith("av") || ietfPrimaryTagLowercase.startsWith("ava") -> "Avaric"
        ietfPrimaryTagLowercase.startsWith("ae") || ietfPrimaryTagLowercase.startsWith("ave") -> "Avestan"
        ietfPrimaryTagLowercase.startsWith("ay") || ietfPrimaryTagLowercase.startsWith("aym") -> "Aymara"
        ietfPrimaryTagLowercase.startsWith("az") || ietfPrimaryTagLowercase.startsWith("aze") -> "Azerbaijani"
        ietfPrimaryTagLowercase.startsWith("bm") || ietfPrimaryTagLowercase.startsWith("bam") -> "Bambara"
        ietfPrimaryTagLowercase.startsWith("ba") || ietfPrimaryTagLowercase.startsWith("bak") -> "Bashkir"
        ietfPrimaryTagLowercase.startsWith("eu") || ietfPrimaryTagLowercase.startsWith("eus") -> "Basque"
        ietfPrimaryTagLowercase.startsWith("be") || ietfPrimaryTagLowercase.startsWith("bel") -> "Belarusian"
        ietfPrimaryTagLowercase.startsWith("bn") || ietfPrimaryTagLowercase.startsWith("ben") -> "Bengali"
        ietfPrimaryTagLowercase.startsWith("bh") || ietfPrimaryTagLowercase.startsWith("bih") -> "Bihari"
        ietfPrimaryTagLowercase.startsWith("bi") || ietfPrimaryTagLowercase.startsWith("bis") -> "Bislama"
        ietfPrimaryTagLowercase.startsWith("bs") || ietfPrimaryTagLowercase.startsWith("bos") -> "Bosnian"
        ietfPrimaryTagLowercase.startsWith("br") || ietfPrimaryTagLowercase.startsWith("bre") -> "Breton"
        ietfPrimaryTagLowercase.startsWith("bg") || ietfPrimaryTagLowercase.startsWith("bul") -> "Bulgarian"
        ietfPrimaryTagLowercase.startsWith("my") || ietfPrimaryTagLowercase.startsWith("mya") -> "Burmese"
        ietfPrimaryTagLowercase.startsWith("ca") || ietfPrimaryTagLowercase.startsWith("cat")
        -> "Catalan, Valencian"
        ietfPrimaryTagLowercase.startsWith("cmn") -> "Chinese"
        ietfPrimaryTagLowercase.startsWith("ch") || ietfPrimaryTagLowercase.startsWith("cha") -> "Chamorro"
        ietfPrimaryTagLowercase.startsWith("ce") || ietfPrimaryTagLowercase.startsWith("che") -> "Chechen"
        ietfPrimaryTagLowercase.startsWith("ny") || ietfPrimaryTagLowercase.startsWith("nya")
        -> "Chichewa, Chewa, Nyanja"
        ietfPrimaryTagLowercase.startsWith("zh") || ietfPrimaryTagLowercase.startsWith("zho") -> "Chinese"
        ietfPrimaryTagLowercase.startsWith("cv") || ietfPrimaryTagLowercase.startsWith("chv") -> "Chuvash"
        ietfPrimaryTagLowercase.startsWith("kw") || ietfPrimaryTagLowercase.startsWith("cor") -> "Cornish"
        ietfPrimaryTagLowercase.startsWith("co") || ietfPrimaryTagLowercase.startsWith("cos") -> "Corsican"
        ietfPrimaryTagLowercase.startsWith("cr") || ietfPrimaryTagLowercase.startsWith("cre") -> "Cree"
        ietfPrimaryTagLowercase.startsWith("hr") || ietfPrimaryTagLowercase.startsWith("hrv") -> "Croatian"
        ietfPrimaryTagLowercase.startsWith("cs") || ietfPrimaryTagLowercase.startsWith("ces") -> "Czech"
        ietfPrimaryTagLowercase.startsWith("da") || ietfPrimaryTagLowercase.startsWith("dan") -> "Danish"
        ietfPrimaryTagLowercase.startsWith("dv") || ietfPrimaryTagLowercase.startsWith("div")
        -> "Divehi, Dhivehi, Maldivian"
        ietfPrimaryTagLowercase.startsWith("nl") || ietfPrimaryTagLowercase.startsWith("nld") -> "Dutch, Flemish"
        ietfPrimaryTagLowercase.startsWith("dz") || ietfPrimaryTagLowercase.startsWith("dzo") -> "Dzongkha"
        ietfPrimaryTagLowercase.startsWith("en") || ietfPrimaryTagLowercase.startsWith("eng") -> "English"
        ietfPrimaryTagLowercase.startsWith("eo") || ietfPrimaryTagLowercase.startsWith("epo") -> "Esperanto"
        ietfPrimaryTagLowercase.startsWith("et") || ietfPrimaryTagLowercase.startsWith("est") -> "Estonian"
        ietfPrimaryTagLowercase.startsWith("ee") || ietfPrimaryTagLowercase.startsWith("ewe") -> "Ewe"
        ietfPrimaryTagLowercase.startsWith("fo") || ietfPrimaryTagLowercase.startsWith("fao") -> "Faroese"
        ietfPrimaryTagLowercase.startsWith("fj") || ietfPrimaryTagLowercase.startsWith("fij") -> "Fijian"
        ietfPrimaryTagLowercase.startsWith("fil") -> "Filipino" // Check before "fi" not to fall there!
        ietfPrimaryTagLowercase.startsWith("fi") || ietfPrimaryTagLowercase.startsWith("fin") -> "Finnish"
        ietfPrimaryTagLowercase.startsWith("fr") || ietfPrimaryTagLowercase.startsWith("fra") -> "French"
        ietfPrimaryTagLowercase.startsWith("ff") || ietfPrimaryTagLowercase.startsWith("ful") -> "Fulah"
        ietfPrimaryTagLowercase.startsWith("gl") || ietfPrimaryTagLowercase.startsWith("glg") -> "Galician"
        ietfPrimaryTagLowercase.startsWith("ka") || ietfPrimaryTagLowercase.startsWith("kat") -> "Georgian"
        ietfPrimaryTagLowercase.startsWith("de") || ietfPrimaryTagLowercase.startsWith("deu") -> "German"
        ietfPrimaryTagLowercase.startsWith("el") || ietfPrimaryTagLowercase.startsWith("ell") -> "Greek, Modern"
        ietfPrimaryTagLowercase.startsWith("gn") || ietfPrimaryTagLowercase.startsWith("grn") -> "Guarani"
        ietfPrimaryTagLowercase.startsWith("gu") || ietfPrimaryTagLowercase.startsWith("guj") -> "Gujarati"
        ietfPrimaryTagLowercase.startsWith("ht") || ietfPrimaryTagLowercase.startsWith("hat")
        -> "Haitian, Haitian Creole"
        ietfPrimaryTagLowercase.startsWith("ha") || ietfPrimaryTagLowercase.startsWith("iw") ||
            ietfPrimaryTagLowercase.startsWith("hau") -> "Hausa"
        ietfPrimaryTagLowercase.startsWith("he") || ietfPrimaryTagLowercase.startsWith("heb") -> "Hebrew"
        ietfPrimaryTagLowercase.startsWith("hz") || ietfPrimaryTagLowercase.startsWith("her") -> "Herero"
        ietfPrimaryTagLowercase.startsWith("hi") || ietfPrimaryTagLowercase.startsWith("hin") -> "Hindi"
        ietfPrimaryTagLowercase.startsWith("ho") || ietfPrimaryTagLowercase.startsWith("hmo") -> "Hiri Motu"
        ietfPrimaryTagLowercase.startsWith("hu") || ietfPrimaryTagLowercase.startsWith("hun") -> "Hungarian"
        ietfPrimaryTagLowercase.startsWith("ia") || ietfPrimaryTagLowercase.startsWith("ina")
        -> "Interlingua (International Auxiliary Language Association)"
        ietfPrimaryTagLowercase.startsWith("id") || ietfPrimaryTagLowercase.startsWith("in") ||
            ietfPrimaryTagLowercase.startsWith("ind") -> "Indonesian"
        ietfPrimaryTagLowercase.startsWith("ie") || ietfPrimaryTagLowercase.startsWith("ile")
        -> "Interlingue, Occidental"
        ietfPrimaryTagLowercase.startsWith("ga") || ietfPrimaryTagLowercase.startsWith("gle") -> "Irish"
        ietfPrimaryTagLowercase.startsWith("ig") || ietfPrimaryTagLowercase.startsWith("ibo") -> "Igbo"
        ietfPrimaryTagLowercase.startsWith("ik") || ietfPrimaryTagLowercase.startsWith("ipk") -> "Inupiaq"
        ietfPrimaryTagLowercase.startsWith("io") || ietfPrimaryTagLowercase.startsWith("ido") -> "Ido"
        ietfPrimaryTagLowercase.startsWith("is") || ietfPrimaryTagLowercase.startsWith("isl") -> "Icelandic"
        ietfPrimaryTagLowercase.startsWith("it") || ietfPrimaryTagLowercase.startsWith("ita") -> "Italian"
        ietfPrimaryTagLowercase.startsWith("iu") || ietfPrimaryTagLowercase.startsWith("iku") -> "Inuktitut"
        ietfPrimaryTagLowercase.startsWith("ja") || ietfPrimaryTagLowercase.startsWith("jpn") -> "Japanese"
        ietfPrimaryTagLowercase.startsWith("jv") || ietfPrimaryTagLowercase.startsWith("jav") -> "Javanese"
        ietfPrimaryTagLowercase.startsWith("kl") || ietfPrimaryTagLowercase.startsWith("kal")
        -> "Kalaallisut, Greenlandic"
        ietfPrimaryTagLowercase.startsWith("kn") || ietfPrimaryTagLowercase.startsWith("kan") -> "Kannada"
        ietfPrimaryTagLowercase.startsWith("kr") || ietfPrimaryTagLowercase.startsWith("kau") -> "Kanuri"
        ietfPrimaryTagLowercase.startsWith("ks") || ietfPrimaryTagLowercase.startsWith("kas") -> "Kashmiri"
        ietfPrimaryTagLowercase.startsWith("kk") || ietfPrimaryTagLowercase.startsWith("kaz") -> "Kazakh"
        ietfPrimaryTagLowercase.startsWith("km") || ietfPrimaryTagLowercase.startsWith("khm")
        -> "Central Khmer"
        ietfPrimaryTagLowercase.startsWith("ki") || ietfPrimaryTagLowercase.startsWith("kik")
        -> "Kikuyu, Gikuyu"
        ietfPrimaryTagLowercase.startsWith("rw") || ietfPrimaryTagLowercase.startsWith("kin") -> "Kinyarwanda"
        ietfPrimaryTagLowercase.startsWith("ky") || ietfPrimaryTagLowercase.startsWith("kir")
        -> "Kirghiz, Kyrgyz"
        ietfPrimaryTagLowercase.startsWith("kv") || ietfPrimaryTagLowercase.startsWith("kom") -> "Komi"
        ietfPrimaryTagLowercase.startsWith("kg") || ietfPrimaryTagLowercase.startsWith("kon") -> "Kongo"
        ietfPrimaryTagLowercase.startsWith("ko") || ietfPrimaryTagLowercase.startsWith("kor") -> "Korean"
        ietfPrimaryTagLowercase.startsWith("kj") || ietfPrimaryTagLowercase.startsWith("kua")
        -> "Kuanyama, Kwanyama"
        ietfPrimaryTagLowercase.startsWith("ku") || ietfPrimaryTagLowercase.startsWith("kur") -> "Kurdish"
        ietfPrimaryTagLowercase.startsWith("lo") || ietfPrimaryTagLowercase.startsWith("lao") -> "Lao"
        ietfPrimaryTagLowercase.startsWith("la") || ietfPrimaryTagLowercase.startsWith("lat") -> "Latin"
        ietfPrimaryTagLowercase.startsWith("lv") || ietfPrimaryTagLowercase.startsWith("lav") -> "Latvian"
        ietfPrimaryTagLowercase.startsWith("li") || ietfPrimaryTagLowercase.startsWith("lim")
        -> "Limburgan, Limburger, Limburgish"
        ietfPrimaryTagLowercase.startsWith("ln") || ietfPrimaryTagLowercase.startsWith("lin") -> "Lingala"
        ietfPrimaryTagLowercase.startsWith("lt") || ietfPrimaryTagLowercase.startsWith("lit") -> "Lithuanian"
        ietfPrimaryTagLowercase.startsWith("lu") || ietfPrimaryTagLowercase.startsWith("lub") -> "Luba-Katanga"
        ietfPrimaryTagLowercase.startsWith("lb") || ietfPrimaryTagLowercase.startsWith("ltz")
        -> "Luxembourgish, Letzeburgesch"
        ietfPrimaryTagLowercase.startsWith("mk") || ietfPrimaryTagLowercase.startsWith("mkd")
        -> "Macedonian"
        ietfPrimaryTagLowercase.startsWith("mg") || ietfPrimaryTagLowercase.startsWith("mlg") -> "Malagasy"
        ietfPrimaryTagLowercase.startsWith("ms") || ietfPrimaryTagLowercase.startsWith("msa") -> "Malay"
        ietfPrimaryTagLowercase.startsWith("ml") || ietfPrimaryTagLowercase.startsWith("mal") -> "Malayalam"
        ietfPrimaryTagLowercase.startsWith("mt") || ietfPrimaryTagLowercase.startsWith("mlt") -> "Maltese"
        ietfPrimaryTagLowercase.startsWith("gv") || ietfPrimaryTagLowercase.startsWith("glv") -> "Manx"
        ietfPrimaryTagLowercase.startsWith("mi") || ietfPrimaryTagLowercase.startsWith("mri") -> "Maori"
        ietfPrimaryTagLowercase.startsWith("mr") || ietfPrimaryTagLowercase.startsWith("mar") -> "Marathi"
        ietfPrimaryTagLowercase.startsWith("mh") || ietfPrimaryTagLowercase.startsWith("mah")
        -> "Marshallese"
        ietfPrimaryTagLowercase.startsWith("mn") || ietfPrimaryTagLowercase.startsWith("mon") -> "Mongolian"
        ietfPrimaryTagLowercase.startsWith("na") || ietfPrimaryTagLowercase.startsWith("nau") -> "Nauru"
        ietfPrimaryTagLowercase.startsWith("nv") || ietfPrimaryTagLowercase.startsWith("nav")
        -> "Navajo, Navaho"
        ietfPrimaryTagLowercase.startsWith("nd") || ietfPrimaryTagLowercase.startsWith("nde")
        -> "North Ndebele"
        ietfPrimaryTagLowercase.startsWith("nr") || ietfPrimaryTagLowercase.startsWith("nbl")
        -> "South Ndebele"
        ietfPrimaryTagLowercase.startsWith("ng") || ietfPrimaryTagLowercase.startsWith("ndo") -> "Ndonga"
        ietfPrimaryTagLowercase.startsWith("ne") || ietfPrimaryTagLowercase.startsWith("nep") -> "Nepali"
        ietfPrimaryTagLowercase.startsWith("se") || ietfPrimaryTagLowercase.startsWith("sme")
        -> "Northern Sami"
        ietfPrimaryTagLowercase.startsWith("no") || ietfPrimaryTagLowercase.startsWith("nb") ||
            ietfPrimaryTagLowercase.startsWith("nor") -> "Norwegian"
        ietfPrimaryTagLowercase.startsWith("nb") || ietfPrimaryTagLowercase.startsWith("nob")
        -> "Norwegian Bokmål"
        ietfPrimaryTagLowercase.startsWith("nn") || ietfPrimaryTagLowercase.startsWith("nno")
        -> "Norwegian Nynorsk"
        ietfPrimaryTagLowercase.startsWith("ny") || ietfPrimaryTagLowercase.startsWith("nya")
        -> "Chichewa, Chewa, Nyanja"
        ietfPrimaryTagLowercase.startsWith("oc") || ietfPrimaryTagLowercase.startsWith("oci") -> "Occitan"
        ietfPrimaryTagLowercase.startsWith("oj") || ietfPrimaryTagLowercase.startsWith("oji") -> "Ojibwa"
        ietfPrimaryTagLowercase.startsWith("or") || ietfPrimaryTagLowercase.startsWith("ori") -> "Oriya"
        ietfPrimaryTagLowercase.startsWith("om") || ietfPrimaryTagLowercase.startsWith("orm") -> "Oromo"
        ietfPrimaryTagLowercase.startsWith("os") || ietfPrimaryTagLowercase.startsWith("oss")
        -> "Ossetian, Ossetic"
        ietfPrimaryTagLowercase.startsWith("pi") || ietfPrimaryTagLowercase.startsWith("pli") -> "Pali"
        ietfPrimaryTagLowercase.startsWith("pa") || ietfPrimaryTagLowercase.startsWith("pan")
        -> "Panjabi, Punjabi"
        ietfPrimaryTagLowercase.startsWith("fa") || ietfPrimaryTagLowercase.startsWith("fas") -> "Persian"
        ietfPrimaryTagLowercase.startsWith("pl") || ietfPrimaryTagLowercase.startsWith("pol") -> "Polish"
        ietfPrimaryTagLowercase.startsWith("pt") || ietfPrimaryTagLowercase.startsWith("por") -> "Portuguese"
        ietfPrimaryTagLowercase.startsWith("ps") || ietfPrimaryTagLowercase.startsWith("pus")
        -> "Pushto, Pashto"
        ietfPrimaryTagLowercase.startsWith("qu") || ietfPrimaryTagLowercase.startsWith("que") -> "Quechua"
        ietfPrimaryTagLowercase.startsWith("ro") || ietfPrimaryTagLowercase.startsWith("ron") -> "Romanian"
        ietfPrimaryTagLowercase.startsWith("rm") || ietfPrimaryTagLowercase.startsWith("roh") -> "Romansh"
        ietfPrimaryTagLowercase.startsWith("rn") || ietfPrimaryTagLowercase.startsWith("run") -> "Rundi"
        ietfPrimaryTagLowercase.startsWith("ru") || ietfPrimaryTagLowercase.startsWith("rus") -> "Russian"
        ietfPrimaryTagLowercase.startsWith("se") || ietfPrimaryTagLowercase.startsWith("sme")
        -> "Northern Sami"
        ietfPrimaryTagLowercase.startsWith("sm") || ietfPrimaryTagLowercase.startsWith("smo") -> "Samoan"
        ietfPrimaryTagLowercase.startsWith("sg") || ietfPrimaryTagLowercase.startsWith("sag") -> "Sango"
        ietfPrimaryTagLowercase.startsWith("sa") || ietfPrimaryTagLowercase.startsWith("san") -> "Sanskrit"
        ietfPrimaryTagLowercase.startsWith("sc") || ietfPrimaryTagLowercase.startsWith("srd") -> "Sardinian"
        ietfPrimaryTagLowercase.startsWith("sr") || ietfPrimaryTagLowercase.startsWith("srp") -> "Serbian"
        ietfPrimaryTagLowercase.startsWith("sn") || ietfPrimaryTagLowercase.startsWith("sna") -> "Shona"
        ietfPrimaryTagLowercase.startsWith("ii") || ietfPrimaryTagLowercase.startsWith("iii")
        -> "Sichuan Yi, Nuosu"
        ietfPrimaryTagLowercase.startsWith("sd") || ietfPrimaryTagLowercase.startsWith("snd") -> "Sindhi"
        ietfPrimaryTagLowercase.startsWith("si") || ietfPrimaryTagLowercase.startsWith("sin")
        -> "Sinhala, Sinhalese"
        ietfPrimaryTagLowercase.startsWith("sk") || ietfPrimaryTagLowercase.startsWith("slk") -> "Slovak"
        ietfPrimaryTagLowercase.startsWith("sl") || ietfPrimaryTagLowercase.startsWith("slv") -> "Slovenian"
        ietfPrimaryTagLowercase.startsWith("so") || ietfPrimaryTagLowercase.startsWith("som") -> "Somali"
        ietfPrimaryTagLowercase.startsWith("st") || ietfPrimaryTagLowercase.startsWith("sot")
        -> "Sotho, Southern"
        ietfPrimaryTagLowercase.startsWith("es") || ietfPrimaryTagLowercase.startsWith("spa")
        -> "Spanish, Castilian"
        ietfPrimaryTagLowercase.startsWith("su") || ietfPrimaryTagLowercase.startsWith("sun") -> "Sundanese"
        ietfPrimaryTagLowercase.startsWith("sw") || ietfPrimaryTagLowercase.startsWith("swa") -> "Swahili"
        ietfPrimaryTagLowercase.startsWith("ss") || ietfPrimaryTagLowercase.startsWith("ssw") -> "Swati"
        ietfPrimaryTagLowercase.startsWith("sv") || ietfPrimaryTagLowercase.startsWith("swe") -> "Swedish"
        ietfPrimaryTagLowercase.startsWith("tl") || ietfPrimaryTagLowercase.startsWith("tgl") -> "Tagalog"
        ietfPrimaryTagLowercase.startsWith("ty") || ietfPrimaryTagLowercase.startsWith("tah") -> "Tahitian"
        ietfPrimaryTagLowercase.startsWith("tg") || ietfPrimaryTagLowercase.startsWith("tgk") -> "Tajik"
        ietfPrimaryTagLowercase.startsWith("ta") || ietfPrimaryTagLowercase.startsWith("tam") -> "Tamil"
        ietfPrimaryTagLowercase.startsWith("tt") || ietfPrimaryTagLowercase.startsWith("tat") -> "Tatar"
        ietfPrimaryTagLowercase.startsWith("te") || ietfPrimaryTagLowercase.startsWith("tel") -> "Telugu"
        ietfPrimaryTagLowercase.startsWith("th") || ietfPrimaryTagLowercase.startsWith("tha") -> "Thai"
        ietfPrimaryTagLowercase.startsWith("bo") || ietfPrimaryTagLowercase.startsWith("bod") -> "Tibetan"
        ietfPrimaryTagLowercase.startsWith("ti") || ietfPrimaryTagLowercase.startsWith("tir") -> "Tigrinya"
        ietfPrimaryTagLowercase.startsWith("to") || ietfPrimaryTagLowercase.startsWith("ton")
        -> "Tonga (Tonga Islands)"
        ietfPrimaryTagLowercase.startsWith("ts") || ietfPrimaryTagLowercase.startsWith("tso") -> "Tsonga"
        ietfPrimaryTagLowercase.startsWith("tn") || ietfPrimaryTagLowercase.startsWith("tsn") -> "Tswana"
        ietfPrimaryTagLowercase.startsWith("tr") || ietfPrimaryTagLowercase.startsWith("tur") -> "Turkish"
        ietfPrimaryTagLowercase.startsWith("tk") || ietfPrimaryTagLowercase.startsWith("tuk") -> "Turkmen"
        ietfPrimaryTagLowercase.startsWith("tw") || ietfPrimaryTagLowercase.startsWith("twi") -> "Twi"
        ietfPrimaryTagLowercase.startsWith("ug") || ietfPrimaryTagLowercase.startsWith("uig")
        -> "Uighur, Uyghur"
        ietfPrimaryTagLowercase.startsWith("uk") || ietfPrimaryTagLowercase.startsWith("ukr") -> "Ukrainian"
        ietfPrimaryTagLowercase.startsWith("ur") || ietfPrimaryTagLowercase.startsWith("urd") -> "Urdu"
        ietfPrimaryTagLowercase.startsWith("uz") || ietfPrimaryTagLowercase.startsWith("uzb") -> "Uzbek"
        ietfPrimaryTagLowercase.startsWith("ve") || ietfPrimaryTagLowercase.startsWith("ven") -> "Venda"
        ietfPrimaryTagLowercase.startsWith("vi") || ietfPrimaryTagLowercase.startsWith("vie")
        -> "Vietnamese"
        ietfPrimaryTagLowercase.startsWith("vo") || ietfPrimaryTagLowercase.startsWith("vol") -> "Volapük"
        ietfPrimaryTagLowercase.startsWith("wa") || ietfPrimaryTagLowercase.startsWith("wln") -> "Walloon"
        ietfPrimaryTagLowercase.startsWith("cy") || ietfPrimaryTagLowercase.startsWith("cym") -> "Welsh"
        ietfPrimaryTagLowercase.startsWith("fy") || ietfPrimaryTagLowercase.startsWith("fry")
        -> "Western Frisian"
        ietfPrimaryTagLowercase.startsWith("wo") || ietfPrimaryTagLowercase.startsWith("wol") -> "Wolof"
        ietfPrimaryTagLowercase.startsWith("xh") || ietfPrimaryTagLowercase.startsWith("xho") -> "Xhosa"
        ietfPrimaryTagLowercase.startsWith("yi") || ietfPrimaryTagLowercase.startsWith("yid") -> "Yiddish"
        ietfPrimaryTagLowercase.startsWith("yo") || ietfPrimaryTagLowercase.startsWith("yor") -> "Yoruba"
        ietfPrimaryTagLowercase.startsWith("yue") -> "Cantonese"
        ietfPrimaryTagLowercase.startsWith("za") || ietfPrimaryTagLowercase.startsWith("zha")
        -> "Zhuang, Chuang"
        ietfPrimaryTagLowercase.startsWith("zu") || ietfPrimaryTagLowercase.startsWith("zul") -> "Zulu"
        else -> {
            /* #LoggingOfUnknownLanguages */
            Log.w(
                DiagnosticEvent(
                    message = "Unhandled language: Support needs to be added to Multiplatform SDK.",
                    sourceAreaId = "LanguageIdentity.tryGetDisplayName",
                    properties = mapOf(
                        "ietfTag" to ietfTag,
                    ),
                ),
            )
            null
        }
    }
}
