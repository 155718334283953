package com.speechify.client.api.services.subscription.models

import com.speechify.client.internal.time.ISO8601DateString
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class Subscription(
    /** The internal ID of the subscription. */
    val id: String,
    val plan: SubscriptionPlan,
    // Is the user is on trial and how many days are left?
    // Due to the trial->paid transition is done in webhooks
    // we don't have a lot of control over the exact time of transition,
    // so number of days is better representation than exact timestamp
    val isOnTrial: Boolean,
    val status: SubscriptionStatus,
    val renewsAt: ISO8601DateString,
    val subscribedAt: ISO8601DateString,
    val renewalStatus: RenewalStatus,
    /** The 3 letter ISO code of the currency the user used to pay for the subscription. */
    val currency: String,
    val lastPaymentAt: ISO8601DateString?,

    /** Array containing all discounts applied to the subscription */
    val discounts: Array<SubscriptionDiscount>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as Subscription

        if (id != other.id) return false
        if (plan != other.plan) return false
        if (isOnTrial != other.isOnTrial) return false
        if (status != other.status) return false
        if (renewsAt != other.renewsAt) return false
        if (subscribedAt != other.subscribedAt) return false
        if (renewalStatus != other.renewalStatus) return false
        if (currency != other.currency) return false
        if (lastPaymentAt != other.lastPaymentAt) return false
        if (!discounts.contentEquals(other.discounts)) return false

        return true
    }

    override fun hashCode(): Int {
        var result = id.hashCode()
        result = 31 * result + plan.hashCode()
        result = 31 * result + isOnTrial.hashCode()
        result = 31 * result + status.hashCode()
        result = 31 * result + renewsAt.hashCode()
        result = 31 * result + subscribedAt.hashCode()
        result = 31 * result + renewalStatus.hashCode()
        result = 31 * result + currency.hashCode()
        result = 31 * result + lastPaymentAt.hashCode()
        result = 31 * result + discounts.contentHashCode()
        return result
    }
}
