// ktlint-disable filename
package com.speechify.client.internal.util.www

import com.speechify.client.api.services.scannedbook.Base64.decodeFromBase64
import com.speechify.client.api.services.scannedbook.Base64.encodeToBase64
import com.speechify.client.api.util.MimeType
import com.speechify.client.internal.util.extensions.strings.skipPrefix
import com.speechify.client.internal.util.extensions.strings.splitInTwoOnFirst
import com.speechify.client.internal.util.extensions.strings.splitInTwoOnLast

internal fun ByteArray.asDataUrl(
    /**
     * The 'mediatype' part of [spec syntax](https://www.rfc-editor.org/rfc/rfc2397#section-3), which is
     * effectively the full MIME type, and can include the 'MIME metadata' after `;`, for example `text/html; charset=utf-8.
     */
    mediaType: String,
): UrlString {
    return "data:$mediaType;base64,${encodeToBase64()}"
}

internal fun dataUrlToBinaryContent(url: UrlString): DataUrlToBinaryContentResult {
    val urlContent = url.skipPrefix("data:")
    val (contentTypePart, encodedData) = urlContent.splitInTwoOnFirst(",")
    require(encodedData != null) { "expected ',' before the actual data" }

    val (mimeType, encoding) = contentTypePart.splitInTwoOnLast(";")
    /** Using `Last` because `;` can occur twice, e.g. in `data:text/plain;charset=US-ASCII;base64`, as
     [per spec](https://www.rfc-editor.org/rfc/rfc2397#section-3). See also [asDataUrl]
     */
    if (encoding != "base64") {
        TODO("Non-base64 encoding is not implemented") /* Could implement it if needed. Probably need to
         percent-decode. */
    }

    val data = encodedData.decodeFromBase64()
    return DataUrlToBinaryContentResult(
        mimeType = MimeType(mimeType),
        bytes = data,
    )
}

internal class DataUrlToBinaryContentResult(
    val mimeType: MimeType,
    val bytes: ByteArray,
)
