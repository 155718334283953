package com.speechify.client.bundlers.reading

import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksAroundCursor
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.WebPageView
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.observableValue.AsyncMutableObservableValue
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.util.extensions.collections.firstInstanceOf
import com.speechify.client.internal.util.extensions.strings.nullIfEmpty
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.isActive
import kotlinx.coroutines.job
import kotlin.js.JsExport
import kotlin.js.JsName

internal const val DEFAULT_TITLE = "Untitled Document"

/** The component allowing to observe and set the **effective** title of the content item, as well as
 * get an 'automatically guessed' title ([MutableObservableContentTitle.getAutoInferredCandidate]).
 * The less ambiguous component for observing the title (see quirks of [ContentTitleExtractor.addTitleListener] and
 * [ContentTitleExtractor.getTitle] for description of the ambiguities).
 *
 * DANGER: See [com.speechify.client.api.util.boundary.observableValue.ObservableValue] for instructions on how to
 * prevent state corruption.
 */
@JsExport
abstract class MutableObservableContentTitle : AsyncMutableObservableValue<String> {
    /* Adding own method, beside the `addListener`, to make the Callback strongly typed for Swift, as discovered to be
       needed [here](https://speechifyworkspace.slack.com/archives/C03DT8SNLN5/p1676629893911809?thread_ts=1676457748.829749&cid=C03DT8SNLN5)
       (It works fine for TypeScript)
     */
    /**
     * Strongly-typed version of [addListener]. Adds a listener to listen to the value being initialized and changing.
     *
     * NOTE: First call of [callback] with an error is also a terminating one (there will be no more calls).
     *
     * DANGER: See [com.speechify.client.api.util.boundary.observableValue.ObservableValue] for instructions on how to
     * prevent state corruption.
     */
    fun addValueListener(callback: Callback<String>): Destructor =
        addListener(callback)

    /**
     * To be used by SDK consumers for propagating renames done outside the bundle of an imported item.
     * Can also be used to set a candidate-title before import, for example from [MutableObservableContentTitle.getAutoInferredCandidate]
     * (it will still be overrideable using [com.speechify.client.bundlers.reading.importing.ContentImporter.startImport]'s [com.speechify.client.api.services.importing.models.ImportOptions.title],
     * which will also be observed in [addListener]).
     *
     * TODO In the future this may acquire a mechanism that also saves the title on library items -
     *  see [PLT-2454](https://linear.app/speechify-inc/issue/PLT-2454)
     */
    override fun set(
        newValue: String,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "MutableObservableContentTitle.set",
    ) {
        set(newValue)
            .successfully()
    }

    internal abstract suspend fun set(
        newValue: String,
    )

    /**
     * Returns a 'guessed' title for the content item, based on metadata or contents.
     */
    fun getAutoInferredCandidate(callback: Callback<String>): Unit = callback.fromCoWithErrorLogging(
        sourceAreaId = "MutableObservableContentTitle.getAutoInferredCandidate",
    ) {
        getAutoInferredCandidate()
            .successfully()
    }

    @JsName(
        "InternalGetAutoInferredCandidate", /* Kotlin code analysis glitch? The function is internal so not
             exported, but it complains at name clash for export */
    )
    internal abstract suspend fun getAutoInferredCandidate(): String
}

@JsExport
class ContentTitleExtractor(private val contentBundle: ContentBundle) :
    WithScope() {
    /** TODO - once all members exposing [ContentTitleExtractor] this are removed (they are marked obsolete), make this
     *   class internal and just implement [MutableObservableContentTitle].
     */

    /**
     * See [MutableObservableContentTitle] for semantics of this member.
     */
    val observable: MutableObservableContentTitle = object : MutableObservableContentTitle() {
        override fun addListener(callback: Callback<String>): Destructor =
            callback.multiShotFromFlowIn(
                effectiveTitle.getStartedFlow(),
                scope = scope,
                notifyOfFlowCancel = false, /* This shouldn't happen as state flows don't cancel, but even if, let's
                 keep the API simple */
            )::destroy

        override suspend fun set(
            newValue: String,
        ) {
            effectiveTitle.set(newValue)
                .successfully()
        }

        override suspend fun getAutoInferredCandidate(): String =
            effectiveTitle.autoInferredCandidate.await()
                .orThrow()
    }

    /**
     * Separate property to encapsulate dangerous access to the underlying flow that hasn't started.
     */
    private val effectiveTitle = object {
        /**
         * Safe member to use to set the value, and it will prevent race conditions with initialization (guessing based
         * on content item).
         */
        @Suppress(
            "RedundantVisibilityModifier", /* Kotlin code analysis glitch? The container object is private so not
             exported, but it complains at non-exportability of `suspend` function */
        )
        internal suspend fun set(newValue: String) {
            initialValueSettingInferredTitle.cancelAndJoin() /* Prevent any race from `autoInferredTitleSettingJob` from
              overwriting this value (`Join` especially ensures there's no race-condition in multithreaded platforms)
            */

            effectiveTitleStateFlow.value = newValue
        }

        /**
         * This member will always return the value, even if guessing for initialization was cancelled.
         */
        @Suppress(
            "RedundantVisibilityModifier", /* Kotlin code analysis glitch? The container object is private so not
             exported, but it complains at non-exportability of `suspend` function */
        )
        internal val autoInferredCandidate: Deferred<Result<String>> = scope.async(
            start = CoroutineStart.LAZY,
        ) {

            try {
                initialValueSettingInferredTitle.await()
            } catch (e: CancellationException) {
                if (kotlin.coroutines.coroutineContext.job.isCancelled) { /* Only throw to propagate the cancellation if
                 it was the call to this function that was cancelled. If it was just the deferred, then continue. */
                    throw e
                }
                this@ContentTitleExtractor.contentBundle.getAutoInferredCandidate() /* The setting job could have
                 been cancelled by a call to `set` but this method requires the inferred title, so let's compute it. */
            }
        }

        /**
         * NOTE: If the 'guessing' has not started yet, it won't be started. Use [getStartedFlow] to get a flow that
         * also starts the guessing.
         */
        fun getCurrentValueWithoutStarting(): String? =
            effectiveTitleStateFlow.value

        @Suppress(
            "NON_EXPORTABLE_TYPE", /* Kotlin code analysis glitch? The container object is private so not exported */
        )
        fun getStartedFlow(): Flow<String> {
            initialValueSettingInferredTitle.start()

            return effectiveTitleStateFlow
                .filterNotNull()
        }

        @Suppress(
            "RedundantVisibilityModifier", /* Kotlin code analysis glitch? The container object is private so not
             exported, but it complains at non-exportability of `suspend` function */
        )
        internal suspend fun waitForInitialValueSettingJobNotRunning() {
            initialValueSettingInferredTitle.join()
        }

        val currentValue: String
            get() = effectiveTitleStateFlow.value
                ?: throw IllegalStateException("Title not set yet")

        private val initialValueSettingInferredTitle: Deferred<Result<String>> = scope.async(
            /** Don't start until requested, to give the opportunity for[MutableObservableContentTitle.set] to prevent
             this from firing and save some computation.
             */
            start = CoroutineStart.LAZY,
        ) {
            val newTitle = contentBundle.getAutoInferredCandidate()
                .also { result ->
                    result.onFailure {
                        Log.e(failure = it, sourceAreaId = "ContentTitleExtractor.initialValueSettingInferredTitle")
                    }
                }
                .orReturn { return@async it }

            if (this@async.isActive) { /* In case the cancellation happened when `getAutoInferredCandidate()` finished,
             we still want to return. Just not update. */
                /* This is the only place where we need to set on the flow directly, and not through our `set` method,
                   because else the `set` would cancel us.
                 */
                effectiveTitleStateFlow.value = newTitle
            }

            newTitle.successfully()
        }

        /**
         * DANGER: Read it only through [getStartedFlow] (except where implementing
         * internals) or else it will hang.
         */
        private val effectiveTitleStateFlow = MutableStateFlow<String?>(null)
    }

    /**
     * To be used on item import, to establish the final title for the item.
     *
     * 1. Returns a non `null` title - from the current value or by using the
     * [MutableObservableContentTitle.getAutoInferredCandidate] (unless there was an error)
     *
     * 2. Sets the title if it was `null` before the method was called to propagate to observers of the title
     */
    internal suspend fun getFinalizedTitleForImport(): Result<String> {
        ifNotNull(effectiveTitle.getCurrentValueWithoutStarting().nullIfEmpty()) {
            return it
                .successfully()
        }

        val autoInferredTitle = effectiveTitle.autoInferredCandidate.await()
            .orReturn { return it }

        effectiveTitle.set(autoInferredTitle)

        return autoInferredTitle
            .successfully()
    }

    /**
     * For testing only
     */
    internal suspend fun waitForInitialValueSettingJobNotRunning(): Unit =
        effectiveTitle.waitForInitialValueSettingJobNotRunning()

    /**
     * For testing only
     */
    internal val currentValue: String
        get() = effectiveTitle.currentValue

    /**
     * * When item not imported: returns an automatically 'guessed' title for the content
     * * When item is imported: returns the effective title of the imported item (it can be customized through
     * [com.speechify.client.bundlers.reading.importing.ContentImporter.startImport]'s [com.speechify.client.api.services.importing.models.ImportOptions.title]).
     */
    @Deprecated(
        "Use [observable.addListener]",
        ReplaceWith("observable.addListener"),
    )
    fun getTitle(callback: Callback<String>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "ContentTitleExtractor.getTitle",
        scope = scope,
    ) {
        coGetTitle()
    }

    internal suspend fun coGetTitle(): Result<String> =
        effectiveTitle.getStartedFlow()
            .first()
            .successfully()

    /**
     * Adds a listener of the various versions of the title:
     * * When item not imported: first result returns an automatically 'guessed' title for the content, and then any
     *   different 'effective title', if it was customized during import through [com.speechify.client.bundlers.reading.importing.ContentImporter.startImport]'s [com.speechify.client.api.services.importing.models.ImportOptions.title])
     * * When item is imported: returns the effective title of the imported item.
     *
     * NOTE: First call of [callback] with an error is also a terminating one (there will be no more calls).
     */
    @Deprecated(
        "Use `observable.addListener`",
        ReplaceWith("observable.addListener"),
    )
    fun addTitleListener(callback: Callback<String>): Destructor =
        observable.addListener(callback)
}

/**
 * The logic for auto inferring without any job control/memoization (it's contained in the other overrides).
 */
private suspend fun ContentBundle.getAutoInferredCandidate(): Result<String> =
    when (this) {
        is ContentBundle.WebPageBundle -> webPageView.extractTitle()?.successfully()
        is ContentBundle.EpubBundle -> when (this.epub.title) {
            null -> {
                this.epubViewAsWebPage.extractTitle()?.successfully()
            }
            else -> {
                this.epub.title.successfully()
            }
        }

        is ContentBundle.EpubV2Bundle -> this.epubV2.title?.successfully()
        is ContentBundle.EpubBundleV3 -> this.epubView.epubV3.title?.successfully()
        // TODO(mendess): Pdf adapters should be able to give us a title directly, change this so it delegates to book view PLT-2115
        else -> null
    } ?: standardView.defaultStandardViewTitleInference()

private suspend fun StandardView.defaultStandardViewTitleInference(): Result<String> =
    coGetBlocksAroundCursor(cursor = start)
        .map {
            it.blocks.firstInstanceOf<StandardBlock.Heading>()?.text
                ?: it.blocks.firstInstanceOf<StandardBlock.Header>()?.text
                ?: it.blocks.firstInstanceOf<StandardBlock.Paragraph>()?.text
        }
        .map {
            val text = it?.text ?: return@map DEFAULT_TITLE
            if (text.length >= 60) {
                text.substring(0 until 60)
            } else {
                text
            }
        }

private fun WebPageNode.Element.findChildElement(name: String): WebPageNode.Element? {
    return children
        .firstOrNull { it is WebPageNode.Element && it.tagName.lowercase() == name }
        as? WebPageNode.Element
}

private fun WebPageView.extractTitle(): String? =
    getWebPage()
        .root
        .findChildElement("head")
        ?.findChildElement("title")
        ?.children?.firstInstanceOf<WebPageNode.Text>()
        ?.rawText
