package com.speechify.client.api.adapters.http

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.io.BinaryContentReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import kotlin.js.JsExport

@JsExport
sealed class HttpRequestBodyData {
    class BodyBytes(val body: ByteArray?) : HttpRequestBodyData()
    class MultipartFormData(
        val entries: BoundaryMap<
            BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableInChunks>,
            >,
    ) : HttpRequestBodyData()
}
