package com.speechify.client.api.util.boundary.observableValue

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.toCallbackIgnoringErrors
import com.speechify.client.internal.util.collections.flows.flowFromCallbackProducer
import kotlinx.coroutines.flow.Flow
import kotlin.js.JsExport

/**
 * A value carrier for the boundary that is similar to [kotlinx.coroutines.flow.Flow], but has no guarantees of
 * the consumptions not racing.
 * DANGER:
 * * Races will easily occur **even in single-threaded environments** if the handlers have points of awaiting any
 *   async code. A second change of value that is early enough may cause listeners to run concurrently and even finish
 *   out of order (**handlers are responsible** for not using asynchronous side effects, to achieve concurrency
 *   control).
 * * Races can also occur if the implementation launches listeners in new threads (implementation is responsible for
 *   preventing this where needed, e.g. using special dispatcher or relying on compilation-target-platform default
 *   dispatcher being single-threaded).
 */
@JsExport
interface ObservableValue<out T> {
    /**
     * Adds a listener to listen to the value being initialized and changing.
     *
     * NOTE: First call of [callback] with an error is also a terminating one (there will be no more calls).
     *
     * DANGER: See [ObservableValue] for instructions on how to prevent state corruption.
     */
    fun addListener(callback: Callback<T>): Destructor
}

/**
 * A version of [ObservableValue] whose value can be set, and the setting is asynchronous.
 *
 * DANGER: See [ObservableValue] for instructions on how to prevent state corruption.
 */
@JsExport
interface AsyncMutableObservableValue<T> : ObservableValue<T> {
    fun set(
        newValue: T,
        /**
         * Will be called after the setting is successful, or when an error occurs.
         */
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    )
}

fun <T> ObservableValue<T>.asFlow(): Flow<T> {
    return flowFromCallbackProducer(
        { cb -> addListener(cb.toCallbackIgnoringErrors()) },
        shouldLogErrorIfCancellationPreventedDelivery = false,
        sourceAreaId = "ObservableValue.asFlow",
    )
}
