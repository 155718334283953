package com.speechify.client.api.content.view.book

import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.shouldRunOcrFallback
import com.speechify.client.api.content.view.book.parser.HeuristicsBookPageParser
import com.speechify.client.api.content.view.book.parser.MLBookPageParser
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.InMemoryCacheManager
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.book.LineGroup
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.services.ml.ParsedPageContentOrRawTextItems
import com.speechify.client.internal.sync.WrappingMutex
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach

/**
 * Utility class that wraps a [BookPage] - this is the place for adding all the "middleware" features like caching,
 * postprocessing, etc.
 */
internal class BaseBookPageWrapper internal constructor(
    // #InternalForTesting
    internal val bookPageDelegate: BookPageDelegate,
    // #InternalForTesting
    internal val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy>,
    private val runOcrFallback: suspend (BookPageDelegate) -> Result<List<BookPageTextContentItem>?>,
    private val mlParsingModeFlow: StateFlow<MLParsingMode>,
    private val getSurroundingLineGroups: suspend (pageIndex: Int, pageOffset: Int) -> Result<List<List<LineGroup>>>,
    private val getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR: suspend (
        bookPageDelegate: BookPageDelegate,
        rawContentTextItemsWithOCRIfNeeded: List<BookPageTextContentItem>,
        shouldRunOcrFallback: Boolean,
    ) -> Result<ParsedPageContentOrRawTextItems>,
    private val notifyTextContentRetrieved: suspend (pageIndex: Int, ParsedPageContent) -> Unit,
) : BookPage() {
    private val telemetryEventBuilder = TelemetryEventBuilder("BaseBookPageWrapper.mlPageParsing").also {
        it.addProperty("pageIndex" to bookPageDelegate.pageIndex)
    }
    override val pageIndex: Int = bookPageDelegate.pageIndex
    override val start: ContentCursor = bookPageDelegate.start
    override val end: ContentCursor = bookPageDelegate.end

    init {
        combine(mlParsingModeFlow, ocrFallbackStrategyFlow) { _, _ -> }
            // We drop the initial value here, in order to not clear the cache for no reason.
            .drop(1)
            .onEach {
                unstableParsedPageContentCache.cancelAndClearState()
                stableParsedPageContentCache.cancelAndClearState()
            }.launchIn(scope)
    }

    private val heuristicsBookPageParser by lazy {
        HeuristicsBookPageParser(
            bookPageDelegate = bookPageDelegate,
            getSurroundingLineGroups = getSurroundingLineGroups,
        )
    }

    private val mLBookPageParser by lazy {
        MLBookPageParser(
            bookPageDelegate = bookPageDelegate,
            getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR = {
                    bookPageDelegate,
                    rawContentTextItemsWithOCRIfNeeded,
                ->
                getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR(
                    bookPageDelegate,
                    rawContentTextItemsWithOCRIfNeeded,
                    shouldRunOcrFallback(ocrFallbackStrategyFlow.value, rawContentTextItemsWithOCRIfNeeded),
                )
            },
            fallbackToHeuristics = heuristicsBookPageParser::parse,
        )
    }

    private val unstableParsedPageContentCache =
        coLazy {
            val rawItems = bookPageDelegate.getRawTextContentItems().orReturn { return@coLazy it }
            when {
                // Handles ML parsing mode
                mlParsingModeFlow.value == MLParsingMode.ForceEnable -> rawItems.successfully()

                // Handles SDK heuristic parsing mode: if OCR is required, run the fallback strategy
                shouldRunOcrFallback(ocrFallbackStrategyFlow.value, rawItems) -> {
                    getRawTextItemsFromOcr().map { it ?: rawItems }
                }

                // Handles SDK heuristic parsing mode use content retrieved from PSPDFKit library parsing
                else -> rawItems.successfully()
            }
        }

    private val stableParsedPageContentCache =
        coLazy {
            run {
                val rawItems = unstableParsedPageContentCache.get().orReturn { return@run it }
                when (mlParsingModeFlow.value) {
                    MLParsingMode.ForceDisable -> heuristicsBookPageParser.parse(rawItems)
                    MLParsingMode.ForceEnable -> mLBookPageParser.parse(rawItems)
                }
            }.ifSuccessful {
                notifyTextContentRetrieved.invoke(pageIndex, it)
            }
        }

    private val imageCache =
        WrappingMutex.of(
            mutableMapOf<
                BookPageRequestOptions,
                BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
                >(),
        )

    private val imageCacheDtor = InMemoryCacheManager.register(
        destructor = suspend {
            imageCache.locked { cache ->
                cache.clear()
            }
        },
    )

    override fun getMetadata(): BookPageMetadata = bookPageDelegate.getMetadata()

    override fun getImage(
        options: BookPageRequestOptions,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) = callback.fromCo {
        imageCache.locked { cache ->
            when (val image = cache[options]) {
                null ->
                    bookPageDelegate
                        .getImage(options)
                        .inspectSuccess {
                            cache[options] = it
                        }

                else -> image.successfully()
            }
        }
    }

    override suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult {
        return bookPageDelegate.getTextInBounds(boxes)
    }

    override suspend fun getStableParsedPageContent(): Result<ParsedPageContent> =
        stableParsedPageContentCache.getWithCancellation()

    override suspend fun getUnstableTextContentApproximatelyOrdered(): Result<List<UnstableBookPageTextContentItem>> =
        unstableParsedPageContentCache.getWithCancellation()

    override suspend fun getStandardBlockRepresentation(): StandardBlocks? = null

    override fun destroy() {
        // Important: We don't cancel the entire scope. If the page loses focus, it may regain focus later.
        // Therefore, we only cancel the suspending operations and clear the CoLazy state.
        stableParsedPageContentCache.cancelAndClearState()
        unstableParsedPageContentCache.cancelAndClearState()
        bookPageDelegate.destroy()
        launchTask {
            imageCacheDtor()
        }
    }

    private suspend fun getRawTextItemsFromOcr(): Result<List<BookPageTextContentItem>?> {
        val result =
            telemetryEventBuilder.addMeasurement("runOcrFallback") {
                runOcrFallback(bookPageDelegate).also {
                    telemetryEventBuilder.addProperty("isMlParsingIncludesOCR" to true)
                }
            }

        if (result is Result.Failure) result.error.logOCRFailure()
        return result
    }

    private fun SDKError.logOCRFailure() {
        telemetryEventBuilder.addProperty("isOCRFailed" to true)
        Log.e(
            DiagnosticEvent(
                message = "BaseBookPageWrapper.runOcrFallback: Failed to run OCR fallback.",
                error = ErrorInfoForDiagnostics(toNativeError()),
                properties = SdkBoundaryMap.of("pageIndex" to bookPageDelegate.pageIndex),
                sourceAreaId = "BaseBookPageWrapper.delegateRawTextContentItemsWithOCRIfNeededCache",
            ),
        )
    }
}
