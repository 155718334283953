package com.speechify.client.internal.caching

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.io.coGetSizeInBytes
import com.speechify.client.api.util.io.toBinaryContentWithMimeTypeReadableSequentially
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload
import com.speechify.client.internal.http.HttpClient
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch

/**
 * A version of [CachedRemoteFileProviderWithHttpDownload] which is a _repository_, so also has writing capabilities (put & delete).
 * (Implementers provide the logic by overriding [putFileToRemoteStorage] and [deleteFileFromRemoteStorage]).
 */
internal abstract class CachedRemoteFileRepositoryWithHttpDownload<FileIdType : FileId>(
    diagnosticAreaId: String,
    cacheStorage: BlobStorageAdapter,
    httpClient: HttpClient,
) : CachedRemoteFileProviderWithHttpDownload<FileIdType>(
    diagnosticAreaId = diagnosticAreaId,
    cacheStorage = cacheStorage,
    httpClient = httpClient,
),
    FileRepository<FileIdType> {
    override suspend fun putFile(
        fileId: FileIdType,
        payload: BinaryContentWithMimeTypePayload<*, *>,
    ): Unit =
        putFile(
            fileId = fileId,
            payload = payload,
            sourceAreaId = "$diagnosticAreaId.putFile",
            cacheAction = {
                when (payload) {
                    is BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload -> {
                        cacheStorage.coPutBlob(
                            key = getCacheKey(fileId),
                            contentWithMimeType = payload.contentWithMimeType,
                        )
                            .orThrow()
                    }

                    is BinaryContentWithMimeTypePayload.FilePayload ->
                        cacheStorage.coPutBytes(
                            key = getCacheKey(fileId),
                            content = payload.contentWithMimeType
                                .toBinaryContentWithMimeTypeReadableSequentially(),
                        )
                            .orThrow()
                }
            },
        )

    override suspend fun putFileByMove(
        fileId: FileIdType,
        payload: BinaryContentWithMimeTypePayload<*, *>,
    ) =
        putFile(
            fileId = fileId,
            payload = payload,
            sourceAreaId = "$diagnosticAreaId.putFileByMove",
            cacheAction = {
                when (payload) {
                    is BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload -> {
                        cacheStorage.coPutBlobByMove(
                            key = getCacheKey(fileId),
                            contentToMove = payload.contentWithMimeType,
                        )
                            .orThrow()
                    }

                    is BinaryContentWithMimeTypePayload.FilePayload ->
                        /** There's no 'move' path for legacy [com.speechify.client.api.util.io.File]
                         *  and because it's legacy, there's little point in implementing it, so let's
                         *  just use the 'bytes' endpoint.
                         */
                        cacheStorage.coPutBytes(
                            key = getCacheKey(fileId),
                            content = payload.contentWithMimeType
                                .toBinaryContentWithMimeTypeReadableSequentially(),
                        )
                            .orThrow()
                }
            },
        )

    /**
     * The common logic for [putFile] and [putFileByMove].
     */
    private suspend fun putFile(
        fileId: FileIdType,
        payload: BinaryContentWithMimeTypePayload<*, *>,
        cacheAction: suspend (payload: BinaryContentWithMimeTypePayload<*, *>) -> Unit,
        sourceAreaId: String,
    ): Unit = withTelemetry(
        telemetryEventName = sourceAreaId,
    ) { tel ->
        val fileSize = payload.contentWithMimeType.binaryContent.coGetSizeInBytes().orThrow()
        tel.addProperty("len", fileSize)
        tel.addProperty("mimeType", payload.contentWithMimeType.mimeType?.fullString)
        putFileToRemoteStorage(
            fileId = fileId,
            payload = payload,
        )

        // we store because it's likely the user might load something they just saved
        cacheAction(payload)
    }

    override suspend fun deleteFile(
        fileId: FileIdType,
    ): Unit = withTelemetry(
        telemetryEventName = "$diagnosticAreaId.deleteFile",
    ) {
        coroutineScope {
            launch {
                cacheStorage
                    .coDeleteBlob(getCacheKey(fileId))
                    .toNullable { e -> Log.e(error = e, sourceAreaId = "FirebaseStorageCache.delete") }
            }
            deleteFileFromRemoteStorage(fileId)
        }
    }

    abstract suspend fun putFileToRemoteStorage(
        fileId: FileIdType,
        payload: BinaryContentWithMimeTypePayload<*, *>,
    )

    abstract suspend fun deleteFileFromRemoteStorage(
        fileId: FileIdType,
    )
}
