/* The `JvmName` is a workaround for [KT-21186](https://youtrack.jetbrains.com/issue/KT-21186/MPP-Duplicate-JVM-class-name-with-same-named-commontarget-files-with-top-level-functions).
Else we get compilation error for JVM (`compileTestKotlinJvm`) when this file has both `expect` and non-`expect` functions:
`Duplicate JVM class name 'SomeFileNameKt' ...`
*/
@file:kotlin.jvm.JvmName("DiagnosticReporterCommonJvm")

package com.speechify.client.api.diagnostics

import com.benasher44.uuid.uuid4
import com.speechify.client.api.SpeechifyVersions
import com.speechify.client.api.telemetry.LogLevel
import com.speechify.client.api.telemetry.SpeechifySDKTelemetry
import com.speechify.client.api.telemetry.StoredDiagnosticEvent
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.SDKErrorException
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.toMap
import com.speechify.client.api.util.toException
import com.speechify.client.internal.sync.AtomicBool
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.extensions.collections.firstMatchingOrFirst
import com.speechify.client.internal.util.extensions.collections.putLogErrorIfDifferentExisted
import com.speechify.client.internal.util.extensions.intentSyntax.nullIfEmpty
import com.speechify.client.internal.util.extensions.throwable.asChainFromTopLevelToRootCause
import com.speechify.client.internal.util.extensions.throwable.getCustomPropertiesFromEntireChain
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.onEach
import kotlin.js.JsExport
import kotlin.js.JsName
import kotlin.reflect.typeOf

@JsExport
object SpeechifySDKDiagnostics {
    /**
     * Registers the [DiagnosticReporter] that the SDK can use to report problems observed in SDK (cause of the problem
     * may be a bug in SDK code or in the usage of the SDK, e.g. unexpected exceptions from callback given to the SDK).
     */
    fun setupDiagnosticReporter(reporter: DiagnosticReporter) {
        val fullReporter = object : DiagnosticReporter() {
            fun DiagnosticEvent.getEnrichedCopy(): DiagnosticEvent {
                val resultProperties = (
                    mapOf(
                        // Here we can add properties to every log event going to the SDK consumers:
                        "SpeechifySdkVersion" to SpeechifyVersions.SDK_VERSION,
                    ) + this.properties?.toMap().orEmpty()
                    ).toMutableMap()

                ifNotNull(error?.nativeError?.getCustomPropertiesFromEntireChain()?.entries?.nullIfEmpty()) {
                        errorProperties ->
                    for ((key, value) in errorProperties) {
                        resultProperties.putLogErrorIfDifferentExisted(
                            key = key,
                            value = value,
                            sourceAreaId = DiagnosticReporterClassName,
                        )
                    }
                }

                return this.copy(
                    properties = resultProperties.toBoundaryMap(),
                )
            }

            override fun reportError(event: DiagnosticEvent) =
                reporter.reportError(event.getEnrichedCopy())

            override fun reportWarning(event: DiagnosticEvent) =
                reporter.reportWarning(event.getEnrichedCopy())

            override fun reportInfo(event: DiagnosticEvent) {
                reporter.reportInfo(event)
            }
        }

        diagnosticReporter = fullReporter
        setupUnhandledExceptionHandlerSpecificToCompilationTarget(
            errorLog = Log,
        )
    }
}

/**
 * Sends diagnostic events to where a developer can see them.
 *
 * Depending on the environment, they may be routed to different targets:
 * - When running on end-user devices, [DiagnosticReporter.reportError]s and [DiagnosticReporter.reportWarning]s must be sent to:
 *    - by minimum, online diagnostic services applicable to the product (e.g. Sentry, Crashlytics, Google Cloud Platform logging)
 *    - any diagnostic subsystem of the device, if exists
 * - When running on local developer machine by default*)
 *   at least [DiagnosticReporter.reportError]s and [DiagnosticReporter.reportWarning]s must be sent to:
 *    - by minimum, the diagnostic output where developer can notice them, e.g. console.error/warn or STDERR
 *    - any diagnostic subsystem of the device, if exists
 *   <br/>_*) 'by default' means that, for example, disabling [DiagnosticReporter.reportWarning]s should only be possible 'ad hoc', and not be committed into the codebase._
 * - When running in Continuous Integration, at least [DiagnosticReporter.reportError]s and [DiagnosticReporter.reportWarning]s must be sent to:
 *    - by minimum, the diagnostic output where they are reported to developers, e.g. console.error/warn or STDERR
 *   [DiagnosticReporter.reportError]s should be strongly considered to be communicated as a failure of the entire CI build.
 */
@JsExport
abstract class DiagnosticReporter {
    @Throws(Throwable::class)
    abstract fun reportError(event: DiagnosticEvent)

    @Throws(Throwable::class)
    abstract fun reportWarning(event: DiagnosticEvent)

    @Throws(Throwable::class)
    abstract fun reportInfo(event: DiagnosticEvent)
}

fun interface ErrorLog {
    // The sink method. All others must be just convenience methods that call this one:

    fun e(diagnosticEvent: DiagnosticEvent)

    // Convenience methods:
    /**
     * NOTE: Use only if you don't have an exception/error/failure object. Otherwise, use the overload that takes it.
     */
    fun e(message: String, sourceAreaId: String) =
        Log.e(DiagnosticEvent(message = message, sourceAreaId = sourceAreaId))

    fun e(message: String, properties: Map<String, Any>, sourceAreaId: String) =
        Log.e(DiagnosticEvent(message = message, properties = properties, sourceAreaId = sourceAreaId))

    fun e(failure: com.speechify.client.api.util.Result.Failure, message: String? = null, sourceAreaId: String) {
        e(error = failure.error, message = message, sourceAreaId = sourceAreaId)
    }

    fun e(error: SDKError, message: String? = null, sourceAreaId: String) {
        Log.e(DiagnosticEvent(message = message, nativeError = error.toException(), sourceAreaId = sourceAreaId))
    }

    fun e(error: SDKError, message: String? = null, sourceAreaId: String, properties: Map<String, Any>?) {
        Log.e(
            DiagnosticEvent(
                message = message,
                nativeError = error.toException(),
                sourceAreaId = sourceAreaId,
                properties = properties,
            ),
        )
    }

    fun e(
        message: String? = null,
        exception: Throwable,
        sourceAreaId: String,
    ) {
        Log.e(
            DiagnosticEvent(
                sourceAreaId = sourceAreaId,
                message = message,
                nativeError = exception,
            ),
        )
    }
}

/**
 * A log that ensures all information essential for diagnosing is logged.
 */
internal interface ErrorLogForUnhandledExceptionHandler {
    fun logUnhandledException(
        exception: Throwable,
        /**
         * Identifies which unhandled exception handler (of the possibly multiple available), this error was reported
         * from. This should typically be exactly the same as the symbol (function name or property) that produces
         * these events.
         */
        unhandledExceptionsSourceId: String,
        /**
         * An optional message to provide human-readable details about how to interpret the exceptions from this source.
         */
        message: String?,
        properties: Map<String, Any>? = null,
    )
}

internal object Log : ErrorLog, ErrorLogForUnhandledExceptionHandler {
    /**
     * Ensures that any reporters that don't have a queue and are required to be present are set up.
     */
    internal fun ensureQueuelessReportersReady() {
        /**
         For [diagnosticReporter] there is no queuing, and it should stay this way, as adding queuing would also
         mean we have no way of ensuring that developers see the diagnostic events.
         */
        /* Just initialize the lazy [diagnosticReporter] property. [getDiagnosticReporter] called in there will throw
         if it wasn't registered. */
        Log::diagnosticReporter.invoke()
    }

    private val diagnosticReporter by lazy {
        getDiagnosticReporter()
    }

    override fun e(diagnosticEvent: DiagnosticEvent) {
        // Report to the SDK consumer's reporter
        diagnosticReporter.reportError(diagnosticEvent)

        // Also report to SDK telemetry
        SpeechifyStoredLog.e(diagnosticEvent)
    }

    /**
     * If you are sure you are dealing with a situation that is wrong and requires a developer to remedy, use [e]rror,
     * since they get logged just the same, and [w]arnings have a higher chance that the developer misses it.
     * Only if you are not sure whether you are dealing with a violation/problem, use [w], to only give a
     * ‘possible explanation’ to a problem-investigator that is already looking at an issue, and they should still
     * see if what is reported is a problem.
     */
    fun w(message: String, sourceAreaId: String) {
        w(DiagnosticEvent(message, sourceAreaId = sourceAreaId))
    }

    fun w(message: String, exception: Throwable, sourceAreaId: String) {
        e(
            DiagnosticEvent(
                message = message,
                nativeError = exception,
                sourceAreaId = sourceAreaId,
            ),
        )
    }

    fun w(diagnosticEvent: DiagnosticEvent) {
        diagnosticReporter.reportWarning(diagnosticEvent)
        SpeechifyStoredLog.w(diagnosticEvent)
    }

    fun i(message: String, sourceAreaId: String) {
        i({ message }, sourceAreaId)
    }

    fun i(message: () -> String, sourceAreaId: String) {
        i(diagnosticEvent = DiagnosticEvent(message = message(), sourceAreaId))
    }

    fun i(diagnosticEvent: DiagnosticEvent) {
        diagnosticReporter.reportInfo(diagnosticEvent)

        if (isSendingInfoDiagnosticLogsToTelemetryEnabled) {
            SpeechifyStoredLog.i(diagnosticEvent)
        }
    }

    fun i(error: SDKError, message: String? = null, sourceAreaId: String) {
        i(DiagnosticEvent(message = message, nativeError = error.toException(), sourceAreaId = sourceAreaId))
    }

    fun d(message: String, exception: Throwable, sourceAreaId: String) =
        dEvent(
            diagnosticEvent = {
                DiagnosticEvent(message = message, nativeError = exception, sourceAreaId = sourceAreaId)
            },
        )

    fun d(message: () -> String, sourceAreaId: String) {
        dEvent(diagnosticEvent = { DiagnosticEvent(message = message(), sourceAreaId = sourceAreaId) })
    }

    fun d(message: String, sourceAreaId: String) {
        dEvent(diagnosticEvent = { DiagnosticEvent(message = message, sourceAreaId = sourceAreaId) })
    }

    fun d(diagnosticEvent: DiagnosticEvent) {
        dEvent(diagnosticEvent = { diagnosticEvent })
    }

    /**
     * Allows to log the rich [DiagnosticEvent] object.
     */
    inline fun dEvent(
        /* Needed a different name due to overload ambiguity with other shorthands. */
        diagnosticEvent: () -> DiagnosticEvent,
    ) {
        /* TODO - allow debug events to be reported on production (e.g. per user session, for investigating an issue report). */
        if (isDebugLoggingEnabled) {
            println(
                "[DEBUG] " +
                    diagnosticEvent().let { event ->
                        listOfNotNull(
                            listOfNotNull(
                                event.sourceAreaId?.let { "$it: " },
                                event.message,
                            ).nullIfEmpty()?.joinToString(separator = ""),
                            event.properties?.let {
                                "\tProperties: ${
                                it.entries()
                                    .joinToString(separator = ",") { (key, value) -> "$key=$value" }
                                }"
                            },
                            event.error?.let {
                                "\tError full text: " + it.getFullErrorString()
                            },
                        )
                    }.joinToString(separator = "\n"),
            )
        }
    }

    internal var isDebugLoggingEnabled: Boolean
        get() = isDebugLogging.get()
        set(value) = isDebugLogging.set(value)

    private val isDebugLogging = AtomicBool(false)

    internal var isSendingInfoDiagnosticLogsToTelemetryEnabled: Boolean
        get() = isSendingInfoDiagnosticLogsToTelemetry.get()
        set(value) = isSendingInfoDiagnosticLogsToTelemetry.set(value)

    private var isSendingInfoDiagnosticLogsToTelemetry = AtomicBool(false)

    override fun logUnhandledException(
        exception: Throwable,
        unhandledExceptionsSourceId: String,
        message: String?,
        properties: Map<String, Any>?,
    ) = e(
        DiagnosticEvent(
            message = message,
            nativeError = exception,
            sourceAreaId = "SpeechifySDK",
            properties = mapOf(
                "unhandledExceptionsSourceId" to unhandledExceptionsSourceId,
            ).let {
                if (properties == null) {
                    it
                } else {
                    it + properties
                }
            },
        ),
    )

    internal object SpeechifyStoredLog : ErrorLog {
        override fun e(diagnosticEvent: DiagnosticEvent) {
            SpeechifySDKTelemetry.report(
                StoredDiagnosticEvent(
                    diagnosticEvent = diagnosticEvent,
                    logLevel = LogLevel.ERROR,
                ),
            )
        }

        fun w(diagnosticEvent: DiagnosticEvent) {
            SpeechifySDKTelemetry.report(
                StoredDiagnosticEvent(
                    diagnosticEvent = diagnosticEvent,
                    logLevel = LogLevel.WARNING,
                ),
            )
        }

        fun i(diagnosticEvent: DiagnosticEvent) {
            SpeechifySDKTelemetry.report(
                StoredDiagnosticEvent(
                    diagnosticEvent = diagnosticEvent,
                    logLevel = LogLevel.INFO,
                ),
            )
        }
    }
}

internal fun <T> CallbackNoError<T>.uuidCallback(areaId: String? = null): Pair<String, CallbackNoError<T>> {
    return if (Log.isDebugLoggingEnabled) {
        val id = uuid4().toString()
        id to {
            Log.d({ "[$id] ${areaId?.let { "$areaId " } ?: ""}RET $it" }, sourceAreaId = "CallbackNoError.uuidCallback")
            this(it)
        }
    } else {
        "NOT IN DEBUG MODE" to this
    }
}

internal fun <T> Flow<T>.traced(areaId: String): Flow<T> = flow {
    val (uuid, taggedCallback) = { _: T -> }.uuidCallback()
    Log.d({ "[$uuid] CALL $areaId" }, sourceAreaId = "Flow.traced")
    this@traced.onEach {
        taggedCallback(it)
    }
        .collect(this::emit)
}

internal inline fun <T> debugCallAndResultWithUuid(
    areaId: String? = null,
    vararg parameters: Any?,
    block: () -> T,
): T {
    return if (Log.isDebugLoggingEnabled) {
        val id = uuid4().toString()
        Log.d(
            {
                "[$id] CALL $areaId${if (parameters.isNotEmpty()) {
                    "" +
                        "(${parameters.joinToString(separator = ", ")})"
                } else {
                    ""
                }}"
            },
            sourceAreaId = "debugCallAndResultWithUuid",
        )
        val result = block()
        Log.d({ "[$id] RET ${areaId?.let { "$it " }}result $result" }, sourceAreaId = "debugCallAndResultWithUuid")

        return result
    } else {
        block()
    }
}

internal fun <T> wrapSyncCall(message: (uuid: String) -> String, call: () -> T): T {
    val uuid = if (Log.isDebugLoggingEnabled) {
        uuid4().toString()
    } else {
        "NOT IN DEBUG MODE"
    }
    Log.d({ message(uuid) }, sourceAreaId = "wrapSyncCall")
    return call().also {
        Log.d({ "[$uuid] RET $it" }, sourceAreaId = "wrapSyncCall")
    }
}

internal expect fun setupUnhandledExceptionHandlerSpecificToCompilationTarget(
    errorLog: ErrorLogForUnhandledExceptionHandler,
)

private fun ensureDiagnosticReporter() {
    if (!::diagnosticReporter.isInitialized) {
        throw Exception(
            "Please set up a ${DiagnosticReporter::class.simpleName} using §com.speechify.client.api.diagnostics." +
                "${
                /* TODO remove hardcoded namespace and use `class.qualifiedName` once kotlin supports it for JS */
                SpeechifySDKDiagnostics::class.simpleName
                }.${SpeechifySDKDiagnostics::setupDiagnosticReporter.name}` before using the SpeechifySDK. For" +
                " end-user builds of your product, you should implement a connector to your product's online service" +
                " for collecting diagnostic events. For development-stage you can use the simple" +
                " $localDiagnosticOutputReporterName provided, but you may be able to implement a better development" +
                " experience for your product with your own implementation. See documentation on" +
                " `com.speechify.client.api.diagnostics.${
                /* TODO remove hardcoded namespace and use `class.qualifiedName` once kotlin supports it for JS */
                DiagnosticReporter::class.simpleName
                }` for more info.",

        )
    }
}

internal expect val localDiagnosticOutputReporterName: String

private fun getDiagnosticReporter(): DiagnosticReporter {
    ensureDiagnosticReporter()
    return diagnosticReporter
}

private lateinit var diagnosticReporter: DiagnosticReporter

/**
 * Note that more than one member can be non-`null` and carry information that should be reported.
 */
@JsExport
data class DiagnosticEvent internal constructor(
    /**
     * A message which, if provided, either describes the event fully (when [error] is null), or
     * provides extra context that was not included in the [error]' messages.
     *
     * If this is null, [error] must be provided
     */
    val message: String? = null,

    /**
     * The exception to report, if any (all the information from it should be reported to the developer,
     * including the stacktrace).
     *
     * If [message] is null, this must be provided
     */
    val error: ErrorInfoForDiagnostics? = null,

    /**
     * A free text identifier for grouping by area.
     */
    val sourceAreaId: String,

    /**
     * Dynamic properties for facilitating structured-logging (being able to filter by value, etc.).
     * If the target does not support them, they should be serialized into one string.
     *
     * When there are no properties, the value will be `null`.
     */
    val properties: BoundaryMap<Any>? = null,
    /**
     * For consumers:
     * - this time should be passed to any log collection services (e.g. Sentry), thanks to which the timeline
     *   of events will match the SDK teams log, and thus allow [cross-referencing/deduplicating].
     * For creators:
     * - Defaults to the time of creation of the event, but a different time can be provided.
     *   `null` can be provided if the time is not known (to populate it later use [DiagnosticEvent.copy]).
     */
    val timeOfOccurrence: DateTime? = DateTime.now(),
) {
    /**
     * A convenience overload for creating without a [Throwable], and ensuring #MessageOrErrorCantBothBeEmpty
     */
    @JsName("withMessage")
    constructor(
        message: String,
        sourceAreaId: String,
        properties: BoundaryMap<Any>? = null,
    ) : this(
        message = message,
        error = null,
        sourceAreaId = sourceAreaId,
        properties = properties,
    )

    /**
     * An overload for SDK-use, which doesn't leak the [BoundaryMap].
     */
    internal constructor(
        message: String,
        sourceAreaId: String,
        timeOfOccurrence: DateTime = DateTime.now(),
        properties: Map<String, Any>,
    ) : this(
        message = message,
        error = null,
        sourceAreaId = sourceAreaId,
        properties = properties.toBoundaryMap(),
        timeOfOccurrence = timeOfOccurrence,
    )

    /**
     * A convenience overload for creating with just a [Throwable], and ensuring #MessageOrErrorCantBothBeEmpty
     *
     * Use [withNullableError] if what you have is a message and a [Throwable] that may be null.
     */
    internal constructor(
        message: String? = null,
        nativeError: Throwable,
        sourceAreaId: String,
        properties: Map<String, Any>? = null,
        timeOfOccurrence: DateTime = DateTime.now(),
    ) : this(
        message = message,
        error = ErrorInfoForDiagnostics(nativeError = nativeError),
        sourceAreaId = sourceAreaId,
        properties = properties?.toBoundaryMap(),
        timeOfOccurrence = timeOfOccurrence,
    )

    companion object {
        /**
         * A convenience function for creating with a [message] and possible [nativeError], and ensuring #MessageOrErrorCantBothBeEmpty
         */
        internal fun withNullableError(
            message: String,
            nativeError: Throwable?,
            sourceAreaId: String,
            properties: Map<String, Any>? = null,
        ) = DiagnosticEvent(
            message = message,
            error = nativeError?.let { ErrorInfoForDiagnostics(it) },
            sourceAreaId = sourceAreaId,
            properties = properties?.toBoundaryMap(),
        )
    }
}

@JsExport
class ErrorInfoForDiagnostics internal constructor(
    /**
     * The native error can be useful to be passed for a superior support of platform-specific tools, e.g. taking
     * advantage of the stacktrace.
     */
    val nativeError: Throwable,
) {
    /**
     * An attempt to get a short-yet-distinctive name for the error type (if there is none whatsoever, it will be
     * `null`, but a `non-null` can still be very generic, like `Error`).
     */
    val type: String? get() {
        val errorToUseForType = nativeError
            .asChainFromTopLevelToRootCause
            // Find fist error more-specific than the base type, or fall back to top-level error
            .firstMatchingOrFirst {
                baseErrorTypes
                    .contains(it::class)
                    .not()
            }

        return if (errorToUseForType is SDKErrorException) {
            errorToUseForType.sdkError::class.simpleName
        } else {
            errorToUseForType::class.simpleName
        }
    }

    private companion object {
        /**
         * Error types that don't carry any distinct information about the type of the error.
         */
        val baseErrorTypes = setOf(
            *sequenceOf(
                typeOf<Throwable>(),
                typeOf<Error>(),
                typeOf<Exception>(),
                typeOf<RuntimeException>(),
            )
                .map {
                    it.classifier!!
                }
                .toList()
                .toTypedArray(),
        )
    }

    /**
     * For some platforms that don't have 'suppressed' exceptions (from any errors during attempted reacting to the
     * exception), for example JavaScript, reporting this string is the only way to show all information related to
     * the error, including any 'suppressed' exceptions and `cause` exceptions.
     */
    fun getFullErrorString(): String = nativeError.stackTraceToString()
}

val DiagnosticReporterClassName = DiagnosticEvent::class.simpleName!!

internal const val sdkErrorMessagePromptToInvestigateOwnCallbacksAndIncludeAllEventData =
    "Please investigate if this isn't a problem with your callback. If reporting to SpeechifySDK maintainers," +
        " please share your full diagnostic event data, including the associated exception and the event's properties."

/*
 * A simple opinionated way to show all properties as a single string
 */
internal fun DiagnosticEvent.propertiesAsSingleString(): String? =
    propertiesAsStrings()?.joinToString()

/*
 * A simple opinionated way to convert all properties into a collection of strings (each string contains both key and value)
 */
internal fun DiagnosticEvent.propertiesAsStrings(): List<String>? =
    properties?.let { it.entries().map { entry -> "{${entry.first}=${entry.second}}" } }
