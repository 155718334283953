package com.speechify.client.api.content.view.standard

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReferenceUtils
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.asThrowingFlow
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.content.standard.ContentProvider
import com.speechify.client.internal.util.collections.flows.generateFlow
import com.speechify.client.internal.util.encodeToXmlTextNode
import com.speechify.client.internal.util.extensions.collections.flows.collectIfAllSuccessful
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.extensions.strings.prependToStringBuilder
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.flatMapConcat
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * A universal representation of content in terms of simple structural blocks, with a lazy API designed to support the scrollable "Classic Mode" experience.
 */
@JsExport
interface StandardView : Content, ContentProvider, Destructible {
    /**
     * Get a bunch of [StandardBlock]s around this cursor. The function always returns some content, except for one case
     * when the [StandardView] is entirely empty, and the content will contain the cursor, except for the
     * one case when the [StandardView.start] cursor is used as [cursor] - in this case the returned content can merely
     * match the [start] (that's the most it can do) but it can be ever after it (may especially be the case for lazily
     * resolved content).
     *
     * The exact number of blocks before and after the cursor is decided by the implementation.
     * And more can be obtained with subsequent calls with the [StandardBlocks.start]/[StandardBlocks.end] cursors of
     * the blocks you are given, and the way to detect the end of content is when the new result has the same boundary
     * on that side (see [isNoMoreContentResult]).
     *
     * PS: This API will probably change once we start implementing production-grade Classic Mode experiences, but this is sufficient to start getting feedback.
     */
    fun getBlocksAroundCursor(
        cursor: ContentCursor,
        callback: Callback<StandardBlocks>,
    )

    /**
     * Get all the blocks that are between or contain the [start] and [end] cursors.
     *
     * WARNING: depending on the size of the range you pass and the size of your content, this may be *very* expensive.
     *
     * So if you use this API to extract all the blocks for the full content, you've been warned!
     */
    fun getBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
        callback: Callback<StandardBlocks>,
    )
}

/**
 * The way [StandardView] works, it always returns some content, so the way to detect the end is to compare ends of the
 * result.
 */
internal fun isNoMoreContentResult(previousContentResult: Content, currentContentResult: Content): Boolean =
    currentContentResult.end
        .isBeforeOrAt(
            /* `At` is crucial here. `Before` shouldn't really be necessary - more 'for good measure' */
            previousContentResult.end,
        )

@JsExport
abstract class StandardViewBase : StandardView {
    internal abstract suspend fun getBlocksAroundCursor(cursor: ContentCursor): Result<StandardBlocks>

    internal abstract suspend fun getBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
    ): Result<StandardBlocks>

    override fun getBlocksAroundCursor(cursor: ContentCursor, callback: Callback<StandardBlocks>) = callback.fromCo {
        getBlocksAroundCursor(cursor)
    }

    override fun getBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
        callback: Callback<StandardBlocks>,
    ) = callback.fromCo {
        getBlocksBetweenCursors(start, end)
    }
}

internal suspend fun StandardView.coGetBlocksAroundCursor(cursor: ContentCursor): Result<StandardBlocks> =
    suspendCoroutine { cont -> getBlocksAroundCursor(cursor, cont::resume) }

internal suspend fun StandardView.coGetBlocksBetweenCursors(start: ContentCursor, end: ContentCursor):
    Result<StandardBlocks> =
    suspendCoroutine { cont -> this.getBlocksBetweenCursors(start = start, end = end, cont::resume) }

@OptIn(FlowPreview::class)
internal suspend fun StandardView.getAllBlocksAsFlow(): Flow<StandardBlock> =
    getAllBlocksAsFlowOfChunks()
        .asThrowingFlow()
        .flatMapConcat(
            transform = {
                it.blocks.asFlow()
            },
        )

/**
 * Note - this will potentially allocate a lot of memory. See [getAllBlocksAsFlow] and [getAllBlocksAsFlowOfResults]
 * for a streaming approach.
 */
internal suspend fun StandardView.getAllBlocks(): Result<Array<StandardBlock>> =
    coGetBlocksBetweenCursors(start, end)
        .orReturn { return@getAllBlocks it }
        .blocks.successfully()

/**
 * Note that encountering a single [Result.Failure] means that the flow has ended due to a malfunction, and the
 * successful results collected do not represent the full content. The [Result.Failure] should be treated correctly,
 * i.e. reported to developers and/or users.
 * (Use [com.speechify.client.internal.util.extensions.collections.flows.collectIfAllSuccessful] over the result of this
 * function to safely handle the flow's success without ignoring or losing the error information)
 */
@OptIn(FlowPreview::class)
internal suspend fun StandardView.getAllBlocksAsFlowOfResults(): Flow<Result<StandardBlock>> =
    this@getAllBlocksAsFlowOfResults.getAllBlocksAsFlowOfChunks()
        .flatMapConcat { chunkResult ->
            when (chunkResult) {
                is Result.Success -> chunkResult.value.blocks.asFlow().map { it.successfully() }
                is Result.Failure -> flowOf(chunkResult) /* A single failure, and as per  */
            }
        }

/**
 * Note that encountering a single [Result.Failure] means that the flow has ended due to a malfunction, and the
 * successful results collected do not represent the full content. The [Result.Failure] should be treated correctly,
 * i.e. reported to developers and/or users.
 * (Use [com.speechify.client.internal.util.extensions.collections.flows.collectIfAllSuccessful] over the result of this
 * function to safely handle the flow's success without ignoring or losing the error information)
 *
 * To have a flatter result:
 * - use [getAllBlocksAsFlow]. Here note that the first [Result.Failure] from [coGetBlocksAroundCursor]
 *   will be raised as an exception.
 * - or [getAllBlocksAsFlowOfResults] - see docs for the same caveat about encountering a [Result.Failure].
 */
internal suspend fun StandardView.getAllBlocksAsFlowOfChunks(): Flow<Result<StandardBlocks>> =
    generateFlow(
        getSeedOrNull = {
            coGetBlocksAroundCursor(start)
        },
        getNextOrNull = { previous ->
            when (previous) {
                is Result.Failure -> null /* No way to get more results, if the previous call returned nothing
                 we can work on. Also ignoring this failure here, as the user of this flow already observed it (it
                 should be propagated to the called by means described in this function's KDoc). */
                is Result.Success -> {
                    /*
                    TODO The below had to be commented out because #FootgunOfParentsEqualToChildren is unaddressed,
                     causing only 2 initial pages from PDFs being returned here and then prematurely concluding the end of document.
                     The footgun leaves it not possible to explicitly represent the end of the entire document through
                     cursors (the only way then is expressed in the remaining code, i.e. by requesting the
                     more content after last and checking that nothing was added beyond the last content).

                    if (previous.value.end.isEqual(this@getAllBlocksAsFlowOfChunks.end) /* Having returned the
                                 `StandardView.end` in the previous call is one way of signalling
                                 that there are no more blocks...
                                 */
                    ) null
                    else {*/
                    coGetBlocksAroundCursor(previous.value.end)
                        .nullIf {
                            this is Result.Success &&
                                (
                                /* ... but another way can be just returning no new result in comparison with
                                   the previous (this works also on returning empty items). This way allows
                                     the implementations of `StandardView` which need to minimize the amount of
                                     content retrieved (every fetch-next is costly, including finding out if there
                                     is more content) */
                                    isNoMoreContentResult(
                                        previousContentResult = previous.value,
                                        currentContentResult = this.value,
                                    )
                                    )
                        }
                        ?.map { standardBlocks ->
                            /* Need to additionally filter out overlapping blocks in the results from subsequent
                             calls. They will have overlapping blocks, as this is the only way that the API like
                             `getBlocksAroundCursor` can allow scanning both backwards and forward (because it does
                             not know which will happen). */
                            standardBlocks.blocks.dropWhile {
                                it.end.isBeforeOrAt(previous.value.end)
                            }.let {
                                StandardBlocks(
                                    blocks = it.toTypedArray(),
                                    start = it.firstOrNull()?.start ?: previous.value.end,
                                    end = standardBlocks.end,
                                )
                            }
                        }
                }
            }
        },
    )

@JsExport
data class StandardBlocks(
    val blocks: Array<StandardBlock>,
    override val start: ContentCursor,
    override val end: ContentCursor,
) : Content {
    override fun toString() = "StandardBlocks(blocks=${blocks.contentToString()}, start=$start, end=$end)"

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as StandardBlocks

        if (!blocks.contentEquals(other.blocks)) return false
        if (start != other.start) return false
        if (end != other.end) return false

        return true
    }

    override fun hashCode(): Int {
        var result = blocks.contentHashCode()
        result = 31 * result + start.hashCode()
        result = 31 * result + end.hashCode()
        return result
    }
}

private val rootElementReferenceWithNullRef = ContentElementReferenceUtils.forRoot()

internal fun emptyStandardBlocksFrom(startCursor: ContentCursor) =
    StandardBlocks(emptyArray(), startCursor, rootElementReferenceWithNullRef.end)

/**
 * The result will include the page-container tags, e.g. `<html>` and `<body>` tags. See [toHtmlFragment] for a version
 * that does not include them.
 */
internal suspend fun StandardView.toHtmlPage(
    title: String,
    /**
     *  See parameter of the same name in [toHtmlFragmentStringBuilder].
     */
    includePageNonMainFlowContent: Boolean? = null,
): String =
    """
    |<!DOCTYPE html>
    |<html>
    |<head>
    |    <title>${title.encodeToXmlTextNode()}</title>
    |</head>
    |<body>
    """.trimMargin().prependToStringBuilder(
        this@toHtmlPage.toHtmlFragmentStringBuilder(
            includePageNonMainFlowContent = includePageNonMainFlowContent,
        )
            .orThrow(),
    ).append(
        """
    |</body>
    |</html>
        """.trimMargin(),
    )
        .toString()

/**
 * Note - the result will not include the page-container tags, e.g. `<html>` and `<body>` tags. See [toHtmlPage] for
 * a version that does include them.
 */
internal suspend fun StandardView.toHtmlFragment(
    /**
     *  See parameter of the same name in [toHtmlFragmentStringBuilder].
     */
    includePageNonMainFlowContent: Boolean? = null,
): Result<String> =
    toHtmlFragmentStringBuilder(
        includePageNonMainFlowContent = includePageNonMainFlowContent,
    )
        .orReturn { return it }
        .toString()
        .successfully()

internal suspend fun StandardView.toHtmlFragmentStringBuilder(
    /**
     * Decides what to do with content that is not part of the main flow of the page (e.g. headers, footers, footnotes).
     *
     *  NOTE:`null` will just give an `Info` log message to make developer working with the new content aware, but
     *  will otherwise skip the content.
     */
    includePageNonMainFlowContent: Boolean? = null,
): Result<StringBuilder> {
    if (includePageNonMainFlowContent == true) {
        throw NotImplementedError("includePageNonMainFlowContent=true is not implemented yet")
    }

    fun handlePageNonMainFlowContent(contentType: String) {
        if (includePageNonMainFlowContent == null) {
            Log.i(
                "$contentType are being skipped in HTML conversion. See `includePageNonMainFlowContent` to change " +
                    "this behavior",
                sourceAreaId = "StandardView.handlePageNonMainFlowContent",
            )
        } else {
            /* `false` is the only other option left, and it means the caller intended a skip of this content, so we do
                nothing. */
        }
    }

    val htmlStringBuilder = StringBuilder()

    return getAllBlocksAsFlowOfResults()
        .collectIfAllSuccessful(
            getSuccessResult = { htmlStringBuilder },
        ) {
            when (it) {
                is StandardBlock.Heading ->
                    htmlStringBuilder.appendLine("<h1>${it.text.text.encodeToXmlTextNode()}</h1>")
                is StandardBlock.Paragraph ->
                    htmlStringBuilder.appendLine("<p>${it.text.text.encodeToXmlTextNode()}</p>")
                is StandardBlock.List -> {
                    htmlStringBuilder.appendLine("<ul>")
                    it.items.forEach { item ->
                        htmlStringBuilder.appendLine("\t<li>${item.text.text.encodeToXmlTextNode()}</li>")
                    }
                    htmlStringBuilder.appendLine("</ul>")
                }

                is StandardBlock.Header -> handlePageNonMainFlowContent("headers")
                is StandardBlock.Footer -> handlePageNonMainFlowContent("footers")
                is StandardBlock.Footnote -> handlePageNonMainFlowContent("footnotes")
                is StandardBlock.Caption -> handlePageNonMainFlowContent("captions")
            }
        }
}
