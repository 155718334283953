package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
enum class RenewalStatus(val status: String) {
    /**
     * This status is set while the subscription is in paying or trial state and is not yet expired.
     */
    ACTIVE("active"),

    /**
     * This status is set after the grace period and missed_payment period have passed and the subscription
     * could not be renewed
     */
    EXPIRED("expired"),

    /**
     * This status is set while on a grace period after the subscription has expired and is
     * waiting for payment/renewal
     */
    GRACE("grace"),

    /**
     * This status is set to tell the customer that payment was missed, and we will try to charge later
     * but will stop after X days.
     */
    MISSED_PAYMENT("missed_payment"),
}
