package com.speechify.client.internal.util.collections

/**
 * To be used when [Comparable] is needed, but the underlying value isn't.
 *
 * NOTE: This leads to a large number of temporary objects being allocated, so should only be used when no other
 * alternative exists.
 */
internal class ComparableFromItemAndComparator<Item>(
    val item: Item,
    private val comparator: Comparator<Item>,
) : Comparable<ComparableFromItemAndComparator<Item>> {
    override fun compareTo(other: ComparableFromItemAndComparator<Item>): Int =
        comparator.compare(item, other.item)
}
