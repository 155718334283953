// ktlint-disable filename

package com.speechify.client.internal.util.collections

/**
 * [listOfNotNull] for Arrays, which seems missing in Kotlin
 */
inline fun <reified T : Any> arrayOfNotNull(vararg elements: T?): Array<T> =
    elements.filterNotNull().toTypedArray()

/**
 * Gets the median value of the collection.
 * For even collections the average of the two middle items is returned.
 */
fun List<Int>.median(): Int {
    if (this.isEmpty()) return 0
    return this.sorted().let {
        if (it.size % 2 == 0) {
            (it[it.size / 2] + it[(it.size - 1) / 2]) / 2
        } else {
            it[it.size / 2]
        }
    }
}

/**
 * Maps (using [transform]) just the first element in the [Iterable], and leaves others intact.
 */
internal inline fun <T> Iterable<T>.mapFirst(crossinline transform: (value: T) -> T): List<T> =
    this.mapIndexed { index, t ->
        if (index == 0) {
            transform(t)
        } else {
            t
        }
    }
