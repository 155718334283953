package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.double
import kotlinx.serialization.json.int
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.jsonPrimitive

@Serializable
internal data class FirebaseUserRewards(
    @Serializable(with = IntFromDoubleSerializer::class)
    val premiumWords: Int,
)

object IntFromDoubleSerializer : KSerializer<Int> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("IntFromDouble", PrimitiveKind.INT)

    override fun serialize(encoder: Encoder, value: Int) {
        encoder.encodeInt(value)
    }

    override fun deserialize(decoder: Decoder): Int {
        return when (decoder) {
            is JsonDecoder -> {
                val jsonPrimitive = decoder.decodeJsonElement().jsonPrimitive
                when {
                    jsonPrimitive.isString -> jsonPrimitive.content.toDouble().toInt()
                    jsonPrimitive.intOrNull != null -> jsonPrimitive.int
                    else -> jsonPrimitive.double.toInt()
                }
            }
            else -> decoder.decodeInt()
        }
    }
}
