package com.speechify.client.api.services.audiobook

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.audio.MediaUtterance
import com.speechify.client.api.audio.SpeechMarksChunk
import com.speechify.client.api.audio.SpeechMarksImpl
import com.speechify.client.api.audio.SynthesisLocation
import com.speechify.client.api.audio.VoiceGender
import com.speechify.client.api.audio.VoiceMetadata
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.speech.SpeechUtils
import com.speechify.client.api.services.audio.AudioServerResponse
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.www.asDataUrl

internal val NarratedAudiobookPlaceholderVoiceSpec: VoiceSpec.Static =
    "AudioBook Human Recording"
        .let { id ->
            VoiceSpec.Static(
                // TODO: we could show the narrator voice
                displayName = id,
                isPremium = false,
                languageCode = "en-US",
                gender = VoiceGender.MALE,
                /** NOTE: [VoiceSpec.Static.idQualified] is not used as per #QuirkVoiceSpecStaticIdUnused
                 * TODO: Should there be [VoiceSpec.Static.idQualified] at all? It's read to translate to an AudioServer request, but
                 *  [VoiceSpec.Static] doesn't go to AudioServer #TODOConsiderRemovingIdFromStaticVoiceSpec
                 */
                id = id,
                // TODO: we could show the cover image here
                avatarUrl = null,
            )
        }

internal val NarratedAudiobookPlaceholderVoiceMetadata: VoiceMetadata =
    NarratedAudiobookPlaceholderVoiceSpec.toVoiceMetadata()

internal suspend fun getUtteranceForAudiobookChapter(
    clientServices: SpeechifyClient,
    audiobookChapter: AudiobookChapter.Aligned,
): Result<MediaUtterance> {
    val blob = clientServices.audiobookLibraryService
        .getChapterFile(
            chapter = audiobookChapter,
            fileId = AudiobookChapterFileId(audiobookChapter.alignmentUri),
        )
        .coGetAllBytes()
        .orReturn { return it }
    val alignment = AudioServerResponse.fromBytes(blob)
    val speechMarks = SpeechMarksImpl(
        alignment.speechMarks.chunks.map {
            SpeechMarksChunk(
                startCharacterIndex = it.start,
                endCharacterIndex = it.end,

                // the audio server gives us doubles but the unit is milliseconds
                startTimeInMilliseconds = it.startTime.toInt(),
                endTimeInMilliseconds = it.endTime.toInt(),
            )
        },
    )
    val mediaUrl = alignment.audioData.asDataUrl("audio/${alignment.audioFormat}")
    val contentText = TextElementContentSlice.fromTextElement(
        ContentElementReference.forRoot(),
        alignment.speechMarks.value,
    )
    return MediaUtterance(
        mediaUrl = mediaUrl,
        speechMarks = speechMarks,
        speech = SpeechUtils.fromText(contentText),
        text = contentText,
        mediaPlayerFactory = clientServices.adaptersProvider.localMediaPlayer,
        synthesisLocation = SynthesisLocation.STATIC,
        voiceMetadata = NarratedAudiobookPlaceholderVoiceMetadata,
    ).successfully()
}
