package com.speechify.client.api.services.importing.models

import kotlin.js.JsExport

/**
 * A sealed class used to determine if the import should start immediately or not.
 */
@JsExport
sealed class ImportStartChoice {
    /**
     * Import is not going to start by itself.
     * Use [com.speechify.client.bundlers.reading.importing.ContentImporter.startImport] to perform the start.
     */
    object DoNotStart : ImportStartChoice()

    /**
     * Import will start immediately before the function returns.
     * Result of calling [com.speechify.client.bundlers.reading.importing.ContentImporter.startImport] on the resulting
     * bundle is undetermined.
     */
    data class StartImmediately(val options: ImportOptions) : ImportStartChoice()
}
