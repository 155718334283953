package com.speechify.client.reader.core

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

/**
 * An internal helper class responsible for managing core commands within the system, such as "ReloadContent".
 *
 * This class abstracts command execution logic into a middle layer to decouple listeners from specific emitters,
 * making the system more flexible and easier to extend. By using commands, such as the "ReloadContent" command,
 * the listeners can remain agnostic to the specific component triggering the command (e.g., the book editor).
 * This allows future emitters to be introduced without altering the core listener logic.
 */
internal class CoreCommandsHelper(
    scope: CoroutineScope,
) : Helper<Unit>(scope) {

    override val stateFlow: StateFlow<Unit> = MutableStateFlow(Unit)
    override val initialState = stateFlow.value

    init {
        commands.onEachInstance<BookEditorCommand.TriggerReloadAfterEdit> {
            dispatch(CoreCommand.ReloadContent(it.location))
        }.onEachInstance<BookTextEditCommand.TriggerReloadAfterTextEdit> {
            dispatch(CoreCommand.ReloadContent(it.location))
        }.onEachInstance<SkipSettingsCommand.SkipSettingChanged> {
            dispatch(CoreCommand.ReloadContent(it.location))
        }.launchInHelper()
    }
}

internal sealed class CoreCommand {
    data class ReloadContent(val location: RobustLocation) : CoreCommand()
}
