package com.speechify.client.internal.sync

internal class AtomicCircularFixedList<T> (
    /**
     * NOTE: `0` is permitted, which means the collection throws-away any [add]s, but this can be dynamically raised
     * with [setMaxSizePreservingExcess] - see there for details.
     */
    maxSize: Int,
) {
    /**
     * Returns the current maximum size of the list.
     *
     * NOTE: `0` is permitted which means the collection throws-away any [add]s.
     */
    val maxSize: Int
        get() = maxSizeMutable.value

    private val maxSizeMutable = AtomicInt(maxSize)

    /**
     * NOTE: If the new [maxSizeMutable] is smaller than the current size of the list, the list will **not** be truncated to
     * the new size immediately.
     * This is fine for many uses, especially for independent concurrent consumption, because if there was excess,
     * then whether the consumer ends up consuming the excess or not would be a race condition anyway.
     *
     * NOTE: `0` is permitted, which will mean the collection throws-away any [add]s, but this can be dynamically raised
     * with another call to [setMaxSizePreservingExcess].
     */
    fun setMaxSizePreservingExcess(newMaxSize: Int) {
        this.maxSizeMutable.value = newMaxSize
    }

    private val blockingMutex = BlockingMutex()
    private val _list = mutableListOf<T>()
    val size: Int
        get() = blockingMutex.withLock { _list.size }

    fun clear() = blockingMutex.withLock { _list.clear() }

    /**
     * adds an item to the list, but, when the current size of the array is equal to [maxSizeMutable]
     * it will remove the first element to make space for the new [element]
     */
    fun add(element: T): Unit = blockingMutex.withLock {
        internalAdd(element)
    }

    /**
     * adds all items to the list, but, when the current size of the array is equal to [maxSizeMutable]
     * it will remove the first element to make space for the new [element]
     */
    fun addAll(elements: List<T>): Unit = blockingMutex.withLock {
        elements.forEach(::internalAdd)
    }

    private fun internalAdd(element: T) {
        val maxSize = maxSizeMutable.value
        if (maxSize == 0) {
            return
        }

        if (_list.size >= maxSize) {
            _list.removeFirst()
        }
        _list.add(element)
    }

    operator fun get(index: Int) = blockingMutex.withLock {
        _list[index]
    }

    fun find(predicate: (T) -> Boolean): T? = blockingMutex.withLock {
        _list.find(predicate)
    }

    /**
     * Performs a shallow copy of the list and clear as an atomic operation and returns the copied list the caller
     */
    fun takeAll(): MutableList<T> = blockingMutex.withLock {
        val copiedList = _list.toMutableList()
        _list.clear()
        copiedList
    }

    fun remove(element: T): Unit = blockingMutex.withLock {
        _list.remove(element)
    }
}
