package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.`internal`.services.db.DbBoolean
import com.speechify.client.`internal`.services.db.DbOcrFile
import com.speechify.client.`internal`.services.importing.models.ImportType
import com.speechify.client.`internal`.time.DateTime
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.util.MimeType
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.features.ListeningProgress
import kotlin.Double
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public data class PendingImport(
  public val speechifyUri: SpeechifyURI,
  public val primaryFileBlobStorageKey: BlobStorageKey?,
  public val scannedPages: List<DbOcrFile>?,
  public val sourceURL: String?,
  public val importOptions: ImportOptions?,
  public val htmlContentLoadOptions: HtmlContentLoadOptions?,
  public val lastErrorStackTrace: String?,
  public val wasLastErrorConnectionError: DbBoolean?,
  public val importType: ImportType,
  public val attemptsPerformedCount: Int,
  public val owner: String,
  public val lastUpdatedAt: DateTime,
  public val listeningProgress: ListeningProgress?,
  public val mimeType: MimeType?,
) {
  public class Adapter(
    public val speechifyUriAdapter: ColumnAdapter<SpeechifyURI, String>,
    public val primaryFileBlobStorageKeyAdapter: ColumnAdapter<BlobStorageKey, String>,
    public val scannedPagesAdapter: ColumnAdapter<List<DbOcrFile>, String>,
    public val importOptionsAdapter: ColumnAdapter<ImportOptions, String>,
    public val htmlContentLoadOptionsAdapter: ColumnAdapter<HtmlContentLoadOptions, String>,
    public val wasLastErrorConnectionErrorAdapter: ColumnAdapter<DbBoolean, Long>,
    public val importTypeAdapter: ColumnAdapter<ImportType, String>,
    public val attemptsPerformedCountAdapter: ColumnAdapter<Int, Long>,
    public val lastUpdatedAtAdapter: ColumnAdapter<DateTime, Double>,
    public val listeningProgressAdapter: ColumnAdapter<ListeningProgress, String>,
    public val mimeTypeAdapter: ColumnAdapter<MimeType, String>,
  )
}
