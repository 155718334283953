package com.speechify.client.api.audio

import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayer
import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerEvent
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.util.Result
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

internal data class MediaPlayer(
    private val mediaPlayer: LocalMediaPlayer,
    override val utterance: MediaUtterance,
) : Player() {
    val scope: CoroutineScope = createTopLevelCoroutineScope()

    init {
        ifNotNull(utterance.speechMarks.startTimeInMilliseconds.nullIf { this == 0 }) { startTimeInMilliseconds ->
            /** This is where #SeekingToSlice happens, making a [Utterance.slice] sufficient for ensuring that
             * the playback also starts from the sliced position.
             */
            mediaPlayer.seek(timeInMilliseconds = startTimeInMilliseconds)
        }
    }

    private val poller = Poller()

    /*
     * When we reach the end we want to make sure the only event that is sent is the `Ended` event.
     *
     * So we register here that we have reached this end event so that extra events, like Pause,
     * are not sent.
     */
    private enum class State {
        Playing, Paused, Ended
    }

    private var state = State.Paused

    init {
        scope.launch {
            mediaPlayer
                .getEventsColdFlow()
                .collect { r ->
                    if (state == State.Ended) return@collect

                    when (r) {
                        is Result.Success -> {
                            when (val event = r.value) {
                                LocalMediaPlayerEvent.Started -> {
                                    eventsSink.tryEmit(PlayerEvent.Started(getCurrentCursor()))
                                    poller.start()
                                }
                                is LocalMediaPlayerEvent.NotPlaying -> {
                                    poller.stop()
                                    when (event) {
                                        LocalMediaPlayerEvent.Ended -> eventsSink.tryEmit(PlayerEvent.Ended)
                                        LocalMediaPlayerEvent.Stopped ->
                                            eventsSink.tryEmit(PlayerEvent.Paused(getCurrentCursor()))
                                    }
                                }
                            }
                        }
                        is Result.Failure -> eventsSink.tryEmit(PlayerEvent.Error(r.error))
                    }
                }
        }
    }

    override fun play() {
        state = State.Playing
        mediaPlayer.play()
    }

    override fun seek(to: ContentCursor) {
        mediaPlayer.seek(
            timeInMilliseconds = utterance.speechMarks.getStartTimeAtCharacterIndex(
                utterance.text.getFirstIndexOfCursor(to),
            ),
        )
    }

    override fun stop() {
        if (state != State.Ended) state = State.Paused
        mediaPlayer.pause()
    }

    override fun updateOptions(newOptions: PlayerOptions) {
        mediaPlayer.updateOptions(newOptions.toLocalPlayerOptions())
    }

    override suspend fun getCurrentCursor(): ContentCursor =
        utterance.millisToCursor(
            millis = mediaPlayer.getCurrentTimeInMilliseconds(),
        )
            ?: utterance.text.end

    override fun setSpeed(speed: Float) = mediaPlayer.setSpeed(speed)

    override fun setVolume(volume: Float) = mediaPlayer.setVolume(volume)

    override fun getOptions(callback: (PlayerOptions) -> Unit) =
        mediaPlayer.getOptions { callback(PlayerOptions.fromLocalPlayerOptions(it)) }

    override fun isPlaying() = state == State.Playing

    override fun destroy() {
        poller.destroy()
        mediaPlayer.destroy()
        super.destroy()
    }

    private inner class Poller : WithScope() {
        private var job: Job? = null
        private fun startPolling(): Job = scope.launch {
            while (true) {
                val millis = mediaPlayer.getCurrentTimeInMilliseconds()
                val cursor = utterance.millisToCursor(millis)
                if (cursor == null) {
                    state = State.Ended
                    this@MediaPlayer.stop()
                    eventsSink.emit(PlayerEvent.Ended)
                    return@launch
                } else {
                    eventsSink.emit(PlayerEvent.Progressed(cursor))
                }
                delay(100)
            }
        }

        fun start() {
            if (job == null) {
                job = startPolling()
            }
        }

        fun stop() {
            job?.cancel()
            job = null
        }
    }
}

private fun MediaUtterance.millisToCursor(millis: Int): ContentCursor? {
    return if (millis < this.speechMarks.endTimeInMilliseconds) {
        val characterIndex = this
            .speechMarks
            .getCharacterIndexAtTime(millis)
        this.text.getLastCursorAtIndex(characterIndex)
    } else {
        null
    }
}
