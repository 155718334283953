package com.speechify.client.api.content.view.txt

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
interface PlainTextView : Content {
    fun getLength(callback: Callback<Int>)

    fun getSlice(start: Int, end: Int, callback: Callback<ContentText>)

    fun getCursor(charIndex: Int, callback: Callback<ContentCursor>)
}

internal suspend fun PlainTextView.coGetLength(): Result<Int> = suspendCoroutine { cont -> getLength(cont::resume) }

internal suspend fun PlainTextView.coGetSlice(start: Int, end: Int): Result<ContentText> =
    suspendCoroutine { cont -> getSlice(start, end, cont::resume) }

internal suspend fun PlainTextView.getCursor(charIndex: Int): Result<ContentCursor> =
    suspendCoroutine { cont -> getCursor(charIndex, cont::resume) }
