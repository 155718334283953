package com.speechify.client.reader.core

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.epub.EpubNavPoint
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlin.js.JsExport

@JsExport
class TableOfContentsHelper internal constructor(
    scope: CoroutineScope,
    tableOfContentsFlow: StateFlow<TableOfContents?>,
    playbackStateFlow: Flow<PlaybackState>,
    readingLocationFlow: Flow<ReadingLocationState>,
) : Helper<TableOfContentsState>(scope = scope) {
    override val stateFlow: StateFlow<TableOfContentsState> =
        combine(
            tableOfContentsFlow,
            playbackStateFlow,
            readingLocationFlow,
        ) { tableOfContents, playbackState, readingLocationState ->
            tableOfContents?.let {
                TableOfContentsState.Available(
                    entries = it.toEntries(
                        dispatch = dispatch,
                        playbackLocation = playbackState.location,
                        readingLocation = readingLocationState.location,
                    ).toTypedArray(),
                )
            } ?: TableOfContentsState.NotAvailable
        }.stateInHelper(
            initialValue = TableOfContentsState.NotAvailable,
        )

    override val initialState = stateFlow.value
}

@JsExport
sealed class TableOfContentsState {
    data class Available internal constructor(
        val entries: Array<TableOfContentsEntry>,
    ) : TableOfContentsState() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Available

            return entries.contentEquals(other.entries)
        }

        override fun hashCode(): Int {
            return entries.contentHashCode()
        }
    }

    object NotAvailable : TableOfContentsState()
}

@JsExport
data class TableOfContentsEntry internal constructor(
    internal val dispatch: CommandDispatch,
    private val target: TocEntryTarget,
    val key: String,
    val content: Content,
    val isListeningHere: Boolean,
    val isReadingHere: Boolean,
    val attributes: Attributes,
) {
    sealed class Content {
        data class Single(val section: Section) : Content()
        data class MultipleMerged(val sections: Array<Section>) : Content() {
            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false

                other as MultipleMerged

                return sections.contentEquals(other.sections)
            }

            override fun hashCode(): Int {
                return sections.contentHashCode()
            }
        }
    }
    data class Section(
        val title: String,
        val hierarchyLevel: Int,
    )
    data class Attributes(
        val pageNumber: Int?,
    )
    fun goTo() {
        dispatch(
            NavigationCommand.NavigateTo(
                NavigationIntent.GoToTableOfContentsEntry(target = target),
            ),
        )
    }
}

internal sealed class TocEntryTarget {
    data class Resolved(val location: RobustLocation) : TocEntryTarget()
    sealed class Unresolved : TocEntryTarget() {
        data class EpubChapter(val navPoint: EpubNavPoint) : Unresolved()
    }
}

private fun TableOfContents.toEntries(
    dispatch: CommandDispatch,
    playbackLocation: SerialLocation?,
    readingLocation: SerialLocation?,
): List<TableOfContentsEntry> {
    val listeningHere = entries.lastOrNull {
        it.start.isBeforeOrAt(playbackLocation?.cursor ?: return@lastOrNull false)
    }
    val readingHere = entries.lastOrNull {
        it.start.isBeforeOrAt(readingLocation?.cursor ?: return@lastOrNull false)
    }

    val result = entries.mapIndexed { index, entry ->
        TableOfContentsEntry(
            dispatch = dispatch,
            target = entry.start.toTocTarget(),
            key = "$index-${entry.start.key}",
            content = when (entry.content) {
                is TableOfContents.Entry.Content.Single -> TableOfContentsEntry.Content.Single(
                    TableOfContentsEntry.Section(
                        title = entry.content.section.title,
                        hierarchyLevel = entry.content.section.hierarchyLevel,
                    ),
                )

                is TableOfContents.Entry.Content.MultipleMerged -> TableOfContentsEntry.Content.MultipleMerged(
                    sections = entry.content.sections.map {
                        TableOfContentsEntry.Section(
                            title = it.title,
                            hierarchyLevel = it.hierarchyLevel,
                        )
                    }.toTypedArray(),
                )
            },
            isListeningHere = entry == listeningHere,
            isReadingHere = entry == readingHere,
            attributes = TableOfContentsEntry.Attributes(
                pageNumber = entry.attributes.targetPageIndex?.let { it + 1 },
            ),
        )
    }
    return result
}

private val TableOfContents.Entry.Start.key get() = when (this) {
    is TableOfContents.Entry.Start.Resolved -> "R"
    is TableOfContents.Entry.Start.Unresolved -> "U"
}

private fun TableOfContents.Entry.Start.isBeforeOrAt(other: ContentCursor) = when (this) {
    is TableOfContents.Entry.Start.Resolved -> cursor.isBeforeOrAt(other)
    is TableOfContents.Entry.Start.Unresolved -> false
}

private fun TableOfContents.Entry.Start.toTocTarget() = when (this) {
    is TableOfContents.Entry.Start.Resolved -> TocEntryTarget.Resolved(
        location = RobustLocation(
            hack = SerialLocation(
                cursor = cursor,
            ),
        ),
    )

    is TableOfContents.Entry.Start.Unresolved.EpubChapter -> TocEntryTarget.Unresolved.EpubChapter(navPoint = navPoint)
}
