package com.speechify.client.api.util.images

import kotlin.js.JsExport

@kotlinx.serialization.Serializable
@JsExport
/**
 * A class that represents a viewport.
 * The unit of the width / height depends on the context.
 * For PDF based content this is expressed in PDF point which are defined as 1/72 of an inch.
 * For OCR / Image based content this is expressed in pixels.
 */
data class Viewport(
    val width: Int,
    val height: Int,
)
