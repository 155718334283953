package com.speechify.client.internal.webview

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.resume
import kotlin.js.JsExport

expect class NativeWebView : WebView

expect fun <T> runOnMainThread(context: CoroutineContext = Dispatchers.Main, block: () -> T): T

/**
 * Creates a resource URL for the given [binaryContentReadableRandomly] file.
 *
 * This function generates a URL that allows access to the content of the file
 * represented by the [binaryContentReadableRandomly] parameter.
 *
 * @return A string representing the resource URL for the given binary content.
 */
expect fun createResourceUrl(binaryContentReadableRandomly: BinaryContentReadableRandomly): String

/**
 * Retrieves the parent directory path of the specified [binaryContentReadableRandomly] file, if available.
 *
 * This function attempts to determine the directory that contains the provided binary file.
 * If the file does not belong to a directory or the path cannot be resolved, it returns `null`.
 *
 * @return A string representing the path of the parent directory, or `null` if no parent directory is found.
 */
expect fun getParentDirectoryPathFromFile(binaryContentReadableRandomly: BinaryContentReadableRandomly): String?

/**
 * Abstract representation of a WebView component for displaying web content.
 *
 * This class provides a platform-agnostic abstraction to a web view.
 *
 * On different platforms, it is backed by:
 * - **Web:** An `iframe` element
 * - **Android:** A native `WebView`
 * - **iOS:** A `KWWebView`
 *
 * SDK already owns the android implementation: [com.speechify.client.internal.webview.AndroidWebView].
 * And owns the Web implementation: [com.speechify.client.internal.webview.JSWebView].
 */
abstract class WebView {

    /**
     * Callback invoked when a JavaScript message is received from the web content.
     *
     * ########## Please make sure the JS Interface Object Name  is set to "Speechify". ##########
     * See the implementation example for android at [https://github.com/SpeechifyInc/multiplatform-sdk/blob/9cc9b847f47cea2b76585760d00f6ee7e8cf14c9/multiplatform-sdk/src/androidMain/kotlin/com/speechify/client/internal/webview/AndroidWebView.kt#L106]
     * * Mark this as open since iOS team faced some limitations implementing the webView in iosMain, thus open this in
     * order to give the possibility to implement/override this on client side.
     */
    open var onJSMessageReceived: ((message: String) -> Unit)? = null

    /**
     * Loads the provided web content into the WebView, optionally using a base URL for resolving resources.
     *
     * @param baseUrl The base URL to use for resolving relative URLs within the web content, or `null`.
     * @param data The HTML or web content to load into the WebView.
     * @param callback A callback invoked upon completion of the content loading process.
     */
    abstract fun loadDataWithBaseURL(
        baseUrl: String?,
        data: String,
        mimeType: String?,
        encoding: String?,
        callback: Callback<Unit>,
    )

    /**
     * Evaluates the given JavaScript expression in the context of the currently loaded page.
     *
     * @param script The JavaScript code to be executed.
     * @param callback A callback to receive the result of the evaluation, if any.
     */
    abstract fun evaluateJavaScript(
        script: String,
        callback: ((String?) -> Unit)? = null,
    )

    /**
     * Releases resources and performs cleanup operations for this WebView instance.
     * After calling this method, the WebView should no longer be used.
     */
    abstract fun destroy()
}

@JsExport
interface WebViewListener {
    fun onInterceptRequest(request: WebResourceRequest, callback: Callback<WebResourceResponse?>)
    fun onSwipeLeft()
    fun onSwipeRight()
    fun onAttached()
    fun onDetached()
}

internal suspend fun NativeWebView.coEvaluateJavaScript(script: String): String? = suspendCancellableCoroutine {
    evaluateJavaScript(script, it::resume)
}

internal suspend fun NativeWebView.coLoadDataWithBaseURL(
    baseUrl: String?,
    data: String,
    mimeType: String?,
    encoding: String?,
) = suspendCancellableCoroutine {
    loadDataWithBaseURL(
        baseUrl = baseUrl,
        data = data,
        mimeType = mimeType,
        encoding = encoding,
        callback = it::resume,
    )
}

internal suspend fun WebViewListener.coOnInterceptRequest(
    request: WebResourceRequest,
) = suspendCancellableCoroutine {
    onInterceptRequest(request, it::resume)
}

/**
 * Represents a web resource request.
 *
 * @property url The URL of the requested web resource.
 */
@JsExport
class WebResourceRequest(
    val url: String,
)

/**
 * Represents a web resource response.
 *
 * @property content The content of the web resource as a byte array.
 */
@JsExport
class WebResourceResponse(
    val content: ByteArray,
)
