package com.speechify.client.internal.util.extensions.iterators

/**
 * An iterator that lets you peek at the current item without advancing the iterator.
 */
internal class Peekable<T : Any>(
    private val iter: Iterator<T>,
    private var peek: T?,
) : Iterator<T> {
    fun peekNext(): T? {
        if (peek == null) {
            if (iter.hasNext()) {
                peek = iter.next()
            } else {
                return null
            }
        }
        return peek
    }

    override fun next(): T {
        return peek?.let {
            peek = null
            it
        } ?: iter.next()
    }

    override fun hasNext(): Boolean {
        return if (peek != null) true else iter.hasNext()
    }
}

internal fun <T : Any> Iterator<T>.peekable(): Peekable<T> {
    return Peekable(this, null)
}
