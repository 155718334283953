package com.speechify.client.helpers.content.index

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentStats
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.StaticContentIndexBase
import com.speechify.client.api.content.view.txt.PlainTextView
import com.speechify.client.api.content.view.txt.coGetLength
import com.speechify.client.api.content.view.txt.getCursor
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.internal.util.roundUpToInt

class PlainTextContentIndex(
    private val view: PlainTextView,
) : StaticContentIndexBase() {

    override fun getCursorFromProgress(
        progress: ProgressFraction,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        val length = view.coGetLength().orReturn { return@fromCo it }
        val charIndex = (length * progress).toInt()
        return@fromCo view.getCursor(charIndex).successfully().orReturn { return@fromCo it }
    }

    override fun getProgressFromCursor(cursor: ContentCursor, callback: Callback<ProgressFraction>) = callback.fromCo {
        when (cursor) {
            is ContentElementBoundary -> {
                when (cursor.boundary) {
                    is ContentBoundary.START -> 0.0
                    is ContentBoundary.END -> 1.0
                }
            }
            is ContentTextPosition -> {
                val length: Int = view.coGetLength().orReturn { return@fromCo it }
                if (length == 0 || length == 1) {
                    1.0.successfully()
                    /* Use `1.0`, as opposed to, e.g. `0.0` (probably the only other option), to allow detecting empty
                       text through this result - if `0.0` was returned for `start`, then one wouldn't be able to tell
                       from such a result that there is no content.
                     */
                }
                (cursor.characterIndex / length.toDouble())
            }
        }.successfully()
    }

    override fun getStats(callback: Callback<ContentStats>) = callback.fromCo {
        val length = view.coGetLength().orReturn { return@fromCo it }
        val estimatedWordCount = if (length == 0) {
            0
        } else {
            (length.toDouble() / 6.0).roundUpToInt()
        }
        return@fromCo ContentStats(
            EstimatedCount(estimatedWordCount, 0.7),
            EstimatedCount(length, 1.0),
        ).successfully()
    }

    override fun destroy() = Unit
}
