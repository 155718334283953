package com.speechify.client.api.services.importing

import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.MimeType
import kotlin.js.JsExport

@JsExport
class ImportFilesBatchOperationInput<T>(
    val file: T,
    val mimeType: MimeType,
    val options: ImportOptions,
    val importableContentMetadata: ImportableContentMetadata,
)
