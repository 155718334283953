package com.speechify.client.reader.core

import com.speechify.client.internal.createTopLevelCoroutineScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.plus
import kotlin.coroutines.CoroutineContext

internal class ReaderCommands(
    val commands: MutableSharedFlow<Any>,
) : CoroutineContext.Element {

    fun dispatch(command: Any) {
        commands.tryEmit(command)
    }

    override val key: CoroutineContext.Key<*> = Key

    companion object Key : CoroutineContext.Key<ReaderCommands>
}

internal fun createTopLevelReaderScope(): ReaderScope {
    val commands = MutableSharedFlow<Any>(
        extraBufferCapacity = 10,
        replay = 10,
        /**
         * Warning: We set `onBufferOverflow` to `BufferOverflow.DROP_OLDEST` to ensure that emissions to
         * the shared flow never suspend. This is crucial because we don't want slower **consumers** of the commands
         * to block or delay the processing of faster. By dropping the oldest values, we
         * maintain a smooth and uninterrupted flow of new commands.
         *
         * Important Note: If `onBufferOverflow` is set to its default value, `BufferOverflow.SUSPEND`,
         * it can cause significant delays or even a suspension in processing. This is particularly problematic
         * with components like `DownloadAudioHelper`, which may be slower **consumers** and inadvertently
         * block other critical operations related to the listening experience. These delays can propagate,
         * causing a **UNRESPONSIVE LISTENING EXPERIENCE**.
         */
        onBufferOverflow = BufferOverflow.DROP_OLDEST,
    )
    val readerCommands = ReaderCommands(commands)
    return createTopLevelCoroutineScope(
        contextToMerge = readerCommands,
    )
}

internal typealias ReaderScope = CoroutineScope

internal val ReaderScope.dispatch: CommandDispatch get() {
    val readerCommands = this.coroutineContext[ReaderCommands]!!
    return readerCommands::dispatch
}

internal fun Reader.createChildReaderScope(): ReaderScope {
    // SupervisorJob so it won't get cancelled if any child command collectors throw
    return scope + SupervisorJob(parent = this.scope.coroutineContext[Job])
}

internal fun ReaderScope.createChildReaderScope(): ReaderScope {
    // SupervisorJob so it won't get cancelled if any child command collectors throw
    return this + SupervisorJob(parent = this.coroutineContext[Job])
}

internal fun CoroutineScope.createChildHelperScope(): CoroutineScope {
    // SupervisorJob so it won't get cancelled if any child command collectors throw
    return this + SupervisorJob(parent = this.coroutineContext[Job])
}

internal typealias CommandDispatch = (Any) -> Unit
