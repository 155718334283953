@file:OptIn(FlowPreview::class)

package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flatMapMerge
import kotlinx.coroutines.flow.flow

internal suspend fun <T, V> Flow<T>.mapConcurrentlyLosingOrder(
    maxConcurrency: Int,
    transformer: suspend (T) -> V,
): Flow<V> =
    this.flatMapMerge(concurrency = maxConcurrency) {
        // flatMapMerge calls this transform function in sequence, so in order to have the mapping happen
        // concurrently we need to create a cold flow that does the mapping only when concurrently collected
        // after this call returned.
        flow {
            val value = transformer(it)
            emit(value)
        }
    }
