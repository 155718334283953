package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.emitAll
import kotlinx.coroutines.flow.flow

/**
 *  Equivalent of [generateSequence] for flows with similar parameters.
 */
internal fun <T : Any> generateFlow(
    getSeedOrNull: suspend () -> T?,
    getNextOrNull: suspend (previousItem: T) -> T? = { null },
): Flow<T> =
    flow {
        var currentItem = getSeedOrNull()

        while (currentItem != null) {
            emit(currentItem)
            currentItem = getNextOrNull(currentItem)
        }
    }

/**
 *  Equivalent of [generateSequence] for flows,
 *  but with result of [GenerateFlowResult.NoMoreItems]/[GenerateFlowResult.MoreItems] for more readable intent and
 *  nullable values.
 */
internal fun <T> generateFlow(
    nextFunction: suspend () -> GenerateFlowResult<T>,
): Flow<T> =
    flow {
        var currentItem = nextFunction()

        while (currentItem is GenerateFlowResult.MoreItems) {
            emitAll(currentItem.items.asFlow())
            currentItem = nextFunction()
        }
    }

/**
 * Generates a flow from a function that always has a next value.
 * Typically used with terminating operators like [kotlinx.coroutines.flow.first].
 *
 * Useful e.g. for creating a flow that polls a source for new values, especially when they include `null`s,
 * where [generateFlowNullTerminating] would not work (beside leaving it hidden that the flow is infinite,
 * so needs terminating).
 */
internal inline fun <T> generateFlowInfinite(
    crossinline nextFunction: suspend () -> T,
): Flow<T> =
    generateFlow {
        GenerateFlowResult.MoreItems(nextFunction())
    }

/**
 * For where `null` means "no more items".
 */
internal fun <T> generateFlowNullTerminating(
    nextFunction: suspend () -> T?,
): Flow<T> =
    generateFlow(
        nextFunction = {
            val item = nextFunction()
            if (item == null) {
                GenerateFlowResult.NoMoreItems
            } else {
                GenerateFlowResult.MoreItems(item)
            }
        },
    )

/**
 * Shortcut of `emitAll(generateFlow(nextFunction))` used especially to reduce indentation.
 */
internal suspend inline fun <T> FlowCollector<T>.emitAllGeneratedBy(
    noinline nextFunction: suspend () -> GenerateFlowResult<T>,
) =
    emitAll(generateFlow(nextFunction))

/**
 * Shortcut over `emitAllGeneratedBy` with a child context each, to further reduce indentation.
 */
internal suspend inline fun <T> FlowCollector<T>.emitAllGeneratedByEachInScope(
    noinline nextFunction: suspend CoroutineScope.() -> GenerateFlowResult<T>,
) =
    emitAllGeneratedBy {
        coroutineScope {
            nextFunction()
        }
    }

internal sealed class GenerateFlowResult<out T> {
    class MoreItems<out T>(val items: Iterator<T>) : GenerateFlowResult<T>() {
        constructor(item: T) : this(listOf(item).iterator())
    }

    object NoMoreItems : GenerateFlowResult<Nothing>() {
        fun <T> asBaseClass() =
            this as GenerateFlowResult<T>
    }
}
