package com.speechify.client.api.adapters.localsynthesis

import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.toException
import com.speechify.client.internal.sync.CoLazy
import com.speechify.client.internal.sync.coLazy

internal abstract class LocalSpeechSynthesisVoiceBySpecProvider {
    suspend fun getSpecWithDetailsOrNull(
        spec: VoiceSpec.LocalSynthesisBackedVoice,
    ): VoiceSpec.LocalAvailable? =
        when (spec) {
            is VoiceSpec.LocalAvailable -> spec
            is VoiceSpec.Local -> {
                getLocalSpeechSynthesisVoiceOrNull(spec)
                    ?.let { localSynthesisVoice ->
                        VoiceSpec.LocalAvailable(
                            localSynthesisVoice = localSynthesisVoice,
                            displayName = spec.displayName,
                            id = spec.id,
                            avatarUrl = spec.avatarUrl,
                            previewAudioSentence = spec.previewAudioSentence,
                        )
                    }
            }
        }

    protected abstract suspend fun getLocalSpeechSynthesisVoiceOrNull(
        spec: VoiceSpec.Local,
    ): LocalSynthesisVoice?
}

internal fun LocalSpeechSynthesisVoicesProvider.toLocalSpeechSynthesisVoiceBySpecProvider() =
    object : LocalSpeechSynthesisVoiceBySpecProvider() {
        private val allLocalSynthesisVoices: CoLazy<Array<LocalSynthesisVoice>> = coLazy {
            coGetAllVoices()
                .orDefaultWith {
                    Log.w(
                        DiagnosticEvent(
                            message = "LocalSpeechSynthesisAdapter failed to return voices",
                            sourceAreaId = "LocalSpeechSynthesisAdapter",
                            nativeError = it.toException(),
                        ),
                    )
                    arrayOf()
                }
        }

        override suspend fun getLocalSpeechSynthesisVoiceOrNull(
            spec: VoiceSpec.Local,
        ): LocalSynthesisVoice? =
            allLocalSynthesisVoices.get()
                .find { it.displayName == spec.displayName }
    }
