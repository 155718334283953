package com.speechify.client.internal.util.intentSyntax

/**
 * An indentation-reducing shorthand for `.also { if (it == null) { block() } }`.
 */
internal fun <T> T?.alsoIfNull(block: () -> Unit): T? =
    also {
        if (it == null) {
            block()
        }
    }
