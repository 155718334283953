package com.speechify.client.api.services.subscription

import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Result.Failure
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.sync.SingleJobMutexByCancelling
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.launch

/**
 * Manages listeners that want to observe a property of the current user, whichever it is (so a listener added once
 * will receive calls from the new user after a user change). - NOTE - this is a property that makes this component
 * DANGEROUS. Read "DANGER" section below.
 *
 * The observing happens through [resultsFlow]. If the current user changes, the collectors of [resultsFlow] are kept
 * and will fire with the new user's properties as soon as their value become available.
 *
 * DANGER: this type creates a potential for corrupting state between old user and new user, or using data of old user
 * in transactions. It is characterized by:
 *  * a race condition during a user change - if reactions are parallel, they race always. And a user-id change causes a
 *    number of reactions, e.g. to retrieve user-derived-data and possibly side effects related to their change.
 *    **This has been reproduced in the test: `FAILING! listeners added after a user changes should not receive the old state`**
 *  * a hidden order dependency: when a collection of [resultsFlow] is used before the [currentUserIdStateFlow] has received
 *   the user change (for example in a handler to [com.speechify.client.api.adapters.firebase.FirebaseAuthService.observeCurrentUser]
 *   that was registered before the instance of this class), then it will observe the previous user's properties in
 *   its first invocation.
 * TODO - to remedy the DANGER described, a better design force the listeners to be re-added, and guarantee that they
 *  are called **after** the new user ID has already propagated to all components holding it. The API should also be
 *  explicit about the fact that the listeners of properties added on the old user won't be called when the user
 *  changes, so that the SDK consumers know they have to re-add them. This, fore example, could be achieved by:
 *  * only allowing listeners to be attached in the context of a user, maybe with an API similar to
 *    `sdk.observeCurrentUser({ user -> user.addUserPropXListener({ value -> doSomething(...) }) })`.
 *    Likely should still use structured concurrency with `Job` for each listener execution, to be able to
 *    await their completion before allowing the new user session to start.
 *  * Or maybe create a top-level `CoroutineScope` in the client for each user (using `createTopLevelCoroutineScope`),
 *    and associate all async and background work to it, and do a `cancelAndJoin()` before it is replaced by a new
 *    scope. This won't however be able to cancel SDK-consumer-side work, because SDK API mostly does not allow to
 *    communicate cancellations to the SDK-consumer side.
 *  * Or maybe even redesign not to allow reuse of SDKClient for different users (not allow a user change).
 *    For example the SpeechifyClient API should be pulled under some user-entity-bound session object. This would
 *    probably require there to be multiple database access objects (found something for this in Android [here](https://stackoverflow.com/questions/72744237/how-to-use-multiple-accounts-in-firebase-auth-in-android/72744934#72744934)).
 *    More context [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/824#issuecomment-1400080660)
 *
 *  A bit more context [here in a PR where the problem was noticed](https://github.com/SpeechifyInc/multiplatform-sdk/pull/824#discussion_r1067063436).
 *  See #UnsafeDesignOfUserMutablePropObservers for code related to the problem.
 */
internal class CrossUserChangeMutablePropertyListeners<T>(
    private val currentUserIdStateFlow: Flow<Result<UserId?>>,
    val getPropertyValuesFlowForUserId: (userId: String) -> Flow<Result<T>>,
) : Destructible {
    internal val resultsFlow: Flow<Result<T?>> by lazy {
        // Wanting to read the results manifests interest in the values produced, so we should start the job if it
        // hasn't been started yet
        userLifecycleJob.start()
        latestResult
            .filterNotNull()/* Note that this filters the `null` [Result], not results with `null` value,
        so just prevents the initial empty state from reaching clients, effectively causing them to wait for the first
         actual value. */
    }

    override fun destroy() {
        scope.cancel()
    }

    private val scope = createTopLevelCoroutineScope()
    private val latestResult = MutableStateFlow<Result<T?>?>(null)
    private val userLifecycleJob = scope.launch(start = CoroutineStart.LAZY) {
        val newValuesJob = SingleJobMutexByCancelling()
        currentUserIdStateFlow
            .collect { newUserIdResult: Result<UserId?> ->
                val newUserUid = if (newUserIdResult is Result.Success && newUserIdResult.value != null) {
                    newUserIdResult.value
                } else {
                    if (newUserIdResult is Failure) {
                        latestResult.value = newUserIdResult
                    } else {
                        latestResult.value = Result.Success(null)
                    }
                    newValuesJob.cancelCurrentJob()
                    return@collect
                }

                // We have non-null [newUserUid] now. We can proceed with the job retrieving the derived properties.

                latestResult.value = null /* SECURITY:
               The `lastSeenValue.value = null` is a stop-gap for #UnsafeDesignOfUserMutablePropObservers that at least
                caters for the scenario of listeners being re-added during a user change:

               When a listener is registered the latest value is always sent, if we don't set this to null when a user
               changes and a new listener is added, the new listener would be able to see the old value from the
               previous user. Setting this `null` makes the `filterNotNull` used on this flow wait for the first value
               from new user's `getPropertyValuesFlowForUserId`, rather than sending the last value from the previous
               user before `getPropertyValuesFlowForUserId` finishes.

               Sadly, the SDK consumers are not required to re-register the listeners on user change,
               so there may still be cases of mixing properties with previous user - see
               #UnsafeDesignOfUserMutablePropObservers.
             */

                newValuesJob.replaceWithNewJobIn(scope = this@launch) {
                    getPropertyValuesFlowForUserId(newUserUid).collect { latestResult.value = it }
                }
            }
    }
}
