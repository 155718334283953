package com.speechify.client.api.content.view.speech

import kotlin.js.JsExport

/**
 * Describes content which can be spoken as well as navigated through.
 * This is a more narrowed-down version of [SpeechView], which only exposes members required for playback and
 * navigation.
 */
@JsExport
abstract class NavigableSpeechProvider :
    SpeechFlowProvider(),
    PlaybackNavigationToContentTranslator
