package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformRestoreAppleSubscriptionBody(
    /** Base64 encoded app store receipt. */
    val receipt: String,
    /** When set to true, and the subscription is already assigned to a different user, a transfer will be made. */
    val transferSubscription: Boolean,
)
