package com.speechify.client.api.content

import com.speechify.client.api.util.boundary.BoundaryPair
import kotlin.js.JsExport

/**
 * Represents an object which corresponds to a part of some _whole_ text, and carries the information about the indexes
 * to that _whole_ (in its [rangeInWhole]).
 */
internal class InfoOnRangeInWholeText<out T>(
    val constituentPart: T,
    /**
     * The index range within the whole from which this slice originated. In [inclusive, exclusive) format.
     */
    val rangeInWhole: IntRange,
)

/**
 * The enrichment that a piece of text may have
 */
@JsExport
sealed class TextEnrichment {
    /** Is superscript */
    object Superscript : TextEnrichment()

    /** Is subscript */
    object Subscript : TextEnrichment()

    /** Is a drop cap */
    object DropCap : TextEnrichment()
}

/**
 * Metadata about a piece of [ContentText].
 */
@JsExport
data class TextMetadata(
    /**
     * Ranges of text that have some kind of enrichment
     */
    @Suppress("ArrayInDataClass") val textEnrichments: Array<TextEnrichment> = emptyArray(),
) {
    override fun toString() = "TextMetadata(enrichedTextRanges=${textEnrichments.contentToString()})"
}

@JsExport
interface ContentSlice : ContentText {
    /**
     * The element containing all the content in this slice
     */
    val rootElement: ContentElementReference

    /**
     * Metadata about the content to help inform speech
     */
    val metadata: TextMetadata

    /**
     * The index range within the [rootElement] from which this slice originated. In [inclusive, exclusive) format.
     */
    val range: BoundaryPair<Int, Int>
    override fun slice(startIndex: Int, endIndex: Int): ContentSlice
    override fun withText(text: String): ContentSlice

    /**
     * Replace the metadata with [newMetadata]
     */
    fun withMetadata(newMetadata: TextMetadata): ContentSlice
}

internal fun ContentSlice.hasEnrichment(enrichment: TextEnrichment) = this.metadata.textEnrichments.contains(enrichment)
