package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.ColumnAdapter
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.adapters.ocr.OCRResult
import kotlin.Int
import kotlin.Long
import kotlin.String

public data class ScannedPage(
  public val speechifyUri: SpeechifyURI,
  public val blobStorageKey: BlobStorageKey,
  public val ocrResult: OCRResult?,
  public val pageIndex: Int,
) {
  public class Adapter(
    public val speechifyUriAdapter: ColumnAdapter<SpeechifyURI, String>,
    public val blobStorageKeyAdapter: ColumnAdapter<BlobStorageKey, String>,
    public val ocrResultAdapter: ColumnAdapter<OCRResult, String>,
    public val pageIndexAdapter: ColumnAdapter<Int, Long>,
  )
}
