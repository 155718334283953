package com.speechify.client.api.adapters.pdf.search

import kotlin.js.JsExport

/**
 * @param caseSensitive if true search with case sensitivity ON otherwise ignore case sensitivity
 */
@JsExport
data class PdfSearchOptions(
    val caseSensitive: Boolean = false,
)
