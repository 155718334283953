package com.speechify.client.api.content.view.epub

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.epub.EpubNavPoint
import com.speechify.client.api.content.epub.FlattenedEpubNavPoint
import com.speechify.client.api.content.epubV3.Link
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.getAttribute

internal interface EpubChapter : Content {
    val index: Int
    val link: Link
    suspend fun getContent(): EpubChapterContent
}

internal data class EpubChapterContent(
    val link: Link,
    val root: WebPageNode.Element,
    val navPointsToTocEntries: Map<EpubNavPoint, OrderedTocEntry>,
) : Content by root

/**
 * Represents an ordered entry in an EPUB table of contents (TOC) that maintains its position in the original navigation hierarchy.
 *
 * This class combines a TOC entry with its chapter location and hierarchical ordering information, which is essential for
 * preserving the original structure and order of the EPUB navigation when processing or displaying the content.
 *
 * @property entry The underlying table of contents entry containing the section information and navigation data
 * @property chapterIndex The index of the chapter in which this TOC entry appears
 * @property rank The zero-based position of this entry in the flattened navigation hierarchy, preserving the original
 *               document order when the nested navigation structure is linearized
 */
internal data class OrderedTocEntry(
    val entry: TableOfContents.Entry,
    val chapterIndex: Int,
    val rank: Int,
)

internal fun resolveChapterNavPoints(
    navPoints: List<FlattenedEpubNavPoint>,
    root: WebPageNode.Element,
): Map<EpubNavPoint, OrderedTocEntry> {
    val idToNodeMap = mutableMapOf<String, WebPageNode>()
    val resultMap = mutableMapOf<EpubNavPoint, OrderedTocEntry>()
    var currentPosition = 0

    fun populateIdMap(node: WebPageNode) {
        when (node) {
            is WebPageNode.Element -> {
                node.getAttribute("id")?.let { id ->
                    idToNodeMap[id] = node
                }
                node.children.forEach { populateIdMap(node = it) }
            }
            is WebPageNode.Text -> {}
        }
    }

    populateIdMap(node = root)

    navPoints.forEach { flattenedNavPoint ->
        val navPoint = flattenedNavPoint.navPoint
        val node = idToNodeMap[navPoint.fragment]
        val startCursor = node?.start ?: ContentElementReference.fromPath(listOf(navPoint.chapterIndex)).start

        val entry = TableOfContents.Entry(
            content = TableOfContents.Entry.Content.Single(
                TableOfContents.Entry.Section(
                    title = navPoint.label,
                    hierarchyLevel = flattenedNavPoint.level,
                ),
            ),
            start = TableOfContents.Entry.Start.Resolved(cursor = startCursor),
            attributes = TableOfContents.Entry.Attributes(targetPageIndex = null),
        )

        resultMap[navPoint] = OrderedTocEntry(
            entry = entry,
            rank = currentPosition++,
            chapterIndex = navPoint.chapterIndex,
        )
    }

    return resultMap
}
