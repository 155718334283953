// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.getAndUpdate

internal fun <T> MutableStateFlow<T>.swap(newValue: T): T =
    getAndUpdate { newValue }

/**
 * Used to signify that ignoring not setting of the value is intended.
 */
internal fun <T> MutableStateFlow<T>.compareAndSetIgnoringNoMatch(
    expect: T,
    update: T,
) {
    compareAndSet(
        expect = expect,
        update = update,
    )
}

internal fun <T> MutableStateFlow<T>.compareAndSetOrThrow(
    expect: T,
    update: T,
    errorMessage: () -> Any,
) =
    compareAndSet(expect = expect, update = update)
        .also { wasSet ->
            check(wasSet, errorMessage)
        }
