package com.speechify.client.internal.util.extensions.collections

/** Useful for mapping which uses a value derived from the previous item.
 * @param getNextItem This function will be executed after the emission of the previous item, to give a chance for any
 * changes (if this is important, then this sequence should not be evaluated (e.g. `toList`), as it
 * will remove the effect).
 */
internal inline fun <SourceItem, MapResultItem> Sequence<SourceItem>.mapWithPreviousItem(
    crossinline getFirstItem: (sourceItem: SourceItem) -> MapResultItem,
    crossinline getNextItem: (sourceItem: SourceItem, previousItem: MapResultItem) -> MapResultItem,
): Sequence<MapResultItem> = sequence {
    val sourceIterator = this@mapWithPreviousItem.iterator()
    if (!sourceIterator.hasNext()) {
        return@sequence
    }

    val firstSourceItem = sourceIterator.next()

    var lastTargetItem = getFirstItem(firstSourceItem)
    yield(lastTargetItem)

    for (sourceItem in sourceIterator) {
        lastTargetItem = getNextItem(sourceItem, lastTargetItem)
        yield(lastTargetItem)
    }
}
