package com.speechify.client.api.adapters.db

import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.driver.worker.WebWorkerDriver
import org.w3c.dom.Worker

@JsExport
abstract class SqlDriverFactoryUsingWebWorkerDependenciesBase :
    SqlDriverFactoryForMigrationlessDriverDependenciesBase() {
    abstract val worker: Worker

    override fun createInternalSqlDriver(): SqlDriver =
        WebWorkerDriver(worker)
}

@JsExport
open class SqlDriverFactoryUsingWebWorker(
    dependencies: SqlDriverFactoryUsingWebWorkerDependenciesBase,
) : SqlDriverFactoryForMigrationlessDriverBase(
    dependencies = dependencies,
)
