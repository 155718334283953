package com.speechify.client.api.services.subscription.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
enum class RenewalFrequency(val frequency: String) {
    MONTHLY("monthly"),
    YEARLY("yearly"),
    WEEKLY("weekly"),
    QUARTERLY("quarterly"),
}

@Serializable
@JsExport
internal enum class PlatformRenewalFrequency {
    @SerialName("monthly")
    Monthly,

    @SerialName("annually")
    Annually,

    @SerialName("quarterly")
    Quarterly,

    @SerialName("weekly")
    Weekly,
}
