package com.speechify.client.helpers.ui.controls

import com.speechify.client.api.audio.AudioController
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.helpers.features.InvalidProgressFractionException
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.helpers.features.getProgressFractionValidationExceptionOrNull
import com.speechify.client.internal.sync.SingleJobMutexByCancelling
import com.speechify.client.internal.util.extensions.collections.flows.asPureFlow
import com.speechify.client.internal.util.extensions.collections.flows.suspendUntil
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlin.coroutines.cancellation.CancellationException
import kotlin.js.JsExport

@JsExport
class Scrubber internal constructor(
    private val onStartScrubbing: suspend () -> Boolean,
    private val handleProgressUpdate: suspend (progressFraction: Double) -> Unit,
    private val onEndScrubbing: suspend (progressFraction: Double, wasPlaying: Boolean) -> Unit,
    private val scope: CoroutineScope,
) {
    private val progressFraction = MutableStateFlow(0.0)
    private val isScrubberReadyToBeReleased = MutableStateFlow(false)

    private val grabHandlingJob = SingleJobMutexByCancelling()

    internal val isScrubbing get() = grabHandlingJob.isCurrentJobRunning

    /**
     * Starts the scrubbing.
     *
     * NOTE: [release] must eventually be called after calling this function, especially to start playing from the
     * position, if grabbing started when play was ongoing (so caused a pause).
     */
    fun grab() {
        isScrubberReadyToBeReleased.value = false
        grabHandlingJob.replaceWithNewJobNoWaitForCancelIn(
            scope = scope,
            onOldJobNotCancelled = { _, _ ->
                Log.w(
                    DiagnosticEvent(
                        sourceAreaId = "Scrubber",
                        message = "grab was called on an already grabbed scrubber. Consider using [scrub] without" +
                            " [grab] directly if there is no way to prevent this.",
                    ),
                )
            },
        ) {
            handleGrabTreatingCancellationAsRelease()
        }
    }

    /**
     * Advances the grabbed scrubber to the given progress fraction.
     *
     * If the scrubber is not grabbed, it will be grabbed.
     *
     * NOTE: [release] must eventually be called after calling this function, especially to start playing from the
     * position, if grabbing started when play was ongoing (so caused a pause).
     * **Calling [release] is required even if [grab] was not called** (Use [AudioController.seek] to advance the play in a single
     * function call).
     *
     * NOTE ON CONCURRENCY: Calls to this function should **not** happen from parallel threads, as this will lead to
     * races causing back-in-time behavior. Additionally, the [release] should also not be called in parallel to calling
     * this function, or else it's not determined if after the call there is, or there isn't a scrubbing pending.
     *
     * @throws [InvalidProgressFractionException] if the given [progressFraction] is invalid.
     */
    @Throws(InvalidProgressFractionException::class)
    fun scrub(progressFraction: ProgressFraction) {
        ifNotNull(getProgressFractionValidationExceptionOrNull(progressFraction, areaId = "Scrubber.scrub")) {
            throw it
        }
        this@Scrubber.progressFraction.value = progressFraction
        grabHandlingJob.ensureJobIn(scope) {
            handleGrabTreatingCancellationAsRelease()
        }
    }

    /**
     * NOTE: Calls to this should never be called in parallel to calling [scrub]/[grab], or else it's not determined if
     * after the call there is, or there isn't a scrubbing pending.
     */
    fun release() {
        scope.launch {
            isScrubberReadyToBeReleased.suspendUntil { it }
            val hadJob = grabHandlingJob.cancelCurrentJobAndJoin()
            if (!hadJob) {
                Log.w(
                    DiagnosticEvent(
                        sourceAreaId = "Scrubber",
                        message = "Release was called before scrubbing or grabbing",
                    ),
                )
            }
        }
    }

    /**
     * The logic for handling the grab-and-scrubs, and the release at the end, written as a single sequential routine to
     * avoid state corruption (to release, need to cancel the [kotlinx.coroutines.Job] that the call is running in).
     *
     * @return [Nothing] because the function never returns - it can only be cancelled (similarly to
     * [kotlinx.coroutines.awaitCancellation]).
     */
    private suspend fun handleGrabTreatingCancellationAsRelease(): Nothing {
        isScrubberReadyToBeReleased.tryEmit(true)
        val wasPlaying = onStartScrubbing()
        try {
            progressFraction
                .asPureFlow()
                /** Don't use [collectLatest], so that cancellations don't happen. A
                 [kotlinx.coroutines.flow.StateFlow] already loses any-in-between values, so collections are not
                 wasteful, and cancellations would actually make the whole process more wasteful, and even lead to
                 frequent [scrub]s lead to never updating. */
                .collect { progressFraction ->
                    try {
                        handleProgressUpdate(progressFraction)
                    } catch (e: CancellationException) {
                        throw e /* Cancellations are not errors to be reported - just throw up to the infrastructure so
                         that every caller can react, and the coroutine infrastructure ignores it. */
                    } catch (e: Throwable) {
                        Log.e(
                            DiagnosticEvent(
                                sourceAreaId = "Scrubber",
                                message = "handleProgressUpdate threw an exception",
                                nativeError = e,
                            ),
                        )
                        /* Don't rethrow to not fail entire routine, making scrubber unusable on a single failure from
                         progress update */
                    }
                }
            /* The below should never be reached because `progressFraction` is a state flow that never finishes, but
             let's protect ourselves from any change in these semantics, just in case. */
            Log.w(
                DiagnosticEvent(
                    sourceAreaId = "Scrubber",
                    message = "Scrubber's progressFraction flow finished without cancellation. This was not expected",
                ),
            )
            throw CancellationException()
        } catch (e: CancellationException) {
            withContext(NonCancellable) { /* Must prevent cancellation in here, or else the below will be interrupted
             at the earliest cancellable code.
             */
                /**
                 Since we're outside the [progressFraction] collection, we can now safely assume that no
                 [handleProgressUpdate] is running, so the below play will not be raced by it.
                 */
                onEndScrubbing(progressFraction.value, wasPlaying)
            }
            throw e /* Cancellations are not errors to be reported - just throw up to the infrastructure so that every
             caller can react, and the coroutine infrastructure ignores it.
             */
        }
    }
}
