package com.speechify.client.api.util

import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.HasSnapshotRef
import com.speechify.client.api.util.boundary.BoundaryPair
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

internal class FirestorePaginator(
    val query: DocumentQueryBuilder,
    val itemsPerPage: Int,
) {
    suspend fun getNextPage(
        lastItem: HasSnapshotRef?,
        dataSource: DataSource = DataSource.DEFAULT,
    ): List<FirebaseFirestoreDocumentSnapshot.Exists> {
        val queryDto = lastItem?.let {
            query.queryDto().copy(
                snapshotBounds = arrayOf(
                    BoundaryPair.from(
                        it.snapshotRef to DocumentQueryBuilder.BoundType.StartAfter,
                    ),
                ),
                limit = itemsPerPage,
            )
        } ?: query.queryDto()

        val snapshot = suspendCoroutine {
            query.fetchImpl(
                queryDto,
                dataSource,
                it::resume,
            )
        }.orThrow()
        return snapshot.mapNotNull { it as? FirebaseFirestoreDocumentSnapshot.Exists }
    }
}
