package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisVoice
import com.speechify.client.api.content.LanguageIdentity
import com.speechify.client.api.content.tryGetDisplayName
import com.speechify.client.api.util.boundary.BoundaryMap
import kotlin.js.JsExport

typealias VoiceId = String

/**
 * Describes the speaker behind a Voice to help users select the Voice they want to listen to.
 */
@JsExport
sealed class VoiceMetadata(
    val id: VoiceId,
    val displayName: String,
    val gender: VoiceGender,
    val languageCode: String,

    /**
     * The URL of the avatar for this voice. May be http, https, or data URL.
     */
    val avatarUrl: String?,
    val isPremium: Boolean,

    @Deprecated(
        /** NOTE to SDK devs: Don't remove this field once no public usages, but just internalize it and
         * change [engineIdInternal] usages to it (unless SDK can be freed from its usages, but it does come handy,
         * especially to give filterable diagnostic events) */
        "If you use this field, please notify @platformteam.",
    )
    val engine: String,

    val localizedDisplayNames: BoundaryMap<String>? = null,
    val previewAudioUrl: String? = null,
    val previewAudioSentence: String? = null,
    val labels: Array<String>? = null,
    open val spec: VoiceSpecOfAvailableVoice,
) {
    val languageIdentity = LanguageIdentity(
        ietfTag = languageCode,
    )
    val languageDisplayName = languageIdentity.tryGetDisplayName() ?: ""

    @Suppress("DEPRECATION")
    internal val engineIdInternal = engine
}

/**
 * A [VoiceMetadata] with [VoiceSpec.VoiceSpecForMediaVoice].
 */
class VoiceMetadataOfMediaVoice(
    id: VoiceId,
    displayName: String,
    gender: VoiceGender,
    languageCode: String,
    avatarUrl: String?,
    isPremium: Boolean,
    engine: String,
    localizedDisplayNames: BoundaryMap<String>? = null,
    previewAudioUrl: String? = null,
    previewAudioSentence: String? = null,
    labels: Array<String>? = null,
    override val spec: VoiceSpec.VoiceSpecForMediaVoice,
) : VoiceMetadata(
    id = id,
    displayName = displayName,
    gender = gender,
    languageCode = languageCode,
    avatarUrl = avatarUrl,
    isPremium = isPremium,
    engine = engine,
    localizedDisplayNames = localizedDisplayNames,
    previewAudioUrl = previewAudioUrl,
    previewAudioSentence = previewAudioSentence,
    labels = labels,
    spec = spec,
)

/**
 * A [VoiceMetadata] that has a [LocalSynthesisVoice] associated with it (exposed through [HasLocalVoiceIdentity.localSynthesisVoice]).
 */
class VoiceMetadataOfLocalVoice(
    id: VoiceId,
    displayName: String,
    gender: VoiceGender,
    languageCode: String,
    avatarUrl: String?,
    isPremium: Boolean,
    engine: String,
    localizedDisplayNames: BoundaryMap<String>? = null,
    previewAudioUrl: String? = null,
    previewAudioSentence: String? = null,
    labels: Array<String>? = null,
    override val spec: VoiceSpec.LocalAvailable,
) : VoiceMetadata(
    id = id,
    displayName = displayName,
    gender = gender,
    languageCode = languageCode,
    avatarUrl = avatarUrl,
    isPremium = isPremium,
    engine = engine,
    localizedDisplayNames = localizedDisplayNames,
    previewAudioUrl = previewAudioUrl,
    previewAudioSentence = previewAudioSentence,
    labels = labels,
    spec = spec,
),
    HasLocalVoiceIdentity {
    override val localSynthesisVoice: LocalSynthesisVoice
        get() = spec.localSynthesisVoice
}
