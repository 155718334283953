package com.speechify.client.bundlers.reading.classic

import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.bundlers.content.createBundleForHtmlDomElement
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import org.w3c.dom.Element

@JsExport
@Deprecated("Use `createBundleForHtmlDomElement`", ReplaceWith("createBundleForHtmlDomElement"))
fun ClassicReadingBundler.createBundleForHtmlElement(
    htmlElement: Element,
    importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
    bundleMetadata: BundleMetadata,
    callback: Callback<ClassicReadingBundle>,
) = createBundleForHtmlDomElement(
    htmlElement = htmlElement,
    options = HtmlContentLoadOptions(
        sourceUrl = null,
        isEntireDocument = null,
        isPostContentExtraction = null,
        isPostJavaScriptExecution = null,
    ),
    importStartChoice = importStartChoice,
    bundleMetadata = bundleMetadata,
    callback = callback,
)

/**
 * Create an [ClassicReadingBundle] from the specified real Browser DOM element, without any filtering or modification.
 * See [ClassicReadingBundler.createBundleForHtmlFromSdkElement] for a version that uses an abstract interface as an input,
 * thus allowing for filtering or modification.
 * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
 */
@JsExport
fun ClassicReadingBundler.createBundleForHtmlDomElement(
    htmlElement: Element,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
    bundleMetadata: BundleMetadata,
    callback: Callback<ClassicReadingBundle>,
) = callback.fromCo {
    coCreateClassicBundle(
        contentBundle = contentBundler.createBundleForHtmlDomElement(
            htmlElement = htmlElement,
            options = options,
            importStartChoice = importStartChoice,
            bundleMetadata = bundleMetadata,
        ).orReturn { return@fromCo it },
        bundleMetadata = bundleMetadata,
    )
}
