package com.speechify.client.bundlers.reading.importing

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.Result
import com.speechify.client.bundlers.reading.ContentTitleExtractor
import com.speechify.client.internal.util.extensions.strings.nullIfEmpty
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.flow.StateFlow

internal class TitleExtractingContentImporter private constructor(
    private val contentImporter: ContentImporter,
    private val contentTitleExtractor: ContentTitleExtractor,
) : ContentImporter() {
    companion object {
        fun of(
            contentImporter: ContentImporter,
            contentTitleExtractor: ContentTitleExtractor,
        ): TitleExtractingContentImporter {
            // the title extractor should never wrap another title extractor
            return when (contentImporter) {
                is TitleExtractingContentImporter -> of(contentImporter.contentImporter, contentTitleExtractor)
                else -> TitleExtractingContentImporter(contentImporter, contentTitleExtractor)
            }
        }
    }

    override val stateFlow: StateFlow<ContentImporterState?>
        get() = contentImporter.stateFlow

    override fun setEditsSaveAction(saveEditsAction: suspend (uri: SpeechifyURI) -> Unit): Deferred<SpeechifyURI> =
        contentImporter.setEditsSaveAction(saveEditsAction)

    override fun queueTaskAfterImport(task: suspend (uri: SpeechifyURI) -> Unit) =
        contentImporter.queueTaskAfterImport(task)

    override suspend fun startImport(options: ImportOptions): Result<SpeechifyURI> =
        withTelemetry(telemetryEventName = "TitleExtractingContentImporter.startImport") {
            startImportImpl(options)
        }

    private suspend fun startImportImpl(options: ImportOptions): Result<SpeechifyURI> {
        val newOptions = run {
            // If title provided, just use it
            ifNotNull(options.title.nullIfEmpty()) {
                // Need to also update the title for any observers of it
                contentTitleExtractor.observable.set(it)
                return@run options
            }

            // Else, try to extract the title from the content and produce new options
            return@run options.copy(
                title = contentTitleExtractor.getFinalizedTitleForImport()
                    .orReturn { return it },
            )
        }

        return contentImporter.startImport(newOptions)
    }
}
