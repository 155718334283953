package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformCoupon(
    val amountOff: Double?,
    val percentOff: Double?,
    val planType: String,
)
