package com.speechify.client.api.util.io

import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.arrays.toBlob
import com.speechify.client.internal.util.collections.arrays.toInt8Array
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.files.Blob
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException

/**
 * Attempts to decode the string using the appropriate charset derived from the file's mimeType.
 * Falls back to default utf-8 encoding [com.speechify.client.api.util.MimeTypeParameters.defaultCharset] if charset
 * is null.
 */
internal suspend fun File.coGetAllAsString(): Result<String> {
    val byteArray = coGetAllBytes().orReturn { return it }
    val blob = byteArray.toInt8Array().toBlob(contentType)
    val charset = mimeType.parameters.charset ?: "utf-8"
    return blob.readAsString(charset).successfully()
}

internal suspend fun Blob.readAsString(charset: String): String =
    suspendCancellableCoroutine { continuation ->
        val fileReader = FileReader()
        fileReader.onload = {
            val result = fileReader.result
            continuation.resume(result)
        }
        fileReader.onerror = {
            continuation.resumeWithException(
                Exception(
                    message = "Error reading file as string: type=${it.type}",
                ),
            )
        }
        fileReader.onabort = {
            continuation.cancel()
        }

        continuation.invokeOnCancellation {
            fileReader.abort()
        }

        fileReader.readAsText(this, charset)
    }
