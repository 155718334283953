package com.speechify.client.helpers.features

import kotlin.js.JsExport
import kotlin.math.abs
import kotlin.math.hypot
import kotlin.math.sqrt

/*
 * TRANSLATED FROM:
 * https://github.com/SpeechifyInc/multiplatform-sdk/blob/dmitry/original-mode/examples/ios/MultiplatformSDKPlayground/Business%20Logic/Listening/Helpers/CGPolygon.swift
 * https://github.com/SpeechifyInc/multiplatform-sdk/blob/dmitry/original-mode/examples/ios/MultiplatformSDKPlayground/Application%20UI/View/Listening/Atoms/Polygon.swift
 */

private const val epsilon = 0.0001

@JsExport
data class Point(
    val x: Int,
    val y: Int,
) {
    fun distanceTo(other: Point): Double {
        val xDiff = (other.x - x).toDouble()
        val yDiff = (other.y - y).toDouble()
        return sqrt(hypot(xDiff, yDiff))
    }
}

@JsExport
data class Box(
    val topLeft: Point,
    val width: Int,
    val height: Int,
) {
    val minX = topLeft.x
    val maxX = minX + width
    val minY = topLeft.y
    val maxY = minY + height
}

@JsExport
class Polygon(boxes: Array<Box>) {

    val vertices = boxes
        .let(::expand)
        .let(::smoothenRows)
        .let(::computeBoundingPolygonVertices)

    val boundingBox by lazy {
        if (vertices.isEmpty()) Box(Point(0, 0), 0, 0)

        val minX = vertices.minOfOrNull(Point::x)!!
        val maxX = vertices.maxOfOrNull(Point::x)!!
        val width = maxX - minX

        val minY = vertices.minOfOrNull(Point::y)!!
        val maxY = vertices.maxOfOrNull(Point::y)!!
        val height = maxY - minY
        Box(Point(x = minX, y = minY), width = width, height = height)
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as Polygon

        if (!vertices.contentEquals(other.vertices)) return false

        return true
    }

    override fun hashCode(): Int {
        return vertices.contentHashCode()
    }

    companion object {
        internal fun computeBoundingPolygonVertices(boxes: Array<Box>): Array<Point> {
            val yCoords = boxes
                .let(::allYCoords)
                .sorted()

            var prevLeftCoord = 0
            var prevRightCoord = 0

            val points = mutableListOf<Point>()
            yCoords.forEachIndexed { index, yCoord ->
                val leftCoord = minXCoord(yCoord, boxes)
                val rightCoord = maxXCoord(yCoord, boxes)

                if (index == 0) {
                    points.add(Point(x = leftCoord, y = yCoord))
                    points.add(Point(x = rightCoord, y = yCoord))
                } else {
                    if (leftCoord != prevLeftCoord) {
                        points.add(0, Point(x = prevLeftCoord, y = yCoord))
                    }
                    points.add(0, Point(x = leftCoord, y = yCoord))

                    if (rightCoord != prevRightCoord) {
                        points.add(Point(x = prevRightCoord, y = yCoord))
                    }
                    points.add(Point(x = rightCoord, y = yCoord))
                }

                prevLeftCoord = leftCoord
                prevRightCoord = rightCoord
            }

            return points
                .toTypedArray()
                .skippingCollinear()
                .skippingTwins()
        }

        private fun expand(boxes: Array<Box>): Array<Box> {
            return boxes
                .map {
                    Box(
                        Point(it.topLeft.x - 1, it.topLeft.y - 1),
                        width = it.width + 2,
                        height = it.height + 2,
                    )
                }
                .toTypedArray()
        }

        private fun smoothenRows(boxes: Array<Box>): Array<Box> {
            return boxes
                .groupBy { "${it.minY}|${it.maxY}" }
                .mapValues { (_, rowBoxes) ->
                    val minX = rowBoxes.minOfOrNull(Box::minX)!!
                    val maxX = rowBoxes.maxOfOrNull(Box::maxX)!!
                    val minY = rowBoxes.minOfOrNull(Box::minY)!!
                    val maxY = rowBoxes.maxOfOrNull(Box::maxY)!!
                    Box(Point(minX, minY), maxX - minX, maxY - minY)
                }
                .values
                .toTypedArray()
        }

        private fun allYCoords(boxes: Array<Box>): Array<Int> {
            return boxes
                .flatMap { listOf(it.minY, it.maxY) }
                .toTypedArray()
        }

        private fun minXCoord(y: Int, boxes: Array<Box>): Int {
            return rectanglesAt(y, boxes)
                .minOfOrNull { it.minX }
                ?: 0
        }

        private fun maxXCoord(y: Int, boxes: Array<Box>): Int {
            return rectanglesAt(y, boxes)
                .maxOfOrNull { it.maxX }
                ?: 0
        }

        private fun rectanglesAt(y: Int, boxes: Array<Box>): Array<Box> {
            val boxesExcludingBottomLines = boxesExcludingBottomLinesAt(y, boxes)

            return if (boxesExcludingBottomLines.isEmpty()) {
                // There are only rectangle bottom lines so we need to consider them.
                boxesIncludingBottomLinesAt(y, boxes)
            } else {
                // There are rectangles that are not closing here, so ignore those that are closing.
                boxesExcludingBottomLines
            }
        }

        private fun boxesExcludingBottomLinesAt(y: Int, boxes: Array<Box>): Array<Box> {
            return boxes
                .filter { it.minY <= y && it.maxY > y + epsilon }
                .toTypedArray()
        }

        private fun boxesIncludingBottomLinesAt(y: Int, boxes: Array<Box>): Array<Box> {
            return boxes
                .filter { it.minY <= y && abs(it.maxY - y) < epsilon }
                .toTypedArray()
        }
    }
}

private fun List<Point>.doubleAt(index: Int): Array<Point> {
    val next = (index + 1) % size
    return arrayOf(this[index], this[next])
}

private fun List<Point>.tripleAt(index: Int): Array<Point> {
    val previous = if (index == 0) size - 1 else index - 1
    val next = (index + 1) % size
    return arrayOf(this[previous], this[index], this[next])
}

private fun Array<Point>.skippingCollinear(): Array<Point> {
    val result = this.toMutableList()
    var i = 0

    while (i < result.size - 1) {
        if (result.tripleAt(i).areCollinear()) {
            result.removeAt(i)
        } else {
            i++
        }
    }

    return result.toTypedArray()
}

private fun Array<Point>.skippingTwins(): Array<Point> {
    val result = this.toMutableList()
    var i = 0

    while (i < result.size - 1) {
        val (curr, next) = result.doubleAt(i)
        if (curr.distanceTo(next) < 2) {
            result.removeAt(i)
        } else {
            i++
        }
    }

    return result.toTypedArray()
}

private fun Array<Point>.areCollinear(): Boolean {
    if (this.size != 3) throw Exception("Only 3 points can be checked for colinearlity")

    val areaOfTriangle = this[0].x * (this[1].y - this[2].y) +
        this[1].x * (this[2].y - this[0].y) +
        this[2].x * (this[0].y - this[1].y)

    return (if (areaOfTriangle < 0) 0 - areaOfTriangle else areaOfTriangle) < epsilon
}
