package com.speechify.client.api.content

import kotlin.js.JsExport

@JsExport
data class ContentElementBoundary internal constructor(
    val element: ContentElementReference,
    val boundary: ContentBoundary,
) : ContentCursor() {

    override fun compareTo(elementBoundary: ContentElementBoundary): ContentRelativeOrder {
        return when (this.element.compareTo(elementBoundary.element)) {
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.EQUAL -> this.boundary.compareTo(elementBoundary.boundary)
        }
    }

    override fun getClosestPositionBetween(
        start: ContentTextPosition,
        end: ContentTextPosition,
    ): ContentTextPosition {
        return when (this.compareTo(start)) {
            ContentRelativeOrder.BEFORE -> start
            ContentRelativeOrder.EQUAL -> start
            ContentRelativeOrder.AFTER -> end
        }
    }

    override fun getParentElement(): ContentElementReference {
        return element
    }

    override fun compareTo(cursor: ContentCursor): ContentRelativeOrder {
        return when (cursor.compareTo(this)) {
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.EQUAL -> ContentRelativeOrder.EQUAL
        }
    }

    override fun compareTo(position: ContentTextPosition): ContentRelativeOrder {
        return when (this.element.compareTo(position.getParentElement())) {
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.EQUAL -> when (this.boundary) {
                ContentBoundary.START -> ContentRelativeOrder.BEFORE
                ContentBoundary.END -> ContentRelativeOrder.AFTER
            }
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other === null || other !is ContentCursor) return false
        return this.isEqual(other)
    }

    override fun hashCode(): Int {
        var result = element.hashCode()
        result = 31 * result + boundary.hashCode()
        return result
    }
}
