package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisVoice
import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerAdapter
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.speech.Speech
import kotlin.js.JsExport

/**
 * Abstracts away the construction of the utterances to not have to pass any infrastructural singletons, but just the
 * utterance data.
 */
@JsExport
class UtteranceFactory internal constructor(
    private val localSpeechSynthesisPlayerFactory: LocalSpeechSynthesisAdapter,
    private val mediaPlayerFactory: LocalMediaPlayerAdapter,
) {
    fun createLocalUtterance(
        localSynthesisVoice: LocalSynthesisVoice,
        speech: Speech,
        text: ContentText,
        voiceMetadata: VoiceMetadata,
    ): Utterance =
        LocalUtterance(
            localSynthesisVoice,
            speech,
            text,
            localSpeechSynthesisPlayerFactory,
            voiceMetadata = voiceMetadata,
        )

    fun createMediaUtterance(
        mediaUrl: String,
        speechMarks: SpeechMarks,
        speech: Speech,
        text: ContentText,
        synthesisLocation: SynthesisLocation,
        voiceMetadata: VoiceMetadata,
    ): Utterance =
        MediaUtterance(
            mediaUrl,
            speechMarks,
            speech,
            text,
            synthesisLocation,
            mediaPlayerFactory,
            voiceMetadata = voiceMetadata,
        )
}
