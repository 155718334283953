package com.speechify.client.reader.classic

import kotlin.js.JsExport

@JsExport
data class IndexRange(
    val startIndex: Int,
    val endIndexExclusive: Int,
) {

    internal val size: Int get() = endIndexExclusive - startIndex

    internal fun offsetBy(offset: Int): IndexRange = IndexRange(
        startIndex = startIndex + offset,
        endIndexExclusive = endIndexExclusive + offset,
    )

    internal companion object {
        fun spanning(text: String): IndexRange {
            return IndexRange(0, text.length)
        }

        fun from(startIndex: Int, endIndexExclusive: Int): IndexRange? {
            if (endIndexExclusive <= startIndex) return null
            return IndexRange(startIndex, endIndexExclusive)
        }
    }
}
