package com.speechify.client.internal.services.auth

import com.speechify.client.api.adapters.firebase.FirebaseAuthService
import com.speechify.client.api.adapters.firebase.FirebaseAuthToken
import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.adapters.firebase.coGetCurrentUser
import com.speechify.client.api.adapters.firebase.coGetCurrentUserIdentityToken
import com.speechify.client.api.adapters.firebase.observeCurrentUserAsFlow
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fallbackNullValueToFailure
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.util.extensions.collections.flows.emitToUnlimited
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch

internal class AuthService(private val firebaseAuthService: FirebaseAuthService) :
    WithScope(
        createTopLevelCoroutineScope(),
    ) {
    private var internalCurrentFirebaseUser = MutableStateFlow<Result<FirebaseAuthUser?>?>(null)

    init {
        this.scope.launch {
            firebaseAuthService.observeCurrentUserAsFlow()
                .collect {
                    internalCurrentFirebaseUser.emitToUnlimited(it)
                }
        }
    }

    /**
     * `null` represents a possible state of user not being determined yet (including not determined a new [UserId]
     * for an anonymous user).
     */
    internal val currentUserOrNullFlow: Flow<Result<FirebaseAuthUser?>> = internalCurrentFirebaseUser
        .filterNotNull() /* NOTE: It filters `null` Result (the initial one), not the `null` user. */

    /**
     * Emits only changes of user identity ([UserId]), and not changes of any other properties of the user.
     */
    internal val currentUserIdOrNullFlow: Flow<Result<UserId?>> = currentUserOrNullFlow
        .map { it.map { user -> user?.uid } }
        .distinctUntilChanged()

    internal suspend fun getCurrentUser(): Result<FirebaseAuthUser> =
        getCurrentUserOrNullResult()
            .fallbackNullValueToFailure(::getErrorFallbackForNoUser)

    internal suspend fun getCurrentUserOrNullResult(): Result<FirebaseAuthUser?> =
        internalCurrentFirebaseUser.value
            ?.toNullable() // if we have cached an error, try to fetch it again
            ?.successfully()
            ?: this.firebaseAuthService.coGetCurrentUser().also { result ->
                internalCurrentFirebaseUser.emit(result)
            }

    /**
     * Produces what is likely the most common use case for user information: gives a [Flow] of values:
     * - the [FirebaseAuthUser] of the user known to be currently logged in to this service
     * - or `null` if user is unknown (correctly resets to `null` when user logs out or there is an error)
     *
     * So, it takes care of the [Result.Failure]s - these result in returning `null` in the flow, but they get logged
     * with the specified [usageIdForLog], to allow to identify which functionality was affected by the failure.
     */
    internal fun getCurrentUserOrNullFlowLoggingErrors(
        usageIdForLog: String,
    ): Flow<FirebaseAuthUser?> = flow {
        when (val firebaseAuthUserResult = getCurrentUserOrNullResult()) {
            is Result.Success -> emit(firebaseAuthUserResult.value)
            is Result.Failure -> Log.e(
                error = firebaseAuthUserResult.error,
                message = "FirebaseAuthService.getCurrentUser gave an error so usage will receive" +
                    " a user only on the next change",
                sourceAreaId = AuthService::class.simpleName ?: "[UNKNOWN]",
                properties = mapOf("usageIdForLog" to usageIdForLog),
            )
        }

        currentUserOrNullFlow
            .collect { firebaseAuthUserResult ->
                when (firebaseAuthUserResult) {
                    is Result.Success -> emit(firebaseAuthUserResult.value)
                    is Result.Failure -> {
                        emit(null)
                        Log.e(
                            error = firebaseAuthUserResult.error,
                            message = "FirebaseAuthService.observeCurrentUser gave an error. Usage " +
                                "will receive a null ",
                            sourceAreaId = AuthService::class.simpleName ?: "[UNKNOWN]",
                            properties = mapOf("usageIdForLog" to usageIdForLog),
                        )
                    }
                }
            }
    }
        .distinctUntilChanged() /* Filter out double `null`s especially */

    internal suspend fun getCurrentUserIdentityToken(): Result<FirebaseAuthToken> =
        // We have seen issues with tokens going stale even when considering the expiry date
        // The safest option is to not cache them at all.
        this.firebaseAuthService.coGetCurrentUserIdentityToken()
            .fallbackNullValueToFailure(::getErrorFallbackForNoUser)

    /**
     * Use this when the current user is needed, or if there is no current user available, wait for the next user to become available.
     */
    internal suspend fun awaitNonNullUser(): FirebaseAuthUser {
        return currentUserOrNullFlow.filterIsInstance<Result.Success<FirebaseAuthUser?>>().mapNotNull { it.value }
            .first()
    }
}

/**
 * TODO - don't represent 'no user logged in' as an error - it's a valid state as per https://firebase.google.com/docs/reference/kotlin/com/google/firebase/auth/FirebaseAuth#getcurrentuser
 */
private fun getErrorFallbackForNoUser() = SDKError.Authentication(
    "There is no current user (normal when Firebase has not created an id for anonymous user yet)",
)
