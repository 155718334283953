package com.speechify.client.internal.services.adoption.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseUserUsageData(
    var android: FirebaseUserUsageDataEntry? = null,

    var ios: FirebaseUserUsageDataEntry? = null,

    @SerialName("web_app")
    var webApp: FirebaseUserUsageDataEntry? = null,

    @SerialName("extension")
    var chromeExtension: FirebaseUserUsageDataEntry? = null,

    @SerialName("safari_extension")
    var safariExtension: FirebaseUserUsageDataEntry? = null,

    @SerialName("mac_app")
    var macApp: FirebaseUserUsageDataEntry? = null,
)
