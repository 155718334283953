package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentLine
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.LinePosition
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage

/** Simple sorting to insure that words in lines in the correct order*/
internal class SortLineContent(private val logger: Logger?) :
    ParsingPipelineStage<List<ContentLine>, List<ContentLine>> {

    override fun process(input: List<ContentLine>): List<ContentLine> {
        val sortedLines = input.map { line ->
            val sortedRawContent = line.rawTextContentItems.sortedWith(getComparator(line.position))
            line.copy(rawTextContentItems = sortedRawContent)
        }

        logSortedLines(sortedLines)

        return sortedLines
    }

    private fun getComparator(position: LinePosition): Comparator<BookPageTextContentItem> =
        when (position) {
            LinePosition.VERTICAL -> compareByDescending { it.normalizedBox.bottom }
            LinePosition.HORIZONTAL -> compareBy { it.normalizedBox.right }
        }

    private fun logSortedLines(lines: List<ContentLine>) {
        logger?.log("Sorted lines content:")
        lines.forEach { logger?.log(it.plainText) }
    }
}
