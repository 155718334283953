package com.speechify.client.internal.services.importing.models

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.services.library.models.RecordType
import com.speechify.client.internal.time.Seconds
import kotlinx.serialization.Serializable

/**
 * [Source](https://github.com/SpeechifyInc/platform/blob/main/services/firebase-cloud-functions/functions/src/legacy/records/record/ICreateFileFromWebUrlRequest.ts)
 */
@Serializable
internal data class CreateFileFromWebLinkPayload(
    val userId: String,
    val client: AppEnvironment,
    val dateAdded: Seconds,
    val recordTitle: String?,
    val url: String,
    val sourceStoredURL: String?,
    val storageBucket: String?,
    val storagePath: String?,
    val recordUid: String,
    val recordCreatePathway: String? = null,
    val type: RecordType,
)
