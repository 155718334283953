package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentDirection
import com.speechify.client.api.content.ContentStartAndEndCursors
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.view.standard.isNoMoreContentResult
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.flows.generateFlow
import com.speechify.client.internal.util.extensions.collections.flows.flatMapConcatSync
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.internal.util.extensions.intentSyntax.runIfParamNotNullOrLeave
import kotlinx.coroutines.flow.dropWhile
import kotlin.js.JsExport

@JsExport
abstract class SpeechView :
    NavigableSpeechProvider(),
    ContentStartAndEndCursors,
    Destructible {
    /**
     * NOTE to SDK developers: use the idiomatic `suspend` version - this method is just for SDK consumers.
     */
    fun getCursor(
        query: CursorQuery,
        callback: Callback<ContentCursor>,
    ) = callback.fromCo {
        getCursor(query)
    }

    /* Safe to `Ignore` - the member is actually on `internal` interface, so just for SDK's internal use */
    @JsExport.Ignore
    override suspend fun getCursor(query: CursorQuery): Result<ContentCursor> {
        // Resolve the starting point and each scan in succession, exiting early on first error
        val startingPoint = getStartingPoint(query)
        return query.scans.fold(startingPoint) { result, scan ->
            when (result) {
                is Result.Failure -> return result
                is Result.Success -> this.scan(result.value, scan)
            }
        }
    }

    /**
     * NOTE to SDK developers: use the idiomatic `suspend` version - this method is just for SDK consumers.
     */
    fun getSpeech(
        query: SpeechQuery,
        callback: Callback<Speech>,
    ) = callback.fromCo {
        getSpeech(query)
    }

    internal suspend fun getSpeech(query: SpeechQuery): Result<Speech> {
        val startCursor = this.getCursor(query.start).orReturn { return it }
        val endCursor = this.getCursor(query.end).orReturn { return it }

        val firstBlock = getSpeechContainingCursor(startCursor)
            .orReturn { return it }
        val blocks = mutableListOf(firstBlock)

        // Scan from start to end, accumulating blocks
        while (blocks.last().end.isBefore(endCursor)) {
            val block = getNextSpeech(
                blocks.last().end,
                ContentDirection.FORWARD,
            )
                .orReturn { return it }
            if (block === null) break
            blocks.add(block)
        }
        return extractSpeechFromBlocks(startCursor, endCursor, blocks).successfully()
    }

    private suspend fun scan(
        cursor: ContentCursor,
        scan: CursorQueryScan,
    ): Result<ContentCursor> {
        var isFirstBlock = true
        var currentScan = scan
        var currentCursor = cursor
        var block: Speech? = getSpeechContainingCursor(cursor)
            .orReturn { return it }
        while (block !== null) {
            val result = when (currentScan.direction) {
                ContentDirection.FORWARD -> scanBlockForward(
                    isFirstBlock,
                    block,
                    currentScan,
                    currentCursor,
                )

                ContentDirection.BACKWARD -> scanBlockBackward(
                    block,
                    currentScan,
                    currentCursor,
                )
            }
            if (result.nextScan == null) {
                return result.nextCursor.successfully()
            }
            currentScan = result.nextScan
            currentCursor = result.nextCursor
            isFirstBlock = false
            block = getNextSpeech(
                currentCursor,
                scan.direction,
            )
                .orReturn { return it }
        }
        return currentCursor.successfully()
    }

    /**
     * The breakdown into below function helps you build efficient [SpeechView]s without worrying about all the edge
     * cases of scanning to resolve queries. As long as you can provide _some_ [Speech] around a cursor and the next one
     * in either direction, it will be sufficient to be translated into [getCursor] and [getSpeech].
     */

    /**
     * #NotProtectedToLackOfProtectedInternal - member is for class-internal use only. Not `protected` only because
     * it has to be `internal` and there's no `protected internal` in Kotlin.
     */
    internal abstract fun getStartingPoint(query: CursorQuery): Result<ContentCursor>

    /**
     * #NotProtectedToLackOfProtectedInternal - member is for class-internal use only. Not `protected` only because
     * it has to be `internal` and there's no `protected internal` in Kotlin.
     */
    internal abstract suspend fun getSpeechContainingCursor(
        cursor: ContentCursor,
    ): Result<Speech>

    /**
     * #NotProtectedToLackOfProtectedInternal - member is for class-internal use only. Not `protected` only because
     * it has to be `internal` and there's no `protected internal` in Kotlin.
     */
    internal abstract suspend fun getNextSpeech(
        cursor: ContentCursor,
        direction: ContentDirection,
    ): Result<Speech?>

    override fun getFullSentencesFlowFromSentenceContaining(
        startingPointOrNullIfBeginning: ContentCursor?,
    ): SpeechFlow =
        generateFlow(
            getSeedOrNull = {
                getSpeech(
                    query = (
                        startingPointOrNullIfBeginning?.let {
                            CursorQuery.fromCursor(it)
                        } ?: CursorQuery.fromStart()
                        )
                        .let { startingCursorQuery ->
                            SpeechQueryBuilder.fromBounds(
                                start = startingCursorQuery.scanBackwardToSentenceStart(),
                                end = startingCursorQuery.scanForwardToSentenceEnd(),
                            )
                        },
                ).orThrow()
            },
            getNextOrNull = { previousSentences ->
                val start = CursorQueryBuilder.fromCursor(previousSentences.end)
                    .scanForwardToSentenceStart(0)
                getSpeech(
                    query = SpeechQueryBuilder.fromBounds(
                        start = start,
                        end = start.scanForwardToSentenceEnd(),
                    ),
                ).orThrow()
                    .nullIf {
                        isNoMoreContentResult(
                            previousContentResult = previousSentences,
                            currentContentResult = this,
                        )
                    }
            },
        )
            .flatMapConcatSync {
                it.sentences.asIterable()
            }
            .runIfParamNotNullOrLeave(
                param = startingPointOrNullIfBeginning,
                block = { startingPoint ->
                    /** Sometimes (currently likely only at the end-of-document, when pressing fast-forward, although it
                     *  may depend on implementation of [com.speechify.client.api.content.view.standard.StandardView],
                     *  e.g. PDF exhibits this behavior only at the end-of-document, and HTML isn't known to produce
                     *  it) the [startingPoint] requested is actually a point *after* the sentence, most typically
                     *  a [com.speechify.client.api.content.ContentElementBoundary] with [com.speechify.client.api.content.ContentElementBoundary.boundary]
                     *  of [com.speechify.client.api.content.ContentBoundary.END].
                     *  In this case, we want to not return that sentence, as not only there's nothing to play
                     *  there, but also trying to generate highlighting for this sentence would create a
                     *  contradiction (trying to highlight a word in the sentence when the cursor given isn't even
                     *  in the sentence).
                     *  NOTE: even though this pertains to the starting of the flow, we do this filtering
                     *  here *after* creating the flow using a [dropWhile], and not in [generateFlow]'s `getSeedOrNull`
                     *  above by returning `null` there, because that would produce an empty flow, while there can
                     *  still be some sentence after the cursor - in `getSeedOrNull` we just took one sentence that
                     *  starts before the cursor and never checked for sentences ahead, and there isn't anything
                     *  guaranteeing that the requested starting cursor will not be a [com.speechify.client.api.content.ContentElementBoundary]
                     *  with [com.speechify.client.api.content.ContentElementBoundary.boundary] of [com.speechify.client.api.content.ContentBoundary.END]
                     *  of just some block in the middle of the document.
                     */
                    dropWhile { sentence ->
                        startingPoint.isAfter(sentence.end)
                    }
                },
            )
}

internal data class ScanBlockResult(
    val nextScan: CursorQueryScan?,
    val nextCursor: ContentCursor,
)

internal fun scanBlockBackward(
    currentBlock: Speech,
    currentScan: CursorQueryScan,
    currentCursor: ContentCursor,
): ScanBlockResult {
    when (currentScan) {
        is CursorQueryScan.Chars -> {
            if (currentBlock.sentences.isEmpty()) {
                return ScanBlockResult(
                    nextScan = currentScan,
                    nextCursor = currentBlock.start,
                )
            }
            val blockText = ContentTextUtils.concat(currentBlock.sentences.map { it.text })
            val currentIndexInBlock = blockText.getFirstIndexOfCursor(currentCursor)
            if (currentIndexInBlock >= currentScan.skipping) {
                return ScanBlockResult(
                    nextScan = null,
                    nextCursor = blockText.getFirstCursorAtIndex(
                        currentIndexInBlock - currentScan.skipping,
                    ),
                )
            } else {
                return ScanBlockResult(
                    nextScan = CursorQueryScan.Chars(
                        currentScan.direction,
                        currentScan.skipping - (currentIndexInBlock + 1),
                    ),
                    nextCursor = currentBlock.start,
                )
            }
        }

        is CursorQueryScan.Words -> {
            val words = currentBlock.words(currentBlock.start, currentCursor)
            val allWords = currentBlock.words(currentBlock.start, currentBlock.end)
            val cursorInNexWord = allWords.size > words.size &&
                allWords[words.size].containsCursor(currentCursor)
            val wordsSize = if (cursorInNexWord) words.size + 1 else words.size

            if (wordsSize == 0) {
                return ScanBlockResult(
                    nextScan = currentScan,
                    nextCursor = currentBlock.start,
                )
            }

            when (currentScan.boundary) {
                ContentBoundary.START -> {
                    return if (wordsSize > currentScan.skipping) {
                        ScanBlockResult(
                            nextScan = null,
                            nextCursor = allWords[wordsSize - currentScan.skipping - 1].start,
                        )
                    } else {
                        ScanBlockResult(
                            nextScan = CursorQueryScan.Words(
                                currentScan.direction,
                                currentScan.boundary,
                                currentScan.skipping - wordsSize,
                            ),
                            nextCursor = currentBlock.start,
                        )
                    }
                }

                ContentBoundary.END -> {
                    return if (words.size > currentScan.skipping) {
                        ScanBlockResult(
                            nextScan = null,
                            nextCursor = words[words.size - currentScan.skipping - 1].end,
                        )
                    } else {
                        ScanBlockResult(
                            nextScan = CursorQueryScan.Sentences(
                                currentScan.direction,
                                currentScan.boundary,
                                currentScan.skipping - words.size,
                            ),
                            nextCursor = currentBlock.end,
                        )
                    }
                }
            }
        }

        is CursorQueryScan.Sentences -> {
            val scannableBlock = currentBlock.slice(currentBlock.start, currentCursor)
            val sentences = scannableBlock.sentences.toMutableList()

            if (sentences.isEmpty()) {
                return ScanBlockResult(
                    nextScan = currentScan,
                    nextCursor = scannableBlock.start,
                )
            } else {
                when (currentScan.boundary) {
                    ContentBoundary.START -> {
                        return if (sentences.size > currentScan.skipping) {
                            ScanBlockResult(
                                nextScan = null,
                                nextCursor = sentences[sentences.size - currentScan.skipping - 1]
                                    .start,
                            )
                        } else {
                            ScanBlockResult(
                                nextScan = CursorQueryScan.Sentences(
                                    currentScan.direction,
                                    currentScan.boundary,
                                    currentScan.skipping - sentences.size,
                                ),
                                nextCursor = scannableBlock.start,
                            )
                        }
                    }

                    ContentBoundary.END -> {
                        return if (sentences.size > currentScan.skipping) {
                            ScanBlockResult(
                                nextScan = null,
                                nextCursor = sentences[sentences.size - currentScan.skipping].end,
                            )
                        } else {
                            ScanBlockResult(
                                nextScan = CursorQueryScan.Sentences(
                                    currentScan.direction,
                                    currentScan.boundary,
                                    currentScan.skipping - sentences.size,
                                ),
                                nextCursor = scannableBlock.end,
                            )
                        }
                    }
                }
            }
        }
    }
}

internal fun scanBlockForward(
    isFirstBlock: Boolean,
    currentBlock: Speech,
    currentScan: CursorQueryScan,
    currentCursor: ContentCursor,
): ScanBlockResult {
    when (currentScan) {
        is CursorQueryScan.Chars -> {
            if (currentBlock.sentences.isEmpty()) {
                return ScanBlockResult(
                    nextScan = currentScan,
                    nextCursor = currentBlock.end,
                )
            }
            val blockText = ContentTextUtils.concat(currentBlock.sentences.map { it.text })
            val blockLength = blockText.length
            val currentIndexInBlock = blockText.getFirstIndexOfCursor(currentCursor)
            val charsRemaining = blockLength - currentIndexInBlock - 1
            if (charsRemaining > currentScan.skipping) {
                return ScanBlockResult(
                    nextScan = null,
                    nextCursor = blockText.getLastCursorAtIndex(
                        currentIndexInBlock + currentScan.skipping,
                    ),
                )
            } else {
                return ScanBlockResult(
                    nextScan = CursorQueryScan.Chars(
                        currentScan.direction,
                        currentScan.skipping - charsRemaining,
                    ),
                    nextCursor = currentBlock.end,
                )
            }
        }

        is CursorQueryScan.Words -> {
            val words = currentBlock.words(currentCursor, currentBlock.end).toMutableList()
            if (words.isEmpty()) {
                return ScanBlockResult(nextScan = currentScan, nextCursor = currentBlock.end)
            }
            when (currentScan.boundary) {
                ContentBoundary.START -> {
                    if (isFirstBlock) words.removeFirst()
                    return if (words.size > currentScan.skipping) {
                        ScanBlockResult(
                            nextScan = null,
                            nextCursor = words[currentScan.skipping].start,
                        )
                    } else {
                        ScanBlockResult(
                            nextScan = CursorQueryScan.Words(
                                currentScan.direction,
                                currentScan.boundary,
                                currentScan.skipping - words.size,
                            ),
                            nextCursor = currentBlock.end,
                        )
                    }
                }

                ContentBoundary.END -> {
                    return if (words.size > currentScan.skipping) {
                        ScanBlockResult(
                            nextScan = null,
                            nextCursor = words[currentScan.skipping].end,
                        )
                    } else {
                        ScanBlockResult(
                            nextScan = CursorQueryScan.Words(
                                currentScan.direction,
                                currentScan.boundary,
                                currentScan.skipping - words.size,
                            ),
                            nextCursor = currentBlock.end,
                        )
                    }
                }
            }
        }

        is CursorQueryScan.Sentences -> {
            val scannableBlock = currentBlock.slice(currentCursor, currentBlock.end)
            val sentences = scannableBlock.sentences.toMutableList()

            // Continue to next block if no sentences in this one
            if (sentences.isEmpty()) {
                return ScanBlockResult(
                    nextScan = currentScan,
                    nextCursor = scannableBlock.end,
                )
            } else {
                when (currentScan.boundary) {
                    ContentBoundary.START -> {
                        // Don't count the current sentence
                        if (isFirstBlock) sentences.removeFirst()
                        return if (sentences.size > currentScan.skipping) {
                            ScanBlockResult(
                                nextScan = null,
                                nextCursor = sentences[currentScan.skipping].start,
                            )
                        } else {
                            ScanBlockResult(
                                nextScan = CursorQueryScan.Sentences(
                                    currentScan.direction,
                                    currentScan.boundary,
                                    currentScan.skipping - sentences.size,
                                ),
                                nextCursor = scannableBlock.end,
                            )
                        }
                    }

                    ContentBoundary.END -> {
                        return if (sentences.size > currentScan.skipping) {
                            ScanBlockResult(
                                nextScan = null,
                                nextCursor = sentences[currentScan.skipping].end,
                            )
                        } else {
                            ScanBlockResult(
                                nextScan = CursorQueryScan.Sentences(
                                    currentScan.direction,
                                    currentScan.boundary,
                                    currentScan.skipping - sentences.size,
                                ),
                                nextCursor = scannableBlock.end,
                            )
                        }
                    }
                }
            }
        }
    }
}

internal fun extractSpeechFromBlocks(
    start: ContentCursor,
    end: ContentCursor,
    blocks: List<Speech>,
): Speech {
    // Build a Speech Fragment from all the blocks
    if (blocks.size == 1) {
        val block = blocks.first()
        return block.slice(start, end)
    } else {
        val firstBlock = blocks.first()
        val middleBlocks = blocks.subList(1, blocks.size - 1)
        val lastBlock = blocks.last()
        val allBlocks = listOf(firstBlock.slice(start, firstBlock.end)) + middleBlocks + listOf(
            lastBlock.slice(
                lastBlock.start,
                end,
            ),
        )
        val sentences = allBlocks.flatMap { it.sentences.toList() }

        return if (sentences.isNotEmpty()) {
            Speech(
                sentences.first().start,
                sentences.last().end,
                sentences.toTypedArray(),
            )
        } else {
            Speech(
                firstBlock.start,
                lastBlock.end,
                arrayOf(),
            )
        }
    }
}
