package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class SubscriptionPurchase {
    data class Stripe(
        val paymentMethodId: String,
        val renewalFrequency: RenewalFrequency,
        val couponCode: String?,
        val hasTrial: Boolean,
        /** Trial length in days. Default is 3 */
        val trialLength: Int? = 3,
        /**
         * ISO code of the currency the subscription should be created in.
         * If specified the users card needs to match the currency, and the subscription needs to
         * support this currency.
         * If an invalid currency is selected, the correct currency to use will be returned in the error.
         * If this is null, the user will be charged in USD regardless of their card country.
         */
        val subscriptionCurrency: String? = null,

        /**
         * Stripe Plan id. Optional. If not set, plan will be deducted from renewalFrequency
         * todo(fernando): Enforce it
         */
        var planId: String? = null,

        /** Flag that indicates the Payment Intent is not fulfilled directly **/
        var confirmPaymentOnClient: Boolean? = false,
    ) : SubscriptionPurchase()

    data class Paypal(
        val subscriptionId: String,
        val renewalFrequency: RenewalFrequency,
        val hasTrial: Boolean,
    ) : SubscriptionPurchase()

    data class Apple(
        val receiptInfo: String,
    ) : SubscriptionPurchase()

    data class PlayStore(
        val productId: String,
        val purchaseToken: String,
    ) : SubscriptionPurchase()
}
