package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
data class Coupon(
    val planType: CouponPlanType,
    val type: CouponType,
    val value: Double,
)

@JsExport
enum class CouponPlanType(val value: String) {
    MONTHLY("monthly"),
    YEARLY("annual"),
    ALL("all"),
}

@JsExport
enum class CouponType(val value: String) {
    Percentage("percentage"),
    Amount("amount"),
}
