package com.speechify.client.api.util.boundary

import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.js.JsExport

/**
 * `BoundaryMap` was created to overcome a `map` not being correctly compiled to the equivalent type in the
 * product app.
 *
 * The hereby is the reading interface that can be used to narrow down contracts - see also [BoundaryMap] for a full
 * interface with mutability.
 */
@JsExport
interface BoundaryMapReadable<V> {
    fun hasKey(key: String): Boolean

    operator fun get(key: String): V?

    fun keys(): Array<String>

    fun entries(): Array<BoundaryPair<String, V>>
}

/**
 * Mutable interface for Boundary Map.
 */
@JsExport
interface BoundaryMap<V> : BoundaryMapReadable<V> {

    operator fun set(key: String, value: V)
}

internal fun <K, V> Map.Entry<K, V>.toBoundaryPair() = this.toPair().toBoundary()

internal fun <V> BoundaryMap<V>.toMap(): Map<String, V> =
    this.entries().associate { it.toPair() }

operator fun <V> BoundaryMap<V>.contains(key: String) = this.hasKey(key)

internal operator fun <V> Map<String, V>.plus(toAdd: BoundaryMap<V>?): BoundaryMap<V> =
    (this.entries.map { it.toPair() } + toAdd?.entries()?.map { it.toPair() }.orEmpty()).toBoundaryMap()

@JsExport
data class BoundaryPair<A, B>(val first: A, val second: B) {
    internal companion object {
        internal fun <A, B> from(p: Pair<A, B>): BoundaryPair<A, B> = BoundaryPair(p.first, p.second)
    }
}

internal fun <A, B> Pair<A, B>.toBoundary(): BoundaryPair<A, B> = BoundaryPair.from(this)

internal fun <A, B> BoundaryPair<A, B>.toPair(): Pair<A, B> = first to second

internal object BoundaryMapOfStringsSerializer : KSerializer<BoundaryMap<String>> {

    private val serializer = MapSerializer(String.serializer(), String.serializer())

    override val descriptor: SerialDescriptor
        get() = serializer.descriptor

    override fun deserialize(decoder: Decoder): BoundaryMap<String> {
        return serializer.deserialize(decoder).toBoundaryMap()
    }

    override fun serialize(encoder: Encoder, value: BoundaryMap<String>) {
        serializer.serialize(encoder, value.toMap())
    }
}
