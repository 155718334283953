package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentBlock
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentLine
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.LinePosition
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage

/** Simple sorting to insure that lines in the correct order*/
internal class SortBlockContent(private val logger: Logger?) :
    ParsingPipelineStage<List<ContentBlock>, List<ContentBlock>> {

    override fun process(input: List<ContentBlock>): List<ContentBlock> {
        val sortedBlocks = input.map { block ->
            block.copy(lines = block.lines.sortedWith(getComparator(block.linesPosition)))
        }

        logSortedBlocks(sortedBlocks)
        return sortedBlocks
    }

    /**
     * Sort right-to-left and bottom-to-top
     * */
    private fun getComparator(position: LinePosition): Comparator<ContentLine> = when (position) {
        LinePosition.VERTICAL -> compareByDescending { it.box.left }
        LinePosition.HORIZONTAL -> compareBy { it.box.bottom }
    }

    private fun logSortedBlocks(blocks: List<ContentBlock>) {
        logger?.log("Sorted blocks content:")
        blocks.forEach { block ->
            logger?.log(block.plainText)
        }
    }
}
