package com.speechify.client.api.services.subscription.models

import com.speechify.client.internal.time.ISO8601DateString
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class SubscriptionDiscount(
    /** Type of discount, percentage or amount */
    val type: SubscriptionDiscountType,

    /** Value of the discount (amount should be aligned with payment currency) */
    val value: Double,

    /** human-readable code from stripe or other system */
    val name: String,

    /** The discount duration **/
    var duration: SubscriptionDiscountDuration,

    /** The starting date of the coupon **/
    var startDate: ISO8601DateString,

    /** The expiry date of the coupon **/
    var expiryDate: ISO8601DateString,
)
