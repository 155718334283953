package com.speechify.client.api.util.boundary

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.fromCoWithErrorLoggingGetJob
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.toDestructor
import com.speechify.client.internal.util.collections.flows.MutableSharedFlowThatFinishes
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import kotlin.js.JsExport

@JsExport
interface CancellableJobWithIntermediateResults<IntermediateResult, FinalResult> {
    /** TODO Consider consolidating this class with [com.speechify.client.api.util.collections.flows.CallbackFlowSourceFromCollectWithResult]
     *   using inheritance to achieve same core method.
     */

    /**
     * NOTE: Calling the destructor will not cancel the whole operation, but just unsubscribe the [callback].
     * Use [cancel] to completely stop the operation.
     */
    fun awaitGettingIntermediates(
        progressListener: CallbackNoError<IntermediateResult>?,
        callback: Callback<FinalResult>,
    ): Destructor

    /**
     * This will completely cancel the underlying job, and will not allow any further collection.
     */
    fun cancel()
}

internal fun <IntermediateResult> Flow<IntermediateResult>.toCancellableJobWithIntermediateResultsIn(
    scope: CoroutineScope,
    sourceAreaId: String,
): CancellableJobWithIntermediateResults<IntermediateResult, Unit> =
    CancellableJobWithIntermediateResultsFromFlow(
        sourceAreaId = sourceAreaId,
        scope = scope,
        flow = this,
    )

internal class CancellableJobWithIntermediateResultsFromFlow<IntermediateResult>(
    private val sourceAreaId: String,
    private val scope: CoroutineScope = createTopLevelCoroutineScope(),
    flow: Flow<IntermediateResult>,
) : CancellableJobWithIntermediateResults<IntermediateResult, Unit> {
    internal val hotFlow = MutableSharedFlowThatFinishes<IntermediateResult>(1)

    private val job = scope.launch(
        CoroutineName("$sourceAreaId.runnerCoroutine"),
    ) {
        supervisorScope {
            try {
                flow
                    .onEach { hotFlow.emit(it) }
                    .collect()
                hotFlow.finish()
            } catch (e: Throwable) {
                hotFlow.fail(e)
            }
        }
    }

    override fun awaitGettingIntermediates(
        progressListener: CallbackNoError<IntermediateResult>?,
        callback: Callback<Unit>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "$sourceAreaId.awaitGettingIntermediates",
    ) {
        hotFlow.collect {
            progressListener?.invoke(it)
        }
        Unit.successfully()
    }.toDestructor()

    override fun cancel() {
        job.cancel()
    }
}
