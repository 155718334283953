package com.speechify.client.helpers.content.standard.html.contentExtractionRules.knownPages.knowledge.medium

import com.speechify.client.helpers.content.standard.html.ElementMatcher
import com.speechify.client.helpers.content.standard.html.contentExtractionRules.RelevantContentExtractionRules

internal val mediumContentExtractionRules by lazy { /* `lazy` just for this file to read overview-before-detail/what-before-how */
    mapOf(
        "medium.com" to contentExtractionRules,
    )
}

// Desktop and mobile are covered in one layout on `medium.com`
private val contentExtractionRules = RelevantContentExtractionRules(
    ignored = ElementMatcher.CompositeAnyOf(
        otherMatchers = arrayOf(
            /* This attribute specifies the top menu bar with sing-in/sign-up links which are not filtered out */
            ElementMatcher.ByAttributeValue("style", "transform: translateY(0px);"),
            /* Specifies relation attribute for different links across the web page
            that are not related to the article itself */
            ElementMatcher.ByAttributeValue("rel", "noopener follow"),
            /* Class name is self-explanatory, however is only used for author and article
            info section at the top of the article*/
            ElementMatcher.ByClass("speechify-ignore"),
            /* Specifies the section with followers count */
            ElementMatcher.ByClass("pw-follower-count"),
        ),
    ),
)
