package com.speechify.client.api.content.view.book

import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.internal.services.ml.models.TextItemType
import kotlin.js.JsExport

@JsExport
enum class TextSourceType(
    val supportsTextEnrichmentDetection: Boolean,
) {
    /** Text that came from a "precise" source such as a PDF, HTML, or similar. */
    DIGITAL_TEXT(
        supportsTextEnrichmentDetection = true,
    ),

    /** Text that might be lossy / skewed, such as one that is obtained by OCRing an image. */
    IMAGE(
        supportsTextEnrichmentDetection = false,
    ),
}

@JsExport
data class BookPageTextContentItem internal constructor(
    /**
     * The text content
     */
    val text: TextElementContentSlice,

    /**
     * The coordinates of the text on the page of the book, its values are normalized.
     */
    @Deprecated(
        "Use normalizedBox instead. These are equivalent, but normalizedBox makes" +
            " it easier to understand that this box is normalized when it is used in your program.",
    )
    val box: BoundingBox,

    /**
     * The font family with which this text is originally displayed
     */
    val fontFamily: String?,

    /**
     * Where this piece of text came from, either a PDF, or by running OCR on an image.
     */
    val textSourceType: TextSourceType,

    /**
     * [BookPageTextContentItem]'s type. Refer to [TextItemType] for further information on these types.
     */
    val type: TextItemType?,
) {
    /**
     * The coordinates of the text on the page of the book, its values are normalized.
     */
    val normalizedBox: BoundingBox = box
}

/**
 * An [UnstableBookPageTextContentItem] is a [BookPageTextContentItem] that DOES NOT guarantee that the
 * [ContentText] and [ContentCursors] it provides will satisfy their contracts.
 *
 * IF YOU ACCESS [text] PROPERTY, DO NOT DO ANYTHING WITH IT OTHER THAN TREAT IT LIKE A STRING.
 */
internal typealias UnstableBookPageTextContentItem = BookPageTextContentItem
