package com.speechify.client.api.util.io

import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toFailureFromSuccessIf
import com.speechify.client.internal.services.file.models.InMemoryFile
import kotlin.js.JsExport

/**
 * A markup interface grouping all types that carry some binary content that can also have a [MimeType],
 * where the content is expressed in one of the subtypes available under the [BinaryContentReadableInChunks] grouping type.
 */
@JsExport
interface BinaryContentWithMimeTypeReadableInChunksNullable<out BC : BinaryContentReadableInChunks> {
    /**
     * `null` means an intentful lack of content (e.g. an HTTP response without a body - see [com.speechify.client.api.adapters.http.HttpResponseWithBody.body]).
     */
    val binaryContent: BC?
    val mimeType: MimeType?
}

/**
 * A version of [BinaryContentWithMimeTypeReadableInChunksNullable] where the [binaryContent] is guaranteed to be non-null.
 */
@JsExport
interface BinaryContentWithMimeTypeReadableInChunks<out BC : BinaryContentReadableInChunks> :
    /* Inherit from the nullable version to allow code that expects the nullable version to work with the non-nullable version. */
        BinaryContentWithMimeTypeReadableInChunksNullable<BC> {
        override val binaryContent: BC
    }

/**
 * Warning: Using this function may lead to Out Of Memory errors - similarly to [BinaryContentReadableSequentiallyMultiplatformAPI.readAllBytesToSingleArray].
 * To prevent the problem, pass the [this] value directly to consumers, so they can read the content in chunks.
 */
@Deprecated(
    "Pass the [this] value directly to consumers, so they can read the content in chunks.",
)
internal suspend fun <
    BC : BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI,
    C : BinaryContentWithMimeTypeFromNativeReadableInChunksNullable<BC>,
    >
C.readAsByteArrayFile(
    /**
     * Exposed especially to maintain historical behavior of [InMemoryFile.contentType] being stripped of charset
     * parameter (it's hard to find out if anything relies on this).
     * #StrippingParameterFromContentTypeToMaintainHistoricalBehavior
     */
    shouldContentTypeLoseParameter: Boolean = false,
): Result<InMemoryFile> {
    val mimeType = mimeType

    return InMemoryFile(
        mimeTypeOrNull = mimeType,
        contentTypeOrNull = mimeType?.let {
            if (shouldContentTypeLoseParameter) it.typeSubtype else it.fullString
        },
        bytes = binaryContent
            ?.let { randomAccessBinaryContent ->
                randomAccessBinaryContent.coGetAllBytes()
                    .orReturn { return it }
            }
            ?: ByteArray(0),
    )
        .successfully()
}

/**
 * A markup interface grouping all subtypes of [BinaryContentWithMimeTypeReadableInChunksNullable] backed by some performant native object
 * expressed in one of the subtypes available under the [BinaryContentReadableInChunksWithNativeAPI] grouping type.
 */
@JsExport
interface BinaryContentWithMimeTypeFromNativeReadableInChunksNullable<
    out BC : BinaryContentReadableInChunksWithNativeAPI,
    > :
    BinaryContentWithMimeTypeReadableInChunksNullable<BC> {
    override val binaryContent: BC?
}

/**
 * A version of [BinaryContentWithMimeTypeFromNativeReadableInChunksNullable] where the [binaryContent] is guaranteed to be non-null.
 */
@JsExport
interface BinaryContentWithMimeTypeFromNativeReadableInChunks<out BC : BinaryContentReadableInChunksWithNativeAPI> :
    BinaryContentWithMimeTypeFromNativeReadableInChunksNullable<BC>,
    BinaryContentWithMimeTypeReadableInChunks<BC> {
    override val binaryContent: BC
}

/**
 * A simple implementation for the most desired among the [BinaryContentReadableInChunks] interfaces, which should be
 * used by the SDK clients.
 */
@JsExport
class BinaryContentWithMimeTypeFromNative<out BC : BinaryContentReadableInChunksWithNativeAPI>(
    override val mimeType: MimeType?,
    override val binaryContent: BC,
) : BinaryContentWithMimeTypeFromNativeReadableInChunks<BC>

internal fun <
    BC : BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI,
    T : BinaryContentWithMimeTypeFromNativeReadableInChunksNullable<BC>,
    > T.toNotNullableContentOrNull(): BinaryContentWithMimeTypeFromNativeReadableInChunks<BC>? =
    when (val body = this.binaryContent) {
        null -> null
        else -> body.withMimeType(mimeType)
    }

internal fun <T : BinaryContentWithMimeTypeReadableInChunks<*>> Result<T>.toFailureIfNoContentType(
    url: String,
): Result<T> =
    toFailureFromSuccessIf(
        predicate = { it.mimeType == null },
        errorForFailure = { SDKError.OtherMessage("Remote file has no content type: $url") },
    )
