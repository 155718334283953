package com.speechify.client.api.content

import com.speechify.client.internal.util.extensions.intentSyntax.isEquivalentBy
import kotlin.js.JsExport
import kotlin.jvm.JvmName

@JsExport
interface ContentStartAndEndCursors {
    /**
     * A cursor pointing to the beginning of the content
     */
    val start: ContentCursor

    /**
     * A cursor pointing to the end of the content
     */
    val end: ContentCursor
}

/**
 * An abstraction defining the common structure and behavior of *all* content
 */
@JsExport
interface Content : ContentStartAndEndCursors

/**
 * Note that #ContentTextEndCursorIsAtLastCharacter, so the [Content.start] and [Content.end] can be the same on a
 * single-character [ContentSlice], or any container that does not modify it and does not have its own
 * [ContentElementBoundary] (e.g. [com.speechify.client.api.content.view.standard.StandardBlock]s are like this).
 */
internal fun ContentStartAndEndCursors.hasUniqueEndCursor() =
    !start.isEqual(end)

internal fun ContentStartAndEndCursors?.isEquivalentByStartAndEndCursors(
    otherNullable: ContentStartAndEndCursors?,
): Boolean =
    isEquivalentBy(
        other = otherNullable,
        isEquivalentNotNull = {
            this.isEquivalentByStartAndEndCursors(other = it)
        },
    )

@JvmName("isEquivalentByStartAndEndCursorsNonNullable")
internal fun ContentStartAndEndCursors.isEquivalentByStartAndEndCursors(
    other: ContentStartAndEndCursors,
): Boolean =
    this.start.isEqual(other.start) &&
        this.end.isEqual(other.end)

/**
 * Returns `true` if the specified [cursor] is within the range of [ContentStartAndEndCursors.start]
 * and [ContentStartAndEndCursors.end] (inclusive). Otherwise, returns `false`.
 */
internal fun ContentStartAndEndCursors.contains(cursor: ContentCursor): Boolean {
    return containsCursor(start, end, cursor)
}

/**
 * Returns `true` if the specified [cursor] is within the range of [ContentStartAndEndCursors.start]
 * and [ContentStartAndEndCursors.end] (exclusive). Otherwise, returns `false`.
 */
internal fun ContentStartAndEndCursors.containsEndExclusive(cursor: ContentCursor): Boolean {
    return containsCursorEndExclusive(start, end, cursor)
}

/**
 * Returns `true` if the contents overlap, even if they only share endpoints. Returns `false` otherwise.
 */
internal fun ContentStartAndEndCursors.hasNontrivialIntersectionWith(other: ContentStartAndEndCursors): Boolean {
    return hasNontrivialIntersection(start, end, other.start, other.end)
}

/**
 * Returns `true` if this content and the given interval overlap, even if they only share endpoints.
 * Returns `false` otherwise.
 */
internal fun ContentStartAndEndCursors.hasNontrivialIntersectionWith(
    otherStart: ContentCursor,
    otherEnd: ContentCursor,
): Boolean {
    return hasNontrivialIntersection(start, end, otherStart, otherEnd)
}
