package com.speechify.client.internal.util.extensions.coroutines.coroutineScope

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.util.extensions.coroutines.job.allDescendantJobs
import com.speechify.client.internal.util.extensions.coroutines.job.ancestorsPathFromParentToRootJob
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.cancel
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

internal fun CoroutineScope.launchAndCancelChildrenAtEnd(
    logWarningIfChildrenRunning: Boolean,
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
) =
    this.launch(
        context = context,
        start = start,
    ) {
        block()

        val runningDescendants = this.coroutineContext
            .job.allDescendantJobs()
            .filter { it.isCompleted.not() && it.isCancelled.not() }
        val firstRunningDescendant = runningDescendants.firstOrNull()
        if (firstRunningDescendant != null) {
            if (logWarningIfChildrenRunning) {
                Log.w(
                    DiagnosticEvent(
                        message = "launchAndCancelChildrenAtEnd: Child coroutine(s) still running, indicating a " +
                            "leak. Cancelling explicitly to end the children.",
                        properties = mapOf(
                            "firstRunningDescendant" to firstRunningDescendant,
                            "firstRunningDescendantPathFromThisJob" to
                                firstRunningDescendant.ancestorsPathFromParentToRootJob()
                                    .takeWhile { it != this@launch }
                                    .toList()
                                    .reversed()
                                    .joinToString(separator = ", "),
                        ),
                        sourceAreaId = "LaunchAndCancelChildrenAtEnd",
                    ),
                )
            }

            this@launch.cancel(CancellationException("Explicitly cancelled to end running children."))
        }
    }
