package com.speechify.client.api.services.subscription.models

import com.speechify.client.internal.time.ISO8601DateString
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
data class ValidateReceiptResult(
    /** Will be true if the receipt is for a valid subscription (not expired, not refunded), false otherwise (invalid receipt, expired, refunded). */
    val isValid: Boolean,
    /** Will be true if the subscription already exists in our system, but is assigned to a different user id than the caller. */
    val needsTransfer: Boolean,
    /** Only set if the receipt is for a subscription, date when the subscription expires. */
    val expiresAt: ISO8601DateString?,
    /** Only set if the receipt is for a subscription, status of the subscription. */
    val status: SubscriptionStatus?,
    /**
     * Indicates if the user is still eligible for a trial period.
     * If the receipt is for a subscription, true when the next subscription most likely will have a trial, false otherwise.
     */
    val isEligibleForTrialPeriod: Boolean?,
)
