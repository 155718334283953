package com.speechify.client.bundlers.reading.book

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.importing.models.ImportableContentMetadata
import com.speechify.client.api.services.scannedbook.models.LazyOCRFiles
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.services.scannedbook.models.numberOfFiles
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.fromCoWithTelemetryLoggingErrors
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentBundler
import com.speechify.client.bundlers.content.SpeechifyContentBundler
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.bundlers.reading.ReadingBundler
import kotlin.js.JsExport

/**
 * A way to get a [BookReadingBundle] from any content, taking care of all the setup for a Book Original Mode experience.
 */
@JsExport
class BookReadingBundler internal constructor(
    override val speechifyClient: SpeechifyClient,
    private val contentBundler: ContentBundler,
    override val speechifyContentBundler: SpeechifyContentBundler,
    override val listeningBundler: ListeningBundler,
    private val bookReadingBundlerConfig: BookReadingBundlerConfig,
    private val eventsTrackerAdapter: EventsTrackerAdapter,
) : ReadingBundler() {

    private suspend fun createBookBundle(
        contentBundle: ContentBundle.BookBundle,
        startingCursor: ContentCursor = contentBundle.standardView.start,
        predecessorBundle: ReadingBundle? = null,
        bundleMetadata: BundleMetadata?,
    ): Result<BookReadingBundle> =
        BookReadingBundle(
            dependencies = createDependencies(
                contentBundle = contentBundle,
                startingCursor = startingCursor,
                bundleMetadata = bundleMetadata,
                eventsTrackerAdapter = eventsTrackerAdapter,
            )
                .orReturn { return it },
            bundlingSourceTelemetryEventBuilder = currentTelemetryEvent(),
            predecessorBundle = predecessorBundle,
            bundleMetadata = bundleMetadata,

        )
            .successfully()

    /**
     * Creates a [BookReadingBundle] for the specified [ocrFiles].
     * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForOcrFiles(
        ocrFiles: Array<OCRFile>,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<BookReadingBundle>,
    ) =
        callback.fromCoWithTelemetryLoggingErrors(
            telemetryEventName = "BookReadingBundler.createBundleForScannedBook",
        ) { telemetryEventBuilder ->
            telemetryEventBuilder.addUUID()
            telemetryEventBuilder.addProperty("numberOfPages", ocrFiles.size)
            createBookBundle(
                contentBundle = contentBundler
                    .createBundleForScannedBook(
                        ocrFiles = ocrFiles,
                        deviceLocalContent = null,
                        importStartChoice = importStartChoice,
                        bundleMetadata = bundleMetadata,
                    ),
                bundleMetadata = bundleMetadata,
            )
        }

    /**
     * Creates a [BookReadingBundle] for the specified [ocrFiles].
     * It takes `importStartChoice` as a parameter, see [ImportStartChoice]'s documentation.
     * @param bundleMetadata (optional) holds bundle metadata, See [BundleMetadata] and [ImportableContentMetadata].
     */
    fun createBundleForOcrFilesLazily(
        ocrFiles: LazyOCRFiles,
        importStartChoice: ImportStartChoice = ImportStartChoice.DoNotStart,
        bundleMetadata: BundleMetadata,
        callback: Callback<BookReadingBundle>,
    ) = callback.fromCoWithTelemetryLoggingErrors(
        telemetryEventName = "BookReadingBundler.createBundleForOcrFilesLazily",
    ) {
        it.addUUID()
        it.addProperty("numberOfPages", ocrFiles.numberOfFiles)
        createBookBundle(
            contentBundle = contentBundler
                .createBundleForOcrFilesLazily(
                    lazyOcrFiles = ocrFiles,
                    deviceLocalContent = null,
                    importStartChoice = importStartChoice,
                    bundleMetadata = bundleMetadata,
                ),
            bundleMetadata = bundleMetadata,
        )
    }

    fun createForEditedBook(
        editor: BookEditor,
        /**
         * The "predecessor" (pre-edits) bundle which the new, edited bundle is based on. This also translates
         * current progress into the new bundle's progress.
         */
        predecessorBundle: ReadingBundle,
        callback: Callback<BookReadingBundle>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "BookReadingBundler.createForEditedBook",
    ) {
        createBookBundle(
            contentBundle = contentBundler.createForEditedBook(
                edits = editor,
            ),
            startingCursor = predecessorBundle.playbackControls.state.latestPlaybackCursor,
            bundleMetadata = null,
            predecessorBundle = predecessorBundle,
        )
    }
}
