package com.speechify.client.internal.util.extensions.collections

internal inline fun <reified SubV> Iterable<IndexedValue<*>>.filterValueIsInstance():
    Iterable<IndexedValue<SubV>> =
    mapNotNull {
        if (it.value is SubV) {
            IndexedValue(it.index, it.value as SubV)
        } else {
            null
        }
    }

internal inline fun <V, R> Iterable<IndexedValue<V>>.mapValues(
    crossinline transform: (value: V) -> R,
): Iterable<IndexedValue<R>> =
    map {
        IndexedValue(it.index, transform(it.value))
    }
