package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlin.coroutines.cancellation.CancellationException

/**
 * `takeUntil` function that collects items from the upstream flow until
 * the provided predicate returns true. The flow will emit the item that satisfies
 * the condition and then complete.
 */
fun <T> Flow<T>.takeUntil(predicate: (T) -> Boolean): Flow<T> = flow {
    try {
        collect { value ->
            emit(value)
            if (predicate(value)) {
                // Complete the flow
                // Throwing an exception to cancel a flow
                throw CancellationException("`takeUntil` condition met. Flow completed.")
            }
        }
    } catch (e: CancellationException) {
        // Ignore the exception
    }
}
