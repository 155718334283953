package com.speechify.client.helpers.content.standard.dynamic.contentProviders

import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.util.AbortReceiverAsync
import com.speechify.client.helpers.content.standard.streamable.StreamableContentChunksBuilder
import com.speechify.client.internal.util.collections.flows.sharedFlowNeverReceivingAnyItem
import kotlinx.coroutines.flow.Flow

/**
 * The internal type used by the SDK for the part of logic that is agnostic to which specific variant
 * of [DynamicContentProviderBase] is being used.
 */
internal class DynamicContentProvider(
    fullInterfaceObject: DynamicContentProviderFullInterface,
) : DynamicContentProviderFullInterface by fullInterfaceObject {
    constructor(immutableAlwaysLiveContent: DynamicContentProviderForImmutableAlwaysLiveContent) :
        this(
            fullInterfaceObject = object :
                DynamicContentProviderFullInterface,
                DynamicContentProviderForImmutableAlwaysLiveContent by immutableAlwaysLiveContent {
                /** Have to override due to conflict in base interfaces. */
                override fun isAlive(ref: ObjectRef<Any?>): Boolean =
                    /** Delegating to [immutableAlwaysLiveContent], just to draw correspondence, but this will always
                     *  return `true` (go to the definition of the method called below, to see) */
                    immutableAlwaysLiveContent.isAlive(ref = ref)

                override fun tryGetResurrectedContent(
                    chunkIndex: Int,
                    responseProducer: TryGetAliveContentResponseProducer,
                    builder: StreamableContentChunksBuilder,
                    abortThisRequestReceiver: AbortReceiverAsync<Throwable?>,
                ) =
                    throw IllegalStateException("This should never be called because `isAlive` is always true")

                override val mutationsFlow: Flow<Unit> =
                    /* Using `*NeverReceivingAnyItem` because we're immutable. */
                    sharedFlowNeverReceivingAnyItem()
            },
        )

    constructor(immutableLimitedLifeContent: DynamicContentProviderForImmutableLimitedLifeContent) :
        this(
            fullInterfaceObject = object :
                DynamicContentProviderFullInterface,
                DynamicContentProviderForImmutableLimitedLifeContent by immutableLimitedLifeContent {
                override val mutationsFlow: Flow<Unit> =
                    /* Using `*NeverReceivingAnyItem` because we're immutable. */
                    sharedFlowNeverReceivingAnyItem()
            },
        )

    constructor(mutableLimitedLifeContent: DynamicContentProviderForMutableLimitedLifeContent) :
        this(
            fullInterfaceObject = object :
                DynamicContentProviderFullInterface,
                DynamicContentProviderForMutableLimitedLifeContentWithSDKInternals by mutableLimitedLifeContent {
                override fun isAlive(ref: ObjectRef<Any?>): Boolean =
                    /* Mutable content is implemented by not having any memory of the content
                     * (#MutableDynamicContentByHavingNoMemory), and always querying the content source, hence we don't
                     * use the `isAlive` mechanism, because it will not provide any improvement in avoiding stale content.
                     */
                    mutableLimitedLifeContent.isAlive(ref)

                override fun tryGetResurrectedContent(
                    chunkIndex: Int,
                    responseProducer: TryGetAliveContentResponseProducer,
                    builder: StreamableContentChunksBuilder,
                    abortThisRequestReceiver: AbortReceiverAsync<Throwable?>,
                ) =
                    /** The [DynamicContentProviderForMutableLimitedLifeContent] content doesn't have the [tryGetResurrectedContent]
                     * because it gets constantly called for new content via [DynamicContentProviderForMutableLimitedLifeContent.getContent].
                     * As such, most of the time the [isAlive] will return true except in rarer cases, perhaps right
                     * after the user scrolled away, killing the content, and the first pull for more content was from
                     * synthesis, rather than highlighting.
                     * For that case, there's the #DontCallTryGetResurrectedContentWhenMutable which should prevent
                     * this method being called, but just in case this breaks somehow - all we can answer that there's no
                     * content that is alive (since there's no [tryGetResurrectedContent]) and count on the next call to
                     * [DynamicContentProviderForMutableLimitedLifeContent.getContent] to achieve the refresh.
                     */
                    responseProducer.FoundNoAliveContent()
            },
        )
}

internal interface DynamicContentProviderFullInterface :
    DynamicContentProviderForImmutableLimitedLifeContent,
    DynamicContentProviderForMutableLimitedLifeContentWithSDKInternals
