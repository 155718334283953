package com.speechify.client.api.audio

import com.speechify.client.api.audio.AudioControllerEvent.Buffering
import com.speechify.client.api.audio.AudioControllerEvent.Ended
import com.speechify.client.api.audio.AudioControllerEvent.Paused
import com.speechify.client.api.audio.AudioControllerEvent.Playing
import com.speechify.client.api.audio.AudioControllerEvent.Seeking
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.SentenceAndWordLocation
import com.speechify.client.api.util.SDKError
import kotlin.js.JsExport

/**
 * Events emitted by an [AudioController].
 *
 * The events are about communicating state changes in the controller in a way that is relevant
 * to the UI:
 * - [Playing] means that we are now playing audio, please show a pause button in the UI
 * - [Paused] means that we are now paused, please show a play button in the UI
 * - [Buffering] means that we are buffering, please show a loading indicator in the UI
 * - [Ended] means that we have reached the end, please show a replay button in the UI
 *
 * It is allowed for a [Playing] controller to emit another [Playing] event, i.e., it is allowed
 * for the [AudioController] to transition to a state it's already in, for example, you successive
 * [Playing] events might be fired.
 *
 * Another important thing to note is that, the events [Playing], [Paused] and [Seeking] carry
 * a cursor, which should be used to update the scrubber.
 *
 * @see [AudioController.addEventListener]
 */
@JsExport
sealed class AudioControllerEvent(val type: String) {
    sealed interface HasCursor {
        val cursor: ContentCursor
    }

    sealed interface HasSentenceAndWordLocation {
        val sentenceAndWordLocation: SentenceAndWordLocation?
    }

    /**
     * Emitted when:
     * - playback resumes from a paused state
     * - buffering has finished and playback has started from the new position
     * - a play command was issued to the controller
     * - progress was made in playing to a new cursor
     */
    data class Playing(
        override val cursor: ContentCursor,

        /**
         * `null` may signify that:
         * - the voice is not known yet, and the event should be used just for its [sentenceAndWordLocation]. In a
         *   normal course of events, the next event will have a non-null [voice].
         * - a special point of playback was reached, e.g., the end of the content, or the start of the content,
         *   where the voice information is not available. The last voice observed can be used for visualization
         *   (which is also available in [com.speechify.client.helpers.ui.controls.PlaybackControls.state])
         */
        val voice: VoiceSpecOfAvailableVoice?,

        override val sentenceAndWordLocation: SentenceAndWordLocation?,
    ) :
        AudioControllerEvent("resumed"),
        HasCursor,
        HasSentenceAndWordLocation

    /**
     * Emitted when:
     * - a pause command was issued
     * - buffering finished, and the controller was paused before buffering.
     */
    data class Paused(
        override val cursor: ContentCursor,
        override val sentenceAndWordLocation: SentenceAndWordLocation?,
    ) :
        AudioControllerEvent("paused"),
        HasCursor,
        HasSentenceAndWordLocation

    /**
     * Emitted every time the audio controller reaches the end of the content.
     */
    object Ended : AudioControllerEvent("ended")

    /**
     * Emitted when the controller is trying to seek to [cursor].
     *
     * **NOTE:** This does not mean we are buffering, check for the [Buffering] event for that.
     */
    data class Seeking(override val cursor: ContentCursor) : AudioControllerEvent("seeking"), HasCursor

    /**
     * Emitted when an operation is taking very long to complete.
     *
     * This event will **always** be followed by [Paused] or [Playing] event.
     */
    object Buffering : AudioControllerEvent("started-buffering")

    /**
     * Emitted once the [com.speechify.client.helpers.ui.controls.PlaybackControls.voiceOfPreferenceOverride] is
     * successfully changed.
     */
    data class ChangedVoice(
        val oldVoice: VoiceSpecOfAvailableVoice?,
        val voice: VoiceSpecOfAvailableVoice?,
    ) :
        AudioControllerEvent("changed-voice")

    /**
     * Emitted once the speed has successfully changed
     */
    data class ChangedSpeed(val speedInWordsPerMinute: Int) :
        AudioControllerEvent("changed-speed")

    /**
     * Emitted every time an error occurs, errors are not fatal
     */
    data class Errored(val error: SDKError) : AudioControllerEvent("errored")

    /**
     * Emitted when the audio player is destroyed, if it was destroyed by a fatal error, [error]
     * will not be null and have the error.
     */
    data class Destroyed(val error: SDKError? = null) : AudioControllerEvent("destroyed")

    override fun toString(): String = this::class.simpleName ?: this::class.toString()
}
