package com.speechify.client.api.adapters.pdf

import com.speechify.client.api.util.images.BoundingBox
import kotlin.js.JsExport

/**
 * A string of text contained in a PDF page, along with the coordinates of its bounding box when rendered onto the page.
 *
 * This text can be a single character or an entire line - the purpose is to represent the actual text and its position
 * on the text as precisely as possible. Transformations will be applied later to extract clean sentences etc.
 *
 * **NOTE:** The bounding box should **always match** the original rect return by PDF library, used within the
 * [PDFPageAdapter], as the [BoundingBox] will be used by the SDK to help discover if this heading/paragraph and further
 * enhance listening and classic view experiences */
@JsExport
@kotlinx.serialization.Serializable // Marked as serializable for testing purposes only.
data class PDFPageTextContentItem(
    val text: String,
    val box: BoundingBox,
    val fontFamily: String?,
)
