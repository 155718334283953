package com.speechify.client.api.adapters.keyvalue

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.flatten
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.toNullSuccessIfResourceNotFound
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * Key Value store is designed for storing small pieces of data. (< 1MB)
 *
 * Contract: If the requested key is not set a [SDKError.ResourceNotFound] error is expected.
 */
@JsExport
interface LocalKeyValueStorageAdapter {

    fun getData(keys: Array<String>, callback: Callback<Array<Result<ByteArray>>>)
    fun putData(
        keys: Array<BoundaryPair<String, ByteArray>>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Array<Result<Unit>>>,
    )

    fun deleteData(
        keys: Array<String>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Array<Result<Unit>>>,
    )

    fun getSingle(key: String, callback: Callback<ByteArray>) {
        getData(arrayOf(key)) { result -> callback(result.map { it[0] }.flatten()) }
    }

    fun putSingle(key: String, data: ByteArray, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) {
        putData(arrayOf(BoundaryPair(key, data))) { result -> callback(result.map { it[0] }.flatten()) }
    }

    fun deleteSingle(key: String, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) {
        deleteData(arrayOf(key)) { result -> callback(result.map { it[0] }.flatten()) }
    }
}

internal fun LocalKeyValueStorageAdapter.traced() =
    if (Log.isDebugLoggingEnabled) LocalKeyValueStorageAdapterTraced(this) else this

internal suspend fun LocalKeyValueStorageAdapter.coGetSingle(key: String): ByteArray? =
    suspendCoroutine {
        getSingle(key, it::resume)
    }
        .toNullSuccessIfResourceNotFound()
        .orThrow()

internal suspend fun LocalKeyValueStorageAdapter.coPutSingle(key: String, data: ByteArray) = suspendCoroutine {
    putSingle(key, data, it::resume)
}

internal suspend fun LocalKeyValueStorageAdapter.coDeleteSingle(key: String): Result<Unit> = suspendCoroutine {
    deleteSingle(key, it::resume)
}

internal class LocalKeyValueStorageAdapterTraced(private val localKeyValueStorageAdapter: LocalKeyValueStorageAdapter) :
    LocalKeyValueStorageAdapter {
    override fun getData(keys: Array<String>, callback: Callback<Array<Result<ByteArray>>>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.getData($keys)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::getData",
        )
        localKeyValueStorageAdapter.getData(keys, taggedCallback)
    }

    override fun putData(keys: Array<BoundaryPair<String, ByteArray>>, callback: Callback<Array<Result<Unit>>>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.putData($keys)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::putData",
        )
        localKeyValueStorageAdapter.putData(keys, taggedCallback)
    }

    override fun deleteData(keys: Array<String>, callback: Callback<Array<Result<Unit>>>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.deleteData($keys)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::deleteData",
        )
        localKeyValueStorageAdapter.deleteData(keys, taggedCallback)
    }

    override fun getSingle(key: String, callback: Callback<ByteArray>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.getSingle($key)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::getSingle",
        )
        localKeyValueStorageAdapter.getSingle(key, taggedCallback)
    }

    override fun putSingle(key: String, data: ByteArray, callback: Callback<Unit>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.putSingle($key, $data)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::putSingle",
        )
        localKeyValueStorageAdapter.putSingle(key, data, taggedCallback)
    }

    override fun deleteSingle(key: String, callback: Callback<Unit>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalKeyValueStorageAdapter.deleteSingle($key)",
            sourceAreaId = "LocalKeyValueStorageAdapterTraced::deleteSingle",
        )
        localKeyValueStorageAdapter.deleteSingle(key, taggedCallback)
    }
}
