package com.speechify.client.internal.util.collections.lists

fun List<Int>.isContiguous(): Boolean {
    if (isEmpty()) return true
    for (i in 1 until size) {
        if (this[i] != this[i - 1] + 1) {
            return false
        }
    }

    return true
}
