package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.util.images.Point
import com.speechify.client.api.util.images.Viewport

/**
 * This is to represent the Boundaries of a character.
 */
internal data class CharBounds(
    val left: Point,
    val right: Point,
) {

    fun normalize(viewport: Viewport): CharBounds {
        return CharBounds(
            Point(left.x / viewport.width, left.y / viewport.height),
            Point(right.x / viewport.width, right.y / viewport.height),
        )
    }
}
