package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisVoice
import com.speechify.client.api.content.LanguageIdentity
import com.speechify.client.api.content.tryGetDisplayName
import com.speechify.client.api.services.audio.VoiceParams
import com.speechify.client.api.services.audio.toVoiceParams
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.toMap
import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlinx.serialization.SerialName
import kotlin.js.JsExport

/**
 * [VoiceSpec]s can be used to initialize the listening experience with your own voice configuration.
 * For both [Local] and [AudioServer] [VoiceSpec]s, you can customize the display names of them in case you
 * want to use the reference data provided at [SpeechifyVoiceSpecifications], or you can also create your own through
 * the public constructor.
 */
@JsExport
sealed class VoiceSpec {
    abstract val displayName: String
    abstract val avatarUrl: String?

    /**
     * Groups all [VoiceSpec]s that are implemented using [com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerAdapter].
     */
    sealed class VoiceSpecForMediaVoice : VoiceSpec(), VoiceSpecOfAvailableVoice

    /**
     * Groups all [VoiceSpecForMediaVoice]s whose media can be generated by the Audio Server
     * [synthesis service](https://audio.docs.speechify.dev/synthesis/overview.html) (Implemented in
     * [com.speechify.client.api.services.audio.AudioServer]).
     */

    sealed class VoiceSpecForMediaVoiceFromAudioServer : VoiceSpecForMediaVoice() {
        abstract val name: String

        abstract override val languageCode: String

        /**
         * The id of the engine, as recognized by the Audio Server.
         * See https://audio.docs.speechify.dev/synthesis/centralized-voice-list.html#current-voices-available
         */
        abstract val engine: String
    }

    /**
     * Defines common properties that are persisted in the database for [VoiceSpecForMediaVoiceFromAudioServer].
     * The significance of not listing all properties of [CVLVoiceSpec] is that they are meant to be
     * always retrieved fresh, so that it's possible to update the voice metadata for all users.
     */
    @kotlinx.serialization.Serializable
    open class VoiceSpecForMediaVoiceFromAudioServerPersisted(
        override val name: String,
        override val languageCode: String,
        override val displayName: String,
        override val avatarUrl: String?,
        override val isPremium: Boolean,
        override val gender: VoiceGender,
        override val engine: String,
        val labels: Array<String> = arrayOf(),
        @Suppress("NON_EXPORTABLE_TYPE")
        @SerialName(
            /** Using Kotlin-idiomatic map to make serialization work out-of-the-box */
            "localizedDisplayNames",
        )
        val localizedDisplayNamesKotlinMap: Map<String, String> = mapOf(),
        val previewAudioUrl: String? = null,
        val previewAudioSentence: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServer() {
        internal val voiceParams: VoiceParams by lazy {
            this.toVoiceParams()
        }

        val localizedDisplayNames: BoundaryMap<String> by lazy {
            localizedDisplayNamesKotlinMap.toBoundaryMap()
        }

        /*
         * Implementing [equals] and [hashCode] to retain the historical `data class` behavior of
         * the subclasses.
         * (Generated with IntelliJ IDEA > Code > Generate > equals() and hashCode())
         */

        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            /* Allow subclasses here. */
            if (other !is VoiceSpecForMediaVoiceFromAudioServerPersisted) return false

            /** [VoiceParams] is a `data class` and it's all that the AudioServer uses, so we can just use that to
             *  establish identity.
             */
            if (voiceParams != other.voiceParams) return false

            return true
        }

        override fun hashCode(): Int =
            /** Matching the [equals] */
            voiceParams.hashCode()

        override fun toString(): String =
            /*
             * (Generated with IntelliJ IDEA > Code > Generate > toString())
             */
            "${this::class.simpleName ?: "unknownName"}(engine='$engine',name='$name', languageCode='$languageCode', " +
                "displayName='$displayName', avatarUrl=$avatarUrl, isPremium=$isPremium, gender=$gender)"
    }

    /**
     * Groups all [VoiceSpec]s that are implemented using [com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisPlayer].
     */
    sealed class LocalSynthesisBackedVoice : VoiceSpec() {
        abstract val id: String
        abstract val previewAudioSentence: String?
    }

    /**
     * Carries just the identity of some [LocalSynthesisBackedVoice], but it's not known if the voice is available
     * (see also [LocalAvailable] for the voice that is considered available).
     */
    data class Local(
        override val displayName: String,
        override val id: String,
        override val avatarUrl: String? = null,
        override val previewAudioSentence: String? = null,
    ) : LocalSynthesisBackedVoice() {
        fun withDisplayName(customDisplayName: String): Local {
            return Local(customDisplayName, id, null)
        }

        fun withAvatarUrl(avatarUrl: String): Local {
            return Local(displayName, id, avatarUrl)
        }

        /**
         * Saves from having to type all the required properties [LocalAvailable], by copying them from
         * this instance.
         */
        fun createLocalAvailable(localSynthesisVoice: LocalSynthesisVoice): LocalAvailable {
            return LocalAvailable(
                localSynthesisVoice = localSynthesisVoice,
                displayName = displayName,
                id = id,
                avatarUrl = avatarUrl,
                previewAudioSentence = previewAudioSentence,
            )
        }
    }

    /**
     * Local Voice (on-device) that is considered to be available on the current device, as per [VoiceSpecOfAvailableVoice].
     */
    class LocalAvailable(
        override val localSynthesisVoice: LocalSynthesisVoice,
        override val displayName: String,
        override val id: String,
        override val avatarUrl: String? = null,
        override val previewAudioSentence: String? = null,
    ) : LocalSynthesisBackedVoice(), VoiceSpecOfAvailableVoice, HasLocalVoiceIdentity {

        override val isPremium: Boolean = false

        override val languageCode: String = localSynthesisVoice.languageCode

        override val gender: VoiceGender = localSynthesisVoice.gender
    }

    /**
     * Voice backed by Speechify TTS
     */
    class Speechify(
        displayName: String,
        isPremium: Boolean = true,
        languageCode: String,
        gender: VoiceGender,
        name: String,
        avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = "speechify",
    ) {
        fun withDisplayName(customDisplayName: String): Speechify {
            return Speechify(customDisplayName, isPremium, languageCode, gender, name)
        }

        fun withAvatarUrl(avatarUrl: String): Speechify {
            return Speechify(displayName, isPremium, languageCode, gender, name, avatarUrl)
        }
    }

    /**
     * Voice backed by Resemble.io
     */
    class ResembleIO(
        displayName: String,
        isPremium: Boolean = true,
        languageCode: String,
        gender: VoiceGender,
        name: String,
        avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = "resemble",
    ) {
        fun withDisplayName(customDisplayName: String): ResembleIO {
            return ResembleIO(customDisplayName, isPremium, languageCode, gender, name)
        }

        fun withAvatarUrl(avatarUrl: String): ResembleIO {
            return ResembleIO(displayName, isPremium, languageCode, gender, name, avatarUrl)
        }
    }

    /**
     * Voice backed by Amazon Polly
     */
    class AmazonPolly(
        displayName: String,
        isPremium: Boolean = true,
        languageCode: String,
        gender: VoiceGender,
        name: String,
        val pollyEngine: PollyEngine,
        avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = when (pollyEngine) {
            PollyEngine.STANDARD -> "standard"
            PollyEngine.NEURAL -> "neural"
            PollyEngine.NEURAL_LFR -> "neural-lfr"
        },
    ) {

        enum class PollyEngine {
            STANDARD,
            NEURAL,
            NEURAL_LFR,
        }

        fun withDisplayName(customDisplayName: String): AmazonPolly {
            return AmazonPolly(customDisplayName, isPremium, languageCode, gender, name, pollyEngine)
        }

        fun withAvatarUrl(avatarUrl: String): AmazonPolly {
            return AmazonPolly(displayName, isPremium, languageCode, gender, name, pollyEngine, avatarUrl)
        }
    }

    /**
     * Voice backed by Azure
     */
    class Azure(
        displayName: String,
        isPremium: Boolean = true,
        languageCode: String,
        gender: VoiceGender,
        name: String,
        avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = "azure",
    ) {
        fun withDisplayName(customDisplayName: String): Azure {
            return Azure(customDisplayName, isPremium, languageCode, gender, name)
        }

        fun withAvatarUrl(avatarUrl: String): Azure {
            return Azure(displayName, isPremium, languageCode, gender, name, avatarUrl)
        }
    }

    /**
     * Voice backed by Google WaveNet
     */
    class GoogleWavenet(
        displayName: String,
        isPremium: Boolean = true,
        languageCode: String,
        gender: VoiceGender,
        name: String,
        avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = "google",
    ) {
        fun withDisplayName(customDisplayName: String): GoogleWavenet {
            return GoogleWavenet(customDisplayName, isPremium, languageCode, gender, name)
        }

        fun withAvatarUrl(avatarUrl: String): GoogleWavenet {
            return GoogleWavenet(displayName, isPremium, languageCode, gender, name, avatarUrl)
        }
    }

    /**
     * Voice specification that was fetched from the CVL API
     * (see https://audio.docs.speechify.dev/synthesis/centralized-voice-list.html#get-v1-synthesis-client-voices ).
     * What engine is used is determined by the [engine] field.
     */
    class CVLVoiceSpec(
        displayName: String,
        isPremium: Boolean = true,
        engine: String,
        languageCode: String,
        gender: VoiceGender,
        override val avatarUrl: String?,
        labels: Array<String>,
        localizedDisplayNames: BoundaryMap<String>,
        previewAudioUrl: String?,
        previewAudioSentence: String?,
        name: String,
    ) : VoiceSpecForMediaVoiceFromAudioServerPersisted(
        name = name,
        languageCode = languageCode,
        displayName = displayName,
        avatarUrl = avatarUrl,
        isPremium = isPremium,
        gender = gender,
        engine = engine,
        labels = labels,
        localizedDisplayNamesKotlinMap = localizedDisplayNames.toMap(),
        previewAudioUrl = previewAudioUrl,
        previewAudioSentence = previewAudioSentence,
    )

    /**
     * Voice backed by pre-recorded human narration, served behind a TTS facade
     */
    data class Static(
        override val displayName: String,
        override val isPremium: Boolean = true,
        override val languageCode: String,
        override val gender: VoiceGender,
        val id: String,
        override val avatarUrl: String? = null,
    ) : VoiceSpecForMediaVoice() {
        fun withDisplayName(customDisplayName: String): Static =
            Static(
                displayName = customDisplayName,
                isPremium = isPremium,
                languageCode = languageCode,
                gender = gender,
                id = id,
                avatarUrl = avatarUrl,
            )

        fun withAvatarUrl(avatarUrl: String): Static =
            Static(
                displayName = displayName,
                isPremium = isPremium,
                languageCode = languageCode,
                gender = gender,
                id = id,
                avatarUrl = avatarUrl,
            )
    }
}

/**
 * Groups the [VoiceSpec]s that have are expected to be working on the current device.
 * For example, [LocalVoice] does not belong to this group because it includes voice choices offered by SDK
 * in [com.speechify.client.helpers.constants.SpeechifyVoiceSpecifications] which may not available on the current
 * device.
 * A voice from this group can be synchronously translated to [VoiceMetadata] using [toVoiceMetadata].
 */
@JsExport
sealed interface VoiceSpecOfAvailableVoice {
    /**
     * An identifier that is qualified with type, so it can be used to identify voice among voices of different types.
     */
    val idQualified get() =
        when (this) {
            is VoiceSpec.LocalAvailable -> this.getMetadataId()
            is VoiceSpec.VoiceSpecForMediaVoice -> this.getMetadataId()
        }

    val displayName: String
    val avatarUrl: String?
    val isPremium: Boolean

    /**
     * `null` can mean that the language is unknown (e.g. for prerecorded audio) or the voice is multilingual, without
     * any primary language or accent.
     */
    val languageCode: String?

    /**
     * `null` can mean that the language is unknown (e.g. for prerecorded audio) or the voice is multilingual, without
     * any primary language or accent.
     */
    val languageIdentity: LanguageIdentity? get() = languageCode?.let { languageCode ->
        LanguageIdentity(
            ietfTag = languageCode,
        )
    }

    val languageDisplayName: String? get() =
        languageIdentity?.tryGetDisplayName()

    val gender: VoiceGender

    fun asVoiceSpec(): VoiceSpec =
        this as VoiceSpec

    fun toVoiceMetadata(): VoiceMetadata =
        when (this) {
            is VoiceSpec.LocalAvailable -> this.toVoiceMetadataAsLocal()
            is VoiceSpec.VoiceSpecForMediaVoice -> this.toVoiceMetadataAsMedia()
        }
}
