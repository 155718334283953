package com.speechify.client.internal.services.book

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coGetObjectOrNullIfNotExists
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.book.PlatformMLParsedBookPageService.FirestoreMLParsedPageModel
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.services.library.getParsedLibraryItemFirestoreDocument
import com.speechify.client.internal.services.ml.models.LabeledPageTextGroup
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.IdGenerator
import com.speechify.client.internal.util.boundary.toBoundaryMap
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

internal fun getMlParsedPagesCollectionRef(itemId: SpeechifyContentId) = "items/$itemId/mlParsedPages"

internal fun getMlParsedPagePath(itemId: SpeechifyContentId, pageId: MLParsedPageId): PathInCollection =
    PathInCollection(getMlParsedPagesCollectionRef(itemId), pageId)

internal typealias MLParsedPageId = String

/**
 * Handles 2 operations which are: saving thr
 * 1- save the ML parsed page data [FirestoreMLParsedPageModel] to firestore as a field in the library item.
 * 2- Get the ML parsed page data [FirestoreMLParsedPageModel] from firestore by `pageIndex`.
 */
internal class PlatformMLParsedBookPageService internal constructor(
    private val authService: AuthService,
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val idGenerator: IdGenerator,
    private val libraryFirebaseDataFetcher: LibraryFirebaseDataFetcher,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
) {
    internal suspend fun getPageByPageIndex(bookId: SpeechifyContentId, pageIndex: Int):
        Result<FirestoreMLParsedPageModel?> {
        val libraryItem = getFirebaseBookLibraryItemMlParsingFields(bookId)
        val orderedPageIds = libraryItem.mlParsedBookFields.pageOrdering
            .mapTo(mutableListOf()) { it.pageId }
        val pageId = orderedPageIds.getOrNull(pageIndex)
            ?: return Result.Success(null)
        if (pageId == PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER) {
            return Result.Success(null)
        }

        val docPath = getMlParsedPagePath(
            itemId = bookId,
            pageId = pageId,
        )
        return firebaseFirestoreService
            .coGetObjectOrNullIfNotExists<FirestoreMLParsedPageModel>(docPath)
    }

    internal suspend fun addMLParsedPage(
        bookId: SpeechifyContentId,
        pageIndex: Int,
        labeledPageTextGroup: List<LabeledPageTextGroup>,
        viewport: Viewport,
        generatedBySoftwareVersion: String,
    ): Result<Unit> {
        val user = authService.getCurrentUser().orThrow()
        val libraryItem = getFirebaseBookLibraryItemMlParsingFields(bookId = bookId)
        val orderedPageIds = libraryItem.mlParsedBookFields.pageOrdering
            .mapTo(mutableListOf()) { it.pageId }

        val mlParsedPageId = orderedPageIds.getOrNull(pageIndex)
            ?.takeIf { it != PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER }
            ?: idGenerator.getGuidAsString()

        // We don't support a sparse tracking of ml parsed pages yet, so just fill the list to the correct size.
        while (orderedPageIds.size <= pageIndex) {
            orderedPageIds.add(PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER)
        }
        firebaseFirestoreService.coSetDocument(
            path = getMlParsedPagePath(bookId, mlParsedPageId),
            value = buildMlParsedBookPageFirestorePayload(
                owner = user.uid,
                firebaseTimestampAdapter = firebaseTimestampAdapter,
                firestoreLabeledPageTextGroups = labeledPageTextGroup.toTypedArray(),
                viewport = viewport,
                generatedBySoftwareVersion = generatedBySoftwareVersion,
            ).toBoundaryMap(),
        ).orThrow()

        orderedPageIds[pageIndex] = mlParsedPageId
        return libraryFirebaseDataFetcher.updateItemDataFromParams(
            itemId = bookId,
            payload = mlParsedBookFields(orderedPageIds = orderedPageIds.asSequence()).toBoundaryMap(),
        )
    }

    private suspend fun getFirebaseBookLibraryItemMlParsingFields(
        bookId: SpeechifyContentId,
    ): FireBaseBookLibraryItemMlParsingFields = firebaseFirestoreService
        .getParsedLibraryItemFirestoreDocument<FireBaseBookLibraryItemMlParsingFields>(libraryItemId = bookId)
        .orThrow()
        .let {
            val telemetryEventBuilder = currentTelemetryEvent()
            if (it.mlParsedBookFields.pageOrdering.isEmpty()) {
                // This should not happen, but we can still try to present something to the user.
                telemetryEventBuilder?.addProperty("fallbackOnMissingMlParsedPageOrderingSucceeded", true)
                val mlParsedPages =
                    firebaseFirestoreService.queryDocuments(getMlParsedPagesCollectionRef(itemId = bookId))
                        // This should give us the order the user intended (or something close to it).
                        .orderBy("createdAt", DocumentQueryBuilder.Direction.Ascending)
                        .coFetch().orDefaultWith {
                            telemetryEventBuilder?.addProperty("fallbackOnMissingMlParsedPageOrderingSucceeded", false)
                            emptyArray()
                        }
                it.copy(
                    mlParsedBookFields = MLParsedBookFields(
                        pageOrdering = mlParsedPages.mapNotNull { page ->
                            when (page) {
                                is FirebaseFirestoreDocumentSnapshot.Exists -> MLParsedBookFields.OrderingEntry(
                                    pageId = page.key,
                                )

                                FirebaseFirestoreDocumentSnapshot.NotExists -> null
                            }
                        },
                    ),
                )
            } else {
                it
            }
        }

    @Serializable
    internal class FirestoreMLParsedPageModel(
        val createdAt: DateTime,
        val owner: String,
        val labeledPageTextGroups: List<LabeledPageTextGroup>,
        val viewport: MLParsedPageViewPort,
        val createdBySDKVersion: String,
    )

    @Serializable
    internal class MLParsedPageViewPort(
        val width: Double,
        val height: Double,
    )

    @Serializable
    internal data class FireBaseBookLibraryItemMlParsingFields(
        @SerialName(RecordProperties.owner.keyId)
        val owner: String,

        @SerialName(RecordProperties.mlParsedBookFields.keyId)
        val mlParsedBookFields: MLParsedBookFields = MLParsedBookFields(emptyList()),
    )

    @Serializable
    internal class MLParsedBookFields(
        @SerialName(RecordProperties.pageOrdering.keyId)
        val pageOrdering: List<OrderingEntry>,
    ) {
        @Serializable
        class OrderingEntry(
            @SerialName(RecordProperties.pageId.keyId)
            val pageId: String,
        )
    }

    companion object {
        const val PAGE_OF_ORIGINAL_DOC__ID_PLACEHOLDER = "PAGE_OF_ORIGINAL_DOC"
    }
}
