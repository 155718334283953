package com.speechify.client.helpers.content.standard.epub

import com.speechify.client.api.content.view.epub.EpubViewAsWebPage
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.helpers.content.standard.html.WebPageStandardView
import kotlin.js.JsExport

@JsExport
class EpubStandardViewV1 internal constructor(
    epubViewAsWebPage: EpubViewAsWebPage,
    shouldUseRichBlocksParsing: Boolean = false,
) : WebPageStandardView(
    webPageView = epubViewAsWebPage,
    shouldUseRichBlocksParsing = shouldUseRichBlocksParsing,
) {
    override fun createStandardBlockChunking(
        chunkedStandardBlocks: StandardBlocks,
        chunkSize: Int,
    ) = EpubStandardBlockChunking(
        allBlocks = chunkedStandardBlocks,
        chunkSize = chunkSize,
    )
}
