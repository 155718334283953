package com.speechify.client.internal.sync

import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

/**
 * Wraps a value in a lock, guaranteeing all access to it are thread safe. Of course these assumptions only hold if
 * the reference never "escapes" the lock.
 */
internal class WrappingMutex<T> private constructor(private var valueRef: AtomicRef<T>) {
    private val lock = Mutex()

    companion object {
        fun <T> of(t: T): WrappingMutex<T> = WrappingMutex(AtomicRef(t))
    }

    /**
     * To make sure thread-safety is guaranteed, you can't return the inner value from this lock
     * using this function
     *
     * @throws ValueEscapedLockException when an attempt is made to make the value escape the lock
     */
    suspend fun <R> locked(block: suspend LockedContext<T>.(T) -> R): R {
        val result = lock.withLock {
            block(
                object : LockedContext<T> {
                    override fun set(value: T) {
                        valueRef.swap(value)
                    }
                },
                valueRef.value,
            )
        }
        if (result === valueRef) {
            throw ValueEscapedLockException()
        }
        return result
    }

    /**
     * Swaps the current value safeguarded in this lock with a new one
     */
    fun swap(with: T): T = valueRef.swap(with)

    interface LockedContext<T> {
        fun set(value: T)
    }
}

/**
 * Takes the inner value leaving null in its place
 */
internal suspend fun <T> WrappingMutex<T?>.take(): T? = swap(with = null)

/**
 * Non suspending version of [WrappingMutex]. Prefer [WrappingMutex] whenever possible, resorting to this one only when
 * needed. Blocking a coroutine can lead to deadlocks more easily.
 *
 * @see WrappingMutex
 */
internal class BlockingWrappingMutex<T>(private var valueRef: AtomicRef<T>) {
    private val lock = BlockingMutex()

    companion object {
        fun <T> of(t: T): BlockingWrappingMutex<T> = BlockingWrappingMutex(AtomicRef(t))
    }

    /**
     * To make sure thread-safety is guaranteed, you can't return the inner value from this lock
     * using this function
     *
     * @throws ValueEscapedLockException when an attempt is made to make the value escape the lock
     */
    fun <R> locked(
        block: LockedContext<T>.(valueAtLockEntry: T) -> R,
    ): R {
        val result = lock.withLock {
            block(
                /* this = */ object : LockedContext<T> {
                    override fun changeMutexSubject(newValue: T) =
                        valueRef.set(newValue)
                },
                /* valueAtLockEntry = */ valueRef.value,
            )
        }
        if (result === valueRef) {
            throw ValueEscapedLockException()
        }
        return result
    }

    interface LockedContext<T> {
        /**
         * Changes the value wrapped by the mutex.
         * Like [swap], but available inside the lock.
         */
        fun changeMutexSubject(
            newValue: T,
        )
    }

    /**
     * Swaps the current value safeguarded in this lock with a new one
     */
    fun swap(with: T): T {
        return valueRef.swap(with)
    }
}

/**
 * Takes the inner value leaving null in its place
 */
internal fun <T> BlockingWrappingMutex<T?>.take(): T? = swap(with = null)

/**
 * Exception thrown by [WrappingMutex.locked] and [BlockingWrappingMutex.locked]
 */
internal class ValueEscapedLockException :
    Throwable(
        "You must not return the value from the lock but only ever a derivative value through which one cannot modify the original", // ktlint-disable max-line-length
    )
