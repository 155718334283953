package com.speechify.client.api.util.images

import com.speechify.client.internal.services.ml.models.BoxCoordinates
import kotlin.js.JsExport
import kotlin.math.abs
import kotlin.math.hypot
import kotlin.math.max
import kotlin.math.min
import kotlin.math.pow
import kotlin.math.sqrt

/**
 * A representation of a rectangle in an image coordinate space, possibly transformed and translated from the origin
 */
@kotlinx.serialization.Serializable
@JsExport
data class BoundingBox(
    /**
     * The width of the box in coordinate space prior to application of the transform
     */
    val width: Double,

    /**
     * The height of the box in coordinate space prior to application of the transform
     */
    val height: Double,

    /**
     * A 3-D transform describing deformation of the rectangle
     */
    val transform: CoordinateTransform,
) {
    /**
     * The x-coordinate of the top-left corner of the box, after the transformation is applied
     */
    val left: Double get() = transform.apply(0.0, 0.0).first

    /**
     * The x-coordinate of the top-right corner of the box, after the transformation is applied
     */
    val right: Double get() = left + width

    /**
     * The y-coordinate of the top-left corner of the box, after the transformation is applied
     */
    val top: Double get() = transform.apply(0.0, 0.0).second

    /**
     * The y-coordinate of the bottom-left corner of the box, after the transformation is applied
     */
    val bottom: Double get() = top + height

    /**
     * The y-coordinate of the center of the box, after the transformation is applied.
     */
    val centerY: Double get() = top + height / 2

    /**
     * The x-coordinate of the center of the box, after the transformation is applied.
     */
    val centerX: Double get() = left + width / 2

    /**
     * Get a new [BoundingBox] by scaling this one by [scale]
     */
    fun scaleBy(scale: Double): BoundingBox {
        return scaleIndividually(scale, scale)
    }

    /**
     * Get a new [BoundingBox] by scaling the width by [widthFactor] and height by [heightFactor]
     */
    fun scaleIndividually(widthFactor: Double, heightFactor: Double): BoundingBox {
        return BoundingBox(
            width * widthFactor,
            height * heightFactor,
            transform.scaleIndividually(widthFactor, heightFactor),
        )
    }

    /**
     * Get a new [BoundingBox] by translating this one rightward by [x] and upward by [y]
     */
    fun translate(x: Double, y: Double): BoundingBox {
        return BoundingBox(width, height, transform.translate(x, y))
    }

    /**
     * Get a bounding box from dimensions and a transform, when the transform assumes a "flipped" Y-axis - where the
     * origin is at the bottom-left instead of top-left.
     */
    fun flipVertically(viewport: Viewport): BoundingBox {
        return BoundingBoxUtils.fromTransformedBoxInFlippedViewport(
            width,
            height,
            transform,
            viewport,
        )
    }

    /**
     * Returns a [BoundingBox] normalized to the [Viewport]'s width and height
     */
    fun normalize(viewport: Viewport): BoundingBox {
        val newBoundingBox = BoundingBox(
            width / viewport.width,
            height / viewport.height,
            transform.copy(
                tx = transform.tx / viewport.width,
                ty = transform.ty / viewport.height,
            ),
        )
        return newBoundingBox
    }

    /**
     * Assuming that [this] is a [BoundingBox] normalized to the given [viewport],
     * @return a representation of this [BoundingBox] in the un-normalized coordinate space of the given [viewport]
     */
    fun unnormalize(viewport: Viewport): BoundingBox {
        return BoundingBox(
            width * viewport.width,
            height * viewport.height,
            transform.copy(
                tx = transform.tx * viewport.width,
                ty = transform.ty * viewport.height,
            ),
        )
    }

    fun union(other: BoundingBox) = BoundingBox(
        width = max(right, other.right) - min(left, other.left),
        height = max(bottom, other.bottom) - min(top, other.top),
        transform = transform.copy(
            // TODO(mendess) what to do with the other fields?
            tx = min(transform.tx, other.transform.tx),
            ty = min(transform.ty, other.transform.ty),
        ),
    )

    fun overlaps(other: BoundingBox) = left < other.right && right > other.left &&
        top < other.bottom && bottom > other.top

    companion object {
        /**
         * Get a bounding box with the specified dimensions and coordinates
         */
        fun fromDimensionsAndCoordinates(
            width: Double,
            height: Double,
            left: Double,
            top: Double,
        ): BoundingBox {
            return BoundingBox(width, height, CoordinateTransform.identity.translate(left, top))
        }

        /**
         * Get a bounding box from dimensions and a transform - useful if your low-level library gives you transforms instead of actual coordinates (e.g. PDFJS)
         */
        fun fromTransformedBox(
            width: Double,
            height: Double,
            transform: CoordinateTransform,
        ): BoundingBox {
            return BoundingBox(width, height, transform)
        }

        /**
         * Get a bounding box from dimensions and a transform, when the transform assumes a "flipped" Y-axis - where the origin is at the bottom-left instead of top-left.
         */
        fun fromTransformedBoxInFlippedViewport(
            width: Double,
            height: Double,
            transform: CoordinateTransform,
            viewport: Viewport,
        ): BoundingBox {
            return BoundingBox(
                width,
                height,
                // Flip the transform, then translate since prior "top" is now "bottom"
                CoordinateTransformUtils.fromMatrixWithFlippedViewport(transform.matrix, viewport)
                    .translate(0.0, -height),
            )
        }
    }
}

internal fun BoundingBox.distanceTo(other: BoundingBox): Double {
    // Calculate the center points of both bounding boxes
    val thisCenterX = this.centerX
    val thisCenterY = this.centerY
    val otherCenterX = other.centerX
    val otherCenterY = other.centerY

    // Calculate the distance between the center points
    return sqrt(
        (thisCenterX - otherCenterX).pow(2) +
            (thisCenterY - otherCenterY).pow(2),
    )
}

internal infix fun BoundingBox.verticalDistanceTo(that: BoundingBox): Double = verticalGapTo(that)?.distance ?: 0.0
internal infix fun BoundingBox.verticalDistanceOrOverlapTo(that: BoundingBox): Double {
    // Y Axis goes from 0 top to 1 bottom. Larger is further down.
    if (this.bottom < that.top) {
        // That box is fully under this box.
        return that.top - this.bottom
    } else if (this.top > that.bottom) {
        // That box is fully above this box.
        return this.top - that.bottom
    } else if (that.centerY > this.centerY) {
        // That box is generally below us. So take the overlap from the bottom edge.
        return that.top - this.bottom
    } else {
        // That box is above us, or perfectly centered in the box.
        // So take the overlap from the top edge.
        return this.top - that.bottom
    }
}

internal fun BoundingBox.verticalGapTo(that: BoundingBox): Gap? = when {
    bottom < that.top -> Gap(this.bottom, that.top)
    top > that.bottom -> Gap(that.bottom, this.top)
    // if neither is true, then the boxes overlap
    else -> null
}

internal infix fun BoundingBox.horizontalDistanceTo(that: BoundingBox): Double = horizontalGapTo(that)?.distance ?: 0.0

internal fun BoundingBox.horizontalGapTo(that: BoundingBox): Gap? = when {
    right < that.left -> Gap(this.right, that.left)
    left > that.right -> Gap(that.right, this.left)
    // if neither is true, then the boxes overlap
    else -> null
}

internal infix fun BoundingBox.horizontalDistanceOrOverlapTo(that: BoundingBox): Double {
    // Y Axis goes from 0 top to 1 bottom. Larger is further down.
    if (this.right < that.left) {
        // That box is fully to the left of this box.
        return that.left - this.right
    } else if (this.left > that.right) {
        // That box is fully to the right of this box.
        return this.left - that.right
    } else if (that.centerX > this.centerX) {
        // That box is generally to the right of us. So take the overlap from the right edge.
        return that.left - this.right
    } else {
        // That box is above us, or perfectly centered in the box.
        // So take the overlap from the top edge.
        return this.left - that.right
    }
}

internal data class Gap(val from: Double, val to: Double) {
    val distance = to - from
    fun contains(point: Double) = (from..to).contains(point)
}

internal operator fun BoundingBox.contains(other: BoundingBox): Boolean {
    return this.top <= other.top && this.bottom >= other.bottom && this.left <= other.left && this.right >= other.right
}

internal fun BoundingBox.isOverlapWithTolerance(other: BoundingBox, tolerance: Double): Boolean {
    return (
        abs(left - other.left) <= tolerance &&
            abs(top - other.top) <= tolerance &&
            abs(right - other.right) <= tolerance &&
            abs(bottom - other.bottom) <= tolerance
        )
}

@JsExport
object BoundingBoxUtils {
    /**
     * Get a bounding box with the specified dimensions and coordinates
     */
    fun fromDimensionsAndCoordinates(
        width: Double,
        height: Double,
        left: Double,
        top: Double,
    ) = BoundingBox.fromDimensionsAndCoordinates(width, height, left, top)

    /**
     * Get a bounding box from dimensions and a transform - useful if your low-level library gives you transforms instead of actual coordinates (e.g. PDFJS)
     */
    fun fromTransformedBox(
        width: Double,
        height: Double,
        transform: CoordinateTransform,
    ) = BoundingBox.fromTransformedBox(width, height, transform)

    /**
     * Get a bounding box from dimensions and a transform, when the transform assumes a "flipped" Y-axis - where the origin is at the bottom-left instead of top-left.
     */
    fun fromTransformedBoxInFlippedViewport(
        width: Double,
        height: Double,
        transform: CoordinateTransform,
        viewport: Viewport,
    ) = BoundingBox.fromTransformedBoxInFlippedViewport(width, height, transform, viewport)
}

fun BoundingBox.toBoxCoordinates(): BoxCoordinates = BoxCoordinates(left, right, top, bottom)

internal data class Point(val x: Double, val y: Double) {

    fun distanceTo(other: Point): Double {
        val xDiff = (other.x - x)
        val yDiff = (other.y - y)
        return hypot(xDiff, yDiff)
    }

    internal companion object {
        internal val origin = Point(0.0, 0.0)
    }
}

internal fun Point.projectOntoWithDistance(lineSegment: Pair<Point, Point>): Pair<Point, Double> {
    val (A, B) = lineSegment
    // Vector AB
    val ABx = B.x - A.x
    val ABy = B.y - A.y

    // Vector AC
    val ACx = this.x - A.x
    val ACy = this.y - A.y

    // Projection scalar t
    val AB_AB = ABx * ABx + ABy * ABy
    val AC_AB = ACx * ABx + ACy * ABy
    val t = AC_AB / AB_AB

    // Closest point on line AB
    val projection = when {
        t < 0.0 -> A
        t > 1.0 -> B
        else -> Point(A.x + t * ABx, A.y + t * ABy)
    }
    return projection to projection.distanceTo(this)
}
