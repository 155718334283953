package com.speechify.client.internal.coroutines.fromNonCancellableAPIs

import com.speechify.client.api.util.Result
import com.speechify.client.api.util.toSdkResult

/**
 * A version of [suspendCancellableCoroutineForNonCancellableAPIByDetach] useful for SDK-[Result] style APIs, where
 * it needs to be translated to a `suspend` function returning [Result].
 *
 * See [suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing] for a version that throws.
 */
internal suspend fun <T> suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
    /**
     * An extra action for when the cancellation leaves the Job running.
     * Useful for reporting wasted resources to developers.
     */
    onCancellationLeavingJobRunning: () -> Unit = {},
    /**
     * Same semantics as [kotlinx.coroutines.suspendCancellableCoroutine]'s parameter, just a better name than `block`:)
     */
    connectToResume: (SdkResultContinuation<T>) -> Unit,
): Result<T> =
    runCatching {
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing(
            onCancellationLeavingJobRunning = onCancellationLeavingJobRunning,
            connectToResume = connectToResume,
        )
    }
        .toSdkResult()
