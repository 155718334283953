package com.speechify.client.internal.services.ml

import com.speechify.client.api.SDKVersion
import com.speechify.client.api.appVersionFromStringOrFallbackToDefault
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.scannedbook.ScannedBookBookPage
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.content.view.book.TextSourceType
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.scannedbook.models.LocalPage
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.toDiagnosticEvent
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.images.toBoxCoordinates
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.bundlers.content.IsBookPageContentEmpty
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.bundlers.reading.importing.ContentPostImportActionsManager
import com.speechify.client.internal.services.book.PlatformMLParsedBookPageService
import com.speechify.client.internal.services.ml.models.BoxCoordinates
import com.speechify.client.internal.services.ml.models.LabeledPageTextGroup
import com.speechify.client.internal.services.ml.models.TextGroupType
import com.speechify.client.internal.services.ml.models.TextItemType
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.userDocumentSettings.UserProfileService
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.withContext
import kotlinx.serialization.Serializable

private const val ML_IMAGE_COMPRESSION_TARGET_PERCENT_PORTRAIT = 40
private const val ML_IMAGE_COMPRESSION_TARGET_PERCENT_LANDSCAPE = 50
private const val ML_IMAGE_SCALE_PORTRAIT = 1.0
private const val ML_IMAGE_SCALE_LANDSCAPE = 1.1

/**
 * Manages the execution of ml parsing, that might include OCR if needed, for a [BookPage] and stores it in Firestore.
 * It verifies whether the BookPage already has ml parsed content saved in Firestore before initiating the
 * ml parsing process again.
 * This Service takes care of saving the OCR Results to firestore in case OCR was done on server side.
 */
internal class BookPageMLParsingWithRemoteOCRService internal constructor(
    private val platformMLParsedBookPageService: PlatformMLParsedBookPageService,
    private val mlPageParsingWithRemoteOCRService: MLPageParsingWithRemoteOCRService,
    private val postImportActionsManager: ContentPostImportActionsManager,
    private val convertImage: suspend (
        imageInput: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        targetQualityPercent: Int,
    ) -> Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    private val scannedBookService: PlatformScannedBookService,
    private val bookPageOCRRequirementFlow: MutableSharedFlow<Pair<BookPageIndex, IsBookPageContentEmpty>>,
    private val minimalSDKVersionToClearMLParsingCaches: SDKVersion?,
    private val userProfileService: UserProfileService,
) {

    /**
     * Processes a book page image + the provided [rawContentTextItems] and returns either a [MLParsedPageContent]
     * OR [RawTextContentDerivedFromServerOCR] content.
     *
     * - If [rawContentTextItems] is empty, the server will run OCR to extract the page text content.
     * - If ML parsing fails, the OCR-derived [RawTextContentDerivedFromServerOCR] are returned as a fallback.
     * - If both ML parsing and OCR fail, an error is returned to indicate that no data could be provided.
     */
    internal suspend fun getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR(
        bookPageDelegate: BookPageDelegate,
        rawContentTextItems: List<BookPageTextContentItem>,
        shouldRunOcrFallback: Boolean,
    ): Result<ParsedPageContentOrRawTextItems> {
        // Notify early in the process through a flow that the OCR requirement is needed for this bookPage.
        // This encompasses cases where OCR has been previously executed and saved but is requested again.
        if (shouldRunOcrFallback) {
            bookPageOCRRequirementFlow.emit(bookPageDelegate.pageIndex to rawContentTextItems.isEmpty())
        }

        val viewport = bookPageDelegate.getMetadata().viewport
        // First we check if we previously already run the ml parsing for this page.
        val importerState = postImportActionsManager.state
        if (importerState is ContentImporterState.Imported) {
            val mlParsedPageFromDatabaseOrNull = platformMLParsedBookPageService.getPageByPageIndex(
                bookId = importerState.uri.id,
                pageIndex = bookPageDelegate.pageIndex,
            ).orReturn { return it }

            if (mlParsedPageFromDatabaseOrNull != null) {
                val mlParsedPageSDKVersion = appVersionFromStringOrFallbackToDefault(
                    version = mlParsedPageFromDatabaseOrNull.createdBySDKVersion,
                )
                if (minimalSDKVersionToClearMLParsingCaches == null ||
                    minimalSDKVersionToClearMLParsingCaches.toLong() <= mlParsedPageSDKVersion.toLong()
                ) {
                    return ParsedPageContentOrRawTextItems.MLParsedPageContent(
                        ParsedPageContent(
                            mlParsedPageFromDatabaseOrNull.labeledPageTextGroups.toParsedBookPageGroups(
                                pageIndex = bookPageDelegate.pageIndex,
                                viewport = mlParsedPageFromDatabaseOrNull.viewport.toViewport(),
                                textSourceType = if (shouldRunOcrFallback) {
                                    TextSourceType.IMAGE
                                } else {
                                    TextSourceType.DIGITAL_TEXT
                                },
                            ),
                        ),
                    ).successfully()
                }
            }
        }

        suspend fun getOcrImageScale(): Double {
            val uri = (postImportActionsManager.state as? ContentImporterState.Imported)?.uri
            val document = uri?.let { userProfileService.userDocumentsMap.get()[it.id] }
            return when {
                document?.ocrImageScale != null -> document.ocrImageScale!!
                viewport.width > viewport.height -> ML_IMAGE_SCALE_LANDSCAPE
                else -> ML_IMAGE_SCALE_PORTRAIT
            }
        }

        suspend fun getOcrImageQuality(): Int {
            val uri = (postImportActionsManager.state as? ContentImporterState.Imported)?.uri
            val document = uri?.let { userProfileService.userDocumentsMap.get()[it.id] }
            return when {
                document?.ocrImageQuality != null -> document.ocrImageQuality!!
                viewport.width > viewport.height -> ML_IMAGE_COMPRESSION_TARGET_PERCENT_LANDSCAPE
                else -> ML_IMAGE_COMPRESSION_TARGET_PERCENT_PORTRAIT
            }
        }

        // Otherwise we try to run ml Parsing on the page.
        return withTelemetry("getMLParsedAndRawContent") { telemetryEventBuilder ->
            telemetryEventBuilder.addProperty("pageIndex", bookPageDelegate.pageIndex)

            val pdfPageRawExtractedData =
                PDFPageRawExtractedData(
                    viewport = viewport,
                    pageTextContentItems =
                    rawContentTextItems.map { it.toRawPDFPageTextContentItem(viewport) },
                )

            val scale = getOcrImageScale()
            val targetQualityPercent = getOcrImageQuality()

            val imageAsFile = telemetryEventBuilder.addMeasurement(
                measurementName = "BookPageMLParsingWithRemoteOCRService.getImage",
            ) {
                bookPageDelegate.getImage(BookPageRequestOptions(scale = scale)).orThrow()
            }

            val convertedImage = telemetryEventBuilder.addMeasurement(
                measurementName = "BookPageMLParsingWithRemoteOCRService.convertImage",
            ) {
                convertImage(imageAsFile, targetQualityPercent).orThrow()
            }

            val mlParsedPageMeasurement = telemetryEventBuilder.addMeasurement(
                measurementName = "BookPageMLParsingWithRemoteOCRService.parsePage",
            ) {
                try {
                    mlPageParsingWithRemoteOCRService.parsePage(
                        pdfPageRawExtractedData = pdfPageRawExtractedData,
                        pdfPageImage = convertedImage,
                        pageIndex = bookPageDelegate.pageIndex,
                        shouldRunOcrFallback = shouldRunOcrFallback,
                    ).successfully()
                } catch (throwable: Throwable) {
                    Result.Failure(SDKError.OtherException(throwable))
                }
            }

            Log.d(
                telemetryEventBuilder.build().toDiagnosticEvent(
                    "BookPageMLParsingWithRemoteOCRService.runMLExcludingTimeToPreparePayload",
                ),
            )

            val mlParsedPage = when (mlParsedPageMeasurement) {
                is Result.Failure -> return@withTelemetry mlParsedPageMeasurement
                is Result.Success -> mlParsedPageMeasurement.value
            }

            val mpPageParsedContentViewport = mlParsedPage.ocrResult?.imageDimensions ?: viewport

            // If we already performed ML page parsing, even when a cancellation is requested, still cache the result,
            // so we don't have to redo it the next time.
            withContext(NonCancellable) {
                postImportActionsManager.queueTaskAfterImport { uri ->
                    mlParsedPage.parsedContent?.run {
                        platformMLParsedBookPageService.addMLParsedPage(
                            bookId = uri.id,
                            pageIndex = bookPageDelegate.pageIndex,
                            labeledPageTextGroup = labeledPageTextGroups,
                            viewport = mpPageParsedContentViewport,
                            generatedBySoftwareVersion = generatedBySoftwareVersion,
                        ).onFailure {
                            Log.e(
                                DiagnosticEvent(
                                    message = "BookPageMLParsingWithRemoteOCRService.getMlParsedPageContent:" +
                                        " Failed to store ml parsed result.",
                                    error = ErrorInfoForDiagnostics(it.errorNative),
                                    properties = SdkBoundaryMap.of(
                                        "pageIndex" to bookPageDelegate.pageIndex,
                                    ),
                                    sourceAreaId = "BookPageMLParsingWithRemoteOCRService.getMLParsedAndRawContent",
                                ),
                            )
                        }
                    }
                }
                val textContentItems = mlParsedPage.ocrResult?.let {
                    val ocrFile = OCRFile(it, imageAsFile)
                    val localPage = LocalPage(ocrFile)
                    val scannedBookBookPage = ScannedBookBookPage(bookPageDelegate.pageIndex, localPage)
                    postImportActionsManager.queueTaskAfterImport { uri ->
                        scannedBookService.addPageForOcrFallback(uri.id, scannedBookBookPage.pageIndex, it)
                            .onFailure { e ->
                                Log.w(
                                    DiagnosticEvent(
                                        message = "BookPageMLParsingWithRemoteOCRService.getMLParsedAndRawContent:" +
                                            " Failed to store OCR result.",
                                        error = ErrorInfoForDiagnostics(e.errorNative),
                                        properties = SdkBoundaryMap.of(
                                            "pageIndex" to scannedBookBookPage.pageIndex,
                                        ),
                                        sourceAreaId = "BookPageMLParsingWithRemoteOCRService.getMLParsedAndRawContent",
                                    ),
                                )
                            }
                    }
                    scannedBookBookPage.getRawTextContentItems().toNullable()
                }

                when {
                    mlParsedPage.parsedContent != null -> {
                        ParsedPageContentOrRawTextItems.MLParsedPageContent(
                            ParsedPageContent(
                                mlParsedPage.parsedContent.labeledPageTextGroups.toParsedBookPageGroups(
                                    pageIndex = bookPageDelegate.pageIndex,
                                    viewport = mpPageParsedContentViewport,
                                    textSourceType = if (shouldRunOcrFallback) {
                                        TextSourceType.IMAGE
                                    } else {
                                        TextSourceType.DIGITAL_TEXT
                                    },
                                ),
                            ),
                        ).successfully()
                    }

                    textContentItems != null -> {
                        ParsedPageContentOrRawTextItems.RawTextContentDerivedFromServerOCR(textContentItems)
                            .successfully()
                    }

                    else -> {
                        Result.Failure(
                            SDKError.OtherMessage(
                                "BookPageMLParsingWithRemoteOCRService.getMLParsedAndRawContent" +
                                    " - Error while getting ML-Parsed/OCR content.",
                            ),
                        )
                    }
                }
            }
        }
    }

    private fun List<LabeledPageTextGroup>.toParsedBookPageGroups(
        pageIndex: Int,
        viewport: Viewport,
        textSourceType: TextSourceType,
    ): List<ParsedBookPageTextGroup> {
        var prevIndex = 0
        val pageElement = ContentElementReference.forRoot().getChild(pageIndex)
        val result =
            map {
                ParsedBookPageTextGroup(
                    textGroupType = it.type,
                    it.labeledPageTextItems.map { item ->
                        val range = Pair(prevIndex, prevIndex + item.text.length)
                        val textElementContentSlice = TextElementContentSlice(pageElement, range, item.text)
                        val textEnrichment =
                            when (item.type) {
                                TextItemType.Superscript -> TextEnrichment.Superscript
                                TextItemType.Subscript -> TextEnrichment.Subscript
                                TextItemType.DropCap -> TextEnrichment.DropCap
                                is TextItemType.Unknown -> null // ignore unknown TextItemType
                            }?.let {
                                textElementContentSlice.withMetadata(
                                    textElementContentSlice.metadata.copy(
                                        textEnrichments = textElementContentSlice.metadata.textEnrichments + it,
                                    ),
                                )
                            } ?: textElementContentSlice

                        prevIndex = range.second
                        BookPageTextContentItem(
                            type = item.type,
                            text = textEnrichment,
                            box = item.box.toBoundingBox().normalize(viewport),
                            fontFamily = null,
                            textSourceType = textSourceType,
                        )
                    },
                )
            }
        return result
    }
}

// internal for testing
internal fun BookPageTextContentItem.toRawPDFPageTextContentItem(viewport: Viewport) =
    RawPDFPageTextContentItem(text.text, normalizedBox.unnormalize(viewport).toBoxCoordinates(), fontFamily)

private fun PlatformMLParsedBookPageService.MLParsedPageViewPort.toViewport() = Viewport(width.toInt(), height.toInt())

@Serializable
internal class PDFPageRawExtractedData(
    val viewport: Viewport,
    val pageTextContentItems: List<RawPDFPageTextContentItem>,
)

@Serializable
internal class RawPDFPageTextContentItem(
    val text: String,
    val box: BoxCoordinates,
    val fontFamily: String?,
)

internal data class ParsedBookPageTextGroup(
    val textGroupType: TextGroupType,
    val labeledBookPageTextContentItems: List<BookPageTextContentItem>,
)

internal sealed class ParsedPageContentOrRawTextItems {
    class MLParsedPageContent(
        val parsedPageContent: ParsedPageContent,
    ) : ParsedPageContentOrRawTextItems()

    class RawTextContentDerivedFromServerOCR(
        val rawTextContentItems: List<BookPageTextContentItem>,
    ) : ParsedPageContentOrRawTextItems()
}
