package com.speechify.client.api.services.library.models

import com.speechify.client.api.util.MimeType
import kotlin.js.JsExport

/**
 * The Content Type is primarily for display purposes, to convey to users the type of content that a Content Item
 * refers to.
 */
@JsExport
enum class ContentType(val type: String) {
    PDF("pdf"),
    HTML("html"),
    DOCX("docx"),
    TXT("txt"),
    SCAN("scan"),
    EPUB("epub"),
    SPEECHIFY_BOOK("speechify-book"),
    ;

    companion object {
        fun fromItemRecordType(itemRecordType: String?): ContentType {
            if (itemRecordType == "SCANNEDBOOK") return SCAN

            if (itemRecordType == null ||
                !RecordType.values().map { r -> r.toString() }.contains(itemRecordType)
            ) {
                return TXT
            }

            return when (enumValueOf<RecordType>(itemRecordType)) {
                RecordType.FILE -> PDF
                RecordType.PDF -> PDF
                RecordType.SCAN -> SCAN
                RecordType.WEB -> HTML
                RecordType.EPUB -> EPUB
                RecordType.SPEECHIFY_BOOK -> SPEECHIFY_BOOK
                else -> TXT
            }
        }

        fun fromMimeType(mimeType: MimeType?): ContentType? {
            return when (mimeType?.typeSubtype) {
                "application/pdf" -> PDF
                "application/epub+zip" -> EPUB
                "text/html" -> HTML
                "text/plain", "text/markdown" -> TXT
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" -> DOCX
                else -> null
            }
        }
    }
}
