package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ContentTextUtils
import com.speechify.client.api.content.FillerContentSlice
import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.hasEnrichment
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.TextSourceType
import com.speechify.client.api.util.images.verticalDistanceOrOverlapTo
import com.speechify.client.internal.util.eqWithTolerance
import kotlin.math.pow

/**
 * Some approximated statistics about the content of a page. Used as the base for identification of the different types
 * of blocks
 */
internal data class LineStats(
    val usualLineDistance: Double,
    val usualLineHeight: Double,
    val usualLineLeft: Double,
    val usualLineRight: Double,
    val usualLineWidth: Double,
    val numberOfLines: Int,
) {
    companion object {
        /**
         * Calculates the line statistics of a page from its lines. This function uses a simple clustering algorithm
         * to find the most common "sizes" of a page.
         */
        fun of(lines: Iterable<Line>): LineStats {
            val lineDistances = mutableListOf<Double>()
            val lineHeights = mutableListOf<Double>()
            val lineLefts = mutableListOf<Double>()
            val lineRights = mutableListOf<Double>()
            val lineWidths = mutableListOf<Double>()

            var prev: Line? = null
            for (line in lines) {
                if (prev != null) {
                    lineDistances.add(prev.normalizedBox verticalDistanceOrOverlapTo line.normalizedBox)
                }
                prev = line
                lineHeights.add(line.normalizedBox.height)
                lineLefts.add(line.normalizedBox.left)
                lineRights.add(line.normalizedBox.right)
                lineWidths.add(line.normalizedBox.width)
            }

            fun List<Double>.mostCommonKMean(k: Int = 7 /* lucky number */): Double =
                kMeans(k).maxByOrNull { it.count }?.average ?: 0.0

            return LineStats(
                usualLineDistance = lineDistances.mostCommonKMean(),
                usualLineHeight = lineHeights.mostCommonKMean(),
                usualLineLeft = lineLefts.mostCommonKMean(),
                usualLineRight = lineRights.mostCommonKMean(),
                usualLineWidth = lineWidths.mostCommonKMean(),
                numberOfLines = lineHeights.size,
            )
        }

        private data class Cluster(
            var average: Double,
            var count: Int,
        )

        private data class DataPoint(
            val value: Double,
            var cluster: Int,
        )

        private fun calculateNearest(point: DataPoint, clusters: List<Cluster>): Int {
            return clusters
                .asSequence()
                .mapIndexed { i, it -> i to (it.average - point.value).pow(2) }
                .minBy { it.second }
                .first
        }

        // clustering algorithm copied from: https://github.com/TheAlgorithms/C/blob/master/machine_learning/k_means_clustering.c
        // original had a small bug where it sometimes divided by 0, this is fixed in our implementation.
        private fun List<Double>.kMeans(suggestedK: Int): List<Cluster> = when {
            suggestedK <= 1 -> listOf(Cluster(this.average(), count = this.size))
            suggestedK >= this.size -> {
                val roundFactor = 10_000
                val values = hashMapOf<Int, Int>()
                for (v in this) {
                    val rounded = (v * roundFactor).toInt()
                    values[rounded] = values[rounded]?.plus(1) ?: 1
                }
                values.map { (k, v) -> Cluster(average = (k.toDouble() / roundFactor.toDouble()), count = v) }
            }
            else -> {
                // if k is larger than half the number of values it will just produce a useless result,
                // so we override the passed in k with a more sensible one
                val k = if (suggestedK > this.size / 2) this.size / 2 else suggestedK

                val dataPoints = this.mapIndexed { i, it -> DataPoint(value = it, cluster = i % k) }
                val clusters = (0 until k).map { Cluster(average = .0, count = 0) }
                val minAcceptedError = this.size / 10_000
                // it's not possible to prove that the following loop terminates, so we bound its execution
                // by an arbitrary amount of iterations. The result should be "correct" in the sense that this
                // is an approximation so there is no wrong result, only bad uses user experience
                var attempts = 10_000
                do {
                    for (c in clusters) {
                        c.average = 0.0
                        c.count = 0
                    }
                    for (d in dataPoints) {
                        val c = d.cluster
                        with(clusters[c]) {
                            average += d.value
                            count++
                        }
                    }
                    for (c in clusters) {
                        if (c.count != 0) c.average /= c.count
                    }
                    var changed = 0
                    for (d in dataPoints) {
                        val t = calculateNearest(d, clusters)
                        if (t != d.cluster) {
                            changed++
                            d.cluster = t
                        }
                    }
                } while (changed > minAcceptedError && attempts-- > 0)
                clusters
            }
        }

        private fun showDataPoints(dataPoints: List<DataPoint>, k: Int) {
            for (i in 0 until k) {
                print("cluster $i {")
                dataPoints.filter { it.cluster == i }.forEach { print("  ${it.value}") }
                println(" }\n == ${dataPoints.filter { it.cluster == i }.map { it.value }.average()}")
            }
        }
    }
}

private val PUNCTUATION = charArrayOf('.', ',', ';', ':', '?', '!')
private fun Char.isPunctuation() = PUNCTUATION.contains(this)

private val LINE_BREAK_HYPHEN = charArrayOf('-', '\u2010', '\u00AD')
fun Char.isLineBreakHyphen() = LINE_BREAK_HYPHEN.contains(this)

private val BULLET_POINT_CHARACTERS = charArrayOf('-', '\u2022', '\u00B7', '➢', '○', '>')
fun Char.isBulletPoint() = BULLET_POINT_CHARACTERS.contains(this)

/**
 * Creates an instance of [ContentText] from a [LineGroup], inserting inferred whitespace between words/sentences.
 *
 * **Note**: inserted whitespace depends on the original [BookPageTextContentItem]s, if they were one per word, then
 * whitespace will be inserted between words. If they were one per sentence, then whitespace will only be inserted
 * between sentences, preserving the original "in-sentence" whitespace.
 */
internal fun MutableList<Line>.joinLinesApplyingCleanupHeuristics(): ContentText {
    fun Char.isTextChar() = !isWhitespace() && !isPunctuation()
    fun String.punctuationFollowedByTextChar() = asSequence()
        .dropWhile { c -> c.isPunctuation() }
        .firstOrNull()
        ?.isTextChar() == true

    fun String.getEndingHyphenIndexOrNull(): Int? {
        // We trim whitespaces at the end since some PDF adapters introduce those.
        val trimmedString = this.trimEnd()
        val hyphenIndex = trimmedString.indexOfLast { it.isLineBreakHyphen() }

        // Only valid if the hyphen is actually the last char after trimming.
        if (hyphenIndex != trimmedString.length - 1) {
            return null
        }
        return hyphenIndex
    }

    fun String.isCitation(): Boolean {
        if (this.isEmpty()) return false

        if (this.length == 1 && this[0].isDigit()) return true

        if (this.length > 1 && (this.startsWith("[") && this.endsWith("]")) ||
            (this.startsWith("(") && this.endsWith(")")) ||
            (this.startsWith("<") && this.endsWith(">"))
        ) {
            val content = this.substring(1, this.length - 1)
            return content.all { it.isDigit() }
        }

        return this.all { it.isDigit() }
    }

    fun startsWithNumber(text: String): Boolean {
        val indexOfWhitespace = text.trimStart().indexOf(' ', 0)

        return if (indexOfWhitespace == -1) {
            text.all { it.isDigit() }
        } else {
            text.substring(0, indexOfWhitespace).all { it.isDigit() }
        }
    }

    /**
     * Determines the maximum distance for which we assume that two [BookPageTextContentItem]s are touching.
     * When the text is extracted from the content itself (source type is not [TextSourceType.IMAGE]), it is always
     * 0.0012. However, if we have the text extracted using OCR, we try to detect if we have a citation right after
     * a punctuation mark ([.] or [,]), since these are common citation patterns. If we do, we increase
     * the distance to 0.0033. This is needed since OCR can sometimes provide bigger distances in these cases
     */
    fun getTouchingDistance(currentChunk: BookPageTextContentItem, nextChunk: BookPageTextContentItem): Double {
        // we only increase this value if we have a possible citation in text extracted using OCR
        if (nextChunk.textSourceType == TextSourceType.IMAGE) {
            val currentChunkText = currentChunk.text.text
            val trimmedCurrentChunk = currentChunkText.trimEnd()
            val nextChunkText = nextChunk.text.text
            if (trimmedCurrentChunk.isNotEmpty() &&
                trimmedCurrentChunk.last() in setOf('.', ',') &&
                startsWithNumber(nextChunkText)
            ) {
                // Value was determined based on tests done as part of
                // https://linear.app/speechify-inc/issue/CXP-4203/some-citations-are-not-skipped
                return 0.0033
            }
        }

        // threshold changed from 0.0015 to 0.0012 due to regression caught by our web integration tests
        // before: https://www.loom.com/share/6dbc5dca70204b11a68b19649e6a0a39?sid=4e4a3ea7-7e7b-4b65-b54f-06994d2983eb
        // after: https://www.loom.com/share/299263a307524b10b0cdd25539b6247c
        return 0.0012
    }

    return this.asSequence()
        .windowed(2, partialWindows = true) lines@{ lines ->
            val currentLine = lines.first()
            val nextLine = lines.getOrNull(1)

            return@lines currentLine.chunks.asSequence().windowed(size = 2, partialWindows = true) {
                val currentChunk = it.first()
                val nextChunk = it.getOrNull(1)

                // For a drop cap we don't add the whitespace since it should be read as part of the next paragraph.
                if (currentChunk.text.hasEnrichment(TextEnrichment.DropCap)) {
                    return@windowed currentChunk.text.slices.asSequence()
                }

                // If this is the last chunk of the current line, there is another line in the paragraph, and
                // the current line ends with a hyphen treat it as a word that was broken and strip the hyphen.
                val hyphenIndex = currentChunk.text.text.getEndingHyphenIndexOrNull()
                if (nextChunk == null &&
                    nextLine !== null &&
                    hyphenIndex != null
                ) {
                    // This is a word that has been hyphenated across two lines. Remove the hyphen and don't put a
                    // white space in the output.
                    return@windowed currentChunk.text.slice(0, hyphenIndex).slices.asSequence()
                } else if (nextChunk == null) {
                    return@windowed currentChunk.text.slices.asSequence() +
                        FillerContentSlice.createSuffixFillerForText(currentChunk.text, " ")
                }

                // Strategy for inferring whitespace
                //
                // prev | next | what to return
                // ---- | ---- | ---
                // null |  *   | no space [1]
                //  *   | null | no space
                //  c/! |  !+  | no space
                //  s   |  *   | no space
                //  *   |  s   | no space
                //  c/! |  !*c | space    [2]
                //
                // Legend:
                //  null => null reference
                //  *    => anything at all
                //  c    => non whitespace, non punctuation
                //  s    => whitespace
                //  !    => punctuation
                //
                //
                // After this a check for bounding box proximity is also done, to make sure words that have been
                // split into multiple chunks are joined back together

                val currentChunkLastChar = currentChunk.text.text.lastOrNull()
                    ?: return@windowed currentChunk.text.slices.asSequence() // [1]

                val touchingDistance = getTouchingDistance(currentChunk, nextChunk)
                if (!currentChunkLastChar.isWhitespace() &&
                    nextChunk.text.text.punctuationFollowedByTextChar()
                ) { // [2]
                    // this assumes normalization of the bounding boxes
                    //
                    val isEndOfCurrentChunkBasicallyTouchingTheStartOfTheNextOne = when (currentLine.textDirection) {
                        null, TextDirection.LeftToRight ->
                            currentChunk.normalizedBox.right > nextChunk.normalizedBox.left ||
                                currentChunk.normalizedBox.right.eqWithTolerance(
                                    nextChunk.normalizedBox.left,
                                    touchingDistance,
                                )
                        TextDirection.RightToLeft ->
                            currentChunk.normalizedBox.left < nextChunk.normalizedBox.right ||
                                currentChunk.normalizedBox.left.eqWithTolerance(
                                    nextChunk.normalizedBox.right,
                                    touchingDistance,
                                )
                    }
                    // If we have OCR images, we always introduce a space if the chunks are not touching
                    // and we don't have a citation
                    if (currentChunk.textSourceType == TextSourceType.IMAGE &&
                        nextChunk.textSourceType == TextSourceType.IMAGE
                    ) {
                        if (isEndOfCurrentChunkBasicallyTouchingTheStartOfTheNextOne &&
                            nextChunk.text.text.isCitation()
                        ) {
                            currentChunk.text.slices.asSequence()
                        } else {
                            currentChunk.text.slices.asSequence() +
                                FillerContentSlice.createSuffixFillerForText(currentChunk.text, " ")
                        }
                    } else {
                        if (isEndOfCurrentChunkBasicallyTouchingTheStartOfTheNextOne) {
                            currentChunk.text.slices.asSequence()
                        } else {
                            currentChunk.text.slices.asSequence() +
                                FillerContentSlice.createSuffixFillerForText(currentChunk.text, " ")
                        }
                    }
                } else {
                    currentChunk.text.slices.asSequence()
                }
            }
        }
        .flatten()
        .flatten()
        .let(ContentTextUtils::concat)
}
