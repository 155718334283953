package com.speechify.client.internal.util.intentSyntax

/**
 * Typical uses are `update` operations (like in [kotlinx.coroutines.flow.update]), which allow to set a value, given
 * the previous one.
 * This class allows to unambiguously specify whether a value should be updated or not.
 * Especially useful when the type held is nullable, and there are cases where the value needs to be set to `null` in
 * some cases.
 */
internal sealed class ValueUpdateChoice<out T> {
    class Set<T>(val value: T) : ValueUpdateChoice<T>()
    object Keep : ValueUpdateChoice<Nothing>()
}

internal fun <T> T.asValueUpdateChoice(): ValueUpdateChoice<T> =
    ValueUpdateChoice.Set(this)

internal fun <T> T?.asValueUpdateChoiceWithNullAsKeep(): ValueUpdateChoice<T> =
    this?.let { ValueUpdateChoice.Set(it) } ?: ValueUpdateChoice.Keep
