package com.speechify.client.internal.util.extensions.collections

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first

internal inline fun <reified R : Any> Sequence<*>.firstInstanceOf(): R? {
    return firstOrNull { it is R } as? R
}

internal inline fun <reified R : Any> Iterable<*>.firstInstanceOf(): R? {
    return firstOrNull { it is R } as? R
}

internal inline fun <reified R : Any> Array<*>.firstInstanceOf(): R? {
    return firstOrNull { it is R } as? R
}

internal suspend inline fun <reified R : Any> Flow<*>.firstInstanceOf(): R =
    first { it is R } as R
