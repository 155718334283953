package com.speechify.client.api.audio

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.LanguageIdentity
import com.speechify.client.api.content.TextEnrichment
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.speech.SpeechSentence
import com.speechify.client.api.content.view.speech.SpeechTextPatterns
import com.speechify.client.helpers.content.speech.ContentTransformOptions
import com.speechify.client.internal.util.collections.flows.ExternalStateChangesFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.flow
import kotlinx.serialization.Transient
import kotlin.js.JsExport

internal typealias SentenceTransformer =
    (inputSentence: SpeechSentence, languageIdentity: LanguageIdentity) -> SpeechSentence?

internal class AudioControllerOptions(
    val speedInWordsPerMinute: Int,
    val bufferOnInit: Boolean = true,
    val startingCursor: ContentCursor? = null,
    internal val contentTransformOptions: ContentTransformOptions = TransformNothingOptions, // Default for tests,
    internal val utteranceBufferSizeOption: UtteranceBufferSizeOption =
        DefaultUtteranceBufferSizeOption, // Default for tests
)

/**
 * Represents preferences for transformations to be applied on the text just before pronunciation (so, not affecting the
 * way the text is returned for display, but affecting the pronunciation).
 *
 * This can be used to apply custom rules for pronunciation of content.
 */
@JsExport
class PreSpeechTransformOptions(
    initialShouldSkipBraces: Boolean,
    initialShouldSkipCitations: Boolean,
    initialShouldSkipParentheses: Boolean,
    initialShouldSkipBrackets: Boolean,
    initialShouldSkipUrls: Boolean,
    customSentenceTransformer: SentenceTransformer? = null,
) {
    internal val currentSkippingSettings = MutableStateFlow(
        ContentSkippingSettings(
            shouldSkipBraces = initialShouldSkipBraces,
            shouldSkipCitations = initialShouldSkipCitations,
            shouldSkipParentheses = initialShouldSkipParentheses,
            shouldSkipBrackets = initialShouldSkipBrackets,
            shouldSkipUrls = initialShouldSkipUrls,
            customSentenceTransformer = customSentenceTransformer,
        ),
    )

    /**
     * Enables / disables  skipping content between curly braces.
     * E.g. "Hello {world}" -> "Hello"
     */
    var shouldSkipBraces: Boolean
        get() = currentSkippingSettings.value.shouldSkipBraces
        set(value) {
            currentSkippingSettings.value = currentSkippingSettings.value.copy(shouldSkipBraces = value)
        }

    /**
     * Enables / disables  skipping content that looks like citations.
     *
     * handles different styles of in text citations, see [here](https://en.wikipedia.org/wiki/Vancouver_system) and [here](https://www.bibguru.com/blog/citation-styles-numbers-in-brackets/)
     * - superscript citations, typically appearing at the end of comma or full stop e.g.
     * "Researchers have also proposed mathematical definitions of bias,11–13
     * describing methods5 for measuring bias,14–17 and offering approaches for
     * mitigating bias.15,18,19"
     * - square bracket number citations, typically appearing before comma or full stop e.g.
     * "The suggestion was first put noted in [1]."
     * "Jones [2] has stated that......."
     * "Recent studies [5], [7], [9], [11] have also described..."
     * "Recent studies [5-11] have also described..."
     * - parentheses number citations
     * "The suggestion was first put noted in (1)."
     * "Jones (2) has stated that......."
     * "Recent studies (5), (7), (9), (11) have also described..."
     * "Recent studies (5-11) have also described..."
     * - parentheses with name and date, e.g. "(Smallbone & Quinton, 2004)"
     */
    var shouldSkipCitations: Boolean
        get() = currentSkippingSettings.value.shouldSkipCitations
        set(value) {
            currentSkippingSettings.value = currentSkippingSettings.value.copy(shouldSkipCitations = value)
        }

    /**
     * Enables / disables  skipping content between parentheses.
     * E.g. "Hello (world)" -> "Hello"
     */
    var shouldSkipParentheses: Boolean
        get() = currentSkippingSettings.value.shouldSkipParentheses
        set(value) {
            currentSkippingSettings.value = currentSkippingSettings.value.copy(shouldSkipParentheses = value)
        }

    /**
     * Enables / disables  skipping content between square brackets.
     * ```
     * E.g. "Hello [world]" -> "Hello"
     * ```
     */
    var shouldSkipBrackets: Boolean
        get() = currentSkippingSettings.value.shouldSkipBrackets
        set(value) {
            currentSkippingSettings.value = currentSkippingSettings.value.copy(shouldSkipBrackets = value)
        }

    /**
     * Enables / disables skipping content that looks like URLs.
     *
     * E.g. "Hello http://www.example.org" -> "Hello"
     *
     */
    var shouldSkipUrls: Boolean
        get() = currentSkippingSettings.value.shouldSkipUrls
        set(value) {
            currentSkippingSettings.value = currentSkippingSettings.value.copy(shouldSkipUrls = value)
        }

    @Suppress("NON_EXPORTABLE_TYPE") // Only used internally
    @kotlinx.serialization.Serializable
    internal data class ContentSkippingSettings(
        val shouldSkipBraces: Boolean,
        val shouldSkipCitations: Boolean,
        val shouldSkipParentheses: Boolean,
        val shouldSkipBrackets: Boolean,
        val shouldSkipUrls: Boolean,
        // Serializer will throw an exception if we try to serialize ContentSkippingSettings with a customSentenceTransformer.
        @Transient
        val customSentenceTransformer: SentenceTransformer? = null,
    ) {
        fun toSentenceTransformers(): List<SentenceTransformer> {
            val transformers = mutableListOf<SentenceTransformer>()
            if (customSentenceTransformer != null) {
                transformers.add(customSentenceTransformer)
            }
            if (shouldSkipBraces) {
                transformers.add(skipBracesSentenceTransformer)
            }
            if (shouldSkipParentheses) {
                transformers.add(skipParenthesesSentenceTransformer)
            }
            if (shouldSkipBrackets) {
                transformers.add(skipBracketsSentenceTransformer)
            }
            if (shouldSkipCitations) {
                transformers.add(skipCitationsSentenceTransformer)
            }
            if (shouldSkipUrls) {
                transformers.add(skipUrlsSentenceTransformer)
            }

            return transformers.toList()
        }
    }

    companion object {

        private val skipBracesSentenceTransformer: SentenceTransformer = { inputSentence, _ ->
            inputSentence.replaceMatchesWithEmpty(SpeechTextPatterns.Braces.patterns)
        }

        private val skipCitationsSentenceTransformer: SentenceTransformer = { inputSentence, _ ->
            val sentenceWithoutSuperScript =
                inputSentence.withRemovedContentOfMetadata(TextEnrichment.Superscript)
            sentenceWithoutSuperScript?.replaceMatchesWithEmpty(SpeechTextPatterns.InTextCitations.patterns)
        }

        private val skipParenthesesSentenceTransformer: SentenceTransformer = { inputSentence, _ ->
            inputSentence.replaceMatchesWithEmpty(SpeechTextPatterns.RoundBrackets.patterns)
        }

        private val skipBracketsSentenceTransformer: SentenceTransformer = { inputSentence, _ ->
            inputSentence.replaceMatchesWithEmpty(SpeechTextPatterns.SquareBrackets.patterns)
        }

        private val skipUrlsSentenceTransformer: SentenceTransformer = { inputSentence, _ ->
            inputSentence.replaceMatchesWithEmpty(SpeechTextPatterns.Urls.patterns)
        }

        private fun SpeechSentence.replaceMatchesWithEmpty(
            patterns: Array<String>,
        ): SpeechSentence? {
            return patterns.fold<String, SpeechSentence?>(this) { sentence, pattern ->
                sentence?.replaceAll(pattern) { "" }
            }
        }
    }
}

private object TransformNothingOptions : ContentTransformOptions {
    override val shouldUseRichBlocksParsingForHtmlContentFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val shouldUseRichBlocksParsingForEpubContentFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val shouldSkipHeadersFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val shouldSkipFootersFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val shouldSkipFootnotesFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val shouldSkipCaptionsFlow: StateFlow<Boolean>
        get() = MutableStateFlow(false)
    override val mlParsingModeFlow: StateFlow<MLParsingMode> = MutableStateFlow(MLParsingMode.ForceDisable)
    override val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy> =
        MutableStateFlow(OcrFallbackStrategy.ForceDisable)
    override val preSpeechTransformationsFlow = MutableStateFlow(
        PreSpeechTransformOptions(
            initialShouldSkipBraces = false,
            initialShouldSkipCitations = false,
            initialShouldSkipParentheses = false,
            initialShouldSkipBrackets = false,
            initialShouldSkipUrls = false,
            customSentenceTransformer = null,
        ),
    )
    override val contentTransformOptionsChanged: ExternalStateChangesFlow = flow { }
}

private object DefaultUtteranceBufferSizeOption : UtteranceBufferSizeOption {
    override val utteranceBufferSizeFlow: StateFlow<Int> = MutableStateFlow(20)
}
