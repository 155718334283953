package com.speechify.client.bundlers.reading.dynamic

import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.embedded.EmbeddedReadingBundleBase
import com.speechify.client.helpers.content.standard.dynamic.DynamicStandardView
import com.speechify.client.helpers.content.standard.dynamic.contentProviders.MidContentStartInfo
import com.speechify.client.helpers.content.standard.streamable.StreamableContentChunksBuilder
import com.speechify.client.helpers.ui.controls.PlaybackControls
import kotlin.js.JsExport

@JsExport
class DynamicStandardViewReadingBundle(
    playbackControls: PlaybackControls,
    override val contentBundle: ContentBundle.StandardBundle<DynamicStandardView>,
) : EmbeddedReadingBundleBase<ContentBundle.StandardBundle<DynamicStandardView>>(
    playbackControls = playbackControls,
) {
    init {
        check(playbackControls.listeningBundle.contentBundle === contentBundle) {
            "The provided DynamicStandardView is not the one used by the ListeningBundle"
        }
    }

    /**
     * Use this to implement 'play from here' functionality.
     * See also [seekFromContent] for a version which doesn't start playing if not playing currently, but merely prepares
     * for play.
     */
    fun playFromContent(
        /**
         *  Provides a builder to build the content to start from, and the part of the chunk that isn't going to be
         *  read (for indexing and to give the ability to rewind to it).
         *  @see [MidContentStartInfo] to learn what parts need to be provided and how.
         */
        getChunkPartsForStart: (builder: StreamableContentChunksBuilder) -> MidContentStartInfo,
        /**
         * The index of the chunk containing the content returned by [getChunkPartsForStart].
         */
        chunkIndex: Int,
        estimatedChunksBefore: Int?,
        estimatedChunksAfter: Int?,
        resetIndexToOnlyThisInfo: Boolean = false,
    ) {
        listeningBundle.audioController.playFromCursor(
            cursor =
            @Suppress(
                /* The Deprecation is only about that member being public */
                "DEPRECATION",
            )
            contentBundle.standardView.addContentAndGetCursorToStartFromIt(
                getChunkPartsForStart = getChunkPartsForStart,
                chunkIndex = chunkIndex,
                estimatedChunksBefore = estimatedChunksBefore,
                estimatedChunksAfter = estimatedChunksAfter,
                resetIndexToOnlyThisInfo = resetIndexToOnlyThisInfo,
            ),
        )
    }

    /**
     * Similar to [playFromContent], but does not start playing if not playing currently (still, the playback will
     * buffer the content to make it ready for playing, [PlaybackControls]'s progress position updated to the new place,
     * and any content changes reflected).
     *
     * This is effectively 'change playback to start from here', which can be useful for bespoke experiences, where the
     * user changes the content while not playing, and it makes sense for the player to already prepare to play from the
     * new place.
     */
    fun seekFromContent(
        /**
         * See argument of the same name in [playFromContent].
         */
        getChunkPartsForStart: (builder: StreamableContentChunksBuilder) -> MidContentStartInfo,
        /**
         * See argument of the same name in [playFromContent].
         */
        chunkIndex: Int,
        estimatedChunksBefore: Int?,
        estimatedChunksAfter: Int?,
        resetIndexToOnlyThisInfo: Boolean = false,
    ) {
        listeningBundle.audioController.seekToCursorWithoutImplyingUserInteraction(
            cursor =
            @Suppress(
                /* The Deprecation is only about that member being public */
                "DEPRECATION",
            )
            contentBundle.standardView.addContentAndGetCursorToStartFromIt(
                getChunkPartsForStart = getChunkPartsForStart,
                chunkIndex = chunkIndex,
                estimatedChunksBefore = estimatedChunksBefore,
                estimatedChunksAfter = estimatedChunksAfter,
                resetIndexToOnlyThisInfo = resetIndexToOnlyThisInfo,
            ),
        )
    }
}
