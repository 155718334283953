package com.speechify.client.api.audio

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
enum class AudioMediaFormat {
    @SerialName("ogg")
    OGG,

    @SerialName("mp3")
    MP3,
}
