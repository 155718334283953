package com.speechify.client.api.adapters.html

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.HtmlFileFromString
import com.speechify.client.internal.util.encodeForDoubleQuotedXmlAttributeValue
import com.speechify.client.internal.util.encodeToXmlTextNode
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * Component responsible for efficient parsing of HTML into DOM tree.
 */
@JsExport
abstract class HTMLParser {

    protected abstract fun parseAsDOM(
        file: File,
        callback: Callback<DOMElement>,
    )

    @JsExport.Ignore
    internal open suspend fun coParseAsDOM(file: File): Result<DOMElement> =
        suspendCoroutine {
            this.parseAsDOM(file, it::resume)
        }
}

/**
 * Base class for the abstraction over the HTML DOM tree to decouple from the actual objects returned by parsers, and to only expose what the SDK needs.
 *
 * An instance of this represents a single concrete [DOM Node](https://developer.mozilla.org/en-US/docs/Web/API/Node) in the DOM tree.
 *
 * NOTE: The sub-interfaces are not 'all possible DOM node types', but are limited to only the types of nodes relevant for the SDK.
 */
@JsExport
abstract class DOMNode {
    /**
     * The object that SDK should provide to the consumer when returning structures that correspond to this node.
     */
    abstract val rawRefObject: Any
}

/**
 * Represents a single concrete [DOM Element](https://developer.mozilla.org/en-US/docs/Web/API/Element) in the DOM tree.
 */
@JsExport
abstract class DOMElement : DOMNode() {
    /**
     * The [tag name](https://developer.mozilla.org/en-US/docs/Web/API/Element/tagName) of the element
     */
    abstract val tagName: String

    /**
     * The [childNodes](https://developer.mozilla.org/en-US/docs/Web/API/Node/childNodes) of the element, filtered
     * to only the types of node present in the SDK's [DOMNode] hierarchy.
     */
    abstract val children: Array<DOMNode>

    /**
     * The [attributes](https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes) of the element
     */
    abstract val attributes: Array<BoundaryPair<String, String>>
}

/**
 * Represents a single concrete [Text Node](https://developer.mozilla.org/en-US/docs/Web/API/Text) in the DOM tree.
 */
@JsExport
abstract class DOMTextNode : DOMNode() {
    /**
     * The [textContent](https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent) of the node
     */
    abstract val text: String
}

internal fun DOMElement.serializeHtmlToFile(): File =
    HtmlFileFromString(
        htmlString = serializeToHtml()
            .joinToString(
                separator = "",
            ),
    )

private fun DOMElement.serializeToHtml(): Sequence<String> = sequence {
    yield("<$tagName")
    for ((attributeName, attributeValue) in attributes) {
        yield(" ")
        yield(attributeName)
        /* Don't produce `=""` for [Boolean attributes](https://developer.mozilla.org/en-US/docs/Glossary/Boolean/HTML)
         * Note that this is how Browser dev-tools behave: adding `<div someAttr="">` will turn it into `<div someAttr>`
         */
        if (attributeValue.isNotEmpty()) {
            yield("=\"")
            yield(
                attributeValue.encodeForDoubleQuotedXmlAttributeValue(),
            )
            yield("\"")
        }
    }
    yield(">")
    for (child in children) {
        when (child) {
            is DOMElement -> yieldAll(child.serializeToHtml())
            is DOMTextNode -> yield(
                child.text.encodeToXmlTextNode(),
            )
        }
    }
    yield("</$tagName>")
}
