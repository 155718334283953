package com.speechify.client.internal.sync

/**
 * A result for synchronous try-get-value class of methods on objects facilitating deferred values (e.g. anything backed
 * by [kotlinx.coroutines.Deferred]), which allow the [Value] to be any type, even a nullable one.
 */
internal sealed class DeferredTryGetResult<out Value> {
    class Success<Value>(val value: Value) : DeferredTryGetResult<Value>()
    object NotAvailableYet : DeferredTryGetResult<Nothing>()
}
