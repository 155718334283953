// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections.flows

import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.flatMapConcat
import kotlinx.coroutines.flow.map

/**
 * A version of [kotlinx.coroutines.flow.flattenConcat] when the underlying items are not flows but simple synchronous
 * sequences.
 */
@OptIn(FlowPreview::class)
internal fun <T> Flow<Iterable<T>>.flattenConcat() =
    flatMapConcat { it.asFlow() }

internal fun <T, R> Flow<T>.flatMapConcatSync(transform: (value: T) -> Iterable<R>) =
    map(transform)
        .flattenConcat()
