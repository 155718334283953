package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.speechify.client.`internal`.sqldelight.multiplatformsdk.newInstance
import com.speechify.client.`internal`.sqldelight.multiplatformsdk.schema
import kotlin.Unit

public interface Database : SuspendingTransacter {
  public val localListeningProgressQueries: LocalListeningProgressQueries

  public val pendingImportQueries: PendingImportQueries

  public val scannedPageQueries: ScannedPageQueries

  public val voiceCacheQueries: VoiceCacheQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = Database::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      downloadedAudioForItemAdapter: DownloadedAudioForItem.Adapter,
      localListeningProgressAdapter: LocalListeningProgress.Adapter,
      pendingImportAdapter: PendingImport.Adapter,
      scannedPageAdapter: ScannedPage.Adapter,
      sentenceIndexAdapter: SentenceIndex.Adapter,
      synthesisResultAdapter: SynthesisResult.Adapter,
    ): Database = Database::class.newInstance(driver, downloadedAudioForItemAdapter,
        localListeningProgressAdapter, pendingImportAdapter, scannedPageAdapter,
        sentenceIndexAdapter, synthesisResultAdapter)
  }
}
