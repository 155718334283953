package com.speechify.client.helpers.content.standard.book.heuristics.v2

import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.helpers.content.standard.book.heuristics.v2.stages.ParsingPipeline

internal object HeuristicsParserV2 {

    internal fun sort(text: List<BookPageTextContentItem>): List<BookPageTextContentItem> {
        val blocks = ParsingPipeline.startPipeline(text)
        return blocks.flatMap { it.rawTextContentItems }
    }
}
