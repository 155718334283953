package com.speechify.client.api.content.view.book

import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks

/**
 * This represents a basic layer of a book page, embodied as a delegate providing a list of raw
 * [BookPageTextContentItem]s.
 *
 * Refer to [BookPage] and its derived implementations for higher-level functionality that provides a ordered
 * and classified text content.
 */
internal abstract class BookPageDelegate : Destructible {
    abstract val pageIndex: Int
    abstract val start: ContentCursor
    abstract val end: ContentCursor

    abstract fun getMetadata(): BookPageMetadata

    abstract suspend fun getImage(options: BookPageRequestOptions):
        Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>

    /**
     * Retrieves the raw text content items. This content has not been parsed yet but may be preliminarily ordered.
     */
    internal abstract suspend fun getRawTextContentItems(): Result<List<BookPageTextContentItem>>

    internal abstract suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult
}
