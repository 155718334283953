package com.speechify.client.internal.util.extensions.coroutines.job

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job

/**
 * A version of [kotlinx.coroutines.cancelAndJoin] that takes a custom exception message.
 */
internal suspend fun Job.cancelAndJoin(exceptionMessage: String) {
    cancel(
        CancellationException(exceptionMessage),
    )
    join()
}
