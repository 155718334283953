package com.speechify.client.api.content

import kotlin.js.JsExport

@JsExport
sealed class ContentBoundary {
    abstract fun compareTo(other: ContentBoundary): ContentRelativeOrder

    object START : ContentBoundary() {
        override fun compareTo(other: ContentBoundary): ContentRelativeOrder {
            return when (other) {
                is START -> ContentRelativeOrder.EQUAL
                is END -> ContentRelativeOrder.BEFORE
            }
        }
    }

    object END : ContentBoundary() {
        override fun compareTo(other: ContentBoundary): ContentRelativeOrder {
            return when (other) {
                is START -> ContentRelativeOrder.AFTER
                is END -> ContentRelativeOrder.EQUAL
            }
        }
    }

    internal companion object {
        fun fromString(name: String) = when (name) {
            START::class.simpleName -> START
            END::class.simpleName -> END
            else -> throw Exception("Unknown ContentBoundary: $name")
        }
    }

    override fun toString() = this::class.simpleName!!
}
