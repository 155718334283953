package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class SubscriptionRestore {
    data class Apple(
        /** Base64 encoded app store receipt. */
        val receiptInfo: String,
        /**
         * When set to true the restore process will be able to transfer the subscription from the previously assigned user to the current user.
         * When set to false the restore operation will fail if the subscription belongs to a different user.
         *
         * When calling restore from the background this needs to be set to false, only set to true when the restore is triggered by direct user interaction.
         */
        val shouldTransferSubscription: Boolean,
    ) : SubscriptionRestore()
}
