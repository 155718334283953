package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformRestoreAppleSubscriptionResponse(
    val message: String,
    val subscriptionId: String,
    /** True if the supplied receipt is valid (not expired, not refunded), false otherwise. */
    val isValid: Boolean,
)
