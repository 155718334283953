package com.speechify.client.api.services.importing.models

import com.speechify.client.api.services.library.models.FolderReference
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.BoundaryMapOfStringsSerializer
import com.speechify.client.internal.util.boundary.emptyBoundaryMap
import kotlin.js.JsExport

/**
 * A data class used to provide extra information for importing library items
 */
@JsExport
@kotlinx.serialization.Serializable
data class ImportOptions(
    /**
     * The title of the library item to be imported. `null` means that the title will be provided (a placeholder
     * of "Untitled File" or discovered, once implemented).
     */
    val title: String? = null,
    /**
     * The folder ID that the item will reside in — null denotes the root folder
     */
    val parentFolder: FolderReference? = null,

    /**
     * Properties stored along with the imported item for analytics purposes
     */
    @kotlinx.serialization.Serializable(with = BoundaryMapOfStringsSerializer::class)
    val analyticsProperties: BoundaryMap<String> = emptyBoundaryMap(),

    /**
     * The cover image URL of the library item to be imported
     */
    val coverImageUrl: String? = null,
)
