// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

/**
 * Returns `null` when [this] is empty.
 *
 * See also [partition].
 */
internal fun <T> Iterable<T>.partitionToFirstAndRestOrNull(): Pair<T, List<T>>? {
    val iterator = this@partitionToFirstAndRestOrNull.iterator()
    if (!iterator.hasNext()) {
        return null
    } else {
        return iterator.next() to iterator.asSequence().toList()
    }
}
