package com.speechify.client.internal.sync

internal actual class AtomicBool actual constructor(var initialValue: Boolean) {

    actual fun get(): Boolean = initialValue

    actual fun set(value: Boolean) {
        this.initialValue = value
    }

    actual fun toggle() {
        this.initialValue = !this.initialValue
    }

    actual fun compareAndSet(expect: Boolean, set: Boolean): Boolean = if (initialValue == expect) {
        initialValue = set
        true
    } else {
        false
    }
}
