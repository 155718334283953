package com.speechify.client.internal.time

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.double
import kotlinx.serialization.json.jsonPrimitive
import kotlinx.serialization.json.long

typealias Seconds = Int

typealias ISO8601DateString = String

typealias Milliseconds = Long

internal const val MILLIS_TO_NANOS_CONSTANT = 1_000_000L

fun nowInSecondsFromEpoch(): Seconds = DateTime.now().asSeconds()
fun nowInMillisecondsFromEpoch(): Milliseconds = DateTime.now().asMillisecondsLong()

object DateTimeSerializer : KSerializer<DateTime> {
    override val descriptor: SerialDescriptor = JsonElement.serializer().descriptor

    override fun serialize(encoder: Encoder, value: DateTime) {
        // we can't implement this, because we don't know if the record we are serializing to is a firebase timestamp
        // or just the number of seconds.
        // To work around this you have to pick the type manually when defining a type for writing
        throw NotImplementedError("serialization of date time is not allowed")
    }

    override fun deserialize(decoder: Decoder): DateTime {
        return when (val jElement = decoder.decodeSerializableValue(JsonElement.serializer())) {
            is JsonPrimitive -> {
                try {
                    DateTime.fromSeconds(jElement.double)
                } catch (e: NumberFormatException) {
                    try {
                        DateTime.fromSeconds(jElement.long.toInt())
                    } catch (e: NumberFormatException) {
                        throw SerializationException("failed to interpret datetime as seconds", e)
                    }
                }
            }
            is JsonObject -> {
                // The trigger that indexes the firestore document to elasticsearch is using the Firebase Admin SDK,
                // and the Admin SDK serializes the Firebase Timestamp with an underscore prefix { _seconds, _nanoseconds }
                // while the client SDK doesn't.
                // see: https://github.com/firebase/firebase-admin-node/issues/1404,
                // or https://stackoverflow.com/questions/63416614/save-firebase-timestamp-value-using-admin-sdk
                val secondsElement = jElement["seconds"] ?: jElement["_seconds"]
                    ?: throw SerializationException("seconds field not found")
                val seconds = try {
                    secondsElement.jsonPrimitive.long
                } catch (e: IllegalArgumentException) { // catches .jsonPrimitive and .long
                    throw SerializationException(
                        "expected long for field seconds found '$secondsElement'",
                        e,
                    )
                }

                val nanosecondsElement = jElement["nanoseconds"] ?: jElement["_nanoseconds"]
                    ?: throw SerializationException("nanoseconds field not found")
                val nanoseconds = try {
                    nanosecondsElement.jsonPrimitive.long
                } catch (e: IllegalArgumentException) { // catches .jsonPrimitive and .long
                    throw SerializationException(
                        "expected long for field nanoseconds found '$nanosecondsElement'",
                        e,
                    )
                }

                val justMilliseconds = nanoseconds / 1_000_000
                DateTime.fromMilliseconds((seconds * 1000) + justMilliseconds)
            }
            JsonNull -> throw SerializationException(
                "expected number of object, found null: '$jElement'",
            )
            is JsonArray -> throw SerializationException(
                "expected number of object, found array: '$jElement'",
            )
        }
    }
}

@Serializable(with = DateTimeSerializer::class)
expect class DateTime(
    year: Int,
    month: Month,
    day: Int,
    hour: Int,
    minute: Int,
    second: Int,
    milliseconds: Milliseconds = 0,
) :
    Comparable<DateTime> {

    companion object {
        val EPOCH: DateTime
        fun now(): DateTime
        fun fromSeconds(s: Seconds): DateTime
        fun fromMilliseconds(s: Milliseconds): DateTime
        fun fromMilliseconds(s: Double): DateTime
        fun fromSeconds(s: Double): DateTime
        fun fromIsoString(s: String): DateTime
        val MAX: DateTime
    }

    operator fun plus(d: Duration): DateTime

    operator fun minus(d: Duration): DateTime

    fun asMillisecondsLong(): Milliseconds

    fun asMillisecondsDouble(): Double

    fun asSeconds(): Seconds

    /**
     * returns the ISO String of the [DateTime] in UTC timezone
     */
    fun toIsoString(): String

    /**
     * returns the year of the [DateTime] in UTC timezone
     */
    val year: Int

    /**
     * returns the month of the [DateTime] in UTC timezone
     */
    val month: Month

    /**
     * returns the day of the [DateTime] in UTC timezone
     */
    val day: Int

    /**
     * returns the hour of the [DateTime] in UTC timezone
     */
    val hour: Int

    /**
     * returns the minute of the [DateTime] in UTC timezone
     */
    val minute: Int

    /**
     * returns the second of the [DateTime] in UTC timezone
     */
    val second: Int

    /**
     * returns the millisecond of the [DateTime] in UTC timezone
     */
    val millisecond: Milliseconds

    override fun equals(other: Any?): Boolean
    override fun hashCode(): Int
    override fun toString(): String
}

enum class Month {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
    ;

    companion object {
        fun fromOrdinal(ord: Int): Month? = values().getOrNull(ord)
    }

    fun next(): Month = fromOrdinal(ordinal + 1) ?: January

    fun prev(): Month = fromOrdinal(ordinal - 1) ?: December

    fun days(year: Int): Int {
        return when (this) {
            February -> when {
                year % 4 != 0 -> 28
                year % 100 != 0 -> 29
                year % 400 != 0 -> 28
                else -> 29
            }
            January, March, May, July, August, October, December -> 31
            else -> 30
        }
    }
}

class Duration private constructor(private val rawValue: Long) : Comparable<Duration> {

    override fun compareTo(other: Duration) = rawValue.compareTo(other.rawValue)

    companion object {
        fun milliseconds(s: Milliseconds) = Duration(s)
        fun seconds(s: Long) = milliseconds(s * 1000)
        fun minutes(m: Long) = seconds(m * 60)
        fun hours(h: Long) = minutes(h * 60)
        fun days(d: Long) = hours(d * 24)
    }

    val inWholeMilliseconds: Milliseconds get() = rawValue
    val inWholeSeconds: Seconds get() = (rawValue / 1000).toInt()
}

fun DateTime.nextMonth(): DateTime {
    val year = if (month == Month.December) year + 1 else year
    val month = month.next()
    val monthDays = month.days(year)
    return DateTime(year, month, day.coerceAtMost(monthDays), hour, minute, second)
}

fun DateTime.previousMonth(): DateTime {
    val year = if (month == Month.January) year - 1 else year
    val month = month.prev()
    val monthDays = month.days(year)
    return DateTime(year, month, day.coerceAtMost(monthDays), hour, minute, second)
}

fun DateTime.minusMonths(count: Int): DateTime {
    val currentMonthOrd = month.ordinal
    var years = count / 12
    val newMonthOrd = run {
        val m = currentMonthOrd - (count % 12)
        if (m < 0) {
            years++
            12 + m
        } else {
            m
        }
    }
    return withYear(year - years)
        .withMonth(Month.fromOrdinal(newMonthOrd)!!)
        .withDay(day)
}

fun DateTime.nextYear(): DateTime {
    val year = year + 1
    val monthDays = month.days(year)
    return DateTime(year, month, day.coerceAtMost(monthDays), hour, minute, second)
}

fun DateTime.previousYear(): DateTime {
    val year = year - 1
    val monthDays = month.days(year)
    return DateTime(year, month, day.coerceAtMost(monthDays), hour, minute, second)
}

fun DateTime.withYear(y: Int) =
    DateTime(
        y,
        month,
        day.coerceAtMost(month.days(y)),
        hour,
        minute,
        second,
        millisecond,
    )

fun DateTime.withMonth(m: Month) =
    DateTime(
        year,
        m,
        day.coerceAtMost(m.days(year)),
        hour,
        minute,
        second,
        millisecond,
    )

fun DateTime.withDay(d: Int) =
    DateTime(
        year,
        month,
        d.coerceAtMost(month.days(year)),
        hour,
        minute,
        second,
        millisecond,
    )
