package com.speechify.client.api.content.view.epub

import com.speechify.client.api.adapters.WebViewAdapter
import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.epub.EpubStartOfMainContent
import com.speechify.client.api.content.epubV3.EpubV3
import com.speechify.client.api.content.startofmaincontent.RawStartOfMainContent
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.util.collections.maps.LockingThreadsafeMapWithMulti
import com.speechify.client.internal.util.collections.maps.MapFilledWhileAsyncGettingWithMultiAndClear
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.epub.EpubLocation

internal class EpubViewV3(
    private val htmlParser: HTMLParser,
    internal val webViewAdapter: WebViewAdapter,
    internal val epubV3: EpubV3,
) : EpubView {

    val totalChapters: Int = epubV3.readingOrder.size
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    private val chapterCache: MapFilledWhileAsyncGettingWithMultiAndClear<Int, EpubChapter> =
        LockingThreadsafeMapWithMulti()

    override val initialNavPointsToTocEntries by lazy {
        epubV3.navigation.toTocEntries()
    }

    override suspend fun getChapters(indexes: List<Int>): List<EpubChapter> {
        return chapterCache.getOrPutMulti(
            keys = indexes,
            produceMissingValues = { keysOfMissingEntries ->
                keysOfMissingEntries.map {
                    EpubChapterV3(
                        htmlParser = htmlParser,
                        epubV3 = epubV3,
                        index = it,
                    )
                }
            },
        )
    }

    override fun getChapterIndex(cursor: ContentCursor): Int {
        return when (cursor) {
            is ContentTextPosition -> cursor.element.path[0]
            is ContentElementBoundary ->
                cursor.element.path.firstOrNull() ?: when (cursor.boundary) {
                    is ContentBoundary.START -> 0
                    is ContentBoundary.END -> epubV3.readingOrder.size - 1
                }
        }
    }

    override fun getMetadata(): EpubMetadata = EpubMetadata(
        numberOfChapters = epubV3.readingOrder.size,
        initialNavPointsToTocEntries = initialNavPointsToTocEntries,
    )

    override suspend fun getMainContentStartCursor(
        remoteStartMainOfContent: RawStartOfMainContent.Epub?,
    ): ContentCursor? {
        return resolveMainContentStartCursor(
            remoteStartMainOfContent = remoteStartMainOfContent,
            resolveRemoteStartMainOfContent = epubV3::resolve,
            localParsedStartOfMainContent = epubV3.startOfMainContent,
        )
    }

    override suspend fun getChaptersEstimatedContentRatios(): Map<Int, Double> {
        return epubV3.chapterIndexesToEstimatedContentRatios
    }

    override fun destroy() {
        launchTask {
            epubV3.zipArchiveView.coDestroy()
        }
    }

    internal suspend fun cursorToEpubLocation(cursor: ContentCursor): EpubLocation {
        val (path, charIndex) = when (cursor) {
            is ContentElementBoundary -> when (cursor.boundary) {
                ContentBoundary.END -> cursor.element.path to 0
                ContentBoundary.START -> cursor.element.path to 0
            }

            is ContentTextPosition -> cursor.element.path to cursor.characterIndex
        }
        val chapterIndex = getChapterIndex(cursor)
        val chapterContent = getChapters(listOf(chapterIndex)).single().getContent()
        // The index of <html> and <body> element is different from a platform to another,
        // thus we take <body> as root reference when we resolve cursors to EpubLocation.
        val hasAdditionalRootElement = chapterContent.root.tagName.lowercase() != "html"
        // if it has an extra #root element we drop 3 (#root, html and body)
        // else we drop 2 (html and body).
        val numberOfElementIndexesToDrop = if (hasAdditionalRootElement) 3 else 2
        return EpubLocation(
            nodePathExcludingHtmlAndBody = path.drop(numberOfElementIndexesToDrop),
            charIndex = charIndex,
        )
    }

    internal suspend fun epubLocationToCursor(chapterIndex: Int, epubLocation: EpubLocation): ContentCursor {
        val chapterContent = getChapters(listOf(chapterIndex)).single().getContent()
        // We resolve the root html-node-element-index since it's different from a platform to another.
        val htmlElementIndex = chapterContent.root.children.indexOfFirst {
            (it as? WebPageNode.Element)?.tagName?.lowercase() == "html"
        }.nullIf { this == -1 }

        // We resolve the body-node-element-index since it's different from a platform to another.
        val rootHtmlElementOrNull = htmlElementIndex?.let {
            chapterContent.root.children[it] as? WebPageNode.Element
        } ?: chapterContent.root
        val bodyNodeElementIndex = rootHtmlElementOrNull.children.indexOfFirst {
            (it as? WebPageNode.Element)?.tagName?.lowercase() == "body"
        }.nullIf { this == -1 }

        val cursor = ContentElementReference.fromPath(
            listOfNotNull(
                chapterIndex,
                htmlElementIndex,
                bodyNodeElementIndex,
            ) + epubLocation.nodePathExcludingHtmlAndBody,
        ).getPosition(epubLocation.charIndex)
        return cursor
    }
}

private fun EpubV3.resolve(
    staticStartOfMainContent: RawStartOfMainContent.Epub,
) = readingOrder.indexOfFirst { chapter ->
    chapter.href.path == staticStartOfMainContent.filename.substringAfterLast("/")
}.nullIf {
    this == -1
}?.let {
    EpubStartOfMainContent(
        chapterIndex = it,
        fragment = staticStartOfMainContent.fragmentId,
    )
}
