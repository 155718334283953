package com.speechify.client.bundlers.reading.classic

import kotlin.js.JsExport

@JsExport
class ClassicReadingBundlerConfig(
    /**
     * Container for all *optional* [ClassicReadingBundler] configurations.
     */
    val options: ClassicReadingBundlerOptions,
)

/**
 * Container for all *optional* [ClassicReadingBundler] configurations.
 */
@JsExport
class ClassicReadingBundlerOptions()
