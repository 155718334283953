package com.speechify.client.api.content.view.book

import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.helpers.content.standard.book.Line
import com.speechify.client.helpers.content.standard.book.LineGroup
import com.speechify.client.helpers.content.standard.book.LineStats
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.AtomicCircularFixedList
import com.speechify.client.internal.util.collections.maps.LockingThreadsafeMapWithMulti
import com.speechify.client.internal.util.collections.maps.MapFilledWhileAsyncGettingWithMultiAndClear
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlin.math.max
import kotlin.math.min

/**
 * A subclass of a [BookView] - this is the place for adding all the "middleware" features like caching, etc.
 */
internal abstract class BaseBookView : BookView() {

    protected val pageCache: MapFilledWhileAsyncGettingWithMultiAndClear<Int, BookPage> =
        LockingThreadsafeMapWithMulti()
    internal val surroundingLineGroupsCache = AtomicCircularFixedList<Pair<Int, List<LineGroup>>>(10)

    override val parsedPageContentFlow: MutableSharedFlow<Pair<BookPageIndex, ParsedPageContent>> = MutableSharedFlow()

    override val bookEditsFlow: MutableSharedFlow<BookEdits> = MutableSharedFlow()

    override fun initializeBookViewFlows() {
        combine(mlParsingModeFlow, ocrFallbackStrategyFlow) { _, _ -> }
            .drop(1)
            .onEach {
                pageCache.clear()
                surroundingLineGroupsCache.clear()
            }.launchIn(scope)
    }

    override fun destroy() {
        val pagesToDestroy = pageCache.clear().map { (_, value) -> value }
        launchTask {
            /* Destroy all pages before destroying the adapter (using `coroutineScope` to wait for each page's destroy
               to finish). */
            coroutineScope {
                for (pageToDestroy in pagesToDestroy) {
                    // Launch the cleanups in parallel, not to block cleaning up any pages by some other pending page.
                    launch {
                        pageToDestroy.await().destroy()
                    }
                }
            }
            super.destroy()
        }
        surroundingLineGroupsCache.clear()
    }

    internal suspend fun getSurroundingLineGroups(pageIndex: Int, pageOffset: Int): Result<List<List<LineGroup>>> {
        val surroundingLineGroups =
            (max(0, pageIndex - pageOffset)..min(getMetadata().numberOfPages - 1, pageIndex + pageOffset))
                .filterNot { it == pageIndex }
                .mapNotNull { index ->
                    surroundingLineGroupsCache.find { it.first == index }
                        ?: (
                            coGetPages(arrayOf(index)).orReturn {
                                return it
                            }.firstOrNull()?.let { newPage ->
                                val lines = getLineGroupsFromPageWithCaching(newPage)
                                newPage.pageIndex to lines.orReturn { return@mapNotNull null }
                            } ?: throw IllegalStateException("No pages returned from coGetPages.").apply {
                                addCustomProperty("requestedPageIndex", index)
                            }
                            )
                }
        return surroundingLineGroups.map { it.second }.successfully()
    }

    /**
     * Gets the text content from the page and groups it into [LineGroup]s.
     */
    /* #InternalForTests */
    internal suspend fun getLineGroupsFromPageWithCaching(page: BookPage): Result<List<LineGroup>> {
        val cached = surroundingLineGroupsCache.find { it.first == page.pageIndex }
        if (cached != null) return cached.second.successfully()

        val textContent = page.getUnstableTextContentApproximatelyOrdered().orReturn { return it }
        val lines = Line.groupsFrom(textContent.filterNotTo(mutableListOf()) { it.text.text.isBlank() })
        val stats = LineStats.of(lines)
        return LineGroup.groupsFrom(lines, stats).also {
            surroundingLineGroupsCache.add(page.pageIndex to it)
        }.successfully()
    }
}
