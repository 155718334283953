package com.speechify.client.internal.util.boundary

import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.boundary.toBoundaryPair

class SdkBoundaryMap<V>(private val inner: MutableMap<String, V>) :
    BoundaryMap<V> {
    constructor() : this(mutableMapOf())

    override fun hasKey(key: String) = key in inner

    override operator fun get(key: String): V? = inner[key]

    override operator fun set(key: String, value: V) {
        inner[key] = value
    }

    override fun keys(): Array<String> = inner.keys.toTypedArray()

    override fun entries(): Array<BoundaryPair<String, V>> = inner.entries.map { it.toBoundaryPair() }.toTypedArray()

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false
        return inner == (other as SdkBoundaryMap<*>).inner
    }

    override fun hashCode() = inner.hashCode()

    override fun toString() = "SdkBoundaryMap($inner)"

    companion object {
        fun <V> fromMap(m: Map<String, V>): SdkBoundaryMap<V> {
            return SdkBoundaryMap(HashMap(m))
        }

        fun <V> of(vararg pairs: Pair<String, V>): SdkBoundaryMap<V> {
            return SdkBoundaryMap(hashMapOf(*pairs))
        }

        fun <V> ofNotNull(vararg pairs: Pair<String, V>?): SdkBoundaryMap<V> {
            return SdkBoundaryMap(hashMapOf(*pairs.filterNotNull().toTypedArray()))
        }

        fun <V> empty(): SdkBoundaryMap<V> = of()
    }
}

fun <V> Iterable<Pair<String, V>>.toBoundaryMap(): SdkBoundaryMap<V> =
    this.toMap().toBoundaryMap()

fun <V> Map<String, V>.toBoundaryMap(): SdkBoundaryMap<V> =
    SdkBoundaryMap.fromMap(this)

/**
 * Provides a mutable [SdkBoundaryMap] interface to [this] MutableMap (The content is not going to be copied,
 * and any changes will be done on the [this] MutableMap).
 */
fun <V> MutableMap<String, V>.asMutableBoundaryMap(): SdkBoundaryMap<V> =
    SdkBoundaryMap(inner = this)

fun <V> emptyBoundaryMap(): BoundaryMap<V> =
    @Suppress("UNCHECKED_CAST")
    (EmptyBoundaryMap as BoundaryMap<V>)
private val EmptyBoundaryMap = SdkBoundaryMap<Any>()
