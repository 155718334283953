package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonPrimitive

@Serializable(with = FirebasePaymentEnvironmentSerializer::class)
internal enum class FirebasePaymentEnvironment(val type: Int) {
    DEVELOPMENT(0),
    PRODUCTION(1),
}

// Android is writing environment as a string "development" | "production", while all other payment
// providers write it as int 0 | 1, so we have to be able to accept both Int and String
internal object FirebasePaymentEnvironmentSerializer :
    KSerializer<FirebasePaymentEnvironment> {
    override val descriptor: SerialDescriptor = JsonElement.serializer().descriptor

    override fun serialize(encoder: Encoder, value: FirebasePaymentEnvironment) {
        encoder.encodeInt(value.type)
    }

    override fun deserialize(decoder: Decoder): FirebasePaymentEnvironment {
        val jElement = decoder.decodeSerializableValue(JsonElement.serializer())
        try {
            return when (jElement.jsonPrimitive.content) {
                "0", "development" -> {
                    FirebasePaymentEnvironment.DEVELOPMENT
                }
                "1", "production" -> {
                    FirebasePaymentEnvironment.PRODUCTION
                }
                else -> {
                    throw SerializationException(
                        "failed to interpret environment value: ${jElement.jsonPrimitive.content}",
                    )
                }
            }
        } catch (e: IllegalArgumentException) {
            throw SerializationException("failed to interpret environment value", e)
        }
    }
}
