package com.speechify.client.api.services.importing

import com.speechify.client.api.util.Result
import kotlin.js.JsExport

@JsExport
class BatchOperationProgressResult<T> internal constructor(
    val batchInputIndex: Int,
    val result: Result<T>,
)
