package com.speechify.client.internal.services.library.models

import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.helpers.features.ListeningProgress
import com.speechify.client.helpers.features.ProgressFraction
import com.speechify.client.helpers.features.SyncedListeningProgress
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.library.CursorSurgeon
import com.speechify.client.internal.services.library.DestructuredCursor
import com.speechify.client.internal.services.scannedbook.ScannedBookFields
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import com.speechify.client.internal.util.extensions.numbers.clearNonFiniteAndCap
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.doubleOrNull
import kotlinx.serialization.json.intOrNull
import kotlin.math.roundToInt
@Serializable
internal data class FirebaseLibraryItem(
    val admins: List<String>? = null,
    val charCount: Int? = null,
    // has to be nullable since some records do have this field null, and we still want to show the item
    // serializer needs to know that this is nullable despite the field having a non-null default value
    // see: https://speechifyworkspace.slack.com/archives/C06CXHFT8LE/p1705102329106729?thread_ts=1704825826.229049&cid=C06CXHFT8LE
    val client: String? = "",
    val coverImagePath: String? = null,
    // has to be nullable since some records do have this field null, and we still want to show the item
    // serializer needs to know that this is nullable despite the field having a non-null default value
    // see: https://speechifyworkspace.slack.com/archives/C06CXHFT8LE/p1705102329106729?thread_ts=1704825826.229049&cid=C06CXHFT8LE
    val createdAt: DateTime? = DateTime.fromSeconds(-1),
    val excerpt: String? = null,
    val guest: List<String>? = null,
    val isArchived: Boolean = false,
    val isArchivedV2: Boolean = false,
    val isRemoved: Boolean = false,
    val isRemovedV2: Boolean = false,
    val isShared: Boolean = false,
    val isTopLevelArchivedItem: Boolean = false,
    val lastListenedAt: DateTime? = null,
    val lastUpdatedPageId: String? = null,
    val listeningProgress: FirebaseListeningProgress? = null,
    val numberOfPages: Int? = null,
    val owner: String = "",
    val pagesOrdering: List<Map<String, String>>? = null,
    val parentFolderId: String? = null,
    val progressFraction: ProgressFraction? = null,
    val progressStatus: String? = null,
    val recordType: String? = null,
    val removedAt: DateTime? = null,
    @SerialName(RecordProperties.sourceURL.keyId)
    val sourceUrl: String? = null,
    @SerialName(RecordProperties.sourceStoredURL.keyId)
    val sourceStoredUrl: String? = null,
    val status: String? = null,
    val title: String = "",
    val type: String = "",
    val updatedAt: DateTime = DateTime.fromSeconds(-1),
    val users: List<String>? = null,
    @Serializable(with = IntegerSerializerWithNonFiniteAsNull::class)
    val wordCount: Int? = null,
    @Serializable(with = IntegerSerializerWithNonFiniteAsNull::class)
    val wordsLeft: Int? = null,
    @SerialName(RecordProperties.analyticsProperties.keyId)
    val analyticsProperties: Map<String, String>? = null,
    @SerialName(RecordProperties.scannedBookFields.keyId)
    val scannedBookFields: ScannedBookFields? = null,
    @SerialName(RecordProperties.hasPageEdits.keyId)
    val hasPageEdits: Boolean = false,
    /**
     In elasticsearch we index the id (ref) field as part of the item model, while we don't do that
     in firestore - the ref is separate from the snapshot. To get a proper response from the search API,
     we also need the id field. This is null if the model is deserialized from Firestore, and won't be null if
     the model is deserialized from Elasticsearch.
     */
    val id: String? = null,
    val speechifyBookProductUri: String? = null,
    val startOfMainContent: Map<String, String?>? = null,
    val contentAccess: ContentAccess? = null,
)

enum class ContentAccess {
    PREVIEW,
    FULL,
}

@Serializable
data class FirebaseListeningProgress(
    val cursor: DestructuredCursor,
    val fraction: ProgressFraction,
    val speedInWordsPerMinute: Int,
    val timestamp: DateTime? = null,
) {
    companion object {
        fun fromListeningProgress(progress: ListeningProgress) = FirebaseListeningProgress(
            cursor = CursorSurgeon.destructure(progress.cursor),
            fraction = progress.fraction,
            speedInWordsPerMinute = progress.speedInWordsPerMinute,
        )
    }

    fun toSyncedListeningProgress() = SyncedListeningProgress(
        cursor = CursorSurgeon.assemble(cursor),
        fraction = fraction.clearNonFiniteAndCap(
            min = 0.0,
            max = 1.0,
            onNaN = 0.0,
        ),
        speedInWordsPerMinute = speedInWordsPerMinute,
        timestamp = (timestamp ?: DateTime.now()).toIsoString(),
    )

    fun toBoundaryMap(firebaseTimestampAdapter: FirebaseTimestampAdapter) = SdkBoundaryMap.of(
        "cursor" to cursor.toBoundaryMap(),
        "fraction" to fraction,
        // Decimals come from PlaybackControls in JS which violate the type PlaybackControls.State so we strip
        // decimals off here ensure that our data reflects expected types and avoid deserialization issues.
        "speedInWordsPerMinute" to speedInWordsPerMinute.toDouble().roundToInt(),
        "timestamp" to firebaseTimestampAdapter.now(),
    )
}

/**
 * This can be used to deserialize NaN / Infinite values to null. This is useful because we can't enforce integer
 * values on Firestore so might end up with NaN values in the database that would otherwise break deserialization.
 */
internal object IntegerSerializerWithNonFiniteAsNull : KSerializer<Int?> {
    override val descriptor: SerialDescriptor = JsonElement.serializer().descriptor

    override fun deserialize(decoder: Decoder): Int? {
        return when (val jElement = decoder.decodeSerializableValue(JsonElement.serializer())) {
            is kotlinx.serialization.json.JsonNull -> null
            is kotlinx.serialization.json.JsonPrimitive -> {
                val double = jElement.doubleOrNull
                jElement.intOrNull
                    ?: if (double != null && (double.isNaN() || double.isInfinite())) {
                        Log.w(
                            DiagnosticEvent(
                                message = "Found non-finite value in integer field. Converting to null.",
                                sourceAreaId = "IntegerSerializerWithNonFiniteAsNull",
                            ),
                        )
                        null
                    } else {
                        throw IllegalStateException("Expected JsonPrimitive to be an Int or Double but found $jElement")
                    }
            }
            else -> throw IllegalStateException("Expected JsonPrimitive or JsonNull but found $jElement")
        }
    }

    @OptIn(ExperimentalSerializationApi::class)
    override fun serialize(encoder: Encoder, value: Int?) {
        if (value == null) {
            encoder.encodeNull()
        } else {
            encoder.encodeInt(value)
        }
    }
}
