package com.speechify.client.api.services.subscription.models
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class SubscriptionPlanEntitlements(
    /** How many HD words this plan grants on each refill. */
    val wordsLimit: Int? = 0,
    /** Number of audiobook credits this plan grants on each refill. */
    val audiobookCredits: Int? = 0,
    /** Number of avatar seconds this plan grants on each refill. */
    val avatarsSeconds: Int? = 0,
    /** Number of dubbing seconds this plan grants on each refill. */
    val dubbingSeconds: Int? = 0,
    /** Number of Voice Over seconds this plan grants on each refill. */
    val voiceoverSeconds: Int? = 0,
    /** Number of the available starting quota for audio download */
    val ttsExportSeconds: Int? = 0,
)

@JsExport
@Serializable
data class SubscriptionPlan(
    /** Payment provider this plan is attached to. */
    val source: SubscriptionSource,
    /** User facing name of the Plan. */
    val name: String,
    /** Price of the subscription plan in USD. */
    val price: Double,
    val renewalFrequency: RenewalFrequency,
    val conversionId: String?,
    val discountIds: Array<String>,
    /** True if this plan starts with an initial trial period. */
    val hasTrial: Boolean,
    /** Number of days the initial trial period lasts. */
    val initialTrialDurationDays: Int?,
    /** List of product types that are included in this plan, will include 'tts', 'audiobooks', 'voiceover', and/or 'dubbing' */
    val productTypes: Array<String>,
    /** Plan entitlements such as wordsLimits, audiobook credits, etc. */
    val entitlements: SubscriptionPlanEntitlements = SubscriptionPlanEntitlements(),
) {

    /** How many HD words this plan grants on each refill. */
    @Deprecated("Use entitlements.wordsLimit instead")
    val wordsLimit: Int = entitlements.wordsLimit ?: 0

    /** Number of audiobook credits */
    @Deprecated("Use entitlements.audiobookCredits instead")
    val audiobookCredits: Int = entitlements.audiobookCredits ?: 0

    /** True if this plan includes TTS subscription product */
    @Deprecated("Use productTypes instead checking if includes 'tts'")
    val hasTTS: Boolean = productTypes.contains("tts")

    /** True if this plan includes Audiobook subscription product */
    @Deprecated("Use productTypes instead checking if includes 'audiobooks'")
    val hasAudiobook: Boolean = productTypes.contains("audiobooks")

    /** True if this plan includes VoiceOver subscription product */
    @Deprecated("Use productTypes instead checking if includes 'voiceover'")
    val hasVoiceOver: Boolean = productTypes.contains("voiceover")

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SubscriptionPlan

        if (source != other.source) return false
        if (name != other.name) return false
        if (price != other.price) return false
        if (renewalFrequency != other.renewalFrequency) return false
        if (conversionId != other.conversionId) return false
        if (!discountIds.contentEquals(other.discountIds)) return false
        if (hasTrial != other.hasTrial) return false
        if (initialTrialDurationDays != other.initialTrialDurationDays) return false
        if (hasTTS != other.hasTTS) return false
        if (hasAudiobook != other.hasAudiobook) return false
        if (hasVoiceOver != other.hasVoiceOver) return false
        if (!productTypes.contentEquals(other.productTypes)) return false
        if (entitlements != other.entitlements) return false

        return true
    }

    override fun hashCode(): Int {
        var result = source.hashCode()
        result = 31 * result + name.hashCode()
        result = 31 * result + price.hashCode()
        result = 31 * result + renewalFrequency.hashCode()
        result = 31 * result + (conversionId?.hashCode() ?: 0)
        result = 31 * result + discountIds.contentHashCode()
        result = 31 * result + hasTrial.hashCode()
        result = 31 * result + (initialTrialDurationDays ?: 0)
        result = 31 * result + hasTTS.hashCode()
        result = 31 * result + hasAudiobook.hashCode()
        result = 31 * result + hasVoiceOver.hashCode()
        result = 31 * result + productTypes.hashCode()
        result = 31 * result + entitlements.hashCode()
        return result
    }
}
