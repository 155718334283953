package com.speechify.client.reader.classic

import com.speechify.client.bundlers.reading.ReadingBundle
import com.speechify.client.reader.core.AnalyticsHelper
import com.speechify.client.reader.core.AutoscrollHelper
import com.speechify.client.reader.core.ContextualActionHelper
import com.speechify.client.reader.core.FocusHelper
import com.speechify.client.reader.core.HoveredSentenceHelper
import com.speechify.client.reader.core.ImportHelper
import com.speechify.client.reader.core.ListenedWordsCountHelper
import com.speechify.client.reader.core.NavigationHelper
import com.speechify.client.reader.core.PaywallHelper
import com.speechify.client.reader.core.PlaybackHelper
import com.speechify.client.reader.core.PremiumStateHelper
import com.speechify.client.reader.core.Reader
import com.speechify.client.reader.core.ScrubberHelper
import com.speechify.client.reader.core.SearchHelper
import com.speechify.client.reader.core.SelectionHelper
import com.speechify.client.reader.core.SkipSettingsHelper
import com.speechify.client.reader.core.TableOfContentsHelper
import com.speechify.client.reader.core.TitleHelper
import com.speechify.client.reader.core.UserHighlightsHelper
import com.speechify.client.reader.core.utils.SelectionUtils
import kotlinx.coroutines.CoroutineScope
import kotlin.js.JsExport

@JsExport
class ClassicReader internal constructor(
    scope: CoroutineScope,
    override val bundle: ReadingBundle,
    override val titleHelper: TitleHelper,
    override val playbackHelper: PlaybackHelper,
    override val scrubberHelper: ScrubberHelper,
    override val importHelper: ImportHelper,
    override val selectionHelper: SelectionHelper,
    override val focusHelper: FocusHelper,
    override val autoscrollHelper: AutoscrollHelper,
    override val navigationHelper: NavigationHelper,
    override val userHighlightsHelper: UserHighlightsHelper,
    override val hoveredSentenceHelper: HoveredSentenceHelper,
    override val analyticsHelper: AnalyticsHelper,
    override val listenedWordsCountHelper: ListenedWordsCountHelper,
    override val premiumStateHelper: PremiumStateHelper,
    override val skipSettingsHelper: SkipSettingsHelper,
    override val tableOfContentsHelper: TableOfContentsHelper,
    override val paywallHelper: PaywallHelper,
    override val contextualActionHelper: ContextualActionHelper,
    override val searchHelper: SearchHelper,
    val viewHelper: ClassicViewHelper,
    override val selectionUtils: SelectionUtils,
) : Reader(scope)
