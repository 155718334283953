package com.speechify.client.api.content.view.epub

import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.epub.EpubV2
import com.speechify.client.api.content.epubV3.Href
import com.speechify.client.api.content.epubV3.Link
import com.speechify.client.api.content.view.web.WebPageElementAttribute
import com.speechify.client.api.content.view.web.WebPageNode
import com.speechify.client.api.content.view.web.buildChildren
import com.speechify.client.api.util.orThrow

internal class EpubChapterV2(
    private val epubV2: EpubV2,
    private val htmlParser: HTMLParser,
    override val index: Int,
) : EpubChapter {

    private val chapter = epubV2.rawChapters.getValue(key = index)
    private val contentElementReference = ContentElementReference.fromPath(listOf(index))
    override val start: ContentCursor = contentElementReference.start
    override val end: ContentCursor = contentElementReference.end
    override val link: Link = Link(Href(chapter.filename))

    override suspend fun getContent(): EpubChapterContent {
        val chapterNavPoints = epubV2.navigation?.flattenedTocNavPoints?.filter {
            it.navPoint.chapterIndex == index
        }.orEmpty()

        val domRoot = htmlParser.coParseAsDOM(chapter.file).orThrow()
        val domRootBuilder = WebPageNode.builder(
            tagName = domRoot.tagName,
            attributes = domRoot.attributes.map { WebPageElementAttribute(it) },
            ref = ObjectRef(domRoot.rawRefObject),
            path = listOf(index),
        ) {
            buildChildren(domRoot)
        }

        val root = domRootBuilder.build()

        return EpubChapterContent(
            link = link,
            root = root,
            navPointsToTocEntries = resolveChapterNavPoints(navPoints = chapterNavPoints, root = root),
        )
    }
}
