package com.speechify.client.helpers.content.standard.book.heuristics.v2.stages

import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ContentBlock
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.Logger
import com.speechify.client.helpers.content.standard.book.heuristics.v2.models.ParsingPipelineStage
import com.speechify.client.helpers.content.standard.book.isAlmostEqual

/** Tolerance for considering two block bottom positions as equal */
private const val BLOCK_BOTTOM_POSITION_TOLERANCE = 0.001

internal class SortBlocks(private val logger: Logger?) :
    ParsingPipelineStage<List<ContentBlock>, List<ContentBlock>> {

    override fun process(input: List<ContentBlock>): List<ContentBlock> {
        val sortedBlocks = input.sortedWith(::compareBlocks)

        logSortedBlocks(sortedBlocks)

        return sortedBlocks
    }

    /**
     * Compares two blocks, sorting them top-to-bottom and left-to-right.
     */
    private fun compareBlocks(block1: ContentBlock, block2: ContentBlock): Int =
        when {
            block1.box.bottom.isAlmostEqual(block2.box.bottom, BLOCK_BOTTOM_POSITION_TOLERANCE) ->
                block1.box.right.compareTo(block2.box.right)

            else -> block1.box.bottom.compareTo(block2.box.bottom)
        }

    private fun logSortedBlocks(blocks: List<ContentBlock>) {
        logger?.log("Sorted blocks content:")
        blocks.forEach { logger?.log(it.plainText) }
    }
}
