package com.speechify.client.reader.epub

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.epub.EpubViewV3
import com.speechify.client.internal.util.extensions.collections.flows.debounceInstances
import com.speechify.client.internal.util.extensions.collections.flows.flatMapLatestForEachInstance
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.core.FocusCommand
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.HoveredSentenceState
import com.speechify.client.reader.core.NavigationCommand
import com.speechify.client.reader.core.NavigationIntent
import com.speechify.client.reader.core.PlaybackState
import com.speechify.client.reader.core.ReaderFeatures
import com.speechify.client.reader.core.ResolvedNavigationIntent
import com.speechify.client.reader.core.RobustLocation
import com.speechify.client.reader.core.SearchState
import com.speechify.client.reader.core.SelectionState
import com.speechify.client.reader.core.SerialLocation
import com.speechify.client.reader.core.UserHighlightsList
import com.speechify.client.reader.core.createReaderFeaturesFlow
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.scan
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class EpubViewHelper internal constructor(
    scope: CoroutineScope,
    private val epubViewV3: EpubViewV3,
    playbackStateFlow: Flow<PlaybackState>,
    selectionStateFlow: Flow<SelectionState>,
    highlightsInView: Flow<UserHighlightsList>,
    navigationIntentsFlow: Flow<ResolvedNavigationIntent>,
    hoveredSentenceStateFlow: Flow<HoveredSentenceState>,
    initialLocation: SerialLocation,
    initialEpubReaderConfig: EpubReaderConfig,
    searchStateFlow: Flow<SearchState>,
) : Helper<EpubViewState>(scope) {

    private val epubReaderConfigFlow = MutableStateFlow(initialEpubReaderConfig)
    private val isLoadingMoreAfterFlow = MutableStateFlow(false)
    private val isLoadingMoreBeforeFlow = MutableStateFlow(false)

    private val navigationHelper = EpubNavigationHelper(scope, navigationIntentsFlow)

    private val currentChapterIndexState = MutableStateFlow(epubViewV3.getChapterIndex(initialLocation.cursor))

    private val readerFeatures = createReaderFeaturesFlow(
        playbackStateFlow,
        selectionStateFlow,
        highlightsInView,
        navigationHelper.fineIntentsFlow,
        hoveredSentenceStateFlow,
        searchStateFlow,
    ).stateInHelper(
        initialValue = ReaderFeatures.Empty,
    )

    private val chaptersInFocusFlow: MutableStateFlow<Map<Int, EpubChapterHelper>> = MutableStateFlow(emptyMap())

    override val initialState: EpubViewState = EpubViewState(
        navigationIntent = null,
        chapters = (0 until epubViewV3.totalChapters)
            .map { EpubChapterFocusState.NotInFocus(it) }
            .toTypedArray(),
        paginationOrientation = epubReaderConfigFlow.value.epubPaginationOrientation,
        hasMoreBefore = true,
        hasMoreAfter = true,
        isLoadingMoreBefore = true,
        isLoadingMoreAfter = true,
    )

    override val stateFlow: StateFlow<EpubViewState> = combine(
        chaptersInFocusFlow,
        navigationHelper.coarseIntentsFlow,
        epubReaderConfigFlow.map { it.epubPaginationOrientation }.distinctUntilChanged(),
        isLoadingMoreBeforeFlow,
        isLoadingMoreAfterFlow,
    ) { chaptersInFocus, coarseIntent, paginationOrientation, isLoadingMoreBefore, isLoadingMoreAfter ->
        val numberOfChapters = epubViewV3.totalChapters
        val chapterHelpers = (0 until numberOfChapters).map { chapterIndex ->
            val chapterInFocus = chaptersInFocus[chapterIndex]
            when {
                chapterInFocus != null -> {
                    EpubChapterFocusState.InFocus(chapterInFocus.chapterIndex, chapterInFocus)
                }

                else -> EpubChapterFocusState.NotInFocus((chapterIndex))
            }
        }

        val coarseNavigation = coarseIntent?.toCoarseEpubNavigationIntent()
        coarseNavigation?.run {
            currentChapterIndexState.value = chapterIndex
        }
        val hasMoreBefore =
            chapterHelpers.filterIsInstance<EpubChapterFocusState.InFocus>().firstOrNull()?.chapterIndex != 0
        val hasMoreAfter =
            chapterHelpers.filterIsInstance<EpubChapterFocusState.InFocus>()
                .lastOrNull()?.chapterIndex != epubViewV3.totalChapters - 1

        EpubViewState(
            navigationIntent = coarseNavigation,
            chapters = chapterHelpers.toTypedArray(),
            paginationOrientation = paginationOrientation,
            hasMoreBefore = hasMoreBefore,
            hasMoreAfter = hasMoreAfter,
            isLoadingMoreBefore = isLoadingMoreBefore,
            isLoadingMoreAfter = isLoadingMoreAfter,
        )
    }.stateInHelper(
        initialValue = initialState,
    )

    init {
        commands
            .debounceInstances<EpubReaderViewCommand.LoadMoreChaptersAfter>(periodMillis = 100)
            .debounceInstances<EpubReaderViewCommand.LoadMoreChaptersBefore>(periodMillis = 100)
            .onEachInstance<EpubReaderViewCommand.UpdateTextFontScale> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        fontScale = command.scale,
                    )
                }
            }
            .onEachInstance<EpubReaderViewCommand.UpdateSelectionBackgroundColor> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        selectionBGHexColor = command.colorHexCode,
                    )
                }
            }
            .onEachInstance<EpubReaderViewCommand.UpdateWordHighlightColor> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        wordHighlightHexColor = command.colorHexCode,
                    )
                }
            }
            .onEachInstance<EpubReaderViewCommand.UpdateSentenceHighlightColor> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        sentenceHighlightHexColor = command.colorHexCode,
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateTextColor> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        textColor = command.colorHexCode,
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateBackgroundColor> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        backgroundColor = command.colorHexCode,
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateLayoutPaginationOrientation> { command ->
                // Todo(Imrane): Investigate a more efficient way to snap between horizontal and vertical pagination.
                // for now, we reload the webView since they need to be re-rendered.
                val chaptersInFocus = chaptersInFocusFlow.value
                chaptersInFocusFlow.value = emptyMap()
                chaptersInFocus.forEach {
                    it.value.destroy()
                }
                epubReaderConfigFlow.update {
                    it.copy(
                        epubPaginationOrientation = command.orientation,
                    )
                }
                setFocusAroundChapter(currentChapterIndexState.value, extraChaptersInEachDirection = 2)
            }
            .onEachInstance<EpubReaderViewCommand.UpdateLineSpacing> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            lineSpacing = command.value,
                        ),
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateLetterSpacing> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            letterSpacing = command.value,
                        ),
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateWordSpacing> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            wordSpacing = command.value,
                        ),
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateHorizontalMargins> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            horizontalMargins = command.value,
                        ),
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateFontWeight> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            fontWeight = command.value,
                        ),
                    )
                }
            }.onEachInstance<EpubReaderViewCommand.UpdateTextAlignment> { command ->
                epubReaderConfigFlow.update {
                    it.copy(
                        epubTextAdjustmentsConfig = it.epubTextAdjustmentsConfig.copy(
                            textAlignment = command.value,
                        ),
                    )
                }
            }
            .onEachInstance<EpubReaderViewCommand.GoToNextChapter> {
                val chapter = epubViewV3.getChapters(listOf(it.chapterIndex)).single()
                dispatch(
                    NavigationCommand.NavigateTo(
                        NavigationIntent.GoToChapter(
                            chapter.index,
                            RobustLocation(SerialLocation(chapter.start)),
                        ),
                    ),
                )
            }
            .onEachInstance<EpubReaderViewCommand.GoToPreviousChapter> {
                val chapter = epubViewV3.getChapters(listOf(it.chapterIndex)).single()
                dispatch(
                    NavigationCommand.NavigateTo(
                        NavigationIntent.GoToChapter(
                            chapter.index,
                            RobustLocation(SerialLocation(chapter.start)),
                        ),
                    ),
                )
            }
            .onEachInstance<EpubReaderViewCommand.LoadMoreChaptersAfter> {
                if (isLoadingMoreAfterFlow.value || isLoadingMoreBeforeFlow.value) return@onEachInstance
                isLoadingMoreAfterFlow.value = true
                val chapterInFocus = chaptersInFocusFlow.value
                val firstChapterIndex = chapterInFocus.keys.toList().first()
                val lastChapterIndex = chapterInFocus.keys.toList().last()
                val startIndex = (firstChapterIndex).coerceAtMost(epubViewV3.totalChapters)
                val endIndexExclusive = (lastChapterIndex + 2).coerceAtMost(epubViewV3.totalChapters)
                dispatch(EpubReaderViewCommand.SetChaptersInFocus(startIndex, endIndexExclusive))
            }
            .onEachInstance<EpubReaderViewCommand.LoadMoreChaptersBefore> {
                if (isLoadingMoreAfterFlow.value || isLoadingMoreBeforeFlow.value) return@onEachInstance
                isLoadingMoreBeforeFlow.value = true
                val chapterInFocus = chaptersInFocusFlow.value
                val firstChapterIndex = chapterInFocus.keys.toList().first()
                val lastChapterIndex = chapterInFocus.keys.toList().last()
                val startIndex = (firstChapterIndex - 1).coerceAtLeast(0)
                val endIndexExclusive = (lastChapterIndex + 1).coerceAtLeast(0)
                dispatch(EpubReaderViewCommand.SetChaptersInFocus(startIndex, endIndexExclusive))
            }
            .onEachInstance<EpubReaderViewCommand.ScrollToNextPage> {
                val currentChapterIndex = currentChapterIndexState.value
                dispatch(EpubChapterHelperCommand.ScrollToNextPage(currentChapterIndex))
            }
            .onEachInstance<EpubReaderViewCommand.ScrollToPreviousPage> {
                val currentChapterIndex = currentChapterIndexState.value
                dispatch(EpubChapterHelperCommand.ScrollToPreviousPage(currentChapterIndex))
            }
            .flatMapLatestForEachInstance<EpubReaderViewCommand.SetChaptersInFocus> { command ->
                val chaptersInFocus = chaptersInFocusFlow.value
                val focusRange = (command.startIndex until command.endIndexExclusive)
                val chapterHelpers = focusRange.associate { chapterIndex ->
                    if (chapterIndex !in chaptersInFocus) {
                        val chapter = epubViewV3.getChapters(listOf(chapterIndex)).single()
                        chapter.index to EpubChapterHelper(
                            scope = scope,
                            epubReaderConfigFlow = epubReaderConfigFlow,
                            epubChapter = chapter,
                            epubViewV3 = epubViewV3,
                            readerFeatures = readerFeatures,
                        )
                    } else {
                        chapterIndex to chaptersInFocus[chapterIndex]!!
                    }
                }

                val chaptersLeavingFocus = chaptersInFocus.filter {
                    it.value.chapterIndex !in focusRange
                }

                chaptersInFocusFlow.update { existingChapters ->
                    (existingChapters - chaptersLeavingFocus.keys + chapterHelpers)
                        .toList()
                        .sortedBy { it.first }
                        .toMap()
                }

                // destroy the pages once they leave focus to free up resources
                chaptersLeavingFocus.forEach {
                    it.value.destroy()
                }

                // TODO: clean up cursor/location wrapping
                val start = chaptersInFocusFlow.value.entries.first().value.epubChapter.start
                val end = chaptersInFocusFlow.value.entries.last().value.epubChapter.end

                // TODO: consider having this react to focus?
                dispatch(
                    FocusCommand.SetFocus(
                        start = SerialLocation(start),
                        end = SerialLocation(end),
                    ),
                )
                isLoadingMoreAfterFlow.value = false
                isLoadingMoreBeforeFlow.value = false
            }
            .launchInHelper()

        /**
         * Since the SDK handles setting the chapter in focus, we ensure that if the navigation moves out of the current focus,
         * the content is loaded based on the new coarse navigation.
         */
        navigationHelper.coarseIntentsFlow
            .mapNotNull {
                it?.toCoarseEpubNavigationIntent()?.chapterIndex?.nullIf {
                    chaptersInFocusFlow.value.keys.map { it }.contains(this)
                }
            }
            .onEach {
                setFocusAroundChapter(it, extraChaptersInEachDirection = 2)
            }.launchInHelper()

        // Map the playback to get the current and previous speaking word in order to clear word and sentence
        // highlight at chapter boundaries. See this ticket for more details [https://linear.app/speechify-inc/issue/CXP-4874/fix-issue-where-sentenceword-highlights-keeps-showing-at-the-end-of]
        playbackStateFlow
            .mapNotNull { it.speakingWord }
            .scan(initial = null) { accumulator: Pair<ContentText?, ContentText>?, value ->
                accumulator?.second to value
            }.mapNotNull { pair ->
                // Ensure both `pair` is not null and `it.first` is not null
                pair?.let { (previousWord, currentWord) ->
                    if (previousWord == null) return@let null
                    val chapterIndexOfPreviousSpeakingWord = epubViewV3.getChapterIndex(previousWord.start)
                    val chapterIndexOfCurrentSpeakingWord = epubViewV3.getChapterIndex(currentWord.start)
                    if (chapterIndexOfPreviousSpeakingWord == chapterIndexOfCurrentSpeakingWord) return@let null
                    chapterIndexOfPreviousSpeakingWord to chapterIndexOfCurrentSpeakingWord
                }
            }
            .distinctUntilChanged()
            .onEach { (chapterIndexOfPreviousSpeakingWord, _) ->
                dispatch(EpubChapterHelperCommand.ClearWordAndSentenceHighlight(chapterIndexOfPreviousSpeakingWord))
            }.launchInHelper()
    }

    private fun setFocusAroundChapter(chapterIndex: Int, extraChaptersInEachDirection: Int) {
        val startIndex = (chapterIndex - extraChaptersInEachDirection).coerceAtLeast(0)
        val endIndexExclusive =
            (chapterIndex + extraChaptersInEachDirection + 1).coerceAtMost(epubViewV3.totalChapters)
        dispatch(EpubReaderViewCommand.SetChaptersInFocus(startIndex, endIndexExclusive))
    }

    fun loadMoreChaptersAfter() = dispatch(EpubReaderViewCommand.LoadMoreChaptersAfter)

    fun loadMoreChaptersBefore() = dispatch(EpubReaderViewCommand.LoadMoreChaptersBefore)

    fun scrollToPreviousPage() = dispatch(EpubReaderViewCommand.ScrollToPreviousPage)

    fun scrollToNextPage() = dispatch(EpubReaderViewCommand.ScrollToNextPage)

    fun updateTextFontScale(scale: Double) = dispatch(EpubReaderViewCommand.UpdateTextFontScale(scale))

    fun updateSelectionBackgroundColor(colorHexCode: String) =
        dispatch(EpubReaderViewCommand.UpdateSelectionBackgroundColor(colorHexCode))

    fun updateWordHighlightColor(colorHexCode: String) =
        dispatch(EpubReaderViewCommand.UpdateWordHighlightColor(colorHexCode))

    fun updateSentenceHighlightColor(colorHexCode: String) =
        dispatch(EpubReaderViewCommand.UpdateSentenceHighlightColor(colorHexCode))

    fun updateLayoutPaginationOrientation(orientation: EpubPaginationOrientation) =
        dispatch(EpubReaderViewCommand.UpdateLayoutPaginationOrientation(orientation))

    fun updateTextColor(colorHexCode: String) =
        dispatch(EpubReaderViewCommand.UpdateTextColor(colorHexCode))

    fun updateBackgroundColor(colorHexCode: String) =
        dispatch(EpubReaderViewCommand.UpdateBackgroundColor(colorHexCode))

    fun updateLineSpacing(lineSpacing: TextAdjustmentValue) =
        dispatch(EpubReaderViewCommand.UpdateLineSpacing(lineSpacing))

    fun updateLetterSpacing(letterSpacing: TextAdjustmentValue) =
        dispatch(EpubReaderViewCommand.UpdateLetterSpacing(letterSpacing))

    fun updateWordSpacing(wordSpacing: TextAdjustmentValue) =
        dispatch(EpubReaderViewCommand.UpdateWordSpacing(wordSpacing))

    fun updateHorizontalMargins(horizontalMargins: TextAdjustmentValue.EMUnit) =
        dispatch(EpubReaderViewCommand.UpdateHorizontalMargins(horizontalMargins))

    fun updateFontWeight(fontWeight: FontWeight) =
        dispatch(EpubReaderViewCommand.UpdateFontWeight(fontWeight))

    fun updateTextAlignment(textAlignment: TextAlignment) =
        dispatch(EpubReaderViewCommand.UpdateTextAlignment(textAlignment))

    internal sealed class EpubReaderViewCommand {
        data class SetChaptersInFocus(val startIndex: Int, val endIndexExclusive: Int) : EpubReaderViewCommand()

        data class UpdateTextFontScale(val scale: Double) : EpubReaderViewCommand()
        data class UpdateSelectionBackgroundColor(val colorHexCode: String) : EpubReaderViewCommand()
        data class UpdateWordHighlightColor(val colorHexCode: String) : EpubReaderViewCommand()
        data class UpdateSentenceHighlightColor(val colorHexCode: String) : EpubReaderViewCommand()
        data class UpdateLayoutPaginationOrientation(
            val orientation: EpubPaginationOrientation,
        ) : EpubReaderViewCommand()

        data class UpdateTextColor(val colorHexCode: String) : EpubReaderViewCommand()
        data class UpdateBackgroundColor(val colorHexCode: String) : EpubReaderViewCommand()
        data class UpdateLineSpacing(val value: TextAdjustmentValue) : EpubReaderViewCommand()
        data class UpdateLetterSpacing(val value: TextAdjustmentValue) : EpubReaderViewCommand()
        data class UpdateWordSpacing(val value: TextAdjustmentValue) : EpubReaderViewCommand()
        data class UpdateHorizontalMargins(val value: TextAdjustmentValue.EMUnit) : EpubReaderViewCommand()
        data class UpdateFontWeight(val value: FontWeight) : EpubReaderViewCommand()
        data class UpdateTextAlignment(val value: TextAlignment) : EpubReaderViewCommand()

        data class GoToNextChapter(val chapterIndex: Int) : EpubReaderViewCommand()
        data class GoToPreviousChapter(val chapterIndex: Int) : EpubReaderViewCommand()

        object LoadMoreChaptersAfter : EpubReaderViewCommand()
        object LoadMoreChaptersBefore : EpubReaderViewCommand()

        object ScrollToNextPage : EpubReaderViewCommand()
        object ScrollToPreviousPage : EpubReaderViewCommand()
    }

    private fun ResolvedNavigationIntent.toCoarseEpubNavigationIntent(): CoarseEpubNavigationIntent {
        val cursor = this.location.hack.cursor
        val chapterIndex = epubViewV3.getChapterIndex(cursor)
        return CoarseEpubNavigationIntent(
            dispatch = dispatch,
            resolvedIntent = this,
            chapterIndex = chapterIndex,
        )
    }

    override fun destroy() {
        super.destroy()
        chaptersInFocusFlow.value.forEach {
            it.value.destroy()
        }
    }
}

@JsExport
data class EpubViewState internal constructor(
    val navigationIntent: CoarseEpubNavigationIntent?,
    val chapters: Array<EpubChapterFocusState>,
    val paginationOrientation: EpubPaginationOrientation,
    val hasMoreBefore: Boolean,
    val hasMoreAfter: Boolean,
    val isLoadingMoreBefore: Boolean,
    val isLoadingMoreAfter: Boolean,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as EpubViewState

        if (navigationIntent != other.navigationIntent) return false
        if (!chapters.contentEquals(other.chapters)) return false
        if (paginationOrientation != other.paginationOrientation) return false
        if (hasMoreBefore != other.hasMoreBefore) return false
        if (hasMoreAfter != other.hasMoreAfter) return false
        if (isLoadingMoreBefore != other.isLoadingMoreBefore) return false
        if (isLoadingMoreAfter != other.isLoadingMoreAfter) return false

        return true
    }

    override fun hashCode(): Int {
        var result = navigationIntent?.hashCode() ?: 0
        result = 31 * result + chapters.contentHashCode()
        result = 31 * result + paginationOrientation.hashCode()
        result = 31 * result + hasMoreBefore.hashCode()
        result = 31 * result + hasMoreAfter.hashCode()
        result = 31 * result + isLoadingMoreBefore.hashCode()
        result = 31 * result + isLoadingMoreAfter.hashCode()
        return result
    }
}

@JsExport
sealed class EpubChapterFocusState(open val chapterIndex: Int) {
    data class InFocus(
        override val chapterIndex: Int,
        val epubChapterHelper: EpubChapterHelper,
    ) : EpubChapterFocusState(chapterIndex)

    data class NotInFocus(
        override val chapterIndex: Int,
    ) : EpubChapterFocusState(chapterIndex)
}
