package com.speechify.client.test

import com.speechify.client.api.content.pdf.PDFBookView
import com.speechify.client.api.content.view.book.coSearch
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.images.BoundingBox
import kotlin.js.JsExport

/**
 * To validate the test cases in this [BreakingChangeTestSuit] , We need to use a specific pdf document only.
 * Here is the link of the document to download:
 * [pdf file to test](https://firebasestorage.googleapis.com/v0/b/speechifydev.appspot.com/o/multiplatform%2Fimport%2FQG8J0GnvAxdPEjpa5bi1w5YUMyv1%2F5f025a5a-7731-4ce9-9026-8a566fdf1d9b?alt=media&token=8194dc06-e2a6-4da7-ad05-d4192d9529d7)
 *
 * Contact CXP Sdk team for details
 *
 */
@JsExport
class PdfSearchTestSuit internal constructor(private val pdfBookView: PDFBookView) : BreakingChangeTestSuit() {

    override fun tests(): List<suspend () -> TestResult> {
        return listOf(
            ::testSearchResultCountInSinglePage,
            ::testSearchResultCountWithCaseSensitivity,
            ::testSearchResultInMultiplePages,
            ::testSearchResultWithValidBoundingBox,
        )
    }

    private suspend fun testSearchResultCountInSinglePage(): TestResult {
        val description = "Search result count"
        val result = pdfBookView.coSearch("learning", 0, 0, BookSearchOptions(false))
        return when (result) {
            is Result.Failure -> {
                TestResult.Failure(description, "Count not matched, ${result.error.toNativeError().message}")
            }

            is Result.Success -> {
                val otherPageIndexCount = result.value.count { it.pageIndex != 0 }
                if (otherPageIndexCount > 0) {
                    TestResult.Failure(description, "result includes pages other than startPageIndex and endPageIndex")
                } else {
                    val pageSearchBoundingBoxes =
                        result.value.filter {
                            it.pageIndex == 0
                        }.flatMap {
                            it.boundingBoxes.toList()
                        }
                    if (pageSearchBoundingBoxes.size == 7) {
                        TestResult.Success(description)
                    } else {
                        TestResult.Failure(
                            description,
                            "Count not matched, should be 7, found ${pageSearchBoundingBoxes.size}",
                        )
                    }
                }
            }
        }
    }

    private suspend fun testSearchResultInMultiplePages(): TestResult {
        val description = "Search result count in multiple pages"
        val result = pdfBookView.coSearch("learning", 0, 5, BookSearchOptions(true))
        return when (result) {
            is Result.Failure -> {
                TestResult.Failure(description, "${result.error.toNativeError().message}")
            }

            is Result.Success -> {
                val otherPageIndexCount =
                    result.value.count { it.pageIndex == null || it.pageIndex < 0 || it.pageIndex > 5 }
                if (otherPageIndexCount > 0) {
                    TestResult.Failure(description, "result includes pages other than startPageIndex and endPageIndex")
                } else {
                    val pageSearchBoundingBoxes = result.value.filter {
                        it.pageIndex != null && it.pageIndex >= 0 && it.pageIndex <= 5
                    }.flatMap {
                        it.boundingBoxes.toList()
                    }
                    if (pageSearchBoundingBoxes.size == 29) {
                        TestResult.Success(description)
                    } else {
                        TestResult.Failure(
                            description,
                            "Count not matched, should be 29, found ${pageSearchBoundingBoxes.size}",
                        )
                    }
                }
            }
        }
    }

    private suspend fun testSearchResultCountWithCaseSensitivity(): TestResult {
        val description = "Search result count with case Sensitivity on"
        val result = pdfBookView.coSearch("Learning", 0, 0, BookSearchOptions(true))
        return when (result) {
            is Result.Failure -> {
                TestResult.Failure(description, "${result.error.toNativeError().message}")
            }

            is Result.Success -> {
                val otherPageIndexCount = result.value.count { it.pageIndex != 0 }
                if (otherPageIndexCount > 0) {
                    TestResult.Failure(description, "result includes pages other than startPageIndex and endPageIndex")
                } else {
                    val pageSearchBoundingBoxes = result.value.filter {
                        it.pageIndex == 0
                    }.flatMap {
                        it.boundingBoxes.toList()
                    }
                    if (pageSearchBoundingBoxes.size == 3) {
                        TestResult.Success(description)
                    } else {
                        TestResult.Failure(
                            description,
                            "Count not matched, should be 3, found ${pageSearchBoundingBoxes.size}",
                        )
                    }
                }
            }
        }
    }

    private suspend fun testSearchResultWithValidBoundingBox(): TestResult {
        val description = "Search result with Valid bounding boxes"
        val result = pdfBookView.coSearch("learning", 0, 5, BookSearchOptions(true))
        return when (result) {
            is Result.Failure -> {
                TestResult.Failure(description, "Count not matched, ${result.error.toNativeError().message}")
            }

            is Result.Success -> {
                val searchResults = result.value
                searchResults.forEach {
                    val errors = validateBoundingBoxFormat(it.boundingBoxes)
                    if (errors.isNotEmpty()) {
                        return TestResult.Failure(description, errors.toString())
                    }
                }
                TestResult.Success(description)
            }
        }
    }

    /**
     * Validates that the returned bounding boxes are properly formatted
     */
    private fun validateBoundingBoxFormat(boxes: Array<BoundingBox>): List<String> {
        val errors = mutableListOf<String>()

        boxes.forEachIndexed { index, box ->
            if (box.width <= 0 || box.height <= 0) {
                errors.add("BoundingBox $index has invalid dimensions: width=${box.width}, height=${box.height}")
            }

            if (box.left < 0 || box.top < 0) {
                errors.add("BoundingBox $index has negative position: left=${box.left}, top=${box.top}")
            }
        }

        return errors
    }
}
