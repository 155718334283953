package com.speechify.client.internal.services.library

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.TransientContentTextPosition
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.Serializable

@Serializable
data class DestructuredCursor(
    val path: List<Int>,
    val position: Int?,
    val boundary: String?,
) {
    fun toBoundaryMap() = SdkBoundaryMap.of(
        "boundary" to boundary,
        "path" to path.toTypedArray(),
        "position" to position,
    )
}

internal object CursorSurgeon {

    fun destructure(cursor: ContentCursor): DestructuredCursor {
        return when (
            cursor
        ) {
            is TransientContentTextPosition -> destructure(cursor.originalContentTextPosition)
            is ContentElementBoundary -> DestructuredCursor(
                cursor.element.path,
                null,
                cursor.boundary.toString(),
            )
            is ContentTextPosition -> DestructuredCursor(
                cursor.element.path,
                cursor.characterIndex,
                null,
            )
        }
    }

    fun assemble(cursor: DestructuredCursor): ContentCursor {
        val (path, position, boundary) = cursor
        return ContentElementReference.fromPath(path).let {
            if (position != null) {
                it.getPosition(position)
            } else if (boundary != null) {
                ContentElementBoundary(it, ContentBoundary.fromString(boundary))
            } else {
                throw Exception("Either position ($position) or boundary ($boundary) must be specified")
            }
        }
    }
}
