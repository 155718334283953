package com.speechify.client.internal.util.collections.maps

internal typealias Func<Arg, Result> = (Arg) -> Result

/**
 * See [memoizedEnsuringSingleExecutionBy].
 * This overload requires that [Arg]'s [Any.equals] and [Any.hashCode] are implemented correctly.
 */
internal fun <Arg, Result> Func<Arg, Result>.memoizedEnsuringSingleExecution(): Func<Arg, Result> =
    this.memoizedEnsuringSingleExecutionBy(getKeyFromArg = { it })

/**
 * Uses [BlockingThreadsafeMap], so it guarantees that the function will be executed only once for each unique key
 * (at a cost of blocking any other threads that try to execute the function for the same key).
 */
internal fun <Arg, Result, TKey> Func<Arg, Result>.memoizedEnsuringSingleExecutionBy(
    getKeyFromArg: (arg: Arg) -> TKey,
): Func<Arg, Result> {
    val cache = BlockingThreadsafeMap<TKey, Result>()
    return { arg ->
        cache.getOrPut(
            key = getKeyFromArg(arg),
            defaultValue = { this@memoizedEnsuringSingleExecutionBy(arg) },
        )
    }
}
