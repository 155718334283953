// ktlint-disable filename
package com.speechify.client.internal.util.extensions.numbers

internal inline fun Double.clearNonFinite(
    onNegativeInfinity: Double,
    onPositiveInfinity: Double,
    onNaN: Double,
): Double =
    when {
        isNaN() -> onNaN
        isInfinite() -> if (this < 0) onNegativeInfinity else onPositiveInfinity
        else -> this
    }

/**
 * Like [clearNonFinite], but will also cap the value to the given [min] and [max] values.
 */
internal inline fun Double.clearNonFiniteAndCap(
    min: Double,
    max: Double,
    onNaN: Double,
): Double =
    when {
        this.isNaN() -> onNaN
        this < min -> min
        this > max -> max
        else -> this
    }
