package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.`internal`.services.db.DbBoolean
import com.speechify.client.`internal`.services.db.DbOcrFile
import com.speechify.client.`internal`.services.importing.models.ImportType
import com.speechify.client.`internal`.time.DateTime
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.util.MimeType
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.features.ListeningProgress
import kotlin.Any
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public class PendingImportQueries(
  driver: SqlDriver,
  private val pendingImportAdapter: PendingImport.Adapter,
  private val localListeningProgressAdapter: LocalListeningProgress.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getResumableImportsWithLocalListeningProgress(
    attemptsPerformedCount: Int,
    owner: String,
    mapper: (
      speechifyUri: SpeechifyURI,
      primaryFileBlobStorageKey: BlobStorageKey?,
      scannedPages: List<DbOcrFile>?,
      sourceURL: String?,
      importOptions: ImportOptions?,
      htmlContentLoadOptions: HtmlContentLoadOptions?,
      lastErrorStackTrace: String?,
      wasLastErrorConnectionError: DbBoolean?,
      importType: ImportType,
      attemptsPerformedCount: Int,
      owner: String,
      lastUpdatedAt: DateTime,
      listeningProgress: ListeningProgress?,
      mimeType: MimeType?,
      listeningProgress_: ListeningProgress?,
    ) -> T,
  ): Query<T> = GetResumableImportsWithLocalListeningProgressQuery(attemptsPerformedCount, owner) {
      cursor ->
    mapper(
      pendingImportAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { pendingImportAdapter.primaryFileBlobStorageKeyAdapter.decode(it) },
      cursor.getString(2)?.let { pendingImportAdapter.scannedPagesAdapter.decode(it) },
      cursor.getString(3),
      cursor.getString(4)?.let { pendingImportAdapter.importOptionsAdapter.decode(it) },
      cursor.getString(5)?.let { pendingImportAdapter.htmlContentLoadOptionsAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7)?.let { pendingImportAdapter.wasLastErrorConnectionErrorAdapter.decode(it) },
      pendingImportAdapter.importTypeAdapter.decode(cursor.getString(8)!!),
      pendingImportAdapter.attemptsPerformedCountAdapter.decode(cursor.getLong(9)!!),
      cursor.getString(10)!!,
      pendingImportAdapter.lastUpdatedAtAdapter.decode(cursor.getDouble(11)!!),
      cursor.getString(12)?.let { pendingImportAdapter.listeningProgressAdapter.decode(it) },
      cursor.getString(13)?.let { pendingImportAdapter.mimeTypeAdapter.decode(it) },
      cursor.getString(14)?.let { localListeningProgressAdapter.listeningProgressAdapter.decode(it)
          }
    )
  }

  public fun getResumableImportsWithLocalListeningProgress(attemptsPerformedCount: Int,
      owner: String): Query<GetResumableImportsWithLocalListeningProgress> =
      getResumableImportsWithLocalListeningProgress(attemptsPerformedCount, owner) { speechifyUri,
      primaryFileBlobStorageKey, scannedPages, sourceURL, importOptions, htmlContentLoadOptions,
      lastErrorStackTrace, wasLastErrorConnectionError, importType, attemptsPerformedCount_, owner_,
      lastUpdatedAt, listeningProgress, mimeType, listeningProgress_ ->
    GetResumableImportsWithLocalListeningProgress(
      speechifyUri,
      primaryFileBlobStorageKey,
      scannedPages,
      sourceURL,
      importOptions,
      htmlContentLoadOptions,
      lastErrorStackTrace,
      wasLastErrorConnectionError,
      importType,
      attemptsPerformedCount_,
      owner_,
      lastUpdatedAt,
      listeningProgress,
      mimeType,
      listeningProgress_
    )
  }

  public fun <T : Any> getListenableImportsWithLocalListeningProgress(owner: String, mapper: (
    speechifyUri: SpeechifyURI,
    primaryFileBlobStorageKey: BlobStorageKey?,
    scannedPages: List<DbOcrFile>?,
    sourceURL: String?,
    importOptions: ImportOptions?,
    htmlContentLoadOptions: HtmlContentLoadOptions?,
    lastErrorStackTrace: String?,
    wasLastErrorConnectionError: DbBoolean?,
    importType: ImportType,
    attemptsPerformedCount: Int,
    owner: String,
    lastUpdatedAt: DateTime,
    listeningProgress: ListeningProgress?,
    mimeType: MimeType?,
    listeningProgress_: ListeningProgress?,
  ) -> T): Query<T> = GetListenableImportsWithLocalListeningProgressQuery(owner) { cursor ->
    mapper(
      pendingImportAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { pendingImportAdapter.primaryFileBlobStorageKeyAdapter.decode(it) },
      cursor.getString(2)?.let { pendingImportAdapter.scannedPagesAdapter.decode(it) },
      cursor.getString(3),
      cursor.getString(4)?.let { pendingImportAdapter.importOptionsAdapter.decode(it) },
      cursor.getString(5)?.let { pendingImportAdapter.htmlContentLoadOptionsAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7)?.let { pendingImportAdapter.wasLastErrorConnectionErrorAdapter.decode(it) },
      pendingImportAdapter.importTypeAdapter.decode(cursor.getString(8)!!),
      pendingImportAdapter.attemptsPerformedCountAdapter.decode(cursor.getLong(9)!!),
      cursor.getString(10)!!,
      pendingImportAdapter.lastUpdatedAtAdapter.decode(cursor.getDouble(11)!!),
      cursor.getString(12)?.let { pendingImportAdapter.listeningProgressAdapter.decode(it) },
      cursor.getString(13)?.let { pendingImportAdapter.mimeTypeAdapter.decode(it) },
      cursor.getString(14)?.let { localListeningProgressAdapter.listeningProgressAdapter.decode(it)
          }
    )
  }

  public fun getListenableImportsWithLocalListeningProgress(owner: String):
      Query<GetListenableImportsWithLocalListeningProgress> =
      getListenableImportsWithLocalListeningProgress(owner) { speechifyUri,
      primaryFileBlobStorageKey, scannedPages, sourceURL, importOptions, htmlContentLoadOptions,
      lastErrorStackTrace, wasLastErrorConnectionError, importType, attemptsPerformedCount, owner_,
      lastUpdatedAt, listeningProgress, mimeType, listeningProgress_ ->
    GetListenableImportsWithLocalListeningProgress(
      speechifyUri,
      primaryFileBlobStorageKey,
      scannedPages,
      sourceURL,
      importOptions,
      htmlContentLoadOptions,
      lastErrorStackTrace,
      wasLastErrorConnectionError,
      importType,
      attemptsPerformedCount,
      owner_,
      lastUpdatedAt,
      listeningProgress,
      mimeType,
      listeningProgress_
    )
  }

  public fun <T : Any> getImportWithLocalListeningProgress(speechifyUri: SpeechifyURI, mapper: (
    speechifyUri: SpeechifyURI,
    primaryFileBlobStorageKey: BlobStorageKey?,
    scannedPages: List<DbOcrFile>?,
    sourceURL: String?,
    importOptions: ImportOptions?,
    htmlContentLoadOptions: HtmlContentLoadOptions?,
    lastErrorStackTrace: String?,
    wasLastErrorConnectionError: DbBoolean?,
    importType: ImportType,
    attemptsPerformedCount: Int,
    owner: String,
    lastUpdatedAt: DateTime,
    listeningProgress: ListeningProgress?,
    mimeType: MimeType?,
    listeningProgress_: ListeningProgress?,
  ) -> T): Query<T> = GetImportWithLocalListeningProgressQuery(speechifyUri) { cursor ->
    mapper(
      pendingImportAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { pendingImportAdapter.primaryFileBlobStorageKeyAdapter.decode(it) },
      cursor.getString(2)?.let { pendingImportAdapter.scannedPagesAdapter.decode(it) },
      cursor.getString(3),
      cursor.getString(4)?.let { pendingImportAdapter.importOptionsAdapter.decode(it) },
      cursor.getString(5)?.let { pendingImportAdapter.htmlContentLoadOptionsAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7)?.let { pendingImportAdapter.wasLastErrorConnectionErrorAdapter.decode(it) },
      pendingImportAdapter.importTypeAdapter.decode(cursor.getString(8)!!),
      pendingImportAdapter.attemptsPerformedCountAdapter.decode(cursor.getLong(9)!!),
      cursor.getString(10)!!,
      pendingImportAdapter.lastUpdatedAtAdapter.decode(cursor.getDouble(11)!!),
      cursor.getString(12)?.let { pendingImportAdapter.listeningProgressAdapter.decode(it) },
      cursor.getString(13)?.let { pendingImportAdapter.mimeTypeAdapter.decode(it) },
      cursor.getString(14)?.let { localListeningProgressAdapter.listeningProgressAdapter.decode(it)
          }
    )
  }

  public fun getImportWithLocalListeningProgress(speechifyUri: SpeechifyURI):
      Query<GetImportWithLocalListeningProgress> =
      getImportWithLocalListeningProgress(speechifyUri) { speechifyUri_, primaryFileBlobStorageKey,
      scannedPages, sourceURL, importOptions, htmlContentLoadOptions, lastErrorStackTrace,
      wasLastErrorConnectionError, importType, attemptsPerformedCount, owner, lastUpdatedAt,
      listeningProgress, mimeType, listeningProgress_ ->
    GetImportWithLocalListeningProgress(
      speechifyUri_,
      primaryFileBlobStorageKey,
      scannedPages,
      sourceURL,
      importOptions,
      htmlContentLoadOptions,
      lastErrorStackTrace,
      wasLastErrorConnectionError,
      importType,
      attemptsPerformedCount,
      owner,
      lastUpdatedAt,
      listeningProgress,
      mimeType,
      listeningProgress_
    )
  }

  public fun <T : Any> getImportEndingWithIdWithLocalListeningProgress(id: String, mapper: (
    speechifyUri: SpeechifyURI,
    primaryFileBlobStorageKey: BlobStorageKey?,
    scannedPages: List<DbOcrFile>?,
    sourceURL: String?,
    importOptions: ImportOptions?,
    htmlContentLoadOptions: HtmlContentLoadOptions?,
    lastErrorStackTrace: String?,
    wasLastErrorConnectionError: DbBoolean?,
    importType: ImportType,
    attemptsPerformedCount: Int,
    owner: String,
    lastUpdatedAt: DateTime,
    listeningProgress: ListeningProgress?,
    mimeType: MimeType?,
    listeningProgress_: ListeningProgress?,
  ) -> T): Query<T> = GetImportEndingWithIdWithLocalListeningProgressQuery(id) { cursor ->
    mapper(
      pendingImportAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { pendingImportAdapter.primaryFileBlobStorageKeyAdapter.decode(it) },
      cursor.getString(2)?.let { pendingImportAdapter.scannedPagesAdapter.decode(it) },
      cursor.getString(3),
      cursor.getString(4)?.let { pendingImportAdapter.importOptionsAdapter.decode(it) },
      cursor.getString(5)?.let { pendingImportAdapter.htmlContentLoadOptionsAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7)?.let { pendingImportAdapter.wasLastErrorConnectionErrorAdapter.decode(it) },
      pendingImportAdapter.importTypeAdapter.decode(cursor.getString(8)!!),
      pendingImportAdapter.attemptsPerformedCountAdapter.decode(cursor.getLong(9)!!),
      cursor.getString(10)!!,
      pendingImportAdapter.lastUpdatedAtAdapter.decode(cursor.getDouble(11)!!),
      cursor.getString(12)?.let { pendingImportAdapter.listeningProgressAdapter.decode(it) },
      cursor.getString(13)?.let { pendingImportAdapter.mimeTypeAdapter.decode(it) },
      cursor.getString(14)?.let { localListeningProgressAdapter.listeningProgressAdapter.decode(it)
          }
    )
  }

  public fun getImportEndingWithIdWithLocalListeningProgress(id: String):
      Query<GetImportEndingWithIdWithLocalListeningProgress> =
      getImportEndingWithIdWithLocalListeningProgress(id) { speechifyUri, primaryFileBlobStorageKey,
      scannedPages, sourceURL, importOptions, htmlContentLoadOptions, lastErrorStackTrace,
      wasLastErrorConnectionError, importType, attemptsPerformedCount, owner, lastUpdatedAt,
      listeningProgress, mimeType, listeningProgress_ ->
    GetImportEndingWithIdWithLocalListeningProgress(
      speechifyUri,
      primaryFileBlobStorageKey,
      scannedPages,
      sourceURL,
      importOptions,
      htmlContentLoadOptions,
      lastErrorStackTrace,
      wasLastErrorConnectionError,
      importType,
      attemptsPerformedCount,
      owner,
      lastUpdatedAt,
      listeningProgress,
      mimeType,
      listeningProgress_
    )
  }

  public fun <T : Any> getScannedPendingImports(mapper: (
    speechifyUri: SpeechifyURI,
    primaryFileBlobStorageKey: BlobStorageKey?,
    scannedPages: List<DbOcrFile>,
    sourceURL: String?,
    importOptions: ImportOptions?,
    htmlContentLoadOptions: HtmlContentLoadOptions?,
    lastErrorStackTrace: String?,
    wasLastErrorConnectionError: DbBoolean?,
    importType: ImportType,
    attemptsPerformedCount: Int,
    owner: String,
    lastUpdatedAt: DateTime,
    listeningProgress: ListeningProgress?,
    mimeType: MimeType?,
  ) -> T): Query<T> = Query(-1_688_378_057, arrayOf("pendingImport"), driver, "pendingImport.sq",
      "getScannedPendingImports", "SELECT * FROM pendingImport WHERE scannedPages IS NOT NULL") {
      cursor ->
    mapper(
      pendingImportAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)?.let { pendingImportAdapter.primaryFileBlobStorageKeyAdapter.decode(it) },
      pendingImportAdapter.scannedPagesAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3),
      cursor.getString(4)?.let { pendingImportAdapter.importOptionsAdapter.decode(it) },
      cursor.getString(5)?.let { pendingImportAdapter.htmlContentLoadOptionsAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7)?.let { pendingImportAdapter.wasLastErrorConnectionErrorAdapter.decode(it) },
      pendingImportAdapter.importTypeAdapter.decode(cursor.getString(8)!!),
      pendingImportAdapter.attemptsPerformedCountAdapter.decode(cursor.getLong(9)!!),
      cursor.getString(10)!!,
      pendingImportAdapter.lastUpdatedAtAdapter.decode(cursor.getDouble(11)!!),
      cursor.getString(12)?.let { pendingImportAdapter.listeningProgressAdapter.decode(it) },
      cursor.getString(13)?.let { pendingImportAdapter.mimeTypeAdapter.decode(it) }
    )
  }

  public fun getScannedPendingImports(): Query<GetScannedPendingImports> =
      getScannedPendingImports { speechifyUri, primaryFileBlobStorageKey, scannedPages, sourceURL,
      importOptions, htmlContentLoadOptions, lastErrorStackTrace, wasLastErrorConnectionError,
      importType, attemptsPerformedCount, owner, lastUpdatedAt, listeningProgress, mimeType ->
    GetScannedPendingImports(
      speechifyUri,
      primaryFileBlobStorageKey,
      scannedPages,
      sourceURL,
      importOptions,
      htmlContentLoadOptions,
      lastErrorStackTrace,
      wasLastErrorConnectionError,
      importType,
      attemptsPerformedCount,
      owner,
      lastUpdatedAt,
      listeningProgress,
      mimeType
    )
  }

  public suspend fun trackImport(pendingImport: PendingImport) {
    driver.execute(-121_393_654,
        """INSERT INTO pendingImport VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""", 14) {
          bindString(0, pendingImportAdapter.speechifyUriAdapter.encode(pendingImport.speechifyUri))
          bindString(1, pendingImport.primaryFileBlobStorageKey?.let {
              pendingImportAdapter.primaryFileBlobStorageKeyAdapter.encode(it) })
          bindString(2, pendingImport.scannedPages?.let {
              pendingImportAdapter.scannedPagesAdapter.encode(it) })
          bindString(3, pendingImport.sourceURL)
          bindString(4, pendingImport.importOptions?.let {
              pendingImportAdapter.importOptionsAdapter.encode(it) })
          bindString(5, pendingImport.htmlContentLoadOptions?.let {
              pendingImportAdapter.htmlContentLoadOptionsAdapter.encode(it) })
          bindString(6, pendingImport.lastErrorStackTrace)
          bindLong(7, pendingImport.wasLastErrorConnectionError?.let {
              pendingImportAdapter.wasLastErrorConnectionErrorAdapter.encode(it) })
          bindString(8, pendingImportAdapter.importTypeAdapter.encode(pendingImport.importType))
          bindLong(9,
              pendingImportAdapter.attemptsPerformedCountAdapter.encode(pendingImport.attemptsPerformedCount))
          bindString(10, pendingImport.owner)
          bindDouble(11,
              pendingImportAdapter.lastUpdatedAtAdapter.encode(pendingImport.lastUpdatedAt))
          bindString(12, pendingImport.listeningProgress?.let {
              pendingImportAdapter.listeningProgressAdapter.encode(it) })
          bindString(13, pendingImport.mimeType?.let {
              pendingImportAdapter.mimeTypeAdapter.encode(it) })
        }.await()
    notifyQueries(-121_393_654) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun updatePrimaryFileBlobStorageKey(primaryFileBlobStorageKey: BlobStorageKey?,
      speechifyUri: SpeechifyURI) {
    driver.execute(-1_431_876_848,
        """UPDATE pendingImport SET primaryFileBlobStorageKey = ? WHERE speechifyUri = ?""", 2) {
          bindString(0, primaryFileBlobStorageKey?.let {
              pendingImportAdapter.primaryFileBlobStorageKeyAdapter.encode(it) })
          bindString(1, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(-1_431_876_848) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun removeImport(speechifyUri: SpeechifyURI) {
    driver.execute(-375_698_609, """DELETE FROM pendingImport WHERE speechifyUri = ?""", 1) {
          bindString(0, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(-375_698_609) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun updateAttemptsPerformedCount(
    attemptsPerformedCount: Int,
    lastErrorStackTrace: String?,
    wasLastErrorConnectionError: DbBoolean?,
    lastUpdatedAt: DateTime,
    speechifyUri: SpeechifyURI,
  ) {
    driver.execute(1_602_784_324,
        """UPDATE pendingImport SET attemptsPerformedCount = ?, lastErrorStackTrace = ?, wasLastErrorConnectionError = ?, lastUpdatedAt = ? WHERE speechifyUri = ?""",
        5) {
          bindLong(0,
              pendingImportAdapter.attemptsPerformedCountAdapter.encode(attemptsPerformedCount))
          bindString(1, lastErrorStackTrace)
          bindLong(2, wasLastErrorConnectionError?.let {
              pendingImportAdapter.wasLastErrorConnectionErrorAdapter.encode(it) })
          bindDouble(3, pendingImportAdapter.lastUpdatedAtAdapter.encode(lastUpdatedAt))
          bindString(4, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(1_602_784_324) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun updateImportOptions(importOptions: ImportOptions?,
      speechifyUri: SpeechifyURI) {
    driver.execute(1_023_435_818,
        """UPDATE pendingImport SET importOptions = ? WHERE speechifyUri = ?""", 2) {
          bindString(0, importOptions?.let { pendingImportAdapter.importOptionsAdapter.encode(it) })
          bindString(1, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(1_023_435_818) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun updateMimeType(mimeType: MimeType?, speechifyUri: SpeechifyURI) {
    driver.execute(1_225_764_349,
        """UPDATE pendingImport SET mimeType = ? WHERE speechifyUri = ?""", 2) {
          bindString(0, mimeType?.let { pendingImportAdapter.mimeTypeAdapter.encode(it) })
          bindString(1, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(1_225_764_349) { emit ->
      emit("pendingImport")
    }
  }

  public suspend fun updateScannedPages(scannedPages: List<DbOcrFile>?,
      speechifyUri: SpeechifyURI) {
    driver.execute(-2_037_370_717,
        """UPDATE pendingImport SET scannedPages = ? WHERE speechifyUri = ?""", 2) {
          bindString(0, scannedPages?.let { pendingImportAdapter.scannedPagesAdapter.encode(it) })
          bindString(1, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(-2_037_370_717) { emit ->
      emit("pendingImport")
    }
  }

  private inner class GetResumableImportsWithLocalListeningProgressQuery<out T : Any>(
    public val attemptsPerformedCount: Int,
    public val owner: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(2_076_485_775,
        """SELECT pendingImport.*, localListeningProgress.listeningProgress FROM pendingImport LEFT JOIN localListeningProgress ON pendingImport.speechifyUri = localListeningProgress.speechifyUri  WHERE attemptsPerformedCount < ? AND owner = ?""",
        mapper, 2) {
      bindLong(0, pendingImportAdapter.attemptsPerformedCountAdapter.encode(attemptsPerformedCount))
      bindString(1, owner)
    }

    override fun toString(): String =
        "pendingImport.sq:getResumableImportsWithLocalListeningProgress"
  }

  private inner class GetListenableImportsWithLocalListeningProgressQuery<out T : Any>(
    public val owner: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(537_634_816,
        """SELECT pendingImport.*, localListeningProgress.listeningProgress FROM pendingImport LEFT JOIN localListeningProgress ON pendingImport.speechifyUri = localListeningProgress.speechifyUri WHERE owner = ?""",
        mapper, 1) {
      bindString(0, owner)
    }

    override fun toString(): String =
        "pendingImport.sq:getListenableImportsWithLocalListeningProgress"
  }

  private inner class GetImportWithLocalListeningProgressQuery<out T : Any>(
    public val speechifyUri: SpeechifyURI,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_150_300_552,
        """SELECT pendingImport.*, localListeningProgress.listeningProgress FROM pendingImport LEFT JOIN localListeningProgress ON pendingImport.speechifyUri = localListeningProgress.speechifyUri WHERE pendingImport.speechifyUri = ?""",
        mapper, 1) {
      bindString(0, pendingImportAdapter.speechifyUriAdapter.encode(speechifyUri))
    }

    override fun toString(): String = "pendingImport.sq:getImportWithLocalListeningProgress"
  }

  private inner class GetImportEndingWithIdWithLocalListeningProgressQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("pendingImport", "localListeningProgress", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-759_228_000,
        """SELECT pendingImport.*, localListeningProgress.listeningProgress FROM pendingImport LEFT JOIN localListeningProgress ON pendingImport.speechifyUri = localListeningProgress.speechifyUri WHERE pendingImport.speechifyUri LIKE ('%/' || ?)""",
        mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String =
        "pendingImport.sq:getImportEndingWithIdWithLocalListeningProgress"
  }
}
