package com.speechify.client.internal.util.collections

import kotlin.jvm.JvmName

/**
 * A type for keys (AKA 'property names') that are used in many places but have the same meaning, which allows to have
 * them documented and functionality built around them in a strongly-typed manner, taking advantage of generics.
 *
 * Use by:
 * - creating single definition for a key - by just hold on to an instance in a variable/property
 * - or grouping related properties - by declaring a grouping subtype.
 */
internal open class KeyWithTypes<KeyId, Value>(
    /**
     * A unique identifier for the key, whose [Any.equals] and [Any.hashCode] can be used to establish whether the
     * key ids are the same.
     */
    internal val keyId: KeyId,
) {
    /**
     * A shorthand to create a [Pair] with this [keyId] and the specified [value].
     */
    fun toPairWithVal(value: Value): Pair<KeyId, Value> =
        keyId to value

    fun toPairWithValOrNull(value: Value?): Pair<KeyId, Value>? =
        if (value == null) null else toPairWithVal(value)

    open fun toPairWithFullKey(value: Value): PairWithSemanticKey<KeyId, Value> =
        this to value
}

fun <K, V> Pair<K, V?>.toNullIfValueNull(): Pair<K, V>? {
    val (key, value) = this
    return if (value == null) {
        null
    } else {
        key to value
    }
}

/**
 * A version of [KeyWithTypes] where only the value varies, while [keyId] is a [String].
 */
internal open class KeyWithValueType<Value>(keyId: String) : KeyWithTypes<String, Value>(keyId) {
    override fun toPairWithFullKey(value: Value): PairWithSemanticStringKey<Value> =
        this to value
}

/**
 * Can be used to allow easy specification of a strongly typed instances with the value of type [InputValue] (for example a
 * DateTime or Enum), while also specifying a way to convert the value to a different type [OutputValue] (typically,
 * less-strongly typed, like a String).
 */
internal open class KeyWithValueTypeAndConversion<InputValue, OutputValue>(
    keyId: String,
    private val getConvertedValue: (InputValue) -> OutputValue,
) : KeyWithValueType<OutputValue>(keyId) {

    /**
     * A shorthand to create a [Pair] with this [keyId] and the specified [value], converted to [OutputValue] using
     * [getConvertedValue].
     */
    @JvmName("toPairWithValWithInputValue")
    fun toPairWithVal(value: InputValue): Pair<String, OutputValue> =
        keyId to getConvertedValue(value)

    @JvmName("toPairWithValOrNullWithInputValue")
    fun toPairWithValOrNull(value: InputValue?): Pair<String, OutputValue>? =
        if (value == null) null else toPairWithVal(value)
}

/**
 * Can be used to pass-around a value where the key is this instance itself, and not just the primitive `KeyId`, so
 * that the property can still be inspected (checked what supertype it is, etc.).
 */
internal typealias PairWithSemanticKey<KeyId, Value> = Pair<KeyWithTypes<KeyId, Value>, Value>

/**
 * A version of [PairWithSemanticKey] for the string-keyed [KeyWithValueType].
 */
internal typealias PairWithSemanticStringKey<Value> = Pair<KeyWithValueType<Value>, Value>
