package com.speechify.client.api.adapters.firebase

import kotlin.js.JsExport

@JsExport
data class FirebaseAuthUser(
    val uid: UserId,
    val isAnonymous: Boolean,
    val displayName: String?,
    val email: String?,
)

typealias UserId = String
