package com.speechify.client.reader.epub

import com.speechify.client.api.services.library.models.UserHighlight
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.reader.core.RelativeNavigationIntent
import kotlin.js.JsExport

/**
 * Configuration class for customizing the appearance and behavior of an EPUB reader.
 *
 * This class allows dynamic updates to key visual properties of the reader, such as
 * highlight colors and font sizes, which are exposed through mutable properties.
 *
 * The dynamic updates would be useful for reactive feature, like light/dark mode of the app.
 *
 * @constructor Creates an instance of [EpubReaderConfig] with initial configuration values.
 *
 * @param sentenceHighlightHexColor Color for highlighting sentences, represented
 * as a hexadecimal color string (e.g., "#FF0000" for red).
 *
 * @param wordHighlightHexColor Color for highlighting individual words, represented
 * as a hexadecimal color string.
 *
 * @param selectionBGHexColor Background color for text selections, represented
 * as a hexadecimal color string.
 *
 * @param fontScale Font scale for the reader, represented as a [Double].
 *
 * @param relativeNavigationIntentOfTapUserAction see more [RelativeNavigationIntent]
 *
 * @param tapActionIntent see more [EpubTapActionIntent]
 *
 * @param epubPaginationOrientation Layout pagination orientation: vertical/horizontal.
 *
 * @param textColor Text color of the reader, represented as a hexadecimal color string.
 * If null, the epub default color is used.
 *
 * @param backgroundColor Background color of the reader, represented as a hexadecimal
 * color string. If null, the epub default background color is used.
 *
 * @param userHighlightColorTokens A map that stores color tokens as Pairs of <ColorToken, colorValueInHex> used for
 * user highlights' color.
 *
 * @param userHighlightFallbackColorToken A fallback highlight ColorToken.
 */
@JsExport
data class EpubReaderConfig(
    internal val relativeNavigationIntentOfTapUserAction: RelativeNavigationIntent,
    internal val tapActionIntent: EpubTapActionIntent,
    internal val sentenceHighlightHexColor: String,
    internal val wordHighlightHexColor: String,
    internal val selectionBGHexColor: String,
    internal val fontScale: Double,
    internal val epubPaginationOrientation: EpubPaginationOrientation,
    internal val textColor: String?,
    internal val backgroundColor: String?,
    internal val epubTextAdjustmentsConfig: EpubTextAdjustmentsConfig,
    internal val userHighlightColorTokens: Array<BoundaryPair<UserHighlight.Style.ColorToken, String>>,
    internal val userHighlightFallbackColorToken: UserHighlight.Style.ColorToken,
) {
    init {
        val definedTokens = userHighlightColorTokens.map { it.first }.toSet()
        val allTokens = UserHighlight.Style.ColorToken.values().toSet()

        // Ensure all ColorTokens are accounted for
        require(definedTokens == allTokens) {
            val missingTokens = allTokens - definedTokens
            "EpubReaderConfig - Invalid ColorTokens. Missing: $missingTokens"
        }

        // Validate hex color format
        val hexColorRegex = "^#(?:[0-9a-fA-F]{3}){1,2}$".toRegex()
        require(userHighlightColorTokens.all { it.second.matches(hexColorRegex) }) {
            "EpubReaderConfig - Invalid hex color codes detected"
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as EpubReaderConfig

        if (relativeNavigationIntentOfTapUserAction != other.relativeNavigationIntentOfTapUserAction) return false
        if (sentenceHighlightHexColor != other.sentenceHighlightHexColor) return false
        if (wordHighlightHexColor != other.wordHighlightHexColor) return false
        if (selectionBGHexColor != other.selectionBGHexColor) return false
        if (fontScale != other.fontScale) return false
        if (epubPaginationOrientation != other.epubPaginationOrientation) return false
        if (textColor != other.textColor) return false
        if (backgroundColor != other.backgroundColor) return false
        if (epubTextAdjustmentsConfig != other.epubTextAdjustmentsConfig) return false
        if (!userHighlightColorTokens.contentEquals(other.userHighlightColorTokens)) return false
        if (userHighlightFallbackColorToken != other.userHighlightFallbackColorToken) return false

        return true
    }

    override fun hashCode(): Int {
        var result = relativeNavigationIntentOfTapUserAction.hashCode()
        result = 31 * result + sentenceHighlightHexColor.hashCode()
        result = 31 * result + wordHighlightHexColor.hashCode()
        result = 31 * result + selectionBGHexColor.hashCode()
        result = 31 * result + fontScale.hashCode()
        result = 31 * result + epubPaginationOrientation.hashCode()
        result = 31 * result + (textColor?.hashCode() ?: 0)
        result = 31 * result + (backgroundColor?.hashCode() ?: 0)
        result = 31 * result + epubTextAdjustmentsConfig.hashCode()
        result = 31 * result + userHighlightColorTokens.contentHashCode()
        result = 31 * result + userHighlightFallbackColorToken.hashCode()
        return result
    }
}

@JsExport
enum class EpubPaginationOrientation {
    Vertical,
    Horizontal,
}

/**
 * [EpubTapActionIntent] expresses the intent of user tap action: it could be Play or Jump.
 */
@JsExport
sealed class EpubTapActionIntent {
    object Play : EpubTapActionIntent()
    object Jump : EpubTapActionIntent()
}

internal inline fun EpubPaginationOrientation.isVertical() = this == EpubPaginationOrientation.Vertical
internal inline fun EpubPaginationOrientation.isHorizontal() = this == EpubPaginationOrientation.Horizontal
