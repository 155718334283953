package com.speechify.client.api.services.ebook

import com.speechify.client.api.adapters.blobstorage.ChunkableEncoder
import com.speechify.client.api.adapters.encription.EncryptionAdapter
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.util.orThrow

internal class SecureEbookFileEncoderV1(
    override val id: String,
    private val userId: UserId,
    private val privateKey: String,
    private val encryptionAdapter: EncryptionAdapter,
) : ChunkableEncoder {
    override fun encodeChunk(data: ByteArray): ByteArray {
        val key = encryptionAdapter.sha256("$privateKey:$id:$userId")
        return encryptionAdapter.encryptWithAes256cbc(data, key).orThrow()
    }

    override fun decodeChunk(data: ByteArray): ByteArray {
        val key = encryptionAdapter.sha256("$privateKey:$id:$userId")
        return encryptionAdapter.decryptAes256cbc(data, key).orThrow()
    }
}
