package com.speechify.client.api.adapters.mediaplayer

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
interface LocalMediaPlayerAdapter {
    @JsName("createMediaPlayer")
    fun createMediaPlayer(
        mediaUrl: String,
        initialOptions: LocalMediaPlayerOptions,
        callback: Callback<LocalMediaPlayer>,
    )
}

internal suspend fun LocalMediaPlayerAdapter.coCreateMediaPlayer(
    mediaUrl: String,
    initialOptions: LocalMediaPlayerOptions,
): Result<LocalMediaPlayer> = suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachRetainingResult(
    onCancellationLeavingJobRunning = {
        // TODO #TODOImplementCancellations
    },
) { /* Cancellable, despite lack of cancellation API, so that
 this suspension doesn't block cancellations of this coroutine - this is safe, because the code is a value retrieval
  without any side effects. */
    createMediaPlayer(mediaUrl, initialOptions, it::resume)
}

internal fun LocalMediaPlayerAdapter.traced() =
    if (Log.isDebugLoggingEnabled) LocalMediaPlayerAdapterTraced(this) else this

internal class LocalMediaPlayerAdapterTraced(private val localMediaPlayerAdapter: LocalMediaPlayerAdapter) :
    LocalMediaPlayerAdapter {
    override fun createMediaPlayer(
        mediaUrl: String,
        initialOptions: LocalMediaPlayerOptions,
        callback: Callback<LocalMediaPlayer>,
    ) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL LocalMediaPlayerAdapter.createMediaPlayer($mediaUrl, $initialOptions)",
            sourceAreaId = "LocalMediaPlayerAdapterTraced.createMediaPlayer",
        )
        localMediaPlayerAdapter.createMediaPlayer(mediaUrl, initialOptions, taggedCallback)
    }
}
