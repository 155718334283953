package com.speechify.client.api.services.library.models

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.offline.OfflineAvailabilityStatus
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.mapNotNull
import kotlin.js.JsExport

@JsExport
data class FilterAndSortOptions(
    val type: FilterType,
    val sortBy: SortBy,
    val sortOrder: SortOrder,
)

@JsExport
enum class SortOrder {
    ASC,
    DESC,
}

@JsExport
sealed class SortBy {
    object ALPHABETICAL : SortBy()
    object DATE_ADDED : SortBy()
    object RECENTLY_LISTENED : SortBy()
    internal object DATE_ARCHIVED : SortBy()
}

@JsExport
abstract class SearchableFilterType

@JsExport
sealed class FilterType : SearchableFilterType() {
    object ANY : FilterType()

    /**
     * This filter will match all library items that are folders.
     */
    object FOLDERS : FilterType()

    /**
     * This filter will match archived items.
     */
    object TOP_LEVEL_ARCHIVED_ITEMS : FilterType()

    /**
     * TODO(chin) the filters below require some work for the search backend
     * [PLT-3105] - https://linear.app/speechify-inc/issue/PLT-3105/support-for-listening-progress-filters-for-search-api
     */

    /**
     * This filter will match all library items where `progressFraction` is less than 1.
     * This means items that haven't been started are also included.
     */
    object LISTENING_IN_PROGRESS_AND_NOT_STARTED : FilterType()

    /**
     * This filter will match all library items where `progressFraction` is less than 1
     * but bigger than 0.
     */
    object LISTENING_IN_PROGRESS : FilterType()

    /**
     * This filter will match all library items where `progressFraction` is equal to 0.
     */
    object LISTENING_NOT_STARTED : FilterType()

    /**
     * This filter will match all library items where `progressFraction` is equal to 1.
     */
    object LISTENING_FINISHED : FilterType()

    /**
     * This filter will match all library items that have been made available offline.
     */
    object AVAILABLE_OFFLINE : ApplyLocallyFilter() {

        override suspend fun shouldInclude(libraryItem: LibraryItem): Boolean = when (libraryItem) {
            is LibraryItem.ListenableContent -> {
                try {
                    val status = libraryItem.offlineAvailabilityStatusFlow.mapNotNull { it }.first()
                    status == OfflineAvailabilityStatus.AVAILABLE
                } catch (e: Exception) {
                    Log.e(
                        DiagnosticEvent(
                            message = "Failed to get offline availability status for library item.",
                            nativeError = e,
                            sourceAreaId = "FilterAndSortOptions.AVAILABLE_OFFLINE.shouldInclude",
                        ),
                    )
                    false
                }
            }
            is LibraryItem.Folder -> true
        }
    }

    /**
     * Base class for filters that need to perform some local filtering.
     */
    abstract class ApplyLocallyFilter : FilterType() {
        internal abstract suspend fun shouldInclude(libraryItem: LibraryItem): Boolean
    }

    class RECORDS internal constructor(internal val recordTypes: Array<RecordType> = emptyArray()) :
        FilterType() {
        companion object {
            /**
             * Creates RECORD filter for the given record types.
             *
             * @param recordTypes A list of record types to create a RECORD filter for.
             *
             * @return The created RECORD filter.
             */
            fun ofTypes(recordTypes: Array<RecordType>): RECORDS {
                return RECORDS(recordTypes)
            }

            /**
             * Creates RECORD filter without specific types.
             *
             * @param recordTypesToExclude A list of record types ignore
             *
             * @return The created RECORD filter.
             */
            fun excluding(recordTypesToExclude: Array<RecordType>): RECORDS {
                return RECORDS(RecordType.values().filter { !recordTypesToExclude.contains(it) }.toTypedArray())
            }

            /**
             * Creates RECORD filter for all the existing record types
             *
             * @return The created RECORD filter.
             */
            fun all(): RECORDS {
                return RECORDS()
            }
        }
    }

    fun and(other: FilterType): FilterType {
        return CompoundFilterType(
            filterTypes = listOf(this, other),
            logicalOperator = CompoundFilterType.LogicalOperator.AND,
        )
    }
}

internal data class CompoundFilterType(
    val filterTypes: List<FilterType>,
    val logicalOperator: LogicalOperator,
) : FilterType() {

    enum class LogicalOperator {
        AND,
        // we could have more operators here like OR, but not adding for now as it leads to breaking changes and not needed at the moment
    }
}
