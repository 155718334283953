package com.speechify.client.api.adapters.pdf

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * An efficient, minimal wrapper for accessing the text and image contained in a single page of a PDF.
 */
@JsExport
interface PDFPageAdapter : Destructible {
    /**
     * The zero-based index of this page in the PDF document
     */
    val pageIndex: Int

    /**
     * Get metadata describing this page
     */
    @JsName("getMetadata")
    fun getMetadata(): PDFPageMetadata

    /**
     * Get the background image of this page, rendered according to the options provided
     */
    fun getImage(
        options: PDFPageImageOptions,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    )

    /**
     * Get the text content (and their coordinates) contained in this page
     */
    @JsName("getTextContent")
    fun getTextContent(callback: Callback<Array<PDFPageTextContentItem>>)

    /**
     * Get the text content in the bounding boxes provided as input
     */
    @JsName("getTextBetween")
    fun getTextBetween(
        boxes: Array<BoundingBox>,
        callback: Callback<TextInBoundingBoxResult>,
    )
}

internal suspend fun PDFPageAdapter.coGetImage(options: PDFPageImageOptions) =
    suspendCoroutine { cont -> getImage(options, cont::resume) }

internal suspend fun PDFPageAdapter.coGetTextContent() = suspendCoroutine { cont -> getTextContent(cont::resume) }

internal suspend fun PDFPageAdapter.coGetTextBetween(
    boxes: List<BoundingBox>,
) = suspendCoroutine { cont -> getTextBetween(boxes.toTypedArray(), cont::resume) }

@JsExport
data class TextInBoundingBoxResult(
    val text: String,
    val characterBoxes: List<BoundingBox>? = null,
)
