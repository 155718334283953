package com.speechify.client.helpers.content.standard

import com.speechify.client.api.content.ObjectRef
import kotlinx.coroutines.flow.Flow
import kotlin.js.JsExport

/**
 * The members of [ContentSequenceCharacteristics] that are provided by SDK-consumer type implementations.
 * ([ContentSequenceCharacteristics.isContentMutable] is not here, because it's implemented on the constructor level,
 * because it changes the [ContentProvider] sub-interface to use - see [com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderBase]).
 */
@JsExport
interface HasInfoOfEffectOfPullingContentOnUser {
/* NEXT STEPS:

(as noted [here with Roman](https://speechifyworkspace.slack.com/archives/D04580TTH7S/p1699968573698279) and discussed
[here with Aaditya](https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1700069064162699?thread_ts=1699879114.197099&cid=C02LEG7AEGM))

Depending on what the stakeholders call satisfactory, there may still be some follow-up work to make user experience ideal:
1.  Build an API to be used with a memory-less  `DynamicStandardView`  , with own cache strategy (whatever SDK consumer needs - can be completely mutable imperatively with  `add`/`remove`)
2.  Detect if mutation happened to content already in the audio buffer, and reload the audio if the change is still before current playback position (This would be bigger, and I think that to deliver it in a maintainable way, it would be best to actually first deliver the HTML model, and then refactor playback stack to pass rich data to the player)
3.  Preventing speech problems of [skipping content/reading content twice] on insertion/deletion into the area that the playback already passed (caused by design of playback-stack: the current reading position can only be remembered as an index, rather than holding onto a location in the document). (similarly to Pt 2., it would be best to actually first deliver the HTML model, and then refactor playback stack to pass rich data to the player)
*/
    /**
     * Answers the general question if pulling content can disturb the user in some way.
     * TODO: Solve this by using #TODOSolveLiveContentWithFlushingToken.
     *  Notably, this will likely need to first deliver a stream-of-tokens model ticketed as [PLT-3012](https://linear.app/speechify-inc/issue/PLT-3012/audio-improvements-connect-rich-content-em-p-to-ssml-generation-speech)
     * - https://linear.app/speechify-inc/issue/PLT-3012/audio-improvements-connect-rich-content-em-p-to-ssml-generation-speech
     *
     * - Use `false` when content can be pulled as needed, without effect on the user, e.g. when some method exists
     *   of getting any chunk from the source without affecting the UI used by the user (e.g. a rest API or scraping
     *   data model of the document). This will enable SDK to use full buffering to prevent hangs if synthesis is slow
     *   (due to slow connection, fast speaking speed chosen by the user, slow synthesis, or a combination thereof).
     * - Use `true` when loading content can have an effect on the user - i.e. for Kindle, where page needs to be
     *   flipped, or Google Docs, where the page needs to be scrolled into view to access its contents.
     * https://speechifyworkspace.slack.com/archives/C03LS9C1SUV/p1693391044207999
     */
    val doesPullingContentCauseEffectOnUser: Boolean

    /**
     * TODO the [aimedCharsCountInBatchOverride] member could be done away with, if we had a solution to all TODOs
     *  in the KDoc below (#TODOSolveProperlyWorkaroundOfAimedCharsCountInBatchOverride)
     */
    /**
     * An override over [com.speechify.client.api.audio.defaultAimedCharsCountInBatch]. See its documentation.
     * A `null` will cause no change to the default of [com.speechify.client.api.audio.defaultAimedCharsCountInBatch].
     *
     * A non-null amount can be used:
     * - when there's a need to minimize buffer, e.g. when content mutates, so minimum audio should always be
     *   produced. Note that this has a downside of causing the utterances to be shorter, which may make the speech
     *   not as natural and fluent, and also may cause hangs due to the overhead of many synthesis requests.
     *   TODO solve this properly (#TODOMutationDetectionInAlreadyBufferedContent). E.g. by rather buffering normal
     *    audio amount - as is needed for smooth playback, while accepting mutation events and regenerating when
     *    detected that a mutation occurred to content for which audio is already buffered
     * - or when the content comes from a live source and at certain places everything needs to be spoken in the buffer
     *   and the speech waiting for full `aimedCharsCountInBatch` would make the speech not finish the last utterance.
     *   TODO solve this properly (#TODOSolveLiveContent). E.g.
     *    - by adding a timeout like:
     *      `val aimedCharsCountInBatchTimeOutMsOverride: Int?`
     *      (would need to change [com.speechify.client.api.audio.SingleSpecsMediaSynthesisService]
     *       to introduce a timeout where using [com.speechify.client.internal.util.extensions.collections.windowedToBatchesOfAimedSizeSumWithMapAndRealignment]):
     *    - or by passing a token which would signify an end-of-current-utterance-waiting-for-next-from-live, which,
     *      upon encountering, would force the playback to synthesize and speak what's currently in the buffer
     *      (#TODOSolveLiveContentWithFlushingToken).
     *
     * NOTE for SDK developers: Do not read this directly but through [getAimedCharsCountInUtteranceOverrideOrNullSafely].
     */
    val aimedCharsCountInBatchOverride: Int? get() =
        /* need to use a `get()` because it's an interface, so this is the only way we can provide a default value. */
        null
}

/**
 * An interface for providing/reading information on the nature of the content, regarding whether the 'life' of the
 * [ObjectRef]s that the SDK was given, to support experiences where rendering the content is ephemeral, i.e. gets lost
 * if the user makes it out-of-view, for the purpose of memory efficiency.
 */
@JsExport
interface HasInfoOfContentLimitedLife {
    /**
     * Answers whether the [ref]
     * See documentation in the overrides where `false` can be returned (so, not having a hardcoded `true`) for specific
     * consequences for the behavior:
     * - [com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderForImmutableLimitedLifeContent.isAlive]
     * - [com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProviderForMutableLimitedLifeContent.isAlive]
     *
     */
    fun isAlive(ref: ObjectRef<Any?>): Boolean
}

/**
 * An interface for providing/reading information on the nature of the content, relevant for when speech is synthesised
 * for it.
 */
interface ContentSequenceCharacteristics :
    HasInfoOfEffectOfPullingContentOnUser,
    HasInfoOfContentLimitedLife {
    /*
     * Informs whether this content can mutate (e.g. user can edit it, or it can live-update itself).
     * TODO solve properly and remove the need for this property:
     *  - The problem of SDK consumers having to refresh audio-buffer (possibly causing glitch) if they want edits to
     *    what's already in the audio buffer be reflected. See #TODOMutationDetectionInAlreadyBufferedContent
     *    for description of the strategy to do it.
     *  - The problem of SDK not using any memory for mutable content (#MutableDynamicContentByHavingNoMemory), so
     *    consumers having to implement their own caching if they want to prevent stopping when the content scrolls out of view.
     *    This can be fixed by the combination of addressing #TODOMutationDetectionInAlreadyBufferedContent and
     *    #TODOSolveLiveContentWithFlushingToken - thanks to knowing about mutations, memory can be reintroduced
     *    and content could always be buffered as much as useful (up to the token signifying end-of-current-utterance-waiting-for-next-from-live),
     *    and the memory would be removed only when a mutation happens.
     */
    val contentMutationsInfo: ContentMutationsInfo
}

sealed class ContentMutationsInfo {
    abstract val isMutable: Boolean

    object Immutable : ContentMutationsInfo() {
        override val isMutable: Boolean get() = false
    }
    class Mutable(
        val mutationsFlow: Flow<Unit>,
    ) : ContentMutationsInfo() {
        override val isMutable: Boolean get() = true
    }
}

internal fun ContentSequenceCharacteristics.getAimedCharsCountInUtteranceOverrideOrNullSafely() =
    aimedCharsCountInBatchOverride
        .also {
            check(it == null || it > 0) {
                "aimedCharsCountInBatchOverride must be null or positive"
            }
        }

interface ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent : ContentSequenceCharacteristics {
    override val contentMutationsInfo: ContentMutationsInfo get() =
        ContentMutationsInfo.Immutable

    override val doesPullingContentCauseEffectOnUser: Boolean get() =
        false

    override val aimedCharsCountInBatchOverride: Int? get() =
        null

    override fun isAlive(ref: ObjectRef<Any?>): Boolean =
        true
}

/**
 * Groups components that provide access to stand-alone unit of content that can be synthesised.
 * Typically, this will be a document, but through advanced mechanisms like
 * [com.speechify.client.helpers.content.standard.dynamic.contentProviders.DynamicContentProvider] may include amorphous
 * contents, including a virtual 'window into multiple sources of content' selected by the user through navigation.
 */
interface ContentProvider : ContentSequenceCharacteristics
