package com.speechify.client.api.content.epubV3.builders

import com.speechify.client.api.content.epubV3.Href
import com.speechify.client.api.content.epubV3.Item
import com.speechify.client.api.content.epubV3.Link
import com.speechify.client.api.content.epubV3.Spine
import com.speechify.client.api.util.MimeType

internal class EpubResourceBuilder(
    private val spine: Spine,
    private val manifest: List<Item>,
) {
    data class Links(
        val readingOrder: List<Link>,
        val resources: List<Link>,
    )

    @Suppress("Unchecked_cast")
    private val itemById = manifest
        .filter { it.id != null }
        .associateBy(Item::id) as Map<String, Item>

    fun build(): Links {
        val readingOrderIds = spine.itemRefs.map { it.idRef }
        val readingOrder = readingOrderIds.mapNotNull { id ->
            itemById[id]?.let { item -> computeLink(item) }
        }
        val readingOrderAllIds = computeIdsWithFallbacks(readingOrderIds)
        val resourceItems = manifest.filterNot { it.id in readingOrderAllIds }
        val resources = resourceItems.map { computeLink(it) }
        return Links(readingOrder, resources)
    }

    /** Recursively find the ids contained in fallback chains of items with [ids]. */
    private fun computeIdsWithFallbacks(ids: List<String>): Set<String> {
        val fallbackIds: MutableSet<String> = mutableSetOf()
        ids.forEach { fallbackIds.addAll(computeFallbackChain(it)) }
        return fallbackIds
    }

    /** Compute the ids contained in the fallback chain of item with [id]. */
    private fun computeFallbackChain(id: String): Set<String> {
        // The termination has already been checked while computing links
        val ids: MutableSet<String> = mutableSetOf()
        val item = itemById[id]
        item?.id?.let { ids.add(it) }
        return ids
    }

    private fun computeLink(item: Item): Link {
        return Link(
            href = Href(item.href),
            mediaType = item.mediaType?.let { MimeType(it) },
        )
    }
}
