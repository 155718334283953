package com.speechify.client.api.adapters.localsynthesis

import kotlin.js.JsExport

@JsExport
sealed class LocalSpeechSynthesisEvent(val type: String) {
    object Started : LocalSpeechSynthesisEvent("started")
    data class Progressed(val currentCharacterIndex: Int) :
        LocalSpeechSynthesisEvent("progressed")

    object Paused : LocalSpeechSynthesisEvent("paused")

    object Ended : LocalSpeechSynthesisEvent("ended")
    object Canceled : LocalSpeechSynthesisEvent("cancelled")

    override fun toString() = "${this::class.simpleName ?: this::class}($type)"
}
