// ktlint-disable filename
package com.speechify.client.internal.util.extensions.strings

import com.speechify.client.internal.util.extensions.intentSyntax.nullIf

/**
 * NOTE: The delimiter will not be included any part of the result. See [splitInTwoOnFirstLeaveDelimiterInFirst] for a
 * variant that does.
 *
 * NOTE: If delimiter is not found, the [Pair.second] of the result will be null, and the [Pair.first] will contain the
 * full string of [this].
 *
 * NOTE: If the delimiter is at the very beginning or at the end, then the corresponding member [Pair] will be an empty
 * string.
 */
internal fun String.splitInTwoOnFirst(delimiter: String): Pair<String, String?> =
    this.splitInTwoOnDelimiterRangeImpl(
        delimiterRange = indexOf(delimiter).nullIf { this < 0 }
            ?.let { delimiterStartIdx ->
                delimiterStartIdx until delimiterStartIdx + delimiter.length
            },
    )

/**
 * Overload for [Regex] [delimiter] - see [splitInTwoOnLast] for behavior.
 */
internal fun String.splitInTwoOnFirst(delimiter: Regex): Pair<String, String?> =
    this.splitInTwoOnDelimiterRangeImpl(
        delimiterRange = delimiter.find(input = this)?.range,
    )

/**
 * A variant of [splitInTwoOnFirst] that searches for the first delimiter from the end of the string.
 */
internal fun String.splitInTwoOnLast(delimiter: String): Pair<String, String?> =
    this.splitInTwoOnDelimiterRangeImpl(
        delimiterRange = lastIndexOf(delimiter).nullIf { this < 0 }
            ?.let { delimiterStartIdx ->
                delimiterStartIdx until delimiterStartIdx + delimiter.length
            },
    )

/**
 * A variant of split-in-two which leaves the delimiter in the first part of the result.
 *
 * NOTE: If delimiter is not found, the [Pair.second] of the result will be null, and the [Pair.first] will contain the
 * full string of [this].
 *
 * NOTE: If the delimiter is at the end, then [Pair.first] will be the entire [this] and [Pair.second] will be an empty
 * string, while if the delimiter is at the very beginning, then the [Pair.first] will be the delimiter and
 * [Pair.second] will be the remaining string.
 */
internal fun String.splitInTwoOnFirstLeaveDelimiterInFirst(delimiter: String): Pair<String, String?> =
    this.splitInTwoOnDelimiterRangeImpl(
        delimiterRange = indexOf(delimiter).nullIf { this < 0 }
            ?.let { delimiterStartIdx ->
                delimiterStartIdx + delimiter.length until delimiterStartIdx + delimiter.length
            },
    )

/**
 * NOTE: If delimiter is not provided, the [Pair.second] of the result will be `null`, and the [Pair.first] will contain
 * the full string of [this].
 *
 * NOTE: If the delimiter is at the very beginning or at the end, then the corresponding member [Pair] will be an empty
 * string.
 */
private fun String.splitInTwoOnDelimiterRangeImpl(
    delimiterRange: IntRange?,
): Pair<String, String?> =
    if (delimiterRange == null) {
        (this to null)
    } else {
        (
            this.substring(
                startIndex = 0,
                endIndex = delimiterRange.first,
            ) to this.substring(startIndex = delimiterRange.last + 1)
            )
    }
