package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.transform

/**
 * Like [kotlinx.coroutines.flow.onEach] but the [action] is called *after* the downstream collects the item.
 */
internal fun <T> Flow<T>.onEachAfterDownstream(action: suspend (T) -> Unit): Flow<T> =
    transform { value ->
        emit(value)
        action(value)
    }
