package com.speechify.client.internal.util.extensions.coroutines

import com.speechify.client.internal.createTopLevelScopesContext
import com.speechify.client.internal.util.extensions.intentSyntax.ignoreValue
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.job
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

/**
 * Especially useful for preventing extra indentation when adding `launch` wrappers to synchronous, [Unit]-returning
 * functions (kotlin linter prevents indentation to function body when it is declared using `=` and the wrapper is added
 * in the same line as `=`, for example ` = wrapper {` ).
 *
 * This is essentially a version of [kotlinx.coroutines.launch] which does not produce a [Job] and this function
 * makes it visibly intentful using the return type and the name (the job can still be controlled by the parent scope
 * so this is not a resource-leak).
 *
 */
internal inline fun CoroutineScope.launchJobControlledFromScopeOnly(
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    noinline block: suspend CoroutineScope.() -> Unit,
) {
    this@launchJobControlledFromScopeOnly.launch(context, start, block)
}

/**
 * A version of [createChildScopeAndCompletableSupervisorJob] returning only the [CoroutineScope]
 * (use [kotlinx.coroutines.cancel] to cancel the scope. If any other way of completing is needed, use [createChildScopeAndCompletableSupervisorJob]).
 * TODO consider replacing usages with [createChildScopeAndCompletableSupervisorJob], which reminds of completing the scope/job */
internal fun CoroutineScope.createChildSupervisorScope(): CoroutineScope {
    val (scope, _) = createChildScopeAndCompletableSupervisorJob()
    return scope
}

/**
 * Similar to [createChildSupervisorJob] but for where [CoroutineContext] already exists and should be retained, rather
 * than coming from the call.
 *
 * WARNING:
 *  When creating a scope in this way, you **may cause the parent job to never complete** (See
 *  [createChildSupervisorJob], and especially #DangerOfNeverCompleting for more on this).
 *  The safer alternative is [launchAndWaitWithoutFailingOnError] which will complete the job when the block is done.
 */
internal fun CoroutineScope.createChildScopeAndCompletableSupervisorJob(): Pair<CoroutineScope, CompletableJob> {
    val job = this.coroutineContext.job.createChildSupervisorJob()
    return CoroutineScope(
        this.coroutineContext + job,
    ) to
        job
}

/**
 * Similar to [createChildSupervisorJob] but for where [CoroutineContext] already exists and should be retained, rather
 * than coming from the call.
 *
 * WARNING:
 *  When creating a scope in this way, you **may cause the parent job to never complete** (See
 *  [createChildSupervisorJob], and especially #DangerOfNeverCompleting for more on this).
 *  The safer alternative is [launchAndWaitWithoutFailingOnError] which will complete the job when the block is done.
 */
internal fun createTopLevelCoroutineScopeWithCompletableSupervisorJob(
    contextToMerge: CoroutineContext? = null,
    parentJob: Job? = null,
): Pair<CoroutineScope, CompletableJob> {
    val context: CoroutineContext = createTopLevelScopesContext(contextToMerge)

    val job: CompletableJob = SupervisorJob(parent = parentJob)
    return CoroutineScope(context + job) to job
}

/**
 * A version of [createChildScopeAndCompletableSupervisorJob] but without the 'supervisor' behavior.
 *
 * Reminder - see [Job.createChildSupervisorJob] for caveats, especially the #DangerOfNeverCompleting
 */
internal fun CoroutineScope.createChildScopeAndCompletableJob(): Pair<CoroutineScope, Job> {
    val job = this.coroutineContext.job.createChildCompletableJob()
    return CoroutineScope(
        this.coroutineContext + job,
    ) to
        job
}

/**
 * A version of [createTopLevelCoroutineScopeWithCompletableSupervisorJob] but without the 'supervisor' behavior, so a
 * failure of any child of the returned [CompletableJob] will also fail the returned [CompletableJob] (and [parentJob]
 * if provided).
 *
 * Reminder - see [Job.createChildSupervisorJob] for caveats, especially the #DangerOfNeverCompleting
 */
internal fun createTopLevelCoroutineScopeWithCompletableJob(
    shouldFailIfAnyChildFails: Boolean,
    contextToMerge: CoroutineContext? = null,
    parentJob: Job? = null,
): Pair<CoroutineScope, CompletableJob> {
    val context: CoroutineContext = createTopLevelScopesContext(contextToMerge)

    val job: CompletableJob = if (shouldFailIfAnyChildFails) {
        Job(parent = parentJob)
    } else {
        SupervisorJob(parent = parentJob)
    }
    return CoroutineScope(context + job) to job
}

/**
 * In contrast to [kotlinx.coroutines.supervisorScope], this function will not throw if an exception happens (but this
 * one is only good for [Unit] blocks).
 *
 * NOTE: Exceptions will reach whatever [CoroutineExceptionHandler] is in [this] scope's
 * [CoroutineScope.coroutineContext] (typically an error-reporter from the top-level coroutine scope).
 */
internal suspend fun launchAndWaitWithoutFailingOnError(
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
) =
    coroutineScope {
        this.launchWithoutFailingOnError(
            context = context,
            start = start,
            block = block,
        )
            /** No need for [Job.join], because [coroutineScope] already does this for us. */
            .ignoreValue()
    }

/**
 * An equivalent of [launch] that uses a [SupervisorJob] underneath, so does not fail the job if an exception happens
 * in the block.
 * NOTE: As with the [launch], This function is only valid to use on [Unit]-result [block]s.
 * For non-Unit blocks, use [kotlinx.coroutines.supervisorScope], but take note that throwing from the block there also
 * throws into the caller's coroutine (even for [Unit]-returning blocks), so if you aren't careful you may still cause
 * the entire parent coroutine to be stopped & failed.
 *
 * See also [com.speechify.client.internal.util.runCatchingSilencingErrorsLoggingThem] for a version that awaits the
 * [block].
 */
internal fun CoroutineScope.launchWithoutFailingOnError(
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
): Job {
    val scope = this@launchWithoutFailingOnError

    /** We don't use [kotlinx.coroutines.supervisorScope], because it works like [coroutineScope], in that it waits
     * for all children to complete, and we want to return immediately (to achieve the desired effect, we'd need
     * `launch { supervisorScope { launch {`, so one more middleman-Job).
     */

    /* Need to hold on to the `childJob` to make sure it is completed, or else `coroutineContext` and `withContext`
     with this function call inside it would hang forever.
     */
    val (supervisorScope, completableSupervisorJob) = scope.createChildScopeAndCompletableSupervisorJob()

    try {
        val childJob = supervisorScope.launch(
            context = context,
            start = start,
            block = {
                try {
                    block()
                } finally {
                    if (!completableSupervisorJob.isCancelled) {
                        /** Complete `completableSupervisorJob` so that everything is cleaned up afterward. */

                        /** It needs to be completed successfully even in an exception happens
                         * Failing it using [CompletableJob.completeExceptionally] would actually fail its parent,
                         * making `completableSupervisorJob` not do what it was involved here for.
                         */
                        completableSupervisorJob.complete()
                    }
                }
            },
        )
        /**
         * Return the child job, so that the caller can inspect its outcome (e.g. after [Job.join]ing it).
         */
        return childJob
    } catch (e: Throwable) {
        /**
         * Note that this is an exception from the [launch] call, not from the [block] itself. We're just making sure
         * that, in this unlikely event, `completableSupervisorJob` is not left as a 'supposedly still running' coroutine.
         */
        completableSupervisorJob.completeExceptionally(e)
        throw e
    }
}

/**
 * A shortcut saving one indentation level on [launch] and [coroutineScope] (a device for parallel work decomposition
 * which creates a single Job that can be used to wait/control all the parallel jobs).
 */
internal fun CoroutineScope.launchCoroutineScope(
    context: CoroutineContext = EmptyCoroutineContext,
    start: CoroutineStart = CoroutineStart.DEFAULT,
    block: suspend CoroutineScope.() -> Unit,
): Job =
    launch(
        context = context,
        start = start,
        block = {
            coroutineScope {
                block()
            }
        },
    )

/**
 * Useful when the work started by the [block] is not needed to continue, and rather is just needed to get some first
 * product of the work, and then should be cancelled.
 * Example use is for [kotlinx.coroutines.flow.stateIn].
 */
internal suspend fun <R> coroutineScopeForSingleExpression(
    block: suspend CoroutineScope.() -> R,
): R =
    coroutineScope {
        val (scopeForBlock, completableJob) = this.createChildScopeAndCompletableJob()
        val result = try {
            scopeForBlock.block()
        } catch (e: Throwable) {
            if (!completableJob.isCancelled) {
                completableJob.cancel(
                    cause = if (e is CancellationException) {
                        e
                    } else {
                        CancellationException(
                            message = "`block` threw an exception.",
                            cause = e,
                        )
                    },
                )
            }
            throw e
        }
        completableJob.cancel()
        return@coroutineScope result
    }
