package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentCursor
import kotlin.js.JsExport

@JsExport
object CursorQueryBuilder {
    /**
     * Get a [CursorQuery] that will evaluate to the start of the content
     */
    fun fromStart() = CursorQuery.fromStart()

    /**
     * Get a [CursorQuery] that will evaluate to the end of the content
     */
    fun fromEnd() = CursorQuery.fromEnd()

    /**
     * Get a [CursorQuery] that will evaluate to the [cursor] provided
     */
    fun fromCursor(cursor: ContentCursor) = CursorQuery.fromCursor(cursor)
}
