package com.speechify.client.reader.core

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.sample
import kotlin.js.JsExport

@OptIn(FlowPreview::class)
@JsExport
class AutoscrollHelper internal constructor(
    scope: CoroutineScope,
    playbackState: Flow<PlaybackState>,
    initialIsEnabled: Boolean,
) : Helper<AutoscrollState>(scope) {
    override val stateFlow: MutableStateFlow<AutoscrollState> = MutableStateFlow(AutoscrollState(initialIsEnabled))
    override val initialState = stateFlow.value

    private val currentLocationFlow = MutableStateFlow<SerialLocation?>(null)

    init {
        commands
            .onEachInstance<AutoscrollHelperCommand> {
                when (it) {
                    AutoscrollHelperCommand.Disable -> {
                        stateFlow.value = AutoscrollState.Disabled
                    }

                    AutoscrollHelperCommand.Enable -> {
                        stateFlow.value = AutoscrollState.Enabled
                    }

                    is AutoscrollHelperCommand.EnableWithLocation -> {
                        currentLocationFlow.value = it.location
                        stateFlow.value = AutoscrollState.Enabled
                    }

                    is AutoscrollHelperCommand.UpdateLocation -> {
                        currentLocationFlow.value = it.location
                    }
                }
            }
            .launchInHelper()

        playbackState
            .mapNotNull { it.location }
            .distinctUntilChanged() // only fire when location changes, since buffer state changes often
            .sample(periodMillis = 500) // only update every 500ms, since not worth perf overhead to have more precision
            .onEach {
                currentLocationFlow.value = it
            }
            .launchInHelper()

        combine(stateFlow, currentLocationFlow) { autoscroll, currentLocation ->
            if (!autoscroll.isEnabled || currentLocation == null) return@combine
            dispatch(
                NavigationCommand.GoTo(
                    location = currentLocation.toRobustLocation(),
                    intent = NavigationIntent.GoToCurrentPlaybackLocation(),
                ),
            )
        }
            .launchInHelper()
    }

    fun enable() {
        dispatch(AutoscrollHelperCommand.Enable)
    }

    fun disable() {
        dispatch(AutoscrollHelperCommand.Disable)
    }
}

internal sealed class AutoscrollHelperCommand {
    object Enable : AutoscrollHelperCommand()
    object Disable : AutoscrollHelperCommand()

    data class EnableWithLocation(val location: SerialLocation) : AutoscrollHelperCommand()
    data class UpdateLocation(val location: SerialLocation) : AutoscrollHelperCommand()
}

@JsExport
data class AutoscrollState(
    val isEnabled: Boolean,
) {
    internal companion object {
        internal val Enabled = AutoscrollState(isEnabled = true)
        internal val Disabled = AutoscrollState(isEnabled = false)
    }
}
