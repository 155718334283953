package com.speechify.client.api.adapters.pdf

import kotlin.js.JsExport

/**
 * Options for rendering the PDF page image to ensure that it is generated at the appropriate resolution.
 */
@JsExport
data class PDFPageImageOptions(
    /**
     * Scale factor applied to the original dimensions. For example, 1.0 is equal to the original dimensions.
     */
    val scale: Double,
)
