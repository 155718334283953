package com.speechify.client.bundlers.reading.importing

import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.importing.SpeechifyUriWithInitializer
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.library.LibraryServiceDelegate
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.RecordType
import com.speechify.client.api.services.scannedbook.models.LazyOCRFiles
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.content.standard.html.asRecordProperties
import com.speechify.client.internal.launchAsync
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.services.importing.ImportableContentPayload
import com.speechify.client.internal.util.diagnostics.enriching.errorEnrichingWithTags
import kotlinx.coroutines.Deferred

internal interface ContentImporterFactory<in Payload : ImportableContentPayload> {
    fun createContentImporter(
        payload: Payload,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentImporter
}

/**
 * Create a [ContentImporter] based on any blob content (content representable with
 * [ImportableContentPayload.ImportableContentPayloadOfSingleBlob]).
 */
internal typealias BinaryContentImporterFactory = ContentImporterFactory<
    ImportableContentPayload.ImportableContentPayloadOfSingleBlob,
    >

/**
 * Create a [ContentImporter] based on any content representable with [ImportableContentPayload].
 */
internal class AllImportableContentImporterFactory(
    private val importService: ImportService,
    private val libraryServiceDelegate: LibraryServiceDelegate,
) : ContentImporterFactory<ImportableContentPayload> {
    fun createForAlreadyImportedResource(
        uri: SpeechifyURI,
        /** Set to improve efficiency of loading by saving a request on resolving the library item. */
        libraryItem: LibraryItem.Content,
    ): AlreadyImportedToLibraryImporter =
        AlreadyImportedToLibraryImporter(uri, libraryItem)

    override fun createContentImporter(
        payload: ImportableContentPayload,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        customProperties: Sequence<Pair<String, Any>>,
        importStartChoice: ImportStartChoice,
        bundleMetadata: BundleMetadata?,
    ): ContentImporter {
        val placeholderItemURI = importStartChoice.createPlaceholderIfChosenAsync(
            deviceLocalContent = deviceLocalContent,
            recordType = payload.recordType,
            speechifyEntityType = payload.speechifyEntityType,
        )

        return when (payload) {
            is ImportableContentPayload.ImportableContentPayloadOfSingleBlob ->
                createForBinaryContentUnstarted(
                    payload = payload,
                    placeholderItemURI = placeholderItemURI,
                    deviceLocalContent = deviceLocalContent,
                    customProperties = customProperties,
                    bundleMetadata = bundleMetadata,
                )
            is ImportableContentPayload.OCR ->
                createForOCRFilesUnstarted(
                    ocrFiles = payload.ocrFiles,
                    placeholderItemURI = placeholderItemURI,
                    deviceLocalContent = deviceLocalContent,
                    bundleMetadata = bundleMetadata,
                )
        }
            .also {
                payload.parsedContentsForImport.contentBundle.startImmediateImportIfChosen(importStartChoice)
            }
    }

    private fun createForBinaryContentUnstarted(
        payload: ImportableContentPayload.ImportableContentPayloadOfSingleBlob,
        placeholderItemURI: SpeechifyUriWithInitializer?,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        customProperties: Sequence<Pair<String, Any>>,
        bundleMetadata: BundleMetadata?,
    ): ContentImporter {
        return ContentImporterImpl(
            placeholderItemURI = placeholderItemURI,
            existingLocalItem = deviceLocalContent,
            startImportAction = { options: ImportOptions, speechifyURI: SpeechifyUriWithInitializer? ->
                if (bundleMetadata != null) {
                    options.analyticsProperties
                        .set("contentSubType", bundleMetadata.contentSubType)
                }
                importService.getOrCreateSharedFlowImportingBinaryContentByUpload(
                    payload = payload,
                    options = options,
                    existingSpeechifyUri = speechifyURI ?: importService.createSpeechifyUriWithInitializingLibraryItem(
                        recordType = payload.recordType,
                        speechifyEntityType = SpeechifyEntityType.LIBRARY_ITEM,
                        options,
                    ),
                    customProperties = customProperties,
                )
            },
            libraryServiceDelegate,
        )
    }

    private fun createForOCRFilesUnstarted(
        ocrFiles: LazyOCRFiles,
        placeholderItemURI: SpeechifyUriWithInitializer?,
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        bundleMetadata: BundleMetadata?,
    ): ContentImporter {
        return ContentImporterImpl(
            placeholderItemURI = placeholderItemURI,
            existingLocalItem = deviceLocalContent,
            startImportAction = { options: ImportOptions, speechifyURI: SpeechifyUriWithInitializer? ->
                if (bundleMetadata != null) {
                    options.analyticsProperties
                        .set("contentSubType", bundleMetadata.contentSubType)
                }
                importService.getOrCreateSharedFlowImportingScannedBook(
                    files = ocrFiles,
                    options = options,
                    existingSpeechifyUri = speechifyURI ?: importService.createSpeechifyUriWithInitializingLibraryItem(
                        recordType = RecordType.SCAN,
                        speechifyEntityType = SpeechifyEntityType.SCANNED_BOOK,
                        options,
                    ),
                    // We need to set it to true since the event will be triggered from the
                    // contentBundle.importer.stateFlow. THis way, we don't trigger the `File Imported` event twice
                    shouldIgnoreLoggingEvent = true,
                )
            },
            libraryServiceDelegate,
        )
    }

    private fun ImportStartChoice.createPlaceholderIfChosenAsync(
        deviceLocalContent: LibraryItem.DeviceLocalContent?,
        recordType: RecordType,
        speechifyEntityType: SpeechifyEntityType,
    ): SpeechifyUriWithInitializer? {
        if (deviceLocalContent != null) {
            // If we already have the item in the DB the user can already see it so we don't need to
            // to create a placeholder item.
            return SpeechifyUriWithInitializer(deviceLocalContent.uri, launchAsync { deviceLocalContent.uri })
        }

        return if (this is ImportStartChoice.StartImmediately) {
            importService.createSpeechifyUriWithInitializingLibraryItem(recordType, speechifyEntityType, this.options)
        } else {
            null
        }
    }
}

internal fun BinaryContentImporterFactory.createForHtmlContent(
    payload: ImportableContentPayload.Html,
    deviceLocalContent: LibraryItem.DeviceLocalContent?,
    options: HtmlContentLoadOptions,
    importStartChoice: ImportStartChoice,
    bundleMetadata: BundleMetadata?,
): ContentImporter =
    createContentImporter(
        payload = payload,
        deviceLocalContent = deviceLocalContent,
        importStartChoice = importStartChoice,
        /**
         * TODO Consider making `customProperties` part of [ImportableContentPayload], removing the need for this
         *  extension method.
         */
        customProperties = options.asRecordProperties(),
        bundleMetadata = bundleMetadata,
    )

private fun Deferred<
    /**
     * Taking [ContentBundle] as the receiver to always perform start on [ContentBundle.importer], and never on
     * just any [ContentImporter], because [ContentBundle.importer] is a wrapped version with extra functionality in
     * [ContentBundle] (e.g. Title extraction).
     */
    ContentBundle,
    >.startImmediateImportIfChosen(importStartChoice: ImportStartChoice) {
    if (importStartChoice is ImportStartChoice.StartImmediately) {
        launchTask {
            errorEnrichingWithTags("ContentBundle.startImmediateImportIfChosen") {
                this@startImmediateImportIfChosen.await().importer.startImport(importStartChoice.options).orThrow()
            }
        }
    }
}
