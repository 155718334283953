package com.speechify.client.internal.services.importing

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.SpeechifyVersions
import com.speechify.client.api.adapters.archiveFiles.ArchiveFilesAdapter
import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.adapters.imageConversion.ImageConverter
import com.speechify.client.api.adapters.pdf.PDFDocumentAdapter
import com.speechify.client.api.adapters.pdf.coGetThumbnail
import com.speechify.client.api.adapters.xml.XMLParser
import com.speechify.client.api.content.epub.EpubParser
import com.speechify.client.api.content.epub.EpubVersion
import com.speechify.client.api.content.epubV3.EpubParserV3
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.importing.models.ImportStartChoice
import com.speechify.client.api.services.library.models.RecordType
import com.speechify.client.api.services.scannedbook.models.LazyOCRFiles
import com.speechify.client.api.services.scannedbook.models.OCRFile
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.toFile
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toResultFailure
import com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.ContentModelsImportDependencies
import com.speechify.client.bundlers.content.GenericFilePayload
import com.speechify.client.bundlers.content.ListenableBinaryContentPayload
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.content.standard.html.asRecordProperties
import com.speechify.client.helpers.features.ListeningProgress
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.caching.getDownloadUrl
import com.speechify.client.internal.caching.putFile
import com.speechify.client.internal.launchAsync
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.importing.models.ContentSummaryInfo
import com.speechify.client.internal.services.importing.models.CreateFileFromWebLinkPayload
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.library.LibraryFirebaseDataFetcher
import com.speechify.client.internal.services.library.PlatformShareService
import com.speechify.client.internal.services.library.getLibraryItemPath
import com.speechify.client.internal.services.library.models.FirebaseListeningProgress
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.services.scannedbook.libraryItemWithScannedBookFields
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.IdGenerator
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.collections.maps.BlockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.mapOfNotNullValues
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancelChildren
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.toList
import kotlinx.coroutines.job
import kotlinx.coroutines.launch

internal class PlatformImportService(
    private val authService: AuthService,
    private val clientConfig: ClientConfig,
    private val firebaseStorage: ReadWriteThroughCachedFirebaseStorage,
    private val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
    private val platformShareService: PlatformShareService,
    private val idGenerator: IdGenerator,
    private val libraryFirebaseDataFetcher: LibraryFirebaseDataFetcher,
    private val platformScannedBookService: PlatformScannedBookService,
    private val imageConverter: ImageConverter,
    private val xmlParser: XMLParser,
    private val htmlParser: HTMLParser,
    private val archiveFilesAdapter: ArchiveFilesAdapter,
) {
    /**
     * NOTE: [sourceURL] and [publicBucketFileLocation] can't both be `null`.
     */
    private val currentlyRunningImports =
        BlockingThreadsafeMap<SpeechifyURI, Job>()

    internal suspend fun createFileFromWebLink(
        sourceURL: String?,
        publicBucketFileLocation: PublicBucketFileLocation?,
        recordType: RecordType,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    ): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }
        try {
            firebaseFunctionsService.createFileFromWebLink(
                CreateFileFromWebLinkPayload(
                    userId = user.uid,
                    client = clientConfig.appEnvironment,
                    dateAdded = DateTime.now().asSeconds(),
                    url = sourceURL ?: publicBucketFileLocation?.downloadUrl
                        ?: throw NullPointerException(
                            "`sourceURL` and `publicBucketFileLocation` can't both be `null` ",
                        ),
                    sourceStoredURL = publicBucketFileLocation?.downloadUrl,
                    /* TODO: make `createFileFromWebLink` readily receive payload to reduce orphaned data on
                        network failure in this call, which is a privacy problem (we capture user's data and don't allow
                        them to delete it), although [a guaranteed solution may still require a cleanup
                         job](https://www.notion.so/fresh-hoodie-9f1/Errors-reporting-passing-handling-practices-guide-8fb4f2f0aaae4e23aceaa3aa83deee93#04c7db2ff6424c20b27b860a069ffcdb) */
                    storageBucket = publicBucketFileLocation?.location?.bucketId,
                    storagePath = publicBucketFileLocation?.location?.filePathAtBucket,
                    recordUid = speechifyUri.id,
                    type = recordType,
                    recordTitle = options?.title?.nullIf { isEmpty() },
                ),
            ).orThrow()

            firebaseFirestoreService.coSetDocument(
                "items",
                speechifyUri.id,
                merge = true,
                value = createLibraryItemToFirestorePayload(
                    owner = user.uid,
                    coverImagePath = options?.coverImageUrl,
                    lastUpdatedPageId = null,
                    /* TODO: make `createFileFromWebLink` readily set sourceURL (ideally
                        `sourceStoredURL` too), to avoid two states visible to the user, and possible inconsistency on
                        network failure in this call */
                    sourceURL = sourceURL ?: publicBucketFileLocation?.downloadUrl,
                    /* Doing this for historic reasons.
                    See #SettingSourceStoredURL_to_sourceURL */
                    sourceStoredURL = publicBucketFileLocation?.downloadUrl,
                    appEnvironment = clientConfig.appEnvironment,
                    title = options?.title,
                    firebaseTimestampAdapter = firebaseTimestampAdapter,
                    recordType = recordType.name,
                    parentFolderId = options?.parentFolder?.id,
                    customProperties = customProperties,
                    analyticsProperties = options?.analyticsProperties ?: SdkBoundaryMap.empty(),
                ),
            ).orThrow()

            return Unit.successfully()
        } catch (e: Throwable) {
            try {
                setItemStatusAsError(speechifyUri).orThrow()
            } catch (exceptionWhileMarkingItemAsError: Throwable) {
                e.addSuppressed(exceptionWhileMarkingItemAsError)
            }
            return e.toResultFailure()
        }
    }

    /**
     * Call this as the first step in an import, even before required files are uploaded to Firebase Storage.
     * The item is marked as initializing, which allows the backend to override data, making this also supported
     * for uploading files that require the backend CF to do processing.
     */
    internal suspend fun createInitializingItem(
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        recordType: RecordType = RecordType.FILE,
        mergeIfAlreadyExists: Boolean,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    ) {
        val user = authService.getCurrentUser().orThrow()

        firebaseFirestoreService.coSetDocument(
            "items",
            speechifyUri.id,
            createProcessingLibraryItemToFirestorePayload(
                appEnvironment = clientConfig.appEnvironment,
                firebaseTimestampAdapter = firebaseTimestampAdapter,
                owner = user.uid,
                lastUpdatedPageId = null,
                title = options?.title,
                recordType = recordType.toString(),
                parentFolderId = options?.parentFolder?.id,
                customProperties = customProperties,
                analyticsProperties = options?.analyticsProperties ?: SdkBoundaryMap.empty(),
                initialStatus = "initializing",
                createdBySDKVersion = SpeechifyVersions.SDK_VERSION,
                createdByAppVersion = clientConfig.appVersion,
                excerpt = null,
                wordCount = null,
                charCount = null,
            ),
            merge = mergeIfAlreadyExists,
        ).orThrow()
    }

    private suspend fun uploadBinaryContentToBucket(
        payload: BinaryContentWithMimeTypePayload<*, *>,
        speechifyUri: SpeechifyURI,
    ): Result<PublicBucketFileLocation> {
        val user = authService.getCurrentUser().orReturn { return it }

        val bucketId = "${clientConfig.googleProjectId}.appspot.com"
        val filePathAtBucket = "multiplatform/import/${user.uid}/${speechifyUri.id}"
        val uploadedFileGsUri = "gs://$bucketId/$filePathAtBucket"

        /** TODO use [ReadWriteThroughCachedFirebaseStorage.putFileByMove] for files that came
         *   from an internet download, so that temporary files are not left behind in the cache.
         *   #TODOCommunicateFileMoveSemanticsForStoringTempFiles
         */
        firebaseStorage.putFile(
            ref = uploadedFileGsUri,
            payload,
        )
            .orReturn { return it }

        val downloadUrl = firebaseStorage.getDownloadUrl(uploadedFileGsUri).orReturn { return it }

        return PublicBucketFileLocation(
            downloadUrl = downloadUrl,
            location = BucketFileLocation(
                bucketId = bucketId,
                filePathAtBucket = filePathAtBucket,
            ),
        ).successfully()
    }

    internal class BucketFileLocation(val bucketId: String, val filePathAtBucket: String)

    /**
     * A bucket file location that also has a publicly accessible URL.
     */
    internal class PublicBucketFileLocation(val downloadUrl: String, val location: BucketFileLocation)

    internal suspend fun copySharedItemToUsersLibrary(sharedItemId: String): Result<SpeechifyURI> {
        val user = authService.getCurrentUser().orReturn { return it }

        if (platformShareService.isSharedItemV1(sharedItemId) is Result.Success) {
            return when (
                val responseResult =
                    firebaseFunctionsService.copyLibraryItemV1(
                        buildCopyLibraryItemV1Payload(sharedItemId),
                    )
            ) {
                is Result.Success -> {
                    SpeechifyURI.fromExistingId(
                        SpeechifyEntityType.LIBRARY_ITEM,
                        responseResult.value.recordId,
                    ).successfully()
                }

                is Result.Failure -> {
                    responseResult
                }
            }
        } else {
            val newItemId = idGenerator.getGuidAsString()
            val payload = buildCopyLibraryItemV2Payload(sharedItemId, newItemId)
            return when (val copyResult = firebaseFunctionsService.copyLibraryItemV2(payload)) {
                is Result.Success -> {
                    SpeechifyURI.fromExistingId(
                        SpeechifyEntityType.LIBRARY_ITEM,
                        payload.newRecordUid,
                    ).successfully()
                }

                is Result.Failure -> {
                    copyResult
                }
            }
        }
    }

    internal suspend fun setItemStatusAsError(speechifyUri: SpeechifyURI): Result<Unit> =
        firebaseFirestoreService.coSetDocument(
            "items",
            speechifyUri.id,
            setLibraryItemStatusToErrorPayload(firebaseTimestampAdapter),
        )

    internal suspend fun processPdf(
        pdfContentPayload: ImportableContentPayload.Pdf,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    ): Result<Unit> {
        val parsedPdf = pdfContentPayload.parsedContentsForImport
        performImport(
            speechifyUri = speechifyUri,
            recordType = RecordType.PDF,
            options = options,
            customProperties = customProperties,
            obtainCoverImage = {
                parsedPdf.pdfDocument.coGetThumbnail().toFile()
            },
            obtainContentSummaryInfo = {
                pdfContentPayload.getContentSummaryInfo()
            },
            obtainFallbackTitle = {
                parsedPdf.pdfDocument.title
            },
            obtainBinaryPayload = {
                pdfContentPayload.listenableBinaryContentPayload
            },
        )

        return Unit.successfully()
    }

    internal suspend fun processTxtFile(
        txtContentPayload: ImportableContentPayload.PlainTextOrMarkdown,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    ): Result<Unit> {
        val txtBinaryContentPayload = txtContentPayload.listenableBinaryContentPayload
        val txtFile = txtBinaryContentPayload.contentWithMimeType
        val bytes = txtFile.coGetAllBytes().orThrow()
        val txtString = bytes.decodeToString()
        val newTitle = options?.title ?: getTitleFromTxtString(txtString)
        performImport(
            speechifyUri,
            RecordType.TXT,
            options,
            customProperties,
            obtainCoverImage = {
                null
            },
            obtainContentSummaryInfo = {
                txtContentPayload.getContentSummaryInfo()
            },
            obtainFallbackTitle = {
                newTitle
            },
            obtainBinaryPayload = {
                txtBinaryContentPayload
            },
        )

        return Unit.successfully()
    }

    internal suspend fun processHtml(
        htmlContentPayload: ImportableContentPayload.Html,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
    ): Result<Unit> {
        val htmlBinaryContentPayload = htmlContentPayload.listenableBinaryContentPayload
        // We create an initializing item before doing anything else so the user can see it in
        // their library immediately. Specifically it is created as initializing otherwise the backend will refuse
        // to update it.
        createInitializingItem(
            speechifyUri,
            options,
            RecordType.fromMimeType(htmlBinaryContentPayload.contentWithMimeType.mimeType),
            mergeIfAlreadyExists = false,
            customProperties,
        )

        try {
            val uploadFileToBucketResult = uploadBinaryContentToBucket(htmlBinaryContentPayload, speechifyUri)
                .orReturn { return it }

            /* TODO(yakeen) phase out uploading and diff bot in favour SDK's parser */
            return createFileFromWebLink(
                sourceURL = htmlContentPayload.listenableBinaryContentPayload.sourceUrl,
                publicBucketFileLocation = uploadFileToBucketResult,
                RecordType.fromMimeType(htmlBinaryContentPayload.contentWithMimeType.mimeType),
                speechifyUri,
                options = if (options?.title != null) {
                    options
                } else {
                    (options ?: ImportOptions()).copy(
                        /* TODO - check if in this use case (used by bundlers-approach) it's safe to defer choosing the title to the
                             backend and remove this if so. */
                        title = "Untitled file",
                    )
                },
                customProperties = customProperties,
            ).orThrow().successfully()
        } catch (e: Throwable) {
            try {
                setItemStatusAsError(speechifyUri).orThrow()
            } catch (exceptionWhileMarkingItemAsError: Throwable) {
                e.addSuppressed(exceptionWhileMarkingItemAsError)
            }
            return e.toResultFailure()
        }
    }

    internal suspend fun processParsedHtmlFile(
        htmlOptions: HtmlContentLoadOptions?,
        htmlFile: File,
        coverImgFile: File?,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        payload: ImportableContentPayload.Html,
    ): Result<Unit> {
        performImport(
            speechifyUri = speechifyUri,
            recordType = RecordType.WEB,
            options = options,
            customProperties = htmlOptions?.asRecordProperties() ?: emptySequence(),
            obtainCoverImage = {
                coverImgFile
            },
            obtainContentSummaryInfo = {
                payload.getContentSummaryInfo()
            },
            obtainFallbackTitle = {
                "Untitled File"
            },
            obtainBinaryPayload = {
                ListenableBinaryContentPayload.Html(htmlFile, htmlOptions?.sourceUrl)
            },
        )

        return Unit.successfully()
    }

    internal suspend fun importScannedBook(
        files: Flow<OCRFile>,
        speechifyUri: SpeechifyURI,
        options: ImportOptions?,
        telemetryContext: TelemetryEventBuilder? = null,
    ): Result<Unit> {
        val filesAsList = files.toList()

        performImport(
            speechifyUri,
            RecordType.SCAN,
            options,
            emptySequence(),
            obtainCoverImage = {
                val thumbnail = imageConverter.cappedWidthJpeg(
                    inputImage = filesAsList.first().source,
                    targetMaxWidth = 440,
                    targetQualityPercent = 80,
                ).orThrow()

                thumbnail.toFile()
            },
            obtainFallbackTitle = {
                // grab title from first page if title isn't provided
                getTitleFromTxtString(filesAsList.first().ocrResult.rawText)
            },
            obtainContentSummaryInfo = {
                filesAsList.getContentSummaryInfo()
            },
            obtainBinaryPayload = {
                null
            },
        ) { user ->
            // Upload the scanned pages
            val orderedPageUploadResults = filesAsList.mapIndexed { idx, elt ->
                // We provide an explicit ID here so if we rerun the import it always creates the same pages.
                return@mapIndexed platformScannedBookService.addPageWithId(
                    pageId = "initial-page-$idx",
                    user = user,
                    scannedBookId = speechifyUri.id,
                    pageContent = elt.ocrResult,
                    pageImage = elt.source,
                ).orThrow()
            }

            // TODO: Technically if a user uses the ScannedBookService to mutate the document this will overwrite the
            // ordering losing their pages. Do we know if any product teams allow users to do this?
            libraryFirebaseDataFetcher.updateItemDataFromParams(
                speechifyUri.id,
                libraryItemWithScannedBookFields(orderedPageUploadResults.map { it.pageId }.asSequence()),
            ).orThrow()
        }

        return Unit.successfully()
    }

    private suspend fun performImport(
        speechifyUri: SpeechifyURI,
        recordType: RecordType,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
        obtainCoverImage: suspend () -> File?,
        obtainContentSummaryInfo: suspend () -> ContentSummaryInfo?,
        /**
         * An initial title will be set from the option if available or null if not.
         * The final title will be set from the result of this function.
         * If you don't have a title return the options one or a non-null fallback.
         */
        obtainFallbackTitle: suspend () -> String,
        obtainBinaryPayload: suspend () -> ListenableBinaryContentPayload<*, *, *>?,
        /**
         * An optional step that will be run in parallel with other import steps that allows custom work only applicable
         * to this kind of resource to be done, for example uploading scanned pages.
         */
        customStep: (suspend (user: FirebaseAuthUser) -> Unit)? = null,
    ) {
        val user = authService.getCurrentUser().orThrow()
        val itemPath = getLibraryItemPath(speechifyUri.id)
        val contentSummaryInfo = obtainContentSummaryInfo()
        val textualCount = contentSummaryInfo?.textualCount

        // Before we do anything we immediately create a record in the library with status "initializing".
        // All nullables passed in here will not overwrite anything set already on the item.
        firebaseFirestoreService.coSetDocument(
            itemPath,
            createProcessingLibraryItemToFirestorePayload(
                appEnvironment = clientConfig.appEnvironment,
                firebaseTimestampAdapter = firebaseTimestampAdapter,
                owner = user.uid,
                lastUpdatedPageId = null,
                title = options?.title,
                recordType = recordType.toString(),
                parentFolderId = options?.parentFolder?.id,
                customProperties = customProperties,
                analyticsProperties = options?.analyticsProperties ?: SdkBoundaryMap.empty(), // TODO: Is this needed?
                initialStatus = "initializing",
                createdBySDKVersion = SpeechifyVersions.SDK_VERSION,
                createdByAppVersion = clientConfig.appVersion,
                excerpt = contentSummaryInfo?.excerpt,
                wordCount = textualCount?.wordCount,
                charCount = textualCount?.charCount,
            ),
            // We merge to make this idempotent when rerunning.
            merge = true,
        ).orThrow()
        var uploadJob: Deferred<Job>? = null
        try {
            uploadJob = launchAsync {
                launch {
                    // Upload the thumbnail.
                    var coverImagePath = options?.coverImageUrl
                    if (coverImagePath == null) {
                        // We use the same ID as the root document for the image file name.
                        // This guarantees that rerunning the import doesn't generate more legacy pages.
                        val coverImageFile = obtainCoverImage() ?: return@launch
                        coverImagePath = libraryFirebaseDataFetcher.storeThumbnail(
                            GenericFilePayload(coverImageFile),
                            // We use the item ID as the filename for the cover image so this is idempotent.
                            coverImageFilename = speechifyUri.id,
                            fileExtensionDotless = "png",
                            /* Hardcoded only to preserve original behavior - could be improved if
                                        there are any other mimetypes  */
                            user = user,
                        ).orThrow()
                    }

                    // And make an update of only the cover image path.
                    firebaseFirestoreService.coUpdateDocument(
                        itemPath,
                        partialUpdateLibraryItemToFirestorePayload(
                            firebaseTimestampAdapter,
                            coverImagePath = coverImagePath,
                        ),
                    ).orThrow()
                }

                // Only fetch a fallback title if needed.
                if (options?.title == null) {
                    launch {
                        // Set the correct title on the item.
                        val fallbackTitle = obtainFallbackTitle()
                        firebaseFirestoreService.coUpdateDocument(
                            itemPath,
                            partialUpdateLibraryItemToFirestorePayload(
                                firebaseTimestampAdapter,
                                title = options?.title ?: fallbackTitle,
                            ),
                        ).orThrow()
                    }
                }

                launch {
                    // Upload the main binary content of this library item.
                    val binaryPayload = obtainBinaryPayload() ?: return@launch
                    // TODO is there a way to make this typing easier?
                    val storageLink = when (binaryPayload) {
                        is ListenableBinaryContentPayload.Html -> uploadBinaryContentToBucket(
                            binaryPayload,
                            speechifyUri,
                        )

                        is ListenableBinaryContentPayload.Pdf -> uploadBinaryContentToBucket(
                            binaryPayload,
                            speechifyUri,
                        )

                        is ListenableBinaryContentPayload.PlainTextOrMarkdown -> uploadBinaryContentToBucket(
                            binaryPayload,
                            speechifyUri,
                        )

                        is ListenableBinaryContentPayload.Epub -> uploadBinaryContentToBucket(
                            binaryPayload,
                            speechifyUri,
                        )

                        is ListenableBinaryContentPayload.SpeechifyBook -> throw Error(
                            "Unsupported Speechify Book for import",
                        )
                    }.orThrow().downloadUrl

                    firebaseFirestoreService.coUpdateDocument(
                        itemPath,
                        partialUpdateLibraryItemToFirestorePayload(
                            firebaseTimestampAdapter,
                            sourceURL = binaryPayload.sourceUrl ?: storageLink,
                            sourceStoredURL = storageLink,
                        ),
                    ).orThrow()
                }

                launch {
                    // Allow callers to perform any custom work they need to do before the item can be marked done.
                    customStep?.invoke(user)
                }
            }
            currentlyRunningImports.put(speechifyUri, uploadJob)
            uploadJob.await()
            // Once all the above tasks successfully processed we can mark the item as ready.
            firebaseFirestoreService.coUpdateDocument(
                itemPath,
                partialUpdateLibraryItemToFirestorePayload(
                    firebaseTimestampAdapter,
                    status = "success",
                ),
            ).orThrow()
        } catch (e: CancellationException) {
            if (uploadJob?.job != null) {
                uploadJob.job.cancel()
            }
            // don't mark as error, but rethrow the exception
            throw e
        } catch (e: Throwable) {
            try {
                setItemStatusAsError(speechifyUri).orThrow()
            } catch (exceptionWhileMarkingItemAsError: Throwable) {
                e.addSuppressed(exceptionWhileMarkingItemAsError)
            }
            throw e
        }
    }

    fun cancelImport(speechifyUri: SpeechifyURI) {
        val job = currentlyRunningImports[speechifyUri]

        if (job != null) {
            val event = DiagnosticEvent(
                message =
                "Canceling import which will result in in orphan files in firestore " +
                    "since the upload is not canceled.",
                properties = mapOf("speechifyUri" to speechifyUri.toString()),
                sourceAreaId = "PlatformImportService.cancelImport",
            )

            Log.d(event)
            job.cancelChildren()

            // We can't use cancelAndJoin() yet because we don't have control over the underlying upload in the adapater,
            // which does not actually cancel the upload yet. To be addresed in PLT-3365
            job.cancel()
            currentlyRunningImports.remove(speechifyUri)
        }
    }

    internal suspend fun processEpub(
        epubContentPayload: ImportableContentPayload.Epub,
        options: ImportOptions?,
        customProperties: Sequence<Pair<String, Any>> = emptySequence(),
        speechifyUri: SpeechifyURI,
    ): Result<Unit> {
        val user = authService.getCurrentUser().orReturn { return it }
        val (coverImage, title) = extractEpubCoverImageAndTitle(epubContentPayload)
        performImport(
            speechifyUri = speechifyUri,
            recordType = RecordType.EPUB,
            options = options,
            customProperties = customProperties,
            obtainBinaryPayload = {
                epubContentPayload.listenableBinaryContentPayload
            },
            obtainContentSummaryInfo = {
                epubContentPayload.getContentSummaryInfo()
            },
            obtainCoverImage = { coverImage?.toFile() },
            obtainFallbackTitle = { title ?: "Untitled Book" },
        )

        return Unit.successfully()
    }

    private suspend fun extractEpubCoverImageAndTitle(epubContentPayload: ImportableContentPayload.Epub) =
        when (clientConfig.options.epubVersion) {
            EpubVersion.V1 -> {
                EpubParser(
                    archiveFilesAdapter = archiveFilesAdapter,
                    xmlParser = xmlParser,
                    htmlParser = htmlParser,
                ).parseEpub(epubContentPayload.listenableBinaryContentPayload.contentWithMimeType.binaryContent).let {
                    it.coverImage to it.title
                }
            }
            EpubVersion.V2 -> {
                EpubParser(
                    archiveFilesAdapter = archiveFilesAdapter,
                    xmlParser = xmlParser,
                    htmlParser = htmlParser,
                ).parseEpubV2(epubContentPayload.listenableBinaryContentPayload.contentWithMimeType.binaryContent).let {
                    it.coverImage to it.title
                }
            }
            EpubVersion.V3 -> {
                EpubParserV3(
                    archiveFilesAdapter = archiveFilesAdapter,
                    xmlParser = xmlParser,
                ).parseEpubV3(epubContentPayload.listenableBinaryContentPayload.contentWithMimeType.binaryContent).let {
                    it.coverImage to it.title
                }
            }
        }

    suspend fun setItemListeningProgress(uri: SpeechifyURI, listeningProgress: ListeningProgress) {
        firebaseFirestoreService.coSetDocument(
            "items",
            uri.id,
            mapOf(
                "listeningProgress" to FirebaseListeningProgress
                    .fromListeningProgress(listeningProgress)
                    .toBoundaryMap(firebaseTimestampAdapter),
            ).toBoundaryMap(),
            merge = true,
        ).orThrow()
    }

    suspend fun applyImportOptions(uri: SpeechifyURI, importOptions: ImportOptions) {
        firebaseFirestoreService.coSetDocument(
            "items",
            uri.id,
            mapOfNotNullValues(
                *importOptions.title?.let {
                    arrayOf(
                        "title" to it,
                        "titleLowercase" to it.lowercase(),
                    )
                }.orEmpty(),
                "parentFolderId" to importOptions.parentFolder?.id,
                RecordProperties.analyticsProperties.key.toPairWithVal(importOptions.analyticsProperties),
            ).toBoundaryMap(),
            merge = true,
        ).orThrow()
    }
}

internal sealed class ImportableContentPayload {
    abstract val recordType: RecordType
    abstract val speechifyEntityType: SpeechifyEntityType
    abstract val parsedContentsForImport: ParsedContentsForImport

    open class ParsedContentsForImport(
        val contentBundle:
            /**
             * The reason for this being a [Deferred] is to encourage prompt importing which, in case
             * [ImportStartChoice] is [ImportStartChoice.StartImmediately], will immediately create a placeholder item
             * in the library even before the content has been fully parsed.
             */
            Deferred<ContentBundle>,
    )

    /**
     * Groups those [ImportableContentPayload] which comprise a single blob (e.g. a single Text, HTML, or PDF file).
     */
    sealed class ImportableContentPayloadOfSingleBlob : ImportableContentPayload() {
        override val speechifyEntityType: SpeechifyEntityType get() =
            SpeechifyEntityType.LIBRARY_ITEM

        abstract val listenableBinaryContentPayload: ListenableBinaryContentPayload<*, *, *>

        abstract override val parsedContentsForImport: ParsedContentsForImportOfSingleBlob

        open class ParsedContentsForImportOfSingleBlob(
            contentBundle: Deferred<ContentBundle>,
        ) : ParsedContentsForImport(
            contentBundle = contentBundle,
        ) {
            val contentModels: Deferred<ContentModelsImportDependencies> get() =
                contentBundle
        }
    }

    class Pdf(
        override val listenableBinaryContentPayload: ListenableBinaryContentPayload.Pdf,
        override val parsedContentsForImport: ParsedContentsForImportOfPdf,
    ) : ImportableContentPayloadOfSingleBlob() {
        override val recordType: RecordType
            get() =
                RecordType.PDF

        class ParsedContentsForImportOfPdf(
            val pdfDocument: PDFDocumentAdapter,
            contentBundle: Deferred<ContentBundle>,
        ) : ParsedContentsForImportOfSingleBlob(
            contentBundle = contentBundle,
        )
    }

    class PlainTextOrMarkdown(
        override val listenableBinaryContentPayload: ListenableBinaryContentPayload.PlainTextOrMarkdown,
        override val parsedContentsForImport: ParsedContentsForImportOfSingleBlob,
    ) : ImportableContentPayloadOfSingleBlob() {
        override val recordType: RecordType
            get() =
                RecordType.TXT
    }

    open class Html(
        override val listenableBinaryContentPayload: ListenableBinaryContentPayload.Html,
        override val parsedContentsForImport: ParsedContentsForImportOfSingleBlob,
    ) : ImportableContentPayloadOfSingleBlob() {
        override val recordType: RecordType
            get() =
                RecordType.WEB
    }

    class Epub(
        override val listenableBinaryContentPayload: ListenableBinaryContentPayload.Epub,
        override val parsedContentsForImport: ParsedContentsForImportOfSingleBlob,
    ) : ImportableContentPayloadOfSingleBlob() {
        override val recordType: RecordType
            get() =
                RecordType.EPUB
    }

    class SpeechifyBook(
        override val listenableBinaryContentPayload: ListenableBinaryContentPayload.SpeechifyBook,
        override val parsedContentsForImport: ParsedContentsForImportOfSingleBlob,
    ) : ImportableContentPayloadOfSingleBlob() {
        override val recordType: RecordType
            get() =
                RecordType.SPEECHIFY_BOOK
    }

    class OCR(
        val ocrFiles: LazyOCRFiles,
        override val parsedContentsForImport: ParsedContentsForImportOfOcr,
    ) : ImportableContentPayload() {
        override val recordType: RecordType
            get() =
                RecordType.SCAN

        override val speechifyEntityType: SpeechifyEntityType get() =
            SpeechifyEntityType.SCANNED_BOOK

        class ParsedContentsForImportOfOcr(
            contentBundle: Deferred<ContentBundle>,
        ) : ParsedContentsForImport(
            contentBundle = contentBundle,
        ) {
            /** Nothing for now, in preparation for further refactoring in next commits */
        }
    }
}
