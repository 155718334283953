package com.speechify.client.api.services.subscription.models

import kotlin.js.JsExport

@JsExport
sealed class SubscriptionPreparation {
    data class Paypal(
        val planId: String,
    ) : SubscriptionPreparation()
}
