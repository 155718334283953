package com.speechify.client.internal.services.subscription.models

import com.speechify.client.api.services.subscription.models.PlatformRenewalFrequency
import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformValidateCardCountryBody(
    val paymentMethodId: String,
    val renewalFrequency: PlatformRenewalFrequency,
    val subscriptionCurrency: String,
)
