package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.adapters.pdf.PDFPageTextContentItem
import com.speechify.client.api.util.images.BoundingBox

internal data class BoundingBoxedText<T>(val source: T, val box: BoundingBox) {
    companion object {
        fun fromOcr(ocr: OCRTextContent) = BoundingBoxedText(ocr, ocr.boundingBox)
        fun fromPdf(pdf: PDFPageTextContentItem) = BoundingBoxedText(pdf, pdf.box)
    }
}
