package com.speechify.client.api.editing

import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentStartAndEndCursors
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.getContentTexts
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonPrimitive
import kotlin.js.JsExport

@JsExport
@Serializable
/**
 * This class represents the entire content of a single book page.
 * It is made up of a list of [PageContentPart] objects. Roughly representing one paragraph / heading on the page.
 */
data class PageContent(val pageIndex: Int, val content: Array<PageContentPart>) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PageContent

        if (pageIndex != other.pageIndex) return false
        if (!content.contentEquals(other.content)) return false

        return true
    }

    /**
     * Returns a list of [PageContentPartWithCursors] objects, which contain the [ContentCursor]s for each part.
     * This uses the string length to obtain the cursors.
     *
     * The flow StandardBlocks (if not obtained from edits itself) -> PageContent -> PageContentPartWithCursors
     * causes the cursors indices to shift. Since the conversion from StandardBlocks to PageContent is done by simple
     * string concatenation.
     */
    internal fun getPartsWithCursors(parentElementReference: ContentElementReference):
        List<PageContentPartWithCursors> {
        var prevIndex = 0
        return content.map { part ->
            val startIndex = prevIndex
            val endIndex = prevIndex + part.text.trim().length
            return@map PageContentPartWithCursors(
                part,
                start = parentElementReference.getPosition(startIndex),
                end = parentElementReference.getPosition(endIndex),
            )
                .also {
                    prevIndex = endIndex
                }
        }
            .toList()
    }

    override fun hashCode(): Int {
        var result = pageIndex
        result = 31 * result + content.contentHashCode()
        return result
    }
}

internal fun StandardBlocks.toPageContent(pageIndex: Int): PageContent {
    return PageContent(
        pageIndex,
        this.blocks.map { block ->
            val kind = when (block) {
                is StandardBlock.Footer -> PageContentPartKind.FOOTER
                is StandardBlock.Header -> PageContentPartKind.HEADER
                is StandardBlock.Heading -> PageContentPartKind.HEADING
                is StandardBlock.List -> PageContentPartKind.TEXT
                is StandardBlock.Paragraph -> PageContentPartKind.TEXT
                is StandardBlock.Footnote -> PageContentPartKind.FOOTNOTE
            }
            PageContentPart(block.getContentTexts().joinToString("") { ct -> ct.textRepresentation }, kind)
        }.toTypedArray(),
    )
}

@JsExport
@Serializable
/**
 * A single piece of content on a book page.
 * The kind indicates how it will be displayed / if it will be filtered when reading.
 */
data class PageContentPart(val text: String, val kind: PageContentPartKind)

@JsExport
@Serializable(with = PageContentPartKindSerializer::class)
enum class PageContentPartKind {
    /** A regular paragraph on a page. */
    TEXT,

    /** A heading, will be displayed in a larger font. */
    HEADING,

    /** A repeated header, can be skipped when listening. */
    HEADER,

    /** A repeated footer, can be skipped when listening. */
    FOOTER,

    /** A footnote referencing some kind of citation. */
    FOOTNOTE,

    /**
     * Used for unknown kinds. Only should appear if the serialized encounters an invalid value.
     */
    UNKNOWN,
}

@JsExport
/**
 * Represents the position of a [PageContentPart] in a document.
 */
data class PageContentPosition(val pageIndex: Int, val partIndex: Int)

internal data class PageContentPartWithCursors(
    val part: PageContentPart,
    override val start: ContentCursor,
    override val end: ContentCursor,
) : ContentStartAndEndCursors

internal object PageContentPartKindSerializer : KSerializer<PageContentPartKind> {
    override val descriptor: SerialDescriptor = JsonElement.serializer().descriptor

    override fun serialize(encoder: Encoder, value: PageContentPartKind) {
        encoder.encodeString(value.name.lowercase())
    }

    override fun deserialize(decoder: Decoder): PageContentPartKind {
        val jElement = decoder.decodeSerializableValue(JsonElement.serializer())
        try {
            return when (jElement.jsonPrimitive.content) {
                "text" -> {
                    PageContentPartKind.TEXT
                }
                "heading" -> {
                    PageContentPartKind.HEADING
                }
                "header" -> {
                    PageContentPartKind.HEADER
                }
                "footer" -> {
                    PageContentPartKind.FOOTER
                }
                "footnote" -> {
                    PageContentPartKind.FOOTNOTE
                }
                else -> {
                    PageContentPartKind.UNKNOWN
                }
            }
        } catch (e: IllegalArgumentException) {
            throw SerializationException("failed to interpret PageTextKind value", e)
        }
    }
}
