package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.images.verticalDistanceTo
import com.speechify.client.internal.util.debugging.dbgString
import com.speechify.client.internal.util.extensions.collections.kMeans
import com.speechify.client.internal.util.extensions.strings.getSimilarityTo
import kotlin.math.abs
import kotlin.math.exp
import kotlin.math.max

/**
 * A line group that scores higher than this will be considered a header or footer.
 * The score is somewhat arbitrary and mostly based on trial and error.
 * The "usual" range of values you can expect are ~-15 for content in the middle of the page to around ~4 for very
 * obvious headers and footers.
 */
private const val HEADER_FOOTER_CUTOFF_SCORE = 1.92

/**
 * Headers and Footers extracted from a list of [LineGroup]s.
 */
internal data class HeadersAndFooters(val headers: List<LineGroup>, val footers: List<LineGroup>) {
    companion object {
        /**
         * This detects headers and footers in the given list of line groups.
         * Headers and footers are not removed from the group, but are returned separately.
         * When parsing the line groups to standard blocks you can use this data to parse the detected parts into
         * headers and footers.
         */
        fun detectIn(
            lineGroups: List<LineGroup>,
            surroundingLineGroups: List<List<LineGroup>>,
            stats: LineStats,
            pageSize: Viewport,
        ): HeadersAndFooters {
            val groupDistances = mutableListOf<Double>()
            lineGroups.windowed(2, partialWindows = false).forEach {
                val (currentGroup, nextGroup) = it
                val distance = nextGroup.normalizedBox.verticalDistanceTo(currentGroup.normalizedBox)
                groupDistances.add(distance)
            }

            val usualGroupDistance = groupDistances.kMeans(7) {
                it
            }.maxByOrNull { it.count }?.average ?: 0.0

            fun extractHeadersFooters(groups: List<IndexedValue<LineGroup>>, checkForHeaders: Boolean):
                List<Pair<Double, LineGroup>> {
                return groups.windowed(2, partialWindows = true)
                    .map {
                        val (currentIndex, currentGroup) = it.first()

                        val relativeIndex = if (checkForHeaders) currentIndex else groups.size - currentIndex - 1
                        val nextGroup = it.getOrNull(1)?.value

                        // We normalize this by subtracting the common distance between groups so only a larger
                        // than usual distance adds to the score.
                        val gapToNextGroup = (
                            (nextGroup?.normalizedBox?.verticalDistanceTo(currentGroup.normalizedBox) ?: 0.0) -
                                usualGroupDistance
                            ).coerceAtLeast(0.0)
                        // The further away the next line is, the more likely we have a header / footer.
                        // This models exponential decay, so after a certain point being even further away
                        // does not inflate the score further.
                        val spacingScoreAlternate = 1 - exp(-abs(gapToNextGroup * pageSize.height) / 20)
                        val shapeScore = currentGroup.getHeaderFooterShapeScore(stats, header = checkForHeaders)

                        // If we can find similar elements on other pages it's an indication they are repeated and should
                        // are more likely to be headers or footers.
                        val otherPageContentScore = surroundingLineGroups
                            .mapNotNull { lgs ->
                                if (checkForHeaders) {
                                    lgs.getOrNull(currentIndex)
                                } else {
                                    lgs.getOrNull(lgs.size - relativeIndex - 1)
                                }
                            }
                            .maxOfOrNull { lg -> getLineGroupSimilarity(currentGroup, lg) } ?: 0.0

                        // In some cases we can have subsequent items that are higher / lower than the current one.
                        // This is a strong indicator that this element is not a header footer. For example:
                        // The first paragraph in column 1 might be lower than the first paragraph in column 2.
                        // This makes it less likely to be detected as a header.
                        val laterElementOffset: Double = if (checkForHeaders) {
                            val higherElement = groups
                                .drop(currentIndex + 1)
                                .find { l ->
                                    l.value.normalizedBox.top < currentGroup.normalizedBox.top &&
                                        l.value.normalizedBox.verticalDistanceTo(currentGroup.normalizedBox) > 0
                                }
                            if (higherElement == null) 0.0 else -2.0
                        } else {
                            val lowerElement = lineGroups.dropLast(currentIndex + 1)
                                .find { l ->
                                    l.normalizedBox.bottom > currentGroup.normalizedBox.bottom &&
                                        l.normalizedBox.verticalDistanceTo(currentGroup.normalizedBox) > 0
                                }
                            if (lowerElement == null) 0.0 else -2.0
                        }

                        // This biases the score so headers / footers that are closer to the expected index in the page are rated higher.
                        val relativeIndexBias = (relativeIndex / groups.size.toDouble())

                        val sizeChangeScore = if (nextGroup != null) {
                            (
                                (
                                    (nextGroup.normalizedBox.height / nextGroup.lines.size) /
                                        (currentGroup.normalizedBox.height / currentGroup.lines.size)
                                    ) - 1.05
                                )
                                .coerceAtLeast(0.0).coerceAtMost(1.0)
                        } else {
                            0.0
                        }

                        return@map (
                            shapeScore +
                                spacingScoreAlternate +
                                otherPageContentScore +
                                sizeChangeScore * 0.8 +
                                laterElementOffset -
                                relativeIndexBias / 2
                            ) to
                            currentGroup
                    }
            }

            val potentialHeaders = extractHeadersFooters(
                lineGroups.withIndex().toList(),
                checkForHeaders = true,
            ).filter { it.first > HEADER_FOOTER_CUTOFF_SCORE }

            val potentialFooters = extractHeadersFooters(
                lineGroups
                    .withIndex()
                    .reversed(),
                checkForHeaders = false,
            ).filter { it.first > HEADER_FOOTER_CUTOFF_SCORE }

            // We take the average score of detected headers and footers and use it to adjust the threshold.
            // The idea being that if we detect a lot of content with a high score something went wrong and our limit
            // for what a header / footer should be should probably be higher.
            // Essentially it makes sure that if there are multiple headers/footers we only keep the highest certainty
            // ones.
            val headerOffset = (potentialHeaders.map { it.first }.average() * 0.8)
                .coerceAtMost(HEADER_FOOTER_CUTOFF_SCORE * 1.1)
            val footerOffset = (potentialFooters.map { it.first }.average() * 0.8)
                .coerceAtMost(HEADER_FOOTER_CUTOFF_SCORE * 1.1)
            return HeadersAndFooters(
                potentialHeaders.filter { it.first - headerOffset > 0 }.map { it.second },
                potentialFooters.filter { it.first - footerOffset > 0 }.map { it.second },
            )
        }
    }
}

/**
 * Headers and footers share the same base heuristics for identification, this function encapsulates the strategy for this
 */
private fun LineGroup.getHeaderFooterShapeScore(stats: LineStats, header: Boolean): Double {
    // We use a scoring system to determine if this line group is a header or footer.
    // Smaller blocks are more likely to be headers / footers.
    val widthRange = 0.1..0.3
    val widthScore = (1 - ((normalizedBox.width - widthRange.start) / (widthRange.endInclusive - widthRange.start)))
        .coerceIn(0.0, 1.0)

    // The smaller the font compared to the rest of the page the likelier it is to be a header or footer.
    // We want to give a slight minus to text that is just as big as the rest of the page, so we multiply by 0.9.
    val averageLineHeight = this.lines.asSequence().map { it.normalizedBox.height }.average()
    val fontSizeScore = (1 - (averageLineHeight / (stats.usualLineHeight * 0.9))).coerceAtLeast(-2.0)

    // Are in which we consider items a header or footer.
    val headerFooterArea = 0.12

    // The closer to the edge of the page the likelier it is to be a header or footer.
    // Header: 0.0  = 1 - 0.12 = 0
    // Footer: 0.88 = 0 - 1.0  = 1
    val edgeProximityScore = (
        1 - if (header) {
            (normalizedBox.bottom) / (headerFooterArea)
        } else {
            (1 - normalizedBox.top) / (headerFooterArea)
        }
        ).coerceAtMost(1.0)

    // Anything more than 3 lines is unlikely to be a header or footer.
    val lineCountScore = 1 - (lines.size - 1) / 3.0

    // The final score is based on the sum of all individual scores. The weights were determined by trial and error.
    val finalScore = widthScore / 2 + fontSizeScore / 4 + (edgeProximityScore * 2) + (lineCountScore / 3)

    // This function is extremely useful when tuning this algorithm, so I think it's okay to open an exception to keeping it
    @Suppress("unused")
    fun dbgDump() {
        println(
            """
              stats: $stats
              box: ${normalizedBox.dbgString()}
              averageLineHeight: $averageLineHeight
              widthScore: $widthScore
              fontSizeScore: $fontSizeScore
              edgeProximityScore: $edgeProximityScore
              lineCountScore: $lineCountScore
              finalScore: $finalScore
              lines: $this
            """.trimIndent(),
        )
    }

    return finalScore // .also { dbgDump() }
}

/**
 * Assigns a score from 0 to 1 on how similar those two [LineGroup]s are.
 */
private fun getLineGroupSimilarity(a: LineGroup, b: LineGroup): Double {
    fun getBoundingBoxSimilarity(a: BoundingBox, b: BoundingBox): Double {
        val leftSimilarityScore = 1 - abs(a.left - b.left) / max(a.left, b.left)
        val rightSimilarityScore = 1 - abs(a.right - b.right) / max(a.right, b.right)
        val topSimilarityScore = 1 - abs(a.top - b.top) / max(a.top, b.top)
        val bottomSimilarityScore = 1 - abs(a.bottom - b.bottom) / max(a.bottom, b.bottom)

        return leftSimilarityScore * rightSimilarityScore * topSimilarityScore * bottomSimilarityScore
    }

    val lineCountScore = 1 - abs(a.lines.size - b.lines.size) / (max(a.lines.size, b.lines.size).toDouble())

    val lineLengthScores = a.lines.zip(b.lines).map { (a, b) ->
        1 - (abs(a.chunks.size - b.chunks.size) / max(a.chunks.size, b.chunks.size).toDouble())
    }.average()

    val lineSimilarityScores = a.lines.zip(b.lines).map { (a, b) ->
        a.chunks.zip(b.chunks).map { (a, b) ->
            a.text.text.getSimilarityTo(b.text.text)
        }.average()
    }.average()

    val boundingBoxSimilarity = getBoundingBoxSimilarity(a.normalizedBox, b.normalizedBox)

    // We multiply all results which range from 0 to 1, so the end result will quickly converge to 0 as differences
    // are found.
    return lineCountScore *
        lineLengthScores *
        // By squaring the line similarity score we make sure that just because text with similar bounds exists on
        // other pages it is not enough to make the score high.
        (lineSimilarityScores * lineSimilarityScores) *
        boundingBoxSimilarity
}
