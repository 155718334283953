package com.speechify.client.internal.services.subscription.models

import kotlinx.serialization.Serializable

@Serializable
internal data class PlatformCreatePlayStoreSubscriptionBody(
    val userId: String,
    val planId: String,
    val purchaseToken: String,
)
