package com.speechify.client.reader.core

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.content.view.standard.coGetBlocksBetweenCursors
import com.speechify.client.api.util.images.Point
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.reader.classic.FormattedText
import com.speechify.client.reader.classic.FormattingTree
import com.speechify.client.reader.classic.getWordContainingCursor
import com.speechify.client.reader.fixedlayoutbook.CharBounds
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageHelper
import com.speechify.client.reader.fixedlayoutbook.resolvePagePointWithRefinedCoordinates
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlin.js.JsExport

@JsExport
class SelectionHelper internal constructor(
    scope: CoroutineScope,
    val standardView: StandardView,
) : Helper<SelectionState>(scope) {

    override val stateFlow: MutableStateFlow<SelectionState> = MutableStateFlow(
        SelectionState.empty(),
    )
    override val initialState = stateFlow.value

    private val anchorHandle = SelectionHandle(dispatch = dispatch)
    private val focusHandle = SelectionHandle(dispatch = dispatch)

    init {
        commands
            .onEachInstance<SelectionHelperCommand.SetGrabbedHandle> { command ->
                stateFlow.update {
                    if (it.grabbedHandle != null) {
                        // make newly grabbed handle inclusive
                        if (command.selectionHandle == it.selection?.anchorHandle) {
                            it.copy(
                                grabbedHandle = command.selectionHandle,
                                selection = it.selection.copy(
                                    anchorInclusive = true,
                                ),
                            )
                        } else {
                            it.copy(
                                grabbedHandle = command.selectionHandle,
                                selection = it.selection?.copy(
                                    focusInclusive = true,
                                ),
                            )
                        }
                    } else {
                        it.copy(
                            grabbedHandle = command.selectionHandle,
                            selection = it.selection,
                        )
                    }
                }
            }
            .onEachInstance<SelectionHelperCommand.SelectWithPoint> { command ->
                val currentState = stateFlow.value
                selectText(currentState, command.granularity, command)
            }
            .onEachInstance<SelectionHelperCommand.Select> { command ->
                val currentState = stateFlow.value
                selectText(currentState, command.granularity, command)
            }
            .onEachInstance<SelectionHelperCommand.SetOrUpdateEntireSelection> { command ->
                stateFlow.update { state ->
                    state.copy(
                        selection = Selection(
                            anchor = command.anchor,
                            focus = command.focus,
                            fixedLayoutSelection = null,
                            anchorHandle = anchorHandle,
                            focusHandle = focusHandle,
                        ),
                    )
                }
            }
            .onEachInstance<SelectionHelperCommand.ClearSelection> {
                stateFlow.update { it.cleared() }
            }
            .launchInHelper()
    }

    private suspend fun selectText(
        currentState: SelectionState,
        granularity: SelectionGranularity,
        command: SelectionHelperCommand,
    ) {
        val newState = if (granularity == SelectionGranularity.WORD) {
            selectionStateForWord(currentState, command)
                // Fall back to selecting a character if no matching word is found.
                ?: selectionStateForChar(currentState, command)
        } else {
            selectionStateForChar(currentState, command)
        }

        // If for some reason there was a concurrent change of the selection state, discard this selection.
        stateFlow.compareAndSet(currentState, newState)
    }

    private suspend fun selectionStateForChar(
        currentState: SelectionState,
        command: SelectionHelperCommand,
    ): SelectionState {
        val location: SerialLocation
        val charBounds: CharBounds?

        when (command) {
            is SelectionHelperCommand.Select -> {
                location = command.location
                charBounds = null
            }

            is SelectionHelperCommand.SelectWithPoint -> {
                val isRepresentingLeftHandle = currentState.grabbedHandle == null ||
                    currentState.grabbedHandle == currentState.selection?.startHandle
                with(
                    resolvePointLocation(
                        command.page,
                        command.fixedlayoutPoint,
                        command.viewport,
                        SelectionGranularity.CHARACTER,
                        isRepresentingLeftHandle,
                    ),
                ) {
                    location = first
                    charBounds = second
                }
            }

            else -> {
                error("Unsupported command for char selection: $command")
            }
        }

        return selectionStateForChar(currentState, location, charBounds)
    }

    private fun selectionStateForChar(
        currentState: SelectionState,
        location: SerialLocation,
        charBounds: CharBounds?,
    ): SelectionState {
        val selection = currentState.selection

        return if (selection == null) {
            currentState.copy(
                grabbedHandle = focusHandle,
            ).initWithAnchor(
                location,
                charBounds,
                anchorHandle,
                focusHandle,
            )
        } else if (currentState.grabbedHandle == anchorHandle) {
            currentState.withAnchor(
                location,
                charBounds,
            )
        } else {
            // fallback to default behaviour of focus moving, rather than failing in case no handle is available
            currentState.withFocus(
                location,
                charBounds,
            )
        }
    }

    private suspend fun resolvePointLocation(
        page: BookPage,
        normalizedPoint: Point,
        viewport: Viewport,
        granularity: SelectionGranularity,
        isRepresentingLeftHandle: Boolean,
    ): Pair<SerialLocation, CharBounds?> {
        val (location, charBounds) = resolvePagePointWithRefinedCoordinates(
            page,
            normalizedPoint.x,
            normalizedPoint.y,
            isRepresentingLeftHandle,
            viewport,
            granularity,
        )
        val updatedCharBounds = charBounds?.normalize(viewport)
        /* NOTE
        FOR WEB:
            In case updatedCharBounds is null in [FixedLayoutOverlayStrategy] may call the search to refine it.
            The other approach here could be to pass the [CharBounds] with same start and end to avoid calling search, but that
            will cause cursor over character issue.
         */
        return location to updatedCharBounds
    }

    private suspend fun selectionStateForWord(
        currentState: SelectionState,
        command: SelectionHelperCommand,
    ): SelectionState? {
        val selectionCursor: ContentCursor
        val wordBounds: Pair<CharBounds, CharBounds>?
        when (command) {
            is SelectionHelperCommand.Select -> {
                selectionCursor = command.location.cursor
                wordBounds = null
            }

            is SelectionHelperCommand.SelectWithPoint -> {
                val isRepresentingLeftHandle = currentState.grabbedHandle == null ||
                    currentState.grabbedHandle == currentState.selection?.startHandle

                selectionCursor = resolvePointLocation(
                    command.page,
                    command.fixedlayoutPoint,
                    command.viewport,
                    SelectionGranularity.CHARACTER,
                    isRepresentingLeftHandle,
                ).first.cursor

                wordBounds =
                    getWordBoundsContaining(command.fixedlayoutPoint, command.page, command.viewport) ?: return null
            }

            else -> {
                error("Unsupported command for word selection: $command")
            }
        }

        val word = getWordContaining(selectionCursor) ?: return null
        val wordStart = CharPosition(word.start, wordBounds?.first)
        val wordEnd = CharPosition(word.end, wordBounds?.second)
        return selectionStateForWord(currentState, selectionCursor, wordStart, wordEnd, command)
    }

    private suspend fun selectionStateForWord(
        currentState: SelectionState,
        selectionCursor: ContentCursor,
        wordStart: CharPosition,
        wordEnd: CharPosition,
        command: SelectionHelperCommand,
    ): SelectionState {
        val selection = currentState.selection

        if (selection == null) {
            // Select the entire word on the initial selection.
            val newSelection = Selection(
                anchor = SerialLocation(wordStart.cursor),
                focus = SerialLocation(wordEnd.cursor),
                fixedLayoutSelection = fixedLayoutSelectionFrom(wordStart, wordEnd),
                anchorHandle,
                focusHandle,
            )
            return SelectionState(newSelection, currentState.grabbedHandle)
        }

        val focusCursor = selection.focus.cursor
        val anchorCursor = selection.anchor.cursor

        if (currentState.grabbedHandle != null) {
            // If a handle is grabbed, move it to the start or end of the word,
            // depending on whether the selection location is before or after the other handle.
            // The location of the non-grabbed handle does not change, even if it is in the middle of a word.
            val otherHandleCursor = if (currentState.grabbedHandle == selection.anchorHandle) {
                focusCursor
            } else {
                anchorCursor
            }
            val grabbedHandlePosition = if (selectionCursor.isBeforeOrAt(otherHandleCursor)) {
                wordStart
            } else {
                wordEnd
            }
            return selectionStateForChar(
                currentState,
                SerialLocation(grabbedHandlePosition.cursor),
                grabbedHandlePosition.bounds,
            )
        }

        // When no handle is grabbed, move only the focus to the start or end of the word,
        // depending on whether the selection location is before or after the anchor.
        // If the relative order of the focus and anchor changes as a result,
        // the anchor moves to the opposite end of the word it was originally attached to.
        // To better understand how this works, long-press or double-tap a word in a mobile web browser,
        // then drag the selection without releasing your finger.

        val newFocusPosition = if (selectionCursor.isBeforeOrAt(anchorCursor)) wordStart else wordEnd

        val anchorPosition = CharPosition(anchorCursor, selection.fixedLayoutSelection?.anchorBox)

        val newAnchorPosition = when {
            newFocusPosition.cursor.isEqual(anchorCursor) ->
                // The focus and anchor are equal now. Move the anchor to the opposite end of the selected word.
                if (newFocusPosition === wordEnd) wordStart else wordEnd
            anchorCursor.isBeforeOrAt(focusCursor) == anchorCursor.isBeforeOrAt(newFocusPosition.cursor) ->
                // The relative order of the focus and anchor has preserved.
                anchorPosition
            else -> {
                // The relative order of the focus and anchor has changed.
                val anchorWord = getWordContaining(anchorCursor)
                if (anchorWord != null) {
                    val isAnchorBeforeNewFocus = anchorCursor.isBefore(newFocusPosition.cursor)
                    val anchorWordBounds = anchorPosition.bounds?.let { anchorBox ->
                        if (command !is SelectionHelperCommand.SelectWithPoint) {
                            null
                        } else {
                            getWordBoundsContaining(
                                if (isAnchorBeforeNewFocus) anchorBox.right else anchorBox.left,
                                command.page,
                                command.viewport,
                            )
                        }
                    }
                    if (isAnchorBeforeNewFocus) {
                        CharPosition(cursor = anchorWord.start, bounds = anchorWordBounds?.first)
                    } else {
                        CharPosition(cursor = anchorWord.end, bounds = anchorWordBounds?.second)
                    }
                } else {
                    // Leave the anchor unchanged if no matching word is found.
                    anchorPosition
                }
            }
        }

        val newSelection = Selection(
            anchor = SerialLocation(newAnchorPosition.cursor),
            focus = SerialLocation(newFocusPosition.cursor),
            fixedLayoutSelection = fixedLayoutSelectionFrom(newAnchorPosition, newFocusPosition),
            anchorHandle,
            focusHandle,
        )
        return currentState.copy(selection = newSelection)
    }

    private suspend fun getWordContaining(cursor: ContentCursor): ContentText? {
        val blocks = standardView.coGetBlocksBetweenCursors(cursor, cursor).toNullable()
        val surroundingBlock = blocks?.blocks?.firstOrNull {
            it.text.containsCursor(cursor)
        }
        return surroundingBlock?.text?.getWordContainingCursor(cursor)
    }

    private suspend fun getWordBoundsContaining(
        normalizedPoint: Point,
        page: BookPage,
        viewport: Viewport,
    ): Pair<CharBounds, CharBounds>? {
        val (_, firstCharBounds) = resolvePointLocation(
            page,
            normalizedPoint,
            viewport,
            SelectionGranularity.WORD,
            isRepresentingLeftHandle = true,
        )
        val (_, lastCharBounds) = resolvePointLocation(
            page,
            normalizedPoint,
            viewport,
            SelectionGranularity.WORD,
            isRepresentingLeftHandle = false,
        )
        return if (firstCharBounds == null || lastCharBounds == null) {
            null
        } else {
            Pair(firstCharBounds, lastCharBounds)
        }
    }

    fun clearSelection() {
        dispatch(SelectionHelperCommand.ClearSelection)
    }
}

/**
 * An enumeration of the available granularities of selection.
 *
 * Constants of this enum class can be passed to [FormattedText.select], [FormattingTree.Text.select],
 * or [FixedLayoutPageHelper.select] to specify the selection granularity.
 */
@JsExport
enum class SelectionGranularity {
    /** Selects the character that matches the specified position. */
    CHARACTER,

    /** Selects the word that contains the specified position. */
    WORD,
}

internal sealed class SelectionHelperCommand {
    data class Select(
        val location: SerialLocation,
        val granularity: SelectionGranularity,
    ) :
        SelectionHelperCommand()

    data class SelectWithPoint(
        val fixedlayoutPoint: Point,
        val page: BookPage,
        val viewport: Viewport,
        val granularity: SelectionGranularity,
    ) :
        SelectionHelperCommand()

    data class SetGrabbedHandle(
        val selectionHandle: SelectionHandle,
    ) :
        SelectionHelperCommand()

    object ClearSelection : SelectionHelperCommand()

    data class SetOrUpdateEntireSelection(
        val anchor: SerialLocation,
        val focus: SerialLocation,
    ) : SelectionHelperCommand()
}

@JsExport
data class SelectionState(
    val selection: Selection?,
    val grabbedHandle: SelectionHandle?,
) {

    internal fun initWithAnchor(
        anchor: SerialLocation,
        anchorBounds: CharBounds?,
        anchorHandle: SelectionHandle,
        focusHandle: SelectionHandle,
    ): SelectionState {
        return copy(
            selection = Selection(
                anchor,
                anchor,
                anchorBounds?.let { FixedLayoutSelectionBounds(anchorBounds, anchorBounds) },
                anchorHandle,
                focusHandle,
            ),
        )
    }

    internal fun withAnchor(
        anchor: SerialLocation,
        anchorBounds: CharBounds?,
    ): SelectionState {
        check(selection != null)

        val fixedLayoutSelection =
            anchorBounds?.let { selection.fixedLayoutSelection?.copy(anchorBox = anchorBounds) }

        return if (selection.anchor.cursor.isBeforeOrAt(selection.focus.cursor) &&
            anchor.cursor.isAfter(selection.focus.cursor)
        ) {
            // anchor moves from left to right of focus
            copy(
                selection = selection.copy(
                    anchor = anchor,
                    fixedLayoutSelection = fixedLayoutSelection,
                    focusInclusive = !selection.focusInclusive,
                ),
            )
        } else if (selection.anchor.cursor.isAfter(selection.focus.cursor) &&
            anchor.cursor.isBeforeOrAt(selection.focus.cursor)
        ) {
            // anchor moves from right to left of focus
            copy(
                selection = selection.copy(
                    anchor = anchor,
                    fixedLayoutSelection = fixedLayoutSelection,
                    focusInclusive = !selection.focusInclusive,
                ),
            )
        } else {
            copy(
                selection = selection.copy(
                    anchor = anchor,
                    fixedLayoutSelection = fixedLayoutSelection,
                ),
            )
        }
    }

    internal fun withFocus(
        focus: SerialLocation,
        focusBounds: CharBounds?,
    ): SelectionState {
        check(selection != null)
        val fixedLayoutSelection =
            focusBounds?.let { selection.fixedLayoutSelection?.copy(focusBox = focusBounds) }

        return if (selection.focus.cursor.isBeforeOrAt(selection.anchor.cursor) &&
            focus.cursor.isAfter(selection.anchor.cursor)
        ) {
            // focus moves from left to right of anchor
            copy(
                selection = selection.copy(
                    focus = focus,
                    fixedLayoutSelection = fixedLayoutSelection,
                    anchorInclusive = !selection.anchorInclusive,
                ),
            )
        } else if (selection.focus.cursor.isAfter(selection.anchor.cursor) &&
            focus.cursor.isBeforeOrAt(selection.anchor.cursor)
        ) {
            // focus moves from right to left of anchor
            copy(
                selection = selection.copy(
                    focus = focus,
                    fixedLayoutSelection = fixedLayoutSelection,
                    anchorInclusive = !selection.anchorInclusive,
                ),
            )
        } else {
            copy(
                selection = selection.copy(focus = focus, fixedLayoutSelection = fixedLayoutSelection),
            )
        }
    }

    internal fun cleared(): SelectionState {
        return SelectionState(
            selection = null,
            grabbedHandle = null,
        )
    }

    internal companion object {
        fun empty(): SelectionState {
            return SelectionState(
                selection = null,
                grabbedHandle = null,
            )
        }
    }
}

@JsExport
data class Selection internal constructor(
    internal val anchor: SerialLocation,
    internal val focus: SerialLocation,
    internal val fixedLayoutSelection: FixedLayoutSelectionBounds? = null,
    internal val anchorHandle: SelectionHandle,
    internal val focusHandle: SelectionHandle,
    internal val anchorInclusive: Boolean = true,
    internal val focusInclusive: Boolean = true,
) : Content {

    private val isAnchorFirst = anchor.cursor.isBeforeOrAt(focus.cursor)

    internal val startHandle: SelectionHandle =
        if (isAnchorFirst) anchorHandle else focusHandle
    internal val endHandle = if (isAnchorFirst) focusHandle else anchorHandle

    internal val startPoint =
        fixedLayoutSelection?.run {
            if (isAnchorFirst) {
                if (anchorInclusive) anchorBox.left else anchorBox.right
            } else {
                if (focusInclusive) focusBox.left else focusBox.right
            }
        }
    internal val endPoint =
        fixedLayoutSelection?.run {
            if (isAnchorFirst) {
                if (focusInclusive) focusBox.right else focusBox.left
            } else {
                if (anchorInclusive) anchorBox.right else anchorBox.left
            }
        }

    override val start get() = if (isAnchorFirst) anchor.cursor else focus.cursor
    override val end get() = if (isAnchorFirst) focus.cursor else anchor.cursor

    internal val startLocation: SerialLocation get() = SerialLocation(start)
    internal val endLocation: SerialLocation get() = SerialLocation(end)

    internal val startOffset = if (anchor.cursor.isBeforeOrAt(focus.cursor)) {
        if (anchorInclusive) 0 else 1
    } else {
        if (focusInclusive) 0 else 1
    }

    internal val endOffset = if (focus.cursor.isAfter(anchor.cursor)) {
        if (focusInclusive) 0 else -1
    } else {
        if (anchorInclusive) 0 else -1
    }
}

internal data class FixedLayoutSelectionBounds(
    internal val anchorBox: CharBounds,
    internal val focusBox: CharBounds,
)

/**
 * Handle or Pointer bound with the [Selection] to help representing the boundaries of selection in UI
 */
@JsExport
class SelectionHandle(private val dispatch: CommandDispatch) {
    /**
     * grab the handle to move the end of selection with which it is bound
     */
    fun grab() {
        dispatch(SelectionHelperCommand.SetGrabbedHandle(this))
    }
}

private data class CharPosition(val cursor: ContentCursor, val bounds: CharBounds?)

private fun fixedLayoutSelectionFrom(
    anchorPosition: CharPosition,
    focusPosition: CharPosition,
): FixedLayoutSelectionBounds? {
    return if (anchorPosition.bounds == null || focusPosition.bounds == null) {
        null
    } else {
        FixedLayoutSelectionBounds(anchorPosition.bounds, focusPosition.bounds)
    }
}
