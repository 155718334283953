package com.speechify.client.bundlers.reading

import com.speechify.client.api.services.library.models.ContentType
import kotlin.js.JsExport

@JsExport
class EmbeddedBundleMetadata(
    override val contentSubType: String,
    override val contentType: ContentType,
    val contentUri: String?,
) :
    BundleMetadata(
        contentSubType = contentSubType,
        contentType = contentType,
    )
