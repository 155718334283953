package com.speechify.client.reader.core

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import kotlinx.coroutines.flow.MutableStateFlow

internal class FocusHelper(scope: ReaderScope) : Helper<FocusState>(
    scope,
) {
    override val stateFlow: MutableStateFlow<FocusState> = MutableStateFlow(FocusState.NotReady)

    override val initialState = stateFlow.value

    init {
        commands
            .onEachInstance<FocusCommand.SetFocus> {
                stateFlow.value = FocusState.Ready(
                    start = it.start,
                    end = it.end,
                )
            }
            .launchInHelper()
    }
}

internal sealed class FocusCommand {
    data class SetFocus(val start: SerialLocation, val end: SerialLocation) : FocusCommand()
}

internal sealed class FocusState {
    internal object NotReady : FocusState()
    internal data class Ready(
        val start: SerialLocation,
        val end: SerialLocation,
    ) : FocusState()
}
