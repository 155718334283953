package com.speechify.client.internal.services.subscription.models

import com.speechify.client.internal.util.serialization.CustomEnumSerializer
import com.speechify.client.internal.util.serialization.SerializableEnum
import kotlinx.serialization.Serializable

@Serializable(with = FirebaseSubscriptionSerializer::class)
enum class FirebaseSubscriptionStatus(override val value: String) : SerializableEnum {
    PAYING("paying"),
    CANCELLED_PAYING("cancelled_paying"),
    TRIAL("trial"),
    CANCELLED_TRIAL("cancelled_trial"),
}

class FirebaseSubscriptionSerializer :
    CustomEnumSerializer<FirebaseSubscriptionStatus>(FirebaseSubscriptionStatus.values())

/**
 * Returns true if the status indicates that the subscription will be auto renewed at time of expiry.
 */
internal fun FirebaseSubscriptionStatus.isRenewing(): Boolean {
    return this == FirebaseSubscriptionStatus.PAYING || this == FirebaseSubscriptionStatus.TRIAL
}
