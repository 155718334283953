package com.speechify.client.internal.services.scannedbook

import com.speechify.client.internal.services.importing.models.RecordProperties
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseInternalScannedBook(
    @SerialName(RecordProperties.owner.keyId)
    val owner: String,

    @SerialName(RecordProperties.scannedBookFields.keyId)
    val scannedBookFields: ScannedBookFields = ScannedBookFields(emptyList()),
)

@Serializable
internal data class ScannedBookFields(
    @SerialName(RecordProperties.pageOrdering.keyId)
    val pageOrdering: List<OrderingEntry>,
) {
    @Serializable
    data class OrderingEntry(
        @SerialName(RecordProperties.pageId.keyId)
        val pageId: String,
    )
}
