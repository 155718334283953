package com.speechify.client.api.content.ocr

import com.speechify.client.api.content.pdf.VALID_TEXT_REGEX
import com.speechify.client.api.content.view.book.BookPageTextContentItem

class LegacyStrategyImplementation private constructor() {
    companion object {
        fun shouldApplyOcrFallback(rawTextContentItems: List<BookPageTextContentItem>): Boolean {
            val fullString = rawTextContentItems.joinToString("") { it.text.textRepresentation }
            val hasEmptyPdfContent = rawTextContentItems.isEmpty()
            val hasBlankPdfContent = fullString.isBlank() || !VALID_TEXT_REGEX.containsMatchIn(fullString)
            return hasEmptyPdfContent || hasBlankPdfContent
        }
    }
}
