package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisEvent
import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisPlayer
import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisOptions
import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisUtterance
import com.speechify.client.api.adapters.localsynthesis.speakingColdFlow
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.slice
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.toSDKError
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.sync.AtomicBool
import com.speechify.client.internal.sync.AtomicLong
import com.speechify.client.internal.sync.AtomicRef
import com.speechify.client.internal.sync.plus
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlin.coroutines.cancellation.CancellationException

internal data class LocalPlayer(
    /**
     * As per KDoc in [LocalSpeechSynthesisPlayer], this can be a singleton, so this [LocalPlayer] class is
     * also providing concurrency control - makes sure that there is only one [LocalSpeechSynthesisPlayer.speak]
     * happening at a time, but a similar control one needs to take place outside this class, to only ever use one
     * instance of [LocalPlayer] at a time.
     */
    val localSynthesis: LocalSpeechSynthesisPlayer,
    override val utterance: LocalUtterance,
    private var options: PlayerOptions,
) : Player() {
    init {
        Log.d(
            { "LocalPlayer: initializing new instance. Text: ${utterance.text.text}" },
            sourceAreaId = "LocalPlayer.init",
        )
    }

    private val scope = createTopLevelCoroutineScope()

    private val currentChar: AtomicLong = AtomicLong(0)
    private val lastStartChar: AtomicLong = AtomicLong(0)

    private val taskCounter: AtomicLong = AtomicLong(0)
    private val currentJob = AtomicRef<Job?>(null)

    /** whether [LocalSpeechSynthesisAdapter.resume] is the correct way to resume play */
    private val canResumeLocalSynthesis: AtomicBool = AtomicBool(false)

    /** whether the caller has requested a pause */
    private val pauseWasRequested = MutableStateFlow(true)

    private fun getStateDump() = """
        currentChar: ${currentChar.get()}
        lastStartChar: ${lastStartChar.get()}
        taskCounter: ${taskCounter.get()}
        pauseWasRequested: ${pauseWasRequested.value}
        canResumeLocalSynthesis: ${canResumeLocalSynthesis.get()}
    """.trimIndent()

    private val currentCursor: ContentCursor
        get() = utterance.text.getFirstCursorAtIndex((lastStartChar + currentChar).toInt())

    override suspend fun getCurrentCursor() = currentCursor

    override fun play() {
        Log.d({ "LocalPlayer.play(): called" }, sourceAreaId = "LocalPlayer.play")
        if (pauseWasRequested.compareAndSet(expect = true, update = false)) {
            if (canResumeLocalSynthesis.get()) {
                localSynthesis.resume()
            } else {
                speakFromChar(currentChar.get().toInt())
            }
        } else {
            Log.w(
                DiagnosticEvent(
                    message = "LocalPlayer.play: Got `play()` call when already playing. This is either an issue" +
                        " with the playback machinery (already playing, but state was not updated in the model), or a" +
                        " potential to improve the UI (it is not communicating the state to the user).",
                    properties = mapOf(
                        "utterance" to utterance,
                        "voice" to utterance.voiceInfoForDebug,
                        "state" to getStateDump(),
                    ),
                    sourceAreaId = "LocalPlayer.play",
                ),
            )
        }
    }

    override fun seek(to: ContentCursor) {
        val index = this.utterance.text.getFirstIndexOfCursor(to)
        if (pauseWasRequested.value) {
            canResumeLocalSynthesis.set(false)
            currentChar.set(index.toLong())
        } else {
            speakFromChar(index)
        }
    }

    override fun stop() {
        if (this.pauseWasRequested.value) {
            return
        }
        canResumeLocalSynthesis.set(true)
        pauseWasRequested.value = true
        localSynthesis.pause()
    }

    /**
     * As per KDoc in [LocalSpeechSynthesisAdapter], it can be a singleton, so this method
     * is also providing concurrency control - makes sure that there is only one [LocalSpeechSynthesisAdapter.speak]
     * happening at a time (and a similar control needs to take place outside this class to only ever use one instance
     * of [LocalPlayer] at a time).
     */
    private fun speakFromChar(index: Int) {
        Log.d(
            { "LocalPlayer.speakFromChar(): called" },
            sourceAreaId = "LocalPlayer.speakFromChar",
        )
        lastStartChar.set(index.toLong())
        val text = this.utterance.text.slice(index, utterance.text.length)
        val previousJob = currentJob.value

        // Let's cancel the previous Job before we even start ours
        previousJob?.cancel("Cancelling to make space for new `speakFromChar` invocation.")
        val thisJob = scope.launch(
            start = CoroutineStart.LAZY,
        ) {
            previousJob?.join() // Await for the cooperative end of the cancellation before doing anything
            speakText(text)
        }

        if (currentJob.compareAndSet(expected = previousJob, thisJob)) {
            thisJob.start()
        } else {
            Log.w(
                message = "A rare race condition occurred in `speakFromChar`. A new invocation didn't even get" +
                    " to start its Job and it was replaced by another one.", /* Very unlikely to happen, but if it
                    does in some investigated problem, it's certainly pertinent to report to the investigating
                    developer. */
                sourceAreaId = "LocalPlayer.speakFromChar",
            )
        }
    }

    private suspend fun speakText(
        text: ContentText,
    ) {
        Log.d({ "LocalPlayer.speakText(): called" }, sourceAreaId = "LocalPlayer.speakText")
        // Local SpeechSynthesis APIs do weird things if you pass them empty text, so just skip
        if (text.text.isBlank()) {
            this.eventsSink.tryEmit(PlayerEvent.Started(text.end))
            this.eventsSink.tryEmit(PlayerEvent.Ended)
            return
        }

        val taskId = taskCounter.getAndIncrement()
        try {
            localSynthesis.cancel() /* Doing this especially to clear any pause, because:
             #PauseIsSentEvenOutsideOfPlaySoNeedsResetting - `localSynthesis.pause()` is
             actually persistent across utterances (e.g. on Web Browsers, [SpeechSynthesis.pause](https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis/pause)
             works this way - one can `pause` before any utterance is put to `speak` and once there is one, it won't
             play until resume), and in this class' `stop()` the `pause` is being sent to `SpeechSynthesis`
             unconditionally, even if playing is not happening (it is always a race-condition whether
             playing is happening, so it's impossible to implement a check). */
            localSynthesis.speakingColdFlow(
                utterance = LocalSynthesisUtterance(
                    utterance.localSynthesisVoice,
                    text.text,
                    LocalSynthesisOptions(speed = options.speed, options.volume),
                ),
            )
                .filter {
                    if (taskId == taskCounter.get() - 1) {
                        true
                    } else {
                        Log.w(
                            message = "LocalPlayer.speakText: Received an event in a job that has been superseded by " +
                                "a new one. This one should have been cancelled - check if this is done correctly.",
                            sourceAreaId = "LocalPlayer.speakText",
                        ) /* TODO - see if we ever get these warnings in this new flow-based implementation and remove
                               the entire `taskCounter` if not */
                        false
                    }
                }
                .onEach { event ->
                    val playerEvent = when (event) {
                        is LocalSpeechSynthesisEvent.Started -> {
                            PlayerEvent.Started(text.start)
                        }

                        is LocalSpeechSynthesisEvent.Progressed -> {
                            // small hack, sometimes we get a progressed event right after pausing. But it doesn't
                            // make sense to be paused and emitting progressed events,
                            // so we just skip emitting this one
                            if (pauseWasRequested.value) return@onEach

                            this@LocalPlayer.currentChar.set(event.currentCharacterIndex.toLong())
                            PlayerEvent.Progressed(text.getFirstCursorAtIndex(event.currentCharacterIndex))
                        }

                        LocalSpeechSynthesisEvent.Paused -> {
                            PlayerEvent.Paused(
                                text.getFirstCursorAtIndex(this@LocalPlayer.currentChar.get().toInt()),
                            )
                        }

                        LocalSpeechSynthesisEvent.Ended -> {
                            PlayerEvent.Ended
                        }

                        LocalSpeechSynthesisEvent.Canceled -> {
                            PlayerEvent.Destroyed
                        }
                    }
                    this@LocalPlayer.eventsSink.emit(playerEvent)
                }
                .collect()
            Log.d({ "LocalPlayer.speakText: Flow collection returned.`" }, sourceAreaId = "LocalPlayer.speakText")
        } catch (e: CancellationException) { // Prevent falling into the catch-all to not double-report cancellations
            throw e
        } catch (e: Throwable) {
            try {
                this@LocalPlayer.eventsSink.emit(PlayerEvent.Error(e.toSDKError())) /* TODO - remove the entire catch
                    and the `PlayerEvent.Error` once we don't use a global scope job but one from which the caller can
                     just receive this exception (e.g. when this whole function is a `suspend` one). */
                /* Not rethrowing so that the caller decides what to do with this error (once the above TODO is done,
                    there will be no catch and the caller will be always deciding */
            } catch (eventEmitError: Throwable) {
                Log.w(
                    message = "Could not propagate error via events due to exception (see this log event), so" +
                        " throwing the error to be handled by the global logging (look for log event with error" +
                        " level).",
                    exception = eventEmitError,
                    sourceAreaId = "LocalPlayer.speakText",
                )
                throw e
            }
        }
    }

    override fun updateOptions(newOptions: PlayerOptions) {
        this.options = newOptions
        // this seek here will trigger a speakFromChar call if we are currently playing, which will create the illusion
        // that we have updated the options live after executing this method
        this@LocalPlayer.seek(currentCursor)
    }

    override fun setSpeed(speed: Float) {
        this.options = options.copy(speed = speed)

        // Requested as part of CXP-4689 to skip to the next word when changing speed
        val index = this.utterance.text.getFirstIndexOfCursor(currentCursor)
        val indexOfNextSpace = this.utterance.text.text.indexOf(' ', index)
        if (indexOfNextSpace != -1) {
            val newCursor = this.utterance.text.getFirstCursorAtIndex(indexOfNextSpace + 1)
            this@LocalPlayer.seek(newCursor)
        } else {
            this@LocalPlayer.seek(currentCursor)
        }
    }

    override fun setVolume(volume: Float) = updateOptions(options.copy(volume = volume))

    override fun getOptions(callback: (PlayerOptions) -> Unit) = callback(options)

    override fun isPlaying(): Boolean {
        // this doesn't actually return whether sound is in fact playing, but what was the last "user intention"
        // as this is what actually matters to callers.
        return !this.pauseWasRequested.value
    }

    override fun destroy() {
        Log.d({ "LocalPlayer.destroy(): called" }, sourceAreaId = "LocalPlayer.destroy")
        scope.cancel()
        localSynthesis.cancel() /* Doing this especially to clear any pause.
            See #PauseIsSentEvenOutsideOfPlaySoNeedsResetting  */
        super.destroy()
    }
}
