package com.speechify.client.api.services.subscription.transformers

import com.speechify.client.api.services.subscription.models.PlatformRenewalFrequency
import com.speechify.client.api.services.subscription.models.RenewalFrequency

internal fun toPlatformRenewalFrequency(r: RenewalFrequency) = when (r) {
    RenewalFrequency.MONTHLY -> PlatformRenewalFrequency.Monthly
    RenewalFrequency.YEARLY -> PlatformRenewalFrequency.Annually
    RenewalFrequency.QUARTERLY -> PlatformRenewalFrequency.Quarterly
    RenewalFrequency.WEEKLY -> PlatformRenewalFrequency.Weekly
}
