// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections

import kotlin.jvm.JvmName

@JvmName("flattenInterleavedOnIterable")
internal fun <T> Sequence<Iterable<T>>.flattenInterleaved(getSeparator: (prev: T, next: T) -> T) =
    this.map { it.asSequence() }.flattenInterleaved(getSeparator)

internal fun <T> Sequence<Sequence<T>>.flattenInterleaved(getSeparator: (prev: T, next: T) -> T) = sequence {
    val outerIterator = this@flattenInterleaved.iterator()
    var innerIterator: Iterator<T>

    var prevItem: T

    // First we just look for the first item to populate `prevItem`
    for (firstItemSearchSequence in outerIterator) {
        innerIterator = firstItemSearchSequence.iterator()
        for (firstItemSearchItem in innerIterator) {
            /* Found the first item. Now we can start the interleaving logic.
               NOTE: Somewhat unusually, we will now iterate the same iterators as the outer loops (possible thanks to
               Kotlin's ability to `for` on an iterator). Thanks to this, we have a compiler-guarantee of `prevItem`
               being initialized.
               There is also a `return` at the end (marked #ReturnAtEndToSaveOnRedundantHasNextCall), which is only
               reached once all iterators are traversed, so it just saves the outer loop calling the `hasNext()`.
            */

            /* We only interleave last and first item of the outer sequences. So the first sequence that had some
             elements just yields everything.
            */
            prevItem = firstItemSearchItem
            yield(prevItem)
            for (item in innerIterator) {
                prevItem = item
                yield(prevItem)
            }

            for (sequence in outerIterator) {
                /* Since we only interleave last and first item of the outer sequences, only now that we see a sequence
                 after the first one, we start doing the interleaving:
                */

                /*
                  The interleaving is only with the first item of a sequence, so here:
                */
                val itemsIterator = sequence.iterator()
                if (itemsIterator.hasNext()) {
                    val nextItem = itemsIterator.next()
                    yield(getSeparator(prevItem, nextItem))
                    prevItem = nextItem
                    yield(prevItem)
                }

                /*
                  The interleaving is only with the first item of a sequence, so the remaining items are yielded intact:
                */
                for (item in itemsIterator) {
                    prevItem = item
                    yield(prevItem)
                }
            }

            return@sequence /* #ReturnAtEndToSaveOnRedundantHasNextCall - this is just preventing a redundant
             `hasNext()` on the outer loops (no more elements, so they would return `false` anyway). */
        }
    }
}

internal fun <T, R> List<T>.flatMapInterleaved(
    transform: (T) -> Iterable<R>,
    getSeparator: (prev: R, next: R) -> R,
) =
    this.asSequence().flatMapInterleaved(transform, getSeparator)

internal fun <T, R> Array<T>.flatMapInterleaved(
    transform: (T) -> Iterable<R>,
    getSeparator: (prev: R, next: R) -> R,
) =
    this.asSequence().flatMapInterleaved(transform, getSeparator)

internal fun <T, R> Sequence<T>.flatMapInterleaved(
    transform: (T) -> Iterable<R>,
    getSeparator: (prev: R, next: R) -> R,
) =
    this.map(transform).flattenInterleaved(getSeparator)
