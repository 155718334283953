package com.speechify.client.api.services.audiobook

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapterWithEncryption
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.helpers.features.ListeningProgress
import com.speechify.client.internal.caching.CachedRemoteFileProviderWithHttpDownload
import com.speechify.client.internal.caching.FileByIdProvider
import com.speechify.client.internal.caching.FileId
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.library.models.FirebaseListeningProgress
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.www.UrlString
import kotlin.js.JsExport
import kotlin.jvm.JvmInline

@JsExport
class AudiobookLibraryService internal constructor(
    private val authService: AuthService,
    private val firebaseFunctionsService: FirebaseFunctionsServiceImpl,
    private val firestoreService: FirebaseFirestoreService,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
    private val blobStorageAdapter: BlobStorageAdapter,
    private val httpClient: HttpClient,
) {
    internal suspend fun getChapterFile(
        chapter: AudiobookChapter.Aligned,
        fileId: AudiobookChapterFileId,
    ): File =
        AudioBookChapterFilesProvider(
            cacheStorage = BlobStorageAdapterWithEncryption(
                blobStorageAdapter = blobStorageAdapter,
                encoderForLocalPersistence = SecureAudiobookFileEncoder1(chapter.id),
            ),
            httpClient = httpClient,
            secureAudiobookAssetDownloadUrlProvider = SecureAudiobookAssetDownloadUrlProvider(
                firebaseFunctionService = firebaseFunctionsService,
                audiobookProductReference = chapter.audiobookProductReference,
            ),
        )
            .getFile(
                /** Using the deprecated [FileByIdProvider.getFile]
                 * here is our only way at the moment, because [BlobStorageAdapterWithEncryption.getBlob]
                 * is not implemented (would need to change [SecureAudiobookFileEncoder1] to work on the native
                 * [com.speechify.client.api.util.io.BinaryContentReadableRandomly]).
                 */
                fileId,
            )

    /**
     * Gets all your audiobooks, without loading any chapter models.
     *
     * NOTE: will only return immersive books
     */
    fun getMyAudiobooks(callback: Callback<Array<Audiobook>>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AudiobookLibraryService.getMyAudiobooks",
    ) {
        coGetMyAudiobooks()
    }

    /**
     * Gets all your audiobooks and loads all their chapter models. This is relatively slow, but we keep it to avoid
     * breaking Android access patterns on the eve of the code freeze
     *
     * NOTE: will only return immersive books
     */
    fun getMyAudiobooksWithChapters(callback: Callback<Array<AudiobookWithChapters>>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getMyAudiobooksWithChapters",
        ) {
            coGetMyAudiobooksWithChapters()
        }

    /**
     * Gets the book with the corresponding product reference. See [Audiobook.audiobookProductReference].
     *
     * NOTE: will only return immersive books
     *
     * Returns null if not found
     */
    fun getMyAudiobookByProductReference(productReference: String, callback: Callback<Audiobook?>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getMyAudiobooksByProductReference",
        ) {
            return@fromCoWithErrorLogging coGetMyAudiobooks().orReturn { return@fromCoWithErrorLogging it }
                .find { it.audiobookProductReference == productReference }
                .successfully()
        }

    /**
     * Gets the book with the corresponding product reference and loads all chapter models.
     *
     * NOTE: will only return immersive books
     *
     * Returns null if not found
     */
    fun getMyAudiobookWithChaptersByProductReference(
        productReference: String,
        callback: Callback<AudiobookWithChapters?>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AudiobookLibraryService.getMyAudiobookWithChaptersByProductReference",
    ) {
        return@fromCoWithErrorLogging coGetMyAudiobooksWithChapters().orReturn { return@fromCoWithErrorLogging it }
            .find { it.book.audiobookProductReference == productReference }
            .successfully()
    }

    /**
     * Gets the book in your library with the corresponding ID, without loading chapter models
     */
    fun getMyAudiobook(bookId: String, callback: Callback<Audiobook>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getMyAudiobook",
        ) { coGetMyAudiobook(bookId) }

    /**
     * Gets the book in your library with the corresponding ID, and loads chapter models
     */
    fun getMyAudiobookWithChapters(bookId: String, callback: Callback<AudiobookWithChapters>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getMyAudiobookWithChapters",
        ) {
            coGetMyAudiobook(bookId).map {
                AudiobookWithChapters(
                    it,
                    coGetChapters(it.id)
                        .orReturn { return@fromCoWithErrorLogging it }.toTypedArray(),
                )
            }
        }

    /**
     * Gets all the chapters for the book with the corresponding ID
     */
    fun getChaptersForMyAudiobook(bookId: String, callback: Callback<Array<AudiobookChapter>>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getChaptersForMyAudiobook",
        ) { coGetChapters(bookId).map { it.toTypedArray() } }

    /** ************************************************************* **/

    internal suspend fun saveListeningProgress(chapterId: String, listeningProgress: ListeningProgress): Result<Unit> {
        Log.i(
            "Saving listening progress for chapter $chapterId: $listeningProgress",
            sourceAreaId = "AudiobookLibraryService.saveListeningProgress",
        )
        val updatePayload = mapOf(
            "listeningProgress" to FirebaseListeningProgress.fromListeningProgress(listeningProgress)
                .toBoundaryMap(firebaseTimestampAdapter),
        ).toBoundaryMap() as BoundaryMap<Any?>
        return firestoreService.coUpdateDocument("audiobookItems", chapterId, updatePayload)
    }

    internal fun getChapter(chapterId: String, callback: Callback<AudiobookChapter>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "AudiobookLibraryService.getChapter",
        ) { coGetAudiobookChapter(chapterId) }

    private suspend fun coGetMyAudiobooksWithChapters(): Result<Array<AudiobookWithChapters>> {
        val books = coGetMyAudiobooks().orReturn { return it }
        return books.map {
            AudiobookWithChapters(it, coGetChapters(it.id).orReturn { return it }.toTypedArray())
        }.toTypedArray().successfully()
    }

    private suspend fun coGetMyAudiobooks(): Result<Array<Audiobook>> {
        val currentUser = authService.getCurrentUser().orReturn { return it }
        return firestoreService.queryDocuments("audiobookItems")
            .where("hidden", DocumentQueryBuilder.Operator.EQ, false)
            .where("isArchived", DocumentQueryBuilder.Operator.EQ, false)
            .where("owner", DocumentQueryBuilder.Operator.EQ, currentUser.uid)
            .where("type", DocumentQueryBuilder.Operator.EQ, "folder")
            // Since we only support listening to 'aligned' books, we exclude all other types for now
            .where("chapterType", DocumentQueryBuilder.Operator.EQ, "aligned")
            .coFetch()
            .orReturn { return it }
            .filterIsInstance<FirebaseFirestoreDocumentSnapshot.Exists>()
            .map { parseAudioBookItem(it, it.key) }
            .onEach {
                it.onFailure {
                    Log.d(
                        "Failed to parse: $it",
                        sourceAreaId = "AudiobookLibraryService.coGetMyAudiobooks",
                    )
                }
            }
            .mapNotNull { it.toNullable() }
            .filterIsInstance<AudiobookItem.Book>()
            .map { it.audiobook }
            .toTypedArray().successfully()
    }

    private suspend fun coGetMyAudiobook(audiobookId: String): Result<Audiobook> {
        return when (val audiobookItem = getAudiobookItem(audiobookId).orReturn { return it }) {
            is AudiobookItem.Book -> audiobookItem.audiobook.successfully()
            is AudiobookItem.Chapter -> Result.Failure(SDKError.OtherMessage("This is a chapter, not a book"))
        }
    }

    private suspend fun coGetChapters(bookId: String): Result<List<AudiobookChapter>> {
        val currentUser = authService.getCurrentUser().orReturn { return it }
        return firestoreService.queryDocuments("audiobookItems")
            .where("owner", DocumentQueryBuilder.Operator.EQ, currentUser.uid)
            .where("hidden", DocumentQueryBuilder.Operator.EQ, false)
            .where("isArchived", DocumentQueryBuilder.Operator.EQ, false)
            .where("type", DocumentQueryBuilder.Operator.EQ, "record")
            .where("parentFolderId", DocumentQueryBuilder.Operator.EQ, bookId)
            // Since we only support listening to 'aligned' books, we exclude all other types for now
            .where("chapterType", DocumentQueryBuilder.Operator.EQ, "aligned")
            .coFetch()
            .orReturn { return it }
            .filterIsInstance<FirebaseFirestoreDocumentSnapshot.Exists>()
            .map { parseAudioBookItem(it, it.key) }
            .onEach {
                it.onFailure {
                    Log.d(
                        "Failed to parse: $it",
                        sourceAreaId = "AudiobookLibraryService.coGetChapters",
                    )
                }
            }
            .mapNotNull { it.toNullable() }
            .filterIsInstance<AudiobookItem.Chapter>()
            .map { it.chapter }
            .sortedBy { it.chapterIndex }
            .toList()
            .successfully()
    }

    internal suspend fun coGetAudiobookChapter(chapterId: String): Result<AudiobookChapter> {
        return when (val audiobookItem = getAudiobookItem(chapterId).orReturn { return it }) {
            is AudiobookItem.Book -> Result.Failure(SDKError.OtherMessage("This is a book, not a chapter"))
            is AudiobookItem.Chapter -> audiobookItem.chapter.successfully()
        }
    }

    private suspend fun getAudiobookItem(id: String): Result<AudiobookItem> {
        val result = firestoreService.coGetDocument("audiobookItems", id).orReturn { return it }
        return parseAudioBookItem(result, id)
    }

    private fun parseAudioBookItem(
        snapshot: FirebaseFirestoreDocumentSnapshot,
        id: String,
    ): Result<AudiobookItem> {
        try {
            return when (snapshot) {
                is FirebaseFirestoreDocumentSnapshot.Exists -> {
                    val firestoreItem = snapshot.value<FirestoreAudiobookItem>().orReturn { return it }
                    when (firestoreItem.type) {
                        FirestoreAudiobookItemType.BOOK -> when (firestoreItem.chapterType) {
                            FirestoreAudiobookChapterType.ALIGNED -> Audiobook.Immersive(
                                id = id,
                                audiobookProductReference = audiobookItemRefToAudiobookProductRef(
                                    firestoreItem.productReference!!,
                                ),
                                title = firestoreItem.title,
                                author = firestoreItem.author ?: "",

                                // TODO: convert path to URL
                                coverImageUrl = firestoreItem.coverImagePath ?: "",
                            )

                            FirestoreAudiobookChapterType.AUDIO_ONLY -> return Result.Failure(
                                SDKError.OtherMessage("Audio-only books are not supported"),
                            )

                            FirestoreAudiobookChapterType.EBOOK -> return Result.Failure(
                                SDKError.OtherMessage("Ebook books are not supported"),
                            )
                        }.let { AudiobookItem.Book(it).successfully() }

                        FirestoreAudiobookItemType.CHAPTER -> when (firestoreItem.chapterType) {
                            FirestoreAudiobookChapterType.ALIGNED -> AudiobookChapter.Aligned(
                                bookId = firestoreItem.parentFolderId!!,
                                id = id,
                                audiobookProductReference = audiobookItemRefToAudiobookProductRef(
                                    firestoreItem.productReference!!,
                                ),
                                chapterIndex = firestoreItem.chapterIndex!!,
                                title = firestoreItem.title,
                                alignmentUri = firestoreItem.alignmentUri!!,
                                contentUri = firestoreItem.contentUri!!,
                                durationSeconds = firestoreItem.durationSeconds,
                                wordCount = firestoreItem.wordCount,
                                listeningProgress = firestoreItem.listeningProgress?.toSyncedListeningProgress(),
                            )

                            FirestoreAudiobookChapterType.AUDIO_ONLY -> return Result.Failure(
                                SDKError.OtherMessage("Audio-only chapters are not supported"),
                            )

                            FirestoreAudiobookChapterType.EBOOK -> return Result.Failure(
                                SDKError.OtherMessage("Ebook chapters are not supported"),
                            )
                        }.let { AudiobookItem.Chapter(it).successfully() }
                    }
                }

                is FirebaseFirestoreDocumentSnapshot.NotExists -> return Result.Failure(
                    SDKError.ResourceNotFound(
                        id,
                        "AudioBook Item does not exist with this ID",
                    ),
                )
            }
        } catch (e: Exception) {
            return Result.Failure(SDKError.OtherException(e))
        }
    }
}

/**
 * A strongly-typed `value class` allowing to convey semantics of the string value, using `value class` not to sacrifice performance.
 */
@JvmInline
value class AudiobookChapterFileId(
    private val chapterFileId: String,
) : FileId {
    override val stringValue: String
        get() = chapterFileId
}

internal class AudioBookChapterFilesProvider(
    cacheStorage: BlobStorageAdapterWithEncryption,
    private val secureAudiobookAssetDownloadUrlProvider: SecureAudiobookAssetDownloadUrlProvider,
    httpClient: HttpClient,
) : CachedRemoteFileProviderWithHttpDownload<AudiobookChapterFileId>(
    diagnosticAreaId = AudioBookChapterFilesProvider::class.simpleName!!,
    cacheStorage = cacheStorage,
    httpClient = httpClient,
),
    FileByIdProvider<AudiobookChapterFileId> {
    override suspend fun getDownloadUrl(fileId: AudiobookChapterFileId): UrlString =
        secureAudiobookAssetDownloadUrlProvider
            .getDownloadUrl(fileId)
            .orThrow()
}
