package com.speechify.client.internal.util

import kotlin.js.RegExp

/* ktlint-disable max-line-length */
internal actual fun getAllMatches(pattern: String, text: String): List<RegexMatch> {
    val ranges = mutableListOf<RegexMatch>()

    // "g" (global) flag because this gives us a stateful regex for iterating matches
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#description
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/RegExp#parameters
    //
    // "d" (indices) flag because we need these to give indices for capture groups
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/RegExp#parameters
    //
    // We would probably prefer to use String.prototype.matchAll, but it seems this isn't exposed by Kotlin/JS atm
    val pattern = RegExp(pattern, "gd")
    var match: dynamic
    while (true) {
        match = pattern.exec(text)?.asDynamic() ?: break

        /* Each match looks like this array/object mashup below
        https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#using_exec

        [0]	<the entire match text>
        [1...] <the match text for each capture group (empty if unmatched)>
        index:	<the index of the start of the match>
        indices:	[[startIndex, endIndex) for each group, with the entire match at index 0], groups: { color: [startIndex, endIndex]}
        input:	<the entire input text>
        groups:	{ <name>: <match text for named group> }

         */
        val unnamedCaptureGroups = getUnnamedCaptureGroups(match)
        val matchText = match[0]?.toString() ?: return ranges
        val matchStartIndex = match.index?.unsafeCast<Int>() ?: return ranges
        ranges.add(
            RegexMatch(
                value = matchText,
                range = RegexMatchRange(matchStartIndex, matchStartIndex + matchText.length),
                unnamedCaptureGroups = unnamedCaptureGroups,
            ),
        )
    }
    return ranges
}

internal fun getUnnamedCaptureGroups(match: dynamic): List<RegexMatchGroup> {
    val groups = mutableListOf<RegexMatchGroup>()

    // start from 1 to skip the "entire match" text/indices at index 0. We already represent these at top-level
    // RegexMatch anyway.
    generateSequence(1) { it + 1 }.forEach { groupIndex ->

        // Capture groups will always have a slot in the array-like structure, but value is undefined if no match.
        // We can test for presence of undefined using hasOwnProperty.
        if (match.hasOwnProperty(groupIndex) as Boolean) {
            // undefined if no match
            val groupText = match[groupIndex]?.toString()

            // undefined if no match
            val groupRange = match.indices[groupIndex]?.unsafeCast<Array<Int>>()
            val group = when {
                groupText != undefined && groupRange != undefined -> RegexMatchGroup.Match(
                    value = groupText,
                    range = groupRange.let { RegexMatchRange(it[0], it[1]) },
                )
                else -> RegexMatchGroup.NoMatch
            }
            groups.add(group)
        } else {
            return groups
        }
    }
    return groups
}
