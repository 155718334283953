@file:OptIn(kotlin.time.ExperimentalTime::class)

package com.speechify.client.api.adapters.http

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.io.BinaryContentReadableInChunksWithNativeAPI
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentially
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyWithMultiplatformAndNativeAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunksNullable
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.readAllBytesToSingleArray
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.coroutines.fromNonCancellableAPIs.suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing
import com.speechify.client.internal.http.HeaderMap
import com.speechify.client.internal.http.HttpClientAdapterRawBase
import com.speechify.client.internal.util.TryGetResult
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.collections.KeyWithValueType
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import com.speechify.client.internal.util.getValueOrNull
import kotlin.js.JsExport
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

/**
 * The class for SDK consumers to provide the implementations of HTTP client.
 * No methods of this class should be called directly by SDK consumers - it's only for them to provide the building
 * block to the SDK (notably, SDK adds essential functionality on top of it, such as retries and diagnostics).
 */
@JsExport
abstract class HttpClientAdapter : HttpClientAdapterRawBase() {
    /**
     * The method to use in SDK code (only this method has the debug-tracing support)
     */
    override suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> coSend(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        headers: HeaderMap,
        parameters: Map<String, String>,
        httpRequestBodyData: HttpRequestBodyData?,
    ): HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B> =
        suspendCancellableCoroutineForNonCancellableAPIWithSDKResultByDetachThrowing(
            onCancellationLeavingJobRunning = {
                Log.d(
                    {
                        "A wasted HTTP request: Cancellation would be possible if a cancellable `request` was " +
                            "introduced"
                    },
                    sourceAreaId = "HttpClientAdapter.coSend",
                )
            },
        ) { continuation ->
            when (requiredResponseBodyInterface) {
                ResponseBodyInterface.Companion.BinaryContentReadableRandomly -> {
                    requestBinaryContentReadableRandomly(
                        method = method,
                        url = url,
                        headers = headers,
                        parameters = parameters.toBoundaryMap(),
                        httpRequestBodyData = httpRequestBodyData,
                        callback = { response ->
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <B>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            continuation.resume(
                                response
                                    as Result<
                                        HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<
                                            BinaryContentReadableRandomly,
                                            >,
                                        >
                                    as Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>>,
                            )
                        },
                    )
                }
                ResponseBodyInterface.Companion.BinaryContentReadableSequentially -> {
                    requestBinaryContentReadableSequentially(
                        method = method,
                        url = url,
                        headers = headers,
                        parameters = parameters.toBoundaryMap(),
                        httpRequestBodyData = httpRequestBodyData,
                        callback = { response ->
                            @Suppress(
                                /* Ignore `UNCHECKED_CAST` because the cast is actually checked because we're in a Branch of the correct <B>, as can be seen by the need for `USELESS_CAST` */
                                "UNCHECKED_CAST",
                                "USELESS_CAST",
                            )
                            continuation.resume(
                                response
                                    as Result<
                                        HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<
                                            BinaryContentReadableSequentially,
                                            >,
                                        >
                                    as Result<HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<B>>,
                            )
                        },
                    )
                }
            }
        }

    /**
     * Perform the request and return the response as a random-access readable binary content.
     * This especially indicates that the content is a file-format designed to carry big amount of data which is
     * consumable not in-sequence, but rather depending on the user intent, and thus the-file format has a structure
     * defined on its binary content for efficient extraction of any parts.
     * The method with an alternative to this random-reading is the [requestBinaryContentReadableSequentially], which
     * only requests readability in-sequence.
     *
     * The implementations which wish to stay memory efficient should consider making the
     * [HttpResponseWithBodyAsBinaryContentReadableRandomly.body] be backed by a temporary file (AKA a cache file) -
     * - for Android see [`getCacheDir`](https://developer.android.com/reference/android/content/Context#getCacheDir()),
     * for iOS see [`tmp/` folder](https://developer.apple.com/library/archive/documentation/FileManagement/Conceptual/FileSystemProgrammingGuide/FileSystemOverview/FileSystemOverview.html).
     * NOTE1: When implementing in this way, this should really be a file clearly treated as a temporary, such that can
     * be cleaned by the OS, any cache-files cleanup done by the user, or by the product using the SDK.
     *
     * NOTE2: If a temporary file is chosen as a backing, that file will not be cleaned by the SDK! The SDK is agnostic
     * to the file location and will lose track of the file when it stops being needed. SDK consumer should use any
     * means available to mark this file to the Operating System that it should be cleaned when the object loses its
     * all references, or when the process terminates.
     *
     * NOTE3: Only for some files, an opportunity may happen to clean this file, and that is if the user chooses to save
     * the file to their catalogue of content in the app. If this happens, the opportunity will occur in
     * [com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter.putBlobByMove], as per #TempFilesCleanupOpportunity.
     * See documentation there for more details.
     */
    protected abstract fun requestBinaryContentReadableRandomly(
        method: HttpMethod,
        url: String,
        headers: BoundaryMap<String>,
        parameters: BoundaryMap<String>,
        httpRequestBodyData: HttpRequestBodyData?,
        callback: Callback<HttpResponseWithBodyAsBinaryContentReadableRandomly>,
    )

    /**
     * Perform the request and return the response as a binary content readable only as a sequence of chunks, in the
     * order in which they arrive.
     * This can indicate that:
     * - the content is requested only so that it can be stored, and it will only be accessed randomly from the storage
     * - the content is a file-format which needs to be read fully in-sequence (e.g. HTML files)
     *
     * The method with an alternative to this sequential-reading is the [requestBinaryContentReadableRandomly], which
     * requests reading randomly.
     */
    protected abstract fun requestBinaryContentReadableSequentially(
        method: HttpMethod,
        url: String,
        headers: BoundaryMap<String>,
        parameters: BoundaryMap<String>,
        httpRequestBodyData: HttpRequestBodyData?,
        callback: Callback<HttpResponseWithBodyAsBinaryContentReadableSequentially>,
    )
}

internal sealed class ResponseBodyInterface<T> {

    abstract class WithNativeBinaryAPI<T : BinaryContentReadableInChunksWithNativeAPI> :
        ResponseBodyInterface<T>()

    companion object {
        object ByteArray : ResponseBodyInterface<kotlin.ByteArray>()

        object BinaryContentReadableSequentially :
            WithNativeBinaryAPI<com.speechify.client.api.util.io.BinaryContentReadableSequentially>()

        object BinaryContentReadableRandomly :
            WithNativeBinaryAPI<com.speechify.client.api.util.io.BinaryContentReadableRandomly>()
    }
}

@JsExport
enum class HttpMethod(
    val method: String,
    /**
     * Also known as [the property of idempotency](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent).
     * NOTE: This is the default from the standard, but [_"the idempotence of a method is not guaranteed by the server
     * and some applications may incorrectly break the idempotence constraint."_](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent)
     */
    internal val canRetryOnResponseNotReceived: Boolean,
) {
    GET(
        "GET",
        canRetryOnResponseNotReceived = true, /* Can retry, because GET is safe-thus-idempotent [by HTTP
             standard](https://developer.mozilla.org/en-US/docs/Glossary/Safe/HTTP) */
    ),
    POST(
        "POST",
        canRetryOnResponseNotReceived = false, /* Not allowing retry by default, because POST is
            non-idempotent [by HTTP standard](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent#see_also) */
    ),
    PUT(
        "PUT",
        canRetryOnResponseNotReceived = true, /* Can retry, because PUT is idempotent [by HTTP
             standard](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent) */
    ),
    PATCH(
        "PATCH",
        canRetryOnResponseNotReceived = false, /* Not allowing retry by default, because PATCH is non-idempotent
            [by HTTP standard](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent#see_also) */
    ),
    DELETE(
        "DELETE",
        canRetryOnResponseNotReceived = true, /* Can retry, because DELETE is idempotent [by HTTP
             standard](https://developer.mozilla.org/en-US/docs/Glossary/Idempotent) */
    ),
    OPTIONS(
        "OPTIONS",
        canRetryOnResponseNotReceived = true, /* Can retry, because OPTIONS is safe-thus-idempotent [by HTTP
             standard](https://developer.mozilla.org/en-US/docs/Glossary/Safe/HTTP) */
    ),
    HEAD(
        "HEAD",
        canRetryOnResponseNotReceived = true, /* Can retry, because HEAD is safe-thus-idempotent [by HTTP
             standard](https://developer.mozilla.org/en-US/docs/Glossary/Safe/HTTP) */
    ),
}

@JsExport
enum class HttpStatus(val status: Short) {
    NOT_MODIFIED(304),
}

@JsExport
sealed class HttpResponse(
    val status: Short,
    headers: BoundaryMap<String>,
) {
    val headers: BoundaryMap<String> = if (headers is HeaderMap) headers else HeaderMap.fromMap(headers)

    val ok get() = status in (200..299)
}

sealed class HttpResponseWithBody<Body : Any>(
    status: Short,
    headers: BoundaryMap<String>,
) : HttpResponse(
    status = status,
    headers = headers,
) {
    /**
     * A `null` here may indicate that the response has no body (as per [_"for example, responses to HEAD requests, or 204 No Content responses"_](https://developer.mozilla.org/en-US/docs/Web/API/Response/body#value)
     * but this is not to be relied on - the http clients are allowed to return a non-null [Body] that is empty, and
     * it will be the case for web, as per [_"Current browsers don't actually conform to the spec requirement to set the body property to null for responses with no body"_](https://developer.mozilla.org/en-US/docs/Web/API/Response/body#value)
     */
    internal abstract val body: Body?
}

internal class HttpResponseWithBodyAsByteArray(
    status: Short,
    headers: BoundaryMap<String>,
    override val body: ByteArray?,
) : HttpResponseWithBody<ByteArray>(
    status = status,
    headers = headers,
)

abstract class HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<
    B : BinaryContentReadableInChunksWithNativeAPI,
    >(
    status: Short,
    headers: BoundaryMap<String>,
) : HttpResponseWithBody<B>(
    status = status,
    headers = headers,
),
    BinaryContentWithMimeTypeFromNativeReadableInChunksNullable<B> {
    override val binaryContent: B?
        get() = body

    override val mimeType: MimeType? = getHeaderOrNull(ResponseHeader.ContentType)
}

/**
 * Warning: This should be called only once, and any need for accessing the body multiple times should happen by
 * storing the result in a variable/property and reusing that one. This because, to allow minimum-memory-use
 * implementations, this may be backed by a [BinaryContentReadableSequentiallyMultiplatformAPI] that reads from the single-use stream
 * connected directly to the network stream from producer endpoint.
 *
 * Warning 2: Using this function may lead to Out Of Memory errors - see [BinaryContentReadableSequentiallyMultiplatformAPI.readAllBytesToSingleArray].
 */
internal suspend fun HttpResponse.consumeBodyAsByteArray(
    sourceAreaIdForInefficienciesWarnings: String,
    expectedBodyByteCountBelow: Int? = null,
): Result<ByteArray?> {
    return when (this) {
        is HttpResponseWithBodyAsByteArray -> body
        is HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<*> -> {
            val body: BinaryContentReadableInChunksWithNativeAPI = body ?: return null.successfully()

            when (body) {
                is BinaryContentReadableSequentiallyWithMultiplatformAndNativeAPI -> {
                    body.readAllBytesToSingleArray(
                        sourceAreaIdForWarningOnInefficientArrayCopy = sourceAreaIdForInefficienciesWarnings,
                        expectedByteCountBelow = expectedBodyByteCountBelow,
                    )
                }
                is BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI -> {
                    Log.w(
                        DiagnosticEvent(
                            sourceAreaId = sourceAreaIdForInefficienciesWarnings,
                            message = "SDK optimization potential - reading a `BinaryContentReadableRandomly` as a" +
                                " single ByteArray. See comments near this log statement for options.",
                              /*
                                Consider:
                                 * not requiring single array but processing chunk-by-chunk - passing the
                                   `BinaryContentReadable*` directly to where it's consumed.
                                    * `BinaryContentReadableSequentially` is the most lightweight option
                                      `BinaryContentReadableRandomly` is expensive because consumers
                                       need to write to a file to achieve random-readability (except JS Blob perhaps,
                                       though it too may be backed by a file to free memory).
                                    * `BinaryContentReadableRandomly` can be used where required
                                 * if a `ByteArray` is the only option, then consider requesting `HttpResponseWithBodyAsByteArray` directly.
                               */
                        ),
                    )

                    body.coGetAllBytes()
                        .orReturn { return it }
                }
            }
        }
    }
        .successfully()
}

@JsExport
class HttpResponseWithBodyAsBinaryContentReadableSequentially(
    status: Short,
    headers: BoundaryMap<String>,
    @Suppress(
        /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
        "NON_EXPORTABLE_TYPE",
    )
    override val body: BinaryContentReadableSequentially?,
) : HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<BinaryContentReadableSequentially>(
    status = status,
    headers = headers,
)

@JsExport
class HttpResponseWithBodyAsBinaryContentReadableRandomly(
    status: Short,
    headers: BoundaryMap<String>,
    @Suppress(
        /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
        "NON_EXPORTABLE_TYPE",
    )
    override val body: BinaryContentReadableRandomly?,
) : HttpResponseWithBodyAsBinaryContentReadableInChunksWithNativeAPI<BinaryContentReadableRandomly>(
    status = status,
    headers = headers,
)

internal fun <V> HttpResponse.getHeaderOrNull(
    header: ResponseHeader<V>,
): V? =
    tryGetHeader(header).getValueOrNull()

/**
 * Use when a value is expected.
 *
 * Throws an exception when the header is not present or has an invalid value.
 */
internal fun <V : Any> HttpResponse.getHeaderNeverNull(
    header: ResponseHeader<V>,
): V {
    val headerStringValue = headers[header.keyId]
        ?: throw IllegalArgumentException(
            "Header ${header.keyId} is not present in the response",
        )
    return header.tryParse(headerStringValue)
        .getValueOrNull()
        ?: throw IllegalArgumentException(
            "Header ${header.keyId} is not present in the response",
        ).apply {
            addCustomProperty(
                key = "headerStringValue",
                value = headerStringValue,
            )
        }
}

internal fun <V> HttpResponse.tryGetHeader(
    header: ResponseHeader<V>,
): TryGetResult<V> =
    headers[header.keyId]?.let { header.tryParse(it) } ?: TryGetResult.Unsuccessful

internal sealed class ResponseHeader<V>(
    keyId: String,
) : KeyWithValueType<V>(keyId) {
    object RetryAfter : ResponseHeader<Duration?>("Retry-After") {
        override fun tryParse(value: String): TryGetResult<Duration> {
            val intOrNull = value.toIntOrNull()
            return if (intOrNull == null) {
                /* Logging here, because the standard allows non-integer forms, i.e. the web dates, but their format
                   is quirky. Notifying developers with the value observed will help to see the exact format and make
                   an informed decision.
                 */
                Log.w(
                    DiagnosticEvent(
                        sourceAreaId = "ResponseHeader.RetryAfter",
                        message = "Unsupported value: $value (integer seconds are supported, dates are discouraged" +
                            " so unimplemented for now)",
                    ),
                )
                TryGetResult.Unsuccessful
            } else {
                TryGetResult.Success(intOrNull.seconds)
            }
        }
    }

    object ContentType : ResponseHeader<MimeType?>("Content-Type") {
        override fun tryParse(value: String): TryGetResult<MimeType?> =
            if (value.isBlank()) TryGetResult.Success(null) else TryGetResult.Success(MimeType(value))
    }

    abstract fun tryParse(value: String): TryGetResult<V>
}

internal fun HttpClientAdapter.traced() = if (Log.isDebugLoggingEnabled) HttpClientAdapterTraced(this) else this

internal class HttpClientAdapterTraced(private val httpClientAdapter: HttpClientAdapter) : HttpClientAdapter() {
    override suspend fun <B : BinaryContentReadableInChunksWithNativeAPI> coSend(
        method: HttpMethod,
        url: String,
        requiredResponseBodyInterface: ResponseBodyInterface.WithNativeBinaryAPI<B>,
        headers: HeaderMap,
        parameters: Map<String, String>,
        httpRequestBodyData: HttpRequestBodyData?,
    ) = debugCallAndResultWithUuid(
        "HttpClientAdapter.coSend",
        method,
        url,
        requiredResponseBodyInterface,
        headers,
        parameters,
        httpRequestBodyData,
    ) {
        httpClientAdapter.coSend(
            method = method,
            url = url,
            requiredResponseBodyInterface = requiredResponseBodyInterface,
            headers = headers,
            parameters = parameters,
            httpRequestBodyData = httpRequestBodyData,
        )
    }

    override fun requestBinaryContentReadableSequentially(
        method: HttpMethod,
        url: String,
        headers: BoundaryMap<String>,
        parameters: BoundaryMap<String>,
        httpRequestBodyData: HttpRequestBodyData?,
        callback: Callback<HttpResponseWithBodyAsBinaryContentReadableSequentially>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coSend], which calls the wrapped instance */
        )

    override fun requestBinaryContentReadableRandomly(
        method: HttpMethod,
        url: String,
        headers: BoundaryMap<String>,
        parameters: BoundaryMap<String>,
        httpRequestBodyData: HttpRequestBodyData?,
        callback: Callback<HttpResponseWithBodyAsBinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coSend], which calls the wrapped instance */
        )
}
