package com.speechify.client.internal.services.userDocumentSettings

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.runCatchingToSdkResult
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.http.parse
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.sync.coLazy
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer

private const val DOCUMENTS_PATH = "settings/user-documents"

private val json = Json {
    ignoreUnknownKeys = true
}

internal class UserProfileService(
    private val httpClient: HttpClient,
    private val serviceUrl: String,
    private val authService: AuthService,
    private val appEnvironment: AppEnvironment,
) {

    val userDocumentsMap = coLazy {
        runCatchingToSdkResult {
            currentTelemetryEvent().addMeasurement("userProfileService") {
                fetchUserDocumentSettings().orThrow().items
            }
        }.orDefaultWith { e ->
            Log.i(
                DiagnosticEvent(
                    message = "Error in fetching user settings.",
                    sourceAreaId = "userDocumentSettings",
                    nativeError = e.toNativeError(),
                ),
            )
            emptyMap()
        }
    }

    private suspend fun fetchUserDocumentSettings(): Result<UserDocumentSettings> {
        val token = authService.getCurrentUserIdentityToken().orThrow().token
        val httpResponse = httpClient.get("$serviceUrl/$DOCUMENTS_PATH") {
            header("Authorization", "Bearer $token")
            parameter("context", appEnvironment.toParam())
        }
        return httpResponse.parse<UserDocumentSettings>(
            decoder = { json.decodeFromString(serializer(), it.decodeToString()) },
        )
    }

    private fun AppEnvironment.toParam(): String {
        return when (this) {
            AppEnvironment.MAC_APP -> "mac"
            AppEnvironment.ANDROID -> "android"
            AppEnvironment.IOS -> "ios"
            AppEnvironment.WEB_APP -> "web_app"
            AppEnvironment.DESKTOP_EXTENSION -> "browser_extension"
            else -> "" // Unsupported
        }
    }
}
