package com.speechify.client.internal.services.userDocumentSettings

import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.bundlers.content.ContentBundlerConfig
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

internal class BookBundleConfigService(
    private val userProfileService: UserProfileService,
    private val contentBundlerConfig: ContentBundlerConfig,
) {
    suspend fun getOcrFallbackStrategy(libraryItemId: String): StateFlow<OcrFallbackStrategy> {
        return userProfileService.userDocumentsMap.get()[libraryItemId]
            ?.ocrStrategy
            ?.let(::MutableStateFlow)
            ?.asStateFlow()
            ?: getOcrFallbackStrategy()
    }

    fun getOcrFallbackStrategy(): StateFlow<OcrFallbackStrategy> {
        return contentBundlerConfig.options.ocrFallbackStrategyFlow.asStateFlow()
    }
}
