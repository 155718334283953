package com.speechify.client.api.content

import kotlin.js.JsExport

@JsExport
open class ContentTextPosition internal constructor(
    val element: ContentElementReference,
    val characterIndex: Int,
) : ContentCursor() {
    override fun compareTo(cursor: ContentCursor): ContentRelativeOrder {
        return when (cursor.compareTo(this)) {
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.EQUAL -> ContentRelativeOrder.EQUAL
        }
    }

    override fun compareTo(position: ContentTextPosition): ContentRelativeOrder {
        return when (this.element.compareTo(position.element)) {
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.EQUAL -> {
                this.characterIndex.compareTo(position.characterIndex).let {
                    when {
                        it < 0 -> ContentRelativeOrder.BEFORE
                        it == 0 -> ContentRelativeOrder.EQUAL
                        else -> ContentRelativeOrder.AFTER
                    }
                }
            }
        }
    }

    override fun compareTo(elementBoundary: ContentElementBoundary): ContentRelativeOrder {
        return when (this.element.compareTo(elementBoundary.getParentElement())) {
            ContentRelativeOrder.AFTER -> ContentRelativeOrder.AFTER
            ContentRelativeOrder.BEFORE -> ContentRelativeOrder.BEFORE
            ContentRelativeOrder.EQUAL -> when (elementBoundary.boundary) {
                ContentBoundary.START -> ContentRelativeOrder.AFTER
                ContentBoundary.END -> ContentRelativeOrder.BEFORE
            }
        }
    }

    override fun getClosestPositionBetween(
        start: ContentTextPosition,
        end: ContentTextPosition,
    ): ContentTextPosition {
        return when (this.compareTo(start)) {
            ContentRelativeOrder.BEFORE -> start
            ContentRelativeOrder.EQUAL -> start
            ContentRelativeOrder.AFTER -> when (this.compareTo(end)) {
                ContentRelativeOrder.BEFORE -> this
                ContentRelativeOrder.EQUAL -> end
                ContentRelativeOrder.AFTER -> end
            }
        }
    }

    override fun getParentElement(): ContentElementReference {
        return this.element
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other === null || other !is ContentCursor) return false
        return this.isEqual(other)
    }

    override fun hashCode(): Int {
        var result = element.hashCode()
        result = 31 * result + characterIndex
        return result
    }

    override fun toString(): String {
        return "ContentTextPosition(element=$element, characterIndex=$characterIndex)"
    }
}
