package com.speechify.client.internal.util.collections.flows

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.util.CallbackNoError
import com.speechify.client.api.util.Destructor
import com.speechify.client.internal.util.runCatchingSilencingErrorsLoggingThem
import kotlinx.coroutines.channels.Channel

/**
 * NOTE: The flow is cold, so a separate listening session will be started on every collect, and any events that
 * happened before the collect will be lost.
 */
internal fun <T> flowFromCallbackProducer(
    /**
     * The callback-based producer of the flow's values.
     *
     * NOTE: The [CallbackBasedProducer] is a `fun interface`, so it can be implemented as a lambda,
     * e.g. `{ callback -> ... })`.
     */
    callbackBasedProducer: CallbackBasedProducer<T>,
    /**
     * See documentation in parameter of the same name in [callbackFlowNeverThrowingToProducer]
     */
    shouldLogErrorIfCancellationPreventedDelivery: Boolean,
    /**
     * The [DiagnosticEvent.sourceAreaId] to use for logging, to be able to identify the source of the error.
     */
    sourceAreaId: String,
    diagnosticProperties: Map<String, Any>? = null,
): FlowThatFinishesOnlyThroughCollectionCancel<T> =
    callbackFlowNeverThrowingToProducer(
        shouldLogErrorIfCancellationPreventedDelivery = shouldLogErrorIfCancellationPreventedDelivery,
        sourceAreaId = sourceAreaId,
        diagnosticProperties = diagnosticProperties,
        bufferCapacity = Channel.UNLIMITED, /* Use unlimited capacity so that `receiveItem` can be synchronous,
        and no message is ever lost due to slow async collector of the flow. */
    ) {
        val unsubscribe = callbackBasedProducer.subscribeAndGetCancel(
            receiveItem = {
                this.send(it)
            },
        )

        awaitClose {
            unsubscribe()
        }
    }
        .flowThatFinishesOnlyThroughCollectionCancelStronglyTyped(
            sourceAreaId = "flowFromCallbackProducer",
        )

internal fun <T> CallbackBasedProducer<T>.wrapWithNeverThrowingToProducer(
    /**
     * The [DiagnosticEvent.sourceAreaId] to use for logging, to be able to identify the source of the error.
     */
    sourceAreaId: String,
    diagnosticProperties: Map<String, Any>? = null,
): CallbackBasedProducer<T> =
    @Suppress("ObjectLiteralToLambda")
    object : CallbackBasedProducer<T> {
        override fun subscribeAndGetCancel(receiveItem: CallbackNoError<T>): Destructor =
            this@wrapWithNeverThrowingToProducer.subscribeAndGetCancel(
                receiveItem = { item ->
                    /** TODO - extract the unsubscribe-diagnostics-behavior of [com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer]'s
                     *   be implemented here as a wrapper around [com.speechify.client.internal.util.collections.flows.CallbackBasedProducer],
                     *   and then call this method in [com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer].
                     *   For now, let's just have the minimum of what we need here - preventing exceptions from propagating to the producer.
                     *   #TODOConsolidateUnsubscribeDiagnosticsBehaviorOfcallbackFlowNeverThrowingToProducer
                     */
                    runCatchingSilencingErrorsLoggingThem(
                        sourceAreaId = sourceAreaId,
                        shouldIgnoreCancellationExceptions =
                        /** `false`, since, as per #TODOConsolidateUnsubscribeDiagnosticsBehaviorOfcallbackFlowNeverThrowingToProducer,
                         * we're just duplicating what [com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer]
                         * (see #CancellationSpecialMeaningInCallbackFlow there for justification).
                         */
                        false,
                        properties = diagnosticProperties,
                    ) {
                        receiveItem(item)
                    }
                },
            )
    }

internal fun interface CallbackBasedProducer<T> {
    /**
     * Should return a [Destructor] function that can be used to unsubscribe from the callback.
     */
    fun subscribeAndGetCancel(
        receiveItem: CallbackNoError<T>,
    ): Destructor
}
