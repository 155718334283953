package com.speechify.client.internal.sync

internal actual class AtomicInt actual constructor(private var value: Int) {
    actual fun get(): Int = value

    actual fun set(newInt: Int) {
        value = newInt
    }

    actual fun getAndAdd(delta: Int): Int {
        val valueBeforeAdd = value
        value += delta
        return valueBeforeAdd
    }

    actual fun addAndGet(delta: Int): Int = getAndAdd(delta) + delta

    actual fun getAndIncrement(): Int = value++

    actual fun getAndDecrement(): Int = value--
}
