package com.speechify.client.api.telemetry

import com.benasher44.uuid.uuid4
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.toMap
import com.speechify.client.api.util.isCausedByConnectionError
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.time.Milliseconds
import com.speechify.client.internal.time.nowInMillisecondsFromEpoch
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.collections.KeyWithValueType
import com.speechify.client.internal.util.collections.KeyWithValueTypeAndConversion
import com.speechify.client.internal.util.collections.maps.BlockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.ThreadSafeMapWithBasics
import com.speechify.client.internal.util.collections.maps.mapOfNotNullValues
import com.speechify.client.internal.util.collections.maps.set
import com.speechify.client.internal.util.extensions.collections.maps.putEntriesFromPairs
import com.speechify.client.internal.util.extensions.collections.maps.putEntryFromPairsNotNull
import com.speechify.client.internal.util.extensions.throwable.getCustomPropertiesFromEntireChain
import com.speechify.client.internal.util.extensions.throwable.getCustomPropertyFromTopMostInEntireChainOrNull
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonObjectBuilder
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject

/**
 * A builder for [TelemetryEvent] that enables accumulation of properties over the course of execution.
 */
internal class TelemetryEventBuilder(
    private val message: String,
) {
    internal val telemetryEventName: String get() =
        /* TODO: phase out the `message` misnomer and use [telemetryEventName] as much as possible (can temporarily
         *  stop at the field name in logs, and still have it named `message`, but eventually it should be changed to
         *  something like `eventName` there too. */
        message

    private val data: ThreadSafeMapWithBasics<String, Any> = BlockingThreadsafeMap()
    private var startTime: Milliseconds? = null
    private var endTime: Milliseconds? = null
    private var result: Result<*>? = null

    fun <T> addProperty(key: String, value: T?): TelemetryEventBuilder = apply {
        if (value != null) {
            data[key] = value
        }
    }

    fun <T> addProperty(pair: Pair<String, T>) {
        addProperty(pair.first, pair.second)
    }

    fun addResult(result: Result<*>): TelemetryEventBuilder = apply {
        endTime = nowInMillisecondsFromEpoch()
        this.result = result
    }

    fun addStartTime(): TelemetryEventBuilder = apply {
        startTime = nowInMillisecondsFromEpoch()
        return this
    }

    fun addUUID() = apply {
        addProperty("uuid", uuid4().toString())
    }

    fun setStartAndEndtime(startTime: Milliseconds, endTime: Milliseconds) = apply {
        this.startTime = startTime
        this.endTime = endTime
    }

    internal fun getProperties() = data.entries.toMap()

    internal fun build(): TelemetryEvent =
        TelemetryEvent(
            message = message,
            startTime = startTime,
            endTime = endTime,
            nativeResult = result,
            properties = data.entries.toMap()
                .toBoundaryMap(),
        )
}

/**
 * A multi-property version of [TelemetryEventBuilder.addProperty].
 */
internal fun TelemetryEventBuilder.addProperties(vararg properties: Pair<String, Any>) {
    for (property in properties) {
        addProperty(property)
    }
}

internal fun TelemetryEventBuilder.addPropertiesNonNull(vararg properties: Pair<String, Any>?) =
    addProperties(
        *properties.filterNotNull().toTypedArray(),
    )

/**
 * Creates a new [TelemetryEventBuilder] with the given [message] and adds the [properties] to it
 * with the given [prefix] prepended to the key.
 * In addition, the original events timing data is copied over as well.
 */
internal fun TelemetryEvent.builderWithPrefixedProperties(
    message: String,
    prefix: String,
): TelemetryEventBuilder {
    val builder = TelemetryEventBuilder(message)
    builder.addProperty("${prefix}_msg", this.message)
    this.additionalProperties?.entries()?.forEach { (key, value) ->
        builder.addProperty("${prefix}_$key", value)
    }
    this.durationMs?.let { builder.addProperty("${prefix}_ms", it) }
    this.startTime?.let { builder.addProperty("${prefix}_${TelemetryEvent.START_MS_KEY}", it) }
    this.endTime?.let { builder.addProperty("${prefix}_${TelemetryEvent.END_MS_KEY}", it) }
    return builder
}

internal abstract class ReportableEvent(
    /**
     * Dynamic properties for facilitating structured-logging (being able to filter by value, etc.).
     * If the target does not support them, they should be serialized into one string.
     *
     * When there are no properties, the value will be `null`.
     */
    val additionalProperties: BoundaryMap<Any>? = null,
) {

    /**
     * This gets the properties that are set for all events, these usually stay the same.
     * This is different from [additionalProperties] which can be anything a caller might set extra.
     */
    abstract fun getCoreProperties(): Map<String, Any?>

    /** Labels are the designated property for 'classifying logs' in Google Cloud, and will end up in
     * [the official `LogEntry.labels` at "Google Cloud > Operations Suite"](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS:~:text=User%2Ddefined%20labels%20are%20arbitrary%20key%2C%20value%20pairs%20that%20you%20can%20use%20to%20classify%20logs.)
     * so that any functionality build on this can be used.
     */
    abstract val labels: Map<String, Any>

    fun toJsonForReport(): JsonObject = buildJsonObject {
        if (additionalProperties != null) {
            putFromMapSafely(additionalProperties.toMap())
        }

        this.putFromMapSafely(
            mapOfNotNullValues(
                /* `"labels"` is the property name, as per [Google's Cloud Logging for Bunyan](https://github.com/googleapis/nodejs-logging-bunyan#logentry-labels) */
                "labels"
                    to labels,
            ),
        )

        val coreProperties = getCoreProperties()
        putFromMapSafely(coreProperties)
    }
}

/**
 * Note that more than one member can be non-`null` and carry information that should be reported.
 */
internal class TelemetryEvent(
    val message: String? = null,
    val startTime: Milliseconds? = null,
    val endTime: Milliseconds? = null,
    /**
     * The [kotlin.Result] of the operation that was being measured.
     */
    val nativeResult: Result<*>?,

    /**
     * Dynamic properties for facilitating structured-logging (being able to filter by value, etc.).
     * If the target does not support them, they should be serialized into one string.
     *
     * When there are no properties, the value will be `null`.
     */
    properties: BoundaryMap<Any>? = null,
) : ReportableEvent(properties) {
    val durationMs: Milliseconds?
        get() = if (startTime != null && endTime != null) {
            endTime - startTime
        } else {
            null
        }

    override val labels: Map<String, Any> = mapOf(
        /** TODO consider using labels - see [ReportableEvent.labels] */
    )

    override fun getCoreProperties(): Map<String, Any?> = buildMap {
        this.putEntryFromPairsNotNull(
            LoggingIndustryStandardProperties.TimeOfOccurrence
                .toPairWithValOrNull(
                    startTime?.let { DateTime.fromMilliseconds(it) },
                ),
        )

        val exception = nativeResult?.exceptionOrNull()
        this["success"] = (exception == null)

        this.putEntryFromPairsNotNull(
            LoggingIndustryStandardProperties.Message.toPairWithValOrNull(
                if (exception != null && (message.isNullOrBlank())) {
                    // If we don't have a normal message it is useful to fill in the error message here as well so it will show
                    // up in the top level on our GCP logs, making it easier to understand what happened without having to
                    // unfold the log line.
                    exception.message
                } else {
                    message
                },
            ),
        )

        // If any exception in the stack is a connection error we can safely assume that this is what caused
        // the overall failure.
        val isConnectionError = exception?.isCausedByConnectionError() ?: false

        ifNotNull(durationMs) {
            this["ms"] = it
            this[START_MS_KEY] = startTime
            this[END_MS_KEY] = endTime
        }

        this.putEntriesFromPairs(
            LoggingIndustryStandardProperties.LogLevel
                .toPairWithVal(LogLevel.INFO),
        )

        this.putEntriesFromPairs(
            LogEventTypeProp.toPairWithVal(
                if (isConnectionError) {
                    // We don't want to treat connection errors on the same level as other errors since they are clearly not
                    // actionable from the SDK side.
                    LogEventType.INFO
                } else {
                    LogEventType.TELEMETRY
                },
            ),
        )
    }

    companion object {
        /** Timestamp of when the telemetry started to be tracked in milliseconds from epoch. */
        const val START_MS_KEY = "tel_start"

        /** Timestamp of when the telemetry ended in milliseconds from epoch. */
        const val END_MS_KEY = "tel_end"
    }
}

internal class StoredDiagnosticEvent(
    private val diagnosticEvent: DiagnosticEvent,
    val logLevel: LogLevel,
) : ReportableEvent(
    additionalProperties = (diagnosticEvent.properties?.toMap() ?: emptyMap()).run {
        val mutableMap = this.toMutableMap()
        ifNotNull(diagnosticEvent.sourceAreaId) {
            mutableMap["sourceAreaId"] = it
        }

        mutableMap.toBoundaryMap()
    },
) {
    override val labels: Map<String, Any> = mapOf(
        /** TODO consider using labels - see [ReportableEvent.labels] */
    )

    override fun getCoreProperties(): Map<String, Any?> = buildMap {
        val message = diagnosticEvent.message
        val exception = diagnosticEvent.error?.nativeError

        this.putEntryFromPairsNotNull(
            LoggingIndustryStandardProperties.TimeOfOccurrence
                .toPairWithValOrNull(
                    diagnosticEvent.timeOfOccurrence,
                ),
        )

        val logLevel = exception?.getCustomPropertyFromTopMostInEntireChainOrNull(
            key = LogLevelOverrideKey,
        ) ?: run {
            // If any exception in the stack is a connection error we can safely assume that this is what caused
            // the overall failure.
            val isConnectionError = exception?.isCausedByConnectionError() ?: false

            // We don't want to treat connection errors on the same level as other errors since they are clearly not
            // actionable from the SDK side.
            val shouldReportAsError = !isConnectionError && logLevel == LogLevel.ERROR
            val shouldReportAsWarning = !isConnectionError && logLevel == LogLevel.WARNING

            when {
                shouldReportAsError -> LogLevel.ERROR
                shouldReportAsWarning -> LogLevel.WARNING
                else -> LogLevel.INFO
            }
        }

        this.putEntriesFromPairs(
            LoggingIndustryStandardProperties.LogLevel
                .toPairWithVal(logLevel),
        )

        this.putEntriesFromPairs(
            LogEventTypeProp.toPairWithVal(
                when (logLevel) {
                    LogLevel.ERROR -> LogEventType.ERROR
                    LogLevel.WARNING -> LogEventType.WARNING
                    LogLevel.INFO -> LogEventType.INFO
                },
            ),
        )

        this.putEntryFromPairsNotNull(
            LoggingIndustryStandardProperties.Message.toPairWithValOrNull(
                if (exception != null && (message.isNullOrBlank())) {
                    // If we don't have a normal message it is useful to fill in the error message here as well so it will show
                    // up in the top level on our GCP logs, making it easier to understand what happened without having to
                    // unfold the log line.
                    exception.message
                } else {
                    message
                },
            ),
        )

        if (exception != null) {
            this["errmsg"] = exception.message
            this["stack_trace"] = exception.stackTraceToString()
            if (logLevel == LogLevel.ERROR) {
                // Using this makes sure that this log will show up in the error reports.
                // See: https://cloud.google.com/error-reporting/docs/formatting-error-messages#format-log-entry
                this["@type"] = "type.googleapis.com/google.devtools.clouderrorreporting.v1beta1.ReportedErrorEvent"
            }

            // Put all the custom error properties into the event, so we can evaluate them from the logs.
            val errorProperties = exception.getCustomPropertiesFromEntireChain()
            errorProperties.entries.forEach {
                this["err_${it.key}"] = it.value
            }
        }
    }
}

/**
 * Groups and documents the meaning of those properties which are supported and recognized by the logging
 * infrastructure 'out of the box' (Speechify agnostic), if they conform to a specific name/format.
 */
internal object LoggingIndustryStandardProperties {
    /**
     * The time at which the event should be marked as have occurred (especially for allowing to search events by time
     * and ordering against other events).
     *
     * This writes to the [`timestamp` key in GCP logs](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS)
     */
    object TimeOfOccurrence : KeyWithValueTypeAndConversion<DateTime, String>(
        keyId =
        /*
         * `"time"`, because even though this writes to the [`timestamp` key in GCP logs](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS)
         * it has to go via the [`time` field of the bunyan framework](https://github.com/trentm/node-bunyan#core-fields),
         * because the Speechify logs collection service feeds the objects to Google Cloud Platform using
         *  [Google's Cloud Logging for Bunyan](https://github.com/googleapis/nodejs-logging-bunyan)
         */
        "time",
        getConvertedValue = { it.toIsoString() },
    )

    /**
     * Level of severity of the event.
     * This writes to the [`severity` key in GCP logs](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS)
     */
    object LogLevel : KeyWithValueTypeAndConversion<com.speechify.client.api.telemetry.LogLevel, String>(
        keyId =
        /*
         * `"level"`, because even though this writes to the [`severity` key in GCP logs](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS)
         * it goes via [this code in the service](https://github.com/SpeechifyInc/platform/blob/4980e0b8c1a8262b8ded03299e423ea3f6e1dbf6/services/firebase-cloud-functions/functions/src/sdk/api/http/diagnostics.ts#L33),
         * which feeds the objects to Google Cloud Platform using [Google's Cloud Logging for Bunyan](https://github.com/googleapis/nodejs-logging-bunyan)
         * and the events acquire their `severity` via [the logger's choice of reporting function](https://github.com/SpeechifyInc/platform/blob/4980e0b8c1a8262b8ded03299e423ea3f6e1dbf6/packages/logging/src/index.ts#L210C7-L210C21).
         */
        "level",
        getConvertedValue = {
            when (it) {
                /*
                 * The string values are bespoke and their meaning defined at [this code in the service](https://github.com/SpeechifyInc/platform/blob/4980e0b8c1a8262b8ded03299e423ea3f6e1dbf6/services/firebase-cloud-functions/functions/src/sdk/api/http/diagnostics.ts#L33)
                 * (so they neither conform to [`severity` key in GCP logs](https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#FIELDS),
                 * nor [Bunyan's `level`](https://github.com/trentm/node-bunyan#levels))
                 */
                com.speechify.client.api.telemetry.LogLevel.ERROR -> "ERROR"
                com.speechify.client.api.telemetry.LogLevel.WARNING -> "WARN"
                com.speechify.client.api.telemetry.LogLevel.INFO -> "INFO"
            }
        },
    )

    object Message : KeyWithValueType<String>(
        keyId =
        /*
         * See [`msg` field of the bunyan framework](https://github.com/trentm/node-bunyan#core-fields), which is
         * interpreted because the Speechify logs collection service feeds the objects to Google Cloud Platform using
         *  [Google's Cloud Logging for Bunyan](https://github.com/googleapis/nodejs-logging-bunyan)
         */
        "msg",
    )
}

/**
 * Especially useful for adding to exceptions, in order to give them a specific level of severity.
 */
internal object LogLevelOverrideKey : KeyWithValueType<LogLevel>(
    keyId = "LogLevelOverride",
)

/**
 * Used to report the type of item that is being opened. Called libraryItemContentType internally for compatibility
 * with the legacy telemetry reported initially by createBundleForResource
 */
internal object LibraryItemContentTypeTelemetryProp : KeyWithValueType<ContentType>("libraryItemContentType")

internal object ErrorUuidTelemetryProp : KeyWithValueType<String>("errorUuid")

/**
 * If isItemImportForDiagnostics=true to telemetry then it will be considered as items import and will appear in listening dashboard
 * here: https://chaitu.retool.com/apps/82643e6c-4647-11ee-a6ff-a7109e6393c9/speechify/User%20Listening%20Dashboard
 */
internal object IsItemImportForDiagnosticsTelemetryProp : KeyWithValueType<Boolean>("isItemImportForDiagnostics")

/**
 * See [com.speechify.client.bundlers.reading.BundleMetadata.customContentSubType].
 */
internal object CustomContentSubTypeTelemetryProp : KeyWithValueType<Boolean>("customContentSubType")

/**
 * This puts all entries of the Boundary map into the JsonObjectBuilder.
 * Any value that is not a JSON primitive will be converted to a string.
 */
private fun <K, V> JsonObjectBuilder.putFromMapSafely(map: Map<K, V>, propertyPrefix: String = "") {
    for ((propertyName, v) in map.entries) {
        try {
            this.put("$propertyPrefix$propertyName", v.toJsonElement())
        } catch (ex: SerializationException) {
            Log.e(
                error = SDKError.OtherException(ex),
                message = "${SpeechifySDKTelemetry::class.simpleName}.$propertyName",
                sourceAreaId = "JsonObjectBuilder.putFromMapSafely",
            )
        }
    }
}

private fun Any?.toJsonElement(): JsonElement =
    when (this) {
        null -> JsonNull
        is String -> JsonPrimitive(this)
        is Number -> JsonPrimitive(this)
        is Boolean -> JsonPrimitive(this)
        is Array<*> -> JsonArray(this.map { it.toJsonElement() })
        is List<*> -> JsonArray(this.map { it.toJsonElement() })
        is Map<*, *> -> JsonObject(
            content = this.entries.associate { (k, v) ->
                k.toString() to v.toJsonElement()
            },
        )
        else -> JsonPrimitive(this.toString())
    }

internal enum class LogLevel {
    ERROR,
    WARNING,
    INFO,
}

internal object LogEventTypeProp : KeyWithValueTypeAndConversion<LogEventType, String>(
    keyId =
    /* Historical name for this property. */
    "typ",
    getConvertedValue = { it.stringValue },
)

internal enum class LogEventType(
    val stringValue: String,
) {
    /** TODO - consider removing log levels from here, because they're already reported as the
     *   [LoggingIndustryStandardProperties.LogLevel], and instead grouping them under one value (e.g. `LOGEVENT`)
     *   As per [this conversation](https://github.com/SpeechifyInc/multiplatform-sdk/pull/1073#discussion_r1281501839)
     */
    ERROR(stringValue = "err"),
    WARNING(stringValue = "warn"),

    /**
     * Used for events that don't contribute to our overall health metrics, but are still useful for debugging customer
     * issues.
     */
    INFO(stringValue = "info"),

    /**
     * Used for telemetry events, and unhandled errors.
     */
    TELEMETRY(stringValue = "tel"),

    ;

    override fun toString(): String {
        return stringValue
    }
}

internal fun TelemetryEvent.toDiagnosticEvent(sourceAreaId: String) = DiagnosticEvent(
    message = message,
    sourceAreaId = sourceAreaId,
    properties = additionalProperties,
)
