package com.speechify.client.helpers.ui.overlays

import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.ObjectRef
import com.speechify.client.api.content.hasNontrivialIntersectionWith
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.boundary.toPair
import com.speechify.client.internal.sync.BlockingWrappingMutex
import kotlin.js.JsExport

/**
 * Provides the relationships between any instance of [ContentText] and the foreign objects that have been registered with this provider in relation to other instances of [ContentText].
 *
 * This is useful for implementing features like Highlighting, where you have [ContentText] and want to get the UI components that rendered other [ContentText] to the screen. In this case, the provider will tell you which UI components contain the text you seek to highlight.
 *
 * Note: Callers will specify the type parameter based on their own knowledge of the type of content they are dealing with. This isn't type-safe, but it helps us avoid threading this type parameter through every content API in the codebase.
 */
@JsExport
class RenderedContentOverlayProvider<RenderingRef : Any?> :
    ContentOverlayProvider<RenderingRef> {
    private val contents = BlockingWrappingMutex.of(mutableListOf<Pair<ObjectRef<RenderingRef>, ContentText>>())

    /**
     * Inform that this [text] was rendered as [rendering].
     *
     * @return a [Destructor] that removes the rendered content from the provider.
     */
    fun addRenderedContent(rendering: ObjectRef<RenderingRef>, text: ContentText): Destructor {
        return addAllRenderedContent(listOf(rendering to text))
    }

    /**
     * Efficient way to register and un-register large numbers of renderings at once.
     *
     * Immediately registers all provided [pairs].
     *
     * @return a [Destructor] that removes the all [pairs] of rendered content from the provider.
     */
    fun addAllRenderedContent(pairs: Array<BoundaryPair<ObjectRef<RenderingRef>, ContentText>>): Destructor {
        return this.addAllRenderedContent(pairs.map { it.toPair() })
    }

    /**
     * Efficiently un-registers all content
     */
    fun clearAllRenderedContent() {
        contents.swap(mutableListOf())
    }

    private fun addAllRenderedContent(entries: List<Pair<ObjectRef<RenderingRef>, ContentText>>): Destructor {
        contents.locked { it.addAll(entries) }
        return { contents.locked { it.removeAll(entries) } }
    }

    /**
     * Get the ranges within all the registered renderings that contain pieces of the [text].
     */
    override fun getOverlayRanges(text: ContentText): Array<ContentOverlayRange<RenderingRef>> {
        val overlapping = contents.locked {
            it.filter { (_, otherText) ->
                otherText.hasNontrivialIntersectionWith(text)
            }
        }
        return overlapping.map { (ref, otherText) ->
            val overlapStartIndex = otherText.getFirstIndexOfCursor(text.start)
            val overlapEndIndex = otherText.getLastIndexOfCursor(text.end) + 1
            val otherTextText = otherText.text
            val newOverlapEndIndex = (0 until overlapEndIndex)
                .reversed()
                .find { otherTextText.getOrNull(it)?.isWhitespace() == false }
                ?: overlapStartIndex
            ContentOverlayRange(
                ref,
                overlapStartIndex,
                // we can only add one if the index changed, if it didn't change it was already in the right place
                if (newOverlapEndIndex == overlapEndIndex) overlapEndIndex else newOverlapEndIndex + 1,
            )
        }.toTypedArray()
    }
}
