package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.scannedbook.ScannedBookBookView
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.InMemoryCacheManager
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.tryToList
import com.speechify.client.helpers.content.standard.ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.runTask
import com.speechify.client.internal.sync.AtomicCircularFixedList
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlin.js.JsExport

/**
 * An adapter that accepts a [BookView] and behaves like a [StandardView]
 */
@JsExport
class BookStandardView(val view: BookView) :
    WithScope(),
    StandardView,
    ContentSequenceCharacteristicsOfImmutableAlwaysLiveNoUserEffectContent,
    Destructible {
    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    // To not blow up memory usage we cache only the blocks for 10 pages.
    private val blockCache = AtomicCircularFixedList<Pair<Int, StandardBlocks>>(10)
    private val blockCacheDtor = InMemoryCacheManager.register(
        destructor = {
            blockCache.clear()
        },
    )

    init {
        combine(view.mlParsingModeFlow, view.ocrFallbackStrategyFlow) { _, _ -> }
            // We drop the initial value here, in order to not clear the cache for no reason.
            .drop(1)
            .onEach {
                blockCache.clear()
            }.launchIn(scope)

        // Launched separately instead of combining with the flows above because `bookEditsFlow` is a shared flow
        // that does not provide an initial value. Combining it would prevent other flows from collecting emissions.
        view.bookEditsFlow.onEach {
            blockCache.clear()
        }.launchIn(scope)
    }

    override fun getBlocksAroundCursor(cursor: ContentCursor, callback: Callback<StandardBlocks>) = callback.fromCo {
        return@fromCo getBlocksBetweenCursors(startCursor = cursor, endCursor = cursor)
    }

    override fun getBlocksBetweenCursors(start: ContentCursor, end: ContentCursor, callback: Callback<StandardBlocks>) {
        runTask {
            callback(coGetBlocksBetweenCursors(start, end))
        }
    }

    private suspend fun coGetBlocksBetweenCursors(
        start: ContentCursor,
        end: ContentCursor,
    ): Result<StandardBlocks> {
        return getBlocksBetweenCursors(start, end)
    }

    private suspend fun getBlocksBetweenCursors(
        startCursor: ContentCursor,
        endCursor: ContentCursor,
    ): Result<StandardBlocks> {
        val startIndex = view.getPageIndex(startCursor)
        val endIndex = view.getPageIndex(endCursor)
        val pageIndexes = (startIndex..endIndex).toList().toTypedArray()
        val pages = view.coGetPages(pageIndexes).orReturn { return it }
        val blocks = getBlocksForPages(pages).asSequence().tryToList().orReturn { return it }
        // Results should have non-null entry for each input index, and is thus never empty by this point
        if (blocks.isEmpty()) {
            return Result.Failure(
                SDKError.OtherMessage("Invalid state: getBlocksForPages obtained no results for nonempty input"),
            )
        }

        // original page indices after Book editing.
        val originalStartPageIndex = getOriginalPageIndexFromCursor(startCursor)
        val originalEndPageIndex = getOriginalPageIndexFromCursor(endCursor)

        // set StandardBlocks.start = the END of the PREVIOUS page, to facilitate "chaining" use-case where you
        // call getBlocksAroundCursor(blocks.start) to get the previous group of blocks
        val resultStartPageIndex = (originalStartPageIndex - 1).coerceAtLeast(0)
        val resultStart = ContentElementReference.fromPath(listOf(resultStartPageIndex)).let {
            if (originalStartPageIndex == 0) it.start else it.end
        }

        val originalBookPagesCount = view.getMetadata().numberOfPages + (originalEndPageIndex - endIndex) - 1
        // likewise set StandardBlocks.end = the START of the NEXT page, to facilitate "chaining" use-case where you
        // call getBlocksAroundCursor(blocks.end) to get the next group of blocks
        val resultEndPageIndex = (originalEndPageIndex + 1).coerceAtMost(originalBookPagesCount)
        val resultEnd = ContentElementReference.fromPath(listOf(resultEndPageIndex)).let {
            if (originalEndPageIndex >= originalBookPagesCount) view.end else it.start
        }

        return StandardBlocks(
            combineBlocksForPages(blocks).toTypedArray(),
            /* must include pages cursors as there could be empty blocks (in the case of multiple pages of images,
            this will allow the consumer to skip past it */
            resultStart,
            resultEnd,
        ).successfully()
    }

    private fun getOriginalPageIndexFromCursor(cursor: ContentCursor): Int =
        when (cursor) {
            is ContentElementBoundary -> cursor.element.path.firstOrNull() ?: when (cursor.boundary) {
                ContentBoundary.END -> view.getMetadata().numberOfPages - 1
                ContentBoundary.START -> 0
            }

            is ContentTextPosition -> cursor.element.path.first()
        }

    private suspend fun getBlocksForPages(pages: Array<BookPage>): List<Result<StandardBlocks>> = coroutineScope {
        pages.map { page ->
            async {
                val cachedEntry = blockCache.find { it.first == page.pageIndex }
                if (cachedEntry != null) {
                    return@async cachedEntry.second.successfully()
                }

                return@async getBlocksForPage(page)
                    .ifSuccessful {
                        val pageIndexToStandardBlocksPair = page.pageIndex to it
                        blockCache.add(pageIndexToStandardBlocksPair)
                    }
            }
        }.awaitAll()
    }

    private suspend fun getBlocksForPage(page: BookPage): Result<StandardBlocks> {
        // Before trying to create the blocks from the page content we check if the page already
        // provides a standard block representation.
        val standardBlocksForPage = page.getStandardBlockRepresentation()

        if (standardBlocksForPage != null) {
            return standardBlocksForPage.successfully()
        }

        val parsedPageContent = page.getStableParsedPageContent().orReturn { return it }

        // TODO: refactor it in the future
        val isScannedBook = when (view) {
            is EditingBookView -> view.originalBookView is ScannedBookBookView
            else -> view is ScannedBookBookView
        }
        return StandardBlocks(
            parsedPageContent.textItemsGroupedAndOrdered.toStandardBlocks(isScannedBook).toList().toTypedArray(),
            page.start,
            page.end,
        ).successfully()
    }

    override fun destroy() {
        super.destroy()
        launchTask {
            blockCacheDtor()
        }
    }
}
