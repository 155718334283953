package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.util.boundary.BoundaryMap
import kotlin.js.JsExport

// https://firebase.google.com/docs/reference/node/firebase.storage.FullMetadata
@JsExport
data class FullMetadata(
    val bucket: String,
    val cacheControl: String?,
    val contentDisposition: String?,
    val contentEncoding: String?,
    val contentLanguage: String?,
    val contentType: String?,
    val customMetadata: BoundaryMap<Any?>,
    val fullPath: String,
    val generation: String,
    val md5Hash: String?,
    val metageneration: String,
    val name: String,
    val size: Int,
    val timeCreated: String,
    val updated: String,
)
