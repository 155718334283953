package com.speechify.client.helpers.audio.controller.root

import com.benasher44.uuid.uuid4
import com.speechify.client.api.audio.AudioControllerEvent
import com.speechify.client.api.audio.Player
import com.speechify.client.api.audio.PlayerEvent
import com.speechify.client.api.audio.PlayerOptions
import com.speechify.client.api.audio.Utterance
import com.speechify.client.api.audio.UtteranceFlowProviderWithPredefinedStartingPoint
import com.speechify.client.api.audio.coGetOptions
import com.speechify.client.api.audio.coGetPlayer
import com.speechify.client.api.audio.playingColdFlow
import com.speechify.client.api.audio.voiceInfoForDebug
import com.speechify.client.api.content.SentenceAndWordLocation
import com.speechify.client.api.content.isEquivalentByStartAndEndCursors
import com.speechify.client.api.content.view.speech.SpeechSentence
import com.speechify.client.api.content.view.speech.getSentenceAndWordLocationInContentAtCursorOrFirstAfter
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.SpeechifySDKTelemetry
import com.speechify.client.api.telemetry.TelemetryEvent
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.builderWithPrefixedProperties
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.internal.sync.AtomicBool
import com.speechify.client.internal.sync.ReadOnlyJobInfo
import com.speechify.client.internal.time.Milliseconds
import com.speechify.client.internal.time.nowInMillisecondsFromEpoch
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.collections.flows.channelFlowWithoutItemsLoss
import com.speechify.client.internal.util.collections.flows.enrichingConsumptionsWith
import com.speechify.client.internal.util.collections.flows.flowOnObservableJobs
import com.speechify.client.internal.util.collections.flows.onCompletionSuccessfully
import com.speechify.client.internal.util.collections.mapFirst
import com.speechify.client.internal.util.diagnostics.enriching.errorEnrichingWithTags
import com.speechify.client.internal.util.extensions.collections.flows.onEachDelayUpstreamOver
import com.speechify.client.internal.util.extensions.collections.flows.onFirst
import com.speechify.client.internal.util.extensions.collections.flows.suspendUntilEquals
import com.speechify.client.internal.util.extensions.collections.flows.useAndDestroyAfterEachCollect
import com.speechify.client.internal.util.extensions.collections.mapWithRunningValue
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.dropWhile
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlin.coroutines.AbstractCoroutineContextElement
import kotlin.coroutines.CoroutineContext
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

/**
 * The player actor is responsible for iterating over the players that each play one block (usually a sentence) of
 * content. It listens to two kinds of events at once:
 * - [PlayerEvent]s, which let this actor know when to move on to the next player, or what to notify the consumer of the
 *   whole audio stack about (by emitting [AudioControllerEvent]s).
 * - state flows of [isPausedStateFlow], [speedStateFlow] s which tell it to pause, resume or change playback speed.
 *
 * It also exposes an API that lets the caller query it's immediate current state.
 */
// TODO: Remove lazy initialization of properties in a refactoring effort: CXP-4926
internal class PlayerActor(
    private val initialOptions: PlayerOptions,
    shouldStartPlayingImmediately: Boolean,
    private val shouldAlwaysLoadFirstUtteranceImmediately: Boolean,
) {
    private var job: ReadOnlyJobInfo? = null
    private var player: Player? = null
    private val isActive
        get() = job?.isActive ?: false

    @OptIn(ExperimentalTime::class)
    private fun getPlayingFlow(
        utteranceFlowProvider: UtteranceFlowProviderWithPredefinedStartingPoint,
        fullFirstSentenceOrNull: SpeechSentence?,
    ) = channelFlowWithoutItemsLoss<AudioControllerEvent> {
        val bundlingToPlaybackLatencyTelemetryTracker =
            BundlingToPlaybackLatencyTelemetryTracker.currentBundlingToPlaybackLatencyTelemetryTracker()

        val shouldOptimizeForQuickStart: Boolean

        bundlingToPlaybackLatencyTelemetryTracker?.apply {
            addProperty("shouldAlwaysLoadFirstUtteranceImmediately", shouldAlwaysLoadFirstUtteranceImmediately)
        }

        if (isPausedStateFlow.value) {
            if (!shouldAlwaysLoadFirstUtteranceImmediately) {
                /* suspend right here, as we were told not to warm anything up */
                bundlingToPlaybackLatencyTelemetryTracker.withPauseBlock {
                    isPausedStateFlow.suspendUntilEquals(false)
                }

                /* But optimize for quick start - it makes sense to at least make the start fast, now that the user
                   pressed resume
                 */
                shouldOptimizeForQuickStart = true
            } else {
                /* don't suspend, so that we warm everything up (we will pause
                 further down, once the player is ready)
                */

                /* And don't optimize for quick start, because we are given time to start with a full-length first
                   chunk.
                 */
                shouldOptimizeForQuickStart = false
            }
        } else {
            /* In not paused already, then we were requested to play immediately, so optimize for quick start */
            shouldOptimizeForQuickStart = true
        }

        bundlingToPlaybackLatencyTelemetryTracker?.addTimeWhenUtterancePreparationStarted()
        val utterancesFlow = utteranceFlowProvider.getUtterancesFlow(
            shouldOptimizeForQuickStart = shouldOptimizeForQuickStart,
            reportBufferMeasurement = { measurement ->
                /**
                 TODO - consider not reporting when user paused during a chunk playback, so as not to skew averages
                 (although it will just give a very low negative hang, while devs should mostly look at top percentiles)

                 NOTE: Local synthesis is being measured for hangs too, even though they are very
                 unlikely to happen. If this is too wasteful, then perhaps we should decrease waste for
                 local and premium users alike, by deciding not to report when `hangBetweenChunksPlaybackIfPositiveMs`
                 is a low-enough negative number (too far from danger of hanging to worry about).
                 */
                SpeechifySDKTelemetry.report(
                    TelemetryEvent(
                        message = "Latency.PlaybackSmoothness.HangBetweenChunksPlayback",
                        properties = mapOf(
                            /**
                             * Perhaps the most important measurement:
                             * * When positive, it indicates that hangs are occurring due to synthesis not being fast enough
                             * * When negative, then the closer to 0 it is, the less safety margin there is to prevent hangs
                             *   on events like network latency.
                             * For more details see [com.speechify.client.internal.util.extensions.collections.flows.RendezVousBufferedFlowMeasurement.hangBetweenConsumptionsIfPositiveDuration].
                             */
                            "hangBetweenChunksPlaybackIfPositiveMs" to
                                measurement.hangBetweenConsumptionsIfPositiveDuration.inWholeMilliseconds.toInt(),

                            "currentChunkPlaybackDurationMs" to
                                measurement.currentItemConsumption.duration.inWholeMilliseconds.toInt(),
                            /* 'Content text length' and 'speed' can help to improve our content chunking strategy */
                            "currentChunkPlaybackSpeed" to speedStateFlow.value,
                            "currentChunkCharsCount" to
                                measurement.currentItemConsumption.value.text.textRepresentation.length,
                            "currentChunkPlaybackSynthesisLocation" to
                                measurement.currentItemConsumption.value.synthesisLocation,
                            "currentChunkPreparationVoiceId" to
                                measurement.currentItemConsumption.value.voiceMetadata.id,
                            "currentChunkPreparationVoiceEngine" to
                                measurement.currentItemConsumption.value.voiceMetadata.engineIdInternal,

                            "nextChunkPreparationDurationMs" to
                                measurement.nextItemProduction.duration.inWholeMilliseconds.toInt(),
                            "nextChunkCharsCount" to
                                measurement.nextItemProduction.value.text.textRepresentation.length,
                            "nextChunkPreparationSynthesisLocation" to
                                measurement.nextItemProduction.value.synthesisLocation,
                            "nextChunkPreparationVoiceId" to
                                measurement.nextItemProduction.value.voiceMetadata.id,
                            "nextChunkPreparationVoiceEngine" to
                                measurement.nextItemProduction.value.voiceMetadata.engineIdInternal,

                            "bufferItemsAfterCurrentCount" to measurement.bufferItemsAfterCurrentCount,

                            /* TODO remove these once confirmed that telemetry relies only on unambiguous `nextChunkPreparationVoice*` or `currentChunkPreparationVoice*`. */
                            "voice" to
                                measurement.nextItemProduction.value.voiceMetadata.id,
                            "voiceEngine" to
                                measurement.nextItemProduction.value.voiceMetadata.engineIdInternal,
                        )
                            .toBoundaryMap(),
                        nativeResult = Result.success(Unit),
                    ),
                )
            },

        )

        utterancesFlow
            .mapWithRunningValue(
                /* NOTE: Mapping utterances to players needs to be done after any `buffer`
                   operators, so that the options `player.coGetOptions()` are already after the play. */
                initialRunningValue = initialOptions,
                getNextItem = { utterance, lastPlayerOptions ->
                    utterance.coGetPlayer(lastPlayerOptions).orThrow()
                },
                getRunningValueForNext = { player -> player.coGetOptions() },
            )
            .useAndDestroyAfterEachCollect(
                /* `Player`s have disposal concern, so here we ensure that each is cleaned
                            up after it has done its work. */
                suppressExceptionFromDestroyEvenOnSuccess = true, /* But we suppress cleanup errors on successful play
                    because, if the play succeeded, we should be able to keep playing, so we need to keep the players
                    pipeline (which is this flow) running.
                    */
            )
            .onFirst {
                bundlingToPlaybackLatencyTelemetryTracker?.addTimeWhenUtterancePreparationEnded()
                bundlingToPlaybackLatencyTelemetryTracker?.apply {
                    addProperty("voice", it.utterance.voiceMetadata.id)
                    addProperty("voiceEngine", it.utterance.voiceMetadata.engineIdInternal)
                    addProperty("utteranceSynthesisLocation", it.utterance.synthesisLocation)
                }
            }
            .onEachDelayUpstreamOver(100.milliseconds) {
                isPausedStateFlow.suspendUntilEquals(false) /* Don't fire 'Buffering' before
                `isPausedStateFlow` is false, which is when we are born not-started (but requested to buffer,
                 since we got here). This also prevents any flickering of the 'buffering' state on the play
                 button before it shows the actual state of 'press to start playing'. */

                /*
                But do send the `Buffering` if the user presses 'play' and the buffering is not finished (note that
                if the item arrives during the wait on above `shouldStartStateFlow`, then the line below will
                never be reached, as per the cancellation mentioned in KDoc of `onEachDelayUpstreamOver`,
                and as per the fact that `suspendUntilEquals` is cooperative for cancels).
                */
                isBuffering = true
                emit(AudioControllerEvent.Buffering)
            }
            .onFirst {
                if (isPausedStateFlow.value) { /* The way to arrive here with
                 `isPausedStateFlow=true` is when `bufferOnInit` is true, which is a feature of 'not starting, but
                  warming up the buffer'. This suspension effectively facilitates this feature, because it gets
                  the player ready, but waits until the start to proceed with it, not even starting the play, so
                  that there are no events.
                */
                    bundlingToPlaybackLatencyTelemetryTracker.withPauseBlock {
                        isPausedStateFlow.suspendUntilEquals(false)
                    }
                }
            }
            .onEach { player ->
                isBuffering = false
                this@PlayerActor.player = player
                val utterance = player.utterance

                coroutineScope {
                    var lastSentenceAndWordLocation: SentenceAndWordLocation? = null

                    val jobOfWaitingForPlayerEndWhileHandlingItsEvents = player
                        .playingColdFlow()
                        /* Comment this line to test the below `collectDiagnosticsOfFrozenPlay`
                        .onNth(5) { delay(5000) }
                        // */
                        .collectDiagnosticsOfFrozenPlay(utterance)
                        .onEach { playerEvent ->
                            emit(
                                when (playerEvent) {
                                    is PlayerEvent.Progressed,
                                    is PlayerEvent.Started,
                                    -> {
                                        // Include a conditional check here, as in certain circumstances, we may
                                        // receive a PlayerEvent.Progressed while the player is in the 'Pause' state.
                                        if (isPausedStateFlow.value) {
                                            playerEvent as PlayerEvent.HasCursor
                                            AudioControllerEvent.Paused(
                                                cursor = playerEvent.cursor,
                                                sentenceAndWordLocation = null,
                                            )
                                        } else {
                                            bundlingToPlaybackLatencyTelemetryTracker
                                                ?.markFirstUtteranceStartedPlaying()
                                            playerEvent as PlayerEvent.HasCursor
                                            AudioControllerEvent.Playing(
                                                cursor = (playerEvent as PlayerEvent.HasCursor).cursor,
                                                voice = utterance.voiceMetadata.spec,
                                                sentenceAndWordLocation = utterance
                                                    .speech.sentences.asIterable().mapFirst { speechSentence ->
                                                        if (fullFirstSentenceOrNull.contains(speechSentence)) {
                                                            // If reached here means the [fullFirstSentenceOrNull] is
                                                            // not null.
                                                            fullFirstSentenceOrNull!!
                                                        } else {
                                                            speechSentence
                                                        }
                                                    }
                                                    .getSentenceAndWordLocationInContentAtCursorOrFirstAfter(
                                                        cursor = playerEvent.cursor,
                                                        logNotFoundSourceAreaId = "UtterancePlayer.playback",
                                                        /** #UtterancePlaybackReportingLocationNotWithinUtterance is here.
                                                         * It shouldn't really happen because speech should only be speaking
                                                         * at indices range within the bounds of the text that it was given,
                                                         * so let's log if it does happen, as it would be a bug with the
                                                         * [com.speechify.client.api.audio.Player]. */
                                                        logNotFoundExtraProperties = {
                                                            mapOf(
                                                                "utteranceText" to utterance.text.textRepresentation,
                                                                "utteranceCursorIndex" to utterance.text
                                                                    .getFirstIndexOfCursor(playerEvent.cursor),
                                                            )
                                                        },
                                                    )
                                                    .let {
                                                        if (
                                                            it.isEquivalentByStartAndEndCursors(
                                                                lastSentenceAndWordLocation,
                                                            )
                                                        ) {
                                                            lastSentenceAndWordLocation
                                                        } else {
                                                            lastSentenceAndWordLocation = it
                                                            it
                                                        }
                                                    },
                                            )
                                        }
                                    }
                                    is PlayerEvent.Paused -> AudioControllerEvent.Paused(
                                        cursor = playerEvent.cursor,
                                        sentenceAndWordLocation = null,
                                    )
                                    is PlayerEvent.Destroyed -> AudioControllerEvent.Destroyed()
                                },
                            )
                        }.launchIn(this)

                    val stateUpdatingJob = launch {
                        val initialSpeed = player.coGetOptions().speed
                        speedStateFlow
                            .dropWhile { it == initialSpeed } /* Drop the first speed of same value, not to call a moot `setSpeed` with it */
                            .onEach { newSpeed ->
                                Log.d(
                                    { "PlayerActor.loop: speed changed to ${speedStateFlow.value}" },
                                    sourceAreaId = "PlayerActor -> speedStateFlow",
                                )
                                player.setSpeed(newSpeed)
                            }
                            .launchIn(this)

                        isPausedStateFlow
                            .dropWhile { !it } // Omit the initial 'false' which has let us into this loop
                            .onEach { isPaused ->
                                Log.d(
                                    { "PlayerActor.loop: isPaused changed to $isPaused" },
                                    sourceAreaId = "PlayerActor -> isPausedStateFlow",
                                )
                                if (isPaused) {
                                    player.stop()
                                } else {
                                    player.play()
                                }
                            }
                            .launchIn(this)
                    }

                    jobOfWaitingForPlayerEndWhileHandlingItsEvents.join()

                    stateUpdatingJob.cancelAndJoin()
                }
            }
            .collect()
    }

    internal fun getUnstartedPlayingFlow(
        utteranceFlowProvider: UtteranceFlowProviderWithPredefinedStartingPoint,
        fullFirstSentenceOrNull: SpeechSentence? = null,
    ): Flow<AudioControllerEvent> =
        getPlayingFlow(
            utteranceFlowProvider = utteranceFlowProvider,
            fullFirstSentenceOrNull = fullFirstSentenceOrNull,
        )
            .enrichingConsumptionsWith(
                enrichingBlock = { performCollect ->
                    errorEnrichingWithTags(
                        /* Add this tag especially to be able to analyze errors happening during playback (causing 'random
                           pauses') and distinguish from same errors happening in other areas of the code. For example - we
                           consume content here for _playback_, but the same content, from the same
                           content providers (AKA `StandardView`s) is also consumed for _display_, _indexing_, _navigating_,
                           _highlighting_, etc.
                         */
                        "Playback.ContinuousSection",
                    ) {
                        performCollect()
                    }
                },
            )
            .flowOnObservableJobs(
                receiveJobInfo = { collectingJob ->
                    job = collectingJob
                },
            )
            .onCompletionSuccessfully {
                Log.d({ "PlayerActor: finished successfully" }, sourceAreaId = "PlayerActor.getUnstartedPlayingFlow")
            }

    private val isPausedStateFlow = MutableStateFlow(!shouldStartPlayingImmediately)

    private val speedStateFlow = MutableStateFlow(initialOptions.speed)

    var isBuffering: Boolean = false

    internal fun pause() {
        isPausedStateFlow.value = true
    }

    /**
     * Stops the player directly, bypassing the usual mechanism where the player
     * is stopped via an update to [isPausedStateFlow]. This direct approach is necessary
     * because the coroutine observing [isPausedStateFlow] might have already been canceled.
     * See CXP-4849.
     */
    internal fun stop() {
        player?.stop()
    }

    internal fun resume() {
        isPausedStateFlow.value = false
    }

    internal fun setSpeed(speed: Int) {
        speedStateFlow.value = speed.toSpeed()
    }

    internal suspend fun getOptions(): PlayerOptions =
        PlayerOptions(
            speed = speedStateFlow.value,
            volume = getVolume(),
        )

    private suspend fun getVolume(): Float =
        this.player.let { player ->
            when {
                isActive && player != null -> player.coGetOptions().volume
                else -> initialOptions.volume
            }
        }

    internal fun isPaused(): Boolean =
        isPausedStateFlow.value

    /**
     * Logging to catch suspicious cases where the player is unresponsive (seemingly playing, but not emitting events)
     */
    private fun Flow<PlayerEvent.InPlayEvent>.collectDiagnosticsOfFrozenPlay(utterance: Utterance) = flow {
        val playTaskId = uuid4().toString()
        val hasProblemOccurred = AtomicBool(false)
        val countsOfEventsThatDidOccur = mutableMapOf<String, Int>()
        try {
            this@collectDiagnosticsOfFrozenPlay
                .onEach {
                    countsOfEventsThatDidOccur[it.type] = (countsOfEventsThatDidOccur[it.type] ?: 0) + 1
                }
                .onEachDelayUpstreamOver(duration = 4.seconds) { duration ->

                    /* Catch only one occurrence */
                    if (hasProblemOccurred.get()) return@onEachDelayUpstreamOver

                    /* If we're in the middle of a pause, don't log. Need to rather wait for the resume, and then:
                        give it the duration again:
                     */
                    while (isPausedStateFlow.value) { /* And thanks to using `while`, we also handle
                        unpausing-then-pausing again being the cause for no events
                     */
                        isPausedStateFlow.suspendUntilEquals(false)
                        delay(duration)
                    }

                    /* NOTE: The below will never be reached if an event comes during the wait for unpause, or the
                       delay after. That's because the `suspendUntilEquals` will be cancelled (all as per KDoc on
                       `onEachDelayUpstreamOver`)
                     */
                    hasProblemOccurred.set(true)
                    Log.w(
                        DiagnosticEvent(
                            message = "Play produced no events for $duration.",
                            properties = mapOf(
                                "playTaskId" to playTaskId,
                                "utterance" to utterance.text.text,
                                "voice" to utterance.voiceInfoForDebug,
                                "countsOfEventsThatDidOccur" to
                                    countsOfEventsThatDidOccur.entries.joinToString { "${it.key}:${it.value}" },
                            ),
                            sourceAreaId = "PlayerActor.diagnosticsOfFrozenPlay",
                        ),
                    )
                }
                .collect {
                    emit(it)
                }
        } catch (e: Throwable) {
            if (hasProblemOccurred.get()) {
                Log.w(
                    DiagnosticEvent(
                        sourceAreaId = "Playback.diagnosticsOfFrozenPlay",
                        message = "An earlier warning (see `playTaskId` and find a matching one) ended with an" +
                            " exception (see error in this log event)",
                        properties = mapOf(
                            "playTaskId" to playTaskId,
                        ),
                        nativeError = e,
                    ),
                )
            }

            throw e
        }
        if (hasProblemOccurred.get()) {
            Log.w(
                DiagnosticEvent(
                    sourceAreaId = "Playback.diagnosticsOfFrozenPlay",
                    message = "An earlier warning (see `playTaskId` and find a matching one) turned out to end with a" +
                        " success, so note it may not be a problem actually",
                    properties = mapOf(
                        "playTaskId" to playTaskId,
                    ),
                ),
            )
        }
    }
}

internal class BundlingToPlaybackLatencyTelemetryTracker(
    /** The original telemetry event builder which was created when the intial bundling operation was started. */
    private val bundleCreationTelemetryEventBuilder: TelemetryEventBuilder,
    /**
     * We provide the bundle metadata at this point, which includes the
     * [com.speechify.client.bundlers.reading.BundleMetadata.disableInitializationTelemetry] used for determining
     * whether to log certain telemetry events. For example, events like those in [markFirstUtteranceStartedPlaying].
     */
    private val bundleMetadata: BundleMetadata?,
) : AbstractCoroutineContextElement(BundlingToPlaybackLatencyTelemetryTracker) {
    private val wasSent = AtomicBool(false)

    /**
     * Timestamp when playback start was requrested.
     */
    internal var playbackStartRequested: Milliseconds? = null
    private var timeWhenUtterancePreparationStarted: Milliseconds? = null
    private var timeWhenUtterancePreparationEnded: Milliseconds? = null
    private var timeWhenUIWasReady: Milliseconds? = null

    private val additionalProperties = mutableMapOf<String, Any>()

    /**
     * Adds another property that will be included in the telemetry event.
     */
    fun addProperty(string: String, value: Any) {
        additionalProperties[string] = value
    }

    /**
     * Tracking the time it takes to prepare the content for listening.
     */
    fun addTimeWhenUtterancePreparationStarted() {
        timeWhenUtterancePreparationStarted = nowInMillisecondsFromEpoch()
    }

    /**
     * Tracking the time it takes to prepare the content for listening.
     */
    fun addTimeWhenUtterancePreparationEnded() {
        timeWhenUtterancePreparationEnded = nowInMillisecondsFromEpoch()
    }

    fun setTimeWhenUIWasReady(timeReady: Milliseconds) {
        this.timeWhenUIWasReady = timeReady
    }

    suspend fun markFirstUtteranceStartedPlaying() {
        val shouldSend = wasSent.compareAndSet(expect = false, set = true) &&
            bundleMetadata?.disableTelemetryOfCreateToPlaying != true
        if (!shouldSend) return

        val timeWhenUtterancePreparationEnded = timeWhenUtterancePreparationEnded
        val timeWhenUtterancePreparationStarted = timeWhenUtterancePreparationStarted

        // Sending should coincide with the block we want to track finishing.
        val timeWhenFirstUtteranceStartedPlaying = nowInMillisecondsFromEpoch()

        // We copy over all the properties from the source event to make analytics easier.
        val bundleCreationTelemetryEvent = bundleCreationTelemetryEventBuilder.build()
        val telemetryEventBuilder = bundleCreationTelemetryEvent.builderWithPrefixedProperties(
            message = "Latency.BundleCreationToFirstUtteranceStartedPlaying",
            prefix = "bundling",
        )
        val bundleCreationStartTime = bundleCreationTelemetryEvent.startTime
            ?: throw NullPointerException("Source telemetry event had no start time.")
        val bundleCreationEndTime = bundleCreationTelemetryEvent.endTime

        val playbackStartRequestToStartMs = playbackStartRequested?.let { playbackStartRequested ->
            timeWhenFirstUtteranceStartedPlaying - playbackStartRequested
        }?.apply {
            telemetryEventBuilder.addProperty("playbackStartRequestToStartMs", this)
            telemetryEventBuilder.addProperty("playbackStartRequested", playbackStartRequested)
        }

        if (playbackStartRequestToStartMs != null && bundleCreationEndTime != null) {
            telemetryEventBuilder.addProperty(
                "bundlingDurationAndPlaybackStartRequestToStartMs",
                (bundleCreationEndTime - bundleCreationStartTime) + playbackStartRequestToStartMs,
            )
        }

        val timeTillUIWasReady = timeWhenUIWasReady?.let { timeWhenUIWasReady ->
            timeWhenUIWasReady - bundleCreationStartTime
        }?.also {
            telemetryEventBuilder.addProperty("timeTillUIWasReadyMs", it)
            telemetryEventBuilder.addProperty("timeWhenUIWasReady", timeWhenUIWasReady)
        }

        val timeToPrepareUtterance =
            if (timeWhenUtterancePreparationEnded != null && timeWhenUtterancePreparationStarted != null) {
                timeWhenUtterancePreparationStarted - timeWhenUtterancePreparationEnded
            } else {
                null
            }?.also {
                telemetryEventBuilder.addProperty(
                    "timeToPrepareUtteranceMs",
                    it,
                )
                telemetryEventBuilder.addProperty(
                    "timeWhenUtterancePreparationEnded",
                    timeWhenUtterancePreparationEnded,
                )
                telemetryEventBuilder.addProperty(
                    "timeWhenUtterancePreparationStarted",
                    timeWhenUtterancePreparationStarted,
                )
            }

        (
            if (playbackStartRequested == null && bundleCreationEndTime != null && timeToPrepareUtterance != null) {
                // In the auto play case the most reliable timing is the time it took to create the bundle + time to
                // prepare the first utterance.
                (bundleCreationEndTime - bundleCreationStartTime) + timeToPrepareUtterance
            } else if (timeTillUIWasReady != null && playbackStartRequestToStartMs != null) {
                // Otherwise the time till the UI was ready + time to playback represents what the user experienced.
                playbackStartRequestToStartMs + timeTillUIWasReady
            } else {
                null
            }
            )?.apply {
            telemetryEventBuilder.addProperty("readingBundleCreationToFirstUtteranceStartedPlayingMs", this)
        }

        telemetryEventBuilder.addProperty(
            "didAutoPlayContent",
            playbackStartRequested == null,
        )

        timeWhenUtterancePreparationEnded?.let { timeWhenUtterancePreparationEnded ->
            timeWhenUtterancePreparationStarted?.let { timeWhenUtterancePreparationStarted ->
                telemetryEventBuilder.addProperty(
                    "timeToPrepareUtteranceMs",
                    timeWhenUtterancePreparationEnded - timeWhenUtterancePreparationStarted,
                )
            }
        }

        telemetryEventBuilder
            .setStartAndEndtime(bundleCreationStartTime, timeWhenFirstUtteranceStartedPlaying)
            .addProperty("timeWhenFirstUtteranceStartedPlaying", timeWhenFirstUtteranceStartedPlaying)

        additionalProperties.forEach {
            telemetryEventBuilder.addProperty(it.key, it.value)
        }

        SpeechifySDKTelemetry.report(telemetryEventBuilder.build())
    }

    companion object : CoroutineContext.Key<BundlingToPlaybackLatencyTelemetryTracker> {

        internal suspend fun currentBundlingToPlaybackLatencyTelemetryTracker():
            BundlingToPlaybackLatencyTelemetryTracker? =
            currentCoroutineContext()[BundlingToPlaybackLatencyTelemetryTracker]
    }
}

// Created as extension function since it allows us to deal with nulls easier.
/**
 * Any calls that wait for the user to take an action that blocks playback should be wrapped in this function.
 * We do this so we can track when the user unpauses.
 */
internal suspend fun BundlingToPlaybackLatencyTelemetryTracker?.withPauseBlock(block: suspend () -> Unit) {
    block()
    this?.playbackStartRequested = nowInMillisecondsFromEpoch()
}

/**
 * Checks if the current [SpeechSentence] instance contains another [SpeechSentence].
 *
 * @return `true` if the current instance is not null and contains the specified [other] [SpeechSentence],
 * `false` otherwise.
 */
private fun SpeechSentence?.contains(other: SpeechSentence): Boolean =
    (this != null && this.start.isBeforeOrAt(other.start) && this.end.isAfterOrAt(other.end))
