package com.speechify.client.internal.services.highlight.models

import com.speechify.client.api.services.library.models.PersistedUserHighlight
import com.speechify.client.internal.services.library.DestructuredCursor
import kotlinx.serialization.Serializable

/**
 * Highlight data for the request to persist a new Highlight in Firestore
 */
@Serializable
internal data class NewHighlightRequestData(
    val id: String,
    val text: String,
    val robustStart: DestructuredCursor,
    val robustEnd: DestructuredCursor,
    val style: PersistedUserHighlight.PersistedStyle,
    val note: String?,
    val pageIndex: Int?,
    val sdkVersionAtCreation: String,
    val sdkVersionAtUpdate: String,
)

/**
 * Highlight data for the request to update an existing Highlight in Firestore
 */
@Serializable
internal data class UpdateHighlightRequestData(
    val id: String,
    val text: String,
    val robustStart: DestructuredCursor,
    val robustEnd: DestructuredCursor,
    val style: PersistedUserHighlight.PersistedStyle,
    val note: String?,
    val pageIndex: Int?,
    val sdkVersionAtUpdate: String,
)

/***************** Payload classes to send as request to Firestore cloud functions****************/
@Serializable
internal data class CreateHighlightPayload(
    val itemId: String,
    val highlight: NewHighlightRequestData,
)

@Serializable
internal data class MergeHighlightsPayload(
    val itemId: String,
    val highlightIdsToMerge: Array<String>,
    val highlight: NewHighlightRequestData,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as MergeHighlightsPayload

        if (itemId != other.itemId) return false
        if (!highlightIdsToMerge.contentEquals(other.highlightIdsToMerge)) return false
        if (highlight != other.highlight) return false

        return true
    }

    override fun hashCode(): Int {
        var result = itemId.hashCode()
        result = 31 * result + highlightIdsToMerge.contentHashCode()
        result = 31 * result + highlight.hashCode()
        return result
    }
}

@Serializable
internal data class DeleteHighlightPayload(
    val itemId: String,
    val highlightId: String,
)

@Serializable
internal data class UpdateHighlightPayload(
    val itemId: String,
    val highlight: UpdateHighlightRequestData,
)
