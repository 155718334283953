package com.speechify.client.internal.util.extensions.coroutines.job

import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job

/**
 * Useful for locating the source of a leak - where a given job started.
 *
 * NOTE: Does not include [this] job itself - use [thisAndAllAncestorJobs] for that.
 */
@OptIn(ExperimentalCoroutinesApi::class)
internal fun Job.ancestorsPathFromParentToRootJob(): Sequence<Job> {
    val parent = parent ?: return emptySequence()

    return parent.thisAndAllAncestorJobs()
}

@OptIn(ExperimentalCoroutinesApi::class)
fun Job.thisAndAllAncestorJobs(): Sequence<Job> = sequence {
    yield(this@thisAndAllAncestorJobs)
    this@thisAndAllAncestorJobs.parent?.let { yieldAll(it.thisAndAllAncestorJobs()) }
}
