package com.speechify.client.internal.util.diagnostics.enriching

import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.internal.util.collections.KeyWithValueType
import com.speechify.client.internal.util.collections.PairWithSemanticStringKey
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import kotlinx.coroutines.CancellationException
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract
import kotlin.jvm.JvmName

/**
 * The [customProperties] will be added to the exception thrown, or [com.speechify.client.api.util.Result.Failure.error]
 * if the blocks produces a [com.speechify.client.api.util.Result].
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <R> errorEnrichingByPropertyAdd(
    vararg customProperties: PairWithSemanticStringKey<Any>,
    /* Don't remove `crossinline` - Removing it will make it possible for callers to use an unqualified return, which
       will prevent telemetry from being reported. */
    crossinline block: suspend () -> R,
): R {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return errorEnrichingByPropertyAdd(
        getCustomProperties = {
            customProperties
                .map {
                    it.toTextKeyProperty()
                }
                .toList()
        },
        block = block,
    )
}

internal fun Throwable.addCustomProperties(
    vararg customProperties: PairWithSemanticStringKey<Any>,
) {
    for ((key, value) in customProperties.map { it.toTextKeyProperty() }) {
        addCustomProperty(key, value)
    }
}

@JvmName("addCustomPropertiesPlain")
internal fun Throwable.addCustomProperties(
    vararg customProperties: Pair<String, Any>,
) {
    for ((key, value) in customProperties.map { it }) {
        addCustomProperty(key, value)
    }
}

internal fun PairWithSemanticStringKey<Any>.toTextKeyProperty(): Pair<String, Any> =
    first.keyId to second

/**
 * The properties fetched by [getCustomProperties] will be added to the exception thrown, or [com.speechify.client.api.util.Result.Failure.error]
 * if the blocks produces a [com.speechify.client.api.util.Result].
 * [getCustomProperties] is only resolved if an exception is thrown.
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <R> errorEnrichingByPropertyAdd(
    crossinline getCustomProperties: suspend () -> List<Pair<String, Any>>,
    /* Don't remove `crossinline` - Removing it will make it possible for callers to use an unqualified return, which
       will prevent telemetry from being reported. */
    crossinline block: suspend () -> R,
): R {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    try {
        val result = block()
        if (result is Result.Failure) {
            if (result.error is SDKError.OtherException &&
                result.error.exception is CancellationException
            ) {
                /** As per #DontEnrichCancellationExceptions, don't enrich [CancellationException] */
                return result
            }

            for ((key, value) in getCustomProperties()) {
                result.error.addCustomProperty(key, value)
            }
        }
        return result
    } catch (e: CancellationException) {
        /** Don't enrich [CancellationException]s because they signify user wanting to release currently engaged
         *  resources, so let's not waste more resources on adding properties here.
         *  #DontEnrichCancellationExceptions
         */
        throw e
    } catch (e: Throwable) {
        for ((key, value) in getCustomProperties()) {
            e.addCustomProperty(key, value)
        }

        throw e
    }
}

@JvmName("errorEnrichingByPropertyAddPlain")
internal suspend inline fun <R> errorEnrichingByPropertyAdd(
    vararg customProperties: Pair<String, Any>,
    /* Don't remove `crossinline` - Removing it will make it possible for callers to use an unqualified return, which
       will prevent telemetry from being reported. */
    crossinline block: suspend () -> R,
): R =
    errorEnrichingByPropertyAdd(
        getCustomProperties = {
            customProperties.toList()
        },
    ) {
        block()
    }

/**
 * See [createTagProperty] for explanation.
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <R> errorEnrichingWithTags(
    vararg tags: String,
    /* Don't remove `crossinline` - Removing it will make it possible for callers to use an unqualified return, which
       will prevent telemetry from being reported. */
    crossinline block: suspend () -> R,
): R {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return errorEnrichingByPropertyAdd(
        /**
         * #PoorMansTagging - add each tag as a separate `is.tag=1` property - a poor-man's solution to be able to add
         * many tags to an event, without introducing support for property collections (here a _set_ would be the
         * appropriate collection).
         */
        customProperties = tags.map { tag ->
            createTagProperty(tag)
        }
            .toTypedArray(),
        block = block,
    )
}

/**
 * A version of [errorEnrichingWithTags] for SDK [Result]s.
 * WARNING: Exceptions will not be covered. To cover them, use [errorEnrichingWithTags].
 */
internal suspend inline fun <T : Result<*>> T.enrichErrorsWithTags(
    vararg tags: String,
): T =
    errorEnrichingWithTags(
        *tags,
    ) {
        this
    }

/**
 * A version of [errorEnrichingByPropertyAdd] for SDK [Result]s.
 * WARNING: Exceptions will not be covered. To cover them, use [errorEnrichingByPropertyAdd].
 */
internal suspend inline fun <T : Result<*>> T.enrichErrorsByPropertyAdd(
    vararg customProperties: Pair<String, Any>,
): T =
    errorEnrichingByPropertyAdd(
        *customProperties,
    ) {
        this
    }

/**
 * A version of [errorEnrichingByPropertyAdd] for Kotlin native [Result]s.
 */
internal inline fun <T : kotlin.Result<*>> T.enrichErrorsByPropertyAdd(
    vararg customProperties: Pair<String, Any>,
): T =
    this
        .apply {
            onFailure {
                it.addCustomProperties(
                    *customProperties,
                )
            }
        }

/**
 * A version of [addTagProperties] for Kotlin native [Result]s.
 */
internal inline fun <T : kotlin.Result<*>> T.enrichErrorsByTagAdd(
    vararg tags: String,
): T =
    this
        .apply {
            onFailure {
                it.addTagProperties(
                    *tags,
                )
            }
        }

/**
 * See [createTagProperty] for explanation.
 */
internal fun Throwable.addTagProperties(
    vararg tags: String,
) =
    addCustomProperties(
        *tags
            .map { tag ->
                createTagProperty(tag)
            }
            .toTypedArray(),
    )

/**
 * 'Tags' are binary "has it" or "doesn't have it" properties that can be added to events.
 * This one is a shorthand [errorEnrichingByPropertyAdd] for tagging.
 *
 * The tags will be added as `is.<tag>=1` properties, as per #PoorMansTagging. But to filter for them in [Log Explorer](https://cloudlogging.app.goo.gl/i2RVpp6UdMaoMucf7),
 * you'll be able to filter events with the tags by simple addition of line with "<tag>" (alternatively, can one can add a line of `jsonPayload."is.TheTag"=1`).
 *
 * Main usefulness is to be able to filter records by them, which itself is useful to:
 * - serve a similar purpose to stack-frames - they allow to mark which entry-point in the code
 *   has the problem (Kotlin doesn't always allow us to have helpful stack-traces, especially around async code).
 */
internal fun createTagProperty(tag: String): PairWithSemanticStringKey<Any> =
    KeyWithValueType<Any>(
        keyId = "is.$tag",
    )
        .toPairWithFullKey(1)
