package com.speechify.client.api.content.epubV3

import com.speechify.client.api.adapters.archiveFiles.ArchiveFilesAdapter
import com.speechify.client.api.adapters.archiveFiles.ZipArchiveView
import com.speechify.client.api.adapters.xml.XMLParser
import com.speechify.client.api.content.epub.EpubNavigation
import com.speechify.client.api.content.epub.findNavElementByType
import com.speechify.client.api.content.epub.findNavMapElement
import com.speechify.client.api.content.epub.parseLandmarks
import com.speechify.client.api.content.epub.parseNavPoints
import com.speechify.client.api.content.epub.parseNcxNavPoints
import com.speechify.client.api.content.epubV3.builders.EpubV3Builder
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.InMemoryByteArrayBinaryContent
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.toFileWithMimeType
import com.speechify.client.api.util.orThrow

internal class EpubParserV3(
    private val archiveFilesAdapter: ArchiveFilesAdapter,
    private val xmlParser: XMLParser,
) {
    internal suspend fun parseEpubV3(bytes: BinaryContentReadableRandomly): EpubV3 {
        val unzippedEntries = archiveFilesAdapter.coCreateViewOfZip(bytes)
        return parseEpubV3(unzippedEntries)
    }

    internal suspend fun parseEpubV3FromByteArray(binaryContent: InMemoryByteArrayBinaryContent) =
        parseEpubV3(
            archiveFilesAdapter.coCreateViewOfZipFromByteArrayAndEncryptAnyTemporaryCreatedFiles(
                binaryContent.binaryContent.coGetAllBytes().orThrow(),
            ),
        )

    private suspend fun parseEpubV3(unzippedEntries: ZipArchiveView): EpubV3 {
        val opfFileEntry = unzippedEntries.entries.first { it.path.endsWith(".opf") }
        val opfElements = xmlParser.coParseAsDOM(
            opfFileEntry.coCreateBinaryContentReadableRandomly().toFileWithMimeType(MimeType("text/xml")),
        )

        val packageDocument = PackageDocument.parse(opfElements, opfFileEntry.path)
            ?: throw Exception("EpubParserV3 Error while parsing the ePub document.")

        val epubV3Navigation = parseNavOrNcxFile(packageDocument, unzippedEntries)

        return EpubV3Builder(
            packageDocument = packageDocument,
            navigation = epubV3Navigation,
            zipArchiveView = unzippedEntries,
        ).build()
    }

    private suspend fun parseNavOrNcxFile(
        packageDocument: PackageDocument,
        unzippedEntries: ZipArchiveView,
    ) = parseNavFile(packageDocument, unzippedEntries) ?: parseNcxFile(packageDocument, unzippedEntries)

    private suspend fun parseNavFile(
        packageDocument: PackageDocument,
        unzippedEntries: ZipArchiveView,
    ): EpubNavigation? {
        // Find the nav file in the manifest
        val navItem = packageDocument.manifest.find { item ->
            item.properties?.split(" ")?.contains("nav") == true
        } ?: return null

        // Extract and decode the nav file content
        val navBasePath = navItem.href.substringBeforeLast(delimiter = '/', missingDelimiterValue = String())
        val navContentFile = unzippedEntries.entries.find { it.path.endsWith(navItem.href) }
            ?.coCreateBinaryContentReadableRandomly()?.toFileWithMimeType(MimeType("application/xhtml+xml"))
            ?: return null

        // Parse the nav file content as XML
        val navDom = xmlParser.coParseAsDOM(navContentFile)

        // Find both TOC and landmarks nav elements
        val tocNavElement = findNavElementByType(navDom, "toc") ?: return null
        val landmarksNavElement = findNavElementByType(navDom, "landmarks")

        // Create a map of href to spine index for easy lookup
        val hrefToIndexMap = packageDocument.spine.itemRefs.mapIndexed { index, itemRef ->
            packageDocument.manifest.find { it.id == itemRef.idRef }?.href to index
        }.toMap()

        // Parse TOC nav points
        val tocNavPoints = parseNavPoints(
            navElement = tocNavElement,
            hrefToIndexMap = hrefToIndexMap,
            navBasePath = navBasePath,
        )

        // Parse landmarks
        val landmarks = landmarksNavElement?.let {
            parseLandmarks(it, navBasePath)
        } ?: emptyList()

        return if (tocNavPoints.isEmpty()) {
            null
        } else {
            EpubNavigation(
                tocNavPoints = tocNavPoints,
                landmarks = landmarks,
            )
        }
    }

    private suspend fun parseNcxFile(
        packageDocument: PackageDocument,
        unzippedEntries: ZipArchiveView,
    ): EpubNavigation? {
        // Find the NCX file in the manifest
        val ncxItem = packageDocument.manifest.find { it.id == "ncx" || it.href.endsWith(".ncx") } ?: return null
        // Extract and decode the NCX file content
        val ncxContentFile = unzippedEntries.entries.find { it.path.endsWith(ncxItem.href) }
            ?.coCreateBinaryContentReadableRandomly()?.toFileWithMimeType(MimeType("application/x-dtbncx+xml"))
            ?: return null
        // Parse the NCX file content as XML
        val ncxDom = xmlParser.coParseAsDOM(ncxContentFile)

        // Find the navMap element
        val navMapElement = findNavMapElement(ncxDom) ?: return null

        // Create a map of href to spine index for easy lookup
        val hrefToIndexMap = packageDocument.spine.itemRefs.mapIndexed { index, itemRef ->
            packageDocument.manifest.find { it.id == itemRef.idRef }?.href to index
        }.toMap()

        // Parse the NCX navigation points
        return EpubNavigation(
            tocNavPoints = parseNcxNavPoints(
                element = navMapElement,
                hrefToIndexMap = hrefToIndexMap,
            ),
            landmarks = emptyList(),
        )
    }
}
