package com.speechify.client.internal.util.extensions.throwable

/**
 * NOTE: it includes the `this` [Throwable] as the first in the chain.
 */
internal val Throwable.asChainFromTopLevelToRootCause: Sequence<Throwable> get() =
    generateSequence(
        seed = this,
        nextFunction = { it.cause },
    )
