package com.speechify.client.internal.util.www

import org.w3c.dom.url.URL

internal actual fun parseUrl(url: String, relativeToBaseUrl: String?): Url? = runCatching {
    val resolved = when {
        relativeToBaseUrl == null -> URL(url = url)
        else -> URL(url = url, base = relativeToBaseUrl)
    }
    return Url.create(
        scheme = resolved.protocol,
        authority = UrlAuthority.create(
            user = resolved.username,
            password = resolved.password,
            host = resolved.hostname,
            port = resolved.port.toIntOrNull(),
        ),
        path = resolved.pathname,
        query = resolved.search,
        hash = resolved.hash,
    )
}.getOrNull()
