package com.speechify.client.internal.util.extensions.collections.flows.sharedFlow

import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.collectLatest

/**
 * A missing equivalent of [kotlinx.coroutines.flow.collectLatest] for [kotlinx.coroutines.flow.StateFlow], which
 * retains its [Nothing] return value.
 */
internal suspend fun <T> SharedFlow<T>.collectLatestFromSharedFlow(
    action: suspend (value: T) -> Unit,
): Nothing {
    this.collectLatest(action)
    throw IllegalStateException(
        /** This will never be reached because the underlying collect returns `Nothing`, and the only reason
         * `collectLatest` doesn't is because it's written against the base `Flow` interface.
         */
        "Bug in `collectLatestFromSharedFlow` - report to SDK maintainers",
    )
}
