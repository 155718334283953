@file:JvmName("BlobStorageAdapterCommon")

package com.speechify.client.api.adapters.blobstorage

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapterWithEncryption.Companion.withEncoderVersionInfo
import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.services.scannedbook.Base64
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fallbackNullValueToFailure
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentially
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.maps.ThreadSafeMapOfPendingItemsOnly
import com.speechify.client.internal.util.collections.maps.threadSafeMapOfPendingItemsOnlyBy
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.jvm.JvmName

/**
 * when defining the name of some storage related to [BlobStorageAdapter] for some platform, this name should be used.
 *
 * For example, if the store is file based, a subdirectory called [STORAGE_NAME] should be used to store the files.
 */
internal const val STORAGE_NAME = "speechify-blob-storage"

@JsExport
@Serializable
class BlobStorageKey(val originalKey: String) {
    @Transient
    val sanitizedKey: String = Base64.urlSafeEncoder.encode(originalKey)
    override fun toString() = "BlobStorageKey($originalKey)"
    override fun hashCode() = sanitizedKey.hashCode()
    override fun equals(other: Any?) = when {
        this === other -> true
        other === null || other::class != this::class -> false
        else -> sanitizedKey == (other as BlobStorageKey).sanitizedKey
    }
}

/**
 * An adapter for storing arbitrary byte arrays, per platform implementations of these adapters are provided by
 * specific `BlobStorage` types defined in the sdk.
 */
@JsExport
abstract class BlobStorageAdapter {

    /**
     * As per [ThreadSafeMapOfPendingItemsOnly], store only the pending items, not to grow this cache indefinitely
     * (thus letting cache be added to higher layers where semantics of the items are known, and thus it's possible to
     * determine if caching needed and with what strategy).
     */
    private val pendingWrites: ThreadSafeMapOfPendingItemsOnly<
        BlobStorageKey,
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        > =
        threadSafeMapOfPendingItemsOnlyBy(
            getEquatableKey = { it.sanitizedKey },
        )

    /**
     * The `get` function to use in SDK.
     */
    internal open suspend fun coGetBlob(key: BlobStorageKey):
        Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?> =
        pendingWrites.getIncludingPending(key)?.await()
            ?.successfully()
            ?: (
                /* No pending writes to wait on, so now we can try in the storage. */
                coGetBlobNoFallbackUnsafeRegardingPendingWrites(key)
                    .orReturn { return it }
                    /** See [getLegacyBackwardsCompatVersion] why the fallback to `key.getLegacyBackwardsCompatVersion()` is done. */
                    ?: coGetBlobNoFallbackUnsafeRegardingPendingWrites(key.getLegacyBackwardsCompatVersion())
                        .orReturn { return it }
                )
                .successfully()

    /**
     * A `suspend` version of [getBlob]. Packaging the boilerplate into this function is useful while it needs to be called multiple times.
     * - `NoFallback` because it does not add the 'legacy keys' fallback that the [coGetBlob] does.
     * - `UnsafeRegardingPendingWrites` because it should only be called while checking the [pendingWrites] (extracted)
     */
    private suspend fun coGetBlobNoFallbackUnsafeRegardingPendingWrites(
        key: BlobStorageKey,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?> =
        suspendCoroutine { cont ->
            getBlob(key, cont::resume)
        }

    /**
     * Load a blob from the backing store. Calling this function is expected to not load the full data into memory.
     *
     * @return Success(null) if the key was not found, Success(file) otherwise.
     *
     * NOTE to SDK developers: Call [coGetBlob] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun getBlob(
        key: BlobStorageKey,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?>,
    )

    internal open suspend fun coPutBlobByMove(
        key: BlobStorageKey,
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    ): Result<BinaryContentReadableRandomly> =
        suspendCoroutine {
            putBlobByMove(
                key = key,
                contentToMove = contentToMove,
                callback = it::resume,
            )
        }

    /**
     * Stores the [contentToMove] under the [key] by moving it, and returns the [BinaryContentReadableRandomly] that can
     * be used to access the content from the store.
     * NOTE: the *move* part of the name signifies that if [contentToMove] is also a resource stored persistently (e.g.
     * a temporary file), then it can be moved to this storage, which should be more performant, i.e. changing the
     * file's path, as opposed to reading its contents. (This is the #TempFilesCleanupOpportunity).
     * WARNING: But such a move should only be done if that move leaves [contentToMove] still
     * operational (like for example a file move does in Unix systems, as any file-handles opened before a file move
     * are still operational), given the platform specific implementation of the provided
     * [BinaryContentReadableRandomly]. A 'copy' can still be considered to be safe. E.g., if [BinaryContentReadableRandomly]
     * stores merely the path, and a file-handle for it may still not be an opened one, then it would only be safe to
     * copy the file (but it would be beneficial to change the implementation of [BinaryContentReadableRandomly] so that
     * it does allow the move).
     *
     * This overwrites the previous value if any.
     *
     * WARNING for Implementors: This should be implemented atomically. Typically, file moves are atomic in platforms,
     * but the implementors should ensure this.
     *
     * NOTE to SDK developers: Call [coPutBlobByMove] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun putBlobByMove(
        key: BlobStorageKey,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        callback: Callback<BinaryContentReadableRandomly>,
    )

    internal open suspend fun coPutBlob(
        key: BlobStorageKey,
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    ): Result<BinaryContentReadableRandomly> =
        pendingWrites.add(
            key = key,
            value = {
                suspendCoroutine {
                    putBlob(
                        key = key,
                        contentWithMimeType = contentWithMimeType,
                        callback = it::resume,
                    )
                }
                    .orThrow()
                    .withMimeType(contentWithMimeType.mimeType)
            },
        )
            .binaryContent
            .successfully()

    /**
     * Stores the [contentWithMimeType] under the [key], and returns the [BinaryContentReadableRandomly] that can
     * be used to access the content from the store.
     *
     * This overwrites the previous value if any.
     *
     * WARNING for Implementors: This should be implemented atomically, i.e. without there being any possibility for
     * a partial file to be left if a crash or device power-off happens when program is in the call. If the platform
     * does not offer any easy atomic API, then it can be implemented by writing to a file at a different location and
     * moving into the final one only after the successful writing.
     *
     * NOTE to SDK developers: Call [coPutBlob] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun putBlob(
        key: BlobStorageKey,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        callback: Callback<BinaryContentReadableRandomly>,
    )

    internal open suspend fun coPutSequenceGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
    ): Result<BinaryContentReadableRandomly> =
        pendingWrites.add(
            key = key,
            value = {
                suspendCoroutine {
                    putSequenceGetReadableRandomly(
                        key = key,
                        content = content,
                        callback = it::resume,
                    )
                }
                    .orThrow()
                    .withMimeType(content.mimeType)
            },
        )
            .binaryContent
            .successfully()

    /**
     * Stores the [content] under the [key] and returns the [BinaryContentReadableRandomly] that can be used to
     * access the content from the store.
     *
     * This overwrites the previous value if any.
     *
     * WARNING for Implementors: This should be implemented atomically, i.e. without there being any possibility for
     * a partial file to be left if a crash or device power-off happens when program is in the call. If the platform
     * does not offer any easy atomic API, then it can be implemented by writing to a file at a different location and
     * moving into the final one only after the successful writing.
     *
     * NOTE to SDK developers: Call [coPutSequenceGetReadableRandomly] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun putSequenceGetReadableRandomly(
        key: BlobStorageKey,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
        @Suppress(
            /* `NON_EXPORTABLE_TYPE` is unnecessary because the `actual` type is exported. */
            "NON_EXPORTABLE_TYPE",
        )
        callback: Callback<BinaryContentReadableRandomly>,
    )

    /**
     * A version of [getBlob] but where the result is not a [com.speechify.client.api.util.io.BinaryContentReadableInChunksWithNativeAPI]
     * (in order to allow the SDK to access the bytes, for example to decrypt the data).
     *
     * A write-counterpart of this is [putBytes].
     */
    @Deprecated("Use coGetBlob instead", ReplaceWith("coGetBlob(key)"))
    internal open suspend fun getBytes(
        key: BlobStorageKey,
    ): Result<BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>?> =
        coGetBlob(key)

    /**
     * See [putBytes]
     */
    internal open suspend fun coPutBytesGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    ): Result<BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>> =
        pendingWrites.add(
            key = key,
            value = {
                coPutBytes(
                    key = key,
                    content = content,
                )
                    .orThrow()

                /**
                 * Use `UnsafeRegardingPendingWrites` is needed here because else we'd get a deadlock (we'd get a
                 * `Deferred` of this very routine, which obviously hasn't completed yet).
                 */
                return@add coGetBlobNoFallbackUnsafeRegardingPendingWrites(key)
                    .fallbackNullValueToFailure {
                        /* Use an exception to know the entry-point via the stacktrace */
                        SDKError.OtherException(
                            Exception(
                                "The file was stored, but its retrieval returned `null`. Check the " +
                                    "`BlobStorageAdapter` implementation",
                            )
                                .apply { addCustomProperty("key", key) },
                        )
                    }
                    .orThrow()
            },
        )
            .successfully()

    /**
     * See [putBytes]
     */
    internal open suspend fun coPutBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    ): Result<Unit> =
        suspendCoroutine {
            putBytes(
                key = key,
                content = content,
                callback = it::resume,
            )
        }

    internal open suspend fun fileExists(key: BlobStorageKey): Boolean {
        return coGetBlob(key).orReturn { return false } != null
    }

    /**
     * A version of [coPutSequenceGetReadableRandomly] but where the [content] is not a [com.speechify.client.api.util.io.BinaryContentReadableInChunksWithNativeAPI]
     * (in order to allow the SDK to access the bytes, for example to encrypt the data).
     *
     * A read-counterpart of this is [getBytes].
     *
     * This overwrites the previous value if any.
     *
     * WARNING for Implementors: This should be implemented atomically, i.e. without there being any possibility for
     * a partial file to be left if a crash or device power-off happens when program is in the call. If the platform
     * does not offer any easy atomic API, then it can be implemented by writing to a file at a different location and
     * moving into the final one only after the successful writing.
     *
     * NOTE to SDK developers: Call [coPutBytesGetReadableRandomly] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun putBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
        @Suppress(
            "NON_EXPORTABLE_TYPE", /* `Unit` exports just fine */
        )
        callback: Callback<Unit>,
    )

    /**
     * The `delete` function to use in SDK.
     */
    internal open suspend fun coDeleteBlob(
        key: BlobStorageKey,
    ): Result<Boolean> =
        (
            coDeleteBlobNoFallback(key)
                .orReturn { return it } ||
                /** See [getLegacyBackwardsCompatVersion] why the fallback to `key.getLegacyBackwardsCompatVersion()` is done. */
                coDeleteBlobNoFallback(key.getLegacyBackwardsCompatVersion())
                    .orReturn { return it }
            )
            .successfully()

    /**
     * A `suspend` version of [deleteBlob]. Packaging the boilerplate into this function is useful while it needs to be called multiple times.
     */
    private suspend fun coDeleteBlobNoFallback(
        key: BlobStorageKey,
    ): Result<Boolean> {
        /*
         * `join` any pending writes, to avoid race condition of deleting a file that is being written to.
         * TODO: Some operating-systems allow deleting an opened file - consider exploring if this is possible in all
         *  platforms, and if so, make it part of the contract.
         */
        pendingWrites.getIncludingPending(key)?.join()
        return suspendCoroutine { cont ->
            deleteBlob(key, cont::resume)
        }
    }

    /**
     * Delete an entry from the cache
     *
     * @return `true` if the item existed
     *
     * NOTE to SDK developers: Call [coDeleteBlob] instead of this function (the reason for this one being `protected`)
     */
    protected abstract fun deleteBlob(key: BlobStorageKey, callback: Callback<Boolean>)

    /** Used for fallback to a behavior temporarily introduced [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/924/files#diff-bc54d0afd3743e7911f28b37045134972ab8c70c8ce1f7266c9da5d439fb018fR70)
     * for all files, where the keys were prefixed with `0:` and all cached files became inaccessible, which
     * was then restored to non-prefixed version for non-encrypted files [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/988/files#diff-bc54d0afd3743e7911f28b37045134972ab8c70c8ce1f7266c9da5d439fb018fL72),
     *  (fallback introduced by the way during the restore, not to degrade user's experience after upgrade and not waste their disk space again,
     *  as [there may be no cleanup mechanism in place](https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1684318661274909)
     */
    private fun BlobStorageKey.getLegacyBackwardsCompatVersion() =
        withEncoderVersionInfo(
            // As per [old code here](https://github.com/SpeechifyInc/multiplatform-sdk/blob/c66921c8bd739670e7492be289ecaff60b0bc9cd/multiplatform-sdk/src/commonMain/kotlin/com/speechify/client/internal/caching/ReadWriteThroughCachedFirebaseStorage.kt#LL197C1-L197C1)
            encoderId = "0",
        )
}

internal fun BlobStorageAdapter.traced() = BlobStorageAdapterTraced(this)

internal class BlobStorageAdapterTraced(private val blobStorageAdapter: BlobStorageAdapter) : BlobStorageAdapter() {
    override fun getBlob(
        key: BlobStorageKey,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coGetBlob], which calls the wrapped instance */
        )

    override suspend fun coGetBlob(
        key: BlobStorageKey,
    ): Result<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>?,
        > = debugCallAndResultWithUuid("BlobStorageAdapter.load($key)") {
        blobStorageAdapter.coGetBlob(key)
    }

    override fun putBlob(
        key: BlobStorageKey,
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coPutBlob], which calls the wrapped instance */
        )

    override suspend fun coPutBlob(
        key: BlobStorageKey,
        contentWithMimeType: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    ) = debugCallAndResultWithUuid("BlobStorageAdapter.putBlob($key, ${contentWithMimeType.mimeType?.fullString})") {
        blobStorageAdapter.coPutBlob(key, contentWithMimeType)
    }

    override fun putBlobByMove(
        key: BlobStorageKey,
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coPutBlobByMove], which calls the wrapped instance */
        )

    override suspend fun coPutBlobByMove(
        key: BlobStorageKey,
        contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    ) = debugCallAndResultWithUuid("BlobStorageAdapter.putByMove($key, ${contentToMove.mimeType?.fullString})") {
        blobStorageAdapter.coPutBlobByMove(key, contentToMove)
    }

    override fun putSequenceGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
        callback: Callback<BinaryContentReadableRandomly>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coPutSequenceGetReadableRandomly], which calls the wrapped instance */
        )

    override suspend fun coPutSequenceGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
    ) = debugCallAndResultWithUuid("BlobStorageAdapter.putSequence($key, ${content.mimeType?.fullString})") {
        blobStorageAdapter.coPutSequenceGetReadableRandomly(key, content)
    }

    override fun putBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
        callback: Callback<Unit>,
    ) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coPutBytesGetReadableRandomly], which calls the wrapped instance */
        )

    override suspend fun coPutBytesGetReadableRandomly(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    ) = debugCallAndResultWithUuid(
        areaId = "BlobStorageAdapter.putBytesGetReadableRandomly($key, ${content.mimeType?.fullString})",
    ) {
        blobStorageAdapter.coPutBytesGetReadableRandomly(key, content)
    }

    override suspend fun coPutBytes(
        key: BlobStorageKey,
        content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    ) = debugCallAndResultWithUuid("BlobStorageAdapter.putBytes($key, ${content.mimeType?.fullString})") {
        blobStorageAdapter.coPutBytes(key, content)
    }

    override fun deleteBlob(key: BlobStorageKey, callback: Callback<Boolean>) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coDeleteBlob], which calls the wrapped instance */
        )

    override suspend fun coDeleteBlob(
        key: BlobStorageKey,
    ): Result<Boolean> = debugCallAndResultWithUuid("BlobStorageAdapter.delete($key)") {
        blobStorageAdapter.coDeleteBlob(key)
    }
}
