package com.speechify.client.internal.util.extensions.coroutines

import com.speechify.client.internal.sync.DeferredTryGetResult
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.ExperimentalCoroutinesApi

/**
 * Returns the value only if it is already available.
 * Returns [DeferredTryGetResult.NotAvailableYet] if it is not yet available.
 *
 * @throws CancellationException passed to [Deferred.cancel] if it was called.
 * @throws CancellationException passed to [kotlinx.coroutines.CompletableDeferred.completeExceptionally] if it was called.
 */
@OptIn(ExperimentalCoroutinesApi::class)
internal fun <V> Deferred<V>.tryGetValue(): DeferredTryGetResult<V> {
    return if (isCompleted) {
        DeferredTryGetResult.Success(getCompleted())
    } else {
        DeferredTryGetResult.NotAvailableYet
    }
}

internal val <T> Deferred<T>.hasValue
    get() = isCompleted && !isCancelled
