package com.speechify.client.internal.services.library

import com.benasher44.uuid.uuid4
import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.adapters.firebase.Collections
import com.speechify.client.api.adapters.firebase.DataSource
import com.speechify.client.api.adapters.firebase.DocumentChangeType
import com.speechify.client.api.adapters.firebase.DocumentQueryBuilder
import com.speechify.client.api.adapters.firebase.FirebaseAuthUser
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.FirebaseTimestampAdapter
import com.speechify.client.api.adapters.firebase.GoogleCloudStorageUriFileId
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.SnapshotRef
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.CompoundFilterType
import com.speechify.client.api.services.library.models.ContentType
import com.speechify.client.api.services.library.models.FilterAndSortOptions
import com.speechify.client.api.services.library.models.FilterType
import com.speechify.client.api.services.library.models.FolderChangeSet
import com.speechify.client.api.services.library.models.FolderQuery
import com.speechify.client.api.services.library.models.FolderReference
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.SortBy
import com.speechify.client.api.services.library.models.SortOrder
import com.speechify.client.api.services.library.models.UpdateLibraryItemParams
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toResultFailure
import com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload
import com.speechify.client.helpers.features.getProgressFractionValidationExceptionOrNull
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.caching.getDownloadUrl
import com.speechify.client.internal.runTask
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.services.importing.setLibraryItemStatusToErrorPayload
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer.createFolderToFirestoreSavePayload
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer.toLibraryItem
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer.updateItemFolderToFirestoreSavePayload
import com.speechify.client.internal.services.library.LibraryFirebaseTransformer.updateLibraryItemParamsToFirestoreSavePayload
import com.speechify.client.internal.services.library.models.FirebaseLibraryItem
import com.speechify.client.internal.services.library.models.LegacyPage
import com.speechify.client.internal.services.library.models.PlatformSearchResult
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import com.speechify.client.internal.util.www.UrlString
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.toList
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

internal class LibraryFirebaseDataFetcher(
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val firebaseTimestampAdapter: FirebaseTimestampAdapter,
    private val firebaseStorage: ReadWriteThroughCachedFirebaseStorage,
    private val platformShareService: PlatformShareService,
    private val clientConfig: ClientConfig,
    internal val offlineAvailabilityManager: OfflineAvailabilityManager,
) {
    suspend fun observeTopLevelArchivedItems(
        userId: String,
        callback: Callback<FolderChangeSet>,
    ): Destructor {
        return buildDocumentsQueryFetcher(
            FolderQuery.All,
            FilterAndSortOptions(
                FilterType.TOP_LEVEL_ARCHIVED_ITEMS,
                SortBy.DATE_ARCHIVED,
                SortOrder.DESC,
            ),
            userId,
            null,
            null,
        ).coObserve { result ->
            callback(
                result.then { querySnapshot ->
                    val changeSet = querySnapshot
                        .docChanges(null)
                        .asFlow()
                        .mapNotNull {
                            when (val r = it.doc.value<FirebaseLibraryItem>()) {
                                is Result.Success -> it.type to toLibraryItem(
                                    firebaseFirestoreService,
                                    it.doc.key,
                                    r.value,
                                    it.doc.snapshotRef,
                                    offlineAvailabilityManager,
                                )
                                is Result.Failure -> {
                                    Log.d(
                                        DiagnosticEvent(
                                            message = "Failed to deserialize library item",
                                            error = ErrorInfoForDiagnostics(r.errorNative),
                                            sourceAreaId = "LibraryFirebaseDataFetcher.observeTopLevelArchivedItems",
                                        ),
                                    )
                                    null
                                }
                            }
                        }
                        .toList()
                        .groupBy({ it.first }, { it.second })

                    FolderChangeSet(
                        added = changeSet[DocumentChangeType.Added]?.toTypedArray() ?: emptyArray(),
                        modified = changeSet[DocumentChangeType.Modified]?.toTypedArray()
                            ?: emptyArray(),
                        removed = changeSet[DocumentChangeType.Removed]?.toTypedArray()
                            ?: emptyArray(),
                    ).successfully()
                },
            )
        }
    }

    internal fun observeLibraryItem(
        itemId: String,
    ): Flow<Result<LibraryItem>> = firebaseFirestoreService
        .observeDocumentAsFlow(
            collectionRef = Collections.ITEMS.collectionRef,
            documentRef = itemId,
            mapExists = { toLibraryItem(it) },
            valueForNotExists = {
                Result.Failure(
                    SDKError.ResourceNotFound(
                        itemId,
                        "Could not find the library Item from `items` Collection.",
                    ),
                ).also { Log.e(it, sourceAreaId = "LibraryFirebaseDataFetcher.observeLibraryItem") }
            },
        )

    suspend fun updateItemDataFromParams(itemId: String, payload: BoundaryMap<Any?>): Result<Unit> {
        return firebaseFirestoreService
            .coUpdateDocument(Collections.ITEMS.collectionRef, itemId, payload)
    }

    suspend fun updateItemDataFromParams(
        itemId: String,
        patch: UpdateLibraryItemParams,
    ): Result<Unit> {
        // Validation
        ifNotNull(
            patch.listeningProgress?.fraction?.let { fraction ->
                getProgressFractionValidationExceptionOrNull(
                    fraction,
                    areaId = "${LibraryFirebaseDataFetcher::class.simpleName} updateItemDataFromParams",
                )
            },
        ) { exception ->
            return@updateItemDataFromParams exception.toResultFailure()
        }

        // Validation passed, update
        val payload = updateLibraryItemParamsToFirestoreSavePayload(patch, firebaseTimestampAdapter)
        return updateItemDataFromParams(itemId, payload)
    }

    suspend fun updateItemFolder(itemId: String, newFolder: FolderReference): Result<Unit> {
        val payload = updateItemFolderToFirestoreSavePayload(newFolder.id, firebaseTimestampAdapter)
        return updateItemDataFromParams(itemId, payload)
    }

    private fun buildDocumentsQueryFetcher(
        folderItemId: FolderQuery,
        options: FilterAndSortOptions,
        userId: String,
        limit: Int?,
        startAfterItem: LibraryItem?,
    ): DocumentQueryBuilder {
        val queryFetcher = firebaseFirestoreService.queryDocuments(Collections.ITEMS.collectionRef)

        // make sure all the items are owned by the current user
        queryFetcher.where("owner", DocumentQueryBuilder.Operator.EQ, userId)

        if (folderItemId is FolderQuery.Only) {
            queryFetcher.where(
                "parentFolderId",
                DocumentQueryBuilder.Operator.EQ,
                folderItemId.ref.id,
            )
        }
        addClauseForFilterType(queryFetcher, options.type)
        // don't fetch anything that's removed
        queryFetcher.where(RecordProperties.isRemovedV2.keyId, DocumentQueryBuilder.Operator.EQ, false)

        // sort by with direction
        val sortDirection =
            if (options.sortOrder == SortOrder.ASC) {
                DocumentQueryBuilder.Direction.Ascending
            } else {
                DocumentQueryBuilder.Direction.Descending
            }

        when (options.sortBy) {
            SortBy.ALPHABETICAL -> queryFetcher.orderBy("titleLowercase", sortDirection)
            SortBy.DATE_ADDED -> queryFetcher.orderBy("createdAt", sortDirection)
            SortBy.RECENTLY_LISTENED -> queryFetcher.orderBy("lastListenedAt", sortDirection)
                .orderBy("createdAt", sortDirection)
            SortBy.DATE_ARCHIVED -> queryFetcher.orderBy("removedAt", sortDirection)
        }

        if (startAfterItem != null) {
            queryFetcher.boundBy(
                startAfterItem.snapshotRef,
                DocumentQueryBuilder.BoundType.StartAfter,
            )
        }

        // apply size limit
        if (limit != null) queryFetcher.limit(limit)

        return queryFetcher
    }

    private fun addClauseForFilterType(
        queryFetcher: DocumentQueryBuilder,
        filterType: FilterType,
    ) {
        if (filterType is FilterType.TOP_LEVEL_ARCHIVED_ITEMS) {
            queryFetcher.where("isTopLevelArchivedItem", DocumentQueryBuilder.Operator.EQ, true)
        } else {
            queryFetcher.where("isArchivedV2", DocumentQueryBuilder.Operator.EQ, false)
        }

        if (filterType is FilterType.LISTENING_NOT_STARTED) {
            queryFetcher.where("progressStatus", DocumentQueryBuilder.Operator.EQ, "not-started")
        } else if (filterType is FilterType.LISTENING_IN_PROGRESS) {
            queryFetcher.where("progressStatus", DocumentQueryBuilder.Operator.EQ, "in-progress")
        } else if (filterType is FilterType.LISTENING_IN_PROGRESS_AND_NOT_STARTED) {
            queryFetcher.where(
                "progressStatus",
                DocumentQueryBuilder.OperatorList.IN,
                arrayOf("in-progress", "not-started"),
            )
        } else if (filterType is FilterType.LISTENING_FINISHED) {
            queryFetcher.where("progressStatus", DocumentQueryBuilder.Operator.EQ, "done")
        } else if (filterType is FilterType.FOLDERS) {
            queryFetcher.where("type", DocumentQueryBuilder.Operator.EQ, "folder")
        } else if (filterType is FilterType.RECORDS) {
            queryFetcher.where("type", DocumentQueryBuilder.Operator.EQ, "record")
            val types = filterType.recordTypes
            if (types.isNotEmpty()) {
                val typesString = types.map { t -> t.toString() }
                queryFetcher.where("recordType", DocumentQueryBuilder.OperatorList.IN, typesString.toTypedArray())
            }
        } else if (filterType is CompoundFilterType) {
            if (filterType.logicalOperator == CompoundFilterType.LogicalOperator.AND) {
                filterType.filterTypes.forEach { nestedFilter ->
                    addClauseForFilterType(queryFetcher, nestedFilter)
                }
            }
        }
    }

    internal suspend fun getSpeechifyBookItem(
        speechifyBookUri: String,
        userId: String,
    ): Result<LibraryItem.Content?> {
        val queryFetcher = firebaseFirestoreService.queryDocuments(Collections.ITEMS.collectionRef)

        queryFetcher.where("owner", DocumentQueryBuilder.Operator.EQ, userId)
        queryFetcher.where("speechifyBookProductUri", DocumentQueryBuilder.Operator.EQ, speechifyBookUri)
        queryFetcher.where(RecordProperties.isRemovedV2.keyId, DocumentQueryBuilder.Operator.EQ, false)

        val documents = suspendCoroutine {
            queryFetcher.fetch(it::resume)
        }.orReturn {
            return Result.Failure(
                SDKError.IO("Failed to fetch item with given speechify book product id in Firebase"),
            )
        }
        if (documents.isEmpty()) {
            return null.successfully()
        }
        val speechifyBookDocument = documents.first()
        if (speechifyBookDocument !is FirebaseFirestoreDocumentSnapshot.Exists) {
            return null.successfully()
        }
        val speechifyBookDocumentValue = speechifyBookDocument.value<FirebaseLibraryItem>()
        if (speechifyBookDocumentValue !is Result.Success) {
            return Result.Failure(
                SDKError.IO(
                    "Failed to serialize item with given speechify book product id to internal Firebase model",
                ),
            )
        }

        val speechifyBookDocumentAsLibraryItem =
            toLibraryItem(
                firebaseFirestoreService,
                speechifyBookDocument.key,
                speechifyBookDocumentValue.value,
                speechifyBookDocument.snapshotRef,
                offlineAvailabilityManager,
            )
        if (speechifyBookDocumentAsLibraryItem !is LibraryItem.Content) {
            return null.successfully()
        }

        return speechifyBookDocumentAsLibraryItem.successfully()
    }

    internal suspend fun getLibraryItem(
        itemId: String,
        dataSource: DataSource = DataSource.DEFAULT,
    ): Result<LibraryItem> {
        val firestoreDocument = firebaseFirestoreService.getLibraryItemFirestoreDocument(itemId, dataSource)
            .orReturn { return it }

        val firebaseLibraryItem = firestoreDocument.value<FirebaseLibraryItem>().orReturn { return it }
        return toLibraryItem(
            firebaseFirestoreService,
            firestoreDocument.key,
            firebaseLibraryItem,
            firestoreDocument.snapshotRef,
            offlineAvailabilityManager,
            dataSource = dataSource,
        ).successfully()
    }

    fun getItemsQuery(
        parentFolderId: FolderQuery,
        options: FilterAndSortOptions,
        userId: String,
        limit: Int,
        startAfterItem: LibraryItem?,
    ): DocumentQueryBuilder {
        return buildDocumentsQueryFetcher(parentFolderId, options, userId, limit, startAfterItem)
    }

    suspend fun toLibraryItem(
        snapshot: FirebaseFirestoreDocumentSnapshot.Exists,
        dataSource: DataSource = DataSource.DEFAULT,
    ) = snapshot
        .value<FirebaseLibraryItem>() /* NOTE: `value()` is where deserialization happens */
        .map {
            toLibraryItem(
                firebaseFirestoreService,
                snapshot.key,
                it,
                snapshot.snapshotRef,
                offlineAvailabilityManager,
                dataSource = dataSource,
            )
        }

    /**
     * Turns the raw results of search into [LibraryItem]s usable in the client
     */
    suspend fun toLibraryItems(platformSearchResult: PlatformSearchResult): List<LibraryItem> {
        return platformSearchResult.results.map {
            toLibraryItem(
                firebaseFirestoreService = firebaseFirestoreService,
                firebaseLibraryItem = it,
                // asserting non-null here since it's safe to assume that the id will exist given the implementation of
                // the indexing trigger.
                firebaseLibraryItemId = it.id!!,
                offlineAvailabilityManager = offlineAvailabilityManager,
                snapshotRef = SnapshotRef(it),
            )
        }
    }

    fun getContentItemBySourceURL(
        sourceURL: String,
        userId: String,
        callback: Callback<LibraryItem.Content?>,
    ) {
        runTask { callback(getContentItemBySourceURL(sourceURL, userId)) }
    }

    private suspend fun getContentItemBySourceURL(
        sourceURL: String,
        userId: String,
    ): Result<LibraryItem.Content?> {
        val filters = FilterAndSortOptions(FilterType.ANY, SortBy.ALPHABETICAL, SortOrder.ASC)
        val queryFetcher =
            buildDocumentsQueryFetcher(FolderQuery.All, filters, userId, 1, null)
        queryFetcher.where(RecordProperties.sourceURL.keyId, DocumentQueryBuilder.Operator.EQ, sourceURL)
        val documents = suspendCoroutine {
            queryFetcher.fetch(it::resume)
        }.orReturn {
            return Result.Failure(
                SDKError.IO("Failed to fetch item with given source URL in Firebase"),
            )
        }
        if (documents.isEmpty()) {
            return null.successfully()
        }
        val firstDocument = documents.first()
        if (firstDocument !is FirebaseFirestoreDocumentSnapshot.Exists) {
            return null.successfully()
        }
        val firstDocumentValue = firstDocument.value<FirebaseLibraryItem>()
        if (firstDocumentValue !is Result.Success) {
            return Result.Failure(
                SDKError.IO(
                    "Failed to serialize item with given source URL to internal Firebase model",
                ),
            )
        }

        val firstDocumentAsLibraryItem =
            toLibraryItem(
                firebaseFirestoreService,
                firstDocument.key,
                firstDocumentValue.value,
                firstDocument.snapshotRef,
                offlineAvailabilityManager,
            )
        if (firstDocumentAsLibraryItem !is LibraryItem.Content) {
            return null.successfully()
        }

        return firstDocumentAsLibraryItem.successfully()
    }

    internal suspend fun shareFileItem(file: LibraryItem.Content): Result<String> {
        if (file.contentType == ContentType.SPEECHIFY_BOOK) {
            throw IllegalArgumentException("Item with type ${ContentType.SPEECHIFY_BOOK} can't be shared")
        }
        return platformShareService.startSharing(file)
    }

    internal suspend fun stopSharingFileItem(fileItemId: String): Result<Unit> {
        return platformShareService.stopSharing(fileItemId)
    }

    suspend fun createFolder(
        folderTitle: String,
        ownerId: String,
        parentFolderId: FolderReference,
    ): Result<FolderReference> {
        val id = uuid4().toString()
        val payload = createFolderToFirestoreSavePayload(
            ownerId,
            parentFolderId,
            folderTitle,
            firebaseTimestampAdapter,
        )

        return firebaseFirestoreService.coSetDocument(Collections.ITEMS.collectionRef, id, payload, merge = false).map {
            id
        }.map { FolderReference.fromId(it) }
    }

    internal suspend fun storeThumbnail(
        thumbnail: BinaryContentWithMimeTypePayload<*, *>,
        coverImageFilename: String,
        fileExtensionDotless: String?,
        user: FirebaseAuthUser,
    ): Result<UrlString> {
        val coverImageStorageBucket = GoogleCloudStorageUriFileId(
            "gs://${clientConfig.googleProjectId}.appspot.com/multiplatform/coverImages/" +
                "${user.uid}/$coverImageFilename${fileExtensionDotless?.let { ".$it" } ?: ""}",
        )

        firebaseStorage.putFile(
            fileId = coverImageStorageBucket,
            payload = thumbnail,
        )

        val coverImagePath = firebaseStorage.getDownloadUrl(coverImageStorageBucket)

        return coverImagePath.successfully()
    }

    internal suspend fun getLegacyPageURLs(itemId: String): Result<List<String>> {
        val results = firebaseFirestoreService.queryDocuments("items/$itemId/pages").coFetch().orReturn { return it }
        // TODO: not sure if this correctly handles deserialization errors
        return coroutineScope {
            val downloadUrlResults = results
                .filterIsInstance<FirebaseFirestoreDocumentSnapshot.Exists>()
                .map { it to it.value<LegacyPage>() }
                // Report parsing failure of Library Item Page records, since we'll filter them out to avoid hard failure
                .map { (_, page) ->
                    page.onFailure({ Log.e(it, sourceAreaId = "LibraryFirebaseDataFetcher.getLegacyPageURLs") })
                    page
                }
                .filterIsInstance<Result.Success<LegacyPage>>()
                .map { it.value }
                .sortedBy { it.index }
                .map { it.storagePath }
                // Make sure the path doesn't have leading or trailing separators - legacy clients behavior is not
                // consistent in this regard, so we remove to normalize
                // see [PLT-2399](https://linear.app/speechify-inc/issue/PLT-2399/library-bundling-for-android-scanned-items-fails) for the initial report
                .map { it.trim('/') }
                .asFlow()
                .map { storagePath ->
                    storagePath to firebaseStorage.getDownloadUrl(
                        "gs://${clientConfig.googleProjectId}.appspot.com/$storagePath",
                    )
                }.toList()

            // Report failures to get download URL, since we'll filter them out to avoid hard failure
            downloadUrlResults.forEach { (_, result) ->
                result.onFailure({ Log.e(it, sourceAreaId = "LibraryFirebaseDataFetcher.getLegacyPageURLs") })
            }

            return@coroutineScope downloadUrlResults.map { (_, downloadUrlResult) -> downloadUrlResult }
                .filterIsInstance<Result.Success<String>>().map { it.value }.successfully()
        }
    }

    internal suspend fun setItemStatusAsError(itemId: String): Result<Unit> {
        val payload = setLibraryItemStatusToErrorPayload(firebaseTimestampAdapter)
        return firebaseFirestoreService.coUpdateDocument(Collections.ITEMS.collectionRef, itemId, payload)
    }

    suspend fun getCount(
        ownerId: String,
        filterType: FilterType,
    ): Result<Long> {
        val queryFetcher =
            firebaseFirestoreService.queryDocuments(Collections.ITEMS.collectionRef)
                .where("owner", DocumentQueryBuilder.Operator.EQ, ownerId)
                .where("isArchived", DocumentQueryBuilder.Operator.EQ, false)
                .where("isArchivedV2", DocumentQueryBuilder.Operator.EQ, false)
                .where("isRemoved", DocumentQueryBuilder.Operator.EQ, false)
                .where("isRemovedV2", DocumentQueryBuilder.Operator.EQ, false)
        addClauseForFilterType(queryFetcher, filterType)

        return suspendCoroutine { queryFetcher.count(it::resume) }
    }
}

internal suspend inline fun <reified TypeToParseTo> FirebaseFirestoreService.getParsedLibraryItemFirestoreDocument(
    libraryItemId: String,
): Result<TypeToParseTo> =
    getLibraryItemFirestoreDocument(libraryItemId)
        .orReturn { return it }
        .value() // This is where the parsing happens

internal fun getLibraryItemPath(itemId: SpeechifyContentId) = PathInCollection(Collections.ITEMS.collectionRef, itemId)

internal suspend fun FirebaseFirestoreService.getLibraryItemFirestoreDocument(
    libraryItemId: String,
    dataSource: DataSource = DataSource.DEFAULT,
): Result<FirebaseFirestoreDocumentSnapshot.Exists> {
    val firestoreDocument = coGetDocument(getLibraryItemPath(libraryItemId), dataSource)
        .orReturn { return it }

    if (firestoreDocument !is FirebaseFirestoreDocumentSnapshot.Exists) {
        return Result.Failure(
            SDKError.ResourceNotFound(
                libraryItemId,
                "Item not found when getting library item",
            ),
        )
    }

    return firestoreDocument
        .successfully()
}
