package com.speechify.client.api.services.audio

import com.speechify.client.api.audio.AudioMediaFormat
import com.speechify.client.api.audio.AudioMediaFormatOptions
import com.speechify.client.api.audio.MediaSynthesisService
import com.speechify.client.api.audio.SynthesizeOptions
import com.speechify.client.api.audio.VoiceSpec
import kotlinx.serialization.Serializable

/**
 * A markup interface to group those [AudioService] implementations which are backed by a version of [Speechify Audio
 * Service](https://audio.docs.speechify.dev/synthesis/overview.html).
 */
internal interface AudioService : MediaSynthesisService

internal fun SynthesizeOptions.toAudioServiceSynthesizeOptions() =
    AudioServiceSynthesizeOptions(
        audioMediaFormat = audioMediaFormat,
        voiceParams = voiceSpec.toVoiceParams(),
    )

internal fun VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer.toVoiceParams(): VoiceParams =
    VoiceParams(
        name = name,
        engine = this.engine,
        languageCode = languageCode,
    )

internal data class AudioServiceSynthesizeOptions(
    override val audioMediaFormat: AudioMediaFormat?,
    val voiceParams: VoiceParams,
) : AudioMediaFormatOptions

@Serializable
internal data class VoiceParams(
    val name: String,
    val engine: String,
    val languageCode: String,
)
