package com.speechify.client.api.content.view.book.parser

import com.speechify.client.api.content.view.book.BookPageDelegate
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.api.telemetry.addMeasurement
import com.speechify.client.api.telemetry.toDiagnosticEvent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.ml.ParsedPageContentOrRawTextItems

internal class MLBookPageParser(
    private val bookPageDelegate: BookPageDelegate,
    private val getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR: suspend (
        bookPageDelegate: BookPageDelegate,
        rawContentTextItemsWithOCRIfNeeded: List<BookPageTextContentItem>,
    ) -> Result<ParsedPageContentOrRawTextItems>,
    private val fallbackToHeuristics: suspend (List<BookPageTextContentItem>) -> Result<ParsedPageContent>,
) : BookPageParser {
    private val telemetryEventBuilder =
        TelemetryEventBuilder("BaseBookPageWrapper.mlPageParsing").also {
            it.addProperty("pageIndex" to bookPageDelegate.pageIndex)
        }

    override suspend fun parse(rawTextContentItems: List<BookPageTextContentItem>): Result<ParsedPageContent> {
        val result =
            telemetryEventBuilder.addMeasurement("runMLIncludingOCRAndPayloadPreparation") {
                getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR(
                    bookPageDelegate,
                    rawTextContentItems,
                )
            }
        Log.d(
            diagnosticEvent =
            telemetryEventBuilder.build().toDiagnosticEvent(
                sourceAreaId = "BaseBookPageWrapper.runMLIncludingOCRAndPayloadPreparation",
            ),
        )
        return when (result) {
            // ML parse fail, run heuristics
            is Result.Failure -> fallbackToHeuristics(rawTextContentItems)

            is Result.Success -> {
                when (val mlParserValue = result.value) {
                    // ML parse success return result
                    is ParsedPageContentOrRawTextItems.MLParsedPageContent -> {
                        mlParserValue.parsedPageContent.successfully()
                    }

                    // ML parse fail, run heuristics with content from OCR
                    is ParsedPageContentOrRawTextItems.RawTextContentDerivedFromServerOCR -> {
                        fallbackToHeuristics(mlParserValue.rawTextContentItems)
                    }
                }
            }
        }
    }
}
