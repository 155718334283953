package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.api.util.FunctionDecoratorSuspending
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlin.coroutines.CoroutineContext

/**
 * Allows to add a wrapper-style behavior to a flow's collection.
 *
 * This is especially useful when the behavior is implemented as a wrapper ([FunctionDecoratorSuspending]).
 *
 * NOTE: You must use [contextForWrapperAndUpstreamFlow] to change [kotlin.coroutines.CoroutineContext] and must not
 * use [kotlinx.coroutines.withContext] in the [invokeWrapping], because it will break the flow's collection violate the [Flow invariant](https://kotlinlang.org/docs/flow.html#a-common-pitfall-when-using-withcontext).
 *
 * NOTE: The behavior will be able to affect the collection of the flow:
 * - if the flow has completed successfully, exceptions thrown from [invokeWrapping] will be propagated to the
 *   flow's consumer (will be thrown from its [kotlinx.coroutines.flow.onCompletion]).
 * - if the flow has completed with an exception, the [invokeWrapping] will be able to observe the exception
 *   being throw from its action and potentially suppress it.
 */
internal fun <T> Flow<T>.collectWrapper(
    /**
     * Use this to set context for the [invokeWrapping] as well as the upstream flow.
     * `null` means no change.
     */
    contextForWrapperAndUpstreamFlow: CoroutineContext? = null,
    /**
     * The function that invokes the collection inside the wrapping code.
     */
    invokeWrapping: FunctionDecoratorSuspending<Unit>,
): Flow<T> =
    flow downstream@{
        invokeWrapping {
            this@collectWrapper
                .collect {
                    this@downstream.emit(it)
                }
        }
    }
        .let {
            if (contextForWrapperAndUpstreamFlow != null) {
                it.flowOn(contextForWrapperAndUpstreamFlow)
            } else {
                it
            }
        }
