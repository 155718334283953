package com.speechify.client.internal.util.extensions.coroutines

import com.speechify.client.internal.sync.AtomicInt
import com.speechify.client.internal.sync.decrement
import com.speechify.client.internal.sync.increment
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume

/**
 * A LIFO (Last In, First Out) semaphore implementation that manages a limited number of permits.
 *
 * This semaphore allows a specified number of permits to be acquired and released in a
 * controlled manner, ensuring that coroutines requesting access are queued and resumed in
 * a last-in-first-out order.
 */
internal class LifoSemaphore(maxPermits: Int) {
    private var availablePermits = AtomicInt(maxPermits)
    private val waitingQueue = ArrayDeque<Continuation<Unit>>()
    private val mutex = Mutex()

    private suspend fun acquire() {
        mutex.withLock {
            if (availablePermits.get() > 0) {
                availablePermits.decrement()
            } else {
                // Suspend the coroutine and add to the waiting queue
                mutex.unlock()
                suspendCancellableCoroutine { continuation ->
                    waitingQueue.addLast(continuation)
                    continuation.invokeOnCancellation {
                        waitingQueue.remove(continuation)
                    }
                }
                mutex.lock()
            }
        }
    }

    private suspend fun release() {
        mutex.withLock {
            if (waitingQueue.isNotEmpty()) {
                // Resume the last waiting coroutine (LIFO)
                val continuation = waitingQueue.removeLast()
                continuation.resume(Unit)
            } else {
                availablePermits.increment()
            }
        }
    }

    suspend inline fun <T> withPermit(action: () -> T): T {
        acquire()
        return try {
            action()
        } finally {
            release()
        }
    }
}
