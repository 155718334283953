package com.speechify.client.`internal`.sqldelight.multiplatformsdk

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.speechify.client.`internal`.sqldelight.Database
import com.speechify.client.`internal`.sqldelight.DownloadedAudioForItem
import com.speechify.client.`internal`.sqldelight.LocalListeningProgress
import com.speechify.client.`internal`.sqldelight.LocalListeningProgressQueries
import com.speechify.client.`internal`.sqldelight.PendingImport
import com.speechify.client.`internal`.sqldelight.PendingImportQueries
import com.speechify.client.`internal`.sqldelight.ScannedPage
import com.speechify.client.`internal`.sqldelight.ScannedPageQueries
import com.speechify.client.`internal`.sqldelight.SentenceIndex
import com.speechify.client.`internal`.sqldelight.SynthesisResult
import com.speechify.client.`internal`.sqldelight.VoiceCacheQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass

internal val KClass<Database>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = DatabaseImpl.Schema

internal fun KClass<Database>.newInstance(
  driver: SqlDriver,
  downloadedAudioForItemAdapter: DownloadedAudioForItem.Adapter,
  localListeningProgressAdapter: LocalListeningProgress.Adapter,
  pendingImportAdapter: PendingImport.Adapter,
  scannedPageAdapter: ScannedPage.Adapter,
  sentenceIndexAdapter: SentenceIndex.Adapter,
  synthesisResultAdapter: SynthesisResult.Adapter,
): Database = DatabaseImpl(driver, downloadedAudioForItemAdapter, localListeningProgressAdapter,
    pendingImportAdapter, scannedPageAdapter, sentenceIndexAdapter, synthesisResultAdapter)

private class DatabaseImpl(
  driver: SqlDriver,
  downloadedAudioForItemAdapter: DownloadedAudioForItem.Adapter,
  localListeningProgressAdapter: LocalListeningProgress.Adapter,
  pendingImportAdapter: PendingImport.Adapter,
  scannedPageAdapter: ScannedPage.Adapter,
  sentenceIndexAdapter: SentenceIndex.Adapter,
  synthesisResultAdapter: SynthesisResult.Adapter,
) : SuspendingTransacterImpl(driver), Database {
  override val localListeningProgressQueries: LocalListeningProgressQueries =
      LocalListeningProgressQueries(driver, localListeningProgressAdapter)

  override val pendingImportQueries: PendingImportQueries = PendingImportQueries(driver,
      pendingImportAdapter, localListeningProgressAdapter)

  override val scannedPageQueries: ScannedPageQueries = ScannedPageQueries(driver,
      scannedPageAdapter)

  override val voiceCacheQueries: VoiceCacheQueries = VoiceCacheQueries(driver,
      synthesisResultAdapter, sentenceIndexAdapter, downloadedAudioForItemAdapter)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 8

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS pendingImport (
          |  speechifyUri TEXT PRIMARY KEY NOT NULL,
          |  primaryFileBlobStorageKey TEXT,
          |  scannedPages TEXT,
          |  sourceURL TEXT,
          |  importOptions TEXT,
          |  htmlContentLoadOptions TEXT,
          |  lastErrorStackTrace TEXT,
          |  wasLastErrorConnectionError INTEGER,
          |  importType TEXT NOT NULL,
          |  attemptsPerformedCount INTEGER NOT NULL DEFAULT 0,
          |  owner TEXT NOT NULL,
          |  lastUpdatedAt REAL NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, "ALTER TABLE pendingImport ADD COLUMN listeningProgress TEXT", 0).await()
      driver.execute(null, "ALTER TABLE pendingImport ADD COLUMN mimeType TEXT", 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS localListeningProgress (
          |  speechifyUri TEXT PRIMARY KEY NOT NULL,
          |  listeningProgress TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS sentenceIndex (
          |  documentUri TEXT NOT NULL,
          |  voiceId TEXT NOT NULL,
          |  sentenceText TEXT NOT NULL,
          |  synthesisResultId INTEGER NOT NULL,
          |  utteranceSentencesIndexOfThisSentence INTEGER NOT NULL,
          |  utteranceSentencesTotalCount INTEGER NOT NULL,
          |  PRIMARY KEY (documentUri, voiceId, sentenceText, synthesisResultId)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null,
          "CREATE INDEX IF NOT EXISTS sentenceIndex_voiceId_sentence ON sentenceIndex (voiceId, sentenceText)",
          0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS synthesisResult (
          |  synthesisResultId INTEGER PRIMARY KEY AUTOINCREMENT,
          |  synthesisResultUUID TEXT NOT NULL,
          |  voiceId TEXT NOT NULL,
          |  audioData BLOB NOT NULL,
          |  synthesisMetadata TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS downloadedAudioForItem(
          |  documentUri TEXT NOT NULL,
          |  voiceId TEXT NOT NULL,
          |  downloadOptions TEXT NOT NULL,
          |  sdkVersionAtCreation TEXT NOT NULL,
          |  hasGapsInAudio INTEGER NOT NULL,
          |  downloadProgress REAL NOT NULL,
          |  startProgressFraction REAL NOT NULL,
          |  startCursor TEXT NOT NULL,
          |  endProgressFraction REAL NOT NULL,
          |  endCursor TEXT NOT NULL,
          |  PRIMARY KEY (documentUri, voiceId)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null,
          "ALTER TABLE localListeningProgress ADD COLUMN lastUpdatedTime INTEGER NOT NULL DEFAULT 0",
          0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS scannedPage (
          |  speechifyUri TEXT NOT NULL,
          |  blobStorageKey TEXT NOT NULL,
          |  ocrResult TEXT,
          |  pageIndex INTEGER NOT NULL DEFAULT 0,
          |  PRIMARY KEY (speechifyUri, pageIndex)
          |)
          """.trimMargin(), 0).await()
    }

    private fun migrateInternal(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      if (oldVersion <= 1 && newVersion > 1) {
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS pendingImport (
            |  speechifyUri TEXT PRIMARY KEY NOT NULL,
            |  primaryFileBlobStorageKey TEXT,
            |  scannedPages TEXT,
            |  sourceURL TEXT,
            |  importOptions TEXT,
            |  htmlContentLoadOptions TEXT,
            |  lastErrorStackTrace TEXT,
            |  wasLastErrorConnectionError INTEGER,
            |  importType TEXT NOT NULL,
            |  attemptsPerformedCount INTEGER NOT NULL DEFAULT 0,
            |  owner TEXT NOT NULL,
            |  lastUpdatedAt REAL NOT NULL
            |)
            """.trimMargin(), 0).await()
      }
      if (oldVersion <= 2 && newVersion > 2) {
        driver.execute(null, "ALTER TABLE pendingImport ADD COLUMN listeningProgress TEXT",
            0).await()
      }
      if (oldVersion <= 3 && newVersion > 3) {
        driver.execute(null, "ALTER TABLE pendingImport ADD COLUMN mimeType TEXT", 0).await()
      }
      if (oldVersion <= 4 && newVersion > 4) {
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS localListeningProgress (
            |  speechifyUri TEXT PRIMARY KEY NOT NULL,
            |  listeningProgress TEXT
            |)
            """.trimMargin(), 0).await()
      }
      if (oldVersion <= 5 && newVersion > 5) {
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS sentenceIndex (
            |  documentUri TEXT NOT NULL,
            |  voiceId TEXT NOT NULL,
            |  sentenceText TEXT NOT NULL,
            |  synthesisResultId INTEGER NOT NULL,
            |  utteranceSentencesIndexOfThisSentence INTEGER NOT NULL,
            |  utteranceSentencesTotalCount INTEGER NOT NULL,
            |  PRIMARY KEY (documentUri, voiceId, sentenceText, synthesisResultId)
            |)
            """.trimMargin(), 0).await()
        driver.execute(null,
            "CREATE INDEX IF NOT EXISTS sentenceIndex_voiceId_sentence ON sentenceIndex (voiceId, sentenceText)",
            0).await()
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS synthesisResult (
            |  synthesisResultId INTEGER PRIMARY KEY AUTOINCREMENT,
            |  synthesisResultUUID TEXT NOT NULL,
            |  voiceId TEXT NOT NULL,
            |  audioData BLOB NOT NULL,
            |  synthesisMetadata TEXT NOT NULL
            |)
            """.trimMargin(), 0).await()
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS downloadedAudioForItem(
            |  documentUri TEXT NOT NULL,
            |  voiceId TEXT NOT NULL,
            |  downloadOptions TEXT NOT NULL,
            |  sdkVersionAtCreation TEXT NOT NULL,
            |  hasGapsInAudio INTEGER NOT NULL,
            |  downloadProgress REAL NOT NULL,
            |  startProgressFraction REAL NOT NULL,
            |  startCursor TEXT NOT NULL,
            |  endProgressFraction REAL NOT NULL,
            |  endCursor TEXT NOT NULL,
            |  PRIMARY KEY (documentUri, voiceId)
            |)
            """.trimMargin(), 0).await()
      }
      if (oldVersion <= 6 && newVersion > 6) {
        driver.execute(null,
            "ALTER TABLE localListeningProgress ADD COLUMN lastUpdatedTime INTEGER NOT NULL DEFAULT 0",
            0).await()
      }
      if (oldVersion <= 7 && newVersion > 7) {
        driver.execute(null, """
            |CREATE TABLE IF NOT EXISTS scannedPage (
            |  speechifyUri TEXT NOT NULL,
            |  blobStorageKey TEXT NOT NULL,
            |  ocrResult TEXT,
            |  pageIndex INTEGER NOT NULL DEFAULT 0,
            |  PRIMARY KEY (speechifyUri, pageIndex)
            |)
            """.trimMargin(), 0).await()
      }
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      var lastVersion = oldVersion

      callbacks.filter { it.afterVersion in oldVersion until newVersion }
      .sortedBy { it.afterVersion }
      .forEach { callback ->
        migrateInternal(driver, oldVersion = lastVersion, newVersion = callback.afterVersion +
          1).await()
        callback.block(driver)
        lastVersion = callback.afterVersion + 1
      }

      if (lastVersion < newVersion) {
        migrateInternal(driver, lastVersion, newVersion).await()
      }
    }
  }
}
