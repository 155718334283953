package com.speechify.client.reader.classic

import com.speechify.client.internal.util.extensions.collections.flows.onEachInstance
import com.speechify.client.internal.util.extensions.intentSyntax.nullIf
import com.speechify.client.reader.core.CommandDispatch
import com.speechify.client.reader.core.Helper
import com.speechify.client.reader.core.ResolvedNavigationIntent
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

internal class ClassicNavigationHelper internal constructor(
    scope: CoroutineScope,
    intentsFlow: Flow<ResolvedNavigationIntent>,
) : Helper<ClassicNavigationState>(scope) {
    private val lastFineIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    private val lastCoarseIntentConsumed = MutableStateFlow<ResolvedNavigationIntent?>(null)
    override val stateFlow: StateFlow<ClassicNavigationState> =
        combine(
            intentsFlow,
            lastFineIntentConsumed,
            lastCoarseIntentConsumed,
        ) { intent, lastFineConsumed, lastCoarseConsumed ->
            ClassicNavigationState(
                coarseIntent = intent.nullIf { this == lastCoarseConsumed },
                fineIntent = intent.nullIf { this == lastFineConsumed },
            )
        }.stateInHelper(
            initialValue = ClassicNavigationState(
                coarseIntent = null,
                fineIntent = null,
            ),
        )

    override val initialState = stateFlow.value

    val fineIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.fineIntent }
    val coarseIntentsFlow: Flow<ResolvedNavigationIntent?> = stateFlow.map { it.coarseIntent }

    init {
        commands
            .onEachInstance<ClassicNavigationCommand.ConsumeCoarseIntent> {
                lastCoarseIntentConsumed.value = it.intent.resolvedIntent
            }
            .onEachInstance<ClassicNavigationCommand.ConsumeFineIntent> {
                lastFineIntentConsumed.value = it.intent.resolvedIntent
            }
            .launchInHelper()
    }
}

internal sealed class ClassicNavigationCommand {
    data class ConsumeCoarseIntent(val intent: CoarseClassicNavigationIntent) :
        ClassicNavigationCommand()

    data class ConsumeFineIntent(val intent: FineClassicNavigationIntent) : ClassicNavigationCommand()
}

internal data class ClassicNavigationState(
    val fineIntent: ResolvedNavigationIntent?,
    val coarseIntent: ResolvedNavigationIntent?,
)

@JsExport
data class FineClassicNavigationIntent internal constructor(
    val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val charIndex: Int,
) {
    val intent = resolvedIntent.intent

    fun consume() {
        dispatch(ClassicNavigationCommand.ConsumeFineIntent(this))
    }
}

@JsExport
data class CoarseClassicNavigationIntent internal constructor(
    internal val dispatch: CommandDispatch,
    internal val resolvedIntent: ResolvedNavigationIntent,
    val blockKey: String,
    val targetBlockPosition: TargetBlockPosition?,
) {
    val intent = resolvedIntent.intent

    fun consume() {
        dispatch(ClassicNavigationCommand.ConsumeCoarseIntent(this))
    }

    sealed class TargetBlockPosition {
        object BeforeReadingLocation : TargetBlockPosition()
        object AfterReadingLocation : TargetBlockPosition()
    }
}
