package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.coGetTextContent
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Point
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.images.projectOntoWithDistance
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.services.ml.models.BoxCoordinates
import com.speechify.client.reader.core.Selection
import com.speechify.client.reader.core.SelectionGranularity
import com.speechify.client.reader.core.SelectionHandle
import com.speechify.client.reader.core.SerialLocation
import kotlin.math.abs
import kotlin.math.round

/**
 * This will identify the position of the overlapped [content] in the complete selection [items] that is highlighted
 * and returns the start or end handle if the current [content] is at the boundary of overall selection.
 *
 */
internal fun findBoundaryHandles(
    items: Array<BookPageTextContentItem>,
    content: Content,
): Pair<SelectionHandle?, SelectionHandle?>? {
    val isContentStartOverlapWithItems = items.isNotEmpty() && items[0].text.start.isBeforeOrAt(content.start)
    val isContentEndOverlapWithItems = items.isNotEmpty() && items[items.size - 1].text.end.isAfterOrAt(content.end)
    return if (content is Selection) {
        when {
            isContentStartOverlapWithItems && isContentEndOverlapWithItems -> {
                Pair(
                    content.startHandle,
                    content.endHandle,
                )
            }

            isContentStartOverlapWithItems -> {
                Pair(content.startHandle, null)
            }

            isContentEndOverlapWithItems -> {
                Pair(null, content.endHandle)
            }

            else -> null
        }
    } else {
        null
    }
}

internal fun computePageRegion(
    items: Array<BookPageTextContentItem>,
    cursor: ContentCursor,
): FixedLayoutPageRegion? {
    return items.firstOrNull { it.text.end.isAfter(cursor) }?.let {
        FixedLayoutPageRegion.fromPageTextContent(
            content = FixedLayoutPageContent(
                text = it.text.text,
                fontFamily = it.fontFamily,
                normalizedBoundingBox = it.normalizedBox,
            ),
            startIndex = it.text.getFirstIndexOfCursor(cursor),
            endIndexExclusive = it.text.getLastIndexOfCursor(cursor) + 1,
        )
    }
}

// WARNING: NONE OF THIS CURRENTLY RESPECTS THE TRANSFORMS THAT MIGHT BE APPLIED TO BOXES
internal suspend fun resolvePagePoint(
    bookPage: BookPage,
    normalizedLeft: Double,
    normalizedTop: Double,
    activationTolerance: Double,
): Pair<SerialLocation, Boolean> {
    val items = bookPage.coGetTextContent().orThrow()
    val target = Point(normalizedLeft, normalizedTop)
    if (items.isEmpty()) {
        // If no text item is found on the page, move to page.start
        return SerialLocation(bookPage.start) to false
    }

    fun project(point: Point, normalizedBox: BoundingBox): Pair<Point, Double> {
        val lineSegment = Point(normalizedBox.left, normalizedBox.centerY) to Point(
            normalizedBox.right,
            normalizedBox.centerY,
        )
        return point.projectOntoWithDistance(lineSegment)
    }

    fun getBoxScoreWithPadding(point: Point, box: BoundingBox, activationTolerance: Double): Double? {
        /**
         * checks if the target [point] lies in the padded box.Padding is based on
         * the activationTolerance, as the percentage of line height(bounding box height).
         * Say activationTolerance is P and bounding box height is H ,
         * we are considering the target in tolerance limit
         * if it falls with in the bounding box with added padding of P*H at all four sides
         */
        val padding = box.height * activationTolerance

        // Calculate distances to edges (negative means outside the padded region)
        val leftDistance = point.x - (box.left - padding)
        val rightDistance = (box.right + padding) - point.x
        val topDistance = point.y - (box.top - padding)
        val bottomDistance = (box.bottom + padding) - point.y

        // If point is outside padded region, return null
        if (leftDistance < 0 || rightDistance < 0 || topDistance < 0 || bottomDistance < 0) {
            return null
        }

        // Return minimum distance to any edge as the score
        return minOf(leftDistance, rightDistance, topDistance, bottomDistance)
    }

    // Get scores for all boxes (null means point is outside padded region)
    val boxScores = items.mapNotNull { item ->
        getBoxScoreWithPadding(target, item.normalizedBox, activationTolerance)?.let {
            item to it
        }
    }

    val (closestItem, projection, targetInToleranceLimit) = when {
        boxScores.isEmpty() -> {
            // If no box contains the point, fall back to the original projection method
            items.map { item ->
                val projection = project(target, item.normalizedBox)
                Triple(item, projection, false)
            }.minBy { it.second.second }
        }

        else -> {
            // Among containing boxes, find the one where the point is most inside
            val item = boxScores.maxBy { it.second }.first
            Triple(item, project(target, item.normalizedBox), true)
        }
    }

    val itemProjectionBasisStart = Point(closestItem.normalizedBox.left, closestItem.normalizedBox.centerY)
    val itemProjectionBasisEnd = Point(closestItem.normalizedBox.right, closestItem.normalizedBox.centerY)
    val (projectionPoint, _) = projection
    val projectionLinearProgress =
        projectionPoint.distanceTo(itemProjectionBasisStart) / itemProjectionBasisEnd.distanceTo(
            itemProjectionBasisStart,
        )
    val estimatedLinearProgressCharIndex = round(closestItem.text.text.lastIndex * projectionLinearProgress).toInt()
    val box =
        BoxCoordinates(
            left = itemProjectionBasisStart.x,
            right = target.x,
            top = closestItem.normalizedBox.top,
            bottom = closestItem.normalizedBox.bottom,
        ).toBoundingBox()
    val index =
        bookPage.getTextInBounds(listOf(box)).text.takeIf { it.isNotEmpty() }?.length
            ?: estimatedLinearProgressCharIndex
    return SerialLocation(closestItem.text.getFirstCursorAtIndex(index)) to targetInToleranceLimit
}

/**
 * resolve the page point to a [SerialLocation], Also, correct the point to aling it
 * at the edge of a character.
 *
 * For Example:
 * If the actual point is any where over a character it should be
 * resolved to represent the [SerialLocation] containing [ContentCursor] for that character
 * and the refined point should be:
 * 1. point at the start of character if the resoled cursor/location is representing the start/left handle in selection,
 * 2. point at the end of character if the resoled cursor/location is representing th end/right handle in selection
 *
 * **When [granularity] is set to WORD:**
 * - The resolved [SerialLocation] will represent the character at the start (left) or end (right)
 *   of the containing word, depending on [isRepresentingLeftHandle]. For web this might not be true.
 * - The refined point will represent the start (left) or end (right) of the containing word,
 *   depending on [isRepresentingLeftHandle].
 */
internal suspend fun resolvePagePointWithRefinedCoordinates(
    bookPage: BookPage,
    normalizedLeft: Double,
    normalizedTop: Double,
    isRepresentingLeftHandle: Boolean = false,
    viewport: Viewport,
    granularity: SelectionGranularity,
): Pair<SerialLocation, CharBounds?> {
    val items = bookPage.coGetTextContent().orThrow()
    val target = Point(normalizedLeft, normalizedTop)

    if (items.isEmpty()) {
        return SerialLocation(bookPage.start) to null
    }

    fun project(point: Point, normalizedBox: BoundingBox): Pair<Point, Double> {
        val lineSegment = Point(normalizedBox.left, normalizedBox.centerY) to Point(
            normalizedBox.right,
            normalizedBox.centerY,
        )
        return point.projectOntoWithDistance(lineSegment)
    }

    val (closestItem, projection) = items.map { it to project(target, it.normalizedBox) }
        .minBy { it.second.second }
    val itemProjectionBasisStart = Point(closestItem.normalizedBox.left, closestItem.normalizedBox.centerY)
    val itemProjectionBasisEnd = Point(closestItem.normalizedBox.right, closestItem.normalizedBox.centerY)
    val (projectionPoint, _) = projection
    val projectionLinearProgress = projectionPoint.distanceTo(itemProjectionBasisStart) /
        itemProjectionBasisEnd.distanceTo(itemProjectionBasisStart)

    val textInBounds = bookPage.getTextInBounds(listOf(closestItem.normalizedBox))

    if (!textInBounds.characterBoxes.isNullOrEmpty()) {
        return when (granularity) {
            SelectionGranularity.CHARACTER -> {
                val unNormalizedTarget = Point(normalizedLeft * viewport.width, normalizedTop * viewport.height)
                // if it's start of selection, pick the character whose start index is closer to target
                // otherwise pick the character whose right end is closer to target
                val newIndex = if (isRepresentingLeftHandle) {
                    textInBounds.characterBoxes.withIndex().minBy { box ->
                        abs(box.value.left - unNormalizedTarget.x)
                    }.index
                } else {
                    textInBounds.characterBoxes.withIndex().minBy { box ->
                        abs(box.value.right - unNormalizedTarget.x)
                    }.index
                }

                val cin = closestItem.text.getFirstCursorAtIndex(newIndex)
                val lastCharacterBoundingBox = textInBounds.characterBoxes.getOrNull(newIndex)?.let { box ->
                    CharBounds(Point(box.left, box.top), Point(box.right, box.top))
                }
                SerialLocation(cin) to lastCharacterBoundingBox
            }

            SelectionGranularity.WORD -> {
                val newIndex = if (isRepresentingLeftHandle) {
                    0
                } else {
                    textInBounds.characterBoxes.size - 1
                }

                val cin = closestItem.text.getFirstCursorAtIndex(newIndex)
                val characterBoundingBox = textInBounds.characterBoxes.getOrNull(newIndex)?.let { box ->
                    CharBounds(Point(box.left, box.top), Point(box.right, box.top))
                }
                SerialLocation(cin) to characterBoundingBox
            }
        }
    }

    // Fall back if character boxes aren't available

    // check if target point is in filler space
    if (target.x < itemProjectionBasisStart.x) {
        val cin = closestItem.text.getFirstCursorAtIndex(0)
        return SerialLocation(cin) to null
    } else if (target.x > itemProjectionBasisEnd.x) {
        val nextItem = items.getOrNull(items.indexOf(closestItem) + 1)
        if (nextItem != null) {
            val cin = nextItem.text.getFirstCursorAtIndex(0)
            return SerialLocation(cin) to null
        } else {
            val cin = closestItem.text.getFirstCursorAtIndex(closestItem.text.length - 1)
            return SerialLocation(cin) to null
        }
    }

    val estimatedLinearProgressCharIndex = round(closestItem.text.text.lastIndex * projectionLinearProgress).toInt()
    val box = BoxCoordinates(
        left = itemProjectionBasisStart.x,
        right = target.x,
        top = closestItem.normalizedBox.top,
        bottom = closestItem.normalizedBox.bottom,
    ).toBoundingBox()

    // text from starting of [closestItem]'s start to target point
    val partialTexInBounds = bookPage.getTextInBounds(listOf(box))
    val text = partialTexInBounds.text
    val indexCorrection = if (isRepresentingLeftHandle) 0 else 1
    val index = text.takeIf { it.isNotEmpty() }?.length?.minus(indexCorrection)
        ?: estimatedLinearProgressCharIndex
    val cin = closestItem.text.getFirstCursorAtIndex(index)

    return SerialLocation(cin) to null
}
