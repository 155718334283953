package com.speechify.client.api.util.io

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.fromBlock
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.util.collections.arrays.toBlob
import com.speechify.client.internal.util.io.blob.extensions.toByteArray
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array
import org.w3c.files.Blob

/**
 * An implementation of [BinaryContentReadableRandomly] backed by a [Blob].
 * Use `createFromInt8Array` to create one from an [Int8Array].
 *
 * Notably [Blob] seems to be the best, better than [js.buffer.ArrayBuffer], and all that is needed, as per [this StackOverflow](https://stackoverflow.com/questions/11821096/what-is-the-difference-between-an-arraybuffer-and-a-blob)
 * - "A Blob can be on disk, in cache memory, and other places not readily available"
 * - "Blob can be generated from an ArrayBuffer" via `new Blob([new Uint8Array(arrayBuffer)])`
 */
@JsExport
actual open class BinaryContentReadableRandomly(
    @Suppress("MemberVisibilityCanBePrivate")
    val blob: Blob,
) :
    BinaryContentReadableRandomlyWithMultiplatformAndNativeAPI() {

    @JsName("createFromInt8Array")
    constructor(
        int8Array: Int8Array,
        contentType: String?,
    ) : this(
        blob = int8Array.toBlob(contentType = contentType),
    )

    @JsName("createFromArrayBuffer")
    constructor(
        arrayBuffer: ArrayBuffer,
        contentType: String?,
    ) : this(
        blob = arrayBuffer.toBlob(contentType = contentType),
    )

    override fun getSizeInBytes(callback: Callback<Int>) = callback.fromBlock {
        blob.size
            .toInt()
            .successfully()
    }

    override fun getBytes(
        startIndex: Int,
        endIndex: Int,
        callback: Callback<ByteArray>,
    ) = callback.fromCo {
        blob
            .slice(startIndex, endIndex)
            .toByteArray()
            .successfully()
    }
}

@JsExport
fun binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(blob: Blob) =
    BinaryContentWithMimeTypeFromNative(MimeType(fullValue = blob.type), BinaryContentReadableRandomly(blob))
