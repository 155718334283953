package com.speechify.client.api.content

internal open class JoinedTextWithMap<
    /* The generics are here especially to be able to abstract away from `ContentText`, and express requirements
    for simpler abstractions and, thanks to the `out` to be able to convert a richer value to the simpler one.
     */
    out JoinedText : ValueWithStringRepresentation,
    out ConstituentPart : ValueWithStringRepresentation,
    >(
    val joinedText: JoinedText,
    val mapOfConstituentPartsToJoinedText: List<InfoOnRangeInWholeText<ConstituentPart>>,
) {
    val constituentParts
        get() = mapOfConstituentPartsToJoinedText.map { it.constituentPart }
}
