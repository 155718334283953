package com.speechify.client.internal.sync

internal expect class AtomicBool constructor(
    initialValue: Boolean,
) {
    fun get(): Boolean
    fun set(value: Boolean)
    fun toggle()
    fun compareAndSet(expect: Boolean, set: Boolean): Boolean
}

internal fun AtomicBool.swap(newValue: Boolean): Boolean {
    return if (compareAndSet(expect = !newValue, set = newValue)) {
        !newValue
    } else {
        newValue
    }
}

internal operator fun AtomicBool.not(): Boolean = !get()

internal fun AtomicBool.setEnsuringWasOpposite(
    newValue: Boolean,
    errorMessage: () -> Any,
) =
    compareAndSet(
        expect = !newValue,
        set = newValue,
    )
        .also { wasOpposite ->
            check(wasOpposite, lazyMessage = errorMessage)
        }
