package com.speechify.client.api.adapters

import com.speechify.client.internal.webview.NativeWebView
import com.speechify.client.internal.webview.WebViewListener
import kotlin.js.JsExport

@JsExport
abstract class WebViewAdapter {

    @Suppress("NON_EXPORTABLE_TYPE")
    abstract fun createWebView(webViewListener: WebViewListener): NativeWebView
}
