package com.speechify.client.internal.util.timeout

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.withTimeoutOrNull
import kotlin.time.Duration

/**
 * Use this when the failure is not expected by user (so developers should be notified and likely user as well).
 *
 * Trows a [TimeoutWithoutCancellationException] on timeout, which doesn't inherit from [kotlinx.coroutines.CancellationException].
 * in contrast to the behavior of the plain [kotlinx.coroutines.withTimeout], which throws a [kotlinx.coroutines.CancellationException]-inheriting
 * [kotlinx.coroutines.TimeoutCancellationException], and consequently is interpreted as a 'user cancelled', so is ignored by logging or user notification.
 *
 * NOTE: The code in the [block] must be cancellable, otherwise this will have no effect - the function will continue
 * waiting for the outcome (just like with [kotlinx.coroutines.withTimeout] and [kotlinx.coroutines.withTimeoutOrNull]).
 */
internal suspend inline fun <T> withTimeoutThrowingNonCancellationException(
    timeout: Duration,
    actionName: String,
    consequencesMessage: String? = null,
    noinline block: suspend CoroutineScope.() -> T,
): T = withTimeoutOrNull(timeout, block)
    ?: throw RuntimeException(
        "$actionName failed to return within ${timeout.inWholeMilliseconds}ms" +
            (consequencesMessage?.let { ". $it" } ?: ""),
    )

/**
 * An alternative to [TimeoutWithoutCancellationException] on timeout, which doesn't inherit from [kotlinx.coroutines.CancellationException].
 * [kotlinx.coroutines.TimeoutCancellationException] is  [kotlinx.coroutines.CancellationException]-inheriting, and is
 * consequently interpreted as a 'user cancelled', so is ignored by logging or user notification.
 */
internal class TimeoutWithoutCancellationException(message: String) : RuntimeException(message)

internal suspend fun <T> Deferred<T>.awaitWithTimeoutThrowingNonCancellationException(
    timeout: Duration,
    actionName: String,
    consequencesMessage: String? = null,
) =
    withTimeoutThrowingNonCancellationException(
        timeout = timeout,
        actionName = actionName,
        consequencesMessage = consequencesMessage,
    ) {
        await()
    }
