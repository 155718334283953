package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisVoiceBySpecProvider
import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerAdapter
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully

internal class DefaultVoiceFactory(
    private val mediaSynthesisService: MediaSynthesisService,
    private val localSpeechSynthesisPlayerFactory: LocalSpeechSynthesisAdapter,
    private val localSpeechSynthesisVoiceBySpecProvider: LocalSpeechSynthesisVoiceBySpecProvider,
    private val mediaPlayerFactory: LocalMediaPlayerAdapter,
) : VoiceFactory {

    override fun createVoice(
        spec: VoiceSpecOfAvailableVoice,
        speechSynthesisConfig: SpeechSynthesisConfig,
        callback: Callback<Voice?>,
    ) {
        callback.fromCo {
            when (spec) {
                is VoiceSpec.LocalSynthesisBackedVoice -> {
                    val specWithDetails = localSpeechSynthesisVoiceBySpecProvider.getSpecWithDetailsOrNull(
                        spec = spec,
                    )
                        ?: return@fromCo null.successfully()

                    LocalVoice(
                        voiceSpec = specWithDetails,
                        localSpeechSynthesisPlayerFactory = localSpeechSynthesisPlayerFactory,
                    )
                }
                is VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer -> {
                    MediaVoice(
                        mediaPlayerFactory = mediaPlayerFactory,
                        speechSynthesisConfig = speechSynthesisConfig,
                        multiSpecMediaSynthesisService = mediaSynthesisService,
                        voiceSpec = spec,
                    )
                }
                is VoiceSpec.Static ->
                    throw IllegalArgumentException(
                        "Static voices should not need a Voice for synthesizing. They should provide Utterances" +
                            "using the `UtteranceFlowProvider`, for example" +
                            " `UtteranceFlowProviderFromSingleStaticUtterance`",
                    )
            }.successfully()
        }
    }
}
