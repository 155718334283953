package com.speechify.client.api.adapters.xml

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.debugCallAndResultWithUuid
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.orThrow
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * NOTES:
 * This is a copy-paste of [com.speechify.client.api.adapters.html.HTMLParser] — though with a differentiation that this is
 * being used to parse XML files and not HTML files. Currently, this is only being used to parse the XML tree in an EPUB's
 * content file, to grab the content structure of the EPUB for correct ordering.
 * This currently serves the functionality well, hence the straight up copy-paste.
 * In the future should we require a more robust XML parser, changes can be made here.
 *
 * Important Note: The adapter may reject valid XML based on the MIME type alone. To prevent this,
 *  * we mask the MIME type to ensure proper processing of valid XML input files.
 *  * For example, an .ncx file in EPUB 2 has the MIME type 'application/x-dtbncx+xml' but is still a valid XML file.
 *  * As Mention above the contract of this parser is designed to parse xml based on file content.
 */
@JsExport
abstract class XMLParser {
    protected abstract fun parseAsDOM(
        file: File,
        callback: Callback<XMLDOMElement>,
    )

    internal open suspend fun coParseAsDOM(file: File): XMLDOMElement {
        return suspendCoroutine {
            this.parseAsDOM(file, it::resume)
        }.orThrow()
    }
}

/**
 * Base class for the abstraction over the HTML DOM tree to decouple from the actual objects returned by parsers, and to only expose what the SDK needs.
 *
 * An instance of this represents a single concrete [DOM Node](https://developer.mozilla.org/en-US/docs/Web/API/Node) in the DOM tree.
 *
 * NOTE: The sub-interfaces are not 'all possible DOM node types', but are limited to only the types of nodes relevant for the SDK.
 */
@JsExport
sealed interface XMLDOMNode

/**
 * Represents a single concrete [DOM Element](https://developer.mozilla.org/en-US/docs/Web/API/Element) in the DOM tree.
 */
@JsExport
abstract class XMLDOMElement : XMLDOMNode {
    /**
     * The [tag name](https://developer.mozilla.org/en-US/docs/Web/API/Element/tagName) of the element
     */
    abstract val tagName: String

    /**
     * The [childNodes](https://developer.mozilla.org/en-US/docs/Web/API/Node/childNodes) of the element, filtered
     * to only the types of node present in the SDK's [XMLDOMNode] hierarchy.
     */
    abstract val children: Array<XMLDOMNode>

    /**
     * The [attributes](https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes) of the element
     */
    abstract val attributes: Array<BoundaryPair<String, String>>
}

/**
 * Represents a single concrete [Text Node](https://developer.mozilla.org/en-US/docs/Web/API/Text) in the DOM tree.
 */
@JsExport
abstract class XMLDOMTextNode : XMLDOMNode {
    /**
     * The [textContent](https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent) of the node
     */
    abstract val text: String
}

internal fun XMLParser.traced() = if (Log.isDebugLoggingEnabled) XMLParserTraced(this) else this

internal class XMLParserTraced(private val xmlParser: XMLParser) : XMLParser() {
    override suspend fun coParseAsDOM(file: File) = debugCallAndResultWithUuid(
        "XMLParser.coParseAsDOM",
        file,
    ) {
        xmlParser.coParseAsDOM(file)
    }

    override fun parseAsDOM(file: File, callback: Callback<XMLDOMElement>) =
        throw UnsupportedOperationException(
            /** Never called, as it's protected and the entry point is [coParseAsDOM], which calls the wrapped instance */
        )
}
