package com.speechify.client.reader.fixedlayoutbook.overlay

import com.speechify.client.api.content.Content
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.util.images.Point
import com.speechify.client.reader.fixedlayoutbook.FixedLayoutPageRegion

// Decide the logic to compute the overlay for selection/highlight
internal interface FixedLayoutOverlayStrategy {
    suspend fun computeOverlay(computeOverlayContext: ComputeOverlayContext): List<FixedLayoutPageRegion>
}

/**
 * Data to be used by different overlay Strategies for overlay contexts
 *
 * @param content [Content] for which we want to find the overlay
 * @param items array of [BookPageTextContentItem] which should contain overlapping items with the [content]
 * @param bookPage  needed to perform some operations on the page, like search
 * @param startPoint normalized starting coordinates from the viewport on UI for evaluating overlay
 * @param endPoint normalized end coordinates from the viewport on UI for evaluating overlay
 */
internal data class ComputeOverlayContext(
    val content: Content,
    val items: Array<BookPageTextContentItem>,
    val bookPage: BookPage,
    val startPoint: Point? = null,
    val endPoint: Point? = null,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ComputeOverlayContext

        if (content != other.content) return false
        if (!items.contentEquals(other.items)) return false
        if (bookPage != other.bookPage) return false
        if (startPoint != other.startPoint) return false
        if (endPoint != other.endPoint) return false

        return true
    }

    override fun hashCode(): Int {
        var result = content.hashCode()
        result = 31 * result + items.contentHashCode()
        result = 31 * result + bookPage.hashCode()
        result = 31 * result + (startPoint?.hashCode() ?: 0)
        result = 31 * result + (endPoint?.hashCode() ?: 0)
        return result
    }
}
