package com.speechify.client.bundlers.reading

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.adapters.events.reportEventsFromFlows
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.services.library.LibraryServiceDelegate
import com.speechify.client.api.telemetry.currentTelemetryEvent
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.content.SpeechifyContentBundler
import com.speechify.client.bundlers.listening.ListeningBundler
import com.speechify.client.helpers.audio.controller.root.BundlingToPlaybackLatencyTelemetryTracker
import com.speechify.client.helpers.features.ListeningProgressTracker
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.helpers.ui.controls.PlaybackControls.Companion.createPlaybackControls
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.createTopLevelCoroutineScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.flowOf

/**
 * Base class for bundlers that create [BasicReadingBundle]s
 * (for navigating all subclasses and for convenience methods aiding creation).
 */
abstract class ReadingBundlerBase :
    WithScope() {
    internal abstract val listeningBundler: ListeningBundler

    internal suspend fun createPlaybackControls(
        contentBundle: ContentBundle,
        startingCursor: ContentCursor,
        scope: CoroutineScope,
    ): Result<PlaybackControls> =
        listeningBundler.coCreateBundleForContent(contentBundle, startingCursor, scope)
            .orReturn { return it }
            .createPlaybackControls(
                startingCursor = startingCursor,
            )
}

/**
 * Base class for bundlers that create [ReadingBundle]s
 * (for navigating all subclasses and for convenience methods aiding creation).
 */
abstract class ReadingBundler :
    ReadingBundlerBase() {
    internal abstract val speechifyClient: SpeechifyClient
    internal abstract val speechifyContentBundler: SpeechifyContentBundler

    internal suspend fun createDependencies(
        contentBundle: ContentBundle,
        startingCursor: ContentCursor,
        bundleMetadata: BundleMetadata?,
        eventsTrackerAdapter: EventsTrackerAdapter,
    ): Result<ReadingBundle.Dependencies> {
        // We put the BundlingToPlaybackLatencyTelemetryTracker into the context so that it can track the time it takes
        // from bundling start to playback start.
        val telemetryEventBuilder = currentTelemetryEvent()
        telemetryEventBuilder?.addBundleMetadataProperties(bundleMetadata = bundleMetadata)

        val context = if (telemetryEventBuilder != null) {
            BundlingToPlaybackLatencyTelemetryTracker(
                bundleCreationTelemetryEventBuilder = telemetryEventBuilder,
                bundleMetadata = bundleMetadata,
            )
        } else {
            null
        }
        val scope = createTopLevelCoroutineScope(
            contextToMerge = context,
        )

        val playbackControls = createPlaybackControls(
            contentBundle,
            startingCursor,
            scope,
        )
            .orReturn { return it }

        val dependencies = object : ReadingBundle.Dependencies {
            override val playbackControls: PlaybackControls = playbackControls

            override val listeningProgressTracker = ListeningProgressTracker(
                speechifyClient.audiobookLibraryService,
                speechifyClient.libraryService.delegate,
                playbackControls,
                contentBundle.importer,
                speechifyClient.importService,
            )

            override val libraryService: LibraryServiceDelegate = speechifyClient.libraryService.delegate

            override val scope = scope
        }

        reportEventsFromFlows(
            dependencies,
            contentBundle,
            playbackControls,
            speechifyClient,
            eventsTrackerAdapter,
            flowOf(bundleMetadata),
        )

        return dependencies.successfully()
    }
}
