package com.speechify.client.api.services.scannedbook.models

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.ocr.OCRTextContent
import com.speechify.client.api.googleCloudStorageUriFileIdFromUrl
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.caching.ReadWriteThroughCachedFirebaseStorage
import com.speechify.client.internal.services.scannedbook.PlatformScannedBookService
import com.speechify.client.internal.sync.WrappingMutex
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope

internal typealias PageFetcher =
    suspend (pageId: String) -> Result<PlatformScannedBookService.FirestoreScannedBookPageModel>

internal class FirestoreScannedBook internal constructor(
    val uri: SpeechifyURI,
    private val pageFetcher: PageFetcher,
    override val numberOfPages: Int,
    private val pagesOrdering: List<String>,
    private val clientConfig: ClientConfig,
    private val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
) : WithScope(), ScannedBook {

    private val pagesCache = WrappingMutex.of(mutableMapOf<Int, Deferred<ScannedBookPage>>())

    /**
     * Asynchronously fetch a representation for the specified page (the first page has index zero)
     */
    override fun getPage(pageIndex: Int, callback: Callback<ScannedBookPage>) = callback.fromCo {
        coroutineScope {
            getDeferredPageIn(
                pageIndex = pageIndex,
                scope = this,
            ).await()
        }
            .successfully()
    }

    private suspend fun getDeferredPageIn(
        pageIndex: Int,
        scope: CoroutineScope,
    ): Deferred<ScannedBookPage> =
        pagesCache.locked { cache ->
            if (pageIndex in cache) {
                cache.getValue(pageIndex)
            } else {
                val pageId = pagesOrdering[pageIndex]
                val pageDeferred =
                    scope.async {
                        FirestoreScannedBookPage(
                            pageFetcher(pageId)
                                .orThrow(),
                            clientConfig,
                            firebaseStorageCache,
                        )
                    }
                cache[pageIndex] = pageDeferred
                pageDeferred
            }
        }
}

internal class FirestoreScannedBookPage(
    private val firestorePage: PlatformScannedBookService.FirestoreScannedBookPageModel,
    private val clientConfig: ClientConfig,
    private val firebaseStorageCache: ReadWriteThroughCachedFirebaseStorage,
) : WithScope(), ScannedBookPage {

    /**
     * Asynchronously returns the dimensions of the background image. Necessary for positioning the text correctly in a view.
     */
    override fun getViewport(): Viewport =
        firestorePage.viewport

    /**
     * Asynchronously provide the fixed-positioned text content on the page
     */
    override fun getContent(callback: Callback<Array<OCRTextContent>>) = callback.fromCo(scope) {
        firestorePage.textContent.toTypedArray()
            .successfully()
    }

    override fun getImage(
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) = callback.fromCo(scope) {
        if (firestorePage.imageUrlOrNull == null) {
            throw IllegalStateException("Scanned document without images should not be consumed in original mode.")
        }

        // Fetch from Firebase Storage cache.
        firebaseStorageCache
            .getBinaryContent(
                clientConfig.googleCloudStorageUriFileIdFromUrl(firestorePage.imageUrlOrNull),
            ).successfully()
    }
}
