package com.speechify.client.reader.core

import com.speechify.client.api.content.EstimatedCount
import com.speechify.client.api.content.coGetStats
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.reading.ReadingBundle
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
class AnalyticsHelper internal constructor(
    scope: CoroutineScope,
    private val readingBundle: ReadingBundle,
) : Helper<AnalyticsState>(scope) {

    override val stateFlow = readingBundle.content.contentIndex.contentAmountStateFlow.map {
        readingBundle.content.contentIndex.coGetStats().orThrow()
    }.distinctUntilChanged().map {
        AnalyticsState.Ready(
            estimatedWordCount = it.estimatedWordCount,
            estimatedCharCount = it.estimatedCharCount,
            numberOfPages = readingBundle.bookContent?.bookView?.getMetadata()?.numberOfPages,
        )
    }.stateInHelper(initialValue = AnalyticsState.NotReady)

    override val initialState = stateFlow.value

    fun reportUIReadyToListen() {
        readingBundle.reportUIReadyToListen()
    }
}

@JsExport
sealed class AnalyticsState {
    data class Ready internal constructor(
        val estimatedWordCount: EstimatedCount,
        val estimatedCharCount: EstimatedCount,
        val numberOfPages: Int?,
    ) : AnalyticsState()

    object NotReady : AnalyticsState()
}
