package com.speechify.client.`internal`.sqldelight

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.adapters.ocr.OCRResult
import kotlin.Any
import kotlin.Int
import kotlin.String

public class ScannedPageQueries(
  driver: SqlDriver,
  private val scannedPageAdapter: ScannedPage.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getScannedPagesBySpeechifyUri(speechifyUri: SpeechifyURI, mapper: (
    speechifyUri: SpeechifyURI,
    blobStorageKey: BlobStorageKey,
    pageIndex: Int,
  ) -> T): Query<T> = GetScannedPagesBySpeechifyUriQuery(speechifyUri) { cursor ->
    mapper(
      scannedPageAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      scannedPageAdapter.blobStorageKeyAdapter.decode(cursor.getString(1)!!),
      scannedPageAdapter.pageIndexAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public fun getScannedPagesBySpeechifyUri(speechifyUri: SpeechifyURI):
      Query<GetScannedPagesBySpeechifyUri> = getScannedPagesBySpeechifyUri(speechifyUri) {
      speechifyUri_, blobStorageKey, pageIndex ->
    GetScannedPagesBySpeechifyUri(
      speechifyUri_,
      blobStorageKey,
      pageIndex
    )
  }

  public fun <T : Any> getScannedPageBySpeechifyUriAndPageIndex(
    speechifyUri: SpeechifyURI,
    pageIndex: Int,
    mapper: (
      speechifyUri: SpeechifyURI,
      blobStorageKey: BlobStorageKey,
      ocrResult: OCRResult?,
      pageIndex: Int,
    ) -> T,
  ): Query<T> = GetScannedPageBySpeechifyUriAndPageIndexQuery(speechifyUri, pageIndex) { cursor ->
    mapper(
      scannedPageAdapter.speechifyUriAdapter.decode(cursor.getString(0)!!),
      scannedPageAdapter.blobStorageKeyAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)?.let { scannedPageAdapter.ocrResultAdapter.decode(it) },
      scannedPageAdapter.pageIndexAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public fun getScannedPageBySpeechifyUriAndPageIndex(speechifyUri: SpeechifyURI, pageIndex: Int):
      Query<ScannedPage> = getScannedPageBySpeechifyUriAndPageIndex(speechifyUri, pageIndex) {
      speechifyUri_, blobStorageKey, ocrResult, pageIndex_ ->
    ScannedPage(
      speechifyUri_,
      blobStorageKey,
      ocrResult,
      pageIndex_
    )
  }

  public fun <T : Any> getOcrResultBySpeechifyUriAndPageIndex(
    speechifyUri: SpeechifyURI,
    pageIndex: Int,
    mapper: (ocrResult: OCRResult?) -> T,
  ): Query<T> = GetOcrResultBySpeechifyUriAndPageIndexQuery(speechifyUri, pageIndex) { cursor ->
    mapper(
      cursor.getString(0)?.let { scannedPageAdapter.ocrResultAdapter.decode(it) }
    )
  }

  public fun getOcrResultBySpeechifyUriAndPageIndex(speechifyUri: SpeechifyURI, pageIndex: Int):
      Query<GetOcrResultBySpeechifyUriAndPageIndex> =
      getOcrResultBySpeechifyUriAndPageIndex(speechifyUri, pageIndex) { ocrResult ->
    GetOcrResultBySpeechifyUriAndPageIndex(
      ocrResult
    )
  }

  public suspend fun removeScannedPageBySpeechifyUri(speechifyUri: SpeechifyURI) {
    driver.execute(1_100_420_699, """DELETE FROM scannedPage WHERE scannedPage.speechifyUri = ?""",
        1) {
          bindString(0, scannedPageAdapter.speechifyUriAdapter.encode(speechifyUri))
        }.await()
    notifyQueries(1_100_420_699) { emit ->
      emit("scannedPage")
    }
  }

  public suspend fun addScannedPage(scannedPage: ScannedPage) {
    driver.execute(908_516_999, """INSERT INTO scannedPage VALUES (?, ?, ?, ?)""", 4) {
          bindString(0, scannedPageAdapter.speechifyUriAdapter.encode(scannedPage.speechifyUri))
          bindString(1, scannedPageAdapter.blobStorageKeyAdapter.encode(scannedPage.blobStorageKey))
          bindString(2, scannedPage.ocrResult?.let { scannedPageAdapter.ocrResultAdapter.encode(it)
              })
          bindLong(3, scannedPageAdapter.pageIndexAdapter.encode(scannedPage.pageIndex))
        }.await()
    notifyQueries(908_516_999) { emit ->
      emit("scannedPage")
    }
  }

  public suspend fun updateOcrResult(
    ocrResult: OCRResult?,
    speechifyUri: SpeechifyURI,
    pageIndex: Int,
  ) {
    driver.execute(1_904_155_625,
        """UPDATE scannedPage SET ocrResult = ? WHERE speechifyUri = ? AND pageIndex = ?""", 3) {
          bindString(0, ocrResult?.let { scannedPageAdapter.ocrResultAdapter.encode(it) })
          bindString(1, scannedPageAdapter.speechifyUriAdapter.encode(speechifyUri))
          bindLong(2, scannedPageAdapter.pageIndexAdapter.encode(pageIndex))
        }.await()
    notifyQueries(1_904_155_625) { emit ->
      emit("scannedPage")
    }
  }

  private inner class GetScannedPagesBySpeechifyUriQuery<out T : Any>(
    public val speechifyUri: SpeechifyURI,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("scannedPage", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("scannedPage", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(588_958_346,
        """SELECT speechifyUri, blobStorageKey, pageIndex  FROM scannedPage WHERE speechifyUri = ? ORDER BY pageIndex""",
        mapper, 1) {
      bindString(0, scannedPageAdapter.speechifyUriAdapter.encode(speechifyUri))
    }

    override fun toString(): String = "scannedPage.sq:getScannedPagesBySpeechifyUri"
  }

  private inner class GetScannedPageBySpeechifyUriAndPageIndexQuery<out T : Any>(
    public val speechifyUri: SpeechifyURI,
    public val pageIndex: Int,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("scannedPage", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("scannedPage", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_836_128_633,
        """SELECT scannedPage.* FROM scannedPage WHERE scannedPage.speechifyUri = ? AND scannedPage.pageIndex = ?""",
        mapper, 2) {
      bindString(0, scannedPageAdapter.speechifyUriAdapter.encode(speechifyUri))
      bindLong(1, scannedPageAdapter.pageIndexAdapter.encode(pageIndex))
    }

    override fun toString(): String = "scannedPage.sq:getScannedPageBySpeechifyUriAndPageIndex"
  }

  private inner class GetOcrResultBySpeechifyUriAndPageIndexQuery<out T : Any>(
    public val speechifyUri: SpeechifyURI,
    public val pageIndex: Int,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("scannedPage", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("scannedPage", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_699_744_387,
        """SELECT ocrResult FROM scannedPage WHERE speechifyUri = ? AND pageIndex = ?""", mapper, 2)
        {
      bindString(0, scannedPageAdapter.speechifyUriAdapter.encode(speechifyUri))
      bindLong(1, scannedPageAdapter.pageIndexAdapter.encode(pageIndex))
    }

    override fun toString(): String = "scannedPage.sq:getOcrResultBySpeechifyUriAndPageIndex"
  }
}
