package com.speechify.client.helpers.features

import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.coGetCursorFromProgress
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.audiobook.AudiobookLibraryService
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.LibraryServiceDelegate
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.services.library.models.UpdateLibraryItemParams
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.importing.ContentImporter
import com.speechify.client.bundlers.reading.importing.suspendUntilAnyUri
import com.speechify.client.helpers.ui.controls.PlaybackControls
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.time.ISO8601DateString
import com.speechify.client.internal.util.isBetween
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.sample
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlin.js.JsExport

/**
 * Fraction in range <0.0, 1.0> (inclusive), with 0 representing the beginning and 1 the end.
 */
typealias ProgressFraction = Double

@JsExport
@kotlinx.serialization.Serializable
data class ListeningProgress(
    val cursor: ContentCursor,
    val fraction: ProgressFraction,
    val speedInWordsPerMinute: Int,
)

@JsExport
data class SyncedListeningProgress(
    val cursor: ContentCursor,
    val fraction: ProgressFraction,
    val speedInWordsPerMinute: Int,
    val timestamp: ISO8601DateString,
)

/**
 * Returns null if valid fraction an exception (so that entry point can be determined from the stacktrace).
 * NOTE: The exception is unthrown.
 */
internal fun getProgressFractionValidationExceptionOrNull(
    progressFraction: ProgressFraction,
    areaId: String,
): InvalidProgressFractionException? =
    if (progressFraction.isBetween(0.0, 1.0, 0.0000001)) {
        null
    } else {
        InvalidProgressFractionException(
            progressFraction = progressFraction,
            areaId = areaId,
        )
    }

@JsExport
class InvalidProgressFractionException(
    progressFraction: ProgressFraction,
    areaId: String,
) : RuntimeException("progressFraction must be between 0.0 and 1.0, but was $progressFraction at `$areaId`")

/**
 * Ensures the user's last-listening place is saved for the user, so that they can continue from that place.
 *
 * Also provides [addEventListener] to listen to when the saving was performed.
 *
 * NOTE: For items-yet-unimported it will start saving once they are imported, and if the user never imports the item,
 * then no progress will be saved.
 */
@JsExport
class ListeningProgressTracker internal constructor(
    private val audiobookLibraryService: AudiobookLibraryService,
    private val libraryService: LibraryServiceDelegate,
    private val playbackControls: PlaybackControls,
    /**
     * Used by [ListeningProgressTracker] to learn when an item has been import to start progress tracking
     */
    private val contentImporter: ContentImporter,
    private val importService: ImportService,
    /**
     * A time window, in milliseconds, within which the latest progress is written.
     */
    private val progressUpdateSamplingDuration: Int = 5000,
    isManualInit: Boolean = false,
) : WithScope() {
    private val progressEvents = MutableStateFlow<Result<ListeningProgress>?>(null)

    init {
        if (!isManualInit) _init()
    }

    /**
     * Called by [ListeningProgressTracker.init] when created and exposed **only** for test convenience as cannot be
     * mocked consistently when called from an `init`. This seems to be a race condition when using `anyConstructed` and
     * verifying a mock has been called.
     */
    internal fun _init() {
        scope.launch {
            val uri = contentImporter.suspendUntilAnyUri()
            trackIn(uri, scope = this)
        }
    }

    /**
     * Called by [ListeningProgressTracker._init] when item has been imported and exposed **only** for test convenience
     */
    @OptIn(FlowPreview::class)
    internal fun trackIn(uri: SpeechifyURI, scope: CoroutineScope) {
        var lastSentenceStart: ContentCursor? = null
        var lastSpeed = playbackControls.state.wordsPerMinute
        scope.launch {
            playbackControls.stateFlow
                .onCompletion {
                    /* handle the case of when the user terminates playback abruptly before sample window closes and
                    * ensure their last listening position is written */
                    // We use NonCancellable here so that if the flow / parent scope end due to cancellation
                    // we are still guaranteed to (at least attempt) write the final progress.
                    withContext(NonCancellable) {
                        writeProgress(
                            uri,
                            ListeningProgress(
                                cursor = playbackControls.state.latestPlaybackCursor,
                                fraction = playbackControls.state.latestPlaybackProgressFraction,
                                speedInWordsPerMinute = playbackControls.state.wordsPerMinute,
                            ),
                        ).orThrow()
                    }
                }
                /* Flow.sample collects the latest event received within a given window */
                .sample(progressUpdateSamplingDuration.toLong()).collect { playbackState ->
                    val currentSentenceStart =
                        playbackState.sentenceAndWordLocation?.sentence?.start
                    if (!isSignificantProgressOrSpeedChange(
                            currentSentenceStart,
                            lastSentenceStart,
                            lastSpeed,
                            playbackState,
                        )
                    ) {
                        return@collect
                    }

                    val cursorToSave =
                        if (playbackState.latestPlaybackProgressFraction == 1.0) {
                            playbackState.latestPlaybackCursor
                        } else {
                            currentSentenceStart ?: playbackState.latestPlaybackCursor
                        }
                    val progress =
                        ListeningProgress(
                            cursor = cursorToSave,
                            fraction = playbackState.latestPlaybackProgressFraction,
                            speedInWordsPerMinute = playbackState.wordsPerMinute,
                        )

                    when (val writeProgressResult = writeProgress(uri, progress)) {
                        is Result.Success -> {
                            lastSentenceStart = currentSentenceStart
                            lastSpeed = playbackState.wordsPerMinute
                            progressEvents.emit(progress.successfully())
                        }
                        is Result.Failure -> progressEvents.emit(writeProgressResult).also {
                            Log.e(error = writeProgressResult.error, sourceAreaId = "ListeningProgressTracker")
                        }
                    }
                }
        }
    }

    private fun isSignificantProgressOrSpeedChange(
        currentSentenceStart: ContentCursor?,
        previousSentenceStart: ContentCursor?,
        previousWordsPerMinute: Int,
        state: PlaybackControls.State,
    ): Boolean {
        val isSignificantProgress = when {
            previousSentenceStart == null -> true
            state.latestPlaybackProgressFraction == 1.0 -> true
            currentSentenceStart != null && !previousSentenceStart.isEqual(currentSentenceStart) ->
                true
            else -> false
        }
        return isSignificantProgress || state.wordsPerMinute != previousWordsPerMinute
    }

    /**
     * Register listener to receive updates of the current content's listening progress
     *
     * @param listener is a function that will be called with the updated [ListeningProgress]
     */
    fun addEventListener(listener: Callback<ListeningProgress>): Destructor =
        listener.multiShotFromFlowIn(
            flow = progressEvents.filterNotNull(),
            scope = scope,
            notifyOfFlowCancel = false,
        )::destroy

    /**
     * Writes the listening progress for a library item to backend storage.
     */
    private suspend fun writeProgress(
        uri: SpeechifyURI,
        progress: ListeningProgress,
    ): Result<Unit> {
        return when (uri.type) {
            SpeechifyEntityType.LIBRARY_ITEM, SpeechifyEntityType.SCANNED_BOOK -> {
                importService.insertOrUpdateLocalListeningProgress(
                    uri,
                    progress,
                )
                try {
                    val item = libraryService.getItemFromFirestoreOrLocalFromUri(uri)
                    if (item is LibraryItem.Content) {
                        libraryService.updateLibraryItemIfIsOwner(
                            item.ownerId,
                            uri.id,
                            UpdateLibraryItemParams(listeningProgress = progress),
                        )
                    } else {
                        Result.Success(Unit)
                    }
                } catch (e: Exception) {
                    Result.Failure(SDKError.OtherException(e))
                }
            }
            SpeechifyEntityType.AUDIOBOOK_CHAPTER -> audiobookLibraryService.saveListeningProgress(
                uri.id,
                progress,
            )
            SpeechifyEntityType.FOLDER ->
                throw IllegalArgumentException("Cannot write listening progress to folders")
        }
    }
}

/**
 * Used to capture a user's saved listening progress.
 * */
internal sealed class SavedListeningProgress(open val lastUpdateTime: DateTime) {
    /**
     * The preferred form of progress, as it is the most recent and most accurate
     */
    data class CursorProgress(val cursor: ContentCursor, override val lastUpdateTime: DateTime) :
        SavedListeningProgress(lastUpdateTime)

    /**
     * This is the legacy form of progress, to handle library items that were listened
     * prior to [CursorProgress]
     */
    data class FractionProgress(val fraction: Double, override val lastUpdateTime: DateTime) :
        SavedListeningProgress(lastUpdateTime)

    /**
     * Attempts to obtain the usable cursor [ContentCursor] from a remote saved cursor, which may undergo changes
     * when content sorting is enabled.
     */
    suspend fun toCursor(contentBundle: ContentBundle): ContentCursor {
        val cursorFromSavedProgress = when (this) {
            is CursorProgress -> cursor
            is FractionProgress -> contentBundle.contentIndex.coGetCursorFromProgress(fraction).toNullable {
                Log.e(error = it, sourceAreaId = "SavedListeningProgress.toCursor")
            } ?: return contentBundle.standardView.start
        }

        return when (contentBundle) {
            is ContentBundle.BookBundle -> {
                val cursor = contentBundle.bookView.translateToUsableCursor(cursorFromSavedProgress)
                if (cursor == null) {
                    Log.e(
                        DiagnosticEvent(
                            message = "SavedListeningProgress toCursor: Unable to find a usable cursor from the given" +
                                " one. Falling back to the cursor positioned at the beginning of the document.",
                            sourceAreaId = "SavedListeningProgress.toCursor",
                        ),
                    )
                    return contentBundle.standardView.start
                }
                cursor
            }

            else -> cursorFromSavedProgress
        }
    }
}

internal fun getSavedListeningProgress(item: LibraryItem.ListenableContent): SavedListeningProgress? {
    val lastListenedAt = if (item.lastListenedAt != null) {
        DateTime.fromIsoString(item.lastListenedAt)
    } else {
        DateTime.EPOCH
    }
    when (item) {
        is LibraryItem.Content -> {
            if (item.listeningProgress != null && item.listenProgressPercent != null) {
                val listeningProgressTimestamp = DateTime.fromIsoString(item.listeningProgress.timestamp)
                return if (listeningProgressTimestamp >= lastListenedAt) {
                    SavedListeningProgress.CursorProgress(item.listeningProgress.cursor, lastListenedAt)
                } else {
                    SavedListeningProgress.FractionProgress(item.listenProgressPercent, lastListenedAt)
                }
            } else if (item.listeningProgress != null && item.listenProgressPercent == null) {
                return SavedListeningProgress.CursorProgress(item.listeningProgress.cursor, lastListenedAt)
            } else if (item.listenProgressPercent != null && item.listeningProgress == null) {
                return SavedListeningProgress.FractionProgress(item.listenProgressPercent, lastListenedAt)
            }
        }

        is LibraryItem.DeviceLocalContent -> {
            if (item.listeningProgress != null) {
                return SavedListeningProgress.CursorProgress(item.listeningProgress.cursor, lastListenedAt)
            }
        }
    }

    return null
}
