package com.speechify.client.api.services.ebook

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapterWithEncryption
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.adapters.encription.EncryptionAdapter
import com.speechify.client.api.adapters.http.ResponseBodyInterface
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.MimeTypeOfListenableContent
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentReadableRandomlyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPI
import com.speechify.client.api.util.io.BinaryContentReadableSequentiallyMultiplatformAPIFromFlow
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks
import com.speechify.client.api.util.io.coGetAllBytes
import com.speechify.client.api.util.io.coGetBytes
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.services.auth.AuthService
import kotlinx.coroutines.flow.flow

internal interface EbookService {
    suspend fun getEbookContentForLibraryItem(itemId: String):
        SpeechifyBookBinaryData
}

internal class EbookServiceImpl internal constructor(
    private val authService: AuthService,
    private val httpClient: HttpClient,
    private val blobStorageAdapter: BlobStorageAdapter,
    private val encryptionAdapter: EncryptionAdapter,
    private val baseUrl: String,
) : EbookService, EncryptedDownloadService() {
    // The maximum length of the password accepted by PSPDFKit. Inline with the value being used on the BE side
    private val PDF_PASSWORD_MAX_LENGTH = 32

    // Has the encryption keys with the coresponding version number on the BE
    private val encryptionKeys = setOf(
        "vFa780fa6" to
            "FSdHhtR2aGN0YY9Zw70L0FR2MnVU2N1ABnGyB09PQhGWtmunfem7l49vqVYie4i1\n" +
            "ShfVvtDXpTaB/gj0OkH2ndq1BPbHHvnhMFHwbp2mxVN1zh+U7Hxk6p+qg4OLdDUT\n" +
            "NlFQaAfRBI0oElyzJLW9vy2AawFHMcefs9NUNpoQRqWFsFLP7x7KpgICovn3fPWS\n" +
            "0W2+f8GmRzzgIUQlRUFWaCEBe42U3VQjlwurahzz7G18j4O/yOy3WBosELd76r0J\n" +
            "sI1NgKXUacdspvs80wPoQn4co9waOC6Vc3jNGw==",
        "vFa780fa6" to
            "4+PZ3ouDGNo2GAgCe/syGigxzS2ObFW5bdsnXDyLFVpGERGPRSUrfoUUIYjU4u8S\n" +
            "BOtlqge2S6Yy0+3ewdT9X+ONZbmFYDVnKKkLKLi96GtOmdXqg2gFlU+uF8tBUHxO\n" +
            "EBOItfA22ztMAFZXrfDrZ1G9uLftGCOd07uhnQ5R6a4oTLBjtE9wMvlThcBIbLZM\n" +
            "/1dvECqBbTZw/RLOczH83wqGzs/PbZOdSjn486NnnMs9jP7lDeRxnrNyWGtMj9it\n" +
            "khoZWLaV1JMvyHXOCOmodPIxX9Aw/UI/WLvEu3+1HDTZ3PUoRpuGMYXQR2Lv4Ucv\n" +
            "S04d65jgzWGhp1v9uQHTUt6sd4hRvEkCRKjDb5Nmm9lVn1+LeQL8iFBrp+DRhRl+\n" +
            "jZUfqqpBLZZMkWEAzKDw/vQdo0+b5loP/BkAIHKWo8pZEyx/jRxSfM2TLrlvj5ef\n" +
            "cI4NORdyNDO5+NsF13TP0vGkSNlobV0N9tE+1IWnC7fV0JPpbbdtx82KwB25UO+j\n" +
            "MkbQX4KMyDEhjwXS572Havcb21NHMpmY4KuAyMypuOK4bJi+Dh9lDJUThm+bKLqt\n" +
            "QJ3Zd93JQmqnPkJbD50wd4yYV1prPda3LDV0JU78J0eb2/KbfJM60oD/gQFVm8W8\n" +
            "p7DX4Qr/U8dK9TIv4v0/s7K6QfE8ffCh2q/+VxWH+p4aXhDlSVHiFVVoO1nMGHJF\n" +
            "XmU0WipVMDk5/jWdYyMRe4uuo4n619uxUEmM6hJBpes8+ghAbjbWXNyPxcX2gczA\n" +
            "MniVjDVsSas6g6gA7JyZhAaAQqgYnH49moZw0lP7UjLb7tTgPD4NEKsa1XDAwcjz\n" +
            "nHp4ugJihlKTGkQruxTLFhJ6wzZkVZ/cW8IpNPzbCPEe/5kwpI9j8B8piGROBAuY\n" +
            "bkDX/5TLS+dtZBxo2QKnpZkwThUegeHd7L5CWsFtLO9RpMmD7OUm4Tb6QFW8WZbv",
    )

    private fun blobStorageKey(itemId: String): BlobStorageKey = BlobStorageKey("ebooks/$itemId")

    override suspend fun getEbookContentForLibraryItem(itemId: String):
        SpeechifyBookBinaryData {
        val rawData = try {
            readEbook(itemId, blobStorageKey(itemId))
        } catch (e: Exception) {
            // If there was a problem decrypting the stored ebook, try to download it again
            Log.e(
                DiagnosticEvent(
                    message = "Error reading stored encrypted ebook",
                    nativeError = e,
                    sourceAreaId = "EbookService.getEbookContentForLibraryItem",
                    properties = mapOf(
                        "itemId" to itemId,
                    ),
                ),
            )
            null
        }
        if (rawData == null) {
            return storeAndGetEbookData(itemId, blobStorageKey(itemId))
        }

        return rawData
    }

    private fun getPdfPassword(libraryItemId: String, ownerId: String): String {
        val raw = "${getEncryptionKey().second}:$libraryItemId:$ownerId"
        return encryptionAdapter.sha256(raw).toHex().take(PDF_PASSWORD_MAX_LENGTH)
    }

    private fun ByteArray.toHex(): String {
        val hexChars = "0123456789abcdef"
        val result = StringBuilder(size * 2)
        forEach { byte ->
            val i = byte.toInt()
            result.append(hexChars[i shr 4 and 0x0f])
            result.append(hexChars[i and 0x0f])
        }
        return result.toString()
    }

    private suspend fun storeAndGetEbookData(itemId: String, storageKey: BlobStorageKey): SpeechifyBookBinaryData {
        val currentUser = authService.getCurrentUser().orThrow()
        val encryptionKey = getEncryptionKey()
        val ebookFileEncoder = SecureEbookFileEncoderV1(
            itemId,
            currentUser.uid,
            encryptionKey.second,
            encryptionAdapter,
        )
        val storage = BlobStorageAdapterWithEncryption(blobStorageAdapter, ebookFileEncoder)

        val token = authService.getCurrentUserIdentityToken().orThrow().token

        val rawData = httpClient.get(
            "$baseUrl/ebooks/download?keyVersion=${encryptionKey.first}&itemId=$itemId",
            ResponseBodyInterface.Companion.BinaryContentReadableRandomly,
        ) {
            header("Authorization", "Bearer $token")
        }.orThrow()

        if (rawData.body == null) {
            throw IllegalStateException("Empty ebook data received from BE")
        }

        return if (isPdf(rawData.body!!.coGetBytes(0, 6).orThrow())) {
            val storedPdf = blobStorageAdapter.coPutBlob(
                key = getPasswordProtectedPdfStorageKey(itemId),
                contentWithMimeType = rawData.body!!.withMimeType(rawData.mimeType),
            ).orThrow()
            SpeechifyBookBinaryData.PasswordProtectedPdfData(
                storedPdf.withMimeType(MimeTypeOfListenableContent.Companion.StaticMimeTypes.PDF),
                getPdfPassword(itemId, currentUser.uid),
            )
        } else {
            // Decrypt in-memory the ebook data received from BE
            val decryptedRawData = ebookFileEncoder.decodeChunk(rawData.body!!.coGetAllBytes().orThrow())
            val ebook = object :
                BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI> {
                override val binaryContent: BinaryContentReadableSequentiallyMultiplatformAPI =
                    BinaryContentReadableSequentiallyMultiplatformAPIFromFlow(
                        sourceFlow = flow {
                            emit(decryptedRawData)
                        },
                    )
                override val mimeType: MimeType
                    get() = MimeTypeOfListenableContent.Companion.StaticMimeTypes.EPUB
            }
            SpeechifyBookBinaryData.EncryptedEbookData(
                storage.coPutBytesGetReadableRandomly(storageKey, ebook).orThrow(),
            )
        }
    }

    protected fun getPasswordProtectedPdfStorageKey(fileId: String): BlobStorageKey =
        BlobStorageKey(fileId)

    private fun isPdf(bytes: ByteArray): Boolean {
        // PDF magic number: %PDF-
        return bytes.size >= 5 &&
            bytes[0] == 0x25.toByte() && // %
            bytes[1] == 0x50.toByte() && // P
            bytes[2] == 0x44.toByte() && // D
            bytes[3] == 0x46.toByte() && // F
            bytes[4] == 0x2D.toByte()
    }

    private suspend fun readEbook(itemId: String, storageKey: BlobStorageKey):
        SpeechifyBookBinaryData? {
        val currentUser = authService.getCurrentUser().orThrow()
        val ebookFileEncoder = SecureEbookFileEncoderV1(
            itemId,
            currentUser.uid,
            getEncryptionKey().second,
            encryptionAdapter,
        )
        val pdfStorageKey = getPasswordProtectedPdfStorageKey(itemId)
        if (blobStorageAdapter.fileExists(pdfStorageKey)) {
            val pdfData = blobStorageAdapter.coGetBlob(pdfStorageKey).orThrow()
            val password = getPdfPassword(itemId, currentUser.uid)
            return SpeechifyBookBinaryData.PasswordProtectedPdfData(pdfData!!, password)
        }

        // We have an encrytped ebook
        val storage = BlobStorageAdapterWithEncryption(blobStorageAdapter, ebookFileEncoder)
        return storage.getBytes(storageKey).orThrow()?.let { SpeechifyBookBinaryData.EncryptedEbookData(it) }
    }

    private fun getEncryptionKey(): Pair<String, String> {
        if (baseUrl.endsWith(".dev")) {
            return encryptionKeys.first()
        }
        return encryptionKeys.last()
    }

    override suspend fun downloadAndStore(itemId: String) {
        storeAndGetEbookData(itemId, blobStorageKey(itemId))
    }

    override suspend fun abortOrUndo(itemId: String) {
        val ebookFileEncoder = SecureEbookFileEncoderV1(
            itemId,
            "", // we don't care about the user since we are only deleting it
            getEncryptionKey().second,
            encryptionAdapter,
        )

        // We need the BlobStorageAdapterWithEncryption since it requieres the blob storage key with encoder version info
        val storage = BlobStorageAdapterWithEncryption(blobStorageAdapter, ebookFileEncoder)
        storage.coDeleteBlob(blobStorageKey(itemId)).orThrow()
    }

    override suspend fun isAvailableOffline(itemId: String): Boolean {
        val ebookFileEncoder = SecureEbookFileEncoderV1(
            itemId,
            "", // we don't care about the user since we only care if it exists
            getEncryptionKey().second,
            encryptionAdapter,
        )

        // We need the BlobStorageAdapterWithEncryption since it requieres the blob storage key with encoder version info
        val storage = BlobStorageAdapterWithEncryption(blobStorageAdapter, ebookFileEncoder)
        return storage.fileExists(blobStorageKey(itemId))
    }

    override suspend fun removeFromCache(itemId: String): Boolean {
        try {
            abortOrUndo(itemId)
        } catch (e: Exception) {
            Log.e(
                DiagnosticEvent(
                    message = "Error removing ebook from cache",
                    nativeError = e,
                    sourceAreaId = "EbookService.removeFromCache",
                    properties = mapOf(
                        "itemId" to itemId,
                    ),
                ),
            )
            return false
        }
        return true
    }
}

internal sealed class SpeechifyBookBinaryData {
    data class EncryptedEbookData(
        val data: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableRandomlyMultiplatformAPI>,
    ) : SpeechifyBookBinaryData()
    data class PasswordProtectedPdfData(
        val data: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
        val password: String,
    ) : SpeechifyBookBinaryData()
}
