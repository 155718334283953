package com.speechify.client.api.content

import com.speechify.client.internal.util.extensions.collections.concatItemToNonEmpty
import com.speechify.client.internal.util.extensions.collections.flatMapInterleaved
import com.speechify.client.internal.util.extensions.collections.mapWithPreviousItem
import com.speechify.client.internal.util.extensions.collections.prefixItemToNonEmpty

/**
 * A collection of static helper functions for processing collections of ContentText
 */
object ContentTextUtils {

    object Format {
        fun surround(prefix: String, contentText: ContentText, suffix: String) =
            prefixFillerIfNotEmpty(prefix, contentText)
                .let {
                    concatFillerTerminatorIfNotEmpty(it, suffix)
                }

        /**
         * If the [prefix] element is concatenated, its characters are all mapped to the `start` of the first item in [contentText]'s [ContentText.slices]
         */
        fun prefixFillerIfNotEmpty(prefix: String, contentText: ContentText): ContentText =
            CompositeContentText.fromSlices(
                contentText.slices
                    .prefixItemToNonEmpty { first ->
                        FillerContentSlice.createPrefixFillerForText(prefix, first)
                    }
                    .toList(),
            )

        /**
         * If the [terminatorIfNotEmpty] element is concatenated, its characters are all mapped to the `end` of the final item in [contentText]'s [ContentText.slices]
         */
        fun concatFillerTerminatorIfNotEmpty(contentText: ContentText, terminatorIfNotEmpty: String): ContentText =
            CompositeContentText.fromSlices(
                contentText.slices
                    .concatItemToNonEmpty { last ->
                        FillerContentSlice.createSuffixFillerForText(last, terminatorIfNotEmpty)
                    }
                    .toList(),
            )

        /**
         * Interpolate [ContentText] with [separator] strings. They will be interleaved with elements of [texts].
         *
         * The [ContentCursor] mapping is preserved by mapping every character of each [separator] element to the `start` of the following [texts] element.
         * @param texts
         * @param separator the filler string to be inserted
         * @return a [ContentText] interleaving the [texts] with the [separator]
         */
        fun joinWithFillerSeparator(texts: List<ContentText>, separator: String): ContentText =
            CompositeContentText.fromSlices(
                slices = if (separator.isEmpty()) {
                    texts.flatMap { it.slices.asIterable() }
                } else {
                    texts
                        .flatMapInterleaved(
                            transform = { it.slices.asIterable() },
                            getSeparator = { _, next ->
                                FillerContentSlice.createPrefixFillerForText(
                                    prefixText = separator,
                                    contentText = next,
                                )
                            },
                        )
                        .toList()
                },
            )

        /**
         * A version of [joinWithFillerSeparator] which also returns a mapping of [constituentParts] items
         * into the resulting joined text using ranges ()
         */
        internal fun <ConstituentPart : ValueWithStringRepresentation> joinWithFillerSeparatorWithMapping(
            constituentParts: List<ConstituentPart>,
            separator: String,
            getContentTextFromConstituentPart: (ConstituentPart) -> ContentText,
        ):
            JoinedTextWithMap<ContentText, ConstituentPart> =
            JoinedTextWithMap(
                joinedText = joinWithFillerSeparator(
                    constituentParts.map { getContentTextFromConstituentPart(it) },
                    separator,
                ),
                mapOfConstituentPartsToJoinedText = constituentParts.asSequence()
                    .mapWithPreviousItem(
                        getFirstItem = { part ->
                            InfoOnRangeInWholeText(
                                constituentPart = part,
                                rangeInWhole = 0..getContentTextFromConstituentPart(part).text.lastIndex,
                            )
                        },
                        getNextItem = { part, prevMapEntry ->
                            val startIdx = (prevMapEntry.rangeInWhole.last + 1) + separator.length
                            InfoOnRangeInWholeText(
                                constituentPart = part,
                                rangeInWhole = startIdx..startIdx +
                                    getContentTextFromConstituentPart(part).text.lastIndex,
                            )
                        },
                    )
                    .toList(),
            )
    }

    /**
     * Interpolate [ContentText] with filler strings. Elements of [fillers] will be interleaved with elements of [texts], with filler elements inserted first, until [fillers] is exhausted - after that point, elements of [texts] are simply concatenated.
     *
     * The [ContentCursor] mapping is preserved by mapping every character of each [fillers] element to the `start` of the following [texts] element. If a [fillers] element is inserted after the final [texts] element, its characters are all mapped to the `end` of the final [texts] element.
     * @param texts
     * @param fillers the filler strings to be inserted
     * @return a [ContentText] interleaving the [texts] with the [fillers]
     */
    // TODO investigate whether SDK consumers use this. Remove if not.
    @Deprecated("Use more specific functions in `ContentTextUtils.Format` or `ContentTextUtils` directly")
    fun format(texts: List<ContentText>, fillers: Sequence<String>): ContentText {
        val fillersIter = fillers.iterator()
        val slices = texts.asSequence()
            .flatMapTo(mutableListOf()) { text ->
                val filler = if (fillersIter.hasNext()) {
                    sequenceOf(
                        FillerContentSlice.createPrefixFillerForText(
                            prefixText = fillersIter.next(),
                            contentText = text,
                        ),
                    )
                } else {
                    emptySequence()
                }
                filler + text.slices.asSequence()
            }

        // if there are still fillers and slices has content in it
        if (fillersIter.hasNext() && slices.isNotEmpty()) {
            // we add one more slice
            val last = slices.last()
            slices.add(FillerContentSlice(last.end, last.end, fillersIter.next()))
        }
        return CompositeContentText.fromSlices(slices.filterNot { it is FillerContentSlice && it.length == 0 })
    }

    /**
     * Join [ContentText] with a delimiter. All instances of delimiter string will be mapped to the `start` of the following text.
     * @param texts a list of [ContentText]
     * @param delimiter a string
     * @return a new [ContentText] with [delimiter] text appearing between each element of [texts]
     */
    fun join(texts: List<ContentText>, delimiter: String): ContentText =
        Format.joinWithFillerSeparator(texts, separator = delimiter)

    /**
     * Concatenates [ContentText].
     * @param texts
     * @return a new [ContentText] containing all the contents of the items in [texts] in order
     */
    fun concat(texts: Sequence<ContentText>): ContentText {
        return CompositeContentText.fromSlices(texts.map { it.slices.asList() }.flatten().toList())
    }

    /**
     * Concatenates [ContentText].
     * @param texts
     * @return a new [ContentText] containing all the contents of the items in [texts] in order
     */
    fun concat(texts: List<ContentText>): ContentText {
        return concat(texts.asSequence())
    }

    /**
     * Interleave a filler between each text, in such a way that there will be at most N-1 fillers,
     * where N is the length of [texts].
     *
     * ## Example
     * ```
     * interleave(["hey", "there", "friends"], [" ", " ", " "])
     *  => ["hey", " ", "there", " ", "friends"]
     * ```
     *
     * **Note:** [fillers] can be an infinite sequence
     */
    fun interleave(texts: List<ContentText>, fillers: Sequence<String>): ContentText {
        val iter = fillers.iterator()
        return interleaveWith(texts.asSequence()) { _, _ -> if (iter.hasNext()) iter.next() else null }
    }

    /**
     * Interleave a filler between each text, in such a way that there will be at most N-1 fillers,
     * where N is the length of [texts].
     *
     * The filler is computed with a function [with] that takes the previous text and the next text, this way
     * the filler can depend on these for context. If [with] returns `null`, no filler will be inserted for that
     * pair of texts
     *
     * ## Example
     * ```
     * interleaveWith(["hey", "there", "friends"]) { _, _ -> " " }
     *  => ["hey", " ", "there", " ", "friends"]
     * ```
     */
    fun interleaveWith(
        texts: Sequence<ContentText>,
        with: (prev: ContentText, next: ContentText) -> String?,
    ): ContentText {
        return CompositeContentText.fromSlices(
            texts
                .windowed(
                    size = 2,
                    partialWindows = true,
                    transform = {
                        val first = it.first()
                        val fillerText = it.getOrNull(1)
                            ?.let { second -> with(first, second) }
                            ?: return@windowed first.slices.asSequence()

                        val filler = FillerContentSlice(
                            start = first.end,
                            end = first.end,
                            text = fillerText,
                        )

                        first.slices.asSequence() + filler
                    },
                )
                .flatten()
                .toList(),
        )
    }

    fun emptyTextSpanningElement(reference: ContentElementReference): ContentText {
        return TextElementContentSlice.fromTextElement(reference, "")
    }

    fun emptyTextSpanningCursors(start: ContentCursor, end: ContentCursor): ContentText {
        return concat(
            listOf(
                FillerContentSlice.fromCursor(start, ""),
                FillerContentSlice.fromCursor(end, ""),
            ),
        )
    }
}
