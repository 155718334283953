package com.speechify.client.internal.util.collections.maps

import kotlinx.coroutines.flow.StateFlow

/**
 * A simple thread-safe map that exposes a state flow for each key, which can be modified
 * using the functions on the map.
 */
internal interface MapWithSubscribableValues<V> {
    suspend fun getSubscribableValueOrPut(key: String, defaultValue: suspend () -> V): StateFlow<V?>

    suspend fun getSubscribableValue(key: String): StateFlow<V?>?

    suspend fun put(key: String, value: V)
}

internal suspend fun <V> MapWithSubscribableValues<V>.getCurrentOrNull(key: String): V? =
    getSubscribableValue(key)?.value
