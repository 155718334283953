package com.speechify.client.api.services.audiobook

import com.speechify.client.api.adapters.blobstorage.ChunkableEncoder
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.FirebaseFunctionsServiceImpl
import com.speechify.client.internal.services.TransformTo
import kotlinx.serialization.Serializable
import kotlin.experimental.xor

/**
 * Uses byte-wise XOR to obfuscate the data. This is not a secure encryption method, but it's good enough to meet the
 * standard for audiobook asset security.
 *
 * Also, we don't call it "XorObfuscatingEncoder" because that would reveal the obfuscation method in case the name
 * is visible in client-side application.
 */
internal class SecureAudiobookFileEncoder1(val chapterId: String) : ChunkableEncoder {
    /**
     * WARNING: changing this key-derivation method alone will break the decoder for all existing encoded files!
     * If you want to change the key-derivation function, you must also change the [id] of the encoder. This will
     * effectively ignore all existing cache entries encoded with the old version - so there will be orphaned files in
     * cache, but that's better than broken files.
     */
    internal fun getKey(): Byte {
        // A very secret salt
        val salt = "ASQ@#\$asdfajlkafd>-}*]{[!&-#@!-*[]-<{>[}+]-<+>{[}/--*%#"

        // using chapterId, each chapter for all books/users will have unique key
        val secret = chapterId

        // Very lossy of course, but all we need is obfuscation
        return "$secret:$salt".hashCode().toByte()
    }

    override val id: String = "1"
    override fun encodeChunk(data: ByteArray): ByteArray {
        val key = getKey()
        return data.map { (it xor key) }.toByteArray()
    }

    override fun decodeChunk(data: ByteArray): ByteArray {
        val key = getKey()
        return data.map { (it xor key) }.toByteArray()
    }
}

/**
 * Request Signed download URL from our backend, which authorizes that the user has access to the asset.
 */
internal class SecureAudiobookAssetDownloadUrlProvider(
    firebaseFunctionService: FirebaseFunctionsServiceImpl,
    private val audiobookProductReference: String,
) {

    private val getAssetDownloadUrlApi =
        firebaseFunctionService.FirebaseFunction<GetAssetDownloadUrlRequest, GetAssetDownloadUrlResponse>(
            name = "audiobooks-http-content-getAssetDownloadUrl",
            TransformTo.deserialize(ignoreUnknownKeys = true),
            isLegacy = false,
        )

    suspend fun getDownloadUrl(chapterFileId: AudiobookChapterFileId): Result<String> {
        return getAssetDownloadUrlApi.invoke(
            GetAssetDownloadUrlRequest(
                productUri = "speechify/product/audiobook/$audiobookProductReference",
                assetUri = chapterFileId.stringValue,
            ),
        )
            .orReturn { r -> return r }.signedUrl.successfully()
    }
}

@Serializable
internal data class GetAssetDownloadUrlRequest(
    val productUri: String,
    val assetUri: String,
)

@Serializable
internal data class GetAssetDownloadUrlResponse(
    val signedUrl: String,
    val expiresAt: Long,
)
