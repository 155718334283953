package com.speechify.client.reader.core

import com.speechify.client.api.content.Content
import com.speechify.client.internal.util.extensions.collections.flows.combine
import kotlinx.coroutines.flow.Flow

internal data class ReaderFeatures(
    val speakingWord: Content?,
    val speakingSentence: Content?,
    val selection: Selection?,
    val highlights: UserHighlightsList,
    val navigationIntent: ResolvedNavigationIntent?,
    val hoveredSentence: Content?,
    val searchState: SearchState,
) {
    internal companion object {
        val Empty = ReaderFeatures(
            speakingWord = null,
            speakingSentence = null,
            selection = null,
            highlights = UserHighlightsList.empty(),
            navigationIntent = null,
            hoveredSentence = null,
            searchState = SearchState.Idle,
        )
    }
}

internal fun createReaderFeaturesFlow(
    playbackStateFlow: Flow<PlaybackState>,
    selectionStateFlow: Flow<SelectionState>,
    highlightsInViewFlow: Flow<UserHighlightsList>,
    navigationStateFlow: Flow<ResolvedNavigationIntent?>,
    hoveredSentenceStateFlow: Flow<HoveredSentenceState>,
    searchStateFlow: Flow<SearchState>,
): Flow<ReaderFeatures> {
    return combine(
        playbackStateFlow,
        selectionStateFlow,
        highlightsInViewFlow,
        navigationStateFlow,
        hoveredSentenceStateFlow,
        searchStateFlow,
    ) { playback, selection, highlightsInView, navigationIntent, hoveredSentenceState, search ->
        ReaderFeatures(
            speakingWord = playback.speakingWord,
            speakingSentence = playback.speakingSentence,
            selection = selection.selection,
            highlights = highlightsInView,
            navigationIntent = navigationIntent,
            hoveredSentence = hoveredSentenceState.hoveredSentence,
            searchState = search,
        )
    }
}
