package com.speechify.client.api.content.view.book

import kotlin.js.JsExport

@JsExport
data class BookMetadata(
    val numberOfPages: Int,
)

internal val BookMetadata.lastPageIndex: Int
    get() = this.numberOfPages - 1
