package com.speechify.client.helpers.content.standard.streamable.items.topLevelItems

import com.speechify.client.api.content.ObjectRef
import com.speechify.client.helpers.content.standard.streamable.ItemLines
import com.speechify.client.helpers.content.standard.streamable.ItemPartsWithImplicitWhitespace
import com.speechify.client.helpers.content.standard.streamable.ItemWithParts
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.Line
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItem
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.ListItemLines
import com.speechify.client.helpers.content.standard.streamable.items.innerItems.TextWithRef
import kotlin.js.JsExport

@JsExport
sealed class TopLevelItem

/**
 * The text in [parts] should include whitespace. For a version where whitespaces are implied, see
 * [ParagraphPartsWithImplicitWhitespace].
 */
@JsExport
class ParagraphParts(
    override val parts: Array<TextWithRef>,
    override val ref: ObjectRef<Any?>,
) : TopLevelItem(), ItemWithParts

@JsExport
class ParagraphLines(
    override val lines: Array<Line>,
    override val ref: ObjectRef<Any?>,
) : TopLevelItem(), ItemLines

@JsExport
class ParagraphPartsWithImplicitWhitespace(
    override val partsWithNoWhitespace: Array<TextWithRef>,
    override val ref: ObjectRef<Any?>,
) : ItemPartsWithImplicitWhitespace, TopLevelItem()

@JsExport
class Heading(
    override val parts: Array<TextWithRef>,
    override val ref: ObjectRef<Any?>,
) : TopLevelItem(), ItemWithParts

@JsExport
class HeadingLines(
    override val lines: Array<Line>,
    override val ref: ObjectRef<Any?>,
) : TopLevelItem(), ItemLines

@JsExport
class List(
    val listItems: Array<ListItem>,
    val ref: ObjectRef<Any?>,
) : TopLevelItem()

@JsExport
class ListFromItemsLines(
    val listItems: Array<ListItemLines>,
    val ref: ObjectRef<Any?>,
) : TopLevelItem()
