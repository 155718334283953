package com.speechify.client.api.services.account

import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.services.account.models.AccountSettings
import com.speechify.client.api.services.account.models.BillingData
import com.speechify.client.api.services.account.models.ReadingExperience
import com.speechify.client.api.services.account.models.toBoundaryMap
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.subscription.PlatformFetcher
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

internal const val ACCOUNT_SETTINGS_COLLECTION = "accountSettings"
internal const val USER_BILLING_DATA_COLLECTION = "userBillingData"

/**
 * Facilitates the storage and retrieval of persistent cross-platform account-level settings.
 *
 * @see [AccountSettings]
 */
@JsExport
class AccountSettingsService internal constructor(
    private val authService: AuthService,
    private val firebaseFirestoreService: FirebaseFirestoreService,
    private val platformFetcher: PlatformFetcher,
) {

    fun getSettings(callback: Callback<AccountSettings>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AccountSettingsService.getSettings",
    ) {
        val user = authService.getCurrentUser().orReturn { return@fromCoWithErrorLogging it }
        val docResult = firebaseFirestoreService.coGetDocument(ACCOUNT_SETTINGS_COLLECTION, user.uid)
            .orReturn { return@fromCoWithErrorLogging it }
        when (docResult) {
            is FirebaseFirestoreDocumentSnapshot.Exists -> try {
                val result = parseAccountSettings(docResult)
                return@fromCoWithErrorLogging result.successfully()
            } catch (ex: Exception) {
                return@fromCoWithErrorLogging Result.Failure(SDKError.OtherException(ex))
            }

            FirebaseFirestoreDocumentSnapshot.NotExists -> {
                val settings = AccountSettings(customSettings = SdkBoundaryMap())
                return@fromCoWithErrorLogging coSetSettings(settings)
            }
        }
    }

    private fun parseAccountSettings(docResult: FirebaseFirestoreDocumentSnapshot.Exists): AccountSettings {
        val json = docResult.data.toJsonElement().jsonObject
        val customSettings = json["customSettings"]
            /* #IncidentsOfMissIngcustomSettingsObserved - Use `?` because a missing `customSettings`
               [has been observed](https://speechifyworkspace.slack.com/archives/C03DT8SNLN5/p1679654552406299?thread_ts=1679650705.469449&cid=C03DT8SNLN5),
               not quite clear why (could be a save from unsafe JavaScript), but let's prevent more login problems here
             */
            ?.jsonObject
            ?.mapValues { entry -> entry.value.jsonPrimitive.content }
            ?.toMutableMap()
            ?: mutableMapOf()

        return AccountSettings(
            readingExperience = json["readingExperience"]?.jsonPrimitive?.content?.let {
                ReadingExperience.valueOf(
                    it,
                )
            } ?: ReadingExperience.PRE_Q3_2022_LEGACY,
            customSettings = SdkBoundaryMap(customSettings),
        )
    }

    fun setSettings(settings: AccountSettings, callback: Callback<AccountSettings>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AccountSettingsService.setSettings",
    ) {
        coSetSettings(settings)
    }

    internal suspend fun coSetSettings(settings: AccountSettings): Result<AccountSettings> {
        val user = authService.getCurrentUser().orReturn { return it }
        firebaseFirestoreService.coSetDocument(
            ACCOUNT_SETTINGS_COLLECTION,
            user.uid,
            settings.toBoundaryMap(),
            merge = true,
        ).orReturn { return it }

        return settings.successfully()
    }

    fun setBillingData(billingData: BillingData, callback: Callback<BillingData>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AccountSettingsService.setBillingData",
        shouldSilenceError = { error ->
            // We don't need to log InvalidBillingDataError since they cannot be prevented by SDK consumers and are
            // expected to happen.
            error is SDKError.InvalidBillingDataError
        },
    ) {
        platformFetcher.updateBillingData(billingData).orReturn { return@fromCoWithErrorLogging it }
        return@fromCoWithErrorLogging billingData.successfully()
    }

    fun getBillingData(callback: Callback<BillingData?>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "AccountSettingsService.getBillingData",
    ) {
        val user = authService.getCurrentUser().orReturn { return@fromCoWithErrorLogging it }
        val docResult = firebaseFirestoreService.coGetDocument(USER_BILLING_DATA_COLLECTION, user.uid)
            .orReturn { return@fromCoWithErrorLogging it }
        when (docResult) {
            is FirebaseFirestoreDocumentSnapshot.NotExists -> {
                return@fromCoWithErrorLogging Result.Success(null)
            }

            is FirebaseFirestoreDocumentSnapshot.Exists -> {
                return@fromCoWithErrorLogging docResult.value<BillingData>()
            }

            else -> {
                throw IllegalStateException("Received result that is neither exists nor not exists.")
            }
        }
    }
}

internal suspend fun AccountSettingsService.coGetSettings() = suspendCoroutine { getSettings(it::resume) }

internal suspend fun AccountSettingsService.coGetBillingData() = suspendCoroutine { getBillingData(it::resume) }
internal suspend fun AccountSettingsService.coSetBillingData(billingData: BillingData) =
    suspendCoroutine { setBillingData(billingData, it::resume) }
