package com.speechify.client.api.content.scannedbook

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.ContentElementReference
import com.speechify.client.api.content.ContentTextPosition
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.book.BaseBookPageWrapper
import com.speechify.client.api.content.view.book.BaseBookView
import com.speechify.client.api.content.view.book.BookMetadata
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.services.scannedbook.models.ScannedBook
import com.speechify.client.api.services.scannedbook.models.ScannedBookPage
import com.speechify.client.api.services.scannedbook.models.coGetPage
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.helpers.content.standard.book.LineGroup
import com.speechify.client.internal.services.ml.BookPageMLParsingWithRemoteOCRService
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

internal class ScannedBookBookView internal constructor(
    private val scannedBook: ScannedBook,
    private val scannedBookBookPageFactory: ScannedBookBookPageFactory,
    override val mlParsingModeFlow: StateFlow<MLParsingMode>,
) : BaseBookView() {

    override val start: ContentCursor = ContentElementReference.forRoot().start
    override val end: ContentCursor = ContentElementReference.forRoot().end

    override val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy> =
        // Scanned books are already OCR-ed by design.
        MutableStateFlow(OcrFallbackStrategy.ForceDisable)

    init {
        initializeBookViewFlows()
    }

    override fun getMetadata(): BookMetadata =
        BookMetadata(scannedBook.numberOfPages)

    override fun getPages(pageIndexes: Array<Int>, callback: Callback<Array<BookPage>>) = callback.fromCo {
        getPages(pageIndexes)
            .map { it.toTypedArray() }
    }

    private suspend fun getPages(pageIndexes: Array<Int>): Result<List<BookPage>> = coroutineScope {
        pageIndexes.map { pageIndex ->
            async {
                pageCache.getOrPut(pageIndex) {
                    scannedBookBookPageFactory.createBookPage(
                        scannedBookPage = scannedBook.coGetPage(pageIndex).orThrow(),
                        pageIndex = pageIndex,
                        getSurroundingLineGroups = ::getSurroundingLineGroups,
                    ) { bookPageIndex, parsedPageContent ->
                        this@ScannedBookBookView.notifyTextContentRetrieved(
                            bookPageIndex,
                            parsedPageContent,
                        )
                    }
                }
            }
        }.awaitAll()
            .successfully()
    }

    override fun getPageIndex(cursor: ContentCursor): Int =
        when (cursor) {
            is ContentTextPosition -> cursor.element.path[0]
            is ContentElementBoundary -> when {
                cursor.element.path.isEmpty() -> {
                    when (cursor.boundary) {
                        is ContentBoundary.START -> 0
                        is ContentBoundary.END -> scannedBook.numberOfPages - 1
                    }
                }

                else -> cursor.element.path[0]
            }
        }

    override fun search(
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        searchOptions: BookSearchOptions,
        callback: Callback<Array<BookSearchResult>>,
    ) {
        // todo: need to implement search in scanned book first
        callback(emptyArray<BookSearchResult>().successfully())
    }

    override suspend fun translateToUsableCursor(originalCursor: ContentCursor): ContentCursor {
        // Content sorting is not intended to be provided at the moment for ScannedBooks, thus it's safe
        // to return [originalCursor] here.
        return originalCursor
    }

    override suspend fun getTableOfContents() = null
}

internal class ScannedBookBookPageFactory internal constructor(
    private val bookPageMLParsingWithRemoteOCRService: BookPageMLParsingWithRemoteOCRService,
    private val mlParsingModeFlow: StateFlow<MLParsingMode>,
) {
    fun createBookPage(
        scannedBookPage: ScannedBookPage,
        pageIndex: Int,
        getSurroundingLineGroups: suspend (pageIndex: Int, pageOffset: Int) -> Result<List<List<LineGroup>>>,
        notifyTextContentRetrieved: suspend (pageIndex: Int, ParsedPageContent) -> Unit,
    ): BookPage {
        val scannedBookBookPage = ScannedBookBookPage(
            pageIndex,
            scannedBookPage,
        )
        return BaseBookPageWrapper(
            bookPageDelegate = scannedBookBookPage,
            runOcrFallback = {
                throw IllegalStateException(
                    "ScannedBookBookPage must avoid running OCR twice, as OCR has already" +
                        " been executed in a previous stage.",
                )
            },
            mlParsingModeFlow = mlParsingModeFlow,
            getSurroundingLineGroups = getSurroundingLineGroups,
            getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR =
            bookPageMLParsingWithRemoteOCRService::getParsedPageContentOrFallbackToRawTextContentDerivedFromServerOCR,
            notifyTextContentRetrieved = notifyTextContentRetrieved,
            // Scanned books are already OCR-ed by design.
            ocrFallbackStrategyFlow = MutableStateFlow(OcrFallbackStrategy.ForceDisable),
        )
    }
}
