package com.speechify.client.api.services.file.models

import com.speechify.client.api.SpeechifyEntity
import com.speechify.client.api.util.io.File
import kotlin.js.JsExport

/**
 * The SpeechifyFile is a File with a stable global identity in the Speechify system.
 */
@JsExport
abstract class SpeechifyFile : File(), SpeechifyEntity
