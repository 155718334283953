package com.speechify.client.helpers.content.standard.book

import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.speech.SpeechUtils
import com.speechify.client.api.content.view.speech.wordCount
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.Viewport
import com.speechify.client.api.util.images.horizontalDistanceOrOverlapTo
import com.speechify.client.api.util.images.horizontalDistanceTo
import com.speechify.client.api.util.images.verticalDistanceOrOverlapTo
import com.speechify.client.internal.util.collections.lists.isContiguous
import com.speechify.client.internal.util.eqWithTolerance
import com.speechify.client.internal.util.extensions.collections.kMeans

/**
 * We wrap the BookPageTextContentItem in a class to be able to add additional information to it.
 */
internal data class WrappedBookPageTextContentItem(
    val source: BookPageTextContentItem,
    /** Index of the text in the original list of texts provided by adapter. */
    val originalIndex: Int = -1,
    /** Index of the text in the list of text sorted left to right, top to bottom. */
    var sortedIndex: Int = -1,
) {
    val normalizedBox: BoundingBox
        get() = source.normalizedBox

    /**
     * This tries to determine if the text is likely 90° rotated for PDF implementations where no angle information
     * is supplied in the transformation matrix.
     */
    val isLikelyVerticalText: Boolean
        get() {
            // In order to avoid false positives we only consider cases that have enough text to where
            // the aspect ratio is not square, and that have a large enough aspect ratio to conclusively determine
            // orientation.
            if (normalizedBox.height < normalizedBox.width ||
                (normalizedBox.height / normalizedBox.width) in 0.75..1.25 ||
                source.text.text.trim().length < 3
            ) {
                return false
            }

            // We try to check the aspect ratio of the characters.
            val textLength = source.text.text.length
            val normalizedWidth = normalizedBox.width / textLength
            val normalizedHeight = normalizedBox.height / textLength

            val aspectRatioHorizontal = normalizedWidth / normalizedBox.height
            val aspectRatioVertical = normalizedHeight / normalizedBox.width

            // And based on this determine if the text is horizontal or vertical.
            return (aspectRatioVertical > aspectRatioHorizontal)
        }

    override fun toString() = source.text.text
}

/**
 * This represents a single line of text. Line in this context means until it's interrupted by a line break, or
 * sufficiently large vertical gap (like the space between columns on a page).
 */
internal class TextLine {
    private val internalTexts = mutableListOf<WrappedBookPageTextContentItem>()

    val texts: List<WrappedBookPageTextContentItem>
        get() = internalTexts.toList()

    lateinit var boundingBox: BoundingBox
        private set

    fun addText(text: WrappedBookPageTextContentItem) {
        internalTexts.add(text)
        boundingBox = if (internalTexts.size == 1) {
            text.normalizedBox
        } else {
            boundingBox.union(text.normalizedBox)
        }
    }

    override fun toString(): String = texts.joinToString(" ") { it.toString() }

    companion object {
        fun fromText(text: WrappedBookPageTextContentItem): TextLine {
            val line = TextLine()
            line.addText(text)
            return line
        }
    }
}

/**
 * This represents a grouping of lines into something that logically makes sense to be read as a single unit.
 * This could be a single line of text, or an entire paragraph in a column.
 */
internal class TextGroup {

    private val internalLines = mutableListOf<TextLine>()

    val lines: List<TextLine>
        get() = internalLines.toList()

    lateinit var boundingBox: BoundingBox
        private set

    fun addLine(line: TextLine) {
        internalLines.add(line)
        boundingBox = if (internalLines.size == 1) {
            line.boundingBox
        } else {
            boundingBox.union(line.boundingBox)
        }
    }

    override fun toString(): String = internalLines.joinToString("\n") { it.toString() }

    companion object {
        fun fromLine(line: TextLine): TextGroup {
            val group = TextGroup()
            group.addLine(line)
            return group
        }

        fun fromLines(lines: List<TextLine>): TextGroup {
            val group = TextGroup()
            lines.forEach { group.addLine(it) }
            return group
        }
    }
}

/**
 * This function takes in the text on a page as it comes from the adapter.
 * It then runs through a chain of processing steps to ensure that the text is ordered in a way that makese sense
 * for listening / reading.
 *
 * The text input can be sorted in any way since the order will be thrown away during processing.
 * The output is the same text (possibly with some whitespace merged) but sorted in a way that makes sense for reading.
 */
internal fun ensureOrderOnPage(text: List<BookPageTextContentItem>, pageSize: Viewport):
    List<BookPageTextContentItem> {
    // Preprocessing: Fold whitespaces into their preceeding items to make future processing easier.
    val mergedText = mergeBlankContentItemsIntoText(text, pageSize)

    // Step 1 - Lay out all items from left to right, top to bottom. Ignoring any existing order, or spacing.
    val sortedContent = sortContentOnPage(mergedText, pageSize)

    // Step 2 - With the text sorted the way it visually appears we can now group it into lines of content.
    // This will split text on the same line with large spacing into two lines.
    // E.g. Content like:
    // C1C2C3C4C5C6C7C8C9
    //
    // C1C2C3C4  C5C6C7C8
    // C1C2C3C4  C5C6C7C8
    //
    // C1C2C3C4C5C6C7C8C9
    // Will be split into sentences like:
    // L1L1L1L1L1L1L1L1L1
    //
    // L2L2L2L2  L3L3L3L3
    // L4L4L4L4  L5L5L5L5
    //
    // L6L6L6L6L6L6L6L6L6
    val roughLines = groupSortedContentIntoLines(sortedContent, pageSize)

    // Step 3 - We now group the lines we created earlier into larger groups of content on the page.
    // To build on the example from before:
    // L1L1L1L1L1L1L1L1L1
    //
    // L2L2L2L2  L3L3L3L3
    // L4L4L4L4  L5L5L5L5
    //
    // L6L6L6L6L6L6L6L6L6
    // Will be grouped as:
    // G1G1G1G1G1G1G1G1G1
    //
    // G2G2G2G2  G3G3G3G3
    // G2G2G2G2  G3G3G3G3
    //
    // G4G4G4G4G4G4G4G4G4
    val groups = createTextGroupsFromTextLines(roughLines, pageSize)

    // Step 4 - Finally we only need to sort the large groups of text in an order that is reasonable for listening.
    // The above example is already sorted, but in some cases the groups will be out of order.
    val sortedGroups = orderGroupsOnPage(groups, pageSize)

    // This simply unwraps the groups back into the list of text items, but now in the correct order.
    return unwrapGroupsIntoTexts(sortedGroups)
}

/**
 * Some PDF adapters (Web) create empty BookPageTextContentItems which only contain empty strings or whitespaces.
 * Since creating a sensible layout is impossible with them polluting the data set we merge them with their preceding
 * text if they can visually be considered to be part of the same line.
 */
internal fun mergeBlankContentItemsIntoText(text: List<BookPageTextContentItem>, pageSize: Viewport):
    List<BookPageTextContentItem> {
    val newText = mutableListOf<BookPageTextContentItem>()
    val textToBeProcessed = text.toMutableList()

    while (textToBeProcessed.isNotEmpty()) {
        val item = textToBeProcessed.removeAt(0)
        // 0 since we removed the item already.
        val nextItem = textToBeProcessed.getOrNull(0)

        if (nextItem == null) {
            newText += item
            break
        }

        newText += if ((
            nextItem.text.text.isBlank() &&
                isSameLine(item.normalizedBox, nextItem.normalizedBox, pageSize)
            ) ||
            nextItem.text.text.isEmpty()
        ) {
            // Remove the blank item, so we don't included it again.
            textToBeProcessed.removeAt(0)

            // We don't modify the bounding box for whitespaces / empty items, since some documents have broken
            // content there messing with our bounding boxes.
            val newBox = item.normalizedBox
            val newSlice = TextElementContentSlice(
                item.text.rootElement,
                Pair(item.text.range.first, nextItem.text.range.second),
                item.text.text + nextItem.text.text,
                item.text.metadata,
            )
            BookPageTextContentItem(
                text = newSlice,
                box = newBox,
                fontFamily = item.fontFamily,
                textSourceType = item.textSourceType,
                type = null,
            )
        } else {
            item
        }
    }

    return newText
}

/**
 * This does a basic sort of all content items from top to bottom, and horizontally per the inferred dominant direction.
 * In doing this we choose to ignore the original order of the content for future processing steps.
 * By doing this we can ensure that we have a consistent quality of order regardless of input data.
 */
internal fun sortContentOnPage(text: List<BookPageTextContentItem>, pageSize: Viewport):
    List<WrappedBookPageTextContentItem> {
    val pageDirection = inferDominantTextDirectionFromPossiblyUnorderedTextFragments(text) { it.text.text }

    val lines = groupUnsortedContentIntoLines(text, pageSize)
    val linesWithContentSorted = sortLineContent(lines, pageDirection)

    // Sort the lines from top to bottom
    linesWithContentSorted.sortBy { it.first().normalizedBox.top }

    // Retrieve the text items from the sorted lines arranged from top to bottom and based on page direction
    return linesWithContentSorted.mapIndexed { count, line ->
        line.withIndex().map { (index, item) ->
            WrappedBookPageTextContentItem(item, text.indexOf(item), index + count)
        }
    }.flatten()
}

internal fun groupUnsortedContentIntoLines(text: List<BookPageTextContentItem>, pageSize: Viewport):
    MutableList<MutableList<BookPageTextContentItem>> {
    val lines = mutableListOf<MutableList<BookPageTextContentItem>>()
    text.forEach { item ->
        val existingLine = lines.find { isSameLine(item.normalizedBox, it.last().normalizedBox, pageSize) }
        if (existingLine != null) {
            existingLine.add(item)
        } else {
            lines.add(mutableListOf(item))
        }
    }

    return lines
}

internal fun sortLineContent(lines: MutableList<MutableList<BookPageTextContentItem>>, pageDirection: TextDirection?):
    MutableList<List<BookPageTextContentItem>> {
    // Sort the content in each line based on page direction
    return lines.map { line ->
        val sortedLine = line.sortedWith { a, b ->
            when (pageDirection) {
                null, TextDirection.LeftToRight -> a.normalizedBox.left.compareTo(b.normalizedBox.left)
                TextDirection.RightToLeft -> -1 * a.normalizedBox.right.compareTo(b.normalizedBox.right)
            }
        }

        sortedLine
    }.toMutableList()
}

/**
 * This is the heavy lifter of the text grouping logic. It takes the sorted content items and groups it into lines.
 * Consider both line breaks, and vertical gaps between words which indicate the space between a column.
 */
internal fun groupSortedContentIntoLines(text: List<WrappedBookPageTextContentItem>, pageSize: Viewport):
    List<TextLine> {
    // Bucket into the 5 most common font sizes to make future calculations easier.
    val fontSizes = text.kMeans(5) { word ->
        return@kMeans word.normalizedBox.width / word.source.text.text.length
    }.sortedByDescending { it.average }
        .filter { it.count > 0 }

    // This allows us to differentiate between PDF adapters that return content word by word, or ones that return
    // whole sentences.
    val isSentenceBasedContent = text.count {
        SpeechUtils.fromText(it.source.text).wordCount(it.source.text.start, it.source.text.end) > 3
    } > text.size / 3

    return text.fold(mutableListOf()) { acc, pdfPageTextContentItem ->
        val currentLine = acc.lastOrNull()

        // Guaranteed to always find a font size.
        val fontSize = fontSizes.find { it.items.contains(pdfPageTextContentItem) }!!
        // After a lot of trial and error, across multiple PDFs,
        // just the font size was enough to detect columns reliably.
        val maximumXSpacing = if (isSentenceBasedContent) {
            // In sentence based content we can have a lower threshold since we no longer are concerned with
            // building the the main sentences, but only about splitting the columns.
            fontSize.average * 1.1
        } else {
            fontSize.average * 1.7
        }

        if (currentLine == null) {
            acc.add(TextLine.fromText(pdfPageTextContentItem))
            return@fold acc
        }

        fun isNumberedListItemAndNextText(currentLine: TextLine): Boolean {
            /**
             * This regex pattern does the following:
             *
             * ^ ensures the match occurs at the start of the string
             * (...) groups the alternative patterns
             * | separates the alternative patterns
             * \\(\\d+\\) matches (number)
             * \\[\\d+\\] matches [number]
             * \\d+\\. matches number.
             * \\d+: matches number
             *
             * It will detect the following patterns:
             *  - [number]
             *  - (number)
             *  - number:
             *  - (c)
             *  - [c]
             *  - c:
             *
             *  *NOTE:* Technicaly, we should include `number.`, however, these are also used in chapters or subtitles
             *  and column grouping may be broken
             */
            val regex = Regex("""^(\[\d+\]|\(\d+\)|\d+:|\(\p{L}\)|\[\p{L}\]|\p{L}:)""")
            val curentLineText = currentLine.texts.map { it.source.text.text }.joinToString(" ")

            return regex.find(curentLineText) != null
        }

        fun shouldBeSplitInSeparateLine(currentLine: TextLine, pdfPageTextContentItem: WrappedBookPageTextContentItem):
            Boolean {
            // If the two texts are not on the same line at all, split
            if (!isSameLine(currentLine.boundingBox, pdfPageTextContentItem.normalizedBox, pageSize)) {
                return true
            }

            // If they are on the same line and the current line is a numbered list item,
            // we don't care about the distance. It is the same line
            if (isNumberedListItemAndNextText(currentLine)) {
                return false
            }

            // Split based on distance.
            return currentLine.boundingBox horizontalDistanceTo (pdfPageTextContentItem.normalizedBox) > maximumXSpacing
        }

        if (shouldBeSplitInSeparateLine(currentLine, pdfPageTextContentItem) ||
            // Additional check to make sure rotated text is not combined into one line.
            pdfPageTextContentItem.isLikelyVerticalText != currentLine.texts.last().isLikelyVerticalText
        ) {
            // Line break or further spaced than words.
            acc.add(TextLine.fromText(pdfPageTextContentItem))
            return@fold acc
        }
        currentLine.addText(pdfPageTextContentItem)
        acc
    }
}

/**
 * After we grouped the text into lines, we can group lines that don't have a large vertical spacing
 * into a larger group, so there is less data to move around in the next step.
 */
internal fun createTextGroupsFromTextLines(lines: List<TextLine>, pageSize: Viewport): List<TextGroup> {
    fun TextGroup.isLikelyRotated(): Boolean {
        return this.boundingBox.transform.getAngle() != 0.0 ||
            this.lines.any { l -> l.texts.any { t -> t.isLikelyVerticalText } }
    }

    fun sortLines(newLines: MutableList<TextLine>): MutableList<TextLine> {
        val lineGroups: MutableList<MutableList<TextLine>> = mutableListOf()

        // We group the lines into different groups to guarantee that each line is present in only one group
        // before sorting them by left position
        newLines.sortedWith(compareBy { it.boundingBox.bottom })
            .forEach { line ->
                val existingGroup = lineGroups.find { isSameLine(it.first().boundingBox, line.boundingBox, pageSize) }
                if (existingGroup != null) {
                    existingGroup.add(line)
                } else {
                    lineGroups.add(mutableListOf(line))
                }
            }

        // We sort each group based on text direction
        val sortedLineGroups = mutableListOf<MutableList<TextLine>>()
        for (lineGroup in lineGroups) {
            val sortedLineGroup = lineGroup.sortedWith(
                compareBy {
                    when (it.textDirection) {
                        null, TextDirection.LeftToRight -> it.boundingBox.left
                        TextDirection.RightToLeft -> -it.boundingBox.right
                    }
                },
            ).toMutableList()
            sortedLineGroups.add(sortedLineGroup)
        }

        // We flatten the groups and return the sorted lines
        // At this point we should have all lines sorted from left to right (or right to left, depending on text direction)
        // and from top to bottom
        return sortedLineGroups.flatten().toMutableList()
    }

    fun isIndexPageContent(currentGroup: TextGroup, textLine: TextLine): Boolean {
        // A set of special characters which we identified can be part of the index number. To be extended as more examples arrive
        val specialCharacters = setOf('▓')
        fun isIndex(text: String): Boolean {
            return text.all { it.isDigit() || it.isWhitespace() || it in specialCharacters }
        }

        fun isIndexRightSide(newItemText: String): Boolean {
            return isIndex(newItemText)
        }

        fun isIndexLeftSide(columnText: String): Boolean {
            return isIndex(columnText)
        }

        val groupText = currentGroup.lines.map { it.texts.map { it.source.text.text }.joinToString("") }
            .joinToString("")
        val textLineText = textLine.texts.map { it.source.text.text }.joinToString("")

        return isIndexRightSide(textLineText) || isIndexLeftSide(groupText)
    }

    fun areLinesNextToEachOther(currentGroup: TextGroup, textLine: TextLine): Boolean {
        val groupIndexStart = currentGroup.lines.first().texts.first().originalIndex
        val groupIndexEnd = currentGroup.lines.last().texts.last().originalIndex

        val textLineIndexStart = textLine.texts.first().originalIndex
        val textLineIndexEnd = textLine.texts.last().originalIndex

        val difWhenGroupOnLeft = textLineIndexStart - groupIndexEnd
        val difWhenGroupOnRight = groupIndexStart - textLineIndexEnd

        return difWhenGroupOnRight == 1 || difWhenGroupOnLeft == 1
    }

    fun isSameLine(
        currentGroup: TextGroup,
        lineBeingChecked: TextLine,
        verticalThreshold: Double,
    ) = (
        currentGroup.boundingBox.verticalDistanceOrOverlapTo(lineBeingChecked.boundingBox)
            <= verticalThreshold
        )

    infix fun TextGroup.isContiguousInOriginalSourceWith(otherTextGroup: TextGroup): Boolean {
        val pdfIndices = this.lines.flatMap { it.texts.map { t -> t.originalIndex } }.sorted()
        val otherPdfIndices = otherTextGroup.lines.flatMap { it.texts.map { t -> t.originalIndex } }.sorted()
        val mergedIndices = (pdfIndices + otherPdfIndices).sorted()
        return mergedIndices.isContiguous()
    }

    infix fun TextGroup.isContiguousInOriginalSourceWith(line: TextLine): Boolean {
        val pdfIndices = this.lines.flatMap { it.texts.map { t -> t.originalIndex } }.sorted()
        val otherPdfIndices = line.texts.map { t -> t.originalIndex }.sorted()
        val mergedIndices = (pdfIndices + otherPdfIndices).sorted()
        return mergedIndices.isContiguous()
    }

    val linesAwaitingProcessing = lines.toMutableList()
    val groups = mutableListOf<TextGroup>()

    val correctOrderFactor = 5.0

    // The threshold is represented as a fixed value of points on the unnormalized page.
    // We divide by page height to get the normalized value to use.
    // The pageSize is interpreted to be in PDF points.
    val horizontalThreshold = (3.0 / pageSize.width)
    val verticalThreshold = (5.5 / pageSize.height)

    while (linesAwaitingProcessing.isNotEmpty()) {
        // Grab the first sentence and start a new group.
        val currentGroup = TextGroup.fromLine(linesAwaitingProcessing.removeAt(0))
        // The sentences are already sorted, so we can just iterate the remainder and append to this group
        // if they belong.

        val groupText = currentGroup.lines.map {
            it.texts.map { it.source.text.text }
                .joinToString("")
        }.joinToString(
            "",
        ).trim()

        for (lineBeingChecked in linesAwaitingProcessing) {
            // If the content was originally in the same order, we can allow a larger horizontal spacing.
            // The bias was determined by trial and error.
            val maxDistance = if (currentGroup isContiguousInOriginalSourceWith lineBeingChecked) {
                // we calculate the average width of a letter in the group and allow a space of up to two and a half letters
                // Why 2.5? Some experiments that I did on different PDFs and this seemed to be the sweet-spot.
                val calculatedThreshold = (currentGroup.boundingBox.width / groupText.length) * 2.5
                if (calculatedThreshold < horizontalThreshold) {
                    horizontalThreshold
                } else {
                    calculatedThreshold
                }
            } else {
                horizontalThreshold
            }

            val isBelowAndMuchWider = lineBeingChecked.boundingBox.centerY > currentGroup.boundingBox.bottom &&
                (lineBeingChecked.boundingBox.width / currentGroup.boundingBox.width) >= 10 &&
                currentGroup.lines.size > 1

            if (isSameLine(currentGroup, lineBeingChecked, verticalThreshold) &&
                currentGroup.boundingBox.horizontalDistanceTo(lineBeingChecked.boundingBox) <= maxDistance &&
                // We don't want to mix 90° rotated text into the same group, as this will break the following logic.
                currentGroup.boundingBox.transform.getAngle()
                    .eqWithTolerance(lineBeingChecked.boundingBox.transform.getAngle(), 0.01) &&
                !isBelowAndMuchWider
            ) {
                currentGroup.addLine(lineBeingChecked)
            } else if (isIndexPageContent(currentGroup, lineBeingChecked) &&
                isSameLine(currentGroup, lineBeingChecked, verticalThreshold) &&
                // This check is needed to validate that we don't accidentaly merge lines that have other text between them
                areLinesNextToEachOther(currentGroup, lineBeingChecked) &&
                !isBelowAndMuchWider
            ) {
                currentGroup.addLine(lineBeingChecked)
            }
        }
        // All the sentences we just grouped we no longer need to check in the next round.
        linesAwaitingProcessing.removeAll(currentGroup.lines)
        groups.add(currentGroup)
    }

    // We do a second step to clean up the output. Sometimes we create two groups that the bounding boxes fully
    // overlap. Example:
    // L1  L2L2L2
    // L3L3L3L3L3
    // Would be grouped as:
    // G1  G2G2G2
    // G1G1G1G1G1
    // By the previous step. This next step will merge those groups into one.
    // G1  G1G1G1
    // G1G1G1G1G1
    val groupsAwaitingProcessing = groups.toMutableList()
    val finalGroups = mutableListOf<TextGroup>()
    while (groupsAwaitingProcessing.isNotEmpty()) {
        val currentGroup = groupsAwaitingProcessing.removeAt(0)

        val overlappingGroups = groupsAwaitingProcessing.filter { otherGroup ->
            // We check to make sure rotated text is not combined into one group.
            if (currentGroup.isLikelyRotated() ||
                otherGroup.isLikelyRotated()
            ) {
                return@filter false
            }

            // We replicate the logic from above allowing a larger vertical spacing if the content was originally
            // in the same order. We found that the case where text really reads across the column gap is quite rare,
            // so we can allow a larger spacing here.

            // Since we want to avoid grouping multiple columns, we add an upper limit to the size for which the
            // horizontal spacing is allowed to be larger.
            val areLargeGroups = currentGroup.boundingBox.height > 0.15 || otherGroup.boundingBox.height > 0.15
            val orderFactor = if (!areLargeGroups &&
                currentGroup isContiguousInOriginalSourceWith otherGroup
            ) {
                // Reduce factor for the larger groups as they should be overlapping anyway.
                correctOrderFactor / 2.0
            } else {
                1.0
            }

            val verticalOverlap = otherGroup.boundingBox.verticalDistanceOrOverlapTo(currentGroup.boundingBox)
            val horizontalOverlap = otherGroup.boundingBox.horizontalDistanceOrOverlapTo(currentGroup.boundingBox)
            verticalOverlap < 0 && horizontalOverlap < horizontalThreshold * orderFactor
        }
        val newLines = mutableListOf<TextLine>()
        newLines.addAll(currentGroup.lines)
        overlappingGroups.forEach {
            newLines.addAll(it.lines)
        }
        groupsAwaitingProcessing.removeAll(overlappingGroups)
        finalGroups.add(
            TextGroup.fromLines(sortLines(newLines)),
        )
    }

    return finalGroups.toList()
}
