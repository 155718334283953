package com.speechify.client.api.audio

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.subscription.SubscriptionService
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.orThrow
import com.speechify.client.bundlers.listening.VoicePreference
import com.speechify.client.bundlers.listening.VoicePreferences
import com.speechify.client.internal.sync.AtomicRef
import com.speechify.client.internal.sync.getOrUpdateIfNoMatchOrNull
import com.speechify.client.internal.util.diagnostics.enriching.addTagProperties
import com.speechify.client.internal.util.extensions.collections.flows.firstValueFallbackOnTimeout
import com.speechify.client.internal.util.extensions.coroutines.createChildSupervisorScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlin.coroutines.cancellation.CancellationException
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.time.Duration.Companion.seconds

@JsExport
interface VoiceFactory {
    fun createVoice(
        spec: VoiceSpecOfAvailableVoice,
        speechSynthesisConfig: SpeechSynthesisConfig,
        callback: Callback<Voice?>,
    )
}

@JsExport
interface SpeechSynthesisConfig :
    TextToSpeechAudioCacheCapacityOption, TextToSpeechAudioContextInclusionOption {
    val audioConfig: AudioConfig
}

internal suspend fun VoiceFactory.createVoice(
    spec: VoiceSpecOfAvailableVoice,
    speechSynthesisConfig: SpeechSynthesisConfig,
): Result<Voice?> =
    suspendCoroutine { cont ->
        createVoice(
            spec = spec,
            speechSynthesisConfig = speechSynthesisConfig,
            callback = cont::resume,
        )
    }

internal fun VoiceFactory.toVoiceFactoryFromSpec(
    speechSynthesisConfig: SpeechSynthesisConfig,
): VoiceFactoryFromSpec =
    object : VoiceFactoryFromSpec {
        override suspend fun createVoice(spec: VoiceSpecOfAvailableVoice): Voice =
            this@toVoiceFactoryFromSpec.createVoice(
                spec = spec,
                speechSynthesisConfig = speechSynthesisConfig,
            )
                .orThrow()
                ?: throw IllegalStateException(
                    "VoiceFactory returned null for voice spec: $spec without providing an error",
                )
    }

/**
 * Abstracts away the [AudioConfig] from uses where it never changes.
 */
internal interface VoiceFactoryFromSpec {
    suspend fun createVoice(
        spec: VoiceSpecOfAvailableVoice,
    ): Voice
}

internal interface VoicesOfPreferenceStateProvider {
    suspend fun getPreferredVoiceStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice>

    suspend fun getPreferredVoiceForOfflineStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice>
}

/**
 * Provides the voice that should be used for content where voice is not specified, and the user's preference should be
 * used instead.
 */
internal interface VoiceOfPreferenceProvider {
    suspend fun getPreferredVoice(): VoiceSpecOfAvailableVoice
}

/**
 * Represents user's preference for offline voice.
 */
internal interface VoiceOfPreferenceForOfflineProvider {
    suspend fun getPreferredOfflineVoice(): Voice
}

internal fun VoiceOfPreferenceProvider.toVoiceOfPreferenceProviderWithCacheForCurrentVoice(
    voiceFactoryFromSpec: VoiceFactoryFromSpec,
): VoiceOfPreferenceProviderWithCache =
    object : VoiceOfPreferenceProviderWithCache {
        private val cachedVoice = AtomicRef<Voice?>(null)
        override suspend fun getPreferredVoiceWithCache(): Voice {
            val currentPreferredVoice = this@toVoiceOfPreferenceProviderWithCacheForCurrentVoice.getPreferredVoice()
            return cachedVoice.getOrUpdateIfNoMatchOrNull(
                isMatch = { it.voiceSpec.idQualified == currentPreferredVoice.idQualified },
                getNewValue = { _ ->
                    voiceFactoryFromSpec.createVoice(
                        spec = currentPreferredVoice,
                    )
                },
            )
        }
    }

/**
 * Note that the [Voice] object includes a cache.
 */
interface VoiceOfPreferenceProviderWithCache {
    suspend fun getPreferredVoiceWithCache(): Voice
}

internal class VoicesOfPreferenceStateProviderFromConfig(
    private val voicePreferences: VoicePreferences,
    private val allVoicesForNoPreference: List<VoiceSpecOfAvailableVoice>,
    private val voicePremiumAvailabilityProvider: VoicePremiumAvailabilityProvider,
    private val voiceSpecAvailabilityProvider: VoiceSpecAvailabilityProvider,
) : VoicesOfPreferenceStateProvider {
    internal constructor(
        voicePreferences: VoicePreferences,
        allVoicesForNoPreference: List<VoiceSpecOfAvailableVoice>,
        subscriptionService: SubscriptionService,
        voiceSpecAvailabilityProvider: VoiceSpecAvailabilityProvider,
    ) : this(
        voicePreferences = voicePreferences,
        allVoicesForNoPreference = allVoicesForNoPreference,
        voicePremiumAvailabilityProvider = VoicePremiumAvailabilityProviderFromEntitlements(
            subscriptionService = subscriptionService,
        ),
        voiceSpecAvailabilityProvider = voiceSpecAvailabilityProvider,
    )

    @ExperimentalCoroutinesApi
    override suspend fun getPreferredVoiceStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice> {
        var childScopeForVoicePreference: CoroutineScope? = null
        return voicePremiumAvailabilityProvider.isPremiumVoiceAvailableFlow.flatMapLatest { hasPremium ->
            val voicePreference = if (hasPremium) {
                voicePreferences.defaultPremiumVoice
            } else {
                voicePreferences.defaultFreeOfflineVoice
            }
            val sourceAreaId = "VoiceOfPreferenceProviderFromConfig.getPreferredVoiceStateIn"
            return@flatMapLatest voicePreference.stateIn(
                sourceAreaId = sourceAreaId,
                getFallback = { allVoices ->
                    Log.w(
                        DiagnosticEvent(
                            message = "No `defaultOfflineVoice` was specified - falling back to " +
                                "`ListeningBundlerConfig.allVoices`",
                            sourceAreaId = sourceAreaId,
                            properties = mapOf(
                                "hasPremium" to hasPremium.toString(),
                            ),
                        ),
                    )

                    if (hasPremium) {
                        allVoices.firstOrNull { it.isPremium }
                    } else {
                        allVoices.firstOrNull { !it.isPremium }
                    }
                },
                scope = scope.createChildSupervisorScope().also {
                    childScopeForVoicePreference?.cancel()
                    childScopeForVoicePreference = it
                },
            )
        }.stateIn(scope = scope)
    }

    override suspend fun getPreferredVoiceForOfflineStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice> {
        val sourceAreaId = "VoiceOfPreferenceProviderFromConfig.getPreferredVoiceForOfflineStateIn"
        return voicePreferences
            .defaultOfflineVoice
            .stateIn(
                sourceAreaId = sourceAreaId,
                getFallback = { allVoices ->
                    Log.w(
                        DiagnosticEvent(
                            message = "No `defaultOfflineVoice` was specified - falling back to " +
                                "`ListeningBundlerConfig.allVoices`",
                            sourceAreaId = sourceAreaId,
                        ),
                    )

                    allVoices.firstOrNull { it is VoiceSpec.LocalAvailable }
                },
                scope = scope,
            )
    }

    private suspend fun VoicePreference.stateIn(
        sourceAreaId: String,
        getFallback: suspend (allVoices: List<VoiceSpecOfAvailableVoice>) -> VoiceSpecOfAvailableVoice?,
        scope: CoroutineScope,
    ) =
        shareIn(
            started = SharingStarted.Eagerly,
            sourceAreaId = sourceAreaId,
            scope = scope,
        )
            .map {
                it?.let { voiceSpec ->
                    voiceSpecAvailabilityProvider.getSpecOfAvailableVoiceOrNull(voiceSpec)
                }
            }
            .map { preferenceFromVoicePreferences ->
                preferenceFromVoicePreferences
                    ?: getFallback(allVoicesForNoPreference)
                    // If we can't fallback to a voice, just crash
                    ?: throw IllegalStateException(
                        /* message = */ "Default voices not available, and no fallback voices available either",
                    )
                        .apply {
                            addTagProperties(
                                sourceAreaId,
                            )
                        }
            }
            .stateIn(
                scope = scope,
            )
}

internal class VoicesOfPreferenceStateProviderFromStaticValue(
    voiceSpec: VoiceSpecOfAvailableVoice,
) : VoicesOfPreferenceStateProvider {
    private val voiceStateFlow =
        MutableStateFlow(voiceSpec)

    override suspend fun getPreferredVoiceStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice> =
        voiceStateFlow

    override suspend fun getPreferredVoiceForOfflineStateIn(
        scope: CoroutineScope,
    ): StateFlow<VoiceSpecOfAvailableVoice> =
        voiceStateFlow
}

internal interface VoicePremiumAvailabilityProvider {
    val isPremiumVoiceAvailableFlow: Flow<Boolean>
}

internal class VoicePremiumAvailabilityProviderFromEntitlements(
    subscriptionService: SubscriptionService,
) : VoicePremiumAvailabilityProvider {

    override val isPremiumVoiceAvailableFlow: Flow<Boolean> =
        /**
         * Mapping [SubscriptionService.getEntitlementsFlowOfResults] instead of `SubscriptionService.getEntitlementsFlow`
         * Because we want the flow alive even when errors occur.
         */
        subscriptionService.getEntitlementsFlowOfResults()
            .map {
                try {
                    it.orThrow()?.isPremium == true
                } catch (e: CancellationException) {
                    /**
                     * Cancellation means that the entire call was cancelled (it doesn't include the timeout)
                     * so no point in trying to proceed.
                     */
                    throw e
                } catch (e: Throwable) {
                    Log.e(
                        message = "Fetching entitlements in VoicePremiumAvailabilityProviderFromEntitlements failed " +
                            "so falling back to non premium.",
                        exception = e,
                        sourceAreaId = "VoiceFactory.isPremiumVoiceAvailableFlow",
                    )
                    false
                }
            }
            .firstValueFallbackOnTimeout(
                timeout = 10.seconds,
                getFallbackItem = {
                    Log.e(
                        message = "Fetching entitlements in VoicePremiumAvailabilityProviderFromEntitlements " +
                            "timed out so falling back to non premium.",
                        sourceAreaId = "VoiceFactory.isPremiumVoiceAvailableFlow",
                    )
                    false
                },
            )
}
