package com.speechify.client.internal.sync

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.single
import kotlinx.coroutines.withTimeoutOrNull
import kotlin.time.Duration

/**
 * Can be used to await a single change of value.
 *
 * Prevents leaking memory that can happen when using [MutableStateFlow] with a plain [MutableStateFlow.collect], as
 * the function never finishes the suspension, holding to the coroutine. [LatchFlow.collect] finishes the suspension as
 * soon as the value changes, and after that every call to it will immediately return the second value.
 *
 * NOTE: [collect] will only ever be called once, so there is no benefit in using [kotlinx.coroutines.flow.collectLatest]
 */
internal class LatchFlow<Value>(
    private val initialValue: Value,
    private val valueAlreadySetHandler: (unsuccessfulValue: Value) -> Unit = { throw ValueAlreadyChangedError(it) },
) : Flow<Value> {

    var value: Value
        get() = mutableStateFlow.value
        set(value) {
            if (!mutableStateFlow.compareAndSet(expect = initialValue, update = value)) {
                valueAlreadySetHandler(value)
            }
        }

    /**
     * A convenience over [collect] that reduces nesting, where flow usage is not needed and there is benefit from reduced nesting.
     */
    suspend fun getChangedValue(): Value =
        this.single()

    /**
     *
     * NOTE: [collect] will only ever be called once, so there is no benefit in using [kotlinx.coroutines.flow.collectLatest]
     */
    override suspend fun collect(collector: FlowCollector<Value>) =
        collector.emit(
            mutableStateFlow.first {
                it != initialValue /* The check using [equals] is deliberate as [mutableStateFlow.compareAndSet] uses it
                     as well ([_"This function use a regular comparison using Any.equals"_](https://kotlinlang.org/api/kotlinx.coroutines/kotlinx-coroutines-core/kotlinx.coroutines.flow/-mutable-state-flow/compare-and-set.html))
                    */
            },
        )

    class ValueAlreadyChangedError(val unsuccessfulValue: Any?) : Error("Value of the latch has already changed")

    private val mutableStateFlow = MutableStateFlow(initialValue)
}

internal suspend fun <Value> LatchFlow<Value>.getChangedValueWithTimeout(millis: Long) =
    withTimeoutOrNull(millis) { getChangedValue() } ?: value

internal suspend fun <Value> LatchFlow<Value>.getChangedValueWithTimeout(duration: Duration) =
    withTimeoutOrNull(timeout = duration) { getChangedValue() } ?: value
