// ktlint-disable filename
package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.use
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

/**
 * Extension for flows of [Destructible] items that allows to apply a _try-with-resources_ to each consumption.
 *
 * @param suppressExceptionFromDestroyEvenOnSuccess - When true, an exception (default is `false`, as per all other
 * languages' try-with-resources solutions).
 */
internal fun <T : Destructible> Flow<T>.useAndDestroyAfterEachCollect(
    suppressExceptionFromDestroyEvenOnSuccess: Boolean = false,
) = flow {
    this@useAndDestroyAfterEachCollect.collect {
        it.use(
            suppressExceptionFromDestroyEvenOnSuccess = suppressExceptionFromDestroyEvenOnSuccess,
        ) { item ->
            emit(item)
        }
    }
}
