package com.speechify.client.api.adapters.localsynthesis

import kotlin.js.JsExport

@JsExport
data class LocalSynthesisUtterance(
    val voice: LocalSynthesisVoice,
    val text: String,
    val options: LocalSynthesisOptions,
)
