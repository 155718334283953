package com.speechify.client.api.util.io

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.MimeType
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.file.models.InMemoryFile
import com.speechify.client.internal.util.www.asDataUrl
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

/**
 * The file has historically been the general-purpose binary data abstraction that is now better
 * represented by its base - the [BinaryContentWithMimeTypeReadableRandomlyMultiplatformAPI], which allows to carry
 * native components, with most efficient method of reading for the platform.
 *
 * SDK will be gradually updated to use [BinaryContentWithMimeTypeReadableRandomlyMultiplatformAPI] instead of [File],
 * and the SDK API expects a [BinaryContentWithMimeTypeReadableRandomlyMultiplatformAPI] and it's possible to obtain it
 * for a given content, it should be used instead.
 */
@JsExport
abstract class File : BinaryContentWithMimeTypeReadableRandomlyMultiplatformAPI() {
    /**
     * NOTE: May contain the full MIME-type (e.g. "text/html; charset=utf-8") or just the [MimeType.typeSubtype]
     * (e.g. "text/html").
     *
     * NOTE: This member should be used only to implement the property. For reading, prefer [mimeType] instead, for
     * an unambiguous intent in regard to [MimeType.parameter].
     */
    abstract val contentType: String

    /**
     * The member that should be used to read the MIME-type information to avoid leaving corner cases when
     * [contentType] contains [MimeType.parameter] and the code doesn't expect that.
     */
    override val mimeType: MimeType
        get() = MimeType(contentType)

    /**
     * A url that points to the file, this can be any kind of url with any kind of protocol
     * - http://
     * - data://
     * - file://
     * - etc
     */
    open fun getUrl(callback: Callback<String>) = callback.fromCo {
        val bytes = coGetAllBytes().orReturn { return@fromCo it }

        return@fromCo bytes.asDataUrl(
            mediaType = mimeType.fullString,
        ).successfully()
    }
}

/**
 * To remove any confusion that this should be in-memory only and not backed up by a file on disk
 */
internal typealias InMemoryByteArrayBinaryContent = File

internal suspend fun File.coGetUrl(): Result<String> = suspendCoroutine { cont ->
    getUrl(cont::resume)
}

internal fun File.withContentType(contentType: String): File {
    return object : File() {
        override val contentType: String = contentType
        override val mimeType: MimeType get() = MimeType(contentType)
        override fun getSizeInBytes(callback: Callback<Int>) = this@withContentType.getSizeInBytes(callback)

        override fun getBytes(startIndex: Int, endIndex: Int, callback: Callback<ByteArray>) =
            this@withContentType.getBytes(startIndex, endIndex, callback)
    }
}

@JsExport
open class FileFromString(
    contentType: String,
    stringValue: String,
) : InMemoryFile(
    contentType = contentType,
    bytes = stringValue.encodeToByteArray(),
)

/*
 TODO - remove the this class and its usages by making [HTMLParser] accept [TextReader] rather than a [File] as
   this is pretty expensive (encoding here only so that HTMLParser decodes it into a string again). After that's
   done, there should be only one usage of this class in the importer, and then it can be replaced by just
    [FileFromString], or [FileFromTextReader] (if implemented).
 */
internal class HtmlFileFromString(
    htmlString: String,
) : FileFromString(
    "text/html",
    htmlString,
)
