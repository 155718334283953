package com.speechify.client.internal.services.adoption

import com.speechify.client.api.adapters.firebase.FirebaseFirestoreDocumentSnapshot
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.coGetDocument
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.adoption.models.UserUsageData
import com.speechify.client.api.services.adoption.models.toBoundaryMap
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.orDefaultWith
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.services.adoption.models.FirebaseUserUsageData

internal const val USER_USAGE_DATA_COLLECTION = "userUsageData"

internal class EcosystemAdoptionDataFetcher(
    private val firebaseFirestoreService: FirebaseFirestoreService,
) {
    companion object {
        const val TAG = "EcosystemAdoptionDataFetcher"
    }

    suspend fun updateUserUsageData(userId: String, userUsageData: UserUsageData): Result<Unit> {
        firebaseFirestoreService.coSetDocument(USER_USAGE_DATA_COLLECTION, userId, userUsageData.toBoundaryMap())
            .orReturn { return it }
        return Unit.successfully()
    }

    suspend fun getUserUsageData(userId: String): Result<FirebaseUserUsageData> {
        val docResult = firebaseFirestoreService.coGetDocument(USER_USAGE_DATA_COLLECTION, userId)
            .orDefaultWith { FirebaseUserUsageData() }

        val parsedDoc = when (docResult) {
            is FirebaseFirestoreDocumentSnapshot.Exists -> {
                try {
                    docResult.value()
                } catch (ex: Exception) {
                    Log.d(TAG, ex, "EcosystemAdoptionDataFetcher.getUserUsageData")
                    Result.Success(FirebaseUserUsageData())
                }
            }
            is FirebaseFirestoreDocumentSnapshot.NotExists -> Result.Success(FirebaseUserUsageData())
            else -> Result.Success(FirebaseUserUsageData())
        }

        return parsedDoc
    }
}
