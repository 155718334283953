package com.speechify.client.api.audio.caching

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOne
import com.benasher44.uuid.uuid4
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.audio.AudioMediaFormat
import com.speechify.client.api.audio.SpeechMarksImpl
import com.speechify.client.api.audio.SynthesizeResponse
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.content.JoinedTextWithMap
import com.speechify.client.api.content.ValueWithStringRepresentation
import com.speechify.client.api.services.audio.toVoiceParams
import com.speechify.client.internal.services.db.DbService
import com.speechify.client.internal.util.extensions.intentSyntax.nullIfEmpty
import com.speechify.client.internal.util.www.asDataUrl
import com.speechify.client.internal.util.www.dataUrlToBinaryContent
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.map

/**
 * A caching implementation that stores the synthesis results in our SQLite database.
 */
internal class MediaSynthesisCachePersistent<
    JoinedSentencesType : ValueWithStringRepresentation,
    SentenceType : ValueWithStringRepresentation,
    >(
    private val speechifyURI: SpeechifyURI,
    private val voiceSpec: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer,
    private val dbService: DbService,
) : MediaSynthesisCache<JoinedSentencesType, SentenceType>() {

    override suspend fun getEntriesContainingSentence(sentenceText: String): Flow<CacheEntry>? =
        dbService.getVoiceCacheQueries().findUtterancesWithSentence(
            documentUri = speechifyURI,
            voiceId = voiceSpec.getIdForDb(),
            sentenceText = sentenceText,
        ).awaitAsList()
            .nullIfEmpty()
            ?.asFlow()
            ?.map { synthesisResultId ->
                dbService.getVoiceCacheQueries().getSynthesisResult(
                    synthesisResultId = synthesisResultId,
                ).awaitAsOne()
                    .let { cacheEntry ->
                        object : CacheEntry {
                            override val joinedText: String get() =
                                cacheEntry.synthesisMetadata.joinedText

                            override suspend fun getSynthesisResult(): SynthesizeResponse =
                                SynthesizeResponse(
                                    format = cacheEntry.synthesisMetadata.format,
                                    mediaUrl = dbService
                                        .getVoiceCacheQueries()
                                        .getAudioData(synthesisResultId)
                                        .awaitAsOne()
                                        .asDataUrl("audio/${cacheEntry.synthesisMetadata.format}"),
                                    speechMarks = cacheEntry.synthesisMetadata.speechMarks,
                                )
                        }
                    }
            }

    override suspend fun putCachedEntry(
        inputSentencesAndWhitespaceSeparators: JoinedTextWithMap<JoinedSentencesType, SentenceType>,
        newSynthesisResult: SynthesizeResponse,
    ) {
        val voiceCacheQueries = dbService.getVoiceCacheQueries()
        voiceCacheQueries.transaction {
            val synthesisResultUUID = uuid4().toString()
            val csr = CachedSynthesisResponse(
                inputSentencesAndWhitespaceSeparators.joinedText.textRepresentation,
                inputSentencesAndWhitespaceSeparators.mapOfConstituentPartsToJoinedText.map {
                    it.constituentPart.textRepresentation
                },
                inputSentencesAndWhitespaceSeparators.mapOfConstituentPartsToJoinedText.map {
                    it.rangeInWhole.first to it.rangeInWhole.last
                },
                newSynthesisResult.speechMarks as SpeechMarksImpl,
                newSynthesisResult.format,
            )
            voiceCacheQueries.insertSynthesisResult(
                synthesisResultUUID = synthesisResultUUID,
                voiceId = voiceSpec.getIdForDb(),
                audioData = dataUrlToBinaryContent(newSynthesisResult.mediaUrl).bytes,
                synthesisMetadata = csr,
            )

            // Slight workaround since we can't use a returning clause on the insert above.
            // The alternative would be to only use the UUID, but that would greatly increase storage requirements of
            // the sentence index table.
            val synthesisResultId = voiceCacheQueries.getSynthesisResultId(synthesisResultUUID).awaitAsOne()

            for ((index, sentence) in inputSentencesAndWhitespaceSeparators.constituentParts.withIndex()) {
                voiceCacheQueries.insertSentenceIndex(
                    documentUri = speechifyURI,
                    voiceId = voiceSpec.getIdForDb(),
                    sentenceText = sentence.textRepresentation,
                    synthesisResultId = synthesisResultId,
                    utteranceSentencesIndexOfThisSentence = index.toLong(),
                    utteranceSentencesTotalCount = inputSentencesAndWhitespaceSeparators.constituentParts.size.toLong(),
                )
            }
        }
    }
}

@kotlinx.serialization.Serializable
data class CachedSynthesisResponse(
    val joinedText: String,
    val constituentParts: List<String>,
    val constituentRanges: List<Pair<Int, Int>>,
    val speechMarks: SpeechMarksImpl,
    val format: AudioMediaFormat,
)

internal fun VoiceSpec.VoiceSpecForMediaVoiceFromAudioServer.getIdForDb(): VoiceIdForDb =
    this.toVoiceParams()
        .run {
            "$engine-$languageCode-$name"
        }

internal typealias VoiceIdForDb = String
