package com.speechify.client.bundlers.reading.classic

import com.speechify.client.api.telemetry.TelemetryEventBuilder
import com.speechify.client.bundlers.reading.BundleMetadata
import com.speechify.client.bundlers.reading.ReadingBundle
import kotlin.js.JsExport

/**
 * Everything you need to deliver a Classic Mode reading experience.
 */
@JsExport
class ClassicReadingBundle internal constructor(
    dependencies: Dependencies,
    bundlingSourceTelemetryEventBuilder: TelemetryEventBuilder?,
    bundleMetadata: BundleMetadata?,
    predecessorBundle: ReadingBundle? = null,
    // TODO(anson): add all the other Classic Mode ViewModels here
) : ReadingBundle(
    dependencies = dependencies,
    bundlingSourceTelemetryEventBuilder = bundlingSourceTelemetryEventBuilder,
    bundleMetadata = bundleMetadata,
    predecessorBundle = predecessorBundle,
)
