package com.speechify.client.internal.coroutines.fromNonCancellableAPIs

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.toKotlinResult
import kotlinx.coroutines.CancellableContinuation
import kotlinx.coroutines.CompletionHandler
import kotlinx.coroutines.suspendCancellableCoroutine

/**
 * A function for converting a one-shot-callback API that is SDK-result-based to idiomatic Kotlin `suspend` code
 * (not using the `Result, so throwing when the callback returns a `Result.Failure`).
 *
 * Note - this function requires a cancellation API. When there's no cancellable API use:
 * - [suspendCoroutineWithSDKResultThrowing] if freezing is acceptable.
 * - use one of `suspendCancellableCoroutineForNonCancellableAPI*` functions
 *   (but note that these functions leave the code running after cancellation)
 */
internal suspend fun <T> suspendCancellableCoroutineWithSDKResultThrowing(
    /**
     * You can pass ::someFunction directly if it takes just one parameter of [Callback]. Otherwise, open up a lambda
     * and pass it there.
     */
    block: (CancellableContinuationForResult<T>) -> Unit,
) =
    suspendCancellableCoroutine { kotlinContinuation ->
        block(
            object : CancellableContinuationForResult<T> {
                override fun resume(sdkResult: Result<T>) =
                    kotlinContinuation.resumeWith(
                        sdkResult.toKotlinResult(),
                    )

                override fun invokeOnCancellation(handler: CompletionHandler) =
                    kotlinContinuation.invokeOnCancellation(handler)
            },
        )
    }

/**
 * An SDK-Result version of [CancellableContinuation].
 */
internal interface CancellableContinuationForResult<T> {
    fun resume(sdkResult: Result<T>)
    fun invokeOnCancellation(handler: CompletionHandler)
}
