package com.speechify.client.reader.core

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.util.Destructible
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Listener
import com.speechify.client.api.util.multiShotFromFlowIn
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import kotlin.js.JsExport

@JsExport
/**
 * The [Helper] is the core building block of a [Reader], exposing observable state and imperative control APIs for
 * each feature of the experience via a straightforward and uniform API -
 * 1. Every helper exposes its state to listeners via [Helper.addStateChangeListener]
 * 2. Every helper exposes imperative control APIs via synchronous methods with no return value - see [PlaybackHelper.skipForwards] for example.
 */
abstract class Helper<T> internal constructor(scope: CoroutineScope) : Destructible {

    internal val scope = scope.createChildHelperScope()
    internal abstract val stateFlow: StateFlow<T>
    abstract val initialState: T

    /**
     * exposed the current state of the [stateFlow]
     */
    val state: T get() = stateFlow.value

    internal fun createChildHelperScope(): CoroutineScope {
        return scope.createChildHelperScope()
    }

    fun addStateChangeListener(listener: Listener<T>): Destructor =
        listener.multiShotFromFlowIn(scope = scope, flow = stateFlow.map { it })::destroy

    internal open val commands = scope.coroutineContext[ReaderCommands]!!.commands

    /**
     * Dispatches an arbitrary value as a "command" to be processed asynchronously by Command Collectors that have been
     * activated.
     *
     * Helpers register listeners for these commands, matching using `is` to identify the commands they are interested
     * in acting upon.
     *
     * DesignRationale: We use open set of commands with Any and `is`-matching to avoid the excessive boilerplate
     * that would be involved in providing strict typing as commands are plumbed through the whole system. The fact
     * that consumers use `is` matching gives us the safety we need for the time being.
     */
    internal val dispatch: CommandDispatch = {
        Log.d("DISPATCH(${this::class.simpleName}): ${it::class.simpleName}", sourceAreaId = "Helper.dispatch")
        scope.coroutineContext[ReaderCommands]!!.dispatch(it)
    }

    /**
     * we are using SharingStarted.Eagerly here as we are exposing the current [state] of helper even in case when no
     * subscriber is there for the [stateFlow]
     */
    internal fun <R> Flow<R>.stateInHelper(
        initialValue: R,
    ): StateFlow<R> {
        return this.stateIn(scope = this@Helper.scope, started = SharingStarted.Eagerly, initialValue)
    }

    internal fun Flow<*>.launchInHelper() {
        this.launchIn(scope)
    }

    internal fun launchInHelper(block: suspend CoroutineScope.() -> Unit) {
        scope.launch(block = block)
    }

    override fun destroy() {
        scope.cancel()
    }
}
