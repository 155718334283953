package com.speechify.client.test

import com.speechify.client.api.content.editing.EditingBookView
import com.speechify.client.api.content.pdf.PDFBookView
import com.speechify.client.bundlers.reading.book.BookReadingBundle
import com.speechify.client.reader.core.ListeningExperience
import kotlin.js.JsExport

/**
 * This is a Validator class to help the platform teams to test the breaking changes. This is helpful
 * in case if platform has not integrated the feature using the breaking change or there is no way for client
 * to validate the changes.
 *
 * This is just a Manager or entry point exposing various testSuits [BreakingChangeTestSuit] ideally one for each
 * breaking change.
 *
 */
@JsExport
object BreakingChangeValidator {

    /**
     * test suit for search in pdf document
     */
    fun pdfSearchTestSuit(listeningExperience: ListeningExperience): PdfSearchTestSuit {
        check(listeningExperience.bundle is BookReadingBundle) { "Test suit can not run from non-book Bundle" }
        val bookView = listeningExperience.bundle.bookContent.bookView
        return if (bookView is PDFBookView) {
            PdfSearchTestSuit(bookView)
        } else if ((bookView is EditingBookView && bookView.originalBookView is PDFBookView)) {
            PdfSearchTestSuit(bookView.originalBookView)
        } else {
            throw IllegalStateException("Test suit can not run on non pdf content")
        }
    }
}
