package com.speechify.client.api.adapters

import com.speechify.client.api.adapters.archiveFiles.ArchiveFilesAdapter
import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.blobstorage.traced
import com.speechify.client.api.adapters.db.AbstractSqlDriverFactory
import com.speechify.client.api.adapters.encription.EncryptionAdapter
import com.speechify.client.api.adapters.events.EventsTrackerAdapter
import com.speechify.client.api.adapters.firebase.FirebaseService
import com.speechify.client.api.adapters.firebase.traced
import com.speechify.client.api.adapters.html.HTMLParser
import com.speechify.client.api.adapters.http.BrowserIdentityUserAgentProvider
import com.speechify.client.api.adapters.http.HttpClientAdapter
import com.speechify.client.api.adapters.http.traced
import com.speechify.client.api.adapters.imageConversion.ImageConverter
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.adapters.keyvalue.traced
import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.traced
import com.speechify.client.api.adapters.mediaplayer.LocalMediaPlayerAdapter
import com.speechify.client.api.adapters.mediaplayer.traced
import com.speechify.client.api.adapters.ocr.OCRAdapter
import com.speechify.client.api.adapters.offlineMode.OfflineModeStatusProvider
import com.speechify.client.api.adapters.pdf.PDFAdapterFactory
import com.speechify.client.api.adapters.pdf.traced
import com.speechify.client.api.adapters.xml.XMLParser
import com.speechify.client.api.diagnostics.Log
import kotlin.js.JsExport

/**
 * Provides adapters that abstract environment-specific details, usually I/O.
 */
@JsExport
interface AdapterFactory {
    fun getFirebaseService(): FirebaseService

    fun getHttpClient(): HttpClientAdapter

    fun getLocalKeyValueStorage(): LocalKeyValueStorageAdapter

    fun getBlobStorage(): BlobStorageAdapter

    fun getLocalSpeechSynthesis(): LocalSpeechSynthesisAdapter

    fun getLocalMediaPlayer(): LocalMediaPlayerAdapter

    fun getPDFAdapterFactory(): PDFAdapterFactory

    fun getOcrAdapter(): OCRAdapter

    fun getHTMLParser(): HTMLParser

    fun getImageConverter(): ImageConverter

    @Suppress("NON_EXPORTABLE_TYPE") // The actual type is exported.
    fun getBrowserIdentityUserAgentProvider(): BrowserIdentityUserAgentProvider

    fun getArchiveFilesAdapter(): ArchiveFilesAdapter

    fun getXMLParser(): XMLParser

    /**
     * `null` can be returned when the SDK consumer does not use any SDK functionality that requires a DB.
     */
    fun getSqlDriverFactory(): AbstractSqlDriverFactory?

    fun getOfflineModeStatusProvider(): OfflineModeStatusProvider

    fun getEncryptionAdapter(): EncryptionAdapter

    fun getEventsTrackerAdapter(): EventsTrackerAdapter

    fun getWebViewAdapter(): WebViewAdapter
}

internal fun AdapterFactory.traced() = if (Log.isDebugLoggingEnabled) AdapterFactoryTraced(this) else this

internal class AdapterFactoryTraced(private val adapterFactory: AdapterFactory) : AdapterFactory {
    override fun getFirebaseService(): FirebaseService = adapterFactory.getFirebaseService().traced()

    override fun getHttpClient(): HttpClientAdapter = adapterFactory.getHttpClient().traced()

    override fun getLocalKeyValueStorage(): LocalKeyValueStorageAdapter =
        adapterFactory.getLocalKeyValueStorage().traced()

    override fun getBlobStorage(): BlobStorageAdapter =
        adapterFactory.getBlobStorage().traced()

    override fun getLocalSpeechSynthesis(): LocalSpeechSynthesisAdapter =
        adapterFactory.getLocalSpeechSynthesis().traced()

    override fun getLocalMediaPlayer(): LocalMediaPlayerAdapter =
        adapterFactory.getLocalMediaPlayer().traced()

    override fun getPDFAdapterFactory(): PDFAdapterFactory = adapterFactory.getPDFAdapterFactory().traced()

    override fun getOcrAdapter(): OCRAdapter = adapterFactory.getOcrAdapter() /* No `traced()` here because tracing is
     added to [OCRAdapter] because it uses inheritance for adding functionality while controlling visibility (this is
      also how we should probably do it elsewhere, to minimize the number of layers/classes to understand) */

    /** no 'tracing'
     * (AKA debug logs on every call) as this doesn't seem very useful to do for absolutely everything, and is spammy.
     */
    override fun getHTMLParser(): HTMLParser = adapterFactory.getHTMLParser()
    override fun getImageConverter(): ImageConverter = adapterFactory.getImageConverter()

    override fun getBrowserIdentityUserAgentProvider(): BrowserIdentityUserAgentProvider =
        adapterFactory.getBrowserIdentityUserAgentProvider()

    override fun getArchiveFilesAdapter(): ArchiveFilesAdapter = adapterFactory.getArchiveFilesAdapter()

    override fun getXMLParser(): XMLParser = adapterFactory.getXMLParser()

    override fun getSqlDriverFactory(): AbstractSqlDriverFactory? =
        adapterFactory.getSqlDriverFactory()

    override fun getOfflineModeStatusProvider(): OfflineModeStatusProvider =
        adapterFactory.getOfflineModeStatusProvider()

    override fun getEncryptionAdapter(): EncryptionAdapter =
        adapterFactory.getEncryptionAdapter()

    override fun getEventsTrackerAdapter(): EventsTrackerAdapter =
        adapterFactory.getEventsTrackerAdapter()

    override fun getWebViewAdapter(): WebViewAdapter =
        adapterFactory.getWebViewAdapter()
}
