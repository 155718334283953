package com.speechify.client.api.services.adoption.models

import com.speechify.client.api.AppEnvironment
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.boundary.SdkBoundaryMap
import kotlin.js.JsExport

@JsExport
data class UserUsageData(
    var android: UserUsageDataEntry? = null,
    var ios: UserUsageDataEntry? = null,
    var webApp: UserUsageDataEntry? = null,
    var chromeExtension: UserUsageDataEntry? = null,
    var safariExtension: UserUsageDataEntry? = null,
    var macApp: UserUsageDataEntry? = null,
) {
    /**
     * Sets the new version to the platform entry if new
     * @param platform
     * @param version
     */
    internal fun setForPlatform(platform: AppEnvironment, version: String) {
        when (platform) {
            AppEnvironment.ANDROID -> {
                this.android = setToEntryIfNew(this.android, version)
            }
            AppEnvironment.IOS -> {
                this.ios = setToEntryIfNew(this.ios, version)
            }
            AppEnvironment.WEB_APP -> {
                this.webApp = setToEntryIfNew(this.webApp, version)
            }
            AppEnvironment.DESKTOP_EXTENSION -> {
                this.chromeExtension = setToEntryIfNew(this.chromeExtension, version)
            }
            AppEnvironment.SAFARI_EXTENSION -> {
                this.safariExtension = setToEntryIfNew(this.safariExtension, version)
            }
            AppEnvironment.MAC_APP -> {
                this.macApp = setToEntryIfNew(this.macApp, version)
            }
            // UNSUPPORTED
            AppEnvironment.PLATFORM_EXPORT_SERVICE -> {}
            AppEnvironment.SOUND_BITES -> {}
            AppEnvironment.LANDING_PAGE -> {}
            AppEnvironment.API -> {}
        }
    }

    /**
     * Sets the new version and last visit timestamp to the entry
     */
    private fun setToEntryIfNew(entry: UserUsageDataEntry?, version: String): UserUsageDataEntry {
        val nowTimestamp = DateTime.now()

        // Check if version is not same & last visit is newer
        if (entry == null ||
            entry.lastVersion != version ||
            DateTime.fromIsoString(entry.lastVisitISODate) < nowTimestamp
        ) {
            return UserUsageDataEntry(
                lastVersion = version,
                lastVisitISODate = nowTimestamp.toIsoString(),
            )
        }

        return entry
    }
}

fun UserUsageData.toBoundaryMap(): BoundaryMap<Any?> = SdkBoundaryMap.of(
    "android" to android?.toBoundaryMap(),
    "ios" to ios?.toBoundaryMap(),
    "extension" to chromeExtension?.toBoundaryMap(),
    "safari_extension" to safariExtension?.toBoundaryMap(),
    "web_app" to webApp?.toBoundaryMap(),
    "mac_app" to macApp?.toBoundaryMap(),
)
