package com.speechify.client.api.util

import com.benasher44.uuid.uuid4
import com.speechify.client.api.adapters.http.HttpResponse
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.library.models.LibraryItem
import com.speechify.client.api.telemetry.ErrorUuidTelemetryProp
import com.speechify.client.api.util.Result.Failure
import com.speechify.client.internal.util.collections.maps.BlockingThreadsafeMap
import com.speechify.client.internal.util.collections.maps.set
import com.speechify.client.internal.util.extensions.collections.putAndGetWasDifferent
import com.speechify.client.internal.util.extensions.intentSyntax.isOfTypeAnd
import com.speechify.client.internal.util.extensions.throwable.addCustomProperty
import com.speechify.client.internal.util.extensions.throwable.addValueToPropertyList
import com.speechify.client.internal.util.extensions.throwable.getOrSetErrorUUID
import com.speechify.client.internal.util.extensions.throwable.putCustomProperty
import com.speechify.client.internal.util.intentSyntax.ifInstanceOf
import com.speechify.client.internal.util.intentSyntax.ifNotNull
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.takeWhile
import kotlinx.serialization.SerializationException
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract
import kotlin.js.JsExport
import kotlin.jvm.JvmName
import kotlin.reflect.KClass

@JsExport
sealed class SDKError {

    /**
     * Can be used for preserving all information about some cause type that the SDK does not have reference to.
     * The value should be specified as [causeAsCustomValue] and the exact same value (by reference) will be retrievable
     * via [getCauseThatMatchesOrNull].
     *
     * NOTE: Consider using a [Throwable] (be it the one observed from some original routine, or, if not available,
     * a new one created in-place, just for the purpose of carrying the cause info) passed through a [OtherException],
     * which have stack-traces for identifying entry-points and can any information about original cause is captured.
     * See [getCauseThatMatchesOrNull] for more details.
     */
    data class SDKErrorWithCauseAsCustomValue(
        internal val causeAsCustomValue: Any,
        /**
         * Can be specified for logging purpose, especially if the [toString] of [causeAsCustomValue] doesn't
         * produce a string with any useful information.
         */
        val message: String? = null,
    ) : SDKError()

    /**
     * Can be used to attach custom properties to any error.
     */
    internal val customProperties: BlockingThreadsafeMap<String, Any> =
        BlockingThreadsafeMap()

    @Suppress("NON_EXPORTABLE_TYPE")
    data class Serialization(
        val exception: SerializationException,
        val source: Any,
        val targetType: KClass<*>,
    ) :
        SDKError()

    /**
     * Special error class that should be used in all places where requests failed due to a connection error.
     * This includes, timeouts, network errors, airplane mode, host name resolution, etc.
     */
    data class ConnectionError(val message: String) : SDKError()
    data class Authentication(val message: String) : SDKError()
    data class HttpError(val status: Short, val message: String, val response: HttpResponse? = null) : SDKError() {
        override fun toString(): String {
            return "HTTP Response status: $status; $message"
        }
    }

    data class IO(val message: String) : SDKError()

    /**
     * See also [NotAuthorized] as that one can also be used for non-existent resources by services which prevent
     * the discovery of the resource's existence.
     */
    data class ResourceNotFound(val identifier: Any, val message: String) : SDKError()

    /**
     * The client permissions are not sufficient to perform the operation. It could be a matter of
     * restrictions on the resource, or the resource not existing while the client has no permission to discover
     * the resource's existence (see [ResourceNotFound] that is used where there is no access control on this).
     */
    data class NotAuthorized(val identifier: Any, val message: String) : SDKError()

    data class ContentNotInListenableState(val content: LibraryItem.ListenableContent) : SDKError()

    class OtherException(val exception: Throwable) : SDKError() {
        override fun toString(): String =
            exception.stackTraceToString()
    }

    data class OtherMessage(val message: String) : SDKError() {
        override fun toString(): String {
            return message
        }
    }

    /**
     * Groups those [SDKError]s, which may be wrappers around some more detailed cause closer to the root which is
     * also a [SDKError].
     * NOTE: [OtherException] is another way of preserving the root causes, applicable when the error
     * info is in a [Throwable] form (the [Throwable.cause] takes that role there).
     */
    sealed class KnownErrorWithCauseAsSdkError : SDKError() {
        abstract val causeAsSDKError: SDKError
    }

    /**
     * An equivalent to [com.speechify.client.internal.util.extensions.throwable.asChainFromTopLevelToRootCause]
     * for where the cause is expressed as a [SDKError].
     */
    internal val asChainFromTopLevelToRootCause get(): Sequence<SDKError> = sequence {

        fun SDKError.getRecursive(): Sequence<SDKError> = sequence {
            yield(this@getRecursive)

            ifInstanceOf<KnownErrorWithCauseAsSdkError>(this@getRecursive) {
                yieldAll(it.causeAsSDKError.getRecursive())
            }
        }

        yieldAll(getRecursive())
    }

    /**
     * An error that occurred during the import processing of a Library Item
     */
    data class ImportProcessingError(
        /**
         * The ID of the Library Item that was being processed
         */
        val itemId: String,

        /**
         * The error that interrupted the import processing
         */
        val sdkError: SDKError,
    ) : KnownErrorWithCauseAsSdkError() {

        override val causeAsSDKError: SDKError get() =
            sdkError
    }

    /**
     * An error that occurred when trying to create a new subscription.
     */
    data class CreateSubscriptionError(
        /** A message describing what went wrong when creating the subscription. */
        val message: String,
        /**
         * A code to indicate what kind of error happened.
         */
        val code: CreateSubscriptionErrorCode,
        /** Obfuscated email of the user who currently has the subscription, only set if code is "NEEDS_TRANSFER" */
        val otherEmail: String? = null,
    ) : SDKError()

    /**
     * An error that is thrown when trying to create a subscription with the wrong currency.
     * Repeating the request and requesting the currency indicated in the payload should succeed.
     */
    data class WrongCurrencyError(
        /**
         * The correct currency to request.
         */
        val currencyISO: String,
        /**
         * The price of the subscription in the correct currency.
         */
        val priceCents: Int,
    ) : SDKError()

    data class InvalidBillingDataError(
        val errorCode: String,
        val originalError: SDKError,
    ) : SDKError()

    internal fun getOrSetErrorUUID(): String {
        return if (this is OtherException) {
            exception.getOrSetErrorUUID()
        } else {
            customProperties.getOrPut(
                key = ErrorUuidTelemetryProp.keyId,
                defaultValue = { uuid4().toString() },
            ) as String
        }
    }

    internal fun addValueToPropertyList(key: String, value: Any) {
        if (this is OtherException) {
            exception.addValueToPropertyList(key, value)
        } else {
            customProperties.update(
                key = key,
            ) { list ->
                when (list) {
                    null -> mutableListOf(value)
                    else -> {
                        @Suppress("UNCHECKED_CAST")
                        (list as MutableList<Any>)
                            .apply {
                                add(value)
                            }
                    }
                }
            }
        }
    }

    /**
     * Adds a new custom property. Replacing any value already set under the key.
     */
    internal fun putCustomProperty(key: String, value: Any) {
        if (this is OtherException) {
            exception.putCustomProperty(key, value)
        } else {
            customProperties[key] = value
        }
    }

    /**
     * Adds a new custom property.
     *
     * Not to lose any information, it follows semantics of [com.speechify.client.internal.util.collections.maps.MutableMapInsertionOrFailure.add],
     * that is, will log an error if the key is already used and not update the value
     * (like [com.speechify.client.internal.util.extensions.throwable.addCustomProperty] does).
     */
    internal fun addCustomProperty(key: String, value: Any) {
        if (this is OtherException) {
            exception.addCustomProperty(key, value)
        } else {
            if (customProperties.putAndGetWasDifferent(
                    key = key,
                    value = value,
                )
            ) {
                Log.e(
                    DiagnosticEvent(
                        message = "Tried to add custom property with same key but different value." +
                            "Use putCustomProperty sibling function if this is an intended change of this " +
                            "property's value. Otherwise, use a unique key.",
                        sourceAreaId = "SDKError.addCustomProperty",
                        properties = mapOf(
                            "key" to key,
                            "newValue" to value,
                        ),
                    ),
                )
            }
        }
    }

    /**
     * Translates this [SDKError] to the platform-language native, idiomatic exception object that can be directly
     * thrown, inspected for its type, inspected for its stacktrace, etc.
     * See also [com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics.nativeError].
     *
     * To check if the error has an associated [SDKError] value, test its type for [SDKErrorException], where the
     * [SDKError] can be accessed in [SDKErrorException.sdkError].
     */
    fun toNativeError(): Throwable =
        toException()

    /**
     * Answers whether an 'identified cause' matching the [predicate] was responsible for this error.
     * Returns `null` if it wasn't, returns the exact instance produced by the SDK-consumers which matched if it was.
     * This method offers more stable behavior over direct matching of error instances, as errors often get wrapped
     * by callers to add information or semantics to the error (e.g. in case of [Throwable]s, the [Throwable.cause] will
     * create a chain of exceptions, all the way to the root cause), to which this function is resilient.
     *
     * The 'identified causes' can be:
     * - Exceptions  (any subtype of [Throwable]s), which are either
     *    - thrown by SDK code from its methods (typically this will be documented in the API documentation)
     *    - or passed to SDK from extension points for SDK-consumers by using [SDKError.OtherException] in form of
     *      - an existing exception (the one observed from some original routine
     *      - or, if not available, a new one created in-place, just for the purpose of  carrying the cause info)
     *   Exceptions are the idiomatic mechanism for errors and chaining causes.
     *   Producing exceptions allows any logging to also log any stacktrace (to be able to identify the entry points)
     *   to complete the [Throwable.cause]s chain of any translating wrapper-exceptions, and any original
     *   information contained within them.
     * - One of the [SDKError] subtypes, which are the errors that SDK defined, if the cause is expressible in such.
     *   These can also be:
     *    - returned by SDK code from its methods (typically this will be documented in the API documentation)
     *    - or passed to SDK from extension points for SDK-consumers
     * - Any values, by passing them in a [SDKError.SDKErrorWithCauseAsCustomValue] (in its [SDKError.SDKErrorWithCauseAsCustomValue.causeAsCustomValue])
     */
    fun getCauseThatMatchesOrNull(
        predicate: (cause: Any) -> Boolean,
    ) =
        getChainOfCausesAsAnyValueFromTopLevelToRootCause()
            .firstOrNull(predicate)
}

/**
 * An equivalent of [SDKError.getCauseThatMatchesOrNull] for [Throwable]s.
 */
internal fun Throwable.getCauseThatMatchesOrNull(
    predicate: (cause: Any) -> Boolean,
) =
    getChainOfCausesAsAnyValueFromTopLevelToRootCause()
        .firstOrNull(predicate)

private fun SDKError.getChainOfCausesAsAnyValueFromTopLevelToRootCause(): Sequence<Any> =
    IterationNodeForTreeOfCauses.SDKErrorCause(
        this@getChainOfCausesAsAnyValueFromTopLevelToRootCause,
    ).getChainOfCausesAsAnyValueFromTopLevelToRootCause()

private fun Throwable.getChainOfCausesAsAnyValueFromTopLevelToRootCause(): Sequence<Any> =
    IterationNodeForTreeOfCauses.ExceptionCause(
        this@getChainOfCausesAsAnyValueFromTopLevelToRootCause,
    ).getChainOfCausesAsAnyValueFromTopLevelToRootCause()

private fun IterationNodeForTreeOfCauses<*>
.getChainOfCausesAsAnyValueFromTopLevelToRootCause(): Sequence<Any> = sequence {
    suspend fun SequenceScope<Any>.yieldRecursiveFromNode(
        node: IterationNodeForTreeOfCauses<*>,
    ) {
        yield(
            node.causeValue,
        )

        for (childNode in node.getChildrenNodes()) {
            yieldRecursiveFromNode(childNode)
        }
    }

    yieldRecursiveFromNode(
        node = this@getChainOfCausesAsAnyValueFromTopLevelToRootCause,
    )
}

/**
 * Encapsulates all the ways that the various error-idioms of SDK ([Throwable]s and [SDKError]s) can contain 'causes'
 * (the equivalent of the idiomatic [Throwable.cause]), and allows to traverse these as a tree.
 * (
 */
private sealed class IterationNodeForTreeOfCauses<out Value : Any>(
    /**
     * The value that can be considered a "cause" - value which the code can test against with [SDKError.getCauseThatMatchesOrNull],
     * to determine if it caused the particular error situation.
     */
    val causeValue: Value,
) {
    class ExceptionCause(
        value: Throwable,
    ) : IterationNodeForTreeOfCauses<Throwable>(
        causeValue = value,
    )

    class SDKErrorCause(
        value: SDKError,
    ) : IterationNodeForTreeOfCauses<SDKError>(
        causeValue = value,
    )

    class CustomErrorCause(
        value: Any,
    ) : IterationNodeForTreeOfCauses<Any>(
        causeValue = value,
    )

    fun getChildrenNodes(): Sequence<IterationNodeForTreeOfCauses<*>> = sequence {
        when (this@IterationNodeForTreeOfCauses) {
            is ExceptionCause -> {
                ifNotNull(this@IterationNodeForTreeOfCauses.causeValue.cause) { innerCause ->
                    yield(
                        ExceptionCause(
                            value = innerCause,
                        ),
                    )
                }
                ifInstanceOf<SDKErrorException>(
                    value = this@IterationNodeForTreeOfCauses.causeValue,
                ) {
                    yield(
                        SDKErrorCause(
                            value = it.sdkError,
                        ),
                    )
                }
            }
            is SDKErrorCause -> {
                ifInstanceOf<SDKError.KnownErrorWithCauseAsSdkError>(
                    value = this@IterationNodeForTreeOfCauses.causeValue,
                ) {
                    yield(
                        SDKErrorCause(
                            value = it.causeAsSDKError,
                        ),
                    )
                }
                ifInstanceOf<SDKError.OtherException>(
                    value = this@IterationNodeForTreeOfCauses.causeValue,
                ) {
                    yield(
                        ExceptionCause(
                            value = it.exception,
                        ),
                    )
                }
                ifInstanceOf<SDKError.SDKErrorWithCauseAsCustomValue>(
                    value = this@IterationNodeForTreeOfCauses.causeValue,
                ) {
                    yield(
                        CustomErrorCause(
                            value = it.causeAsCustomValue,
                        ),
                    )
                }
            }
            is CustomErrorCause -> {
                /** A value from [SDKError.SDKErrorWithCauseAsCustomValue] - they are opaque to SDK, so no children */
            }
        }
    }
}

/** An error code to indicate what went wrong with creating the subscription. */
@JsExport
enum class CreateSubscriptionErrorCode(val code: String) {
    /**
     * Sent when the request would transfer a subscription without this being explicitly allowed.
     */
    NEEDS_TRANSFER("NEEDS_TRANSFER"),

    /**
     * Send when trying to sign up for a new subscription with an expired receipt.
     */
    INVALID_SUBSCRIPTION("INVALID_SUBSCRIPTION"),

    /**
     * Used for error codes the SDK doesn't yet recognize.
     */
    OTHER("OTHER"),
    ;

    companion object {
        /** Gets the enum value corresponding to the code, falling back to OTHER otherwise. */
        fun enumValue(code: String): CreateSubscriptionErrorCode =
            values().firstOrNull { it.code == code } ?: OTHER
    }
}

@JsExport
sealed class Result<out T> {
    data class Success<out T>(val value: T) : Result<T>()
    data class Failure(val error: SDKError) : Result<Nothing>() {
        /**
         * The platform-language native, idiomatic exception object that can be directly thrown, inspected for its type,
         * inspected for its stacktrace, etc.
         * See also [com.speechify.client.api.diagnostics.ErrorInfoForDiagnostics.nativeError].
         *
         * To check if the error has an associated [SDKError] value, test its type for [SDKErrorException], where the
         * [SDKError] can be accessed in [SDKErrorException.sdkError].
         */
        val errorNative: Throwable get() =
            error.toNativeError()
    }

    /**
     * Applies a function with the value of the result if successful.
     */
    inline fun ifSuccessful(f: (T) -> Unit): Result<T> = apply {
        if (this is Success) {
            f(this.value)
        }
    }

    /**
     * Map the inner [T] producing a new result of type [R]
     */
    inline fun <R> map(f: (T) -> R): Result<R> = when (this) {
        is Success<T> -> Success(f(this.value))
        is Failure -> Failure(this.error)
    }

    /**
     * Map the inner error to a different one
     */
    inline fun mapFailure(f: (SDKError) -> SDKError): Result<T> = when (this) {
        is Success -> this
        is Failure -> Failure(f(this.error))
    }

    /**
     * Map the inner [T] with a function that returns [Result<R>]
     */
    inline fun <R> then(f: (T) -> Result<R>): Result<R> = when (this) {
        is Success<T> -> f(this.value)
        is Failure -> Failure(this.error)
    }

    /**
     * Return the success value or `null` in case it's a [Failure]
     */
    fun toNullable(log: ((SDKError) -> Unit)? = null): T? = when (this) {
        is Success<T> -> this.value
        is Failure -> {
            log?.invoke(this.error)
            null
        }
    }

    /**
     * Early return from a function
     */
    inline fun orReturn(f: (Failure) -> Nothing): T = when (this) {
        is Success<T> -> this.value
        is Failure -> f(this)
    }

    /**
     * Run a closure on failure
     */
    inline fun onFailure(f: (Failure) -> Unit) {
        if (this is Failure) f(this)
    }

    /**
     * Runs a closure on the success value of this and returns the same result. Useful for debugging
     */
    inline fun inspectSuccess(block: (T) -> Unit): Result<T> = apply {
        if (this is Success) block(this.value)
    }

    /**
     * A SDK-Result version of [kotlin.Result.fold]
     * This function applies two transformation functions: one for the success case and
     * another for the failure case. Depending on whether this instance represents a success or failure,
     * the corresponding function is applied, and its result is returned.
     * iOS should prefer using this instead of matching against types, as a workaround to an issue that
     * [we have reported to Kotlin](https://youtrack.jetbrains.com/issue/KT-64886/Kotlin-Multiplatform-Loss-of-access-to-values-of-a-sealed-class-subtypes-that-specify-Nothing-cannot-cast-exclusively-in-Release)
     * Similar to Kotlin's [kotlin.Result.fold]: https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/fold.html
     */
    inline fun <R> translate(
        onSuccess: (value: T) -> R,
        onFailure: (error: SDKError) -> R,
    ): R = when (this) {
        is Success -> onSuccess(this.value)
        is Failure -> onFailure(this.error)
    }

    /**
     * Overload '==' operator to check if two results are equal
     */
    override fun equals(other: Any?): Boolean {
        return when (this) {
            is Success<T> -> (other is Success<*>) && this.value == other.value
            is Failure -> (other is Failure) && this.error == other.error
        }
    }

    override fun hashCode(): Int {
        return when (this) {
            is Success<T> -> this.value.hashCode()
            is Failure -> this.error.hashCode()
        }
    }

    companion object {
        fun <T, R> continuationThen(
            callback: (Result<T>) -> Unit,
            f: (R) -> Unit,
        ): (Result<R>) -> Unit {
            return {
                when (it) {
                    is Failure -> callback(Failure(it.error))
                    is Success -> f(it.value)
                }
            }
        }

        fun <R> continuationThenElse(
            onSuccess: (R) -> Unit,
            onFailure: (error: SDKError) -> Unit,
        ): (Result<R>) -> Unit {
            return {
                when (it) {
                    is Success -> onSuccess(it.value)
                    is Failure -> onFailure(it.error)
                }
            }
        }
    }
}

/**
 * Changes [this] result's [Failure]s of [SDKError.ResourceNotFound] to a `null` [Result.Success].
 * This is in order to remove the [Failure] semantics from it, so that it can be excluded from getting logged, or being
 * treated the same way as unexpected errors.
 * Leaves all other error types untouched, so they can be logged/propagated as errors in the conventional manner.
 *
 * Use it where it is known that the `ResourceNotFound` is a normal app flow and is not an error to be reported to
 * developers.
 */
internal inline fun <T : Any> Result<T?>.toNullSuccessIfResourceNotFound(): Result<T?> =
    if (this is Failure && this.error is SDKError.ResourceNotFound) {
        Result.Success(null)
    } else {
        this
    }

internal inline fun <T> Result<T?>.fallbackNullValueToFailure(
    getErrorForNull: () -> SDKError,
): Result<T> =
    when (this) {
        is Result.Success<T?> -> {
            if (this.value != null) {
                @Suppress("UNCHECKED_CAST")
                this as Result<T>
            } else {
                Failure(getErrorForNull())
            }
        }
        is Failure -> this
    }

internal inline fun <T> Result<T>.toFailureFromSuccessIf(
    predicate: (value: T) -> Boolean,
    errorForFailure: (value: T) -> SDKError,
): Result<T> =
    when (this) {
        is Result.Success -> {
            if (predicate(value)) {
                Result.Failure(errorForFailure(value))
            } else {
                this
            }
        }
        is Result.Failure -> this
    }

/**
 * Get the success value or some lazily computed default in case this result is a [Failure]
 */
internal inline fun <T> Result<T>.orDefaultWith(defaultValue: (SDKError) -> T): T = when (this) {
    is Result.Success<T> -> this.value
    is Failure -> defaultValue(this.error)
}

internal fun <T> kotlin.Result<Result<T>>.flatten(): Result<T> =
    toSdkResult()

@JvmName("nativeToSdkResult")
internal fun <T> kotlin.Result<T>.toSdkResult(): Result<T> =
    Result.Success(getOrElse { return@toSdkResult Failure(SDKError.OtherException(it)) })

internal fun <T> kotlin.Result<Result<T>>.toSdkResult(): Result<T> =
    getOrElse { Failure(if (it is SDKErrorException) it.sdkError else SDKError.OtherException(it)) }

/**
 * A version of [runCatching] for translating Kotlin-idiomatic code (without the [Result]) to the SDK-[Result] based
 * one.
 * See [runCatchingExceptionsAsReturnedSdkFailure] for a version that retains compiler features of making it
 *  * allow the [block] to initialize `val`s defined before it (by the use of [kotlin.contracts.InvocationKind.EXACTLY_ONCE]).
 */
internal inline fun <T> runCatchingToSdkResult(block: () -> T): Result<T> =
    runCatchingExceptionsAsReturnedSdkFailure(
        returnException = { return it.toSDKFailure() },
    ) {
        block()
    }
        .successfully()

/**
 * A version of [coroutineScope] for translating Kotlin-idiomatic code (without the [Result]) to the SDK-[Result] based
 * one.
 * See [coroutineScopeCatchingExceptionsAsReturnedSdkFailure] for a version that retains compiler features of making it
 *  * allow the [block] to initialize `val`s defined before it (by the use of [kotlin.contracts.InvocationKind.EXACTLY_ONCE]).
 */
internal suspend inline fun <T> coroutineScopeToSdkResult(
    noinline block: suspend CoroutineScope.() -> T,
): Result<T> =
    coroutineScopeCatchingExceptionsAsReturnedSdkFailure(
        returnException = { return it.toSDKFailure() },
    ) {
        block()
    }
        .successfully()

/**
 * [runCatchingExceptionsAsReturnedSdkFailure] plus [coroutineScope], saving one level of indentation, and
 * allowing to write Kotlin-idiomatic coroutine decomposition code in the `block` which can interrupt the entire
 * coroutine scope by throwing an exception from any of the sub-coroutines, and translate it to a SDK [Result.Failure]
 * later (so any of the sub-coroutines is free to use [orThrow]).
 */
@OptIn(ExperimentalContracts::class)
internal suspend inline fun <T> coroutineScopeCatchingExceptionsAsReturnedSdkFailure(
    /**
     * See [runCatchingExceptionsAsReturnedSdkFailure]'s parameter.
     */
    returnException: (exception: Throwable) -> Nothing,
    noinline block: suspend CoroutineScope.() -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    return runCatchingExceptionsAsReturnedSdkFailure(returnException) {
        coroutineScope {
            block()
        }
    }
}

/**
 * A version of [runCatching] which translates exception-throws to returned SDK [Result.Failure].
 * This is useful for writing for Kotlin-idiomatic code (without the [Result]) inside contexts that need to translate
 * exceptions to SDK [Result.Failure]s and especially be able to retain compiler features of making it
 * allow the [block] to initialize `val`s defined before it (by the use of [kotlin.contracts.InvocationKind.EXACTLY_ONCE]).
 *
 * (eventually, the SDK-result-based code should be replaced with Kotlin-idiomatic code, and this function moved to
 * higher and higher levels of the codebase, until [fromCo] can be seen and take its role, and thus usage of this
 * one no longer needed).
 *
 * The return of failures is enforced by the compiler through [returnException] having a [Nothing] return type, which is required
 * to allow the [block] to initialize `val`s defined before it (see [returnException] for why).
 */
@OptIn(ExperimentalContracts::class)
internal inline fun <T> runCatchingExceptionsAsReturnedSdkFailure(
    /**
     * NOTE: This function must strictly be `{ return@someOuterScope it.toSDKFailure() }`, in order to return the
     * `exception` to some outer scope using `{}`.
     * This is required to make the compiler allow the [block] to initialize `val`s defined before it (by the use of [kotlin.contracts.InvocationKind.EXACTLY_ONCE]).
     *
     * Elaboration it's necessary for the entire [runCatchingExceptionsAsReturnedSdkFailure] function to not return for an exception,
     * to be able to use [kotlin.contracts.InvocationKind.EXACTLY_ONCE]. See how this fact was realized late in Kotlin's
     * [runCatching] [here](https://youtrack.jetbrains.com/issue/KT-26523/EXACTLYONCE-contract-in-runCatching-doesnt-consider-lambda-exceptions-are-caught)).
     */
    returnException: (exception: Throwable) -> Nothing,
    block: () -> T,
): T {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    return try {
        block()
    } catch (e: Throwable) {
        returnException(e)
    }
}

internal fun <T> Result<T>.toKotlinResult(): kotlin.Result<T> =
    when (this) {
        is Failure -> kotlin.Result.failure(error.toException())
        is Result.Success -> kotlin.Result.success(value)
    }

/**
 * Flattens any SDK [Result.Failure] to the native [Result], while leaving the [Result.Success] intact.
 * NOTE: The result of the SDK call does not change, as Failures don't involve [T].
 */
internal inline fun <T> kotlin.Result<Result<T>>.flattenSdkFailureToNative(): kotlin.Result<Result<T>> =
    fold(
        onFailure = { this },
        onSuccess = { value ->
            when (value) {
                is Failure -> kotlin.Result.failure(value.error.toException())
                else -> this
            }
        },
    )

internal inline fun <reified T : Throwable> Failure.isExceptionType(): Boolean =
    isExceptionMatching { exception ->
        exception is T
    }

internal inline fun Failure.isExceptionMatching(
    predicate: (exception: Throwable) -> Boolean,
): Boolean =
    this.error.isOfTypeAnd { exceptionError: SDKError.OtherException ->
        predicate(exceptionError.exception)
    }

/**
 * Turn any [T] into a [Result<T>] that is successful
 */
fun <T> T.successfully(): Result<T> = Result.Success(this)

/**
 * Make a [Result]<[Unit]>
 */
fun success(): Result.Success<Unit> = unit
private val unit =
    Result.Success(Unit) // save a memory allocation by always returning the same object

/**
 * Flatten a result of a result into a cleaner on level deep result
 */
fun <T> Result<Result<T>>.flatten(): Result<T> = this.then { it }

/**
 * Equivalent of [Throwable.addSuppressed] for Failures.
 *
 * Mechanism for preventing the loss of root cause Failure, but also not losing errors that occurred in the code on the
 * error path control flow. See also [guide](https://www.notion.so/fresh-hoodie-9f1/Errors-reporting-passing-handling-practices-guide-8fb4f2f0aaae4e23aceaa3aa83deee93#fccda4a4fff844db9845b7ceeebf8cdb)
 */
internal fun Failure.addSuppressed(
    setStatusFailure: Failure,
) {
    when (val error = this.error) {
        is SDKError.OtherException -> error.exception.addSuppressed(
            when (val setStatusError = setStatusFailure.error) {
                is SDKError.OtherException -> error.exception
                else -> Error(setStatusError.toString())
            },
        )

        else -> {
            // TODO - add mechanism for adding suppressed Failure to a Failure and document [here](https://www.notion.so/fresh-hoodie-9f1/Errors-reporting-passing-handling-practices-guide-8fb4f2f0aaae4e23aceaa3aa83deee93#fccda4a4fff844db9845b7ceeebf8cdb)
        }
    }
}

internal fun <T> Result<T>.orThrow(): T =
    when (this) {
        is Failure -> this.throwIt()
        is Result.Success<T> -> this.value
    }

internal fun <T> Flow<Result<T>>.asThrowingFlow(): Flow<T> =
    this.map { it.orThrow() }

internal fun Failure.throwIt(): Nothing =
    this.error.throwIt()

internal fun SDKError.throwIt(): Nothing =
    throw this.toException()

internal
/*
 * NOTE on `inline` here: This is desired especially so that the exception created here gets a call stack that includes
 * the caller - see [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/922#discussion_r1151668719)
 * #InlineForCallerInStacktrace
 */
inline
fun SDKError.toException(): Throwable =
    when (val error = this) {
        is SDKError.OtherException -> error.exception
        else -> SDKErrorException(error)
    }

/**
 * Like [toException], but ensures that the exception has a call stack that includes the caller.
 * WARNING: This wrapper loses the outermost exception's type, so only use it where there is no contract on the exception type
 * (or use a contract on 'any exception in chain is of type', like the [com.speechify.client.api.util.isCausedByConnectionError]).
 */
internal
/*
 * NOTE on `inline` here: This is desired especially so that the exception created here gets a call stack that includes
 * the caller - see [here](https://github.com/SpeechifyInc/multiplatform-sdk/pull/922#discussion_r1151668719)
 * #InlineForCallerInStacktrace
 */
inline
fun SDKError.toExceptionWithCallerStackTrace(): Throwable =
    when (val error = this) {
        is SDKError.OtherException -> ExceptionForCallerStacktrace(error.exception)
        else -> SDKErrorException(error)
    }

/**
 * An exception that wraps another exception for the purpose of adding a caller stacktrace to it.
 *
 * A defined class is used, to explain the purpose of wrapping the exception, and suggest to look into the [cause] for
 * type and any more info.
 */
internal class ExceptionForCallerStacktrace(
    originalException: Throwable,
) : Exception(
    /**
     * Duplicate the message, because we do want to keep the message on top, because the logging only takes the top
     * level exception message to populate the single "message" property of the log entry.
     *
     * Specify explicitly as the message and don't rely on the single-param constructor with just the [cause], because
     * otherwise the message is copied from [cause] anyway, but includes the type-name prefix, which is sometimes
     * compiled-away, ending up with type-names like `Jun:` or `Fun:`, and making the message confusing.
     */
    /* message = */ originalException.message,
    /* cause = */ originalException,
)

internal fun Throwable.toResultFailure(): Failure = Failure(this.toSDKError())

@JsExport
class SDKErrorException(
    val sdkError: SDKError,
    /**
     * Use this to pass exception (if any) that lead to this error, which may help investigation, (include the inner
     * trace, error type, etc.)
     */
    cause: Throwable? = null,
) : RuntimeException(
    sdkError.toString(),
    cause,
)

internal fun Throwable.toSDKFailure(): Failure =
    Failure(toSDKError())

internal fun Throwable.toSDKError(): SDKError =
    if (this is SDKErrorException) sdkError else SDKError.OtherException(this)

/**
 * Transposes a result of a nullable into a nullable result
 *
 * ### Examples
 * - Result.Success(1) -> Result.Success(1)
 * - Result.Success(null) -> null
 * - Result.Failure(e) -> Result.Failure(e)
 */
internal fun <T> Result<T?>.resultOfNullableToNullableResult(): Result<T>? = when (this) {
    is Result.Success<T?> -> if (this.value == null) null else Result.Success(this.value)
    is Failure -> Failure(this.error)
}

/**
 * Transposes a nullable result into a result of a nullable
 *
 * ### Examples
 * - null -> Result.Success(null)
 * - Result.Success(1) -> Result.Success(1)
 * - Result.Failure(e) -> Result.Failure(e)
 */
internal fun <T> Result<T>?.nullResultToResultOfNullable(): Result<T?> = when (this) {
    null -> Result.Success(null)
    else -> this
}

/**
 * Go from a collection/sequence of [Result]<[T]>s to a [Result]<[Collection]<[T]>>
 */
internal fun <T, C : MutableCollection<T>> Sequence<Result<T>>.tryToCollection(c: C): Result<C> {
    for (i in this) {
        when (i) {
            is Result.Success<T> -> c.add(i.value)
            is Failure -> return Failure(i.error)
        }
    }
    return Result.Success(c)
}

/**
 * Go from a collection/sequence of [Result]<[T]>s to a [Result]<[MutableList]<[T]>>
 */
internal fun <T> Sequence<Result<T>>.tryToList(): Result<MutableList<T>> = tryToCollection(mutableListOf())

/**
 * Go from a collection/sequence of [Result]<[T]>s to a [Result]<[MutableSet]<[T]>>
 */
internal fun <T> Sequence<Result<T>>.tryToSet(): Result<MutableSet<T>> = tryToCollection(mutableSetOf())

/**
 * Go from a [Flow]<[Result]<T>> to a [Result]<[Collection]<T>>
 */
internal suspend fun <T, C : MutableCollection<T>> Flow<Result<T>>.tryCollectTo(collection: C): Result<C> =
    tryFold(collection) { c, it -> c.apply { add(it) } }

// These are probably the ugliest fold I've ever written, but it seems flows don't have good mechanisms for
// short-circuiting: https://github.com/Kotlin/kotlinx.coroutines/issues/2183
internal suspend fun <T, R> Flow<Result<T>>.tryFold(initial: R, f: (R, T) -> R): Result<R> {
    var acc = initial
    var error: SDKError? = null
    takeWhile {
        when (it) {
            is Result.Success -> {
                acc = f(acc, it.value)
                true
            }

            is Failure -> {
                error = it.error
                false
            }
        }
    }.collect {}

    if (error != null) return Failure(error!!)
    return acc.successfully()
}

internal suspend fun <T, R> Flow<Result<T>>.tryFoldFallible(initial: R, f: (R, T) -> Result<R>): Result<R> {
    var acc = initial
    var error: SDKError? = null
    map {
        when (val x = it.then { v -> f(acc, v) }) {
            is Result.Success -> acc = x.value
            is Failure -> {
                error = x.error
                null
            }
        }
    }.takeWhile { it != null }.collect {}

    if (error != null) return Failure(error!!)
    return acc.successfully()
}
