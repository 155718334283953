package com.speechify.client.internal.util

import kotlin.math.abs
import kotlin.math.ceil
import kotlin.math.min

internal fun <T : Comparable<T>> max(a: T?, vararg other: T?): T? {
    var biggest = a
    for (o in other) {
        if (o == null) {
            continue
        } else if (biggest == null) {
            biggest = o
        } else if (o > biggest) biggest = o
    }
    return biggest
}

/**
 * The Kotlin stdlib does not have a way to format a double, so we have to do it ourselves
 */
internal fun Double.toString(decimalPlaces: Int): String {
    val asInteger = toInt()
    val asDecimal = (this - asInteger).toString().split('.').drop(1).firstOrNull()?.substring(0..decimalPlaces)
    return "$asInteger.$asDecimal"
}

/**
 * This scales up the number by the given factor.
 * For positive numbers this is the same as just multiplying, but for negative numbers this will move the number towards the positive.
 * Scale needs to be a positive integer larger than 0.
 */
internal fun Double.scaleToCeiling(scale: Double): Double {
    return this + (abs(this) * (scale - 1.0))
}

/**
 * Compares two doubles, considering them different if their difference is greater then [tolerance]
 */
internal fun Double.notEqWithTolerance(that: Double, tolerance: Double): Boolean = !eqWithTolerance(that, tolerance)

/**
 * Compares two doubles, considering them equal if their difference is smaller then [tolerance]
 */
internal fun Double.eqWithTolerance(that: Double, tolerance: Double): Boolean {
    return this == that || (if (this < that) that - this else this - that) <= tolerance
}

/**
 * Compares two doubles, considering them equal if their difference is smaller then [tolerance]
 */
internal fun Double.lessThanOrEqWithTolerance(that: Double, tolerance: Double): Boolean {
    return this <= that || this.eqWithTolerance(that, tolerance)
}

internal fun Double.isBetween(a: Double, b: Double, tolerance: Double = 0.0): Boolean {
    val smallEnd = min(a, b)
    val bigEnd = kotlin.math.max(a, b)

    return this.greaterThanOrEqWithTolerance(smallEnd, tolerance) && this.lessThanOrEqWithTolerance(bigEnd, tolerance)
}

/**
 * Compares two doubles, considering them equal if their difference is smaller then [tolerance]
 */
internal fun Double.greaterThanOrEqWithTolerance(that: Double, tolerance: Double): Boolean {
    return this >= that || this.eqWithTolerance(that, tolerance)
}

/**
 * Compares double with the supplied sum of doubles as `values`
 */
internal fun Double.isGreaterThanOrEqualToSumOf(vararg values: Double): Boolean {
    return this >= values.sum()
}

internal fun IntRange.requireContainedAndOrdered(from: Int, to: Int) {
    require(from in this) { "from out of bounds: $from not in $this" }
    require((to - 1) in this) { "to out of bounds: $to not in $this" }
    require(from <= to) { "$from > $to" }
}

/**
 * Shift elements of the array to the left in place.
 *
 * @param from optionally can start at an index other than 0
 * @param length optionally can shift less than length elements
 */
internal fun <T> Array<T>.shiftLeft(from: Int = 0, to: Int = this.lastIndex) {
    require(from <= to) {
        "from ($from) must be less than or equal to to ($to)"
    }
    for (i in from until to) {
        this[i] = this[i + 1]
    }
}

/**
 * Shift elements of the array to the right in place.
 *
 * @param from optionally can start at an index other than 0
 * @param length optionally can shift less than length elements
 */
internal fun <T> Array<T>.shiftRight(from: Int = this.lastIndex, to: Int = 0) {
    require(to <= from) {
        "to ($to) must be less than or equal to from ($from)"
    }
    for (i in ((to + 1)..from).reversed()) {
        this[i] = this[i - 1]
    }
}

/**
 * Rounds up a Double to the nearest integer
 */
fun Double.roundUpToInt(): Int = ceil(this).toInt()

internal inline fun <reified T> Array<T>.updateItemsAtIndices(
    indices: Set<Int>,
    update: (T) -> T,
): Array<T> {
    return this.mapIndexed { index, item ->
        if (index in indices) update(item) else item
    }.toTypedArray()
}

internal suspend fun String.replaceWithSuspend(
    regex: Regex,
    replaceBlock: suspend (MatchResult) -> String,
): String {
    val result = StringBuilder()
    var lastIndex = 0

    for (match in regex.findAll(this)) {
        result.append(this, lastIndex, match.range.first) // Append text before the match
        result.append(replaceBlock(match)) // Replace the match with the result of the suspend function
        lastIndex = match.range.last + 1
    }

    if (lastIndex < length) {
        result.append(substring(lastIndex)) // Append remaining text
    }

    return result.toString()
}
