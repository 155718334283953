package com.speechify.client.internal.util.coroutines

import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope

/**
 * Creates a scope where the [block] has access to the [Deferred] that is the result of itself.
 * itself.
 *
 * This may be useful to work-around circular dependencies in constructors (by making of the types take a [Deferred] of the other).
 */
internal suspend fun <T> coroutineScopeWithResultDeferredAvailableInBlock(
    block: suspend CoroutineScope.(theAsyncResult: Deferred<T>) -> T,
): T =
    coroutineScope {
        asyncWithResultDeferredAvailableInBlock {
            block(it)
        }
            .await()
    }

/**
 * A version of [kotlinx.coroutines.async] where the [block] has access to the [Deferred] that is the result
 * itself.
 *
 * This may be useful to work-around circular dependencies in constructors (by making of the types take a [Deferred] of the other).
 */
private suspend fun <T> CoroutineScope.asyncWithResultDeferredAvailableInBlock(
    block: suspend (theAsyncResult: Deferred<T>) -> T,
): Deferred<T> {
    val coroutineScope = this
    return coroutineScope.async {
        val completableDeferred = CompletableDeferred<T>()
        return@async try {
            block(completableDeferred)
        } catch (e: Throwable) {
            completableDeferred.completeExceptionally(e)
            throw e
        }
            .also {
                completableDeferred.complete(it)
            }
    }
}
