package com.speechify.client.internal.sync

internal actual class AtomicRef<T> actual constructor(private var initialValue: T) : AtomicVal<T> {
    actual override val value: T
        get() = initialValue

    actual override fun swap(with: T): T {
        val old = initialValue
        initialValue = with
        return old
    }

    /**
     * Compare current reference with `expected` and if they are equal, then the current reference is set to `with` and
     * returns true, otherwise, it will _not_ update and return false
     */
    actual override fun compareAndSet(expected: T, with: T): Boolean {
        if (initialValue !== expected) return false

        initialValue = with
        return true
    }
}
