package com.speechify.client.internal.util.collections.flows

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

/**
 * Allows every flow collection to be enriched with [enrichingBlock].
 * NOTE: The [enrichingBlock] will run once around the entire collection of the flow (but once for each collection of
 * the flow, if the flow is collected multiple times).
 *
 * For example, this could be used to add a `finally` for a lifecycle concern, or a `catch`, for [enriching/translation]
 * of any exceptions thrown during consumption.
 */
internal inline fun <T> Flow<T>.enrichingConsumptionsWith(
    /**
     * Example:
     * ```
     * { performCollect ->
     *    try {
     *      // Here you can do something before start
     *
     *      performCollect() // You must always call this
     *
     *      // Here you can do something on successful completion
     *
     *    } catch (e: Throwable) {
     *
     *      // Here you can do something on error
     *
     *      throw e // You should always rethrow the error
     *    }
     *    finally {
     *      // Here you can do something after completion, regardless of success or error
     *    }
     * }
     * ```
     */
    crossinline enrichingBlock: suspend (performCollect: suspend () -> Unit) -> Unit,
) = flow {
    enrichingBlock {
        this@enrichingConsumptionsWith.collect(this@flow)
    }
}
