package com.speechify.client.internal.services.importing.models

import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.adapters.firebase.UserId
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.telemetry.TelemetryContentType
import com.speechify.client.api.util.MimeType
import com.speechify.client.helpers.features.ListeningProgress
import com.speechify.client.internal.services.db.DBPendingImport
import com.speechify.client.internal.services.db.DbScannedPageFile
import com.speechify.client.internal.time.DateTime
import com.speechify.client.internal.util.extensions.intentSyntax.nullIfEmpty

internal sealed class ItemRequiringImport {
    abstract val speechifyUri: SpeechifyURI
    abstract val sourceURL: String?
    abstract val importOptions: ImportOptions?
    abstract val lastErrorStackTrace: String?
    abstract val wasLastErrorConnectionError: Boolean?
    abstract val attemptsPerformedCount: Int
    abstract val lastUpdatedAt: DateTime
    abstract val owner: UserId
    abstract val listeningProgress: ListeningProgress?
    abstract val dbSource: DBPendingImport
    abstract val isImportCurrentlyRunning: Boolean
    abstract val mimeType: MimeType?

    companion object {
        suspend fun fromDb(
            pendingImport: DBPendingImport,
            isImportCurrentlyRunning: Boolean,
            obtainScannedPageFiles: suspend () -> List<DbScannedPageFile>?,
        ): ItemRequiringImport {
            when (pendingImport.importType) {
                ImportType.FILE -> return FileImport(
                    pendingImport,
                    pendingImport.speechifyUri,
                    pendingImport.primaryFileBlobStorageKey ?: throw IllegalArgumentException(
                        "Encountered file import with no primary file blob storage key.",
                    ),
                    pendingImport.sourceURL,
                    pendingImport.importOptions,
                    pendingImport.lastErrorStackTrace,
                    pendingImport.wasLastErrorConnectionError?.value,
                    pendingImport.attemptsPerformedCount,
                    pendingImport.lastUpdatedAt,
                    pendingImport.owner,
                    pendingImport.listeningProgress,
                    isImportCurrentlyRunning,
                    pendingImport.mimeType,
                )

                ImportType.SCANNED_PAGES -> return ScannedPagesImport(
                    pendingImport,
                    pendingImport.speechifyUri,
                    obtainScannedPageFiles()?.nullIfEmpty() ?: throw IllegalArgumentException(
                        "Encountered scanned pages import with no scanned pages.",
                    ),
                    pendingImport.importOptions,
                    pendingImport.lastErrorStackTrace,
                    pendingImport.wasLastErrorConnectionError?.value,
                    pendingImport.attemptsPerformedCount,
                    pendingImport.lastUpdatedAt,
                    pendingImport.owner,
                    pendingImport.listeningProgress,
                    isImportCurrentlyRunning,
                )

                ImportType.URL -> return UrlImport(
                    pendingImport,
                    pendingImport.speechifyUri,
                    pendingImport.sourceURL ?: throw IllegalArgumentException(
                        "Encountered URL import with no source URL.",
                    ),
                    pendingImport.primaryFileBlobStorageKey,
                    pendingImport.importOptions,
                    pendingImport.lastErrorStackTrace,
                    pendingImport.wasLastErrorConnectionError?.value,
                    pendingImport.attemptsPerformedCount,
                    pendingImport.lastUpdatedAt,
                    pendingImport.owner,
                    pendingImport.listeningProgress,
                    isImportCurrentlyRunning,
                    pendingImport.mimeType,
                )
            }
        }
    }

    data class FileImport(
        override val dbSource: DBPendingImport,
        override val speechifyUri: SpeechifyURI,
        val primaryFileBlobStorageKey: BlobStorageKey,
        override val sourceURL: String?,
        override val importOptions: ImportOptions?,
        override val lastErrorStackTrace: String?,
        override val wasLastErrorConnectionError: Boolean?,
        override val attemptsPerformedCount: Int,
        override val lastUpdatedAt: DateTime,
        override val owner: UserId,
        override val listeningProgress: ListeningProgress?,
        override val isImportCurrentlyRunning: Boolean,
        override val mimeType: MimeType?,
    ) : ItemRequiringImport()

    data class ScannedPagesImport(
        override val dbSource: DBPendingImport,
        override val speechifyUri: SpeechifyURI,
        val scannedPages: List<DbScannedPageFile>,
        override val importOptions: ImportOptions?,
        override val lastErrorStackTrace: String?,
        override val wasLastErrorConnectionError: Boolean?,
        override val attemptsPerformedCount: Int,
        override val lastUpdatedAt: DateTime,
        override val owner: UserId,
        override val listeningProgress: ListeningProgress?,
        override val isImportCurrentlyRunning: Boolean,
    ) : ItemRequiringImport() {
        override val sourceURL = null
        override val mimeType = null
    }

    data class UrlImport(
        override val dbSource: DBPendingImport,
        override val speechifyUri: SpeechifyURI,
        override val sourceURL: String,
        val primaryFileBlobStorageKey: BlobStorageKey?,
        override val importOptions: ImportOptions?,
        override val lastErrorStackTrace: String?,
        override val wasLastErrorConnectionError: Boolean?,
        override val attemptsPerformedCount: Int,
        override val lastUpdatedAt: DateTime,
        override val owner: UserId,
        override val listeningProgress: ListeningProgress?,
        override val isImportCurrentlyRunning: Boolean,
        override val mimeType: MimeType?,
    ) : ItemRequiringImport()

    /**
     * Provides the content type for display on the Retool listening dashboard.
     */
    val telemetryContentType: String
        get() {
            return when (this) {
                is ScannedPagesImport -> TelemetryContentType.ScannedBook.contentType
                is UrlImport -> this.mimeType?.fullString ?: TelemetryContentType.FileOfUnknownMimeType.contentType
                is FileImport -> this.mimeType?.fullString ?: TelemetryContentType.UrlOfUnknownMimeType.contentType
            }
        }
}

enum class ImportType {
    FILE,
    SCANNED_PAGES,
    URL,
}
