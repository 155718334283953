package com.speechify.client.api.adapters.firebase

import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.diagnostics.traced
import com.speechify.client.api.diagnostics.uuidCallback
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.CoCallback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.boundary.toBoundary
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.runTask
import com.speechify.client.internal.util.collections.flows.FlowThatFinishesOnlyThroughCollectionCancel
import com.speechify.client.internal.util.collections.flows.flowFromCallbackProducer
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport

@JsExport
internal enum class Collections(val collectionRef: String) {
    ITEMS("items"),
    SHARED_ITEMS("sharedItems"),
}

@JsExport
enum class DataSource {
    /**
     * Causes Firestore to try to retrieve an up-to-date (server-retrieved) snapshot,
     * but fall back to returning cached data if the server can’t be reached.
     */
    DEFAULT,

    /**
     * Causes Firestore to immediately return a value from the cache,
     * ignoring the server completely (implying that the returned value may be stale with respect
     * to the value on the server).
     *
     * WARNING: Caller of functions taking this as a parameter should not assume that an item just put into cache will
     * still be there, by using this option. Cache can be cleared at any time (like it happened in Web [here](https://linear.app/speechify-inc/issue/WEB-2539/use-getdocfromcache-in-firebasefirestoreservicegetdocument-when#comment-6082c3f6),
     * by a whim from Firestore (e.g. due to exceeding quota).
     *
     * NOTE: The behavior if there is no data in the cache, at the time of writing, in at least some of the methods,
     * is an exception which should be tested with [com.speechify.client.api.util.isCausedByConnectionError] (or [com.speechify.client.api.util.SDKError] where still in a `Result`) .
     * See `dataSource` parameter [FirebaseFirestoreService.getDocument] for documentation.
     */
    CACHE,

    /**
     * Causes Firestore to avoid the cache, generating an error if the server cannot be reached. Note that the cache
     * will still be updated if the server request succeeds.
     */
    SERVER,
}

@JsExport
abstract class FirebaseFirestoreService {

    protected abstract fun observeDocument(
        collectionRef: String,
        documentRef: String,
        callback: Callback<FirebaseFirestoreDocumentSnapshot>,
    ): Destructor

    internal open fun <T> observeDocumentAsFlow(
        collectionRef: String,
        documentRef: String,
        mapExists: suspend (FirebaseFirestoreDocumentSnapshot.Exists) -> Result<T>,
        valueForNotExists: () -> Result<T>,
    ): Flow<Result<T>> =
        flowFromCallbackProducer(
            callbackBasedProducer = { callback ->
                observeDocument(collectionRef, documentRef, callback)
            },
            /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
             *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
             *  until no more items coming is ensured)
             */
            shouldLogErrorIfCancellationPreventedDelivery = false,
            sourceAreaId = "FirebaseFirestoreService.observeDocumentAsFlow",
        ).map { result ->
            val snapshotResult = result
                .orReturn { return@map it }
            when (snapshotResult) {
                is FirebaseFirestoreDocumentSnapshot.Exists -> {
                    mapExists(snapshotResult)
                }
                is FirebaseFirestoreDocumentSnapshot.NotExists -> {
                    valueForNotExists()
                }
            }
        }

    /**
     * Provides SDK access to [Firestore's 'get all documents from a collection'](https://firebase.google.com/docs/firestore/query-data/get-data#get_all_documents_in_a_collection)
     */
    abstract fun getCollection(
        ref: String,
        /**
         * Note: If [dataSource] is [DataSource.CACHE] the behavior for 'not found in cache' is producing a
         * [com.speechify.client.api.util.SDKError.ConnectionError].
         * This is a historical behavior - see #ConnectionErrorForNotFoundInCache.
         */
        dataSource: DataSource,
        callback: Callback<Array<FirebaseFirestoreDocumentSnapshot>>,
    )

    /**
     * Provides SDK access to [Firestore's 'get document' function](https://firebase.google.com/docs/firestore/query-data/get-data#get_a_document)
     */
    abstract fun getDocument(
        collectionRef: String,
        documentRef: String,
        /**
         * Note: If [dataSource] is [DataSource.CACHE] the behavior for 'not found in cache' is producing a
         * [com.speechify.client.api.util.SDKError.ConnectionError].
         *
         * This is a historical behavior caused by the fact that Firebase's API throws an error with the same error code*)
         * for this situation as for loss of connectivity in non-[DataSource.CACHE] calls.
         * The error observed was:
         * - in JavaScript [`FirestoreErrorCode`](https://firebase.google.com/docs/reference/js/firestore_lite.md#firestoreerrorcode)
         *   of 'unavailable' in [`FirestoreError`](https://firebase.google.com/docs/reference/js/firestore_lite.firestoreerror)
         *   (though the `Error` instance observed is not really marked with `FirestoreError` class)
         * - in Android [`FirebaseFirestoreException.Code.UNAVAILABLE`](https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/FirebaseFirestoreException.Code#UNAVAILABLE)
         *   in [`FirebaseFirestoreException`](https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/FirebaseFirestoreException)
         * - in iOS [`_ErrorType.unavailable`](https://firebase.google.com/docs/reference/swift/firebasefirestore/api/reference/Enums/Error-Types#unavailable)
         *   in the native `NSError`
         * #ConnectionErrorForNotFoundInCache
         */
        dataSource: DataSource,
        callback: Callback<FirebaseFirestoreDocumentSnapshot>,
    )

    abstract fun setDocument(
        collectionRef: String,
        documentRef: String,
        value: BoundaryMap<Any?>,
        merge: Boolean,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    )

    abstract fun queryDocuments(collectionRef: String): DocumentQueryBuilder

    abstract fun updateDocument(
        collectionRef: String,
        documentRef: String,
        value: BoundaryMap<Any?>,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    )

    abstract fun deleteDocument(
        collectionRef: String,
        documentRef: String,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    )
}

internal suspend fun FirebaseFirestoreService.coGetCollection(
    ref: String,
    dataSource: DataSource = DataSource.DEFAULT,
) =
    suspendCoroutine { cont ->
        getCollection(ref, dataSource, cont::resume)
    }.mapFailure {
        it.addCustomProperty("collectionRef", ref)
        it.addCustomProperty("firestoreOperation", "coGetCollection")
        it.addCustomProperty("dataSource", dataSource)
        it
    }

internal suspend fun FirebaseFirestoreService.coGetDocument(
    path: PathInCollection,
    dataSource: DataSource = DataSource.DEFAULT,
): Result<FirebaseFirestoreDocumentSnapshot> =
    coGetDocument(
        collectionRef = path.collectionPath,
        documentRef = path.documentPath,
        dataSource = dataSource,
    )

internal suspend inline fun <reified R> FirebaseFirestoreService.coGetObjectOrNullIfNotExists(
    path: PathInCollection,
): Result<R?> =
    coGetDocument(
        collectionRef = path.collectionPath,
        documentRef = path.documentPath,
    )
        .orReturn { return it }
        .let { snapshotResult ->
            when (snapshotResult) {
                is FirebaseFirestoreDocumentSnapshot.Exists ->
                    snapshotResult.value<R>()
                        .orReturn { return it }
                FirebaseFirestoreDocumentSnapshot.NotExists -> null
            }
                .successfully()
        }

internal suspend fun FirebaseFirestoreService.coGetDocument(
    collectionRef: String,
    documentRef: String,
    dataSource: DataSource = DataSource.DEFAULT,
):
    Result<FirebaseFirestoreDocumentSnapshot> =
    suspendCoroutine { cont -> getDocument(collectionRef, documentRef, dataSource, cont::resume) }.mapFailure {
        it.addCustomProperty("collectionRef", collectionRef)
        it.addCustomProperty("documentRef", documentRef)
        it.addCustomProperty("firestoreOperation", "coGetDocument")
        it.addCustomProperty("dataSource", dataSource)
        it
    }

internal suspend fun FirebaseFirestoreService.coUpdateDocument(
    path: PathInCollection,
    value: BoundaryMap<Any?>,
) =
    coUpdateDocument(
        collectionRef = path.collectionPath,
        documentRef = path.documentPath,
        value = value,
    )

internal suspend fun FirebaseFirestoreService.coUpdateDocument(
    collectionRef: String,
    documentRef: String,
    value: BoundaryMap<Any?>,
) =
    suspendCoroutine {
        this.updateDocument(
            collectionRef,
            documentRef,
            value,
            it::resume,
        )
    }.mapFailure {
        it.addCustomProperty("collectionRef", collectionRef)
        it.addCustomProperty("documentRef", documentRef)
        it.addCustomProperty("firestoreOperation", "coUpdateDocument")
        it.addCustomProperty("updatedKeys", value.keys().joinToString(","))
        it
    }

internal suspend fun FirebaseFirestoreService.coSetDocument(
    path: PathInCollection,
    value: BoundaryMap<Any?>,
    merge: Boolean = false,
) =
    coSetDocument(
        collectionRef = path.collectionPath,
        documentRef = path.documentPath,
        value = value,
        merge = merge,
    )

internal suspend fun FirebaseFirestoreService.coSetDocument(
    collectionRef: String,
    documentRef: String,
    value: BoundaryMap<Any?>,
    merge: Boolean = false,
) =
    suspendCoroutine {
        this.setDocument(
            collectionRef,
            documentRef,
            value,
            merge,
            it::resume,
        )
    }.mapFailure {
        it.addCustomProperty("collectionRef", collectionRef)
        it.addCustomProperty("documentRef", documentRef)
        it.addCustomProperty("firestoreOperation", "coSetDocument")
        it.addCustomProperty("updatedKeys", value.keys().joinToString(","))
        it
    }

internal suspend fun FirebaseFirestoreService.coDeleteDocument(path: PathInCollection) =
    coDeleteDocument(
        collectionRef = path.collectionPath,
        documentRef = path.documentPath,
    )

internal suspend fun FirebaseFirestoreService.coDeleteDocument(collectionRef: String, documentRef: String) =
    suspendCoroutine { cont -> deleteDocument(collectionRef, documentRef, cont::resume) }.mapFailure {
        it.addCustomProperty("collectionRef", collectionRef)
        it.addCustomProperty("documentRef", documentRef)
        it.addCustomProperty("firestoreOperation", "coDeleteDocument")
        it
    }

@JsExport
sealed class WhereClause {
    data class WithPrimitive(
        val property: String,
        val operator: DocumentQueryBuilder.Operator,
        val value: Any?,
    ) : WhereClause()

    data class WithList(
        val property: String,
        val operator: DocumentQueryBuilder.OperatorList,
        val value: Array<Any?>,
    ) : WhereClause() {
        override fun toString(): String =
            "WithList(property='$property', operator=$operator, value=${value.contentToString()})"
    }
}

typealias CollectionReference = String

/**
 * Helps to encapsulate the logic for the location of the document in a single function.
 *
 * Especially needed because [FirebaseFirestoreService] doesn't have CRUD overloads that take the path as
 * a single parameter. This type allows to have overloads that do have a single parameter.
 */
internal class PathInCollection(
    val collectionPath: CollectionReference,
    val documentPath: String,
) {
    val pathString = "$collectionPath/$documentPath"

    override fun toString(): String = pathString
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PathInCollection

        if (pathString != other.pathString) return false

        return true
    }

    override fun hashCode(): Int {
        return pathString.hashCode()
    }
}

@JsExport
class DocumentQueryBuilder(
    internal val fetchImpl: (DocumentQuery, DataSource, Callback<Array<FirebaseFirestoreDocumentSnapshot>>) -> Unit,
    /** TODO - replace all SDK usages of [observeImpl] with [observeAsFlow] and make it private */
    internal val observeImpl: (DocumentQuery, Callback<FirebaseFirestoreQuerySnapshot>) -> Destructor,
    internal val countImpl: (DocumentQuery, Callback<Long>) -> Unit,
) {
    // not actually exported so this warning was faulty
    @Suppress("NON_EXPORTABLE_TYPE")
    private data class OrderBy(
        val field: String,
        val direction: Direction,
        val bound: Pair<Any, BoundType>?,
    )

    private val whereClauses: MutableList<WhereClause> = mutableListOf()
    private var orderBy: MutableList<OrderBy> = mutableListOf()
    private val snapshotBounds: MutableList<Pair<SnapshotRef, BoundType>> = mutableListOf()
    private var limit: Int? = null
    private var fields: Array<out String>? = null

    enum class Operator(val op: String) {
        LT("<"),
        LE("<="),
        EQ("=="),
        GT(">"),
        GE(">="),
        NE("!="),
        ArrayContains("array-contains"),
    }

    enum class OperatorList(val op: String) {
        ArrayContainsAny("array-contains-any"),
        IN("in"),
        NotIn("not-in"),
    }

    enum class Direction(val direction: String) {
        Ascending("asc"),
        Descending("desc"),
    }

    enum class BoundType(val boundType: String) {
        StartAfter("startAfter"),
        StartAt("startAt"),
        EndBefore("endBefore"),
        EndAt("endAt"),
    }

    @Suppress("ArrayInDataClass")
    data class DocumentQuery(
        val whereClauses: Array<WhereClause>,
        val orderBy: Array<BoundaryPair<String, Direction>>,
        val limit: Int?,
        val fields: Array<out String>?,
        val bounds: Array<BoundaryPair<Any, BoundType>>,
        val snapshotBounds: Array<BoundaryPair<SnapshotRef, BoundType>>,
    ) {
        override fun toString(): String =
            "DocumentQuery(whereClauses=${whereClauses.contentToString()}, orderBy=${orderBy.contentToString()}," +
                " limit=$limit, fields=${fields?.contentToString()}, bounds=${bounds.contentToString()}, " +
                " snapshotBounds=${snapshotBounds.contentToString()})"
    }

    internal fun where(property: String, operator: OperatorList, value: Array<Any?>) = apply {
        this.whereClauses.add(WhereClause.WithList(property, operator, value))
    }

    internal fun where(property: String, operator: Operator, value: Any?) = apply {
        this.whereClauses.add(WhereClause.WithPrimitive(property, operator, value))
    }

    internal fun orderBy(
        property: String,
        direction: Direction = Direction.Ascending,
        bound: Pair<Any, BoundType>? = null,
    ) = apply {
        orderBy.add(OrderBy(property, direction, bound))
    }

    internal fun boundBy(snapshotRef: SnapshotRef, boundType: BoundType) = apply {
        snapshotBounds.add(snapshotRef to boundType)
    }

    internal fun limit(amount: Int) = apply {
        limit = amount
    }

    internal fun select(vararg f: String) = apply {
        fields = f
    }

    internal fun fetch(callback: Callback<Array<FirebaseFirestoreDocumentSnapshot>>) = callback.fromCo {
        coFetch()
    }

    internal suspend fun coFetch(source: DataSource = DataSource.DEFAULT):
        Result<Array<FirebaseFirestoreDocumentSnapshot>> = suspendCoroutine {
        fetchImpl(queryDto(), source, it::resume)
    }

    internal fun count(callback: Callback<Long>) = callback.fromCo {
        coCount()
    }

    internal suspend fun coCount():
        Result<Long> = suspendCoroutine {
        countImpl(queryDto(), it::resume)
    }

    internal fun observeAsFlow(
        sourceAreaId: String,
        diagnosticProperties: Map<String, Any>? = null,
    ): FlowThatFinishesOnlyThroughCollectionCancel<Result<FirebaseFirestoreQuerySnapshot>> =
        flowFromCallbackProducer(
            callbackBasedProducer = { callback ->
                observeImpl(queryDto(), callback)
            },
            /** shouldLogErrorIfCancellationPreventedDelivery=`false`, because the adapters were implemented without
             *  requirement to control concurrency (they could do that by not returning from the unsubscribe function
             *  until no more items coming is ensured)
             */
            shouldLogErrorIfCancellationPreventedDelivery = false,
            sourceAreaId = sourceAreaId,
            diagnosticProperties = mapOf(
                "query" to queryDto().toString(),
            ) + (diagnosticProperties ?: emptyMap()),
        )

    internal suspend fun coObserve(coCallback: CoCallback<FirebaseFirestoreQuerySnapshot>):
        Destructor {
        val result = observeImpl(queryDto()) {
            runTask {
                coCallback(it)
            }
        }
        return result
    }

    internal fun queryDto() = DocumentQuery(
        whereClauses.toTypedArray(),
        orderBy.map { (it.field to it.direction).toBoundary() }.toTypedArray(),
        limit,
        fields,
        orderBy.mapNotNull { it.bound?.toBoundary() }.toTypedArray(),
        snapshotBounds.map { it.toBoundary() }.toTypedArray(),
    )
}

internal fun FirebaseFirestoreService.traced(): FirebaseFirestoreService =
    if (Log.isDebugLoggingEnabled) FirebaseFirestoreServiceTraced(this) else this

internal class FirebaseFirestoreServiceTraced(private val firebaseFirestoreService: FirebaseFirestoreService) :
    FirebaseFirestoreService() {
    override fun observeDocument(
        collectionRef: String,
        documentRef: String,
        callback: Callback<FirebaseFirestoreDocumentSnapshot>,
    ): Destructor {
        throw UnsupportedOperationException(
            "Should never be called as `observeDocumentAsFlow` is only entry point",
            /* ... and it uses the `firebaseFirestoreService` directly */
        )
    }

    override fun <T> observeDocumentAsFlow(
        collectionRef: String,
        documentRef: String,
        mapExists: suspend (FirebaseFirestoreDocumentSnapshot.Exists) -> Result<T>,
        valueForNotExists: () -> Result<T>,
    ): Flow<Result<T>> =
        firebaseFirestoreService.observeDocumentAsFlow(
            collectionRef,
            documentRef,
            mapExists = mapExists,
            valueForNotExists = valueForNotExists,
        )
            .traced(
                areaId = "FirebaseFirestoreService.observeDocument($collectionRef, $documentRef)",
            )

    override fun getCollection(
        ref: String,
        dataSource: DataSource,
        callback: Callback<Array<FirebaseFirestoreDocumentSnapshot>>,
    ) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseFirestoreService.getCollection($ref)",
            sourceAreaId = "FirebaseFirestoreService.getCollection",
        )
        firebaseFirestoreService.getCollection(ref, dataSource, taggedCallback)
    }

    override fun getDocument(
        collectionRef: String,
        documentRef: String,
        dataSource: DataSource,
        callback: Callback<FirebaseFirestoreDocumentSnapshot>,
    ) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseFirestoreService.getDocument($collectionRef, $documentRef)",
            sourceAreaId = "FirebaseFirestoreService.getDocument",
        )
        firebaseFirestoreService.getDocument(collectionRef, documentRef, dataSource, taggedCallback)
    }

    override fun setDocument(
        collectionRef: String,
        documentRef: String,
        value: BoundaryMap<Any?>,
        merge: Boolean,
        callback: Callback<Unit>,
    ) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseFirestoreService.setDocument($collectionRef, $documentRef, $value)",
            sourceAreaId = "FirebaseFirestoreService.setDocument",
        )
        firebaseFirestoreService.setDocument(collectionRef, documentRef, value, merge, taggedCallback)
    }

    override fun queryDocuments(collectionRef: String): DocumentQueryBuilder {
        val builder = firebaseFirestoreService.queryDocuments(collectionRef)
        return DocumentQueryBuilder(
            fetchImpl = { q, d, c ->
                val (uuid, taggedCallback) = c.uuidCallback()
                Log.d(
                    "[$uuid] CALL FirebaseFirestoreService.queryDocuments.fetchImpl($collectionRef, $q)",
                    sourceAreaId = "FirebaseFirestoreService.queryDocuments.fetchImpl",
                )
                builder.fetchImpl(q, d, taggedCallback)
            },
            countImpl = { q, c ->
                val (uuid, taggedCallback) = c.uuidCallback()
                Log.d(
                    "[$uuid] CALL FirebaseFirestoreService.queryDocuments.countImpl($collectionRef, $q)",
                    sourceAreaId = "FirebaseFirestoreService.queryDocuments.countImpl",
                )
                builder.countImpl(q, taggedCallback)
            },
            observeImpl = { q, c ->
                val (uuid, taggedCallback) = c.uuidCallback()
                Log.d(
                    "[$uuid] CALL FirebaseFirestoreService.queryDocuments.fetchImpl($collectionRef, $q)",
                    sourceAreaId = "FirebaseFirestoreService.queryDocuments.observeImpl",
                )
                builder.observeImpl(q, taggedCallback)
            },
        )
    }

    override fun updateDocument(
        collectionRef: String,
        documentRef: String,
        value: BoundaryMap<Any?>,
        callback: Callback<Unit>,
    ) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseFirestoreService.updateDocument($collectionRef, $documentRef, $value)",
            sourceAreaId = "FirebaseFirestoreService.updateDocument",
        )
        firebaseFirestoreService.updateDocument(collectionRef, documentRef, value, taggedCallback)
    }

    override fun deleteDocument(collectionRef: String, documentRef: String, callback: Callback<Unit>) {
        val (uuid, taggedCallback) = callback.uuidCallback()
        Log.d(
            "[$uuid] CALL FirebaseFirestoreService.deleteDocument($collectionRef, $documentRef)",
            sourceAreaId = "FirebaseFirestoreService.deleteDocument",
        )
        firebaseFirestoreService.deleteDocument(collectionRef, documentRef, taggedCallback)
    }
}
