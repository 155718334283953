package com.speechify.client.helpers.audio.controller

import com.speechify.client.api.audio.VoiceSpecOfAvailableVoice
import com.speechify.client.api.content.ContentCursor

internal sealed class AudioControllerCommand {
    data class Play(val cursor: ContentCursor) : AudioControllerCommand()
    data class Seek(val cursor: ContentCursor, val impliesUserInteraction: Boolean = true) : AudioControllerCommand()
    object Pause : AudioControllerCommand()
    object Resume : AudioControllerCommand()
    data class SetSpeed(val speedInWordsPerMinute: Int) : AudioControllerCommand()
    data class SetVoice(
        val voice: VoiceSpecOfAvailableVoice?,
    ) : AudioControllerCommand()
    object ReloadContent : AudioControllerCommand()

    override fun toString(): String = this::class.simpleName ?: this::class.toString()
}
