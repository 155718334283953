package com.speechify.client.api.audio

import kotlin.js.JsExport

@JsExport
enum class VoiceGender {
    MALE,
    FEMALE,
    UNSPECIFIED,
}
