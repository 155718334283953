package com.speechify.client.internal.util

import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import kotlin.coroutines.cancellation.CancellationException

/**
 * Note - this function will log using explicit log event and will also log [kotlinx.coroutines.CancellationException]s
 * (see #LoggingCancellations below).
 * See [com.speechify.client.internal.util.extensions.coroutines.launchWithoutFailingOnError] for a non-awaiting
 * version (just returns the Job) that also logs the errors using the Kotlin's built-in coroutine-context error handling (and always ignores [CancellationException]s).
 *
 * NOTE: This function is only valid to use on [Unit]-result [block]s.
 */
internal inline fun runCatchingSilencingErrorsLoggingThem(
    /**
     * The [DiagnosticEvent.sourceAreaId] to use for logging, to be able to identify the source of the error.
     */
    sourceAreaId: String,
    /**
     * Whether to ignore [CancellationException]s and not log them.
     * NOTE: Even though the convention is to not log them (as per Kotlin's interpretation of the cancellations
     * representing something that the user requested), exceptions to this rule are possible and should be considered
     * (For example when a producer of some tokens of information expects that consumers receive all the tokens,
     * or else a loss of consistency could occur, or if the producer was told to stop sending the information and
     * ignored it such is the case for [com.speechify.client.internal.util.collections.flows.callbackFlowNeverThrowingToProducer])
     * In that case the [CancellationException]s should be logged, to notify developers that the behavior needs to
     * change.
     */
    shouldIgnoreCancellationExceptions: Boolean,
    /**
     * The [DiagnosticEvent.properties] to add to the error.
     */
    properties: Map<String, Any>? = null,
    /**
     * You can entirely override the logging behavior by providing this function.
     * It effectively makes this function purpose just grouping similar cases where we are preventing a throw at
     * all costs (e.g. because the caller does not expect an error and will crash otherwise).
     */
    noinline overrideLogging: ((exception: Throwable, sourceAreaId: String, properties: Map<String, Any>?) -> Unit)? =
        null,
    block: () -> Unit,
) {
    try {
        block()
    } catch (e: Throwable) {
        if (e is CancellationException && shouldIgnoreCancellationExceptions) {
            return
        }

        if (overrideLogging != null) {
            overrideLogging(e, sourceAreaId, properties)
        } else {
            Log.e(
                DiagnosticEvent(
                    message = "Error in callback was prevented from going to the un-expecting caller" +
                        " to avoid critical error.",
                    e,
                    sourceAreaId = sourceAreaId,
                    properties = properties,
                ),
            )
        }
    }
}
