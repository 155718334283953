@file:OptIn(ExperimentalTime::class)

package com.speechify.client.internal.util.extensions.collections.flows

import com.speechify.client.internal.util.time.DurationWithStartAndEndTime
import com.speechify.client.internal.util.time.ItemWithPreviousConsumptionMeasurement
import com.speechify.client.internal.util.time.TimedValueWithStartAndEnd
import com.speechify.client.internal.util.time.measureTimedValueWithStartAndEnd
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.transform
import kotlin.time.ComparableTimeMark
import kotlin.time.ExperimentalTime
import kotlin.time.TimeSource

internal fun <T> Flow<T>.withConsumptionMeasurementWithStartAndEnd(): Flow<ItemWithPreviousConsumptionMeasurement<T>> =
    flow {
        var lastConsumptionMeasurement: TimedValueWithStartAndEnd<T>? = null
        this@withConsumptionMeasurementWithStartAndEnd
            .transform { item ->
                val currentConsumptionWithMeasurement = measureTimedValueWithStartAndEnd(
                    block = {
                        this@transform.emit(item)
                        item
                    },
                )
                /** Note, the above `emit` in the `transform` makes us go into the `collect` below first, so it will
                 *  observe the [lastConsumptionMeasurement] already, so here we are in fact preparing the value for
                 *  the **next** iteration.
                 *  **/
                lastConsumptionMeasurement = currentConsumptionWithMeasurement
            }
            .collect {
                this@flow.emit(
                    ItemWithPreviousConsumptionMeasurement(
                        currentItem = it,
                        previousItemWithConsumptionMeasurement = lastConsumptionMeasurement,
                    ),
                )
            }
    }

@OptIn(ExperimentalTime::class)
internal fun <T> Flow<T>.withProductionTimeMeasurementWithStartAndEnd(): Flow<ValueWithProductionTimeMeasurement<T>> {
    var nextProductionStartTimeMark: ComparableTimeMark = TimeSource.Monotonic.markNow()
    return this
        .transform { value ->
            val thisProductionDuration = nextProductionStartTimeMark.elapsedNow()
            emit(
                ValueWithProductionTimeMeasurement(
                    value = value,
                    productionTimeMeasurement = DurationWithStartAndEndTime(
                        startTimeMark = nextProductionStartTimeMark,
                        duration = thisProductionDuration,
                    ),
                ),
            )

            nextProductionStartTimeMark = TimeSource.Monotonic.markNow()
        }
}

internal class ValueWithProductionTimeMeasurement<T>(
    val value: T,
    val productionTimeMeasurement: DurationWithStartAndEndTime<T>,
)
