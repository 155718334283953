package com.speechify.client.internal.services.subscription.models

import com.speechify.client.api.services.subscription.models.SubscriptionSource

object FirebaseSubscriptionSource {
    const val APPLE = "apple"
    const val PLAY_STORE = "play_store"
    const val STRIPE = "stripe"
    const val PAYPAL = "paypal"
    const val TEAMS = "teams"
}

internal fun String.toSubscriptionSource(): SubscriptionSource {
    return when (this) {
        FirebaseSubscriptionSource.APPLE -> SubscriptionSource.APPLE
        FirebaseSubscriptionSource.PLAY_STORE -> SubscriptionSource.PLAY_STORE
        FirebaseSubscriptionSource.STRIPE -> SubscriptionSource.STRIPE
        FirebaseSubscriptionSource.PAYPAL -> SubscriptionSource.PAYPAL
        FirebaseSubscriptionSource.TEAMS -> SubscriptionSource.TEAMS
        else -> SubscriptionSource.OTHER
    }
}
