package com.speechify.client.api.util.boundary

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.JsExport
import kotlin.js.JsName

/**
 * Allows to pass a text value without having to fully load it into memory (possibly slowing the app down on memory
 * allocation and risking an out-of-memory crash). An equivalent of [Java's `Reader`](https://docs.oracle.com/javase/7/docs/api/java/io/Reader.html).
 * It builds on the abstraction of [File] (or, more precisely,an `InputStream` [in Java](https://docs.oracle.com/javase/7/docs/api/java/io/InputStream.html), or [or Swift](https://developer.apple.com/documentation/foundation/inputstream),
 * and abstracts from the reader the need to know the character encoding to decode the text data.
 */
@JsExport
interface TextReader {
    /**
     * @return `null` if reached the end.
     */
    @JsName("getNextPart")
    fun getNextPart(
        callback: Callback<String?>,
    )
}

/**
 * Reads the entire text to a single string.
 */
fun TextReader.readAll(callback: Callback<String>) =
    callback.fromCo { coReadAll() }

/**
 * The simplest [TextReader] for cases where the string has already been read, so there's no way to do any
 * memory-allocation optimization.
 */
@JsExport
class TextReaderFromString(
    /**
     * The entire text that this reader holds.
     */
    textValue: String,
) : TextReader {

    override fun getNextPart(callback: Callback<String?>) =
        callback.fromCo { coGetNextPart().successfully() }

    internal fun coGetNextPart(): String? = if (iterator.hasNext()) iterator.next() else null

    private val iterator = sequenceOf(textValue).iterator()
}

internal suspend fun TextReader.coReadAll(): Result<String> =
    readAllToList().map { it.joinToString() }

private suspend fun TextReader.readAllToList(): Result<List<String>> {
    /* TODO - see if there's a primitive for this (`sequence` is not the one, as it doesn't allow calling suspend
         functions other than its own `yield*` - see https://github.com/Kotlin/KEEP/blob/master/proposals/coroutines.md#restricted-suspension)*/
    val results = mutableListOf<String>()
    do {
        val nextOrNullIfEnd = this.coGetNextPart().orReturn { return it }
        if (nextOrNullIfEnd == null) {
            break
        } else {
            results.add(nextOrNullIfEnd)
        }
    } while (true)

    return results.successfully()
}

internal suspend fun TextReader.coGetNextPart(): Result<String?> =
    suspendCoroutine {
        this.getNextPart(it::resume)
    }
