package com.speechify.client.internal.caching

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.toFile
import com.speechify.client.api.util.io.withMimeType
import com.speechify.client.api.util.orThrow
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.util.www.UrlString

/**
 * Convenience base for implementing [FileByIdProvider] from a remote http-backed-source, with caching to a
 * [BlobStorageAdapter].
 */
internal abstract class CachedRemoteFileProviderWithHttpDownload<FileIdType : FileId>(
    protected val diagnosticAreaId: String,
    protected val cacheStorage: BlobStorageAdapter,
    private val httpClient: HttpClient,
) : FileByIdProvider<FileIdType> {
    @Deprecated(
        "Ensure your [cacheStorage] implements [getBlob] and use getBinaryContent instead",
        ReplaceWith("getBinaryContent(key)"),
    )
    override suspend fun getFile(fileId: FileIdType): File = withTelemetry(
        "$diagnosticAreaId.getFile",
    ) { telemetry ->
        val cachedFile = cacheStorage.getBytes(getCacheKey(fileId))
            .orThrow()

        telemetry.addProperty("hit", cachedFile != null)

        if (cachedFile != null) {
            return@withTelemetry cachedFile
                .toFile()
        }

        val content = httpClient.getBinaryContentReadableSequentially(
            url = getDownloadUrl(fileId),
        )
            .orThrow()

        val storedContent = cacheStorage
            .coPutBytesGetReadableRandomly(
                key = getCacheKey(fileId),
                content = content,
            )
            .orThrow()

        return@withTelemetry storedContent
            .toFile()
    }

    override suspend fun getBinaryContent(fileId: FileIdType):
        BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly> = withTelemetry(
        "$diagnosticAreaId.getBinaryContent",
    ) { telemetry ->
        val cachedFile = cacheStorage.coGetBlob(getCacheKey(fileId))
            .orThrow()

        telemetry.addProperty("hit", cachedFile != null)

        if (cachedFile != null) {
            return@withTelemetry cachedFile
        }

        val content = httpClient.getBinaryContentReadableSequentially(
            url = getDownloadUrl(fileId),
        )
            .orThrow()

        val storedContent = cacheStorage
            .coPutSequenceGetReadableRandomly(
                key = getCacheKey(fileId),
                content = content,
            )
            .orThrow()

        return@withTelemetry storedContent
            .withMimeType(content.mimeType)
    }

    abstract suspend fun getDownloadUrl(fileId: FileIdType): UrlString

    protected fun getCacheKey(fileId: FileIdType): BlobStorageKey =
        BlobStorageKey(fileId.stringValue)
}
