package com.speechify.client.internal.services.db

import app.cash.sqldelight.ColumnAdapter
import app.cash.sqldelight.EnumColumnAdapter
import app.cash.sqldelight.adapter.primitive.IntColumnAdapter
import app.cash.sqldelight.async.coroutines.awaitAsList
import com.speechify.client.api.AdaptersProvider
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.blobstorage.BlobStorageKey
import com.speechify.client.api.adapters.db.AbstractSqlDriverFactoryClassName
import com.speechify.client.api.adapters.ocr.OCRResult
import com.speechify.client.api.audio.AudioMediaFormat
import com.speechify.client.api.audio.VoiceSpec
import com.speechify.client.api.audio.caching.CachedSynthesisResponse
import com.speechify.client.api.audio.caching.VoiceIdForDb
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.pdf.ContentSortingStrategy
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.api.services.importing.models.ImportOptions
import com.speechify.client.api.services.library.offline.AudioDownloadOptions
import com.speechify.client.api.services.library.offline.StaticContentTransformOptions
import com.speechify.client.api.util.MimeType
import com.speechify.client.helpers.content.standard.html.HtmlContentLoadOptions
import com.speechify.client.helpers.features.ListeningProgress
import com.speechify.client.internal.sqldelight.Database
import com.speechify.client.internal.sqldelight.DownloadedAudioForItem
import com.speechify.client.internal.sqldelight.LocalListeningProgress
import com.speechify.client.internal.sqldelight.LocalListeningProgressQueries
import com.speechify.client.internal.sqldelight.PendingImport
import com.speechify.client.internal.sqldelight.PendingImportQueries
import com.speechify.client.internal.sqldelight.ScannedPage
import com.speechify.client.internal.sqldelight.ScannedPageQueries
import com.speechify.client.internal.sqldelight.SentenceIndex
import com.speechify.client.internal.sqldelight.SynthesisResult
import com.speechify.client.internal.sqldelight.VoiceCacheQueries
import com.speechify.client.internal.sync.CoLazy
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
/**
 * The data class representing the raw data in the database. Not to be confused
 * with [com.speechify.client.internal.services.importing.models.ItemRequiringImport].
 */
internal typealias DBPendingImport = PendingImport

class DbService(private val adaptersProvider: AdaptersProvider) {

    private val database: CoLazy<Database?> = coLazy {
        val driver = adaptersProvider.sqlDriverFactory?.createSqlDriver(adaptersProvider.localKeyValueStorage)
            ?: return@coLazy null
        Database(
            driver = driver,
            localListeningProgressAdapter = LocalListeningProgress.Adapter(
                speechifyUriAdapter = speechifyUriAdapter,
                listeningProgressAdapter = listeningProgressAdapter,
            ),
            pendingImportAdapter = PendingImport.Adapter(
                speechifyUriAdapter = speechifyUriAdapter,
                primaryFileBlobStorageKeyAdapter = blobStorageKeyAdapter,
                scannedPagesAdapter = scannedPagesAdapter,
                importOptionsAdapter = importOptionsAdapter,
                htmlContentLoadOptionsAdapter = htmlContentLoadOptionsAdapter,
                attemptsPerformedCountAdapter = IntColumnAdapter,
                importTypeAdapter = EnumColumnAdapter(),
                lastUpdatedAtAdapter = dateTimeColumnAdapter,
                listeningProgressAdapter = listeningProgressAdapter,
                wasLastErrorConnectionErrorAdapter = dbBooleanAdapter,
                mimeTypeAdapter = mimeTypeAdapter,
            ),
            synthesisResultAdapter = SynthesisResult.Adapter(
                voiceIdAdapter = voiceIdForDbAdapter,
                synthesisMetadataAdapter = synthesisMetadataAdapter,
            ),
            sentenceIndexAdapter = SentenceIndex.Adapter(
                documentUriAdapter = speechifyUriAdapter,
                voiceIdAdapter = voiceIdForDbAdapter,
            ),
            downloadedAudioForItemAdapter = DownloadedAudioForItem.Adapter(
                documentUriAdapter = speechifyUriAdapter,
                voiceIdAdapter = voiceIdForDbAdapter,
                downloadOptionsAdapter = downloadOptionsAdapter,
                startCursorAdapter = contentCursorAdapter,
                endCursorAdapter = contentCursorAdapter,
                hasGapsInAudioAdapter = dbBooleanAdapter,
            ),
            scannedPageAdapter = ScannedPage.Adapter(
                speechifyUriAdapter = speechifyUriAdapter,
                blobStorageKeyAdapter = blobStorageKeyAdapter,
                ocrResultAdapter = ocrResultAdapter,
                pageIndexAdapter = IntColumnAdapter,
            ),
        ).apply {
            /**
             * This migration moves scanned pages data from pendingImport.scannedPages (JSON column)
             * to the dedicated scannedPage table.
             * We need to do this because the scannedPages column in pendingImport is a JSON column,
             * and JSON operation are not supported by SQLDelight during migrations.
             */
            migrateScannedPagesFromPendingImportToScannedPages(this)
        }
    }

    /**
     * Migrates scanned pages data from the pendingImport table to the dedicated scanned_pages table.
     *
     * This migration function performs the following steps:
     * 1. Retrieves all pending imports that contain scanned pages
     * 2. For each pending import:
     *    - Creates individual scanned page entries in the scannedPage table
     *    - Updates the original pending import record to clear its scanned pages data
     *
     * The migration preserves the following data for each page:
     * - The document's SpeechifyURI
     * - The blob storage key for the scanned file
     * - OCR results (if any)
     * - The original page ordering (via index)
     *
     * If any errors occur during migration, they are logged but won't crash the application.
     */
    private suspend fun migrateScannedPagesFromPendingImportToScannedPages(database: Database) {
        val scannedPendingImports = database.pendingImportQueries.getScannedPendingImports().awaitAsList()
        try {
            scannedPendingImports.forEach { pendingImport ->
                pendingImport.scannedPages.forEachIndexed { index, scannedPage ->
                    database.scannedPageQueries.addScannedPage(
                        ScannedPage(
                            speechifyUri = pendingImport.speechifyUri,
                            blobStorageKey = scannedPage.localStorageKey,
                            ocrResult = scannedPage.ocrResult,
                            pageIndex = index,
                        ),
                    )
                }
                database.pendingImportQueries.updateScannedPages(
                    null,
                    pendingImport.speechifyUri,
                )
            }
        } catch (e: Exception) {
            Log.e(
                DiagnosticEvent(
                    sourceAreaId = "DbService.migrateScannedPagesFromPendingImportToScannedPages",
                    nativeError = e,
                ),
            )
        }
    }

    suspend fun getPendingImportQueries(): PendingImportQueries =
        getDatabaseForRequiredAction().pendingImportQueries

    suspend fun getScannedPageQueries(): ScannedPageQueries =
        getDatabaseForRequiredAction().scannedPageQueries

    /**
     * Use when lack of database is known not to be a problem for the intent of the calling function.
     * If lack of database is a problem for the calling function's intent, use [getPendingImportQueries] instead.
     * (if the intent is not clear from the function name, use [getPendingImportQueries], but a redesign/refactor should
     * be considered).
     */
    suspend fun getPendingImportQueriesForOptionalAction(): PendingImportQueries? =
        getDatabaseForOptionalAction()?.pendingImportQueries

    suspend fun getLocalListeningProgressQueries(): LocalListeningProgressQueries =
        getDatabaseForRequiredAction().localListeningProgressQueries

    suspend fun getVoiceCacheQueries(): VoiceCacheQueries =
        getDatabaseForRequiredAction().voiceCacheQueries

    private suspend fun getDatabaseForOptionalAction() =
        database.get()

    private suspend fun getDatabaseForRequiredAction() =
        getDatabaseForOptionalAction()
            ?: throw IllegalStateException(
                "An action that requires $AbstractSqlDriverFactoryClassName was invoked, " +
                    "but the SDK-consumer has not provided one. Consider if this is a case for a redesign, so that " +
                    "the action isn't invoked, or a case for SDK-consumer to start providing " +
                    "$AbstractSqlDriverFactoryClassName.",
            )
}

@kotlinx.serialization.Serializable
data class DbOcrFile(
    val localStorageKey: BlobStorageKey,
    val ocrResult: OCRResult?,
)

/**
 * Represents a scanned page file in the database.
 * Contains the [localStorageKey] of the scanned page file, the [speechifyURI] of the document it belongs to,
 * and the [pageIndex] of the scanned page in the document.
 * This class does not contain the OCR result for the scanned page.
 * To get the OCR result, use [ImportingService.getScannedPagesFor].
 */
internal data class DbScannedPageFile(
    val localStorageKey: BlobStorageKey,
    private val speechifyURI: SpeechifyURI,
    private val pageIndex: Int,
)

/**
 * Converts a [DbScannedPageFile] to a [DbOcrFile] with a null [OCRResult].
 */
internal fun DbScannedPageFile.toDbOcrFileWithNoOcrResult(): DbOcrFile {
    return DbOcrFile(
        localStorageKey,
        null,
    )
}

/**
 * A Json instance that is configured to be lenient to unknown keys and special floating point values.
 * Useful since sometimes NaN, Infinity, and -Infinity are returned from the OCR engine.
 */
private val dbJson = Json {
    ignoreUnknownKeys = true
    allowSpecialFloatingPointValues = true
    encodeDefaults = true
}

private val synthesisMetadataAdapter = object : ColumnAdapter<CachedSynthesisResponse, String> {
    override fun decode(databaseValue: String): CachedSynthesisResponse = dbJson.decodeFromString(databaseValue)

    override fun encode(value: CachedSynthesisResponse): String = dbJson.encodeToString(value)
}

private val speechifyUriAdapter = object : ColumnAdapter<SpeechifyURI, String> {
    override fun decode(databaseValue: String): SpeechifyURI = SpeechifyURI.fromString(databaseValue)

    override fun encode(value: SpeechifyURI): String = value.toString()
}

private val scannedPagesAdapter = object : ColumnAdapter<List<DbOcrFile>, String> {
    override fun decode(databaseValue: String): List<DbOcrFile> =
        if (databaseValue.isEmpty()) {
            emptyList()
        } else {
            dbJson.decodeFromString(databaseValue)
        }

    override fun encode(value: List<DbOcrFile>) = dbJson.encodeToString(value)
}

private val importOptionsAdapter = object : ColumnAdapter<ImportOptions, String> {
    override fun decode(databaseValue: String): ImportOptions =
        if (databaseValue.isEmpty()) {
            ImportOptions()
        } else {
            dbJson.decodeFromString(databaseValue)
        }

    override fun encode(value: ImportOptions) = dbJson.encodeToString(value)
}

private val htmlContentLoadOptionsAdapter = object : ColumnAdapter<HtmlContentLoadOptions, String> {
    override fun decode(databaseValue: String): HtmlContentLoadOptions =
        if (databaseValue.isEmpty()) {
            HtmlContentLoadOptions(
                null,
                null,
                null,
                null,
            )
        } else {
            dbJson.decodeFromString(databaseValue)
        }

    override fun encode(value: HtmlContentLoadOptions) = dbJson.encodeToString(value)
}

private val blobStorageKeyAdapter = object : ColumnAdapter<BlobStorageKey, String> {
    override fun decode(databaseValue: String): BlobStorageKey = BlobStorageKey(databaseValue)

    override fun encode(value: BlobStorageKey): String = value.originalKey
}

private val dateTimeColumnAdapter = object : ColumnAdapter<DateTime, Double> {
    override fun decode(databaseValue: Double): DateTime = DateTime.fromSeconds(databaseValue)

    override fun encode(value: DateTime): Double = value.asSeconds().toDouble()
}

private val listeningProgressAdapter = object : ColumnAdapter<ListeningProgress, String> {
    override fun decode(databaseValue: String): ListeningProgress = dbJson.decodeFromString(databaseValue)

    override fun encode(value: ListeningProgress): String = dbJson.encodeToString(value)
}

/** It was observed in JS that the native boolean in SQLDelight was returned as an int.
 * so we manually wrap them
 */
// Unfortunately needs to be public since SQLDelight makes all DB interfaces public.
data class DbBoolean(val value: Boolean)

private val dbBooleanAdapter = object : ColumnAdapter<DbBoolean, Long> {
    override fun decode(databaseValue: Long): DbBoolean = if (databaseValue == 0L) {
        DbBoolean(false)
    } else {
        DbBoolean(true)
    }

    override fun encode(value: DbBoolean): Long = if (value.value) 1 else 0
}

private val voiceIdForDbAdapter = ColumnAdapterForTypeAlias<VoiceIdForDb>()

private val mimeTypeAdapter = object : ColumnAdapter<MimeType, String> {
    override fun decode(databaseValue: String): MimeType = MimeType(databaseValue)

    override fun encode(value: MimeType): String = value.fullString
}

private val downloadOptionsAdapter = object : ColumnAdapter<AudioDownloadOptions, String> {
    override fun decode(databaseValue: String) = try {
        dbJson.decodeFromString<AudioDownloadOptionsV4>(databaseValue).toAudioDownloadOptions()
    } catch (t: Throwable) {
        dbJson.decodeFromString<AudioDownloadOptionsV3>(databaseValue).toAudioDownloadOptions()
    } catch (t: Throwable) {
        dbJson.decodeFromString<AudioDownloadOptionsV2>(databaseValue).toAudioDownloadOptions()
    } catch (t: Throwable) {
        dbJson.decodeFromString<AudioDownloadOptionsV1>(databaseValue).toAudioDownloadOptions()
    }

    override fun encode(value: AudioDownloadOptions) = dbJson.encodeToString(value.toV4())
}

private val contentCursorAdapter = object : ColumnAdapter<ContentCursor, String> {
    override fun decode(databaseValue: String): ContentCursor = Json.decodeFromString(databaseValue)

    override fun encode(value: ContentCursor) = Json.encodeToString(value)
}

private val ocrResultAdapter = object : ColumnAdapter<OCRResult, String> {
    override fun decode(databaseValue: String): OCRResult =
        dbJson.decodeFromString(databaseValue)

    override fun encode(value: OCRResult) = dbJson.encodeToString(value)
}

private class ColumnAdapterForTypeAlias<T : Any> : ColumnAdapter<T, T> {
    override fun decode(databaseValue: T): T =
        databaseValue

    override fun encode(value: T): T =
        value
}

@Serializable
internal class AudioDownloadOptionsV1(
    val voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServerPersisted,
    val contentTransformOptions: StaticContentTransformOptions,
    val allowOcrFallbackForEmptyPDF: Boolean,
    val allowExperimentalContentSorting: Boolean,
    val audioMediaFormat: AudioMediaFormat,
    val allowMlPageParsing: Boolean = false,
)

@Serializable
internal class AudioDownloadOptionsV2(
    val voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServerPersisted,
    val contentTransformOptions: StaticContentTransformOptions,
    val ocrFallbackStrategy: OcrFallbackStrategy,
    val allowExperimentalContentSorting: Boolean,
    val audioMediaFormat: AudioMediaFormat,
    val allowMlPageParsing: Boolean = false,
)

@Serializable
internal class AudioDownloadOptionsV3(
    val voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServerPersisted,
    val contentTransformOptions: StaticContentTransformOptions,
    val ocrFallbackStrategy: OcrFallbackStrategy,
    val contentSortingStrategy: ContentSortingStrategy,
    val audioMediaFormat: AudioMediaFormat,
    val allowMlPageParsing: Boolean = false,
)

@Serializable
internal class AudioDownloadOptionsV4(
    val voice: VoiceSpec.VoiceSpecForMediaVoiceFromAudioServerPersisted,
    val contentTransformOptions: StaticContentTransformOptions,
    val ocrFallbackStrategy: OcrFallbackStrategy,
    val contentSortingStrategy: ContentSortingStrategy,
    val audioMediaFormat: AudioMediaFormat,
    val mlParsingMode: MLParsingMode = MLParsingMode.ForceDisable,
)

internal fun AudioDownloadOptionsV1.toAudioDownloadOptions() = AudioDownloadOptions(
    voice = voice,
    contentTransformOptions = contentTransformOptions,
    ocrFallbackStrategy = if (allowOcrFallbackForEmptyPDF) {
        OcrFallbackStrategy.ConservativeLegacyStrategy
    } else {
        OcrFallbackStrategy.ForceDisable
    },
    contentSortingStrategy = if (allowExperimentalContentSorting) {
        ContentSortingStrategy.ExperimentalV1
    } else {
        ContentSortingStrategy.None
    },
    audioMediaFormat = audioMediaFormat,
    mlParsingMode = if (allowMlPageParsing) {
        MLParsingMode.ForceEnable
    } else {
        MLParsingMode.ForceDisable
    },
)

internal fun AudioDownloadOptionsV2.toAudioDownloadOptions() = AudioDownloadOptions(
    voice = voice,
    contentTransformOptions = contentTransformOptions,
    ocrFallbackStrategy = ocrFallbackStrategy,
    contentSortingStrategy = if (allowExperimentalContentSorting) {
        ContentSortingStrategy.ExperimentalV1
    } else {
        ContentSortingStrategy.None
    },
    audioMediaFormat = audioMediaFormat,
    mlParsingMode = if (allowMlPageParsing) {
        MLParsingMode.ForceEnable
    } else {
        MLParsingMode.ForceDisable
    },
)

internal fun AudioDownloadOptionsV3.toAudioDownloadOptions() = AudioDownloadOptions(
    voice = voice,
    contentTransformOptions = contentTransformOptions,
    ocrFallbackStrategy = ocrFallbackStrategy,
    contentSortingStrategy = contentSortingStrategy,
    audioMediaFormat = audioMediaFormat,
    mlParsingMode = if (allowMlPageParsing) {
        MLParsingMode.ForceEnable
    } else {
        MLParsingMode.ForceDisable
    },
)

internal fun AudioDownloadOptionsV4.toAudioDownloadOptions() = AudioDownloadOptions(
    voice = voice,
    contentTransformOptions = contentTransformOptions,
    ocrFallbackStrategy = ocrFallbackStrategy,
    contentSortingStrategy = contentSortingStrategy,
    audioMediaFormat = audioMediaFormat,
    mlParsingMode = mlParsingMode,
)

internal fun AudioDownloadOptions.toV4() = AudioDownloadOptionsV4(
    voice = voice,
    contentTransformOptions = contentTransformOptions,
    ocrFallbackStrategy = ocrFallbackStrategy,
    contentSortingStrategy = contentSortingStrategy,
    audioMediaFormat = audioMediaFormat,
    mlParsingMode = mlParsingMode,
)
