package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.util.extensions.collections.filterNotNullSecond

/**
 * Similar to [kotlin.collections.listOfNotNull], but for [Map]s, filtering to only pairs with non-null values.
 */
internal fun <K, V> mapOfNotNullValues(vararg pairs: Pair<K, V?>): Map<K, V> =
    mapOf(
        *(
            pairs.toList()
                .filterNotNullSecond()
                .toTypedArray()
            ),
    )
