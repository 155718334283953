package com.speechify.client.api.services.subscription

import com.speechify.client.api.ClientConfig
import com.speechify.client.api.adapters.keyvalue.LocalKeyValueStorageAdapter
import com.speechify.client.api.services.subscription.models.BillingDashboardOptions
import com.speechify.client.api.services.subscription.models.Coupon
import com.speechify.client.api.services.subscription.models.Entitlements
import com.speechify.client.api.services.subscription.models.OneClickRenewal
import com.speechify.client.api.services.subscription.models.OneClickRenewalStatus
import com.speechify.client.api.services.subscription.models.RenewalFrequency
import com.speechify.client.api.services.subscription.models.RewardBalance
import com.speechify.client.api.services.subscription.models.Subscription
import com.speechify.client.api.services.subscription.models.SubscriptionAndEntitlements
import com.speechify.client.api.services.subscription.models.SubscriptionCreationResponse
import com.speechify.client.api.services.subscription.models.SubscriptionPlan
import com.speechify.client.api.services.subscription.models.SubscriptionPreparation
import com.speechify.client.api.services.subscription.models.SubscriptionPricingResult
import com.speechify.client.api.services.subscription.models.SubscriptionPurchase
import com.speechify.client.api.services.subscription.models.SubscriptionRestore
import com.speechify.client.api.services.subscription.models.SubscriptionValidation
import com.speechify.client.api.services.subscription.models.ValidateReceiptResult
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Destructor
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.Result.Failure
import com.speechify.client.api.util.Result.Success
import com.speechify.client.api.util.Service
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.fromCoWithErrorLogging
import com.speechify.client.api.util.fromCoWithErrorLoggingGetJob
import com.speechify.client.api.util.multiShotFromFlowIn
import com.speechify.client.api.util.successfully
import com.speechify.client.internal.createTopLevelCoroutineScope
import com.speechify.client.internal.services.auth.AuthService
import com.speechify.client.internal.services.subscription.PlatformFetcher
import com.speechify.client.internal.services.subscription.SubscriptionsFirebaseDataFetcher
import com.speechify.client.internal.toDestructor
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

/**
 * Provides methods to retrieve, listen, and manipulate subscription and entitlements information.
 * All methods in the service expect that user is authenticated with credentials or as anonymous user.
 */
@JsExport
class SubscriptionService internal constructor(
    clientConfig: ClientConfig,
    authService: AuthService,
    subscriptionsFirebaseDataFetcher: SubscriptionsFirebaseDataFetcher,
    platformFetcher: PlatformFetcher,
    localKeyValueStorageAdapter: LocalKeyValueStorageAdapter,
) : Service {
    private val delegate: SubscriptionServiceDelegate = SubscriptionServiceDelegate(
        clientConfig,
        authService,
        subscriptionsFirebaseDataFetcher,
        platformFetcher,
        localKeyValueStorageAdapter,
    )

    /**
     * Retrieves an active subscription information for a logged-in user and passes it into the callback.
     *
     * @param[callback] will be called with [Success]<[Subscription]?> if user purchased
     * a plan, a null value if user is anonymous or had not purchased a subscription, or [Failure] if the call fails.
     *
     * @return[Destructor] can be called to cancel the request.
     **/
    @Deprecated(
        "Use [getAllSubscriptions] instead. If you require only one subscription, pick first from the list.",
        ReplaceWith("getAllSubscriptions"),
    )
    fun getSubscription(callback: Callback<Subscription?>): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.getSubscription",
    ) {
        delegate.getPrimarySubscription()
    }.toDestructor()

    /**
     * Retrieves all subscriptions from all different product a user has premium for.
     * For instance, a user might have a TTS subscription, and a VoiceOver subscription. This function returns
     * this 2 subscriptions along with their entitlements.
     */
    fun getAllSubscriptions(callback: Callback<SubscriptionAndEntitlements?>): Destructor =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "SubscriptionService.getAllSubscriptions",
        ) {
            delegate.getAllSubscriptions().successfully()
        }.toDestructor()

    /**
     * Subscribes the [callback] to user-subscription information and its changes.
     * Unlike [getAllSubscriptions], this method uses a readily available cached version of the [Subscription] objects,
     * so it will return an item immediately, but it may contain outdated / no information.
     *
     * The listener will be called if there are any changes to user subscription, including but not limited to auth state change, subscription change.
     * The received changes are real-time but are only eventually-consistent.
     *
     * If the same callback is passed more than once it will be deduplicated and called one per change.
     *
     * @param[callback] will be called with [Success]<[Subscription]?> if user purchased
     * a plan, a null value if user is anonymous or had not purchased a subscription, or [Failure]  if setting up the listener fails.  **Note** In this case, the
     * listener will not be added and this operation must be retried.
     *
     * @return A function to unregister the [callback].
     *
     * DANGER: Do not access any other user properties in the [callback] as they may be affected by concurrent user
     * change. Ideally, to prevent this, the listener [callback] should not use any user state from outside its
     * argument. It should especially not expect that listener of other user properties, like
     * [addEntitlementsChangeListener] have already received the values for the new user (they may have a different user
     * data). If other state is required, before every invocation of [com.speechify.client.api.adapters.firebase.FirebaseAuthService.observeCurrentUser] to the SDK,
     * all listeners should be unregistered, to prevent new executions of them, and all current listener executions
     * should be waited on. Only then [com.speechify.client.api.adapters.firebase.FirebaseAuthService.observeCurrentUser]
     * can be executed and only then new listeners should be added *after*.
     * This is a design imperfection of separating lifecycle of the user and its properties. See
     * #UnsafeDesignOfUserMutablePropObservers in the code for related places.
     **/
    fun addSubscriptionChangeListener(callback: Callback<Array<Subscription>?>): Destructor =
        callback.multiShotFromFlowIn(
            flow = delegate.subscriptionsCachingFlowOfResults,
            scope = createTopLevelCoroutineScope(),
        )::destroy

    /**
     * Retrieves entitlements information for a logged-in user.
     * Unlike [getEntitlements], this method uses a readily available cached version of the [Entitlements] object.
     * So it will return immediately, but might contain outdated / no information.
     * We start fetching right when the SDK is constructed, so no information is unlikely, but possible.
     *
     * This can be used in cases where speed is more important than accuracy, for example when determining some kind
     * of defaults. If the information is needs to be accurate, use [getEntitlements] instead.
     *
     * @return [Entitlements] object or null if no object is available yet.
     */
    fun getSubscriptionsLastObserved(
        callback: Callback<Array<Subscription>?>,
    ) = callback.fromCo {
        getSubscriptionsLastObserved()
            .successfully()
    }

    private suspend fun getSubscriptionsLastObserved(): Array<Subscription>? =
        delegate
            .subscriptionsCachingFlow
            .first()

    internal fun getSubscriptionsFlowOfResults(): Flow<Result<Array<Subscription>?>> =
        delegate.subscriptionsCachingFlowOfResults

    /**
     * Makes preparations on the Payment Server for the user to proceed with the purchase flow.
     * This call will return a subscription ID that can be used in the payment flow.
     * Only implemented for PayPal right now.
     *
     * @param[preparation] is an object containing details needed to prepare the purchase.
     * @param[callback] will be called with [Success]<[String]> returning the preliminary subscription id.
     *
     * @return[Unit]
     */
    fun prepareSubscription(preparation: SubscriptionPreparation, callback: Callback<String>) =
        callback.fromCoWithErrorLogging(
            sourceAreaId = "SubscriptionService.prepareSubscription",
        ) {
            delegate.prepareSubscription(preparation)
        }

    /**
     * Creates a subscription for user based on the details provided in the purchase parameter,
     * allowing the user to start using premium Speechify features immediately. After the call is completed expect the
     * subscription and entitlements listeners to be called containing the information that matches the purchase
     * Call this method right after the user completed on-device purchase.
     *
     * @param[purchase] is an object containing details of the purchase, currently supports
     * Apple, PlayStore, Stripe, and PayPal providers.
     * @param[callback] will be called with [Success]<[SubscriptionCreationResponse]> representing a result of the operation.
     *
     * @return[Unit]
     */
    fun createSubscription(
        purchase: SubscriptionPurchase,
        callback: Callback<SubscriptionCreationResponse>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.createSubscription",
    ) {
        delegate.createSubscription(purchase)
    }

    /**
     * Validates if the given purchase is valid based on the requested currency, and the country the users payment
     * method is from.
     *
     * @param[purchase] is an object containing details of the purchase, currently supports Stripe. Other payment
     * providers will result in an error.
     *
     *  @param[callback] will be called with [Success]<[Unit]> if the payment method matched the currency, otherwise
     *  an error will be thrown.
     */
    fun validateCardCountry(
        purchase: SubscriptionPurchase,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<Unit>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.validateCardCountry",
    ) {
        delegate.validateCardCountry(purchase)
    }

    /**
     * Retrieves entitlements information for a logged-in user and passes it into the callback.
     * The [Entitlements] object should be considered as source of truth to decided if user has access to paid features.
     *
     * @param[callback] will be called with [Success]<[Entitlements]>,
     * or [Failure] if the call fails.
     * The [Entitlements] object is also provided for anonymous users or users that never purchased a subscription with 0 values.
     *
     * @return[Destructor] can be called to cancel the request.
     **/
    fun getEntitlements(callback: Callback<Entitlements>) = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.getEntitlements",
    ) {
        delegate.getEntitlementsFromBackend()
    }.toDestructor()

    internal suspend fun coGetEntitlements(): Result<Entitlements> {
        return delegate.getEntitlementsFromBackend()
    }

    /**
     * Retrieves entitlements information for a logged-in user.
     * Unlike [getEntitlements], this method uses a readily available cached version of the [Entitlements] object.
     * So it will return immediately, but might contain outdated / no information.
     * We start fetching right when the SDK is constructed, so no information is unlikely, but possible.
     *
     * This can be used in cases where speed is more important than accuracy, for example when determining some kind
     * of defaults. If the information is needs to be accurate, use [getEntitlements] instead.
     *
     * @return [Entitlements] object or null if no object is available yet.
     */
    fun getEntitlementsLastObserved(
        callback: Callback<Entitlements?>,
    ) = callback.fromCo {
        getEntitlementsLastObserved()
            .successfully()
    }

    internal suspend fun getEntitlementsLastObserved(): Entitlements? =
        delegate
            .entitlementsCachingFlow
            .first()

    internal fun getEntitlementsFlowOfResults(): Flow<Result<Entitlements?>> =
        delegate.entitlementsCachingFlowOfResults

    /**
     * Subscribes the [callback] to user-subscription information and its changes.
     * Unlike [getEntitlements], this method uses a readily available cached version of the [Entitlements] objects,
     * so it will return an item immediately, but it may contain outdated / no information.
     *
     * The [callback] will be called if there are any changes to user entitlements,
     * including but not limited to auth state change, hd words change, subscription change.
     * The [Entitlements] object should be considered the source of truth to decide if user has access to paid features.
     * The received changes are real-time but are only eventually-consistent.
     *
     * If the same callback is passed more than once it will be deduplicated and called once per change.
     *
     * @param[callback] will be called with [Success]<[Entitlements]>,
     * or [Failure] if setting up the listener fails. **Note** In this case, the
     * listener will not be added and this operation must be retried.
     * The [Entitlements] object is also provided for anonymous users or users that never purchased a subscription.
     * @return A function to unregister the [callback].
     *
     * DANGER: Do not access any other user properties in the [callback] as they may be affected by concurrent user
     * change. Ideally, to prevent this, the listener [callback] should not use any user state from outside its
     * argument. It should especially not expect that listener of other user properties, like
     * [addSubscriptionChangeListener] have already received the values for the new user (they may have a different user
     * data). If other state is required, before every invocation of [com.speechify.client.api.adapters.firebase.FirebaseAuthService.observeCurrentUser] to the SDK,
     * all listeners should be unregistered, to prevent new executions of them, and all current listener executions
     * should be waited on. Only then [com.speechify.client.api.adapters.firebase.FirebaseAuthService.observeCurrentUser]
     * can be executed and only then new listeners should be added *after*.
     * This is a design imperfection of separating lifecycle of the user and its properties. See
     * #UnsafeDesignOfUserMutablePropObservers in the code for related places.
     **/
    fun addEntitlementsChangeListener(callback: Callback<Entitlements>): Destructor =
        callback.multiShotFromFlowIn(
            flow = delegate.entitlementsCachingFlowOfResults
                .map { result ->
                    result.map { it ?: Entitlements.NONE }
                },
            scope = createTopLevelCoroutineScope(),
        )::destroy

    /**
     * Validates if a current purchase coupon code is valid and fetches its details. Only Stripe coupons are supported at the moment.
     * @param[couponCode] is a coupon code entered by a customer.
     * @param[callback] will be called with either [Success]<[Coupon]>
     *     if the coupon is valid or with [Failure] if it is not or the call has failed.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun validateCoupon(couponCode: String, callback: Callback<Coupon>) = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.validateCoupon",
    ) {
        delegate.validateCoupon(couponCode)
    }.toDestructor()

    /**
     * Use this method to log how many HD words user listened after a successful listening or a text chunk.
     * Call to this method will decrement [Entitlements.hdWordsLeft] and will trigger listeners attached by [addEntitlementsChangeListener].
     * There is no guarantee that the two will happen synchronously, but the listeners should be called soon after.
     *
     * @param[numberOfWords] is a number of words that user just have listened.
     * @param[callback] will be called with [Success]<[Unit]>
     *     if the call was successful or with [Failure] if it was not.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun logHdWordsListened(numberOfWords: Int, @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "SubscriptionService.logHdWordsListened",
        ) {
            delegate.logHDWordsListened(numberOfWords)
        }.toDestructor()

    /**
     * Use this method to get the billing dashboard URL for the current user.
     * If the user does not have an active subscription, this method will result in a failure.
     * If calling on the Chrome Extension product, pass the extension ID to the billing dashboard options when calling this method.
     *
     * @param[billingDashboardOptions] are options to customize the parameters for the URL of the billing dashboard.
     * @param[callback] will be called with [Success]<[Unit]>
     *     if the call was successful or with [Failure] if it was not.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun getBillingDashboardUrl(
        billingDashboardOptions: BillingDashboardOptions?,
        callback: Callback<String>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.getBillingDashboardUrl",
    ) {
        val options = billingDashboardOptions ?: BillingDashboardOptions()
        delegate.getBillingDashboardUrl(options)
    }.toDestructor()

    /**
     * Use this method to cancel renewals for currently active subscription. At the moment only Stripe, PlayStore,
     * and PayPal subscriptions are supported. For all other types of subscriptions this method will return a [Failure].
     *
     * @param[callback] will be called with [Success]<[Unit]> if the cancellation was successful
     *      or with [Failure] if it was not.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun cancelSubscription(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "SubscriptionService.cancelSubscription",
        ) {
            delegate.cancelSubscription()
        }.toDestructor()

    /**
     * Use this method to cancel renewals for the given subscription. At the moment only Stripe, PlayStore,
     * and PayPal subscriptions are supported. For all other types of subscriptions this method will return a [Failure].
     *
     * @param[callback] will be called with [Success]<[Unit]> if the cancellation was successful
     *      or with [Failure] if it was not.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun cancelSubscriptionById(
        subscriptionId: String,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ) =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "SubscriptionService.cancelSubscriptionById",
        ) {
            delegate.cancelSubscription(subscriptionId)
        }.toDestructor()

    /**
     * Use this method to restore access to an already purchased subscription. This can be called in the background
     * without user interaction.
     *
     * @param[restore] is an object containing details used to restore the subscription.
     * @param[callback] will be called with [Success]<[Unit]> if the restore operation succeeded.
     *
     * @return[Unit]
     */
    fun restoreSubscription(
        restore: SubscriptionRestore,
        @Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.restoreSubscription",
    ) {
        delegate.restoreSubscription(restore)
    }.toDestructor()

    /**
     * This method allows you to validate if an purchase receipt is still valid, and if it's currently assigned to a different user.
     *
     * @param [validation] is an object containing details used to validate the validity of the purchase.
     * @param [callback] will be called with [Success]<[ValidateReceiptResult]> when the receipt was validated.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun validateSubscription(
        validation: SubscriptionValidation,
        callback: Callback<ValidateReceiptResult>,
    ): Destructor = callback.fromCoWithErrorLoggingGetJob(
        sourceAreaId = "SubscriptionService.validateSubscription",
    ) {
        delegate.validateSubscription(validation)
    }.toDestructor()

    /**
     * This will fetch the data of the supplied subscription plan.
     *
     * @param [subscriptionPlanId] The id of the subscription plan to fetch.
     * @param [callback] will be called with [Success]<[SubscriptionPlan]> when the plan was fetched.
     *
     * @return[Destructor] can be called to cancel the request.
     */
    fun getSubscriptionPlan(subscriptionPlanId: String, callback: Callback<SubscriptionPlan>) =
        callback.fromCoWithErrorLoggingGetJob(
            sourceAreaId = "SubscriptionService.getSubscriptionPlan",
        ) {
            delegate.getSubscriptionPlan(subscriptionPlanId)
        }.toDestructor()

    /**
     * This will return the status of one click renewal for the current user. The status indicates if
     * renewal is available, as well as which provider is used and other relevant details.
     *
     * @param [callback] will be called with [Success]<[OneClickRenewalStatus]> when the status was fetched.
     *
     */
    fun getOneClickRenewalStatus(
        oneClickRenewal: OneClickRenewal,
        callback: Callback<OneClickRenewalStatus>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.getOneClickRenewalStatus",
    ) {
        delegate.getOneClickRenewalStatus(oneClickRenewal)
    }

    /**
     * Can be used if the previous call returned [OneClickRenewalStatus.Available] to perform a one click
     * renewal for the current user. Wait for the subscription listener to return the new subscription after
     * calling this.
     *
     * @param [callback] will be called with [Success]<[Unit]> once the renewal was performed.
     */
    fun performOneClickRenew(
        oneClickRenewal: OneClickRenewal,
        @Suppress("NON_EXPORTABLE_TYPE")
        callback: Callback<Unit>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.performOneClickRenew",
    ) {
        delegate.performOneClickRenew(oneClickRenewal)
    }

    /**
     * This will call the catalog service to fetch the pricing information for a Speechify subscription.
     * If no currency is specified the currency will be determined based on the users IP address.
     * If a currency is specified that is not currently supported pricing in USD will be returned.
     *
     * @param [renewalFrequency] Whether to get pricing for an annual or monthly subscription.
     * @param [currency] Optional: If specified pricing in this currency will be returned if available, otherwise
     *  the currency will be determined based on the users IIP.
     * @param [callback] will be called with [Success]<[SubscriptionPricingResult]> with the pricing information.
     */
    fun getSubscriptionPricing(
        renewalFrequency: RenewalFrequency,
        currency: String?,
        callback: Callback<SubscriptionPricingResult>,
    ) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.getSubscriptionPricing",
    ) {
        delegate.getSubscriptionPricing(renewalFrequency, currency)
    }

    /**
     * This will fetch the users current rewards balance. The returned value is in USD cents.
     *
     * @param [callback] will be called with [Success]<[RewardBalance]> with the reward balance.
     */
    fun getRewardBalance(callback: Callback<RewardBalance>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.getRewardBalance",
    ) {
        delegate.getRewardBalance()
    }

    /**
     * When called will extend the trial of the current user by 3 days.
     * This can only be used once by each user, and will throw an error if tried to be extended again.
     */
    fun extendTrial(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.extendTrial",
    ) {
        delegate.extendTrial()
    }

    /**
     * When called will skip the trial of the current user. This will directly convert and charge the user.
     * This can only be used if subscription is in trial, otherwise it will throw an error.
     * Note: This functionality is only available for Stripe subscriptions so far.
     */
    fun skipTrial(@Suppress("NON_EXPORTABLE_TYPE") callback: Callback<Unit>) = callback.fromCoWithErrorLogging(
        sourceAreaId = "SubscriptionService.skipTrial",
    ) {
        delegate.skipTrial()
    }

    /**
     * Removes all listeners
     * @return[Unit]
     */
    override fun destroy() {
        delegate.destroy()
    }
}
