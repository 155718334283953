// ktlint-disable filename
package com.speechify.client.api.util.boundary.deferred

import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import kotlinx.coroutines.CancellationException
import kotlin.js.JsExport

@JsExport
object CancellationUtils {
    /**
     * Checks if the exception is a special [CancellationException] which indicates that
     * **there was no error**, and the part of program execution which threw this exception was cancelled (most of the
     * time by the user - directly or indirectly by requesting a new feature before the previous one finished).
     *
     * A `true` result means that the exception should not be reported to developers or user.
     *
     * The exception itself, especially its inner exception or its message, can be useful to developers debugging code
     * related to the cancellation (it can help discern source-code from which the cancellation was triggered).
     */
    fun isCancellationException(error: Throwable): Boolean =
        error is CancellationException

    /**
     * A version of [isCancellationException] for [Result] received in [com.speechify.client.api.util.Callback]s.
     */
    fun <T> isCancellationResult(result: Result<T>): Boolean =
        result is Result.Failure &&
            result.error is SDKError.OtherException &&
            isCancellationException(result.error.exception)

    /**
     * See [createCancellationCause] for more details on the semantics of the result.
     */
    fun createCancellationCauseResult(
        /**
         * NOTE: This value is only used for tracing the origin of a normal cancellation (one that user is aware of).
         */
        messageForDiagnostics: String?,
        /**
         * NOTE: This value is only used for tracing the origin of a normal cancellation (one that user is aware of).
         */
        reasonForDiagnostics: Throwable?,
    ): Result.Failure =
        Result.Failure(
            SDKError.OtherException(
                exception = createCancellationCause(
                    messageForDiagnostics = messageForDiagnostics,
                    reasonForDiagnostics = reasonForDiagnostics,
                ),
            ),
        )

    /**
     * Creates an exception which is to be interpreted as a cancellation that the user is aware of, so one that should
     * specifically not be reported to developers.
     * SDK consumers can create instances of this for advising the SDK callers of situations like "user navigated away
     * while this call was in progress" (e.g. especially common in Web Browsers, which cancel requests automatically
     * this way, and the cancellation is notified as an exception from the `fetch` call and not from the caller).
     */
    fun createCancellationCause(
        /**
         * NOTE: This value is only used for tracing the origin of a normal cancellation (one that user is aware of).
         */
        messageForDiagnostics: String?,
        /**
         * NOTE: This value is only used for tracing the origin of a normal cancellation (one that user is aware of).
         */
        reasonForDiagnostics: Throwable?,
    ): Throwable =
        CancellationException(messageForDiagnostics, reasonForDiagnostics)
}
