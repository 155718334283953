package com.speechify.client.internal.util.collections.maps

import com.speechify.client.internal.util.TryGetResult

/**
 * A drop-in replacement for [Map] with only most frequently used methods, that allows to easily add wrappers around it.
 * Note that the individual interfaces have the exact same signatures as [Map], to allow drop-in replacement.
 *
 * The individual interfaced could be even be used to have even more restricted maps.
 */
internal interface MapWithBasics<K, V> :
    MapRetrieval<K, V>,
    MapEntriesEnumeration<K, V>

/**
 * A version of [MapWithBasics] that includes members that should be discouraged in code that doesn't have concurrency
 * control.
 */
internal interface MapWithBasicsThreadUnsafe<K, V> :
    MapWithBasics<K, V>,
    MapContainsKeyCheck<K, V>

/**
 * A drop-in replacement for [MutableMap] with only most frequently used methods, that allows to easily add wrappers
 * around it, for example for thread safety.
 *
 * Note that the individual interfaces have the exact same signatures as [Map], to allow drop-in replacement.
 */
internal interface MutableMapWithBasics<K, V> :
    MapWithBasics<K, V>,
    MutableMapInsertion<K, V>,
    MutableMapRemoval<K, V>,
    MutableMapClearing<K, V>

internal interface MutableMapWithBasicsThreadUnsafe<K, V> :
    MutableMapWithBasics<K, V>,
    MapWithBasicsThreadUnsafe<K, V>

internal fun <K, V> MutableMap<K, V>.asMutableMapWithBasics(): MutableMapWithBasicsThreadUnsafe<K, V> =
    object : MutableMapWithBasicsThreadUnsafe<K, V> {
        override fun get(key: K): V? = this@asMutableMapWithBasics[key]
        override fun put(key: K, value: V): V? =
            this@asMutableMapWithBasics.put(key, value)

        override val entries: Iterable<Pair<K, V>>
            get() = this@asMutableMapWithBasics.entries.map { it.toPair() }

        override fun remove(key: K): V? =
            this@asMutableMapWithBasics.remove(key)

        override fun clear() =
            this@asMutableMapWithBasics.clear()

        override fun containsKey(key: K): Boolean =
            this@asMutableMapWithBasics.containsKey(key)
    }

internal interface MapRetrieval<K, V> {
    /**
     * [Map.get]
     */
    operator fun get(key: K): V?
}

internal interface MapRetrievalOfNullable<K, V> {
    /**
     * A version of [MapRetrieval.get], but to be used it when [V] is `null`able and the map can contain `null`s.
     */
    fun getNullable(key: K): TryGetResult<V>
}

internal interface MapContainsKeyCheck<K, V> {
    /**
     * [Map.containsKey].
     * Warning: after getting any result from this method, does not guarantee anything about a call [MapRetrieval.get]
     * afterward, unless there is mutual-exclusion over threads accessing the map.
     */
    fun containsKey(key: K): Boolean
}

internal interface MutableMapInsertion<K, V> {
    /**
     * [MutableMap.put]
     */
    fun put(key: K, value: V): V?
}

internal interface MutableMapInsertionOrFailure<K, V> {
    /**
     * Similar to [MutableMapInsertion.put], but throws an error if the key already exists.
     * This is especially helpful for code that uses the map for its concurrency-control/thread-safety, e.g. when it's
     * required to never lose a value.
     * Equivalent of .NET's [Dictionary.Add](https://learn.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.add?view=net-7.0#exceptions)
     * (seem it [doesn't exist in Kotlin](https://kotlinlang.org/docs/map-operations.html))
     */
    fun add(key: K, value: V)
}

internal interface MutableMapRemoval<K, V> {
    /**
     * [MutableMap.remove]
     */
    fun remove(key: K): V?
}

internal interface MutableMapClearing<K, V> {
    /**
     * [MutableMap.clear]
     */
    fun clear()
}

internal interface MapEntriesEnumeration<K, V> {
    /**
     * [Map.entries]
     */
    val entries: Iterable<Pair<K, V>>
}

/**
 * [MutableMap.set]
 */
internal inline operator fun <K, V> MutableMapInsertion<K, V>.set(key: K, value: V) {
    put(key, value)
}

/**
 * [MutableMap.getOrPut]
 * See also [MapFilledWhileGetting.getOrPut] for a thread-safe version.
 */
internal fun <K, V> MutableMapWithBasics<K, V>.getOrPut(key: K, defaultValue: () -> V): V {
    /** The code is literally a copy-paste of [MutableMap.getOrPut] */
    val value = get(key)
    return if (value == null) {
        val answer = defaultValue()
        put(key, answer)
        answer
    } else {
        value
    }
}
