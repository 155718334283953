package com.speechify.client.api.adapters.offlineMode

import com.speechify.client.api.util.collections.flows.StateFlowFromCallback
import com.speechify.client.api.util.collections.flows.stateIn
import com.speechify.client.internal.WithScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlin.js.JsExport

/**
 * Implemented by product teams to indicate if the user is currently online or offline.
 */
@JsExport
abstract class OfflineModeStatusProvider : StateFlowFromCallback<OfflineModeStatusProvider.OfflineModeStatus>() {
    enum class OfflineModeStatus {
        ONLINE,
        OFFLINE,
    }

    /**
     * Obtains a Kotlin-idomatic version of this type, to be used by SDK developers.
     */
    internal fun toFlowProviderIn(scope: CoroutineScope): FlowProvider =
        FlowProviderFromOfflineModeStatusProvider(
            offlineModeStatusProvider = this,
            scope = scope,
        )

    @JsExport.Ignore
    internal interface FlowProvider {
        val offlineModeStatusFlow: StateFlow<OfflineModeStatus>
    }

    @JsExport.Ignore
    internal class FlowProviderWithStaticValue(
        staticValue: OfflineModeStatus,
    ) : FlowProvider {
        override val offlineModeStatusFlow: StateFlow<OfflineModeStatus> =
            MutableStateFlow(staticValue)
    }

    /* `Ignore` needed due to compiler glitch? It's an internal class */
    @JsExport.Ignore
    internal class FlowProviderFromOfflineModeStatusProvider
    /**
     * NOTE: SDK developers should use [OfflineModeStatusProvider.toFlowProviderIn] instead of this constructor.
     */
    internal constructor(
        offlineModeStatusProvider: OfflineModeStatusProvider,
        scope: CoroutineScope,
    ) : WithScope(
        scope = scope,
    ),
        FlowProvider {
        override val offlineModeStatusFlow: StateFlow<OfflineModeStatus> by lazy {
            offlineModeStatusProvider.stateIn(
                sourceAreaId = "OfflineModeStatusProvider.offlineModeStatusFlow",
                scope = scope,
            )
        }
    }
}
