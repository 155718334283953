package com.speechify.client.api.services.library.models

import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.firebase.HasSnapshotRef
import com.speechify.client.api.adapters.firebase.HasUri
import com.speechify.client.api.adapters.firebase.SnapshotRef
import com.speechify.client.api.services.importing.ImportService
import com.speechify.client.api.services.library.offline.OfflineAvailabilityManager
import com.speechify.client.api.services.library.offline.OfflineAvailabilityStatus
import com.speechify.client.api.services.library.offline.VoiceAudioDownloadInfo
import com.speechify.client.api.services.library.offline.toVoiceAudioDownloadInfo
import com.speechify.client.api.util.boundary.BoundaryMap
import com.speechify.client.api.util.collections.flows.CallbackFlowSourceFromCollectWithResult
import com.speechify.client.api.util.collections.flows.NeverEndingCallbackStateFlowOfNonNulls
import com.speechify.client.api.util.collections.flows.toCallbackFlowSourceFromCollectWithResult
import com.speechify.client.api.util.collections.flows.toNeverEndingCallbackStateFlowOfNonNulls
import com.speechify.client.helpers.features.SyncedListeningProgress
import com.speechify.client.internal.services.importing.models.ItemRequiringImport
import com.speechify.client.internal.services.library.models.ContentAccess
import com.speechify.client.internal.time.ISO8601DateString
import com.speechify.client.internal.util.boundary.toBoundaryMap
import com.speechify.client.internal.util.collections.flows.flowFromAsyncGetFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlin.js.JsExport

@JsExport
sealed class LibraryItem(
    val updatedAt: ISO8601DateString,
    val createdAt: ISO8601DateString,
    val title: String,
    val ownerId: String,
    val coverImageUrl: String?,
    override val snapshotRef: SnapshotRef,
    val analyticsProperties: BoundaryMap<String>,
    override val uri: SpeechifyURI,
    val parentFolderId: String,
    val lastListenedAt: ISO8601DateString?,
    val removedAt: ISO8601DateString?,
    val isArchived: Boolean,
) : HasSnapshotRef, HasUri {

    /**
     * Root class for all library items that are considered listenable.
     * This included both items that are uploaded to the users remote Speechify library, as well as items that are
     * only available locally.
     * Note: This doesn't necessarily mean that the item is actually listenable, since it still could be a failed import
     * or otherwise broken.
     */
    abstract class ListenableContent internal constructor(
        updatedAt: ISO8601DateString,
        createdAt: ISO8601DateString,
        title: String,
        ownerId: String,
        coverImageUrl: String?,
        snapshotRef: SnapshotRef,
        analyticsProperties: BoundaryMap<String>,
        uri: SpeechifyURI,
        parentFolderId: String,
        lastListenedAt: ISO8601DateString?,
        removedAt: ISO8601DateString?,
        isArchived: Boolean,
    ) : LibraryItem(
        updatedAt, createdAt, title, ownerId, coverImageUrl,
        snapshotRef, analyticsProperties, uri, parentFolderId, lastListenedAt, removedAt, isArchived,
    ) {
        abstract val sourceUrl: String?
        abstract val status: ItemStatus

        /**
         * Will be set to [LibraryImportProgress.FINISHED] for items that are fully uploaded to the users library.
         * Will be set to [LibraryImportProgress.UNFINISHED] for items that are only on the current device and that
         * still need to be uploaded.
         * Will be set to `null` for items that are being uploaded from other devices, or those where the upload failed.
         */
        abstract val libraryImportProgress: LibraryImportProgress?

        internal abstract val offlineAvailabilityStatusFlow: StateFlow<OfflineAvailabilityStatus?>
        abstract val offlineAvailabilityStatus: NeverEndingCallbackStateFlowOfNonNulls<OfflineAvailabilityStatus>

        abstract val listenProgressStatus: ListenProgressStatus?
        abstract val contentType: ContentType?

        /**
         * Represents whether the item is in a listenable state right now based on its current availability of content.
         * The item is considered in listenable state if it's fully uploaded to the users library or if it's available on the device.
         */
        abstract val isInListenableState: Boolean

        abstract val hasEverBeenEdited: Boolean
        internal abstract val startOfMainContent: LibraryStartOfMainContent?
    }

    /**
     * Represents a piece of listenable content that was uploaded the user Speechify library.
     * This only includes items that are uploaded to the users Speechify library, though they don't necessarily need to
     * have finished the import process yet.
     *
     * Note: This doesn't necessarily mean that the item is actually listenable, since it still could be a failed import
     * or otherwise broken.
     */
    class Content internal constructor(
        updatedAt: ISO8601DateString,
        override val status: ItemStatus,

        val isShared: Boolean,
        /**
         * @see [com.speechify.client.internal.services.importing.models.RecordProperties.sourceURL]
         */
        override val sourceUrl: String?,
        /**
         * @see [com.speechify.client.internal.services.importing.models.RecordProperties.sourceStoredURL]
         */
        val sourceStoredUrl: String?,

        title: String,
        val excerpt: String?,
        val totalWords: Int?,
        val wordsLeft: Int?,

        val listenProgressPercent: Double?,
        override val listenProgressStatus: ListenProgressStatus?,

        /**
         * This broadly represents the mime type of this item. This is independent of how the
         * item was added to the users Library.
         * Meaning that for example: even when adding a file from a URL, this isn't necessarily [ContentType.HTML].
         *
         * In order to preserve backwards compatibility this field will hold `SCAN` for any kind of scanned book,
         * legacy imported and sdk imported, but it's important to be aware that these have slightly different storage
         * representations. The relation between legacy and sdk can be seen in the following table.
         *
         * UPDATE 9/9/2023 in context of this thread: https://speechifyworkspace.slack.com/archives/C02LEG7AEGM/p1694012167648679
         * Both the legacy and non-legacy scanned books are of SCAN type, though they are differentiated in the
         * [com.speechify.client.internal.services.library.LibraryFirebaseTransformer] to support listening to legacy
         * items. See PR: https://github.com/SpeechifyInc/multiplatform-sdk/pull/1122 for the changes.
         *
         * | Import method              | [SpeechifyEntityType]  | Firestore.recordType | [LibraryItem].[contentType] | [Libraryitem.scannedBookFields]
         * |      ---                   |       ---              |          ---         |           ---               |           ---                 |
         * | legacy                     | LIBRARY_ITEM           | SCAN                 | SCAN                        | null                          |
         * | sdk (before 94.1.0)        | SCANNED_BOOK           | SCANNEDBOOK          | SCAN                        | non-null                      |
         * | sdk (starting 94.1.0)      | SCANNED_BOOK           | SCAN                 | SCAN                        | non-null                      |
         */

        override val contentType: ContentType,
        ownerId: String,
        /**
         * Reference to listenable content somewhere in the Speechify system. Using the URI, you will know which service to ask for this content.
         */
        uri: SpeechifyURI,
        createdAt: ISO8601DateString,
        coverImageUrl: String?,
        snapshotRef: SnapshotRef,
        @Deprecated(message = "use uri instead")
        val id: String,
        lastListenedAt: ISO8601DateString?,
        val listeningProgress: SyncedListeningProgress?,
        analyticsProperties: BoundaryMap<String>,
        offlineAvailabilityStatusFlowProvider: () -> StateFlow<OfflineAvailabilityStatus?>,
        internal val hasPageEditsFlow: MutableStateFlow<Boolean>,
        private val offlineAvailabilityManager: OfflineAvailabilityManager,
        parentFolderId: String,
        removedAt: ISO8601DateString?,
        val speechifyBookProductUri: String?,
        isArchived: Boolean,
        override val startOfMainContent: LibraryStartOfMainContent?,
        val contentAccess: ContentAccess?,
    ) : ListenableContent(
        updatedAt, createdAt, title, ownerId, coverImageUrl,
        snapshotRef, analyticsProperties, uri, parentFolderId,
        lastListenedAt, removedAt, isArchived,
    ) {

        override val offlineAvailabilityStatusFlow: StateFlow<OfflineAvailabilityStatus?> by lazy {
            offlineAvailabilityStatusFlowProvider()
        }

        /**
         * This is null until the underlying data source provided the first value.
         * Will be automatically updated as the items state changes.
         */
        override val offlineAvailabilityStatus by lazy {
            offlineAvailabilityStatusFlow
                .toNeverEndingCallbackStateFlowOfNonNulls()
        }

        /**
         * Information about any audio-downloads for this library item.
         */
        val audioDownloads: ContentItemAudioDownloadsInfo = object : ContentItemAudioDownloadsInfo() {
            override val downloads: CallbackFlowSourceFromCollectWithResult<Array<VoiceAudioDownloadInfo>> =
                flowFromAsyncGetFlow(
                    getFlow = {
                        offlineAvailabilityManager.observeDownloadedAudioForUri(
                            uri = uri,
                        )
                            .map {
                                it.map { downloadedAudioForItem ->
                                    downloadedAudioForItem.toVoiceAudioDownloadInfo()
                                }
                            }
                    },
                )
                    .map { it.toTypedArray() }
                    .toCallbackFlowSourceFromCollectWithResult()

            override val hasGaps: CallbackFlowSourceFromCollectWithResult<Boolean> =
                flowFromAsyncGetFlow(
                    getFlow = { offlineAvailabilityManager.observeItemHavingGapsInAudio(uri) },
                )
                    .toCallbackFlowSourceFromCollectWithResult()
        }

        override val libraryImportProgress = if (status == ItemStatus.DONE) {
            LibraryImportProgress.Finished
        } else {
            null
        }

        override val isInListenableState: Boolean = status == ItemStatus.DONE

        override val hasEverBeenEdited = hasPageEditsFlow.value

        override fun toString(): String {
            return "LibraryItem.Content(uri=$uri)"
        }
    }

    /**
     * A library item representing some content that is only available on the current device.
     * This is used to represent items that the user intended to upload to their library, but where the upload has not
     * happened yet, for example due to connection errors.
     */
    class DeviceLocalContent internal constructor(
        internal val underlyingItemRequiringImport: ItemRequiringImport,
        lastListenedAt: ISO8601DateString? = null,
        removedAt: ISO8601DateString? = null,
        isArchived: Boolean = false,
        override val startOfMainContent: LibraryStartOfMainContent? = null,
    ) : ListenableContent(
        updatedAt = underlyingItemRequiringImport.lastUpdatedAt.toIsoString(),
        createdAt = underlyingItemRequiringImport.lastUpdatedAt.toIsoString(),
        // TODO: Find better fallback title.
        //       For the common path this doesn't matter since when going through the ContentImporter
        //       the title will be set to the correct value in the ImportOptions, this is only relevant
        //       when importing directly through the ImportService.
        title = underlyingItemRequiringImport.importOptions?.title
            ?: "Import from ${underlyingItemRequiringImport.lastUpdatedAt.toIsoString()}",
        ownerId = underlyingItemRequiringImport.owner,
        coverImageUrl = null,
        snapshotRef = SnapshotRef(Any()),
        analyticsProperties = emptyMap<String, String>().toBoundaryMap(),
        uri = underlyingItemRequiringImport.speechifyUri,
        parentFolderId = underlyingItemRequiringImport.importOptions?.parentFolder?.id
            ?: FolderReference.Root.asRaw(),
        lastListenedAt, removedAt, isArchived,
    ) {
        override val sourceUrl = when (underlyingItemRequiringImport) {
            is ItemRequiringImport.FileImport -> underlyingItemRequiringImport.sourceURL
            is ItemRequiringImport.ScannedPagesImport -> null
            is ItemRequiringImport.UrlImport -> underlyingItemRequiringImport.sourceURL
        }

        val listeningProgress = underlyingItemRequiringImport.listeningProgress

        override val status: ItemStatus = when (underlyingItemRequiringImport) {
            is ItemRequiringImport.FileImport -> ItemStatus.DONE
            is ItemRequiringImport.ScannedPagesImport -> ItemStatus.DONE
            is ItemRequiringImport.UrlImport -> if (underlyingItemRequiringImport.primaryFileBlobStorageKey != null) {
                ItemStatus.DONE
            } else {
                // If the URL was not downloaded yet, don't consider the item listenable.
                ItemStatus.PROCESSING
            }
        }
        override val libraryImportProgress: LibraryImportProgress? =
            if (underlyingItemRequiringImport.isImportCurrentlyRunning) {
                LibraryImportProgress.Unfinished
            } else if (underlyingItemRequiringImport.lastErrorStackTrace != null ||
                underlyingItemRequiringImport.wasLastErrorConnectionError != null
            ) {
                LibraryImportProgress.Failed(
                    underlyingItemRequiringImport.wasLastErrorConnectionError ?: false,
                    ImportService.willAutomaticallyRetryImport(underlyingItemRequiringImport),
                )
            } else {
                null
            }

        override val offlineAvailabilityStatusFlow: StateFlow<OfflineAvailabilityStatus?> =
            when (underlyingItemRequiringImport) {
                is ItemRequiringImport.FileImport -> MutableStateFlow(OfflineAvailabilityStatus.AVAILABLE)
                is ItemRequiringImport.ScannedPagesImport -> MutableStateFlow(OfflineAvailabilityStatus.AVAILABLE)
                is ItemRequiringImport.UrlImport ->
                    if (underlyingItemRequiringImport.primaryFileBlobStorageKey != null) {
                        MutableStateFlow(OfflineAvailabilityStatus.AVAILABLE)
                    } else {
                        MutableStateFlow(OfflineAvailabilityStatus.NOT_AVAILABLE)
                    }
            }
        override val offlineAvailabilityStatus: NeverEndingCallbackStateFlowOfNonNulls<OfflineAvailabilityStatus> =
            offlineAvailabilityStatusFlow.toNeverEndingCallbackStateFlowOfNonNulls()

        override val listenProgressStatus: ListenProgressStatus?
            get() = when {
                listeningProgress == null -> null
                listeningProgress.fraction == 0.0 -> ListenProgressStatus.NOT_STARTED
                listeningProgress.fraction >= 0.95 -> ListenProgressStatus.DONE
                else -> ListenProgressStatus.IN_PROGRESS
            }

        override val contentType: ContentType?
            get() = when (underlyingItemRequiringImport) {
                is ItemRequiringImport.ScannedPagesImport -> ContentType.SCAN
                is ItemRequiringImport.FileImport -> ContentType.fromMimeType(underlyingItemRequiringImport.mimeType)
                is ItemRequiringImport.UrlImport -> ContentType.fromMimeType(underlyingItemRequiringImport.mimeType)
            }

        override val isInListenableState: Boolean = true
        override val hasEverBeenEdited: Boolean = false

        override fun toString(): String {
            return "LibraryItem.DeviceLocalContent(id=${uri.id}, status=$status)"
        }
    }

    class Folder internal constructor(
        val childrenCount: Int,
        ownerId: String,
        val id: String,
        createdAt: ISO8601DateString,
        coverImageUrl: String?,
        title: String,
        updatedAt: ISO8601DateString,
        snapshotRef: SnapshotRef,
        analyticsProperties: BoundaryMap<String>,
        parentFolderId: String,
        lastListenedAt: ISO8601DateString?,
        removedAt: ISO8601DateString?,
        isArchived: Boolean,
    ) : LibraryItem(
        updatedAt,
        createdAt,
        title,
        ownerId,
        coverImageUrl,
        snapshotRef,
        analyticsProperties,
        SpeechifyURI.fromExistingId(SpeechifyEntityType.FOLDER, id),
        parentFolderId,
        lastListenedAt,
        removedAt,
        isArchived,
    ) {
        override fun toString(): String {
            return "LibraryItem.Folder(id=$id)"
        }
    }
}

@JsExport
enum class ListenProgressStatus(val type: String) {
    NOT_STARTED("not-started"),
    DONE("done"),
    IN_PROGRESS("in-progress"),
}

@JsExport
enum class ItemStatus(val type: String) {
    PROCESSING("processing"),
    DONE("done"),
    FAILED("failed"),
}

@JsExport
sealed class LibraryImportProgress {
    abstract val isFinishedSuccessfully: Boolean

    object Unfinished : LibraryImportProgress() {
        override val isFinishedSuccessfully = false

        override fun toString(): String = "Unfinished"
    }

    object Finished : LibraryImportProgress() {
        override val isFinishedSuccessfully = true

        override fun toString(): String = "Finished"
    }

    data class Failed(
        /** When true the last import error was a connection error. */
        val wasLastFailureConnectionError: Boolean,
        /** Indicates if this item will automatically be retried at the next opportunity. */
        val willAutomaticallyRetryImport: Boolean,
    ) : LibraryImportProgress() {
        override val isFinishedSuccessfully: Boolean = false
    }
}

@JsExport
abstract class ContentItemAudioDownloadsInfo internal constructor() {
    /**
     * Allows to query for a list of audio downloads that user performed on the item, as well as to continue
     * getting updates about changes to the list.
     */
    abstract val downloads: CallbackFlowSourceFromCollectWithResult<Array<VoiceAudioDownloadInfo>>

    /**
     * Allows to observe detection of the downloaded audio having gaps (e.g. due to improvements to content
     * extraction, a better text content has been produced) and needs a re-download.
     */
    abstract val hasGaps: CallbackFlowSourceFromCollectWithResult<Boolean>
}

internal sealed class LibraryStartOfMainContent {
    data class Epub(
        val filename: String,
        val nodeId: String?,
    ) : LibraryStartOfMainContent()
}
