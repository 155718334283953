package com.speechify.client.internal.services.subscription.models

import com.speechify.client.internal.time.DateTime
import kotlinx.serialization.Serializable

@Serializable
internal data class FirebaseSubscriptionsInfo(
    val expiresAt: DateTime,
    val nextApplicationAt: DateTime,
    val updatedAt: DateTime? = null,
    val productId: String? = null,
    val source: String,
    val userId: String,
    val is_trial_period: String? = null,
    val environment: FirebasePaymentEnvironment,
    val subscribedAt: DateTime? = null,
    val status: FirebaseSubscriptionStatus,
    val renewalStatus: FirebaseRenewalStatus? = null,
    val localCurrency: String? = null,
    val lastPaymentAt: DateTime? = null,

    /** All discounts applied to the Subscription **/
    val discounts: Array<FirebaseSubscriptionDiscount>? = null,
)
