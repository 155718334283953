package com.speechify.client.reader.fixedlayoutbook

import com.speechify.client.reader.core.BookEditorHelper
import kotlin.js.JsExport

@JsExport
data class FixedLayoutBookView internal constructor(
    val zoomLevel: Double,
    val navigationIntent: CoarseFixedLayoutBookNavigationIntent?,
    val pages: Array<FixedLayoutPageView>,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as FixedLayoutBookView

        if (zoomLevel != other.zoomLevel) return false
        if (navigationIntent != other.navigationIntent) return false
        if (!pages.contentEquals(other.pages)) return false

        return true
    }

    override fun hashCode(): Int {
        var result = zoomLevel.hashCode()
        result = 31 * result + (navigationIntent?.hashCode() ?: 0)
        result = 31 * result + pages.contentHashCode()
        return result
    }
}

@JsExport
sealed class FixedLayoutPageView {
    abstract val pageIndex: Int
    data class NotInFocus(override val pageIndex: Int) : FixedLayoutPageView()
    data class InFocus(override val pageIndex: Int, val pageHelper: FixedLayoutPageHelper) : FixedLayoutPageView()

    data class Hidden(
        private val bookEditorHelper: BookEditorHelper,
        override val pageIndex: Int,
        val pageHelper: FixedLayoutPageHelper,
    ) : FixedLayoutPageView() {
        fun restore() {
            bookEditorHelper.showPages(arrayOf(pageIndex))
            bookEditorHelper.saveEdits()
        }
    }
}
