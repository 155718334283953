package com.speechify.client.api.services.library.models

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully
import kotlin.js.JsExport

/**
 * Encapsulates the parameters for a search request.
 *
 * @property queryString The search query string.
 * @property filters Filters to apply to the search, see [SearchableFilterType] & [FilterType]
 * @property pageSize The number of results per page. Null defaults to a system-defined page size if not explicitly set.
 */
@JsExport
data class SearchRequest(
    val queryString: String,
    val filters: Array<SearchableFilterType> = emptyArray(),
    val pageSize: Int? = null,
)

internal data class SearchRequestInternal(
    val searchRequest: SearchRequest,
    // starts at page 1 by default, increments as the next pages are loaded using [SearchResult.loadMoreItems]
    val page: Int = 1,
)

/**
 * Encapsulates the result of a search query. [hasMore] denotes whether more results are available ,
 * [items] holds the current list of items found, and supports fetching additional results with pagination through
 * the [loadMoreItems] method.
 */
@JsExport
data class SearchResult internal constructor(
    val hasMore: Boolean,
    val items: Array<LibraryItem>,
    private val searchHelperFn: suspend (SearchRequestInternal) -> SearchResultInternal,
    private val searchRequest: SearchRequestInternal,
) {
    /**
     *  Loads more items in case there is a next page, as denoted by [hasMore]
     *  Returns the accumulation of the items since the first page, and not just the items on this page.
     */
    fun loadMoreItems(callback: Callback<SearchResult>) = callback.fromCo {
        coLoadMoreItems().successfully()
    }

    private suspend fun coLoadMoreItems(): SearchResult {
        if (!hasMore) return this
        val newSearchRequest = searchRequest.copy(page = searchRequest.page + 1)
        val newResults = searchHelperFn(newSearchRequest)
        return this.copy(
            hasMore = newResults.hasMore,
            items = items + newResults.items,
            searchRequest = newSearchRequest,
        )
    }
}

/**
 * Used as an intermediate, internal representation of a Search Result, one without the
 * bells and whistles that the public [SearchResult] model has.
 */
internal data class SearchResultInternal(
    val items: Array<LibraryItem>,
    val page: Int,
    val hasMore: Boolean,
)
