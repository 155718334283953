package com.speechify.client.bundlers.reading.embedded

import kotlin.js.JsExport

@JsExport
class EmbeddedReadingBundlerConfig(
    /**
     * Container for all *optional* [EmbeddedReadingBundler] configurations.
     */
    val options: EmbeddedReadingBundlerOptions,
)

/**
 * Container for all *optional* [EmbeddedReadingBundler] configurations.
 */
@JsExport
class EmbeddedReadingBundlerOptions()
