package com.speechify.client.internal.services.ml.models

import com.speechify.client.api.util.images.BoundingBox
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonPrimitive
import kotlin.js.JsExport

@Serializable
internal class MLParsedPageResponse(
    val labeledPageTextGroups: List<LabeledPageTextGroup>,
    @SerialName(value = "version")
    val generatedBySoftwareVersion: String,
)

@Serializable
internal class LabeledPageTextGroup(
    val type: TextGroupType, // Header, Footer, Footnote...
    val labeledPageTextItems: List<LabeledPageTextItem>,
)

@Serializable
internal class LabeledPageTextItem(
    val type: TextItemType, // subscript, superScript ...
    val text: String,
    val box: BoxCoordinates,
    val fontFamily: String? = null,
)

@Serializable
class BoxCoordinates(
    val left: Double,
    val right: Double,
    val top: Double,
    val bottom: Double,
) {
    fun toBoundingBox(): BoundingBox {
        return BoundingBox.fromDimensionsAndCoordinates(
            width = right - left,
            height = bottom - top,
            left = left,
            top = top,
        )
    }
}

@JsExport
@Serializable(with = TextItemTypeSerializer::class)
sealed class TextItemType(
    /** This is used to serialize the type of the textItem for both: server ml-endpoint at [LabeledPageTextItem] and
     * for the firestore database at [com.speechify.client.api.content.view.book.LabeledBookPageTextContentItem]
     */
    val serializedValue: String,
) {
    object Superscript : TextItemType("superscript")
    object Subscript : TextItemType("subscript")
    object DropCap : TextItemType("drop_cap")
    class Unknown(stringValue: String) : TextItemType(stringValue)

    companion object {
        fun fromSerializedValue(stringValue: String): TextItemType = when (stringValue) {
            Superscript.serializedValue -> Superscript
            Subscript.serializedValue -> Subscript
            DropCap.serializedValue -> DropCap
            else -> Unknown(stringValue)
        }
    }
}

@Serializable(with = TextGroupTypeSerializer::class)
sealed class TextGroupType(
    /** This is used to serialize the type of the textGroup for both: server ml-endpoint at [LabeledPageTextGroup] and
     * for the firestore database at [com.speechify.client.api.content.pdf.ParsedBookPageTextGroup]
     */
    val serializedValue: String,
) {
    object Paragraph : TextGroupType("text")
    object Header : TextGroupType("header")
    object Footer : TextGroupType("footer")
    object Footnote : TextGroupType("footnote")
    object Picture : TextGroupType("picture")
    object ListItem : TextGroupType("list-item")
    object Caption : TextGroupType("caption")
    object Formula : TextGroupType("formula")
    object SectionTitle : TextGroupType("section-title")
    object Table : TextGroupType("table")
    object Title : TextGroupType("title")
    object SectionHeader : TextGroupType("section-header")
    class Unknown(stringValue: String) : TextGroupType(stringValue)

    companion object {
        fun fromSerializedValue(stringValue: String): TextGroupType = when (stringValue) {
            Header.serializedValue -> Header
            Paragraph.serializedValue -> Paragraph
            Footnote.serializedValue -> Footnote
            Footer.serializedValue -> Footer
            SectionTitle.serializedValue -> SectionTitle
            Picture.serializedValue -> Picture
            ListItem.serializedValue -> ListItem
            Caption.serializedValue -> Caption
            Formula.serializedValue -> Formula
            Table.serializedValue -> Table
            Title.serializedValue -> Title
            SectionHeader.serializedValue -> SectionHeader
            else -> Unknown(stringValue)
        }
    }
}

@OptIn(ExperimentalSerializationApi::class)
@Serializer(forClass = TextItemType::class)
internal object TextItemTypeSerializer : KSerializer<TextItemType> {
    override fun serialize(encoder: Encoder, value: TextItemType) {
        encoder.encodeString(value.serializedValue)
    }

    override fun deserialize(decoder: Decoder): TextItemType {
        val jElement = decoder.decodeSerializableValue(JsonElement.serializer())
        return TextItemType.fromSerializedValue(stringValue = jElement.jsonPrimitive.content)
    }
}

@OptIn(ExperimentalSerializationApi::class)
@Serializer(forClass = TextGroupType::class)
internal object TextGroupTypeSerializer : KSerializer<TextGroupType> {
    override fun serialize(encoder: Encoder, value: TextGroupType) {
        encoder.encodeString(value.serializedValue)
    }

    override fun deserialize(decoder: Decoder): TextGroupType {
        val jElement = decoder.decodeSerializableValue(JsonElement.serializer())
        return TextGroupType.fromSerializedValue(stringValue = jElement.jsonPrimitive.content)
    }
}
