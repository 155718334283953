package com.speechify.client.api.util

import com.speechify.client.api.adapters.firebase.HasSnapshotRef
import com.speechify.client.api.adapters.firebase.HasUri
import com.speechify.client.api.diagnostics.DiagnosticEvent
import com.speechify.client.api.diagnostics.Log
import com.speechify.client.internal.WithScope
import com.speechify.client.internal.launchTask
import com.speechify.client.internal.sync.WrappingMutex
import com.speechify.client.internal.toDestructor
import com.speechify.client.internal.util.extensions.collections.toTypedArraySeedingTypeFrom
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.channelFlow
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlin.js.JsExport

internal sealed class ItemChange<T>(val item: T) {
    class Added<T>(item: T) : ItemChange<T>(item)
    class Removed<T>(item: T) : ItemChange<T>(item)
    class Modified<T>(item: T) : ItemChange<T>(item)
}

@JsExport
class LiveQueryViewV2<T> private constructor(
    // Needed for converting a list into a typed array where T is non-reified- see [List<T>.toTypedArraySeeingTypeFrom]
    private val arrayForTypeInference: Array<T>,
    private val getNextPageOfRemoteItems: suspend (T?) -> List<T>,
    private val localItems: Flow<List<T>>,
    private val itemChanges: Flow<List<ItemChange<T>>>,
    private val outputItemFilter: PredicateAsync<T>?,
    private val sortByComparator: Comparator<T>,
    private val timestampComparator: Comparator<T>,
) : WithScope(), ILiveQueryView<T> where T : HasUri, T : HasSnapshotRef {

    companion object {
        internal inline fun <reified T> empty(
            noinline getNextPageOfRemoteItems: suspend (T?) -> List<T>,
            localItems: Flow<List<T>>,
            itemChanges: Flow<List<ItemChange<T>>>,
            noinline outputItemFilter: PredicateAsync<T>? = null,
            sortByComparator: Comparator<T>,
            timestampComparator: Comparator<T>,
        ) where T : HasUri, T : HasSnapshotRef = LiveQueryViewV2(
            arrayForTypeInference = emptyArray(),
            getNextPageOfRemoteItems = getNextPageOfRemoteItems,
            localItems = localItems,
            itemChanges = itemChanges,
            outputItemFilter = outputItemFilter,
            sortByComparator = sortByComparator,
            timestampComparator = timestampComparator,
        )
    }

    private val currentItems: WrappingMutex<MutableMap<String, T>> = WrappingMutex.of(mutableMapOf())
    private val updatesFlow: MutableSharedFlow<ILiveQueryView<T>> = MutableSharedFlow()

    init {
        scope.launch {
            // We emit Unit here just to signal an update to the callback, we don't emit the actual values of the update
            // since we get the actual values in the `getCurrentItems()` method.
            // Used channelFlow here to fix "Flow exception transparency is violated" exception.
            // See this link for more details: [https://stackoverflow.com/a/67582267]
            channelFlow {
                itemChanges.collect { itemChanges ->
                    currentItems.locked { itemsMap ->
                        try {
                            var isCurrentItemsListModified = false
                            itemChanges.forEach { itemChange ->
                                val itemFromUpdate = itemChange.item
                                val matchingItem = itemsMap[itemFromUpdate.uri.id]
                                when (itemChange) {
                                    is ItemChange.Added, is ItemChange.Modified -> {
                                        // Giving precedence to local Item if it exists; likewise in LiveQueryViewV1.
                                        val localItem =
                                            localItems.firstOrNull()?.find { it.uri.id == itemFromUpdate.uri.id }
                                        if (localItem != null && matchingItem == null) {
                                            itemsMap[itemFromUpdate.uri.id] = localItem
                                            isCurrentItemsListModified = true
                                        } else if (localItem == null && (
                                            matchingItem == null || timestampComparator.compare(
                                                    itemFromUpdate,
                                                    matchingItem,
                                                ) >= 0
                                            )
                                        ) {
                                            itemsMap[itemFromUpdate.uri.id] = itemFromUpdate
                                            isCurrentItemsListModified = true
                                        }
                                    }

                                    is ItemChange.Removed -> {
                                        if (itemsMap[itemFromUpdate.uri.id] != null) {
                                            itemsMap.remove(itemFromUpdate.uri.id)
                                            isCurrentItemsListModified = true
                                        }
                                    }
                                }
                            }
                            if (isCurrentItemsListModified) {
                                send(Unit.successfully())
                            }
                        } catch (e: Throwable) {
                            Log.e(
                                DiagnosticEvent(
                                    message = "Failed to handle view change.",
                                    sourceAreaId = "LiveQueryView.init",
                                    nativeError = e,
                                ),
                            )
                        }
                    }
                }
            }.collect {
                updatesFlow.emit(this@LiveQueryViewV2)
            }
        }

        scope.launch {
            localItems.collect { localItems ->
                currentItems.locked { itemsMap ->
                    val remoteItems = itemsMap.values.toList()
                    val localItemIds = localItems.map { it.uri.id }.toSet()
                    val uniqueRemoteItems = remoteItems.filterNot { it.uri.id in localItemIds }
                    val allItems = localItems + uniqueRemoteItems
                    allItems.forEach {
                        itemsMap[it.uri.id] = it
                    }
                }
                updatesFlow.emit(this@LiveQueryViewV2)
            }
        }
    }

    override fun loadMoreItems(callback: Callback<ILiveQueryView<T>?>) = callback.fromCo {
        val lastItem = currentItems.locked { it.values.sortedWith(sortByComparator).lastOrNull() }
        val nextPageItems = getNextPageOfRemoteItems(lastItem)
        currentItems.locked { itemsMap ->
            try {
                nextPageItems.forEach {
                    // Giving precedence to local Item if it exists; likewise in LiveQueryViewV1.
                    val localItem = localItems.firstOrNull()?.find { item -> item.uri.id == it.uri.id }
                    if (localItem != null) {
                        itemsMap[it.uri.id] = localItem
                        return@forEach
                    }
                    when (val curr = itemsMap[it.uri.id]) {
                        null -> itemsMap[it.uri.id] = it
                        else -> {
                            if (timestampComparator.compare(it, curr) > 0) {
                                itemsMap[it.uri.id] = it
                            }
                        }
                    }
                }
            } catch (e: Throwable) {
                Log.e(
                    DiagnosticEvent(
                        message = "Failed to fetch more items for view",
                        sourceAreaId = "LiveQueryView.loadMoreItems",
                        nativeError = e,
                    ),
                )
            }
        }
        updatesFlow.emit(this@LiveQueryViewV2)
        this@LiveQueryViewV2.successfully()
    }

    override fun getCurrentItems(callback: (Array<T>) -> Unit) = callback.fromCoNoError {
        currentItems.locked { itemsMap ->
            val allItems = itemsMap.values.toList()
            if (outputItemFilter != null) {
                val filteredItems =
                    allItems.map { it to async { outputItemFilter.let { itemFilter -> itemFilter(it) } } }
                        .toList()
                        .filter { (_, filter) -> filter.await() }
                        .map { (item, _) -> item }
                filteredItems.sortedWith(sortByComparator).toTypedArraySeedingTypeFrom(arrayForTypeInference)
            } else {
                allItems.sortedWith(sortByComparator).toTypedArraySeedingTypeFrom(arrayForTypeInference)
            }
        }
    }

    override fun addChangeListener(callback: Callback<ILiveQueryView<T>>): Destructor {
        return updatesFlow.onEach { callback(it.successfully()) }.launchIn(scope).toDestructor()
    }

    override fun destroy() {
        /* Disposes of the `scope`, to gracefully cancel both producers and listeners. */
        super.destroy()
        /* Need to use a new scope, as the `scope` has just been cancelled.*/
        launchTask {
            currentItems.locked { itemsMap ->
                itemsMap.clear()
            }
        }
    }
}
