package com.speechify.client.api.adapters.html

import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.boundary.BoundaryPair
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.coGetAllAsString
import com.speechify.client.api.util.successfully
import org.w3c.dom.Element
import org.w3c.dom.ItemArrayLike
import org.w3c.dom.Text
import org.w3c.dom.parsing.DOMParser

/**
 * [HTMLParser] implementation using [org.w3c.dom.parsing.DOMParser] available in the browsers.
 */
@JsExport
class W3CHTMLParser : HTMLParser() {
    override fun parseAsDOM(
        file: File,
        callback: Callback<DOMElement>,
    ) =
        callback.fromCo { coParseAsDOM(file) }

    @JsExport.Ignore
    override suspend fun coParseAsDOM(file: File): Result<DOMElementFromW3CElement> =
        DOMElementFromW3CElement(
            w3cElement = DOMParser().parseFromString(
                str = file.coGetAllAsString().orReturn { return@coParseAsDOM it },
                type = "text/html",
            ).documentElement!!,
        ).successfully()
}

/**
 * An implementation of [DOMElement] that takes all the content from the specified real [w3cElement], without any
 * filtering or modification.
 */
internal class DOMElementFromW3CElement(private val w3cElement: Element) : DOMElement() {
    override val rawRefObject: Element
        get() = w3cElement

    override val tagName: String
        get() = w3cElement.tagName

    override val attributes: Array<BoundaryPair<String, String>>
        get() =
            w3cElement.attributes.iterate().filterNotNull().map {
                BoundaryPair(it.name, it.value)
            }.toTypedArray()

    override val children: Array<DOMNode>
        get() =
            sequence {
                for (child in w3cElement.childNodes.iterate().filterNotNull()) {
                    when (child) {
                        is Element -> yield(DOMElementFromW3CElement(child))
                        is Text -> {
                            val text = child.textContent
                            if (text != null) {
                                yield(DOMTextNodeFromW3CTextNode(text, child))
                            }
                        }
                    }
                }
            }.toList().toTypedArray()
}

/**
 * An implementation of [DOMTextNode] that takes all the content from the specified real [node], without any
 * filtering or modification.
 */
private class DOMTextNodeFromW3CTextNode(
    // Requiring the non-null text here, because [org.w3c.dom.Node] itself has it nullable. We want to simplify this API and skip such weird text nodes (likely they don't even happen).
    override val text: String,
    private val node: Text,
) : DOMTextNode() {
    override val rawRefObject: Text
        get() = node
}

private fun <Item> ItemArrayLike<Item>.iterate(): Iterable<Item?> =
    (0 until this.length).map { index ->
        this.item(index)
    }
