package com.speechify.client.api.audio

import com.speechify.client.api.adapters.localsynthesis.LocalSpeechSynthesisAdapter
import com.speechify.client.api.adapters.localsynthesis.LocalSynthesisVoice
import com.speechify.client.api.adapters.localsynthesis.coCreateLocalSpeechSynthesisPlayer
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentText
import com.speechify.client.api.content.view.speech.Speech
import com.speechify.client.api.content.view.speech.slice
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.successfully

/**
 * Construction by SDK-consumers can be done via [com.speechify.client.api.audio.UtteranceFactory].
 */
internal data class LocalUtterance(
    val localSynthesisVoice: LocalSynthesisVoice,
    override val speech: Speech,
    override val text: ContentText,
    private val localSpeechSynthesisPlayerFactory: LocalSpeechSynthesisAdapter,
    override val voiceMetadata: VoiceMetadata,
) : Utterance() {

    override fun slice(start: ContentCursor, end: ContentCursor): Utterance {
        val startIndex = text.getFirstIndexOfCursor(start)
        val endIndex = text.getLastIndexOfCursor(end)
        return LocalUtterance(
            localSynthesisVoice,
            speech.slice(start, end),
            this.text.slice(startIndex, endIndex + 1),
            localSpeechSynthesisPlayerFactory = localSpeechSynthesisPlayerFactory,
            voiceMetadata = voiceMetadata,
        )
    }

    override val synthesisLocation: SynthesisLocation = SynthesisLocation.LOCAL

    override fun getPlayer(initialOptions: PlayerOptions, callback: Callback<Player>) = callback.fromCo {
        return@fromCo LocalPlayer(
            localSynthesis = localSpeechSynthesisPlayerFactory
                .coCreateLocalSpeechSynthesisPlayer()
                .orReturn { return@fromCo it },
            utterance = this@LocalUtterance,
            options = initialOptions,
        ).successfully()
    }
}
