package com.speechify.client.helpers.content.standard.streamable.items.innerItems

import com.speechify.client.api.content.ObjectRef
import com.speechify.client.helpers.content.standard.streamable.ItemLines
import com.speechify.client.helpers.content.standard.streamable.ItemWithParts
import kotlin.js.JsExport

@JsExport
class TextWithRef(
    val text: String,
    val ref: ObjectRef<Any?>,
)

@JsExport
open class Line(
    override val parts: Array<TextWithRef>,
    override val ref: ObjectRef<Any?>,
) : ItemWithParts

internal class LineOfOnePart(
    part: TextWithRef,
) : Line(
    parts = arrayOf(part),
    ref = ObjectRef(null),
)

@JsExport
open class ListItem(
    override val parts: Array<TextWithRef>,
    override val ref: ObjectRef<Any?>,
) : ItemWithParts

internal class ListItemOfOnePart(
    part: TextWithRef,
) : ListItem(
    parts = arrayOf(part),
    ref = ObjectRef(null),
)

@JsExport
class ListItemLines(
    override val lines: Array<Line>,
    override val ref: ObjectRef<Any?>,
) : ItemLines
