package com.speechify.client.internal.services.editing

import com.speechify.client.api.SpeechifyContentId
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.api.adapters.firebase.Collections
import com.speechify.client.api.adapters.firebase.FirebaseFirestoreService
import com.speechify.client.api.adapters.firebase.PathInCollection
import com.speechify.client.api.adapters.firebase.coGetObjectOrNullIfNotExists
import com.speechify.client.api.adapters.firebase.coSetDocument
import com.speechify.client.api.adapters.firebase.coUpdateDocument
import com.speechify.client.api.editing.BookEditor
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.SDKError
import com.speechify.client.api.util.boundary.Boundary
import com.speechify.client.api.util.fallbackNullValueToFailure
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.success
import com.speechify.client.bundlers.content.ContentBundle
import com.speechify.client.bundlers.reading.importing.ContentImporterState
import com.speechify.client.internal.services.importing.models.RecordProperties
import com.speechify.client.internal.util.boundary.toBoundaryMap

internal fun getEditsPath(itemId: SpeechifyContentId): PathInCollection =
    PathInCollection(
        collectionPath = "${Collections.ITEMS.collectionRef}/$itemId/edits",
        /*
        Using a collection to defer loading edits when they're needed (e.g. not load them when listing library items).

        Using a single fixed document `pageEdits` inside the collection, preserving the original design's choice of
           these edits as a single document. This is because:
            * it's minimal change to the original design, especially that it straightforwardly translates to existent
              'update by replacing whole collection' (else we'd need to do a 'merge' operation)
            * perhaps this is also more performant to load all the edits?
         */
        documentPath = "pageEdits",
    )

internal class BookEditingService(
    private val firestoreService: FirebaseFirestoreService,
) {

    /**
     * Initializes a new instance of [BookEditor]:
     * If [previousEdits] is null, it indicates the user's initial attempt to create the book editor,
     * and attempts to retrieve edits remotely if they exist; otherwise, falls back to default edits.
     *
     * If [previousEdits] is not null, creates a new [BookEditor] based on the provided previous edits,
     * reflecting the user's intent to update the edits based on the previous ones.
     */
    internal suspend fun createBookEditingInstance(
        source: ContentBundle.BookBundle,
        previousEdits: BookEdits? = null,
    ): BookEditor {
        val numberOfPages = source.bookView.getMetadata().numberOfPages
        val initialBookEdits: BookEdits = when (previousEdits) {
            null -> when (val state = source.coImporter.state) {
                is ContentImporterState.ImportedToLibrary -> {
                    val shouldFetchEdits = state.libraryItem.hasPageEditsFlow.value
                    if (shouldFetchEdits) {
                        loadBookEditsPayload(state.uri).orThrow()
                    } else {
                        BookEdits.defaultWith(numberOfPages)
                    }
                }

                else -> BookEdits.defaultWith(numberOfPages)
            }

            else -> previousEdits
        }
        return BookEditor(
            bookBundle = source,
            bookEditingService = this,
            initialState = initialBookEdits,
        )
    }

    internal suspend fun setBookEdits(uri: SpeechifyURI, edits: BookEdits, state: ContentImporterState): Result<Unit> {
        firestoreService.coSetDocument(
            path = getEditsPath(uri.id),
            value = Boundary.encodeToBoundaryMap(
                edits,
            ).orReturn { return it },
        ).orReturn { return it }

        firestoreService.coUpdateDocument(
            collectionRef = Collections.ITEMS.collectionRef,
            documentRef = uri.id,
            value = mapOf(
                RecordProperties.hasPageEdits.key.toPairWithVal(true),
            ).toBoundaryMap(),
        )

        if (state is ContentImporterState.ImportedToLibrary) {
            state.libraryItem.hasPageEditsFlow.value = true
        }

        return success()
    }

    internal suspend fun loadBookEditsPayload(uri: SpeechifyURI): Result<BookEdits> {
        val path = getEditsPath(uri.id)
        return firestoreService.coGetObjectOrNullIfNotExists<BookEdits>(
            path = path,
        ).fallbackNullValueToFailure {
            return Result.Failure(
                SDKError.ResourceNotFound(
                    path.documentPath,
                    "${path.documentPath} does not exist in the ${path.collectionPath} collection.",
                ),
            )
        }
    }
}
