package com.speechify.client.reader.core

import com.speechify.client.api.content.view.standard.StandardView
import com.speechify.client.helpers.content.standard.epub.EpubStandardViewV2

internal interface TocEntryTargetResolver {
    suspend fun resolveTarget(unresolvedTarget: TocEntryTarget.Unresolved): TocEntryTarget.Resolved
}

internal class EpubV2TocEntryTargetResolver(
    private val epubStandardViewV2: EpubStandardViewV2,
) : TocEntryTargetResolver {
    override suspend fun resolveTarget(
        unresolvedTarget: TocEntryTarget.Unresolved,
    ) = when (unresolvedTarget) {
        is TocEntryTarget.Unresolved.EpubChapter -> epubStandardViewV2.getContentCursorForNavPoint(
            unresolvedTarget.navPoint,
        )?.let {
            TocEntryTarget.Resolved(
                location = RobustLocation(
                    hack = SerialLocation(cursor = it),
                ),
            )
        } ?: throw IllegalStateException("Failed to resolve EPUB chapter target: $unresolvedTarget")
    }
}

internal class NoOpTocEntryTargetResolver : TocEntryTargetResolver {
    override suspend fun resolveTarget(unresolvedTarget: TocEntryTarget.Unresolved): TocEntryTarget.Resolved {
        throw IllegalStateException("No resolver available for target: $unresolvedTarget")
    }
}

internal object TocEntryTargetResolverFactory {
    fun createResolver(standardView: StandardView) = when (standardView) {
        is EpubStandardViewV2 -> EpubV2TocEntryTargetResolver(epubStandardViewV2 = standardView)
        else -> NoOpTocEntryTargetResolver()
    }
}
