// ktlint-disable filename
package com.speechify.client.internal.util.collections.flows

import com.speechify.client.internal.sync.CoLateInit
import com.speechify.client.internal.sync.coLazy
import com.speechify.client.internal.sync.getValueOrNull
import kotlinx.coroutines.flow.FlowCollector

/**
 * Useful for components that orchestrate multiple shared flows that have cancellation concerns. This is especially
 * usual for our [SharedFlowThatFinishesDestructible], which are often employed to adapt flow-based streams to
 * interfaces with discrete methods (i.e. which don't have any stream abstraction, so flows need to be 'shared').
 */
internal fun <T> lazyFlowStartingAfterCancellingPrevious(
    /**
     * Represented as a [CoLateInit] because  `swap` methods (which should be used for concurrency-control) will make
     * the previous flow available only once the new flow is created (using `swap`).
     */
    previousFlowSlot: CoLateInit<SharedFlowThatFinishesDestructible<T>>,
    getFlow: suspend () -> SharedFlowThatFinishesDestructible<T>,
): SharedFlowThatFinishesDestructible<T> =
    lazySharedFlowThatFinishesDestructible(
        createFlow = {
            previousFlowSlot.valueInited().destroyAndAwaitFinish()
            getFlow()
        },
    )

/**
 * General purpose lazy-flow for hiding initialization logic into the first collection of the flow.
 */
internal fun <T> lazySharedFlowThatFinishesDestructible(
    createFlow: suspend () -> SharedFlowThatFinishesDestructible<T>,
):
    SharedFlowThatFinishesDestructible<T> {
    val lazyVal = coLazy(supplier = createFlow)

    return object : SharedFlowThatFinishesDestructible<T> {
        override suspend fun collect(collector: FlowCollector<T>) =
            lazyVal.get().collect(collector)

        override suspend fun destroyAndAwaitFinish() =
            lazyVal.get().destroyAndAwaitFinish()

        override val replayCache: List<T>
            get() = lazyVal.getValueOrNull()?.replayCache ?: emptyList()
    }
}
