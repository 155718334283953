package com.speechify.client.internal.caching

import com.speechify.client.api.adapters.blobstorage.BlobStorageAdapter
import com.speechify.client.api.adapters.firebase.FirebaseStorageAdapter
import com.speechify.client.api.adapters.firebase.GoogleCloudStorageUri
import com.speechify.client.api.adapters.firebase.GoogleCloudStorageUriFileId
import com.speechify.client.api.adapters.firebase.coDelete
import com.speechify.client.api.adapters.firebase.coGetDownloadUrl
import com.speechify.client.api.adapters.firebase.coGetMetadata
import com.speechify.client.api.adapters.firebase.coPutBinaryContent
import com.speechify.client.api.adapters.firebase.coPutFile
import com.speechify.client.api.telemetry.withTelemetry
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.io.File
import com.speechify.client.api.util.io.coGetSizeInBytes
import com.speechify.client.api.util.orThrow
import com.speechify.client.api.util.successfully
import com.speechify.client.api.util.toNullSuccessIfResourceNotFound
import com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload
import com.speechify.client.bundlers.content.GenericFilePayload
import com.speechify.client.internal.http.HttpClient
import com.speechify.client.internal.util.www.UrlString

/**
 * A [CachedRemoteFileRepositoryWithHttpDownload] that uses [FirebaseStorageAdapter] as its remote storage
 * (and also implements [FirebaseStorageAdapter] itself, so it can be used in place of it).
 */
internal interface ReadWriteThroughCachedFirebaseStorage : FileRepositoryWithHttpDownload<GoogleCloudStorageUriFileId> {

    /**
     * Returns true if the item was removed, false if the item wasn't in the cache.
     */
    suspend fun deleteFileFromCache(
        fileId: GoogleCloudStorageUriFileId,
    ): Boolean

    /**
     * Returns true if the item is in the cache, false otherwise.
     */
    suspend fun isFileCached(
        fileId: GoogleCloudStorageUriFileId,
    ): Boolean
}

/**
 * The split into `interface` and this class is a workaround for error from tests (e.g. `AddPagesTests`):
 * `java.lang.AbstractMethodError: Receiver class com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload$BinaryContentReadableRandomlyWithMimeTypePayload$Subclass3 does not define or inherit an implementation of the resolved method 'abstract com.speechify.client.api.util.io.BinaryContentWithMimeTypeReadableInChunks getContentWithMimeType()' of interface com.speechify.client.bundlers.content.BinaryContentWithMimeTypePayload.
 * Trying to just use the class directly made `coEvery { putFile(any(), any()) }` execute the actual code of `putFile`
 * with some dummy instance produced by the `any()`, leading to a crash in the `coEvery`. Only introducing the interface
 * of the same name, thus making dependencies not know the actual implementation was able to alleviate the issue.
 */
internal class ReadWriteThroughCachedFirebaseStorageImpl(
    private val firebaseStorageAdapter: FirebaseStorageAdapter,
    blobStorageAdapter: BlobStorageAdapter,
    httpClient: HttpClient,
) : CachedRemoteFileRepositoryWithHttpDownload<GoogleCloudStorageUriFileId>(
    diagnosticAreaId = ReadWriteThroughCachedFirebaseStorage::class.simpleName!!,
    cacheStorage = blobStorageAdapter,
    httpClient = httpClient,
),
    ReadWriteThroughCachedFirebaseStorage {
    override suspend fun getDownloadUrl(fileId: GoogleCloudStorageUriFileId): UrlString =
        firebaseStorageAdapter.coGetDownloadUrl(fileId.stringValue)
            .orThrow()

    override suspend fun putFileToRemoteStorage(
        fileId: GoogleCloudStorageUriFileId,
        payload: BinaryContentWithMimeTypePayload<*, *>,
    ) {
        val metadata = firebaseStorageAdapter.coGetMetadata(ref = fileId.stringValue)
            // We get a ResourceNotFound error if the file doesn't exist, so we ignore it.
            .toNullSuccessIfResourceNotFound()
            .orThrow()

        if (metadata != null &&
            // We check the size, so we retry in case a file was half uploaded or otherwise changed.
            // Ideally we would check the hash, but SDK has no efficient way to calculate it.
            metadata.size == payload.contentWithMimeType.binaryContent.coGetSizeInBytes().orThrow()
        ) {
            // The file was already uploaded, no need to do anything.
            return
        }

        when (payload) {
            is BinaryContentWithMimeTypePayload.BinaryContentReadableRandomlyWithMimeTypePayload -> {
                firebaseStorageAdapter.coPutBinaryContent(
                    ref = fileId.stringValue,
                    binaryContent = payload.contentWithMimeType,
                )
                    .orThrow()
            }
            is BinaryContentWithMimeTypePayload.FilePayload -> {
                firebaseStorageAdapter.coPutFile(
                    ref = fileId.stringValue,
                    file = payload.contentWithMimeType,
                )
                    .orThrow()
            }
        }
    }

    override suspend fun deleteFileFromRemoteStorage(fileId: GoogleCloudStorageUriFileId) =
        firebaseStorageAdapter.coDelete(fileId.stringValue)
            .orThrow()

    override suspend fun deleteFileFromCache(
        fileId: GoogleCloudStorageUriFileId,
    ): Boolean = withTelemetry(
        telemetryEventName = "$diagnosticAreaId.deleteFileFromCache",
    ) {
        return@withTelemetry cacheStorage
            .coDeleteBlob(getCacheKey(fileId))
            .orThrow()
    }

    /**
     * @return true if the file is cached, false otherwise.
     */
    override suspend fun isFileCached(
        fileId: GoogleCloudStorageUriFileId,
    ): Boolean {
        val blob = cacheStorage.coGetBlob(getCacheKey(fileId)).orThrow()
        return blob != null
    }
}

@Deprecated("Refactor to use strongly typed [GoogleCloudStorageUriFileId] instead and use the overload that accepts it")
internal suspend fun ReadWriteThroughCachedFirebaseStorage.putFile(
    ref: GoogleCloudStorageUri,
    payload: BinaryContentWithMimeTypePayload<*, *>,
): Result<Unit> =
    putFile(
        fileId = GoogleCloudStorageUriFileId(ref),
        payload = payload,
    )
        .successfully()

@Deprecated("Refactor to use strongly typed [GoogleCloudStorageUriFileId] instead and use the overload that accepts it")
internal suspend fun ReadWriteThroughCachedFirebaseStorage.putFileByMove(
    ref: GoogleCloudStorageUri,
    payload: BinaryContentWithMimeTypePayload<*, *>,
) =
    putFileByMove(
        fileId = GoogleCloudStorageUriFileId(ref),
        payload = payload,
    )
        .successfully()

@Deprecated("Refactor to use strongly typed [GoogleCloudStorageUriFileId] instead and use the overload that accepts it")
internal suspend fun ReadWriteThroughCachedFirebaseStorage.getDownloadUrl(
    ref: GoogleCloudStorageUri,
): Result<UrlString> =
    getDownloadUrl(GoogleCloudStorageUriFileId(ref))
        .successfully()

@Deprecated(
    "Use strongly typed [GoogleCloudStorageUriFileId] overload of putFile or putFileByMove instead",
    ReplaceWith("putFile or putFileByMove"),
)
internal suspend fun ReadWriteThroughCachedFirebaseStorage.coPutFile(
    ref: GoogleCloudStorageUri,
    file: File,
): Result<Unit> =
    /** TODO The old behavior used to not distinct 'move', so we continue it with
     *   [ReadWriteThroughCachedFirebaseStorage.putFile], but when replacing this usage with a direct call,
     *   [ReadWriteThroughCachedFirebaseStorage.putFileByMove] should be considered where the [file] is
     *   a content downloaded temporarily by the product, without the user being aware of its save location
     *   #TODOCommunicateFileMoveSemanticsForStoringTempFiles
     */
    putFile(
        ref = ref,
        payload = GenericFilePayload(file),
    )
