// ktlint-disable filename
package com.speechify.client.internal.util.www

/**
 * A string that contains a valid URL.
 */
typealias UrlString = String

private fun String?.nullIfBlank(): String? = when {
    this == null -> null
    this.isBlank() -> null
    else -> this
}

internal data class UrlAuthority private constructor(
    val user: String?,
    val password: String?,
    val host: String?,
    val port: Int?,
) {
    override fun toString(): String {
        val userInfo = user?.let { "$user${if (password != null) ":$password" else ""}@" } ?: ""
        val portStr = port?.let { ":$port" } ?: ""
        return "$userInfo$host$portStr"
    }

    companion object {
        /**
         * Helper [UrlAuthority] factory function to cleanup inputs coming from platform libs that have slightly different behavior
         * regarding surrounding URL parts and whitespace
         */
        fun create(
            user: String?,
            password: String?,
            host: String?,
            port: Int?,
        ) = UrlAuthority(
            user = user.nullIfBlank(),
            password = password.nullIfBlank(),
            host = host.nullIfBlank(),
            port = port,
        )
    }
}

internal data class Url private constructor(
    val scheme: String?,
    val authority: UrlAuthority?,
    val path: String?,
    val query: String?,
    val hash: String?,
) {
    override fun toString(): String {
        val authorityStr = authority?.toString() ?: ""
        val pathStr = path?.let { if (it.startsWith("/")) it else "/$it" } ?: ""
        val queryStr = query?.let { if (it.startsWith("?")) it else "?$it" } ?: ""
        val hashStr = hash?.let { if (it.startsWith("#")) it else "#$it" } ?: ""

        return "$scheme://${authorityStr}${pathStr}${queryStr}$hashStr"
    }

    companion object {
        /**
         * Helper [Url] factory function to cleanup inputs coming from platform libs that have slightly different behavior
         * regarding surrounding URL parts and whitespace
         */
        fun create(
            scheme: String?,
            authority: UrlAuthority?,
            path: String?,
            query: String?,
            hash: String?,
        ) = Url(
            scheme = scheme.nullIfBlank()?.trim(':', '/'),
            authority = authority,
            path = path.nullIfBlank(),
            query = query.nullIfBlank()?.trim('?'),
            hash = hash.nullIfBlank()?.trim('#'),
        )
    }
}

/**
 * Parse a URL, optionally resolving it against a base URL.
 * @return null if encountered parsing error for either [url] or [relativeToBaseUrl].
 */
internal expect fun parseUrl(url: String, relativeToBaseUrl: String? = null): Url?
