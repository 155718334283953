package com.speechify.client.api.content.editing

import com.speechify.client.api.adapters.pdf.TextInBoundingBoxResult
import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentCursor
import com.speechify.client.api.content.ContentElementBoundary
import com.speechify.client.api.content.TableOfContents
import com.speechify.client.api.content.TextElementContentSlice
import com.speechify.client.api.content.TransientContentElementReference
import com.speechify.client.api.content.ml.MLParsingMode
import com.speechify.client.api.content.ocr.OcrFallbackStrategy
import com.speechify.client.api.content.view.book.BookMetadata
import com.speechify.client.api.content.view.book.BookPage
import com.speechify.client.api.content.view.book.BookPageMetadata
import com.speechify.client.api.content.view.book.BookPageRequestOptions
import com.speechify.client.api.content.view.book.BookPageTextContentItem
import com.speechify.client.api.content.view.book.BookView
import com.speechify.client.api.content.view.book.ParsedPageContent
import com.speechify.client.api.content.view.book.UnstableBookPageTextContentItem
import com.speechify.client.api.content.view.book.coGetPages
import com.speechify.client.api.content.view.book.lastPageIndex
import com.speechify.client.api.content.view.book.search.BookSearchOptions
import com.speechify.client.api.content.view.book.search.BookSearchResult
import com.speechify.client.api.content.view.book.toRawOrderedTextItems
import com.speechify.client.api.content.view.book.translateToUsableCursor
import com.speechify.client.api.content.view.standard.StandardBlock
import com.speechify.client.api.content.view.standard.StandardBlocks
import com.speechify.client.api.editing.BookEdits
import com.speechify.client.api.editing.PageContentPartKind
import com.speechify.client.api.editing.regionsNormalized
import com.speechify.client.api.util.Callback
import com.speechify.client.api.util.Result
import com.speechify.client.api.util.fromCo
import com.speechify.client.api.util.images.BoundingBox
import com.speechify.client.api.util.images.contains
import com.speechify.client.api.util.io.BinaryContentReadableRandomly
import com.speechify.client.api.util.io.BinaryContentWithMimeTypeFromNativeReadableInChunks
import com.speechify.client.api.util.successfully
import com.speechify.client.bundlers.content.BookPageIndex
import com.speechify.client.internal.services.ml.ParsedBookPageTextGroup
import com.speechify.client.internal.services.ml.models.TextGroupType
import com.speechify.client.internal.sync.WrappingMutex
import com.speechify.client.internal.util.DiffMatchPatch
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import kotlin.math.min

/**
 * This book view removes the pages of the original book view and rewrites [BookPage.pageIndex] to reflect
 * hiding of preceding pages.
 */
internal class EditingBookView(initialBookEdits: BookEdits, bookView: BookView) : BookView() {
    val originalBookView = bookView.unEdited()

    override val bookEditsFlow: MutableSharedFlow<BookEdits> = originalBookView.bookEditsFlow

    private val bookEditsStateFlow: StateFlow<BookEdits> = bookEditsFlow.stateIn(
        scope = scope,
        started = SharingStarted.Eagerly,
        initialValue = initialBookEdits,
    )

    internal val bookEdits get() = bookEditsStateFlow.value

    private val editedByOriginalIndexes: Map<Int, Int> get() =
        bookEdits.pages
            .withIndex()
            .filterNot { (_, pageEdit) -> pageEdit.hidden }
            .mapIndexed { indexPostEdit, pageEditWithOriginalIndex ->
                pageEditWithOriginalIndex.index to indexPostEdit
            }
            .toMap()

    private val originalByEditedIndexes: Map<Int, Int> get() =
        editedByOriginalIndexes.asSequence()
            .map { it.value to it.key }.toMap()

    override val start: ContentCursor
        get() = originalBookView.start
    override val end: ContentCursor
        get() = originalBookView.end

    override val parsedPageContentFlow get() = originalBookView.parsedPageContentFlow

    override val mlParsingModeFlow: StateFlow<MLParsingMode> = originalBookView.mlParsingModeFlow

    override val ocrFallbackStrategyFlow: StateFlow<OcrFallbackStrategy> = originalBookView.ocrFallbackStrategyFlow
    override fun initializeBookViewFlows() {
        // Nothing needed for EditingBookView
    }

    private val pageCache = WrappingMutex.of(mutableMapOf<Int, EditingBookPage?>())

    init {
        scope.launch {
            bookEditsFlow.collect {
                pageCache.locked {
                    it.clear()
                }
            }
        }
    }

    override fun getPageIndex(cursor: ContentCursor): Int =
        getPageIndexOrSubstituteFromCursorPreEdits(
            cursorPreEdits = cursor,
        ) ?: 0 /* If there is no pages starting from cursor, then they have been removed. Using 0 which should be there,
          because currently there's already a lot of code assuming that there is some content in the book and SDK
          consumers are already preventing this case from happening, e.g. [here](https://speechifyworkspace.slack.com/archives/C04HDU10L1J/p1676373894009829).
         */

    override suspend fun translateToUsableCursor(originalCursor: ContentCursor): ContentCursor? {
        val pageIndex = getPageIndex(originalCursor)
        val bookPage = coGetPages(arrayOf(pageIndex)).orReturn { return null }.single()
        return bookPage.translateToUsableCursor(originalCursor)
    }

    // TODO: Consider stripping out the missing entries after editing
    override suspend fun getTableOfContents(): TableOfContents? {
        return if (bookEdits.hasEdits) null else originalBookView.getTableOfContents()
    }

    override fun getPages(
        pageIndexes: Array<Int>,
        callback: Callback<Array<BookPage>>,
    ) = callback.fromCo(scope) {
        getPages(pageIndexes)
    }

    internal suspend fun getPages(pageIndexesPostEdit: Array<Int>): Result<Array<BookPage>> {
        if (pageIndexesPostEdit.isEmpty()) return emptyArray<BookPage>().successfully()
        // Caching here, despite the underlying BookView already having caching is needed due to the slightly leaky way
        // page caches are structured.
        // While each BookPage caches its raw BookPageTextContentItem output. The cache of the content in reading order
        // is only populated when it's accessed, and more importantly only cached on the outermost BookPage.
        // So despite the underlying BookPages being cached if we returned a new one here everytime we would incur the
        // cost of content sorting multiple times. Hence why we add yet another layer of caching.
        // TODO: Clean up the BookPage API so wrapping comes with less foot-guns.
        return pageCache.locked { cache ->
            val unCachedIndexesPostEdit = pageIndexesPostEdit.filter { cache[it] == null }.toTypedArray()

            if (unCachedIndexesPostEdit.isNotEmpty()) {
                val newPagesForCache =
                    originalBookView
                        .coGetPages(
                            unCachedIndexesPostEdit.map { indexPostEdit ->
                                originalByEditedIndexes[indexPostEdit]
                                    ?: throw IllegalStateException(
                                        "Page index $indexPostEdit (post-edit) not found among original book indexes" +
                                            " (containing ${originalByEditedIndexes.size} items)",
                                    )
                            }.toTypedArray(),
                        )
                        .map { originalPages ->
                            originalPages
                                .asSequence()
                                .map { originalPage ->
                                    EditingBookPage(
                                        editedPage = bookEdits.pages[originalPage.pageIndex],
                                        bookPage = originalPage,
                                        indexPostEdit =
                                        editedByOriginalIndexes[originalPage.pageIndex]
                                            ?: throw IllegalStateException(
                                                "Page index ${originalPage.pageIndex} (original) not found among" +
                                                    " post-edit book indexes " +
                                                    "(containing ${editedByOriginalIndexes.size} items)",
                                            ),
                                    )
                                }
                                .toList()
                                .toTypedArray()
                        }.orReturn { return@locked it }

                for (
                (indexMissingInCache, newPageForCache)
                in
                unCachedIndexesPostEdit.asSequence().zip(newPagesForCache.asSequence())
                ) {
                    cache[indexMissingInCache] = newPageForCache
                }
            }

            pageIndexesPostEdit.map {
                cache[it]
                    ?: throw IllegalStateException("Page index $it not found in cache (containing ${cache.size} items)")
            }.toTypedArray<BookPage>().successfully()
        }
    }

    override fun search(
        text: String,
        startPageIndex: BookPageIndex,
        endPageIndex: BookPageIndex,
        searchOptions: BookSearchOptions,
        callback: Callback<Array<BookSearchResult>>,
    ) {
        originalBookView.search(text, startPageIndex, endPageIndex, searchOptions, callback)
    }

    override fun getMetadata(): BookMetadata {
        val metadata = originalBookView.getMetadata()
        return metadata
            .copy(
                numberOfPages =
                (metadata.numberOfPages - bookEdits.pages.count { it.hidden })
                    .coerceAtLeast(0),
            )
    }

    /**
     * @return `null` if there is no pages with or even after the cursor (all pages from cursor were removed)
     */
    private fun getPageIndexOrSubstituteFromCursorPreEdits(cursorPreEdits: ContentCursor): Int? =
        /** Using [tryGetPageIndexFromCursorPreEdits] is especially needed to cater for the cases where
         [cursorPreEdits] is a start cursor.
         */
        tryGetPageIndexFromCursorPreEdits(cursorPreEdits)
            .let { result ->
                when (result) {
                    is TryGetEditedPageIndexResult.Found -> {
                        result.indexPostEdit
                    }

                    is TryGetEditedPageIndexResult.PageRemoved -> {
                        /* This logic decides the order of pages to try, if the current one has been removed.
                           Currently, reordering is not supported, so we can just traverse the original indexes
                           order.
                           With reordering, it would be possible to be more helpful, e.g. if the page was removed but another
                           one added in its place or after, then it could make sense to not skip these.
                         */
                        (result.indexPreRemoval..originalBookView.getMetadata().lastPageIndex)
                            .firstNotNullOfOrNull {
                                editedByOriginalIndexes[it]
                            }
                    }
                }
            }

    private fun tryGetPageIndexFromCursorPreEdits(cursorPreEdits: ContentCursor): TryGetEditedPageIndexResult {
        if (cursorPreEdits is ContentElementBoundary &&
            cursorPreEdits.element.path.isEmpty()
        ) {
            return TryGetEditedPageIndexResult.Found(
                indexPostEdit =
                when (cursorPreEdits.boundary) {
                    is ContentBoundary.START -> 0
                    is ContentBoundary.END -> getMetadata().numberOfPages - 1
                },
            )
        } else {
            val indexInOriginalBook =
                originalBookView
                    .getPageIndex(cursorPreEdits)

            if (bookEdits.pages[indexInOriginalBook].hidden) {
                return TryGetEditedPageIndexResult.PageRemoved(
                    indexInOriginalBook,
                )
            }

            return TryGetEditedPageIndexResult.Found(
                indexPostEdit =
                editedByOriginalIndexes[indexInOriginalBook]
                    ?: throw IllegalStateException(
                        "Page index $indexInOriginalBook (original) not found among post-edit book indexes" +
                            "(containing ${editedByOriginalIndexes.size} items)",
                    ),
            )
        }
    }

    override fun destroy() {
        super.destroy()
        val pagesToDestroy = pageCache.swap(mutableMapOf())
        pagesToDestroy.values.forEach { it?.destroy() }
        originalBookView.destroy()
    }

    private sealed class TryGetEditedPageIndexResult {
        class Found(val indexPostEdit: Int) : TryGetEditedPageIndexResult()

        class PageRemoved(val indexPreRemoval: Int) : TryGetEditedPageIndexResult()
    }
}

internal class EditingBookPage(
    private val editedPage: BookEdits.Page,
    /**
     * NOTE: Its [BookPage.pageIndex] is the pre-edits index.
     */
    private val bookPage: BookPage,
    /**
     * The index after applying the page edits.
     */
    private val indexPostEdit: Int,
) : BookPage() {

    val regionsOfInterest = editedPage.regions

    override val start: ContentCursor
        get() = bookPage.start
    override val end: ContentCursor
        get() = bookPage.end

    override val pageIndex: Int
        get() = indexPostEdit

    override suspend fun getStableParsedPageContent(): Result<ParsedPageContent> {
        val parsedPageContent = bookPage.getStableParsedPageContent().orReturn { return it }
        return applyPageEditsAndTextReplacements(parsedPageContent)
    }

    override suspend fun getTextInBounds(boxes: List<BoundingBox>): TextInBoundingBoxResult {
        return bookPage.getTextInBounds(boxes)
    }

    override suspend fun getUnstableTextContentApproximatelyOrdered(): Result<List<UnstableBookPageTextContentItem>> {
        val normalizedRegions = editedPage.regionsNormalized(bookPage.getMetadata().viewport)
        val textContent = bookPage.getUnstableTextContentApproximatelyOrdered().orReturn { return it }
        return textContent.toTypedArray().sortedByRegionsAddingPath(normalizedRegions).let {
            getReplacementTextForTextContent(it) ?: it
        }.toList().successfully()
    }

    /**
     * Firstly, it selectively filters text content within the [BookEdits.Page.regions], regardless of whether
     * the text content is classified or not.
     *
     * Secondly, it implements text replacement for the filtered text content.
     */
    private fun applyPageEditsAndTextReplacements(parsedPageContent: ParsedPageContent): Result<ParsedPageContent> {
        val normalizedRegions = editedPage.regionsNormalized(bookPage.getMetadata().viewport)
        val editedParsedPageContent = parsedPageContent.sortedByRegionsAddingPath(normalizedRegions)

        val replacementText =
            getReplacementTextForTextContent(editedParsedPageContent.toRawOrderedTextItems())
                ?: return editedParsedPageContent.successfully()

        // TODO: Correct this behavior: where text content item replacements are grouped into a single default paragraph
        //  text group. Instead, we should provide replacements without losing their text group classifications.
        //  Note: While this issue doesn't impact user experience, however it's preferable to retain text groupings whenever possible.
        return ParsedPageContent(
            listOf(
                ParsedBookPageTextGroup(
                    TextGroupType.Paragraph,
                    replacementText.toList(),
                ),
            ),
        ).successfully()
    }

    private fun ParsedPageContent.sortedByRegionsAddingPath(
        normalizedRegions: Array<BoundingBox>,
    ): ParsedPageContent {
        if (normalizedRegions.isEmpty()) return this

        val newTextGroups = mutableListOf<ParsedBookPageTextGroup>()
        var index = 0

        for (region in normalizedRegions) {
            val filteredGroups = textItemsGroupedAndOrdered
                .filter { group ->
                    group.labeledBookPageTextContentItems.any { region.contains(it.normalizedBox) }
                }
                .map { group ->
                    val newItems = group.labeledBookPageTextContentItems
                        .filter { region.contains(it.normalizedBox) }
                        .map { item ->
                            val slice = item.text
                            val newElement =
                                TransientContentElementReference(slice.element, pathAddition = index++)
                            item.copy(text = slice.copy(element = newElement))
                        }
                    group.copy(labeledBookPageTextContentItems = newItems)
                }
            newTextGroups.addAll(filteredGroups)
        }

        return this.copy(textItemsGroupedAndOrdered = newTextGroups)
    }

    private fun Array<BookPageTextContentItem>.sortedByRegionsAddingPath(
        normalizedRegions: Array<BoundingBox>,
    ): Array<BookPageTextContentItem> {
        if (normalizedRegions.isEmpty()) return this
        val newText = mutableListOf<BookPageTextContentItem>()
        val groupedByRegion =
            this.groupBy {
                normalizedRegions.indexOfFirst { region ->
                    region.contains(it.normalizedBox)
                }
            }

        for (index in normalizedRegions.indices) {
            newText += groupedByRegion[index] ?: emptyList()
        }

        // In order for the content cursors to be sorted correctly, we need to wrap the original ContentElementReference
        // and add a path addition to it. This way the sorting will follow the order specified here, and not the order
        // reported by the underlying content source.
        return newText.mapIndexed { index, item ->
            val slice = item.text
            val newElement = TransientContentElementReference(slice.element, pathAddition = index)
            item.copy(text = slice.copy(element = newElement))
        }.toTypedArray()
    }

    /**
     * When there is replacement text, we need to return it instead of the original text.
     * This will on a best-effort basis map the replacement text content onto the original text, keeping
     * any bounds intact. Doing this allows us to, where possible, keep original mode reading available.
     */
    private fun getReplacementTextForTextContent(
        originalPageContent: Array<BookPageTextContentItem>,
    ): Array<BookPageTextContentItem>? {
        val replacementPageContent = editedPage.replacementPageContent ?: return null
        val pageElement = start.getParentElement()

        val diffMatchPatch = DiffMatchPatch()

        // We get a list of diffs we can apply to the original page content so the text is the same as our
        // replacement content.
        val diffs =
            diffMatchPatch.diff_main(
                originalPageContent.joinToString("") { it.text.text },
                replacementPageContent.content.joinToString("") { it.text.trim() },
            )

        val originalContentItemsToProcess = originalPageContent.toMutableList()
        val updatedContentItems = mutableListOf<BookPageTextContentItem>()

        // We keep track of the current piece of the original content we are working with, and where in the content
        // we are. This way we can apply all the diffs to the original content so once we're done it matches our
        // replacement content.
        var contentItemBeingProcessed = originalContentItemsToProcess.removeFirst()
        var indexInContentItemBeingProcessed = 0
        while (diffs.isNotEmpty()) {
            val currentDiff = diffs.removeFirst()
            do {
                if (currentDiff.operation != DiffMatchPatch.Operation.INSERT &&
                    indexInContentItemBeingProcessed == contentItemBeingProcessed.text.text.length
                ) {
                    // Only grab the next chunk if there is one, otherwise keep working with the current one.
                    if (originalContentItemsToProcess.size > 0) {
                        updatedContentItems.add(contentItemBeingProcessed)
                        contentItemBeingProcessed = originalContentItemsToProcess.removeFirst()
                        indexInContentItemBeingProcessed = 0
                    }
                }
                val contentItemBeingProcessedText = contentItemBeingProcessed.text.text
                when (currentDiff.operation) {
                    DiffMatchPatch.Operation.DELETE -> {
                        val overlapRange =
                            min(
                                currentDiff.text.length,
                                (contentItemBeingProcessedText.length - indexInContentItemBeingProcessed),
                            )
                        // Remove the processed text from the diff. Once the diff is empty we process the next.
                        currentDiff.text = currentDiff.text.drop(overlapRange)

                        // Delete the requested content.
                        val textWithRangeRemoved =
                            contentItemBeingProcessedText.removeRange(
                                indexInContentItemBeingProcessed,
                                indexInContentItemBeingProcessed + overlapRange,
                            )
                        contentItemBeingProcessed =
                            contentItemBeingProcessed
                                .copy(
                                    text =
                                    TextElementContentSlice(
                                        pageElement,
                                        contentItemBeingProcessed.text.range,
                                        textWithRangeRemoved,
                                    ),
                                )
                    }

                    DiffMatchPatch.Operation.INSERT -> {
                        val textBeforeInsertionPoint =
                            contentItemBeingProcessedText.substring(0, indexInContentItemBeingProcessed)
                        val textAfterInsertionPoint =
                            contentItemBeingProcessedText.substring(indexInContentItemBeingProcessed)

                        contentItemBeingProcessed =
                            contentItemBeingProcessed
                                .copy(
                                    text =
                                    TextElementContentSlice(
                                        pageElement,
                                        contentItemBeingProcessed.text.range,
                                        textBeforeInsertionPoint + currentDiff.text + textAfterInsertionPoint,
                                    ),
                                )
                        // Make sure we point after the insertion so the diffs get applied at the right spot.
                        indexInContentItemBeingProcessed += currentDiff.text.length

                        // Remove the processed text from the diff. Once the diff is empty we process the next.
                        currentDiff.text = ""
                    }

                    DiffMatchPatch.Operation.EQUAL -> {
                        val overlapRange =
                            min(
                                currentDiff.text.length,
                                (contentItemBeingProcessed.text.text.length - indexInContentItemBeingProcessed),
                            )
                        // Remove the processed text from the diff. Once the diff is empty we process the next.
                        currentDiff.text = currentDiff.text.drop(overlapRange)
                        indexInContentItemBeingProcessed += overlapRange
                    }
                }
            } while (currentDiff.text.isNotEmpty())
        }

        // After the loop is done there is one dangling piece of content to add.
        updatedContentItems.add(contentItemBeingProcessed)

        // Since the text in each page text item now matches exactly our replacement text,
        // the ranges generated here will be compatible with the ranges generated in getStandardBlocks.
        var prevIndex = 0
        return updatedContentItems.map { pageText ->
            val text = pageText.text
            val range = Pair(prevIndex, prevIndex + text.length)
            prevIndex = range.second
            val slice = TextElementContentSlice(pageElement, range, text.text)
            pageText.copy(text = slice)
        }.toTypedArray()
    }

    override suspend fun getStandardBlockRepresentation(): StandardBlocks? {
        val replacementPageContent =
            editedPage.replacementPageContent
                ?: return bookPage.getStandardBlockRepresentation()
        val pageElement = start.getParentElement()
        var prevIndex = 0
        val blocks =
            replacementPageContent.content.asSequence().fold(mutableListOf<StandardBlock>()) { acc, item ->
                val range = Pair(prevIndex, prevIndex + item.text.trim().length)
                prevIndex = range.second
                val text = TextElementContentSlice(pageElement, range, item.text.trim())

                val block =
                    when (item.kind) {
                        PageContentPartKind.HEADING -> {
                            StandardBlock.Heading(text)
                        }

                        PageContentPartKind.TEXT -> {
                            StandardBlock.Paragraph(text)
                        }

                        PageContentPartKind.HEADER -> StandardBlock.Header(text)
                        PageContentPartKind.FOOTER -> StandardBlock.Footer(text)
                        PageContentPartKind.FOOTNOTE -> StandardBlock.Footnote(text)
                        PageContentPartKind.UNKNOWN -> StandardBlock.Paragraph(text)
                    }
                acc.add(block)

                acc
            }.toTypedArray()
        return StandardBlocks(blocks, start, end)
    }

    override fun getMetadata(): BookPageMetadata = bookPage.getMetadata()

    override fun getImage(
        options: BookPageRequestOptions,
        callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>,
    ) {
        bookPage.getImage(options, callback)
    }

    override fun destroy() {
        bookPage.destroy()
        scope.cancel()
    }
}

internal fun BookView.unEdited(): BookView {
    return when (this) {
        is EditingBookView -> originalBookView
        else -> this
    }
}
private val BookEdits.hasEdits get() = pages.any { it.isEdited }

private val BookEdits.Page.isEdited get() = regions.isNotEmpty() || hidden || replacementPageContent != null
