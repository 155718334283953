package com.speechify.client.api.content.view.speech

import com.speechify.client.api.content.ContentBoundary
import com.speechify.client.api.content.ContentDirection

internal sealed class CursorQueryScan(val direction: ContentDirection) {
    class Chars(direction: ContentDirection, val skipping: Int) : CursorQueryScan(direction) {
        override fun toString() = "${super.toString()}(skipping=$skipping)"
    }

    class Words(direction: ContentDirection, val boundary: ContentBoundary, val skipping: Int) :
        CursorQueryScan(direction) {

        override fun toString() = "${super.toString()}(boundary=$boundary, skipping=$skipping)"
    }

    class Sentences(direction: ContentDirection, val boundary: ContentBoundary, val skipping: Int) :
        CursorQueryScan(direction) {

        override fun toString() = "${super.toString()}(boundary=$boundary, skipping=$skipping)"
    }

    override fun toString(): String =
        "${this::class.simpleName ?: this::class.toString()}($direction)"
}
