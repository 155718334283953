package com.speechify.client.api.adapters.mediaplayer

import kotlin.js.JsExport

@JsExport
sealed class LocalMediaPlayerEvent {
    object Started : LocalMediaPlayerEvent()
    sealed class NotPlaying : LocalMediaPlayerEvent()
    object Stopped : NotPlaying()
    object Ended : NotPlaying()
}
