package com.speechify.client.bundlers

import com.speechify.client.api.SpeechifyClient
import com.speechify.client.api.audio.DefaultVoiceFactory
import com.speechify.client.api.audio.UtteranceFactory
import com.speechify.client.api.audio.Voice
import com.speechify.client.api.audio.VoiceFactory
import com.speechify.client.api.audio.VoiceSpec
import kotlin.js.JsExport

/**
 * The [BundlerPlugins] exposes well-defined interfaces for modifying and extending core functionality of the Listening Stack.
 */
@JsExport
class BundlerPlugins private constructor(
    private val client: SpeechifyClient,
    val voiceFactory: VoiceFactory,
    internal val bookViewFactory: BookViewFactory,
) {
    companion object {
        internal fun defaultPluginsFromClient(
            client: SpeechifyClient,
            bundlerFactoryConfig: BundlerFactoryConfig,
        ): BundlerPlugins {
            return with(client) {
                // Voice List Initialization
                BundlerPlugins(
                    client = client,
                    voiceFactory = DefaultVoiceFactory(
                        mediaSynthesisService = audioService,
                        localSpeechSynthesisPlayerFactory = adaptersProvider.localSpeechSynthesis,
                        localSpeechSynthesisVoiceBySpecProvider = adaptersProvider
                            .localSpeechSynthesisVoiceBySpecProvider,
                        mediaPlayerFactory = adaptersProvider.localMediaPlayer,
                    ),
                    bookViewFactory = BookViewFactory(
                        bundlerFactoryConfig.bookReadingBundlerConfig.options,
                        ocrAdapter = client.adaptersProvider.ocrAdapter,
                        scannedBookService = client.platformScannedBookService,
                        clientConfig = client.clientConfig,
                        firebaseStorageCache = client.firebaseStorageCache,
                        platformMLParsedBookPageService = client.platformMLParsedBookPageService,
                        imageConverter = client.adaptersProvider.imageConverter,
                        mlPageParsingWithRemoteOCRService = client.mlPageParsingWithRemoteOCRService,
                        mlParsingModeFlow = bundlerFactoryConfig.contentBundlerConfig.options.mlParsingModeFlow,
                        userProfileService = client.userProfileService,
                    ),
                )
            }
        }
    }

    /**
     * Override the [VoiceFactory] used to create [Voice] instances from [VoiceSpec]s provided to the [BundlerFactory].
     *
     * Your override function has access the current [BundlerPlugins], so you can delegate to the current [VoiceFactory] as appropriate.
     * It also includes [CustomVoiceHelpers] as the second parameter, making available lower level SDK components for
     * reuse.
     */
    fun withVoiceFactory(
        block: (
            old: BundlerPlugins,
            helpers: CustomVoiceHelpers,
        ) -> VoiceFactory,
    ): BundlerPlugins =
        this.copy(
            voiceFactory = block(
                /* old = */
                this,
                /* helpers = */
                customVoiceHelpers,
            ),
        )

    /**
     * Derive a new [BundlerPlugins] by applying an arbitrary mapping. Useful for applying clean grouping of overrides
     * via a fluent API.
     *
     * For example, `plugins.with(CharacterVoiceSupportPlugins)` might apply a number of specific overrides, but using this API makes the top-level application much clearer.
     */
    fun with(block: (old: BundlerPlugins) -> BundlerPlugins): BundlerPlugins {
        return block(this)
    }

    fun copy(
        /* Not using `data` class' `copy()` feature, not to expose the confusion of being able to override all
         members through it. */
        voiceFactory: VoiceFactory? = null,
    ) =
        BundlerPlugins(
            client = client,
            voiceFactory = voiceFactory ?: this.voiceFactory,
            bookViewFactory = bookViewFactory,
        )

    @Deprecated(
        "utteranceFactory as a top level member of `BundlerPlugins` was deprecated to make it clear that it" +
            " can only be used for building a custom VoiceFactory",
        ReplaceWith(
            "withVoiceFactory((oldPlugins, helpers) -> helpers.utteranceFactory)",
        ),
    )
    val utteranceFactory: UtteranceFactory
        get() = customVoiceHelpers.utteranceFactory

    // TODO - inline this member, once the deprecated [utteranceFactory] is removed
    private val customVoiceHelpers: CustomVoiceHelpers
        get() = CustomVoiceHelpers(
            utteranceFactory = UtteranceFactory(
                localSpeechSynthesisPlayerFactory = client.adaptersProvider.localSpeechSynthesis,
                mediaPlayerFactory = client.adaptersProvider.localMediaPlayer,
            ),
        )
}

/**
 * Carries the components that can be useful for making custom [Voice] implementations, to make available the
 * lower-level SDK components for reuse.
 */
@JsExport
class CustomVoiceHelpers(
    val utteranceFactory: UtteranceFactory,
)
