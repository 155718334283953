package com.speechify.client.api

import com.benasher44.uuid.uuid4
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * The SpeechifyURI provides the type and identifier of an Entity. Identifiers are only guaranteed to be unique within a given type.
 */
@Serializable
@JsExport
class SpeechifyURI private constructor(

    /** What type of entity is this? */
    val type: SpeechifyEntityType,

    /** What instance is this? */
    val id: SpeechifyContentId,
) {
    companion object {
        internal fun generate(kind: SpeechifyEntityType) = SpeechifyURI(kind, uuid4().toString())

        fun fromExistingId(kind: SpeechifyEntityType, id: String) = SpeechifyURI(kind, id)

        internal fun SpeechifyEntityType.intoUri(id: String) = fromExistingId(this, id)

        /**
         * Parse a String to a [SpeechifyURI].
         *
         * Format is expected to be "<type>/<id>". For example:
         * - LIBRARY_ITEM/02f33488-0ec8-11ed-861d-0242ac120002
         *
         * See [toString] for the method that formats these strings.
         */
        fun fromString(string: String): SpeechifyURI {
            val groups = Regex("([\\w_]+)/([\\w-]+)").matchEntire(string)?.groupValues?.drop(1)
            if (groups == null || groups.size != 2) throw IllegalArgumentException("Invalid URI format: $string")
            val (type, id) = groups
            return fromExistingId(SpeechifyEntityType.valueOf(type), id)
        }
    }

    /**
     * Return a String representation of this URI.
     *
     * Format will be "<type>/<id>". For example:
     * - LIBRARY_ITEM/02f33488-0ec8-11ed-861d-0242ac120002
     *
     * See [fromString] for the method that parses these strings.
     */
    override fun toString(): String {
        return "${type.name}/$id"
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SpeechifyURI

        if (type != other.type) return false
        if (id != other.id) return false

        return true
    }

    override fun hashCode(): Int {
        var result = type.hashCode()
        result = 31 * result + id.hashCode()
        return result
    }
}

/**
 * The identifier part of a [SpeechifyURI].
 *
 * NOTE: Identifiers are only guaranteed to be unique within a given [SpeechifyEntityType].
 */
typealias SpeechifyContentId = String
