package com.speechify.client.internal.util

/**
 *
 *
 * @return a list of [RegexMatch]es describing each match of the [pattern] within [text], including its capture groups.
 */
internal expect fun getAllMatches(pattern: String, text: String): List<RegexMatch>

/**
 * @return a list of [RegexMatchRange]es describing the extent of each match of [pattern] within [text]. If any capture
 * groups match, the returned range will describe the first matching capture group. Otherwise, it will describe the
 * entire match.
 *
 * #RationaleForRegexCaptureGroupBehavior
 * NOTE(anson): The ideal contract here would be to simply use the range of the entire match, with callers using
 * lookahead/lookbehind assertions to achieve the non-matching behavior for which we're currently (ab?)using capture
 * groups. This would make for a less-surprising public API.
 * However, we choose the capture group approach because, as of Feb 4, 2023, Safari doesn't support
 * [lookbehind assertions](https://caniuse.com/js-regexp-lookbehind) assertions. Since our sentence-splitting currently
 * depends on lookbehind-like functionality, this is a dealbreaker.
 *
 * Using an ugly name to highlight this :)
 * */
internal fun findRangesOfFirstMatchingCaptureGroupOrElseEntireMatch(
    pattern: String,
    text: String,
): List<RegexMatchRange> {
    return getAllMatches(pattern, text).map { it.getRangeOfFirstCaptureGroupOrEntireMatch() }
}

/**
 * Describes a match of a regular expression within a string
 */
internal data class RegexMatch(
    /**
     * The entire substring matched by the pattern
     */
    val value: String,

    /**
     * The index range within the input string matched by the pattern
     */
    val range: RegexMatchRange,

    /**
     * The unnamed capture group results, in the order in which they appear in the expression. Contrary to many APIs,
     * does *not* include the entire match. See [RegexMatch] for that.
     *
     * All matched and unmatched capture groups will appear here, either as [RegexMatchGroup.Match] or [RegexMatchGroup.NoMatch].
     */
    val unnamedCaptureGroups: List<RegexMatchGroup>,
) {
    /**
     * @return the [RegexMatchRange] of the first matching capture group if any exist, otherwise return
     * the [RegexMatchRange] of the entire match
     */
    fun getRangeOfFirstCaptureGroupOrEntireMatch(): RegexMatchRange {
        return unnamedCaptureGroups
            .filterIsInstance<RegexMatchGroup.Match>()
            .map { it.range }
            .firstOrNull() ?: range
    }
}

/**
 * Represents the two outcomes of a capture group's application in pattern, so that consumers can correlate items in
 * the list of results with the groups as written in the pattern if desired, even if the patterns didn't match.
 */
internal sealed class RegexMatchGroup {
    data class Match(val value: String, val range: RegexMatchRange) : RegexMatchGroup()
    object NoMatch : RegexMatchGroup()
}

internal data class RegexMatchRange(
    val startIndex: Int,
    val endIndexExclusive: Int,
)
