package com.speechify.client.api.services.audiobook

import com.speechify.client.api.SpeechifyEntityType
import com.speechify.client.api.SpeechifyURI
import com.speechify.client.helpers.features.SyncedListeningProgress
import kotlin.js.JsExport

@JsExport
/**
 * Bundles a book and it's chapters as a convenient DTO.
 */
data class AudiobookWithChapters(
    val book: Audiobook,
    val chapters: Array<AudiobookChapter>,
)

@JsExport
// NOTE(anson): we omit modeling for audio-only books because we don't support listening to them yet
sealed class Audiobook {
    abstract val id: String

    /**
     * You can take this identifier to the `publicCatalog` Firestore collection to retrieve many more additional details
     */
    abstract val audiobookProductReference: String
    abstract val title: String
    abstract val author: String
    abstract val coverImageUrl: String

    data class Immersive(
        override val id: String,
        override val audiobookProductReference: String,
        override val title: String,
        override val author: String,
        override val coverImageUrl: String,
    ) : Audiobook()
}

@JsExport
// NOTE(anson): we omit modeling for audio-only chapters because we don't support listening to them yet
sealed class AudiobookChapter {
    abstract val bookId: String
    abstract val id: String
    abstract val chapterIndex: Int
    abstract val title: String
    abstract val audiobookProductReference: String
    internal abstract val listeningProgress: SyncedListeningProgress?

    data class Aligned internal constructor(
        override val bookId: String,
        override val id: String,
        override val audiobookProductReference: String,
        override val chapterIndex: Int,
        override val title: String,
        override val listeningProgress: SyncedListeningProgress?,
        val alignmentUri: String,
        val contentUri: String,
        val durationSeconds: Int?,
        val wordCount: Int?,
    ) : AudiobookChapter()

    /**
     * URI that can be persisted and resolved in another context
     */
    val uri
        get() = SpeechifyURI.fromExistingId(
            SpeechifyEntityType.AUDIOBOOK_CHAPTER,
            id,
        )
}

internal sealed class AudiobookItem {
    class Book(val audiobook: Audiobook) : AudiobookItem()
    class Chapter(val chapter: AudiobookChapter) : AudiobookItem()
}

/**
 * HACK(anson): sometimes we set the ref to be teh powerup ID. This logic is still fluid, so here we restrict the
 * interface to just show audiobook ref, and parse the ref accordingly
 */
internal fun audiobookItemRefToAudiobookProductRef(ref: String): String {
    return ref.split(":").last()
}
